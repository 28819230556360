import React, { Component } from "react";
import { Table, Button, Menu, Icon, Header } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import Select from "react-select";
import requisicoes from "../../../utils/Requisicoes";

let buscando = false;

export default class PessoaLista extends Component {
  constructor(props) {
    super(props);
    this.state = { listaPessoa: [], pagina: 1, totalPaginas: 1 };
    this.listaPessoa();
  }

  listaPessoa = async (page = 1) => {
    const resposta = await api.post(
      "/pessoa/lista" + requisicoes.entidade + "&pagina=" + page,
      "",
      requisicoes.header
    );
    const { pessoas, pagina, totalPaginas } = resposta.data;

    this.setState({ listaPessoa: pessoas, pagina, totalPaginas });
  };

  pesquisaPessoa = async () => {
    if (buscando === true) return;
    buscando = true;
    setTimeout(async () => {
      const resp = await api.post(
        "/pessoa/pesquisaTodos" + requisicoes.entidade,
        {
          pesquisa: document.getElementById("pesquisaPessoa").value,
        },
        requisicoes.header
      );
      buscando = false;
      this.setState({ listaPessoa: resp.data });
    }, 300);
  };

  proximaPagina = async () => {
    const { pagina, totalPaginas } = this.state;
    if (pagina >= totalPaginas) return;
    const paginaNumero = parseInt(pagina) + 1;
    this.listaPessoa(paginaNumero);
  };

  voltarPagina = async () => {
    const { pagina } = this.state;
    if (pagina <= 1) return;
    const paginaNumero = pagina - 1;
    this.listaPessoa(paginaNumero);
  };

  render() {
    return (
      <div className="lista">
        <Header
          as="h1"
          dividing
          style={{
            display:
              window.localStorage.getItem("pagina_anterior") === "pessoa"
                ? "none"
                : "block",
          }}
        >
          Pessoas
        </Header>
        <Row>
          <Col sm={6}>
            <Select
              placeholder="Digite para buscar: Nome, CPF ou CNPJ"
              name="pessoa"
              onKeyDown={this.pesquisaPessoa}
              inputId="pesquisaPessoa"
              menuIsOpen={false}
            />
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Título</Table.HeaderCell>
              <Table.HeaderCell>Tipo de Título</Table.HeaderCell>
              <Table.HeaderCell>Cidade</Table.HeaderCell>
              <Table.HeaderCell>Estado</Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <Link to={{ pathname: "/pessoa" }}>
                  <Button positive icon="add" size="tiny"></Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.listaPessoa.map((e, i) => (
              <Table.Row key={i} negative={e.ativo === false}>
              <Table.Cell>
                {e.id}
              </Table.Cell>
                <Table.Cell>
                  {e.ativo === false && <strong>{"(inativo) "}</strong>}
                  {e.nome}
                </Table.Cell>
                <Table.Cell>
                  {e.acao && e.acao.codigo !== null
                    ? e.acao.codigo
                    : e.acao && e.acao.codigo_auxiliar !== null
                    ? e.acao.codigo_auxiliar : " "}
                </Table.Cell>
                <Table.Cell>
                  {e.tipo_titulo
                    ? e.tipo_titulo
                    : e.acao && e.acao.tipo_titulo !== null
                    ? e.acao.tipo_titulo.descricao
                    : " "}
                </Table.Cell>
                <Table.Cell>{e.cidade}</Table.Cell>

                <Table.Cell>{e.estado}</Table.Cell>
                <Table.Cell width={1}>
                  <Link to={{ pathname: "/pessoa", query: { id: e.id } }}>
                    <Button primary icon="edit" size="tiny"></Button>
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan={7}>
                <Menu pagination>
                  <Menu.Item
                    icon
                    onClick={this.voltarPagina}
                    disabled={parseInt(this.state.pagina) === 1}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  <Menu.Item
                    icon
                    onClick={this.proximaPagina}
                    disabled={
                      parseInt(this.state.pagina) ===
                      parseInt(this.state.totalPaginas)
                    }
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}
