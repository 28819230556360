import React, { Component } from "react";
import {
  FormField,
  Header,
  Modal,
  Button,
  Segment,
  Grid,
  Divider,
  Input,
  Message,
} from "semantic-ui-react";
import { IconButton } from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";
import api from "../../../services/api";
import Requisicoes from "../../../utils/Requisicoes";

export default class ModalCancelamento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logado: false,
      login: null,
      senha: null,
      mensagem_erro_login: null,
    };
  }

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  login = async () => {
    await api
      .post(
        "/usuario/autenticacaoAdministrador" + Requisicoes.entidade,
        { login: this.state.login, senha: this.state.senha },
        Requisicoes.header
      )
      .then((e) => {
        if (e.data.validacao === true) {
          this.setState({ logado: true });
          this.props.setaAdministradorId(e.data.administrador_id);
        } else {
          this.setState({ mensagem_erro_login: "Perfil de Usuário Inválido!" });
        }
      })
      .catch(() => {
        this.setState({ mensagem_erro_login: "Login Inválido!" });
      });
  };

  render() {
    return (
      <Modal
        open={this.props.openMensagem}
        centered={false}
        // onClose={this.props.openMensagem}
      >
        <IconButton
          className="bottomModal"
          color="secondary"
          onClick={() => this.props.handleClose(false)}
        >
          <CloseIcon />
        </IconButton>
        <Header content="Mensagem" />

        <Modal.Content>
          Tem Certeza que Deseja realizar o Cancelamento?
          <Divider />
          {this.props.usuarioLogado !== null &&
          this.state.logado === false &&
          (this.props.usuarioLogado.permitir_estorno === null ||
            this.props.usuarioLogado.permitir_estorno === false) ? (
            <Grid
              textAlign="center"
              style={{ height: "50vh" }}
              verticalAlign="middle"
              container
            >
              <Grid.Column style={{ maxWidth: 450 }}>
                <Header as="h1" color="blue" textAlign="center">
                  Login Administrador
                </Header>
                <Segment raised>
                  <FormField>
                    <Input
                      placeholder="Login"
                      icon="user"
                      iconPosition="left"
                      fluid
                      name="login"
                      onChange={this.alteracao}
                    />
                  </FormField>
                  <FormField style={{ marginTop: "10px" }}>
                    <Input
                      placeholder="Senha"
                      icon="lock"
                      iconPosition="left"
                      fluid
                      type="password"
                      name="senha"
                      onChange={this.alteracao}
                    />
                  </FormField>
                  {this.state.mensagem_erro_login !== null && (
                    <Message negative size="mini">
                      {this.state.mensagem_erro_login}
                    </Message>
                  )}
                  <Button
                    fluid
                    primary
                    type="button"
                    size="large"
                    style={{ marginTop: "10px" }}
                    onClick={this.login}
                  >
                    Login
                  </Button>
                </Segment>
              </Grid.Column>
            </Grid>
          ) : (
            <Segment clearing>
              <Header floated="right">
                <Button
                  variant="light"
                  onClick={this.props.handleCloseMensagem}
                  style={{ marginLeft: "10px" }}
                >
                  Voltar
                </Button>
                <Button primary onClick={this.props.cancelarBaixaTitulo}>
                  Salvar
                </Button>
              </Header>
            </Segment>
          )}
        </Modal.Content>
      </Modal>
    );
  }
}
