import React, { Component } from "react";
import { Header, Button, Segment, Form, Message } from "semantic-ui-react";
import { Divider } from "semantic-ui-react";

import { Link } from "react-router-dom";

import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

export default class InstituicaoCobranca extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Options selects
      bancoOptions: [],

      //editando ou criando
      instituicao_id: null,

      //Fields values
      agencia: "",
      digito_agencia: "",
      banco: "",
      conta: "",
      digito_conta: "",
      codigo_convenio: "",
      boleto_checkbox: false,
      debito_em_conta_checkbox: false,
      cliente: "",
      digito_cliente: "",
      descricao: "",
      modalide: 1,
      carteira: "",
      checkboxRegistroAutomatico: false,
      linkApi: "",
      clientId: "",
      username: "",
      password: "",

      porcentagem_juros: 0,
      porcentagem_multa: 0,

      // estados da requisicao
      loading: false,
      error: false,
      success: false,
    };
    if (this.props.location.query && this.props.location.query.id) {
      this.visualiza(this.props.location.query.id);
    }
    this.buscaBancos();
  }

  alteracao(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  alteracaoCheckbox(event, a) {
    this.setState({ [a.name]: a.checked });
  }

  visualiza = async (id) => {
    const instituicao = await api.post(
      "/instituicaoCobranca/visualiza" + requisicoes.entidade,
      {
        id: id,
      },
      requisicoes.header
    );
    const inst = instituicao.data;
    this.setState({
      instituicao_id: inst.id,
      agencia: inst.agencia,
      digito_agencia: inst.digito_agencia,
      banco: inst.banco_id,
      conta: inst.conta,
      digito_conta: inst.digito_conta,
      codigo_convenio: inst.codigo_convenio,
      boleto_checkbox: inst.boleto,
      debito_em_conta_checkbox: inst.debito_em_conta,
      cliente: inst.cliente,
      digito_cliente: inst.digito_cliente,
      descricao: inst.descricao,
      carteira: inst.carteira,
      modalide: inst.modalide,
      checkboxRegistroAutomatico: inst.registro_automatico,
      linkApi: inst.url_api,
      clientId: inst.client_id,
      username: inst.username,
      password: inst.password,
      porcentagem_juros: inst.porcentagem_juros,
      porcentagem_multa: inst.porcentagem_multa,
    });
  };

  buscaBancos = async () => {
    const bancos = await api.post(
      "/banco/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    let bancoOptions = [];
    for (let i = 0; i < bancos.data.length; i++) {
      const element = bancos.data[i];
      bancoOptions.push({
        key: element.id,
        value: element.id,
        text: element.nome,
      });
    }
    this.setState({ bancoOptions });
  };

  submit = async () => {
    try {
      this.setState({ loading: true, error: false, success: false });
      let instituicaoCobranca = {
        agencia: this.state.agencia,
        digito_agencia: this.state.digito_agencia,
        conta: this.state.conta,
        digito_conta: this.state.digito_conta,
        banco_id: this.state.banco,
        boleto: this.state.boleto_checkbox,
        codigo_convenio: this.state.codigo_convenio,
        debito_em_conta: this.state.debito_em_conta_checkbox,
        cliente: this.state.cliente,
        digito_cliente: this.state.digito_cliente,
        descricao: this.state.descricao,

        registro_automatico: this.state.checkboxRegistroAutomatico,
        url_api: this.state.checkboxRegistroAutomatico
          ? this.state.linkApi
          : null,
        client_id: this.state.checkboxRegistroAutomatico
          ? this.state.clientId
          : null,
        username: this.state.checkboxRegistroAutomatico
          ? this.state.username
          : null,
        password: this.state.checkboxRegistroAutomatico
          ? this.state.password
          : null,

        porcentagem_juros: this.state.porcentagem_juros,
        porcentagem_multa: this.state.porcentagem_multa,
      };

      if (this.state.instituicao_id) {
        await api.post(
          "/instituicaoCobranca/alterar" + requisicoes.entidade,
          { ...instituicaoCobranca, id: this.state.instituicao_id },
          requisicoes.header
        );
      } else {
        await api.post(
          "/instituicaoCobranca/salvar" + requisicoes.entidade,
          instituicaoCobranca,
          requisicoes.header
        );
      }

      this.setState({ loading: false, success: true });
      setTimeout(() => {
        window.location.pathname = "/instituicaoCobranca/lista";
      }, 1000);
    } catch (e) {
      this.setState({ loading: false, error: true });
      setTimeout(() => {
        this.setState({ error: false });
      }, 4000);
    }
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Instituição cobrança
        </Header>
        <Form
          onSubmit={(e, a) => this.submit()}
          error={this.state.error}
          success={this.state.success}
          loading={this.state.loading}
        >
          <Form.Group>
            <Form.Input
              name="agencia"
              label={"Agencia"}
              placeholder="Agencia"
              value={this.state.agencia}
              type="number"
              onChange={(e) => this.alteracao(e)}
              required
              width="4"
            />
            <Form.Input
              name="digito_agencia"
              label={"Digito Agência"}
              placeholder="Digito Agência"
              onChange={(e) => this.alteracao(e)}
              value={this.state.digito_agencia}
              type="number"
              width="4"
            />

            <Form.Select
              name="banco"
              options={this.state.bancoOptions}
              label={"Banco"}
              placeholder="Banco"
              required
              search
              value={this.state.banco}
              onChange={(e, a) => {
                this.setState({ banco: a.value });
                if (this.state.descricao === "" || !this.state.descricao) {
                  this.setState({ descricao: e.target.textContent });
                }
              }}
              type="number"
              width="8"
            />
          </Form.Group>

          <Form.Group>
            <Form.Input
              name="conta"
              label={"Conta"}
              placeholder="Conta"
              required
              onChange={(e) => this.alteracao(e)}
              value={this.state.conta}
              type="number"
              width="4"
            />

            <Form.Input
              name="digito_conta"
              label={"Digito Conta"}
              placeholder="Digito Conta"
              required
              onChange={(e) => this.alteracao(e)}
              value={this.state.digito_conta}
              type="number"
              width="4"
            />
            <Form.Input
              name="codigo_convenio"
              label={"Código do convenio"}
              placeholder="Código do convenio"
              onChange={(e) => this.alteracao(e)}
              value={this.state.codigo_convenio}
              type="number"
              width="4"
            />
            <Form.Group grouped width="4">
              <Form.Checkbox
                checked={this.state.boleto_checkbox}
                name="boleto_checkbox"
                onChange={(e, a) => this.alteracaoCheckbox(e, a)}
                label={"Boleto"}
                grouped
              />
              <Form.Checkbox
                checked={this.state.debito_em_conta_checkbox}
                name="debito_em_conta_checkbox"
                onChange={(e, a) => this.alteracaoCheckbox(e, a)}
                label={"Debito em conta"}
                grouped
              />
            </Form.Group>
          </Form.Group>
          <Form.Group>
            <Form.Input
              name="cliente"
              label={"Código do cliente"}
              placeholder="Código do cliente"
              required
              onChange={(e) => this.alteracao(e)}
              value={this.state.cliente}
              type="number"
              width="4"
            />

            <Form.Input
              name="digito_cliente"
              label={"Digito do cliente"}
              placeholder="Digito do cliente"
              required
              onChange={(e) => this.alteracao(e)}
              value={this.state.digito_cliente}
              type="number"
              width="4"
            />
            <Form.Input
              name="carteira"
              label={"Carteira"}
              placeholder="Carteira"
              required
              onChange={(e) => this.alteracao(e)}
              value={this.state.carteira}
              type="number"
              min={1}
              width="2"
            />
            <Form.Select
              name="modalidade"
              label={"Modalide"}
              placeholder="Modalide"
              required
              onChange={(e) => this.alteracao(e)}
              disabled
              //Caso algum dia isso seja usado no futuro
              options={[
                { value: 1, text: "01-Cobrança Simples" },
                { value: 2, text: "02-Cobrança Vinculada" },
                { value: 3, text: "03-Cobrança Caucionada" },
                { value: 4, text: "04-Cobrança Descontada" },
                { value: 5, text: "05-Carnê de Pagamentos" },
              ]}
              value={this.state.modalide}
              type="number"
              width="2"
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              name="descricao"
              label={"Descrição"}
              placeholder="Descrição"
              onChange={(e) => this.alteracao(e)}
              value={this.state.descricao}
            />
          </Form.Group>

          <Divider />

          <Form.Group widths="equal">
            <Form.Input
              name="porcentagem_juros"
              label={"Porcentagem Juros (diária)"}
              placeholder="Porcentagem Juros"
              onChange={(e) => this.alteracao(e)}
              value={this.state.porcentagem_juros}
              type="number"
              required
            />
            <Form.Input
              name="porcentagem_multa"
              label={"Porcentagem Multa"}
              placeholder="Porcentagem Multa"
              onChange={(e) => this.alteracao(e)}
              value={this.state.porcentagem_multa}
              type="number"
              required
            />
          </Form.Group>
          <Divider />
          <Form.Checkbox
            label="Registro automático"
            checked={this.state.checkboxRegistroAutomatico}
            onChange={(e, a) =>
              this.setState({ checkboxRegistroAutomatico: a.checked })
            }
          />

          {this.state.checkboxRegistroAutomatico && (
            <>
              <Form.Group widths="equal">
                <Form.Input
                  name="linkApi"
                  label={"Link da api"}
                  placeholder="Link da api"
                  onChange={(e) => this.alteracao(e)}
                  value={this.state.linkApi}
                />
                <Form.Input
                  name="clientId"
                  label={"Cliente Id"}
                  placeholder="Cliente Id"
                  onChange={(e) => this.alteracao(e)}
                  value={this.state.clientId}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  name="username"
                  label={"Username"}
                  placeholder="Username"
                  onChange={(e) => this.alteracao(e)}
                  value={this.state.username}
                />
                <Form.Input
                  name="password"
                  label={"Password"}
                  placeholder="Password"
                  onChange={(e) => this.alteracao(e)}
                  value={this.state.password}
                />
              </Form.Group>
            </>
          )}

          <Message
            error
            header="Erro ao salvar o registro"
            content="Ocorreu um erro ao salvar a instituição cobrança."
          />
          <Message
            success
            header="Salvo com sucesso"
            content="O registro foi salvo com sucesso."
          />
          <Segment style={{ display: "flex", justifyContent: "flex-end" }}>
            <Link
              to={{
                pathname: "/instituicaoCobranca/lista",
              }}
            >
              <Button
                disabled={
                  this.state.loading || this.state.error || this.state.success
                }
                type="button"
              >
                Voltar
              </Button>
            </Link>

            <Button
              color="green"
              disabled={
                this.state.loading || this.state.error || this.state.success
              }
            >
              Salvar
            </Button>
          </Segment>
        </Form>
      </div>
    );
  }
}
