import React, { Component } from "react";
import {
  Container,
  Table,
  Button,
  Menu,
  Icon,
  Modal,
  Form,
  Message,
} from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

import TipoCargo from "../../../enum/TipoCargo";

export default class ListaCargo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaCargos: [],
      pagina: 1,
      totalPaginas: 1,
      openModal: false,
      nomeCargo: null,
      tipoCargo: null,
      cargoId: null,
      loading: false,
      mensagem: null,
    };
    this.listaCargos();
  }

  listaCargos = async (page = 1) => {
    const resposta = await api.post(
      "/funcionario/listaCargos" + requisicoes.entidade + "&pagina=" + page,
      "",
      requisicoes.header
    );
    // const { pessoas, pagina, totalPaginas } = resposta.data;
    console.log("resposta.data: ", resposta.data);
    this.setState({
      listaCargos: resposta.data.docs,
      pagina: page,
      totalPaginas: resposta.data.pages,
    });
  };

  proximaPagina = async () => {
    const { pagina, totalPaginas } = this.state;
    if (pagina >= totalPaginas) return;
    const paginaNumero = parseInt(pagina) + 1;
    this.listaCargos(paginaNumero);
  };

  voltarPagina = async () => {
    const { pagina } = this.state;
    if (pagina <= 1) return;
    const paginaNumero = pagina - 1;
    this.listaCargos(paginaNumero);
  };

  novoCargo = async () => {
    this.setState({ loading: true });
    if (
      this.state.nomeCargo === null ||
      this.state.nomeCargo === "" ||
      this.state.tipoCargo === null
    ) {
      this.setState({
        loading: false,
        mensagem: (
          <Message
            negative
            header="Cadastro incompleto"
            content="Forneça todas as informações solicitadas."
          />
        ),
      });
      setTimeout(async () => {
        this.setState({
          mensagem: null,
        });
      }, 5000);
      return;
    }

    if (this.state.cargoId !== null) {
      await api.post(
        "/funcionario/updateCargo" + requisicoes.entidade,
        {
          id: this.state.cargoId,
          nome: this.state.nomeCargo,
          tipo: this.state.tipoCargo,
        },
        requisicoes.header
      );
    } else {
      await api.post(
        "/funcionario/novoCargo" + requisicoes.entidade,
        {
          nome: this.state.nomeCargo,
          tipo: this.state.tipoCargo,
        },
        requisicoes.header
      );
    }

    this.listaCargos(this.state.pagina);
    this.setState({
      loading: false,
      mensagem:
        this.state.cargoId !== null ? (
          <Message
            success
            header="O cargo foi alterado!"
            content="O cargo foi alterado com sucesso."
          />
        ) : (
          <Message
            success
            header="O cargo foi cadastrado!"
            content="Novo cargo foi cadastrado com sucesso."
          />
        ),
    });
    setTimeout(async () => {
      this.setState({
        openModal: false,
        mensagem: null,
      });
    }, 3000);
  };

  render() {
    return (
      <div className="lista">
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Tipo de cargo</Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <Button
                  positive
                  icon="add"
                  size="tiny"
                  onClick={() =>
                    this.setState({
                      openModal: true,
                      nomeCargo: null,
                      tipoCargo: null,
                      cargoId: null,
                    })
                  }
                ></Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.listaCargos.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>{e.nome}</Table.Cell>
                <Table.Cell>
                  {TipoCargo.find((element) => element.value === e.tipo).text}
                </Table.Cell>
                <Table.Cell width={1}>
                  <Button
                    primary
                    icon="edit"
                    size="tiny"
                    onClick={() =>
                      this.setState({
                        openModal: true,
                        nomeCargo: e.nome,
                        tipoCargo: e.tipo,
                        cargoId: e.id,
                      })
                    }
                  ></Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan={4}>
                <Menu pagination>
                  <Menu.Item
                    icon
                    onClick={this.voltarPagina}
                    disabled={parseInt(this.state.pagina) === 1}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  <Menu.Item
                    icon
                    onClick={this.proximaPagina}
                    disabled={
                      parseInt(this.state.pagina) ===
                      parseInt(this.state.totalPaginas)
                    }
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Modal
          open={this.state.openModal}
          onClose={() => this.setState({ openModal: false })}
          style={{ overflow: "visible" }}
        >
          <Modal.Header>Novo Cargo</Modal.Header>
          <Modal.Content>
            <Form loading={this.state.loading}>
              <Row>
                <Col>
                  <Form.Input
                    label="Nome do cargo"
                    placeholder="Nome do cargo"
                    value={this.state.nomeCargo}
                    onChange={(e, a) => this.setState({ nomeCargo: a.value })}
                  />
                </Col>
                <Col>
                  <Form.Select
                    label="Tipo do cargo"
                    placeholder="Tipo do cargo"
                    options={TipoCargo}
                    value={this.state.tipoCargo}
                    onChange={(e, a) => this.setState({ tipoCargo: a.value })}
                  />
                </Col>
              </Row>
            </Form>
            {this.state.mensagem !== null && this.state.mensagem}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.setState({ openModal: false })}>
              Voltar
            </Button>
            <Button
              color="green"
              onClick={this.novoCargo}
              loading={this.state.loading}
            >
              Salvar
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
