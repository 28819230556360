import React, { Component } from "react";
import {
  Container,
  Header,
  Menu,
  Icon,
  Table,
  Button,
} from "semantic-ui-react";
import { Row, Col, Form } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import { Link } from "react-router-dom";
import Select from "react-select";
import moment from "moment-timezone";

import api from "../../../services/api";
import renomearChave from "../../../services/renomearChave";

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista_titulares: [],
      imprimindo: false,

      servicoSelect: null,
      servico_value: null,
      servico_id: null,

      servico: 261,

      tipo_cobranca: "boleto",

      tipo_titulo: 12514,
      data_inicio: moment().year() + "-02-01",
      data_final: moment().year() + 1 + "-02-01",
      selectedTipoTitulo: null,
      optionsTipoTitulo: [],
      optionsSelectBuscaServicos: [],
      optionsBuscaServicos: [],
    };
    this.selectBuscaServicos();
  }

  alteracao = async (event, value) => {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ lista_titulares: [] });
    this.setState({ lista_debitoConta: [] });
  };

  pesquisaServicos = async (event) => {
    var r = await api.post(
      "/auditoria/numeroDeDividasServico" + requisicoes.entidade,
      {
        data_inicio: this.state.data_inicio,
        data_final: this.state.data_final,
        tipo_titulo: this.state.tipo_titulo,
        servico: this.state.servico,
        tipo_cobranca: this.state.tipo_cobranca,
      },
      requisicoes.header
    );

    this.setState({ lista_titulares: r.data });
  };

  selectBuscaServicos = async () => {
    //Busca os valores dos servicos e dos tipo titulos para os selects

    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    const retornoservicos = await api.post(
      "/lancamentoConta/buscaTodosServicos?e=" + entidade,
      {},
      {
        headers: { token: "Baer " + token },
      }
    );
    retornoservicos.data.sort(function (a, b) {
      if (a.nome > b.nome) {
        return 1;
      }
      if (a.nome < b.nome) {
        return -1;
      }
      return 0;
    });
    var lista = [];
    // lista.push({label: "Todos", value: 0})
    for (let i = 0; i < retornoservicos.data.length; i++) {
      const element = retornoservicos.data[i];
      if (
        element.turmas === true &&
        element.dataInicio === null &&
        element.dataFinal === null
      ) {
        continue;
      }
      let objeto = renomearChave(element, "id", "value");
      objeto = renomearChave(objeto, "nome", "label");
      if (element.turmas === true) {
        objeto.label += " - " + moment(element.dataInicio).year();
      }
      objeto.dataInicio = element.dataInicio;
      objeto.dataFinal = element.dataFinal;
      objeto.turmas = element.turmas;

      lista.push(objeto);
    }
    this.setState({ optionsBuscaServicos: lista });
    this.setState({ optionsSelectBuscaServicos: lista });

    const tiposTitulos = await api.post(
      "/tipoTitulo/lista?e=" + entidade,
      {},
      {
        headers: { token: "Baer " + token },
      }
    );
    console.log("tiposTitulos: ", tiposTitulos.data);
    lista = [];
    for (let i = 0; i < tiposTitulos.data.length; i++) {
      const element = tiposTitulos.data[i];
      lista.push({ value: element.id, label: element.descricao });
    }
    this.setState({ optionsTipoTitulo: lista });
    console.log("mes: ", this.state.mesSelecionado);
  };

  render() {
    return (
      <div className="pagina" style={{ paddingBottom: 80 }}>
        <Header as="h1" dividing>
          Dívidas
        </Header>
        <Row
          style={{
            marginBottom: "10px",
            marginTop: "15px",
            marginLeft: 0,
            marginRight: 0,
            display: "flex",
          }}
        >
          <Button
            positive
            style={{ marginTop: "25px", marginLeft: "auto" }}
            onClick={this.pesquisaServicos}
          >
            Buscar
          </Button>
        </Row>
        <Row>
          <Col sm={2}>
            <Form.Label>Data Inicio</Form.Label>
            <Form.Control
              placeholder="Data Inicio"
              aria-describedby="basic-addon1"
              type="date"
              name="data_inicio"
              value={this.state.data_inicio}
              onChange={this.alteracao}
            />
          </Col>
          <Col sm={2}>
            <Form.Label>Data Final</Form.Label>
            <Form.Control
              placeholder="Data Final"
              aria-describedby="basic-addon1"
              type="date"
              name="data_final"
              value={this.state.data_final}
              onChange={this.alteracao}
            />
          </Col>

          <Col sm={4}>
            <Form.Label>Tipo de título</Form.Label>
            <Form.Control
              placeholder="Tipo de título"
              aria-describedby="basic-addon1"
              as="select"
              name="tipo_titulo"
              value={this.state.tipo_titulo}
              onChange={this.alteracao}
              // options={this.state.optionsTipoTitulo}
            >
              {this.state.optionsTipoTitulo.map((e, i) => (
                <option value={e.value}>{e.label}</option>
              ))}
            </Form.Control>
          </Col>

          <Col sm={4}>
            <Form.Label>Serviço</Form.Label>
            <Form.Control
              placeholder="Selecione o serviço"
              as="select"
              name="servico"
              id="servico"
              required
              value={this.state.servico}
              onChange={this.alteracao}
              options={this.state.optionsSelectBuscaServicos}
            >
              {this.state.optionsSelectBuscaServicos.map((e, i) => (
                <option value={e.value}>{e.label}</option>
              ))}
            </Form.Control>
          </Col>

          <Col sm={2}>
            <Form.Label>Tipo de cobrança</Form.Label>
            <Form.Control
              placeholder="Tipo de cobrança"
              aria-describedby="basic-addon1"
              as="select"
              name="tipo_cobranca"
              value={this.state.tipo_cobranca}
              onChange={this.alteracao}
            >
              <option value={"boleto"}>Boleto</option>
              <option value={"debito_em_conta"}>Debito em conta</option>
            </Form.Control>
          </Col>
        </Row>

        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Quantidade</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.lista_titulares.length === 0 ? (
              <Table.Row>
                <Table.Cell colSpan={5}>
                  <div style={{ margin: "15px" }}>
                    <h4> Não há dados para mostrar. </h4>
                  </div>
                </Table.Cell>
              </Table.Row>
            ) : (
              this.state.lista_titulares.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{e.nome}</Table.Cell>
                  <Table.Cell>{e.count}</Table.Cell>
                </Table.Row>
              ))
            )}
          </Table.Body>
        </Table>
      </div>
    );
  }
}
