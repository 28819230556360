import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Col, Row, FormControl, Alert, Button } from "react-bootstrap";
import {
  Header,
  Table,
  Divider,
  Modal,
  Segment,
  Label,
  Form as FormSemantic,
  Select,
  TextArea,
} from "semantic-ui-react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

//utils
import numeroTitulo from "../../../utils/numeroTituloCodigoBoleto";
import calculoMoraJuro from "../../../utils/calculoMoraJuro";

import { parseISO, format } from "date-fns";
import renomearChave from "../../../services/renomearChave";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";

import TipoLancamento from "../../../enum/TipoLancamento";
import Checkbox from "muicss/lib/react/checkbox";
import Requisicoes from "../../../utils/Requisicoes";
import CircularProgress from "@material-ui/core/CircularProgress";

import api from "../../../services/api";
import moment from "moment";
import CancelamentoBaixa from "./modalCancelamento";
import ModalVisualiza from "./modalVisualiza";
import Liquidacoes from "../liquidacao/index";

let buscando = false;
export default class Caixa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lancamento_contas: [],
      lancamento_contas_modal: [],
      open: false,
      openMensagem: false,
      open_lancamentos: false,
      open_fechamento_caixa: false,
      open_aberto_caixa: false,
      confirmaCaixaId: 0,
      pessoa: null,
      options: [],
      codigoBoleto: null,
      lancamento_conta: null,
      forma_pagamento: null,
      caixa: null,
      caixaAberto: null,
      totalCaixa: null,
      mensagem: null,
      element: null,
      numPages: null,
      pageNumber: 1,
      totalGeral: 0,
      selectedCheckboxes: new Set(),
      saldo_anterior: null,
      total_movimento_dia: null,
      total_suprimento: null,
      total_sangria: null,
      total_transferencia: null,
      confirmacao_fechamento_caixa: false,
      tem_porcentagem: null,
      administrador_id: null,
      lancamentosDependentes: [],
      total_titular: 0.0,
      total_dependentes: 0.0,
      usuarioLogado: null,
      optionsContaBancaria: [],
      contaBancaria: null,
      socioChecked: true,
      ativoChecked: true,
      dataAbertura: null,
      sangriaAutomaticaChecked: true,
      observacaoSangria: "",

      pagamentos_com_caixa: false,

      openModalLiquidacao: false,

      porcentagem_juros: 0.07,
      porcentagem_multa: 2.0,
    };
    this.visualiza();
    this.buscaContaBancaria();
    this.buscaParametrosCaixa();
    if (
      this.props.location.query !== undefined &&
      this.props.location.query.id !== undefined
    ) {
      let selectCheckBoxProps = this.props.location.query.selectCheckBox;
      if (selectCheckBoxProps !== undefined) {
        selectCheckBoxProps.delete(undefined);
        //this.setState({selectedCheckboxes: selectCheckBoxProps});
        this.state.selectedCheckboxes = selectCheckBoxProps;
      }
      let v = { value: this.props.location.query.id };
      this.lancamentoEmAbertoPorPessoa(null, v);
    }
  }

  buscaParametrosCaixa = async () => {
    const resposta = await api.post(
      "/parametros/visualiza" + Requisicoes.entidade,
      {
        nome: [
          "pagamentos_com_caixa",
          "porcentagem_juros",
          "porcentagem_multa",
        ],
      },
      Requisicoes.header
    );
    const listaParametros = resposta.data;

    for (let i = 0; i < listaParametros.length; i++) {
      const element = listaParametros[i];
      if (element.nome === "pagamentos_com_caixa" && element.valor === "true") {
        this.setState({ pagamentos_com_caixa: true });
      } else if (element.nome === "porcentagem_juros") {
        this.setState({ porcentagem_juros: parseFloat(element.valor) });
      } else if (element.nome === "porcentagem_multa") {
        this.setState({ porcentagem_multa: parseFloat(element.valor) });
      }
    }
  };

  buscaContaBancaria = async () => {
    let lista = await api.post(
      "/contaBancaria/lista" + Requisicoes.entidade,
      {},
      Requisicoes.header
    );
    for (let i = 0; i < lista.data.length; i++) {
      const element = lista.data[i];
      element.key = element.id;
      element.text = element.conta;
      element.label = (
        <Label content={element.banco.nome} attached="bottom right"></Label>
      );
      element.value = element.id;
    }
    this.setState({ optionsContaBancaria: lista.data });
  };

  setaAdministradorId = (id) => {
    this.setState({ administrador_id: id });
  };

  toggleCheckbox = (e) => {
    if (this.state.selectedCheckboxes.has(e.target.value)) {
      this.state.selectedCheckboxes.delete(e.target.value);
    } else {
      this.state.selectedCheckboxes.add(e.target.value);
    }
  };
  mensagemCaixaAberto = async () => {
    this.handleCloseAbertoCaixa();
    const retorno = await api.post(
      "/caixa/mensagemCaixaAberto" + Requisicoes.entidade,
      {
        mensagemCaixaAberto: true,
        confirmaCaixaId: this.state.confirmaCaixaId,
        tipo: "caixa",
      },
      Requisicoes.header
    );
  };
  visualiza = async () => {
    const retorno = await api.post(
      "/caixa/consultaCaixaPorUsuarioETipo" + Requisicoes.entidade,
      { tipo: "caixa" },
      Requisicoes.header
    );
    // this.state.confirmaCaixaId = retorno.data.id;
    // if (!retorno.data.data_fechamento) {
    //   const retorno2 = await api.post(
    //     "/caixa/mensagemCaixaAberto" + Requisicoes.entidade,
    //     {
    //       mensagemCaixaAberto: false,
    //       confirmaCaixaId: this.state.confirmaCaixaId,
    //       tipo: "caixa",
    //     },
    //     Requisicoes.header
    //   );
    // }
    this.setState({ confirmaCaixaId: retorno.data.id });
    if (!retorno.data.data_fechamento) {
      this.state.dataAbertura = moment(retorno.data.data_abertura).format(
        "DD/MM/YYYY"
      );
      if (
        retorno.data.data_abertura < moment().format("YYYY-MM-DD") &&
        retorno.data.mensagem_caixa_aberto === false
      ) {
        this.handleShowAbertoCaixa();
      } else if (
        retorno.data.data_modal === null &&
        retorno.data.data_modal < moment().format("YYYY-MM-DD")
      )
        this.handleShowAbertoCaixa();
    }

    let total = 0;
    let totalMovimentoDia = 0;
    let totalSuprimento = 0;
    let totalSangria = 0;
    let totalTransferencia = 0;
    let totalDepositoBancario = 0;
    if (retorno.data !== "") {
      this.setState({ caixa: retorno.data });
      retorno.data.lancamento_contas.forEach((e) => {
        //seta saldo anterior
        if (e.tipo_lancamento === "saldoanterior") {
          this.setState({ saldo_anterior: e });
        }
        //calcula total movimento
        if (
          e.tipo_lancamento !== "saldoanterior" &&
          e.tipo_lancamento !== "sangria" &&
          e.tipo_lancamento !== "suprimento" &&
          e.tipo_lancamento !== "transferencia"
        ) {
          if (e.credito_debito === "credito") {
            if (e.valor_pago !== null) {
              totalMovimentoDia = totalMovimentoDia + parseFloat(e.valor_pago);
            } else {
              totalMovimentoDia = totalMovimentoDia + parseFloat(e.valor);
            }
          } else {
            if (e.valor_pago !== null) {
              totalMovimentoDia = totalMovimentoDia - parseFloat(e.valor_pago);
            } else {
              totalMovimentoDia = totalMovimentoDia - parseFloat(e.valor);
            }
          }
        }
        //calcula total suprimento
        if (e.tipo_lancamento === "suprimento") {
          totalSuprimento = totalSuprimento + parseFloat(e.valor);
        }
        //calcula total sangria
        if (e.tipo_lancamento === "sangria") {
          totalSangria = totalSangria + parseFloat(e.valor);
        }
        //calcula total transferencia
        if (e.tipo_lancamento === "transferencia") {
          if (e.credito_debito === "credito") {
            totalTransferencia = totalTransferencia + parseFloat(e.valor);
          } else {
            totalTransferencia = totalTransferencia - parseFloat(e.valor);
          }
        }
        //calcula total depósito bancário
        if (e.forma_pagamento === "depositoBancario") {
          totalDepositoBancario =
            totalDepositoBancario + parseFloat(e.valor_pago);
        }
        //calcula total geral
        // else
        if (e.credito_debito === "credito") {
          if (e.valor_pago !== null) {
            total = total + parseFloat(e.valor_pago);
          } else {
            total = total + parseFloat(e.valor);
          }
        } else {
          if (e.valor_pago !== null) {
            total = total - parseFloat(e.valor_pago);
          } else {
            total = total - parseFloat(e.valor);
          }
        }
      });

      this.setState({ total_movimento_dia: totalMovimentoDia });
      this.setState({ total_suprimento: totalSuprimento });
      this.setState({ total_sangria: totalSangria });
      this.setState({ total_transferencia: totalTransferencia });
      this.setState({ totalCaixa: total });
      this.setState({ totalDepositoBancario: totalDepositoBancario });
    }
  };
  handleClose = () => {
    // window.location.reload();
    this.setState({ open: false });
  };

  handleShow = () => this.setState({ open: true });

  handleCloseFechamentoCaixa = () => {
    window.location.reload();
    this.setState({ open_fechamento_caixa: false });
  };
  handleShowAbertoCaixa = () => {
    this.setState({ open_aberto_caixa: true });
  };
  handleCloseAbertoCaixa = () => {
    this.setState({ open_aberto_caixa: false });
  };

  handleShowFechamentoCaixa = () =>
    this.setState({ open_fechamento_caixa: true });

  handleCloseLancamentos = () => {
    // window.location.reload();
    this.setState({
      open_lancamentos: false,
      lancamento_contas: [],
      lancamentosDependentes: [],
      total_dependentes: 0.0,
      total_titular: 0.0,
      forma_pagamento: null,
      sangriaAutomaticaChecked: true,
      contaBancaria: null,
      lancamento_contas_modal: [],
      selectedCheckboxes: new Set(),
    });

    this.visualiza();
  };

  handleShowLancamentos = () => this.setState({ open_lancamentos: true });

  handleCloseMensagem = () => {
    window.location.reload();
    this.setState({ openMensagem: false });
  };

  handleShowMensagem = async (event) => {
    this.setState({
      openMensagem: true,
      element: parseInt(event.target.id.toString().substring(6, 20)),
    });
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + Requisicoes.entidade,
      {},
      Requisicoes.header
    );
    this.setState({ usuarioLogado: usuarioLogado.data });
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    this.somaValor();
  };

  converterParaLabel = (objetoLista) => {
    let lista = [];
    objetoLista.forEach((element) => {
      let objeto = renomearChave(element, "id", "value");
      objeto.label =
        renomearChave(objeto.acao, "codigo", "label").label +
        " - " +
        objeto.nome;
      if (element.titular_id !== null) {
        objeto.label += "    - Dependente";
      } else if (element.acao === undefined || element.acao === null) {
        objeto.label += "    - Sem ação";
      } else {
        objeto.label += "    - Titular";
      }
      lista.push(objeto);
    });
    return lista;
  };

  converterParaLabelPessoa = (objetoLista, prefixo, busca) => {
    if (busca) {
      busca = busca.toUpperCase();
    }
    function ordenaNomesBusca(a, b) {
      if (
        a.nome.toUpperCase().indexOf(busca) >
        b.nome.toUpperCase().indexOf(busca)
      ) {
        return 1;
      }
      if (
        a.nome.toUpperCase().indexOf(busca) <
        b.nome.toUpperCase().indexOf(busca)
      ) {
        return -1;
      }
      return 0;
    }

    let lista2 = [];
    objetoLista.forEach((element) => {
      let objeto = element;
      element.value = element.id;
      element.label = element.nome;

      if (element.titular_id !== null) {
        objeto.label += "    - Dependente";
      } else if (element.acao === undefined || element.acao === null) {
        objeto.label += "    - Sem ação";
      } else {
        objeto.label += "    - Titular";
      }
      lista2.push(objeto);
    });
    lista2.sort(ordenaNomesBusca);
    return lista2;
  };

  pesquisa = async () => {
    if (buscando) {
      return;
    }
    let term = document.getElementById("pesquisaPessoa");
    if (term === null) {
      return;
    }
    this.setState({
      lancamento_contas: [],
      lancamentosDependentes: [],
      total_dependentes: 0.0,
      buscando: true,
    });

    buscando = true;

    setTimeout(async () => {
      term = document.getElementById("pesquisaPessoa");

      if (term === null || term.value.trim() === "") {
        buscando = false;
        this.setState({ buscando: false });
        return;
      }
      term = term.value;
      if (isFinite(String(term))) {
        await api
          .post(
            "/pessoa/pesquisaPorCodigoAcaoValorExato" + Requisicoes.entidade,
            {
              pesquisa: term,
              ativo: this.state.ativoChecked,
              socio: this.state.socioChecked,
            },
            Requisicoes.header
          )
          .then((retorno) => {
            this.setState({ options: this.converterParaLabel(retorno.data) });
          });
      } else {
        await api
          .post(
            //"/pessoa/pesquisaNomeInicial" + Requisicoes.entidade,
            "/pessoa/pesquisa" + Requisicoes.entidade,
            {
              pesquisa: term,
              ativo: this.state.ativoChecked,
              socio: this.state.socioChecked,
            },
            Requisicoes.header
          )
          .then((retorno) => {
            this.setState({
              options: this.converterParaLabelPessoa(retorno.data, null, term),
            });
          });
      }
      buscando = false;
      this.setState({ buscando: false });
    }, 2000);
  };

  lancamentoEmAbertoPorPessoa = async (event, v) => {
    let retorno = null;
    let dependentes = null;
    var lancamentosDependentes = [];
    var totalDependentes = [];
    var totalTitular = 0.0;
    this.state.selectedCheckboxes.clear();
    if (v !== null) {
      retorno = await api.post(
        "/lancamentoConta/lancamentoEmAbertoPorPessoa" + Requisicoes.entidade,
        { pessoa_id: v.value },
        Requisicoes.header
      );

      for (let i = 0; i < retorno.data.length; i++) {
        const element = retorno.data[i];
        if (
          this.state.selectedCheckboxes.has(parseFloat(element.id).toString())
        ) {
          element.select = true;
        } else {
          element.select = false;
        }
        totalTitular += parseFloat(element.valor);
      }
      this.setState({ total_titular: totalTitular });
      dependentes = await api.post(
        "/pessoa/dependentes" + Requisicoes.entidade,
        { titular_id: v.value },
        Requisicoes.header
      );
      if (dependentes.data.length === 0) {
        const resposta = await api.post(
          "/pessoa/visualiza" + Requisicoes.entidade,
          { id: v.value },
          Requisicoes.header
        );
        if (resposta.data.titular_id !== null) {
          dependentes.data.push({ id: resposta.data.titular_id });
          let dependentes2 = await api.post(
            "/pessoa/dependentes" + Requisicoes.entidade,
            { titular_id: resposta.data.titular_id },
            Requisicoes.header
          );
          for (let i = 0; i < dependentes2.data.length; i++) {
            const element = dependentes2.data[i];
            if (element.id === v.value) {
              dependentes2.data.splice(i, 1);
              i--;
              continue;
            }
            dependentes.data.push(element);
          }
        }
      }
      for (let i = 0; i < dependentes.data.length; i++) {
        const element = dependentes.data[i];
        let ret2 = await api.post(
          "/lancamentoConta/lancamentoEmAbertoPorPessoa" + Requisicoes.entidade,
          { pessoa_id: element.id },
          Requisicoes.header
        );
        let aux = 0.0;
        if (ret2.data.length > 0) {
          for (let i = 0; i < ret2.data.length; i++) {
            const element2 = ret2.data[i];
            if (
              this.state.selectedCheckboxes.has(
                parseFloat(element2.id).toString()
              )
            ) {
              element2.select = true;
            } else {
              element2.select = false;
            }
            aux += parseFloat(element2.valor);
          }
          totalDependentes.push(aux);
          lancamentosDependentes.push(ret2.data);
        }
      }
    }

    if (
      (retorno !== null && retorno.data.length !== 0) ||
      lancamentosDependentes.length !== 0
    ) {

      this.setState({ lancamento_contas: retorno.data });
      this.setState({ lancamentosDependentes: lancamentosDependentes });
      this.setState({ total_dependentes: totalDependentes });
    } else {
      this.mostraMensagem("Nenhum Titulo Em Aberto Encontrado!");
    }
  };

  mostraMensagem = async (msg) => {
    this.setState({ mensagem: msg });
    this.setState({ caixaAberto: msg });
    setTimeout(async () => {
      this.setState({ caixaAberto: null });

      this.setState({ mensagem: null });
    }, 10000);
  };

  codigoLancamento = async (event) => {
    const numero = numeroTitulo(
      event.target.value.toString().replace(/\D/g, "")
    );
    if (numero !== undefined) {
      const retorno = await api.post(
        "/lancamentoConta/buscaNumeroTitulo" + Requisicoes.entidade,
        { numero_titulo: numero },
        Requisicoes.header
      );
      if (retorno.data !== "") {
        this.setState({ lancamento_conta: retorno.data });
        this.setState({ codigoBoleto: "" });
        this.handleShow();
      } else {
        this.mostraMensagem("Nenhum Titulo Em Aberto Encontrado!");
      }
    }
  };

  buscarTitulo = async (event) => {
    const retorno = await api.post(
      "/lancamentoConta/visualiza" + Requisicoes.entidade,
      {
        id: parseInt(event.target.id.toString().substring(6, 20)),
      },
      Requisicoes.header
    );
    this.setState({ lancamento_conta: retorno.data });
    if (retorno.data.descricao.replace(/\s+/g, "") !== "SaldoAnterior") {
      this.handleShow();
    }
  };

  buscarTitulos = async (event) => {
    try {
      let lista = [];
      this.state.selectedCheckboxes.forEach((e) => {
        lista.push(parseInt(e));
      });
      const retorno = await api.post(
        "/lancamentoConta/buscarVariosLancamentos" + Requisicoes.entidade,
        {
          lancamentos: lista,
        },
        Requisicoes.header
      );
      this.setState({ lancamento_contas_modal: retorno.data });
      let naoAbrirModal = false;
      retorno.data.forEach((e) => {
        if (e.descricao.replace(/\s+/g, "") === "SaldoAnterior") {
          naoAbrirModal = true;
        }
      });
      if (naoAbrirModal === false) {
        this.handleShowLancamentos();
        this.somaValor();
      }
    } catch (error) {
      alert("Problema ao buscar os lançamentos.");
    }
  };

  cancelarBaixaTitulo = async (event) => {
    await api
      .post(
        "/lancamentoConta/cancelarBaixa" + Requisicoes.entidade,
        {
          id: this.state.element,
          tipo: "caixa",
          administrador_id: this.state.administrador_id,
        },
        Requisicoes.header
      )
      .then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 200);
      })
      .catch(() => {
        alert(
          "Não é possível executar o cancelamento porque é saldo anterior ou já foi efetuado a remessa do mesmo"
        );
      });
  };

  fechaCaixa = async () => {
    const retorno = await api.post(
      "/caixa/fechaCaixa" + Requisicoes.entidade,
      { id: this.state.caixa.id },
      Requisicoes.header
    );
    if (retorno.data !== null) {
      window.location.reload();
    }
  };

  abreCaixa = async () => {
    this.setState({ abrindoCaixa: true });
    const retorno = await api.post(
      "/caixa/abreCaixa" + Requisicoes.entidade,
      { tipo: "caixa" },
      Requisicoes.header
    );
    if (retorno.data !== null) {
      window.location.reload();
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.submit();
  };

  submit = async () => {
    var token = localStorage.getItem("token_clube");

    if (
      this.state.forma_pagamento === "depositoBancario" &&
      this.state.contaBancaria === null
    ) {
      alert("Selecione uma conta bancária!");
      return;
    }

    if (
      (this.state.forma_pagamento === "cartao_credito" ||
        this.state.forma_pagamento === "cartao_debito" ||
        this.state.forma_pagamento === "pix") &&
      this.state.contaBancaria === null
    ) {
      alert("Selecione uma conta bancária!");
      return;
    }

    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + Requisicoes.entidade,
      {},
      { headers: { token: "Baer " + token } }
    );
    const nomePessoa = await api.post(
      "/pessoa/pessoaIdNome" + Requisicoes.entidade,
      { id: usuarioLogado.data.pessoa_id },
      { headers: { token: "Baer " + token } }
    );

    // let listaLancamentosId = [];
    // for (let i = 0; i < this.state.lancamento_contas_modal.length; i++) {
    //   const element = this.state.lancamento_contas_modal[i];
    //   listaLancamentosId.push(element.id);
    // }

    if (this.state.forma_pagamento === "dinheiro") {
      this.state.sangriaAutomaticaChecked = false;
    }

    let res = await api.post(
      "/caixa/baixaListaLancamentos" + Requisicoes.entidade,
      {
        // lancamentos: listaLancamentosId,
        lancamentos: this.state.lancamento_contas_modal,
        tipo: "caixa",
        forma_pagamento: this.state.forma_pagamento,
        conta_bancaria_id: this.state.sangriaAutomaticaChecked
          ? this.state.contaBancaria
          : null,
        observacaoSangria: this.state.sangriaAutomaticaChecked
          ? this.state.observacaoSangria
          : null,
      },
      Requisicoes.header
    );
    for (let i = 0; i < this.state.lancamento_contas_modal.length; i++) {
      const e = this.state.lancamento_contas_modal[i];

      let reservaEfetivada = await api.post(
        "/reservaEfetivada/buscarPorLancamentoConta" + Requisicoes.entidade,
        {
          lancamento_conta_id: e.id,
        },
        Requisicoes.header
      );
      if (reservaEfetivada.data !== null) {
        const listaConvidados = await api.post(
          "/presencaReserva/presencaReservaPorHorario" + Requisicoes.entidade,
          { id: reservaEfetivada.data.reserva_horario_id },
          Requisicoes.header
        );

        let dadosImpressao = {
          dataReserva: reservaEfetivada.data.dataReserva,
          evento: reservaEfetivada.data.evento,
          pessoa: reservaEfetivada.data.pessoa,
          reserva_horario_id: reservaEfetivada.data.reserva_horario_id,
          lancamentoConta: res.data,
          lancamento_conta_id: reservaEfetivada.data.lancamento_conta_id,
          nome: reservaEfetivada.data.reserva_horario.horario_servico.servico
            .nome,
          id: reservaEfetivada.data.reserva_horario.horario_servico.servico.id,
          observacao: reservaEfetivada.data.observacao,
          listaConvidados: listaConvidados.data,
        };

        await api.post(
          "/reservaHorario/contratoEfetivada" + Requisicoes.entidade,
          {
            userId: usuarioLogado.data.id,
            nome_operador: nomePessoa.data.nome,
            dados: dadosImpressao,
          },
          Requisicoes.header
        );

        const r = await api.get(
          "/reservaHorario/arquivoContratoEfetivada" +
          Requisicoes.entidade +
          "&t=Baer " +
          token +
          "&userId=" +
          usuarioLogado.data.id
        );

        let oReq = new XMLHttpRequest();
        let URLToPDF =
          r.config.baseURL +
          "reservaHorario/arquivoContratoEfetivada" +
          Requisicoes.entidade +
          "&t=Baer " +
          token +
          "&userId=" +
          usuarioLogado.data.id;

        oReq.open("GET", URLToPDF, true);
        oReq.responseType = "blob";
        oReq.onload = function () {
          const file = new Blob([oReq.response], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, "_blank");
        };
        oReq.send();
      }
    }

    await api.post(
      "/caixa/comprovantePagamento" + Requisicoes.entidade,
      {
        recibo_caixa_id: res.data.recibo_caixa_id,
        lancamentos: null,
        operador: usuarioLogado.data.nome,
      },
      Requisicoes.header
    );
    this.gerarPdfComprovante();
    setTimeout(async () => {
      this.handleCloseLancamentos();
    }, 500);
  };

  alteracaoDesconto = async (event, lancamento_id) => {
    let lista = [...this.state.lancamento_contas_modal];
    let index = lista.findIndex((e) => e.id === lancamento_id);
    if (index !== -1) {
      lista[index].valor_pago =
        event.target.value === ""
          ? "0.0"
          : event.target.value.replace(",", ".");
    }
    this.setState({ lancamento_contas_modal: lista }, this.somaValor);
  };

  somaValor = async () => {
    let totalGeral = 0;
    this.state.lancamento_contas_modal.forEach((e) => {
      let total = 0;
      if (e.numero_titulo !== null) {
        total = parseFloat(
          calculoMoraJuro(
            e.valor,
            this.state.porcentagem_juros,
            this.state.porcentagem_multa,
            e.data_vencimento
          ).total
        );

        if (e.valor_pago == null || e.valor_pago === "") {
          e.valor_pago = total;
          document.getElementById("total" + e.id).value = total;
        }

        e.desconto = total - parseFloat(e.valor_pago);
        totalGeral = parseFloat(totalGeral) + total - e.desconto;
      } else {
        total = parseFloat(e.valor);

        if (e.valor_pago == null || e.valor_pago === "") {
          e.valor_pago = total;
          document.getElementById("total" + e.id).value = total;
        }

        e.desconto = total - parseFloat(e.valor_pago);
        totalGeral = parseFloat(totalGeral) + total - e.desconto;
      }
      this.setState({
        totalGeral: parseFloat(totalGeral).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      });

      //Manipular uma string de diferença pra mostrar na tela
      let strDesconto = parseFloat(e.desconto).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      if (e.desconto > 0) {
        strDesconto = "-" + strDesconto;
      }
      if (e.desconto < 0) {
        strDesconto = "+" + strDesconto.slice(1, strDesconto.length);
      }
      document.getElementById("desconto" + e.id).value = strDesconto;
    });
    if (document.getElementById("totalGeral") !== null) {
      document.getElementById("totalGeral").value = parseFloat(
        totalGeral
      ).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  };

  gerarPdfComprovante = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    const r = await api.get(
      "/caixa/arquivoComprovantePagamento?e=" + entidade + "&t=Baer " + token
    );
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "caixa/arquivoComprovantePagamento?e=" +
      entidade +
      "&t=Baer " +
      token;

    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  render() {
    return (
      <div className="pagina" style={{ paddingBottom: 100 }}>
        <div>
          {this.state.caixaAberto !== null && (
            <Alert ariant="primary" style={{ marginTop: "10px" }}>
              {this.state.caixaAberto}
            </Alert>
          )}
        </div>
        <Header as="h1" dividing>
          <Row>
            <Col sm={1}>Caixa</Col>
            <Col sm={3} style={{ display: "flex", alignItems: "flex-end" }}>
              <h5>
                {this.state.caixa !== null
                  ? "Data Abertura do Caixa: " +
                  format(
                    parseISO(this.state.caixa.data_abertura),
                    "dd/MM/yyyy"
                  )
                  : ""}
              </h5>
            </Col>
            <Col sm={8}>
              <Row>
                <Col sm={2}>
                  <Button
                    variant="success"
                    block
                    disabled={!this.state.pagamentos_com_caixa}
                    onClick={() => this.setState({ openModalLiquidacao: true })}
                  >
                    Pagamentos
                  </Button>
                </Col>
                <Col sm={2}>
                  <Link
                    to={{
                      pathname: "/lancamentoCaixa",
                      query: { select: { value: "venda", label: "Venda" } },
                      from: "lancamentoCaixa",
                    }}
                  >
                    <Button variant="success" block>
                      Venda
                    </Button>
                  </Link>
                </Col>
                <Col sm={2}>
                  <Link
                    to={{
                      pathname: "/lancamentoCaixa",
                      query: { select: { value: "sangria", label: "Sangria" } },
                      from: "lancamentoCaixa",
                    }}
                  >
                    <Button variant="success" block>
                      Sangria
                    </Button>
                  </Link>
                </Col>
                <Col sm={2}>
                  <Link
                    to={{
                      pathname: "/lancamentoCaixa",
                      query: {
                        select: { value: "suprimento", label: "Suprimento" },
                      },
                      from: "lancamentoCaixa",
                    }}
                  >
                    <Button variant="success" block>
                      Suprimento
                    </Button>
                  </Link>
                </Col>
                <Col sm={2}>
                  {this.state.caixa !== null &&
                    this.state.caixa.data_fechamento !== null ? (
                    <Button
                      positive
                      onClick={this.abreCaixa}
                      block
                      disabled={this.state.abrindoCaixa}
                    >
                      Abrir
                    </Button>
                  ) : (
                    <Button
                      block
                      variant="danger"
                      onClick={this.handleShowFechamentoCaixa}
                    >
                      Fechar
                    </Button>
                  )}
                </Col>
                <Col sm={2}>
                  <Link to={{ pathname: "/caixa/lista" }}>
                    <Button primary block>
                      Consulta
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Header>
        <Row>
          <Col sm={3}>
            <Form.Label>
              <b>Saldo Anterior: &nbsp;&nbsp;</b> R$
              {this.state.saldo_anterior !== null &&
                parseFloat(this.state.saldo_anterior.valor).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}{" "}
            </Form.Label>
          </Col>
          <Col sm={3} style={{ float: "right" }}>
            <Form.Label>
              <b>Movimento Dia: &nbsp;&nbsp;</b> R$
              {this.state.total_movimento_dia !== null &&
                parseFloat(this.state.total_movimento_dia).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}{" "}
            </Form.Label>
          </Col>
          <Col sm={3}>
            <Form.Label>
              <b>Suprimentos:&nbsp;&nbsp;</b>R$
              {this.state.total_suprimento !== null &&
                parseFloat(this.state.total_suprimento).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}{" "}
            </Form.Label>
          </Col>
          <Col sm={3}>
            <Form.Label>
              <b>Situação:</b>{" "}
              {this.state.caixa !== null &&
                this.state.caixa.data_fechamento != null
                ? "Fechado"
                : "Aberto"}{" "}
            </Form.Label>
          </Col>
          <Col sm={3} style={{ float: "right" }}>
            <Form.Label>
              <b>Transferências: &nbsp;&nbsp;</b> R$
              {this.state.total_transferencia !== null &&
                parseFloat(this.state.total_transferencia).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}{" "}
            </Form.Label>
          </Col>

          <Col sm={3} style={{ float: "right" }}>
            <Form.Label>
              <b>Sangrias: &nbsp;&nbsp;</b> R$
              {this.state.total_sangria !== null &&
                parseFloat(this.state.total_sangria).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
            </Form.Label>
          </Col>

          <Col sm={3} style={{ float: "right" }}>
            <Form.Label>
              <b>Depósito Bancário: &nbsp;&nbsp;</b> R$
              {this.state.totalDepositoBancario !== null &&
                parseFloat(this.state.totalDepositoBancario).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}{" "}
            </Form.Label>
          </Col>

          <Col sm={3}>
            <Form.Label>
              <b>Saldo Caixa: &nbsp;&nbsp;</b> R$
              {parseFloat(this.state.totalCaixa).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
            </Form.Label>
          </Col>

          <Col sm={12}>
            <br />
          </Col>

          <Col sm={6}>
            <Form.Label>Pessoa</Form.Label>
            <Row>
              <Col sm={12}>
                <Autocomplete
                  id="pesquisaPessoa"
                  options={this.state.options}
                  getOptionLabel={(option) => option.label}
                  onChange={this.lancamentoEmAbertoPorPessoa}
                  onKeyUp={this.pesquisa}
                  loading={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Busca"
                      size="small"
                      style={{ background: "white" }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.buscando ? (
                              <CircularProgress
                                color="inherit"
                                size={20}
                                style={{
                                  marginRight: "5%",
                                }}
                              />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                ></Autocomplete>
              </Col>
            </Row>
          </Col>
          <Col>
            <Checkbox
              label=" Sócio"
              name="socioChecked"
              checked={this.state.socioChecked}
              onChange={(e) =>
                this.setState({ socioChecked: e.target.checked })
              }
            />
            <Checkbox
              label=" Ativo"
              name="ativoChecked"
              checked={this.state.ativoChecked}
              onChange={(e) =>
                this.setState({ ativoChecked: e.target.checked })
              }
            />
          </Col>
          <Col></Col>
        </Row>
        {this.state.mensagem !== null && (
          <Alert variant="primary" style={{ marginTop: "10px" }}>
            {this.state.mensagem}
          </Alert>
        )}
        <Divider />
        {this.state.lancamento_contas.length > 0 ||
          this.state.lancamentosDependentes.length > 0 ? (
          <>
            <Col
              sm={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Col sm={"auto"}>
                <Button
                  primary
                  onClick={() => {
                    this.setState({
                      lancamento_contas: [],
                      lancamentosDependentes: [],
                      total_dependentes: 0.0,
                      total_titular: 0.0,
                      selectedCheckboxes: new Set(),
                    });
                  }}
                  disabled={
                    this.state.caixa !== null &&
                    this.state.caixa.data_fechamento !== null
                  }
                >
                  Voltar
                </Button>
              </Col>
              <Col sm={"auto"}>
                <Row sm={12}>
                  <Button
                    primary
                    onClick={this.buscarTitulos}
                    disabled={
                      this.state.caixa !== null &&
                      this.state.caixa.data_fechamento !== null
                    }
                  >
                    Baixar
                  </Button>
                </Row>
              </Col>
            </Col>
            <Col sm={12}>
              {this.state.lancamento_contas.length > 0 && (
                <Table celled selectable compact>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={6}>Nome Pessoa</Table.HeaderCell>
                      <Table.HeaderCell width={5}>Descrição</Table.HeaderCell>
                      <Table.HeaderCell width={1}>Vencimento</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Referência</Table.HeaderCell>
                      <Table.HeaderCell textAlign={"right"} width={2}>
                        Valor
                      </Table.HeaderCell>
                      <Table.HeaderCell width={1}>
                        <Link to={{ pathname: "/lancamentoCaixa" }}>
                          <Button variant="success">
                            <IoMdAdd />
                          </Button>
                        </Link>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.lancamento_contas.map((e, i) => (
                      <>
                        {/* {this.state.lancamento_contas[i].tipo_cobranca !== 'debito_em_conta' && ( */}
                          <>
                            {i > 0 &&
                              moment(e.data_vencimento).month() !==
                              moment(
                                this.state.lancamento_contas[i - 1].data_vencimento
                              ).month() ? (
                              <Table.Row
                                key={i * -1}
                                style={{ background: "#2dcbec" }}
                              >
                                <Table.Cell
                                  colSpan={5}
                                  disabled
                                  style={{ height: "2px", padding: "0px" }}
                                ></Table.Cell>
                              </Table.Row>
                            ) : null}
                            <Table.Row key={i}>
                              <Table.Cell>{e.pessoa.nome}</Table.Cell>
                              <Table.Cell>
                                {e.servico !== undefined && e.servico !== null
                                  ? e.servico.nome
                                  : e.descricao !== null && //e.descricao.toString().length > 20 ||
                                  (e.tipo_lancamento === "sangria"
                                    ? TipoLancamento.find(
                                      (t) => t.value === e.tipo_lancamento
                                    ) !== undefined
                                      ? TipoLancamento.find(
                                        (t2) => t2.value === e.tipo_lancamento
                                      ).label
                                      : e.descricao
                                    : e.descricao)}
                              </Table.Cell>
                              <Table.Cell>
                                {e.data_vencimento !== null &&
                                  format(parseISO(e.data_vencimento), "dd/MM/yyyy")}
                              </Table.Cell>
                              <Table.Cell>
                                {e.mes_referencia + "/" + e.ano_referencia}
                              </Table.Cell>
                              <Table.Cell textAlign={"right"}>
                                {e.valor === null
                                  ? parseFloat(e.valor_pago)
                                    .toFixed(2)
                                    .replace(".", ",")
                                    .replace(/\d(?=(\d{3}),)/g, "$&.")
                                  : parseFloat(e.valor)
                                    .toFixed(2)
                                    .replace(".", ",")
                                    .replace(/\d(?=(\d{3}),)/g, "$&.")}
                              </Table.Cell>
                              <Table.Cell>
                                <Checkbox
                                  label=""
                                  defaultChecked={e.select}
                                  onClick={this.toggleCheckbox}
                                  defaultValue={e.id}
                                />
                              </Table.Cell>
                            </Table.Row>
                          </>
                        {/* // )} */}

                      </>
                    ))}
                  </Table.Body>
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell colSpan={2}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} textAlign={"right"}>
                        <strong>
                          {"Total da divida: R$ " +
                            parseFloat(this.state.total_titular)
                              .toFixed(2)
                              .replace(".", ",")
                              .replace(/\d(?=(\d{3}),)/g, "$&.")}
                        </strong>
                      </Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
              )}
            </Col>
          </>
        ) : this.state.lancamentosDependentes.length > 0 ? null : (
          <>
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Data Pagamento</Table.HeaderCell>
                  <Table.HeaderCell>Pessoa</Table.HeaderCell>
                  <Table.HeaderCell>Data Vencimento</Table.HeaderCell>
                  <Table.HeaderCell>Descrição</Table.HeaderCell>
                  <Table.HeaderCell>Hora</Table.HeaderCell>
                  <Table.HeaderCell>Valor</Table.HeaderCell>
                  <Table.HeaderCell width={2}>
                    <Link to={{ pathname: "/lancamentoCaixa" }}>
                      <Button variant="success" title="Adicionar lancamento">
                        <IoMdAdd />
                      </Button>
                    </Link>
                    <Link to={{ pathname: "/retorno" }}>
                      <Button
                        variant="primary"
                        title="Retorno"
                        style={{ marginLeft: "10px" }}
                      >
                        <AiOutlineUnorderedList />
                      </Button>
                    </Link>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.caixa !== null &&
                  this.state.caixa.lancamento_contas.length !== 0 &&
                  this.state.caixa.lancamento_contas.map(
                    (e, i) =>
                      e.tipo_lancamento !== "saldoanterior" && (
                        <Table.Row key={i}>
                          <Table.Cell>
                            {e.data_pagamento !== null &&
                              format(parseISO(e.data_pagamento), "dd/MM/yyyy")}
                          </Table.Cell>
                          <Table.Cell>
                            {e.pessoa !== null && e.pessoa.nome}
                          </Table.Cell>

                          <Table.Cell>
                            {e.data_vencimento !== null
                              ? moment(e.data_vencimento).format("DD/MM/YYYY")
                              : ""}
                          </Table.Cell>

                          {e.tipo_lancamento !== "sangria" &&
                            e.tipo_lancamento !== "suprimento" &&
                            e.servico !== null ? (
                            <Table.Cell>{e.servico.nome}</Table.Cell>
                          ) : (
                            <Table.Cell>
                              {(e.descricao !== null &&
                                e.descricao.toString().length > 20) ||
                                e.tipo_lancamento === "sangria" ||
                                e.tipo_lancamento === "suprimento"
                                ? TipoLancamento.find(
                                  (t) => t.value === e.tipo_lancamento
                                ) !== undefined &&
                                TipoLancamento.find(
                                  (t2) => t2.value === e.tipo_lancamento
                                ).label
                                : e.descricao}
                            </Table.Cell>
                          )}
                          <Table.Cell>
                            {e.hora_baixa !== null &&
                              e.hora_baixa.toString().substring(0, 5)}
                          </Table.Cell>
                          <Table.Cell textAlign={"right"}>
                            {e.valor_pago !== null
                              ? parseFloat(e.valor_pago).toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                              : parseFloat(e.valor).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </Table.Cell>
                          <Table.Cell>
                            <Button
                              variant="primary"
                              id={"button" + e.id}
                              onClick={this.buscarTitulo}
                              title="Visualizar lancamento"
                            >
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 512 512"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                                id={"button" + e.id}
                                onClick={this.buscarTitulo}
                              >
                                <path
                                  d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49
                                  48-48V80c0-26.51-21.49-48-48-48zm-6 400H54a6 6 0 0 1-6-6V86a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v340a6 6 0 0 1-6
                                  6zm-42-92v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373
                                  12 12zm0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373
                                  12 12zm0-96v24c0 6.627-5.373 12-12 12H204c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h200c6.627 0 12 5.373
                                  12 12zm-252 12c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36zm0 96c0 19.882-16.118 36-36
                                   36s-36-16.118-36-36 16.118-36 36-36 36 16.118 36 36zm0 96c0 19.882-16.118 36-36 36s-36-16.118-36-36 16.118-36 36-36
                                    36 16.118 36 36z"
                                  id={"button" + e.id}
                                  onClick={this.buscarTitulo}
                                ></path>
                              </svg>
                            </Button>
                            <Button
                              onClick={this.handleShowMensagem}
                              id={"button" + e.id}
                              variant="danger"
                              style={{ marginLeft: "10px" }}
                              title="Cancelar lancamento"
                            >
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                id={"button" + e.id}
                                onClick={this.handleShowMensagem}
                                stroke-width="0"
                                viewBox="0 0 1024 1024"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  id={"button" + e.id}
                                  onClick={this.handleShowMensagem}
                                  d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1
                      191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203
                      838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8
                      512z"
                                ></path>
                              </svg>
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      )
                  )}
              </Table.Body>
            </Table>

            <Col sm={12} style={{ paddingBottom: "10px" }}>
              <Row>
                <Header>Movimento Estorno</Header>
                <Table celled selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Data Cancelamento</Table.HeaderCell>
                      <Table.HeaderCell>Pessoa</Table.HeaderCell>
                      <Table.HeaderCell>Data Vencimento</Table.HeaderCell>
                      <Table.HeaderCell>Descrição</Table.HeaderCell>
                      <Table.HeaderCell>Hora</Table.HeaderCell>
                      <Table.HeaderCell>Valor</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.caixa !== null &&
                      this.state.caixa.historico_cancelamento_baixas.length !==
                      0 &&
                      this.state.caixa.historico_cancelamento_baixas.map(
                        (e, i) =>
                          e.lancamento_conta.tipo_lancamento !==
                          "saldoanterior" && (
                            <Table.Row key={i}>
                              <Table.Cell>
                                {moment(e.data).format("DD/MM/YYYY")}
                              </Table.Cell>
                              <Table.Cell>
                                {e.lancamento_conta.pessoa !== null &&
                                  e.lancamento_conta.pessoa.nome}
                              </Table.Cell>

                              <Table.Cell>
                                {e.lancamento_conta.data_vencimento !== null
                                  ? moment(
                                    e.lancamento_conta.data_vencimento
                                  ).format("DD/MM/YYYY")
                                  : ""}
                              </Table.Cell>

                              {e.tipo_lancamento === "cobrancaServicoAvulsa" ? (
                                <Table.Cell>
                                  {e.lancamento_conta.servico !== null &&
                                    e.lancamento_conta.servico.nome}
                                </Table.Cell>
                              ) : (
                                <Table.Cell>
                                  {e.lancamento_conta.descricao !== null &&
                                    (e.lancamento_conta.descricao.toString()
                                      .length > 20 ||
                                      e.lancamento_conta.tipo_lancamento ===
                                      "sangria"
                                      ? TipoLancamento.find(
                                        (t) =>
                                          t.value ===
                                          e.lancamento_conta.tipo_lancamento
                                      ) !== undefined &&
                                      TipoLancamento.find(
                                        (t2) =>
                                          t2.value ===
                                          e.lancamento_conta.tipo_lancamento
                                      ).label
                                      : e.lancamento_conta.descricao)}
                                </Table.Cell>
                              )}
                              <Table.Cell>
                                {e.lancamento_conta.hora_baixa !== null &&
                                  e.lancamento_conta.hora_baixa
                                    .toString()
                                    .substring(0, 5)}
                              </Table.Cell>
                              <Table.Cell textAlign={"right"}>
                                {e.lancamento_conta.valor_pago !== null
                                  ? parseFloat(
                                    e.lancamento_conta.valor_pago
                                  ).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                  : parseFloat(
                                    e.lancamento_conta.valor
                                  ).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                              </Table.Cell>
                            </Table.Row>
                          )
                      )}
                  </Table.Body>
                </Table>
              </Row>
            </Col>
          </>
        )}
        {this.state.lancamentosDependentes.length > 0
          ? this.state.lancamentosDependentes.map((e, i) => (
            <Col sm={12} style={{ marginTop: "20px" }}>
              <Table celled selectable compact>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={6}>Nome Pessoa</Table.HeaderCell>
                    <Table.HeaderCell width={5}>Descrição</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Vencimento</Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"} width={2}>
                      Valor
                    </Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {e.map((e2, i2) => (
                    <>
                      {i2 > 0 &&
                        moment(e2.data_vencimento).month() !==
                        moment(
                          this.state.lancamentosDependentes[i][i2 - 1]
                            .data_vencimento
                        ).month() ? (
                        <Table.Row
                          key={i * -1}
                          style={{ background: "#2dcbec" }}
                        >
                          <Table.Cell
                            colSpan={5}
                            disabled
                            style={{ height: "2px", padding: "0px" }}
                          ></Table.Cell>
                        </Table.Row>
                      ) : null}
                      <Table.Row key={i2}>
                        <Table.Cell>{e2.pessoa.nome}</Table.Cell>
                        <Table.Cell>
                          {e2.servico !== undefined && e2.servico !== null
                            ? e2.servico.nome
                            : e2.descricao !== null && //e.descricao.toString().length > 20 ||
                            (e2.tipo_lancamento === "sangria"
                              ? TipoLancamento.find(
                                (t) => t.value === e2.tipo_lancamento
                              ) !== undefined
                                ? TipoLancamento.find(
                                  (t2) => t2.value === e2.tipo_lancamento
                                ).label
                                : e2.descricao
                              : e2.descricao)}
                        </Table.Cell>
                        <Table.Cell>
                          {e2.data_vencimento !== null &&
                            format(
                              parseISO(e2.data_vencimento),
                              "dd/MM/yyyy"
                            )}
                        </Table.Cell>
                        <Table.Cell textAlign={"right"}>
                          {e2.valor === null
                            ? parseFloat(e2.valor_pago)
                              .toFixed(2)
                              .replace(".", ",")
                              .replace(/\d(?=(\d{3}),)/g, "$&.")
                            : parseFloat(e2.valor)
                              .toFixed(2)
                              .replace(".", ",")
                              .replace(/\d(?=(\d{3}),)/g, "$&.")}
                        </Table.Cell>
                        <Table.Cell textAlign={"center"}>
                          <Checkbox
                            label=""
                            defaultChecked={e2.select}
                            onClick={this.toggleCheckbox}
                            defaultValue={e2.id}
                          />
                        </Table.Cell>
                      </Table.Row>
                    </>
                  ))}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan={2}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} textAlign={"right"}>
                      <strong>
                        {"Total da divida: R$ " +
                          parseFloat(this.state.total_dependentes[i])
                            .toFixed(2)
                            .replace(".", ",")
                            .replace(/\d(?=(\d{3}),)/g, "$&.")}
                      </strong>
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Col>
          ))
          : null}
        {/* Modais abaixo */}
        <ModalVisualiza
          open={this.state.open}
          handleClose={this.handleClose}
          lancamento_conta={this.state.lancamento_conta}
          optionsContaBancaria={this.state.optionsContaBancaria}
          gerarPdfComprovante={this.gerarPdfComprovante}
          reimprimirSangria={this.reimprimirSangria}
          reimprimirSuprimento={this.reimprimirSuprimento}
          porcentagem_juros={this.state.porcentagem_juros}
          porcentagem_multa={this.state.porcentagem_multa}
        />
        <CancelamentoBaixa
          openMensagem={this.state.openMensagem}
          handleCloseMensagem={this.handleCloseMensagem}
          cancelarBaixaTitulo={this.cancelarBaixaTitulo}
          setaAdministradorId={this.setaAdministradorId}
          usuarioLogado={this.state.usuarioLogado}
        />
        <Modal
          open={this.state.open_lancamentos}
          onClose={this.handleCloseLancamentos}
          centered={false}
          style={{ overflow: "visible", width: 1200 }}

        >
          <Header content="Titulos" />
          <Modal.Content>
            {this.state.lancamento_contas_modal.length > 0 && (
              <Segment.Group>
                <Segment.Group horizontal>
                  <Segment>
                    <Col sm={12} style={{ textAlign: "center" }}>
                      <Header as="h4">Pagador</Header>
                    </Col>
                    <Col sm={12}>
                      <Row>
                        <Col sm={6}>
                          <b>Nome: </b>
                          {this.state.lancamento_contas_modal[0].pessoa !==
                            null &&
                            this.state.lancamento_contas_modal[0].pessoa.nome}
                        </Col>
                        {this.state.lancamento_contas_modal[0].pessoa !==
                          null && (
                            <Col sm={6}>
                              <b>CPF/CNPJ: </b>
                              {this.state.lancamento_contas_modal[0].pessoa
                                .cpf === null
                                ? this.state.lancamento_contas_modal[0].pessoa
                                  .cnpj
                                : this.state.lancamento_contas_modal[0].pessoa
                                  .cpf}
                            </Col>
                          )}
                      </Row>
                    </Col>
                    {this.state.lancamento_contas_modal[0].pessoa !== null && (
                      <Col sm={12}>
                        <b>Endereço: </b>
                        {
                          this.state.lancamento_contas_modal[0].pessoa.endereco
                        }, {this.state.lancamento_contas_modal[0].pessoa.bairro}{" "}
                        -{this.state.lancamento_contas_modal[0].pessoa.cidade}/
                        {this.state.lancamento_contas_modal[0].pessoa.estado}{" "}
                        CEP:
                        {this.state.lancamento_contas_modal[0].pessoa.cep}
                      </Col>
                    )}
                  </Segment>
                </Segment.Group>
                {this.state.lancamento_contas_modal.map((e, i) => {
                  return (
                    <Segment.Group horizontal>
                      <Segment>
                        {i === 0 && (
                          <Col sm={12} style={{ textAlign: "center" }}>
                            <Header as="h4">Informações</Header>
                          </Col>
                        )}
                        {i === 0 && (
                          <Col sm={12}>
                            <Row>
                              <Col sm={1}>
                                <b>Serviço</b>
                              </Col>
                              <Col sm={1}>
                                <b>Referêrencia</b>
                              </Col>
                              <Col sm={2}>
                                <b>Vencimento</b>
                              </Col>
                              <Col sm={1}>
                                <b>Valor</b>
                              </Col>
                              <Col sm={1}>
                                <b>Multa</b>
                              </Col>
                              <Col sm={1}>
                                <b>Mora</b>
                              </Col>
                              <Col sm={1}>
                                <b>Atualizado</b>
                              </Col>
                              <Col sm={2}>
                                <Col sm={12}>
                                  <b>Diferença</b>
                                </Col>
                              </Col>
                              <Col sm={2}>
                                <Col sm={12}>
                                  <b>Total</b>
                                </Col>
                              </Col>
                            </Row>
                          </Col>
                        )}
                        <Col sm={12}>
                          <Row>
                            <Col sm={1}>
                              {TipoLancamento.find(
                                (t) => t.value === e.tipo_lancamento
                              ) !== undefined
                                ? TipoLancamento.find(
                                  (t) => t.value === e.tipo_lancamento
                                ).label
                                : ""}
                            </Col>
                            <Col sm={1}>
                              {e.mes_referencia ? e.mes_referencia.substr(0, 3) + "/" + e.ano_referencia.substr(2, 2) : ""}
                            </Col>
                            <Col sm={2}>
                              {e.data_vencimento !== null &&
                                format(
                                  parseISO(e.data_vencimento),
                                  "dd/MM/yyyy"
                                )}
                            </Col>
                            <Col sm={1}>
                              {parseFloat(e.valor).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Col>
                            <Col sm={1}>
                              {e.valor !== null &&
                                e.data_vencimento !== null &&
                                e.numero_titulo !== null &&
                                parseFloat(
                                  calculoMoraJuro(
                                    e.valor,
                                    this.state.porcentagem_juros,
                                    this.state.porcentagem_multa,
                                    e.data_vencimento
                                  ).multa
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                            </Col>
                            <Col sm={1}>
                              {e.valor !== null &&
                                e.data_vencimento !== null &&
                                e.numero_titulo !== null &&
                                parseFloat(
                                  calculoMoraJuro(
                                    e.valor,
                                    this.state.porcentagem_juros,
                                    this.state.porcentagem_multa,
                                    e.data_vencimento
                                  ).mora
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                            </Col>
                            <Col sm={1}>
                              {e.valor !== null &&
                                e.data_vencimento !== null &&
                                e.numero_titulo !== null &&
                                parseFloat(
                                  calculoMoraJuro(
                                    e.valor,
                                    this.state.porcentagem_juros,
                                    this.state.porcentagem_multa,
                                    e.data_vencimento
                                  ).total
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                            </Col>
                            <Col sm={2}>
                              <Col sm={12}>
                                <FormControl
                                  placeholder="0.00"
                                  aria-label="Valor"
                                  aria-describedby="basic-addon1"
                                  id={"desconto" + e.id}
                                  //onChange={this.alteracaoDesconto}
                                  //value={e.desconto}
                                  disabled
                                />
                              </Col>
                            </Col>
                            <Col sm={2}>
                              <Col sm={12}>
                                <FormControl
                                  placeholder="Total"
                                  aria-label="Total"
                                  aria-describedby="basic-addon1"
                                  id={"total" + e.id}
                                  value={e.total}
                                  defaultValue={e.total}
                                  onChange={(event) =>
                                    this.alteracaoDesconto(event, e.id)
                                  }
                                //disabled
                                />
                              </Col>
                            </Col>
                          </Row>
                        </Col>
                      </Segment>
                    </Segment.Group>
                  );
                })}
              </Segment.Group>
            )}
            <Col
              sm={3}
              style={{ float: "right", marginRight: "0%", padding: "0" }}
            >
              <Row>
                <Col sm={4}>
                  <Form.Label>Total</Form.Label>
                </Col>
                <Col sm={6}>
                  <FormControl
                    id="totalGeral"
                    value={this.state.totalGeral}
                    name="totalGeral"
                    disabled
                  />
                </Col>
              </Row>
            </Col>
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col sm={4}>
                  <Form.Label>Forma de Pagamento</Form.Label>
                  <Form.Control
                    as="select"
                    name="forma_pagamento"
                    onChange={this.alteracao}
                    value={this.state.forma_pagamento}
                    required
                  >
                    <option></option>
                    <option value="dinheiro">Dinheiro</option>
                    <option value="cheque">Cheque</option>
                    {/* <option value="cartao">Cartão</option> */}
                    <option value="cartao_credito">Cartão de Crédito</option>
                    <option value="cartao_debito">Cartão de Débito</option>
                    <option value="pix">Pix</option>
                    <option value="depositoBancario">Depósito Bancario</option>
                  </Form.Control>
                </Col>
              </Row>

              {(this.state.forma_pagamento === "depositoBancario" ||
                this.state.forma_pagamento === "cartao_credito" ||
                this.state.forma_pagamento === "cartao_debito" ||
                this.state.forma_pagamento === "pix") && (
                  <Row
                    style={{
                      margin: 0,
                      display: "flex",
                      // justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Col sm={4}>
                      <Checkbox
                        label="  Sangria automática"
                        onChange={() =>
                          this.setState({
                            sangriaAutomaticaChecked:
                              !this.state.sangriaAutomaticaChecked,
                          })
                        }
                        checked={this.state.sangriaAutomaticaChecked}
                      ></Checkbox>
                    </Col>
                    <Col sm={8}>
                      <Form.Label>Conta Bancária</Form.Label>
                      <FormSemantic>
                        <FormSemantic.Field
                          control={Select}
                          placeholder="Conta Bancária"
                          options={this.state.optionsContaBancaria}
                          value={this.state.contaBancaria}
                          onChange={(e, a) =>
                            this.setState({ contaBancaria: a.value })
                          }
                          labelPosition="right"
                          disabled={!this.state.sangriaAutomaticaChecked}
                          required={this.state.sangriaAutomaticaChecked}
                        />
                      </FormSemantic>
                    </Col>

                    <Col sm={12}>
                      <Form.Label>Observação Sangria</Form.Label>
                      <FormSemantic>
                        <FormSemantic.Field
                          control={TextArea}
                          placeholder="Observação Sangria"
                          value={this.state.observacaoSangria}
                          onChange={(e, a) =>
                            this.setState({ observacaoSangria: a.value })
                          }
                          required={this.state.sangriaAutomaticaChecked}
                          error={
                            this.state.observacaoSangria.length > 230 &&
                            "Máximo de 230 caracteres."
                          }
                        />
                      </FormSemantic>
                    </Col>
                  </Row>
                )}

              <Divider />
              <Segment clearing>
                <Header floated="right">
                  <Button
                    variant="success"
                    type="submit"
                    disabled={this.state.forma_pagamento === null}
                  >
                    Liquidar
                  </Button>
                  <Button
                    variant="light"
                    onClick={() =>
                      this.setState({
                        open_lancamentos: false,
                        conta_bancaria_id: null,
                        sangriaAutomaticaChecked: true,
                      })
                    }
                    style={{ marginLeft: "10px" }}
                  >
                    Voltar
                  </Button>
                </Header>
              </Segment>
            </Form>
          </Modal.Content>
        </Modal>

        <Modal
          open={this.state.open_fechamento_caixa}
          onClose={this.handleCloseFechamentoCaixa}
          centered={false}
        >
          <Header content="Mensagem" />
          <Modal.Content>Confirma Fechamento do Caixa?</Modal.Content>

          <Header floated="right">
            <Row>
              <Col sm={5}>
                <Button
                  type="button"
                  variant="light"
                  onClick={this.handleCloseFechamentoCaixa}
                >
                  Voltar
                </Button>
              </Col>
              <Col sm={5}>
                <Button variant="success" onClick={this.fechaCaixa}>
                  Sim
                </Button>
              </Col>
            </Row>
          </Header>
        </Modal>
        <Modal
          open={this.state.open_aberto_caixa}
          // onClose={this.handleCloseAbertoCaixa}
          centered={false}
        >
          <Header content="Caixa em Aberto" style={{ textAlign: "center" }} />
          <Modal.Content>
            Caixa em aberto do dia:{" " + this.state.dataAbertura}
          </Modal.Content>

          <div floated="right" style={{ marginLeft: "5px" }}>
            <Col sm={3}>
              <p>Deseja Finalizar o caixa ?</p>
            </Col>

            <div
              floated="right"
              style={{ marginLeft: "675px", marginTop: "-25px" }}
            >
              <p>Deseja Continuar no caixa atual ?</p>
            </div>

            {/* </div> */}
          </div>
          <Header floated="left">
            <Button
              variant="danger"
              // onClick={this.handleCloseAbertoCaixa}
              onClick={this.fechaCaixa}
            >
              Fechar caixa
            </Button>
          </Header>

          <Header floated="right">
            <Row>
              <Col sm={12}>
                <Button
                  variant="success"
                  onClick={this.mensagemCaixaAberto}
                // onClick={this.fechaCaixa}
                >
                  Caixa Atual
                </Button>
              </Col>
            </Row>
          </Header>
        </Modal>
        <Modal
          open={this.state.openModalLiquidacao}
          onClose={() => this.setState({ openModalLiquidacao: false })}
          size="large"
        >
          <div style={{ margin: 20 }}>
            <Liquidacoes />
          </div>
        </Modal>
      </div>
    );
  }
}
