import React, { Component } from "react";
import {
  Header,
  Button,
  Segment,
  Container,
  Modal,
  Table,
  Menu,
} from "semantic-ui-react";
import { Col, Row, Form } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import moment from "moment-timezone";
import Select from "react-select";

import TipoLancamentoHistoricoAcao from "../../../enum/TipoLancamentoHistoricoAcao";

import requisicoes from "../../../utils/Requisicoes";
import api from "../../../services/api";

export default class AcaoTituloLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acaoHistoricoAcao: null,
      acoesPessoa: [],
      buscou: false,
      activeItem: "acao",
      salvando: false,
    };
  }

  buscaHistorico = async () => {
    this.setState({ buscou: false });
    const retorno2 = await api.post(
      "/historicoAcao/historicoPorPessoa" + requisicoes.entidade,
      { pessoa_id: this.props.infoPessoa.id },
      requisicoes.header
    );

    this.setState({ acoesPessoa: retorno2.data });

    if (
      this.props.acao === null ||
      this.props.acao.historico_acoes[0] === undefined
    ) {
      let a = [];
      this.setState({ acaoHistoricoAcao: a });
      this.setState({ buscou: true });
      return;
    }
    const retorno = await api.post(
      "/historicoAcao/historicoPorAcao" + requisicoes.entidade,
      { acao_id: this.props.acao.id },
      requisicoes.header
    );

    var historico_acoes = retorno.data[0].historico_acoes;

    historico_acoes.sort(function (a, b) {
      if (Date.parse(a.data_tranferencia) > Date.parse(b.data_tranferencia)) {
        return 1;
      }
      if (Date.parse(a.data_tranferencia) < Date.parse(b.data_tranferencia)) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }
      return 0;
    });

    // for (let i = 0; i < historico_acoes.length; i++) {
    //   const element = historico_acoes[i];
    //   const retorno1 = await api.post(
    //     "/pessoa/buscaID" + requisicoes.entidade,
    //     { id: element.pessoa_compra_id },
    //     requisicoes.header
    //   );
    //   historico_acoes[i].pessoa_compra = retorno1.data;

    //   const retorno2 = await api.post(
    //     "/pessoa/buscaID" + requisicoes.entidade,
    //     { id: element.pessoa_venda_id },
    //     requisicoes.header
    //   );
    //   historico_acoes[i].pessoa_venda = retorno2.data;
    // }
    retorno.data[0].historico_acoes = historico_acoes;
    this.setState({ acaoHistoricoAcao: retorno.data[0] });

    this.setState({ buscou: true });
  };

  atualizaDatasAcao = async (event) => {
    if (moment(event.target.value).isValid()) {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  salvarDatasAcao = async () => {
    var aux = {};
    if (this.state.data_inicial !== undefined) {
      aux.data_inicial = this.state.data_inicial;
    }
    if (this.state.data_final !== undefined) {
      aux.data_final = this.state.data_final;
    }
    if (this.state.data_emissao !== undefined) {
      aux.data_emissao = this.state.data_emissao;
    }
    aux.id = this.props.acao.id;
    this.setState({ salvando: true });
    await api
      .post("/acao/alterar" + requisicoes.entidade, aux, requisicoes.header)
      .then(() => {
        setTimeout(() => {
          this.setState({ salvando: "salvo" });
          setTimeout(() => {
            this.setState({
              data_inicial: undefined,
              data_final: undefined,
              data_emissao: undefined,
              salvando: false,
            });
          }, 1000);
        }, 500);
      });
  };

  render() {
    return (
      <Col
        sm={12}
        style={{
          paddingTop: "10px",
          margin: "0px",
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
      >
        <Row>
          <Col>
            <Header as="h3">Ação/Titulo</Header>
          </Col>{" "}
          {this.props.permissoes.find(
            (e) => e.nome === "visualizar_meus_dados_alheios"
          ) && (
            <Modal
              trigger={
                <Col style={{ paddingBottom: 15 }}>
                  <Button
                    content="Historico da Ação"
                    icon="history"
                    color="blue"
                    size="tiny"
                    floated="right"
                  ></Button>
                </Col>
              }
              onOpen={this.buscaHistorico}
            >
              {/* <Modal.Header> */}

              <Menu pointing secondary>
                <Menu.Item
                  name="acao"
                  active={this.state.activeItem === "acao"}
                  onClick={() => this.setState({ activeItem: "acao" })}
                >
                  Histórico da Ação
                </Menu.Item>
                <Menu.Item
                  name="pessoa"
                  active={this.state.activeItem === "pessoa"}
                  onClick={() => this.setState({ activeItem: "pessoa" })}
                >
                  Títulos já possuidos
                </Menu.Item>
              </Menu>
              {/* <Header as="h3">Histórico da ação</Header> */}
              {/* </Modal.Header> */}
              <Modal.Content>
                {this.state.buscou ? (
                  <>
                    {this.state.activeItem === "acao" && (
                      <Table celled compact selectable>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Ocorrencia</Table.HeaderCell>
                            <Table.HeaderCell>
                              Pessoa que vendeu
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                              Pessoa que comprou
                            </Table.HeaderCell>
                            <Table.HeaderCell>Data</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.state.acaoHistoricoAcao !== null &&
                          this.state.acaoHistoricoAcao.historico_acoes !==
                            null &&
                          this.state.acaoHistoricoAcao.historico_acoes !==
                            undefined &&
                          this.state.acaoHistoricoAcao.historico_acoes.length >
                            0
                            ? this.state.acaoHistoricoAcao.historico_acoes.map(
                                (e, i) => (
                                  <Table.Row key={i}>
                                    <Table.Cell>
                                      {e.tipo_lancamento ===
                                        "alteracaoTipoTitulo" ||
                                      e.tipo_lancamento === "desvincularAcao"
                                        ? e.motivo
                                        : TipoLancamentoHistoricoAcao.find(
                                            (e2) =>
                                              e2.value == e.tipo_lancamento
                                          ) != undefined
                                        ? TipoLancamentoHistoricoAcao.find(
                                            (e2) =>
                                              e2.value == e.tipo_lancamento
                                          ).label
                                        : e.tipo_lancamento}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {e.pessoa_venda.nome !== null
                                        ? e.pessoa_venda.nome
                                        : ""}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {e.pessoa_compra.nome !== null
                                        ? e.pessoa_compra.nome
                                        : ""}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {moment(e.data).format("DD/MM/YYYY")}
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              )
                            : null}
                        </Table.Body>
                      </Table>
                    )}
                    {this.state.activeItem === "pessoa" && (
                      <Table celled compact selectable>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Título</Table.HeaderCell>
                            <Table.HeaderCell>Ocorrencia</Table.HeaderCell>
                            <Table.HeaderCell>
                              Pessoa que vendeu
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                              Pessoa que comprou
                            </Table.HeaderCell>
                            <Table.HeaderCell>Data</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.state.acoesPessoa.map((e, i) => (
                            <Table.Row>
                              <Table.Cell>{e.codigo}</Table.Cell>
                              <Table.Cell>
                                {e.tipo_lancamento === "alteracaoTipoTitulo" ||
                                e.tipo_lancamento === "desvincularAcao"
                                  ? e.motivo
                                  : TipoLancamentoHistoricoAcao.find(
                                      (e2) => e2.value == e.tipo_lancamento
                                    ) != undefined
                                  ? TipoLancamentoHistoricoAcao.find(
                                      (e2) => e2.value == e.tipo_lancamento
                                    ).label
                                  : e.tipo_lancamento}
                              </Table.Cell>
                              <Table.Cell>
                                {e.pessoa_venda_nome !== null
                                  ? e.pessoa_venda_nome
                                  : ""}
                              </Table.Cell>
                              <Table.Cell>
                                {e.pessoa_compra_nome !== null
                                  ? e.pessoa_compra_nome
                                  : ""}
                              </Table.Cell>
                              <Table.Cell>
                                {moment(e.data).format("DD/MM/YYYY")}
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    )}
                  </>
                ) : (
                  <Container
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "30px",
                      marginBottom: "30px",
                    }}
                  >
                    <CircularProgress />
                  </Container>
                )}
              </Modal.Content>
            </Modal>
          )}
        </Row>
        {this.props.acao != null && (
          <Container>
            <Row>
              <Col sm={4}>

                <b>Tipo Título: </b>
                {this.props.acao !== null &&
                this.props.acao.tipo_titulo !== null
                  ? this.props.acao.tipo_titulo.descricao
                  : ""}
                {/* {(localStorage.getItem("tipo_usuario_clube") ===
                  "administrador"
                  || localStorage.getItem("tipo_usuario_clube") === "administrativo"
                  ) &&
                  this.props.acao !== null &&
                  this.props.acao.tipo_titulo !== null &&
                  (this.props.acao.tipo_titulo.descricao === "PATRIMONIAL" ||
                  this.props.acao.tipo_titulo.descricao === "LICENCIADO") && (
                    <Button
                      primary
                      icon="edit"
                      size="mini"
                      style={{ marginLeft: "10px" }}
                      onClick={this.props.handleShowAlteracaoTipoTitulo}
                    ></Button>
                  )} */}
                <br />
                <br />
                <b>Situação: </b>
                {this.props.pessoa.acao !== null &&
                this.props.pessoa.acao !== undefined &&
                this.props.pessoa.acao.status !== null ? (
                  <span
                    style={{
                      fontWeight: "bold",
                      color:
                        this.props.pessoa.acao.status === "ativo"
                          ? "green"
                          : this.props.pessoa.acao.status === "cancelado"
                          ? "red"
                          : "blue",
                    }}
                  >
                    {this.props.pessoa.acao.status
                      .toString()
                      .substring(0, 1)
                      .toUpperCase() +
                      this.props.pessoa.acao.status
                        .toString()
                        .substring(1, 100)}
                  </span>
                ) : this.props.acao.historico_acoes !== undefined &&
                  this.props.acao.historico_acoes.length > 0 ? (
                  <span
                    style={{
                      fontWeight: "bold",
                      color:
                        this.props.acao.historico_acoes[0].tipo_lancamento ===
                        "transferencia"
                          ? "blue"
                          : "red",
                    }}
                  >
                    {this.props.acao.historico_acoes[0].status === null
                      ? this.props.acao.historico_acoes[0].tipo_lancamento ===
                        "cancelamento"
                        ? "Cancelado"
                        : this.props.acao.historico_acoes[0].tipo_lancamento
                      : this.props.acao.historico_acoes[0].status}
                  </span>
                ) : (
                  <span style={{ fontWeight: "bold", color: "brown" }}>
                    {"Sem informação"}
                  </span>
                )}
                {this.props.infoPessoa !== null &&
                this.props.infoPessoa !== undefined &&
                this.props.infoPessoa.titular_id !== null &&
                this.props.infoPessoa.titular_id !== undefined ? (
                  <>
                    <br />
                    <br />
                    <b>Titular: </b>
                    {this.props.titular !== null &&
                    this.props.titular !== undefined
                      ? this.props.titular.nome
                      : ""}
                  </>
                ) : null}
              </Col>
              <Col sm={4}>
                {console.log("Acao",this.props.acao)}
                <b>Título:</b>{" "}
                {this.props.acao !== null && this.props.acao.codigo !== null && this.props.acao.tipo_titulo.prefixo !== null
                  ?this.props.acao.tipo_titulo.prefixo + " " + this.props.acao.codigo
                  : this.props.acao.codigo}
                {this.props.acao !== null &&
                this.props.acao.codigo_auxiliar !== null &&
                this.props.acao.tipo_titulo !== null &&
                this.props.acao.tipo_titulo.prefixo !== null
                  ? this.props.acao.tipo_titulo.prefixo +
                    " " +
                    this.props.acao.codigo_auxiliar
                  : ""}{" "}
                <br />
                <br />
                {this.props.acao.tipo_titulo !== null &&
                this.props.acao.tipo_titulo.descricao !== "TEMPORÁRIO" &&
                this.props.acao.tipo_titulo.descricao !== "TEMPORARIO" &&
                this.props.acao.tipo_titulo.descricao !== "TERCEIRIZADO" ? (
                  <>
                    <b>Valor Mensalidade: </b>
                    {this.props.acao !== null &&
                    this.props.acao.tipo_titulo !== null
                      ? parseFloat(
                          this.props.acao.tipo_titulo.valor_mensalidade
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""}
                  </>
                ) : (
                  <>
                    {localStorage.getItem("tipo_usuario_clube") ===
                      "administrador" ||
                    localStorage.getItem("tipo_usuario_clube") === "caixa" ||
                    localStorage.getItem("tipo_usuario_clube") ===
                      "administradorSistema" ||
                    localStorage.getItem("tipo_usuario_clube") ===
                      "administrativo" ? (
                      <Row>
                        <Col sm={6} style={{ marginRight: 0, paddingRight: 0 }}>
                          <b>Data inicial permitida: </b>
                        </Col>

                        <Col sm={6} style={{ paddingLeft: 0, marginRight: 0 }}>
                          <Form.Control
                            type="date"
                            defaultValue={this.props.acao.data_inicial}
                            name="data_inicial"
                            onChange={this.atualizaDatasAcao}
                          ></Form.Control>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col sm={6} style={{ marginRight: 0, paddingRight: 0 }}>
                          <b>Data inicial permitida: </b>
                        </Col>
                        <Col sm={6} style={{ paddingLeft: 0, marginRight: 0 }}>
                          {moment(this.props.acao.data_inicial).format(
                            "DD/MM/YYYY"
                          )}
                        </Col>
                      </Row>
                    )}
                  </>
                )}
                <br />
                <br />
                {this.props.acao.tipo_titulo.descricao === "PROJETO" && (
                  <>
                    <b>Responsável 1: </b>
                    {this.props.pessoa.responsavel1}
                  </>
                )}
              </Col>
              <Col sm={4}>
                {localStorage.getItem("tipo_usuario_clube") ===
                  "administrador" ||
                localStorage.getItem("tipo_usuario_clube") ===
                  "administrativo" ? (
                  <Row style={{ paddingBottom: 10 }}>
                    <Col sm={5}>
                      <b>Data Admissão: </b>
                    </Col>
                    <Col sm={7}>
                      <Form.Control
                        type="date"
                        defaultValue={this.props.acao.data_emissao}
                        name="data_emissao"
                        onChange={this.atualizaDatasAcao}
                      ></Form.Control>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <b>Data Admissão: </b>{" "}
                    {moment(this.props.acao.data_emissao).format("DD/MM/YYYY")}
                    <br />
                    <br />
                  </>
                )}
                {this.props.acao.tipo_titulo !== null &&
                this.props.acao.tipo_titulo.descricao !== "TEMPORÁRIO" &&
                this.props.acao.tipo_titulo.descricao !== "TEMPORARIO" &&
                this.props.acao.tipo_titulo.descricao !== "TERCEIRIZADO" ? (
                  <>
                    <b>Valor Titulo: </b>
                    {this.props.acao !== null &&
                    this.props.acao.tipo_titulo !== null
                      ? parseFloat(
                          this.props.acao.tipo_titulo.valor_titulo
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""}
                  </>
                ) : (
                  <>
                    {localStorage.getItem("tipo_usuario_clube") ===
                      "administrador" ||
                    localStorage.getItem("tipo_usuario_clube") === "caixa" ||
                    localStorage.getItem("tipo_usuario_clube") ===
                      "administradorSistema" ||
                    localStorage.getItem("tipo_usuario_clube") ===
                      "administrativo" ? (
                      <Row style={{ paddingTop: 5 }}>
                        <Col sm={6} style={{ marginRight: 0, paddingRight: 0 }}>
                          <b>Data final permitida: </b>
                        </Col>
                        <Col sm={6} style={{ paddingLeft: 0, marginRight: 0 }}>
                          <Form.Control
                            type="date"
                            defaultValue={this.props.acao.data_final}
                            name="data_final"
                            onChange={this.atualizaDatasAcao}
                          ></Form.Control>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col sm={6} style={{ marginRight: 0, paddingRight: 0 }}>
                          <b>Data final permitida: </b>
                        </Col>
                        <Col sm={6} style={{ paddingLeft: 0, marginRight: 0 }}>
                          {moment(this.props.acao.data_final).format(
                            "DD/MM/YYYY"
                          )}
                        </Col>
                      </Row>
                    )}
                  </>
                )}
                {(this.state.data_emissao !== undefined ||
                  this.state.data_inicial !== undefined ||
                  this.state.data_final !== undefined) && (
                  <Row>
                    <Col
                      sm={12}
                      style={{ paddingLeft: 0, marginRight: 0, paddingTop: 10 }}
                    >
                      <Button
                        color="blue"
                        floated="right"
                        size="tiny"
                        icon={
                          this.state.salvando === "salvo" ? "check" : "save"
                        }
                        content={
                          this.state.salvando === "salvo"
                            ? "Salvo"
                            : "Salvar datas"
                        }
                        onClick={() => this.salvarDatasAcao()}
                        loading={this.state.salvando === true}
                      />
                    </Col>
                  </Row>
                )}
                <br />
                <br />
                {this.props.acao.tipo_titulo.descricao === "PROJETO" && (
                  <>
                    <b>Responsável 2: </b>
                    {this.props.pessoa.responsavel2}
                  </>
                )}
              </Col>
            </Row>
            {this.props.acao.historico_acoes !== undefined &&
              this.props.acao.historico_acoes.length > 0 && (
                <Segment.Group horizontal>
                  <Segment>
                    <b>Motivo: </b>{" "}
                    {
                      this.props.acao.historico_acoes[
                        this.props.acao.historico_acoes.length - 1
                      ].motivo
                    }
                  </Segment>
                </Segment.Group>
              )}
          </Container>
        )}
      </Col>
    );
  }
}
