import React, { Component } from "react";
import {
  Header,
  Modal,
  Segment,
  Dropdown,
  Table,
  Menu,
  Icon,
  Button,
  Popup,
  Divider,
  Message,
  FormField,
  Input,
} from "semantic-ui-react";
import { Col, Form, Row, FormControl } from "react-bootstrap";
import Select from "react-select";
import Checkbox from "muicss/lib/react/checkbox";

import ModalAvisoExportacaoGerada from "./modalAvisoExportacaoGerada";

import { parseISO, format } from "date-fns";
import renomearChave from "../../../services/renomearChave";
import renomear2Chaves from "../../../services/renomear2Chaves";

import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";
import moment from "moment-timezone";
import $ from "jquery";
import "jquery-mask-plugin/dist/jquery.mask.min";

import ReplaceAll from "replaceall";

var formatter = new Intl.NumberFormat("pt-br", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export default class LiquidacaoLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: moment().tz("America/Sao_Paulo").format(),
      lista: [],
      tipo: "ordem_pagamento",
      data_inicio: null,
      data_final: null,
      options: [],
      pessoa_id: null,
      pessoa: null,
      open: false,
      contasAPagar: null,
      lancamento_conta: null,
      conta_bancaria_lista: [],
      conta_bancaria: null,
      conta_bancaria_id: null,
      data_pagamento: moment().tz("America/Sao_Paulo").format(),
      conta: null,
      num_cheque: null,
      valor: 0,
      valor_atraso: 0,
      valor_pago: 0,
      desconto: 0,
      favorecido: null,
      op: null,
      op_cod: null,
      optionsOP: [],
      listaOP: [],
      descricao: null,
      centro_de_custo: null,
      elemento_de_custo: null,
      quantidadeParcelas: null,
      tipos: [
        { key: "data", value: "data", text: "Data" },
        { key: "nome", value: "nome", text: "Fornecedor" },
        {
          key: "ordem_pagamento",
          value: "ordem_pagamento",
          text: "Ordem Pagamento",
        },
      ],
      ordem_pagamento: null,
      salvando: false,
      allcheck: false,
      selectedCheckboxes: [],
      modalLiquidar: false,
      listaCheckBox: [],
      totalLiquidacoes: 0,
      totalOriginal: 0,
      tipo_pagamento: null,
      lancamento_conta_itens: [{}],
      modalInconsistencia: false,
      liquidacaoErro: null,
      totalSelecionados: 0,
    };
    this.alteracao = this.alteracao.bind(this);
    this.jurosmulta = this.jurosmulta.bind(this);

    console.log("this.props.location.query: ", this.props.location.query);
    if (this.props?.location?.query?.liquidar_lancamento_conta_id) {
      this.buscaTitulo(this.props.location.query.liquidar_lancamento_conta_id);
      this.props.location.query.liquidar_lancamento_conta_id = undefined;
    }
  }

  componentMoney() {
    $(".dinheiro").mask("00.000.000.000.000,00", {
      reverse: true,
      placeholder: "0,00",
    });
  }

  jurosmulta = async (event) => {
    let juros = 0;
    if (event.target.value !== "") {
      juros = event.target.value.replace(".", "").replace(",", ".");
    }
    const valor =
      parseFloat(this.state.lancamento_conta.valor) + parseFloat(juros);
    this.setState({
      valor_atraso: parseFloat(
        event.target.value.replace(".", "").replace(",", ".")
      ),
    });
    this.setState({ valor_pago: valor.toFixed(2) });
  };

  desconto = (event) => {
    let desconto = 0;
    if (event.target.value !== "") {
      desconto = event.target.value.replace(".", "").replace(",", ".");
    }
    let valor =
      parseFloat(this.state.lancamento_conta.valor) - parseFloat(desconto);
    valor = parseFloat(this.state.valor_atraso) + valor;
    this.setState({
      desconto: parseFloat(
        event.target.value.replace(".", "").replace(",", ".")
      ),
    });
    this.setState({ valor_pago: valor.toFixed(2) });
  };

  handleChange = (e, { value }) => this.setState({ tipo: value });

  handleClose = () => this.setState({ open: false });

  handleShow = () => this.setState({ open: true });

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  alteracaoNumCheque = async (event, index) => {
    let lista = [...this.state.lancamento_conta_itens];
    lista[index].numero_cheque = event.target.value;
    this.setState({ lancamento_conta_itens: lista });
  };

  alteracaoValorCheque = async (event, index) => {
    let lista = [...this.state.lancamento_conta_itens];
    let s = ReplaceAll(".", "", event.target.value)
      .toString()
      .replace(",", ".");
    lista[index].valor = parseFloat(s);
    this.setState({ lancamento_conta_itens: lista });
    this.componentMoney();
  };

  alteracaoFavorecido = async (event, index) => {
    let lista = [...this.state.lancamento_conta_itens];
    lista[index].favorecido = event.target.value;
    this.setState({ lancamento_conta_itens: lista });
  };

  alteracaoMulta = async (event) => {
    const idItem = event.target.id;
    for (let i = 0; i < this.state.listaCheckBox.length; i++) {
      if (i == idItem && event.target.value != "" && event.target.value != 0) {
        this.state.listaCheckBox[i].valor_atraso = event.target.value;
        this.state.listaCheckBox[i].valor_pago =
          parseFloat(this.state.listaCheckBox[i].valor_pago) +
          parseFloat(event.target.value.replace(".", "").replace(",", "."));
        this.setState({
          totalLiquidacoes:
            parseFloat(this.state.totalLiquidacoes) +
            parseFloat(event.target.value.replace(".", "").replace(",", ".")),
        });
        this.forceUpdate();
      } else {
        this.state.listaCheckBox[i].valor_pago =
          parseFloat(this.state.listaCheckBox[i].valor) -
          parseFloat(
            this.state.listaCheckBox[i].desconto != null
              ? this.state.listaCheckBox[i].desconto
              : 0
          );
        this.forceUpdate();
      }
    }
  };

  alteracaoDesconto = async (event, target) => {
    const idItem = event.target.id;
    for (let i = 0; i < this.state.listaCheckBox.length; i++) {
      if (i == idItem && event.target.value != "" && event.target.value != 0) {
        this.setState({
          pagoOriginal: parseFloat(this.state.listaCheckBox[i].valor),
        });
        this.state.listaCheckBox[i].desconto = event.target.value;
        this.state.listaCheckBox[i].valor_pago =
          parseFloat(this.state.listaCheckBox[i].valor_pago) -
          parseFloat(event.target.value.replace(".", "").replace(",", "."));
        this.setState({
          totalLiquidacoes:
            parseFloat(this.state.totalLiquidacoes) -
            parseFloat(event.target.value.replace(".", "").replace(",", ".")),
        });
        this.forceUpdate();
      } else {
        this.state.listaCheckBox[i].valor_pago =
          parseFloat(this.state.listaCheckBox[i].valor) +
          parseFloat(
            this.state.listaCheckBox[i].valor_atraso != null
              ? this.state.listaCheckBox[i].valor_atraso
              : 0
          );
        this.forceUpdate();
      }
    }
  };

  alteracaoConta = async (event) => {
    this.setState({ conta_bancaria_id: event.target.value });
    this.setState({ conta_bancaria: event });
    const respContaInfo = await api.post(
      "/contaBancaria/visualiza" + requisicoes.entidade,
      { id: event.target.value },
      requisicoes.header
    );
    if (respContaInfo.data !== null) {
      let banco = respContaInfo.data.banco.nome.split("-");
      let desc = banco[1] + " / " + respContaInfo.data.agencia.nome;
      this.setState({ descricao: desc });
    }
  };

  alteracaoSelectCentroCusto = async (event, id) => {
    this.setState({ centro_de_custo_id: event.value });
    this.setState({ centro_de_custo: event });
    document.getElementById("pesquisaCentroCusto").removeAttribute("onchange");
  };

  alteracaoSelectElementoCusto = async (event, id) => {
    this.setState({ elemento_de_custo_id: event.value });
    this.setState({ elemento_de_custo: event });
    document
      .getElementById("pesquisaElementoCusto")
      .removeAttribute("onchange");
  };

  contaBancaria = async () => {
    await api
      .post(
        "/contaBancaria/lista" + requisicoes.entidade,
        {},
        requisicoes.header
      )
      .then((resp) => {
        this.setState({ conta_bancaria_lista: resp.data });
      });
  };

  pesquisaPessoa = (newValue) => {
    api
      .post(
        "/pessoa/pesquisaFornecedor" + requisicoes.entidade,
        { pesquisa: newValue },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomear2Chaves(objeto, "reduzido", "nome", "label");
          lista.push(objeto);
        });
        this.setState({ options: lista });
      });
  };

  pesquisaFavorecido = (newValue) => {
    api
      .post(
        "/pessoa/pesquisa" + requisicoes.entidade,
        { pesquisa: newValue },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomearChave(objeto, "nome", "label");
          lista.push(objeto);
        });
        this.setState({ options: lista });
      });
  };

  pesquisaConta = async () => {
    await api
      .post(
        "/contaBancaria/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaConta").value },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomearChave(objeto, "nome", "label");
          lista.push(objeto);
        });
        this.setState({ options: lista });
      });
  };

  pesquisaCentroCusto = async () => {
    await api
      .post(
        "/centroDeCusto/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaCentroCusto").value },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomearChave(objeto, "descricao", "label");
          lista.push(objeto);
        });
        this.setState({ optionsCC: lista });
      });
  };

  pesquisaElementoCusto = async () => {
    await api
      .post(
        "/elementoDeCusto/pesquisaByContaContabil" + requisicoes.entidade,
        {
          pesquisa: document.getElementById("pesquisaElementoCusto").value,
          centroCusto: this.state.centro_custo_id,
          cad: false,
        },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomearChave(objeto, "descricao", "label");
          lista.push(objeto);
        });
        this.setState({ optionsEC: lista });
      });
  };

  alteracaoSelectPessoa = async (event) => {
    this.setState({ pessoa_id: event.value });
    this.setState({ pessoa: event });
  };

  alteracaoSelectConta = async (event) => {
    this.setState({ conta_id: event.value });
    this.setState({ conta: event });
  };

  pesquisaOP = async () => {
    await api
      .post(
        "/ordemPagamento/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaOP").value },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomearChave(objeto, "codigo", "label");
          lista.push(objeto);
        });
        this.setState({ optionsOP: lista });
      });
  };

  alteracaoSelectOP = async (event) => {
    this.setState({ op_cod: event.value });
    this.setState({ op: event });
  };

  verificaDatas = () => {
    let valida =
      moment(this.state.data_inicio).isValid() &&
      moment(this.state.data_final).isValid();
    if (!valida) alert("Data inválida!");

    console.log("data_inicio: ", this.state.data_inicio);
    console.log("data_final: ", this.state.data_final);

    return valida;
  };

  buscaTitulos = async () => {
    this.setState({ loading: true });
    try {
      let retorno = "";
      switch (this.state.tipo) {
        case "nome":
          retorno = await api.post(
            "/contasAPagar/lancamentosAbertosPorPessoa" + requisicoes.entidade,
            { pessoa_id: this.state.pessoa_id },
            requisicoes.header
          );
          break;

        case "data":
          if (!this.verificaDatas()) {
            return;
          }
          retorno = await api.post(
            "/contasAPagar/lancamentoAVencerPorData" + requisicoes.entidade,
            {
              data_inicial: this.state.data_inicio,
              data_final: this.state.data_final,
            },
            requisicoes.header
          );
          break;

        case "ordem_pagamento":
          retorno = await api.post(
            "/contasAPagar/lancamentosPorOrdemPagamentoAVencer" +
              requisicoes.entidade,
            { ordem_pagamento: this.state.ordem_pagamento },
            requisicoes.header
          );
          break;
        default:
          break;
      }
      retorno.data.forEach(async (a) => {
        a.selecionado = false;
      });
      this.setState({ lista: retorno.data });
    } catch (error) {
      alert(
        "Ocorreu um erro ao buscar os registros. Por favor, tente novamente."
      );
    } finally {
      this.setState({ loading: false });
    }
  };

  buscaTitulo = async (lancamento_conta_id) => {
    // const id = event.target.id.toString().substring(6, 20);
    let retorno = await api.post(
      "/contasAPagar/contasAPagarPorLancamento" + requisicoes.entidade,
      { lancamento_conta_id: lancamento_conta_id },
      requisicoes.header
    );
    console.log("retorno", retorno.data.tipo_contabilizacao);

    this.setState({ contasAPagar: retorno.data });
    this.setState({
      quantidadeParcelas: retorno.data.lancamento_contas.length,
    });
    retorno = await api.post(
      "/lancamentoConta/visualiza" + requisicoes.entidade,
      { id: lancamento_conta_id },
      requisicoes.header
    );
    this.setState({ lancamento_conta: retorno.data });
    this.contaBancaria();
    this.setState({ valor_pago: retorno.data.valor });
    this.handleShow();
  };

  voltarPagina = async () => {
    const { pagina } = this.state;
    if (pagina <= 1) return;
    const paginaNumero = pagina - 1;
    this.lista(paginaNumero);
  };

  proximaPagina = async () => {
    const { pagina, totalPaginas } = this.state;
    if (pagina >= totalPaginas) return;
    const paginaNumero = parseInt(pagina) + 1;
    this.lista(paginaNumero);
  };

  verificaDataExportacaoContabil = async (data) => {
    const maxDataFinal = await api.post(
      "/exportacaoContabil/maiorDataFinalRemessaGeradaTipo" +
        requisicoes.entidade,
      { arquivo: "LancamentoConta" },
      requisicoes.header
    );
    console.log(maxDataFinal.data);
    if (maxDataFinal.data && maxDataFinal.data >= data) {
      return false;
    } else {
      return true;
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ salvando: true });

    let verifica = await this.verificaDataExportacaoContabil(
      this.state.data_pagamento
    );
    if (verifica) {
      this.submit();
    } else {
      this.setState({
        modalAvisoExportacaoGeradaOpen: true,
        salvando: false,
      });
    }
  };

  submit = async () => {
    let valor = parseFloat(this.state.lancamento_conta.valor);
    let valor_pago = parseFloat(this.state.valor_pago.replace(",", "."));
    let valor_atraso = parseFloat(this.state.valor_atraso);
    let desconto = parseFloat(this.state.desconto);

    if (
      valor_pago !==
      Math.round((valor + valor_atraso - desconto) * 1000) / 1000
    ) {
      alert("Valor da conta com juros e descontos é diferente do valor total.");
      this.setState({ salvando: false });
      return;
    }
    try {
      await api.post(
        "/lancamentoConta/baixarLancamento" + requisicoes.entidade,
        {
          id: this.state.lancamento_conta.id,
          data_pagamento: this.state.data_pagamento,
          forma_pagamento: this.state.tipo_pagamento,
          conta_bancaria_id:
            this.state.tipo_pagamento === "dinheiro"
              ? null
              : this.state.conta_bancaria_id,
          conta: this.state.conta,
          num_cheque: this.state.num_cheque,
          // valor: this.state.valor_pagamento,
          valor_pago: valor_pago,
          valor_atraso: valor_atraso,
          desconto: desconto,
          favorecido: this.state.favorecido,
          lancamento_conta_itens: this.state.lancamento_conta_itens,
          observacoes: this.state.obs,
        },
        requisicoes.header
      );

      this.setState(
        {
          tipo: "data",
          data_inicio: moment().format("YYYY-MM-DD"),
          data_final: moment().format("YYYY-MM-DD"),
          id: null,
          lancamento_conta_itens: [],
          salvando: false,
        },
        async () => {
          await this.buscaTitulos();
          this.setState({ open: false });
        }
      );
      // window.location.href = "/financeiro";
    } catch (error) {
      this.setState({
        salvando: false,
      });
      if (error.response.data !== "") {
        alert(error.response.data);
      } else {
        alert(
          "Ocorreu um erro indesperado ao salvar sua requisição. Por favor tente novamente."
        );
      }
    }
  };

  toggleCheckbox = (event) => {
    let lista = this.state.lista;
    for (let i = 0; i < lista.length; i++) {
      const e = lista[i];
      if (parseInt(e.id) === parseInt(event)) {
        if (e.selecionado === true) {
          e.selecionado = false;
          this.setState({ allcheck: false });
          this.setState({
            totalSelecionados:
              this.state.totalSelecionados - parseFloat(e.valor),
          });
        } else {
          e.selecionado = true;
          this.setState({
            totalSelecionados:
              this.state.totalSelecionados + parseFloat(e.valor),
          });
        }
      }
    }
    this.setState({ lista: lista });
  };

  marcartodas = () => {
    if (this.state.allcheck === false) {
      this.setState({ allcheck: true });
    } else {
      this.setState({ allcheck: false });
    }
    if (this.state.allcheck === false) {
      let soma = 0;
      this.state.lista.forEach((e) => {
        if (!e.cancelado) {
          if (e.contas_a_pagars.length > 0) {
            e.selecionado = true;
            soma += parseFloat(e.valor);
          } else {
            e.selecionado = false;
          }
        }
      });
      this.setState({
        totalSelecionados: soma,
      });
    } else {
      let subtrai = this.state.totalSelecionados;
      this.state.lista.forEach((e) => {
        if (!e.cancelado) {
          e.selecionado = false;
          subtrai -= parseFloat(e.valor);
        }
      });
      this.setState({
        totalSelecionados: subtrai,
      });
    }
  };

  handleShowLiquidar = async (event) => {
    event.preventDefault();
    let lista = [];
    let total = 0;
    await asyncForEach(this.state.lista, async (e) => {
      if (e.selecionado !== undefined) {
        if (e.selecionado === true) {
          const retorno = await api.post(
            "/lancamentoConta/visualiza" + requisicoes.entidade,
            { id: e.id },
            requisicoes.header
          );
          total = total + parseFloat(retorno.data.valor);
          retorno.data.valor_pago = retorno.data.valor;
          lista.push(retorno.data);
        }
      }
    });
    this.setState({ totalLiquidacoes: total });
    this.setState({ totalOriginal: total });
    this.setState({ listaCheckBox: lista });
    this.contaBancaria();
    this.setState({ modalLiquidar: true });
  };

  submitLiquidacoes = async (event) => {
    event.preventDefault();
    if (
      !(await this.verificaDataExportacaoContabil(this.state.data_pagamento))
    ) {
      this.setState({
        modalAvisoExportacaoGeradaOpen: true,
        salvando: false,
      });
      return;
    }
    for (let i = 0; i < this.state.listaCheckBox.length; i++) {
      this.state.listaCheckBox[i].valor_atraso !== null
        ? (this.state.listaCheckBox[i].valor_atraso = parseFloat(
            this.state.listaCheckBox[i].valor_atraso
              .replace(".", "")
              .replace(",", ".")
          ))
        : (this.state.listaCheckBox[i].valor_atraso = 0);
      this.state.listaCheckBox[i].desconto !== null
        ? (this.state.listaCheckBox[i].desconto = parseFloat(
            this.state.listaCheckBox[i].desconto
              .replace(".", "")
              .replace(",", ".")
          ))
        : (this.state.listaCheckBox[i].desconto = 0);
      this.state.listaCheckBox[i].valor_pago =
        parseFloat(
          parseFloat(this.state.listaCheckBox[i].valor_pago) -
            parseFloat(this.state.listaCheckBox[i].desconto)
        ) + parseFloat(this.state.listaCheckBox[i].valor_atraso);
      this.forceUpdate();
    }

    try {
      await api.post(
        "/lancamentoConta/baixarVarios" + requisicoes.entidade,
        {
          lancamentos: this.state.listaCheckBox,
          data_pagamento: this.state.data_pagamento,
          forma_pagamento: this.state.tipo_pagamento,
          conta_bancaria_id:
            this.state.tipo_pagamento === "dinheiro"
              ? null
              : this.state.conta_bancaria_id,
          conta: this.state.conta,
          observacoes: this.state.observacao,
          lancamento_conta_itens:
            this.state.tipo_pagamento !== "cheque" &&
            this.state.tipo_pagamento !== "ddaCheque"
              ? []
              : this.state.lancamento_conta_itens,
        },
        requisicoes.header
      );
      this.setState(
        {
          tipo: "data",
          data_inicio: moment().format("YYYY-MM-DD"),
          data_final: moment().format("YYYY-MM-DD"),
          lancamentos: [],
          lancamento_conta_itens: [],
          salvando: false,
        },
        async () => {
          await this.buscaTitulos();
          this.setState({ modalLiquidar: false });
        }
      );
      // window.location.href = "/financeiro";
    } catch (error) {
      if (error.response.data !== "") {
        alert(error.response.data);
      } else {
        alert(
          "Ocorreu um erro indesperado ao salvar sua requisição. Por favor tente novamente."
        );
      }
    }
  };

  addItem = async (event) => {
    const item = {};
    this.setState({
      lancamento_conta_itens: [...this.state.lancamento_conta_itens, item],
    });
  };

  render() {
    return (
      <div className="lista">
        <Row>
          <Col sm={4}>
            <Form.Label>Tipo</Form.Label>
            <Dropdown
              placeholder="Tipo"
              id="tipo"
              name="tipo"
              fluid
              search
              selection
              options={this.state.tipos}
              onChange={this.handleChange}
              value={this.state.tipo}
            />
          </Col>
          {this.state.tipo === "ordem_pagamento" && (
            <Col sm={6}>
              <Form.Label>Busca</Form.Label>
              <FormControl
                placeholder="nº"
                aria-describedby="basic-addon1"
                name="ordem_pagamento"
                id="ordem_pagamento"
                required
                onChange={this.alteracao}
              />
            </Col>
          )}
          {this.state.tipo === "nome" && (
            <Col sm={6}>
              <Form.Label>Busca</Form.Label>
              <Select
                placeholder="Digite para buscar"
                name="pessoa"
                onInputChange={this.pesquisaPessoa}
                onChange={this.alteracaoSelectPessoa}
                options={this.state.options}
              />
            </Col>
          )}
          {this.state.tipo === "data" && (
            <Col sm={6}>
              <Row>
                <Col sm={6}>
                  <Form.Label>Data Inicio</Form.Label>
                  <FormControl
                    placeholder="Data Inicio"
                    aria-describedby="basic-addon1"
                    type="date"
                    name="data_inicio"
                    onChange={this.alteracao}
                    value={this.state.data_inicio}
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label>Data Final</Form.Label>
                  <FormControl
                    placeholder="Data Final"
                    aria-describedby="basic-addon1"
                    type="date"
                    name="data_final"
                    onChange={this.alteracao}
                    value={this.state.data_final}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col sm={2}>
            <Button
              positive
              style={{ marginTop: "25px" }}
              onClick={this.buscaTitulos}
              disabled={this.state.loading}
              loading={this.state.loading}
              animated
            >
              <Button.Content hidden>
                <Icon name="search" />
              </Button.Content>
              <Button.Content visible>Buscar</Button.Content>
            </Button>
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>Data Vencimento</Table.HeaderCell>
              <Table.HeaderCell width={5}>Nome</Table.HeaderCell>
              <Table.HeaderCell width={1}>Documento</Table.HeaderCell>
              <Table.HeaderCell width={2}>O.P</Table.HeaderCell>
              <Table.HeaderCell width={2}>Valor</Table.HeaderCell>
              <Table.HeaderCell style={{ width: "1%" }} textAlign="center">
                <Checkbox
                  label=""
                  onChange={this.marcartodas}
                  // onClick={this.marcartodas}
                  name="allcheck"
                  checked={this.state.allcheck}
                />
              </Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign="center">
                <Popup
                  content="Liquidar em massa"
                  position="top right"
                  mouseEnterDelay={1000}
                  on="hover"
                  trigger={
                    <Button
                      color="blue"
                      onClick={this.handleShowLiquidar}
                      icon={{
                        className: "clipboard list",
                        onClick: [this.handleShowLiquidar],
                      }}
                    />
                  }
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.lista.map((e, i) => (
              <>
                {e.cancelado !== true ? (
                  <Table.Row key={i}>
                    <Table.Cell>
                      {e.data_vencimento !== null &&
                        format(parseISO(e.data_vencimento), "dd/MM/yyyy")}
                    </Table.Cell>
                    <Table.Cell>
                      {e.pessoa !== null && e.pessoa.nome}
                    </Table.Cell>
                    <Table.Cell>
                      {e.contas_a_pagars != 0 && e.contas_a_pagars[0].documento}
                    </Table.Cell>
                    <Table.Cell>
                      {e.contas_a_pagars != 0 &&
                        e.contas_a_pagars[0].ordem_pagamento != null &&
                        e.contas_a_pagars[0].ordem_pagamento.codigo +
                          " / "}{" "}
                      {e.numero_parcela}
                      {e.contas_a_pagars != 0 &&
                        e.contas_a_pagars[0].lancamento_contas != null &&
                        " - " + e.contas_a_pagars[0].lancamento_contas.length}
                    </Table.Cell>
                    <Table.Cell>
                      {parseFloat(e.valor).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Checkbox
                        hidden={e.contas_a_pagars.length <= 0}
                        label=""
                        onChange={() => this.toggleCheckbox(e.id)}
                        defaultValue={e.id}
                        checked={e.selecionado}
                      />
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Button
                        hidden={e.contas_a_pagars.length <= 0}
                        color="blue"
                        // id={"button" + e.id}
                        onClick={() => this.buscaTitulo(e.id)}
                        icon="edit"
                        // icon={{
                        //   className: "edit",
                        //   id: ["button" + e.id],
                        //   onClick: [this.buscaTitulo],
                        // }}
                      />
                      <Button
                        hidden={e.contas_a_pagars.length > 0}
                        color="red"
                        id={"button" + e.id}
                        onClick={() =>
                          this.setState({
                            modalInconsistencia: true,
                            liquidacaoErro: e,
                          })
                        }
                        icon={{
                          className: "exclamation",
                          id: ["button" + e.id],
                          onClick: [
                            () =>
                              this.setState({
                                modalInconsistencia: true,
                                liquidacaoErro: e,
                              }),
                          ],
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                ) : null}
              </>
            ))}
            <Table.Row>
              <Table.Cell>
                <b>Total:</b>
              </Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell></Table.Cell>
              <Table.Cell>
                <FormField
                  id="totalSelecionados"
                  control={Input}
                  name="totalSelecionados"
                  value={formatter.format(this.state.totalSelecionados)}
                  fluid
                />
              </Table.Cell>
              <Table.Cell></Table.Cell>
            </Table.Row>
          </Table.Body>

          <Table.Footer>
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan={7}>
                <Menu pagination>
                  <Menu.Item
                    icon
                    onClick={this.voltarPagina}
                    disabled={this.state.pagina === 1}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  <Menu.Item
                    icon
                    onClick={this.proximaPagina}
                    disabled={this.state.pagina === this.state.totalPaginas}
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>

        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          centered={false}
        >
          <Modal.Header>
            <Row>
              <Col sm={6}>Conta</Col>
              <Col
                sm={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {this.state.contasAPagar !== null && (
                  <>
                    {"Tipo Contabilização: " +
                      (this.state.contasAPagar.tipo_contabilizacao === "emissao"
                        ? "Emissão"
                        : this.state.contasAPagar.tipo_contabilizacao[0].toUpperCase() +
                          this.state.contasAPagar.tipo_contabilizacao.substring(
                            1
                          ))}
                  </>
                )}
              </Col>
            </Row>
          </Modal.Header>
          <Modal.Content>
            <Segment.Group>
              <Segment.Group horizontal>
                <Segment>
                  <b>Fornecedor: </b>
                  {this.state.lancamento_conta !== null &&
                    this.state.lancamento_conta.pessoa.nome}
                </Segment>
                <Segment>
                  <b>Documento: </b>
                  {this.state.contasAPagar !== null &&
                    this.state.contasAPagar.documento}
                </Segment>
                <Segment>
                  <b>O.P: </b>
                  {this.state.contasAPagar !== null &&
                    this.state.lancamento_conta != null &&
                    this.state.contasAPagar.ordem_pagamento != null &&
                    this.state.contasAPagar.ordem_pagamento.codigo +
                      " / " +
                      this.state.lancamento_conta.numero_parcela +
                      " - " +
                      this.state.quantidadeParcelas}
                </Segment>
              </Segment.Group>
              <Segment.Group horizontal>
                <Segment>
                  <b>Valor Total: </b>
                  {this.state.contasAPagar !== null &&
                    parseFloat(this.state.contasAPagar.valor).toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                </Segment>
                <Segment>
                  <b>Valor Título: </b>
                  {this.state.lancamento_conta !== null &&
                    parseFloat(
                      this.state.lancamento_conta.valor
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </Segment>
                <Segment>
                  <b>Vencimento: </b>
                  {this.state.lancamento_conta !== null &&
                    format(
                      parseISO(this.state.lancamento_conta.data_vencimento),
                      "dd/MM/yyyy"
                    )}
                </Segment>
              </Segment.Group>
            </Segment.Group>

            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col sm={3}>
                  <Form.Label>Data Pagamento</Form.Label>
                  <FormControl
                    placeholder="Data"
                    type="date"
                    name="data_pagamento"
                    onChange={this.alteracao}
                    defaultValue={this.state.data_pagamento
                      .toString()
                      .substr(0, 10)}
                  />
                </Col>
                <Col sm={3}>
                  <Form.Label>Valor Título</Form.Label>
                  <FormControl
                    placeholder="Valor"
                    aria-describedby="basic-addon1"
                    name="valor_pagamento"
                    required
                    onChange={this.alteracao}
                    value={
                      this.state.lancamento_conta != null &&
                      formatter.format(this.state.lancamento_conta.valor)
                    }
                    readOnly
                  />
                </Col>
                <Col sm={3}>
                  <Form.Label>Juros/Multas</Form.Label>
                  <FormControl
                    placeholder="Valor"
                    aria-describedby="basic-addon1"
                    name="valor_atraso"
                    onChange={this.alteracao}
                    className="dinheiro"
                    onKeyUp={this.componentMoney}
                    onBlur={this.jurosmulta}
                    value={this.state.valor_atraso}
                  />
                </Col>
                <Col sm={3}>
                  <Form.Label>Desconto</Form.Label>
                  <FormControl
                    placeholder="Desconto"
                    aria-describedby="basic-addon1"
                    name="desconto"
                    onChange={this.alteracao}
                    className="dinheiro"
                    onKeyUp={this.componentMoney}
                    onBlur={this.desconto}
                    value={this.state.desconto}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={3}>
                  <Form.Label>Valor Pagamento</Form.Label>
                  <FormControl
                    placeholder="Valor Pagamento"
                    aria-describedby="basic-addon1"
                    name="valor_pago"
                    id="valor_pago"
                    required="required"
                    onChange={this.alteracao}
                    className="dinheiro"
                    onKeyUp={this.componentMoney}
                    value={formatter.format(this.state.valor_pago)}
                    readOnly="readonly"
                  />
                </Col>
                <Col sm={3}>
                  <Form.Label>Tipo Pagamento</Form.Label>
                  <Form.Control
                    placeholder="Tipo"
                    as="select"
                    name="tipo_pagamento"
                    onChange={this.alteracao}
                    checked={this.state.selectTipo}
                    required
                  >
                    <option></option>
                    <option value="cheque">Cheque</option>
                    <option value="dda">DDA</option>
                    <option value="dinheiro">Dinheiro</option>
                    {
                      //<option value="ddaCheque">DDA + Cheque</option>
                    }
                    {
                      // ddaCheque removido pq não funciona corretamente com a ideia de
                      // contas bancarias. Um lancamento conta só pode ter uma conta bancária.
                    }
                  </Form.Control>
                </Col>
                <Col
                  sm={3}
                  hidden={
                    this.state.tipo_pagamento !== "dda" &&
                    this.state.tipo_pagamento !== "ddaCheque"
                  }
                >
                  <Form.Label>Conta DDA</Form.Label>
                  <Form.Control
                    placeholder="Conta"
                    as="select"
                    name="conta_bancaria"
                    onChange={this.alteracaoConta}
                    required={
                      this.state.tipo_pagamento === "dda" ||
                      this.state.tipo_pagamento === "ddaCheque"
                    }
                    value={this.state.conta_bancaria_id}
                  >
                    <option></option>
                    {this.state.conta_bancaria_lista.map((e) => (
                      <option value={e.id}>{e.conta}</option>
                    ))}
                  </Form.Control>
                </Col>
                <Col
                  sm={3}
                  hidden={
                    this.state.tipo_pagamento !== "cheque" &&
                    this.state.tipo_pagamento !== "ddaCheque"
                  }
                >
                  <Form.Label>Conta Cheque</Form.Label>
                  <Form.Control
                    placeholder="Conta"
                    as="select"
                    name="conta_bancaria"
                    onChange={this.alteracaoConta}
                    required={
                      this.state.tipo_pagamento === "cheque" ||
                      this.state.tipo_pagamento === "ddaCheque"
                    }
                    value={this.state.conta_bancaria_id}
                  >
                    <option></option>
                    {this.state.conta_bancaria_lista.map((e) => (
                      <option value={e.id}>{e.conta}</option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>

              <Row style={{ marginTop: 30 }}>
                <Col
                  md="auto"
                  hidden={
                    this.state.tipo_pagamento !== "cheque" &&
                    this.state.tipo_pagamento !== "ddaCheque"
                  }
                >
                  <Button
                    positive
                    icon="add"
                    size="tiny"
                    onClick={this.addItem}
                    type="button"
                    content={"Adicionar Cheque"}
                  ></Button>
                </Col>
              </Row>
              {this.state.lancamento_conta_itens.map((e, i) => (
                <Row
                  style={{ marginTop: 5, marginBottom: 5 }}
                  hidden={
                    this.state.tipo_pagamento !== "cheque" &&
                    this.state.tipo_pagamento !== "ddaCheque"
                  }
                >
                  <Col>
                    <Form.Label>Nº Cheque</Form.Label>
                    <Form.Control
                      placeholder="nº cheque"
                      name="num_cheque"
                      id={[i]}
                      onChange={(event) => this.alteracaoNumCheque(event, i)}
                      required={
                        this.state.tipo_pagamento === "cheque" ||
                        this.state.tipo_pagamento === "ddaCheque"
                      }
                      value={e.numero_cheque || ""}
                    ></Form.Control>
                  </Col>
                  <Col sm={3}>
                    <Form.Label>Valor</Form.Label>
                    <Form.Control
                      placeholder="valor"
                      id={[i * 100]}
                      name={[i * 100]}
                      className="dinheiro"
                      onKeyUp={(event) => this.alteracaoValorCheque(event, i)}
                      required={
                        this.state.tipo_pagamento === "cheque" ||
                        this.state.tipo_pagamento === "ddaCheque"
                      }
                    ></Form.Control>
                  </Col>
                  <Col sm={6}>
                    <Form.Label>Favorecido</Form.Label>
                    <Form.Control
                      placeholder="favorecido"
                      id={[i * 10000]}
                      name={[i * 10000]}
                      onChange={(event) => this.alteracaoFavorecido(event, i)}
                      // required={ //foi solicitado para retirar
                      //   this.state.tipo_pagamento === "cheque" ||
                      //   this.state.tipo_pagamento === "ddaCheque"
                      // }
                      value={e.favorecido}
                    ></Form.Control>
                  </Col>
                  {this.state.lancamento_conta_itens.length > 1 && (
                    <Col
                      md="auto"
                      style={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <Button
                        negative
                        icon="x"
                        size="tiny"
                        onClick={() => {
                          let lista = [...this.state.lancamento_conta_itens];
                          lista.splice(i, 1);
                          this.setState({
                            lancamento_conta_itens: lista,
                          });
                        }}
                        type="button"
                      ></Button>
                    </Col>
                  )}
                </Row>
              ))}
              <Row>
                {this.state.conta_bancaria !== null && (
                  <Col sm={6}>
                    <Form.Label>Banco/Agência</Form.Label>
                    <FormControl
                      placeholder="Banco/Agência"
                      aria-describedby="basic-addon1"
                      name="descricao"
                      id="descricao"
                      defaultValue={this.state.descricao}
                      disabled={true}
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col sm={12}>
                  <Form.Label>Observação</Form.Label>
                  <FormControl
                    placeholder="Observação"
                    aria-describedby="basic-addon1"
                    as="textarea"
                    name="obs"
                    onChange={this.alteracao}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} hidden>
                  <Form.Label>Centro de Custo</Form.Label>
                  <Select
                    placeholder="Adicionar Centro de Custo"
                    name="centro_de_custo"
                    onChange={this.alteracaoSelectCentroCusto}
                    options={this.state.optionsCC}
                    onKeyDown={this.pesquisaCentroCusto}
                    inputId="pesquisaCentroCusto"
                    value={
                      this.state.contasAPagar != null &&
                      this.state.contasAPagar.centro_de_custo
                    }
                  />
                </Col>
                <Col sm={6} hidden>
                  <Form.Label>Elemento de Custo</Form.Label>
                  <Select
                    placeholder="Adicionar Elemento de Custo"
                    name="elemento_de_custo"
                    onChange={this.alteracaoSelectElementoCusto}
                    options={this.state.optionsEC}
                    onKeyDown={this.pesquisaElementoCusto}
                    inputId="pesquisaElementoCusto"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.Cell>Rateio</Table.Cell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.HeaderCell width={4}>
                          Centro de Custo
                        </Table.HeaderCell>
                        <Table.HeaderCell width={4}>
                          Elemento de Custo
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>Valor </Table.HeaderCell>
                      </Table.Row>
                    </Table.Body>
                    <Table.Body>
                      {this.state.lancamento_conta != null &&
                        this.state.lancamento_conta.rateios.map((e, i) => (
                          <Table.Row key={i}>
                            <Table.Cell>
                              <Select
                                placeholder="Adicionar Centro de Custo"
                                name={[i]}
                                onChange={this.alteracaoSelectCentroCusto}
                                options={this.state.optionsCC}
                                onKeyDown={this.pesquisaCentroCusto}
                                isDisabled={e.centro_de_custo != null}
                                defaultInputValue={
                                  e.centro_de_custo != null
                                    ? e.centro_de_custo.descricao
                                    : ""
                                }
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Select
                                placeholder="Adicionar Elemento de Custo"
                                name={[i]}
                                onChange={this.alteracaoSelectElementoCusto}
                                options={this.state.optionsEC}
                                onKeyDown={this.pesquisaElementoCusto}
                                isDisabled={e.elemento_de_custo != null}
                                defaultInputValue={
                                  e.elemento_de_custo != null
                                    ? e.elemento_de_custo.descricao
                                    : ""
                                }
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <FormControl
                                placeholder=""
                                aria-describedby="basic-addon1"
                                id={"itens[" + [i] + "].valor"}
                                name={"valorItem" + i}
                                defaultValue={formatter.format(e.valor)}
                                required
                                className="dinheiro"
                                onKeyUp={this.componentMoney}
                                onBlur={this.diminuiTotal}
                                onKeyDown={this.enter}
                              />
                            </Table.Cell>
                          </Table.Row>
                        ))}
                    </Table.Body>
                  </Table>
                </Col>
              </Row>
              <Segment clearing>
                <Header floated="right">
                  <Button
                    variant="light"
                    onClick={this.handleClose}
                    style={{ marginLeft: "10px" }}
                  >
                    Voltar
                  </Button>
                  <Button
                    positive
                    loading={this.state.salvando}
                    disabled={this.state.salvando}
                  >
                    Liquidar
                  </Button>
                </Header>
              </Segment>
            </Form>
          </Modal.Content>
        </Modal>

        {/* Modal liquidar em massa - liquidacoes */}
        <Modal
          open={this.state.modalLiquidar}
          onClose={() => this.setState({ modalLiquidar: false })}
          centered={false}
        >
          <Header content="Contas" />
          <Modal.Content>
            <Form onSubmit={this.submitLiquidacoes}>
              {this.state.listaCheckBox.map((e, i) =>
                e.contas_a_pagars[0] !== undefined ? (
                  <Row>
                    <Col sm={12}>
                      <Segment.Group>
                        <Segment.Group horizontal>
                          <Segment>
                            <b>Fornecedor: </b>
                            {e.pessoa.nome}
                          </Segment>
                          <Segment>
                            <b>Documento: </b>
                            {e.contas_a_pagars[0].documento}
                          </Segment>
                          <Segment>
                            <b>O.P: </b>
                            {e.contas_a_pagars[0].ordem_pagamento.codigo +
                              " / " +
                              e.numero_parcela}
                          </Segment>
                        </Segment.Group>
                        <Segment.Group horizontal>
                          <Segment>
                            <b>Valor Total: </b>
                            {parseFloat(
                              e.contas_a_pagars[0].valor
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Segment>
                          <Segment>
                            <b>Valor Título: </b>
                            {parseFloat(e.valor).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Segment>
                          <Segment>
                            <b>Vencimento: </b>
                            {format(parseISO(e.data_vencimento), "dd/MM/yyyy")}
                          </Segment>
                        </Segment.Group>
                      </Segment.Group>
                    </Col>
                    <Col sm={3} style={{ marginTop: "1%", marginBottom: "1%" }}>
                      <Form.Label>Valor Título</Form.Label>
                      <FormControl
                        placeholder="Valor"
                        aria-describedby="basic-addon1"
                        name="valor_pagamento"
                        required
                        value={formatter.format(e.valor)}
                        readOnly
                      />
                    </Col>
                    <Col sm={3} style={{ marginTop: "1%", marginBottom: "1%" }}>
                      <Form.Label>Juros/Multas</Form.Label>
                      <FormControl
                        placeholder="Valor"
                        className="dinheiro"
                        id={[i]}
                        name={[i]}
                        // onChange={this.alteracaoMulta}
                        onKeyUp={this.componentMoney}
                        onBlur={this.alteracaoMulta}
                        defaultValue={e.valor_atraso}
                      />
                    </Col>
                    <Col sm={3} style={{ marginTop: "1%", marginBottom: "1%" }}>
                      <Form.Label>Desconto</Form.Label>
                      <FormControl
                        placeholder="Desconto"
                        id={[i]}
                        name={[i]}
                        onBlur={this.alteracaoDesconto}
                        className="dinheiro"
                        onKeyUp={this.componentMoney}
                        defaultValue={e.desconto}
                      />
                    </Col>
                    <Col sm={3} style={{ marginTop: "1%", marginBottom: "1%" }}>
                      <Form.Label>Valor Pagamento</Form.Label>
                      <FormControl
                        placeholder="Valor Pagamento"
                        aria-describedby="basic-addon1"
                        name="valor_pago"
                        id="valor_pago"
                        required="required"
                        className="dinheiro"
                        onKeyUp={this.componentMoney}
                        value={formatter.format(e.valor_pago)}
                        readOnly="readonly"
                      />
                    </Col>
                    <Col sm={12}>
                      <Table celled>
                        <Table.Body>
                          <Table.Row>
                            <Table.HeaderCell width={4}>
                              Centro de Custo
                            </Table.HeaderCell>
                            <Table.HeaderCell width={4}>
                              Elemento de Custo
                            </Table.HeaderCell>
                            <Table.HeaderCell width={2}>
                              Valor{" "}
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Body>
                        <Table.Body>
                          {e.rateios.map((r, i) => (
                            <Table.Row key={i}>
                              <Table.Cell>
                                <Select
                                  placeholder="Adicionar Centro de Custo"
                                  name={[i]}
                                  onChange={this.alteracaoSelectCentroCusto}
                                  options={this.state.optionsCC}
                                  onKeyDown={this.pesquisaCentroCusto}
                                  isDisabled={r.centro_de_custo != null}
                                  defaultInputValue={
                                    r.centro_de_custo != null
                                      ? r.centro_de_custo.descricao
                                      : ""
                                  }
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <Select
                                  placeholder="Adicionar Elemento de Custo"
                                  name={[i]}
                                  onChange={this.alteracaoSelectElementoCusto}
                                  options={this.state.optionsEC}
                                  onKeyDown={this.pesquisaElementoCusto}
                                  isDisabled={r.elemento_de_custo != null}
                                  defaultInputValue={
                                    r.elemento_de_custo != null
                                      ? r.elemento_de_custo.descricao
                                      : ""
                                  }
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <FormControl
                                  placeholder=""
                                  aria-describedby="basic-addon1"
                                  id={"itens[" + [i] + "].valor"}
                                  name={"valorItem" + i}
                                  defaultValue={formatter.format(r.valor)}
                                  required
                                  className="dinheiro"
                                  onKeyUp={this.componentMoney}
                                  onBlur={this.diminuiTotal}
                                  onKeyDown={this.enter}
                                />
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                      <Divider section />
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col sm={12}>
                      <Message negative>
                        <Message.Header>
                          Liquidação com inconsistência
                        </Message.Header>
                        <p>
                          Dados incompletos, favor verificar liquidação data:{" "}
                          {format(parseISO(e.data_vencimento), "dd/MM/yyyy")} e
                          valor: {e.valor}{" "}
                        </p>
                      </Message>
                      <Divider section />
                    </Col>
                  </Row>
                )
              )}
              <Row>
                <Col sm={3}>
                  <Form.Label>Data Pagamento</Form.Label>
                  <FormControl
                    placeholder="Data"
                    type="date"
                    name="data_pagamento"
                    onChange={this.alteracao}
                    defaultValue={this.state.data_pagamento
                      .toString()
                      .substr(0, 10)}
                  />
                </Col>
                <Col sm={3}>
                  <Form.Label>Valor Total</Form.Label>
                  <FormControl
                    placeholder="Valor"
                    aria-describedby="basic-addon1"
                    name="valor_pagamento"
                    required
                    onChange={this.alteracao}
                    value={formatter.format(this.state.totalOriginal)}
                    readOnly
                  />
                </Col>
                <Col sm={3}>
                  <Form.Label>Valor Pagamento</Form.Label>
                  <FormControl
                    placeholder="Valor Pagamento"
                    aria-describedby="basic-addon1"
                    name="valor_pago"
                    id="valor_pago"
                    required="required"
                    onChange={this.alteracao}
                    className="dinheiro"
                    onKeyUp={this.componentMoney}
                    value={formatter.format(this.state.totalLiquidacoes)}
                    readOnly="readonly"
                  />
                </Col>
                <Col sm={3}>
                  <Form.Label>Tipo Pagamento</Form.Label>
                  <Form.Control
                    placeholder="Tipo"
                    as="select"
                    name="tipo_pagamento"
                    onChange={this.alteracao}
                    checked={this.state.selectTipo}
                    required
                  >
                    <option></option>
                    <option value="cheque">Cheque</option>
                    <option value="dda">DDA</option>
                    <option value="dinheiro">Dinheiro</option>
                    {/* <option value="ddaCheque">DDA + Cheque</option> */}
                  </Form.Control>
                </Col>
              </Row>
              <Row>
                <Col
                  sm={3}
                  hidden={
                    this.state.tipo_pagamento !== "dda" &&
                    this.state.tipo_pagamento !== "ddaCheque"
                  }
                >
                  <Form.Label>Conta DDA</Form.Label>
                  <Form.Control
                    placeholder="Conta"
                    as="select"
                    name="conta_bancaria"
                    onChange={this.alteracaoConta}
                    required={
                      this.state.tipo_pagamento === "dda" ||
                      this.state.tipo_pagamento === "ddaCheque"
                    }
                    value={this.state.conta_bancaria_id}
                  >
                    <option></option>
                    {this.state.conta_bancaria_lista.map((e) => (
                      <option value={e.id}>{e.conta}</option>
                    ))}
                  </Form.Control>
                </Col>

                <Col
                  sm={3}
                  hidden={
                    this.state.tipo_pagamento !== "cheque" &&
                    this.state.tipo_pagamento !== "ddaCheque"
                  }
                >
                  <Form.Label>Conta Cheque</Form.Label>
                  <Form.Control
                    placeholder="Conta"
                    as="select"
                    name="conta_bancaria"
                    onChange={this.alteracaoConta}
                    required={
                      this.state.tipo_pagamento === "cheque" ||
                      this.state.tipo_pagamento === "ddaCheque"
                    }
                    value={this.state.conta_bancaria_id}
                  >
                    <option></option>
                    {this.state.conta_bancaria_lista.map((e) => (
                      <option value={e.id}>{e.conta}</option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>

              <Row style={{ marginTop: 30 }}>
                <Col
                  md="auto"
                  hidden={
                    this.state.tipo_pagamento !== "cheque" &&
                    this.state.tipo_pagamento !== "ddaCheque"
                  }
                >
                  <Button
                    positive
                    icon="add"
                    size="tiny"
                    onClick={this.addItem}
                    type="button"
                    content={"Adicionar Cheque"}
                  ></Button>
                </Col>
              </Row>
              {this.state.lancamento_conta_itens.map((e, i) => (
                <Row
                  hidden={
                    this.state.tipo_pagamento !== "cheque" &&
                    this.state.tipo_pagamento !== "ddaCheque"
                  }
                >
                  <Col>
                    <Form.Label>Nº Cheque</Form.Label>
                    <Form.Control
                      placeholder="nº cheque"
                      id={[i]}
                      name={[i]}
                      onChange={(event) => this.alteracaoNumCheque(event, i)}
                      required={
                        this.state.tipo_pagamento === "cheque" ||
                        this.state.tipo_pagamento === "ddaCheque"
                      }
                    ></Form.Control>
                  </Col>
                  <Col sm={3}>
                    <Form.Label>Valor</Form.Label>
                    <Form.Control
                      placeholder="valor"
                      id={[i * 100]}
                      name={[i * 100]}
                      onChange={(event) => this.alteracaoValorCheque(event, i)}
                      className="dinheiro"
                      required={
                        this.state.tipo_pagamento === "cheque" ||
                        this.state.tipo_pagamento === "ddaCheque"
                      }
                    ></Form.Control>
                  </Col>
                  <Col sm={6}>
                    <Form.Label>Favorecido</Form.Label>
                    <Form.Control
                      placeholder="favorecido"
                      id={[i * 10000]}
                      name={[i * 10000]}
                      onChange={(event) => this.alteracaoFavorecido(event, i)}
                      required={
                        this.state.tipo_pagamento === "cheque" ||
                        this.state.tipo_pagamento === "ddaCheque"
                      }
                    ></Form.Control>
                  </Col>
                  {this.state.lancamento_conta_itens.length > 1 && (
                    <Col
                      md="auto"
                      style={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <Button
                        negative
                        icon="x"
                        size="tiny"
                        onClick={() => {
                          let lista = [...this.state.lancamento_conta_itens];
                          lista.splice(i, 1);
                          this.setState({
                            lancamento_conta_itens: lista,
                          });
                        }}
                        type="button"
                      ></Button>
                    </Col>
                  )}
                </Row>
              ))}
              {this.state.conta_bancaria !== null && (
                <Row>
                  <Col sm={6}>
                    <Form.Label>Banco/Agência</Form.Label>
                    <FormControl
                      placeholder="Banco/Agência"
                      aria-describedby="basic-addon1"
                      name="descricao"
                      id="descricao"
                      defaultValue={this.state.descricao}
                      disabled={true}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm={12}>
                  <Form.Label>Observação</Form.Label>
                  <FormControl
                    placeholder="Observação2"
                    aria-describedby="basic-addon1"
                    as="textarea"
                    name="observacao"
                    onChange={this.alteracao}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6} hidden>
                  <Form.Label>Centro de Custo</Form.Label>
                  <Select
                    placeholder="Adicionar Centro de Custo"
                    name="centro_de_custo"
                    onChange={this.alteracaoSelectCentroCusto}
                    options={this.state.optionsCC}
                    onKeyDown={this.pesquisaCentroCusto}
                    inputId="pesquisaCentroCusto"
                    value={
                      this.state.contasAPagar != null &&
                      this.state.contasAPagar.centro_de_custo
                    }
                  />
                </Col>
                <Col sm={6} hidden>
                  <Form.Label>Elemento de Custo</Form.Label>
                  <Select
                    placeholder="Adicionar Elemento de Custo"
                    name="elemento_de_custo"
                    onChange={this.alteracaoSelectElementoCusto}
                    options={this.state.optionsEC}
                    onKeyDown={this.pesquisaElementoCusto}
                    inputId="pesquisaElementoCusto"
                  />
                </Col>
              </Row>
              <Segment clearing>
                <Header floated="right">
                  <Button
                    variant="light"
                    onClick={() => this.setState({ modalLiquidar: false })}
                    style={{ marginLeft: "10px" }}
                  >
                    Voltar
                  </Button>
                  <Button
                    positive
                    loading={this.state.salvando}
                    disabled={this.state.salvando}
                  >
                    Liquidar
                  </Button>
                </Header>
              </Segment>
            </Form>
          </Modal.Content>
        </Modal>
        <Modal
          open={this.state.modalInconsistencia}
          onClose={() => this.setState({ modalInconsistencia: false })}
          centered={false}
        >
          <Header content="Erro!" />
          <Modal.Content>
            <Row>
              <Col sm={12}>
                <Message negative>
                  <Message.Header>Liquidação com inconsistência</Message.Header>
                  <p>
                    Dados incompletos, favor verificar liquidação data:{" "}
                    {this.state.liquidacaoErro != null
                      ? format(
                          parseISO(this.state.liquidacaoErro.data_vencimento),
                          "dd/MM/yyyy"
                        ) +
                        " e valor: " +
                        this.state.liquidacaoErro.valor
                      : ""}
                  </p>
                </Message>
                <Divider section />
              </Col>
            </Row>
            <Header floated="right">
              <Button
                variant="light"
                onClick={() => this.setState({ modalInconsistencia: false })}
                style={{ marginLeft: "10px", marginBottom: "10px" }}
              >
                Fechar
              </Button>
            </Header>
          </Modal.Content>
        </Modal>
        <ModalAvisoExportacaoGerada
          open={this.state.modalAvisoExportacaoGeradaOpen}
          onClose={() =>
            this.setState({ modalAvisoExportacaoGeradaOpen: false })
          }
        />
      </div>
    );
  }
}
