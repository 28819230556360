import React, { Component } from "react";
import { Header, Table, Modal, Button } from "semantic-ui-react";
import { AiOutlineUnorderedList } from "react-icons/ai";
import api from "../../../services/api";
import { parseISO, format } from "date-fns";
import { IconContext } from "react-icons";
import calculoLancamentoCaixa from "../../../utils/calculoLancamentosCaixa";
import TipoLancamento from "../../../enum/TipoLancamento";
import { Col } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import { DeleteForever } from "@material-ui/icons/";
import requisicoes from "../../../utils/Requisicoes";
import { IconButton } from "@material-ui/core";

export default class CaixaRelatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caixas: [],
      caixa: null,
      open: false,
      caixaMovimento: null,
      lancamento_contas: [],
      activePages: 1,
    };
    this.buscarCaixas();
  }
  handleClose = () => {
    this.setState({ open: false });
  };

  handleShow = () => this.setState({ open: true });

  proximaPagina = async () => {
    const { pagina, totalPaginas } = this.state;

    if (pagina >= totalPaginas) return;
    const paginaNumero = parseInt(pagina) + 1;
    this.lista(paginaNumero);
  };

  voltarPagina = async () => {
    const { pagina } = this.state;

    if (pagina <= 1) return;

    const paginaNumero = pagina - 1;

    this.lista(paginaNumero);
  };
  gerarRelatorio = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const usuarioCaixa = await api.post(
      "/usuario/visualiza?e=" + entidade,
      { id: this.state.caixa.usuario_id },
      requisicoes.header
    );
    const nomeCaixa = await api.post(
      "/pessoa/pessoaIdNome?e=" + entidade,
      { id: usuarioCaixa.data.pessoa_id },
      requisicoes.header
    );
    await api.post(
      "/caixa/relatorio" + requisicoes.entidade,
      { id: this.state.caixa.id, nome_operador: nomeCaixa.data.nome },
      requisicoes.header
    );
    const r = await api.get(
      "/caixa/arquivoRelatorio?e=" + entidade + "&t=Baer " + token
    );
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "caixa/arquivoRelatorio?e=" +
      entidade +
      "&t=Baer " +
      token;

    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };
  mostraLancamentos = async (event) => {
    this.state.caixaMovimento = event.target.id.toString().substring(1, 10);
    const lancamentos = this.state.caixas.find(
      (e) =>
        parseInt(e.id) === parseInt(event.target.id.toString().substring(1, 10))
    );
    if (
      lancamentos !== undefined &&
      lancamentos.lancamento_contas !== undefined
    ) {
      this.setState({ caixa: lancamentos });
      let ids = [];
      lancamentos.lancamento_contas.forEach((e) => {
        ids.push(e.id);
      });
      const retorno = await api.post(
        "/lancamentoConta/ordenaPorHoraBaixa" + requisicoes.entidade,
        { ids: ids },
        requisicoes.header
      );
      let total = 0;
      if (retorno.data !== "") {
        this.setState({ caixa: lancamentos });
        retorno.data.forEach((e) => {
          total = calculoLancamentoCaixa(e, total);
        });
      }
      this.setState({ saldo_total: total });
      this.setState({ lancamento_contas: retorno.data });
      this.handleShow();
    }
  };
  buscarCaixas = async (activePages = 1) => {
    let retorno = "";
    if (this.props.location.query !== undefined) {
      retorno = await api.post(
        "/caixa/consultaListaCaixaSelecionado" + requisicoes.entidade,
        {
          tipo: "caixa",
          codigoUsuario: this.props.location.query.id,
          activePages: activePages,
        },
        requisicoes.header
      );
      let total = 0;
      let movimento = 0;
      retorno.data.docs.forEach((element) => {
        movimento = 0;
        element.lancamento_contas.forEach((e) => {
          total = calculoLancamentoCaixa(e, total);
          if (
            e.tipo_lancamento !== "saldoanterior" &&
            e.tipo_lancamento !== "sangria" &&
            e.tipo_lancamento !== "suprimento" &&
            e.tipo_lancamento !== "transferencia"
          ) {
            movimento += parseFloat(e.valor_pago);
          }
        });
        element.total = total;
        element.movimento = movimento;
        total = 0;
      });
      this.setState({
        caixas: retorno.data.docs,
        activePages: activePages,
        totalPaginas: retorno.data.page,
      });
    }
  };
  deletaLancamento = async (event) => {
    let lancamento_contas = this.state.lancamento_contas;
    let movimentoCaixa = this.state.caixas;
    const retorno = await api.post(
      "/caixaController/delete" + requisicoes.entidade,
      { id: event },
      requisicoes.header
    );
    retorno.data.forEach((element) => {
      const indiceExcluir = lancamento_contas.findIndex(
        (item) => item.id === element.lancamento_conta_id
      );
      if (indiceExcluir !== -1) {
        lancamento_contas.splice(indiceExcluir, 1);
      }
      movimentoCaixa = movimentoCaixa.map((cxms) => {
        if (cxms.id === parseInt(this.state.caixaMovimento)) {
          const indiceExcluirMovimento = cxms.lancamento_contas.findIndex(
            (item) => item.id === element.lancamento_conta_id
          );
          if (indiceExcluirMovimento !== -1) {
            cxms.lancamento_contas.splice(indiceExcluirMovimento, 1);
          }
        }
        return cxms;
      });
    });
    this.buscarCaixas();
    this.setState({
      lancamento_contas: lancamento_contas,
    });
  };
  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Perfis
        </Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Data Abertura</Table.HeaderCell>
              <Table.HeaderCell>Data Fechamento</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Total</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                Movimento do Dia
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.caixas.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>
                  {e.data_abertura != null &&
                    format(parseISO(e.data_abertura), "dd/MM/yyyy")}
                </Table.Cell>
                <Table.Cell>
                  {e.data_fechamento != null &&
                    format(parseISO(e.data_fechamento), "dd/MM/yyyy")}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {e.total != null &&
                    parseFloat(e.total).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {e.movimento != null &&
                    parseFloat(e.movimento).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </Table.Cell>
                <Table.Cell>
                  <IconContext.Provider value={{ color: "blue", size: "30px" }}>
                    <div>
                      <AiOutlineUnorderedList
                        id={"l" + e.id}
                        onClick={this.mostraLancamentos}
                      />
                    </div>
                  </IconContext.Provider>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>

          <Table.Footer sm={12}>
            <Table.Row>
              <Table.HeaderCell colSpan={6}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Pagination
                    count={this.state.totalPaginas}
                    variant="outlined"
                    shape="rounded"
                    boundaryCount={1}
                    page={this.state.activePages}
                    onChange={(e, a) => {
                      if (a) this.buscarCaixas(a);
                    }}
                  />
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          centered={false}
        >
          <Header content="Lancamentos" />
          <Modal.Content>
            <Button primary onClick={this.gerarRelatorio}>
              Imprimir
            </Button>
            {this.state.lancamento_contas !== null && (
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Data Pagamento</Table.HeaderCell>
                    <Table.HeaderCell>Pessoa</Table.HeaderCell>
                    <Table.HeaderCell>Data Vencimento</Table.HeaderCell>
                    <Table.HeaderCell>Descrição</Table.HeaderCell>
                    <Table.HeaderCell>Hora</Table.HeaderCell>
                    <Table.HeaderCell>Valor Pago</Table.HeaderCell>
                    <Table.HeaderCell>Excluir</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.lancamento_contas !== null &&
                    this.state.lancamento_contas.length !== 0 &&
                    this.state.lancamento_contas.map((e, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>
                          {e.data_pagamento !== null &&
                            format(parseISO(e.data_pagamento), "dd/MM/yyyy")}
                        </Table.Cell>
                        <Table.Cell>
                          {e.pessoa !== null && e.pessoa.nome}
                        </Table.Cell>
                        <Table.Cell>
                          {e.data_vencimento !== null
                            ? format(parseISO(e.data_vencimento), "dd/MM/yyyy")
                            : ""}
                        </Table.Cell>
                        {e.tipo_lancamento === "cobrancaServicoAvulsa" ? (
                          <Table.Cell>
                            {e.servico !== null && e.servico.nome}
                          </Table.Cell>
                        ) : (
                          <Table.Cell>
                            {e.descricao !== null &&
                              (e.descricao.toString().length > 20 ||
                              e.tipo_lancamento === "sangria"
                                ? TipoLancamento.find(
                                    (t) => t.value === e.tipo_lancamento
                                  ) !== undefined &&
                                  TipoLancamento.find(
                                    (t2) => t2.value === e.tipo_lancamento
                                  ).label
                                : e.descricao)}
                          </Table.Cell>
                        )}
                        <Table.Cell>
                          {e.hora_baixa !== null &&
                            e.hora_baixa.toString().substring(0, 5)}
                        </Table.Cell>
                        <Table.Cell>
                          {e.valor_pago !== null
                            ? parseFloat(e.valor_pago).toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : parseFloat(e.valor).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </Table.Cell>
                        <Table.Cell>
                          {/* // Todos: tem que fazer teste antes de liberar */}
                          {/*//todos:  cartao_debito cartao_credito */}
                          {console.log("forma_pagamento",e.forma_pagamento)}
                          {localStorage.getItem("tipo_usuario_clube") ===
                            "administrador" &&
                          e.tipo_lancamento !== "sangria" &&
                          e.forma_pagamento === "pix" ? (
                            <IconButton
                              onClick={() => {
                                this.deletaLancamento(e.id);
                              }}
                            >
                              <DeleteForever color="error" />{" "}
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => {
                                this.deletaLancamento(e.id);
                              }}
                              disabled
                            >
                              <DeleteForever color="grey" />{" "}
                            </IconButton>
                          )}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            )}
            <Col sm={2} style={{ float: "right", marginBottom: "10px" }}>
              Saldo:&nbsp;&nbsp;{" "}
              {parseFloat(this.state.saldo_total).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Col>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
