import React, { Component } from "react";
import {
  Container,
  Table,
  Header,
  Modal,
  Button,
  Divider,
  Card,
} from "semantic-ui-react";
import { Col, Form, FormControl, Row } from "react-bootstrap";
import moment from "moment-timezone";
import { Link } from "react-router-dom";

export default class ModalMatriculas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anoMatricula: moment().get("year"),
    };
  }

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <Card.Content
        style={{
          maxHeight: 250,
          flex: 1,
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Card.Header>Matriculas</Card.Header>
        {/* <Card.Meta>Friends of Elliot</Card.Meta> */}
        <Card.Description>
          <Row>
            {/* <Col sm={9}><Header as="h2">Matrículas</Header></Col> */}
            <Col md="auto">
              <Form.Control
                placeholder="Ano"
                label="Ano"
                id="anoMatricula"
                as="select"
                name="anoMatricula"
                onChange={this.alteracao}
                defaultValue={this.state.anoMatricula}
                required
              >
                {this.props.lista_anos.map((e, i) => (
                  <option value={e}>{e}</option>
                ))}
              </Form.Control>
            </Col>

            {localStorage.getItem("tipo_usuario_clube") !==
              "acessoTemporario" && (
              <Col md="auto">
                <Link
                  to={{
                    pathname: "/reservas",
                    query: {
                      select: { value: "venda", label: "Vendas" },
                      pessoa: this.state.pessoa,
                    },
                  }}
                  style={{
                    marginLeft: "1%",
                    paddingTop: "0px",
                    marginTop: "0px",
                  }}
                >
                  <Button color="green" icon={"add"}></Button>
                </Link>
              </Col>
            )}
          </Row>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Data Matricula</Table.HeaderCell>
                <Table.HeaderCell>Turma</Table.HeaderCell>
                <Table.HeaderCell>Situação Matricula</Table.HeaderCell>
                <Table.HeaderCell>Data Cancelamento</Table.HeaderCell>
                {(localStorage.getItem("tipo_usuario_clube") ===
                  "administrador" ||
                  localStorage.getItem("tipo_usuario_clube") ===
                    "administrativo" ||
                  localStorage.getItem("tipo_usuario_clube") ===
                    "administradorSistema" ||
                  localStorage.getItem("tipo_usuario_clube") === "caixa" ||
                  localStorage.getItem("tipo_usuario_clube") ===
                    "modalidade") && (
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.matriculas.map((e, i) => {
                return (
                  <>
                    {moment(e.dataMatricula).get("year") ===
                      parseInt(this.state.anoMatricula) && (
                      <Table.Row key={i}>
                        <Table.Cell>
                          {e.dataMatricula !== null &&
                            moment(e.dataMatricula).format("DD/MM/YYYY")}
                        </Table.Cell>
                        <Table.Cell>
                          {e.turma_servico !== null && e.turma_servico.nome}
                        </Table.Cell>
                        <Table.Cell>
                          {e.situacaoMatricula !== null && e.situacaoMatricula}
                        </Table.Cell>
                        <Table.Cell>
                          {e.dataCancelamento !== null &&
                            moment(e.dataCancelamento).format("DD/MM/YYYY")}
                        </Table.Cell>
                        {(localStorage.getItem("tipo_usuario_clube") ===
                          "administrador" ||
                          localStorage.getItem("tipo_usuario_clube") ===
                            "administrativo" ||
                          localStorage.getItem("tipo_usuario_clube") ===
                            "administradorSistema" ||
                          localStorage.getItem("tipo_usuario_clube") ===
                            "caixa" ||
                          localStorage.getItem("tipo_usuario_clube") ===
                            "modalidade") && (
                          <Table.Cell
                            width={1}
                            style={{ paddingTop: 7, paddingBottom: 7 }}
                          >
                            {e.dataCancelamento == null && (
                              <Modal
                                size="small"
                                trigger={
                                  <Button
                                    circular
                                    icon="remove"
                                    negative
                                    size="tiny"
                                  ></Button>
                                }
                              >
                                <Modal.Content>
                                  <Container textAlign="center">
                                    <Col sm={12}>
                                      <Form.Label>Motivo </Form.Label>
                                      <FormControl
                                        aria-label="Motivo"
                                        aria-describedby="basic-addon1"
                                        name="motivo_cancelamento"
                                        onChange={this.props.alteracao}
                                      />
                                    </Col>
                                    <br />
                                    Confirma Cancelamento?
                                    <br />
                                    <br />
                                    <Button
                                      primary
                                      size="tiny"
                                      type="button"
                                      value={e.id}
                                      onClick={this.props.cancelarMatricula}
                                    >
                                      Sim
                                    </Button>
                                  </Container>
                                </Modal.Content>
                              </Modal>
                            )}
                          </Table.Cell>
                        )}
                      </Table.Row>
                    )}
                  </>
                );
              })}
            </Table.Body>
          </Table>
        </Card.Description>
      </Card.Content>
    );
  }
}
