import React, { Component } from "react";
import ReactSelect from "react-select";
import { Table, Button, Checkbox } from "semantic-ui-react";
import { Row, Col, Form } from "react-bootstrap";
import {
  Select,
  FormControl,
  Snackbar,
  Alert,
  OutlinedInput,
  MenuItem,
  InputLabel
} from "@material-ui/core/";
import api from "../../../services/api";
import renomearChave from "../../../services/renomearChave";
import requisicoes from "../../../utils/Requisicoes";
import moment from "moment-timezone";
import { valorFormatter } from "../../../utils/formatterValor";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo: "pagas",
      data_inicio: null,
      data_final: null,
      lista_lancamentos: [],
      lista_servicos: [],
      imprimindo: false,
      totaisServicos: {},
      tipo_busca: false,
      listaInstituicao: [],
      bancoSelect: null,
      banco_value: null,
      banco_id: null,
      lista_debitoConta: [],
      servicoSelect: null,
      servico_value: null,
      servico_id: null,
      servico: null,
      detalhes: null,
      tipo_relatorio: "simplificado",
      tela_usada: "todas",
      optionsSelectBuscaServicos: [],
      optionsBuscaServicos: [],
      parcelas: "mensal",
      servico_selecionado: [],
      servicos_options: [],
      mensagemSnackBar: { show: false },
      listaGrupoServico: [],
      listaServico: [],
      grupoServico: 0,
      buscando: false,
      buscandoExcel: false,
      mes_referencia: null,
      ano_referencia: null,
      listaAnos: [],
      listaMeses: [
        { label: "Janeiro", value: 0 },
        { label: "Fevereiro", value: 1 },
        { label: "Março", value: 2 },
        { label: "Abril", value: 3 },
        { label: "Maio", value: 4 },
        { label: "Junho", value: 5 },
        { label: "Julho", value: 6 },
        { label: "Agosto", value: 7 },
        { label: "Setembro", value: 8 },
        { label: "Outubro", value: 9 },
        { label: "Novembro", value: 10 },
        { label: "Dezembro", value: 11 },
      ],
    };
    // this.selectBuscaServicos();
    this.selectBuscaGrupoServico();
    this.selectBuscaInstituicaoCobranca();
  }

  getYears = async () => {
    try {
      let currentYear = new Date().getFullYear();
      let currentMonth = new Date().getMonth();
      let current_mes_referencia = this.state.listaMeses.find(
        (m) => m.value === currentMonth
      );
      let startYear = parseInt(currentYear - 5);

      let lista = [];
      for (let i = startYear; i <= currentYear + 3; i++) {
        let y = parseInt(startYear++);
        lista.push({ label: "" + y, value: y });
      }

      this.setState({
        listaAnos: lista,
        ano_referencia: { label: "" + currentYear, value: currentYear },
        mes_referencia: current_mes_referencia,
      });
    } catch (Exception) {
      console.log("GetYears.Exception: " + Exception);
    }
  };

  selectBuscaGrupoServico = async () => {
    let lista = [];
    //Options instituição cobrança

    lista.push({ label: "Todos", value: 0 });
    const listaGrupoServico = await api.post(
      "/grupoServico/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    listaGrupoServico.data.forEach((element) => {
      element.value = element.id;
      element.label = element.descricao;
      lista.push(element);
    });
    this.setState({ listaGrupoServico: lista });
  };

  selectBuscaInstituicaoCobranca = async () => {
    let lista = [];
    //Options instituição cobrança
    const listaInstituicao = await api.post(
      "/instituicaoCobranca/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    listaInstituicao.data.forEach((element) => {
      let objeto = renomearChave(element, "banco_id", "value");
      objeto = renomearChave(objeto, "descricao", "label");
      lista.push(objeto);
    });

    this.setState({ listaInstituicao: lista });
  };

  selectBuscaServicos = async (grupo_servico_id) => {
    //Options dos servicos
    this.state.servico_selecionado = []
    const retornoservicos = await api.post(
      "/servico/servicoPorGrupoServicoComDiversos" + requisicoes.entidade,
      {
        id: grupo_servico_id,
        turmas: false,
      },
      requisicoes.header
    );
    retornoservicos.data.sort(function (a, b) {
      if (a.nome > b.nome) {
        return 1;
      }
      if (a.nome < b.nome) {
        return -1;
      }
      return 0;
    });
    let lista = [];
    let lista2 = [];
    // lista.push({
    //   value: 0,
    //   label: "Todos",
    //   selected: false,
    // });
    // lista2.push({
    //   value: 0,
    //   label: "Todos",
    //   selected: false,
    // });
    for (let i = 0; i < retornoservicos.data.length; i++) {
      const element = retornoservicos.data[i];
      if (
        element.turmas === true &&
        element.dataInicio === null &&
        element.dataFinal === null
      ) {
        continue;
      }
      let objeto2 = {
        value: element.id,
        label: element.nome,
        selected: false,
      };

      let objeto = renomearChave(element, "id", "value");
      objeto = renomearChave(objeto, "nome", "label");
      if (element.turmas === true) {
        objeto.label += " - " + moment(element.dataInicio).year();
      }
      objeto.dataInicio = element.dataInicio;
      objeto.dataFinal = element.dataFinal;
      objeto.turmas = element.turmas;
      lista2.push(objeto2);
      lista.push(objeto);
    }
    this.setState({
      optionsBuscaServicos: lista,
      optionsSelectBuscaServicos: lista,
      servicos_options: lista2,
      listaServico: lista2
    });
  };

  alteracao = async (event, value) => {
    this.setState({ [event.target.name]: event.target.value });

    if (event.target.value === "competencia") {
      this.getYears();
    }

    if (
      event.target.name === "data_inicio" ||
      event.target.name === "data_final"
    ) {
      let lista = [];
      for (let i = 0; i < this.state.optionsBuscaServicos.length; i++) {
        const element = this.state.optionsBuscaServicos[i];
        let data_inicio =
          event.target.name === "data_inicio"
            ? event.target.value
            : this.state.data_inicio;
        let data_final =
          event.target.name === "data_final"
            ? event.target.value
            : this.state.data_final;
        if (
          (element.turmas === true &&
            moment(element.dataInicio).year() >= moment(data_inicio).year() &&
            moment(element.dataInicio).year() <= moment(data_final).year()) ||
          element.turmas === false
        ) {
          lista.push(element);
        }
      }
      this.setState({ optionsSelectBuscaServicos: lista });
    }

    this.setState({ lista_servicos: [] });
    this.setState({ lista_debitoConta: [] });
    //this.setState({ optionsSelectBuscaServicos: [] });
  };

  alteracaoSelectBanco = (e) => {
    this.setState({ bancoSelect: e });
    this.setState({ banco_value: e });
    this.setState({ banco_id: e.banco.cod });
  };

  alteracaoSelectServicos = (e) => {
    this.setState({ servicoSelect: e });
    this.setState({ servico_value: e });
    this.setState({ servico_id: e.value });
    //this.setState({ optionsSelectBuscaServicos: [] });
    this.setState({ servico: e.value });
  };

  buscaServicos = async (event) => { // essa aqui é onde chama a primeira select lá onde escolhe o tipo?
    this.state.buscando = true;
    this.setState({ buscando: true });
    // Buscar todos os lancamentos contas no intervalo,
    // agrupado pelo servico id com join com servico

    if (this.state.tipo === "debito") {
      const retornoDebitoConta = await api.post(
        "/lancamentoConta/RelatorioDebitoConta" + requisicoes.entidade,
        {
          data_inicio: this.state.data_inicio,
          data_final: this.state.data_final,
          banco: this.state.banco_id,
        },
        requisicoes.header
      );

      this.setState({ lista_debitoConta: retornoDebitoConta });
    } else {
      try {
        // let listaServicoId = this.state.servicos_options
        // .filter((e) => e.selected === true)
        // .map((e) => e.value)
        const totalServicos = await api.post(
          "/lancamentoConta/lancamentosPorDataTotalServico" +
          requisicoes.entidade,
          {
            data_inicio: this.state.data_inicio,
            data_final: this.state.data_final,
            mes_referencia:
              this.state.mes_referencia != null &&
                this.state.tipo === "competencia"
                ? this.state.mes_referencia.label
                : null,
            ano_referencia:
              this.state.ano_referencia != null &&
                this.state.tipo === "competencia"
                ? this.state.ano_referencia.value
                : null,
            tipo: this.state.tipo,
            // servico: this.state.servico,
            servico: this.state.servico_selecionado,

            grupoServico: parseInt(this.state.grupoServico),
            tipo_relatorio: this.state.tipo_relatorio,

            parcelas: this.state.parcelas,
            tela_usada:
              this.state.tipo === "canceladas"
                ? this.state.tela_usada
                : undefined,
          },
          requisicoes.header
        );

        let totaisServicos = {
          quantidade: 0,
          total_divida: 0.0,
          total_desconto: 0.0,
          total_juros_multa: 0.0,
          total_pago: 0.0,
          total_em_aberto: 0.0,
          total_valor_lancamentos_pagos: 0.0,
        };

        let totaisServicosDetalhado = {
          quantidade: 0,
          total_divida: 0,
          total_juros: 0,
          total_multa: 0,
          total_desconto: 0,
          total_valor_pago: 0,
        };
        if (this.state.tipo_relatorio === "simplificado") {
          for (let i = 0; i < totalServicos.data.length; i++) {
            const element = totalServicos.data[i];
            totaisServicos.quantidade += parseInt(element.quantidade);
            if (element.total_divida !== null) {
              totaisServicos.total_divida =
                parseFloat(element.total_divida) + totaisServicos.total_divida;
            }
            if (element.total_desconto !== null) {
              let desconto = element.total_desconto;
              if (desconto < 0) {
                desconto = desconto * -1;
              }
              totaisServicos.total_desconto += parseFloat(desconto);
              //totaisServicos.total_desconto += parseFloat(element.total_desconto);
            }
            if (element.total_pago !== null) {
              totaisServicos.total_pago += parseFloat(
                parseFloat(element.total_pago).toFixed(2)
              );
            }
            if (element.total_valor_atraso !== null) {
              totaisServicos.total_juros_multa += parseFloat(
                element.total_valor_atraso
              );
            }
            if (element.total_valor_mora !== null) {
              totaisServicos.total_juros_multa += parseFloat(
                element.total_valor_mora
              );
            }
            if (element.total_em_aberto !== null) {
              totaisServicos.total_em_aberto += parseFloat(
                element.total_em_aberto
              );
            }
            if (element.total_valor_lancamentos_pagos !== null) {
              totaisServicos.total_valor_lancamentos_pagos += parseFloat(
                element.total_valor_lancamentos_pagos
              );
            }
          }
        } else {
          totaisServicosDetalhado.quantidade = totalServicos.data.length;
          for (let i = 0; i < totalServicos.data.length; i++) {
            const element = totalServicos.data[i];
            if (!isNaN(element.valor)) {
              totaisServicosDetalhado.total_divida +=
                element.valor !== null ? parseFloat(element.valor) : 0;
            }
            totaisServicosDetalhado.total_juros +=
              element.valor_atraso !== null
                ? parseFloat(element.valor_atraso)
                : 0;

            totaisServicosDetalhado.total_multa +=
              element.valor_mora !== null ? parseFloat(element.valor_mora) : 0;

            totaisServicosDetalhado.total_desconto +=
              element.desconto !== null ? parseFloat(element.desconto) : 0;

            totaisServicosDetalhado.total_valor_pago +=
              element.valor_pago !== null
                ? parseFloat(parseFloat(element.valor_pago).toFixed(2))
                : 0;

            if (
              element.desconto != null &&
              element.valor_pago != null &&
              element.valor != null &&
              parseFloat(element.valor_pago) > parseFloat(element.valor) &&
              parseFloat(element.valor_pago) - parseFloat(element.desconto) >
              parseFloat(element.valor) &&
              (element.valor_atraso == null ||
                parseFloat(element.valor_atraso) === 0) &&
              (element.total_multa == null ||
                parseFloat(element.total_multa) === 0)
            ) {
              let newVal =
                element.valor_pago -
                parseFloat(element.desconto) *
                (parseFloat(element.desconto) > 0 ? 1 : -1) *
                2;

              totalServicos.data[i].valor_pago = newVal;
            }
          }
        }

        //Ordena os servicos/lancamentos
        // if(this.state.tipo === "emAberto"){
        if (totalServicos.data.length > 0)
          totalServicos.data.sort(function (a, b) {
            return b.total_divida - a.total_divida;
          });
        // } else {
        //   totalServicos.data.sort(function (a, b) {
        //     return b.total_pago - a.total_pago;
        //   });
        // }
        this.state.buscando = false;
        this.setState({ buscando: false });
        this.setState({
          totaisServicosDetalhado,
          totaisServicos: totaisServicos,
          lista_servicos: totalServicos.data,
        });
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  verificaDatas = async () => {
    if (!moment(this.state.data_inicio, "YYYY-MM-DD", true).isValid()) {
      await this.setState({
        data_inicio: moment().startOf("month").format("YYYY-MM-DD"),
      });
    }
    if (!moment(this.state.data_final, "YYYY-MM-DD", true).isValid()) {
      await this.setState({ data_final: moment().format("YYYY-MM-DD") });
    }
  };

  pesquisaServicos = async (event) => {
    await this.verificaDatas();
    if (
      Object.values(this.state.servico_selecionado).every(
        (valor) => valor.length === 0
      )
    ) {
      this.mensagem(false, "Selecione um serviço", 4500);
    } else {
      await this.buscaServicos();
    }
  };

  mensagem = (positive, content, duration) => {
    this.setState({
      mensagemSnackBar: {
        show: true,
        positive: positive,
        content: content,
        duration: duration || 4000,
      },
    });
  };

  gerarRelatorio = async (event) => {
    let localStorage = window.localStorage;
    let token = localStorage.getItem("token_clube");
    let entidade = localStorage.getItem("e_clube");

    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado?e=" + entidade,
      {},
      {
        headers: { token: "Baer " + token },
      }
    );
    const nomePessoa = await api.post(
      "/pessoa/pessoaIdNome?e=" + entidade,
      {
        id: usuarioLogado.data.pessoa_id,
      },
      {
        headers: { token: "Baer " + token },
      }
    );

    await api.post(
      "/lancamentoConta/relatorioServico?e=" + entidade,
      {
        userId: usuarioLogado.data.pessoa_id,
        nome_operador: nomePessoa.data.nome,
        tipo: this.state.tipo,
        data_inicio: this.state.data_inicio,
        data_final: this.state.data_final,
        mes_referencia:
          this.state.mes_referencia != null && this.state.tipo === "competencia"
            ? this.state.mes_referencia.label
            : null,
        ano_referencia:
          this.state.ano_referencia != null && this.state.tipo === "competencia"
            ? this.state.ano_referencia.value
            : null,
        totaisServicos: this.state.totaisServicos,
        parcelas: this.state.parcelas,
        grupoServico: this.state.grupoServico,
        servico: this.state.servico_selecionado,
      },
      {
        headers: { token: "Baer " + token },
      }
    );

    const r = await api.get(
      "/lancamentoConta/arquivoRelatorioServico?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.pessoa_id
    );
    let oReq = new XMLHttpRequest();

    let URLToPDF =
      r.config.baseURL +
      "lancamentoConta/arquivoRelatorioServico?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.pessoa_id;

    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  imprimirButtonOnClick = () => {
    this.setState({ imprimindo: true });
    this.gerarRelatorio().then(() => this.setState({ imprimindo: false }));
  };

  imprimirPdfDebitoConta = async () => {
    this.setState({ imprimindo: true });
    await api.post(
      "/lancamentoConta/GerarPdfDebitoConta" + requisicoes.entidade,
      {
        data_inicio: this.state.data_inicio,
        data_final: this.state.data_final,
        banco: this.state.banco_id,
      },
      requisicoes.header
    );
    this.buscarPdfDebitoConta();
    this.setState({ imprimindo: false });
  };

  buscarPdfDebitoConta = async () => {
    let localStorage = window.localStorage;
    let token = localStorage.getItem("token_clube");
    let entidade = localStorage.getItem("e_clube");
    const r = await api.get(
      "/lancamentoConta/buscarPdfDebitoConta?e=" + entidade + "&t=Baer " + token
    );

    let oReq = new XMLHttpRequest();

    let URLToPDF =
      r.config.baseURL +
      "lancamentoConta/buscarPdfDebitoConta?e=" +
      entidade +
      "&t=Baer " +
      token;
    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = await new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };
  XSLXButtonClick = async () => {
    this.state.buscandoExcel = true;
    this.setState({ buscandoExcel: true });
    const relatorioXSLX = await api.post(
      "/lancamentoConta/xlsxRelatorioListaReceita" + requisicoes.entidade,
      {
        data_inicio: this.state.data_inicio,
        data_final: this.state.data_final,
        mes_referencia:
          this.state.mes_referencia != null && this.state.tipo === "competencia"
            ? this.state.mes_referencia.label
            : null,
        ano_referencia:
          this.state.ano_referencia != null && this.state.tipo === "competencia"
            ? this.state.ano_referencia.value
            : null,
        tipo: this.state.tipo,
        servico: this.state.servico_selecionado,

        grupoServico: parseInt(this.state.grupoServico),
        tipo_relatorio: this.state.tipo_relatorio,

        parcelas: this.state.parcelas,
      },
      {
        headers: {
          token: "Baer " + window.localStorage.getItem("token_clube"),
        },
        responseType: "arraybuffer", //Sem isso aqui não funciona o xlsx!!!
      }
    )
    const url = window.URL.createObjectURL(new Blob([relatorioXSLX.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `relatorioReceitas${moment()}.xlsx`);
    document.body.appendChild(link);
    link.click();
    this.state.buscandoExcel = false;
    this.setState({ buscandoExcel: false });
  }
  imprimirPdfDetalhado = async () => {
    this.setState({ imprimindo: true });
    let localStorage = window.localStorage;
    let token = localStorage.getItem("token_clube");
    let entidade = localStorage.getItem("e_clube");
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado?e=" + entidade,
      {},
      {
        headers: { token: "Baer " + token },
      }
    );

    await api.post(
      "/lancamentoConta/gerarPdfDetalhado?e=" + entidade,
      {
        data_inicio: this.state.data_inicio,
        data_final: this.state.data_final,
        mes_referencia:
          this.state.mes_referencia != null && this.state.tipo === "competencia"
            ? this.state.mes_referencia.label
            : null,
        ano_referencia:
          this.state.ano_referencia != null && this.state.tipo === "competencia"
            ? this.state.ano_referencia.value
            : null,
        tipo: this.state.tipo,
        servico: this.state.servico_selecionado,

        grupoServico: parseInt(this.state.grupoServico),
        tipo_relatorio: this.state.tipo_relatorio,

        parcelas: this.state.parcelas,
      },
      {
        headers: { token: "Baer " + token },
      }
    );

    const r = await api.get(
      "/lancamentoConta/buscaPdfDetalhado?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.pessoa_id
    );
    let oReq = new XMLHttpRequest();

    let URLToPDF =
      r.config.baseURL +
      "lancamentoConta/buscaPdfDetalhado?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.pessoa_id;

    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();

    this.setState({ imprimindo: false });
  };

  render() {
    return (
      <div>
        <Row
          style={{
            marginBottom: "10px",
            marginTop: "15px",
            marginLeft: 0,
            marginRight: 0,
            display: "flex",
          }}
        >
          <Button
            positive
            style={{ marginTop: "25px", marginLeft: "auto" }}
            onClick={this.pesquisaServicos}
            loading={this.state.buscando}
            disabled={this.state.buscando }

          >
            Buscar
          </Button>
          <Button.Group style={{ height: "37px", marginTop: '25px' }}>
            <Button
              color="blue"
              onClick={this.XSLXButtonClick}
              disabled={this.state.lista_servicos.length === 0 || this.state.buscandoExcel }
              loading={this.state.buscandoExcel}
              hidden={(
                this.state.tipo !== "pagas" &&
                this.state.tipo !== "vencidas" &&
                this.state.tipo !== "emAberto")
              }
            >
              XLSX
            </Button>
            <Button.Or text="ou" hidden={(
              this.state.tipo !== "pagas" &&
              this.state.tipo !== "vencidas" &&
              this.state.tipo !== "emAberto")
            } />
            <Button
              color="blue"
              onClick={this.imprimirPdfDetalhado}
              disabled={this.state.lista_servicos.length === 0 || this.state.imprimindo}
              loading={this.state.imprimindo}
              hidden={
                (this.state.tipo !== "pagas" &&
                  this.state.tipo !== "vencidas" &&
                  this.state.tipo !== "competencia" &&
                  this.state.tipo !== "emAberto" &&
                  this.state.tipo !== "canceladas") ||
                this.state.tipo_relatorio !== "detalhado"
              }
            >
              PDF
            </Button>
            <Button
              color="blue"
              loading={this.state.imprimindo}
              onClick={this.imprimirButtonOnClick}
              disabled={
                this.state.lista_servicos.length === 0 ||
                this.state.imprimindo === true
              }
              hidden={
                this.state.tipo === "debito" ||
                this.state.tipo_relatorio === "detalhado"
              }
            >
              PDF
            </Button>
          </Button.Group>

        </Row>
        <Row>
          <Col sm={2}>
            <Form.Label>Tipo de busca</Form.Label>
            <Form.Control
              as="select"
              name="tipo"
              id="tipo"
              value={this.state.tipo}
              required
              onChange={this.alteracao}
              style={{ height: "37px" }}
            >
              <option value="debito">Débito em conta</option>
              <option value="pagas">Pagos no intervalo</option>
              <option value="vencidas">Vence no intervalo</option>
              <option value="emAberto">Em aberto</option>
              <option value="canceladas">Canceladas</option>
              <option value="competencia">Competência</option>
            </Form.Control>
          </Col>

          <Col sm={2} hidden={this.state.tipo === "competencia"}>
            <Form.Label>Data Inicio</Form.Label>
            <Form.Control
              placeholder="Data Inicio"
              aria-describedby="basic-addon1"
              type="date"
              name="data_inicio"
              value={this.state.data_inicio}
              onChange={this.alteracao}
              style={{ height: "37px" }}
            />
          </Col>
          <Col sm={2} hidden={this.state.tipo === "competencia"}>
            <Form.Label>Data Final</Form.Label>
            <Form.Control
              placeholder="Data Final"
              aria-describedby="basic-addon1"
              type="date"
              name="data_final"
              value={this.state.data_final}
              onChange={this.alteracao}
              style={{ height: "37px" }}
            />
          </Col>

          <Col sm={2} hidden={this.state.tipo !== "competencia"}>
            <Form.Label>Mês de Competência</Form.Label>
            <Select
              as="select"
              name="mes_referencia"
              id="mes_referencia"
              defaultValue={this.state.mes_referencia}
              required
              onChange={(event) => {
                this.setState({
                  mes_referencia: event,
                });
              }}
              options={this.state.listaMeses}
            ></Select>
          </Col>
          <Col sm={2} hidden={this.state.tipo !== "competencia"}>
            <Form.Label>Ano de Competência</Form.Label>
            <Select
              as="select"
              name="ano_referencia"
              id="ano_referencia"
              defaultValue={this.state.ano_referencia}
              required
              onChange={(event) => {
                this.setState({ ano_referencia: event });
              }}
              options={this.state.listaAnos}
            ></Select>
          </Col>
          <Col
            hidden={
              this.state.tipo !== "pagas" &&
              this.state.tipo !== "vencidas" &&
              this.state.tipo !== "emAberto" &&
              this.state.tipo !== "canceladas" &&
              this.state.tipo !== "competencia"
            }
            style={{ marginRight: "5px" }}
          >
            <Form.Label>Grupo Serviço</Form.Label>
            <ReactSelect
              as="select"
              name="grupoServico"
              id="grupoServico"

              onKeyDown={this.selectBuscaServicos}
              onChange={(event) => {
                this.setState({ grupoServico: event.value });
                this.selectBuscaServicos(event.value);
              }}
              options={this.state.listaGrupoServico}
              style={{ height: "50px", width: "250px" }}
            />

          </Col>
          <Col
            hidden={
              this.state.tipo !== "pagas" &&
              this.state.tipo !== "vencidas" &&
              this.state.tipo !== "emAberto" &&
              this.state.tipo !== "canceladas" &&
              this.state.tipo !== "competencia"
            }
            style={{ marginLeft: "-1rem", marginRight: "-15px" }}
          >
            <FormControl sx={{ m: 1.5, width: 390 }} fullWidth>
              <InputLabel id="demo-multiple-name-label" style={{ marginTop: '10px' }} >Serviço</InputLabel>
              <Select
                style={{ maxHeight: "34px", marginTop: '17px' }}
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={this.state.servico_selecionado}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value.indexOf(0) !== -1) {
                    this.setState({
                      servico_selecionado: [
                        0,
                        ...this.state.servicos_options.map((e) => e.value),
                      ],
                    });
                  } else if (this.state.servico_selecionado.indexOf(0) !== -1) {
                    this.setState({
                      servico_selecionado: [],
                    });
                  } else {
                    this.setState({
                      servico_selecionado:
                        typeof value === "string" ? value.split(",") : value,
                    });
                  }
                }}

                MenuProps={{
                  ...MenuProps, getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
                input={<OutlinedInput label="Name" />}
              >
                {[{ value: 0, label: "Todos" }, ...this.state.servicos_options].map(
                  (e) => (
                    <MenuItem
                      key={e.value}
                      value={e.value}
                      style={
                        this.state.servico_selecionado.indexOf(e.value) === -1
                          ? {}
                          : { fontWeight: "bold" }
                      }
                    >
                      {e.label}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Col>
          <Col sm={2} hidden={this.state.tipo !== "debito"}>
            <Form.Label>Banco:</Form.Label>
            <Select
              name="banco"
              onChange={this.alteracaoSelectBanco}
              value={this.state.banco_value}
              options={this.state.listaInstituicao}
              disabled
              inputId="pesquisaInstituicao"
            />
          </Col>
        </Row>

        {this.state.tipo !== "debito" && (
          <Row style={{ paddingTop: 10 }}>
            <Col sm={2}>
              <Checkbox
                label="Parcelas mensais"
                checked={this.state.parcelas === "mensal"}
                onChange={() => this.setState({ parcelas: "mensal" })}
              />
            </Col>
            <Col sm={2}>
              <Checkbox
                label="Parcelas anuais"
                checked={this.state.parcelas === "anual"}
                onChange={() => this.setState({ parcelas: "anual" })}
              />
            </Col>
            <Col sm={2}>
              <Checkbox
                label="Todas"
                checked={this.state.parcelas === "todas"}
                onChange={() => this.setState({ parcelas: "todas" })}
              />
            </Col>
            {this.state.tipo === "canceladas" && (
              <Col sm={3} style={{ marginLeft: "auto" }}>
                <Form.Label>Tela utilizada</Form.Label>
                <Form.Control
                  as="select"
                  name="tela_usada"
                  value={this.state.tela_usada}
                  required
                  onChange={this.alteracao}
                >
                  <option value="todas">Todas</option>
                  <option value="cancelamento_titulo">
                    Cancelamento de título
                  </option>
                  <option value="transferencia_titularidade">
                    Transferência de titularidade
                  </option>
                  <option value="transferencia_tipo_titulo">
                    Transferência de tipo de título
                  </option>
                  <option value="gerenciar_dividas">Gerenciar dívidas</option>
                </Form.Control>
              </Col>
            )}

            <Col
              sm={2}
              style={
                this.state.tipo !== "canceladas" ? { marginLeft: "auto" } : {}
              }
            >
              <Form.Label>Tipo do Relatório</Form.Label>
              <Form.Control
                as="select"
                name="tipo_relatorio"
                checked={this.state.tipo}
                id="tipo_relatorio"
                defaultValue="vazio"
                required
                onChange={this.alteracao}
              >
                <option value="simplificado">Simplificado</option>
                <option value="detalhado">Detalhado</option>
              </Form.Control>
            </Col>
          </Row>
        )}

        {this.state.tipo_relatorio === "simplificado" && (
          <Table
            celled
            selectable
            hidden={
              this.state.tipo !== "pagas" &&
              this.state.tipo !== "vencidas" &&
              this.state.tipo !== "competencia" &&
              this.state.tipo !== "emAberto"
            }
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing>Quantidade</Table.HeaderCell>
                <Table.HeaderCell>Serviço</Table.HeaderCell>
                <Table.HeaderCell textAlign={"right"} collapsing>
                  Valor a receber
                </Table.HeaderCell>
                {(this.state.tipo === "vencidas" ||
                  this.state.tipo === "competencia") && (
                    <Table.HeaderCell textAlign={"right"} collapsing>
                      Valor a receber pago
                    </Table.HeaderCell>
                  )}
                {this.state.tipo !== "emAberto" && (
                  <>
                    <Table.HeaderCell textAlign={"right"} collapsing>
                      Juros/Multa
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"} width={1}>
                      {"Descontos(-)/\nAcréscimos(+)"}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"} collapsing>
                      Valor pago
                    </Table.HeaderCell>
                  </>
                )}
                {(this.state.tipo === "vencidas" ||
                  this.state.tipo === "competencia") && (
                    <>
                      <Table.HeaderCell textAlign={"right"} collapsing>
                        Em aberto
                      </Table.HeaderCell>
                    </>
                  )}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.state.lista_servicos.length === 0 ? (
                <Table.Row>
                  <Table.Cell colSpan={5}>
                    <div style={{ margin: "15px" }}>
                      <h4> Não há dados para mostrar. </h4>
                    </div>
                  </Table.Cell>
                </Table.Row>
              ) : (
                <>
                  {this.state.lista_servicos.map((e, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{e.quantidade}</Table.Cell>
                      <Table.Cell>
                        {e.servico ? e.servico.nome : e.servico_nome}
                      </Table.Cell>
                      <Table.Cell textAlign={"right"}>
                        {e.total_divida === null
                          ? valorFormatter.format(0.00)
                          : valorFormatter.format(e.total_divida)
                        }
                      </Table.Cell>
                      {this.state.tipo !== "emAberto" &&
                        this.state.tipo !== "pagas" && (
                          <Table.Cell textAlign={"right"}>
                            {e.total_valor_lancamentos_pagos === null
                              ? valorFormatter.format(0.00)
                              : valorFormatter.format(e.total_valor_lancamentos_pagos)
                            }
                          </Table.Cell>
                        )}
                      {this.state.tipo !== "emAberto" && (
                        <>
                          <Table.Cell textAlign={"right"}>
                            {e.total_valor_atraso === null &&
                              e.total_valor_mora === null
                              ? valorFormatter.format(0.00)
                              : e.total_valor_atraso === null
                                ? valorFormatter.format(e.total_valor_mora)
                                : e.total_valor_mora === null
                                  ? valorFormatter.format(e.total_valor_atraso)
                                  : valorFormatter.format(
                                    parseFloat(e.total_valor_atraso) +
                                    parseFloat(e.total_valor_mora)
                                  )
                            }
                          </Table.Cell>
                          <Table.Cell textAlign={"right"}>
                            {e.total_desconto === null || e.total_desconto === "0.00"
                              ? valorFormatter.format(0.00)
                              : valorFormatter.format(e.total_desconto * -1)
                            }
                          </Table.Cell>
                          <Table.Cell textAlign={"right"}>
                            {e.total_pago === null
                              ? valorFormatter.format(0.00)
                              : valorFormatter.format(e.total_pago)
                            }
                          </Table.Cell>
                        </>
                      )}
                      {(this.state.tipo === "vencidas" ||
                        this.state.tipo === "competencia") && (
                          <>
                            <Table.Cell textAlign={"right"}>
                              {e.total_em_aberto === null
                                ? valorFormatter.format(0.00)
                                : valorFormatter.format(e.total_em_aberto)
                              }
                            </Table.Cell>
                          </>
                        )}
                    </Table.Row>
                  ))}
                </>
              )}
            </Table.Body>
            {this.state.lista_servicos.length !== 0 && (
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell>
                    {<strong>{this.state.totaisServicos.quantidade}</strong>}
                  </Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell textAlign={"right"}>
                    {
                      <strong>
                        {valorFormatter.format(this.state.totaisServicos.total_divida)}
                      </strong>
                    }
                  </Table.HeaderCell>
                  {this.state.tipo !== "emAberto" &&
                    this.state.tipo !== "pagas" && (
                      <Table.HeaderCell textAlign={"right"}>
                        {
                          <strong>
                            {valorFormatter.format(this.state.totaisServicos.total_valor_lancamentos_pagos)}
                          </strong>
                        }
                      </Table.HeaderCell>
                    )}
                  {this.state.tipo !== "emAberto" && (
                    <>
                      <Table.HeaderCell textAlign={"right"}>
                        {
                          <strong>
                            {valorFormatter.format(this.state.totaisServicos.total_juros_multa)}
                          </strong>
                        }
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign={"right"}>
                        {
                          <strong>
                            {valorFormatter.format(this.state.totaisServicos.total_desconto * -1)}
                          </strong>
                        }
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign={"right"}>
                        {
                          <strong>
                            {valorFormatter.format(this.state.totaisServicos.total_pago)}
                          </strong>
                        }
                      </Table.HeaderCell>
                    </>
                  )}
                  {(this.state.tipo === "vencidas" ||
                    this.state.tipo === "competencia") && (
                      <>
                        <Table.HeaderCell textAlign={"right"}>
                          {
                            <strong>
                              {valorFormatter.format(this.state.totaisServicos.total_em_aberto)}
                            </strong>
                          }
                        </Table.HeaderCell>
                      </>
                    )}
                </Table.Row>
              </Table.Footer>
            )}
          </Table>
        )}

        <Table celled selectable hidden={this.state.tipo !== "debito"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1} collapsing>
                Título
              </Table.HeaderCell>
              <Table.HeaderCell width={4}>Nome</Table.HeaderCell>
              <Table.HeaderCell collapsing>CPF</Table.HeaderCell>
              <Table.HeaderCell width={3} textAlign={"left"} collapsing>
                Serviço
              </Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign={"left"} collapsing>
                Referente
              </Table.HeaderCell>

              <Table.HeaderCell width={1} textAlign={"left"}>
                Vencimento
              </Table.HeaderCell>
              <Table.HeaderCell textAlign={"right"} collapsing>
                Valor
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.lista_debitoConta.length === 0 ? (
              <Table.Row>
                <Table.Cell colSpan={5}>
                  <div style={{ margin: "15px" }}>
                    <h4> Não há dados para mostrar. </h4>
                  </div>
                </Table.Cell>
              </Table.Row>
            ) : (
              this.state.lista_debitoConta.data.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{e.acao}</Table.Cell>
                  <Table.Cell>{e.pessoa_nome}</Table.Cell>
                  <Table.Cell textAlign={"left"} collapsing>
                    {e.pessoa_cpf !== null ? e.pessoa_cpf : e.pessoa_cnpj}
                  </Table.Cell>
                  <Table.Cell textAlign={"left"}>{e.descricao}</Table.Cell>

                  <Table.Cell textAlign={"left"}>{e.mes_referencia}</Table.Cell>
                  <Table.Cell textAlign={"left"}>
                    {moment(e.data_vencimento).format("DD/MM/YYYY")}
                  </Table.Cell>

                  <Table.Cell textAlign={"right"}>
                    {"R$ " +
                      parseFloat(e.valor).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  </Table.Cell>
                </Table.Row>
              ))
            )}
          </Table.Body>
        </Table>

        {this.state.tipo_relatorio === "detalhado" && (
          <>
            <Table
              celled
              selectable
              hidden={
                this.state.tipo !== "pagas" &&
                this.state.tipo !== "vencidas" &&
                this.state.tipo !== "competencia" &&
                this.state.tipo !== "emAberto"
              }
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={2} collapsing>Título</Table.HeaderCell>
                  <Table.HeaderCell width={4}>Nome</Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign={"left"} collapsing>
                    Data de Pagamento
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign={"left"} collapsing>
                    Data de Vencimento
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign={"left"} collapsing>
                    Valor a receber
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign={"right"} collapsing>
                    Juros
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign={"right"} collapsing>
                    Multa
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign={"right"} collapsing>
                    Descontos
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign={"right"} collapsing>
                    Valor pago
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.lista_servicos.length === 0 ? (
                  <Table.Row>
                    <Table.Cell colSpan={5}>
                      <div style={{ margin: "15px" }}>
                        <h4> Não há dados para mostrar. </h4>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  <>
                    <Table.Row style={{ fontWeight: "bold" }}>
                      <Table.Cell colSpan={2} textAlign="right">
                        {"Quantidade: " +
                          this.state.totaisServicosDetalhado.quantidade}
                      </Table.Cell>
                      <Table.Cell colSpan={2} textAlign="right">
                        {"Totais"}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {this.state.totaisServicosDetalhado.total_divida !== null
                          ? valorFormatter.format(this.state.totaisServicosDetalhado.total_divida)
                          : valorFormatter.format(0.00)
                        }
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {valorFormatter.format(this.state.totaisServicosDetalhado.total_juros)}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {valorFormatter.format(this.state.totaisServicosDetalhado.total_multa)}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {valorFormatter.format(this.state.totaisServicosDetalhado.total_desconto)}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {valorFormatter.format(this.state.totaisServicosDetalhado.total_valor_pago)}
                      </Table.Cell>
                    </Table.Row>
                    {this.state.lista_servicos.map((e, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>
                          {e.pessoa?.acao
                            ? e.pessoa.acao.codigo
                              ? e.pessoa.acao.codigo
                              : // relatorio alterado para pegar o prefixo correto
                              e.pessoa.acao.tipo_titulo?.prefixo +
                              " - " +
                              e.pessoa.acao.codigo_auxiliar
                            : ""}
                        </Table.Cell>
                        <Table.Cell>{e.pessoa ? e.pessoa.nome : ""}</Table.Cell>
                        <Table.Cell textAlign={"left"}>
                          {e.data_pagamento !== null
                            ? moment(e.data_pagamento).format("DD/MM/YYYY")
                            : ""}
                        </Table.Cell>
                        <Table.Cell textAlign={"left"}>
                          {e.data_vencimento !== null
                            ? moment(e.data_vencimento).format("DD/MM/YYYY")
                            : ""}
                        </Table.Cell>
                        <Table.Cell textAlign={"right"}>
                          {e.valor !== null
                            ? valorFormatter.format(e.valor)
                            : valorFormatter.format(0.00)
                          }
                        </Table.Cell>
                        <Table.Cell textAlign={"right"}>
                          {e.valor_atraso !== null
                            ? valorFormatter.format(e.valor_atraso)
                            : valorFormatter.format(0.00)
                          }
                        </Table.Cell>
                        <Table.Cell textAlign={"right"}>
                          {e.valor_mora !== null
                            ? valorFormatter.format(e.valor_mora)
                            : valorFormatter.format(0.00)
                          }
                        </Table.Cell>

                        <Table.Cell textAlign={"right"}>
                          {e.desconto !== null
                            ? valorFormatter.format(e.desconto)
                            : valorFormatter.format(0.00)
                          }
                        </Table.Cell>
                        <Table.Cell textAlign={"right"}>
                          {e.valor_pago !== null
                            ? valorFormatter.format(e.valor_pago)
                            : valorFormatter.format(0.00)
                          }
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </>
                )}
              </Table.Body>
            </Table>
          </>
        )}

        {this.state.tipo_relatorio === "simplificado" &&
          this.state.tipo === "canceladas" && (
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={2} collapsing>
                    Quantidade
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign={"left"} collapsing>
                    Servico
                  </Table.HeaderCell>
                  <Table.HeaderCell width={3} textAlign={"right"} collapsing>
                    Valor
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.lista_servicos.map((e, i) => (
                  <Table.Row>
                    <Table.Cell>{e.quantidade}</Table.Cell>
                    <Table.Cell>
                      {e.servico ? e.servico.nome : e.servico_nome}
                    </Table.Cell>
                    <Table.Cell textAlign={"right"}>
                      {e.total_divida === null
                        ? valorFormatter.format(0.00)
                        : valorFormatter.format(e.total_divida)
                      }
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        {this.state.tipo_relatorio === "detalhado" &&
          this.state.tipo === "canceladas" && (
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={2} collapsing>
                    Título
                  </Table.HeaderCell>
                  <Table.HeaderCell width={4}>Nome</Table.HeaderCell>
                  <Table.HeaderCell textAlign={"left"} collapsing>
                    Servico
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign={"left"} collapsing>
                    Data de Vencimento
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign={"left"} collapsing>
                    Data de Cancelamento
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign={"right"} collapsing>
                    Valor
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.lista_servicos.length === 0 ? (
                  <Table.Row>
                    <Table.Cell colSpan={15}>
                      <div style={{ margin: "15px" }}>
                        <h4> Não há dados para mostrar. </h4>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  <>
                    <Table.Row style={{ fontWeight: "bold" }}>
                      <Table.Cell colSpan={2} textAlign="right">
                        {"Quantidade: " +
                          this.state.totaisServicosDetalhado.quantidade}
                      </Table.Cell>
                      <Table.Cell colSpan={3} textAlign="right">
                        {"Totais"}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {valorFormatter.format(this.state.totaisServicosDetalhado.total_divida)}
                      </Table.Cell>
                    </Table.Row>
                    {this.state.lista_servicos.map((e, i) => (
                      <Table.Row key={e.id}>
                        <Table.Cell>
                          {e.pessoa.acao
                            ? e.pessoa.acao.codigo
                              ? e.pessoa.acao.codigo
                              : e.pessoa.acao.codigo_auxiliar
                            : ""}
                        </Table.Cell>
                        <Table.Cell>{e.pessoa.nome}</Table.Cell>
                        <Table.Cell>{e.servico.nome}</Table.Cell>
                        <Table.Cell>
                          {moment(e.data_vencimento).format("DD/MM/YYYY")}
                        </Table.Cell>
                        <Table.Cell>
                          {moment(e.data_cancelamento).format("DD/MM/YYYY")}
                        </Table.Cell>

                        <Table.Cell textAlign={"right"}>
                          {valorFormatter.format(e.valor)}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </>
                )}
              </Table.Body>
            </Table>
          )}
        <Snackbar
          open={this.state.mensagemSnackBar.show}
          autoHideDuration={this.state.mensagemSnackBar?.duration}
          anchorOrigin={{ vertical: "botton", horizontal: "center" }}
          onClose={() =>
            setTimeout(() => {
              this.setState({
                mensagemSnackBar: {
                  ...this.state.mensagemSnackBar,
                  show: false,
                },
              });
            }, 500)
          }
        >
          <Alert
            onClose={() =>
              setTimeout(() => {
                this.setState({
                  mensagemSnackBar: {
                    ...this.state.mensagemSnackBar,
                    show: false,
                  },
                });
              }, 500)
            }
            severity={
              this.state.mensagemSnackBar?.positive ? "success" : "error"
            }
            sx={{ width: "100%" }}
          >
            {this.state.mensagemSnackBar?.content}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}
