/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Button, IconButton, Snackbar, Alert } from "@material-ui/core/";
import { Edit, Settings, Add } from "@material-ui/icons/";

import { Table, Image } from "semantic-ui-react";
import ModalEnviarLogoTimes from "./modalEnviarLogoTimes";

import { storage } from "../../../config/firebase";

import fs from "fs";
export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalidade: [],
      openModalLogo: false,
      mensagemErro: false,
      mensagem: false,
      modalAlterar: false,
      nomeModalidade: "",
    };
    this.state = {};
  }

  render() {
    return (
      <div>
        <Table selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={11}>Nome</Table.HeaderCell>

              <Table.HeaderCell width={1} textAlign="right">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#21ba45",
                    outline: "none",
                  }}
                  width={32}
                  size="mini"
                  onClick={this.props.adicionarOrganizacao}
                >
                  <Add />
                </Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body hidden={this.props.organizacoesCadastradas.length === 0}>
            {this.props.organizacoesCadastradas.map((element, index) => (
              <Table.Row>
                <Table.Cell
                  onClick={() => this.props.editOrganizacao(element, true)}
                >
                  <p style={{ fontSize: 15 }}> {element.nome}</p>
                </Table.Cell>

                <Table.Cell textAlign="right">
                  <Button
                    variant="contained"
                    color="inherit"
                    startIcon={<Edit />}
                    onClick={() => {
                      this.setState({
                        modalidade_id: element.id,
                        modalidade_nome: element.nome,
                        indiceListaEditar: index,
                        openModalLogo: true,
                      });
                    }}
                  >
                    Editar
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <ModalEnviarLogoTimes
          openModal={this.state.openModalLogo}
          changeOpenModal={(openModal) => {
            this.setState({ openModalLogo: openModal });
          }}
          changeOpenSnackbar={(openSnackbar, error) => {
            this.setState({ mensagem: openSnackbar, mensagemErro: error });
          }}
          modalidade_id={this.state.modalidade_id}
          modalidade_nome={this.state.modalidade_nome}
          modalidade_img={
            this.state.listaLogosTimes &&
            this.state.listaLogosTimes[this.state.indiceListaEditar]
          }
          alteracaoStateOrganizacao={this.props.alteracaoStateOrganizacao}
        />
        <Snackbar
          open={this.state.mensagem}
          autoHideDuration={3000}
          onClose={() => {
            this.setState({ mensagem: false });
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          fullWidth
        >
          <Alert
            variant="filled"
            severity={this.state.mensagemErro ? "error" : "success"}
            onClose={() => {
              this.setState({ mensagem: false });
            }}
            onError={() =>
              setTimeout(() => {
                this.setState({ mensagem: false });
              }, 3000)
            }
          >
            {this.state.mensagemErro
              ? "Erro ao enviar o arquivo"
              : "Salvo com sucesso"}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}
