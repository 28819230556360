import React, { Component } from "react";
import { Table, Button, Checkbox, Input, Select } from "semantic-ui-react";
import { Row, Col, Form } from "react-bootstrap";
import ReactSelect from "react-select";
import Pagination from "@material-ui/lab/Pagination";

import moment from "moment-timezone";

import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // dados inputs pelo user
      optionsSelectedTitulo: [],
      selectTodos: false,
      selectTodosTitulos: false,
      adimplentes: [],

      // options
      tipoTituloOptions: [],
      optionsServico: [],
      options_grupo_servico: [],
      servico_selecionado: 0,
      grupo_servico_selecionado: 0,
      grupo_servico: [],

      //dados da tela
      acoes: [],
      optionsServicoGrupo: [],

      //estados de controle
      dataInvalida: false,
      buscando: false,
      sortedName: "nome",
      sortDirection: "ascending",

      totalPages: 0,
      dadosPorPagina: 30,
      activePage: 1,
      pagina: 1,
      totaRegistro: 0,
    };
    this.listaTipoTitulo();
    this.listaGrupoServico();
    this.listaServico();
  }

  alteracao = async (event) => {
    this.state.optionsSelectedTitulo = [];
    this.state.optionsSelectedTitulo.push([{ value: event.target.value }]);

    this.setState({
      [event.target.name]: event.target.value,
      grupo_servico: event.target.value,
    });
  };
  alteracaoServico = async (event, a) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    this.listaServicoGrupo(event.target.value);
  };

  imprimirOnClick = async () => {
    let listaTipoTituloId = [];

    for (let i = 0; i < this.state.optionsSelectedTitulo.length; i++) {
      const element = this.state.optionsSelectedTitulo[i];
      listaTipoTituloId.push(element.value);
    }

    listaTipoTituloId = this.state.optionsSelectedTitulo.map((e) => e.value);

    await api.post(
      "/lancamentoConta/gerarPdfAdimplentesTitulos" + requisicoes.entidade,
      {
        servico_id:
          this.state.servico_selecionado === 0
            ? null
            : this.state.servico_selecionado,
        tipo_titulo_id: this.state.selectTodosTitulos
          ? null
          : listaTipoTituloId,
        grupo_servico_id:
          this.state.grupo_servico_selecionado === 0
            ? null
            : this.state.grupo_servico_selecionado,
        sortedName: this.state.sortedName,
        sortDirection: this.state.sortDirection,

        sortedName: this.state.sortDirection,
      },
      requisicoes.header
    );

    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");

    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    const r = await api.get(
      "/lancamentoConta/buscarPdfAdimplentesTitulos" +
      requisicoes.entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.id
    );
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "lancamentoConta/buscarPdfAdimplentesTitulos" +
      requisicoes.entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.id;

    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  listaTipoTitulo = async () => {
    const titulos = await api.post(
      "/tipoTitulo/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    let listaTitulos = [];
    listaTitulos.push({
      value: 0,
      label: "Todos",
      selected: false,
    });
    for (let i = 0; i < titulos.data.length; i++) {
      const element = titulos.data[i];
      listaTitulos.push({
        value: element.id,
        label: element.descricao,
        selected: false,
      });
    }
    this.setState({ tipoTituloOptions: listaTitulos });
  };

  onChangeSelectItemTitulo = (e, a) => {
    console.log("aaaa", a)
    if (a.action === "clear") {
      let lista = this.state.tipoTituloOptions;
      for (let i = 0; i < lista.length; i++) {
        const element = lista[i];
        element.selected = false;
      }
      this.setState({
        tipoTituloOptions: lista,
        optionsSelectedTitulo: [],
        selectTodosTitulos: false,
      });
      return;
    }
    if (a.action === "pop-value" && a.removedValue === undefined) {
      return;
    }
    if (a.removedValue !== undefined) {
      a.option = a.removedValue;
    }
    if (a.option.value === 0) {
      console.log("this.state.tipoTituloOptions",this.state.tipoTituloOptions)
      if (a.action === "select-option") {
        let lista2 = this.state.tipoTituloOptions;
        for (let i = 0; i < lista2.length; i++) {
          const element = lista2[i];
          element.selected = true;
        }
        this.setState({
          tipoTituloOptions: lista2,
          selectTodosTitulos: true,
        });
      }
      if (
        a.action === "deselect-option" ||
        a.action === "pop-value" ||
        a.action === "remove-value"
      ) {
        let lista2 = this.state.tipoTituloOptions;
        const optionsSelectedTitulo = this.state.optionsSelectedTitulo;
        for (let i = 0; i < lista2.length; i++) {
          const element = lista2[i];
          if (
            optionsSelectedTitulo.findIndex((e) => e.value === element.id) !==
            -1
          ) {
            element.selected = true;
          } else {
            element.selected = false;
          }
        }
        this.setState({ tipoTituloOptions: lista2, selectTodosTitulos: false });
      }
    }
    console.log("this.state.optionsSelectedTitulo", this.state.optionsSelectedTitulo)
    console.log("this.state.tipoTituloOptions", this.state.tipoTituloOptions)
    if (a.action === "select-option") {
      let lista = this.state.optionsSelectedTitulo;
      lista.push(a.option);
      let lista2 = this.state.tipoTituloOptions;
      let index = lista2.findIndex(
        (element) => element.value === a.option.value
      );
      lista2[index].selected = true;
      this.setState({
        optionsSelectedTitulo: lista,
        tipoTituloOptions: lista2,
      });
    }
    if (
      a.action === "deselect-option" ||
      a.action === "pop-value" ||
      a.action === "remove-value"
    ) {
      let lista = this.state.optionsSelectedTitulo;
      let index0 = lista.findIndex(
        (element) => element.value === a.option.value
      );
      lista.splice(index0, 1);

      let lista2 = this.state.tipoTituloOptions;
      let index = lista2.findIndex(
        (element) => element.value === a.option.value
      );
      lista2[index].selected = false;
      this.setState({
        optionsSelectedTitulo: lista,
        tipoTituloOptions: lista2,
      });
    }
  };

  listaGrupoServico = async () => {
    const resposta = await api.post(
      "/grupoServico/lista" + requisicoes.entidade,
      "",

      requisicoes.header
    );
    let lista = [];
    lista.push({
      value: 0,
      label: "Todos",
      selected: false,
    });
    for (let i = 0; i < resposta.data.length; i++) {
      const element = resposta.data[i];
      lista.push({
        value: element.id,
        label: element.descricao,
        selected: false,
      });
    }
    this.setState({ options_grupo_servico: lista });
  };
  listaServico = async () => {
    const resposta = await api.post(
      "/servico/servicoPorGrupoServicoComDiversos" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    let lista = [];
    lista.push({
      value: 0,
      label: "Todos",
      selected: false,
    });
    for (let i = 0; i < resposta.data.length; i++) {
      const element = resposta.data[i];

      lista.push({
        value: element.id,
        label: element.nome,
        grupo_servico_id: element.grupo_servico_id,
      });
    }

    function ordena(a, b) {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    }
    lista.sort(ordena);
    this.setState({ optionsServicoGrupo: lista });
  };

  sortTableClick = async (sortedName) => {
    this.state.sortedName = sortedName;
    this.state.sortDirection =
      this.state.sortDirection === "ascending" ? "descending" : "ascending";
    this.buscaAdimplentes();
  };

  buscaAdimplentes = async (parcelas, b, activePage = 1) => {
    this.setState({ buscando: true });

    console.log("bucar selectTodosTitulos", this.state.optionsSelectedTitulo)
    let listaTipoTituloId = this.state.optionsSelectedTitulo.map((e) => e.value)

    const adimplentes = await api.post(
      "/lancamentoConta/buscaAdimplentes" + requisicoes.entidade,
      {
        servico_id:
          this.state.servico_selecionado === 0
            ? null
            : this.state.servico_selecionado,
        tipo_titulo_id: this.state.selectTodosTitulos
          ? null
          : listaTipoTituloId,
        grupo_servico_id:
          this.state.grupo_servico_selecionado === 0
            ? null
            : this.state.grupo_servico_selecionado,
        sortedName: this.state.sortedName,
        sortDirection: this.state.sortDirection,
        activePage: activePage,
      },
      requisicoes.header
    );

    let lista = [];
    for (let i = 0; i < adimplentes.data.docs.length; i++) {
      const element = adimplentes.data.docs[i];
      lista.push({
        id: element.acao.codigo ? element.acao.codigo : element.acao.auxiliar,
        nome: element.nome,
        descricao: element.acao.tipo_titulo.descricao,
        data_admissao: element.acao.data_emissao,
        codigo: element.acao?.id,
      });
    }
    this.setState({
      buscando: false,
      adimplentes: lista,
      activePages: activePage,
      totalPages: adimplentes.data.page,
      totaRegistro: adimplentes.data.total,
    });
    return this.setState({ buscando: false });
  };

  listaServicoGrupo = async (a) => {
    let lista = this.state.optionsServicoGrupo.filter(
      (e) => e.grupo_servico_id === parseInt(a)
    );
    this.setState({ optionsServico: lista });
  };
  render() {
    return (
      <div>
        <Row style={{ alignItems: "flex-end", paddingTop: 15 }}>
          <Col md={true}>
            <Form.Label> Grupo Serviços</Form.Label>
            <Form.Control
              as="select"
              name="grupo_servico_selecionado"
              id="grupo_servico_selecionado"
              value={this.state.grupo_servico_selecionado}
              required
              onChange={this.alteracaoServico}
            >
              {this.state.options_grupo_servico.map((e, i) => (
                <option value={e.value}>{e.label}</option>
              ))}
            </Form.Control>
          </Col>
          <Col sm={3}>
            <Form.Label>Serviços</Form.Label>
            <Form.Control
              as="select"
              name="servico_selecionado"
              checked={this.state.servico_selecionado}
              id="servico_selecionado"
              value={this.state.servico_selecionado}
              required
              onChange={this.alteracao}
              disabled={this.state.optionsServico.length === 0}
            >
              <option value={0}>Todos</option>
              {this.state.optionsServico.map((e, i) => (
                <option value={e.value}>{e.label}</option>
              ))}
            </Form.Control>
          </Col>

          <Col md={true}>
            <ReactSelect
              options={this.state.tipoTituloOptions}
              isMulti
              className="basic-multi-select"
              classNamePrefix="select"
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              onChange={this.onChangeSelectItemTitulo}
              isOptionSelected={(element) => element.selected === true}
            />
          </Col>
          <Col md="auto" style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={this.buscaAdimplentes}
              color="green"
              loading={this.state.buscando}
              disabled={
                this.state.optionsSelectedTitulo.length === 0 ||
                this.state.buscando === true
              }
            >
              Buscar
            </Button>
          </Col>

          <Col md="auto" style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={this.imprimirOnClick}
              color="blue"
              disabled={
                this.state.optionsSelectedTitulo.length === 0 ||
                this.state.buscando === true
              }
            >
              Imprimir
            </Button>
          </Col>
        </Row>
        <Row
          style={{
            marginLeft: 0,
            marginRight: 0,
            marginTop: 15,
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Col sm="auto"></Col>
          <div>
            <h5>{"Total por tela: " + this.state.adimplentes.length}</h5>
          </div>
        </Row>
        <Row
          style={{
            marginLeft: 0,
            marginRight: 0,
            marginTop: 5,
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Col sm="auto"></Col>
          <div>
            <h5>{"Total : " + this.state.totaRegistro}</h5>
          </div>
        </Row>

        <Table celled sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={
                  this.state.sortedName === "codigo"
                    ? this.state.sortDirection
                    : null
                }
                onClick={() => this.sortTableClick("codigo")}
              >
                N° do título
              </Table.HeaderCell>
              <Table.HeaderCell>Tipo título</Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  this.state.sortedName === "nome"
                    ? this.state.sortDirection
                    : null
                }
                onClick={() => this.sortTableClick("nome")}
              >
                Nome do sócio
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  this.state.sortedName === "data"
                    ? this.state.sortDirection
                    : null
                }
                onClick={() => this.sortTableClick("data")}
              >
                Data de Admissão
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.adimplentes?.map((e, i) => (
              <Table.Row>
                <Table.Cell>{e.id}</Table.Cell>
                <Table.Cell>{e.descricao}</Table.Cell>
                <Table.Cell>{e.nome}</Table.Cell>
                <Table.Cell>
                  {e.data_admissao
                    ? moment(e.data_admissao).format("DD/MM/YYYY")
                    : ""}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Row style={{ justifyContent: "center" }}>
          <Pagination
            count={this.state.totalPages}
            variant="outlined"
            shape="rounded"
            boundaryCount={1}
            page={this.state.activePage}
            onChange={(e, a) => {
              this.setState({ activePage: a });
              if (a) this.buscaAdimplentes(null, null, a);
            }}
          />
        </Row>
      </div>
    );
  }
}
