import React, { Component } from "react";
import {
  Table,
  Modal,
  Button,
  Container,
  Icon,
  Dimmer,
  Loader,
  Divider,
  Header,
  Segment
} from "semantic-ui-react";
import Select from "react-select";

import { Col, Row, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";

// Utils
import moment from "moment";

export default class ListaReservas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      presencaSocio: null,
    };
  }

  carregaListaConvidados = async (e) => {
    await this.props.carregaListaConvidados(e.reserva_id);
    await this.props.setSocioResponsavel(e.pessoa);
  }

  render() {
    return (
      <div style={{ paddingTop: 30 }}>
        <h2>Reservas</h2>
        <Divider />
        <Row>
          <Col sm={4}>
            <Select
              placeholder="Digite para buscar"
              name="pessoa"
              value={this.props.pessoa}
              onChange={this.props.alteracaoSelectPessoa}
              options={this.props.options}
              onKeyDown={this.props.pesquisaPessoa}
              inputId="pesquisaPessoa"
              isClearable
            />
          </Col>
        </Row>

        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Titulo</Table.HeaderCell>
              <Table.HeaderCell>Pessoa</Table.HeaderCell>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Data Reserva</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Lista Conv.
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Lista Forn.
              </Table.HeaderCell>
              {(localStorage.getItem("tipo_usuario_clube") === "caixa" ||
                localStorage.getItem("tipo_usuario_clube") ===
                  "administrador") && (
                <Table.HeaderCell textAlign="center">Reserva</Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>
          {this.props.listaReservasAdmin.map((e, i) => (
            <Table.Body>
              <Table.Row key={i} positive={e.efetivado} negative={e.cancelado}>
                <Table.Cell>
                  <div style={{ fontWeight: "bold" }}>
                    {e.pessoa && e.pessoa.acao
                      ? e.pessoa.acao.codigo
                        ? ((e.pessoa.acao.tipo_titulo &&
                            e.pessoa.acao.tipo_titulo.prefixo) ||
                            "") +
                          " " +
                          e.pessoa.acao?.codigo
                        : (e.pessoa.acao.tipo_titulo?.prefixo || "") +
                          " " +
                          (e.pessoa.acao?.codigo_auxiliar || "")
                      : " "}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div style={{ fontWeight: "bold" }}>{e.pessoa?.nome}</div>
                </Table.Cell>
                <Table.Cell>{e.nome}</Table.Cell>
                <Table.Cell>
                  {moment(e.dataReserva).format("DD/MM/YYYY")}
                </Table.Cell>
                <Table.Cell width={1} textAlign="center">
                  <Modal
                    closeIcon
                    size="small"
                    trigger={
                      <Button
                        onClick={() => this.carregaListaConvidados(e)}
                        icon="clipboard list"
                        color="blue"
                      ></Button>
                    }
                  >
                    <Modal.Content>
                      <Header>
                        <h3>{this.props.somente_convidados_presentes ? "Convidados Presentes" : "Lista de Convidados"}</h3>
                        {(!this.props.somente_convidados_presentes) && (
                        <Button style={{ marginBottom: 15 }}
                          floated="right"
                          // fluid={false}
                          color={"blue"}
                          onClick={() => this.props.imprimirListaConvidados(e)}
                        >
                          Imprimir
                        </Button>)}
                        <Button style={{ marginBottom: 15 }}
                          floated="right"
                          // fluid={false}
                          color={this.props.somente_convidados_presentes ? "grey" : "blue"}
                          onClick={() => this.props.mostraListaConvidadosPresentes(e)}
                        >
                          {this.props.somente_convidados_presentes ? "Voltar" : "Convidados Presentes"}
                        </Button>
                      </Header>

                      <Container textAlign="center">
                        {this.props.buscandoListaPresencaReservas ? (
                          <Dimmer active inverted>
                            <Loader active inverted inline />
                          </Dimmer>
                        ) : (this.props.presencaSocio &&
                          ((this.props.somente_convidados_presentes && this.props.presencaSocio.presente !== false) || // Mostra apenas os já presentes
                          (!this.props.somente_convidados_presentes && this.props.presencaSocio.presente === false)) && ( // Mostra apenas os convidados não presentes
                            <Table celled>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>Sócio Responsável</Table.HeaderCell>
                                  <Table.HeaderCell>CPF</Table.HeaderCell>
                                  <Table.HeaderCell width={1} textAlign="center">
                                    <Icon name="clipboard list" />
                                  </Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                <Table.Row key={this.props.presencaSocio.id}>
                                  <Table.Cell>
                                    <FormControl
                                      required
                                      name="nome"
                                      fluid
                                      value={this.props.presencaSocio.nome}
                                      disabled
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <FormControl
                                      required
                                      name="cpf_convidado"
                                      fluid
                                      value={this.props.presencaSocio.cpf}
                                      disabled
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    {this.props.presencaSocio.presente !== false ? (
                                      <Button
                                        fluid
                                        positive
                                        icon="clipboard check"
                                        size="tiny"
                                        onClick={() => {
                                          this.props.confirmaPresenca(
                                            this.props.presencaSocio,
                                            e.reserva_id,
                                            false
                                          );
                                          this.props.setSocioResponsavel(e.pessoa);
                                        }}
                                      ></Button>
                                    ) : (
                                      <Button
                                        fluid
                                        color="yellow"
                                        icon="clipboard"
                                        size="tiny"
                                        onClick={() => {
                                          this.props.confirmaPresenca(
                                            this.props.presencaSocio,
                                            e.reserva_id,
                                            true
                                          );
                                          this.props.setSocioResponsavel(e.pessoa);
                                        }}
                                      ></Button>
                                    )}
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                          )
                        )}
                      </Container>

                      <Container textAlign="center">
                        {this.props.buscandoListaPresencaReservas ? (
                          <Dimmer active inverted>
                            <Loader active inverted inline />
                          </Dimmer>
                        ) : (
                          <Table celled>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>Nome</Table.HeaderCell>
                                <Table.HeaderCell>CPF</Table.HeaderCell>
                                <Table.HeaderCell width={1} textAlign="center">
                                  <Icon name="clipboard list" />
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {this.props.listaPresencaReservas.length === 0 ? (
                                <Table.Row>
                                  <Table.Cell collSpan={6}>
                                    <h4>
                                      Não foram encontrados convidados para esta reserva.
                                    </h4>
                                  </Table.Cell>
                                </Table.Row>
                              ) : (
                                this.props.listaPresencaReservas.map(
                                  (presenca, i) => (presenca.nome.includes(e.pessoa?.nome)) ? null :
                                  ((this.props.somente_convidados_presentes && presenca.presente !== false) || // Mostra apenas os já presentes
                                  (!this.props.somente_convidados_presentes && presenca.presente === false)) && ( // Mostra apenas os convidados não presentes
                                    <Table.Row key={i}>
                                      <Table.Cell>
                                        <FormControl
                                          required
                                          name="nome"
                                          fluid
                                          value={presenca.nome}
                                          disabled
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <FormControl
                                          required
                                          name="cpf_convidado"
                                          fluid
                                          value={presenca.cpf}
                                          disabled
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        {presenca.presente !== false ? (
                                          <Button
                                            fluid
                                            positive
                                            icon="clipboard check"
                                            size="tiny"
                                            onClick={() =>
                                              this.props.confirmaPresenca(
                                                presenca,
                                                e.reserva_id,
                                                false
                                              )
                                            }
                                          ></Button>
                                        ) : (
                                          <Button
                                            fluid
                                            color="yellow"
                                            icon="clipboard"
                                            size="tiny"
                                            onClick={() =>
                                              this.props.confirmaPresenca(
                                                presenca,
                                                e.reserva_id,
                                                true
                                              )
                                            }
                                          ></Button>
                                        )}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )
                              )}
                            </Table.Body>
                          </Table>
                        )}
                      </Container>
                    </Modal.Content>
                  </Modal>
                </Table.Cell>
                <Table.Cell width={1}>
                  <Modal
                    closeIcon
                    size="small"
                    trigger={
                      <Button
                        onClick={() =>
                          this.props.carregaListaFornecedor(e.reserva_id)
                        }
                        icon="clipboard list"
                        color="blue"
                      ></Button>
                    }
                  >
                    <Modal.Content>
                      <Container
                        textAlign="right"
                        style={{ paddingBottom: 10 }}
                      >
                        <Button
                          // fluid={false}
                          color={"blue"}
                          onClick={() => this.props.imprimirListaFornecedor(e)}
                        >
                          Imprimir
                        </Button>
                      </Container>
                      <Container textAlign="center">
                        {this.props.buscandoListaFornecedorReserva ? (
                          <Dimmer active inverted>
                            <Loader active inverted inline />
                          </Dimmer>
                        ) : (
                          <Table celled>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>Nome</Table.HeaderCell>
                                <Table.HeaderCell>Função</Table.HeaderCell>
                                <Table.HeaderCell width={1} textAlign="center">
                                  <Icon name="clipboard list" />
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {this.props.listaFornecedorReserva.length ===
                              0 ? (
                                <Table.Row>
                                  <Table.Cell collSpan={6}>
                                    <h4>
                                      Não foram encontrados fornecedores para
                                      esta reserva.
                                    </h4>
                                  </Table.Cell>
                                </Table.Row>
                              ) : (
                                this.props.listaFornecedorReserva.map(
                                  (presenca, i) => (
                                    <Table.Row key={i}>
                                      <Table.Cell>
                                        <FormControl
                                          required
                                          name="nome"
                                          fluid
                                          value={presenca.nome}
                                          disabled
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        <FormControl
                                          required
                                          name="parentesco"
                                          fluid
                                          value={presenca.funcao}
                                          disabled
                                        />
                                      </Table.Cell>
                                      <Table.Cell>
                                        {presenca.presente !== false ? (
                                          <Button
                                            fluid
                                            positive
                                            icon="clipboard check"
                                            size="tiny"
                                          ></Button>
                                        ) : (
                                          <Button
                                            fluid
                                            color="yellow"
                                            icon="clipboard"
                                            size="tiny"
                                            onClick={() => {
                                              this.props.confirmaPresencaFornecedor(
                                                presenca,
                                                e.reserva_id
                                              );
                                            }}
                                          ></Button>
                                        )}
                                      </Table.Cell>
                                    </Table.Row>
                                  )
                                )
                              )}
                            </Table.Body>
                          </Table>
                        )}
                      </Container>
                    </Modal.Content>
                  </Modal>
                </Table.Cell>

                {this.props.permissoes.find((e) => e.nome === "reservar") && (
                  <Table.Cell width={1} textAlign="center">
                    <Link
                      to={{
                        pathname: "/reserva",
                        query: {
                          id: e.id,
                          dataReserva: e.dataReserva,
                          pessoa: e.pessoa,
                        },
                      }}
                    >
                      <Button positive icon="calendar alternate"></Button>
                    </Link>
                  </Table.Cell>
                )}
              </Table.Row>
              {e.evento !== null && (
                <Table.Cell colSpan={7} style={{ borderTop: 0 }}>
                  Evento: {e.evento}
                </Table.Cell>
              )}
              {this.props.listaReservasAdmin.length - 1 !== i && (
                <Table.Row>
                  <Table.Cell
                    colSpan={8}
                    style={{
                      background: "#808080",
                      height: "2px",
                      padding: "0px",
                    }}
                  ></Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          ))}
        </Table>
      </div>
    );
  }
}
