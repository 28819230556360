import React, { Component } from "react";
import { Button, Segment, Checkbox, Message, Header } from "semantic-ui-react";
import { Row, Col } from "react-bootstrap";

// Componentes
import ModalRecusar from "./modalRecusar";
import ModalAvisoAceitar from "./modalAvisoAceitar";

// Utils
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";
import moment from "moment";

// Enum
import EnumMes from "../../../enum/Mes";

export default class Termo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Info
      pessoa: {},
      entidade: {},

      // state botões
      aceitando: false,
      recusando: false,

      // Inputs
      checkboxAceito:
        localStorage.getItem("aceitou_termos") === "true" ? true : false,

      // Modais
      modalOpenCancelar: false,
      modalOpenAvisoAceitar:
        localStorage.getItem("aceitou_termos") === "true" ? false : true,

      mensagem: null,
    };
    this.buscar();
  }

  buscar = async () => {
    try {
      const pessoa = await api.post(
        "/pessoa/pessoaLogada" + requisicoes.entidade,
        {},
        requisicoes.header
      );

      const entidade = await api.post(
        "/entidade/buscaEntidadeUsuario" + requisicoes.entidade,
        {},
        requisicoes.header
      );

      this.setState({ pessoa: pessoa.data, entidade: entidade.data });
    } catch (error) {
      console.log("Problema busca pessoa");
    }
  };

  aceitar = async () => {
    try {
      if (this.state.recusando) {
        return;
      }
      if (!this.state.checkboxAceito) {
        this.mensagem(
          false,
          null,
          "Você precisa confirmar que leu os termos.",
          1500
        );
        return;
      }
      this.setState({ aceitando: true });
      await api.post(
        "/usuario/aceitarTermos" + requisicoes.entidade,
        {
          aceitou: true,
        },
        requisicoes.header
      );
      this.mensagem(
        true,
        "Aceito!",
        "Obrigado por aceitar. Agora você pode utilizar o sistema à vontade."
      );
      setTimeout(() => {
        window.location.href = "/";
        localStorage.setItem("token_clube", null);
      }, 3000);
    } catch (error) {
      this.setState({ aceitando: false });
      this.mensagem(
        false,
        "Erro ao processar a requisição.",
        "Ocorreu um erro ao processar a sua ação. Por favor, tente novamente ou entre em contato."
      );
    }
  };

  recusar = async () => {
    if (this.state.aceitando) {
      return;
    }
    try {
      this.setState({ recusando: true });
      await api.post(
        "/usuario/aceitarTermos?e=" + requisicoes.entidade,
        {
          aceitou: false,
        },
        requisicoes.header
      );
      window.localStorage.setItem("token_clube", undefined);

      setTimeout(() => {
        window.location.href = "/";
      }, 1500);
    } catch (error) {}
  };

  mensagem = (positive, header, content, duration) => {
    this.setState({
      mensagem: (
        <Message
          positive={positive ? true : false}
          negative={positive ? false : true}
          header={header}
          content={content}
        ></Message>
      ),
    });
    setTimeout(
      () => {
        this.setState({ mensagem: null });
      },
      duration ? duration : 4000
    );
  };

  render() {
    return (
      <div>
        <Segment style={{ padding: 30 }} align="justify">
          <div>
            <Header>
              TERMO DE CONSENTIMENTO PARA TRATAMENTO DE DADOS PESSOAIS LEI GERAL
              DE PROTEÇÃO DE DADOS PESSOAIS – LGPD
            </Header>
            <p>
              Através do presente termo, eu {this.state.pessoa?.nome}, inscrito
              (a) no CPF sob n° {this.state.pessoa?.cpf}, aqui denominado (a)
              como TITULAR, venho por meio deste, autorizar que a empresa
              {"" + this.state.entidade?.nome?.trim()}, aqui denominada como
              CONTROLADORA, inscrita no CNPJ sob n° {this.state.entidade?.cnpj},
              em razão do vínculo de sociedade, disponha dos meus dados pessoais
              e dados pessoais sensíveis, de acordo com os artigos
              7° e 11 da Lei n° 13.709/2018, conforme disposto neste termo:
            </p>
            <h3>CLÁUSULA PRIMEIRA</h3>
            <h3>Dados Pessoais</h3>O Titular autoriza a o Sistema FAEE CLUBE a
            realizar o tratamento, ou seja, a utilizar os seguintes dados
            pessoais, para os fins que serão relacionados na cláusula segunda:
            <ul>
              <li>Nome completo</li>
              <li>Data de nascimento</li>
              <li>Número e imagem do Cadastro de Pessoas Físicas (CPF)</li>
              <li>Número e imagem do Certificado de Reservista</li>
              <li>Fotografia de Perfil</li>

              <li>Estado Civil</li>
              <li>Nível de instrução ou escolaridade</li>
              <li>Instituição de Ensino e período de estudo</li>
              <li>Endereço completo</li>
              <li>Números de telefone, WhatsApp e endereços de e-mail</li>
              <li>Banco, agência e número de contas bancárias</li>
              <li>Banco, agência e número de contas bancárias</li>
              <li>
                Nome de usuário e senha específicos para uso dos serviços do
                Sistema FAEE CLUBE
              </li>
            </ul>
            <h3>CLÁUSULA SEGUNDA</h3>
            <h3>Finalidade do Tratamento dos Dados</h3>O Titular autoriza que o
            Sistema FAEE CLUBE utilize os dados pessoais e dados pessoais
            sensíveis listados neste termo para as seguintes finalidades:
            <ul>
              <li>
                Permitir que o Sistema FAEE CLUBE proporcione aos operadores do
                mesmo utilizar os dados de contato para exercer o mesmo em razão
                do vínculo de sociedade entre o clube e o sócio
              </li>
              <li>
                Quando necessário, entrar em contato com os sócios,
                informando-os sobre débitos para com o clube ou qualquer outra
                razão de contato
              </li>
              <li>
                Utilize os dados bancários coletados para realizar a geração de
                cobranças de mensalidades, serviços e produtos fornecidos pelo
                clube, desde que haja consentimento do sócio
              </li>
              <li>
                Utilize fotos e dados cadastrais e informações biométricas para
                identificação do sócio, visando a segurança do clube quanto ao
                acesso aos seus espaços físicos e digitais
              </li>
              <li>
                Utilize informações referentes à exames médicos e físicos,
                realizando o controle de acesso aos ambientes que tenham como
                requisito devidas informações, visando a saúde coletiva dos
                sócios
              </li>
              <li>
                Utilize meu nome para elaborar relatórios estatísticos e
                gerenciais para o clube, como relatórios de acesso e
                movimentação financeira
              </li>
              <li>
                Quando necessário para atender aos interesses legítimos do
                controlador ou de terceiros, exceto no caso de prevalecerem
                direitos e liberdades fundamentais do titular que exijam a
                proteção dos dados pessoais
              </li>
              <li>
                Permitir que a o Clube utilize esses dados para a contratação e
                prestação de serviços diversos dos inicialmente ajustados, desde
                que o Titular também demonstre interesse em contratar novos
                serviços
              </li>
            </ul>
            <p>
              <span style={{ fontWeight: "bold" }}>Parágrafo Primeiro:</span>{" "}
              Caso seja necessário o compartilhamento de dados com terceiros que
              não tenham sido relacionados nesse termo ou qualquer alteração
              contratual posterior, será ajustado novo termo de consentimento
              para este fim (§ 6° do artigo 8° e § 2° do artigo 9° da Lei n°
              13.709/2018).
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Parágrafo Segundo:</span> Em
              caso de alteração na finalidade, que esteja em desacordo com o
              consentimento original, a Controladora deverá comunicar o Titular,
              que poderá revogar o consentimento, conforme previsto na cláusula
              sexta.
            </p>
            <h3>CLÁUSULA TERCEIRA</h3>
            <h3>Compartilhamento de Dados</h3>
            <p>
              O Clube fica autorizado a compartilhar os dados pessoais do
              Titular com outros agentes de tratamento de dados, caso seja
              necessário para as finalidades listadas neste instrumento, desde
              que, sejam respeitados os princípios da boa-fé, finalidade,
              adequação, necessidade, livre acesso, qualidade dos dados,
              transparência, segurança, prevenção, não discriminação e
              responsabilização e prestação de contas.
            </p>
            <h3>CLÁUSULA QUARTA</h3>
            <h3>Responsabilidade pela Segurança dos Dados</h3>
            <p>
              O clube contratante e a FAEE SISTEMAS em conjunto, se
              responsabilizam em manter medidas de segurança, técnicas e
              administrativas suficientes a proteger os dados pessoais do
              Titular e à Autoridade Nacional de Proteção de Dados (ANPD),
              comunicando ao Titular, caso ocorra algum incidente de segurança
              que possa acarretar risco ou dano relevante, conforme artigo
              48 da Lei n° 13.709/2020.
            </p>
            <h3>CLÁUSULA QUINTA</h3>
            <h3>Término do Tratamento dos Dados</h3>
            <p>
              À FAEE e o clube contratante, é permitido manter e utilizar os
              dados pessoais do Titular durante todo o período contratualmente
              firmado para as finalidades relacionadas nesse termo e ainda após
              o término da contratação para cumprimento de obrigação legal ou
              impostas por órgãos de fiscalização, nos termos do artigo
              16 da Lei n° 13.709/2018.
            </p>
            <h3>CLÁUSULA SEXTA</h3>
            <h3>Direito de Revogação do Consentimento</h3>
            <p>
              O Titular poderá revogar seu consentimento, a qualquer tempo, por
              e-mail, através do sistema no portal FALE CONOSCO ou por carta
              escrita, conforme o artigo 8°, § 5°, da Lei n° 13.709/2020.
            </p>
            <p>
              O Titular fica ciente de que a FAEE SISTEMAS e o clube contratante
              poderá permanecer utilizando os dados para as seguintes
              finalidades:
            </p>
            <ul>
              <li>
                Para cumprimento, pela Controladora, de obrigações impostas por
                órgãos de fiscalização
              </li>
              <li>
                Para o exercício regular de direitos em processo judicial,
                administrativo ou arbitral
              </li>
              <li>
                Quando necessário para atender aos interesses legítimos do
                controlador ou de terceiros, exceto no caso de prevalecerem
                direitos e liberdades fundamentais do titular que exijam a
                proteção dos dados pessoais
              </li>
            </ul>
            <h3>CLÁUSULA SÉTIMA</h3>
            <h3>Tempo de Permanência dos Dados Recolhidos</h3>
            <p>
              O titular fica ciente de que a Controladora deverá permanecer com
              os seus dados pelo período proporcional à duração do vínculo entre
              FAEE SISTEMAS e Clube contratante.
            </p>
            <h3>CLÁUSULA OITAVA</h3>
            <h3>Vazamento de Dados ou Acessos Não Autorizados – Penalidades</h3>
            <p>
              As partes poderão entrar em acordo, quanto aos eventuais danos
              causados, caso exista o vazamento de dados pessoais ou acessos não
              autorizados, e caso não haja acordo, a Controladora tem ciência
              que estará sujeita às penalidades previstas no artigo 52 da Lei n°
              13.709/2018:
            </p>
            <p>
              Este consentimento serve para atendimento aos requisitos da LGPD.{" "}
            </p>
            <p>
              Acesse a lei:{" "}
              <a
                href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709.htm"
                target="_blank"
                rel="noreferrer"
              >
                http://www.planalto.gov.br/ccivil_03/_ato
                2015-2018/2018/lei/L13709.htm
              </a>
            </p>
            <p>{`${this.state.entidade?.cidade?.trim()}, ${moment().format(
              "DD"
            )} de ${EnumMes[moment().month()].label} de ${moment().format(
              "YYYY"
            )}`}</p>
          </div>

          <h5 style={{ marginBottom: 7, color: "red" }}>
            Você precisa aceitar os termos para poder usar o sistema.
          </h5>
          <Row>
            <Col style={{ display: "flex", alignContent: "center" }}>
              <Checkbox
                style={{ marginRight: 10 }}
                checked={this.state.checkboxAceito}
                onChange={(e, a) =>
                  this.setState({ checkboxAceito: a.checked })
                }
              />
              Li e concordo com os termos apresentados.
            </Col>
          </Row>
        </Segment>

        {this.state.mensagem && this.state.mensagem}
        <Segment>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="auto">
              <Button
                color="red"
                onClick={() => this.setState({ modalOpenCancelar: true })}
              >
                Não aceito
              </Button>
            </Col>
            <Col md="auto">
              <Button
                color="green"
                onClick={this.aceitar}
                disabled={this.state.aceitando}
                loading={this.state.aceitando}
              >
                Li e aceito os termos
              </Button>
            </Col>
          </Row>
        </Segment>

        <ModalRecusar
          modalOpenCancelar={this.state.modalOpenCancelar}
          fechaModalCancelar={() => {
            this.setState({ modalOpenCancelar: false });
          }}
          recusando={this.state.recusando}
          recusar={this.recusar}
        />

        <ModalAvisoAceitar
          open={this.state.modalOpenAvisoAceitar}
          onClose={() => this.setState({ modalOpenAvisoAceitar: false })}
        />
      </div>
    );
  }
}
