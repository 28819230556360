import React, { Component } from "react";
import {
  Container,
  Table,
  Button,
  Modal,
  Tab,
  Icon,
  Header,
  Menu,
  Card,
  Checkbox,
} from "semantic-ui-react";
import { Col, Row, Form, FormControl } from "react-bootstrap";
import { TextareaAutosize } from "@material-ui/core/";
import { Link } from "react-router-dom";
import moment from "moment";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

export default class ServicosLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseEnterObs: false,
      ultimaAlteracaoTermos: "",
      servicos_id: 0,
    };
    this.historicoAlteracao();
  }
  historicoAlteracao = async (servicos_id) => {
    let setUsuarios = new Set([]);
    if (this.props.historicoTeremosAltecao.data) {
      for (let i = 0; i < this.props.historicoTeremosAltecao.data.length; i++) {
        const element = this.props.historicoTeremosAltecao.data[i];
        if (element.servico_id === servicos_id) {
          setUsuarios.add(element.usuario_id);
        }
      }
      setUsuarios.add(2);
      const usuarioLogado = await api.post(
        "/usuario/visualizaVarios" + requisicoes.entidade,
        { listaId: [...setUsuarios] },
        requisicoes.header
      );

      let alteracao = "";
      for (let i = 0; i < this.props.historicoTeremosAltecao.data.length; i++) {
        const element = this.props.historicoTeremosAltecao.data[i];
        if (element.servico_id === servicos_id) {
          alteracao =
            "  " +
            usuarioLogado.data[0].nome +
            " - Dia: " +
            moment(element.data).format("DD/MM/YYYY") +
            " - Hora:  " +
            element.hora;
        }
      }
      this.setState({
        ultimaAlteracaoTermos: alteracao,
      });
    }
  };

  render() {
    const parametros = [
      {
        menuItem: "Dependencia de Efetivação",
        render: () => (
          <Tab.Pane>
            <Modal.Content scrolling>
              <Modal.Description>
                <Container textAlign="center">
                  <Header>Sem dependencia de Efetivação</Header>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Nome</Table.HeaderCell>
                        <Table.HeaderCell
                          width={1}
                          style={{ textAlign: "center" }}
                        >
                          <Icon name="check"></Icon>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.props.semParametrosReservaEfetivada.map((e, i) => (
                        <Table.Row key={i}>
                          <Table.Cell>{e.nome}</Table.Cell>
                          <Table.Cell width={1}>
                            <Modal
                              closeIcon
                              size="small"
                              trigger={
                                <Button
                                  type="button"
                                  name={e.id}
                                  size="tiny"
                                  positive
                                  icon="check"
                                ></Button>
                              }
                            >
                              <Header icon>
                                <Icon name="add" />
                                Criar Parametro
                              </Header>
                              <Modal.Content>
                                <label>Dias para o cancelamento</label>
                                {/* <formulario.Label></formulario.Label>*/}
                                <FormControl
                                  name="diasCancelamento"
                                  fluid
                                  value={this.props.diasCancelamento}
                                  onChange={this.props.alteracao}
                                  required
                                />
                              </Modal.Content>
                              <Modal.Actions>
                                <Button
                                  color="green"
                                  inverted
                                  onClick={() =>
                                    this.props.addReservaEfetivada(e.id)
                                  }
                                >
                                  <Icon name="checkmark" /> Criar Parametro
                                </Button>
                              </Modal.Actions>
                            </Modal>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                  <Header>Com dependencia de Efetivação</Header>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Nome</Table.HeaderCell>
                        <Table.HeaderCell
                          width={1}
                          style={{ textAlign: "center" }}
                        >
                          <Icon name="remove"></Icon>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.props.parametrosReservaEfetivada.map((e, i) => (
                        <Table.Row key={i}>
                          <Table.Cell>{e.nome}</Table.Cell>
                          <Table.Cell>
                            <Button
                              negative
                              icon="remove"
                              size="tiny"
                              onClick={() =>
                                this.props.excluirReservaEfetivada(e.id)
                              }
                            ></Button>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Container>
              </Modal.Description>
            </Modal.Content>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Termos e Condições",
        render: () => (
          <Tab.Pane>
            <Modal.Content scrolling>
              <Modal.Description>
                <Container textAlign="center"></Container>
                <Form style={{ display: "flex" }}>
                  <TextareaAutosize
                    style={{ width: "100%" }}
                    name="texto"
                    minRows={8}
                    placeholder="Termos e Condições"
                    onChange={this.props.alteracao}
                  />
                </Form>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions style={{ textAlign: "right", paddingTop: 10 }}>
              <Button
                color="green"
                onClick={() => this.props.addParametroTextoPorGrupo()}
                loading={this.props.salvando}
                disabled={this.props.salvando}
              >
                <Icon name="checkmark" /> Alterar
              </Button>
            </Modal.Actions>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Dependencia de Evento",
        render: () => (
          <Tab.Pane>
            <Modal.Content scrolling>
              <Modal.Description>
                <Container textAlign="center">
                  <Header>Sem dependencia de Evento</Header>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Nome</Table.HeaderCell>
                        <Table.HeaderCell
                          width={1}
                          style={{ textAlign: "center" }}
                        >
                          <Icon name="check"></Icon>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.props.semParametrosReservaEvento.map((e, i) => (
                        <Table.Row key={i}>
                          <Table.Cell>{e.nome}</Table.Cell>
                          <Table.Cell width={1}>
                            <Button
                              type="button"
                              name={e.id}
                              size="tiny"
                              positive
                              icon="check"
                              onClick={() => this.props.addReservaEvento(e.id)}
                            ></Button>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                  <Header>Com dependencia de Evento</Header>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Nome</Table.HeaderCell>
                        <Table.HeaderCell
                          width={1}
                          style={{ textAlign: "center" }}
                        >
                          <Icon name="remove"></Icon>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.props.parametrosReservaEvento.map((e, i) => (
                        <Table.Row key={i}>
                          <Table.Cell>{e.nome}</Table.Cell>
                          <Table.Cell>
                            <Button
                              negative
                              icon="remove"
                              size="tiny"
                              onClick={() =>
                                this.props.excluirReservaEvento(e.id)
                              }
                            ></Button>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Container>
              </Modal.Description>
            </Modal.Content>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Dias Liberados Reserva",
        render: () => (
          <Tab.Pane>
            <Modal.Content scrolling>
              <Modal.Description>
                <Table definition>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell />
                      <Table.HeaderCell>D</Table.HeaderCell>
                      <Table.HeaderCell>S</Table.HeaderCell>
                      <Table.HeaderCell>T</Table.HeaderCell>
                      <Table.HeaderCell>Q</Table.HeaderCell>
                      <Table.HeaderCell>Q</Table.HeaderCell>
                      <Table.HeaderCell>S</Table.HeaderCell>
                      <Table.HeaderCell>S</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {/* lista magica hehe */}
                    {this.props.for()}
                  </Table.Body>
                </Table>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions style={{ textAlign: "right", paddingTop: 10 }}>
              <Button
                color="green"
                // inverted
                onClick={() => this.props.addDiaReservaLiberadaPorGrupo()}
                loading={this.props.salvando}
                disabled={this.props.salvando}
              >
                <Icon name="checkmark" /> Alterar
              </Button>
            </Modal.Actions>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Dias da Semana",
        render: () => (
          <Tab.Pane>
            <Modal.Content scrolling>
              {this.props.listaDiasSemana.map((e, i) => (
                <Checkbox
                  label={e.nome}
                  style={{ paddingLeft: 15 }}
                  onChange={(event, a) => {
                    let lista = this.props.parametrosDiasDaSemanaGrupo;
                    lista[i] = a.checked;
                  }}
                  checked={this.props.parametrosDiasDaSemanaGrupo[i]}
                />
              ))}
            </Modal.Content>
            <Modal.Actions style={{ textAlign: "right", paddingTop: 10 }}>
              <Button
                color="green"
                // inverted
                onClick={() => this.props.alteraParametroDiasDaSemanaGrupo()}
                loading={this.props.salvando}
                disabled={this.props.salvando}
              >
                <Icon name="checkmark" /> Alterar
              </Button>
            </Modal.Actions>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Lista Convidados",
        render: () => (
          <Tab.Pane>
            <Modal.Content>
              <Container textAlign="center">
                <Header icon>
                  <Icon name="edit" />
                </Header>
                <h3 style={{ margin: 0 }}>Alterar Parâmetros de Convidados</h3>
                {/* <formulario.Label>Quantidade</formulario.Label>*/}
              </Container>
              <Container sm={12}>
                <Col>
                  <Row sm={4}>
                    <Col>
                      <label>Quantidade</label>
                      <FormControl
                        name="capacidadeGrupo"
                        value={this.props.capacidadeGrupo}
                        onChange={this.props.alteracao}
                        type="number"
                      />
                    </Col>
                    <Col sm={2} style={{ paddingLeft: 16, paddingTop: 20 }}>
                      <Button
                        color="green"
                        onClick={() => this.props.salvarParametroCapacidadeGrupo()}
                        disabled={this.props.salvando}
                        loading={this.props.salvando}
                      >
                        <Icon name="save" />
                      </Button>
                    </Col>
                    <Col sm={4} style={{ paddingLeft: 16, paddingTop: 28 }}>
                      <Checkbox
                        label='Exigir CPF'
                        onChange={(event, a) => this.props.alteraParametroExigeCPF(a.checked)}
                        checked={this.props.parametroExigeCPFConvidado}
                      />
                    </Col>
                  </Row>
                </Col>
              </Container>
            </Modal.Content>

            {/* <Modal.Actions
              style={{
                textAlign: "right",
                paddingTop: 10,
              }}
            >
              <Button
                color="green"
                onClick={() => this.props.salvarParametroCapacidadeGrupo()}
                disabled={this.props.salvando}
                loading={this.props.salvando}
              >
                <Icon name="checkmark" /> Salvar
              </Button>
            </Modal.Actions> */}
          </Tab.Pane>
        ),
      },
    ];

    /*
     *
     *
     *
     *
     *
     *
     *
     *
     *
     *
     *
     *
     *
     *
     *
     *
     */
    return (
      <div style={{ paddingTop: 10 }}>
        {this.props.servicos !== false ? (
          <div>
            {localStorage.getItem("tipo_usuario_clube") === "administrador" && (
              <Modal
                trigger={<Button circular icon="cogs"></Button>}
                size="large"
              >
                <Modal.Header>Parâmetros</Modal.Header>
                <Tab panes={parametros} />
              </Modal>
            )}

            <div style={{ textAlign: "right" }}>
              <Button
                color="blue"
                icon="th"
                size="small"
                content="Voltar"
                onClick={this.props.voltarServicos}
              ></Button>
            </div>

            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Nome</Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign="center">
                    <Link to={{ pathname: "/servico" }}>
                      <Button positive icon="add" size="tiny"></Button>
                    </Link>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{ textAlign: "center" }}>
                    <Icon name="cog"></Icon>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.listaServico.map((e, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{e.nome}</Table.Cell>
                    <Table.Cell width={1} textAlign="center">
                      <Link to={{ pathname: "/servico", query: { id: e.id } }}>
                        <Button primary icon="edit" size="tiny"></Button>
                      </Link>
                    </Table.Cell>
                    <Table.Cell width={1} textAlign="center">
                      {localStorage.getItem("tipo_usuario_clube") ===
                        "administrador" && (
                          <Modal
                            onClose={() => {
                              this.props.abreModalAlteracao(false);
                            }}
                            onOpen={() => {
                              this.props.abreModalAlteracao(true);
                              this.props.setState({
                                diasDistanciamento:
                                  e.diasDistanciamento[0]?.diasDistanciamento,
                                frequenciaDias:
                                  e.frequenciaDias[0]?.dias_frequencia,
                              });
                            }}
                            trigger={
                              <Button
                                type="button"
                                name={e.id}
                                onClick={() => this.historicoAlteracao(e.id)}
                                icon="cog"
                              ></Button>
                            }
                            size="large"
                          >
                            <Tab
                              panes={[
                                {
                                  menuItem: "Texto de Termos e Condições",
                                  render: () => (
                                    <Tab.Pane>
                                      <Modal.Content scrolling>
                                        <Modal.Description>
                                          <Form style={{ display: "flex" }}>
                                            <TextareaAutosize
                                              style={{ width: "100%" }}
                                              name="texto"
                                              minRows={8}
                                              defaultValue={e.texto}
                                              onChange={this.props.alteracao}
                                            />
                                          </Form>
                                        </Modal.Description>
                                      </Modal.Content>
                                      <Modal.Actions
                                        style={{
                                          textAlign: "right",
                                          paddingTop: 10,
                                        }}
                                      >
                                        {/* Quem Alterou */}
                                        <div style={{ textAlign: "left" }}>
                                          <Table.HeaderCell>
                                            {/* {this.state.historicoAlteracao(e.id)} */}
                                            Alterado por:
                                            {" " +
                                              this.state.ultimaAlteracaoTermos}
                                          </Table.HeaderCell>
                                          {/* aaa */}
                                        </div>
                                        <Button
                                          color="green"
                                          inverted
                                          onClick={() =>
                                            this.props.addParametroTexto(
                                              e.termos_id,
                                              e.id
                                            )
                                          }
                                          disabled={this.props.alterado === false}
                                        >
                                          <Icon name="checkmark" /> Alterar
                                        </Button>
                                      </Modal.Actions>
                                    </Tab.Pane>
                                  ),
                                },
                                {
                                  menuItem: "Dias Cancelamento",
                                  render: () => (
                                    <Tab.Pane>
                                      {e.parametro !== false ? (
                                        <Container textAlign="center">
                                          <Header icon>
                                            <Icon name="edit" />
                                            Alterar Dias para cancelamento
                                          </Header>
                                          <Modal.Content>
                                            {/* <formulario.Label>
                                            Dias para Cancelamento
                                          </formulario.Label>*/}
                                            <label>Dias para Cancelamento</label>
                                            <FormControl
                                              name="diasCancelamento"
                                              fluid
                                              value={e.diasCancelamento}
                                              onChange={this.alteracao}
                                            />
                                          </Modal.Content>
                                          <Modal.Actions
                                            style={{
                                              textAlign: "right",
                                              paddingTop: 10,
                                            }}
                                          >
                                            <Button
                                              color="green"
                                              inverted
                                              onClick={() =>
                                                this.props.editarParametro(
                                                  e.parametro_id
                                                )
                                              }
                                            >
                                              <Icon name="checkmark" /> Alterar
                                            </Button>
                                          </Modal.Actions>
                                        </Container>
                                      ) : (
                                        <Modal.Content>
                                          <Header>
                                            Adicione a lista de reservas efetivas
                                            para adicionar dias de cancelamento
                                          </Header>
                                        </Modal.Content>
                                      )}
                                    </Tab.Pane>
                                  ),
                                },
                                {
                                  menuItem: "Lista Convidados",
                                  render: () => (
                                    <Tab.Pane>
                                      <Container textAlign="center">
                                        <Header icon>
                                          <Icon name="edit" />
                                          Alterar Capacidade de Convidados
                                        </Header>
                                        <Modal.Content>
                                          {/*<formulario.Label>
                                          Quantidade
                                        </formulario.Label> */}

                                          <label>Quantidade</label>

                                          <FormControl
                                            name="capacidade"
                                            fluid
                                            defaultValue={e.capacidade}
                                            value={this.props.capacidade}
                                            onChange={this.props.alteracao}
                                          />
                                        </Modal.Content>
                                        <Modal.Actions
                                          style={{
                                            textAlign: "right",
                                            paddingTop: 10,
                                          }}
                                        >
                                          <Button
                                            color="green"
                                            inverted
                                            onClick={() =>
                                              this.props.salvarParametroCapacidade(
                                                e.parametro_id,
                                                e.id
                                              )
                                            }
                                          >
                                            <Icon name="checkmark" /> Salvar
                                          </Button>
                                        </Modal.Actions>
                                      </Container>
                                    </Tab.Pane>
                                  ),
                                },
                                {
                                  menuItem: "Libera Dias Para Reserva",
                                  render: () => (
                                    <Tab.Pane>
                                      <Modal.Content scrolling>
                                        <Modal.Description>
                                          <Table definition>
                                            <Table.Header>
                                              <Table.Row>
                                                <Table.HeaderCell />
                                                <Table.HeaderCell>
                                                  D
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                  S
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                  T
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                  Q
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                  Q
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                  S
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                  S
                                                </Table.HeaderCell>
                                              </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                              {/* lista magica hehe */}
                                              {this.props.forDiasPorServico(
                                                e.listaDiasReservaLiberada,
                                                e.id
                                              )}
                                            </Table.Body>
                                          </Table>
                                        </Modal.Description>
                                      </Modal.Content>
                                      <Modal.Actions
                                        style={{
                                          textAlign: "right",
                                          paddingTop: 10,
                                        }}
                                      >
                                        <Button
                                          color="green"
                                          inverted
                                          onClick={() =>
                                            this.props.addDiasReservaLiberadaServico(
                                              e.id
                                            )
                                          }
                                        >
                                          <Icon name="checkmark" /> Alterar
                                        </Button>
                                      </Modal.Actions>
                                    </Tab.Pane>
                                  ),
                                },
                                {
                                  menuItem: "Dias de Intervalo",
                                  render: () => (
                                    <Tab.Pane>
                                      <Container textAlign="center">
                                        <Header icon>
                                          <Icon name="edit" />
                                          Alterar Dias de Intervalo
                                        </Header>
                                        <Modal.Content>
                                          {/*<formulario.Label>
                                          Dias de Intervalo
                                        </formulario.Label> */}

                                          <label>Dias de Intervalo</label>

                                          <FormControl
                                            name="diasDistanciamento"
                                            fluid
                                            value={this.props.diasDistanciamento}
                                            type="number"
                                            onChange={this.props.alteracao}
                                          />

                                          <Row>
                                            <Col
                                              sm={12}
                                              onMouseEnter={() =>
                                                this.setState({
                                                  mouseEnterObs: true,
                                                })
                                              }
                                              onMouseLeave={() =>
                                                this.setState({
                                                  mouseEnterObs: false,
                                                })
                                              }
                                            >
                                              <h5
                                                style={
                                                  this.state.mouseEnterObs
                                                    ? { color: "black" }
                                                    : { color: "#d3d3d3" }
                                                }
                                              >
                                                {
                                                  "OBS: Se o parâmetro é 2 dias então é possível fazer uma reserva para até daqui a 2 dias"
                                                }
                                              </h5>
                                            </Col>
                                          </Row>
                                        </Modal.Content>
                                        <Modal.Actions
                                          style={{
                                            textAlign: "right",
                                            paddingTop: 10,
                                          }}
                                        >
                                          <Button
                                            color="green"
                                            inverted
                                            onClick={() =>
                                              this.props.addDiasDistanciamento(
                                                e.id,
                                                e.diasDistanciamento.id
                                              )
                                            }
                                          >
                                            <Icon name="checkmark" /> Alterar
                                          </Button>
                                        </Modal.Actions>
                                      </Container>
                                    </Tab.Pane>
                                  ),
                                },
                                {
                                  menuItem: "Frequência de reserva",
                                  render: () => (
                                    <Tab.Pane>
                                      <Container textAlign="center">
                                        <Header icon>
                                          <Icon name="edit" />
                                          Alterar frequência de dias
                                        </Header>
                                        <Modal.Content>
                                          {/*<formulario.Label>
                                          Frequência de dias
                                        </formulario.Label> */}

                                          <label>Frequência de dias</label>
                                          <FormControl
                                            name="frequenciaDias"
                                            fluid
                                            value={this.props.frequenciaDias}
                                            type="number"
                                            onChange={this.props.alteracao}
                                          />

                                          <Row>
                                            <Col
                                              sm={12}
                                              onMouseEnter={() =>
                                                this.setState({
                                                  mouseEnterObs: true,
                                                })
                                              }
                                              onMouseLeave={() =>
                                                this.setState({
                                                  mouseEnterObs: false,
                                                })
                                              }
                                            >
                                              <h5
                                                style={
                                                  this.state.mouseEnterObs
                                                    ? { color: "black" }
                                                    : { color: "#d3d3d3" }
                                                }
                                              >
                                                {
                                                  "OBS: Se o parâmetro é 5 dias então, após fazer uma reserva só será possível reservar novamente o serviço após 5 dias."
                                                }
                                              </h5>
                                            </Col>
                                          </Row>
                                        </Modal.Content>
                                        <Modal.Actions
                                          style={{
                                            textAlign: "right",
                                            paddingTop: 10,
                                          }}
                                        >
                                          <Button
                                            color="green"
                                            inverted
                                            onClick={() =>
                                              this.props.addDiasFrequencia(e.id)
                                            }
                                          >
                                            <Icon name="checkmark" /> Alterar
                                          </Button>
                                        </Modal.Actions>
                                      </Container>
                                    </Tab.Pane>
                                  ),
                                },
                              ]}
                            />
                          </Modal>
                        )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>

              <Table.Footer>
                <Table.Row verticalAlign="middle" textAlign="center">
                  <Table.HeaderCell colSpan={3}>
                    <Menu pagination>
                      <Menu.Item
                        icon
                        onClick={this.props.voltarPagina}
                        disabled={this.props.pagina === 1}
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item
                        icon
                        onClick={this.props.proximaPagina}
                        disabled={this.props.pagina === this.props.totalPaginas}
                      >
                        <Icon name="chevron right" />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </div>
        ) : (
          <Card.Group itemsPerRow={4}>
            {this.props.listaGrupoServico.map((e, i) => (
              <Card onClick={() => this.props.carregaListaServico(e.id)}>
                <Card.Content>
                  <Header>{e.descricao}</Header>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
        )}
      </div>
    );
  }
}
