import React, { Component } from "react";
import { Container } from "semantic-ui-react";
import Lista from "../../../components/paginaLista";
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

export default class EventoClube extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
    };
    this.lista();
  }

  lista = async () => {
    const resposta = await api.post(
      "/eventoClube/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ lista: resposta.data });
  };

  render() {
    return (
      <div className="pagina">
        <Lista
          title={null}
          columns={[{ name: "Titulo", key: "titulo" }]}
          list={this.state.lista}
          link="/eventoClube"
        />
      </div>
    );
  }
}
