/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Modal, Segment, Divider, Header, Table } from "semantic-ui-react";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment-timezone";
import SettingsIcon from "@material-ui/icons/Settings";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
// import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { Tooltip } from "@material-ui/core";
import ViewDayIcon from "@material-ui/icons/ViewDay";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";

import { Row, Col } from "react-bootstrap";

import "../styles.css";
import { Link } from "react-router-dom";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = { checkIniciar: false, listaPrimeiros: [] };
  }

  checkIniciar = () => {
    this.setState({ checkIniciar: !this.state.checkIniciar });
  };

  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Modal
          style={{ overflow: "visible" }}
          size="large"
          open={this.props.modalIniciarMataMata}
        >
          <Modal.Header>
            <IconButton
              onClick={() => this.props.changeModalIniciarMataMata(false)}
              className="bottomModal"
              color="secondary"
            >
              <CloseIcon />
            </IconButton>

            <h6>
              <strong> Iniciar Fase Mata-Mata</strong>
              <br />
              Os times Eliminados não poderam ser selecionados para as próximas
              rodadas
            </h6>
          </Modal.Header>

          <Modal.Content>
            <Segment>
              <Row>
                <Col>
                  <Tooltip
                    title={
                      <h5>
                        " Quantidade de times que passarão para a próxima fase"
                      </h5>
                    }
                    arrow
                    placement="right"
                  >
                    <TextField
                      style={{ paddingBottom: "1ch" }}
                      variant="standard"
                      name="timesProximaFase"
                      type="number"
                      label="Times por Grupo"
                      onChange={(event) => this.props.alteracao(event)}
                    >
                      {" "}
                    </TextField>
                  </Tooltip>
                </Col>
                <Col></Col>
              </Row>
            </Segment>

            <Row>
              {this.props.infoTimesTabela.map((element, index) => {
                return (
                  <Col>
                    <Segment>
                      <Header>
                        {" "}
                        <div className="flexSpaceBetween">
                          <div>{" GRUPO " + (index + 1)}</div>
                        </div>
                        <Divider style={{ marginTop: "-0.13ch" }} />
                      </Header>
                      <Table selectable>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Times</Table.HeaderCell>
                            <Table.HeaderCell>pontos</Table.HeaderCell>

                            <Table.HeaderCell>jogos</Table.HeaderCell>
                            <Table.HeaderCell>gols</Table.HeaderCell>
                            <Table.HeaderCell>vitorias</Table.HeaderCell>
                            <Table.HeaderCell>empates</Table.HeaderCell>
                            <Table.HeaderCell>derrotas</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {element.map((elmt, indx) => {
                            return (
                              <Table.Row
                                className={
                                  this.props.timesProximaFase > indx
                                    ? "rowClassficado"
                                    : "rowEliminado"
                                }
                                style={{
                                  color: "rgb(9, 124, 165)",
                                }}
                              >
                                <Table.Cell> {elmt.nome_time}</Table.Cell>
                                <Table.Cell>{elmt.pontuacao}</Table.Cell>

                                <Table.Cell>{elmt.jogos}</Table.Cell>
                                <Table.Cell>{elmt.gols}</Table.Cell>
                                <Table.Cell>{elmt.vitorias}</Table.Cell>
                                <Table.Cell>{elmt.empates}</Table.Cell>
                                <Table.Cell>{elmt.derrotas}</Table.Cell>
                              </Table.Row>
                            );
                          })}
                        </Table.Body>
                      </Table>
                    </Segment>
                  </Col>
                );
              })}
            </Row>
          </Modal.Content>
          <Modal.Actions>
            <Col>
              <div className="flexSpaceBetween">
                <div className="flexStart">
                  <div>
                    <IconButton
                      edge="start"
                      style={{ outline: "none", color: "green" }}
                    >
                      <ViewDayIcon style={{ outline: "none" }} />
                    </IconButton>
                    <strong> Classificado</strong>
                  </div>
                  <div>
                    <IconButton style={{ outline: "none", color: "red" }}>
                      <ViewDayIcon />
                    </IconButton>
                    <strong> Eliminado</strong>
                  </div>
                </div>

                <div className="flexEnd">
                  <FormControl component="fieldset">
                    <FormGroup>
                      <FormControlLabel
                        style={{ paddingTop: "1ch" }}
                        control={
                          <Switch
                            size="medium"
                            name="checkEtapa"
                            id="checkEtapa"
                            checked={this.props.checkIniciar}
                            onChange={() => this.props.checkIniciarMataMata()}
                          />
                        }
                        label="Estou ciente"
                      ></FormControlLabel>
                    </FormGroup>
                  </FormControl>
                  <Button
                    style={{ outline: "none", color: "darkblue" }}
                    disabled={
                      !this.props.checkIniciar ||
                      this.props.timesProximaFase <= 0
                    }
                    onClick={() => this.props.selecionaClassificados()}
                  >
                    {" Confirmar "}
                  </Button>
                </div>
              </div>
            </Col>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
