import React, { Component } from "react";

import { Row, Col } from "react-bootstrap";
import { Tabs, Tab } from "@material-ui/core";

import Geral from "./lista";
import FluxoDeCaixa from "./fluxoDeCaixa";

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selecionado: "geral",
    };
  }

  changeTabMenu = async (event, newValue) => {
    localStorage.setItem("pagina_anterior_contabil_relatorio", newValue);
    this.setState({ selecionado: newValue });
  };

  render() {
    return (
      <>
        <Row style={{ paddingBottom: 10 }}>
          <Col sm={12}>
            <Tabs
              indicatorColor="secondary"
              textColor="primary"
              style={{ outline: "none" }}
              variant="scrollable"
              onChange={this.changeTabMenu}
              value={this.state.selecionado}
            >
              <Tab
                style={{ outline: "none" }}
                label="Geral"
                value={"geral"}
              ></Tab>
              <Tab
                style={{ outline: "none" }}
                label="Fluxo de caixa"
                value={"fluxo_de_caixa"}
              ></Tab>
            </Tabs>
          </Col>
        </Row>
        <TabPanel
          value={this.state.selecionado}
          index={"geral"}
          {...this.props}
        >
          <Geral />
        </TabPanel>
        <TabPanel
          value={this.state.selecionado}
          index={"fluxo_de_caixa"}
          {...this.props}
        >
          <FluxoDeCaixa />
        </TabPanel>
      </>
    );
  }
}

function TabPanel(props) {
  const { children, value, index, submenus, ...other } = props;
  return (
    <div hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
}
