export default [
  { value: "2", label: "2x" },
  { value: "3", label: "3x" },
  { value: "4", label: "4x" },
  { value: "5", label: "5x" },
  { value: "6", label: "6x" },
  { value: "7", label: "7x" },
  { value: "8", label: "8x" },
  { value: "9", label: "9x" },
  { value: "10", label: "10x" },
  { value: "11", label: "11x" },
  { value: "12", label: "12x" },
]