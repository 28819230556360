import React, { Component } from "react";
import { Row, Col, FormControl, Form } from "react-bootstrap";
import { Header, Button, Divider, Segment, Modal } from "semantic-ui-react";
import Select from "react-select";
import { Paper } from "@material-ui/core";
import moment from "moment-timezone";
import { withRouter } from "react-router-dom";

class ModalVisualizaTransferencia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
    };
  };

  render() {
    return (
      <Form>
        <Modal
          open={this.props.modal_view_transferencia}
          onClose={this.props.handleCloseTransferencia}
          centered={true}
          style={{padding: 20}}
        >
          <div className="pagina">
            <Header as="h1" dividing>
              Transferência de Titularidade
            </Header>
            <Form>
              <Paper style={{ padding: 20 }}>
                <Header as="h3">Pessoa - Titular da Venda</Header>
                <Row>
                  <Col sm={6}>
                    <Form.Label>Pessoa</Form.Label>
                    <FormControl
                      placeholder="Digite para buscar"
                      name="pessoaVenda"
                      value={this.props.pessoa_venda.nome}
                      disabled
                    />
                  </Col>

                  <Col sm={6}>
                    <Form.Label>Perfil</Form.Label>
                    <Form.Control
                      placeholder="Tipo"
                      as="select"
                      name="tipo_usuario"
                      value={'titular'}
                      required
                      disabled
                    >
                      <option></option>
                      <option value="titular">Titular</option>
                      <option value="dependente">Dependente</option>
                      <option value="administrador">Administrador</option>
                      <option value="temporario">Temporário</option>
                      <option value="funcionario">Funcionário</option>
                      <option value="caixa">Caixa</option>
                      <option value="medico">Médico</option>
                      <option value="portaria">Acesso</option>
                      <option value="almoxarifado">Almoxarifado</option>
                      <option value="administrativo">Administrativo</option>
                    </Form.Control>
                  </Col>

                  {this.state.tipo_usuario === "funcionario" && (
                    <Col sm={6}>
                      <Form.Label>Função</Form.Label>
                      <FormControl
                        placeholder="Função"
                        aria-describedby="basic-addon1"
                        name="funcao"
                        onChange={this.alteracao}
                        required
                        defaultValue={this.state.funcao}
                      />
                    </Col>
                  )}

                  {this.props.pessoa_venda !== null && (
                    <Col sm={12} style={{ paddingTop: "10px" }}>
                      <Header as="h3">Dados Cadastrais</Header>
                      <Segment.Group>
                        <Segment.Group horizontal>
                          <Segment>
                            <b>CPF/CNPJ:</b>{" "}
                            {this.props.pessoa_venda.cpf !== null
                              ? this.props.pessoa_venda.cpf
                              : this.props.pessoa_venda.cnpj}
                          </Segment>
                          <Segment>
                            <b>Telefone:</b> {this.props.pessoa_venda.telefone}
                          </Segment>
                          <Segment>
                            <b>E-mail:</b> {this.props.pessoa_venda.email}
                          </Segment>
                        </Segment.Group>
                        <Segment>
                          <b>Endereço:</b> {this.props.pessoa_venda.endereco} -{" "}
                          {this.props.pessoa_venda.bairro},{" "}
                          {this.props.pessoa_venda.cep} {this.props.pessoa_venda.cidade}
                          -{this.props.pessoa_venda.estado}
                        </Segment>
                      </Segment.Group>
                    </Col>
                  )}

                  {this.state.tipo_usuario === "dependente" && (
                    <Col sm={12} style={{ paddingTop: "10px" }}>
                      <Header as="h2">Titular</Header>
                      <Divider />
                      <Segment.Group>
                        <Segment.Group horizontal>
                          <Segment>
                            <Col sm={6}>
                              <Select
                                placeholder="Digite para buscar"
                                name="usuario"
                                onKeyDown={this.pesquisaUsuario}
                                onChange={this.alteracaoSelectUsuario}
                                value={this.state.usuario}
                                options={this.state.listaUsuario}
                              />
                            </Col>
                          </Segment>
                        </Segment.Group>
                      </Segment.Group>
                    </Col>
                  )}
                </Row>
              </Paper>
              <Paper style={{ padding: 20, marginTop: 20 }}>
                <Header as="h3">Pessoa - Titular da Compra</Header>
                <Row>
                  <Col sm={6}>
                    <Form.Label>Pessoa</Form.Label>
                    <FormControl
                      placeholder="Digite para buscar"
                      name="pessoaCompra"
                      value={this.props.pessoa_compra.nome}
                      disabled
                    />
                  </Col>

                  <Col sm={6}>
                    <Form.Label>Perfil</Form.Label>
                    <Form.Control
                      placeholder="Tipo"
                      as="select"
                      name="tipo_usuario"
                      value={'titular'}
                      required
                      disabled
                    >
                      <option></option>
                      <option value="titular">Titular</option>
                      <option value="dependente">Dependente</option>
                      <option value="administrador">Administrador</option>
                      <option value="temporario">Temporário</option>
                      <option value="funcionario">Funcionário</option>
                      <option value="caixa">Caixa</option>
                      <option value="medico">Médico</option>
                      <option value="portaria">Acesso</option>
                      <option value="almoxarifado">Almoxarifado</option>
                      <option value="administrativo">Administrativo</option>
                    </Form.Control>
                  </Col>

                  {this.props.pessoa_compra !== null && (
                    <Col sm={12} style={{ paddingTop: "10px" }}>
                      <Header as="h3">Dados Cadastrais</Header>

                      <Segment.Group>
                        <Segment.Group horizontal>
                          <Segment>
                            <b>CPF/CNPJ:</b>{" "}
                            {this.props.pessoa_compra.cpf !== null
                              ? this.props.pessoa_compra.cpf
                              : this.props.pessoa_compra.cnpj}
                          </Segment>
                          <Segment>
                            <b>Telefone:</b> {this.props.pessoa_compra.telefone}
                          </Segment>
                          <Segment>
                            <b>E-mail:</b> {this.props.pessoa_compra.email}
                          </Segment>
                        </Segment.Group>
                        <Segment>
                          <b>Endereço:</b> {this.props.pessoa_compra.endereco} -{" "}
                          {this.props.pessoa_compra.bairro},{" "}
                          {this.props.pessoa_compra.cep} {this.props.pessoa_compra.cidade}
                          -{this.props.pessoa_compra.estado}
                        </Segment>
                      </Segment.Group>
                    </Col>
                  )}
                </Row>
              </Paper>
              {this.props.acao !== null && this.props.acao !== undefined && (
                <Paper style={{ padding: 20, marginTop: 20 }}>
                  <Row>
                    <Col sm={12} style={{ paddingTop: "10px" }}>
                      <Header as="h3">Dados do Título</Header>

                      <Segment.Group>
                        <Segment.Group horizontal>
                          <Segment>
                            <b>Tipo Título:</b>
                            <FormControl
                              placeholder="TipoTitulo"
                              name="tipoTitulo"
                              value={this.props.acao.tipo_titulo && this.props.acao.tipo_titulo.descricao}
                              id="TipoTitulo"
                              disabled
                            />
                          </Segment>
                          <Segment>
                            <b>Numero do Título:</b> {this.props.acao.codigo} <br />
                            <br />
                            <b>Valor Mensalidade:</b> R$ {this.props.acao.tipo_titulo !== null && this.props.acao.tipo_titulo.valor_mensalidade}
                          </Segment>
                          <Segment>
                            <b>
                              {this.props.pessoa_compra === true
                                ? "Data Transferência:"
                                : "Data Emissão:"}{" "}
                            </b>{" "}
                            {this.props.pessoa_compra === true ? (
                              <FormControl
                                aria-describedby="basic-addon1"
                                type="date"
                                name="data_transferencia"
                                defaultValue={this.props.pessoa_compra === true
                                    ? moment(this.props.acao.historico_acoes.last.data_transferencia).format(
                                      "YYYY-MM-DD"
                                    ) : moment(this.props.acao.data_emissao).format(
                                      "YYYY-MM-DD"
                                    )
                                }
                              />
                            ) : (
                              ""
                            )}
                            <br />
                            <br />
                            <b>Valor Titulo:</b> R$ {this.props.acao.tipo_titulo !== null &&
                              this.props.acao.tipo_titulo.valor_titulo}
                          </Segment>
                        </Segment.Group>
                      </Segment.Group>
                    </Col>
                  </Row>
                </Paper>
              )}
              <Segment clearing>
                <Header floated="right">
                  <Button type="button" onClick={this.props.handleCloseTransferencia}>
                    Fechar
                  </Button>
                </Header>
              </Segment>
            </Form>
          </div>
        </Modal>
      </Form>
    );
  }
}
export default withRouter(ModalVisualizaTransferencia);
