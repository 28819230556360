import React, { Component } from "react";
import { Table, Button } from "semantic-ui-react";
import { Pagination } from "@material-ui/core";
import { Col, Row, Form } from "react-bootstrap";
import moment from "moment";

import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

export default class Reservas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Inputs
      data_inicial: moment().startOf("month").format("YYYY-MM-DD"),
      data_final: moment().format("YYYY-MM-DD"),
      nome_convidado: "",
      totalPaginas: 0,
      activePage: 1,

      // Dados
      listaConvidados: [],
    };
  }

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  buscarConvidado = async (event, activePage = 1) => {
    event.preventDefault();
    const convidados = await api.post(
      "/presencaReserva/buscaPresencaReserva" + requisicoes.entidade,
      {
        data_inicial: this.state.data_inicial,
        data_final: this.state.data_final,
        nome: this.state.nome_convidado,
        activePage: activePage,
      },
      requisicoes.header
    );
    this.setState({
      listaConvidados: convidados.data.docs,
      totalPaginas: convidados.data.pages,
      activePage: activePage,
    });
  };
  imprimirOnClick = async (event) => {
    event.preventDefault();
    const convidados = await api.post(
      "/presencaReserva/gerarPdfComprovanteReserva" + requisicoes.entidade,
      {
        data_inicial: this.state.data_inicial,
        data_final: this.state.data_final,
        nome: this.state.nome_convidado,
      },
      requisicoes.header
    );

    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    const r = await api.get(
      "/presencaReserva/buscaPdfComprovanteReserva" +
        requisicoes.entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.id
    );
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "presencaReserva/buscaPdfComprovanteReserva" +
      requisicoes.entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.id;

    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };
  render() {
    return (
      <div>
        <Form onSubmit={this.buscarConvidado}>
          <Row>
            <Col sm={2}>
              <Form.Label>Data Inicio</Form.Label>
              <Form.Control
                placeholder="Data Inicio"
                aria-describedby="basic-addon1"
                type="date"
                name="data_inicial"
                value={this.state.data_inicial}
                onChange={this.alteracao}
                required
              />
            </Col>
            <Col sm={2}>
              <Form.Label>Data Final</Form.Label>
              <Form.Control
                placeholder="Data Final"
                aria-describedby="basic-addon1"
                type="date"
                name="data_final"
                value={this.state.data_final}
                onChange={this.alteracao}
                required
              />
            </Col>
            <Col>
              <Form.Label>Nome do convidado</Form.Label>
              <Form.Control
                placeholder="Nome do convidado"
                aria-describedby="basic-addon1"
                name="nome_convidado"
                value={this.state.nome_convidado}
                onChange={this.alteracao}
                width=""
              />
            </Col>
            <Col md="auto">
              <Button
                type="submit"
                positive
                style={{ marginTop: "25px", float: "right" }}
              >
                Buscar
              </Button>
            </Col>
            <Col sm="auto">
              <Button
                //  type=""
                onClick={this.imprimirOnClick}
                primary
                style={{ marginTop: "25px", float: "right" }}
              >
                Imprimir
              </Button>
            </Col>
          </Row>
        </Form>

        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Convidado</Table.HeaderCell>
              <Table.HeaderCell>Quem reservou</Table.HeaderCell>
              <Table.HeaderCell>Data Reserva</Table.HeaderCell>
              <Table.HeaderCell>Serviço</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.listaConvidados.map((e) => (
              <Table.Row>
                <Table.Cell>{e.nome}</Table.Cell>
                <Table.Cell>{e.reserva_horario.pessoa.nome}</Table.Cell>
                <Table.Cell>
                  {e.reserva_horario.dataReserva &&
                    moment(e.reserva_horario.dataReserva).format("DD/MM/YYYY")}
                </Table.Cell>
                <Table.Cell>
                  {e.reserva_horario.horario_servico.servico.nome}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer sm={12}>
            <Table.Row>
              <Table.HeaderCell colSpan={6}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Pagination
                    count={this.state.totalPaginas}
                    variant="outlined"
                    shape="rounded"
                    boundaryCount={1}
                    page={this.state.activePage}
                    onChange={this.buscarConvidado}
                  />
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}
