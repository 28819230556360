import React, { Component } from "react";
import api from "../../../../services/api";
import { Table, Button, Menu, Icon } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { format, addDays } from "date-fns";
import requisicoes from "../../../../utils/Requisicoes";
import { TextField } from "@material-ui/core";

export default class ExameFisicoLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      pagina: 1,
      totalPaginas: 1,
      pesquisa: null,
    };
    this.lista();
  }

  lista = async (page = 1) => {
    api
      .post(
        "/exameFisico/lista" + requisicoes.entidade + "&pagina=" + page,
        "",
        requisicoes.header
      )
      .then((resp) => {
        const { exames, pagina, totalPaginas } = resp.data;
        this.setState({ lista: exames, pagina, totalPaginas });
      });
  };

  proximaPagina = async () => {
    const { pagina, totalPaginas } = this.state;
    if (pagina >= totalPaginas) return;
    const paginaNumero = parseInt(pagina) + 1;
    this.listaExame(paginaNumero);
  };

  voltarPagina = async () => {
    const { pagina } = this.state;
    if (pagina <= 1) return;
    const paginaNumero = pagina - 1;
    this.listaExame(paginaNumero);
  };

  // pesquisaPessoa = (newValue) => {
  //   api
  //     .post(
  //       "/exameFisico/buscaPorPessoa" + requisicoes.entidade,
  //       { pesquisa: newValue },
  //       requisicoes.header
  //     )
  //     .then((resp) => {
  //       this.setState({ lista: resp.data });
  //     });
  // };

  pesquisaAsyncPessoa = async () => {
    await api
      .post(
        "/exameFisico/buscaPorPessoa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaPessoa").value },
        requisicoes.header
      )
      .then(async (resp) => {
        this.setState({ lista: resp.data });
      });
  };

  render() {
    return (
      <div className="lista">
        <Row>
          {/* <Col sm={1}>
            <Form.Label id="porcentagem"></Form.Label>
          </Col> */}
          <Col sm={6}>
            <TextField
              variant="standard"
              fullWidth
              placeholder="Digite para buscar"
              name="pesquisa"
              // onInputChange={this.pesquisaPessoa}
              onKeyDown={this.pesquisaAsyncPessoa}
              id="pesquisaPessoa"
              // menuIsOpen={false}
            />
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Pessoa</Table.HeaderCell>
              <Table.HeaderCell>Data Inicio</Table.HeaderCell>
              <Table.HeaderCell>Data Final</Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <Link to={{ pathname: "/exameFisico" }}>
                  <Button positive icon="add" size="tiny"></Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.lista !== null &&
              this.state.lista.map(
                (e, i) =>
                  e.pessoa !== null && (
                    <Table.Row key={i}>
                      <Table.Cell>{e.pessoa.nome}</Table.Cell>
                      <Table.Cell>
                        {format(
                          addDays(new Date(e.dataExame), 1),
                          "dd/MM/yyyy"
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {format(
                          addDays(new Date(e.dataValidade), 1),
                          "dd/MM/yyyy"
                        )}
                      </Table.Cell>
                      <Table.Cell width={1}>
                        <Link
                          to={{
                            pathname: "/exameFisico",
                            query: { id: e.id },
                          }}
                        >
                          <Button primary icon="edit" size="tiny"></Button>
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  )
              )}
          </Table.Body>
          <Table.Footer>
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan={4}>
                <Menu pagination>
                  <Menu.Item
                    icon
                    onClick={this.voltarPagina}
                    disabled={this.state.pagina === 1}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  <Menu.Item
                    icon
                    onClick={this.proximaPagina}
                    disabled={this.state.pagina === this.state.totalPaginas}
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}
