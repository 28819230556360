/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Table, Segment, Divider, Header } from "semantic-ui-react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { Tooltip } from "@material-ui/core";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SettingsIcon from "@material-ui/icons/Settings";
import IconButton from "@material-ui/core/IconButton";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Campeao from "./campeao.ico";
import Medalha from "./medalha.png";
import Pergaminho from "./pergaminho.png";

import { Link } from "react-router-dom";

import "../styles.css";
import "../fireWORK.css";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  pontuacao(info) {
    var pontosPorEmpate = this.props.pontosPorEmpate;
    var pontosPorVitoria = this.props.pontosPorVitoria;

    var empate = 0;
    var vitoria = 0;
    var pontuacao = 0;
    for (let index = 0; index < info.equipe.placares.length; index++) {
      if (info.equipe.placares[index].empate === true) {
        empate += 1;
      }
      if (info.equipe.placares[index].colocacao === 1) {
        vitoria += 1;
      }
    }

    pontuacao =
      parseInt(vitoria) * pontosPorVitoria + parseInt(empate) * pontosPorEmpate;

    return pontuacao;
  }

  gols(info) {
    var gols = 0;
    for (let index = 0; index < info.equipe.placares.length; index++) {
      gols += info.equipe.placares[index].pontuacao;
    }

    return gols;
  }
  jogos(info) {
    var jogos = 0;

    for (let index = 0; index < info.equipe.placares.length; index++) {
      if (
        info.equipe.placares[index].colocacao ||
        info.equipe.placares[index].empate
      ) {
        jogos += 1;
      }
    }

    return jogos;
  }

  render() {
    return (
      <div
        className="paginaSemPaddingTop"
        style={{ marginTop: "-6ch", marginBottom: "-1ch" }}
      >
        <Row>
          {this.props.infoTimesTabela.map((element, index) => {
            return (
              <Col>
                <Segment>
                  <Header>
                    {" "}
                    <div className="flexSpaceBetween">
                      <div>{" GRUPO " + (index + 1)}</div>
                    </div>
                    <Divider style={{ marginTop: "-0.13ch" }} />
                  </Header>
                  <Table selectable color="blue">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Times</Table.HeaderCell>

                        <Table.HeaderCell>Pontos</Table.HeaderCell>
                        <Table.HeaderCell>Jogos</Table.HeaderCell>
                        <Table.HeaderCell>Gols</Table.HeaderCell>

                        <Table.HeaderCell>Vitorias</Table.HeaderCell>
                        <Table.HeaderCell>Empates</Table.HeaderCell>
                        <Table.HeaderCell>Derrotas</Table.HeaderCell>
                        <Table.HeaderCell>Aproveit.</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {element.map((elmt, indx) => {
                        return (
                          <Table.Row
                            style={{
                              color: "rgb(9, 124, 165)",
                              backgroundColor: "rgba(73, 105, 186, 0.01)",
                            }}
                          >
                            <Table.Cell>{elmt.nome_time}</Table.Cell>
                            <Table.Cell>{elmt.pontuacao}</Table.Cell>
                            <Table.Cell>{elmt.jogos}</Table.Cell>

                            <Table.Cell>{elmt.gols}</Table.Cell>
                            <Table.Cell>{elmt.vitorias}</Table.Cell>
                            <Table.Cell>{elmt.empates}</Table.Cell>
                            <Table.Cell>{elmt.derrotas}</Table.Cell>
                            <Table.Cell>{elmt.aproveitamento + "%"}</Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                </Segment>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}
