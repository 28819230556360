import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import {
  CircularProgress,
  Divider,
  // TextField,
  Select,
  Button,
  InputLabel,
  FormControl,
  // IconButton,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
// import { Row, Col, Form, FormControl } from "react-bootstrap";

import { LoadingButton } from "@material-ui/lab";
import { Save } from "@material-ui/icons/";
// import Select from "react-select";

import { Row, Col, Form } from "react-bootstrap";

// libs
// import moment from "moment-timezone";

// requisicoes
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

export default class ModalAlteraTurma extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // loading modal
      loadingModalVisualiza: false,

      // inputs
      openteste: false,
      lecionaTurma: "",
      // loading/disable button
      salvando: false,
      disabledSalvar: true,
    };
  }

  salvarAlunoTurna = async (event, a, b) => {
    this.state.salvando = true;
    await api.post(
      "/matriculaTurma/alteraAlunoTurma" + requisicoes.entidade,
      { id: this.props.turma.id, novaTurna: this.state.lecionaTurma },
      requisicoes.header
    );
  };

  changeSelectTurma = async (event) => {
    this.state.lecionaTurma = event.target.value;
    this.setState({ lecionaTurma: event.target.value });
  };

  render() {
    return (
      <Modal open={this.props.open} size="large">
        {this.props.turma && (
          <>
            <Modal.Header>
              <Row>
                <Col md="auto">Aluno:</Col>
                <Col>
                  {this.props.turma.pessoa + " - " + this.props.turma.turma}
                </Col>
              </Row>
            </Modal.Header>
            <Modal.Content>
              {this.state.loadingModalVisualiza ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 50,
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <Row>
                    <Form.Label>Aluno</Form.Label>
                    <Col>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Trocar de Turma
                        </InputLabel>
                        <Select
                          fullWidth
                          required
                          name="grupoMuscular"
                          placeholder="Digite para buscar"
                          value={this.lecionaTurma}
                          label={"Trocar de Turma"}
                          onChange={(event) => this.changeSelectTurma(event)}
                        >
                          {this.props?.quantidade_Turma.map((element) => {
                            if (element.turma !== this.props.turma.turma) {
                              return (
                                <MenuItem value={element.id}>
                                  {element.nome + " - " + element.turma}
                                </MenuItem>
                              );
                            }
                          })}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                  <Divider style={{ paddingTop: 15 }} />

                  <Divider style={{ marginBottom: 15 }} />

                  <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Col md="auto">
                      <Button
                        variant="contained"
                        color="inherit"
                        onClick={this.props.onClose}
                      >
                        Voltar
                      </Button>
                    </Col>
                    <Col md="auto">
                      <Tooltip title={"Salva a alteração da Turma"}>
                        <div>
                          <LoadingButton
                            startIcon={<Save />}
                            variant="contained"
                            onClick={async () => {
                              this.setState({ loading: true });

                              setTimeout(() => {
                                this.salvarAlunoTurna();
                                this.props.buscaMatriculados();
                                this.props.onClose();
                                this.setState({ loading: false });
                              }, 2800);
                            }}
                            loading={this.state.loading}
                          >
                            Salvar
                          </LoadingButton>
                        </div>
                      </Tooltip>
                    </Col>
                  </Row>
                </>
              )}
            </Modal.Content>
          </>
        )}
      </Modal>
    );
  }
}
