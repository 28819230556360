import React, { Component } from "react";
import { Container, Header, Menu, Icon } from "semantic-ui-react";

import ListaTransferenciasTitularidade from "./titularidade/lista";
import ListaTransferenciasTipoTitulo from "./tipoTitulo/lista";

export default class MenuTransferencias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo_clube: window.localStorage.getItem("tipo_clube"),
      // selectedMenu: localStorage.getItem("pagina_anterior"),
      selectedMenu: "titularidade",
    };
  }

  render() {
    let content = "";
    switch (this.state.selectedMenu) {
      case "titularidade":
        // localStorage.setItem("pagina_anterior", "cancelar");
        content = <ListaTransferenciasTitularidade />;
        break;
      case "tipoTitulo":
        // localStorage.setItem("pagina_anterior", "cancelar");
        content = <ListaTransferenciasTipoTitulo />;
        break;
      default:
        break;
    }
    return (
      <div>
        {/* <Header as="h1" dividing>
          Gerenciar dívidas
        </Header> */}
        <Menu secondary pointing>
          <Menu.Item
            name="titularidade"
            active={this.state.selectedMenu === "titularidade"}
            onClick={this.selectMenu.bind(null, "titularidade")}
          >
            {/* <Icon name="calendar alternate outline" /> */}
            Transferência de Titularidade
          </Menu.Item>
          <Menu.Item
            name="tipoTitulo"
            active={this.state.selectedMenu === "tipoTitulo"}
            onClick={this.selectMenu.bind(null, "tipoTitulo")}
          >
            {/* <Icon name="calendar alternate outline" /> */}
            Transferência de Tipo Titulo
          </Menu.Item>
        </Menu>
        {content}
      </div>
    );
  }
  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
  };
}
