import React, { Component } from "react";
import { Table, Button, Checkbox, Input, Select } from "semantic-ui-react";
import { Row, Col, Form } from "react-bootstrap";
import ReactSelect from "react-select";
import Pagination from "@material-ui/lab/Pagination";

// import moment from "moment-timezone";

import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // dados inputs pelo user

      // options

      //dados da tela
      titulosDisponivel: [],

      //estados de controle
      optionsSelected: [],
      buscando: false,
      buscaLista: [],
      totalPages: 0,
      dadosPorPagina: 30,
      activePage: 1,
      pagina: 1,
    };
    this.buscaTituloComDividas();
  }

  imprimirTituloComDividas = async (parcelas, b, activePage = 1) => {
    await api.post(
      "/tipoTitulo/gerarPdfTitulosComDividas" + requisicoes.entidade,
      { lista: this.state.optionsSelected },
      requisicoes.header
    );
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");

    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    const r = await api.get(
      "/tipoTitulo/buscarArquivoPdfTitulosComDividas" +
        requisicoes.entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.id
    );
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "tipoTitulo/buscarArquivoPdfTitulosComDividas" +
      requisicoes.entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.id;

    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  buscaTituloComDividas = async (parcelas, b, activePage = 1) => {
    this.setState({ buscando: true });

    let titulosComDividas = await api.post(
      "/tipoTitulo/listaTituloComDividas" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    for (let i = 0; i < titulosComDividas.data[1].rows.length; i++) {
      const element = titulosComDividas.data[1].rows[i];

      this.state.buscaLista.push({
        value: element.pessoa_id,
        label: element.nome,
      });
    }

    const dadosPorPagina = this.state.dadosPorPagina;

    this.setState({
      buscando: false,
      titulosDisponiveis: titulosComDividas.data,
      titulosDisponivel: titulosComDividas.data[1].rows,
      // .slice(
      //   0,
      //   titulosDisponivel.data.length < dadosPorPagina
      //     ? titulosDisponivel.data.length
      //     : dadosPorPagina
      // ),
      // activePage: 1,
      // totalPages: Math.ceil(titulosDisponivel.data.length / dadosPorPagina),
    });
    return this.setState({ buscando: false });
  };
  onChangeSelectItem = (e, a) => {
    if (a.action === "clear") {
      let lista = this.state.buscaLista;
      for (let i = 0; i < lista.length; i++) {
        const element = lista[i];
        element.selected = false;
      }

      this.setState({
        buscaLista: lista,
        optionsSelected: [],
        selectTodos: false,
      });
      return;
    }
    if (a.action === "pop-value" && a.removedValue === undefined) {
      return;
    }
    if (a.removedValue !== undefined) {
      a.option = a.removedValue;
    }

    if (a.option.value === 0) {
      if (a.action === "select-option") {
        let lista2 = this.state.buscaLista;
        for (let i = 0; i < lista2.length; i++) {
          const element = lista2[i];
          element.selected = true;
        }

        this.setState({ buscaLista: lista2, selectTodos: true });
      }
      if (
        a.action === "deselect-option" ||
        a.action === "pop-value" ||
        a.action === "remove-value"
      ) {
        let lista2 = this.state.buscaLista;
        const optionsSelected = this.state.optionsSelected;

        for (let i = 0; i < lista2.length; i++) {
          const element = lista2[i];
          if (optionsSelected.findIndex((e) => e.value === element.id) !== -1) {
            element.selected = true;
          } else {
            element.selected = false;
          }
        }

        this.setState({ buscaLista: lista2, selectTodos: false });
      }
    }
    if (a.action === "select-option") {
      let lista = this.state.optionsSelected;
      lista.push(a.option);
      let lista2 = this.state.buscaLista;
      let index = lista2.findIndex(
        (element) => element.value === a.option.value
      );
      lista2[index].selected = true;

      this.setState({
        optionsSelected: lista,
        buscaLista: lista2,
      });
    }
    if (
      a.action === "deselect-option" ||
      a.action === "pop-value" ||
      a.action === "remove-value"
    ) {
      let lista = this.state.optionsSelected;
      let index0 = lista.findIndex(
        (element) => element.value === a.option.value
      );
      lista.splice(index0, 1);
      let lista2 = this.state.buscaLista;
      let index = lista2.findIndex(
        (element) => element.value === a.option.value
      );
      lista2[index].selected = false;
      this.setState({
        optionsSelected: lista,
        tipoTituloOptions: lista2,
      });
    }
  };
  render() {
    return (
      <div>
        <Row style={{ alignItems: "flex-end", paddingTop: 15 }}>
          <Col md={6}>
            <Form.Label>Títulos com Pendencia</Form.Label>
            <ReactSelect
              isMulti
              options={this.state.buscaLista}
              className="basic-multi-select"
              classNamePrefix="select"
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              onChange={this.onChangeSelectItem}
              isOptionSelected={(element) => element.selected === true}
            />
          </Col>

          <Col md={true} />
          <Col md="auto" style={{ display: "flex", alignItems: "center" }}>
            {/* <Button
              onClick={this.buscaTituloComDividas}
              color="green"
              loading={this.state.buscando}
              disabled={true}
              // disabled={
              //   this.state.optionsSelectedTitulo.length === 0 ||
              //   this.state.buscando === true
              // }
            >
              Buscar
            </Button> */}
          </Col>

          <Col md="auto" style={{ display: "flex", alignItems: "center" }}>
            <Button onClick={this.imprimirTituloComDividas} color="blue">
              Imprimir
            </Button>
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell sm={6}>Nome</Table.HeaderCell>
              <Table.HeaderCell sm={6}>Ação</Table.HeaderCell>
              <Table.HeaderCell sm={6}>Tipo do título</Table.HeaderCell>
              <Table.HeaderCell sm={3}>Quantidade de parcela</Table.HeaderCell>
              <Table.HeaderCell sm={3}>Valor</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.titulosDisponivel?.map((e, i) => (
              <Table.Row>
                {/* <Table.Cell>{e.codigo_acao}</Table.Cell> */}
                <Table.Cell>{e.nome}</Table.Cell>
                <Table.Cell>{e.codigo_acao}</Table.Cell>
                <Table.Cell>{e.tipo_titulo}</Table.Cell>
                <Table.Cell>{e.quantidade_parcelas}</Table.Cell>
                <Table.Cell>{e.valor_total}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {/* <Row style={{ justifyContent: "center" }}>
          <Pagination
            count={this.state.totalPages}
            variant="outlined"
            shape="rounded"
            boundaryCount={1}
            page={this.state.activePage}
            onChange={(e, a) => {
              this.setState({
                activePage: a,
                titulosDisponivel: this.state.titulosDisponiveis.slice(
                  (a - 1) * this.state.dadosPorPagina,
                  this.state.titulosDisponiveis.length <
                    a * this.state.dadosPorPagina
                    ? this.state.titulosDisponiveis.length
                    : a * this.state.dadosPorPagina
                ),
              });
            }}
          />
        </Row> */}
      </div>
    );
  }
}
