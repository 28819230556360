import React, { Component } from "react";
import {
  Table,
  Button,
  Menu,
  Icon,
  Header,
  Search,
  Pagination,
} from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import Select from "react-select";
import requisicoes from "../../../utils/Requisicoes";

export default class ExercicioLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaExercicios: [],
      pagina: 1,
      totalPaginas: 1,
      busca: null,
    };
    this.listaExercicios();
  }

  listaExercicios = async (a, data, busca) => {
    if (data === undefined || data === null) {
      data = {};
      data.activePage = 1;
    }
    if (busca !== undefined && busca !== null && busca.trim() === "") {
      busca = undefined;
    }
    await api
      .post(
        "/exercicio/lista" + requisicoes.entidade,
        {
          busca: busca,
          pagina: data.activePage || this.state.pagina,
        },
        requisicoes.header
      )
      .then((resp) => {
        const { docs, pages } = resp.data;
        this.setState({
          listaExercicios: docs,
          pagina: data.activePage,
          totalPaginas: pages,
        });
      });
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === "busca") {
      this.listaExercicios(null, null, event.target.value);
    }
  };

  render() {
    return (
      <div className="lista">
        <Header
          as="h1"
          dividing
          style={{
            display:
              window.localStorage.getItem("pagina_anterior") === "pessoa"
                ? "none"
                : "block",
          }}
        >
          Exercícios
        </Header>
        <Row>
          <Col sm={6}>
            <Search
              placeholder="Digite para buscar"
              name="busca"
              onSearchChange={this.alteracao}
              open={false}
              fluid
            />
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Grupo Muscular</Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign="center">
                <Link to={{ pathname: "/exercicio" }}>
                  <Button positive icon="add"></Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.listaExercicios.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>{e.nome}</Table.Cell>
                <Table.Cell>
                  {e.grupo_muscular_id !== null && (
                    <> {e.grupoMuscular.nome} </>
                  )}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Link to={{ pathname: "/exercicio", query: { id: e.id } }}>
                    <Button primary icon="edit"></Button>
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan="3">
                <Pagination
                  boundaryRange={0}
                  ellipsisItem={null}
                  totalPages={this.state.totalPaginas}
                  onPageChange={this.listaExercicios}
                  activePage={this.state.pagina}
                ></Pagination>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}
