import React, { Component } from "react";
import { Menu } from "semantic-ui-react";

import MensalidadeCancelar from "./cancelar";
import MensalidadeLista from "./lista";

export default class MensalidadeTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo_clube: window.localStorage.getItem("tipo_clube"),
      // selectedMenu: localStorage.getItem("pagina_anterior"),
      selectedMenu: "gerar",
    };
  }

  render() {
    let content = "";
    switch (this.state.selectedMenu) {
      case "gerar":
        content = <MensalidadeLista />;
        break;
      case "cancelar":
        content = <MensalidadeCancelar />;
        break;
      default:
        break;
    }
    return (
      <div>
        <Menu secondary pointing>
          <Menu.Item
            name="gerar"
            active={this.state.selectedMenu === "gerar"}
            onClick={this.selectMenu.bind(null, "gerar")}
          >
            Gerar
          </Menu.Item>
          <Menu.Item
            name="cancelar"
            active={this.state.selectedMenu === "cancelar"}
            onClick={this.selectMenu.bind(null, "cancelar")}
          >
            Cancelar
          </Menu.Item>
        </Menu>
        {content}
      </div>
    );
  }
  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
  };
}
