import React, { Component } from "react";
import { Table } from "semantic-ui-react";

import "../styles.css";

import api from "../../../services/api";
export default class Competicao extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Table
        striped
        selectable
        hidden={this.props.relatorio_descricao !== "Etapas"}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>NOME</Table.HeaderCell>
            <Table.HeaderCell width={3}>ARMA</Table.HeaderCell>
            <Table.HeaderCell width={2}>PEDANA</Table.HeaderCell>
            <Table.HeaderCell width={2}>POSIÇÃO</Table.HeaderCell>
            <Table.HeaderCell width={2}>PONTUAÇÃO</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.tabela_etapas.length !== 0 ? (
            this.props.tabela_etapas.map((element, index) => (
              <Table.Row key={index}>
                <Table.Cell> {element.nome} </Table.Cell>
                <Table.Cell>{element.arma.modelo} </Table.Cell>
                <Table.Cell>{element.pedana} </Table.Cell>
                <Table.Cell>{element.posicao} </Table.Cell>
                <Table.Cell>
                  {element.pontuacoes[0].pontuacao !== null
                    ? element.pontuacoes[0].pontuacao
                    : ""}{" "}
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Cell>
              {" "}
              <h5>Não há inscritos nessa etapa </h5>{" "}
            </Table.Cell>
          )}
        </Table.Body>
      </Table>
    );
  }
}
