import React, { Component } from "react";
import {
  Container,
  Header,
  Menu,
  Segment,
  Icon,
  Table,
  Divider,
  Grid,
  Label,
  Image,
  TextArea,
  Checkbox,
  Input,
} from "semantic-ui-react";
import { Form, Col, Row, FormControl, Alert } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import moment from "moment-timezone";
import renomearChave from "../../../services/renomearChave";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";

import "../styles.css";

import api from "../../../services/api";
export default class Competicao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campeonatotexto: "",
      rodadas: null,
      modalidade_descricao: "",
      modalidade_id: null,
      optionsSG: [],
      optionsM: [],
      subgrupo_descricao: "",
      subgrupo_id: null,
      etapas: 0,
      tipo_evento: "",
      tipo_do_evento: "",
      data: [],
    };
  }
  pesquisaModalidade = async () => {
    api
      .post("/modalidade/listar" + requisicoes.entidade, {}, requisicoes.header)
      .then((resp) => {
        let lista = [];

        resp.data.forEach((e) => {
          let objeto = renomearChave(e, "id", "value");
          objeto = renomearChave(objeto, "descricao", "label");
          lista.push(objeto);
        });
        this.setState({ optionsM: lista });
      });
  };

  pesquisaSubGrupo = async () => {
    const lista = [];
    const retorno = api
      .post(
        "/modalidade/listaSubGrupo" + requisicoes.entidade,
        { modalidade_id: this.state.modalidade_id },
        requisicoes.header
      )
      .then((resp) => {
        resp.data.forEach((element, index) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomearChave(objeto, "evento", "label");

          lista.push(objeto);
        });

        this.setState({ optionsSG: lista });

        this.setState({ tipo_do_evento: lista[0].tipo_evento });
      });
  };

  alteracaoSelectModalidade = async (event) => {
    this.setState({ modalidade_descricao: event.label });
    this.setState({ modalidade_id: event.value });
  };
  alteracaoSelectSubGrupo = async (event) => {
    this.setState({ subgrupo_descricao: event.label });
    this.setState({ subgrupo_id: event.value });
  };
  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });

    if (event.target.name === "etapas") {
      this.setState({
        data: Array.from({ length: event.target.value }).fill(0),
      });
    }
  };

  alteracaoData = async (event, index) => {
    var listaData = [...this.state.data];

    listaData[index] = event.target.value;

    this.setState({ data: listaData });
  };

  voltar = async () => {
    window.location.href = "/eventos";
  };

  handleSubmit = async () => {
    this.submit();
  };

  submit = async () => {
    const resposta = await api
      .post(
        "/eventos/salvar" + requisicoes.entidade,
        {
          descricao: this.state.campeonatotexto,
          etapas: parseInt(this.state.etapas),
          subgrupo_id: this.state.subgrupo_id,
          modalidade_id: this.state.modalidade_id,
          data_inicio: this.state.data,
        },
        requisicoes.header
      )
      .then(() => {
        alert("Salvo com Sucesso");
      })
      .catch((error) => {
        alert("Não foi possível salvar o evento");
      });
  };

  render() {
    return (
      <Container className="paginaSemPaddingTop">
        <Form onSubmit={this.handleSubmit}>
          <Segment>
            <Header as="h2" dividing>
              Cadastro de Evento
              <Button
                style={{ outline: "none" }}
                color="primary"
                floated="right"
                type="button"
                onClick={this.voltar}
              >
                Voltar
              </Button>
            </Header>
            <Col sm={12}>
              <Row>
                <Col sm={4}>
                  <Form.Label className="label">Modalidade</Form.Label>
                  <Select
                    placeholder="Definir Modalidade"
                    label="Tipo"
                    id="modalidade"
                    name="modalidade"
                    options={this.state.optionsM}
                    onChange={this.alteracaoSelectModalidade}
                    onKeyDown={this.pesquisaModalidade}
                    required
                  />
                </Col>
                <Col sm={4}>
                  <Form.Label className="label">Sub-Grupo</Form.Label>

                  <Select
                    placeholder="Definir SubGrupo"
                    label="Tipo"
                    id="subgrupo"
                    name="subgrupo"
                    options={this.state.optionsSG}
                    onChange={this.alteracaoSelectSubGrupo}
                    onKeyDown={this.pesquisaSubGrupo}
                    required
                  />
                </Col>
                <Col sm={3} hidden={this.state.tipo_do_evento === "treino"}>
                  <TextField
                    style={{ marginTop: "7%" }}
                    id="etapas"
                    label="Nº de Etapas"
                    name="etapas"
                    onChange={this.alteracao}
                  />
                </Col>
              </Row>
            </Col>

            <Col sm={12}>
              <Row>
                <Col sm={12}>
                  <Form.Label>Descrição</Form.Label>

                  <div className="input-group">
                    <textarea
                      className="form-control"
                      name="campeonatotexto"
                      id="campeonatotextoId"
                      rows="3"
                      placeholder="Descreva aqui o seu campeonato"
                      required
                      onBlur={this.alteracao}
                    ></textarea>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col sm={12}>
              <Row>
                <Col sm={12}>
                  <Table
                    striped
                    selectable
                    hidden={
                      this.state.etapa === 0 ||
                      this.state.tipo_do_evento === "treino"
                    }
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Etapa</Table.HeaderCell>

                        <Table.HeaderCell>Data</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {Array.from({ length: this.state.etapas }).map(
                        (element, index) => {
                          return (
                            <Table.Row>
                              <Table.Cell
                                textAlign="left"
                                verticalAlign="middle"
                              >
                                Etapa {index + 1}{" "}
                              </Table.Cell>
                              <Table.Cell
                                textAlign="left"
                                verticalAlign="middle"
                              >
                                <TextField
                                  style={{ outline: "none" }}
                                  type="date"
                                  id="data"
                                  name="data"
                                  onChange={(event) =>
                                    this.alteracaoData(event, index)
                                  }
                                />
                              </Table.Cell>
                            </Table.Row>
                          );
                        }
                      )}
                    </Table.Body>
                  </Table>
                </Col>
              </Row>
            </Col>
          </Segment>

          <Segment clearing>
            <Header floated="right">
              <Button
                type="submit"
                disabled={this.state.tipoevento === null}
                style={{ outline: "none" }}
                color="primary"
              >
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>
      </Container>
    );
  }
}
