import React, { Component } from "react";
import {
  Header,
  Button,
  Divider,
  Segment,
  FormField,
  Input,
  Table,
  Modal,
} from "semantic-ui-react";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import requisicoes from "../../../utils/Requisicoes";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import Select from "react-select";
import renomearChave from "../../../services/renomearChave";
import renomear2Chaves from "../../../services/renomear2Chaves";

export default class ModalContaContabil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      codigo: null,
      descricao: null,
      centro_de_custo: null,
      elemento_de_custo: null,
      centro_de_custo_id: null,
      elemento_de_custo_id: null,
      erroCodigoExiste: false,
      itens: [],
    };
    //this.visualizacao();
    this.alteracao = this.alteracao.bind(this);
    this.alteracaoSelectCentroCusto = this.alteracaoSelectCentroCusto.bind(
      this
    );
    this.alteracaoSelectElementoCusto = this.alteracaoSelectElementoCusto.bind(
      this
    );
  }

  alteracao(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  alteracaoSelectCentroCusto = async (event, id) => {
    this.setState({ centro_de_custo_id: event.value });
    this.setState({ centro_de_custo: event });
    for (let i = 0; i < this.state.itens.length; i++) {
      if (i == id.name) {
        this.state.itens[i].centro_de_custo_id = event.value;
      }
    }
  };

  alteracaoSelectElementoCusto = async (event, id) => {
    this.setState({ elemento_de_custo_id: event.value });
    this.setState({ elemento_de_custo: event });
    for (let i = 0; i < this.state.itens.length; i++) {
      if (i == id.name) {
        this.state.itens[i].elemento_de_custo_id = event.value;
      }
    }
  };

  pesquisaCentroCusto = (newValue) => {
    api
      .post(
        "/centroDeCusto/pesquisa" + requisicoes.entidade,
        { pesquisa: newValue },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomear2Chaves(objeto, "codigo", "descricao", "label");
          lista.push(objeto);
        });
        this.setState({ optionsCC: lista });
      });
  };

  pesquisaElementoCusto = (newValue) => {
    api
      .post(
        "/elementoDeCusto/pesquisa" + requisicoes.entidade,
        { pesquisa: newValue },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomear2Chaves(objeto, "codigo", "descricao", "label");
          lista.push(objeto);
        });
        this.setState({ optionsEC: lista });
      });
  };

  visualizacao = async () => {
    if (this.props.location.query !== undefined) {
      const resposta = await api.post(
        "/contaContabil/visualiza" + requisicoes.entidade,
        this.props.location.query,
        requisicoes.header
      );
      this.setState(resposta.data);
      let objeto = renomearChave(resposta.data.centro_de_custo, "id", "value");
      objeto = renomear2Chaves(objeto, "codigo", "descricao", "label");
      this.setState({ centro_de_custo_id: resposta.data.centro_de_custo_id });
      this.setState({ centro_de_custo: objeto });

      let elemento = renomearChave(
        resposta.data.elemento_de_custo,
        "id",
        "value"
      );
      elemento = renomear2Chaves(elemento, "codigo", "descricao", "label");
      this.setState({
        elemento_de_custo_id: resposta.data.elemento_de_custo_id,
      });
      this.setState({ elemento_de_custo: elemento });
      console.log(resposta.data);
      this.setState({itens: resposta.data.itens});
    }
  };

  handleSubmit = (event) => {
    this.submit();
    event.preventDefault();
  };

  submit = async () => {
    const c = this.state;
    let retorno = null;
    const retornoR = await api.post(
      "/itemContaContabil/salvarVarios" + requisicoes.entidade,
      this.state.itens,
      requisicoes.header
    );
    console.log(retornoR.data);
    const dados = {
      codigo: c.codigo,
      descricao: c.descricao,
      centro_de_custo_id: c.centro_de_custo_id,
      elemento_de_custo_id: c.elemento_de_custo_id,
      itens: retornoR.data,
    };

    if (this.state.id !== null) {
      retorno = await api.post(
        "/contaContabil/alterar" +
          requisicoes.entidade +
          "&id=" +
          this.state.id,
        dados,
        requisicoes.header
      );
      window.location.href = "/contabil";
    } else {
      retorno = await api.post(
        "/contaContabil/salvar" + requisicoes.entidade,
        dados,
        requisicoes.header
      );
      if (retorno.data === "Codigo ja existe") {
        this.setState({ erroCodigoExiste: true });
      } else {
        this.setState({openModal: false})
        // window.location.href = "/contabil";
      }
    }
  };

  removeItem = async (event) => {
    if (event.target != null) {
      const retorno = await api.post(
        "/contaContabil/removeRateio" + requisicoes.entidade,
        {
          rateio: event.target.id,
        },
        requisicoes.header
      );
    }
    let lista = this.state.itens;
    lista.splice(lista.indexOf(1), 1);
    this.setState({ itens: lista });
  };

  addItem = async (event) => {
    const item = { centro_de_custo_id: null, elemento_de_custo_id: null };
    this.state.itens.push(item);
    this.state.item = "";
    this.setState(this.state);
  };

  render() {
    return (
      <Modal
        trigger={
            <Button
              color="green"
              icon="add"
            />
        }
        onOpen={()=>this.setState({openModal: true})}
        open={this.state.openModal}
      >
        <Header as="h1" dividing>
          Conta Contábil
        </Header>
        <Modal.Content>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col sm={6}>
                <Form.Label>Código</Form.Label>
                <FormControl
                  type="number"
                  aria-label="Código"
                  name="codigo"
                  onChange={this.alteracao}
                  isInvalid={!!this.state.erroCodigoExiste}
                  defaultValue={this.state.codigo}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Código já existe
                </Form.Control.Feedback>
              </Col>
              <Col sm={6}>
                <Form.Label>Descrição</Form.Label>
                <FormField
                  placeholder="Descrição"
                  name="descricao"
                  control={Input}
                  onChange={this.alteracao}
                  required
                  defaultValue={this.state.descricao}
                  fluid
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col sm={12}>
                <Table celled>
                  <Table.Body>
                    <Table.Row>
                      <Table.HeaderCell width={4}>
                        Centro de Custo
                      </Table.HeaderCell>
                      <Table.HeaderCell width={4}>
                        Elemento de Custo
                      </Table.HeaderCell>
                      <Table.HeaderCell width={1} textAlign="center">
                        <Button
                          positive
                          icon="add"
                          size="tiny"
                          onClick={this.addItem}
                          type="button"
                          style={{ marginTop: "7%" }}
                        ></Button>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Body>
                  <Table.Body>
                    {this.state.itens.map((e, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>
                          <Select
                            placeholder="Adicionar Centro de Custo"
                            name={[i]}
                            onChange={this.alteracaoSelectCentroCusto}
                            options={this.state.optionsCC}
                            onInputChange={this.pesquisaCentroCusto}
                            isDisabled={e.centro_de_custo != null}
                            defaultInputValue={
                              e.centro_de_custo != null
                                ? e.centro_de_custo.codigo + " - " + e.centro_de_custo.descricao
                                : ""
                            }
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Select
                            placeholder="Adicionar Elemento de Custo"
                            name={[i]}
                            onChange={this.alteracaoSelectElementoCusto}
                            options={this.state.optionsEC}
                            onInputChange={this.pesquisaElementoCusto}
                            isDisabled={e.elemento_de_custo != null}
                            defaultInputValue={
                              e.elemento_de_custo != null
                                ? e.elemento_de_custo.codigo + " - " + e.elemento_de_custo.descricao
                                : ""
                            }
                          />
                        </Table.Cell>
                        <Table.Cell width={1} textAlign="center">
                          <Button
                            negative
                            icon={{
                              className: "remove",
                              id: [e.id],
                              onClick: [this.removeItem],
                            }}
                            size="tiny"
                            id={e.id}
                            onClick={this.removeItem}
                            type="button"
                            style={{ marginTop: "7%" }}
                            value={i}
                          ></Button>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Col>
            </Row>
            <Divider />
            <Segment clearing>
              <Header floated="right">
                {/* <Link to="/contabil">
                  <Button type="button">Voltar</Button>
                </Link> */}
                <Button type="button" onClick={()=>this.setState({openModal: false})}>Voltar</Button>

                <Button positive type="submit">
                  Salvar
                </Button>
              </Header>
            </Segment>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}
