import React, { Component } from "react";
import {
  Header,
  Button,
  Divider,
  Segment,
  FormField,
  Input,
  Table,
  Select,
} from "semantic-ui-react";
import api from "../../../services/api";
import { Link, withRouter } from "react-router-dom";
import requisicoes from "../../../utils/Requisicoes";
import { Row, Col, Form, FormControl } from "react-bootstrap";

class ContaContabil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // inputs
      id: null,
      codigo: null,
      descricao: null,
      itens: [],

      //options
      optionsCC: [],
      optionsEC: [],

      // state buttons/modal/erro
      erroCodigoExiste: false,
    };
    this.visualizacao();
  }

  visualizacao = async () => {
    if (this.props.location.query !== undefined) {
      const resposta = await api.post(
        "/contaContabil/visualiza" + requisicoes.entidade,
        this.props.location.query,
        requisicoes.header
      );
      this.setState(resposta.data);
    }
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  alteracaoSelectCentroCusto = async (event, props, index) => {
    let itens = [...this.state.itens];
    itens[index].centro_de_custo_id = props.value;
    this.setState({ itens });
  };

  alteracaoSelectElementoCusto = async (event, props, index) => {
    let itens = [...this.state.itens];
    itens[index].elemento_de_custo_id = props.value;
    this.setState({ itens });
  };

  pesquisaCentroCusto = (event, props) => {
    let newValue = props.searchQuery;

    api
      .post(
        "/centroDeCusto/pesquisa" + requisicoes.entidade,
        { pesquisa: newValue },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          lista.push({
            value: element.id,
            text: element.codigo + " - " + element.descricao,
          });
        });
        this.setState({ optionsCC: lista });
      });
  };

  pesquisaElementoCusto = (event, props) => {
    let newValue = props.searchQuery;
    api
      .post(
        "/elementoDeCusto/pesquisa" + requisicoes.entidade,
        { pesquisa: newValue },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          lista.push({
            value: element.id,
            text: element.codigo + " - " + element.descricao,
          });
        });
        this.setState({ optionsEC: lista });
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.submit();
  };

  submit = async () => {
    try {
      const c = this.state;
      let retorno = null;
      const dados = {
        codigo: c.codigo,
        descricao: c.descricao,
        itens: this.state.itens.filter((e) => !e.id),
      };
      if (this.state.id !== null) {
        retorno = await api.post(
          "/contaContabil/alterar" +
            requisicoes.entidade +
            "&id=" +
            this.state.id,
          dados,
          requisicoes.header
        );
        this.props.history.push("/contabil");
      } else {
        retorno = await api.post(
          "/contaContabil/salvar" + requisicoes.entidade,
          dados,
          requisicoes.header
        );
        if (retorno.data === "Codigo ja existe") {
          this.setState({ erroCodigoExiste: true });
        } else {
          this.props.history.push("/contabil");
        }
      }
    } catch (error) {
      if (error.response.data) {
        alert(error.response.data);
      } else {
        alert("Ocorreu um erro inesperado ao salvar a conta contábil.");
      }
    }
  };

  removeItem = async (event, index) => {
    let lista = [...this.state.itens];

    if (lista[index].id) {
      await api.post(
        "/contaContabil/removeRateio" + requisicoes.entidade,
        {
          rateio: lista[index].id,
        },
        requisicoes.header
      );
    }

    lista.splice(index, 1);
    this.setState({ itens: [...lista] });
  };

  addItem = async (event) => {
    const item = { centro_de_custo_id: null, elemento_de_custo_id: null };
    let lista = [...this.state.itens];
    lista.push(item);
    this.setState({ itens: lista });
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Conta Contábil
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={6}>
              <Form.Label>Código</Form.Label>
              <FormControl
                type="number"
                aria-label="Código"
                name="codigo"
                onChange={this.alteracao}
                isInvalid={this.state.erroCodigoExiste}
                value={this.state.codigo}
                required
              />
              <Form.Control.Feedback type="invalid">
                Código já existe
              </Form.Control.Feedback>
            </Col>
            <Col sm={6}>
              <Form.Label>Descrição</Form.Label>
              <FormField
                placeholder="Descrição"
                name="descricao"
                control={Input}
                onChange={this.alteracao}
                required
                value={this.state.descricao}
                fluid
              />
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col sm={12}>
              <Table celled>
                <Table.Body>
                  <Table.Row>
                    <Table.HeaderCell width={4}>
                      Centro de Custo
                    </Table.HeaderCell>
                    <Table.HeaderCell width={4}>
                      Elemento de Custo
                    </Table.HeaderCell>
                    <Table.HeaderCell width={1} textAlign="center">
                      <Button
                        positive
                        icon="add"
                        size="tiny"
                        onClick={this.addItem}
                        type="button"
                        style={{ marginTop: "7%" }}
                      ></Button>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Body>
                <Table.Body>
                  {this.state.itens.map((e, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>
                        <Select
                          placeholder="Adicionar Centro de Custo"
                          onChange={(event, props) =>
                            this.alteracaoSelectCentroCusto(event, props, i)
                          }
                          options={
                            e.centro_de_custo
                              ? [
                                  {
                                    text:
                                      e.centro_de_custo.codigo +
                                      " - " +
                                      e.centro_de_custo.descricao,
                                    value: e.centro_de_custo.id,
                                  },
                                ]
                              : this.state.optionsCC
                          }
                          onSearchChange={this.pesquisaCentroCusto}
                          //disabled={e.centro_de_custo}
                          value={e.centro_de_custo_id}
                          required
                          search
                          fluid
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Select
                          placeholder="Adicionar Elemento de Custo"
                          onChange={(event, props) =>
                            this.alteracaoSelectElementoCusto(event, props, i)
                          }
                          options={
                            e.elemento_de_custo
                              ? [
                                  {
                                    text:
                                      e.elemento_de_custo.codigo +
                                      " - " +
                                      e.elemento_de_custo.descricao,
                                    value: e.elemento_de_custo.id,
                                  },
                                ]
                              : this.state.optionsEC
                          }
                          onSearchChange={this.pesquisaElementoCusto}
                          //disabled={e.elemento_de_custo}
                          value={e.elemento_de_custo_id}
                          required
                          search
                          fluid
                        />
                      </Table.Cell>
                      <Table.Cell width={1} textAlign="center">
                        <Button
                          negative
                          icon="remove"
                          size="tiny"
                          onClick={(event) => this.removeItem(event, i)}
                          type="button"
                          style={{ marginTop: "7%" }}
                        ></Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Col>
          </Row>
          <Divider />
          <Segment clearing>
            <Header floated="right">
              <Link to="/contabil">
                <Button type="button">Voltar</Button>
              </Link>
              <Button positive type="submit">
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}

export default withRouter(ContaContabil);
