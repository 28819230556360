import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { Button, Card, Header, Icon, Segment } from "semantic-ui-react";

import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

import moment from "moment-timezone";

export default class MensagemFaleConosco extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mensagens: [],
      paginas: 0,
      paginaAtual: 1,
    };
    this.listaMensagens();
  }

  listaMensagens = async () => {
    const mensagens = await api.post(
      "/mensagemFaleConosco/lista" + requisicoes.entidade,
      {
        pagina: 1,
        registros_por_pagina: 20,
      },
      requisicoes.header
    );

    this.setState({
      mensagens: mensagens.data.docs,
      paginas: mensagens.data.pages,
    });
  };

  marcarComolida = async (mensagem_id, index) => {
    let lista = this.state.mensagens;
    lista[index].salvando = true;
    this.setState({ mensagens: lista });

    let mensagem = {
      id: mensagem_id,
      data_lida: moment().format("YYYY-MM-DD"),
      hora_lida: moment().format("HH:mm:ss"),
      status: "lida",
    };
    await api.post(
      "/mensagemFaleConosco/alterar" + requisicoes.entidade,
      mensagem,
      requisicoes.header
    );

    lista[index].data_lida = mensagem.data_lida;
    lista[index].hora_lida = mensagem.hora_lida;
    lista[index].status = mensagem.status;
    lista[index].salvando = false;

    this.setState({ mensagens: lista });
  };

  render() {
    return (
      <div>
        <Header>Mensagens Fale Conosco</Header>
        <Segment>
          {this.state.mensagens.map((e, i) => (
            <Card fluid>
              <Card.Content>
                <Row>
                  <Col>
                    <div style={{ flex: 1 }}>
                      <h3>{e.motivo + " - " + e.usuario.entidade}</h3>
                    </div>
                  </Col>
                  <Col md="auto">{e.usuario.nome}</Col>
                  <Col md="auto">{e.usuario.tipo_usuario}</Col>

                  <Col
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    md="auto"
                  >
                    <div>
                      {`${moment(e.data_envio).format(
                        "DD/MM/YYYY"
                      )} - ${e.hora_envio.slice(0, 5)}`}
                    </div>
                  </Col>
                </Row>
              </Card.Content>
              <Card.Content
                meta={e.titulo}
                description={e.mensagem}
              ></Card.Content>
              <Card.Content>
                <Row>
                  <Col md="auto">Email: {e.email}</Col>
                  <Col md="auto">Telefone: {e.telefone}</Col>
                  <Col></Col>
                  <Col md="auto">
                    {e.status !== "lida" ? (
                      <Button
                        color="green"
                        onClick={() => this.marcarComolida(e.id, i)}
                        disabled={e.salvando}
                        loading={e.salvando}
                      >
                        Marcar como lida
                        <Icon name="check" style={{ paddingLeft: 10 }} />
                      </Button>
                    ) : (
                      <b style={{ color: "#21ba45" }}>Mensagem já lida</b>
                    )}
                  </Col>
                </Row>
              </Card.Content>
            </Card>
          ))}
        </Segment>
      </div>
    );
  }
}
