import React, { Component } from "react";
import {
  Header,
  Button,
  Modal,
} from "semantic-ui-react";
import { Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";
import renomearChave from "../../../services/renomearChave";

export default class ModalServicoTitulo extends Component {
  constructor(props) {
    super(props);
    this.state = { optionsServico:[] };
  }

  pesquisaServico = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/servico/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaServico").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista.push(objeto);
      });
      this.setState({ optionsServico: lista });
    }, 500);
  };

  render() {
    return (
      <Modal open={this.props.open_modal_servico_titulo} centered={true}
      onClose={this.props.fecharModalTitulo}>
      <Header content="Serviço Titulo" />
      <Modal.Content>
        <Row>
          <Col sm={12}>
            <Form.Label>Servico</Form.Label>
            <Select
              placeholder="Digite para buscar"
              name="servico"
              value={this.props.servico_titulo}
              onChange={this.props.alteracaoSelectServicoTitulo}
              options={this.state.optionsServico}
              onKeyDown={this.pesquisaServico}
              inputId="pesquisaServico"
            />
          </Col>
          <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
        </Row>
      </Modal.Content>
      <div class="actions">
        <Button type="button" primary onClick={this.props.fecharModalTitulo}>Voltar</Button>
        <Button type="button" secondary onClick={this.props.fecharModalTitulo}>Salvar</Button>
      </div>
    </Modal>
    );
  }
}
