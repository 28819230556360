import React, { Component } from "react";
import {
  Button,
  Modal,
  List,
  Form,
  Message,
  Input,
  Segment,
  TextArea,
} from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import { Tooltip, CircularProgress } from "@material-ui/core";

// Utils
import moment from "moment-timezone";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

export default class ModalEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Modal
          open={this.props.open}
          onClose={() => this.props.onClose()}
          onOpen={() => this.props.onOpen()}
          size="tiny"
        >
          <Modal.Header content="Enviar boleto por email" />
          <Modal.Content>
            <Modal.Description>
              <Row>
                <Col>
                  <Form
                    loading={this.props.enviando}
                    success={this.props.enviando === false}
                  >
                    <Form.Field
                      control={Input}
                      label={
                        <>
                          <strong>Email</strong>
                          <Tooltip
                            title={
                              <h5>
                                Separe por virgula para enviar para vários
                                endereços.
                              </h5>
                            }
                            arrow
                            placement="right"
                          >
                            <span>{"(?)"}</span>
                          </Tooltip>
                        </>
                      }
                      placeholder="E-mail"
                      name="email"
                      onChange={this.props.alteracao}
                      defaultValue={this.props.email}
                      error={
                        this.props.emailInvalido
                          ? {
                              content: "Por favor, entre com um e-mail válido.",
                              pointing: "below",
                            }
                          : null
                      }
                    ></Form.Field>
                    <Segment>
                      {this.props.gerandoBoleto ? (
                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ paddingLeft: 15 }}>
                            Gerando boleto...
                          </div>
                          <div style={{ paddingLeft: 15 }}>
                            <CircularProgress size={15}></CircularProgress>
                          </div>
                        </Row>
                      ) : (
                        <Row style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ paddingLeft: 15 }}>
                            Boleto pronto para envio.
                          </div>
                          <div style={{ paddingLeft: 15 }}>
                            <Button
                              icon="eye"
                              onClick={() => this.props.gerarPdfBoletos()}
                              primary
                            ></Button>
                          </div>
                        </Row>
                      )}
                    </Segment>

                    <Form.Field
                      control={Input}
                      label="Assunto"
                      placeholder="Assunto"
                      name="assunto"
                      onChange={this.props.alteracao}
                      defaultValue={this.props.assunto}
                    />

                    <Form.Field
                      control={TextArea}
                      label="Mensagem"
                      placeholder="Mensagem"
                      name="mensagem"
                      defaultValue={this.props.mensagem}
                      onChange={this.props.alteracao}
                      style={{ minHeight: 150 }}
                    />
                    <Message
                      success
                      header="E-mail enviado"
                      content={"O e-mail foi enviado para: " + this.props.email}
                    />
                    <Row>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          content="Voltar"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={this.props.onClose}
                        ></Button>
                      </Col>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          content="Enviar"
                          icon="send"
                          positive
                          onClick={this.props.enviarEmail}
                          disabled={
                            this.props.enviando === false ||
                            this.props.gerandoBoleto ||
                            this.props.emailInvalido
                          }
                        />
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}
