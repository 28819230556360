import moment from "moment-timezone";
import checkFeriado from "../enum/Feriados";
import {
  getYear,
  getMonth,
  getDate,
  
} from "date-fns";

const metodo = (data, lista) => {
  let dataVerificacao = moment(data);//.add(1, "day");
  let indiceProtecao = 0;
  
  while (
    dataVerificacao.day() === 6 ||
    dataVerificacao.day() === 0 ||
    checkFeriado(dataVerificacao, lista) ||
    indiceProtecao > 100
  ) {
    dataVerificacao = moment(dataVerificacao).add(1, "day");
    indiceProtecao++;
  }
  return dataVerificacao.format('YYYY-MM-DD');
};
export default metodo;

export const getFormatStringDate = (param) => {
  const data = moment(param).toDate();
  const mes = parseInt(getMonth(data)) <= 0 ? 12 : parseInt(getMonth(data));

  return  getYear(data) + "-" +
          (mes).toString().padStart(2, '0') + "-" +
          getDate(data).toString().padStart(2, '0');
}

Date.prototype.addDays=function(d){return new Date(this.valueOf()+864E5*d);};

Date.prototype.proximoDiaUtil=function(){return metodo(this);};
