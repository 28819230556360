import React, { Component } from "react";
import { Table } from "semantic-ui-react";

// Utils
import moment from "moment-timezone";

export default class InformacaoLancamento extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {this.props.lancamento.historicos?.length === 0 ? (
          <div style={{ paddingBottom: 15, paddingTop: 15 }}>
            Este lancamento não possui histórico.
          </div>
        ) : (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Tipo Operação</Table.HeaderCell>
                <Table.HeaderCell>Usuário</Table.HeaderCell>
                <Table.HeaderCell>Data</Table.HeaderCell>
                <Table.HeaderCell>Motivo</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.lancamento.historicos.map((e) => (
                <Table.Row>
                  <Table.Cell collapsing>{e.tipo}</Table.Cell>
                  <Table.Cell collapsing>{e.usuario?.nome}</Table.Cell>
                  <Table.Cell collapsing>
                    {moment(e.data).format("DD/MM/YYYY") +
                      " - " +
                      (e.hora ? e.hora.slice(0, 5) : "")}
                  </Table.Cell>
                  <Table.Cell>{e.motivo}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </>
    );
  }
}
