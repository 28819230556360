import React, { Component } from "react";
import { Table, Header } from "semantic-ui-react";
import { Col } from "react-bootstrap";

export default class ListaPessoas extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Col
        sm={12}
        style={{ paddingTop: "10px", paddingLeft: "0", paddingRight: "0" }}
      >
        <Header as="h2">Todas Pessoas</Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>CPF/CNPJ</Table.HeaderCell>
              <Table.HeaderCell>Endereço</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.lista_pessoas !== undefined &&
              this.props.lista_pessoas.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{e.nome}</Table.Cell>
                  <Table.Cell>{e.cpf === null ? e.cnpj : e.cpf}</Table.Cell>
                  <Table.Cell>{e.endereco}</Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Col>
    );
  }
}
