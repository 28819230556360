import React, { Component } from "react";
import { Image, Button, Table, Card, Header } from "semantic-ui-react";
import { Row, Col, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";

// utils
import moment from "moment";

// Requisicoes
import requisicoes from "../../../../utils/Requisicoes";
import api from "../../../../services/api";

export default class Turmas extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div style={{ paddingTop: 10 }}>
          {this.props.turmas !== false ? (
            <div>
              <Row>
                <Col sm={5}>
                  <FormControl
                    aria-describedby="basic-addon1"
                    name="dataBuscaTurma"
                    defaultValue={this.props.dataBuscaTurma}
                    onChange={this.props.alteracao}
                  />
                </Col>
                <Col sm={1}>
                  <Button
                    type="button"
                    size="tiny"
                    primary
                    icon="search"
                    onClick={() =>
                      this.props.carregaListaTurmas(
                        this.props.grupoServicoTurma
                      )
                    }
                  ></Button>
                </Col>
                <Col sm={6}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      floated="right"
                      color="blue"
                      icon="th"
                      size="small"
                      content="Voltar"
                      onClick={this.props.voltarTurmas}
                    ></Button>
                  </div>
                </Col>
              </Row>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Nome</Table.HeaderCell>
                    <Table.HeaderCell width={1} textAlign="center">
                      {localStorage.getItem("tipo_usuario_clube") ===
                        "administrador" && (
                        <Link to={{ pathname: "/servicosTurmas" }}>
                          <Button positive icon="add" size="tiny"></Button>
                        </Link>
                      )}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {this.props.listaTurmas.map((e, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{e.nome}</Table.Cell>
                      <Table.Cell width={1} textAlign="center">
                        <Link
                          to={{
                            pathname: "/matriculaTurma",
                            query: { id: e.id },
                          }}
                        >
                          <Button positive icon="calendar" size="tiny"></Button>
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          ) : localStorage.getItem("tipo_usuario_clube") === "modalidade" ? (
            <>
              <h2>Turmas</h2>
              <Card.Group itemsPerRow={4}>
                {this.props.listaUsuarioGrupoServico.map((e, i) => (
                  <Card onClick={() => this.props.carregaListaTurmas(e.id)}>
                    <Card.Content style={{ textAlign: "center" }}>
                      <Header
                      // style={{ color: "white" }}
                      >
                        {e.descricao}
                      </Header>
                    </Card.Content>
                  </Card>
                ))}
              </Card.Group>
            </>
          ) : (
            <>
              <h2>Turmas</h2>
              <Card.Group itemsPerRow={4}>
                {this.props.listaGrupoServico.map((e, i) => (
                  <>
                    {e.turma ? (
                      <Card onClick={() => this.props.carregaListaTurmas(e.id)}>
                        <Card.Content style={{ textAlign: "center" }}>
                          <Header
                          // style={{ color: "white" }}
                          >
                            {e.descricao}
                          </Header>
                        </Card.Content>
                      </Card>
                    ) : null}
                  </>
                ))}
              </Card.Group>
            </>
          )}
        </div>
      </div>
    );
  }
}
