/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Form, FormControl, Col, Row } from "react-bootstrap";
import { Modal, Segment, Table } from "semantic-ui-react";
import {
  AddCircleOutline,
  VisibilityOff,
  Visibility,
  Close,
} from "@material-ui/icons/";
import {
  Tooltip,
  Button,
  Select,
  IconButton,
  InputLabel,
  TextField,
  Autocomplete,
  CircularProgress,
  Alert,
  Snackbar
} from "@material-ui/core";

import requisicoes from "../../../utils/Requisicoes";
import api from "../../../services/api";
import { Link } from "react-router-dom";

import "../styles.css";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exibirLista: false,
      options: [],
      listaSelecionada: [],
      atletasEComissaoTecnica: [],
      buscando: false,
      linhaInscricao: 1,
      participantesPorTime: [],
      atletasInscricao: [],
      pessoas: [],
      lista: [],
      mensagemSnackBar: { show: false },
      alertErro: false,
      alertErrorIniciar: false,
      alertSucessIniciar: false,
      alertTamanhoEquipes: false,
      alertSuccessSalvarTime: false,
      alertErrorSalvarTime: false,
      atletasInscricao: []

    };
    // setTimeout(() => {
    // this.listaAtleta();
    // }, 200);
    this.listaAtleta()
    this.addModalLinhaInscricao();

  }

  changeExibirLista = async () => {
    if (this.state.exibirLista) {
      this.setState({ exibirLista: false });
    } else {
      this.setState({ exibirLista: true });
    }
  };


  listaAtleta = async (element, index, e) => {
    try {

      const equipes = await api.post(
        "/equipe/listarEquipePorCampeonato" + requisicoes.entidade,
        {
          campeonato_id: this.props.campeonatoId,
          modalidade_desporto_id: this.props.modalidadeDesportoId,
        },
        requisicoes.header
      );

      let listaAux = [];
      for (let i = 0; i < equipes.data[0].length; i++) {
        const element = equipes.data[0];
        listaAux.push({
          id: element[i].id,
          organizacao_id: element[i].organizacao_id,
          nomeOrg: element[i].organizacao?.nome,
          nomeAtleta: element[i].p_m_d?.pessoa.nome,
          equipes_id: element[i].organizacao?.id,
          modalidade_desporto_id: element[i].modalidade_desporto_id,
          campeonato_equipes: element[i].campeonato_equipes,
          organizacao: element[i].organizacao,
          p_m_d: element[i].p_m_d,
        });
      }
      const atletas = [];

      for (let index = 0; index < equipes.data[1].length; index++) {
        atletas.push({
          id: equipes.data[1][index].id,
          nome: equipes.data[1][index].pessoa.nome,
          tipo_vinculo_id: equipes.data[1][index].tipo_vinculo.id,
          tipo_vinculo_descricao: equipes.data[1][index].tipo_vinculo.descricao,
        });
      }
      const inscritosModalidade = await api.post(
        "/pessoaModalidadesDesporto/listarAtletaeComissaoTecnicaPorModalidade" +
        requisicoes.entidade,
        {
          modalidade_desporto_id: this.props.modalidadeDesportoId,

        },
        requisicoes.header
      );


      let lista = [];
      this.state.options = [];
      this.setState({ buscando: true });

      for (let i = 0; i < inscritosModalidade.data.length; i++) {
        const element = inscritosModalidade.data[i];
        let objeto = {
          value: element.id,
          label: element.pessoa.titular_id
            ? element.pessoa.titular_id +
            " - " +
            element.pessoa.nome +
            " - " +
            element.tipo_vinculo.descricao
            : " " +
            "  " +
            //caso nao tenha acao mostra
            element.pessoa.nome +
            " - " +
            element.tipo_vinculo.descricao,
          equipe_id: element.tipo_vinculo.id,
        };
        lista.push(objeto);
      }
      this.setState({
        options: [...this.state.options, ...lista],
        buscando: false,
        pessoas: atletas,
        listaTimeInscritosTabela: listaAux,
      });

    } catch (error) {
      console.log("Error:", error);
    }
  };
  // todos: -----------------------------------------
  addModalLinhaInscricao = async () => {

    if (this.state.verificaLinha < this.props.tamanhoMaxEquipe
    ) {
      this.setState({ linhaInscricao: this.state.linhaInscricao + 1 });
      this.setState({ verificaLinha: this.state.verificaLinha + 1 });
    } else {
      this.setState({ alertTamanhoEquipes: true });

      setTimeout(() => {
        this.setState({ alertTamanhoEquipes: false });
      }, 2500);
    }
  };

  adicionaListaAtleta = async () => {
    try {

      let altetaInscricao =
        this.state.atletasInscricao[0].pessoas_modalidades_desporto_id;
      for (let i = 0; i < this.state.pessoas.length; i++) {
        const element = this.state.pessoas[i];

        if (element.id === altetaInscricao) {
          if (
            this.props.participantesPorTime.findIndex(
              (e) => e.pessoas_modalidades_desporto_id === altetaInscricao
            ) !== -1
          ) {
            this.mensagem(false, "Atleta já cadastrado.", 4500);
            return;
          }

          this.state.lista.push({
            nome: element.nome,
            lista: "Teste",
            vinculo: element.tipo_vinculo_descricao,
            equipe_id: this.state.atletasInscricao[0].equipe_id,
            pessoas_modalidades_desporto_id:
              this.state.atletasInscricao[0].pessoas_modalidades_desporto_id,
            status: this.state.atletasInscricao[0].status,
          });
        }
      }
      this.props.participantesPorTime.unshift(...this.state.lista);
      this.setState({
        participantesPorTime: this.state.participantesPorTime,
        atletasInscricao: this.state.atletasInscricao,
      });
      this.state.lista = [];
    } catch (error) {
      console.error("error:", error);
    }
  };
  alteracaoSelectAtleta = async (event, index, a) => {
    try {

      var inscricaoA = [...this.state.atletasInscricao];
      inscricaoA[index] = {
        pessoas_modalidades_desporto_id: a.value,
        nome: "",
        equipe_id: this.props.organizacaoSelecionadaId,
        vinculo: "",
        status: "regular",
      };
      this.setState({
        atletaSelecionadoId: a.value,
        atletaSelecionadoNome: a.label,
        atletasInscricao: inscricaoA,
      });
    } catch (error) {
      console.error("error:", error);
    }
  };
  salvarTime = async (event) => {
    try {
      let listaParticipantesAdicionados =
        this.props.participantesPorTime.filter((e) => !e.id);

      const equipePessoa = await api.post(
        "/equipePessoas/salvar" + requisicoes.entidade,
        {
          props: listaParticipantesAdicionados,
          campeonato_id: this.props.campeonatoId,
        },
        requisicoes.header
      );

      if (equipePessoa.data.length !== listaParticipantesAdicionados.length) {
        this.mensagem(false, "Nem todos os atletas foram salvos.", 1500);
      } else {
        this.setState({ alertSuccessSalvarTime: true });
      }

      setTimeout(() => {
        this.props.changeModalAdicionaAtleta(null, false);
        this.setState({
          modalInscricao: false,
          linhaInscricao: 1,
          atletasInscricao: [],
          alertSuccessSalvarTime: false,
        });
      }, 2000);
    } catch (error) {
      this.setState({ alertErrorSalvarTime: true });

      setTimeout(() => {
        this.setState({ alertErrorSalvarTime: false });
      }, 2000);
    }
  };

  excluirAtleta = async (event) => {
    let index = this.props.participantesPorTime.indexOf(event);
    if (index !== -1) {
      this.props.participantesPorTime.splice(index, 1);
    }
    let index2 = this.props.participantesPorTime.indexOf(event);
    if (index2 !== -1) {
      this.props.participantesPorTime.splice(index2, 1);
    }
    let confimar = window.confirm("Tem certeza que deseja excluir?");
    try {
      if (confimar) {
        const equipes = await api.post(
          "/equipepessoas/deleta" + requisicoes.entidade,
          {
            id: event.id,
            equipe_id: event.equipe_id,
          },
          requisicoes.header
        );
        this.setState({
          listaTimeInscritosTabela: this.state.listaTimeInscritosTabela,
          listaTimeAdicionado: this.state.listaTimeAdicionado,
        });
      }
    } catch (error) {
      console.log("Error", error)
    }
  };

  mensagem = (positive, content, duration) => {
    this.setState({
      mensagemSnackBar: {
        show: true,
        positive: positive,
        content: content,
        duration: duration || 4000,
      },
    });
  };
  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Modal
          style={{ overflow: "visible" }}
          dimmer="bluring"
          size="small"
          open={this.props.modalInscricao}
          onClose={() => {
            this.props.changeModalAdicionaAtleta(null, false);
          }}
          onMount={() => {
            this.listaAtleta();
          }}
        >
          <Modal.Header>
            {"Inscrição " + this.props.organizacaoSelecionadaNome}
            <IconButton
              onClick={() => this.props.changeModalAdicionaAtleta(null, false)}
              className="bottomModal"
              color="secondary"
            >
              <Close />
            </IconButton>

            <h6>
              Adicionar participantes ao time:
              <strong> Atletas, treinador e comissão técnica! </strong>
            </h6>
          </Modal.Header>

          <Modal.Content>
            {this.state.alertTamanhoEquipes ? (
              <Alert severity="error" style={{ marginTop: "10px" }}>
                Número de participantes excedido!
              </Alert>
            ) : this.props.alertErrorSalvarTime ? (
              <Alert severity="error">Erro ao salvar time!</Alert>
            ) : this.props.alertSuccessSalvarTime ? (
              <Alert severity="success">Time Salvo!</Alert>
            ) : (
              Array.from({ length: this.state.linhaInscricao }).map(
                (element, index) => {
                  return (
                    <div className="flexStart">
                      <div style={{ width: "60%" }}>
                        <InputLabel id="demo-mutiple-name-label">
                          Atleta
                        </InputLabel>

                        <Autocomplete
                          id="tipoCampeonato"
                          disableClearable
                          getOptionLabel={(option) => option.label}
                          options={this.state.options}
                          value={null}
                          onChange={(element, a) =>
                            this.alteracaoSelectAtleta(element, index, a)
                          }
                          onKeyUp={this.listaAtleta}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              variant="standard"
                              label="Digite para buscar"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {this.state.buscando ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                        style={{
                                          marginRight: "-3.7ch",
                                        }}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                          required
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginLeft: "15px",
                          marginTop: "4ch",
                        }}
                      >
                        <Button
                          style={{
                            display: "flex",
                            outline: "none",
                          }}
                          color="primary"
                          variant="contained"
                          onClick={this.adicionaListaAtleta}
                        >
                          Adicionar
                        </Button>
                      </div>
                    </div>
                  );
                }
              )
            )}

            <Table selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={7}>Nome</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Vínculo</Table.HeaderCell>
                  <Table.HeaderCell width={2}></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {this.props.participantesPorTime.length > 0 ? (
                this.props.participantesPorTime.map((element, index) => {
                  return (
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          {element?.p_m_d?.pessoa?.nome
                            ? element?.p_m_d?.pessoa?.nome
                            : element?.nome}{" "}
                        </Table.Cell>
                        <Table.Cell>
                          {element?.p_m_d?.tipo_vinculo?.descricao
                            ? element?.p_m_d?.tipo_vinculo?.descricao
                            : element?.vinculo}
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            icon
                            style={{ outline: "none" }}
                            size="mini"
                            onClick={() =>
                              this.excluirAtleta(element, true)
                            }
                            color={"error"}
                            disabled={this.state.atletasInscricao.length !== 0}
                          >
                            Deletar
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  );
                })
              ) : (
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Nenhum inscrito encontrado</Table.Cell>
                  </Table.Row>
                </Table.Body>
              )}
            </Table>
          </Modal.Content>
          <Modal.Actions>
            <div className="flexSpaceBetween">
              <div>
                <Tooltip
                  title={
                    this.state.exibirLista ? (
                      <h5>Mostrar Inscritos</h5>
                    ) : (
                      <h5>Esconder Inscritos</h5>
                    )
                  }
                  arrow
                  placement="right"
                >
                  <IconButton
                    color="secondary"
                    style={{ outline: "none" }}
                    onClick={this.changeExibirLista}
                  >
                    {this.state.exibirLista ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
              <div>
                <Button
                  color="primary"
                  style={{ outline: "none" }}
                  onClick={this.salvarTime}
                // disabled={this.props.atletasInscricao.length === 0}
                >
                  Salvar
                </Button>
              </div>
            </div>
          </Modal.Actions>
        </Modal>
        <Snackbar
          open={this.state.mensagemSnackBar.show}
          autoHideDuration={this.state.mensagemSnackBar?.duration}
          anchorOrigin={{ vertical: "botton", horizontal: "center" }}
          onClose={() =>
            setTimeout(() => {
              this.setState({
                mensagemSnackBar: {
                  ...this.state.mensagemSnackBar,
                  show: false,
                },
              });
            }, 500)
          }
        >
          <Alert
            onClose={() =>
              setTimeout(() => {
                this.setState({
                  mensagemSnackBar: {
                    ...this.state.mensagemSnackBar,
                    show: false,
                  },
                });
              }, 500)
            }
            severity={
              this.state.mensagemSnackBar?.positive ? "success" : "error"
            }
            sx={{ width: "100%" }}
          >
            {this.state.mensagemSnackBar?.content}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}
