import React, { Component } from "react";
import {
  Container,
  Header,
  Menu,
  Segment,
  Icon,
  Table,
  Divider,
  Grid,
  Label,
  Image,
  TextArea,
  Checkbox,
  FormControl,
  Modal,
  Card,
} from "semantic-ui-react";
import { Form, Col, Row } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import { Link } from "react-router-dom";
import renomearChave from "../../../services/renomearChave";
import Button from "@material-ui/core/Button";
import moment from "moment-timezone";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import allLocales from "@fullcalendar/core/locales-all";
import interactionPlugin from "@fullcalendar/interaction";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import SendIcon from "@material-ui/icons/Send";
import IconButton from "@material-ui/core/IconButton";
import Select from "react-select";
import "../styles.css";

import api from "../../../services/api";
export default class Competicao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalidadedescricao: "",
      pontuacao: null,
      serie: null,
      etapas: null,
      descricao: "",
      dataSelected: null,
      modal: false,
      dia: null,
      mes: null,
      ano: null,
      dataFim: null,
      descricao: "",
      alerta: true,
      selectEvento: [],
      evento_descricao: "",
      evento_id: null,
      listaAuxiliar: [],
      listaEvento: [],
      renderEvento: [],
    };
    this.buscaEventosCalendario();
  }

  buscaEventosCalendario = async () => {
    var lista = [];
    const evento = await api.post(
      "/CalendarioEvento/BuscaTodosEventosCalendario" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    evento.data.forEach((element, index) => {
      lista.push({
        start: moment(element.inicio).format("YYYY-MM-DD"),
        end: moment(element.final).format("YYYY-MM-DD"),
        backgroundColor: element.calendarioTiposEventos[0].cor,
        display: "background",
        title: element.calendarioTiposEventos[0].descricao,
      });
    });

    this.setState({ renderEvento: lista });
  };

  buscaEventos = async () => {
    const lista = [];
    var listaaux = [];
    const evento = await api.post(
      "/tipoEvento/BuscaEventos" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    // evento.data.forEach((e) => {
    //   lista.push({
    //     title: e.descricao,
    //     backgroundColor: e.cor,
    //     id: e.id,
    //   });
    // });

    evento.data.forEach((element, index) => {
      let objeto = renomearChave(element, "id", "value");
      objeto = renomearChave(objeto, "descricao", "label");

      lista.push(objeto);
    });

    // lista.forEach((e) => {
    //   let objeto = renomearChave(e, "id", "value");
    //   objeto = renomearChave(objeto, "title", "label");
    //   listaaux.push(objeto);
    // });

    this.setState({ selectEvento: lista });

    this.setState({ listaAuxiliar: evento.data });
  };

  alteracao = async (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  alteracaoSelectEvento = async (event) => {
    var lista = [];
    this.setState({ evento_descricao: event.label });
    this.setState({ evento_id: event.value });

    this.state.listaAuxiliar.forEach((element, index) => {
      if (element.id === event.value) {
        this.setState({ listaEvento: element });
      }
    });
  };

  voltar = async () => {
    window.location.href = "/eventos";
  };

  changeModal = async (status) => {
    this.setState({ modal: status });
  };

  salvarEventoDia = async () => {
    const evento = await api.post(
      "/CalendarioEvento/SalvarEvento" + requisicoes.entidade,
      {
        inicio: this.state.dataSelected,
        final: this.state.dataFim,
        titulo: this.state.descricao,
        listaEvento: this.state.listaEvento,
      },
      requisicoes.header
    );
    this.setState({ alerta: false });
    setTimeout(() => {
      this.setState({ modal: false });
      this.buscaEventosCalendario();
    }, 2500);
  };

  render() {
    const handleDateClick = (dateClickInfo) => {
      this.setState({ dataSelected: dateClickInfo.dateStr });
      this.setState({ modal: true });
      this.setState({ dia: this.state.dataSelected.substring(8, 11) });
      this.setState({ mes: this.state.dataSelected.substring(5, 7) });
      this.setState({ ano: this.state.dataSelected.substring(0, 4) });
    };
    return (
      <Container className="paginaSemPaddingTop">
        <Form onSubmit={this.handleSubmit}>
          <Segment>
            <Col sm={12} className="calender">
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                locale="pt-br"
                height={500}
                events={this.state.renderEvento}
                dateClick={handleDateClick}
              >
                {" "}
              </FullCalendar>
            </Col>

            <Modal
              dimmer="bluring"
              open={this.state.modal}
              style={{ overflow: "visible" }}
            >
              <Modal.Header>
                {"Cadastrar evento dia " +
                  this.state.dia +
                  "/" +
                  this.state.mes +
                  "/" +
                  this.state.ano}
                <Button
                  style={{ outline: "none" }}
                  onClick={() => this.changeModal(false)}
                  color="secondary"
                >
                  Fechar
                </Button>
              </Modal.Header>
              <Modal.Content>
                <Col sm={12}>
                  <Alert severity="success" hidden={this.state.alerta}>
                    <AlertTitle>evento Salvo!</AlertTitle>
                    Iremos redirecionar-lhe — <strong>Aguarde!</strong>
                  </Alert>
                  <Row>
                    <Col sm={4}>
                      <TextField
                        id="descricao"
                        name="descricao"
                        label="Descricao do evento"
                        onChange={this.alteracao}
                        required
                      />
                    </Col>
                    <Col sm={4}>
                      <TextField
                        id="dataFim"
                        name="dataFim"
                        label="Encerramento do evento"
                        type="date"
                        onChange={this.alteracao}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ display: "flex", flexWrap: "wrap" }}
                        required
                      />
                    </Col>
                    <Col sm={4}>
                      <Select
                        placeholder="Escolha o Evento"
                        label="evento"
                        id="evento"
                        name="evento"
                        options={this.state.selectEvento}
                        onChange={this.alteracaoSelectEvento}
                        onKeyDown={this.buscaEventos}
                        required
                      />
                    </Col>
                  </Row>
                </Col>
              </Modal.Content>
              <Modal.Actions>
                <IconButton
                  style={{ outline: "none" }}
                  color="primary"
                  aria-label="add to shopping cart"
                  type="button"
                  onClick={this.salvarEventoDia}
                >
                  <SendIcon />
                </IconButton>
              </Modal.Actions>
            </Modal>
            <Divider />
            <Row>
              <Col sm={10}>
                <Card.Group>
                  <Card className="treinoTrap" link>
                    <Card.Content className="fontCard" textAlign="center">
                      Treino de Trap{" "}
                    </Card.Content>
                  </Card>
                  <Card className="provaPratico" link>
                    <Card.Content className="fontCard" textAlign="center">
                      Prova Tiro Prático
                    </Card.Content>
                  </Card>
                  <Card className="treinoHelice" link>
                    <Card.Content className="fontCard" textAlign="center">
                      Treino Hélice{" "}
                    </Card.Content>
                  </Card>
                  <Card className="copaOesteEtapaXanxere" link>
                    <Card.Content className="fontCard" textAlign="center">
                      Copa Oeste Etapa Xxê{" "}
                    </Card.Content>
                  </Card>
                  <Card className="provaHelice" link>
                    <Card.Content className="fontCard" textAlign="center">
                      Prova Helice{" "}
                    </Card.Content>
                  </Card>
                  <Card className="treinoTiroPratico" link>
                    <Card.Content className="fontCard" textAlign="center">
                      Treino tiro Prático{" "}
                    </Card.Content>
                  </Card>
                </Card.Group>
              </Col>
            </Row>
          </Segment>

          <Segment clearing>
            <Header floated="right"></Header>
          </Segment>
        </Form>
      </Container>
    );
  }
}
