import React, { Component } from "react";

import { Menu } from "semantic-ui-react";

import Lista from "./lista/index";
import Relatorio from "./relatorios/index";

export default class Inadimplentes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: "lista",
    };
  }

  render() {
    let content = "";
    switch (this.state.selectedMenu) {
      case "lista":
        localStorage.setItem("pagina_anterior", "lista");
        content = <Lista />;
        break;

      case "relatorio":
        localStorage.setItem("pagina_anterior", "relatorio");
        content = <Relatorio />;
        break;
      default:
        localStorage.setItem("pagina_anterior", "relatorio");
        content = <Relatorio />;
        break;
    }
    return (
      <div>
        <Menu pointing secondary>
          <Menu.Item
            name="lista"
            active={this.state.selectedMenu === "lista"}
            onClick={this.selectMenu.bind(null, "lista")}
          >
            Cartas de inadimplência
          </Menu.Item>
          <Menu.Item
            name="relatorio"
            active={this.state.selectedMenu === "relatorio"}
            onClick={this.selectMenu.bind(null, "relatorio")}
          >
            Relatório Cartas
          </Menu.Item>
        </Menu>
        {content}
      </div>
    );
  }
  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
  };
}
