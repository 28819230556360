/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import {
  Modal,
  Segment,
  Table,
  Checkbox,
  Header,
  Divider,
} from "semantic-ui-react";
import { Form, Col, Row } from "react-bootstrap";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import TextField from "@material-ui/core/TextField";
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { Tooltip } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Alert from "@material-ui/lab/Alert";

import { Link } from "react-router-dom";

import "../styles.css";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = { tipoCriacaoCampeonato: null };
  }

  tootleRadio = async (event) => {
    this.setState({ tipoCriacaoCampeonato: event.target.value });
  };

  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Modal
          style={{ overflow: "visible" }}
          dimmer="bluring"
          size="large"
          open={this.props.modalGrupoFaseDeGrupo}
        >
          <Modal.Header>
            <IconButton
              onClick={() => this.props.changeModalRodadasFaseDeGrupo(false)}
              className="bottomModal"
              color="secondary"
            >
              <CloseIcon />
            </IconButton>

            <h6>
              Selecione o modo de criação de grupos. <br /> Manual:
              <strong> Você designará cada time a um grupo </strong>
              <br />
              Automático:
              <strong>O sistema fará os grupos automaticamente </strong>
            </h6>
          </Modal.Header>

          <Modal.Content>
            <Col sm={12}>
              {this.props.alertErrorIniciar ? (
                <Alert severity="error">
                  {this.props.mensagemErrorIniciar}
                </Alert>
              ) : this.props.alertSucessIniciar ? (
                <Alert severity="success">Campeonato Iniciado!</Alert>
              ) : (
                <Col>
                  <Row>
                    <Col sm={4}>
                      <FormControl component="fieldset">
                        <FormLabel>Criação de Grupo</FormLabel>
                        <RadioGroup
                          row
                          aria-label="gender"
                          name="gender1"
                          onChange={this.tootleRadio}
                        >
                          <FormControlLabel
                            value="automatico"
                            control={<Radio />}
                            label="Automático"
                          />
                          <FormControlLabel
                            value="Manual"
                            control={<Radio />}
                            label="Manual"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Col>

                    <Col sm={3}>
                      {this.state.tipoCriacaoCampeonato === "automatico" ? (
                        <TextField
                          variant="standard"
                          label="Times por Grupo"
                          name="timesPorGrupo"
                          id="timesPorGrupo"
                          onChange={this.props.alteracao}
                        ></TextField>
                      ) : (
                        <TextField
                          variant="standard"
                          label="Nº de grupos"
                          name="numDeGrupos"
                          id="numDeGrupos"
                          onChange={this.props.alteracao}
                        ></TextField>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    {this.props.alertErrorIniciar ? (
                      <Alert severity="error">
                        {this.props.mensagemErrorIniciar}
                      </Alert>
                    ) : this.props.alertSucessIniciar ? (
                      <Alert severity="success">Campeonato Iniciado!</Alert>
                    ) : this.props.gruposTotais !== undefined ||
                      this.props.gruposTotais.length > 0 ? (
                      this.state.tipoCriacaoCampeonato === "automatico" ? (
                        this.props.gruposTotais.map((element, index) => {
                          return (
                            <Col>
                              <Segment style={{ marginTop: "1ch" }}>
                                <Table selectable>
                                  <Table.Header>
                                    <Table.Row>
                                      <Table.HeaderCell>
                                        {"Grupo " + element.numero_grupo}
                                      </Table.HeaderCell>
                                    </Table.Row>
                                  </Table.Header>
                                  <Table.Body>
                                    {this.props.timesSeparadosPorGrupo !==
                                      undefined ||
                                      this.props.timesSeparadosPorGrupo !== []
                                      ? this.props.timesSeparadosPorGrupo.map(
                                        (info, indx) => {
                                          return info.grupo ===
                                            element.numero_grupo ? (
                                            <Table.Row>
                                              <Table.Cell>
                                                {info.nomeOrg}
                                              </Table.Cell>
                                            </Table.Row>
                                          ) : (
                                            ""
                                          );
                                        }
                                      )
                                      : ""}
                                  </Table.Body>
                                </Table>
                              </Segment>
                            </Col>
                          );
                        })
                      ) : this.props.numDeGrupos > 0 ? (
                        Array.from({
                          length: this.props.numDeGrupos,
                        }).map((element, index) => {
                          return (
                            <Col>
                              <Segment>
                                <Header>
                                  <div
                                    className="flexSpaceBetween"
                                    style={{ marginTop: "-1ch" }}
                                  >
                                    <div>{"Grupo: " + (index + 1)}</div>
                                    <div>
                                      <IconButton
                                        style={{
                                          outline: "none",
                                        }}
                                        color="primary"
                                        onClick={() =>
                                          this.props.addRowFaseGrupoManual()
                                        }
                                      >
                                        <AddCircleOutlineIcon />{" "}
                                      </IconButton>
                                    </div>
                                  </div>
                                </Header>

                                <Divider style={{ marginTop: "-1ch" }} />
                                {Array.from({
                                  length: this.props.linhaTimesGrupoManual,
                                }).map((elmt, indx) => {
                                  return (
                                    <Row>
                                      <Col>
                                        <InputLabel id="demo-mutiple-name-label">
                                          Equipe
                                        </InputLabel>

                                        <Select
                                          variant="standard"
                                          fullWidth
                                          placeholder="Digite para buscar"
                                          name="modalidade"
                                          onChange={(event) =>
                                            this.props.alteracaoSelectOrganizacaoEscolhaManualFasedeGrupos(
                                              event,
                                              index,
                                              indx
                                            )
                                          }
                                          required
                                        >
                                          {this.props.listaTimeInscritosTabela.map(
                                            (element, index) => {
                                              return (
                                                <MenuItem value={element.id}>
                                                  {element.organizacao.nome}
                                                </MenuItem>
                                              );
                                            }
                                          )}
                                        </Select>
                                      </Col>
                                    </Row>
                                  );
                                })}
                              </Segment>
                            </Col>
                          );
                        })
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </Row>
                </Col>
              )}
            </Col>
          </Modal.Content>
          <Modal.Actions>
            <div className="flexSpaceBetween">
              <div>
                {" "}
                <Button
                  color="primary"
                  style={{ outline: "none" }}
                  hidden={this.state.tipoCriacaoCampeonato !== "automatico"}
                  disabled={this.props.timesPorGrupo === 0}
                  onClick={this.props.randomTimes}
                >
                  Sortear Times
                </Button>
              </div>
              <div>
                <Button
                  color="primary"
                  disabled={
                    this.state.tipoCriacaoCampeonato === "automatico" &&
                    (this.props.gruposTotais.length === 0 ||
                      this.props.timesSeparadosPorGrupo.length === 0)
                  }
                  onClick={this.props.iniciarCampeonatoFasedeGrupo}
                  style={{ outline: "none" }}
                >
                  Iniciar Campeonato
                </Button>
              </div>
            </div>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
