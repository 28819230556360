import React, { Component } from "react";
import { Row, Col, Tab } from "react-bootstrap";
import { Box, TextField, Button } from "@material-ui/core";
import {
  DatePicker,
  LocalizationProvider,
  LoadingButton,
} from "@material-ui/lab";
import { Table } from "semantic-ui-react";

// Icons
import { Print, Search } from "@material-ui/icons";

// Datas
import AdapterDateFns from "@material-ui/lab/AdapterDateFns/index";
import moment from "moment";
import localePtBr from "date-fns/locale/pt-BR";

// Requisições
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

export default class FluxoDeCaixa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // inputs
      data_inicial: moment().startOf("month"),
      data_final: moment(),

      // valores
      fluxo_caixa: {
        receitas: [],
        despesas: [],
        totalReceitas: 0,
        totalDespesas: 0,
      },

      // stateButton
      buscando: false,
      imprimindo: false,
    };
  }

  buscar = async () => {
    try {
      this.setState({ buscando: true });
      const fluxo_caixa = await api.post(
        "/lancamentoConta/fluxoDeCaixa" + requisicoes.entidade,
        {
          data_inicial: moment(this.state.data_inicial).format("YYYY-MM-DD"),
          data_final: moment(this.state.data_final).format("YYYY-MM-DD"),
        },
        requisicoes.header
      );

      let totalReceitas = 0,
        totalDespesas = 0;
      for (let i = 0; i < fluxo_caixa.data.receitas.length; i++) {
        const element = fluxo_caixa.data.receitas[i];
        totalReceitas += parseFloat(element.sum);
      }
      for (let i = 0; i < fluxo_caixa.data.despesas.length; i++) {
        const element = fluxo_caixa.data.despesas[i];
        totalDespesas += parseFloat(element.sum);
      }

      this.setState({
        fluxo_caixa: { ...fluxo_caixa.data, totalReceitas, totalDespesas },
      });
    } catch (error) {
      console.log("error: ", error);
      alert("Ocorreu um problema ao buscar os dados.");
    } finally {
      this.setState({ buscando: false });
    }
  };

  imprimir = async () => {
    try {
      this.setState({ imprimindo: true });

      await api.post(
        "/lancamentoConta/relatorioFluxoDeCaixa" + requisicoes.entidade,
        {
          data_inicial: moment(this.state.data_inicial).format("YYYY-MM-DD"),
          data_final: moment(this.state.data_final).format("YYYY-MM-DD"),
        },
        requisicoes.header
      );

      var localStorage = window.localStorage;
      var token = localStorage.getItem("token_clube");
      var entidade = localStorage.getItem("e_clube");
      const r = await api.get(
        "/lancamentoConta/pdfFluxoCaixa" +
          requisicoes.entidade +
          "&t=" +
          requisicoes.header.headers.token
      );

      var oReq = new XMLHttpRequest();

      var URLToPDF =
        r.config.baseURL +
        "lancamentoConta/pdfFluxoCaixa" +
        requisicoes.entidade +
        "&t=" +
        requisicoes.header.headers.token;

      oReq.open("GET", URLToPDF, true);
      oReq.responseType = "blob";
      oReq.onload = async () => {
        const file = await new Blob([oReq.response], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      };
      oReq.send();
    } catch (error) {
      alert("Ocorreu um problema ao imprimir o relatório.");
    } finally {
      this.setState({ imprimindo: false });
    }
  };

  render() {
    return (
      <>
        <Box component="form">
          <Row>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={localePtBr}
            >
              <Col md="auto">
                <DatePicker
                  label="Data Inicial"
                  value={this.state.data_inicial}
                  onChange={(newValue) => {
                    this.setState({ data_inicial: newValue });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Col>
              <Col md="auto">
                <DatePicker
                  label="Data Final"
                  value={this.state.data_final}
                  onChange={(newValue) => {
                    this.setState({ data_final: newValue });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Col>
            </LocalizationProvider>
            {/* <Col></Col> */}

            <Col md="auto">
              <LoadingButton
                variant="contained"
                startIcon={<Search />}
                onClick={this.buscar}
                loading={this.state.buscando}
              >
                Buscar
              </LoadingButton>
            </Col>
            <Col md="auto">
              <LoadingButton
                variant="contained"
                startIcon={<Print />}
                loading={this.state.imprimindo}
                onClick={this.imprimir}
              >
                Imprimir
              </LoadingButton>
            </Col>
          </Row>
        </Box>

        <h3>Receitas</h3>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Conta Contábil</Table.HeaderCell>
              <Table.HeaderCell>Descrição</Table.HeaderCell>
              <Table.HeaderCell>Valor</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.fluxo_caixa.receitas.map((e) => (
              <Table.Row>
                <Table.Cell>{e.codigo}</Table.Cell>
                <Table.Cell>{e.descricao}</Table.Cell>
                <Table.Cell>
                  {parseFloat(e.sum).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>
                {parseFloat(
                  this.state.fluxo_caixa.totalReceitas
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>

        <h3>Despesas</h3>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Conta Contábil</Table.HeaderCell>
              <Table.HeaderCell>Descrição</Table.HeaderCell>
              <Table.HeaderCell>Valor</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.fluxo_caixa.despesas.map((e) => (
              <Table.Row>
                <Table.Cell>{e.codigo}</Table.Cell>
                <Table.Cell>{e.descricao}</Table.Cell>
                <Table.Cell>
                  {parseFloat(e.sum).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>
                {parseFloat(
                  this.state.fluxo_caixa.totalDespesas
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>

        <div style={{ fontSize: 18 }}>
          <Row>
            <Col></Col>
            <Col sm={2}>
              <Row>
                <Col>Receitas</Col>
              </Row>
              <Row>
                <Col>Despesas</Col>
              </Row>
              <Row style={{ marginTop: 10, fontWeight: "bold" }}>
                <Col>Saldo</Col>
              </Row>
            </Col>
            <Col md="auto">
              <Row>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "green",
                  }}
                >
                  {parseFloat(
                    this.state.fluxo_caixa.totalReceitas
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Col>
              </Row>
              <Row>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    color: "red",
                  }}
                >
                  {parseFloat(
                    parseFloat(this.state.fluxo_caixa.totalDespesas) * -1
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Col>
              </Row>

              <Row style={{ marginTop: 10, fontWeight: "bold" }}>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    color:
                      this.state.fluxo_caixa.totalDespesas * -1 +
                        this.state.fluxo_caixa.totalReceitas >=
                      0
                        ? "green"
                        : "red",
                  }}
                >
                  {parseFloat(
                    this.state.fluxo_caixa.totalDespesas * -1 +
                      this.state.fluxo_caixa.totalReceitas
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
