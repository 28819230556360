import React, { Component } from "react";
import { Image, Table, Button, Menu, Icon } from "semantic-ui-react";

//Utils
import moment from "moment";
import { Link } from "react-router-dom";

// Requisicoes
import requisicoes from "../../../../utils/Requisicoes";
import api from "../../../../services/api";

export default class MinhasReservas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaReservasUsuario: [],
    };
    this.buscaReservasUsuario();
  }

  buscaReservasUsuario = async () => {
    const respostaUsuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ usuarioLogado: respostaUsuarioLogado.data });
    const reservaHorarioPessoa = await api.post(
      "/reservaHorario/horarioPorPessoa" + requisicoes.entidade,
      { id: respostaUsuarioLogado.data.pessoa_id },
      requisicoes.header
    );

    const reservaHorarioLista = reservaHorarioPessoa.data;
    const respostaServico = await api.post(
      "/servico/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    const servicos = respostaServico.data;
    const listaRetorno = [];
    let pessoa = { id: null };

    servicos.forEach((element) => {
      reservaHorarioLista.forEach((objeto) => {
        if (element.id === objeto.horario_servico.servico_id) {
          if (objeto.pessoa !== undefined) {
            pessoa = objeto.pessoa;
          }

          if (objeto.situacaoReserva !== "cancelado") {
            // Não mostrar reservas canceladas
            listaRetorno.push({
              pessoa: pessoa,
              nome: element.nome,
              id: element.id,
              dataReserva: objeto.dataReserva,
              horaInicio: objeto.horario_servico.horaInicio,
              horaFim: objeto.horario_servico.horaFim,
              evento: objeto.evento,
            });
          }
        }
      });
    });
    function ordenaDatas(a, b) {
      if (a.dataReserva > b.dataReserva) {
        return 1;
      }
      if (a.dataReserva < b.dataReserva) {
        return -1;
      }
      return 0;
    }
    listaRetorno.sort(ordenaDatas);

    this.setState({ listaReservasUsuario: listaRetorno });
  };

  render() {
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Nome</Table.HeaderCell>
            <Table.HeaderCell>Data Reserva</Table.HeaderCell>
            <Table.HeaderCell>Hora Inicio</Table.HeaderCell>
            <Table.HeaderCell>Hora Fim</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Reserva</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        {this.state.listaReservasUsuario.map((e, i) => (
          <Table.Body>
            <Table.Row key={i}>
              <Table.Cell>{e.nome}</Table.Cell>
              <Table.Cell>
                {moment(e.dataReserva).format("DD/MM/YYYY")}
              </Table.Cell>
              <Table.Cell>{e.horaInicio}</Table.Cell>
              <Table.Cell>{e.horaFim}</Table.Cell>
              <Table.Cell width={1} textAlign="center">
                <Link
                  to={{
                    pathname: "/reserva",
                    query: {
                      id: e.id,
                      dataReserva: e.dataReserva,
                      pessoa: e.pessoa,
                    },
                  }}
                >
                  <Button positive icon="calendar" size="tiny"></Button>
                </Link>
              </Table.Cell>
            </Table.Row>
            {e.evento !== null && (
              <Table.Row>
                <Table.Cell colSpan={5}>Evento: {e.evento}</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        ))}

        <Table.Footer>
          <Table.Row verticalAlign="middle" textAlign="center">
            <Table.HeaderCell colSpan={5}>
              <Menu pagination>
                <Menu.Item
                  icon
                  onClick={this.voltarPagina}
                  disabled={this.state.pagina === 1}
                >
                  <Icon name="chevron left" />
                </Menu.Item>
                <Menu.Item
                  icon
                  onClick={this.proximaPagina}
                  disabled={this.state.pagina === this.state.totalPaginas}
                >
                  <Icon name="chevron right" />
                </Menu.Item>
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }
}
