import React, { Component } from "react";
import {
  Header,
  Button,
  Divider,
  Segment,
  FormField,
  Input,
  Table,
  Card,
  Dropdown,
  Modal,
  List,
  TextArea,
  FormInput,
  Checkbox,
  Message,
} from "semantic-ui-react";
import api from "../../../../../services/api";
import { Col, Form, FormControl, Row } from "react-bootstrap";
import Select from "react-select";
import renomearChave from "../../../../../services/renomearChave";
import renomear2Chaves from "../../../../../services/renomear2Chaves";
import renomear4Chaves from "../../../../../services/renomear4Chaves";
import {
  addMonths,
  getYear,
  getMonth,
  getDate,
  parseISO,
  format,
} from "date-fns";
import TipoLancamento from "../../../../../enum/TipoLancamento";
import CreditoDebito from "../../../../../enum/CreditoDebito";
import requisicoes from "../../../../../utils/Requisicoes";
import moment from "moment-timezone";
import $ from "jquery";
import "jquery-mask-plugin/dist/jquery.mask.min";
const ReplaceAll = require("replaceall");

const optionsTipoContabilizacao = [
  { value: "emissao", label: "Emissão" },
  { value: "vencimento", label: "Vencimento" },
  { value: "baixa", label: "Baixa" },
];

export default class EntradaItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      data_emissao: moment().format("YYYY-MM-DD"),
      itens: [],
      item: "",
      num_doc: null,
      total: 0,
      totalGeral: 0,
      listaPessoa: [],
      options: [],
      optionsI: [],
      infoPessoa: "",
      enderecoCompleto: "",
      documento: "",
      infoParcelas: [],
      forma_pagamento: null,
      contas_a_pagar: { forma_pagamento: "" },
      centro_de_custo: null,
      elemento_de_custo: null,
      centro_de_custo_id: null,
      elemento_de_custo_id: null,
      optionsP: [
        { key: "av", value: "aVista", text: "Á Vista" },
        { key: "pz", value: "prazo", text: "Prazo" },
      ],
      parcelas: [
        { key: "1", value: "1x", text: "1" },
        { key: "2", value: "2x", text: "2" },
        { key: "3", value: "3x", text: "3" },
        { key: "4", value: "4x", text: "4" },
        { key: "5", value: "5x", text: "5" },
        { key: "6", value: "6x", text: "6" },
        { key: "7", value: "7x", text: "7" },
        { key: "8", value: "8x", text: "8" },
        { key: "9", value: "9x", text: "9" },
        { key: "10", value: "10x", text: "10" },
        { key: "11", value: "11x", text: "11" },
        { key: "12", value: "12x", text: "12" },
      ],
      ordem_pagamento: null,
      quantidadeParcelas: null,
      subtraiParcelas: null,
      tamanhoLista: null,
      tipo: "nome",
      ordem_pagamento_id: null,
      somaParcelas: 0,
      observacao: null,
      open: false,
      contabilizar: true,
      error: false,
      tipo_contabilizacao: null,
    };
    this.alteracaoSelectPessoa = this.alteracaoSelectPessoa.bind(this);
    this.alteracaoSelectItem = this.alteracaoSelectItem.bind(this);
    this.alteracao = this.alteracao.bind(this);
    this.geraParcelas = this.geraParcelas.bind(this);
    this.buscaParametro();
    this.buscaOP();
    this.visualizacao();
    this.alteraValorParcelas = this.alteraValorParcelas.bind(this);
  }

  componentMoney() {
    $(".dinheiro").mask("#.##0,00", {
      reverse: true,
      placeholder: "0,00",
    });
  }

  buscaParametro = async () => {
    if (this.props.location.query === undefined) {
      const retorno = await api.post(
        "/parametrosEntradaItem/visualiza" + requisicoes.entidade,
        "",
        requisicoes.header
      );
      if (retorno.data !== "") {
        let c = renomearChave(retorno.data[0].centro_de_custo, "id", "value");
        c = renomearChave(c, "descricao", "label");
        this.setState({
          centro_de_custo_id: retorno.data[0].centro_de_custo_id,
        });
        this.setState({ centro_de_custo: c });

        let p = renomearChave(retorno.data[0].elemento_de_custo, "id", "value");
        p = renomearChave(p, "descricao", "label");
        this.setState({
          elemento_de_custo_id: retorno.data[0].elemento_de_custo_id,
        });
        this.setState({ elemento_de_custo: p });
      }
    }
  };

  buscaOP = async () => {
    if (this.props.location.query === undefined) {
      const retorno = await api.post(
        "/ordemPagamento/findLast" + requisicoes.entidade,
        "",
        requisicoes.header
      );
      // this.setState({ ordem_pagamento_id: retorno.data.id });
      // this.setState({ ordem_pagamento: retorno.data.codigo });
      this.setState({ ordem_pagamento: retorno.data[0].codigo + 1 });
    }
  };

  alteracao(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  voltar = async () => {
    // if (this.state.id == null) {
    //   await api.post(
    //     "/ordemPagamento/excluir" + requisicoes.entidade,
    //     {
    //       id: this.state.ordem_pagamento_id,
    //     },
    //     requisicoes.header
    //   );
    // }
    localStorage.setItem("pagina_anterior", "entradaItem");
    window.location.replace("/almoxarifado");
  };

  geraParcelas = (id) => {
    let parcelas = [];
    const numeroParcela = id.value.toString().replace("x", "");

    if (id.value == "aVista") {
      parcelas.push({
        numero: "Única",
        valor: this.state.total,
        data_vencimento: parseISO(moment().tz("America/Sao_Paulo").format()),
      });
      this.setState({ infoParcelas: parcelas });
    } else {
      for (var i = 2; i < parseInt(numeroParcela) + 2; i++) {
        let data = null;
        if (this.state.data != null) {
          data = addMonths(parseISO(this.state.data), i);
        } else {
          data = addMonths(
            parseISO(moment().tz("America/Sao_Paulo").format()),
            i
          );
        }
        const mes = getMonth(data) == 0 ? 12 : getMonth(data);
        const ano = getMonth(data) == 0 ? getYear(data) - 1 : getYear(data);

        parcelas.push({
          numero: i - 1,
          valor: parseFloat(
            parseFloat(this.state.total) / parseInt(numeroParcela)
          ).toFixed(2),
          data_vencimento:
            ano +
            "-" +
            (mes.toString().length == 1 ? "0" + mes : mes) +
            "-" +
            (getDate(data).toString().length == 1
              ? "0" + getDate(data)
              : getDate(data)),
        });

        this.setState({ infoParcelas: parcelas });
        this.setState({ tamanhoLista: parcelas.length });
      }
    }
  };

  alteraValorInput = async (event) => {
    let lista = [];
    const indice = event.target.id.toString().substring(12, 20);
    lista = this.state.infoParcelas;
    lista.splice(parseInt(indice), 1, {
      numero: parseInt(indice) + 1,
      valor: parseFloat(event.target.value),
      data_vencimento: lista[parseInt(indice)].data_vencimento,
    });

    if (lista.find((e) => e.valor.toString() === "NaN")) {
      lista.forEach((element) => {
        if (isNaN(element.valor) || element.valor.toString() === "NaN") {
          element.valor = "";
        }
      });
    }
    this.setState({ infoParcelas: lista });
  };

  alteraValorParcelas = async (event) => {
    let valorCampoParcela = parseInt(
      event.target.id.toString().replace("valorParcela", "")
    );

    let valor = event.target.value;
    let listaParcelas = this.state.infoParcelas;
    let soma1 = 0;
    for (let i = 0; i <= valorCampoParcela; i++) {
      const element = listaParcelas[i];
      soma1 += parseFloat(element.valor);
    }

    if (listaParcelas[valorCampoParcela + 1]) {
      let diferenca = parseFloat(this.state.total) - soma1;
      for (let i = valorCampoParcela + 1; i < listaParcelas.length; i++) {
        const element = listaParcelas[i];
        element.valor = (
          diferenca /
          (listaParcelas.length - (valorCampoParcela + 1))
        ).toFixed(2);
      }
      this.setState({ infoParcelas: listaParcelas });
    }
  };

  alteraDataVencimentoParcelas = (event) => {
    let lista = [];
    const indice = event.target.id.toString().substring(20, 30);
    lista = this.state.infoParcelas;
    lista.splice(parseInt(indice), 1, {
      numero: parseInt(indice) + 1,
      valor: lista[parseInt(indice)].valor,
      data_vencimento: event.target.value,
    });

    if (lista.find((e) => e.valor.toString() === "NaN")) {
      lista.forEach((element) => {
        if (element.valor.toString() === "NaN") {
          element.valor = "";
        }
      });
    }

    this.setState({ infoParcelas: lista });
  };

  alteracaoSelectPessoa = async (event) => {
    this.setState({ pessoa_id: event.value });
    this.setState({ pessoa: event });
    const respPessoaInfo = await api.post(
      "/pessoa/visualiza" + requisicoes.entidade,
      { id: event.value },
      requisicoes.header
    );
    if (respPessoaInfo.data !== null) {
      this.setState({ infoPessoa: respPessoaInfo.data });
      this.setState({
        novoEmail: respPessoaInfo.data.email,
        novoTelefone: respPessoaInfo.data.telefone,
      });
      if (this.state.infoPessoa.cnpj != null) {
        this.state.documento = this.state.infoPessoa.cnpj;
      } else {
        this.state.documento = this.state.infoPessoa.cpf;
      }
      this.setState({
        enderecoCompleto:
          this.state.infoPessoa.endereco +
          " - " +
          this.state.infoPessoa.bairro +
          ", " +
          this.state.infoPessoa.cep +
          " " +
          this.state.infoPessoa.cidade +
          " - " +
          this.state.infoPessoa.estado +
          " - CPF/CNPJ: " +
          this.state.documento,
      });
    }
  };

  alteracaoSelectItem = async (event, id) => {
    const idItem = id.name;
    this.setState({ item_id: event.value });
    this.setState({ item: event });
    for (let i = 0; i < this.state.itens.length; i++) {
      if (i == idItem) {
        this.state.itens[i].id = event.value;
        api
          .post(
            "/item/visualiza" + requisicoes.entidade,
            { id: event.value },
            requisicoes.header
          )
          .then((resp) => {
            let unidade_medida = resp.data.unidade_medida;
            let quantidade = resp.data.quantidade;
            if (unidade_medida == undefined) {
              unidade_medida = "UN";
            }
            if (quantidade == undefined) {
              quantidade = 0;
            }
            document
              .getElementById("itens[" + i + "].item.unidade_medida")
              .setAttribute("value", unidade_medida);
            document
              .getElementById("itens[" + i + "].item.quantidade")
              .setAttribute("value", quantidade);
          });
      }
    }
    document
      .getElementById("pesquisaItem" + idItem)
      .removeAttribute("onchange");
  };

  alteracaoSelectPagamento = async (event, id) => {
    if (id.name === "forma_pagamento") {
      this.setState({ forma_pagamento: id.value });
    }
    if (
      id.name === "quantidade_parcelas" ||
      (id.name === "forma_pagamento" && id.value === "aVista")
    ) {
      this.geraParcelas(id);
    }
  };

  alteracaoQuantidade = (indice) => {
    console.log(
      document.getElementById("itens[" + [indice] + "].valor_unidade").value
    );
    // if(document.getElementById("itens[" + [indice] + "].valor_unidade")
    // .value == null || document.getElementById("itens[" + [indice] + "].valor_unidade")
    // .value == ""){
    //   valor = 0;
    // }
    const event = {
      target: {
        id: "itens[" + [indice] + "].valor_unidade",
        value:
          document.getElementById("itens[" + [indice] + "].valor_unidade")
            .value != ""
            ? document.getElementById("itens[" + [indice] + "].valor_unidade")
                .value
            : "0.00",
      },
    };
    this.calculoTotal(event);
  };

  calculoTotal = async (event, id) => {
    const stringId = event.target.id.split(".");
    let total = 0;
    console.log(event.target.value);
    if (stringId[1] === "valor_unidade") {
      total = 0;
      let valor = parseFloat(event.target.value.replace(",", "."));
      let quantidade = parseFloat(
        document.getElementById(stringId[0] + ".quantidade").value
      );
      total = parseFloat(quantidade * valor).toFixed(2);
    }
    let idItem = stringId[0].substring(6, 7);
    for (let i = 0; i < this.state.itens.length; i++) {
      if (i == idItem) {
        this.state.itens[i].valor_total = total.toString().replace(".", ",");
      }
    }
    let totalG = 0;
    for (let i = 0; i < this.state.itens.length; i++) {
      console.log("totalG: ", totalG);
      console.log("valor_total: ", this.state.itens[i].valor_total);

      totalG = parseFloat(
        parseFloat(totalG.toString().replace(",", ".")) +
          parseFloat(this.state.itens[i].valor_total.replace(",", "."))
      ).toFixed(2);
      console.log("totalG: ", totalG);
      this.setState({ total: totalG }); //totalG.replace(".", ",")
      this.setState({ totalGeral: totalG });
    }
    this.setState({ subtraiParcelas: totalG });
    this.setState({ subtraiParcelas: total });
    document.getElementById("totalGeral").setAttribute("value", totalG);
  };

  addItem = async (event) => {
    const item = {};
    this.state.itens.push(item);
    this.state.item = "";
    this.setState(this.state);
  };

  removeItem = async () => {
    let lista = this.state.itens;
    lista.splice(lista.indexOf(1), 1);
    this.setState({ itens: lista });
  };

  visualizacao = async () => {
    if (this.props.location.query !== undefined) {
      const resposta = await api.post(
        "/entradaItem/visualiza" + requisicoes.entidade,
        this.props.location.query,
        requisicoes.header
      );
      this.setState(resposta.data);
      this.setState({
        contabilizar: resposta.data.contas_a_pagar_entrada_item[0].contabilizar,
        tipo_contabilizacao: resposta.data.contas_a_pagar_entrada_item[0]
          .tipo_contabilizacao
          ? optionsTipoContabilizacao.find(
              (e) =>
                e.value ===
                resposta.data.contas_a_pagar_entrada_item[0].tipo_contabilizacao
            )
          : resposta.data.contas_a_pagar_entrada_item[0].tipo_contabilizacao,
      });
      this.setState({ totalGeral: resposta.data.total });
      let parcelas = [];
      resposta.data.contas_a_pagar_entrada_item.forEach((c) => {
        this.setState({ forma_pagamento: c.forma_pagamento });
        this.setState({ ordem_pagamento: c.ordem_pagamento.codigo });
        this.setState({ contas_a_pagar: c });
        c.lancamento_contas.forEach((e, i) => {
          parcelas.push({
            numero: i + 1,
            valor: e.valor,
            data_vencimento: e.data_vencimento,
          });
        });
        this.setState({ infoParcelas: parcelas });
      });
      this.setState({ itens: resposta.data.itens });

      this.state.itens.forEach((e) => {
        const item = api.post(
          "/item/findOne" + requisicoes.entidade,
          { pesquisaId: e.item_id },
          requisicoes.header
        );
        item.then((result) => {
          let o = renomearChave(result.data, "id", "value");
          o = renomearChave(o, "descricao", "label");
          this.setState({ item_id: result.data.id });
          this.setState({ item: o });
        });
      });
      let p = renomearChave(resposta.data.pessoa, "id", "value");
      p = renomearChave(p, "nome", "label");
      this.setState({ pessoa_id: resposta.data.pessoa_id });
      this.setState({ pessoa: p });

      let c = renomearChave(resposta.data.centro_de_custo, "id", "value");
      c = renomearChave(c, "descricao", "label");
      this.setState({ centro_de_custo_id: resposta.data.centro_de_custo_id });
      this.setState({ centro_de_custo: c });

      let e = renomearChave(resposta.data.elemento_de_custo, "id", "value");
      e = renomearChave(e, "descricao", "label");
      this.setState({
        elemento_de_custo_id: resposta.data.elemento_de_custo_id,
      });
      this.setState({ elemento_de_custo: e });

      const respPessoaInfo = await api.post(
        "/pessoa/buscaID" + requisicoes.entidade,
        { id: resposta.data.pessoa_id },
        requisicoes.header
      );
      this.setState({ infoPessoa: respPessoaInfo.data });
      this.setState({
        novoEmail: respPessoaInfo.data.email,
        novoTelefone: respPessoaInfo.data.telefone,
      });
      if (this.state.infoPessoa.cnpj != null) {
        this.state.documento = this.state.infoPessoa.cnpj;
      } else {
        this.state.documento = this.state.infoPessoa.cpf;
      }
      this.setState({
        enderecoCompleto:
          this.state.infoPessoa.endereco +
          " - " +
          this.state.infoPessoa.bairro +
          ", " +
          this.state.infoPessoa.cep +
          " " +
          this.state.infoPessoa.cidade +
          " - " +
          this.state.infoPessoa.estado +
          " - CPF/CNPJ: " +
          this.state.documento,
      });
    }
  };

  pesquisaPessoa = (newValue) => {
    api
      .post(
        "/pessoa/pesquisaFornecedor" + requisicoes.entidade,
        { pesquisa: newValue },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomear4Chaves(
            objeto,
            "reduzido",
            "nome",
            "cnpj",
            "nomefantasia",
            "label"
          );

          objeto.isDisabled = objeto.conta_contabil_id === null;

          lista.push(objeto);
        });
        this.setState({ options: lista });
      });
  };

  pesquisaItem = (newValue) => {
    api
      .post(
        "/item/pesquisa" + requisicoes.entidade,
        { pesquisa: newValue },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto.id = element.id;
          objeto = renomear2Chaves(objeto, "id", "descricao", "label");
          lista.push(objeto);
        });
        this.setState({ optionsI: lista });
      });
  };

  mostraMensagem = (header, content) => {
    this.setState({
      salvando: false,
      mensagem: <Message negative header={header} content={content} />,
    });
    setTimeout(() => {
      this.setState({ mensagem: null });
    }, 4000);
  };

  verificaDataExportacaoContabil = async (data) => {
    const maxDataFinal = await api.post(
      "/exportacaoContabil/maiorDataFinalRemessaGeradaTipo" +
        requisicoes.entidade,
      { arquivo: "ContasAPagar" },
      requisicoes.header
    );
    if (maxDataFinal.data && maxDataFinal.data >= data) {
      return false;
    } else {
      return true;
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ salvando: true });

    if (!this.state.pessoa_id || !this.state.tipo_contabilizacao) {
      if (!this.state.pessoa_id) {
        this.mostraMensagem(
          "Valores de entrada inválidos",
          "Selecione um fornecedor."
        );
        return;
      }
      if (!this.state.tipo_contabilizacao) {
        this.mostraMensagem(
          "Valores de entrada inválidos",
          "Selecione o tipo de contabilizacão para a conta a pagar a ser gerada."
        );
        return;
      }
    } else if (
      await this.verificaDataExportacaoContabil(this.state.data_emissao)
    ) {
      this.submit();
    } else {
      this.mostraMensagem(
        "Data inválida",
        "Já foi gerada exportação contábil para a data seleciona. Por favor, entre com outra data."
      );
    }
  };

  submit = async () => {
    const c = this.state;
    let retorno = null;
    let total = 0;
    for (let i = 0; i < c.itens.length; i++) {
      c.itens[i].quantidade = document.getElementById(
        "itens[" + i + "].quantidade"
      ).value;

      c.itens[i].valor_unidade = ReplaceAll(
        ",",
        ".",
        document.getElementById("itens[" + i + "].valor_unidade").value
      );
      c.itens[i].valor_total = ReplaceAll(
        ",",
        ".",
        document.getElementById("itens[" + i + "].valor_total").value
      );

      //verificacao se o valor entrado está certo.
      if (
        c.itens[i].quantidade === "" ||
        c.itens[i].valor_total.indexOf(".") !==
          c.itens[i].valor_total.lastIndexOf(".") ||
        c.itens[i].valor_unidade.indexOf(".") !==
          c.itens[i].valor_unidade.lastIndexOf(".")
      ) {
        this.mostraMensagem(
          "Valores de entrada inválidos",
          "Valores dos itens inválidos. Verifique os dados e tente novamente."
        );
        return;
      }
    }

    let lista = [];
    try {
      if (this.state.id === null) {
        this.state.infoParcelas.forEach((e, i) => {
          let v = ReplaceAll(",", ".", e.valor.toString());
          if (v.indexOf(".") !== v.lastIndexOf(".")) {
            this.mostraMensagem(
              "Valores de entrada inválidos",
              "Valores dos itens inválidos. Verifique os dados e tente novamente."
            );
            return;
          }
          lista.push({
            descricao: "Cobrança Fornecedor",
            data_emissao: moment().format("YYYY-MM-DD"),
            tipo_lancamento: TipoLancamento.find(
              (t) => t.value === "contaAPagar"
            ).value,
            valor: v,
            data_vencimento: e.data_vencimento,
            credito_debito: CreditoDebito.find((c) => c.value === "debito")
              .value,
            pessoa_id: this.state.pessoa_id,
            numero_parcela: i + 1,
          });
          this.state.somaParcelas =
            parseFloat(this.state.somaParcelas.toFixed(6)) +
            parseFloat(e.valor);
        });

        this.state.somaParcelas = parseFloat(
          this.state.somaParcelas.toFixed(6)
        );

        let totalItens = 0;
        for (let i = 0; i < this.state.itens.length; i++) {
          totalItens = totalItens + parseFloat(this.state.itens[i].valor);
        }
        let verificaTotal =
          parseFloat(this.state.total.replace(",", ".")) -
          this.state.somaParcelas;
        if (verificaTotal === 0) {
          // const respLancamentos = await api.post(
          //   "/lancamentoConta/salvarVarios" + requisicoes.entidade,
          //   lista,
          //   requisicoes.header
          // );
          const dados = {
            pessoa_id: c.pessoa_id,
            total: c.totalGeral.replace(",", "."),
            data_emissao: moment(c.data_emissao).format("YYYY-MM-DD"),
            num_doc: c.num_doc,
            itens: c.itens,
            contas_a_pagar: c.contas_a_pagar,
            vencimento: c.vencimento,
            lancamentos: lista,
            // lancamentos: respLancamentos.data,
            forma_pagamento: c.forma_pagamento,
            ordem_pagamento: c.ordem_pagamento,
            centro_de_custo_id: c.centro_de_custo_id,
            elemento_de_custo_id: c.elemento_de_custo_id,
            observacao: c.observacao,
            contabilizar: c.contabilizar,
            tipo_contabilizacao: c.tipo_contabilizacao
              ? c.tipo_contabilizacao.value
              : c.tipo_contabilizacao,
          };

          const resultEntradaItem = await api
            .post(
              "/entradaItem/salvar" + requisicoes.entidade,
              dados,
              requisicoes.header
            )

            .catch((error) => {
              if (error.response.data && error.response.data.trim !== "") {
                this.state.error = true;
                alert(error.response.data);
              }
            });
          if (
            resultEntradaItem.data.contas_a_pagar_entrada_item[0]
              .ordem_pagamento.codigo !== c.ordem_pagamento
          ) {
            alert(
              "A OP " +
                this.state.ordem_pagamento +
                " já foi utilizada por outro usuário. \n" +
                "Redirecionamos esta OP para o número " +
                resultEntradaItem.data.contas_a_pagar_entrada_item[0]
                  .ordem_pagamento.codigo +
                "."
            );
          }
          localStorage.setItem("pagina_anterior", "entradaItem");
          window.location.href = "/almoxarifado";
        } else {
          if (this.state.error === false) {
            alert(
              "Atenção a soma das parcelas não corresponde ao valor total! R$ " +
                this.state.somaParcelas
            );
          } else {
            alert(
              "O documento informado já foi utilizado em outra conta a pagar com este mesmo fornecedor."
            );
          }
          this.setState({ somaParcelas: 0 });
          this.setState({ salvando: false });
        }
      } else {
        const dados = {
          id: c.id,
          pessoa_id: c.pessoa_id,
          total: c.totalGeral,
          data_emissao: c.data_emissao,
          num_doc: c.num_doc,
          itens: c.itens,
          contas_a_pagar: c.contas_a_pagar,
          vencimento: c.vencimento,
          lancamentos: this.state.infoParcelas,
          forma_pagamento: c.forma_pagamento,
          ordem_pagamento: c.ordem_pagamento,
          centro_de_custo_id: c.centro_de_custo_id,
          elemento_de_custo_id: c.elemento_de_custo_id,
          observacao: c.observacao,
          contabilizar: c.contabilizar,
          tipo_contabilizacao: c.tipo_contabilizacao
            ? c.tipo_contabilizacao.value
            : c.tipo_contabilizacao,
        };
        await api
          .post(
            "/entradaItem/alterar" + requisicoes.entidade,
            dados,
            requisicoes.header
          )
          .catch((error) => {
            console.log("err.response.data:", error);
          });
        localStorage.setItem("pagina_anterior", "entradaItem");
        window.location.href = "/almoxarifado";
      }
    } catch (e) {
      console.log("erro: ", e);
      this.setState({ salvando: false });
      if (this.state.error === false) {
        alert("Ocorreu um erro ao salvar o registro 111:");
      }
    }
  };

  pesquisaPessoaReduzido = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/pessoa/pesquisaReduzidoFornecedor" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaPessoaReduzido").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        if (
          !isNaN(
            parseFloat(document.getElementById("pesquisaPessoaReduzido").value)
          ) &&
          isFinite(document.getElementById("pesquisaPessoaReduzido").value)
        ) {
          if (element.reduzido !== null) {
            let objeto = renomearChave(element, "id", "value");
            objeto = renomearChave(objeto, "reduzido", "label");
            objeto.label = objeto.label + " - " + objeto.nome;
            lista.push(objeto);
          }
        }
      });
      this.setState({ options: lista });
    }, 500);
  };

  handleClose = () => this.setState({ open: false });
  handleShow = () => this.setState({ open: true });

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Entrada de Itens
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={5}>
              <Form.Label>Fornecedor</Form.Label>
              <Select
                placeholder="Digite para buscar"
                name="pessoa"
                value={this.state.pessoa}
                onChange={this.alteracaoSelectPessoa}
                options={this.state.options}
                onInputChange={this.pesquisaPessoa}
                inputId="pesquisaPessoa"
              />
            </Col>
            <Col md="auto">
              <Form.Label>Data Emissão</Form.Label>
              <FormControl
                type="date"
                aria-describedby="basic-addon1"
                name="data_emissao"
                onChange={this.alteracao}
                value={this.state.data_emissao.toString().substr(0, 10)}
                required
              />
            </Col>
            <Col fluid>
              <Form.Label>Número Documento</Form.Label>
              <FormField
                placeholder="Nº"
                name="num_doc"
                control={Input}
                onChange={this.alteracao}
                defaultValue={this.state.num_doc}
                required
                fluid
              />
            </Col>
            <Col md="auto">
              <Form.Label>Contabilizar</Form.Label>
              <FormField
                control={Checkbox}
                required
                fluid
                toggle
                onChange={(e, a) => {
                  this.setState({ contabilizar: a.checked });
                  if (a.checked === false)
                    this.setState({ tipo_contabilizacao: null });
                }}
                checked={this.state.contabilizar}
              />
            </Col>
            <Col fluid>
              <Form.Label>Tipo de contabilização</Form.Label>
              <FormField
                control={Select}
                isDisabled={!this.state.contabilizar}
                options={optionsTipoContabilizacao}
                value={this.state.tipo_contabilizacao}
                onChange={(e, a) => this.setState({ tipo_contabilizacao: e })}
                placeholder={"Selecione o tipo"}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Label>Endereço</Form.Label>
              <FormControl
                placeholder="Endereço"
                name="endereco"
                control={Input}
                onChange={this.alteracao}
                defaultValue={this.state.enderecoCompleto}
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <Form.Label>Ordem de Pagamento</Form.Label>
              <FormControl
                placeholder="Nº"
                name="codigoOp"
                control={Input}
                onChange={this.alteracao}
                defaultValue={this.state.ordem_pagamento}
                required
                fluid
                readOnly
              />
            </Col>
          </Row>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={5}>Descrição</Table.HeaderCell>
                <Table.HeaderCell width={2}>Unidade Medida</Table.HeaderCell>
                <Table.HeaderCell width={2}>Estoque</Table.HeaderCell>
                <Table.HeaderCell width={2}>Quantidade</Table.HeaderCell>
                <Table.HeaderCell width={2}>Valor Unitário</Table.HeaderCell>
                <Table.HeaderCell width={2}>Valor Total</Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  <Button
                    positive
                    icon="add"
                    size="tiny"
                    onClick={this.addItem}
                    type="button"
                    style={{ marginTop: "7%" }}
                  ></Button>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.itens.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell>
                    {this.state.id === null && (
                      <Select
                        placeholder="Adicionar Item"
                        name={[i]}
                        onChange={this.alteracaoSelectItem}
                        options={this.state.optionsI}
                        onInputChange={this.pesquisaItem}
                        inputId={"pesquisaItem" + i}
                      />
                    )}
                    {this.state.id !== null && (
                      <Select
                        placeholder="Adicionar Item"
                        name={[i]}
                        onChange={this.alteracaoSelectItem}
                        options={this.state.optionsI}
                        onInputChange={this.pesquisaItem}
                        inputId={"pesquisaItem" + i}
                        isDisabled={this.state.id !== null}
                        inputValue={e.item.descricao}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <FormField
                      placeholder="Kg"
                      id={"itens[" + [i] + "].item.unidade_medida"}
                      name={"itens[" + [i] + "].item.unidade_medida"}
                      control={Input}
                      fluid
                      disabled
                    />
                  </Table.Cell>
                  <Table.Cell width={1}>
                    <FormField
                      placeholder="0"
                      id={"itens[" + [i] + "].item.quantidade"}
                      name={"itens[" + [i] + "].item.quantidade"}
                      control={Input}
                      fluid
                      disabled
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <FormField
                      placeholder=""
                      onChange={() => this.alteracaoQuantidade(i)}
                      id={"itens[" + [i] + "].quantidade"}
                      name={"itens[" + [i] + "].quantidade"}
                      control={Input}
                      defaultValue={e.quantidade}
                      fluid
                      type="number"
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <FormField
                      placeholder="R$"
                      id={"itens[" + [i] + "].valor_unidade"}
                      control={Input}
                      onChange={(e) => this.calculoTotal(e)}
                      name={"itens[" + [i] + "].valor_unidade"}
                      defaultValue={e.valor_unidade}
                      className="dinheiro"
                      onKeyUp={this.componentMoney}
                      fluid
                      type="number"
                      step="0.0001"
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <FormControl
                      placeholder="R$"
                      id={"itens[" + [i] + "].valor_total"}
                      control={Input}
                      name={"itens[" + [i] + "].valor_total"}
                      className="dinheiro"
                      onKeyUp={this.componentMoney}
                      value={"" || e.valor_total}
                      fluid
                      disabled
                    />
                  </Table.Cell>
                  <Table.Cell width={1}>
                    <Button
                      negative
                      icon="remove"
                      size="tiny"
                      onClick={(e) => this.removeItem(e.target.value)}
                      type="button"
                      style={{ marginTop: "7%" }}
                      value={i}
                    ></Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.Cell>Total:</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell>
                  <FormInput
                    id="totalGeral"
                    control={Input}
                    name="totalGeral"
                    value={
                      this.state.total === 0
                        ? "0,00"
                        : parseFloat(this.state.total)
                            .toFixed(2)
                            .replace(".", ",")
                    }
                    fluid
                    disabled
                  />
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
            </Table.Footer>
          </Table>
          <Row>
            <Col sm={12}>
              <Form.Label>Observação</Form.Label>
              <TextArea
                rows={3}
                placeholder="Descrição"
                className="col-12"
                value={this.state.observacao}
                onChange={this.alteracao}
                name="observacao"
                id="observacao"
                as="textarea"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Label>Forma de Pagamento</Form.Label>
              <Dropdown
                placeholder="Forma de Pagamento"
                fluid
                selection
                name="forma_pagamento"
                options={this.state.optionsP}
                onChange={this.alteracaoSelectPagamento}
                value={
                  this.state.contas_a_pagar != null
                    ? this.state.forma_pagamento
                    : ""
                }
              />
            </Col>
            {this.state.forma_pagamento === "prazo" && (
              <Col sm={3}>
                <Form.Label>Quantidade Parcelas</Form.Label>
                <Dropdown
                  placeholder="Parcelas"
                  fluid
                  selection
                  name="quantidade_parcelas"
                  options={this.state.parcelas}
                  onChange={this.alteracaoSelectPagamento}
                  // defaultValue={this.state.parcelas[parseInt(this.state.quantidadeParcelas)].value}
                />
              </Col>
            )}
            {this.state.infoParcelas.map((e, i) => {
              return (
                <Col key={i} sm={6}>
                  <Row>
                    <Col sm={4}>
                      Parcela {e.numero}
                      <FormControl
                        value={e.valor}
                        id={"valorParcela" + i}
                        onChange={this.alteraValorInput}
                        onBlur={
                          this.state.id !== null ? "" : this.alteraValorParcelas
                        }
                        name="valorParcela"
                        placeholder="R$"
                        type="number"
                        fluid
                      />
                    </Col>
                    <Col sm={6}>
                      Vencimento
                      <FormControl
                        type="date"
                        defaultValue={e.data_vencimento}
                        id={"dataVencimentoTitulo" + i}
                        // disabled={this.state.contas_a_pagar !== null}
                        onChange={this.alteraDataVencimentoParcelas}
                      />
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Label>Centro de Custo</Form.Label>
              <Select
                name="centroCusto"
                value={this.state.centro_de_custo}
                defaultValue={this.state.centro_de_custo}
                isDisabled
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Elemento de Custo</Form.Label>
              <Select
                name="elementoCusto"
                value={this.state.elemento_de_custo}
                defaultValue={this.state.elemento_de_custo}
                isDisabled
              />
            </Col>
          </Row>
          <Divider />
          {this.state?.mensagem}
          <Segment clearing>
            <Row>
              <Col sm={8}>
                <Card.Group>
                  <Card>
                    {this.state.id != null &&
                      this.state.historico_entrada_item != 0 && (
                        <Card.Content>
                          <Button
                            floated="right"
                            size="mini"
                            icon="list"
                            color="olive"
                            onClick={this.handleShow}
                            type="button"
                          />
                          <Card.Meta style={{ color: "black" }}>
                            {this.state.id == null &&
                            this.state.historico_entrada_item <= 0
                              ? ""
                              : "Usuário: " +
                                this.state.historico_entrada_item[
                                  this.state.historico_entrada_item.length - 1
                                ].usuario.nome}
                          </Card.Meta>
                          <Card.Description style={{ color: "black" }}>
                            {this.state.id == null
                              ? ""
                              : this.state.historico_entrada_item[
                                  this.state.historico_entrada_item.length - 1
                                ].usuario.entidade +
                                ": " +
                                format(
                                  parseISO(
                                    this.state.historico_entrada_item[
                                      this.state.historico_entrada_item.length -
                                        1
                                    ].data
                                  ),
                                  "dd/MM/yyyy"
                                ) +
                                " " +
                                this.state.historico_entrada_item[
                                  this.state.historico_entrada_item.length - 1
                                ].hora +
                                " hs"}
                          </Card.Description>
                        </Card.Content>
                      )}
                  </Card>
                </Card.Group>
              </Col>
              <Col sm={4}>
                <Header floated="right">
                  <Button type="button" onClick={this.voltar}>
                    Voltar
                  </Button>
                  <Button
                    positive
                    type="submit"
                    loading={this.state.salvando}
                    disabled={this.state.salvando}
                  >
                    Salvar
                  </Button>
                </Header>
              </Col>
            </Row>
          </Segment>
          <Modal
            dimmer={"blurring"}
            open={this.state.open}
            onClose={this.handleClose}
          >
            <Modal.Header>Histórico</Modal.Header>
            <Modal.Content>
              <List>
                {this.state.id != null &&
                  this.state.historico_entrada_item != null &&
                  this.state.historico_entrada_item.map((e, i) => (
                    <List.Item>
                      <List.Icon
                        name="file alternate"
                        size="large"
                        verticalAlign="middle"
                        color="olive"
                      />
                      <List.Content>
                        <List.Header as="a">
                          Usuário: {e.usuario.nome}
                        </List.Header>
                        <List.Description>
                          Data: {format(parseISO(e.data), "dd/MM/yyyy")}
                        </List.Description>
                        <List.Description>Hora: {e.hora}</List.Description>
                        <List.Description>Ação: {e.acao}</List.Description>
                      </List.Content>
                    </List.Item>
                  ))}
              </List>
            </Modal.Content>
            <Modal.Actions clearing>
              <Button onClick={this.handleClose}>Fechar</Button>
            </Modal.Actions>
          </Modal>
        </Form>
      </div>
    );
  }
}
