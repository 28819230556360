import React, { Component } from "react";
import { Row, Col, FormControl, Form } from "react-bootstrap";
import {
  Container,
  Header,
  Button,
  Divider,
  Segment,
  Input,
  Modal,
  FormField,
  Message,
  Transition,
  Icon,
} from "semantic-ui-react";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";
import Select from "react-select";
import renomearChave from "../../../../services/renomearChave";

export default class ArmaIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      grupo_arma_id: null,
      grupo_arma: null,
      modelo: null,
      marca: null,
      salvo: false,
      nome_grupo: null,
    };
    this.visualizacao();
    this.alteracao = this.alteracao.bind(this);
    this.alteracaoSelectGrupo = this.alteracaoSelectGrupo.bind(this);
  }

  visualizacao = async () => {
    if (this.props.location.query !== undefined) {
      const resposta = await api.post(
        "/arma/visualiza" + requisicoes.entidade,
        this.props.location.query,
        requisicoes.header
      );
      this.setState(resposta.data);
      let o = renomearChave(resposta.data.grupo, "id", "value");
      o = renomearChave(o, "nome", "label");
      this.setState({ grupo_arma_id: resposta.data.grupo_arma_id });
      this.setState({ grupo_arma: o });
    }
  };

  alteracao(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  alteracaoSelectGrupo(event) {
    console.log(event.value);
    this.setState({ grupo_arma_id: event.value });
    this.setState({ grupo_arma: event });
  }

  handleSubmit = (event) => {
    this.submit();
    event.preventDefault();
  };

  voltar() {
    window.location.href = "/administrativo";
  }

  submit = async () => {
    if (this.state.id !== null) {
      await api.post(
        "/arma/alterar" + requisicoes.entidade + "&id=" + this.state.id,
        this.state,
        requisicoes.header
      );
    } else {
      await api.post(
        "/arma/salvar" + requisicoes.entidade,
        this.state,
        requisicoes.header
      );
    }
    window.location.href = "/administrativo";
  };

  submitGrupo = async () => {
    await api
      .post(
        "/arma/salvar_grupo" + requisicoes.entidade,
        { nome: this.state.nome_grupo },
        requisicoes.header
      )
      .then(() => {
        this.setState({ modalGrupo: false });
        this.setState({ salvo: true });
      });
    setTimeout(() => {
      this.setState({ salvo: false });
    }, 5000);
  };

  pesquisaGrupo = (newValue) => {
    api
      .post(
        "/arma/pesquisa_grupo" + requisicoes.entidade,
        { pesquisa: newValue },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomearChave(objeto, "nome", "label");
          lista.push(objeto);
        });
        this.setState({ optionsG: lista });
      });
  };

  render() {
    return (
      <div>
        <Header as="h1" dividing>
          Cadastro de arma
        </Header>
        <Transition
          visible={this.state.salvo}
          animation="scale"
          duration={{ hide: 1800, show: 2600 }}
        >
          <Message
            positive
            floating
            icon
            style={{ width: "30%", textAlign: "center" }}
          >
            <Row>
              <Icon name="check" size="huge" />
              <Message.Content style={{ marginLeft: "2%", marginTop: "1%" }}>
                <Message.Header>Grupo salvo com sucesso!</Message.Header>
                <p>Disponível na pesquisa de grupos.</p>
              </Message.Content>
            </Row>
          </Message>
        </Transition>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={5}>
              <Form.Label>Grupo</Form.Label>
              <Select
                placeholder="Digite para buscar"
                name="grupo_arma"
                value={this.state.grupo_arma}
                onChange={this.alteracaoSelectGrupo}
                options={this.state.optionsG}
                onInputChange={this.pesquisaGrupo}
              />
            </Col>
            <Col sm={1}>
              <Button
                positive
                icon="add"
                size="tiny"
                style={{ marginTop: "30px" }}
                onClick={() => this.setState({ modalGrupo: true })}
                type="button"
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Modelo</Form.Label>
              <FormControl
                placeholder="Modelo"
                name="modelo"
                onChange={this.alteracao}
                value={this.state.modelo}
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Marca</Form.Label>
              <FormControl
                placeholder="Marca"
                name="marca"
                onChange={this.alteracao}
                value={this.state.marca}
              />
            </Col>
          </Row>
          <Divider />
          <Segment clearing>
            <Header floated="right">
              <Button type="button" onClick={this.voltar}>
                Voltar
              </Button>
              <Button positive type="submit">
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>

        <Modal
          open={this.state.modalGrupo}
          dimmer={"blurring"}
          centered={false}
        >
          <Modal.Header>Cadastro Grupo</Modal.Header>
          <Modal.Content>
            <Row>
              <Col sm={6}>
                <Form.Label>Nome</Form.Label>
                <FormField
                  placeholder="Nome"
                  name="nome_grupo"
                  control={Input}
                  onChange={this.alteracao}
                  required
                  defaultValue={this.state.nome_grupo}
                  fluid
                />
              </Col>
            </Row>
          </Modal.Content>
          <Modal.Actions>
            <Button
              variant="light"
              onClick={() => this.setState({ modalGrupo: false })}
              style={{ marginLeft: "10px" }}
            >
              Voltar
            </Button>
            <Button positive onClick={this.submitGrupo}>
              Salvar
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
