const listaEnum = [
  { value: 0, label: "Débito/crédito efetuado" },
  { value: 1, label: "Débito não efetuado - Insuficiência de fundos" },
  { value: 2, label: "Débito não efetuado - Conta não cadastrada." },
  { value: 3, label: "Tipo Registro Invalido" },
  { value: 4, label: "Débito não efetuado - Outras restrições" },
  {
    value: 5,
    label:
      "Débito não efetuado – valor do débito excede valor limite aprovado.",
  },
  {
    value: 10,
    label: "Débito não efetuado - Agência em regime de encerramento",
  },
  { value: 12, label: "Débito não efetuado - Valor inválidos" },
  { value: 13, label: "Débito não efetuado - Data de lançamento inválida" },
  { value: 14, label: "Débito não efetuado - Agência inválida" },
  { value: 15, label: "Débito não efetuado - conta inválida" },
  {
    value: 18,
    label: "Débito não efetuado - Data do débito anterior à de processamento",
  },
  {
    value: 19,
    label:
      "Débito não efetuado – Agência/Conta não pertence ao CPF/CNPJ informado",
  },
  { value: 20, label: "Débito não efetuado – conta conjunta não solidária" },
  {
    value: 30,
    label: "Débito não efetuado - Sem contrato de débito automático",
  },
  {
    value: 31,
    label:
      "Débito efetuado em data diferente da data informada – feriado na praça de débito",
  },
  { value: 96, label: "Manutenção do Cadastro" },
  { value: 97, label: "Cancelamento - Não Encontrado" },
  { value: 98, label: "Cancelamento - Não efetuado, fora do tempo hábil" },
  { value: 99, label: "Cancelamento - cancelado conforme solicitação" },

  // { value: 1, label: 'Número Remessa Inválido' },
  // { value: 2, label: 'Arquivo sem “HEADER”.' },
  // { value: 3, label: 'Tipo Registro Invalido' },
  // { value: 4, label: 'Código Banco Inválido' },
  // { value: 5, label: 'Insuficiência de Fundos' }
];
export default listaEnum;

/*
“00” = Débito efetuado
“01” = Débito não efetuado - Insuficiência de fundos
“02” = Débito não efetuado - Conta não cadastrada
“04” = Débito não efetuado - Outras restrições
“05” = Débito não efetuado – valor do débito excede valor limite
aprovado.
“10” = Débito não efetuado - Agência em regime de encerramento
“12” = Débito não efetuado - Valor inválido
“13” = Débito não efetuado - Data de lançamento inválida
“14” = Débito não efetuado - Agência inválida
“15” = Débito não efetuado - conta inválida
“18” = Débito não efetuado - Data do débito anterior à do
processamento
“19”= Débito não efetuado – Agência/Conta não pertence ao
CPF/CNPJ informado
“20” = Débito não efetuado – conta conjunta não solidária
“30” = Débito não efetuado - Sem contrato de débito automático
“31” = Débito efetuado em data diferente da data informada –
feriado na praça de débito
“96” = Manutenção do Cadastro
“97” = Cancelamento - Não encontrado
“98” = Cancelamento - Não efetuado, fora do tempo hábil
“99” = Cancelamento - cancelado conforme solicitação
*/
