import React, { Component } from "react";
import { Header, Table, Container, Button } from "semantic-ui-react";
import { Row } from "react-bootstrap";

// libs
import fileDownload from "js-file-download";
import moment from "moment-timezone";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

export default class ListaRemessaBoleto extends Component {
  constructor(props) {
    super(props);
    this.state = { lista: [] };
    this.buscaLancamentoParaRemessa();
  }

  buscaLancamentoParaRemessa = async () => {
    const retorno = await api.post(
      "/remessa/cobrancasNaoGeradoRemessa" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ lista: retorno.data });
  };

  render() {
    return (
      <div className="pagina">
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Data Emissão</Table.HeaderCell>
              <Table.HeaderCell>Descrição</Table.HeaderCell>
              <Table.HeaderCell>Valor</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.lista.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>
                  {moment(e.data_emissao).format("DD/MM/YYYY")}
                </Table.Cell>
                <Table.Cell>{e.descricao}</Table.Cell>
                <Table.Cell>{e.valor}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer></Table.Footer>
        </Table>
      </div>
    );
  }
}
