import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import Button from "@material-ui/core/Button";
import FilterTiltShiftIcon from "@material-ui/icons/FilterTiltShift";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import "../styles.css";

export default class lista extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Table striped selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>NOME</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">PEDANA</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">RODADA</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">PONTUAÇÃO</Table.HeaderCell>
            <Table.HeaderCell textAlign="center"></Table.HeaderCell>
            <Table.HeaderCell textAlign="center">REMOVER</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.lista_atiradores.map(
            (element, index) => (
              console.log(element),
              (
                <Table.Row key={index}>
                  <Table.Cell width={3} textAlign="left">
                    {" "}
                    {element.nome}
                  </Table.Cell>

                  <Table.Cell width={2} textAlign="center">
                    {element.pedana}
                  </Table.Cell>
                  <Table.Cell width={2} textAlign="center">
                    {element.rodada}
                  </Table.Cell>
                  <Table.Cell width={2} textAlign="center">
                    {element.pontuacoes[0] !== undefined &&
                    element.pontuacoes[0] !== null &&
                    element.pontuacoes[0].pontuacao !== undefined &&
                    element.pontuacoes[0].pontuacao !== null
                      ? element.pontuacoes[0].pontuacao
                      : "0"}
                  </Table.Cell>
                  <Table.Cell width={1} textAlign="center">
                    <Link
                      to={{
                        pathname: "/eventos/lancamento/pontuacao",
                        query: {
                          id: element.id,
                          etapa_id: this.props.etapa_id,
                          evento_id: this.props.evento_id,
                          numero_etapa: this.props.numero_etapa,
                          pedana: this.props.pedana,
                          status: this.props.status,
                          nome: element.nome,
                          arma: element.arma_modelo,
                          regra_pontuacao: this.props.regra_pontuacao,
                        },
                      }}
                    >
                      <Button
                        icon
                        size="mini"
                        style={{ outline: "none" }}
                        className="botaoTabble"
                      >
                        <FilterTiltShiftIcon
                          size="small"
                          name="configure"
                          id={element.id}
                        />
                      </Button>
                    </Link>
                  </Table.Cell>
                  <Table.Cell width={2} textAlign="center">
                    {" "}
                    <Button
                      onClick={() =>
                        this.props.changeModalExclusao(
                          true,
                          element.nome,
                          element.id
                        )
                      }
                      icon
                      size="mini"
                      style={{ outline: "none" }}
                      className="botaoTabbleDelete"
                    >
                      <DeleteIcon
                        size="small"
                        name="configure"
                        id={element.id}
                        className="botaoTabbleDelete2"
                      />
                    </Button>
                  </Table.Cell>
                </Table.Row>
              )
            )
          )}
        </Table.Body>
      </Table>
    );
  }
}
