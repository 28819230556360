import React, { Component } from "react";
import { Table, Header, Form, Button, Card } from "semantic-ui-react";
import { Row, Col, FormControl } from "react-bootstrap";
import moment from "moment-timezone";

export default class exames extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  comparaDatas(data) {
    return new Date(data) >= new Date() ? true : false;
  }

  render() {
    return (
      <Card.Content>
        <Card.Header>Exame Médico</Card.Header>
        <Card.Description>
          <Row>
            <Col sm={6}>
              <Form.Label>Data Validade</Form.Label>
              <FormControl
                type="date"
                aria-describedby="basic-addon1"
                disabled={true}
                value={
                  this.props.examesMedicos.length > 0 &&
                  this.props.examesMedicos[0] !== null &&
                  this.props.examesMedicos[0].dataValidade
                }
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Autorização Médica</Form.Label>
              {this.props.examesMedicos.length > 0 &&
              this.props.examesMedicos[0] !== null &&
              this.comparaDatas(this.props.examesMedicos[0].dataValidade) ? (
                <Button fluid icon="thumbs up" positive></Button>
              ) : (
                <Button fluid icon="thumbs down" negative></Button>
              )}
            </Col>
          </Row>
        </Card.Description>
        <Card.Header>Exame Físico</Card.Header>

        <Card.Description>
          <Row>
            <Col sm={6}>
              <Form.Label>Data Validade</Form.Label>
              <FormControl
                type="date"
                aria-describedby="basic-addon1"
                disabled={true}
                value={
                  this.props.examesFisicos.length > 0 &&
                  this.props.examesFisicos[0] !== null &&
                  this.props.examesFisicos[0].dataValidade
                }
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Autorização Médica</Form.Label>

              {this.props.examesFisicos.length > 0 &&
              this.props.examesFisicos[0] !== null &&
              this.comparaDatas(this.props.examesFisicos[0].dataValidade) ? (
                <Button fluid icon="thumbs up" positive></Button>
              ) : (
                <Button fluid icon="thumbs down" negative></Button>
              )}
            </Col>
          </Row>
        </Card.Description>
      </Card.Content>
    );
  }
}
