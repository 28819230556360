import React, { Component } from "react";
import api from "../services/api";
import logo from "../images/faeeazul.png";
import { Menu, Image, Icon, Container, Button } from "semantic-ui-react";
import { storage } from "../config/firebase";

import logosClubes from "../images/logo";

import { Link } from "react-router-dom";

const logoMenu = logo;

export default class MenuContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // menus: [],
      menus: this.props.menus,
      srcImagem: null,
      visible: true,
      width: window.innerWidth,
    };
    console.log("props: ", props);
    console.log("this.props: ", this.props);
    // this.recebeListaMenu();
  }

  recebeListaMenu = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var tipo_usuario = localStorage.getItem("tipo_usuario_clube");
    var entidade = localStorage.getItem("e_clube");

    console.log("Entidade: ", entidade);

    const resposta = await api.post("/menu/lista?e=" + entidade, "", {
      headers: { token: "Baer " + token, tipo_usuario: tipo_usuario },
    });
    this.setState({ menus: resposta.data.menus });

    if (!window.localStorage.getItem("srcImagem")) {
      await storage
        .ref(
          `${
            process.env.REACT_APP_ENVIRONMENT === "production"
              ? "producao"
              : "hml"
          }/${window.localStorage.getItem("e_clube")}/logo`
        )
        .child(`logo.png`)
        .getDownloadURL()
        .then((url) => {
          window.localStorage.setItem("srcImagem", url);
          this.setState({ srcImagem: url });
        })
        .catch(() => this.setState({ srcImagem: null }));
    } else {
      this.setState({ srcImagem: window.localStorage.getItem("srcImagem") });
    }
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    return (
      <>
        {this.state.visible ? (
          <>
            {this.state.width >= 1280 ? (
              <Menu vertical fixed="left" className="menuLateral" size="large">
                <Button
                  basic
                  floated="right"
                  icon
                  onClick={() => this.setState({ visible: false })}
                  style={{ outline: "none", boxShadow: "none" }}
                >
                  <Icon name="arrow left" color="red" />
                </Button>
                <Menu.Header style={{ padding: "50px" }}>
                  <Image
                    src={
                      (logosClubes["getImage"] &&
                        logosClubes["getImage"](
                          window.localStorage.getItem("e_clube")
                        )) ||
                      (this.state.srcImagem === null
                        ? logoMenu
                        : this.state.srcImagem)
                    }
                    size="small"
                    fluid
                    centered
                  />
                </Menu.Header>
                {this.state.menus.map((e, i) =>
                  e.componenteMenu === true ? (
                    <Link to={e.url}>
                      <Menu.Item
                        link
                        key={i}
                        // href={e.url}
                        name={e.menu}
                        content={e.menu}
                        title={e.menu}
                      >
                        {e.icon ? (
                          <Icon
                            name={e.icon}
                            color={e.color ? e.color : "#000000"}
                          ></Icon>
                        ) : (
                          <></>
                        )}
                        {e.menu}
                      </Menu.Item>
                    </Link>
                  ) : (
                    ""
                  )
                )}
                <Container
                  style={{
                    textAlign: "center",
                    paddingBottom: 20,
                    paddingTop: 100,
                  }}
                >
                  Desenvolvido por FAEE Sistemas ©
                </Container>
              </Menu>
            ) : (
              <Menu
                icon
                vertical
                fixed="left"
                size="tiny"
                className="menuLateral"
              >
                <Button
                  basic
                  floated="right"
                  icon
                  onClick={() => this.setState({ visible: false })}
                  style={{ outline: "none", boxShadow: "none" }}
                >
                  <Icon name="arrow left" color="red" />
                </Button>
                <Menu.Header style={{ padding: "50px" }}>
                  <Image
                    src={
                      (logosClubes["getImage"] &&
                        logosClubes["getImage"](
                          window.localStorage.getItem("e_clube")
                        )) ||
                      (this.state.srcImagem === null
                        ? logoMenu
                        : this.state.srcImagem)
                    }
                    size="tiny"
                    fluid
                    centered
                  />
                </Menu.Header>

                {this.state.menus.map((e, i) =>
                  e.componenteMenu === true ? (
                    <Link to={e.url}>
                      <Menu.Item
                        key={i}
                        // href={e.url}
                        name={e.menu}
                        content={e.menu}
                        title={e.menu}
                      >
                        {e.icon ? (
                          <Icon
                            name={e.icon}
                            color={e.color ? e.color : "#000000"}
                          ></Icon>
                        ) : (
                          <></>
                        )}
                        {e.menu}
                      </Menu.Item>
                    </Link>
                  ) : (
                    ""
                  )
                )}
                <Container
                  style={{
                    textAlign: "center",
                    paddingBottom: 20,
                    paddingTop: 100,
                  }}
                >
                  Desenvolvido por FAEE Sistemas ©
                </Container>
              </Menu>
            )}
          </>
        ) : (
          <Menu icon vertical fixed="left" className="menuLateral">
            <Menu.Header>
              {/* <Image
                src={
                  this.state.srcImagem === null
                    ? logoMenu
                    : this.state.srcImagem
                }
                size="tiny"
                fluid
                centered
              /> */}
              <Menu.Item onClick={() => this.setState({ visible: true })}>
                <Icon name="content" color="blue" />
              </Menu.Item>
            </Menu.Header>
            {this.state.menus.map((e, i) =>
              e.componenteMenu === true ? (
                <Link to={e.url}>
                  <Menu.Item
                    key={i}
                    // href={e.url}
                    name={e.menu}
                    content={e.menu}
                    title={e.menu}
                  >
                    {e.icon ? (
                      <Icon
                        name={e.icon}
                        color={e.color ? e.color : "#000000"}
                      ></Icon>
                    ) : (
                      <></>
                    )}
                    {/* {e.menu} */}
                  </Menu.Item>
                </Link>
              ) : (
                ""
              )
            )}
            {/* <Container
              style={{
                textAlign: "center",
                paddingBottom: 20,
                paddingTop: 100,
              }}
            >
              Desenvolvido por FAEE Sistemas ©
            </Container> */}
          </Menu>
        )}
      </>
    );
  }
}
