/* eslint-disable no-unused-vars */
import React, { Component } from "react";

import { Header, Modal } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import { Button, Icon, Snackbar, Alert } from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";

import { CloudUpload } from "@material-ui/icons/";

import api from "../../../services/api";

import { storage } from "../../../config/firebase";
//import { exit } from "process";

export default class ModalEnviarSumula extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arquivo: null,
      enviando: false,
    };
  }

  enviarSumula = async () => {
    if (!this.props.modalidade_id) {
      return;
    }

    this.setState({ enviando: true });
    var token = localStorage.getItem("token_clube");
    var e = localStorage.getItem("e_clube");
    var entidade = localStorage.getItem("e_clube");

    const uploadTask = storage
      .ref(
        `${
          process.env.REACT_APP_ENVIRONMENT === "production"
            ? "producao"
            : "hml"
        }/${window.localStorage.getItem("e_clube")}/sumulas/${
          this.props.modalidade_id
        }.pdf`
      )
      .put(this.state.arquivo);

    uploadTask.on(
      "state_changed",
      () => {
        console.log("state change");
      },
      (error) => {
        this.props.changeOpenSnackbar(true, true);
        this.setState({ enviando: false });
      },
      async () => {
        try {
          await api.post(
            "/modalidadeDesporto/baixarFirebaseParaLocalStorageArquivoSumula" +
              requisicoes.entidade,
            {
              projeto:
                process.env.REACT_APP_API_URL === undefined
                  ? "hml"
                  : "producao",
              modalidade_id: this.props.modalidade_id,
            },
            requisicoes.header
          );
          this.props.changeOpenSnackbar(true, false);

          this.setState({ enviando: false });
          setTimeout(() => {
            this.props.changeOpenModal(false);
          }, 3000);
        } catch (e) {
          this.props.changeOpenSnackbar(true, true);
          this.setState({ enviando: false });
        }
      }
    );
  };

  verificaArquivo = async (e) => {
    if (e.target.files[0].type !== "application/pdf") {
      alert("Somente arquivos PDFs são aceitos.");
      return;
    }
    if (e.target.files[0].size > 4000000) {
      alert("Arquivo muito grande!");
      return;
    }
    this.setState({ arquivo: e.target.files[0] });
  };

  render() {
    return (
      <Modal
        size="tiny"
        open={this.props.openModal}
        onClose={() => {
          this.setState({ arquivo: null });
          this.props.changeOpenModal(false);
        }}
      >
        <Modal.Header>Enviar Súmula</Modal.Header>
        <Modal.Content>
          <Button
            variant="contained"
            color="inherit"
            fullWidth
            component="label"
            onChange={(e) => this.verificaArquivo(e)}
            endIcon={!this.state.arquivo && <CloudUpload />}
          >
            {this.state.arquivo
              ? this.state.arquivo.name
              : "Selecione o arquivo"}

            <input type="file" hidden accept="application/pdf" />
          </Button>
        </Modal.Content>
        <Modal.Actions>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="auto">
              <Button
                variant="contained"
                color="inherit"
                onClick={() => {
                  this.props.changeOpenModal(false);
                  this.setState({ arquivo: null });
                }}
              >
                Voltar
              </Button>
            </Col>

            <Col md="auto">
              <LoadingButton
                variant="contained"
                onClick={() => {
                  this.enviarSumula();
                }}
                loading={this.state.enviando}
                disabled={!this.state.arquivo}
              >
                Enviar
              </LoadingButton>
            </Col>
            <Col md="auto"></Col>
          </Row>
        </Modal.Actions>
      </Modal>
    );
  }

  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
  };
}
