import React, { Component } from "react";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import {
  Header,
  Segment,
  Modal,
  Divider,
  Button,
  Icon,
} from "semantic-ui-react";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";
import codigoOperacaoEnum from "../../../../enum/CodigoOperacao";
import Select from "react-select";
import renomearChave from "../../../../services/renomearChave";

export default class ModalDebito extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      lista_instituicao: [],
      lista_agencia: [],
      instituicao_cobranca: null,
      ciclo: null,
      socio: null,
      agencia: null,
      agencia_id: null,
      digito_agencia: null,
      conta: null,
      digito_conta: null,
      codigo_pessoa: null,
      dia_vencimento: null,
      tipo_operacao: null,
      tipos_operacao: codigoOperacaoEnum,
      modalAgencia: false,
      modalConta: false,
      fromTitular: false,
    };
    this.instituicoes();
  }

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  abreModal = async (e) => {
    this.setState({ modalAgencia: e });
  };

  instituicoes = async () => {
    const retorno = await api.post(
      "/instituicaoCobranca/lista" + requisicoes.entidade,
      {
        debito_em_conta: true,
      },
      requisicoes.header
    );
    this.setState({ lista_instituicao: retorno.data });
  };

  pesquisaAgencia = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/agenciaDebitoConta/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaAgencia").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        lista.push({
          value: element.id,
          label: element.agencia + " - " + element.digito_agencia,
          agencia: element.agencia,
          digito_agencia: element.digito_agencia,
        });
      });
      this.setState({ lista_agencia: lista });
    }, 500);
  };

  alteracaoSelectAgencia = async (event) => {
    try {
      this.setState({ agencia: event });
      this.setState({ agencia_id: event.value });
    } catch(error) {
      console.log(error);
    }
  };

  salvarAgencia = async () => {
    const agencia = await api.post(
      "/agenciaDebitoConta/salvar" + requisicoes.entidade,
      {
        agencia: this.state.agencia,
        digito_agencia: this.state.digito_agencia,
      },
      requisicoes.header
    );
    let objeto = renomearChave(agencia.data, "id", "value");
    objeto = renomearChave(objeto, "agencia_digito", "label");
    this.setState({ agencia: objeto });
    this.setState({ agencia_id: objeto.value });
    this.setState({ agencia_digito: "" });
    this.setState({ modalAgencia: false });
  };

  submit = async (event) => {
    try {
      event.preventDefault();
      const c = this.state;

      if(c.instituicao_cobranca == null || c.instituicao_cobranca == undefined || c.instituicao_cobranca == '') 
        return alert('Escolha uma instituição de cobrança');
      if(c.ciclo == null || c.ciclo == undefined || c.ciclo == '') 
        return alert('Defina um ciclo');
      if(c.tipo_operacao == null || c.tipo_operacao == undefined || c.tipo_operacao == '') 
        return alert('Escolha um código de operação');
      if(c.agencia == null || c.agencia == undefined || c.agencia.label == null || c.agencia.label == '') 
        return alert('Escolha uma agência');
      if(c.conta == null || c.conta == undefined || c.conta == '') 
        return alert('Informe uma conta válida');
      if(c.digito_conta == null || c.digito_conta == undefined || c.digito_conta == '') 
        return alert('Informe o dígito da conta');
      if(c.dia_vencimento == null || c.dia_vencimento == undefined || c.dia_vencimento == '') 
        return alert('Informe o dia de vencimento');

      let dados = {
        ciclo: c.ciclo,
        agencia: c.agencia.label,
        digito_agencia: c.digito_agencia,
        conta: c.conta,
        digito_conta: c.digito_conta,
        dia_vencimento: c.dia_vencimento,
        pessoa_id: this.props.pessoa.id,
        instituicao_cobranca_id: parseInt(this.state.instituicao_cobranca),
        agencia_debito_conta_id: parseInt(this.state.agencia_id),
        agencia_debito_conta: c.agencia,
        tipo_operacao: c.tipo_operacao,
        proxima_remessa: "cadastro",
      };
      const retorno = await api.post(
        "/debitoConta/salvar" + requisicoes.entidade,
        dados,
        requisicoes.header
      );
      this.setState(retorno.data);
      this.props.handleCloseModal(retorno.data);
    } catch(error) {
      console.log('modalDebito/submit: ' + error);
    }
  };

  render() {
    return (
      <Modal
        size="tiny"
        open={this.props.open_conta}
        onMount={() => {
          if (this.props.debito_conta) {
            this.setState({
              ...this.props.debito_conta,
              instituicao_cobranca: this.props.debito_conta.instituicao_cobranca_id,
              agencia: this.props.debito_conta.agencia_debito_conta,
            });
            console.log('agencia: '+JSON.stringify(this.state.agencia));

            console.log('agencia_debito_conta: '+JSON.stringify(this.props.debito_conta.agencia_debito_conta));

            console.log('debito_conta: '+JSON.stringify(this.props.debito_conta));
            this.pesquisaAgencia();
            if(this.state.agencia != null) {
              this.alteracaoSelectAgencia({
                value: this.state.agencia.id,
                label: this.state.agencia.agencia + " - " + this.state.agencia.digito_agencia,
                agencia: this.state.agencia,
                digito_agencia: this.state.agencia.digito_agencia,
              });
            }
          }
        }}
      >
        <Modal.Header content="Débito Em Conta" />

        <Modal.Content>
          <Modal.Description>
            <Form.Label>Instituição Cobrança: </Form.Label>
            <Form.Control
              aria-describedby="basic-addon1"
              as="select"
              onChange={this.alteracao}
              name="instituicao_cobranca"
              required
              value={this.state.instituicao_cobranca}
            >
              <option value=""></option>
              {this.state.lista_instituicao.map((e) => {
                return <option value={e.id}>{e.descricao}</option>;
              })}
            </Form.Control>
            <Form.Label>Ciclo: </Form.Label>
            <Form.Control
              aria-describedby="basic-addon1"
              as="select"
              onChange={this.alteracao}
              name="ciclo"
              required
              value={this.state.ciclo}
            >
              <option></option>
              <option value="mensal">Mensal</option>
              <option value="anual">Anual</option>
            </Form.Control>
            <Form.Label>Sócio</Form.Label>
            <FormControl
              name="socio"
              aria-describedby="basic-addon1"
              defaultValue={
                this.props.pessoa !== undefined ? this.props.pessoa.nome : ""
              }
              disabled
            />
            <Form.Label>Codigo operação</Form.Label>
            <Form.Control
              aria-describedby="basic-addon1"
              as="select"
              onChange={this.alteracao}
              name="tipo_operacao"
              required
              value={this.state.tipo_operacao}
            >
              <option value=""></option>
              {this.state.tipos_operacao.map((e) => {
                return <option value={e.value}>{e.label}</option>;
              })}
            </Form.Control>
            <Row>
              <Col sm={10}>
                <Form.Label>Agencia</Form.Label>
                <Select
                  placeholder="Buscar agencia"
                  name="agencia"
                  onKeyDown={this.pesquisaAgencia}
                  value={this.state.agencia}
                  onChange={this.alteracaoSelectAgencia}
                  inputId="pesquisaAgencia"
                  options={this.state.lista_agencia}
                />
              </Col>
              <Col sm={2}>
                {/* caracter vazio abaixo */}
                <Form.Label>⠀⠀⠀⠀⠀⠀⠀⠀</Form.Label>
                <Modal
                  open={this.state.modalAgencia}
                  closeIcon
                  size="small"
                  trigger={
                    <Button
                      positive
                      icon="add"
                      onClick={() => this.abreModal(true)}
                    ></Button>
                  }
                >
                  <Modal.Header>Nova Agencia</Modal.Header>
                  <Modal.Content>
                    <Row>
                      <Col sm={6}>
                        <Form.Label>Agencia</Form.Label>
                        <FormControl
                          name="agencia"
                          onChange={this.alteracao}
                          aria-describedby="basic-addon1"
                          value={this.state.agencia}
                          type="number"
                          required
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Label>Digito Agencia</Form.Label>
                        <FormControl
                          name="digito_agencia"
                          onChange={this.alteracao}
                          aria-describedby="basic-addon1"
                          value={this.state.digito_agencia}
                          type="number"
                          required
                        />
                      </Col>
                    </Row>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      color="red"
                      inverted
                      onClick={() => this.abreModal(false)}
                    >
                      <Icon name="remove" /> Fechar
                    </Button>
                    <Button color="green" inverted onClick={this.salvarAgencia}>
                      <Icon name="checkmark" /> Sim
                    </Button>
                  </Modal.Actions>
                </Modal>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <Form.Label>Conta</Form.Label>
                <FormControl
                  name="conta"
                  aria-describedby="basic-addon1"
                  onChange={this.alteracao}
                  value={this.state.conta}
                  type="number"
                />
              </Col>
              <Col sm={4}>
                <Form.Label>Digito Conta</Form.Label>
                <FormControl
                  name="digito_conta"
                  aria-describedby="basic-addon1"
                  onChange={this.alteracao}
                  required
                  value={this.state.digito_conta}
                  type="number"
                />
              </Col>
            </Row>
            <Form.Label>Dia de Vencimento</Form.Label>
            <FormControl
              name="dia_vencimento"
              aria-describedby="basic-addon1"
              onChange={this.alteracao}
              required
              type="number"
              value={this.state.dia_vencimento}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={this.submit}>
            Salvar
          </Button>
          <Button negative onClick={() => this.props.handleCloseModal(null)}>
            Fechar
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
