import React, { Component } from "react";
import {
  Container,
  Header,
  Button,
  Divider,
  Segment,
  FormField,
  Input,
} from "semantic-ui-react";
import api from "../../../../services/api";
import { Link } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import requisicoes from "../../../../utils/Requisicoes";
import renomearChave from "../../../../services/renomearChave";
import renomear2Chaves from "../../../../services/renomear2Chaves";
import Select from "react-select";

export default class Estrutura extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      codigo: null,
      nome: null,
      optionsCC: [],
    };
    this.alteracao = this.alteracao.bind(this);
    this.visualiza();
  }

  alteracao(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  visualiza = async () => {
    if (this.props.location.query !== undefined) {
      const retorno = await api.post(
        "/estrutura/visualiza" + requisicoes.entidade,
        { id: this.props.location.query.id },
        requisicoes.header
      );
      this.setState(retorno.data);
      let o = renomearChave(retorno.data.conta_contabil, "id", "value");
      o = renomear2Chaves(o, "codigo", "descricao", "label");
      this.setState({ conta_contabil_id: retorno.data.conta_contabil_id });
      this.setState({ conta_contabil: o });
    }
  };

  handleSubmit = (event) => {
    this.submit();
    event.preventDefault();
  };

  submit = async () => {
    const dados = this.state;
    let retorno = "";
    if (this.state.id === null) {
      retorno = await api.post(
        "/estrutura/salvar" + requisicoes.entidade,
        dados,
        requisicoes.header
      );
    } else {
      retorno = await api.post(
        "/estrutura/editar" + requisicoes.entidade,
        dados,
        requisicoes.header
      );
    }
    if (retorno !== null) {
      window.location.href = "/almoxarifado";
    }
  };

  excluir = async () => {
    const dados = this.state;
    let retorno = "";
    retorno = await api.post(
      "/estrutura/excluir" + requisicoes.entidade,
      dados,
      requisicoes.header
    );
    if (retorno !== null) {
      window.location.href = "/almoxarifado";
    }
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Estrutura
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={3}>
              <Form.Label>Código</Form.Label>
              <FormField
                placeholder="Código"
                name="codigo"
                control={Input}
                onChange={this.alteracao}
                required
                defaultValue={this.state.codigo}
                fluid
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Nome</Form.Label>
              <FormField
                placeholder="Nome"
                name="nome"
                control={Input}
                onChange={this.alteracao}
                required
                defaultValue={this.state.nome}
                fluid
              />
            </Col>
          </Row>
          <Divider />
          <Segment clearing>
            <Header floated="right">
              <Link to="/almoxarifado">
                <Button type="button">Voltar</Button>
              </Link>
              <Button positive type="submit">
                Salvar
              </Button>
              {this.state.id !== null && (
                <Button negative type="delete" onClick={this.excluir}>
                  Deletar
                </Button>
              )}
            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}
