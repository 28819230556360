import React, { Component } from "react";
import {
  Header,
  Segment,
  Table,
  Icon as IconSemantic,
} from "semantic-ui-react";
import { IconButton, Icon, ThemeProvider } from "@material-ui/core";

import { green } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

// utils

// Componentes criados
import ModalAdicionarRota from "./modalAdicionarRota";

export default class Rotas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Lista de dados
      listaRotas: this.props.listaRotas,

      // Modal
      openModalAdicionarRota: false,
      rotaSelectedModalAdicionarRota: null,
    };
  }

  render() {
    return (
      <div>
        <Segment>
          <Header>Cadastro de Rotas</Header>
          <Table celled compact>
            <Table.Header>
              <Table.HeaderCell width={1}>Id</Table.HeaderCell>
              <Table.HeaderCell>Url</Table.HeaderCell>
              <Table.HeaderCell width={1}>Menu</Table.HeaderCell>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell width={1}>Ordem</Table.HeaderCell>
              <Table.HeaderCell width={1}>Menus</Table.HeaderCell>
              <Table.HeaderCell width={1}>Icon</Table.HeaderCell>
              <Table.HeaderCell
                width={1}
                style={{
                  padding: 0,
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <IconButton
                    onClick={() =>
                      this.setState({ openModalAdicionarRota: true })
                    }
                  >
                    <ThemeProvider
                      theme={createTheme({
                        palette: {
                          primary: green,
                        },
                      })}
                    >
                      <Icon color="primary">add_circle</Icon>
                    </ThemeProvider>
                  </IconButton>
                </div>
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {this.state.listaRotas.map((e, i) => (
                <Table.Row>
                  <Table.Cell>{e.id}</Table.Cell>
                  <Table.Cell>{e.url}</Table.Cell>
                  <Table.Cell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {e.component_menu ? (
                      <ThemeProvider
                        theme={createTheme({
                          palette: {
                            primary: green,
                          },
                        })}
                      >
                        <Icon color="primary">check</Icon>
                      </ThemeProvider>
                    ) : (
                      <Icon color="error">close</Icon>
                    )}
                  </Table.Cell>
                  <Table.Cell>{e.nome_component_menu}</Table.Cell>
                  <Table.Cell style={{ textAlign: "center" }}>
                    {e.ordenacao}
                  </Table.Cell>
                  <Table.Cell style={{ textAlign: "center" }}>
                    {e.menus.length}
                  </Table.Cell>
                  <Table.Cell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {e.icon && <IconSemantic name={e.icon} />}
                    </div>
                  </Table.Cell>

                  <Table.Cell
                    style={{
                      padding: 0,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        this.setState({
                          rotaSelectedModalAdicionarRota: e,
                          openModalAdicionarRota: true,
                        });
                      }}
                    >
                      <Icon color="primary">edit</Icon>
                    </IconButton>
                    <IconButton onClick={() => {}}>
                      <Icon color="error">delete_icon</Icon>
                    </IconButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
        <ModalAdicionarRota
          open={this.state.openModalAdicionarRota}
          onClose={() =>
            this.setState({
              openModalAdicionarRota: false,
              rotaSelectedModalAdicionarRota: null,
            })
          }
          addRota={(e) => {
            let lista = this.state.listaRotas;
            lista.push(e);
            this.setState({ listaRotas: lista });
          }}
          alteraRota={(e) => {
            let lista = this.state.listaRotas;
            let idx = lista.findIndex((e2) => e2.id === e.id);
            if (idx !== -1) {
              lista[idx] = { ...lista[idx], ...e };
              this.setState({ listaRotas: lista });
            }
          }}
          id={
            this.state.rotaSelectedModalAdicionarRota
              ? this.state.rotaSelectedModalAdicionarRota.id
              : null
          }
          rota={this.state.rotaSelectedModalAdicionarRota}
        />
      </div>
    );
  }
}
