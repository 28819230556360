/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Modal, Segment, Table } from "semantic-ui-react";
import { Form, FormControl, Col, Row } from "react-bootstrap";

import Checkbox from "@material-ui/core/Checkbox";

import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Alert from "@material-ui/lab/Alert";

import "../styles.css";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = { checkIniciar: false };
  }

  checkboxVerify = () => {
    if (this.state.checkIniciar === false) {
      this.setState({ checkIniciar: true });
    } else {
      this.setState({ checkIniciar: false });
    }
  };

  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Modal
          style={{ overflow: "visible" }}
          dimmer="bluring"
          size="small"
          open={this.props.modalGrupoMataMata}
        >
          <Modal.Header>
            <IconButton
              onClick={() => this.props.changeModalRodadasMataMata(false)}
              className="bottomModal"
              color="secondary"
            >
              <CloseIcon />
            </IconButton>

            <h6>
              {" "}
              Ao clicar em <strong>estou ciente</strong>, você irá iniciar o
              campeonato! <br />
              <br />
              <strong>
                VERIFIQUE SE TODOS OS TIMES POSSUEM JOGADORES CADASTRADOS!
              </strong>
            </h6>
          </Modal.Header>
          <Modal.Content>
            <Col sm={12}>
              {this.props.alertErrorIniciar ? (
                <Alert severity="error">
                  {this.props.mensagemErrorIniciar}
                </Alert>
              ) : this.props.alertSucessIniciar ? (
                <Alert severity="success">Campeonato Iniciado!</Alert>
              ) : (
                <div className="flexStart">
                  <div>
                    <Checkbox
                      root
                      name="checkEtapa"
                      id="checkEtapa"
                      onClick={this.checkboxVerify}
                    />
                  </div>

                  <h6 style={{ marginTop: "4px" }}> Estou ciente!</h6>
                </div>
              )}
            </Col>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="primary"
              style={{ outline: "none" }}
              disabled={!this.state.checkIniciar}
              onClick={this.props.iniciarCampeonatoMataMata}
            >
              Iniciar Campeonato
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
