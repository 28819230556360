import React, { Component } from "react";
import {
  Container,
  Table,
  Header,
  Modal,
  Button,
  Segment,
  Menu,
  Icon,
} from "semantic-ui-react";
import { Row, Col, FormControl } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import api from "../../../services/api";
import { Icon as Iconify, InlineIcon } from "@iconify/react";
import fingerprintIcon from "@iconify-icons/mdi/fingerprint";

export default class ModalFoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conteudo: null,
      catracas: [],
    };
  }

  loop = async () => {
    setInterval(async () => {
      const retorno = await api.post(
        "/catraca/conteudoDispositivo" + requisicoes.entidade,
        "",
        requisicoes.header
      );
      this.setState({ conteudo: retorno.data.conteudo });
    }, 1000);
  };

  catracasPorUsuario = async () => {
    const retorno = await api.post(
      "/catraca/catracaPorUsuario" + requisicoes.entidade,
      { usuario_id: 13 },
      requisicoes.header
    );
    this.setState({ catracas: retorno.data });
  };

  render() {
    return (
      <Modal
        trigger={
          <Button
            content="Digital"
            icon={<Iconify icon={fingerprintIcon} width={15} />}
            color="blue"
            floated="right"
            onClick={this.catracasPorUsuario}
          ></Button>
        }
        size="small"
      >
        <Modal.Header>
          <Header as="h3">Conteudo Dispositivo</Header>
        </Modal.Header>

        <Modal.Content>
          <Row>
            <Col sm={6}>
              <Menu compact icon="labeled">
                <Menu.Item>
                  <Icon name="feed" />
                  Rfid
                </Menu.Item>
              </Menu>
            </Col>
            <Col sm={6}>
              <Menu compact icon="labeled">
                <Menu.Item>
                  <Iconify icon={fingerprintIcon} width={30} />
                  Digital
                </Menu.Item>
              </Menu>
            </Col>
          </Row>
          <FormControl
            placeholder="Codigo"
            name="nome_pai"
            defaultValue={this.state.conteudo}
          />
        </Modal.Content>
      </Modal>
    );
  }
}
