import React, { Component } from "react";
import {
  Header,
  Button,
  Divider,
  Segment,
  FormField,
  Input,
  Table,
  Message,
  Modal,
} from "semantic-ui-react";
import api from "../../../../../services/api";
import { Link } from "react-router-dom";
import { Col, Form, FormControl, Row } from "react-bootstrap";
import Select from "react-select";
import renomearChave from "../../../../../services/renomearChave";
import renomear2Chaves from "../../../../../services/renomear2Chaves";
import requisicoes from "../../../../../utils/Requisicoes";
import moment from "moment-timezone";

import $, { data } from "jquery";

export default class Requisicao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      data: moment().format("YYYY-MM-DD"),
      itens: [],
      numero: "",
      optionsB: [],
      optionsC: [],
      optionsCC: [],
      optionsEC: [],
      pessoa: null,
      pessoa_id: null,
      infoPessoa: null,
      centro_de_custo: null,
      centro_de_custo_id: null,
      elemento_de_custo: null,
      elemento_de_custo_id: null,
      item_elemento_custo: null,
      item_elemento_custo_id: null,
      item_centro_custo: null,
      item_centro_custo_id: null,
      mensagemErroDocumento: null,
      loadingDocumento: false,
      mensagemErro: null,

      open_modal_confirmacao: false,
      submit_confirmado: false,
    };
    this.visualizacao();
    this.alteracaoSelectPessoaR = this.alteracaoSelectPessoaR.bind(this);
    this.alteracaoSelectPessoaA = this.alteracaoSelectPessoaA.bind(this);
    this.alteracaoSelectOrigem = this.alteracaoSelectOrigem.bind(this);
    this.alteracaoSelectDestino = this.alteracaoSelectDestino.bind(this);
    this.alteracaoSelectCentroCusto =
      this.alteracaoSelectCentroCusto.bind(this);
    this.alteracaoSelectElementoCusto =
      this.alteracaoSelectElementoCusto.bind(this);
    this.alteracaoSelectItem = this.alteracaoSelectItem.bind(this);
    this.alteracao = this.alteracao.bind(this);
  }

  alteracao(event) {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === "numero") {
      this.pesquisaDocumento(event.target.value);
    }
  }

  alteracaoSelectPessoaR = async (event) => {
    this.setState({ requisitado_id: event.value });
    this.setState({ requisitado: event });
  };

  alteracaoSelectPessoaA = async (event) => {
    this.setState({ autorizado_id: event.value });
    this.setState({ autorizado: event });
  };

  alteracaoSelectDestino = async (event) => {
    this.setState({ destino_id: event.value });
    this.setState({ destino: event });
  };

  alteracaoSelectOrigem = async (event) => {
    this.setState({ origem_id: event.value });
    this.setState({ origem: event });
  };

  alteracaoSelectCentroCusto = async (event) => {
    this.setState({ centro_de_custo_id: event.value });
    this.setState({ centro_de_custo: event });
  };

  alteracaoSelectElementoCusto = async (event) => {
    this.setState({ elemento_de_custo_id: event.value });
    this.setState({ elemento_de_custo: event });
  };

  alteracaoSelectItem = async (event, target) => {
    for (let i = 0; i < this.state.itens.length; i++) {
      if (i === target.name) {
        api
          .post(
            "/item/visualiza" + requisicoes.entidade,
            { id: event.value },
            requisicoes.header
          )
          .then((resp) => {
            if (resp.data.quantidade > 0) {
              this.state.itens[i].item_id = parseInt(event.value);
              this.state.itens[i].item = resp.data;
              this.forceUpdate();
            } else {
              alert("Este item não possui quantidade em estoque!");
            }
          });
      }
    }
  };

  verificaQuantidade = async (event, id) => {
    const idItem = event.target.name;
    let quantidade = document
      .getElementById("itens[" + idItem + "].item.quantidade")
      .getAttribute("value");
    let estoque = parseFloat(quantidade) - parseFloat(event.target.value);
    this.state.itens[idItem].quantidade = event.target.value;
    if (estoque < 0) {
      alert("Quantidade em estoque insuficiente!");
      document
        .getElementById("itens[" + idItem + "].quantidade")
        .setAttribute("value", 0);
    }
  };

  alteracaoSelectItemCentroCusto = async (event, target) => {
    for (let i = 0; i < this.state.itens.length; i++) {
      if (i === parseInt(target.name)) {
        console.log("parseInt(target.name): ", parseInt(target.name));
        this.state.itens[i].centro_de_custo_id = parseInt(event.value);
        this.state.itens[i].elemento_de_custo_id = null;
        this.state.itens[i].elemento_de_custo = {};
        api
          .post(
            "/centroDeCusto/visualiza" + requisicoes.entidade,
            { id: event.value },
            requisicoes.header
          )
          .then((resp) => {
            this.state.itens[i].centro_de_custo = resp.data;
            let lista0 = [];
            if (resp.data.elementos.length > 0) {
              resp.data.elementos.forEach((e) => {
                let objeto = renomearChave(e, "id", "value");
                objeto = renomear2Chaves(
                  objeto,
                  "codigo",
                  "descricao",
                  "label"
                );
                lista0.push(objeto);
              });
              this.setState({ optionsC: lista0 });
            }
          });
      }
    }
  };

  alteracaoSelectItemElementoCusto = async (event, target) => {
    for (let i = 0; i < this.state.itens.length; i++) {
      if (i === parseInt(target.name)) {
        this.state.itens[i].elemento_de_custo_id = parseInt(event.value);
        api
          .post(
            "/elementoDeCusto/visualiza" + requisicoes.entidade,
            { id: event.value },
            requisicoes.header
          )
          .then((resp) => {
            this.state.itens[i].elemento_de_custo = resp.data;
          });
      }
    }
  };

  addItem = async () => {
    const item = {
      item: {},
      item_id: null,
      quantidade: null,
      centro_de_custo: {},
      centro_de_custo_id: null,
      elemento_de_custo: {},
      elemento_de_custo_id: null,
    };
    if (this.state.itens.length > 0) {
      item.centro_de_custo_id = this.state.itens[0].centro_de_custo_id;
      let b = renomearChave(this.state.itens[0].centro_de_custo, "id", "value");
      b = renomear2Chaves(b, "codigo", "descricao", "label");
      item.centro_de_custo = b;
      item.elemento_de_custo_id = this.state.itens[0].elemento_de_custo_id;
      let c = renomearChave(
        this.state.itens[0].elemento_de_custo,
        "id",
        "value"
      );
      c = renomear2Chaves(c, "codigo", "descricao", "label");
      item.elemento_de_custo = c;
    }
    this.state.itens.push(item);
    this.state.item = "";
    this.forceUpdate();
  };

  removeItem = async () => {
    let lista = this.state.itens;
    lista.splice(lista.indexOf(1), 1);
    this.setState({ itens: lista });
  };

  visualizacao = async () => {
    if (this.props.location.query !== undefined) {
      const resposta = await api.post(
        "/requisicao/visualiza" + requisicoes.entidade,
        this.props.location.query,
        requisicoes.header
      );
      this.setState({ itens: resposta.data.itens });
      let o = renomearChave(resposta.data.estrutura, "id", "value");
      o = renomearChave(o, "nome", "label");
      this.setState({ origem_id: resposta.data.origem_id });
      this.setState({ origem: o });

      let d = renomearChave(resposta.data.estrutura1, "id", "value");
      d = renomearChave(d, "nome", "label");
      this.setState({ destino_id: resposta.data.centro_de_custo1 });
      this.setState({ destino: d });

      let r = renomearChave(resposta.data.pessoa1, "id", "value");
      r = renomearChave(r, "nome", "label");
      this.setState({ requisitado_id: resposta.data.pessoa1 });
      this.setState({ requisitado: r });

      let a = renomearChave(resposta.data.pessoa, "id", "value");
      a = renomearChave(a, "nome", "label");
      this.setState({ autorizado_id: resposta.data.pessoa });
      this.setState({ autorizado: a });
      this.setState({ numero: resposta.data.numero });
      this.setState({ data: resposta.data.data });
      this.setState({ id: resposta.data.id });

      const respPessoaInfo = await api.post(
        "/pessoa/visualiza" + requisicoes.entidade,
        { id: resposta.data.pessoa_id },
        requisicoes.header
      );
      if (respPessoaInfo.data !== null) {
        const respPessoa = await api.post(
          "/pessoa/pessoaIdNome" + requisicoes.entidade,
          { id: resposta.data.pessoa_id },
          requisicoes.header
        );
        let p = renomearChave(respPessoa.data, "id", "value");
        p = renomearChave(p, "nome", "label");
        this.setState({ pessoa_id: resposta.data.pessoa_id });
        this.setState({ pessoa: p });
        this.setState({ infoPessoa: respPessoaInfo.data });
        this.setState({
          novoEmail: respPessoaInfo.data.email,
          novoTelefone: respPessoaInfo.data.telefone,
        });
      }
    }
  };

  pesquisaOrigem = (newValue) => {
    api
      .post(
        "/estrutura/pesquisa" + requisicoes.entidade,
        { pesquisa: newValue },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomearChave(objeto, "nome", "label");
          lista.push(objeto);
        });
        this.setState({ optionsO: lista });
      });
  };

  pesquisaDestino = (newValue) => {
    this.setState({ optionsD: [] });
    api
      .post(
        "/estrutura/pesquisa" + requisicoes.entidade,
        { pesquisa: newValue },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomearChave(objeto, "nome", "label");
          lista.push(objeto);
        });
        this.setState({ optionsD: lista });
      });
  };

  pesquisaPessoa = (newValue) => {
    //buscaFuncionariosDiretoriaConselho
    api
      .post(
        "/pessoa/buscaFuncionariosDiretoriaConselho" + requisicoes.entidade,
        { pesquisa: newValue },
        requisicoes.header
      )
      .then((resp) => {
        console.log("resp: ", resp);

        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomearChave(objeto, "nome", "label");
          lista.push(objeto);
        });
        console.log("lista: ", lista);
        this.setState({ optionsA: lista });
      });
  };

  pesquisaCentroCusto = (newValue) => {
    this.setState({ optionsB: [] });
    api
      .post(
        "/centroDeCusto/pesquisa" + requisicoes.entidade,
        { pesquisa: newValue },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomear2Chaves(objeto, "codigo", "descricao", "label");
          lista.push(objeto);
        });
        this.setState({ optionsB: lista });
      });
  };

  pesquisaElementoCusto = (newValue) => {
    this.setState({ optionsC: [] });
    api
      .post(
        "/elementoDeCusto/pesquisaByContaContabil" + requisicoes.entidade,
        {
          pesquisa: newValue,
          centroCusto: this.state.centro_custo_id,
          cad: false,
        },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomear2Chaves(objeto, "codigo", "descricao", "label");
          lista.push(objeto);
        });
        this.setState({ optionsC: lista });
      });
  };

  pesquisaItem = (newValue) => {
    api
      .post(
        "/item/pesquisa" + requisicoes.entidade,
        { pesquisa: newValue },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto.id = element.id;
          objeto = renomear2Chaves(objeto, "id", "descricao", "label");
          lista.push(objeto);
        });
        this.setState({ optionsI: lista });
      });
  };

  verificaDataExportacaoContabil = async (data) => {
    const maxDataFinal = await api.post(
      "/exportacaoContabil/maiorDataFinalRemessaGeradaTipo" +
        requisicoes.entidade,
      { arquivo: "Requisicao" },
      requisicoes.header
    );
    if (maxDataFinal.data && maxDataFinal.data >= data) {
      return false;
    } else {
      return true;
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    let setarMensagem = (header, content) => {
      this.setState({
        mensagemErro: {
          header,
          content,
        },
      });
      setTimeout(() => {
        this.setState({ mensagemErro: null });
      }, 5000);
    };

    // Tratar os campos da tela.
    if (!this.state.origem || !this.state.destino) {
      setarMensagem(
        "Erro ao salvar o registro.",
        "Você deve selecionar a origem e o destino da requisição."
      );
      return;
    }
    if (!this.state.requisitado || !this.state.autorizado) {
      setarMensagem(
        "Erro ao salvar o registro.",
        "Requisitado e Autorizado não podem ser vazios."
      );
      return;
    }

    if (this.state.itens.length === 0) {
      setarMensagem(
        "Erro ao salvar o registro.",
        "Nenhum item foi adicionado."
      );
      return;
    }
    for (let i = 0; i < this.state.itens.length; i++) {
      const element = this.state.itens[i];
      if (!element.item_id || !element.item) {
        setarMensagem(
          "Erro ao salvar o registro.",
          "Deve ser selecionado o item da requisição."
        );
        return;
      }
      if (!element.quantidade) {
        setarMensagem(
          "Erro ao salvar o registro.",
          "Deve ser descrita a quantidade dos itens."
        );
        return;
      }
      if (!element.centro_de_custo_id || !element.elemento_de_custo_id) {
        console.log("element.centro_custo_id: ", element.centro_de_custo_id);
        console.log(
          "element.elemento_de_custo_id: ",
          element.elemento_de_custo_id
        );

        setarMensagem(
          "Erro ao salvar o registro.",
          "Todos os itens devem ter centro e elemento de custo."
        );
        return;
      }
    }

    // Se todos os campos estão válidos.
    if (await this.verificaDataExportacaoContabil(this.state.data)) {
      this.submit();
    } else {
      setarMensagem(
        "Data do registro bloqueada.",
        "Já foi feita exportação da data selecionada. Por favor, entre com outra data."
      );
    }
  };

  submit = async (confirmed) => {
    const c = this.state;
    const dados = {
      data: c.data,
      numero: c.numero,
      autorizado_id: c.autorizado_id,
      requisitado_id: c.requisitado_id,
      origem_id: c.origem_id,
      destino_id: c.destino_id,
      itens: c.itens,
      centro_de_custo_id: c.centro_de_custo_id,
      elemento_de_custo_id: c.elemento_de_custo_id,
    };

    if(c.data < moment().format("YYYY-MM-DD") && confirmed == null) {
      this.setState({
        open_modal_confirmacao: true,
      });
      return;
    } 
    if(confirmed == null || confirmed == true) {
      this.setState({ 
        open_modal_confirmacao: false,
      });
    }

    if (this.state.id !== null) {
      await api.post(
        "/requisicao/editar" + requisicoes.entidade + "&id=" + this.state.id,
        dados,
        requisicoes.header
      );
      localStorage.setItem("pagina_anterior", "requisicao");
      window.location.href = "/almoxarifado";
    } else {
      await api.post(
        "/requisicao/salvar" + requisicoes.entidade,
        dados,
        requisicoes.header
      );
      localStorage.setItem("pagina_anterior", "requisicao");
      window.location.href = "/almoxarifado";
    }
  };

  pesquisaDocumento = async (a) => {
    if (a === "") {
      this.setState({ mensagemErroDocumento: null });
      return;
    }
    if (parseInt(a) > 2147483647) {
      this.setState({
        mensagemErroDocumento: "Número inválido: muito grande.",
      });
      return;
    }
    this.setState({ loadingDocumento: true });
    let listaRetorno = await api.post(
      "/requisicao/pesquisa" + requisicoes.entidade,
      {
        pesquisa: a,
      },
      requisicoes.header
    );
    this.setState({ loadingDocumento: false });
    if (listaRetorno.data.length > 0) {
      this.setState({
        mensagemErroDocumento: "Documento " + a + " já foi utilizado.",
      });
    } else {
      this.setState({ mensagemErroDocumento: null });
    }
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Requisição
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={3}>
              <Form.Label>Data</Form.Label>
              <FormControl
                type="date"
                aria-describedby="basic-addon1"
                name="data"
                onChange={this.alteracao}
                value={this.state.data.toString().substr(0, 10)}
                required
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Número Documento</Form.Label>
              <FormField
                placeholder="Nº"
                name="numero"
                control={Input}
                type="number"
                onChange={this.alteracao}
                defaultValue={this.state.numero}
                loading={this.state.loadingDocumento}
                error={
                  this.state.mensagemErroDocumento !== null
                    ? {
                        content: this.state.mensagemErroDocumento,
                        pointing: "left",
                      }
                    : false
                }
                required
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Label>Origem</Form.Label>
              <Select
                placeholder="Digite para buscar"
                name="origem"
                value={this.state.origem}
                onChange={this.alteracaoSelectOrigem}
                options={this.state.optionsO}
                onInputChange={this.pesquisaOrigem}
                inputId="pesquisaOrigem"
                required
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Destino</Form.Label>
              <Select
                placeholder="Digite para buscar"
                name="destino"
                value={this.state.destino}
                onChange={this.alteracaoSelectDestino}
                options={this.state.optionsD}
                onInputChange={this.pesquisaDestino}
                inputId="pesquisaDestino"
              />
            </Col>
          </Row>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={5}>Descrição</Table.HeaderCell>
                <Table.HeaderCell width={1}>Quantidade</Table.HeaderCell>
                <Table.HeaderCell width={1}>Estoque</Table.HeaderCell>
                <Table.HeaderCell width={3}>Centro Custo</Table.HeaderCell>
                <Table.HeaderCell width={3}>Elemento Custo</Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  <Button
                    positive
                    icon="add"
                    size="tiny"
                    onClick={this.addItem}
                    type="button"
                    style={{ marginTop: "7%" }}
                  ></Button>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.itens.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell>
                    <Input
                      id={"itens[" + [i] + "].item_id"}
                      name={"itens[" + [i] + "].item_id"}
                      value={e.item_id}
                      hidden
                    />
                    <Select
                      placeholder="Adicionar Item"
                      name={i}
                      id={i}
                      onChange={this.alteracaoSelectItem}
                      options={this.state.optionsI}
                      isDisabled={this.state.id !== null}
                      onInputChange={this.pesquisaItem}
                      inputId={
                        e.item.value != null && this.state.id == null
                          ? e.item.value
                          : this.state.id != null
                          ? e.item.id
                          : undefined
                      }
                      inputValue={
                        e.item.value != null && this.state.id == null
                          ? e.item.label
                          : this.state.id != null
                          ? e.item.id + " - " + e.item.descricao
                          : undefined
                      }
                    />
                  </Table.Cell>
                  <Table.Cell width={1}>
                    <FormField
                      placeholder=""
                      id={"itens[" + [i] + "].quantidade"}
                      name={i}
                      control={Input}
                      defaultValue={e.quantidade}
                      onBlur={this.verificaQuantidade}
                      fluid
                    />
                  </Table.Cell>
                  <Table.Cell width={1}>
                    <FormField
                      placeholder="0"
                      id={"itens[" + [i] + "].item.quantidade"}
                      name={"itens[" + [i] + "].item.quantidade"}
                      control={Input}
                      defaultValue={e.item.quantidade}
                      fluid
                      disabled
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      id={"itens[" + [i] + "].centro_de_custo_id"}
                      name={"itens[" + [i] + "].centro_de_custo_id"}
                      value={e.centro_de_custo_id}
                      hidden
                    />
                    <Select
                      placeholder="Centro de Custo"
                      name={[i]}
                      id={i}
                      onChange={this.alteracaoSelectItemCentroCusto}
                      options={this.state.optionsB}
                      onInputChange={this.pesquisaCentroCusto}
                      isDisabled={this.state.id !== null}
                      inputId={
                        e.centro_de_custo.value != null && this.state.id == null
                          ? e.centro_de_custo.value
                          : this.state.id != null
                          ? e.centro_de_custo.codigo
                          : undefined
                      }
                      defaultInputValue={
                        e.centro_de_custo.value != null && this.state.id == null
                          ? e.centro_de_custo.label
                          : this.state.id != null
                          ? e.centro_de_custo.codigo +
                            " - " +
                            e.centro_de_custo.descricao
                          : undefined
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      id={"itens[" + [i] + "].elemento_de_custo_id"}
                      name={"itens[" + [i] + "].elemento_de_custo_id"}
                      value={e.elemento_de_custo_id}
                      hidden
                    />
                    <Select
                      placeholder="Elemento de Custo"
                      name={[i]}
                      id={i}
                      onChange={this.alteracaoSelectItemElementoCusto}
                      options={this.state.optionsC}
                      isDisabled={this.state.id !== null}
                      inputId={
                        e.elemento_de_custo.value != null
                          ? e.elemento_de_custo.value
                          : this.state.id != null
                          ? e.elemento_de_custo.codigo
                          : undefined
                      }
                      defaultInputValue={
                        e.elemento_de_custo != null &&
                        e.elemento_de_custo.value != null
                          ? e.elemento_de_custo.label
                          : this.state.id != null
                          ? e.elemento_de_custo.codigo +
                            " - " +
                            e.elemento_de_custo.descricao
                          : undefined
                      }
                    />
                  </Table.Cell>
                  <Table.Cell width={1}>
                    <Button
                      negative
                      icon="remove"
                      size="tiny"
                      onClick={(e) => this.removeItem(e.target.value)}
                      type="button"
                      style={{ marginTop: "7%" }}
                      value={i}
                    ></Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer></Table.Footer>
          </Table>
          <Row>
            <Col sm={6}>
              <Form.Label>Requisítado Por:</Form.Label>
              <Select
                placeholder="Digite para buscar"
                name="requisitado"
                value={this.state.requisitado}
                onChange={this.alteracaoSelectPessoaR}
                options={this.state.optionsA}
                onInputChange={this.pesquisaPessoa}
                inputId="pesquisaPessoa"
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Autorizado Por:</Form.Label>
              <Select
                placeholder="Digite para buscar"
                name="autorizado"
                value={this.state.autorizado}
                onChange={this.alteracaoSelectPessoaA}
                options={this.state.optionsA}
                onInputChange={this.pesquisaPessoa}
                inputId="pesquisaPessoa"
              />
            </Col>
          </Row>
          <Divider />
          <Segment clearing>
            <Header floated="right">
              <Link to="/almoxarifado">
                <Button type="button">Voltar</Button>
              </Link>
              <Button
                positive
                type="submit"
                disabled={this.state.mensagemErroDocumento !== null}
              >
                Salvar
              </Button>
            </Header>
          </Segment>
          {this.state.mensagemErro && (
            <Message negative>
              <Message.Header>{this.state.mensagemErro.header}</Message.Header>
              <Message.Content>
                {this.state.mensagemErro.content}
              </Message.Content>
            </Message>
          )}

          {(this.state.open_modal_confirmacao == true) ? (<Modal
            open={this.state.open_modal_confirmacao}
            onClose={() => this.setState({ open_modal_confirmacao: false })}
            size="small"
          >
            <Header content="Saída de Item irregular" />
            <Modal.Content>
              <Row>
                <Col sm={12}>
                  Data da requisição é anterior a data de hoje, em caso de não haver alguma entrada para algum item adicionado, 
                  o mesmo poderá ser registrado com valor de entrada zerado.
                </Col>
              </Row>
            </Modal.Content>

            <Header floated="right">
              <Row>
                <Col sm={6}>
                  <Button 
                    variant="light" 
                    onClick={() => this.setState({ open_modal_confirmacao: false })}>
                    Fechar
                  </Button>
                </Col>
                <Col sm={6}>
                  <Button
                    type="button"
                    variant="success"
                    onClick={() => this.submit(true)}
                  >
                    Confirmar
                  </Button>
                </Col>
              </Row>
            </Header>
          </Modal>
          ) : null}
        </Form>
      </div>
    );
  }
}
