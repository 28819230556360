import React, { Component } from "react";
import {
  Header,
  Button,
  Modal
} from "semantic-ui-react";
import { Row, Col } from "react-bootstrap";


export default class ModalUploadArquivo extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  
  

  render() {
    return (
        <Modal open={this.props.open_modal_arquivo} centered={true}>
          <Header content="Upload Imagem" />
          <Modal.Content>
            <Row>
              <Col sm={1}></Col>
              <Col sm={10}>
                <Col sm={6}>
                  <input
                    id="file"
                    name="file"
                    type="file"
                    accept=".jpg"
                    onChange={this.props.uploadImagem}
                  />
                </Col>
                <Col sm={4}></Col>
              </Col>
              <Col sm={1}></Col>
            </Row>
          </Modal.Content>
          <div class="actions">
            <Button type="button" primary onClick={this.props.fecharModalArquivo}>
              Voltar
            </Button>
            <Button type="button" secondary onClick={this.props.fecharModalArquivo}>
              Salvar
            </Button>
          </div>
        </Modal>
    );
  }
}