import React, { Component } from "react";
import { Table, Button, Menu, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";
import { TextField, Pagination } from "@material-ui/core/";

const tiposUsuario = [
  { value: "titular", label: "Titular" },
  { value: "dependente", label: "Dependente" },
  { value: "administrador", label: "Administrador" },
  { value: "temporario", label: "Temporário" },
  { value: "funcionario", label: "Funcionario" },
  { value: "caixa", label: "Caixa" },
  { value: "medico", label: "Médico" },
  { value: "portaria", label: "Portaria" },
  { value: "almoxarifado", label: "Almoxarifado" },
  { value: "projeto", label: "Projeto" },
];

export default class UsuarioLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaUsuario: [],
      pagina: 1,
      totalPaginas: 1,
      porTipoUsuario: false,
      tipoUsuario: null,
      pessoaPesquisada: [],
      listaPessoa: [],
      buscando: false,
      activePage: 1,
      tituloUsuario: "",
    };
    this.listaUsuario();
  }

  listaUsuario = async (page = 1) => {
    const resposta = await api.post(
      "/usuario/lista" + requisicoes.entidade + "&pagina=" + page,
      "",
      requisicoes.header
    );
    const { usuarios, pagina, totalPaginas } = resposta.data;
    this.setState({ listaUsuario: usuarios, pagina, totalPaginas });
  };

  pesquisaUsuario = async (activePage = 1) => {
    let term = "";
    term = document.getElementById("pesquisaUsuario").value;
    if (this.state.buscando) {
      return;
    }
    this.setState({ buscando: true });
    setTimeout(async () => {
      if (term === null) {
        this.state.buscando = false;
        this.setState({ buscando: false });
        return;
      }

      const retorno = await api.post(
        "/usuario/pesquisa" + requisicoes.entidade,
        {
          pesquisa: term,
          entidade: window.localStorage.getItem("e_clube"),
          pesquisaUsuario: true,
          // page: page,
          activePage: activePage,
        },
        requisicoes.header
      );
      let lista = [];
      retorno.data.docs.forEach((e) => {
        let objeto = {
          value: e.id,
          label: e.nome,
          login: e.login,
          tipo_usuario: e.tipo_usuario,
        };
        lista.push(objeto);
      });
      const { usuarios, pagina, totalPaginas } = retorno.data.docs;

      this.setState({
        listaPessoa: lista,
        listaUsuario: retorno.data.docs,
        buscando: false,
        activePage: activePage,
        totalPaginas: retorno.data.pages,
        totalRegistro: retorno.data.total,
      });
    }, 500);
  };
  alteracaoSelectTipoVinculo = async (event) => {
    this.setState({ tituloUsuario: event.value });
    await this.pesquisaPorTipoUsuario();
  };

  pesquisaPorTipoUsuario = async (e, page = 1) => {
    setTimeout(async () => {
      this.setState({ tipoUsuario: e?.value, porTipoUsuario: true });

      const retorno = await api.post(
        "/usuario/buscaPorTipoUsuario" +
          requisicoes.entidade +
          "&pagina=" +
          page,
        {
          tipo_usuario: this.state.tituloUsuario,
          entidade: window.localStorage.getItem("e_clube"),
          activePage: page,
        },
        requisicoes.header
      );
      const { usuarios, pagina, totalPaginas } = retorno.data;
      this.setState({
        listaUsuario: usuarios,
        pagina: pagina,
        totalPaginas: totalPaginas,
        activePage: pagina,
        totalRegistro: totalPaginas,
      });
    }, 100);
  };

  proximaPagina = async () => {
    const { pagina, totalPaginas } = this.state;
    if (pagina >= totalPaginas) return;
    const paginaNumero = parseInt(pagina) + 1;
    this.state.activePage = paginaNumero;
    await this.listaUsuario(paginaNumero);
  };

  voltarPagina = async () => {
    const { pagina } = this.state;
    if (pagina <= 1) return;
    const paginaNumero = pagina - 1;
    await this.listaUsuario(paginaNumero);
  };

  proximaPaginaTipo = async () => {
    const tipo_usuario = { value: this.state.tipoUsuario };
    const { pagina, totalPaginas } = this.state;
    if (pagina >= totalPaginas) return;
    const paginaNumero = parseInt(pagina) + 1;
    await this.pesquisaPorTipoUsuario(tipo_usuario, paginaNumero);
  };

  voltarPaginaTipo = async () => {
    const tipo_usuario = { value: this.state.tipoUsuario };
    const { pagina } = this.state;
    if (pagina <= 1) return;
    const paginaNumero = pagina - 1;
    await this.pesquisaPorTipoUsuario(tipo_usuario, paginaNumero);
  };

  render() {
    return (
      <div className="lista">
        <Row>
          <Col sm={6}>
            <TextField
              size="small"
              fullWidth
              placeholder="Digite para Buscar"
              variant="outlined"
              name="nome"
              label="Digite para Buscar"
              id="pesquisaUsuario"
              onChange={(e, a) => this.pesquisaUsuario(a)}
            />
          </Col>
          <Col sm={6}>
            <Select
              placeholder="Perfil"
              name="tipo_usuario"
              onChange={(e) => this.alteracaoSelectTipoVinculo(e, 1)}
              options={tiposUsuario}
            />
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Login</Table.HeaderCell>
              <Table.HeaderCell>Perfil</Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <Link to={{ pathname: "/usuario" }}>
                  <Button positive icon="add" size="tiny"></Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.listaUsuario.map((e, i) => (
              <Table.Row key={e?.id ? e?.id : e?.value}>
                <Table.Cell>{e?.nome ? e?.nome : e?.label}</Table.Cell>
                <Table.Cell>{e?.login}</Table.Cell>
                <Table.Cell>{e?.tipo_usuario}</Table.Cell>
                <Table.Cell width={1}>
                  <Link to={{ pathname: "/usuario", query: { id: e?.id } }}>
                    <Button primary icon="edit" size="tiny"></Button>
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>

          {this.state.porTipoUsuario === false ? (
            <Table.Footer>
              <Table.Row verticalAlign="middle" textAlign="center">
                <Table.HeaderCell colSpan={4}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Pagination
                      count={this.state?.totalPaginas}
                      variant="outlined"
                      shape="rounded"
                      boundaryCount={1}
                      page={this.state.activePage}
                      onChange={(e, a) => {
                        this.pesquisaUsuario(a);
                      }}
                    />
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          ) : (
            <Table.Footer>
              <Table.Row verticalAlign="middle" textAlign="center">
                <Table.HeaderCell colSpan={4}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Pagination
                      count={this.state?.totalPaginas}
                      variant="outlined"
                      shape="rounded"
                      boundaryCount={1}
                      page={this.state.activePage}
                      onChange={(e, a) => {
                        this.pesquisaPorTipoUsuario(e, a);
                      }}
                    />
                  </div>
                  {/* <Menu pagination>
                    <Menu.Item
                      icon
                      onClick={this.voltarPaginaTipo}
                      disabled={parseInt(this.state.pagina) === 1}
                    >
                      <Icon name="chevron left" />
                    </Menu.Item>
                    <Menu.Item
                      icon
                      onClick={this.proximaPaginaTipo}
                      disabled={
                        parseInt(this.state.pagina) ===
                        parseInt(this.state.totalPaginas)
                      }
                    >
                      <Icon name="chevron right" />
                    </Menu.Item>
                  </Menu> */}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
      </div>
    );
  }
}
