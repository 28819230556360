import React, { Component } from "react";
import { Col } from "react-bootstrap";
import {
  Button,
  Table
} from "semantic-ui-react";

export default class ListaServico extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <>
      <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Serviço</Table.HeaderCell>
                  <Table.HeaderCell>Valor</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" style={{ width: "9%" }}>
                    <Button positive icon="add" size="tiny" onClick={() => this.props.abreFechaModalLancamentoServico()} type="button"></Button>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.lista.map((e, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>
                      {e.servico.label}
                    </Table.Cell>
                    <Table.Cell>
                      {parseFloat(e.valor).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Button primary icon="edit" size="tiny" type="button" onClick={() => this.props.visualizaServico(e.id,e.data_vencimento)}></Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <Col sm={12} style={{textAlign:"right"}}>
              Total:&nbsp;&nbsp;{" "}
              {parseFloat(this.props.lista.reduce((a,v) =>  a = parseFloat(a) + parseFloat(v.valor) , 0 )).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Col>
          </>
    );
  }
}
