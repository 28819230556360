export default [
  { value: "filho", label: 'Filho(a)' },
  { value: "conjuge", label: 'Conjuge' },
  { value: "neto", label: 'Neto(a)' },
  { value: "companheiro", label: 'Companheiro(a)' },
  { value: "enteado", label: 'Enteado(a)' },
  { value: "irmao", label: 'Irmão(ã)' },
  { value: "progenitor", label: 'Pai/Mãe' },
  { value: "sobrinho", label: 'Sobrinho(a)' },
  { value: "sogro", label: 'Sogro(a)' },
]