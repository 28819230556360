//Componentes
import React, { Component } from "react";
import { Row, Col, Form, FormControl, Button } from "react-bootstrap";
import {
  Header,
  Segment,
  Divider,
  Icon,
  Table,
  Checkbox,
} from "semantic-ui-react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

//Libs
import moment from "moment-timezone";
import $ from "jquery";
import ReplaceAll from "replaceall";

//Outras classes
import Meses from "../../../../../enum/Mes";
import converterParaLabel from "../../../../../utils/converteParaLabel";
import api from "../../../../../services/api";
import requisicoes from "../../../../../utils/Requisicoes";

export default class ModalAlteracaoTipoTitulo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data_lancamento: moment().format("YYYY-MM-DD"),
      hora_lancamento: moment().format("HH:mm"),
      data_transferencia: moment().format("YYYY-MM-DD"),
      tipo_titulo_para: null,
      motivo: null,
      options: [],
      inputValue: "",
      acao: null,
      options_tipo_titulo_para: [],
      listaTipoTitulo: [],
      pessoa_id: null,
      mensalidades_em_aberto: [],
      checkboxTodos: false,
      novasMensalidades: [],
      pessoa: null,
      instituicoes_boleto: [],
      servico_mensalidade_id: null,
    };
    this.listaTipoTitulo();
    this.listaInstituicoes();
    this.getServicoMensalidade();
  }

  componentMoney() {
    $(".dinheiro").mask("#.##0,00", {
      reverse: true,
      placeholder: "0,00",
    });
  }

  getServicoMensalidade = async () => {
    const parametro = await api.post(
      "/parametros/visualiza" + requisicoes.entidade,
      {
        nome: "servico_mensalidade_id",
      },
      requisicoes.header
    );
    this.setState({
      servico_mensalidade_id: parametro.data[0].valor,
    });
  };

  listaTipoTitulo = async () => {
    const resposta = await api.post(
      "/tipoTitulo/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ listaTipoTitulo: resposta.data });
  };

  listaInstituicoes = async () => {
    const retorno = await api.post(
      "/instituicaoCobranca/lista" + requisicoes.entidade,
      {
        boleto: true,
      },
      requisicoes.header
    );

    let instituicoes_debito_em_conta = [];
    let instituicoes_boleto = [];

    for (let i = 0; i < retorno.data.length; i++) {
      const element = retorno.data[i];
      let option = {
        key: element.id,
        value: parseInt(element.id),
        label:
          element.conta +
          "-" +
          element.digito_conta +
          " / " +
          element.banco.nome,
      };

      if (element.debito_em_conta) {
        instituicoes_debito_em_conta.push(option);
      }
      if (element.boleto) {
        instituicoes_boleto.push(option);
      }
    }
    console.log("instituicoes_boleto: ", instituicoes_boleto);
    this.setState({ instituicoes_boleto });
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  pesquisaPessoaAcao = async () => {
    let term = document.getElementById("pesquisaPessoa").value;
    if (term === "") {
      return;
    }
    var prefixo = null;
    var tipoTituloBusca = null;
    setTimeout(async () => {
      if (prefixo !== null) {
        term = term.slice(prefixo.length);
        if (term === "") {
          return;
        }
      }
      if (isFinite(String(term))) {
        await api
          .post(
            // "/pessoa/pesquisaPorCodigoAcaoValorExato" + requisicoes.entidade,
            "/pessoa/pesquisaPessoaAcaoSemTitularEComAcao" +
              requisicoes.entidade,
            {
              pesquisa: term,
              tipo_titulo: tipoTituloBusca,
              flag_codigo_aux: tipoTituloBusca !== null,
            },
            requisicoes.header
          )
          .then((retorno) => {
            this.setState({
              options: converterParaLabel(retorno.data, prefixo),
            });
          });
      } else {
        await api
          .post(
            // "/pessoa/pesquisa" + requisicoes.entidade,
            "/pessoa/pesquisaPessoaSemTitularEComAcao" + requisicoes.entidade,
            {
              pesquisa: term,
              tipo_titulo: tipoTituloBusca,
            },
            requisicoes.header
          )
          .then((retorno) => {
            this.setState({
              options: converterParaLabel(retorno.data, prefixo),
            });
          });
      }
    }, 200);
  };

  onChangeSelectPessoa = async (event, pessoa) => {
    let options = [];

    for (let i = 0; i < this.state.listaTipoTitulo.length; i++) {
      const element = this.state.listaTipoTitulo[i];

      if (pessoa.acao.tipo_titulo.descricao === "PATRIMONIAL") {
        if (
          element.descricao === "LICENCIADO" ||
          element.descricao === "REMIDO" ||
          element.descricao === "CONTRIBUINTE COM DEPENDENTE" ||
          element.descricao === "CONTRIBUINTE SEM DEPENDENTES"
        ) {
          options.push(element);
        }
      }
      if (pessoa.acao.tipo_titulo.descricao === "LICENCIADO") {
        if (element.descricao === "PATRIMONIAL") {
          options.push(element);
        }
      }
      if (
        pessoa.acao.tipo_titulo.descricao === "CONTRIBUINTE SEM DEPENDENTES"
      ) {
        if (element.descricao === "CONTRIBUINTE COM DEPENDENTE") {
          options.push(element);
        }
      }
      if (pessoa.acao.tipo_titulo.descricao === "CONTRIBUINTE COM DEPENDENTE") {
        if (element.descricao === "CONTRIBUINTE SEM DEPENDENTES") {
          options.push(element);
        }
      }
      if (pessoa.acao.tipo_titulo.descricao === "REMIDO") {
        if (element.descricao === "PATRIMONIAL") {
          options.push(element);
        }
        if (element.descricao === "BENEMERITO") {
          options.push(element);
        }
      }

      if (pessoa.acao.tipo_titulo.descricao === "TEMPORÁRIO") {
        if (element.descricao === "PATRIMONIAL") {
          options.push(element);
        }
      }
    }

    let mensalidades = await api.post(
      "/lancamentoConta/lancamentosEmAbertoPorPessoaEServico" +
        requisicoes.entidade,
      {
        pessoa_id: pessoa.id,
        servico_id: this.state.servico_mensalidade_id,
      },
      requisicoes.header
    );

    for (let index = 0; index < mensalidades.data.length; index++) {
      const element = mensalidades.data[index];
      element.checked = true;
    }

    this.setState({
      pessoa_id: pessoa.id,
      acao: pessoa.acao,
      tipo_titulo_para: null,
      options_tipo_titulo_para: options,
      mensalidades_em_aberto: mensalidades.data,
      checkboxTodos: true,
      pessoa: pessoa,
    });
  };

  onChangeTipoTituloPara = (e, i) => {
    for (let i = 0; i < this.state.listaTipoTitulo.length; i++) {
      const element = this.state.listaTipoTitulo[i];
      if (element.id === parseInt(e.target.value)) {
        this.setState({ tipo_titulo_para: element });
        return;
      }
    }
  };

  changeCheckbox = (checked, i) => {
    let lista = this.state.mensalidades_em_aberto;
    if (i === -1) {
      this.setState({ checkboxTodos: checked });
      for (let i = 0; i < lista.length; i++) {
        const element = lista[i];
        element.checked = checked;
      }
    } else {
      lista[i].checked = checked;
    }
    this.setState({ mensalidades_em_aberto: lista });
  };

  geraParcelas = (event) => {
    if (this.state.tipo_titulo_para === null) {
      alert("Selecione um tipo de título para gerar as novas mensalidades.");
      $("#forma_pagamento_mensalidade").val(0);
      return;
    }

    const parcelas = [];
    let dataVencimento = "";
    let dataHoje = moment();

    const numeroParcela = event.target.value.toString().replace("x", "");

    if (numeroParcela === 0 || numeroParcela === "0") {
      this.setState({ novasMensalidades: [] });
      return;
    }

    for (var i = 1; i < parseInt(numeroParcela) + 1; i++) {
      let valor = 0;
      if (i === 1) {
        dataHoje.add(1, "month");
        dataVencimento = moment()
          .set("year", dataHoje.get("year"))
          .set("month", dataHoje.get("month"))
          .set("date", this.state.tipo_titulo_para.dia_vencimento);
        // if (dataVencimento.day() === 6 || dataVencimento.day() === 0) {
        //   dataVencimento = proximoDiaUtil(dataVencimento);
        // }
        const quantidadeDiasDoMes = moment().daysInMonth();
        const valorDia =
          this.state.tipo_titulo_para.valor_mensalidade / quantidadeDiasDoMes;
        const diasFaltantesFinalDoMes = moment()
          .endOf("month")
          .subtract(moment())
          .get("date");
        valor = diasFaltantesFinalDoMes * valorDia;
      } else {
        dataVencimento.add(1, "month");
        const mes = dataVencimento.get("month") + 1;
        dataVencimento = moment(
          dataVencimento.get("year") +
            "-" +
            (mes.length === 1 ? "0" + mes : mes) +
            "-" +
            this.state.acao.tipo_titulo.dia_vencimento
        );
        // if (dataVencimento.day() === 6 || dataVencimento.day() === 0) {
        //   dataVencimento = proximoDiaUtil(dataVencimento);
        // }
        valor = this.state.tipo_titulo_para.valor_mensalidade;
      }
      const mes = dataVencimento.get("month") + 1;
      parcelas.push({
        numero: i,
        pessoa: this.state.pessoa,
        valor: parseFloat(valor).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        tipo_cobranca: this.state.pessoa.tipo_cobranca,
        instituicao_cobranca_id:
          this.state.pessoa.tipo_cobranca === "boleto"
            ? this.state.instituicao_cobranca_id
            : null,
        servico: { value: this.state.servico_mensalidade_id },
        data_vencimento:
          dataVencimento.get("year") +
          "-" +
          (mes.toString().length === 1 ? "0" + mes : mes) +
          "-" +
          (dataVencimento.get("date").toString().length === 1
            ? "0" + dataVencimento.get("date")
            : dataVencimento.get("date")),
        mes_referencia:
          Meses[
            dataVencimento.get("month") - 1 === -1
              ? 11
              : dataVencimento.get("month") - 1
          ].value,
        ano_referencia:
          dataVencimento.get("month") - 1 === -1
            ? dataVencimento.get("year") - 1
            : dataVencimento.get("year"),
      });

      // parcelas.push({
      //   servico: servico,

      // this.props.setParcelasMensalidade(parcelas);
      this.setState({ novasMensalidades: parcelas });
    }
  };

  alteracaoParcelasMensalidade = (event) => {
    let lista = [];
    const indice = event.target.id;
    lista = this.state.novasMensalidades;
    let elementoAlterado = lista[parseInt(indice)];

    elementoAlterado.numero = parseInt(indice) + 1;
    if (event.target.name === "valorParcelaMensalidade") {
      elementoAlterado.valor = event.target.value;
    }
    if (event.target.name === "dataVencimentoParcelaMensalidade") {
      elementoAlterado.data_vencimento = event.target.value;
    }
    if (event.target.name === "mesReferencia") {
      elementoAlterado.mes_referencia = event.target.value;
    }
    if (event.target.name === "anoReferencia") {
      elementoAlterado.ano_referencia = event.target.value;
    }

    lista.splice(parseInt(indice), 1, elementoAlterado);

    if (lista.find((e) => e.valor.toString() === "NaN")) {
      lista.forEach((element) => {
        if (element.valor.toString() === "NaN") {
          element.valor = "";
        }
      });
    }
    // this.props.setParcelasMensalidade(lista);
    this.setState({ novasMensalidades: lista });
    if (event.target.name === "valorParcelaMensalidade") {
      this.componentMoney();
    }
  };

  submit = async (event) => {
    event.preventDefault();
    if (
      this.state.novasMensalidades.length > 0 &&
      this.state.pessoa.tipo_cobranca === "boleto" &&
      (!this.state.instituicao_cobranca_id ||
        this.state.instituicao_cobranca_id === "")
    ) {
      alert("Selecione a instituição de cobrança do boleto bancário.");
      return;
    }
    let listaLancamentos = this.state.mensalidades_em_aberto;
    let listaCancelar = [];

    for (let i = 0; i < listaLancamentos.length; i++) {
      const element = listaLancamentos[i];
      if (element.checked === true) {
        listaCancelar.push({ id: element.id });
      }
    }
    await api.post(
      "/lancamentoConta/cancelarVarios" + requisicoes.entidade,
      {
        lancamentos: listaCancelar,
        motivo: "Alteração de tipo de título do sócio.",
        tela_usada: "transferencia_tipo_titulo",
      },
      requisicoes.header
    );

    let listaMensalidadesNovas = this.state.novasMensalidades;

    for (let i = 0; i < listaMensalidadesNovas.length; i++) {
      const element = listaMensalidadesNovas[i];
      if (
        element.valor === "" ||
        parseFloat(element.valor) === 0 ||
        isNaN(parseInt(element.valor))
      ) {
        listaMensalidadesNovas.splice(i, 1);
        i--;
      } else {
        element.valor = parseFloat(
          ReplaceAll(".", "", element.valor).replace(",", ".")
        );
      }
    }

    if (listaMensalidadesNovas.length > 0) {
      await api.post(
        "/mensalidade/salvar" + requisicoes.entidade,
        listaMensalidadesNovas,
        requisicoes.header
      );
    }

    await api.post(
      "/acao/alterar" + requisicoes.entidade,
      {
        id: this.state.acao.id,
        tipo_titulo_id: this.state.tipo_titulo_para.id,
      },
      requisicoes.header
    );

    await api.post(
      "/historicoAcao/salvar" + requisicoes.entidade,
      {
        tipo_lancamento: "alteracaoTipoTitulo",
        status: this.state.tipo_titulo_para.id,
        data_transferencia: this.state.data_transferencia,
        pessoa_compra_id: this.state.pessoa_id,
        pessoa_venda_id: this.state.pessoa_id,
        tipo_titulo_id_anterior: this.state.acao.tipo_titulo.id,
        tipo_titulo_id_novo: this.state.tipo_titulo_para.id,
        motivo:
          "Alteração de " +
          this.state.acao.tipo_titulo.descricao +
          " para " +
          this.state.tipo_titulo_para.descricao +
          ". " +
          this.state.motivo,
        acao_id: this.state.acao.id,
      },
      requisicoes.header
    );

    window.location.href = "/administrativo";
  };

  render() {
    return (
      <div className="pagina" style={{ paddingBottom: 100 }}>
        <Header as="h1" dividing>
          Alteração de Tipo de Título
        </Header>
        <Row>
          <Col sm={8}>
            <Form.Label>Pessoa</Form.Label>
            <Row>
              <Col sm={12}>
                <Autocomplete
                  id="pesquisaPessoa"
                  value={this.state.pessoa}
                  inputValue={this.state.inputValue}
                  onInputChange={(event, newInputValue) => {
                    this.setState({ inputValue: newInputValue });
                  }}
                  options={this.state.options}
                  getOptionLabel={(option) => option.label}
                  onKeyUp={this.pesquisaPessoaAcao}
                  onChange={(event, a) => this.onChangeSelectPessoa(event, a)}
                  filterSelectedOptions={false}
                  filterOptions={(options, state) => options}
                  renderInput={(params) => (
                    <TextField {...params} label="Busca" size="small" />
                  )}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Form onSubmit={this.submit}>
          <Row>
            <Col sm={5}>
              <Form.Label>De</Form.Label>
              <Form.Control
                as="select"
                name="tipo_titulo_de"
                required
                disabled
                value={
                  this.state.acao !== null
                    ? this.state.acao.tipo_titulo.descricao
                    : this.state.acao
                }
              >
                {this.state.acao !== null ? (
                  <option value={this.state.acao.tipo_titulo.descricao}>
                    {this.state.acao.tipo_titulo.descricao}
                  </option>
                ) : null}
              </Form.Control>
            </Col>
            <Col sm={2}>
              <Form.Label></Form.Label>
              <Icon
                name="exchange"
                size="large"
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "40%",
                }}
              />
            </Col>
            <Col sm={5}>
              <Form.Label>Para</Form.Label>
              <Form.Control
                as="select"
                name="tipo_titulo_para"
                required
                onChange={(e, i) => this.onChangeTipoTituloPara(e, i)}
                value={
                  this.state.tipo_titulo_para !== null
                    ? this.state.tipo_titulo_para.id
                    : null
                }
              >
                <option></option>
                {this.state.options_tipo_titulo_para.map((e, i) => (
                  <option value={e.id}>{e.descricao}</option>
                ))}
              </Form.Control>
            </Col>

            <Col sm={6} style={{ paddingTop: "10px" }}>
              <Form.Label>Data Lançamento</Form.Label>
              <FormControl
                aria-describedby="basic-addon1"
                type="date"
                name="data_lancamento"
                value={this.state.data_lancamento.toString().substr(0, 10)}
                disabled
              />
            </Col>

            <Col sm={6} style={{ paddingTop: "10px" }}>
              <Form.Label>Hora </Form.Label>
              <FormControl
                name="hora_lancamento"
                aria-describedby="basic-addon1"
                value={this.state.hora_lancamento}
                disabled
              />
            </Col>

            <Col sm={6} style={{ paddingTop: "10px" }}>
              <Form.Label>Data Transferência</Form.Label>
              <FormControl
                aria-describedby="basic-addon1"
                type="date"
                name="data_transferencia"
                onChange={this.alteracao}
                value={this.state.data_transferencia.toString().substr(0, 10)}
              />
            </Col>

            <Col sm={12}>
              <Form.Label>Motivo</Form.Label>
              <FormControl
                placeholder="Motivo"
                aria-describedby="basic-addon1"
                as="textarea"
                name="motivo"
                onChange={this.alteracao}
                required
              />
            </Col>
          </Row>
          {this.state.pessoa_id !== null && (
            <>
              <h3>Mensalidades em aberto</h3>
              {this.state.mensalidades_em_aberto.length === 0 ? (
                <h4>
                  Não foram encontradas mensalidades em aberto no sistema.
                </h4>
              ) : (
                <>
                  {this.state.tipo_titulo_para !== null &&
                    this.state.tipo_titulo_para !== undefined &&
                    this.state.tipo_titulo_para.valor_mensalidade !==
                      this.state.acao.tipo_titulo.valor_mensalidade && (
                      <h5 style={{ color: "gray", marginTop: "10px" }}>
                        As seguintes parcelas devem ser canceladas por serem
                        diferentes do valor de mensalidade do novo tipo de
                        título. Novas parcelas devem ser geradas.
                        <br />
                        Valor da parcela do tipo de título{" "}
                        {this.state.acao.tipo_titulo.descricao}:{" "}
                        {parseFloat(
                          this.state.acao.tipo_titulo.valor_mensalidade
                        ).toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        . Valor da parcela do novo tipo de título{" "}
                        {this.state.tipo_titulo_para.descricao}:{" "}
                        {parseFloat(
                          this.state.tipo_titulo_para.valor_mensalidade
                        ).toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </h5>
                    )}
                  <h5 style={{ color: "red", marginTop: "10px" }}>
                    Selecione as parcelas que deseja cancelar.
                  </h5>

                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Descrição</Table.HeaderCell>
                        <Table.HeaderCell>Data vencimento</Table.HeaderCell>
                        <Table.HeaderCell>Valor</Table.HeaderCell>
                        <Table.HeaderCell>
                          <Checkbox
                            checked={this.state.checkboxTodos}
                            onChange={(event, a) =>
                              this.changeCheckbox(a.checked, -1)
                            }
                          />
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.state.mensalidades_em_aberto.map((e, i) => (
                        <Table.Row>
                          <Table.Cell>{e.descricao}</Table.Cell>
                          <Table.Cell>
                            {moment(e.data_vencimento).format("DD/MM/YYYY")}
                          </Table.Cell>
                          <Table.Cell>
                            {parseFloat(e.valor).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Table.Cell>
                          <Table.Cell>
                            <Checkbox
                              checked={e.checked}
                              onChange={(event, a) =>
                                this.changeCheckbox(a.checked, i)
                              }
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </>
              )}

              <h3>Novas mensalidades</h3>

              <Row>
                <Col sm={2}>
                  <b>Mensalidade</b>

                  <Form.Control
                    id="forma_pagamento_mensalidade"
                    placeholder="Tipo"
                    as="select"
                    name="forma_pagamento_mensalidade"
                    onChange={this.geraParcelas}
                  >
                    <option value="0"></option>
                    <option value="1x">1</option>
                    <option value="2x">2</option>
                    <option value="3x">3</option>
                    <option value="4x">4</option>
                    <option value="5x">5</option>
                    <option value="6x">6</option>
                    <option value="7x">7</option>
                    <option value="8x">8</option>
                    <option value="9x">9</option>
                    <option value="10x">10</option>
                    <option value="11x">11</option>
                    <option value="12x">12</option>
                  </Form.Control>
                </Col>
                <Col sm={3}>
                  <b>Tipo Cobrança</b>
                  <Form.Control
                    placeholder="Tipo"
                    as="select"
                    name="forma_pagamento_mensalidade"
                    value={this.state.pessoa.tipo_cobranca}
                    disabled={true}
                  >
                    <option value=""></option>
                    <option value="boleto">Boleto</option>
                    <option value="debito_em_conta">Débito em conta</option>
                    <option value="isento">Isento</option>
                  </Form.Control>
                </Col>
                {this.state.pessoa.tipo_cobranca === "boleto" && (
                  <Col sm={7}>
                    <b>Instituição cobrança</b>
                    <Form.Control
                      placeholder="Tipo"
                      as="select"
                      name="instituicao_cobranca_id"
                      value={this.state.instituicao_cobranca_id}
                      options={this.state.instituicoes_boleto}
                      onChange={(e) => {
                        let lista = this.state.novasMensalidades;
                        for (let i = 0; i < lista.length; i++) {
                          const element = lista[i];
                          element.instituicao_cobranca_id = e.target.value;
                        }
                        this.setState({
                          novasMensalidades: lista,
                          instituicao_cobranca_id: e.target.value,
                        });
                      }}
                    >
                      <option value=""></option>
                      {this.state.instituicoes_boleto.map((e, i) => {
                        return <option value={e.value}>{e.label}</option>;
                      })}
                    </Form.Control>
                  </Col>
                )}
              </Row>
              <Col sm={8}>
                <Row>
                  {this.state.novasMensalidades.map((e, i) => {
                    return (
                      <Row>
                        <Col key={i} sm={12}>
                          <Row>
                            <Col sm={2}>
                              Parcela {e.numero}
                              <Form.Control
                                id={"" + i + ""}
                                value={e.valor}
                                onKeyUp={this.alteracaoParcelasMensalidade}
                                onChange={this.alteracaoParcelasMensalidade}
                                name="valorParcelaMensalidade"
                                className="dinheiro"
                              ></Form.Control>
                            </Col>
                            <Col sm={3}>
                              Mês de referência
                              <Form.Control
                                id={"" + i + ""}
                                as="select"
                                name="mesReferencia"
                                onChange={this.alteracaoParcelasMensalidade}
                                defaultValue={e.mes_referencia}
                              >
                                <option></option>
                                {Meses.map((e, i) => (
                                  <option key={i} value={e.value}>
                                    {e.label}
                                  </option>
                                ))}
                              </Form.Control>
                            </Col>
                            <Col sm={2}>
                              Ano
                              <Form.Control
                                id={"" + i + ""}
                                defaultValue={e.ano_referencia}
                                name="anoReferencia"
                                onChange={this.alteracaoParcelasMensalidade}
                              ></Form.Control>
                            </Col>
                            <Col sm={5}>
                              Vencimento
                              <FormControl
                                type="date"
                                defaultValue={e.data_vencimento}
                                id={"" + i + ""}
                                onChange={this.alteracaoParcelasMensalidade}
                                name="dataVencimentoParcelaMensalidade"
                              ></FormControl>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                </Row>
              </Col>
            </>
          )}

          <Divider />
          <Segment clearing>
            <Header floated="right">
              <Row>
                <Col>
                  <Button
                    variant="light"
                    onClick={() => (window.location.href = "/administrativo")}
                    style={{ marginLeft: "10px" }}
                  >
                    Voltar
                  </Button>
                </Col>
                <Col>
                  <Button variant="primary" type="submit">
                    Salvar
                  </Button>
                </Col>
              </Row>
            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}
