import React, { Component } from "react";
import { Header, Segment } from "semantic-ui-react";

export default class Politica extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Segment>
        <div style={{ padding: 30 }} align="justify">
          <Header>POLÍTICA DE DADOS FAEE CLUBE</Header>
          Esta política descreve as informações que tratamos para suportar o
          produto denominado FAEE CLUBE e todas as suas funcionalidades. A
          seguir, descreveremos como gerimos o fluxo de informações inclusas nos
          nossos ambientes, visando sempre a total integridade e segurança das
          informações dos usuários.
          <h4>Quais tipos de informações recolhemos?</h4>
          Para fornecermos o Produto FAEE CLUBE, precisamos gerenciar e
          movimentar informações sobre todas as pessoas vinculadas a ele, de
          todas as naturezas envolvidas, como pessoas físicas e jurídicas,
          entidades públicas e privadas. Os tipos de informações que recolhemos
          variam de acordo com as necessidades de cada perfil de usuário e
          natureza das pessoas cadastradas, de acordo também com o objetivo do
          clube cliente. Recolhemos informações sobre as pessoas de todas as
          naturezas envolvidas com o cliente contratante do sistema, sejam
          órgãos públicos, entidades privadas, pessoas físicas e jurídicas. As
          informações coletadas majoritariamente são informações cadastrais
          básicas, como nome, CPF ou CNPJ, endereços, e-mails, informações para
          contato e uma imagem de identificação. Também, se faz necessária a
          coleta e armazenagem de dados avançados, como dados referentes a
          contas bancárias e a biometria.
          <h4>Informações de Dispositivos</h4>
          Nossos sistemas não captam nenhuma informação dos dispositivos
          utilizados para sua operação, sejam eles computadores, laptops,
          smartphones ou tablets. Também não são armazenados cookies e
          informações dos navegadores. Todas as importações são realizadas de
          maneira manual e não automáticas. A única informação acessada pelo
          nosso aplicativo são as de resolução do dispositivo, afim de
          ajustar-se aos valores do aparelho.
          <h4>Como utilizamos estas informações? </h4>
          Utilizamos as informações coletadas única e exclusivamente para
          atingir os objetivos dos sistemas, de acordo com sua finalidade. A
          missão do sistema FAEE CLUBE é propiciar uma gestão completa do
          ambiente do clube, bem como a gestão de espaços físicos, informações
          dos sócios, informações do clube e produzir relatórios e dados que
          facilitem e tornem a gestão total possível. Os dados pessoais
          coletados dos sócios serão utilizados para toda a movimentação
          necessária do clube, seja para controle de mensalidades, controle de
          reservas, controle de acessos e requisitos para acessos a espaços e
          benefícios fornecidos pelo clube, como exames físicos e médicos por
          exemplo. Ainda, serão utilizadas informações pessoais para o vínculo
          com ações e titularidades, produção de carteirinhas, matrículas e
          todos os possíveis tipos de interação entre sócio e clube. No caso de
          dados bancários, serão utilizados para os trâmites financeiros entre
          sócio e clube, como a geração de mensalidades, débitos em conta e
          eventuais cobranças referentes à serviços ofertados pelo clube e
          adquiridos e/ou acessados pelo sócio de acordo com a sua vontade.
          Também serão coletados e utilizados dados bancários na relação entre
          fornecedores e clube, sejam eles pessoa física e jurídica, para
          geração de boletos e cobranças referentes a comercialização de
          produtos e serviços entre as entidades. Dados cadastrais e dados
          biométricos serão utilizados também serão utilizados para produção de
          material de acesso ao clube, podendo variar de acordo com as escolhas
          do clube cliente, caracterizando-se na forma de carteirinhas físicas,
          carteirinhas digitais, leitores biométricos, leitores RFID (chip de
          aproximação) ou códigos e senhas.
          <h4>Como são compartilhadas essas informações? </h4>
          Referente à segurança das informações coletadas, armazenadas e
          tratadas em nosso sistema, informamos que: No sistema FAEE CLUBE,
          todas as informações são tratadas de maneira interna, em um circuito
          composto por clube cliente e sócios. Isso significa que todas as
          informações coletadas pelo Clube cliente, não serão utilizadas em
          banco de dados pessoais da FAEE SISTEMAS para outros fins e que também
          não serão compartilhadas com outras entidades que adquirirem e
          utilizarem o sistema FAEE CLUBES. O acesso aos dados é concedido de
          maneira personzalizada, ou seja, os dados pessoais relacionados à
          cobranças, dívidas, movimentações financeiras e informações restritas
          poderão ser acessados apenas pelo clube e seus funcionários
          autorizados, isso significa dizer que nem todos os funcionários do
          clube terão acesso à informações sensíveis, restritas e essenciais. Em
          casos de informações internas e com consentimento de sócios, o sistema
          fornece a plataforma de marketing, com a funcionalidade de exibir
          mensagens, anúncios, ofertas e comunicados aos sócios de maneira
          compartilhada. As informações de contato serão utilizadas para que o
          clube cliente entre em contato com seus sócios por quaisquer eventuais
          motivos. O clube poderá ainda executar funcionalidades de contato
          visando agilidade e facilidade para ambas as partes, sócios e clube,
          como o envio de informações automáticas, como por exemplo boletos, por
          endereço eletrônico (e-mail) e via aplicativo WhatsApp. Ressaltamos
          que, os operadores do sistema FAEE CLUBE, estão orientados e em
          conformidade com o termo de consentimento, sabendo da responsabilidade
          em manusear os dados inseridos no sistema, e que sua integridade
          perpassa por suas ações. Periodicamente, a FAEE SISTEMAS fornece
          material informativo e de carácter instrutivo para seus clientes,
          maximizando ainda mais a capacidade de nos mantermos seguros e
          íntegros.
          <h4>
            Como as entidades parceiras trabalham em conjunto com a FAEE?{" "}
          </h4>
          A FAEE SISTEMAS atua como desenvolvedora de softwares para gestão,
          sendo a operação do sistema realizada por parte dos clientes. A FAEE
          entende que a segurança de seus sistemas perpassa pelas ações de seus
          clientes, por isso, disponibiliza treinamentos In Loco com todos os
          operadores na implantação do sistema, orientando os operadores ao
          correto manuseio das ferramentas já no primeiro contato. Além disso,
          treinamentos podem ser solicitados a qualquer momento e serão
          realizados conforme agenda. Ainda, a FAEE disponibiliza aos usuários
          suporte em horário comercial, visando a diminuição de problemas
          decorrentes da má utilização, e todos os profissionais do suporte FAEE
          são orientados e capacitados a agir de maneira assertiva em relação às
          exigências dos órgãos fiscalizadores. Em relação à LGPD e o manuseio
          de dados, a FAEE informa que realiza a produção de materiais
          informativos periodicamente e compartilha-os com seus clientes,
          orientando e instruindo ao uso correto das informações contidas nos
          sistemas.
          <h4>Quanto ao princípio da não discriminação: </h4>
          Nenhuma funcionalidade desenvolvida em qualquer um dos sistemas da
          FAEE tem o objetivo de levantar informações, dados estatísticos,
          relatórios e informações gerais para o uso de caráter preconceituoso,
          racista, sexista, machista, homofóbico, desclassificatório,
          discriminatório e ofensivo. Se identificada qualquer situação de mau
          uso de funcionalidades para fins ilícitos, medidas tratativas serão
          adotadas imediatamente.
          <h4>
            Como iremos lhe notificar em caso de alterações na nossa política?{" "}
          </h4>
          Nossa política ficará sempre disponível a todos. Em caso de
          atualizações, revisões, alterações e incrementos, notificaremos todos
          os usuários via sistema com uma notificação acusando qual a alteração,
          onde ela se encontra e contextualizando o porquê de sua alteração e
          qual a sua importância.
          <h4>Como você pode requisitar acesso aos seus dados? </h4>
          Caso um usuário sinta a necessidade de saber quais dos seus dados
          estão inseridos no sistema, o contato deverá ser realizado diretamente
          com a FAEE SISTEMAS, via site, aplicativo, e-mail, telefone ou
          presencialmente. Está disponível no menu “Política de Dados” o submenu
          “FALE CONOSCO”. Será apresentado um formulário de segurança, para que
          as informações sejam realmente acessadas somente por usuários
          validados, e então, liberadas as informações.
          <h4>Como cancelar o acesso aos meus dados? </h4>
          Você pode pedir para acessar dados seus que estejam em uso por uma
          entidade cliente a qualquer momento, também, pode solicitar que certos
          dados pessoais sejam eliminados. Para isso, entre em contato
          diretamente com a FAEE SISTEMAS. Está disponível no menu “Política de
          Dados” o submenu “FALE CONOSCO”. A Lei Geral de Proteção de Dados
          Pessoais (LGPD) assegura diferentes direitos a você cidadão,
          brasileiro ou não, que esteja no Brasil. Afinal, os dados são seus,
          logo, os direitos também.
        </div>
      </Segment>
    );
  }
}
