import { Component } from "react";
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "semantic-ui-react";

export default class ModalRecusar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.onClose} size="tiny">
        <ModalHeader>Atenção</ModalHeader>
        <ModalContent>
          <h3>
            É necessário aceitar estes termos aprensentados para utilizar o
            sistema.
          </h3>
        </ModalContent>
        <ModalActions>
          <Button color="green" onClick={this.props.onClose}>
            Ok
          </Button>
        </ModalActions>
      </Modal>
    );
  }
}
