import React, { Component } from "react";
import { Row, Col, FormControl, Form, Alert } from "react-bootstrap";
import { Header, Button, Divider, Segment } from "semantic-ui-react";
import Select from "react-select";

// Componentes
import ModalDependente from "./modalDependente";
import ListaDependentes from "./listaDependentes";
import VisualizaPessoa from "./visualizaPessoa";
import Cobranca from "./cobranca";

// Enum
import Meses from "../../../../enum/Mes";
import Parcelas from "../../../../enum/ParcelasEnum";
import Acao from "../../../../enum/Acao";
import ValidadorCamposPessoa from "../../../../utils/validadorCamposPessoa";

// Utils
import renomearChave from "../../../../services/renomearChave";
import {
  addMonths,
  setDate,
  getYear,
  getMonth,
  getDate,
  setMonth,
  setYear,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import ReplaceAll from "replaceall";
import moment from "moment-timezone";
import Numerico from "../../../../utils/numerico";
import proximoDiaUtil, {
  getFormatStringDate,
} from "../../../../utils/proximoDiaUtil";
import checkFeriado from "../../../../enum/Feriados";

// Requisicao
import requisicoes from "../../../../utils/Requisicoes";
import api from "../../../../services/api";

import { withRouter, Link } from "react-router-dom";

class Usuario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      login: null,
      senha: null,
      tipo_usuario: "titular",
      pessoa_id: null,
      options: [],
      pessoa: null,
      qrCodeLink: "",
      infoPessoa: null,
      acao: null,
      selectTipo: null,
      listaTipoTitulo: [],
      parcelas: Parcelas,
      infoParcelasMensalidade: [],
      infoParcelasTitulo: [],
      instituicao_cobranca: null,
      meses: Meses,
      tipo_pagamento: null,
      efetuar_cobranca: false,
      listaUsuario: [],
      usuario_id: null,
      usuario: null,
      open_modal_dependentes: false,
      dependentes: [],
      lancamentos: [],
      tipoTitulo: null,
      numero_titulo: null,
      acao_dependentes: null,
      tipo_titulo_descricao: null,
      msgCodigoExiste: null,
      data_inicial: null,
      data_final: null,
      servico_titulo: null,
      servico_mensalidade: null,
      open_modal_pessoa: false,
      codigo: null,
      mensagem: null,
      desabilitar_impressao: false,
      forma_pagamento: "boleto",
      desvinculandoAcao: false,
      instituicoes_boleto: [],
      instituicao_cobranca_id: "",
      novo_dependente: null,
      valor_parcelas_titulo: null,
      valorTitulo: 0,
      listaPessoaIdade: [],
    };

    this.alteracao = this.alteracao.bind(this);
    this.alteracaoSelectPessoa = this.alteracaoSelectPessoa.bind(this);
    this.removeDependentes = this.removeDependentes.bind(this);
    this.alteraAcao = this.alteraAcao.bind(this);
    this.geraNumeroTitulo = this.geraNumeroTitulo.bind(this);

    this.visualizacao();
    this.listaTipoTitulo();

    var url_string = window.location.href;
    var url = new URL(url_string);
    var pessoa_id = url.searchParams.get("pessoa_id");
    var dependente_id = url.searchParams.get("dependente_id");
    if (pessoa_id !== null) {
      this.alteracaoSelectPessoa({ value: pessoa_id });
    }
    if (dependente_id !== null) {
      this.alteracaoSelectDependente({ value: dependente_id });
    }
  }

  removeDependenteFromURL = async () => {
    try {
      var url_string = window.location.href;
      var url = new URL(url_string);
      var dependente_id = url.searchParams.get("dependente_id");

      if (dependente_id) {
        url.searchParams.delete("dependente_id");

        window.history.replaceState({}, "Faee - Clube", url.href);

        this.setState({
          dependente_id: null,
          novo_dependente: null,
        });
      }
    } catch (error) {
      console.log("removeDependenteFromURL.error: " + error);
    }
  };

  alteracaoPessoa = (event) => {
    let pessoa = this.state.infoPessoa;
    Object.entries(pessoa).map((k) => {
      if (k[0] === event.target.name) {
        pessoa[k[0]] = event.target.value;
      }
    });
    this.setState({ infoPessoa: pessoa });
  };
  numeroDependente = (event) => {
    const pessoa = { ...this.state.infoPessoa };
    Object.entries(pessoa).reduce((acc, [key, value]) => {
      if (key === event.target.name) {
        acc[key] = event.target.value;
      }
      return acc;
    }, pessoa);
    this.setState({ infoPessoa: pessoa });
  };
  handleOpenDependentes = async () => {
    this.setState({
      open_modal_dependentes: true,
      numero_titulo: this.geraNumeroTitulo(),
    });
    this.removeDependenteFromURL();
  };

  handleCloseDependentes = () => {
    this.setState({
      open_modal_dependentes: false,
    });
  };

  adicionaDependentes = (element) => {
    this.state.dependentes.push(element);
  };

  geraNumeroTitulo = () => {
    return (
      this.state.acao_dependentes +
      "-" +
      Numerico(this.state.dependentes.length + 1, 3)
    );
  };

  removeDependentes = async (event) => {
    const lista = this.state.dependentes;
    let indice = lista.findIndex(
      (element) => element.pessoa.value === parseInt(event.target.value)
    );
    lista.splice(indice, 1);
    this.setState({ dependentes: lista });
    await api.post(
      "/pessoa/alterar" +
      requisicoes.entidade +
      "&id=" +
      parseInt(event.target.value),
      { grau_parentesco: null, titular_id: null, acao_id: null },
      requisicoes.header
    );
    await api.post(
      "/historicoDependente/salvar" + requisicoes.entidade,
      {
        acao: Acao.find((e) => e.value === "remover").value,
        motivo: "",
        dependente_id: parseInt(event.target.value),
        titular_id: this.state.pessoa_id,
      },
      requisicoes.header
    );
  };

  visualizacao = async () => {
    if (this.props.location.query !== undefined) {
      const resposta = await api.post(
        "/usuario/porPessoa" + requisicoes.entidade,
        this.props.location.query,
        requisicoes.header
      );
      this.setState(resposta.data);
      //pessoa
      const respPessoa = await api.post(
        "/pessoa/pessoaIdNome" + requisicoes.entidade,
        { id: resposta.data.pessoa_id },
        requisicoes.header
      );
      let p = renomearChave(respPessoa.data, "id", "value");
      p = renomearChave(p, "nome", "label");
      this.setState({ pessoa: p });
      //usuario
      if (resposta.data.tipo_usuario === "dependente") {
        const resPessoa = await api.post(
          "/pessoa/visualiza" + requisicoes.entidade,
          { id: respPessoa.data.id },
          requisicoes.header
        );
        const respPessoaTitular = await api.post(
          "/pessoa/pessoaIdNome" + requisicoes.entidade,
          { id: resPessoa.data.titular_id },
          requisicoes.header
        );
        let p = renomearChave(respPessoaTitular.data, "id", "value");
        p = renomearChave(p, "nome", "label");
        this.setState({ usuario: p });
        this.setState({ usuario_id: p.id });
      }
      if (resposta.data.tipo_usuario === "titular") {
        const resDependentes = await api.post(
          "/pessoa/dependentes" + requisicoes.entidade,
          { titular_id: respPessoa.data.id },
          requisicoes.header
        );
        let listaDependentes = [];
        resDependentes.data.forEach(async (e) => {
          let p = renomearChave(e, "id", "value");
          p = renomearChave(p, "nome", "label");
          await api
            .post(
              "/usuario/usuariosPorPessoaETipo" + requisicoes.entidade,
              {
                pessoa_id: e.id,
                tipo_usuario: "dependente",
                entidade: window.localStorage.getItem("e_clube"),
              },
              requisicoes.header
            )
            .then((ele) => {
              if (ele.data.length > 0) {
                listaDependentes.push({
                  pessoa: p,
                  login: ele.data[0].login,
                  grau_parentesco: e.grau_parentesco,
                });
              }
            });
        });
        this.setState({ dependentes: listaDependentes });
      }
      this.setState({ qrCodeLink: resposta.data.id });
      const respPessoaInfo = await api.post(
        "/pessoa/visualiza" + requisicoes.entidade,
        { id: resposta.data.pessoa_id },
        requisicoes.header
      );
      this.setState({ infoPessoa: respPessoaInfo.data });
      if (
        respPessoaInfo.data.acao !== undefined &&
        respPessoaInfo.data.acao !== null
      ) {
        const respAcao = await api.post(
          "/acao/visualiza" + requisicoes.entidade,
          { id: respPessoaInfo.data.acao.id },
          requisicoes.header
        );
        this.setState({ acao: respAcao.data });
        if (respAcao.data.tipo_titulo !== null) {
          const descricaoTipoTitulo = this.state.listaTipoTitulo.find(
            (e) => parseInt(e.id) === parseInt(respAcao.data.tipo_titulo.id)
          );
          if (descricaoTipoTitulo !== undefined) {
            this.setState({
              tipo_titulo_descricao: descricaoTipoTitulo.descricao,
            });
          }
        }
      }
      const respLancamentos = await api.post(
        "/lancamentoConta/lancamentoPorPessoaTipoEAcao" + requisicoes.entidade,
        {
          pessoa_id: respPessoaInfo.data.id,
          tipo_lancamento: ["mensalidade", "titulo"],
          acao_id: this.state.acao.id,
        },
        requisicoes.header
      );
      this.setState({ lancamentos: respLancamentos.data });
    }
  };

  listaTipoTitulo = async () => {
    const resposta = await api.post(
      "/tipoTitulo/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ listaTipoTitulo: resposta.data });
  };

  alteracao(event) {
    if (event.target.name === "tipo_usuario") {
      this.setState({ tipo_usuario: event.target.value });
    } else if (event.target.name === "tipo_pagamento") {
      this.setState({ [event.target.name]: event.target.value });
      this.geraParcelas(event);
    } else if (event.target.name === "data_emissao") {
      let acao = this.state.acao;
      acao.data_emissao = event.target.value;
      this.setState({ acao: acao });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  setParcelasTitulo = async (parcelas) => {
    this.setState({ infoParcelasTitulo: parcelas });
  };

  setParcelasMensalidade = (parcelas) => {
    this.setState({ infoParcelasMensalidade: parcelas });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let titulo = this.state.listaTipoTitulo.findIndex(
      (e) => e.descricao === this.state.tipo_titulo_descricao
    );
    if (
      this.state.tipo_usuario === "dependente" &&
      this.state.usuario === null
    ) {
      alert("Campo Titular Obrigatório");
    } else if (
      this.state.forma_pagamento === "boleto" &&
      this.state.instituicao_cobranca_id === "" &&
      (this.state.infoParcelasTitulo.length > 0 ||
        this.state.infoParcelasMensalidade.length > 0)
    ) {
      alert(
        "O campo de instituição cobrança é obrigatório ao gerar lançamentos com cobrança boleto."
      );
    } else if (
      this.state.forma_pagamento === "debito_em_conta" &&
      this.state.infoPessoa.debito_conta_id === null
    ) {
      return alert("Cadastre uma conta para utilizar o débito em conta.");
    }
    if (this.state.infoPessoa == null || this.state.infoPessoa === undefined) {
      return alert("Pessoa não validada para cadastro de título.");
    } else if (this.state.acao === null || this.state.acao === undefined) {
      return alert("Antes de salvar você deve criar uma Nova Ação");
    } else if (
      this.state.listaTipoTitulo[titulo].valor_mensalidade === "0.00"
    ) {
      this.submit();
      return;
    } else if (this.state.listaTipoTitulo[titulo].valor_titulo === "0.00") {
      this.submit();
      return;
    } else if (
      (this.state.valor_parcelas_titulo == null ||
        this.state.valor_parcelas_titulo === undefined ||
        this.state.valor_parcelas_titulo <= 0) &&
      this.state.efetuar_cobranca === true
    ) {
      return alert(
        "Não é possível registrar um novo título sem valor de parcelas"
      );
    } else if (
      (this.state.infoParcelasMensalidade == null ||
        this.state.infoParcelasMensalidade === undefined ||
        this.state.infoParcelasMensalidade.length <= 0) &&
      this.state.efetuar_cobranca === true
    ) {
      return alert("Defina a quantidade de parcelas da mensalidade");
    } else if (
      (this.state.infoParcelasTitulo == null ||
        this.state.infoParcelasTitulo === undefined ||
        this.state.infoParcelasTitulo.length <= 0) &&
      this.state.efetuar_cobranca === true
    ) {
      return alert("Defina a quantidade de parcelas do título");
    } else {
      this.submit();
    }
  };

  submit = async () => {
    try {
      var dados = {
        tipo_usuario: this.state.tipo_usuario,
        pessoa_id: this.state.pessoa_id,
        valor_mensalidade: this.state.valor_mensalidade,
        acao_id:
          this.state.acao !== null && this.state.acao !== undefined
            ? this.state.acao.id
            : null,
        cobranca_titulo: this.state.infoParcelasTitulo,
        cobranca_mensalidade: this.state.infoParcelasMensalidade,
      };
      if (this.state.tipo_titulo_descricao === "ASSOCIADO PARTICIPATIVO") {
        const historicoTipoTitulo = await api.post(
          "/historicoTipoTitulo/buscar" + requisicoes.entidade,
          {
            pessoa: this.state.pessoa_id,
            cpf: this.state.infoPessoa.cpf,
          },
          requisicoes.header
        );
        if (historicoTipoTitulo.data.length > 0) {
          alert("Pessoa já cadastrada para ASSOCIADO PARTICIPATIVO.");
          return;
        }
      }
      let pessoa_validada = true;
      ValidadorCamposPessoa(this.state.infoPessoa).map((e) => {
        return Object.entries(e).map((k, i) => {
          if (k[1] === false) {
            pessoa_validada = false;
            alert("Dados da Pessoa São Inválidos");
          }
        });
      });

      if (pessoa_validada === true) {
        if (this.state.tipo_titulo_descricao === "ACESSO TEMPORARIO") {
          dados.tipo_usuario = "acessoTemporario";
        }
        if (this.state.tipo_titulo_descricao === "PROJETO") {
          dados.tipo_usuario = "projeto";
        }
        if (this.state.tipo_titulo_descricao === "ESCOLINHA") {
          dados.tipo_usuario = "escolinha";
        }
        if (
          this.state.login !== null &&
          this.state.senha !== null &&
          (this.state.usuario === null || this.state.usuario === undefined)
        ) {
          dados.login = this.state.login;
          dados.senha = this.state.senha;
          dados.cpf =
            this.state.infoPessoa.cpf !== null
              ? this.state.infoPessoa.cpf
              : null;
          dados.cnpj =
            this.state.infoPessoa.cnpj !== null
              ? this.state.infoPessoa.cnpj
              : null;
          await api
            .post(
              "/usuario/salvar" + requisicoes.entidade,
              dados,
              requisicoes.header
            )
            .catch((err) => {
              this.setState({ mensagem: err.response.data });
              return;
            });
        }
        if (
          this.state.login === this.state.usuario.login &&
          this.state.senha !== this.state.usuario.senha
        ) {
          dados.login = this.state.login;
          dados.senha = this.state.senha;
          dados.id = this.state.usuario.id;
          await api
            .put(
              "/usuario/alterar" + requisicoes.entidade,
              dados,
              requisicoes.header
            )
            .catch((err) => {
              this.setState({ mensagem: err.response.data });
              return;
            });
        }
        if (this.state.mensagem !== null) return;

        await api
          .post(
            "/acao/verificarJaExisteAcao" + requisicoes.entidade,
            {
              codigo: this.state.codigo,
              descricao_tipo_titulo: this.state.listaTipoTitulo.find(
                (e) => parseInt(e.id) === parseInt(this.state.selectTipo)
              ).descricao,
              tipo_titulo_id: this.state.selectTipo,
            },
            requisicoes.header
          )
          .then(async (r) => {
            if (r.data === true) {
              this.setState({ msgCodigoExiste: true });
            } else {
              if (this.state.acao !== null && this.state.acao !== undefined) {
                this.setState({ desabilitar_impressao: true });
                let acao = this.state.acao;
                acao.status = "ativo";
                acao.tipo_titulo_id = parseInt(this.state.selectTipo);
                acao.tipo_titulo_descricao = this.state.listaTipoTitulo.find(
                  (e) => parseInt(e.id) === parseInt(this.state.selectTipo)
                ).descricao;
                if (
                  acao.tipo_titulo_descricao === "MAMPITUBA PRAIA" ||
                  acao.tipo_titulo_descricao === "ACESSO TEMPORARIO" ||
                  acao.tipo_titulo_descricao === "PROJETO" ||
                  acao.tipo_titulo_descricao === "ESCOLINHA" ||
                  acao.tipo_titulo_descricao === "TERCEIRIZADO"
                ) {
                  acao.codigo_auxiliar = this.state.codigo;
                  acao.codigo = null;
                } else {
                  acao.codigo = this.state.codigo;
                }
                if (
                  this.state.tipo_titulo_descricao === "TEMPORÁRIO" ||
                  this.state.tipo_titulo_descricao === "ACESSO TEMPORARIO" ||
                  this.state.tipo_titulo_descricao === "TERCEIRIZADO"
                ) {
                  acao.data_inicial = this.state.data_inicial;
                  acao.data_final = this.state.data_final;
                }
                if (
                  this.state.tipo_titulo_descricao === "MAMPITUBA PRAIA" ||
                  this.state.tipo_titulo_descricao === "PROJETO" ||
                  this.state.tipo_titulo_descricao === "ACESSO TEMPORARIO" ||
                  acao.tipo_titulo_descricao === "ESCOLINHA" ||
                  this.state.tipo_titulo_descricao === "TERCEIRIZADO"
                ) {
                  acao.historico_codigo = this.state.codigo + acao.prefixo;
                  for (let i = 0; i <= 8 - acao.historico_codigo.length; i++) {
                    acao.historico_codigo = "0" + acao.historico_codigo;
                  }
                }
                await api
                  .post(
                    "/acao/salvar" + requisicoes.entidade,
                    acao,
                    requisicoes.header
                  )
                  .then(async (retorno) => {
                    if (retorno.data === "numero_existe") {
                      this.setState({ msgCodigoExiste: true });
                      return;
                    }
                    let acao = retorno.data;
                    let pessoa = {};
                    pessoa.nome = this.state.infoPessoa.nome;
                    pessoa.cpf =
                      this.state.infoPessoa.cpf !== null
                        ? this.state.infoPessoa.cpf
                        : null;
                    pessoa.cnpj =
                      this.state.infoPessoa.cnpj !== null
                        ? this.state.infoPessoa.cnpj
                        : null;
                    pessoa.endereco = this.state.infoPessoa.endereco;
                    pessoa.bairro = this.state.infoPessoa.bairro;
                    pessoa.numero = this.state.infoPessoa.numero;
                    pessoa.numero_dependente =
                      this.state.infoPessoa.numero_dependente;
                    pessoa.acao = acao;
                    pessoa.acao_id = acao.id;
                    pessoa.responsavel1 = this.state.infoPessoa.responsavel1;
                    pessoa.responsavel2 = this.state.infoPessoa.responsavel2;
                    pessoa.dependente_cobrar = this.state.infoPessoa.dependente_cobrar;
                    //setar a acao nos dependentes

                    let listaDependentes = await api.post(
                      "/pessoa/dependentes" + requisicoes.entidade,
                      { titular_id: this.state.pessoa_id },
                      requisicoes.header
                    );
                    for (let i = 0; i < listaDependentes.data.length; i++) {
                      const element = listaDependentes.data[i];
                      await api.post(
                        "/pessoa/alterar" +
                        requisicoes.entidade +
                        "&id=" +
                        element.id,
                        {
                          acao_id: acao.id,
                          novoBarcode: true, //Remover isso quando for refazer em uma rota
                        },
                        requisicoes.header
                      );
                    }
                    //seta ação
                    retorno = await api.post(
                      "/pessoa/alterar" +
                      requisicoes.entidade +
                      "&id=" +
                      this.state.pessoa_id,
                      {
                        titular_id: null,
                        acao_id: acao.id,
                        tipo_cobranca: this.state.forma_pagamento,
                        novoBarcode: true, //Remover isso quando for refazer em uma rota
                      },
                      requisicoes.header
                    );
                    if (retorno !== undefined) {
                      if (this.state.tipo_usuario === "dependente") {
                        retorno = await api.post(
                          "/usuario/visualiza" + requisicoes.entidade,
                          { id: this.state.usuario_id },
                          requisicoes.header
                        );
                        const dadosInserirTitular = {
                          id: this.state.pessoa_id,
                          titular_id: retorno.data.pessoa_id,
                        };
                        retorno = await api.post(
                          "/pessoa/inserirTitular" + requisicoes.entidade,
                          dadosInserirTitular,
                          requisicoes.header
                        );
                      }
                      if (
                        this.state.tipo_titulo_descricao ===
                        "ASSOCIADO PARTICIPATIVO"
                      ) {
                        await api.post(
                          "/historicoTipoTitulo/salvar" + requisicoes.entidade,
                          {
                            data: moment().format("YYYY-MM-DD"),
                            dataExpiracao: moment()
                              .add(4, "Y")
                              .format("YYYY-MM-DD"),
                            pessoa: this.state.pessoa_id,
                            cpf: retorno.data.cpf,
                            usuario: this.state.usuario_id,
                            hora: moment().format("HH:mm:ss"),
                          },
                          requisicoes.header
                        );
                      }
                      await api.post(
                        "/historicoAcao/salvar" + requisicoes.entidade,
                        {
                          tipo_lancamento: "novo",
                          status: "Ativo",
                          acao_id: acao.id,
                          pessoa_compra_id: this.state.pessoa_id,
                          pessoa_venda_id: this.state.pessoa_id,
                        },
                        requisicoes.header
                      );

                      retorno = await api.post(
                        "/usuario/alteraPessoa" + requisicoes.entidade,
                        dados,
                        requisicoes.header
                      );

                      //altera dados da pessoa
                      retorno = await api.post(
                        "/pessoa/alterar" +
                        requisicoes.entidade +
                        "&id=" +
                        this.state.infoPessoa.id,
                        pessoa,
                        requisicoes.header
                      );

                      if (this.state.efetuar_cobranca === true) {
                        //gera cobrança do titulo
                        this.state.infoParcelasTitulo.forEach((e) => {
                          e.valor = ReplaceAll(".", "", e.valor);
                          e.valor = ReplaceAll(",", ".", e.valor);
                          e.valor = parseFloat(e.valor);
                        });
                        this.state.infoParcelasMensalidade.forEach((e) => {
                          e.valor = ReplaceAll(".", "", e.valor);
                          e.valor = ReplaceAll(",", ".", e.valor);
                          e.valor = parseFloat(e.valor);
                        });
                        const dadosCobranca = {
                          pessoa_id: this.state.pessoa_id,
                          acao_id: this.state.acao.id,
                          cobranca_titulo: this.state.infoParcelasTitulo,
                          cobranca_mensalidade: this.state.infoParcelasMensalidade,
                          servico_titulo_id:
                            this.state.acao.tipo_titulo.servico_titulo_id,
                          servico_mensalidade_id:
                            this.state.acao.tipo_titulo.servico_mensalidade_id,
                          instituicao_cobranca_id:
                            this.state.instituicao_cobranca_id,
                          tipo_cobranca: this.state.forma_pagamento,
                        };
                        await api
                          .post(
                            "/usuario/cobranca" + requisicoes.entidade,
                            dadosCobranca,
                            requisicoes.header
                          )
                          .then(async (r) => {
                            if (
                              this.state.forma_pagamento === "boleto" &&
                              this.state.instituicao_cobranca &&
                              !this.state.instituicao_cobranca
                                .registro_automatico
                            ) {
                              await api.post(
                                "/boleto/geraBoleto" + requisicoes.entidade,
                                {
                                  lancamento_contas: r.data,
                                },
                                requisicoes.header
                              );
                              await this.gerarPdfBoletos();
                              this.props.history.push("/administrativo");
                            } else {
                              this.props.history.push("/administrativo");
                            }
                          });
                      }
                    }
                    if (retorno === undefined) {
                      if (retorno.status !== "500") {
                        this.props.history.push("/administrativo");
                      }
                    }
                  });
              }
            }
          });
        alert(
          "Novo título " +
          this.state.tipo_titulo_descricao.toUpperCase() +
          " gerado com sucesso!"
        );
        window.location.href = "/usuarioTitular";
      }
    } catch (err) {
      console.log("usuarioTitular/submit: " + err);
      alert("Erro ao salvar:\n" + err);
    }
  };

  gerarPdfBoletos = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    const r = await api.get("/boleto?e=" + entidade + "&t=Baer " + token);
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL + "boleto?e=" + entidade + "&t=Baer " + token;

    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  pesquisaUsuario = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/usuario/pesquisaTitulares" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaUsuario").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista.push(objeto);
      });
      this.setState({ listaUsuario: lista });
    }, 500);
  };
  alteracaoSelectUsuario = async (event) => {
    this.setState({ usuario_id: event.value });
    this.setState({ usuario: event });
  };

  buscarDependente = async (titular) => {
    let listaDependentes = [];

    const dependente = await api.post(
      "/pessoa/dependentes" + requisicoes.entidade,
      { titular_id: titular },
      requisicoes.header
    );
    dependente.data.forEach(async (e) => {
      let pessoa = renomearChave(e, "id", "value");
      pessoa = renomearChave(pessoa, "nome", "label");
      await api
        .post(
          "/usuario/usuariosPorPessoaETipo" + requisicoes.entidade,
          {
            pessoa_id: e.id,
            tipo_usuario: "dependente",
            entidade: window.localStorage.getItem("e_clube"),
          },
          requisicoes.header
        )
        .then((ele) => {
          if (ele.data.length > 0) {
            listaDependentes.push({
              pessoa: pessoa,
              login: ele.data[0].login,
              grau_parentesco: e.grau_parentesco,
            });
          }
        });
    });
    this.setState({ listaPessoaIdade: listaDependentes });
    this.setState({ dependentes: listaDependentes });
  };

  alteracaoSelectPessoa = async (event) => {
    if (event.acao) {
      if (event.titular_id) {
        alert(
          `Esta pessoa é titular do título: ${event.acao.codigo}.\n` +
          `Caso seu objetivo seja de atribuir uma ação de tipo sócio, acesse o perfil do titular ` +
          `desta pessoa em Meus dados e desvincule ela dos seus dependentes.`
        );
        return;
      } else {
        alert(`Esta pessoa já possui ação vinculada.`);
      }
    }

    this.setState({
      pessoa_id: event.value,
      pessoa: event,
    });

    const resposta = await api.post(
      "/pessoa/visualiza" + requisicoes.entidade,
      { id: event.value },
      requisicoes.header
    );
    this.buscarDependente(resposta.data.id);
    this.setState({ infoPessoa: resposta.data });

    if (resposta.data.cpf !== null) {
      let varCpf = resposta.data.cpf;
      varCpf = ReplaceAll(".", "", varCpf);
      varCpf = ReplaceAll("-", "", varCpf);
      this.setState({
        login: varCpf,
        senha: varCpf,
      });
    } else if (resposta.data.cnpj !== null) {
      let varCnpj = resposta.data.cpf;
      if (varCnpj !== null) {
        varCnpj = ReplaceAll(".", "", varCnpj);
        varCnpj = ReplaceAll("-", "", varCnpj);
        varCnpj = ReplaceAll("/", "", varCnpj);
      }
      this.setState({
        login: varCnpj,
        senha: varCnpj,
      });
    } else {
      this.setState({
        login: null,
        senha: null,
      });
    }

    if (resposta.data.acao !== null) {
      const respAcao = await api.post(
        "/acao/visualiza" + requisicoes.entidade,
        { id: resposta.data.acao.id },
        requisicoes.header
      );
      if (respAcao.data.qtd_transferencias === null) {
        this.setState({ acao_dependentes: respAcao.data.codigo });
      } else {
        this.setState({
          acao_dependentes:
            respAcao.data.codigo +
            "T" +
            Numerico(respAcao.data.qtd_transferencias, 3),
        });
      }

      this.alteraAcao(null, resposta.data.acao.tipo_titulo_id);
      this.setState({ acao: respAcao.data });
    } else {
      this.setState({ acao: null });
    }

    const usuario_resp = await api.post(
      "/usuario/porPessoa" + requisicoes.entidade,
      { id: event.value },
      requisicoes.header
    );
    this.setState({ usuario: usuario_resp.data });
    // if(this.state.options.length === 0) {
    //   let strLoginSenha = ReplaceAll(".","", resposta.data.cpf);
    //   strLoginSenha = ReplaceAll("-","", strLoginSenha);
    //   this.setState({
    //     options: [{
    //       value: parseInt(resposta.data.id),
    //       label: resposta.data.nome
    //     }],
    //     pessoa: {
    //       value: parseInt(resposta.data.id),
    //       label: resposta.data.nome
    //     },
    //     login: strLoginSenha,
    //     senha: strLoginSenha,
    //     pessoa_id: resposta.data.id
    //   });
    // }
  };
  alteracaoSelectDependente = async (event) => {
    const resposta = await api.post(
      "/pessoa/visualiza" + requisicoes.entidade,
      { id: event.value },
      requisicoes.header
    );

    this.setState({
      novo_dependente: resposta.data,
    });
    alert(
      "Nova pessoa cadastrada com sucesso.\nCadastro: " +
      this.state.novo_dependente.nome +
      "\nAo clicar em 'Adicionar', esse cadastro será setado como pré-selecionado."
    );
    //this.handleOpenDependentes();
  };

  alteraEfetuarCobranca = (descricao) => {
    if (
      descricao === "ACESSO TEMPORARIO" ||
      descricao === "PROJETO" ||
      descricao === "ESCOLINHA" ||
      descricao === "TERCEIRIZADO" ||
      descricao === "BENEMERITO" ||
      descricao === "COLUNISTA" ||
      descricao === "CONVIDADO" ||
      descricao === "EMPRESA" ||
      descricao === "ESCOLINHA" ||
      descricao === "ESPECIAL" ||
      descricao === "FUNCIONÁRIO" ||
      descricao === "REMIDO"
    ) {
      this.setState({ efetuar_cobranca: false });
    } else {
      this.setState({ efetuar_cobranca: true });
    }
  };

  novaAcao = async () => {
    const tipoTitulo = this.state.listaTipoTitulo.find(
      (e) => parseInt(e.id) === parseInt(this.state.selectTipo)
    );
    await api
      .post(
        "/acao/codigoSequencial" + requisicoes.entidade,
        {
          tipo_titulo_descricao: tipoTitulo.descricao,
          tipo_titulo_id: tipoTitulo.id,
        },
        requisicoes.header
      )
      .then(async (a) => {
        await api
          .post(
            "/tipoTitulo/visualiza" + requisicoes.entidade,
            { id: this.state.selectTipo },
            requisicoes.header
          )
          .then((t) => {
            let acao = {};

            if (a.data.codigo === undefined) {
              acao.codigo = 1;
              this.setState({ codigo: 1 });
            } else {
              acao.codigo = a.data.codigo;
              this.setState({ codigo: a.data.codigo });
            }

            if (t.data.prefixo !== null) {
              acao.prefixo = t.data.prefixo;
            }

            acao.tipo_titulo = t.data;
            acao.data_emissao = moment().format("YYYY-MM-DD");
            this.setState({ acao: acao });
            this.alteraEfetuarCobranca(tipoTitulo.descricao);
          });
      });
  };

  alteraAcao = async (event, tipo_id) => {
    this.setState({
      selectTipo:
        event != null ? event.target.value : tipo_id != null ? tipo_id : 0,
    });
    const tipoTitulo = this.state.listaTipoTitulo.find(
      (e) =>
        parseInt(e.id) ===
        parseInt(
          event != null ? event.target.value : tipo_id != null ? tipo_id : 0
        )
    );
    // Quando altera o Tipo Título ele altera o código sequencial
    if (
      this.state.tipo_titulo_descricao !== tipoTitulo.descricao &&
      this.state.acao !== null
    ) {
      await api
        .post(
          "/acao/codigoSequencial" + requisicoes.entidade,
          {
            tipo_titulo_descricao: tipoTitulo.descricao,
            tipo_titulo_id: tipoTitulo.id,
          },
          requisicoes.header
        )
        .then(async (a) => {
          let acao = this.state.acao;

          if (a.data.codigo === undefined) {
            acao.codigo = 1;
            this.setState({ codigo: 1 });
          } else {
            acao.codigo = a.data.codigo;
            this.setState({ codigo: a.data.codigo });
          }

          acao.tipo_titulo = tipoTitulo;
          acao.prefixo = tipoTitulo.prefixo;
          acao.data_emissao = moment().format("YYYY-MM-DD");
          this.setState({ acao: acao });
        });
    }

    await api
      .post(
        "/tipotitulo/visualiza" + requisicoes.entidade,
        { id: tipoTitulo.id },
        requisicoes.header
      )
      .then((t) => {
        if (
          t.data.descricao !== "ACESSO TEMPORARIO" &&
          t.data.descricao !== "PROJETO" &&
          t.data.descricao !== "ESCOLINHA" &&
          t.data.descricao !== "TERCEIRIZADO" &&
          t.data.servico_mensalidade === null
        ) {
          return alert(
            "Favor Selecionar o Serviço da Mensalidade na pagina do Tipo de Titulo"
          );
        }
        this.setState({
          valorTitulo: t.data.valor_titulo,
          servico_titulo: t.data.servico_titulo,
          servico_mensalidade: t.data.servico_mensalidade,
        });
      });

    this.setState({ tipo_titulo_descricao: tipoTitulo.descricao });
    if (
      tipoTitulo.descricao === "TEMPORÁRIO" ||
      tipoTitulo.descricao === "ACESSO TEMPORARIO" ||
      tipoTitulo.descricao === "TERCEIRIZADO"
    ) {
      if (this.state.data_inicial === null) {
        const data_inicial = moment();
        this.setState({ data_inicial: data_inicial.format("YYYY-MM-DD") });
      }
      if (tipoTitulo.tempo_titularidade !== null) {
        if (this.state.data_final === null) {
          const data_final = moment().add(
            tipoTitulo.tempo_titularidade,
            "month"
          );
          this.setState({ data_final: data_final.format("YYYY-MM-DD") });
        }
      }
    }

    this.alteraEfetuarCobranca(tipoTitulo.descricao);
  };

  verificarMes = (data) => {
    if (getMonth(data) === 0) {
      data = setMonth(data, 11);
      data = setYear(data, getYear(data) - 1);
      return data;
    } else {
      return data;
    }
  };

  alteraDataDiaUtil(date_param) {
    const date = new Date(date_param);
    if (
      typeof date === "object" &&
      date !== null &&
      "getDay" in date &&
      date.toString().length == 62 &&
      (date.getDay() == 0 || date.getDay() == 6 || checkFeriado(date_param))
    ) {
      const data_prox = moment(
        new Date(proximoDiaUtil(date, this.state.calendarEvents))
      );
      return data_prox;
    }
    return moment(date_param);
  }

  geraParcelas = (event) => {
    try {
      const parcelas = [];
      let dataVencimento = "";
      let diaVencimentoBase = this.state.acao.tipo_titulo.dia_vencimento ?? 7;
      let data_aux = moment();
      const dataHoje = zonedTimeToUtc(new Date(), "America/Sao_Paulo");

      if (event.target.value === "avista") {
        if (getDate(dataHoje) > 15) {
          const setDia = setDate(
            dataHoje,
            this.state.acao.tipo_titulo.dia_vencimento
          );
          dataVencimento = addMonths(setDia, 1);
        } else {
          dataVencimento = setDate(
            dataHoje,
            this.state.acao.tipo_titulo.dia_vencimento
          );
        }
        const mes = getMonth(dataVencimento) + 1;
        dataVencimento = setMonth(dataVencimento, mes + 1);

        if (event.target.name === "forma_pagamento_mensalidade") {
          this.setState({
            infoParcelasMensalidade: [],
            valor_mensalidade: parseFloat(
              this.state.acao.tipo_titulo.valor_mensalidade
            ),
          });
          parcelas.push({
            numero: "Única",
            valor: parseFloat(
              this.state.acao.tipo_titulo.valor_mensalidade
            ).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            data_vencimento: getFormatStringDate(
              moment(proximoDiaUtil(dataVencimento)).format("YYYY-MM-DD")
            ),
          });
          this.setState({ infoParcelasMensalidade: parcelas });
        } else {
          this.setState({
            infoParcelasTitulo: [],
            valor_parcelas_titulo: parseFloat(
              this.state.acao.tipo_titulo.valor_titulo
            ),
          });
          parcelas.push({
            numero: "Única",
            valor: parseFloat(
              this.state.acao.tipo_titulo.valor_titulo
            ).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            data_vencimento: getFormatStringDate(
              moment(proximoDiaUtil(dataVencimento)).format("YYYY-MM-DD")
            ),
          });
          this.setState({ infoParcelasTitulo: parcelas });
        }
      } else {
        const tipoTitulo = this.state.listaTipoTitulo.find(
          (e) => parseInt(e.id) === parseInt(this.state.selectTipo)
        );
        const numeroParcela = event.target.value.toString().replace("x", "");
        let acresMes = 1;
        let idadeCobranca = tipoTitulo.idade_cobranca;
        let porcentagem = tipoTitulo.porcentagem / 100
        let valorMensalidadeTotal = null;
        let valorBaseCaculo = this.state.acao.tipo_titulo.valor_base_calculo
        let valorMensalidade = this.state.acao.tipo_titulo.valor_mensalidade;
        let listaPessoa = this.state.listaPessoaIdade;
        valorMensalidade = parseFloat(valorMensalidade);
        valorBaseCaculo = parseFloat(valorBaseCaculo);
        const dataAtual = moment();
        if (tipoTitulo.cobrar_dependente === true) {
          for (let i = 0; i < listaPessoa.length; i++) {
            const element = listaPessoa[i];
            const idade = dataAtual.diff(element.pessoa.datanascimento, "years");
            if (porcentagem !== 0) {
              if (idade > idadeCobranca) {
                valorMensalidadeTotal += valorBaseCaculo * porcentagem;
              }
            }
          }

          if (valorMensalidadeTotal !== null) {
            valorMensalidade += valorMensalidadeTotal;
          }
        }
        if (event.target.name === "forma_pagamento_mensalidade") {
          for (var i = 1; i < parseInt(numeroParcela) + 1; i++) {
            if (
              getDate(dataHoje) > this.state.acao.tipo_titulo.dia_vencimento &&
              i === 1
            ) {
              const setDia = setDate(
                dataHoje,
                this.state.acao.tipo_titulo.dia_vencimento
              );
              dataVencimento = addMonths(setDia, 1);
              acresMes = acresMes + 1;
            } else {
              const addDia = setDate(
                dataHoje,
                this.state.acao.tipo_titulo.dia_vencimento
              );
              dataVencimento = addMonths(addDia, acresMes);
              acresMes++;
            }

            data_aux = moment(dataVencimento);
            let mes_ref =
              Meses[
                data_aux.get("month") - 1 === -1
                  ? 11
                  : data_aux.get("month") - 1
              ].value;
            let ano_ref =
              data_aux.get("month") - 1 === -1
                ? data_aux.get("year") - 1
                : data_aux.get("year");

            parcelas.push({
              numero: i,
              valor: parseFloat(valorMensalidade).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              data_vencimento: moment(proximoDiaUtil(data_aux)).format(
                "YYYY-MM-DD"
              ),
              mes_referencia: mes_ref,
              ano_referencia: ano_ref,
            });
            data_aux.set("date", diaVencimentoBase);
            data_aux = this.alteraDataDiaUtil(data_aux);
          }
          this.setState({
            infoParcelasMensalidade: parcelas,
            valor_mensalidade:
              parseFloat(valorMensalidade) * parseInt(numeroParcela),
          });
        } else {
          for (var ind = 1; ind < parseInt(numeroParcela) + 1; ind++) {
            if (
              getDate(dataHoje) > this.state.acao.tipo_titulo.dia_vencimento &&
              ind === 1
            ) {
              const setDia = setDate(
                dataHoje,
                this.state.acao.tipo_titulo.dia_vencimento
              );
              dataVencimento = addMonths(setDia, 1);
              acresMes = acresMes + 1;
            } else {
              const addDia = setDate(
                dataHoje,
                this.state.acao.tipo_titulo.dia_vencimento
              );
              dataVencimento = addMonths(addDia, acresMes);
              acresMes++;
            }
            data_aux = moment(dataVencimento);

            parcelas.push({
              numero: ind,
              valor: parseFloat(
                this.state.acao.tipo_titulo.valor_titulo / numeroParcela
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              data_vencimento: moment(proximoDiaUtil(data_aux)).format(
                "YYYY-MM-DD"
              ),
            });
            data_aux.set("date", diaVencimentoBase);
            data_aux = this.alteraDataDiaUtil(data_aux);
          }
          this.setState({
            infoParcelasTitulo: parcelas,
            valor_parcelas_titulo: parseFloat(
              this.state.acao.tipo_titulo.valor_titulo
            ),
          });
        }
      }
    } catch (error) {
      console.log("usuarioTitular/geraParcelas: " + error);
    }
  };

  alteraValorParcelas = (event) => {
    let lista = [];
    const indice = event.target.id.toString().substring(12, 14);
    if (event.target.name === "valorParcelaMensalidade") {
      lista = this.state.infoParcelasMensalidade;
      lista.splice(parseInt(indice), 1, {
        numero: parseInt(indice) + 1,
        valor: event.target.value,
        data_vencimento: lista[parseInt(indice)].data_vencimento,
        mes_referencia: lista[parseInt(indice)].mes_referencia,
        ano_referencia: lista[parseInt(indice)].ano_referencia,
      });
    } else {
      lista = this.state.infoParcelasTitulo;
      lista.splice(parseInt(event.target.id.toString().substring(18, 20)), 1, {
        numero: parseInt(event.target.id.toString().substring(18, 20)) + 1,
        valor: event.target.value,
      });
    }

    if (lista.find((e) => e.valor.toString() === "NaN")) {
      lista.forEach((element) => {
        if (element.valor.toString() === "NaN") {
          element.valor = "";
        }
      });
    }
    if (event.target.name === "valorParcelaMensalidade") {
      this.setState({ infoParcelasMensalidade: lista });
    } else {
      this.setState({ infoParcelasTitulo: lista });
    }
  };

  alteraDataVencimentoParcelas = (event) => {
    let lista = [];
    const indice = event.target.id.toString().substring(25, 27);
    if (event.target.name === "dataVencimentoParcelaMensalidade") {
      lista = this.state.infoParcelasMensalidade;
      if (event.target.value !== "") {
        lista.splice(parseInt(indice), 1, {
          numero: parseInt(indice) + 1,
          valor: lista[parseInt(indice)].valor,
          data_vencimento: event.target.value,
          mes_referencia: lista[parseInt(indice)].mes_referencia,
          ano_referencia: lista[parseInt(indice)].ano_referencia,
        });
      }
    } else {
      lista = this.state.infoParcelasTitulo;
      if (event.target.value !== "") {
        lista.splice(
          parseInt(event.target.id.toString().substring(20, 22)),
          1,
          {
            numero: parseInt(event.target.id.toString().substring(20, 22)) + 1,
            valor:
              lista[parseInt(event.target.id.toString().substring(20, 22))]
                .valor,
            data_vencimento: event.target.value,
          }
        );
      }
    }
    if (event.target.name === "dataVencimentoParcelaMensalidade") {
      this.setState({ infoParcelasMensalidade: lista });
    } else {
      this.setState({ infoParcelasTitulo: lista });
    }
  };

  alteracaoMesReferencia = (event) => {
    let lista = this.state.infoParcelasMensalidade;
    const indice = event.target.name.toString().substring(13, 15);
    lista.splice(indice, 1, {
      numero: parseInt(indice) + 1,
      valor: lista[parseInt(indice)].valor,
      data_vencimento: lista[parseInt(indice)].data_vencimento,
      mes_referencia: event.target.value,
      ano_referencia: lista[parseInt(indice)].ano_referencia,
    });
    this.setState({ infoParcelasMensalidade: lista });
  };

  alteracaoAnoReferencia = (event) => {
    let lista = this.state.infoParcelasMensalidade;
    const indice = event.target.name.toString().substring(13, 15);
    lista.splice(indice, 1, {
      numero: parseInt(indice) + 1,
      valor: lista[parseInt(indice)].valor,
      data_vencimento: lista[parseInt(indice)].data_vencimento,
      mes_referencia: lista[parseInt(indice)].mes_referencia,
      ano_referencia: event.target.value,
    });
    this.setState({ infoParcelasMensalidade: lista });
  };

  pesquisaPessoa = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/pessoa/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaPessoa").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista.push(objeto);
      });
      this.setState({ options: lista });
    }, 500);
  };

  desvincularAcao = async () => {
    this.state.desvinculandoAcao = true;
    await api.post(
      "/pessoa/alterar" +
      requisicoes.entidade +
      "&id=" +
      this.state.infoPessoa.id,
      {
        acao_id: null,
      },
      requisicoes.header
    );

    await api.post(
      "/historicoAcao/salvar" + requisicoes.entidade,
      {
        tipo_lancamento: "desvincularAcao",
        acao_id: this.state.acao.id,
        data_transferencia: moment().format("YYYY-MM-DD"),
        motivo: "Desvinculaçao de título " + this.state.acao.status,
        pessoa_compra_id: this.state.infoPessoa.id, //Salvar quem tem a ação até o momento.
        // pessoa_venda_id: this.state.pessoa_venda_id
      },
      requisicoes.header
    );
    this.state.desvinculandoAcao = false;
    window.location.reload();
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Usuario
        </Header>

        {this.state.mensagem !== null && (
          <Alert variant="danger">{this.state.mensagem}</Alert>
        )}
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={6}>
              <Form.Label>Tipo Título</Form.Label>
              <Form.Control
                placeholder="TipoTitulo"
                label="TipoTitulo"
                id="TipoTitulo"
                as="select"
                name="tipoTitulo"
                onChange={this.alteraAcao}
                checked={this.state.selectTipo}
                disabled={this.props.location.query !== undefined}
                required
              >
                <option></option>
                {this.state.listaTipoTitulo.map((e, i) =>
                  this.state.acao !== null &&
                    this.state.acao.tipo_titulo !== null &&
                    this.state.acao.tipo_titulo.id === e.id ? (
                    <option selected value={e.id}>
                      {e.descricao}
                    </option>
                  ) : (
                    <option key={i} value={e.id}>
                      {e.descricao}
                    </option>
                  )
                )}
              </Form.Control>
            </Col>
            <Col sm={6}>
              <Form.Label>Perfil</Form.Label>
              <Form.Control
                placeholder="Tipo"
                as="select"
                name="tipo_usuario"
                onChange={this.alteracao}
                checked={this.state.selectTipo}
                required
                value="titular"
                disabled
              >
                <option></option>
                <option value="titular">Titular</option>
              </Form.Control>
            </Col>

            <Col sm={6}>
              <Form.Label>Pessoa</Form.Label>
              <Select
                placeholder="Digite para buscar"
                name="pessoa"
                value={this.state.pessoa}
                onChange={this.alteracaoSelectPessoa}
                options={this.state.options}
                onKeyDown={this.pesquisaPessoa}
                inputId="pesquisaPessoa"
                isDisabled={this.state.id !== null}
              />
            </Col>

            {this.props.location.query === undefined && (
              <Col sm={6} style={{ display: "flex", alignItems: "flex-end" }}>
                <Button
                  color="blue"
                  onClick={() => {
                    window.location.href = "/pessoa?from=usuarioTitular";
                  }}
                >
                  Cadastrar Pessoa
                </Button>
                {/* Fiz assim pq o Link deixava a pagina em branco */}
              </Col>
            )}

            {/* <Col sm={6}>
              <Link to={{ pathname: "/pessoa" }}>
                <Button positive icon="add" size="tiny"></Button>
              </Link>
            </Col> */}

            <Col sm={6}>
              <Form.Label>Login</Form.Label>
              <FormControl
                placeholder="Login"
                aria-label="Login"
                aria-describedby="basic-addon1"
                name="login"
                onChange={this.alteracao}
                value={this.state.login}
                disabled={this.props.location.query !== undefined}
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Senha</Form.Label>
              <FormControl
                placeholder="Senha"
                aria-label="Senha"
                type="password"
                aria-describedby="basic-addon1"
                name="senha"
                onChange={this.alteracao}
                value={this.state.senha}
                disabled={this.props.location.query !== undefined}
              />
            </Col>

            {this.state.infoPessoa !== null && (
              <Col sm={12} style={{ paddingTop: "10px" }}>
                <Col sm={3}>
                  <Header as="h2">Dados Cadastrais</Header>
                </Col>
                <Divider />

                <VisualizaPessoa
                  pessoa={this.state.infoPessoa}
                  numeroDependente={this.numeroDependente}
                  alteracaoPessoa={this.alteracaoPessoa}
                  tipo_titulo_descricao={this.state.tipo_titulo_descricao}
                />

                <Segment.Group horizontal>
                  <Segment>
                    <b>Tipo:</b> {this.state.infoPessoa.tipo}
                  </Segment>
                  <Segment>
                    <b>Telefone:</b> {this.state.infoPessoa.telefone}
                  </Segment>
                  <Segment>
                    <b>E-mail:</b> {this.state.infoPessoa.email}
                  </Segment>
                </Segment.Group>
              </Col>
            )}

            {this.state.tipo_titulo_descricao !== null &&
              (this.state.tipo_titulo_descricao === "TEMPORÁRIO" ||
                this.state.tipo_titulo_descricao === "ACESSO TEMPORARIO" ||
                this.state.tipo_titulo_descricao === "TERCEIRIZADO") && (
                <Col sm={12} className="row" style={{ paddingTop: "10px" }}>
                  <Col sm={12}>
                    <Header as="h2">Tempo de Titularidade</Header>
                    <Divider />
                  </Col>
                  <Col sm={12} className="row">
                    <Col sm={4}>
                      <Form.Label>Data Inicial</Form.Label>
                      <FormControl
                        type="date"
                        name="data_inicial"
                        onChange={this.alteracao}
                        value={this.state.data_inicial}
                      />
                    </Col>
                    <Col sm={4}>
                      <Form.Label>Data Final</Form.Label>
                      <FormControl
                        type="date"
                        name="data_final"
                        onChange={this.alteracao}
                        value={this.state.data_final}
                      />
                    </Col>
                  </Col>
                </Col>
              )}

            {this.state.infoPessoa !== null &&
              this.state.tipo_titulo_descricao !== null &&
              this.state.tipo_titulo_descricao !==
              "Contribuinte Sem Dependente" && (
                <Col
                  sm={12}
                  style={{
                    paddingTop: "10px",
                  }}
                >
                  <Header as="h2">Ação/Titulo</Header>
                  <Divider />
                  {this.state.acao === null ? (
                    <Button
                      positive
                      icon="add"
                      size="tiny"
                      onClick={this.novaAcao}
                      type="button"
                    >
                      Nova Ação
                    </Button>
                  ) : (
                    <Row>
                      <Col sm={12}>
                        {this.state.msgCodigoExiste === true && (
                          <Alert variant="primary">
                            Código do título já cadastrado
                          </Alert>
                        )}
                        <Segment.Group>
                          <Segment.Group horizontal>
                            <Segment>
                              <Row>
                                <Col sm={6}>
                                  <b>Codigo:</b>
                                  <FormControl
                                    aria-describedby="basic-addon1"
                                    name="codigo"
                                    onChange={this.alteracao}
                                    required
                                    value={
                                      this.state.acao.prefixo !== undefined &&
                                        this.state.acao.prefixo !== null
                                        ? this.state.acao.prefixo +
                                        " " +
                                        this.state.acao.codigo
                                        : this.state.codigo
                                    }
                                    disabled={
                                      this.state.efetuar_cobranca === false
                                    }
                                  />
                                </Col>
                                {this.state.acao.status === "cancelado" ||
                                  this.state.acao.status === "inativo" ||
                                  this.state.acao.status === "transferido" ? (
                                  <Col sm={6} style={{ paddingTop: 17 }}>
                                    <Button
                                      color="red"
                                      onClick={this.desvincularAcao}
                                      loading={this.state.desvinculandoAcao}
                                    >
                                      Desvincular ação
                                    </Button>
                                  </Col>
                                ) : null}
                              </Row>
                            </Segment>
                            <Segment>
                              <b>{"Valor Mensalidade: "}</b>R$
                              {this.state.servico_mensalidade !== null &&
                                parseInt(this.state.servico_mensalidade.valor) > 0
                                ? this.state.servico_mensalidade.valor
                                : " 0"}
                              ,00
                              <br />
                              <br />
                              <br />
                              <br />
                              <b>{"Status: "}</b>
                              <span
                                style={{
                                  color:
                                    this.state.acao.status === "cancelado" ||
                                      this.state.acao.status === "inativo" ||
                                      this.state.acao.status === "transferido"
                                      ? "red"
                                      : "black",
                                }}
                              >
                                {this.state.acao.status !== undefined
                                  ? this.state.acao.status
                                    .charAt(0)
                                    .toUpperCase() +
                                  this.state.acao.status.slice(1)
                                  : ""}
                              </span>
                            </Segment>
                            <Segment>
                              <b>Data Emissão:</b>{" "}
                              <FormControl
                                aria-describedby="basic-addon1"
                                type="date"
                                name="data_emissao"
                                onChange={this.alteracao}
                                disabled={this.state.efetuar_cobranca === false}
                                value={
                                  this.state.acao.data_emissao === null
                                    ? ""
                                    : this.state.acao.data_emissao
                                      .toString()
                                      .substr(0, 10)
                                }
                              />
                              <br />
                              <b>Valor Título: </b>R$
                              {this.state.servico_titulo !== null &&
                                parseInt(this.state.servico_titulo.valor) > 0
                                ? this.state.valorTitulo
                                : " 0"}
                            </Segment>
                          </Segment.Group>
                        </Segment.Group>

                        {this.state.lancamentos.length > 0 && (
                          <Segment.Group>
                            <Segment.Group horizontal>
                              <Segment>
                                <Col sm={12} style={{ textAlign: "center" }}>
                                  <Header as="h4">Cobrança:</Header>
                                </Col>
                                <Col sm={12} style={{ textAlign: "left" }}>
                                  <Header as="h5">Titulo</Header>
                                </Col>
                                <Row>
                                  <Col sm={6}>
                                    {this.state.lancamentos.map((l, i) => {
                                      return (
                                        l.tipo_lancamento === "titulo" && (
                                          <Col sm={6} key={i}>
                                            <Row>
                                              <Col sm={6}>
                                                Parcela {l.numero_parcela}
                                              </Col>
                                              <Col sm={6}>
                                                R${" "}
                                                {parseFloat(
                                                  l.valor
                                                ).toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })}
                                              </Col>
                                            </Row>
                                          </Col>
                                        )
                                      );
                                    })}
                                  </Col>
                                  <Col sm={6}>
                                    {this.state.lancamentos.map((l, i) => {
                                      return (
                                        l.tipo_lancamento === "mensalidade" && (
                                          <Col sm={6} key={i}>
                                            <Row>
                                              <Col sm={6}>
                                                Parcela {l.numero_parcela}
                                              </Col>
                                              <Col sm={6}>
                                                R${" "}
                                                {parseFloat(
                                                  l.valor
                                                ).toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })}
                                              </Col>
                                            </Row>
                                          </Col>
                                        )
                                      );
                                    })}
                                  </Col>
                                </Row>
                              </Segment>
                            </Segment.Group>
                          </Segment.Group>
                        )}

                        {this.props.location.query === undefined &&
                          this.state.efetuar_cobranca === true &&
                          // parseInt(this.state.servico_titulo?.valor) > 0 && //Titulo temporario nao tem valordo titulo
                          parseInt(this.state.servico_mensalidade?.valor) >
                          0 && (
                            <>
                              {this.state.tipo_titulo_descricao !==
                                "CONTRIBUINTE SEM DEPENDENTES" &&
                                this.state.tipo_titulo_descricao !==
                                "ACESSO TEMPORARIO" &&
                                this.state.tipo_titulo_descricao !==
                                "PROJETO" &&
                                this.state.tipo_titulo_descricao !==
                                "ESCOLINHA" &&
                                this.state.tipo_titulo_descricao !==
                                "TERCEIRIZADO" && (
                                  <ListaDependentes
                                    dependentes={this.state.dependentes}
                                    removeDependentes={this.removeDependentes}
                                    handleOpenDependentes={
                                      this.handleOpenDependentes
                                    }
                                  />
                                )}
                              <Cobranca
                                acao={this.state.acao}
                                setParcelasTitulo={this.setParcelasTitulo}
                                setParcelasMensalidade={
                                  this.setParcelasMensalidade
                                }
                                infoParcelasTitulo={
                                  this.state.infoParcelasTitulo
                                }
                                infoParcelasMensalidade={
                                  this.state.infoParcelasMensalidade
                                }
                                servico_titulo={this.state.servico_titulo}
                                servico_mensalidade={
                                  this.state.servico_mensalidade
                                }
                                alteracao={this.alteracao}
                                forma_pagamento={this.state.forma_pagamento}
                                instituicao_cobranca_id={
                                  this.state.instituicao_cobranca_id
                                }
                                instituicao_cobranca={
                                  this.state.instituicao_cobranca
                                }
                                infoPessoa={this.state.infoPessoa}
                                tipoTituloDescricao={
                                  this.state.tipo_titulo_descricao
                                }
                                geraParcelas={this.geraParcelas}
                              />
                            </>
                          )}
                        {this.state.tipo_titulo_descricao !==
                          "CONTRIBUINTE SEM DEPENDENTES" &&
                          this.state.tipo_titulo_descricao !==
                          "ACESSO TEMPORARIO" &&
                          this.state.tipo_titulo_descricao !== "PROJETO" &&
                          this.state.tipo_titulo_descricao !== "ESCOLINHA" &&
                          this.state.tipo_titulo_descricao !==
                          "TERCEIRIZADO" && (
                            <ModalDependente
                              open_modal_dependentes={
                                this.state.open_modal_dependentes
                              }
                              handleCloseDependentes={
                                this.handleCloseDependentes
                              }
                              adicionaDependentes={this.adicionaDependentes}
                              titular_id={this.state.pessoa_id}
                              numero_titulo={this.state.numero_titulo}
                              tipoTitulo={this.state.tipoTitulo}
                              acao_id={this.state.acao.id}
                              pessoa={this.state.novo_dependente}
                              infoPessoa={this.state.novo_dependente}
                            />
                          )}
                      </Col>
                    </Row>
                  )}
                </Col>
              )}
          </Row>
          <Divider />
          <Segment clearing>
            <Header floated="right">
              <Link to="/administrativo">
                <Button type="button">Voltar</Button>
              </Link>
              {this.props.location.query === undefined && (
                <Button
                  positive
                  type="submit"
                  disabled={
                    this.state.desabilitar_impressao === true ||
                    this.state.pessoa == null ||
                    this.state.pessoa === undefined ||
                    this.state.selectTipo == null ||
                    this.state.selectTipo === undefined
                  }
                >
                  Salvar
                </Button>
              )}
            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}
export default withRouter(Usuario);
