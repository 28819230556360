import React, { Component } from "react";

import { Modal, Checkbox } from "semantic-ui-react";
import { Row, Col } from "react-bootstrap";

import Button from "@material-ui/core/Button";

import IconButton from "@material-ui/core/IconButton";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import SendIcon from "@material-ui/icons/Send";

import "../styles.css";

export default class Competicao extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal dimmer="bluring" open={this.props.modalExclusao}>
        <Modal.Header>
          {"Excluir atirador da Etapa " + this.props.numero_etapa}
          <Button
            style={{ outline: "none" }}
            onClick={() => this.props.changeModalExclusao(false, "", 0)}
            color="secondary"
          >
            Fechar
          </Button>
        </Modal.Header>
        <Modal.Content>
          <Alert severity="success" hidden={this.props.alertaExclusao === true}>
            <AlertTitle>Atirador Excluído!</AlertTitle>
            Iremos redirecionar-lhe — <strong>Aguarde!</strong>
          </Alert>

          <Alert severity="error" hidden={this.props.alertaExclusao === false}>
            <AlertTitle>ATENÇÃO!</AlertTitle>
            {this.props.nomeExclusao} será <strong>excluído</strong> e não
            poderá ser recuperado nenhuma informação!
          </Alert>

          <Row>
            <Col sm={12}>
              <div className="checkModalAjuste">
                <div className="CheckBoxModal">
                  <Checkbox
                    root
                    name="checkEtapa"
                    id="checkEtapa"
                    onClick={this.props.checkboxExclusao}
                  />
                  <div>
                    <h6> Estou ciente!</h6>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Content>
        <Modal.Actions>
          <IconButton
            style={{ outline: "none" }}
            color="primary"
            aria-label="add to shopping cart"
            type="button"
            disabled={this.props.checkExclusao === false}
            onClick={this.props.excluirAtirador}
          >
            <SendIcon />
          </IconButton>
        </Modal.Actions>
      </Modal>
    );
  }
}
