const cargos = [
  { key: "funcionario", value: "funcionario", text: "Funcionario" },
  { key: "diretoria", value: "diretoria", text: "Diretoria" },
  {
    key: "conselhoDeliberativo",
    value: "conselhoDeliberativo",
    text: "Conselho Deliberativo",
  },
  { key: "conselhoFiscal", value: "conselhoFiscal", text: "Conselho Fiscal" },
];
export default cargos;
