/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import Select from "react-select";
import { Form, Col, Row } from "react-bootstrap";

import { Table, Checkbox } from "semantic-ui-react";
import {
  Alert,
  Snackbar,
  Button,
  IconButton,
  TextField,
  CircularProgress,
  Pagination,
} from "@material-ui/core/";
import { LoadingButton, Autocomplete } from "@material-ui/lab";
import { Cancel } from "@material-ui/icons";

//utils
import renomearChave from "../../../services/renomearChave";

//requisição
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";
let buscando = false;
export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Inputs options
      pessoas: [],
      modalidades: [],
      tipoVinculos: [],
      pessoa: null,

      // Inputs value
      pessoaSelecionadaNome: "",
      modalidadeSelecionadaNome: "",
      tipoVinculoSelecionadoDescricao: "",
      pessoaSelecionadaId: null,
      modalidadeSelecionadaId: null,
      tipoVinculoSelecionadoId: null,
      acaoPessoa: null,
      tipo_vinculo_id: 0,
      checkboxSocio: true,
      checkboxAtivo: true,

      // Listas
      listaPessoa: [], // Lista de dados da tela
      pessoasCadastradas: [], // Lista de pessoas a serem cadastradas
      deleteListaModalidadeDesporto: [],
      listaTeste: [],
      // listaAux: [], // Lista de pessoas a serem cadastradas

      // Paginação

      activePages: 1,
      totalPaginas: 0,

      // Alerta
      mensagemSnackBar: { show: false },
      salvando: false,
      adicinando: false,
    };
    this.listaGrupoServicoDesporto();
    this.listaTipoVinculo();
  }

  pesquisaPessoaAcao = async () => {
    if (buscando === true) {
      return;
    }
    buscando = true;
    this.setState({ buscando: true });

    setTimeout(async () => {
      let term = document.getElementById("pesquisaPessoa");

      if (term === null) {
        buscando = false;
        this.setState({ buscando: false });
        return;
      }
      term = term.value;

      if (term.trim() === "") {
        buscando = false;
        this.setState({ buscando: false });
        return;
      }

      let prefixo = null;
      let tipoTituloBusca = null;
      for (let i = 0; i < this.state.listaTipoTitulo.length; i++) {
        const element = this.state.listaTipoTitulo[i];
        if (
          element.prefixo !== null &&
          term.toUpperCase().includes(element.prefixo) &&
          term.slice(0, element.prefixo.length).toUpperCase() ===
            element.prefixo
        ) {
          prefixo = element.prefixo;
          tipoTituloBusca = element.id;
          break;
        }
      }

      if (prefixo !== null) {
        term = term.slice(prefixo.length);
        term = term.trim();
      }
      if (term.trim() === "") {
        buscando = false;
        this.setState({ buscando: false });
        return;
      }
      if (isFinite(String(term))) {
        await api
          .post(
            "/pessoa/pesquisaPorCodigoAcaoValorExato" + requisicoes.entidade,
            {
              pesquisa: term,
              tipo_titulo: tipoTituloBusca,
              flag_codigo_aux: tipoTituloBusca !== null,
              socio: this.state.checkboxSocio,
              ativo: this.state.checkboxAtivo,
            },
            requisicoes.header
          )
          .then((retorno) => {
            this.setState({
              options: this.converterParaLabel(retorno.data, prefixo),
            });
          });
      } else {
        await api
          .post(
            "/pessoa/pesquisa" + requisicoes.entidade,
            {
              pesquisa: term,
              tipo_titulo: tipoTituloBusca,
              socio: this.state.checkboxSocio,
              ativo: this.state.checkboxAtivo,
            },
            requisicoes.header
          )
          .then((retorno) => {
            this.setState({
              // options: this.converterParaLabelPessoa(retorno.data, prefixo),
              options: this.converterParaLabel(retorno.data, prefixo, term),
            });
          });
      }
      buscando = false;
      this.setState({ buscando: false });
    }, 2000);
  };

  pesquisaPessoa = async () => {
    let term = "";
    if (this.state.buscando) {
      return;
    }
    this.setState({ buscando: true });

    setTimeout(async () => {
      term = document.getElementById("pessoas");

      if (term === null) {
        this.state.buscando = false;
        this.setState({ buscando: false });
        return;
      }
      term = term?.value;
      if (term.trim() === "") {
        buscando = false;
        this.setState({ buscando: false });
        return;
      }

      let prefixo = null;
      let tipoTituloBusca = null;
      let resposta = [];
      if (isFinite(String(term))) {
        resposta = await api.post(
          "/pessoa/pesquisaPorCodigoAcaoValorExato" + requisicoes.entidade,
          {
            pesquisa: term,
            tipo_titulo: tipoTituloBusca,
            flag_codigo_aux: tipoTituloBusca !== null,
            socio: this.state.checkboxSocio,
            ativo: this.state.checkboxAtivo,
          },
          requisicoes.header
        );
      } else {
        resposta = await api.post(
          "/pessoa/pesquisa" + requisicoes.entidade,
          {
            pesquisa: term,
            socio: this.state.checkboxSocio,
            ativo: this.state.checkboxAtivo,
          },
          requisicoes.header
        );
      }
      let lista = [];
      for (let i = 0; i < resposta.data.length; i++) {
        const element = resposta.data[i];

        let descricao = "";
        if (element.titular_id === null) {
          descricao = "Titular";
        } else {
          descricao = "Dependente";
        }
        let objeto = {
          value: element.id,

          label: element.acao?.codigo
            ? element.acao?.codigo + " - " + element.nome + " - " + descricao
            : element.acao?.codigo_auxiliar +
              " - " +
              element.nome +
              " - " +
              descricao,

          acao: element.acao?.codigo
            ? element.acao?.codigo
            : element.acao?.codigo_auxiliar,
        };
        lista.push(objeto);
      }
      this.setState({ pessoas: lista, buscando: false });
    }, 3000);
  };

  alteracaoSelectPessoa = async (event, option) => {
    if (option !== null && option !== undefined) {
      this.setState({ pessoa: option });
      this.setState({ pessoa_id: option.value });
    } else {
      this.setState({ pessoa: event });
      this.setState({ pessoa_id: event.value });
    }
    this.setState({
      pessoaSelecionadaId: option ? option.value : null,
      pessoaSelecionadaNome: option ? option?.label : "",
      acaoPessoa: option?.acao,
    });
  };

  alteracaoSelectModalidades = async (event, activePage = 1, tipoVinculoId) => {
    let modalidade_desporto_id =
      event && event.value ? event.value : this.state.modalidadeSelecionadaId;
    let tipo_vinculo_id =
      tipoVinculoId || tipoVinculoId === 0
        ? tipoVinculoId
        : this.state.tipo_vinculo_id;
    activePage = 1;
    if (event) {
      this.setState({
        modalidadeSelecionadaId: event.value,
        modalidadeSelecionadaNome: event.label,
      });
    }
    this.setState({
      tipo_vinculo_id,
    });

    const pessoasPorModalidade = await api.post(
      "/pessoaModalidadesDesporto/listarPorModalidade" + requisicoes.entidade,
      {
        modalidade_desporto_id: modalidade_desporto_id,
        activePages: activePage,
        tipo_vinculo_id: tipo_vinculo_id,
      },
      requisicoes.header
    );
    let listaAux = [];

    for (let i = 0; i < pessoasPorModalidade.data.docs.length; i++) {
      const element = pessoasPorModalidade.data.docs[i];

      listaAux.push({
        id: element.id,
        pessoa_id: element.pessoa_id,
        modalidade_desporto_id: element.modalidade_desporto_id,
        nome: element.pessoa.nome,
        nome_tipo_vinculo: element.tipo_vinculo.descricao,
        tipo_vinculo_id: element.tipo_vinculo_id,
        acao: element.pessoa.acao?.codigo
          ? element.pessoa.acao?.codigo
          : element.pessoa.acao?.codigo_auxiliar,
      });
    }

    this.setState({
      pessoasCadastradas: listaAux,
      listaPessoa: [],
      totalPaginas: pessoasPorModalidade.data.pages,
      activePages: activePage,
      pagina: pessoasPorModalidade.data.pages,
    });
  };
  alteracaoSelectTipoVinculo = async (event) => {
    this.setState({ tipoVinculoSelecionadoId: event.value });
    this.setState({ tipoVinculoSelecionadoDescricao: event.label });
  };

  listaGrupoServicoDesporto = async (newValue) => {
    const resp = await api.post(
      "/modalidadeDesporto/listar" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    let lista = [];

    resp.data.forEach((e) => {
      let objeto = { value: e.id, label: e.nome };
      lista.push(objeto);
    });
    function ordena(a, b) {
      if (a.label > b.label) {
        return 1;
      }
      if (a.label < b.label) {
        return -1;
      }
      return 0;
    }

    lista.sort(ordena);
    this.setState({ modalidades: lista });
  };

  listaTipoVinculo = async (newValue) => {
    const resp = await api.post(
      "/tipoVinculo/listar" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    let lista = [];

    resp.data.forEach((e) => {
      let objeto = {
        value: e.id,
        label: e.descricao,
      };
      lista.push(objeto);
    });

    this.setState({ tipoVinculos: lista });
  };

  salvar = async (event) => {
    event.preventDefault();

    setTimeout(async () => {
      try {
        this.setState({ salvando: true });

        if (this.state.listaPessoa.length > 0) {
          await api.post(
            "/pessoaModalidadesDesporto/salvar" + requisicoes.entidade,
            this.state.listaPessoa,
            requisicoes.header
          );
        }
        if (this.state.deleteListaModalidadeDesporto.length > 0) {
          await api.post(
            "/pessoaModalidadesDesporto/excluir" + requisicoes.entidade,
            this.state.deleteListaModalidadeDesporto,
            requisicoes.header
          );
        }
        this.setState({
          deleteListaModalidadeDesporto: [],
          listaPessoa: [],
          pessoasCadastradas: [
            ...this.state.listaPessoa,
            ...this.state.pessoasCadastradas,
          ],
        });
        this.mensagem(true, "Cadastrado Com sucesso", 3000);
      } catch (error) {
        if (error.response.data !== "") {
          this.mensagem(false, error.response.data, 4500);
        } else {
          this.mensagem(false, "Ocorreu um erro ao salvar o registro.", 4500);
        }
      } finally {
        this.setState({
          salvando: false,
          adicinando: false,
          pessoasCadastradas: this.state.pessoasCadastradas,
        });
      }
    }, 800);
  };

  deleteLista = async (event) => {
    try {
      let index = this.state.pessoasCadastradas.indexOf(event);
      let indexB = this.state.listaPessoa.indexOf(event);

      if (index !== -1) {
        this.state.pessoasCadastradas.splice(index, 1);
        this.state.listaPessoa.splice(indexB, 1);
        this.state.deleteListaModalidadeDesporto.push(event);
      }
      if (!window.confirm("Tem certeza que deseja excluir a pessoa?")) {
        return;
      }
      await api.post(
        "/pessoaModalidadesDesporto/excluir" + requisicoes.entidade,
        this.state.deleteListaModalidadeDesporto,
        requisicoes.header
      );
      this.setState({
        pessoasCadastradas: this.state.pessoasCadastradas,
        adicinando: true,
      });
    } catch (error) {
      this.setState({ adicinando: false });
      console.error("Error", error);
    }
  };

  adicionaListaModalidade = async () => {
    try {
      this.state.adicinando = true;
      let listaAdiciona = {
        pessoa_id: this.state.pessoaSelecionadaId,
        nome: this.state.pessoaSelecionadaNome,
        tipo_vinculo_id: this.state.tipoVinculoSelecionadoId,
        nome_tipo_vinculo: this.state.tipoVinculos[
          this.state.tipoVinculoSelecionadoId - 1
        ].label,
        modalidade_desporto_id: this.state.modalidadeSelecionadaId,
        acao: this.state.acaoPessoa,
      };
      //verifica o que foi passado esta na lista  se nao tiver retorna -1
      let index = this.state.pessoasCadastradas.findIndex(
        (e) =>
          listaAdiciona.pessoa_id === e.pessoa_id &&
          listaAdiciona.tipo_vinculo_id === e.tipo_vinculo_id
      );

      // Se ele exclui e adicionou o mesmo elemento
      let elementListaExcluido = null;
      if (this.state.deleteListaModalidadeDesporto.length > 0) {
        let indexB = this.state.deleteListaModalidadeDesporto.findIndex(
          (e) =>
            listaAdiciona.pessoa_id === e.pessoa_id &&
            listaAdiciona.tipo_vinculo_id === e.tipo_vinculo_id
        );

        if (indexB !== -1) {
          elementListaExcluido = this.state.deleteListaModalidadeDesporto[
            indexB
          ];
          this.state.deleteListaModalidadeDesporto.splice(indexB, 1);
        }
      }

      let indexListaPessoa = this.state.listaPessoa.findIndex(
        (e) =>
          listaAdiciona.pessoa_id === e.pessoa_id &&
          listaAdiciona.tipo_vinculo_id === e.tipo_vinculo_id
      );

      // se não existir na lista ele adiciona
      if (index === -1 && indexListaPessoa === -1) {
        //verifica se ja tem o mesmo item excluido e adicinando
        if (elementListaExcluido === null) {
          this.state.listaPessoa.push(listaAdiciona);
          this.state.pessoasCadastradas.push(listaAdiciona);
        }

        this.setState({ adicinando: true });
      } else {
        this.mensagem(false, "Já Cadastrado!", 3000);
      }
    } catch (error) {
      this.setState({ adicinando: false });
      console.error("Error", error);
    }
  };

  mensagem = (positive, content, duration) => {
    this.setState({
      mensagemSnackBar: {
        show: true,
        positive: positive,
        content: content,
        duration: duration || 4000,
      },
    });
  };

  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Form onSubmit={this.salvar}>
          <Row>
            <Col sm={3}>
              <Form.Label>Modalidade</Form.Label>

              <Select
                placeholder="Digite para buscar"
                inputId="modalidade"
                as="select"
                name="modalidade"
                options={this.state.modalidades}
                onChange={(e) => this.alteracaoSelectModalidades(e)}
                required
              />
            </Col>

            <Col sm={4}>
              <Form.Label>Pessoa</Form.Label>
              <Autocomplete
                id="pessoas"
                onKeyUp={this.pesquisaPessoa}
                onChange={this.alteracaoSelectPessoa}
                // value={this.state.pessoaSelecionadaId}
                options={this.state.pessoas}
                getOptionLabel={(option) => {
                  return option.label;
                }}
                filterSelectedOptions={false}
                disableListWrap
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Buscar"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.state.buscando ? (
                            <CircularProgress
                              color="inherit"
                              size={20}
                              style={{
                                marginRight: "3ch",
                              }}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Col>

            <Col sm={1} style={{ marginTop: "20px" }}>
              <Checkbox
                label="Sócio"
                checked={this.state.checkboxSocio}
                onChange={(e, a) => this.setState({ checkboxSocio: a.checked })}
              />
              <Checkbox
                label="Ativo"
                checked={this.state.checkboxAtivo}
                onChange={(e, a) => this.setState({ checkboxAtivo: a.checked })}
              />
            </Col>

            <Col sm={2}>
              <Form.Label>Tipo</Form.Label>
              <Select
                placeholder="Digite para buscar"
                inputId="tipo"
                as="select"
                name="tipo"
                options={this.state.tipoVinculos}
                onChange={this.alteracaoSelectTipoVinculo}
                required
              />
            </Col>

            <Col sm={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "25px",
                }}
              >
                <Button
                  disabled={
                    this.state.pessoaSelecionadaId === null ||
                    this.state.modalidadeSelecionadaId === null ||
                    this.state.tipoVinculoSelecionadoId === null
                  }
                  // todos: type="submit"
                  //*  style={{ outline: "none" }}
                  color="primary"
                  onClick={this.adicionaListaModalidade}
                >
                  Adicionar
                </Button>

                <div>
                  <Button
                    type="submit"
                    style={{ outline: "none" }}
                    color="primary"
                    disabled={
                      this.state.adicinando === false ||
                      this.state.salvando === true
                    }
                    loading={this.state.salvando}
                  >
                    Salvar
                  </Button>
                </div>
              </div>
            </Col>
          </Row>

          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Titulo</Table.HeaderCell>
                <Table.HeaderCell>Nome</Table.HeaderCell>
                <Table.HeaderCell collapsing style={{ overflow: "visible" }}>
                  <Row
                    style={{
                      maxWidth: 600,
                      minWidth: 300,
                    }}
                  >
                    <Col
                      md={"auto"}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      Tipo:
                    </Col>
                    <Col>
                      <Select
                        isDisabled={this.state.adicinando === true}
                        inputId="tipos"
                        as="selects"
                        name="tipos"
                        value={[
                          { value: 0, label: "Todos" },
                          ...this.state.tipoVinculos,
                        ].find((e) => e.value === this.state.tipo_vinculo_id)}
                        options={[
                          { value: 0, label: "Todos" },
                          ...this.state.tipoVinculos,
                        ]}
                        onChange={(event) =>
                          this.alteracaoSelectModalidades(
                            null,
                            null,
                            event.value
                          )
                        }
                      />
                    </Col>
                  </Row>
                </Table.HeaderCell>
                <Table.HeaderCell collapsing></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {/* {this.state.pessoasCadastradas.map((element, index) => (
                <Table.Row style={{ backgroundColor: "#F5F5F5" }}>
                  <Table.Cell>{element.acao}</Table.Cell>

                  <Table.Cell>{element.nome}</Table.Cell>
                  <Table.Cell>{element.nome_tipo_vinculo}</Table.Cell>
                  <Table.Cell>
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={(e) => this.deleteLista(element)}
                    >
                      <Cancel color="error" />
                    </IconButton>
                  </Table.Cell>
                </Table.Row>
              ))} */}
              {this.state.pessoasCadastradas.map((element) => (
                <Table.Row>
                  <Table.Cell>{element.acao}</Table.Cell>

                  <Table.Cell>{element.nome}</Table.Cell>

                  <Table.Cell>{element.nome_tipo_vinculo}</Table.Cell>
                  <Table.Cell>
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={(e) => this.deleteLista(element)}
                    >
                      <Cancel color="error" />
                    </IconButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={6}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Pagination
                      count={this.state.totalPaginas}
                      variant="outlined"
                      shape="rounded"
                      boundaryCount={1}
                      page={this.state.activePages}
                      onChange={(e, a) =>
                        this.alteracaoSelectModalidades(null, a)
                      }
                    />
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>

          {/* <Segment clearing>
            <Header floated="right">
              <LoadingButton
                type="submit"
                style={{ outline: "none" }}
                color="primary"
                disabled={
                  this.state.adicinando === false ||
                  this.state.salvando === true
                }
                loading={this.state.salvando}
              >
                Salvar
              </LoadingButton>
            </Header>
          </Segment> */}
        </Form>
        <Snackbar
          open={this.state.mensagemSnackBar.show}
          autoHideDuration={this.state.mensagemSnackBar?.duration}
          anchorOrigin={{ vertical: "botton", horizontal: "center" }}
          onClose={() =>
            setTimeout(() => {
              this.setState({
                mensagemSnackBar: {
                  ...this.state.mensagemSnackBar,
                  show: false,
                },
              });
            }, 500)
          }
        >
          <Alert
            onClose={() =>
              setTimeout(() => {
                this.setState({
                  mensagemSnackBar: {
                    ...this.state.mensagemSnackBar,
                    show: false,
                  },
                });
              }, 500)
            }
            severity={
              this.state.mensagemSnackBar?.positive ? "success" : "error"
            }
            sx={{ width: "100%" }}
          >
            {this.state.mensagemSnackBar?.content}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}
