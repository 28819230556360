import React, { Component } from "react";
import { Container, Header, Table, Button, Modal } from "semantic-ui-react";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import api from "../../../../services/api";
import renomearChave from "../../../../services/renomearChave";
import Requisicoes from "../../../../utils/Requisicoes";
import TipoLancamento from "../../../../enum/TipoLancamento";
import moment from "moment-timezone";
import { Divider, Segment } from "semantic-ui-react";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ListaDependentes from "./listaDependentes";
import Checkbox from "muicss/lib/react/checkbox";

export default class CancelamentoDividas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      lancamentos: [],
      pessoa: null,
      motivo: null,
      dependentes: [],
      toggleAll: false,
      selectedCheckboxes: new Set(),
      open_cancelamento_dividas: null,
    };
  }

  handleCloseDividas = () => {
    this.setState({ open_cancelamento_dividas: false });
  };

  handleShowDividas = () => this.setState({ open_cancelamento_dividas: true });

  toggleCheckboxCancelada = (e) => {
    let lancamentos = this.state.lancamentos;
    if (lancamentos[e].checked === undefined) {
      lancamentos[e].checked = true;
    } else {
      lancamentos[e].checked = !lancamentos[e].checked;
    }
    this.setState({ lancamentos: lancamentos });
  };

  toggleAllCheckboxes = () => {
    let lancamentos = this.state.lancamentos;
    for (let i = 0; i < lancamentos.length; i++) {
      const element = lancamentos[i];
      element.checked = !this.state.toggleAll;
    }
    this.setState({
      toggleAll: !this.state.toggleAll,
      lancamentos: lancamentos,
    });
  };

  toggleCheckbox = (e) => {
    if (this.state.selectedCheckboxes.has(e.target.value)) {
      this.state.selectedCheckboxes.delete(e.target.value);
    } else {
      this.state.selectedCheckboxes.add(e.target.value);
    }
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  converterParaLabel = (objetoLista) => {
    let lista = [];
    objetoLista.forEach((element) => {
      if (element.acao !== null) {
        let objeto = renomearChave(element, "id", "value");
        objeto.label =
          renomearChave(objeto.acao, "codigo", "label").label +
          " - " +
          objeto.nome;
        lista.push(objeto);
      }
    });
    return lista;
  };
  converterParaLabelPessoa = (objetoLista) => {
    let lista2 = [];
    objetoLista.forEach((element) => {
      if (element.acao !== null) {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista2.push(objeto);
      }
    });
    return lista2;
  };

  pesquisaPessoa = async () => {
    let term = document.getElementById("pesquisaPessoa").value;
    if (term.toString().indexOf("%") !== -1) {
      term = document
        .getElementById("pesquisaPessoa")
        .value.toString()
        .replace("%", "");
      document.getElementById("pesquisaPessoa").value = term;
      if (document.getElementById("porcentagem").innerText === "%") {
        document.getElementById("porcentagem").innerText = "";
      } else {
        document.getElementById("porcentagem").innerText = "%";
      }
    }
    setTimeout(async () => {
      if (isFinite(String(term))) {
        await api
          .post(
            "/pessoa/pesquisaPorCodigoAcaoValorExato" + Requisicoes.entidade,
            { pesquisa: document.getElementById("pesquisaPessoa").value },
            Requisicoes.header
          )
          .then((retorno) => {
            this.setState({ options: this.converterParaLabel(retorno.data) });
          });
      } else {
        await api
          .post(
            // "/pessoa/pesquisaNome" + Requisicoes.entidade,
            // {
            //   pesquisa: document.getElementById("pesquisaPessoa").value
            // },
            "/pessoa/pesquisa" + Requisicoes.entidade,
            {
              pesquisa: document.getElementById("pesquisaPessoa").value,
              pessoaInativa: true,
            },
            Requisicoes.header
          )
          .then((retorno) => {
            this.setState({
              options: this.converterParaLabelPessoa(retorno.data),
            });
          });
      }
    }, 200);
  };

  buscaDividas = async (e, v) => {
    if (v !== null) {
      const pessoa = await api.post(
        "/pessoa/visualiza" + Requisicoes.entidade,
        { id: v.value },
        Requisicoes.header
      );
      await this.setState({ pessoa: pessoa.data });
      const retorno = await api.post(
        //"/lancamentoConta/pesquisaPorPessoaCodigo"
        "/lancamentoConta/lancamentoEmAbertoPorPessoa" + Requisicoes.entidade,
        { pessoa_id: v.value },
        Requisicoes.header
      );
      const resDependentes = await api.post(
        "/pessoa/dependentes" + Requisicoes.entidade,
        { titular_id: pessoa.data.id },
        Requisicoes.header
      );
      let listaDependentes = [];
      resDependentes.data.forEach(async (e, i) => {
        let p = renomearChave(e, "id", "value");
        p = renomearChave(p, "nome", "label");
        await api
          .post(
            "/usuario/usuariosPorPessoaETipo" + Requisicoes.entidade,
            {
              pessoa_id: e.id,
              tipo_usuario: "dependente",
              entidade: window.localStorage.getItem("e_clube"),
            },
            Requisicoes.header
          )
          .then((ele) => {
            if (ele.data.length > 0) {
              listaDependentes.push({
                pessoa: p,
                login: ele.data[0].login,
                grau_parentesco: e.grau_parentesco,
              });
            }
          });
        if (i === resDependentes.data.length - 1) {
          this.setState({ dependentes: listaDependentes });
        }
      });
      this.setState({ lancamentos: retorno.data });
    }
  };

  cancelamento = async () => {
    if (this.state.motivo === null) {
      alert("Favor Informar um Motivo");
    } else {
      await api.post(
        "/acao/alterar" + Requisicoes.entidade,
        { status: "inativo", id: this.state.pessoa.acao.id },
        Requisicoes.header
      );
      await api.post(
        "/acao/removerAcaoPessoas" + Requisicoes.entidade,
        { acao_id: this.state.pessoa.acao.id },
        Requisicoes.header
      );
      // await api.post(
      //   "/pessoa/alterar" +
      //     Requisicoes.entidade +
      //     "&id=" +
      //     this.state.pessoa.id,
      //   { acao_id: null },
      //   Requisicoes.header
      // );

      await api.post(
        "/historicoAcao/salvar" +
        Requisicoes.entidade +
        "&id=" +
        this.state.pessoa.id,
        {
          tipo_lancamento: "cancelamento",
          pessoa_compra_id: this.state.pessoa.id,
          acao_id: this.state.pessoa.acao.id,
          motivo: this.state.motivo,
        },
        Requisicoes.header
      );
      window.location.href = "/administrativo";
    }
  };

  cancelamentoDividas = async () => {
    if (this.state.motivo === null) {
      alert("Favor Informar um Motivo");
    } else {
      let lista = [];
      for (let i = 0; i < this.state.lancamentos.length; i++) {
        const element = this.state.lancamentos[i];
        if (element.checked !== true) {
          continue;
        } else {
          lista.push(element);
        }
      }
      lista.forEach(async (e, i) => {
        this.state.lancamentos.find(
          (l) => parseInt(l.id) === parseInt(e.id)
        );
        this.state.lancamentos.find(
          (l) => parseInt(l.id) === parseInt(e.id)
        )
        this.state.lancamentos.find(
          (l) => parseInt(l.id) === parseInt(e.id)
        ).cancelado = true;
        if (lista.length > 0) {
          await api.post(
            "/lancamentoConta/cancelarVarios" + Requisicoes.entidade,
            {
              lancamentos: lista,
              motivo: this.state.motivo,
              tela_usada: "cancelamento_acao_titulo",
            },
            Requisicoes.header
          );
        }

      });
      this.handleCloseDividas();
    }
  };

  handleClose = () => {
    window.location.reload();
    this.setState({ open: false });
  };

  handleShow = () => {
    let lista = []
    for (let i = 0; i < this.state.lancamentos.length; i++) {
      const element = this.state.lancamentos[i];
      if (element.checked !== true) {
        lista.push(element)
      }
    }
    if (lista.length === 0) {
      this.setState({ open: true })
    } else {
      alert("Impossivel cancelar título. Titular atual com pendências em aberto.")
    }
  };
  render() {
    return (
      <div>
        <Row>
          <Col sm={6}>
            <Form.Label>Pessoa</Form.Label>
            <Row>
              <Col sm={1}>
                <Form.Label id="porcentagem"></Form.Label>
              </Col>
              <Col sm={11}>
                <Autocomplete
                  id="pesquisaPessoa"
                  options={this.state.options}
                  getOptionLabel={(option) => option.label}
                  onKeyDown={this.pesquisaPessoa}
                  onChange={this.buscaDividas}
                  renderInput={(params) => (
                    <TextField {...params} label="Busca" size="small" />
                  )}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6} style={{ float: "right" }}>
            <Button
              negative
              onClick={this.handleShow}
              style={{ float: "right" }}
            >
              Cancelar Título
            </Button>
          </Col>
          <Col sm={12}>
            <Form.Label>Motivo</Form.Label>
            <FormControl
              aria-describedby="basic-addon1"
              type="text"
              name="motivo"
              onChange={this.alteracao}
            />
          </Col>
          <Col sm={12}>
            <Row>
              {this.state.pessoa !== null && (
                <Col sm={12} style={{ paddingTop: "10px" }}>
                  <Header as="h3">Dados Cadastrais</Header>
                  <Divider />
                  <Segment.Group>
                    <Segment.Group horizontal>
                      <Segment>
                        <b>Tipo:</b> {this.state.pessoa.tipo}
                      </Segment>
                      <Segment>
                        <b>Telefone:</b> {this.state.pessoa.telefone}
                      </Segment>
                      <Segment>
                        <b>E-mail:</b> {this.state.pessoa.email}
                      </Segment>
                    </Segment.Group>
                    <Segment>
                      <b>Endereço:</b> {this.state.pessoa.endereco} -{" "}
                      {this.state.pessoa.bairro}, {this.state.pessoa.cep}{" "}
                      {this.state.pessoa.cidade}-{this.state.pessoa.estado}
                    </Segment>
                  </Segment.Group>
                </Col>
              )}
              {this.state.pessoa !== null && (
                <Col sm={12} style={{ paddingTop: "10px" }}>
                  <Header as="h3" dividing>
                    Ação/Titulo
                  </Header>
                  <Segment.Group>
                    <Segment.Group horizontal>
                      <Segment>
                        <Col sm={6}>
                          <b>Codigo:</b>
                          {this.state.pessoa.acao !== null &&
                            this.state.pessoa.acao.codigo + "-000"}
                        </Col>
                      </Segment>
                      <Segment>
                        <b>Valor Mensalidade:</b>
                        {this.state.pessoa.acao?.tipo_titulo !== null &&
                          this.state.pessoa.acao?.tipo_titulo.valor_mensalidade}
                        <br />
                        <br />
                        <b>Tipo Titulo:</b>
                        {this.state.pessoa.acao?.tipo_titulo !== null &&
                          this.state.pessoa.acao?.tipo_titulo.descricao}
                      </Segment>
                      <Segment>
                        <b>Data Emissão:</b>{" "}
                        {new Date(
                          this.state.pessoa.acao?.data_emissao
                        ).toLocaleDateString()}
                        <br />
                        <br />
                        <b>Valor Titulo:</b>
                        {this.state.pessoa.acao?.tipo_titulo !== null &&
                          this.state.pessoa.acao?.tipo_titulo.valor_titulo}
                      </Segment>
                    </Segment.Group>
                  </Segment.Group>
                </Col>
              )}
              {this.state.pessoa !== null && (
                <Col sm={12} style={{ paddingTop: "10px" }}>
                  <Header as="h3" dividing>
                    Dependentes
                  </Header>
                  <ListaDependentes dependentes={this.state.dependentes} />
                </Col>
              )}
            </Row>
          </Col>

          {this.state.lancamentos.length > 0 && (
            <Col sm={12} style={{ paddingTop: "10px" }}>
              <Header as="h3" dividing>
                Dívidas
              </Header>
            </Col>
          )}
          {this.state.lancamentos.length > 0 && (
            <Col sm={12} style={{ float: "right", paddingTop: "10px" }}>
              <Button
                negative
                onClick={this.handleShowDividas}
                style={{ float: "right" }}
              >
                Cancelar Dívidas
              </Button>
            </Col>
          )}
          {this.state.lancamentos.length > 0 ? (
            <Col sm={12} style={{ paddingTop: 10 }}>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={4}>Serviço</Table.HeaderCell>
                    <Table.HeaderCell>Vencimento</Table.HeaderCell>
                    <Table.HeaderCell>Valor</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell width={1} textAlign="center">
                      <Checkbox
                        label=""
                        onClick={() => this.toggleAllCheckboxes()}
                        checked={this.state.toggleAll}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.lancamentos.map((e, i) => (
                    <Table.Row
                      key={i}
                      style={{
                        background: [
                          e.cancelado !== undefined &&
                          e.cancelado !== null &&
                          e.cancelado !== false &&
                          "red"
                        ],
                      }}
                    >
                      <Table.Cell width={4}>
                        {e.servico !== null
                          ? e.servico.nome
                          : TipoLancamento.find(
                            (t) => t.value === e.tipo_lancamento
                          ).label}
                      </Table.Cell>
                      <Table.Cell width={4}>
                        {e.data_vencimento !== null &&
                          moment(e.data_vencimento).format("DD/MM/YYYY")}
                      </Table.Cell>
                      <Table.Cell width={2}>
                        {parseFloat(e.valor).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Table.Cell>
                      <Table.Cell width={2}>

                        {e.cancelado === true
                          ? "Cancelado"
                          : Date.parse(e.data_vencimento) <
                            Date.parse(moment().format("YYYY-MM-DD"))
                            ? "Vencido"
                            : "Em aberto"}
                      </Table.Cell>
                      <Table.Cell width={1} textAlign="center">
                        {(e.cancelado === undefined ||
                          e.cancelado === null ||
                          e.cancelado === false) && (
                            <Checkbox
                              label=""
                              onClick={() => this.toggleCheckboxCancelada(i)}
                              defaultValue={e.id}
                              checked={e.checked}
                            />
                          )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>

                <Table.Footer></Table.Footer>
              </Table>
            </Col>
          ) : (
            this.state.pessoa !== null && (
              <Col style={{ paddingTop: 10 }}>
                <Header
                  as="h3"
                  dividing
                  style={{ color: "green" }}
                >
                  Sem Titulos Vencidos
                </Header>
              </Col>
            )
          )}
        </Row>

        <Modal
          open={this.state.open_cancelamento_dividas}
          onClose={this.handleCloseDividas}
          centered={false}
        >
          <Header content="Mensagem" />
          <Modal.Content>
            <Form.Label>Motivo</Form.Label>
            <FormControl
              aria-describedby="basic-addon1"
              type="text"
              name="motivo"
              onChange={this.alteracao}
              required
            />
            Confirma Cancelamento ?
          </Modal.Content>
          <Header floated="right">
            <Row>
              <Col sm={5}>
                <Button
                  type="button"
                  variant="light"
                  onClick={this.handleCloseDividas}
                >
                  Voltar
                </Button>
              </Col>
              <Col sm={5}>
                <Button positive onClick={this.cancelamentoDividas}>
                  Sim
                </Button>
              </Col>
            </Row>
          </Header>
        </Modal>

        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          centered={false}
        >
          <Header content="Cancelamento" />
          <Modal.Content>Confirma Cancelamento ?</Modal.Content>
          <Header floated="right">
            <Row>
              <Col sm={5}>
                <Button
                  type="button"
                  variant="light"
                  onClick={this.handleClose}
                >
                  Voltar
                </Button>
              </Col>
              <Col sm={5}>
                <Button positive onClick={this.cancelamento}>
                  Sim
                </Button>
              </Col>
            </Row>
          </Header>
        </Modal>
      </div>
    );
  }
}
