import React, { Component } from "react";
import { Col } from "react-bootstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Table, Button, Segment, Icon } from "semantic-ui-react";
import {
  Pagination,
  Tab,
  Tabs,
  Typography,
  Box,
  TextField,
} from "@material-ui/core/";

import api from "../../../services/api";
import { Link } from "react-router-dom";
import requisicoes from "../../../utils/Requisicoes";

export default class ParametroAcademiaGrupoServicoLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaGrupoServicos: [],
      listaGrupoCadastrado: [],
      options: [],
      pagina: 1,
      totalPaginas: 1,
    };
    this.listaGrupoServicos();
    this.listaParametrosGrupoServico();
  }

  listaGrupoServicos = async () => {
    const grupoServico = await api.post(
      "/parametroAcademiaCargos/listaGrupoServicos" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    let lista = [];
    for (let i = 0; i < grupoServico.data.length; i++) {
      const element = grupoServico.data[i];
      lista.push({
        value: element.id,
        label: element.descricao,
      });
    }

    this.setState({
      options: lista,
    });
  };

  listaParametrosGrupoServico = async () => {
    try {
      const parametrosGrupo = await api.post(
        "/parametroAcademiaCargos/listaParametrosGrupoServico" +
          requisicoes.entidade,
        {},
        requisicoes.header
      );
      const grupoServico = await api.post(
        "/parametroAcademiaCargos/listaGrupoServicos" + requisicoes.entidade,
        {},
        requisicoes.header
      );
      let lista = [];
      for (let i = 0; i < parametrosGrupo.data.length; i++) {
        const element = parametrosGrupo.data[i];
        for (let j = 0; j < grupoServico.data.length; j++) {
          const element2 = grupoServico.data[j];
          if (element.grupo_servico_id === element2.id) {
            lista.push({ value: element2.id, label: element2.descricao });
          }
        }
      }
      function ordenaEquipe(a, b) {
        let aAux = a.label.toLowerCase();
        let bAux = b.label.toLowerCase();
        if (aAux > bAux) {
          return 1;
        }
        if (aAux < bAux) {
          return -1;
        }
        return 0;
      }
      lista.sort(ordenaEquipe);
      this.setState({ listaGrupoServicos: lista });
    } catch (error) {
      console.log("error ", error);
    }
  };

  onChangeSelectGrupoServico = async (event, GrupoServico) => {
    this.state.listaGrupoCadastrado = [];
    this.state.listaGrupoCadastrado.push(GrupoServico);
  };

  salvaParametrosGrupoServico = async () => {
    const salvaGrupoServico = await api.post(
      "/parametroAcademiaCargos/salvarParametrosGrupoServicos" +
        requisicoes.entidade,
      { grupo_servico_id: this.state.listaGrupoCadastrado[0].value },
      requisicoes.header
    );
    let lista = [];
    this.state.listaGrupoServicos.unshift({
      value: this.state.listaGrupoCadastrado[0].value,
      label: this.state.listaGrupoCadastrado[0].label,
    });
    this.setState({ listaGrupoServicos: this.state.listaGrupoServicos });
  };

  excluirParametroGrupoServico = async (event, e) => {
    await api.post(
      "/parametroAcademiaCargos/excluiParametrosGrupoServicos" +
        requisicoes.entidade,
      { grupo_servico_id: event.value },
      requisicoes.header
    );

    let index = this.state.listaGrupoServicos.indexOf(event);

    if (index !== -1) {
      this.state.listaGrupoServicos.splice(index, 1);
    }

    this.setState({
      listaGrupoServicos: this.state.listaGrupoServicos,
    });
  };

  render() {
    return (
      <div className="lista">
        <Segment clearing>
          <Table.Cell>
            <Col>
              <Autocomplete
                sx={{ width: 300 }}
                fullWidth
                id="pesquisaGrupoServico"
                options={this.state.options}
                getOptionLabel={(option) => option.label}
                onKeyDown={this.listaGrupoServicos}
                onChange={(e, a) => this.onChangeSelectGrupoServico(e, a)}
                renderInput={(params) => (
                  <TextField {...params} label="Busca" size="small" />
                )}
              />
            </Col>
          </Table.Cell>
          <Table.Cell style={{ alignItems: "left" }}>
            <Button
              animated
              fluid
              positive
              type="submit"
              color="green"
              style={{ outline: "none", top: "8.5px" }}
              onClick={() => this.salvaParametrosGrupoServico()}
            >
              <Button.Content visible type="submit">
                Adicionar
              </Button.Content>
              <Button.Content hidden>
                <Icon name="add" />
              </Button.Content>
            </Button>
          </Table.Cell>
        </Segment>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Grupo Servico</Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign="center"></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.listaGrupoServicos.map((element, i) => (
              <Table.Row key={i}>
                <Table.Cell>{element.label}</Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    color="red"
                    icon="x"
                    onClick={(e) => this.excluirParametroGrupoServico(element)}
                  ></Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>

          {/* <Table.Footer>
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan="3">
                <Pagination
                  boundaryRange={0}
                  ellipsisItem={null}
                  totalPages={this.state.totalPaginas}
                  onPageChange={this.listaExercicios}
                  activePage={this.state.pagina}
                ></Pagination>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer> */}
        </Table>
      </div>
    );
  }
}
