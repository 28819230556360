import React, { Component } from "react";
import { Header, Modal, Button } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default class ModalLiquidarContaAVista extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal open={this.props.open} centered={true} size="tiny">
        <Header content="Deseja liquidar a conta a pagar?" />
        <Modal.Content>
          <Row>
            <Col>
              <div style={{ fontSize: 15 }}>
                A conta a pagar foi salva com sucesso!
                <br />
                <br />
                Identificamos que você selecionou pagamento à vista. Deseja
                liquidar a conta a pagar agora?
              </div>
            </Col>
          </Row>
        </Modal.Content>
        <Modal.Actions>
          <Link to="/financeiro">
            <Button>Talvez mais tarde</Button>
          </Link>

          <Link
            to={{
              pathname: "/financeiro",
              query: {
                liquidar_lancamento_conta_id: this.props.lancamento_conta_id,
              },
            }}
          >
            <Button
              color="green"
              onClick={() => {
                localStorage.setItem(
                  "pagina_anterior_financeiro",
                  "liquidacao"
                );
              }}
            >
              Sim
            </Button>
          </Link>
        </Modal.Actions>
      </Modal>
    );
  }
}
