import React, { Component } from "react";
import { Header, 
    Modal, 
    Button, 
    Input, 
    Table, 
    Segment } from "semantic-ui-react";
import { Row, FormControl } from "react-bootstrap";
import readXlsxFile from 'read-excel-file';

import $ from "jquery";

export default class ModalLerExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open_modal_arquivo: false,
      file_excel: null,
    };
  }

  abrirModalArquivo = () => {
    this.setState({
      open_modal_arquivo: true,
    });
  };

  fecharModalArquivo = () => {
    this.clearExcelList();
    this.setState({
        open_modal_arquivo: false,
    });
  };

  loadExcelList = async (e) => {
    e.preventDefault();
    try {
        const file = e?.target?.files[0];

        if(!file) return alert("Falha ao carregar arquivo excel");
    
        const rows = await readXlsxFile(file);
        let array = [];
        for(let i = 0; i < rows.length; i++) {
            const row = rows[i];
            array.push({
                nome: row[0],
                cpf: row[1],
            });
        }

        this.setState({
            file_excel: array
        });
    } catch(err) {
        console.log('loadExcelList/err: ' + err);
        if(err.toString().includes("invalid zip data"))
            return alert("Por favor, escolha um arquivo de planilha excel");
    }
  };

  importExcelList = async () => {
    if(this.state.file_excel) {
      console.log("his.state.file_excel",this.state.file_excel.length)
        this.props.adicionaListaPresencaByExcel(this.state.file_excel);
        this.fecharModalArquivo();
    }
    else 
        return alert("Nenhuma lista carregada.");
  };

  clearExcelList = () => {
    this.setState({
        ["file"]: null,
        file: null,
        file_excel: null,
    });
  };

  render() {
    return (
      <Modal
        open={this.state.open_modal_arquivo}
        trigger={
          <Button
            icon="upload"
            floated="right"
            color="blue"
            size="tiny"
            onClick={this.abrirModalArquivo}
            ></Button>
        }
        onOpen={this.buscaImagem}
        size="small"
      >
        <Modal.Header>
            <Header as="h3">Carregar lista de convidados</Header>
        </Modal.Header>
        <Modal.Content>
            <p className="justify-content-center">Lembre-se de seguir o padrão no arquivo excel: <b>[ Nome ] [ CPF ]</b></p>
          <Row className="justify-content-center">
            <Input 
                type="file" 
                name="file" 
                id="file"
                onChange={this.loadExcelList}
            />
            <Button 
                style={{marginLeft: 8, marginRight: 8}}
                type="button"
                disabled={this.state.file_excel === null 
                    || this.state.file_excel === undefined}
                color={"blue"}
                onClick={this.importExcelList}
            >Importar</Button>
            {(this.state.file_excel !== null 
            && this.state.file_excel !== undefined) && (
                <Button 
                    type="button"
                    color={"red"}
                    onClick={this.clearExcelList}
                >Limpar</Button>
            )}
          </Row>
          <p className="justify-content-center"><b>DICAS:</b></p>
          <p className="justify-content-center"><b>1 -</b> Lembre-se de preencher o CPF corretamente, com ou sem pontos
          <br /><b>2 -</b> Verifique se a coluna A e B estão marcadas como campo de texto
          <br /><b>3 -</b> Para mudar a coluna A e B para texto, siga os passos abaixo:
          <br /><b>3.1 -</b> Normalmente ao criar uma nova planilha, os campos são marcados como <b><i>Geral</i></b>
          <br /><b>3.2 -</b> Procure na tela onde tiver a opção selecionada <b><i>Geral</i></b> e verifique se nela tem a opção <b><i>Texto</i></b>
          <br /><b>3.3 -</b> Clique na coluna A e B e altere para <b><i>Texto</i></b></p>

          <p className="justify-content-center"><b>Planilha do Google (Online)</b></p>
          <p className="justify-content-center">Você pode criar uma planilha através do Google Planilhas e fazer o download no seu smartphone, se preferir.
          <br />Acesse o link: <a href="https://docs.google.com/spreadsheets/u/0/create?usp=sheets_home&ths=true" target="_blank"><b> Google Planilhas</b></a></p>
          <p className="justify-content-center">Para alterar a coluna A e B para <b><i>Texto</i></b> na planilha do google, você deve clicar na opção <i>123v</i>, irá abrir um menu suspenso, selecione a opção <b><i>Texto Simples</i></b> e pronto, só informar o nome e cpf do convidado</p>


          
          {(this.state.file_excel) && (
            
            <Segment>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Nome</Table.HeaderCell>
                  <Table.HeaderCell>CPF</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.file_excel.map((e, i)  => (
                  <Table.Row key={i}>
                    <Table.Cell width={2}>
                      <FormControl
                        name="name"
                        fluid
                        disabled
                        value={e.nome}
                      />
                    </Table.Cell>
                    <Table.Cell width={2}>
                      <FormControl
                        name="cpf"
                        fluid
                        disabled
                        value={e.cpf}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Segment>
          )}
        </Modal.Content>
        <Modal.Actions>
            <div class="actions">
                <Button 
                    type="button" 
                    color="grey" 
                    onClick={this.fecharModalArquivo}
                >Voltar</Button>
            </div>
        </Modal.Actions>
      </Modal>
    );
  }
}
