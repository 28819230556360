import React, { Component } from "react";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { List, Card } from "semantic-ui-react";
import { Row } from "react-bootstrap";

import "../styles.css";

export default class lista_modalidades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertaModalidadeVinculada: false,
    };
  }

  showAlertaModalidadeVinculada = async () => {
    this.setState({ alertaModalidadeVinculada: true });

    setTimeout(() => {
      this.setState({ alertaModalidadeVinculada: false });
    }, 1300);
  };

  render() {
    return (
      <div>
        <div>
          <Alert
            severity="error"
            hidden={!this.state.alertaModalidadeVinculada}
          >
            <AlertTitle>Modalidade Vinculada!</AlertTitle>
            <strong> Impossível editar </strong>
          </Alert>
        </div>

        <Card.Group stackable itemsPerRow={4} style={{ marginTop: "0.3%" }}>
          {this.props.listaModalidades.map((element, index) => {
            return (
              <Card link>
                <Card.Content
                  textAlign="left"
                  className="cardsLista"
                  onClick={
                    element.evento.length !== 0
                      ? () => this.showAlertaModalidadeVinculada()
                      : () => this.props.changeModalEditar(element, true)
                  }
                >
                  <List>
                    <List.Item>
                      <List.Icon name="quote left" />
                      <List.Content>{element.descricao} </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="sync" />
                      <List.Content>{element.serie}</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="settings" />
                      <List.Content>{element.tipo_pontuacao}</List.Content>
                    </List.Item>
                  </List>
                </Card.Content>
              </Card>
            );
          })}
        </Card.Group>
      </div>
    );
  }
}
