import React, { Component } from "react";
import { Container, Table, Header, Modal, Button, Checkbox} from "semantic-ui-react";
import { Col, Form, FormControl } from "react-bootstrap";
import moment from "moment-timezone";

export default class ModalEstudante extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open_modal_estudante: false
    };
  }

  render(){
    return(
      <Modal
        open={this.state.open_modal_estudante}
        onClose={() => {
          this.setState({ open_modal_estudante: false });
          // this.props.submit();
        }}
        onOpen={() => {
          this.setState({ open_modal_estudante: true });
        }}
        trigger={
          <Button
            content="Estudante"
            icon="student"
            color="blue"
            size="tiny"
            floated="right"
          ></Button>
        }
        size="tiny"
      >
        <Modal.Content>
          <Col
            style={{
              paddingRight: "0px",
              marginRight: "0px",
              paddingBottom: "0px",
              marginBottom: "0px",
            }}
          >
            <Container
              style={{
                display: "flex",
                paddingRight: "0px",
                paddingTop: "0px",
                marginTop: "0px",
                marginRight: "0px",
                paddingBottom: "0px",
                marginBottom: "0px",
              }}
            >
              <Button
                icon="x"
                color="red"
                size="tiny"
                style={{ marginLeft: "auto" }}
                onClick={() => {
                  this.setState({
                    open_modal_estudante: false,
                  });
                }}
              />
            </Container>
          </Col>

          <Col
            sm={10}
            style={{
              paddingLeft: "0px",
              marginLeft: "0px",
              paddingBottom: "0px",
              marginBottom: "0px",
            }}
          >
            <Container
              style={{
                paddingLeft: "0px",
                marginLeft: "0px",
                paddingBottom: "0px",
                marginBottom: "0px",
              }}
            >
              <h3>Dados do estudante:</h3>
            </Container>
          </Col>

          <Container
            style={{
              paddingLeft: "0px",
              marginLeft: "0px",
              paddingBottom: "0px",
              marginBottom: "0px",
            }}
          >
            <Col
              style={{
                paddingLeft: "0px",
                marginLeft: "0px",
                paddingBottom: "0px",
                marginBottom: "0px",
              }}
            >
              <Form.Label>Estudante</Form.Label>
            </Col>
            <Col
              style={{
                paddingLeft: "0px",
                marginLeft: "0px",
                paddingBottom: "0px",
                marginBottom: "0px",
              }}
            >
              <Checkbox
                toggle
                onChange={(event, data) => {
                  this.props.onChangeCheckboxEstudante(
                    event,
                    data
                  );
                }}
                checked={this.props.estudante}
                disabled={this.props.bloqueia}
              />
            </Col>
          </Container>

          <Form.Label>Entidade de ensino</Form.Label>
          <Form.Control
            placeholder="Entidade de ensino"
            fluid
            name="nova_entidade_ensino"
            onChange={this.props.alteracao}
            value={this.props.nova_entidade_ensino}
            disabled={this.props.estudante !== true || this.props.bloqueia}
            style={{ marginBottom: "10px" }}
          />
          <Form.Label>
            Data vencimento da matrícula
          </Form.Label>
          <FormControl
            placeholder="Data vencimento Matricula"
            aria-label="Data vencimento da matrícula"
            aria-describedby="basic-addon1"
            type="date"
            fluid
            name="nova_data_matricula"
            onChange={this.props.alteracao}
            disabled={this.props.estudante !== true || this.props.bloqueia}
            value={this.props.nova_data_matricula}
            style={{ marginBottom: "10px" }}
          />
        </Modal.Content>
      </Modal>
    )
  }
}

