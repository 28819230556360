import React, { Component } from "react";
import {
  Table,
  Input,
  Form,
  Select,
  Checkbox,
  Accordion,
  Button,
  Segment,
} from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

import moment from "moment";

const optionsAviso = [
  {
    key: 1,
    text: "1º Aviso: 2 parcelas vencidas",
    value: 2,
  },
  {
    key: 2,
    text: "2º Aviso: 3 parcelas vencidas",
    value: 3,
  },
  {
    key: 3,
    text: "3º Aviso: 4 parcelas vencidas",
    value: 4,
  },
  {
    key: 4,
    text: "4º Aviso: 5 parcelas vencidas",
    value: 5,
  },
  {
    key: 5,
    text: "5º Aviso: 6 parcelas vencidas",
    value: 6,
  },
  {
    key: 6,
    text: "6º Aviso: 7 parcelas vencidas",
    value: 7,
  },
  {
    key: 7,
    text: "7º Aviso: 8 parcelas vencidas",
    value: 8,
  },
  {
    key: 8,
    text: "8º Aviso: 9 parcelas vencidas",
    value: 9,
  },
  {
    key: 9,
    text: "9º Aviso: 10 parcelas vencidas",
    value: 10,
  },
  {
    key: 10,
    text: "10º Aviso: 11 parcelas vencidas",
    value: 11,
  },
  {
    key: 11,
    text: "11º Aviso: 12 parcelas vencidas",
    value: 12,
  },
];

const enumAcaoFeitaIndimplentes = {
  imprimir: { label: "Imprimiu" },
  email: { label: "Enviou email" },
};
// enumAcaoFeitaIndimplentes["imprimir"];
export default class Inadimplentes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //inputs
      avisoSelected: 0,
      checkboxDatas: false,
      data_inicial: moment().startOf("month").format("YYYY-MM-DD"),
      data_final: moment().format("YYYY-MM-DD"),
      checkboxNumOp: true,
      acaoSelected: 0,
      num_op: null,
      ultima_op: true,

      //dados
      historicoInadimplentes: [],
    };
    this.buscarOnClick();
  }
  alteracao = async (event, a) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  alteracao2 = async (event, a) => {
    this.setState({ [a.name]: a.value });
  };

  buscarOnClick = async () => {
    let historicoInadimplentes = await api.post(
      "/inadimplentes/buscaHistoricoInadimplentes" + requisicoes.entidade,
      {
        numero_aviso:
          this.state.avisoSelected === 0 ? null : this.state.avisoSelected - 1,
        data_inicial: this.state.checkboxDatas ? this.state.data_inicial : null,
        data_final: this.state.checkboxDatas ? this.state.data_final : null,
        acao_feita:
          this.state.acaoSelected === 0 ? null : this.state.acaoSelected,
        num_op: this.state.ultima_op
          ? null
          : this.state.checkboxNumOp
          ? this.state.num_op
          : null,
        ultima_op: this.state.ultima_op,
      },
      requisicoes.header
    );

    if (this.state.ultima_op && historicoInadimplentes.data.length > 0) {
      this.setState({
        num_op: historicoInadimplentes.data[0].sequencial_operacao,
      });
    }

    this.setState({ historicoInadimplentes: historicoInadimplentes.data });
  };

  imprimirOnClick = async () => {
    await api.post(
      "/inadimplentes/gerarPdfInadimplentes" + requisicoes.entidade,
      {
        numero_aviso:
          this.state.avisoSelected === 0 ? null : this.state.avisoSelected - 1,
        data_inicial: this.state.checkboxDatas ? this.state.data_inicial : null,
        data_final: this.state.checkboxDatas ? this.state.data_final : null,
        acao_feita:
          this.state.acaoSelected === 0 ? null : this.state.acaoSelected,
        num_op: this.state.ultima_op
          ? null
          : this.state.checkboxNumOp
          ? this.state.num_op
          : null,
        ultima_op: this.state.ultima_op,
      },
      requisicoes.header
    );

    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");

    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    const r = await api.get(
      "/inadimplentes/buscarArquivoPdfInadimplentes" +
        requisicoes.entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.id
    );
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "inadimplentes/buscarArquivoPdfInadimplentes" +
      requisicoes.entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.id;

    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  render() {
    return (
      <div style={{ paddingBottom: 100 }}>
        <Row>
          <Form>
            <Form.Group style={{ margin: 0 }}>
              <Col md="auto">
                <Form.Field
                  name="avisoSelected"
                  label="Aviso"
                  control={Select}
                  options={[
                    {
                      key: 0,
                      text: "Todos",
                      value: 0,
                    },
                    ...optionsAviso,
                  ]}
                  value={this.state.avisoSelected}
                  onChange={this.alteracao2}
                />
              </Col>
              <Col md="auto">
                <Form.Field
                  label="Acao"
                  control={Select}
                  name="acaoSelected"
                  options={[
                    { key: 0, text: "Todos", value: 0 },
                    { key: 1, text: "Imprimir", value: "imprimir" },
                    { key: 2, text: "Enviar email", value: "email" },
                  ]}
                  value={this.state.acaoSelected}
                  onChange={this.alteracao2}
                />
              </Col>
              <Col>
                <Segment style={{ backgroundColor: "rgba(0,0,0,0)" }}>
                  <Row>
                    <Col md="auto">
                      <Form.Field
                        name="checkboxDatas"
                        label="Datas"
                        control={Checkbox}
                        checked={this.state.checkboxDatas}
                        onChange={(e, a) =>
                          this.setState({ checkboxDatas: a.checked })
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="auto">
                      <Form.Field
                        name="data_inicial"
                        label="Data inicio"
                        control={Input}
                        type="date"
                        value={this.state.data_inicial}
                        disabled={!this.state.checkboxDatas}
                        onChange={this.alteracao2}
                      />
                    </Col>
                    <Col md="auto">
                      <Form.Field
                        name="data_final"
                        label="Data Final"
                        control={Input}
                        type="date"
                        value={this.state.data_final}
                        disabled={!this.state.checkboxDatas}
                        onChange={this.alteracao2}
                      />
                    </Col>
                  </Row>
                </Segment>
              </Col>

              <Col md="auto">
                <Segment style={{ backgroundColor: "rgba(0,0,0,0)" }}>
                  <Row>
                    <Col md="auto">
                      <Form.Field
                        name="checkboxNumOp"
                        label="Operação"
                        control={Checkbox}
                        checked={this.state.checkboxNumOp}
                        onChange={(e, a) => {
                          this.setState({ checkboxNumOp: a.checked });
                          if (a.checked === false) {
                            this.setState({ ultima_op: a.checked });
                          }
                        }}
                      />
                    </Col>
                    <Col md="auto">
                      <Form.Field
                        name="ultima_op"
                        label="Última"
                        control={Checkbox}
                        checked={this.state.ultima_op}
                        onChange={(e, a) =>
                          this.setState({ ultima_op: a.checked })
                        }
                        disabled={!this.state.checkboxNumOp}
                      />
                    </Col>
                  </Row>
                  <Form.Field
                    label="Número Op."
                    control={Input}
                    name="num_op"
                    value={this.state.num_op}
                    onChange={this.alteracao2}
                    disabled={!this.state.checkboxNumOp || this.state.ultima_op}
                    type="number"
                    min="0"
                    step="1"
                  />
                </Segment>
              </Col>
              <Col
                md="auto"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: 0,
                  marginLeft: 0,
                  paddingRight: 0,
                  marginRight: 0,
                  paddingTop: 10,
                }}
              >
                <Button color="green" onClick={this.buscarOnClick}>
                  Buscar
                </Button>
              </Col>
              <Col
                md="auto"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: 0,
                  marginLeft: 0,
                  paddingRight: 0,
                  marginRight: 0,
                  paddingTop: 10,
                }}
              >
                <Button color="blue" onClick={this.imprimirOnClick}>
                  Imprimir
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Row>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell collapsing>N° Op</Table.HeaderCell>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Aviso</Table.HeaderCell>
              <Table.HeaderCell>Data</Table.HeaderCell>
              <Table.HeaderCell>Ação</Table.HeaderCell>
              <Table.HeaderCell>Usuário</Table.HeaderCell>
              <Table.HeaderCell>Lancamentos</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.historicoInadimplentes.map((e, i) => (
              <Table.Row>
                <Table.Cell>{e.sequencial_operacao}</Table.Cell>
                <Table.Cell>{e.pessoa.nome}</Table.Cell>
                <Table.Cell>{e.numero_aviso}</Table.Cell>
                <Table.Cell>{moment(e.data).format("DD/MM/YYYY")}</Table.Cell>
                <Table.Cell>
                  {enumAcaoFeitaIndimplentes[e.acao_feita].label}
                </Table.Cell>
                <Table.Cell>{e.usuario ? e.usuario.nome : ""}</Table.Cell>
                <Table.Cell collapsing>
                  <Accordion styled fluid>
                    <Accordion.Title
                      onClick={() =>
                        this.setState({
                          acordionSelected:
                            this.state.acordionSelected !== i ? i : null,
                        })
                      }
                      active={this.state.acordionSelected === i}
                    >
                      {e.lancamento_contas.length +
                        " - R$ " +
                        parseFloat(e.total_lancamentos).toLocaleString(
                          "pt-BR",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                    </Accordion.Title>
                    <Accordion.Content
                      active={this.state.acordionSelected === i}
                    >
                      {e.lancamento_contas.map((e2, i2) => (
                        <p style={{ margin: 0 }}>
                          {moment(e2.data_vencimento).format("DD/MM/YYYY") +
                            " - " +
                            parseFloat(e2.valor).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) +
                            " - " +
                            e2.servico.nome}
                        </p>
                      ))}
                    </Accordion.Content>
                  </Accordion>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}
