import React, { Component } from "react";
import { Container, Header, Table, Menu, Icon } from "semantic-ui-react";
import { AiOutlineUnorderedList } from "react-icons/ai";
import api from "../../../services/api";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import requisicoes from "../../../utils/Requisicoes";
import Pagination from "@material-ui/lab/Pagination";
import { IconButton } from "@material-ui/core";
export default class CaixaRelatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      totalPaginas: 0,
      usuarios: [],
      listaUsuarioRelatorio: [],
    };
    this.buscarUsuarios();
  }
  buscarUsuarios = async (activePage = 1) => {
    var localStorage = window.localStorage;

    var entidade = localStorage.getItem("e_clube");
    const resposta = await api.post(
      "/usuario/buscaPorTipoUsuario?e=" + entidade,
      {
        tipo_usuario: ["caixa", "administrativo"],
        entidade: entidade,
        activePage: activePage,
      },
      requisicoes.header
    );
    if (activePage === undefined || activePage === "" || activePage === null) {
      activePage = 1;
    }
    this.setState({
      listaUsuarioRelatorio: resposta.data.usuarios,
      activePage: activePage,
      totalPaginas: resposta.data.totalPaginas,
      totalRegistro: resposta.data.total,
    });
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Perfis
        </Header>

        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Login</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.listaUsuarioRelatorio.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>{e.nome !== null && e.nome}</Table.Cell>
                <Table.Cell>{e.login !== null && e.login}</Table.Cell>

                <Table.Cell>
                  <Link
                    to={{
                      pathname: "/caixaRelatorio/mostrar",
                      query: { id: e.id },
                    }}
                  >
                    <IconContext.Provider
                      value={{ color: "blue", size: "30px" }}
                    >
                      <div>
                        <AiOutlineUnorderedList />
                      </div>
                    </IconContext.Provider>
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer verticalAlign="middle" textAlign="center">
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan={4}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Pagination
                    count={this.state.totalPaginas}
                    variant="outlined"
                    shape="rounded"
                    boundaryCount={1}
                    page={this.state.activePage}
                    onChange={(e, a) => this.buscarUsuarios(a)}
                  />
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}
