import React, { Component } from "react";
import {
  Table,
  Icon,
  Button,
  Modal,
  Form,
  Input,
  TextArea,
  Container,
  Segment,
  Message,
  Card,
} from "semantic-ui-react";
import moment from "moment-timezone";
import Checkbox from "muicss/lib/react/checkbox";
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";
import { Row, Col } from "react-bootstrap";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";

import TipoLancamento from "../../../enum/TipoLancamento";
import { IoMdAdd } from "react-icons/io";
import { parseISO, format } from "date-fns";

export default class ListaLancamentos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCheckboxes: new Set(),
      selectedCheckboxes2: new Set(),
      total: 0.0,
      openModalInformacoes: null,
      gerandoBoleto: false,
      mensagem:
        "Mensagem automática.\n\nEnvio de boleto referente a pendencia no clube.\n\n",
      email: props.novoEmail,
      assunto: "Envio de boleto pendente com o clube",
      emailInvalido: false,
      lancamento_contas: [],
      lancamentosDependentes: [],
      total_titular: 0.0,
      total_dependentes: 0.0,
    };
    this.myViewer = React.createRef();
  }

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === "email") {
      this.setState({ emailInvalido: false });
    }
  };

  toggleCheckbox = (e) => {
    if (this.state.selectedCheckboxes.has(e.target.value)) {
      this.state.selectedCheckboxes.delete(e.target.value);
    } else {
      this.state.selectedCheckboxes.add(e.target.value);
    }
  };
  toggleCheckbox2 = (e) => {
    if (this.state.selectedCheckboxes2.has(e.target.value)) {
      this.state.selectedCheckboxes2.delete(e.target.value);
    } else {
      this.state.selectedCheckboxes2.add(e.target.value);
    }
  };

  gerarPdfBoletos = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    const r = await api.get("/boleto?e=" + entidade + "&t=Baer " + token);
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL + "boleto?e=" + entidade + "&t=Baer " + token;

    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  gerarBoleto = async (id, mostrar) => {
    this.setState({ gerandoBoleto: true });
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    const lancamento = this.props.listaLancamentos.find((e) => e.id === id);

    if (
      lancamento.instituicao_cobranca &&
      lancamento.instituicao_cobranca.registro_automatico &&
      !lancamento.remessa_gerada
    ) {
      if (!window.confirm("Seu boleto será registrado, deseja continuar?")) {
        this.setState({ gerandoBoleto: false });
        return;
      }
    }

    let dados = { lancamento_contas: [{ id: id }] };
    await api
      .post("/boleto/geraBoleto?e=" + entidade, dados, {
        headers: { token: "Baer " + token },
      })
      .then(async (retorno) => {
        if (mostrar !== false) {
          setTimeout(async () => {
            this.gerarPdfBoletos();
          }, 500);
        }
        this.setState({ gerandoBoleto: false });
      });
  };

  enviarEmail = async () => {
    this.setState({ enviando: true });
    if (!this.state.email.includes("@") || !this.state.email.includes(".")) {
      this.setState({ emailInvalido: true });
      this.setState({ enviando: null });
      return;
    }

    await api.post(
      "/email/enviarEmail" + requisicoes.entidade,
      {
        email: this.state.email,
        assunto: this.state.assunto,
        mensagem: this.state.mensagem,
        tipo: "boleto",
      },
      requisicoes.header
    );

    this.setState({ enviando: false });
  };

  buscaEmAbertoTitulo = async (event, v) => {
    let retorno = null;
    let dependentes = null;
    var lancamentosDependentes = [];
    var totalDependentes = [];
    var totalTitular = 0.0;
    if (v !== null) {
      retorno = await api.post(
        "/lancamentoConta/lancamentoEmAbertoPorPessoa" + requisicoes.entidade,
        { pessoa_id: v.value },
        requisicoes.header
      );

      for (let i = 0; i < retorno.data.length; i++) {
        const element = retorno.data[i];
        if (
          this.state.selectedCheckboxes2.has(parseFloat(element.id).toString())
        ) {
          element.select = true;
        } else {
          element.select = false;
        }
        totalTitular += parseFloat(element.valor);
      }
      this.setState({ total_titular: totalTitular });
      dependentes = await api.post(
        "/pessoa/dependentes" + requisicoes.entidade,
        { titular_id: v.value },
        requisicoes.header
      );
      for (let i = 0; i < dependentes.data.length; i++) {
        const element = dependentes.data[i];
        let ret2 = await api.post(
          "/lancamentoConta/lancamentoEmAbertoPorPessoa" + requisicoes.entidade,
          { pessoa_id: element.id },
          requisicoes.header
        );
        let aux = 0.0;
        if (ret2.data.length > 0) {
          for (let i = 0; i < ret2.data.length; i++) {
            const element2 = ret2.data[i];
            if (
              this.state.selectedCheckboxes2.has(
                parseFloat(element2.id).toString()
              )
            ) {
              element2.select = true;
            } else {
              element2.select = false;
            }
            aux += parseFloat(element2.valor);
          }
          totalDependentes.push(aux);
          lancamentosDependentes.push(ret2.data);
        }
      }
    }
    if (retorno !== null && retorno.data.length !== 0) {
      this.setState({ lancamento_contas: retorno.data });
      this.setState({ lancamentosDependentes: lancamentosDependentes });
      this.setState({ total_dependentes: totalDependentes });
    } else {
      this.mostraMensagem("Nenhum Titulo Em Aberto Encontrado!");
    }
  };

  render() {
    return (
      <Card.Content
        style={{
          maxHeight: 250,
          flex: 1,
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Card.Header>Financeiro</Card.Header>
        <Card.Description>
          <div>
            <Modal
              onOpen={() => this.setState({ modalDividas: true })}
              onClose={() => this.setState({ modalDividas: false })}
              open={this.state.modalDividas}
              trigger={
                <Button
                  size="small"
                  primary
                  type="button"
                  style={{ float: "right", marginBottom: "10px" }}
                  onClick={() => {
                    this.setState({
                      selectedCheckboxes2: this.state.selectedCheckboxes,
                    });
                    this.buscaEmAbertoTitulo(null, {
                      value: this.props.pessoa.id,
                    });
                  }}
                >
                  Dívidas do título
                </Button>
              }
            >
              <Modal.Header>Dívidas do título</Modal.Header>
              <Modal.Content>
                {
                  <Table celled selectable compact>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={6}>
                          Nome Pessoa
                        </Table.HeaderCell>
                        <Table.HeaderCell width={5}>Descrição</Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                          Vencimento
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign={"right"} width={2}>
                          Valor
                        </Table.HeaderCell>
                        {(localStorage.getItem("tipo_usuario_clube") ===
                          "administrativo" ||
                          localStorage.getItem("tipo_usuario_clube") ===
                          "caixa") && (
                            <Table.HeaderCell width={1}></Table.HeaderCell>
                          )}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.state.lancamento_contas.map((e, i) => (
                        <>
                          {i > 0 &&
                            moment(e.data_vencimento).month() !==
                            moment(
                              this.state.lancamento_contas[i - 1]
                                .data_vencimento
                            ).month() ? (
                            <Table.Row
                              key={i * -1}
                              style={{ background: "#2dcbec" }}
                            >
                              <Table.Cell
                                colSpan={5}
                                disabled
                                style={{ height: "2px", padding: "0px" }}
                              ></Table.Cell>
                            </Table.Row>
                          ) : null}
                          <Table.Row key={i}>
                            <Table.Cell>{e.pessoa.nome}</Table.Cell>

                            {e.tipo_lancamento === "cobrancaServicoAvulsa" ? (
                              <Table.Cell>
                                {e.servico !== undefined && e.servico.nome}
                              </Table.Cell>
                            ) : (
                              <Table.Cell>
                                {e.descricao !== null &&
                                  (e.descricao.toString().length > 20 ||
                                    e.tipo_lancamento === "sangria"
                                    ? TipoLancamento.find(
                                      (t) => t.value === e.tipo_lancamento
                                    ) !== undefined &&
                                    TipoLancamento.find(
                                      (t2) => t2.value === e.tipo_lancamento
                                    ).label
                                    : e.descricao)}
                              </Table.Cell>
                            )}
                            <Table.Cell>
                              {e.data_vencimento !== null &&
                                format(
                                  parseISO(e.data_vencimento),
                                  "dd/MM/yyyy"
                                )}
                            </Table.Cell>
                            <Table.Cell textAlign={"right"}>
                              {e.valor === null
                                ? parseFloat(e.valor_pago)
                                  .toFixed(2)
                                  .replace(".", ",")
                                  .replace(/\d(?=(\d{3})+\,)/g, "$&.")
                                : parseFloat(e.valor)
                                  .toFixed(2)
                                  .replace(".", ",")
                                  .replace(/\d(?=(\d{3})+\,)/g, "$&.")}
                            </Table.Cell>
                            {localStorage.getItem("tipo_usuario_clube") ===
                              "administrativo" ||
                              (localStorage.getItem("tipo_usuario_clube") ===
                                "caixa" && (
                                  <Table.Cell>
                                    <Checkbox
                                      label=""
                                      defaultChecked={e.select}
                                      onClick={this.toggleCheckbox2}
                                      defaultValue={e.id}
                                    />
                                  </Table.Cell>
                                ))}
                          </Table.Row>
                        </>
                      ))}
                    </Table.Body>
                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell colSpan={2}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} textAlign={"right"}>
                          <strong>
                            {"Total da divida: R$ " +
                              parseFloat(this.state.total_titular)
                                .toFixed(2)
                                .replace(".", ",")
                                .replace(/\d(?=(\d{3})+\,)/g, "$&.")}
                          </strong>
                        </Table.HeaderCell>
                        {localStorage.getItem("tipo_usuario_clube") ===
                          "administrativo" ||
                          (localStorage.getItem("tipo_usuario_clube") ===
                            "caixa" && <Table.HeaderCell></Table.HeaderCell>)}
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                }
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => this.setState({ modalDividas: false })}>
                  Voltar
                </Button>
                {localStorage.getItem("tipo_usuario_clube") ===
                  "administrativo" ||
                  (localStorage.getItem("tipo_usuario_clube") === "caixa" && (
                    <Link
                      to={{
                        pathname: "/caixa",
                        query: {
                          id: this.props.pessoa.id,
                          selectCheckBox: this.state.selectedCheckboxes2,
                        },
                        from: "meusDados",
                      }}
                    >
                      <Button
                        primary
                        type="button"
                        onClick={""}
                        style={{ float: "right", marginBottom: "10px" }}
                      >
                        Cobrar Dívidas
                      </Button>
                    </Link>
                  ))}
              </Modal.Actions>
            </Modal>
          </div>
          <Table celled compact selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>Data Vencimento</Table.HeaderCell>
                <Table.HeaderCell>Serviço</Table.HeaderCell>
                {this.props.tipoLancamento == "pagas" ? (
                  <Table.HeaderCell width={3}>Data Pagamento</Table.HeaderCell>
                ) : null}
                <Table.HeaderCell width={2} textAlign={"right"}>
                  Valor
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="center">
                  <Icon name="dollar"></Icon>
                </Table.HeaderCell>
                {localStorage.getItem("tipo_usuario_clube") !==
                  "modalidade" && (
                    <>
                      <Table.HeaderCell width={1} textAlign="center">
                        <Icon name="print"></Icon>
                      </Table.HeaderCell>
                    </>
                  )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.listaLancamentos.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell>
                    {moment(e.data_vencimento).format("DD/MM/yyyy")}
                  </Table.Cell>
                  <Table.Cell>
                    {e.servico !== null ? e.servico.nome : ""}
                  </Table.Cell>
                  {this.props.tipoLancamento == "pagas" ? (
                    <Table.Cell>
                      {e.hora_baixa !== null
                        ? moment(e.data_pagamento).format("DD/MM/yyyy") +
                        " - " +
                        e.hora_baixa.slice(0, 5)
                        : moment(e.data_pagamento).format("DD/MM/yyyy")}
                    </Table.Cell>
                  ) : null}
                  <Table.Cell textAlign={"right"}>
                    {e.valor_pago !== null
                      ? parseFloat(e.valor_pago).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                      : parseFloat(e.valor).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  </Table.Cell>
                  <Table.Cell width={1} textAlign="center">
                    {e.data_pagamento !== null ? (
                      <Button
                        fluid
                        icon="thumbs up"
                        positive
                        onClick={() =>
                          this.setState({ openModalInformacoes: i })
                        }
                      ></Button>
                    ) : (
                      <div>
                        {this.props.comparaDatas(e.data_vencimento) ? (
                          <Button
                            fluid
                            icon="thumbs up"
                            positive
                            onClick={() =>
                              this.setState({ openModalInformacoes: i })
                            }
                          ></Button>
                        ) : (
                          <Button
                            fluid
                            icon="thumbs down"
                            negative
                            onClick={() =>
                              this.setState({ openModalInformacoes: i })
                            }
                          ></Button>
                        )}
                      </div>
                    )}
                  </Table.Cell>
                  {localStorage.getItem("tipo_usuario_clube") !==
                    "modalidade" && (
                      <>
                        <Table.Cell
                          width={
                            localStorage.getItem("tipo_usuario_clube") ===
                              "administrador" ||
                              localStorage.getItem("tipo_usuario_clube") ===
                              "caixa" ||
                              localStorage.getItem("tipo_usuario_clube") ===
                              "administradorSistema" ||
                              localStorage.getItem("tipo_usuario_clube") ===
                              "administrativo"
                              ? 2
                              : 1
                          }
                          textAlign="center"
                        >
                          {e.numero_titulo !== null &&
                            e.numero_titulo.length <= 11 &&
                            e.tipo_cobranca === "boleto" &&
                            e.data_pagamento === null && (
                              <Row>
                                <Col sm={12}>
                                  <Button
                                    fluid
                                    icon="download"
                                    primary
                                    inverted={
                                      !e.remessa_gerada &&
                                      e.instituicao_cobranca &&
                                      e.instituicao_cobranca.registro_automatico
                                    }
                                    onClick={() => this.gerarBoleto(e.id)}
                                  ></Button>
                                </Col>
                              </Row>
                            )}
                        </Table.Cell>
                      </>
                    )}
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell
                  colSpan={this.props.tipoLancamento == "pagas" ? 3 : 2}
                ></Table.HeaderCell>
                <Table.HeaderCell width={3} textAlign={"right"}>
                  <strong>
                    {"Total: R$ " +
                      parseFloat(this.props.total_lancamentos)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/\d(?=(\d{3})+\,)/g, "$&.")}
                  </strong>
                </Table.HeaderCell>
                <Table.HeaderCell colSpan={3}></Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
          <Modal
            open={this.state.openModalInformacoes !== null}
            onClose={() => this.setState({ openModalInformacoes: null })}
            size={"tiny"}
          >
            <Modal.Header content="Informações do lancamento" />
            <Modal.Content>
              <Modal.Description>
                {this.state.openModalInformacoes !== null ? (
                  <Row>
                    <Col>
                      {this.props.listaLancamentos[
                        this.state.openModalInformacoes
                      ]?.numero_titulo !== null ? (
                        <div>
                          <strong>{"Nosso número: "}</strong>
                          {
                            this.props.listaLancamentos[
                              this.state.openModalInformacoes
                            ]?.numero_titulo
                          }
                        </div>
                      ) : (
                        <div>
                          <strong>Nosso número: </strong>
                          {"nosso número não foi gerado"}
                        </div>
                      )}
                    </Col>
                  </Row>
                ) : null}
                {this.state.openModalInformacoes !== null ? (
                  <Row>
                    <Col>
                      {this.props.listaLancamentos[
                        this.state.openModalInformacoes
                      ].data_pagamento !== null ? (
                        <div>
                          <strong>{"Quem cobrou: "}</strong>
                          {
                            this.props.listaLancamentos[
                              this.state.openModalInformacoes
                            ].caixas[0]?.usuario.nome
                          }
                        </div>
                      ) : (
                        <div>
                          <strong>Quem cobrou: </strong>
                          {"dívida ainda não foi paga."}
                        </div>
                      )}
                    </Col>
                  </Row>
                ) : null}
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </Card.Description>
      </Card.Content>
    );
  }
}
