import React, { Component } from "react";
import {
  Button,
  Modal,
  Form,
  Icon,
  Checkbox,
  TextArea,
} from "semantic-ui-react";
import { Form as formulario, FormControl, Col, Row } from "react-bootstrap";

export default class ModalReservar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Modal open={this.props.modalReservaOpen} closeIcon size="small">
          <Modal.Header icon="true">
            <Icon name="file text" />
            Termos e condições
            <Button
              content="Imprimir"
              color="blue"
              size="tiny"
              floated="right"
              icon="print"
              onClick={this.props.gerarTermos}
            ></Button>
          </Modal.Header>
          <Modal.Content scrolling>
            {this.props.reservaEvento === true && (
              <Modal.Description>
                <formulario.Label>Descrição do Evento</formulario.Label>
                <FormControl
                  aria-describedby="basic-addon1"
                  name="evento"
                  onChange={this.props.alteracao}
                  required
                  defaultValue={this.props.evento}
                  placeholder="Descrição do Evento"
                  isInvalid={this.props.parametroEvento}
                />
                {(localStorage.getItem("tipo_usuario_clube") ===
                  "administrador" ||
                  localStorage.getItem("tipo_usuario_clube") ===
                  "administrativo" ||
                  localStorage.getItem("tipo_usuario_clube") === "caixa") && (
                    <>
                      <formulario.Label style={{ paddingTop: 10 }}>
                        Observação
                      </formulario.Label>
                      <Form>
                        <TextArea
                          name="observacao"
                          rows={15}
                          value={this.state.observacao}
                          onChange={this.props.alteracao}
                          placeholder="Observação"
                        />
                      </Form>
                    </>
                  )}
              </Modal.Description>
            )}
            {this.props.termos && (
              <Modal.Description>
                <formulario.Label style={{ paddingTop: 10 }}>
                  Termos e Condições
                </formulario.Label>
                {this.props.termos.texto !== "" && (
                  <Form>
                    <TextArea
                      name="texto"
                      rows={25}
                      value={this.props.termos.texto}
                      disabled
                    />
                  </Form>
                )}
              </Modal.Description>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Row>
              <Col style={{ textAlign: "left" }}>
                <Checkbox
                  value={this.props.aceitaTermos}
                  label="Aceito os Termos e condições"
                  onChange={() =>
                    this.props.confirmaTermos(!this.props.aceitaTermos)
                  }
                />
              </Col>
              <Button
                color="red"
                inverted
                onClick={() => this.props.abreModalReserva(false)}
              >
                <Icon name="remove" /> Fechar
              </Button>

              {this.props.reservaEfetivacao === true ? (
                <>
                  {/* Reserva Saloes */}
                  {localStorage.getItem("tipo_usuario_clube") ===
                    "administrador" ||
                    localStorage.getItem("tipo_usuario_clube") ===
                    "administrativo" ||
                    localStorage.getItem("tipo_usuario_clube") === "caixa" ? (
                    <Button
                      disabled={
                        this.props.aceitaTermos === false ||
                        this.props.liberaReservaBotao}
                      color="blue"
                      inverted
                      icon="calendar check"
                      onClick={() => this.props.abreModalAlteraDias(true)}
                    >
                      <Icon name="checkmark" /> Reservar
                    </Button>
                  ) : (
                    <Button
                      disabled={this.props.liberaReservaBotao}
                      color="blue"
                      inverted
                      icon="calendar check"
                      onClick={() => this.props.reservaEfetivacao()}
                    >
                      <Icon name="checkmark" /> Reservar
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  disabled={
                    this.props.reservada === true ||
                    this.props.aceitaTermos === false ||
                    this.props.liberaReservaBotao ||
                    this.props.loadingReservaBotao
                  }
                  loading={this.props.loadingReservaBotao}
                  color="green"
                  inverted
                  icon="calendar check"
                  onClick={() => this.props.confirmaReserva(false)}

                >
                  <Icon name="checkmark" /> Reservar
                </Button>
              )}
            </Row>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}
