import React, { Component } from "react";
import { Header, Button, Segment } from "semantic-ui-react";
import { Col, Form, FormControl } from "react-bootstrap";
import moment from "moment-timezone";

export default class AcaoTituloLista extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Col sm={12} style={{ paddingTop: "10px" }}>
        <Header as="h3">Ação/Titulo</Header>
        {this.props.acao === null ? (
          <Button
            positive
            icon="add"
            size="tiny"
            onClick={this.props.novaAcao}
            type="button"
          >
            Nova Ação
          </Button>
        ) : (
          <Segment.Group>
            <Segment.Group horizontal>
              <Segment>
                <b>Tipo Titulo:</b>
                <Form.Control
                  placeholder="TipoTitulo"
                  label="TipoTitulo"
                  id="TipoTitulo"
                  as="select"
                  name="tipoTitulo"
                  onChange={this.props.alteraAcao}
                  checked={this.props.selectTipo}
                  required
                  disabled={this.props.efetuar_cobranca === false}
                >
                  <option></option>
                  { this.props.acao.tipo_titulo.descricao == "REMIDO" &&
                    this.props.pessoaCompra === true ?
                    this.props.listaTipoTitulo.map((e, i) =>
                    this.props.acao.tipo_titulo !== null &&
                    e.descricao === "PATRIMONIAL" ? (
                      <option key={i} value={e.id} selected>
                        {e.descricao}
                      </option>
                    ) : (
                      <option key={i} value={e.id}>
                        {e.descricao}
                      </option>
                    )
                  ):
                  this.props.listaTipoTitulo.map((e, i) =>
                    this.props.acao.tipo_titulo !== null &&
                    e.id === this.props.acao.tipo_titulo.id ? (
                      <option key={i} value={e.id} selected>
                        {e.descricao}
                      </option>
                    ) : (
                      <option key={i} value={e.id}>
                        {e.descricao}
                      </option>
                    )
                  )}
                </Form.Control>
              </Segment>
              <Segment>
                <b>Numero do Titulo:</b> {this.props.acao.codigo} <br />
                <br />
                {this.props.pessoaCompra === true ? (
                  ""
                ) : (
                  <b>Valor Mensalidade:</b>
                )}
                {this.props.acao.tipo_titulo !== null &&
                  this.props.pessoaCompra === false &&
                  this.props.acao.tipo_titulo.valor_mensalidade}
              </Segment>
              <Segment>
                <b>
                  {this.props.pessoaCompra === true
                    ? "Data Transferência:"
                    : "Data Emissão:"}{" "}
                </b>{" "}
                {this.props.pessoaCompra === true ? (
                  <FormControl
                    aria-describedby="basic-addon1"
                    type="date"
                    name="data_transferencia"
                    onChange={this.props.alteracao}
                    defaultValue={
                      this.props.data_transferencia === null
                        ? ""
                        : moment(this.props.data_transferencia).format(
                            "YYYY-MM-DD"
                          )
                    }
                  />
                ) : (
                  ""
                )}
                <br />
                <br />
                {this.props.pessoaCompra === true ? "" : <b>Valor Titulo:</b>}
                {this.props.acao.tipo_titulo !== null &&
                  this.props.pessoaCompra === false &&
                  this.props.acao.tipo_titulo.valor_titulo}
              </Segment>
            </Segment.Group>
          </Segment.Group>
        )}
      </Col>
    );
  }
}
