import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import TipoLancamento from "../../../../../enum/TipoLancamento";
import moment from "moment-timezone";
import Checkbox from "muicss/lib/react/checkbox";

export default class Dependentes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Tipo</Table.HeaderCell>
            <Table.HeaderCell>Data Vencimento</Table.HeaderCell>
            <Table.HeaderCell>Valor</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell width={1}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.pendencias !== undefined &&
            this.props.pendencias.map((e, i) => (
              <Table.Row
                key={e.id}
                style={{ background: [e.cancelado === true && "red"] }}
              >
                <Table.Cell>
                  {e.tipo_lancamento !== null &&
                    TipoLancamento.find((t) => t.value === e.tipo_lancamento)
                      .label}
                </Table.Cell>
                <Table.Cell>
                  {e.data_vencimento !== null &&
                    moment(e.data_vencimento).format("DD/MM/YYYY")}
                </Table.Cell>
                <Table.Cell>
                  {e.valor !== null &&
                    parseFloat(e.valor).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </Table.Cell>
                <Table.Cell width={2}>
                  {e.cancelado === true && "Cancelado"}
                </Table.Cell>
                <Table.Cell width={1}>
                  {e.cancelado === false && (
                    <Checkbox
                      label=""
                      onClick={this.props.toggleCheckbox}
                      value={e.id + ""}
                      checked={this.props.selectedCheckboxes.has(e.id + "")}
                    />
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    );
  }
}
