import React, { Component } from "react";
import { Table, Header, Button, Modal, Container } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";
import moment from "moment-timezone";

import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

export default class Dependentes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buscou: false,
      historicoDependentes: [],
    };
  }

  buscaHistorico = async () => {
    const retorno = await api.post(
      "/historicoDependente/busca" + requisicoes.entidade,
      { titular_id: this.props.pessoa.id },
      requisicoes.header
    );
    this.setState({ buscou: true });
    this.setState({ historicoDependentes: retorno.data });
  };

  render() {
    return (
      <Row>
        <Col sm={12} style={{ paddingTop: "10px", paddingBottom: "2%" }}>
          <Row>
            <Col>
              <Header as="h3">Dependentes</Header>
            </Col>
            <Modal
              trigger={
                <Col>
                  <Button
                    content="Historico de Dependentes"
                    icon="history"
                    color="blue"
                    size="tiny"
                    floated="right"
                  ></Button>
                </Col>
              }
              onOpen={this.buscaHistorico}
            >
              <Modal.Header>
                <Header as="h3">Histórico de dependentes</Header>
              </Modal.Header>
              <Modal.Content>
                {this.state.buscou ? (
                  <Table celled compact selectable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Ocorrencia</Table.HeaderCell>
                        <Table.HeaderCell>Dependente</Table.HeaderCell>
                        <Table.HeaderCell>Data</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.state.historicoDependentes.length > 0
                        ? this.state.historicoDependentes.map((e, i) => (
                            <Table.Row key={i}>
                              <Table.Cell>
                                {e.acao === "remover" ? "Remover" : "Adicionar"}
                              </Table.Cell>
                              <Table.Cell>{e.dependente.nome}</Table.Cell>
                              <Table.Cell>
                                {moment(e.data).format("DD/MM/YYYY")}
                              </Table.Cell>
                            </Table.Row>
                          ))
                        : null}
                    </Table.Body>
                  </Table>
                ) : (
                  <Container
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "30px",
                      marginBottom: "30px",
                    }}
                  >
                    <CircularProgress />
                  </Container>
                )}
              </Modal.Content>
            </Modal>
          </Row>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1}></Table.HeaderCell>
                <Table.HeaderCell>Nome</Table.HeaderCell>
                <Table.HeaderCell>Login</Table.HeaderCell>
                <Table.HeaderCell>Grau Parentesco</Table.HeaderCell>
                {this.props.permissoes.find(
                  (e) => e.nome === "adicionar_dependentes"
                ) &&
                  this.props.pessoa.acao !== null && (
                    <Table.HeaderCell width={1}>
                      <Button
                        positive
                        onClick={this.props.handleOpenDependentes}
                        icon="add"
                        type="button"
                        size="tiny"
                      ></Button>
                    </Table.HeaderCell>
                  )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.dependentes !== undefined &&
                this.props.dependentes.map((e, i) => (
                  <Table.Row key={i}>
                    <Table.Cell width={1}>
                      <Button
                        color="blue"
                        icon="eye"
                        onClick={() =>
                          this.props.alteracaoSelectPessoa(null, e.pessoa)
                        }
                      ></Button>
                    </Table.Cell>
                    <Table.Cell>{e.pessoa.label}</Table.Cell>
                    <Table.Cell>{e.login}</Table.Cell>
                    <Table.Cell>{e.grau_parentesco}</Table.Cell>
                    {this.props.permissoes.find(
                      (e) => e.nome === "adicionar_dependentes"
                    ) && (
                      <Table.Cell width={1}>
                        <Button
                          negative
                          icon={{
                            className: "remove",
                            onClick: [
                              () =>
                                this.props.handleShowRemoveDependentes(
                                  e.pessoa.value
                                ),
                            ],
                          }}
                          size="tiny"
                          onClick={() =>
                            this.props.handleShowRemoveDependentes(
                              e.pessoa.value
                            )
                          }
                          type="button"
                          style={{ marginTop: "7%" }}
                        ></Button>
                      </Table.Cell>
                    )}
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Col>
      </Row>
    );
  }
}
