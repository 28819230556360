import React, { useEffect, useState } from "react";
import Rotas from "./rotas";
import "semantic-ui-css/semantic.min.css";
import "react-slideshow-image/dist/styles.css";
import "./styles/styles.css";
//import socketIOClient from "socket.io-client";
// const ENDPOINT = "http://127.0.0.1:3001";

function App() {
  // const [response, setResponse] = useState("");
  // const socket = socketIOClient("http://127.0.0.1:3001?userName=tom");
  // socket.emit('chat message', "recipientUserName", "messageContent");
  // useEffect(() => {
  //   const socket = socketIOClient(ENDPOINT);
  //   socket.on("FromAPI", (data) => {
  //     setResponse(data);
  //   });
  // }, []);
  // console.log(response);
  return (
    <div className="App">
      <Rotas />
    </div>
  );
}

export default App;

//REACT_APP_ENVIRONMENT=development react-scripts start
//"build": "cross-env VARIABLE webpack --config build/webpack.config.js",

/*
"start-w": "set REACT_APP_ENVIRONMENT=development && react-scripts start",
"start": "REACT_APP_ENVIRONMENT=development react-scripts start",
*/