/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Header, Segment } from "semantic-ui-react";
import { Col } from "react-bootstrap";

import {
  TextField,
  Autocomplete,
  IconButton,
  CircularProgress,
  Snackbar,
  Alert,
} from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons/";

import requisicoes from "../../../utils/Requisicoes";

import api from "../../../services/api";
import Lista from "./lista";
import ModalAdicionar from "./modalAdicionar";
import enumCampeonato from "../../../enum/TipoCampeonatoDesporto";

import "../styles.css";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campeonatosCadastrados: [],
      campeonatoSelecionado: [],
      campeonatos: enumCampeonato,
      descricao: "",
      grupoServicoId: null,
      idaeVolta: false,
      modalidades: [],
      modalidadeSelecionadaId: null,
      modalidadeSelecionadaNome: "",
      pontosPorEmpate: null,
      pontosPorVitoria: null,
      quantidadeReservas: null,
      quantidadeComissao: null,
      quantidadeMaxAtleta: null,
      servicos: [],
      ServicosInscricaoSelecionadoNome: "",
      ServicosInscricaoSelecionadoId: null,
      tipoCampeonatoSelecionadoNome: "",
      tipo: "",
      tipoCampeonatoSelecionadoId: null,
      tempoPenalidade: null,
      todosContraTodos: false,
      valorInscricaoEquipe: null,
      valorInscricaoIndividual: null,
      open_modal_campeonato: null,
      mensagemErro: true,
      mensagemSnackBar: { show: false },
    };
    this.buscaCampeonatosCadastrador();
    this.selecionaModalidade();
  }

  buscaCampeonatosCadastrador = async (req) => {
    const retorno = await api.post(
      "/campeonato/listar" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    const modalidades = await api.post(
      "/modalidadeDesporto/listar" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    let infoModalidades = [];
    for (let index = 0; index < modalidades.data.length; index++) {
      infoModalidades.push({
        value: modalidades.data[index].id,
        label: modalidades.data[index].nome,
        grupo_servico_id: modalidades.data[index].grupo_servico_id,
      });
    }
    retorno.data.sort(function (a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
    this.setState({
      modalidades: infoModalidades,
      campeonatosCadastrados: retorno.data,
    });
  };

  alteracaoSelectTipoCampeonato = async (event) => {
    let descricaoCampeonato = enumCampeonato.find(
      (element) => element.value === event.target.value
    );
    this.setState({
      tipoCampeonatoSelecionadoId: event.target.value,
      tipoCampeonatoSelecionadoNome: descricaoCampeonato.label,
    });
  };

  alteracaoSelectModalidades = async (event) => {
    const retorno = await api.post(
      "/servico/servicoPorGrupoServicoComDiversos" + requisicoes.entidade,
      { id: event.target.value.grupo_servico_id, desporto: true },
      requisicoes.header
    );
    let servicos = [];

    for (let index = 0; index < retorno.data.length; index++) {
      servicos.push({
        label: retorno.data[index].nome,
        value: retorno.data[index].id,
      });
    }
    servicos.sort(function (a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    this.setState({
      modalidadeSelecionadaId: event.target.value.value,
      modalidadeSelecionadaNome: event.target.label,
      grupoServicoId: event.target.value.grupo_servico_id,
      servicos: servicos,
    });
  };

  alteracaoSelectServicos = async (event) => {
    this.setState({ ServicosInscricaoSelecionadoId: event.value });
    this.setState({ ServicosInscricaoSelecionadoNome: event.label });
  };

  alteracao = async (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  handleClose = () => {
    this.setState({ open_modal_campeonato: false });
  };

  handleShow = () => {
    this.setState({ open_modal_campeonato: true });
  };
  adicionaCampeonato = async (lista) => {
    try {
      this.state.campeonatosCadastrados.push(lista);
      this.mensagem(true, "Salvo com sucesso.", 3000);

      this.setState({ mensagemErro: true });
    } catch (error) {
      console.log("Erro ao adicionar na lista", error);
      this.mensagem(false, "Ocorreu um erro ao salvar.", 4500);
    }
  };
  selecionaModalidade = async (a, event, c) => {
    try {
      setTimeout(() => {
        this.state.campeonatoSelecionado = [];
        if (event) {
          for (let i = 0; i < this.state.campeonatosCadastrados.length; i++) {
            const element = this.state.campeonatosCadastrados[i];
            if (element?.modalidade_desporto_id === event?.value) {
              this.state.campeonatoSelecionado.push(element);
            }
          }
        } else {
          this.setState({
            campeonatoSelecionado: this.state.campeonatosCadastrados,
          });
          return;
        }
        this.setState({
          campeonatoSelecionado: this.state.campeonatoSelecionado,
        });
      }, 300);
    } catch (error) {
      console.error("error", error);
    }
  };
  mensagem = (positive, content, duration) => {
    this.setState({
      mensagemSnackBar: {
        show: true,
        positive: positive,
        content: content,
        duration: duration || 4000,
      },
    });
  };
  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Col sm={4}>
          <Autocomplete
            id="selecione"
            options={this.state.modalidades}
            getOptionLabel={(option) => option.label}
            onKeyDown={this.selecionaModalidade}
            onClick={this.selecionaModalidade}
            onChange={this.selecionaModalidade}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Selecione um campeonado..."
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {this.state.buscando ? (
                        <CircularProgress
                          color="inherit"
                          size={20}
                          style={{
                            marginRight: "3ch",
                          }}
                        />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Col>
        <IconButton
          size="mini"
          className="bottomModal"
          color="primary"
          onClick={this.handleShow}
        >
          <AddCircleOutline />
        </IconButton>
        <Lista campeonatosCadastrados={this.state.campeonatoSelecionado} />
        <ModalAdicionar
          open_modal_campeonato={this.state.open_modal_campeonato}
          handleClose={this.handleClose}
          campeonatos={this.state.campeonatos}
          alteracaoSelectTipoCampeonato={this.alteracaoSelectTipoCampeonato}
          adicionarNomeCampeonato={this.adicionarNomeCampeonato}
          tipoCampeonatoSelecionadoId={this.state.tipoCampeonatoSelecionadoId}
          modalidadeSelecionadaId={this.state.modalidadeSelecionadaId}
          modalidadeSelecionadaNome={this.state.modalidadeSelecionadaNome}
          tipoCampeonatoSelecionadoNome={
            this.state.tipoCampeonatoSelecionadoNome
          }
          ServicosInscricaoSelecionadoId={
            this.state.ServicosInscricaoSelecionadoId
          }
          modalidades={this.state.modalidades}
          alteracaoSelectModalidades={this.alteracaoSelectModalidades}
          servicos={this.state.servicos}
          buscaServicoInscricaoCadastradas={
            this.buscaServicoInscricaoCadastradas
          }
          alteracaoSelectServicos={this.alteracaoSelectServicos}
          alteracao={this.alteracao}
          adicionaCampeonato={this.adicionaCampeonato}
        />

        <Snackbar
          open={this.state.mensagem}
          autoHideDuration={3000}
          onClose={() => {
            this.setState({ mensagem: false });
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          fullWidth
        >
          <Alert
            variant="filled"
            severity={this.state.mensagemErro ? "error" : "success"}
            onClose={() => {
              this.setState({ mensagem: false });
            }}
            onError={() =>
              setTimeout(() => {
                this.setState({ mensagem: false });
              }, 1000)
            }
          >
            {this.state.mensagemErro ? "Erro ao salvar" : "Salvo com sucesso"}
          </Alert>
        </Snackbar>
        <Snackbar
          open={this.state.mensagemSnackBar.show}
          autoHideDuration={this.state.mensagemSnackBar?.duration}
          anchorOrigin={{ vertical: "botton", horizontal: "center" }}
          onClose={() =>
            setTimeout(() => {
              this.setState({
                mensagemSnackBar: {
                  ...this.state.mensagemSnackBar,
                  show: false,
                },
              });
            }, 500)
          }
        >
          <Alert
            onClose={() =>
              setTimeout(() => {
                this.setState({
                  mensagemSnackBar: {
                    ...this.state.mensagemSnackBar,
                    show: false,
                  },
                });
              }, 500)
            }
            severity={
              this.state.mensagemSnackBar?.positive ? "success" : "error"
            }
            sx={{ width: "100%" }}
          >
            {this.state.mensagemSnackBar?.content}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}
