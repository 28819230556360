import React, { Component } from "react";
import { Header, Select } from "semantic-ui-react";
import { Row, Col } from "react-bootstrap";

import Simplificado from "./simplificado";
import Detalhado from "./detalhado";

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipoRelatorio: 0,
    };
  }

  render() {
    return (
      <div>
        <Header as="h3" dividing>
          Títulos Cadastrados
        </Header>
        <Row>
          <Col>
            <Select
              options={[
                { value: 0, text: "Simplificado" },
                { value: 1, text: "Detalhado" },
              ]}
              value={this.state.tipoRelatorio}
              onChange={(e, a) => this.setState({ tipoRelatorio: a.value })}
            />
          </Col>
        </Row>
        {this.state.tipoRelatorio === 0 && <Simplificado />}
        {this.state.tipoRelatorio === 1 && <Detalhado />}
      </div>
    );
  }
}
