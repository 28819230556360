import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { Table, Button, Segment, Icon } from "semantic-ui-react";
import { Pagination, TextField } from "@material-ui/core/";
import Autocomplete from "@material-ui/lab/Autocomplete";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import requisicoes from "../../../utils/Requisicoes";

export default class ParametroAcademiaCargoLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaCargo: [],
      listaTeste: [],

      listaCargoCadastrado: [],
      options: [],

      linhaAddCargo: 1,
      pagina: 1,
      activePage: 1,
      totalPaginas: 1,
      busca: false,
      nome: "",
    };
    this.listaCargos();
    this.listaParametrosCargos();
  }

  listaCargos = async (activePage = 1) => {
    const cargos = await api.post(
      "/parametroAcademiaCargos/listaCargos" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    let lista = [];
    for (let i = 0; i < cargos.data.length; i++) {
      const element = cargos.data[i];
      lista.push({
        value: element.id,
        label: element.nome,
      });
    }

    this.setState({
      options: lista,
    });
  };

  listaParametrosCargos = async (activePage = 1) => {
    try {
      const parametrosCargos = await api.post(
        "/parametroAcademiaCargos/listaParametrosCargos" + requisicoes.entidade,
        { activePage: activePage },
        requisicoes.header
      );
      const cargos = await api.post(
        "/parametroAcademiaCargos/listaCargos" + requisicoes.entidade,
        {},
        requisicoes.header
      );
      let lista = [];
      for (let i = 0; i < parametrosCargos.data.length; i++) {
        const element = parametrosCargos.data[i];
        for (let j = 0; j < cargos.data.length; j++) {
          const element2 = cargos.data[j];
          if (element.cargo_id === element2.id) {
            lista.push({ value: element2.id, label: element2.nome });
          }
        }
      }
      function ordenaEquipe(a, b) {
        let aAux = a.label.toLowerCase();
        let bAux = b.label.toLowerCase();
        if (aAux > bAux) {
          return 1;
        }
        if (aAux < bAux) {
          return -1;
        }
        return 0;
      }
      lista.sort(ordenaEquipe);

      this.setState({ listaCargo: lista });
    } catch (error) {
      console.log("error ", error);
    }
  };

  onChangeSelectCargo = async (event, cargo) => {
    this.state.listaCargoCadastrado = [];
    if (cargo) {
      this.state.listaCargoCadastrado.push(cargo);
      this.setState({ busca: true });
    } else {
      this.setState({ busca: false });
    }
  };

  salvaParametrosCargos = async () => {
    try {
      await api.post(
        "/parametroAcademiaCargos/salvaParametrosCargos" + requisicoes.entidade,
        { tipo: this.state.listaCargoCadastrado[0].value },
        requisicoes.header
      );
      this.state.listaCargo.unshift({
        value: this.state.listaCargoCadastrado[0].value,
        label: this.state.listaCargoCadastrado[0].label,
      });
      this.setState({ listaCargo: this.state.listaCargo });
    } catch (error) {
      console.log("Erro ao Salvar.", error);
    }
  };

  excluirParametroCargo = async (event, e) => {
    await api.post(
      "/parametroAcademiaCargos/excluiParametrosCargos" + requisicoes.entidade,
      { id: event.value },
      requisicoes.header
    );

    let index = this.state.listaCargo.indexOf(event);

    if (index !== -1) {
      this.state.listaCargo.splice(index, 1);
    }

    this.setState({
      listaCargo: this.state.listaCargo,
    });
  };
  render() {
    return (
      <div className="lista">
        <Segment clearing>
          <Table.Cell>
            <Col>
              <Autocomplete
                sx={{ width: 300 }}
                fullWidth
                id="pesquisaCargo"
                options={this.state.options}
                getOptionLabel={(option) => option.label}
                onKeyDown={this.listaCargos}
                onChange={(e, a) => this.onChangeSelectCargo(e, a)}
                renderInput={(params) => (
                  <TextField {...params} label="Busca" size="small" />
                )}
              />
            </Col>
          </Table.Cell>
          <Table.Cell
            style={{
              alignItems: "left",
            }}
          >
            <Button
              animated
              fluid
              positive
              type="submit"
              color="green"
              style={{ outline: "none", top: "8.5px" }}
              onClick={() => this.salvaParametrosCargos()}
              disabled={this.state.busca === false}
            >
              <Button.Content visible type="submit">
                Adicionar
              </Button.Content>
              <Button.Content hidden>
                <Icon name="add" />
              </Button.Content>
            </Button>
          </Table.Cell>
        </Segment>

        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign="center"></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.listaCargo.map((element, i) => (
              <Table.Row key={i}>
                <Table.Cell>{element.label}</Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    color="red"
                    icon="x"
                    onClick={(e) => this.excluirParametroCargo(element)}
                  ></Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          {/* <Table.Footer>
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan="3">
                <Pagination
                  boundaryRange={0}
                  ellipsisItem={null}
                  totalPages={this.state.totalPaginas}
                  onPageChange={this.listaCargos}
                  activePage={this.state.pagina}
                ></Pagination>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer> */}
        </Table>
      </div>
    );
  }
}
