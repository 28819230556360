import React, { Component } from "react";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import {
  Header,
  Segment,
  Modal,
  List,
  Button,
  Icon,
  Card,
} from "semantic-ui-react";
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";
import codigoOperacaoEnum from "../../../enum/CodigoOperacao";
import Select from "react-select";
import renomearChave from "../../../services/renomearChave";
// import { parseISO, format } from "date-fns";

import { parseISO, format } from "date-fns";

export default class ModalConta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      lista_instituicao: [],
      lista_agencia: [],
      historico_debito_em_conta: [],
      instituicao_cobranca: null,
      ciclo: null,
      socio: null,
      agencia: null,
      agencia_id: null,
      digito_agencia: null,
      agencia_add: null,
      digito_agencia_add: null,
      conta: null,
      digito_conta: null,
      historico_debito_conta: [],
      codigo_pessoa: null,
      dia_vencimento: null,
      tipo_operacao: null,
      tipos_operacao: codigoOperacaoEnum,
      modalAgencia: false,
      // contas_a_pagar: [],
      modalConta: false,
      fromTitular: false,
    };
    this.instituicoes();
    this.carregaHistoricoDebitoConta();
    this.pesquisaAgencia();

    // this.setState({
    //   ...this.props.debito_conta,
    //   instituicao_cobranca: this.props.debito_conta.instituicao_cobranca_id,
    //   agencia: this.props.debito_conta.agencia_debito_conta,
    // });
  }

  carregaHistoricoDebitoConta = async () => {
    try {
      const resposta = await api.post(
        "/historicoDebitoConta/lista" + requisicoes.entidade,
        {
          debito_conta_id:
            this.props.debito_conta !== null
              ? this.props.debito_conta.id
              : null,
        },
        requisicoes.header
      );
      this.setState({ historico_debito_conta: resposta.data });
    } catch (err) {
      console.log("carregaHistoricoDebitoConta: " + err);
    }
  };

  alteracao = (event) => {
    console.log("event",event.target.value)
    this.setState({ [event.target.name]: event.target.value });
  };

  abreModal = async (e) => {
    this.setState({ modalAgencia: e });
  };

  instituicoes = async () => {
    const retorno = await api.post(
      "/instituicaoCobranca/lista" + requisicoes.entidade,
      {
        debito_em_conta: true,
      },
      requisicoes.header
    );

    this.setState({
      lista_instituicao: retorno.data,
    });
  };

  pesquisaAgencia = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/agenciaDebitoConta/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaAgencia")?.value },
        requisicoes.header
      );
      let lista = [];
      console.log("retorno", retorno);

      retorno.data.forEach((element) => {
        // let objeto = renomearChave(element, "id", "value");
        // objeto = renomearChave(objeto, "agencia", "label");
        // objeto.label = objeto.label + " - " + objeto.digito_agencia;
        // lista.push(objeto);
        lista.push({
          value: element.id,
          label: element.agencia + " - " + element.digito_agencia,
          agencia: element.agencia,
          digito_agencia: element.digito_agencia,
        });
      });
      this.setState({ lista_agencia: lista });
    }, 500);
  };

  alteracaoSelectAgencia = async (event) => {
    this.setState({ agencia: event });
    this.setState({ agencia_id: event.value });
  };

  salvarAgencia = async () => {
    console.log("this.state.digito_agencia_add",this.state.digito_agencia_add)
    if(this.state.digito_agencia_add === null || this.state.digito_agencia_add === undefined){
      alert("Informe dígito agencia");
      return;
    }else{
    const agencia = await api.post(
      "/agenciaDebitoConta/salvar" + requisicoes.entidade,
      {
        agencia: this.state.agencia_add,
        digito_agencia: this.state.digito_agencia_add,
      },
      requisicoes.header
    );
    console.log("agencia", agencia);
    let objeto = renomearChave(agencia.data, "id", "value");
    objeto = renomearChave(objeto, "agencia_digito", "label");
    this.setState({ agencia: objeto });
    this.setState({ agencia_id: objeto.value });
    this.setState({ agencia_digito: "" });
    this.setState({ modalAgencia: false });
    }
  };

  submit = async (event) => {
    event.preventDefault();
    const c = this.state;

    if (c.agencia) {
      if (c.agencia_id === null) {
        c.agencia_id = c.agencia_debito_conta_id;
      }
    }

    if (
      c.instituicao_cobranca === null ||
      c.instituicao_cobranca === undefined ||
      c.instituicao_cobranca === ""
    ) {
      alert("Informe uma instituição cobrança válida");
      return;
    }
    if (c.ciclo === null || c.ciclo === undefined || c.ciclo === "") {
      alert("Informe um ciclo válido");
      return;
    }
    if (
      c.tipo_operacao === null ||
      c.tipo_operacao === undefined ||
      c.tipo_operacao === ""
    ) {
      alert("Informe um código de operação válido");
      return;
    }

    if (c.agencia_id === null || c.agencia_id === undefined) {
      alert("Informe uma agência válida");
      return;
    }
    if (c.conta === null || c.conta === undefined || c.conta === "") {
      alert("Informe uma conta válida");
      return;
    }
    if (
      c.digito_conta === null ||
      c.digito_conta === undefined ||
      c.digito_conta === ""
    ) {
      alert("Informe um dígito de conta válido");
      return;
    }
    if (
      c.dia_vencimento === null ||
      c.dia_vencimento === undefined ||
      c.dia_vencimento === ""
    ) {
      alert("Informe um dia de vencimento válido");
      return;
    }

    this.setState({ modalConta: false });
    let dados = null;

    this.setState({ modalConta: false });

    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    if (this.props.debito_conta !== null) {
      dados = {
        ciclo: c.ciclo === null ? this.props.debito_conta.ciclo : c.ciclo,
        agencia:
          c.agencia === null
            ? this.props.debito_conta.agencia
            : c.agencia.agencia,
        digito_agencia:
          c.agencia === null
            ? this.props.debito_conta.digito_agencia
            : c.agencia.digito_agencia,
        conta: c.conta === null ? this.props.debito_conta.conta : c.conta,
        digito_conta:
          c.digito_conta === null
            ? this.props.debito_conta.digito_conta
            : c.digito_conta,
        dia_vencimento:
          c.dia_vencimento === null
            ? this.props.debito_conta.dia_vencimento
            : c.dia_vencimento,
        pessoa_id: this.props.pessoa.id,
        instituicao_cobranca_id: parseInt(
          c.instituicao_cobranca === null
            ? this.props.debito_conta.banco_id
            : c.instituicao_cobranca
        ),
        remessa_gerada: false,
        proxima_remessa: "cadastro",
        tipo_operacao:
          c.tipo_operacao === null ? this.props.tipo_operacao : c.tipo_operacao,
        agencia_debito_conta_id: c.agencia.value || null,
      };
      await api
        .post(
          "/debitoConta/alterar" +
            requisicoes.entidade +
            "&id=" +
            this.props.debito_conta.id +
            "&userId=" +
            usuarioLogado.data.id,
          dados,
          requisicoes.header
        )
        .then(async (retorno) => {
          this.setState(retorno.data);
          await api
            .post(
              "/pessoa/alterar" +
                requisicoes.entidade +
                "&id=" +
                this.props.pessoa.id,
              { debito_conta_id: retorno.data.id },
              requisicoes.header
            )
            .finally(() => {
              this.props.handleCloseConta(retorno.data);
            });
        });
    } else {
      dados = {
        ciclo: c.ciclo,
        agencia: c.agencia.label,
        digito_agencia: c.digito_agencia,
        conta: c.conta,
        digito_conta: c.digito_conta,
        dia_vencimento: c.dia_vencimento,
        pessoa_id: this.props.pessoa.id,
        instituicao_cobranca_id: parseInt(this.state.instituicao_cobranca),
        agencia_debito_conta_id: parseInt(this.state.agencia_id),
        tipo_operacao: c.tipo_operacao,
        proxima_remessa: "cadastro",
      };
      const retorno = await api.post(
        "/debitoConta/salvar" +
          requisicoes.entidade +
          "&userId=" +
          usuarioLogado.data.id,
        dados,
        requisicoes.header
      );
      this.setState(retorno.data);
      // await api.post(
      //   "/pessoa/alterar" +
      //     requisicoes.entidade +
      //     "&id=" +
      //     this.props.pessoa.id,
      //   { debito_conta_id: retorno.data.id },
      //   requisicoes.header
      // );
      this.props.handleCloseConta(retorno.data);
    }
  };

  abreModalConta = async (e) => {
    this.setState({ modalConta: e });
  };

  render() {
    return (
      <Modal
        size="tiny"
        open={this.state.modalConta}
        onMount={() => {
          if (this.props.debito_conta) {
            this.setState({
              ...this.props.debito_conta,
              instituicao_cobranca: this.props.debito_conta.instituicao_cobranca_id,
              agencia: this.props.debito_conta.agencia_debito_conta,
            });
          }
        }} // ele vem as infomacoes da onde?
        trigger={
          <Button
            primary
            size="small"
            onClick={() => this.abreModalConta(true)}
          >
            <Icon name="briefcase"></Icon>
          </Button>
        }
      >
        <Modal.Header content="Débito Em Conta" />
        <Modal.Content>
          <Modal.Description>
            <Form.Label>Instituição Cobrança: </Form.Label>
            <Form.Control
              aria-describedby="basic-addon1"
              as="select"
              onChange={this.alteracao}
              name="instituicao_cobranca"
              required
              // defaultValue={
              //   this.props.debito_conta !== null &&
              //   this.props.debito_conta !== undefined &&
              //   this.props.debito_conta.instituicao_cobranca_id
              // }
              value={this.state.instituicao_cobranca}
            >
              <option value=""></option>
              {this.state.lista_instituicao.map((e) => {
                return <option value={e.id}>{e.descricao}</option>;
              })}
            </Form.Control>
            <Form.Label>Ciclo: </Form.Label>
            <Form.Control
              aria-describedby="basic-addon1"
              as="select"
              onChange={this.alteracao}
              name="ciclo"
              required
              // defaultValue={
              //   this.props.debito_conta !== null &&
              //   this.props.debito_conta !== undefined &&
              //   this.props.debito_conta.ciclo
              // }
              value={this.state.ciclo}
            >
              <option></option>
              <option value="mensal">Mensal</option>
              <option value="anual">Anual</option>
            </Form.Control>
            <Form.Label>Sócio</Form.Label>
            <FormControl
              name="socio"
              aria-describedby="basic-addon1"
              defaultValue={
                this.props.pessoa !== undefined ? this.props.pessoa.nome : ""
              }
              disabled
            />
            <Form.Label>Codigo operação</Form.Label>
            <Form.Control
              aria-describedby="basic-addon1"
              as="select"
              onChange={this.alteracao}
              name="tipo_operacao"
              required
              // defaultValue={
              //   this.props.debito_conta !== null &&
              //   this.props.debito_conta !== undefined &&
              //   this.props.debito_conta.tipo_operacao !== null &&
              //   this.props.debito_conta.tipo_operacao
              // }
              value={this.state.tipo_operacao}
            >
              <option value=""></option>
              {this.state.tipos_operacao.map((e) => {
                return <option value={e.value}>{e.label}</option>;
              })}
            </Form.Control>
            <Row>
              {/* eu acho que trocar o select  */}
              <Col sm={10}>
                <Form.Label>Agencia</Form.Label>
                <Select
                  placeholder="Buscar agencia"
                  name="agencia"
                  onKeyDown={this.pesquisaAgencia}
                  value={this.state.agencia}
                  onChange={this.alteracaoSelectAgencia}
                  inputId="pesquisaAgencia"
                  options={this.state.lista_agencia}
                  required
                />
              </Col>
              <Col sm={2}>
                {/* caracter vazio abaixo */}
                <Form.Label>⠀⠀⠀⠀⠀⠀⠀⠀</Form.Label>
                <Modal
                  open={this.state.modalAgencia}
                  closeIcon
                  size="small"
                  trigger={
                    <Button
                      positive
                      icon="add"
                      onClick={() => this.abreModal(true)}
                    ></Button>
                  }
                >
                  <Modal.Header>Nova Agencia</Modal.Header>

                  <Modal.Content>
                    <Row>
                      <Col sm={6}>
                        <Form.Label>Agencia</Form.Label>
                        <FormControl
                          name="agencia_add"
                          onChange={this.alteracao}
                          aria-describedby="basic-addon1"
                          value={this.state.agencia_add}
                          type="number"
                          required
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Label>Digito Agencia</Form.Label>
                        <FormControl
                          name="digito_agencia_add"
                          onChange={this.alteracao}
                          aria-describedby="basic-addon1"
                          value={this.state.digito_agencia_add}
                          type="number"
                          required
                        />
                      </Col>
                    </Row>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      color="red"
                      inverted
                      onClick={() => this.abreModal(false)}
                    >
                      <Icon name="remove" /> Fechar
                    </Button>
                    <Button color="green" inverted onClick={this.salvarAgencia}>
                      <Icon name="checkmark" /> Sim
                    </Button>
                  </Modal.Actions>
                </Modal>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <Form.Label>Conta</Form.Label>
                <FormControl
                  name="conta"
                  aria-describedby="basic-addon1"
                  onChange={this.alteracao}
                  required
                  // defaultValue={
                  //   this.props.debito_conta !== null &&
                  //   this.props.debito_conta !== undefined
                  //     ? this.props.debito_conta.conta
                  //     : ""
                  // }
                  value={this.state.conta}
                  type="number"
                />
              </Col>
              <Col sm={4}>
                <Form.Label>Digito Conta</Form.Label>
                <FormControl
                  name="digito_conta"
                  aria-describedby="basic-addon1"
                  onChange={this.alteracao}
                  required
                  // defaultValue={
                  //   this.props.debito_conta !== null &&
                  //   this.props.debito_conta !== undefined
                  //     ? this.props.debito_conta.digito_conta
                  //     : ""
                  // }
                  value={this.state.digito_conta}
                  type="number"
                />
              </Col>
            </Row>
            <Form.Label>Dia de Vencimento</Form.Label>
            <FormControl
              name="dia_vencimento"
              aria-describedby="basic-addon1"
              onChange={this.alteracao}
              required
              type="number"
              defaultValue={
                this.props.debito_conta !== null &&
                this.props.debito_conta !== undefined &&
                this.props.debito_conta.dia_vencimento
              }
              value={this.state.dia_vencimento}
            />
          </Modal.Description>
        </Modal.Content>
        <Segment raised>
          <Row>
            <Col sm={6}>
              <Card.Group>
                <Card>
                  {this.props.debito_conta != null &&
                    this.state.historico_debito_conta != 0 && (
                      <Card.Content>
                        <Button
                          floated="right"
                          size="mini"
                          icon="list"
                          color="olive"
                          onClick={() => this.setState({ open: true })}
                          type="button"
                        />
                        <Card.Meta style={{ color: "black" }}>
                          {this.props.debito_conta == null &&
                          this.state.historico_debito_conta <= 0
                            ? ""
                            : "Usuário: " +
                              this.state.historico_debito_conta[
                                this.state.historico_debito_conta.length - 1
                              ].usuario?.nome}
                        </Card.Meta>
                        <Card.Description style={{ color: "black" }}>
                          {this.props.debito_conta == null
                            ? ""
                            : this.state.historico_debito_conta[
                                this.state.historico_debito_conta.length - 1
                              ].usuario?.entidade +
                              ": " +
                              format(
                                parseISO(
                                  this.state.historico_debito_conta[
                                    this.state.historico_debito_conta.length - 1
                                  ].data
                                ),
                                "dd/MM/yyyy"
                              ) +
                              " " +
                              this.state.historico_debito_conta[
                                this.state.historico_debito_conta.length - 1
                              ].hora +
                              " hs"}
                        </Card.Description>
                      </Card.Content>
                    )}
                </Card>
              </Card.Group>
            </Col>
            <Col sm={6}>
              <Header floated="right">
                <Button positive onClick={this.submit}>
                  Salvar
                </Button>
                <Button negative onClick={() => this.abreModalConta(false)}>
                  Fechar
                </Button>
              </Header>
            </Col>
          </Row>
        </Segment>
        <Modal
          dimmer={"blurring"}
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
        >
          <Modal.Header>Histórico</Modal.Header>
          <Modal.Content>
            <List>
              {this.props.debito_conta != null &&
                this.state.historico_debito_conta != null &&
                this.state.historico_debito_conta.map((e, i) => (
                  <List.Item>
                    <List.Icon
                      name="file alternate"
                      size="large"
                      verticalAlign="middle"
                      color="olive"
                    />
                    <List.Content>
                      <List.Header as="a">
                        Usuário: {e.usuario?.nome}
                      </List.Header>
                      <List.Description>
                        Data: {format(parseISO(e.data), "dd/MM/yyyy")}
                      </List.Description>
                      <List.Description>Hora: {e.hora}</List.Description>
                      <List.Description>Ação: {e.acao}</List.Description>
                    </List.Content>
                  </List.Item>
                ))}
            </List>
          </Modal.Content>
          <Modal.Actions clearing>
            <Button onClick={() => this.setState({ open: false })}>
              Fechar
            </Button>
          </Modal.Actions>
        </Modal>
      </Modal>
    );
  }
}
