import React, { Component } from "react";
import { Modal, Header, Icon } from "semantic-ui-react";

export default class ModalReservaEfetivacao extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal
        closeIcon
        size="mini"
        open={this.props.modalReservaEfetivacao}
        style={{ textAlign: "center" }}
      >
        <Header icon>
          <Icon name="warning circle" />
          Reserva aguardando efetivação
        </Header>
        <Modal.Content>
          Prazo de {this.props.diasCancelamento} dias para a efetivação
        </Modal.Content>
      </Modal>
    );
  }
}
