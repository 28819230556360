import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import { IconButton, TextField, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Alert, AlertTitle } from "@material-ui/lab";
import "../styles.css";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkEditarOrganizacao: false,
    };
  }

  render() {
    return (
      <Modal dimmer="bluring" size="mini" open={this.props.modalAdicionar}>
        <Modal.Header>
          {"Adicionar Organizacao"}
          <IconButton
            className="bottomModal"
            onClick={() => this.props.adicionarOrganizacao(false)}
            color="secondary"
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>

        <Modal.Content>
          {this.props.alertaSalvando === true ? (
            <Alert severity="success">
              <AlertTitle>Organização Salva!</AlertTitle>
              Iremos redirecionar-lhe — <strong>Aguarde!</strong>
            </Alert>
          ) : (
            <Row>
              <Col sm={12}>
                <TextField
                  placeholder="Digite para buscar"
                  id="nome"
                  name="nome"
                  onChange={this.props.alteracao}
                  required
                  label="Nome"
                />
              </Col>
            </Row>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="primary"
            floated="right"
            style={{ outline: "none" }}
            onClick={this.props.salvar}
          >
            Adicionar Organizacao
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
