import React, { Component } from "react";
import requisicoes from "../../../../utils/Requisicoes";
import api from "../../../../services/api";

import { Col, Row } from "react-bootstrap";
import SearchIcon from "@material-ui/icons/Search";
import PrintIcon from "@material-ui/icons/Print";

import {
  Select,
  MenuItem,
  InputLabel,
  IconButton,
} from "@material-ui/core/";

import ListaArtilheiro from "./listaArtilheiro";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabSelected: 0,
      campeonatoSelecionadoId: null,
      campeonato: [],
      artilheiros: [],
    };
    this.buscaCampeonato();
  }
  buscaArtilheiroPorCampeonato = async () => {
    const buscarArtilheiros = await api.post(
      "/campeonato/buscaPenalidadePorCampeonato" + requisicoes.entidade,
      {
        id: this.state.campeonatoSelecionadoId,
        descricao: "pontuacao",
      },

      requisicoes.header
    );

    var infoArtilheiros = [];
    for (let i = 0; i < buscarArtilheiros.data.equipe.length; i++) {
      const element = buscarArtilheiros.data.equipe[i];
      let totalGols = 0;
      // console.log(buscarArtilheiros);
      for (let j = 0; j < element.equipe_pessoas.length; j++) {
        const element2 = element.equipe_pessoas[j];

        for (let k = 0; k < element2.evento_desporto_princ.length; k++) {
          const element3 = element2.evento_desporto_princ[k];
          totalGols += element3.pontos;
        }
        infoArtilheiros.push({
          nome_equipe: element.organizacao.nome,
          equipe_id: element.id,
          gols: totalGols,
          nome_atleta: element2.p_m_d.pessoa.nome,
        });
      }
    }

    infoArtilheiros.sort(function (a, b) {
      if (a.gols > b.gols) {
        return -1;
      }
      if (b.gols > a.gols) {
        return 1;
      }
      if (a.nome_atleta < b.nome_atleta) {
        return -1;
      }
      if (b.nome_atleta < a.nome_atleta) {
        return 1;
      }

      return 0;
    });
    this.setState({ artilheiros: infoArtilheiros });
  };
  imprimir = async () => {
    // const buscarArtilheiros = await api.post(
    //   "/campeonato/gerarPDFArtilheiro" + requisicoes.entidade,
    //   {
    //     id: this.state.campeonatoSelecionadoId,
    //     descricao: "pontuacao",
    //   },
    //   requisicoes.header
    // );

    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");

    const r = await api.get(
      "/campeonato/buscarPdfArtilheiro" +
      requisicoes.entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.id
    );
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "campeonato/buscarPdfArtilheiro" +
      requisicoes.entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.id;

    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  changeSelectCampeonatos = async (event) => {
    this.setState({ campeonatoSelecionadoId: event.target.value });
  };
  buscaCampeonato = async () => {
    const campeonato = await api.post(
      "/campeonato/listar" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    var listaCampeonato = [];
    for (let index = 0; index < campeonato.data.length; index++) {
      listaCampeonato.push({
        id: campeonato.data[index].id,
        fase_atual: campeonato.data[index].fase_atual,
        nome_modalidade: campeonato.data[index].modalidade_desporto.nome,
        campeonato_status: campeonato.data[index].modalidade_desporto.status,
        tipo_campeonato_descricao:
          campeonato.data[index].tipo_campeonato.descricao,
      });
    }

    this.setState({ campeonato: listaCampeonato });
  };

  render() {
    return (
      <div>
        <Row style={{ marginTop: "3ch" }}>
          <Col sm={6}>
            <InputLabel htmlFor="age-native-helper">Campeonatos</InputLabel>
            <Select
              variant="standard"
              fullWidth
              name="TipodeFiltro"
              inputProps={{ "aria-label": "Atletas" }}
              onChange={(event) => this.changeSelectCampeonatos(event)}
            >
              {this.state.campeonato.map((element, index) => (
                <MenuItem value={element.id}>
                  {element.nome_modalidade +
                    " Modelo: " +
                    element.tipo_campeonato_descricao +
                    " - Fase Atual: " +
                    element.fase_atual}
                </MenuItem>
              ))}
            </Select>
          </Col>
          <Col md="auto">
            <IconButton
              variant="contained"
              color="primary"
              // className={classes.button}
              onClick={() => this.buscaArtilheiroPorCampeonato()}
              style={{ outline: "none" }}
              disabled={this.state.campeonatoSelecionadoId === null}
            >
              <SearchIcon />
            </IconButton>
          </Col>
          <Col md="auto">
            <IconButton
              variant="contained"
              color="primary"
              onClick={() => this.imprimir()}
              style={{ outline: "none" }}
              disabled={this.state.campeonatoSelecionadoId === null}
            >
              <PrintIcon />
            </IconButton>
          </Col>
        </Row>
        <ListaArtilheiro artilheiros={this.state.artilheiros} />
      </div>
    );
  }
}
