import React, { Component } from "react";
import { Header, Segment, Icon } from "semantic-ui-react";
import { Col, Form, Row, FormControl, Button } from "react-bootstrap";

import moment from "moment-timezone";
import proximoDiaUtil from "../../../../utils/proximoDiaUtil";
import Meses from "../../../../enum/Mes";
import Parcelas from "../../../../enum/ParcelasEnum";
import $ from "jquery";

import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";
import ModalDebito from "./modalDebito";

export default class AcaoTituloLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo_pagamento: null,
      meses: Meses,
      parcelas: Parcelas,
      instituicoes: [],

      open_conta: false,
      debito_conta: null,
    };
    this.listaInstituicaoCobrancaBoleto();
  }
  listaInstituicaoCobrancaBoleto = async () => {
    const instituicoes = await api.post(
      "/instituicaoCobranca/lista" + requisicoes.entidade,
      { boleto: true },
      requisicoes.header
    );

    let lista = [];

    for (let i = 0; i < instituicoes.data.length; i++) {
      const element = instituicoes.data[i];
      lista.push({
        value: element.id,
        text: element.descricao + " - " + element.conta,
        id: element.id,
        registro_automatico: element.registro_automatico,
      });
    }

    this.setState({ instituicoes: lista });
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentMoney() {
    $(".dinheiro").mask("#.##0,00", {
      reverse: true,
      placeholder: "0,00",
    });
  };

  visualizaDebitoConta = () => {
    try {
      if (this.props.infoPessoa.debito_conta === null) {
        this.setState({ debito_conta: null });
      } else {
        let debito_conta = this.props.infoPessoa.debito_conta;
        if (debito_conta === undefined) {
          debito_conta = null;
        }
        if (debito_conta !== null && debito_conta.agencia_debito_conta) {
          debito_conta.agencia_debito_conta.value =
            debito_conta.agencia_debito_conta.id;
          debito_conta.agencia_debito_conta.label =
            debito_conta.agencia_debito_conta.agencia;
        }
        this.setState({ debito_conta: debito_conta });
      }
      this.setState({ open_conta: true });
    } catch (error) {
      console.log('cobranca/visualizaDebitoConta: ' + error);
    }
  };

  handleCloseModal = (dados) => {
    this.setState({ open_conta: false });
    if (dados !== null && dados.id !== null) {
      this.setState({ debito_conta: dados });
    }
  };

  // alteracaoParcelasMensalidade = (event) => {
  //   let lista = [];
  //   const indice = event.target.id;
  //   lista = this.props.infoParcelasMensalidade;
  //   lista.splice(parseInt(indice), 1, {
  //     numero: parseInt(indice) + 1,
  //     valor:
  //       event.target.name === "valorParcelaMensalidade"
  //         ? event.target.value
  //         : lista[parseInt(indice)].valor,
  //     data_vencimento:
  //       event.target.name === "dataVencimentoParcelaMensalidade"
  //         ? event.target.value
  //         : lista[parseInt(indice)].data_vencimento,
  //     mes_referencia:
  //       event.target.name === "mesReferencia"
  //         ? event.target.value.toString().split("/")[0]
  //         : lista[parseInt(indice)].mes_referencia,
  //     ano_referencia:
  //       event.target.name === "mesReferencia"
  //         ? event.target.value.toString().split("/")[1]
  //         : lista[parseInt(indice)].ano_referencia,
  //       // event.target.name === "anoReferencia"
  //       //   ? event.target.value
  //       //   : lista[parseInt(indice)].ano_referencia,
  //   });

  //   if (lista.find((e) => e.valor.toString() === "NaN")) {
  //     lista.forEach((element) => {
  //       if (element.valor.toString() === "NaN") {
  //         element.valor = "";
  //       }
  //     });
  //   }
  //   this.props.setParcelasMensalidade(lista);
  // };


  alteracaoParcelasMensalidade = (event) => {
    try {
      let lista = [];
      let parcelas = [];
      console.log("event teste ", event.target.id)
      console.log("event teste ", event.target.value)
      const indice = parseInt(event.target.id);
      lista = this.props.infoParcelasMensalidade;


      if (event.target.name === 'valorParcelaMensalidade') {
        lista.splice(indice, 1, {
          numero: indice + 1,
          valor: event.target.name === "valorParcelaMensalidade"
            ? event.target.value : lista[indice].valor,
          data_vencimento: lista[indice].data_vencimento,
          mes_referencia: lista[indice].mes_referencia,
          ano_referencia: lista[indice].ano_referencia,
        });
        console.log("lista:", lista)
        } else {
          console.log("else")
          lista.splice(indice, 1, {
            numero: indice + 1,
            valor:
              event.target.name === "valorParcelaMensalidade"
                ? event.target.value
                : lista[indice].valor,
            data_vencimento: moment(event.target.name === "dataVencimentoParcelaMensalidade"
              ? event.target.value
              : lista[indice].data_vencimento),
            mes_referencia:
              event.target.name === "mesReferencia"
                ? event.target.value
                : lista[indice].mes_referencia,
            ano_referencia:
              event.target.name === "anoReferencia"
                ? event.target.value
                : lista[indice].ano_referencia,
          });

          let data_venc = moment(
            event.target.name === "dataVencimentoParcelaMensalidade"
              ? event.target.value
              : lista[0].data_vencimento
          ).toDate();

          const atualizarListaParcelas = ((data_venc !== undefined && data_venc !== 'Invalid Date')
            && (data_venc.toString().length === 62)
            && (moment(data_venc).get('year') > 2000));

          if (atualizarListaParcelas) {
            parcelas = this.atualizaLista(data_venc, event, lista);
          }
        }

        if (parcelas !== null && parcelas !== undefined && parcelas.length > 0) {
          lista = parcelas;
        }

        if (lista.find((e) => e.valor.toString() === "NaN")) {
          lista.forEach((element) => {
            if (element.valor.toString() === "NaN") {
              element.valor = "";
            }
          });
        }
        console.log('Lista 222:',lista);
        this.props.setParcelasMensalidade(lista);
        if (event.target.name === "valorParcelaMensalidade") {
          this.componentMoney();
      }
    } catch (error) {
      console.log("alteracaoParcelasMensalidade/error: ", error);
    }
  };

  atualizaLista(data_venc, event, lista) {
    try {
      let parcelas = [];
      let diaVencimentoBase = null;

      let mes_ref = event.target.name === "mesReferencia"
        ? event.target.value
        : lista[0].mes_referencia;

      let mes_ref_id = event.target.name === "mesReferencia"
        ? Meses.find(n => { return n.value === mes_ref; }).id
        : event.target.name === "dataVencimentoParcelaMensalidade"
          ? Meses[data_venc.getMonth() - 1 < 0 ? 11 : data_venc.getMonth() - 1].id
          : Meses[data_venc.getMonth()].id;

      let ano_ref = event.target.name === "anoReferencia"
        ? event.target.value
        : event.target.name === "dataVencimentoParcelaMensalidade"
          ? data_venc.getFullYear()
          : lista[0].ano_referencia;

      let dataVencimento = '',
        dataHoje = moment(new Date(ano_ref, mes_ref_id, data_venc.getDate()));

      for (var i = 1; i <= parseInt(lista.length); i++) {
        let valor = 0;
        if (i === 1) {
          dataHoje.add(1, "month");
          dataVencimento = moment()
            .set("year", dataHoje.get("year"))
            .set("month", dataHoje.get("month"))
            .set("date", dataHoje.get('date'));
          const quantidadeDiasDoMes = moment().daysInMonth();
          const valorDia =
            this.props.acao.tipo_titulo.servico_mensalidade.valor /
            quantidadeDiasDoMes;
          const diasFaltantesFinalDoMes = moment()
            .endOf("month")
            .subtract(moment())
            .get("date");
          valor = diasFaltantesFinalDoMes * valorDia;
        } else {
          dataVencimento.add(1, "month");
          const mes = dataVencimento.get("month") + 1;
          dataVencimento = moment(dataVencimento.get("year") +
            "-" +
            (mes.length === 1 ? "0" + mes : mes) +
            "-" +
            dataHoje.get('date'));
          valor = this.props.acao.tipo_titulo.servico_mensalidade.valor;
        }
        if (diaVencimentoBase === null) diaVencimentoBase = dataHoje.get('date');
        dataVencimento.set("date", diaVencimentoBase);

        // const mes = dataVencimento.get("month") + 1;
        // dataVencimento = setMonth(dataVencimento, mes);

        parcelas.push({
          numero: i,
          valor: parseFloat(valor).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          data_vencimento: moment(proximoDiaUtil(dataVencimento)).format('YYYY-MM-DD')/*
            dataVencimento.get("year") +
            "-" +
            (mes.toString().length === 1 ? "0" + mes : mes) +
            "-" +
            (dataVencimento.get("date").toString().length === 1
              ? "0" + dataVencimento.get("date")
              : dataVencimento.get("date"))*/,
          mes_referencia: Meses[
            dataVencimento.get("month") - 1 === -1 ? 11 :
              dataVencimento.get("month") - 1].value,
          ano_referencia:
            dataVencimento.get("month") - 1 === -1
              ? dataVencimento.get("year") - 1
              : dataVencimento.get("year"),
        });
        this.props.setParcelasMensalidade(parcelas);
      }
      return parcelas;
    } catch (error) {
      console.log("atualizaLista/error: ", error);
    }
  };

  alteracaoFormaPagamentoTitulo = (event) => {
    // if (event.target.value === "parcelado") {
    //   this.setState({ tipo_pagamento: event.target.value });
    // } else {
    this.setState({
      tipo_pagamento: event.target.value,
    });
    this.props.geraParcelas(event);
    //}
  };
  handleChange(event, campo) {
    console.log("event", event)
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, ""); // Remove caracteres não numéricos
    const parsedValue = parseFloat(numericValue) / 100; // Divide por 100 para ajustar as casas decimais
    if (!isNaN(parsedValue)) {
      const formattedValue = parsedValue.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL"
      });
      this.setState({ [campo]: formattedValue }); // Usa o valor de 'campo' como chave dinâmica para atualizar o estado
    } else {
      this.setState({ [campo]: "" });
    }
  }
  render() {
    return (
      <Col
        sm={12}
        style={{ paddingTop: "10px", paddingRight: "0", paddingLeft: "0" }}
      >
        <Header as="h3">Cobrança</Header>
        <Segment.Group>
          <Segment.Group horizontal>
            <Segment>
              <Col sm={12} style={{ marginBottom: 8 }}>
                <Row>
                  <Col sm={2}>
                    <b>Forma de pagamento</b>
                  </Col>
                  <Col sm={2}>
                    <Form.Control
                      as="select"
                      name="forma_pagamento"
                      onChange={this.props.alteracao}
                    >
                      <option value="boleto" selected>Boleto</option>
                      <option value="debito_em_conta">Debito em conta</option>
                    </Form.Control>
                  </Col>
                  {this.props.forma_pagamento === "boleto" && (
                    <>

                      <Col sm={2}>
                        <b>Insitituição cobranca :</b>
                      </Col>
                      <Col sm={6}>
                        <Form.Control
                          as="select"
                          name="instituicao_cobranca_id"
                          onChange={(event) => {
                            this.props.alteracao(event);
                            let inst = this.state.instituicoes.find(
                              (e) => e.id === event.target.value
                            );
                            if (inst)
                              this.props.alteracao({
                                target: {
                                  name: "instituicao_cobranca",
                                  value: inst,
                                },
                              });
                          }}
                          value={this.props.instituicao_cobranca_id}
                        >
                          <option value=""></option>
                          {this.state.instituicoes.map((e) => (
                            <option value={e.value}>{e.text}</option>
                          ))}
                        </Form.Control>
                      </Col>
                    </>
                  )}
                  {(this.props.forma_pagamento === "debito_em_conta") ? (
                    <Col sm={1}
                      style={{ display: "flex", alignItems: "flex-start" }}
                    >
                      <Button
                        primary
                        size="small"
                        onClick={() => this.visualizaDebitoConta()}
                      >
                        <Icon name="briefcase"></Icon>
                      </Button>
                      {/* <ModalConta
                        alteracaoDebitoConta={this.alteracaoDebitoConta}
                        pessoa={this.props.infoPessoa}
                        debito_conta={this.state.debito_conta}
                        handleCloseConta={this.handleCloseConta}
                        open_conta={this.props.open_conta}
                      /> */}
                    </Col>
                  ) : null}
                </Row>
              </Col>
              <Col sm={12} style={{ marginBottom: 8 }}>
                <Row>
                  {/* <Col
                      sm={this.state.tipo_pagamento !== "parcelado" ? 6 : 3}
                  >
                    <b></b>
                  </Col> */}
                  <Col sm={4}>
                    <Row>
                      {this.props.tipoTituloDescricao !==
                        "CONTRIBUINTE SEM DEPENDENTES" && (
                          <>
                            <Col sm={6} style={{ marginBottom: 8 }}>
                              <b>Parcelamento</b>
                            </Col>
                            {this.props.servico_titulo ? (
                              <Col sm={6} style={{ marginBottom: 8 }}>
                                <Form.Control
                                  as="select"
                                  name="tipo_pagamento"
                                  onChange={this.alteracaoFormaPagamentoTitulo}
                                >
                                  <option></option>
                                  <option value="avista">Á Vista</option>
                                  <option value="parcelado">Parcelado</option>
                                </Form.Control>
                              </Col>
                            ) : (
                              <Col sm={6}>Sem Vinculo com Serviço Titulo</Col>
                            )}

                            <Col sm={6} hidden={this.state.tipo_pagamento !== "parcelado"} style={{ marginBottom: 8 }}>
                              <b>Número de Parcelas</b>
                            </Col>
                            <Col sm={6} style={{ display: "flex", marginBottom: 8 }}>
                              <Form.Control
                                hidden={this.state.tipo_pagamento !== "parcelado"}
                                as="select"
                                name="forma_pagamento_titulo"
                                onChange={this.props.geraParcelas}
                              >
                                <option></option>
                                {this.state.parcelas.map((e, i) => (
                                  <option key={i} value={e.value}>
                                    {e.label}
                                  </option>
                                ))}
                              </Form.Control>
                            </Col>

                            {(this.props.infoParcelasTitulo.length > 0) ? this.props.infoParcelasTitulo.map((e, i) => {
                              return (
                                <Row key={'kr' + e.numero}>
                                  <Col sm={12}>
                                    <Row>
                                      <Col sm={5}>
                                        Parcela {e.numero}
                                        <FormControl
                                          key={'kp' + e.numero}
                                          id={"p_" + e.numero}
                                          //onBlur={this.alteracaoParcelasTitulo}
                                          value={e.valor}
                                          // onKeyUp={this.componentMoney}
                                          onChange={(event) => this.handleChange(event, "valor_titulo")}
                                          name="valorParcelaTitulo"
                                          className="dinheiro1"
                                          disabled={true}
                                        ></FormControl>
                                      </Col>
                                      <Col sm={6}>
                                        Vencimento
                                        <FormControl
                                          key={'kv' + e.numero}
                                          type="date"
                                          value={e.data_vencimento}
                                          id={"v_" + e.numero}
                                          //onBlur={this.alteracaoParcelasTitulo}
                                          name="dataVencimentoTitulo"
                                          disabled={true}
                                        ></FormControl>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              );
                            }) : null
                            }

                          </>
                        )}
                    </Row>
                  </Col>

                  <Col sm={8}>
                    <Row>
                      <Col sm={3}>
                        <b>Mensalidade:</b>
                      </Col>
                      <Col sm={9}>
                        <Form.Control
                          placeholder="Tipo"
                          as="select"
                          name="forma_pagamento_mensalidade"
                          onChange={this.props.geraParcelas}
                        >
                          <option></option>
                          <option value="1x">1</option>
                          <option value="2x">2</option>
                          <option value="3x">3</option>
                          <option value="4x">4</option>
                          <option value="5x">5</option>
                          <option value="6x">6</option>
                          <option value="7x">7</option>
                          <option value="8x">8</option>
                          <option value="9x">9</option>
                          <option value="10x">10</option>
                          <option value="11x">11</option>
                          <option value="12x">12</option>
                        </Form.Control>
                      </Col>
                      {console.log(this.props.infoParcelasMensalidade)}
                      {console.log(this.props.infoParcelasMensalidade.length)}
                      {this.props.infoParcelasMensalidade.map((e, i) => {
                        return (
                          <Row>
                            <Col key={i} sm={12}>
                              <Row>
                                <Col sm={3}>
                                  Parcela {e.numero}
                                  <Form.Control
                                    id={"" + i + ""}
                                    value={e.valor}
                                    onKeyUp={this.componentMoney}
                                    onChange={this.alteracaoParcelasMensalidade}
                                    name="valorParcelaMensalidade"
                                    className="dinheiro"
                                    disabled={i === 0 ? false : true}
                                  ></Form.Control>
                                </Col>
                                <Col sm={4}>
                                  Mês de referência 3
                                  <Form.Control
                                    id={"" + i + ""}
                                    as="select"
                                    name="mesReferencia"
                                    disabled={i === 0 ? false : true}
                                    onChange={this.alteracaoParcelasMensalidade}
                                    value={
                                      this.props.infoParcelasMensalidade[i].mes_referencia
                                    }
                                  >
                                    <option></option>
                                    {this.state.meses.map((m, i) => (
                                      <option key={i} value={m.value}>
                                        {m.label + "/" + e.ano_referencia}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Col>
                                {/* <Col sm={2}>
                                  Ano
                                  <Form.Control
                                    id={"" + i + ""}
                                    defaultValue={e.ano_referencia}
                                    name="anoReferencia"
                                    onBlur={this.alteracaoParcelasMensalidade}
                                  ></Form.Control>
                                </Col> */}
                                <Col sm={5}>
                                  Vencimento
                                  <FormControl
                                    type="date"
                                    disabled={i === 0 ? false : true}
                                    value={e.data_vencimento}
                                    id={"" + i + ""}
                                    onBlur={this.alteracaoParcelasMensalidade}
                                    onChange={this.alteracaoParcelasMensalidade}
                                    name="dataVencimentoParcelaMensalidade"
                                  ></FormControl>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Segment>
          </Segment.Group>
        </Segment.Group>
        {(this.state.open_conta === true) && (
          <ModalDebito
            alteraDebitoConta={this.alteraDebitoConta}
            handleCloseModal={this.handleCloseModal}
            pessoa={this.props.infoPessoa}
            open_conta={this.state.open_conta}
            debito_conta={this.state.debito_conta}
          />
        )}
      </Col>
    );
  }
}
