import React, { Component } from "react";
import { Table, Button } from "semantic-ui-react";
import { Row, Col, Form } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";

import moment from "moment-timezone";
import api from "../../../services/api";
import { valorFormatter } from "../../../utils/formatterValor";

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titulosCancelados: [],
      buscando: false,
      imprimindo: false,
      listaCancelados: [],
      //Filtros
      data_inicio: moment().startOf("month").format("YYYY-MM-DD"),
      data_final: moment().format("YYYY-MM-DD"),
      tipo: "emissao",
    };
  }

  imprimir = async () => {
    this.setState({ imprimindo: true });
    try {
      await api.post(
        "/tipoTitulo/gerarPdfTitulosCancelados" + requisicoes.entidade,
        {
          tipo: this.state.tipo,
          data_inicio: this.state.data_inicio,
          data_final: this.state.data_final,
          listaCancelados: this.state.listaCancelados
        },
        requisicoes.header
      );

      var localStorage = window.localStorage;
      var token = localStorage.getItem("token_clube");

      const r = await api.get(
        "/tipoTitulo/buscarArquivoPdfTitulosCancelados" +
        requisicoes.entidade +
        "&t=Baer " +
        token
      );
      var oReq = new XMLHttpRequest();

      var URLToPDF =
        r.config.baseURL +
        "tipoTitulo/buscarArquivoPdfTitulosCancelados" +
        requisicoes.entidade +
        "&t=Baer " +
        token;

      oReq.open("GET", URLToPDF, true);
      oReq.responseType = "blob";
      oReq.onload = function () {
        const file = new Blob([oReq.response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      };
      oReq.send();
    } catch (e) { }

    this.setState({ imprimindo: false });
  };

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  buscaTitulosCancelados = async (event, a) => {
    this.setState({ buscando: true });
    try {
      const titulosCancelados = await api.post(
        "/tipoTitulo/buscaCancelados" + requisicoes.entidade,
        {
          tipo: this.state.tipo,
          data_inicio: this.state.data_inicio,
          data_final: this.state.data_final,
        },
        requisicoes.header
      );
      const lancamentoConta = await api.post(
        "/lancamentoConta/pesquisaLancamentosCanceladoPorPessoa" + requisicoes.entidade,
        {
          listaCancelados: titulosCancelados.data,
        },
        requisicoes.header
      )
      this.setState({
        listaCancelados: lancamentoConta.data,
        titulosCancelados: lancamentoConta.data,
        buscando: false,
      });
    } catch (e) { }
    this.setState({ buscando: false });
  };

  render() {
    return (
      <div>
        {
          <div>
            <Row>
              <Col sm={3}>
                <Form.Label>Data Inicio</Form.Label>
                <Form.Control
                  placeholder="Data Inicio"
                  aria-describedby="basic-addon1"
                  type="date"
                  name="data_inicio"
                  value={this.state.data_inicio}
                  onChange={this.alteracao}
                />
              </Col>
              <Col sm={3}>
                <Form.Label>Data Final</Form.Label>
                <Form.Control
                  placeholder="Data Final"
                  aria-describedby="basic-addon1"
                  type="date"
                  name="data_final"
                  value={this.state.data_final}
                  onChange={this.alteracao}
                />
              </Col>
              <Col md="auto">
                <Form.Label>Tipo Busca</Form.Label>
                <Form.Control
                  as="select"
                  name="tipo"
                  checked={this.state.tipo}
                  id="tipo"
                  value={this.state.tipo}
                  required
                  onChange={this.alteracao}
                >
                  <option value="emissao">Data de emissão</option>
                  <option value="cancelamento">Data de cancelamento</option>
                </Form.Control>
              </Col>
              <Col md="auto">
                <Button
                  color="green"
                  style={{ marginTop: "25px" }}
                  onClick={this.buscaTitulosCancelados}
                  loading={this.state.buscando}
                  disabled={this.state.buscando}
                >
                  Buscar
                </Button>
              </Col>
              <Col md="auto">
                <Button
                  color="blue"
                  style={{ marginTop: "25px" }}
                  onClick={this.imprimir}
                  loading={this.state.imprimindo}
                  disabled={
                    this.state.imprimindo ||
                    this.state.titulosCancelados.length === 0
                  }
                >
                  Imprimir
                </Button>
              </Col>
            </Row>

            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Nº Título</Table.HeaderCell>
                  <Table.HeaderCell>Pessoa</Table.HeaderCell>
                  <Table.HeaderCell>Tipo de Título</Table.HeaderCell>
                  <Table.HeaderCell>Data de emissão</Table.HeaderCell>
                  <Table.HeaderCell>Data de cancelamento</Table.HeaderCell>
                  <Table.HeaderCell>Total da dívida</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.titulosCancelados.length === 0
                  ? null
                  : this.state.titulosCancelados.map((e, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>
                        {e?.codigo
                          ? e?.codigo
                          : e?.codigo_auxiliar
                            ? e?.tipo_titulo_prefixo + " " + e?.codigo_auxiliar
                            : ""}
                      </Table.Cell>
                      <Table.Cell>
                        {e?.nome}
                      </Table.Cell>
                      <Table.Cell>{e?.tipo_titulo}</Table.Cell>
                      <Table.Cell>
                        {moment(e?.data_emissao).format("DD/MM/YYYY")}
                      </Table.Cell>
                      <Table.Cell>
                        {moment(e?.data_cancelamento).format("DD/MM/YYYY")}
                      </Table.Cell>
                      <Table.Cell>
                        {valorFormatter.format(e?.valor)}
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </div>
        }
      </div>
    );
  }
}
