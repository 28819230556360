import React, { Component } from "react";
import { Header, Segment } from "semantic-ui-react";
import requisicoes from "../../../../utils/Requisicoes";
import api from "../../../../services/api";
import Icon from "@material-ui/core/Icon";
import { Form, FormControl, Col, Row } from "react-bootstrap";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SearchIcon from "@material-ui/icons/Search";
import PrintIcon from "@material-ui/icons/Print";
import {
  Select,
  TextField,
  MenuItem,
  InputLabel,
  Button,
  IconButton,
  Menu,
} from "@material-ui/core/";

import ListaCartoes from "./listaCartoes";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabSelected: 0,
      campeonato: [],
      campeonatoSelecionadoId: null,
      cartoesVermelho: [],
    };
    this.buscaCampeonato();
  }
  buscaCampeonato = async () => {
    const campeonato = await api.post(
      "/campeonato/listar" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    var listaCampeonato = [];
    for (let index = 0; index < campeonato.data.length; index++) {
      listaCampeonato.push({
        id: campeonato.data[index].id,
        fase_atual: campeonato.data[index].fase_atual,
        nome_modalidade: campeonato.data[index].modalidade_desporto.nome,
        campeonato_status: campeonato.data[index].modalidade_desporto.status,
        tipo_campeonato_descricao:
          campeonato.data[index].tipo_campeonato.descricao,
      });
    }

    this.setState({ campeonato: listaCampeonato });
  };
  changeSelectCampeonatos = async (event) => {
    this.setState({ campeonatoSelecionadoId: event.target.value });
  };
  buscarCartoesPorCampeonato = async () => {
    const buscarCartoes = await api.post(
      "/campeonato/buscaPenalidadePorCampeonato" + requisicoes.entidade,
      {
        id: this.state.campeonatoSelecionadoId,
        descricao: "penalidade",
      },

      requisicoes.header
    );
    this.setState({ cartoesVermelho: buscarCartoes.data.equipe });
  };
  imprimir = async () => {
    this.setState({ imprimindo: true });

    try {
      await api.post(
        "campeonato/gerarPdfPenalidade" + requisicoes.entidade,

        {
          id: this.state.campeonatoSelecionadoId,
          descricao: "penalidade",
        },
        requisicoes.header
      );

      const usuarioLogado = await api.post(
        "/usuario/usuarioLogado" + requisicoes.entidade,
        {},
        requisicoes.header
      );

      var localStorage = window.localStorage;
      var token = localStorage.getItem("token_clube");

      const r = await api.get(
        "/campeonato/buscarPdfCartoesPenalidade" +
          requisicoes.entidade +
          "&t=Baer " +
          token +
          "&userId=" +
          usuarioLogado.data.id
      );
      var oReq = new XMLHttpRequest();

      var URLToPDF =
        r.config.baseURL +
        "campeonato/buscarPdfCartoesPenalidade" +
        requisicoes.entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.id;

      oReq.open("GET", URLToPDF, true);
      oReq.responseType = "blob";
      oReq.onload = function () {
        const file = new Blob([oReq.response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      };
      oReq.send();
    } catch (e) {
      console.log("error: ", e);
    }

    this.setState({ imprimindo: false });
  };

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <div>
        <Row style={{ marginTop: "3ch" }}>
          <Col sm={6}>
            <InputLabel htmlFor="age-native-helper">Campeonatos</InputLabel>
            <Select
              variant="standard"
              fullWidth
              name="TipodeFiltro"
              inputProps={{ "aria-label": "Atletas" }}
              onChange={(event) => this.changeSelectCampeonatos(event)}
            >
              {this.state.campeonato.map((element, index) => (
                <MenuItem value={element.id}>
                  {element.nome_modalidade +
                    " Modelo: " +
                    element.tipo_campeonato_descricao +
                    " - Fase Atual: " +
                    element.fase_atual}
                </MenuItem>
              ))}
            </Select>
          </Col>
          <Col md="auto">
            <IconButton
              variant="contained"
              color="primary"
              // className={classes.button}
              onClick={() => this.buscarCartoesPorCampeonato()}
              style={{ outline: "none" }}
              disabled={this.state.campeonatoSelecionadoId === null}
            >
              <SearchIcon />
            </IconButton>
          </Col>
          <Col md="auto">
            <IconButton
              variant="contained"
              color="primary"
              onClick={() => this.imprimir()}
              style={{ outline: "none" }}
              disabled={this.state.campeonatoSelecionadoId === null}
            >
              <PrintIcon />
            </IconButton>
          </Col>
        </Row>
        <ListaCartoes cartoesVermelho={this.state.cartoesVermelho} />
      </div>
    );
  }
}
