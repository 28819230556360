import React, { Component } from "react";
import {
  Container,
  Table,
  Header,
  Modal,
  Button,
  Card,
} from "semantic-ui-react";
import { Col, Form, FormControl, Row } from "react-bootstrap";
import moment from "moment-timezone";
import { Link } from "react-router-dom";

export default class ModalReservas extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Card.Content
        style={{
          maxHeight: 250,
          flex: 1,
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Card.Header>Reservas</Card.Header>
        <Card.Description>
          <Row>
            {/* <Col sm={11}><Header as="h3">Reservas</Header></Col> */}
            <Col sm={1}>
              <Link
                to={{
                  pathname: "/reservas",
                  query: {
                    select: { value: "venda", label: "Vendas" },
                    pessoa: this.state.pessoa,
                  },
                }}
                style={{
                  marginLeft: "1%",
                  paddingTop: "0px",
                  marginTop: "0px",
                }}
              >
                <Button color="green" icon={"add"}></Button>
              </Link>
            </Col>
          </Row>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Serviço</Table.HeaderCell>
                <Table.HeaderCell>Data Reservada</Table.HeaderCell>
                {(localStorage.getItem("tipo_usuario_clube") ===
                  "administrador" ||
                  localStorage.getItem("tipo_usuario_clube") ===
                  "administrativo" ||
                  localStorage.getItem("tipo_usuario_clube") === "dependente" ||
                  localStorage.getItem("tipo_usuario_clube") ===
                  "administradorSistema" ||
                  localStorage.getItem("tipo_usuario_clube") === "titular" ||
                  localStorage.getItem("tipo_usuario_clube") === "caixa") && (
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                  )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.reservas.length === 0 ? (
                <div style={{ margin: "20px" }}>
                  <h3>Não possui reservas ativas!</h3>
                </div>
              ) : (
                this.props.reservas.map((e, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{e.horario_servico.servico.nome}</Table.Cell>
                    <Table.Cell>
                      {moment(e.dataReserva).format("DD/MM/YYYY")}
                    </Table.Cell>
                    {(localStorage.getItem("tipo_usuario_clube") ===
                      "administrador" ||
                      localStorage.getItem("tipo_usuario_clube") ===
                      "administrativo" ||
                      localStorage.getItem("tipo_usuario_clube") ===
                      "administradorSistema" ||
                      localStorage.getItem("tipo_usuario_clube") ===
                      "dependente") && (
                        <Table.Cell width={1}>
                          {e.dataCancelamento == null && (
                            <Modal
                              size="small"
                              trigger={
                                <Button circular icon="remove" negative></Button>
                              }
                            >
                              <Modal.Content>
                                <Container textAlign="center">
                                  <Col sm={12}>
                                    <Form.Label>Motivo </Form.Label>
                                    <FormControl
                                      aria-label="Motivo"
                                      aria-describedby="basic-addon1"
                                      name="motivo_cancelamento"
                                      onChange={this.props.alteracao}
                                    />
                                  </Col>
                                  <br />
                                  Confirma Cancelamento?
                                  <br />
                                  <br />
                                  <Button
                                    primary
                                    size="tiny"
                                    type="button"
                                    value={e.id}
                                    onClick={this.props.cancelarReserva}
                                  >
                                    Sim
                                  </Button>
                                </Container>
                              </Modal.Content>
                            </Modal>
                          )}
                        </Table.Cell>
                      )}
                  </Table.Row>
                ))
              )}
            </Table.Body>
          </Table>
        </Card.Description>
      </Card.Content>
    );
  }
}
