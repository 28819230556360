import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { Modal } from "semantic-ui-react";
import { TextField, Button, ThemeProvider } from "@material-ui/core";

import { green } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

export default class ModalAdicionaPermissao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // inputs
      nome: "",
    };
  }

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.onClose}>
        <Modal.Header>Adicionar Menu</Modal.Header>
        <Modal.Content>
          <TextField
            style={{ margin: 10 }}
            variant="outlined"
            label="Nome do menu"
            value={this.state.nome}
            onChange={(e) => this.setState({ nome: e.target.value })}
            fullWidth
          />
        </Modal.Content>
        <Modal.Actions>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="auto">
              <Button
                onClick={this.props.onClose}
                variant="contained"
                color="inherit"
              >
                Voltar
              </Button>
            </Col>
            <Col md="auto">
              <ThemeProvider
                theme={createTheme({
                  palette: {
                    primary: green,
                  },
                })}
              >
                <Button
                  variant="contained"
                  style={{ color: "#FFFFFF" }}
                  onClick={() => this.props.addMenu({ nome: this.state.nome })}
                  disabled={this.state.nome.trim() === ""}
                >
                  Adicionar
                </Button>
              </ThemeProvider>
            </Col>
          </Row>
        </Modal.Actions>
      </Modal>
    );
  }
}
