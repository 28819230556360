import React, { Component } from "react";
import {
  Header,
  Menu,
  Table,
  Button,
  Modal,
  Icon,
  Card,
  Checkbox,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Form as formulario, FormControl, Row, Col } from "react-bootstrap";
import { Tooltip, Input, TextField } from "@material-ui/core";
import { LocalizationProvider, DesktopTimePicker } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns/index";
import { HelpOutline } from "@material-ui/icons/";
import brLocale from "date-fns/locale/pt-BR";
import Select from "react-select";

// requisicao
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

// import SportsBaseballIcon from "@material-ui/icons/SportsBaseball";
// import SportsVolleyballIcon from "@material-ui/icons/SportsVolleyball";
// import SportsTennisIcon from "@material-ui/icons/SportsTennis";
// import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
// import GroupWorkIcon from "@material-ui/icons/GroupWork";
// import ExtensionIcon from "@material-ui/icons/Extension";
// Utils
import moment from "moment";
import "../../../styles/styles.css";

// Componentes
import Feriados from "../administrativo/feriado/lista";
import Servicos from "./servicos/index";
import Turmas from "./turmas/index";

let buscando = false;

export default class Contabil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsServicos: [],
      listaGrupoServico: [],
      listaServico: [],

      menuDiversosSelected: "",
      grupo_servico_id_search: null,
      listaDiversos: [],
      listaDiversosBackup: [],

      parametros: [],
      listaGrupoParametro: [],
      selectedMenu:
        /*this.props.submenus &&
        this.props.submenus.find((e) => e.nome === "grupoServico")
          ? "grupoServico"
          :*/ "",
      descricao: "",
      alert: false,
      servicos: false,
      diversos: false,
      parametrosReservaEfetivada: [],
      semParametrosReservaEfetivada: [],
      parametrosReservaEvento: [],
      semParametrosReservaEvento: [],
      diasCancelamento: "",
      grupo_servico_id: null,
      nome: "",
      texto: "",
      textoEdicao: "",
      modalAlteracao: false,

      capacidade: null,
      listaDiasSemana: [],
      listaDiasReserva: [],
      listaDiasReservaLiberada: [],
      listaDiasReservaLiberadaServico: [],
      diasDistanciamento: null,
      parametrosDiasDaSemanaGrupo: [],
      menusSelect: "permissao_reserva",
      desportoPermitido: [],
      desportoNaoPermitido: [],
      icon: "",
      iconSelecionado: "",
      iconNome: "",
      modalIcons: false,
      salvando: false,
      openModalGrupos: false,
      historicoTeremosAltecao: [],
      alterado: false,

      parametroExigeCPFConvidado: false,
      parametroExigeCPF: null,
    };
    this.carregaTab();
    this.listaGrupoServico();
    this.buscaParametros();
    this.historicoTermos();
  }

  carregaTab = async () => {

    const selectedMenuStorage = window.localStorage.getItem('selectedMenu');
    if (this.props.selectedMenu !== null) {
       this.state.selectedMenu = selectedMenuStorage
    }
  }
  /*buscaServico = async (event, a) => {
    let resp = await api.post(
      "/servico/diversosPorGrupoServico" + requisicoes.entidade,
      { pesquisa: event.target.value },
      requisicoes.header
    );


    let lista = [];
    for (let i = 0; i < resp.data.length; i++) {
      const element = resp.data[i];
      lista.push({ key: element.id, text: element.nome, value: element.id });
    }
    this.setState({ optionsServicos: lista });
  };
  */
  buscaServicoDiversos = async () => {
    if (buscando === true) return;
    buscando = true;

    setTimeout(async () => {
      var id = this.state.grupo_servico_id_search;
      if (this.state.listaDiversosBackup.length <= 0)
        this.carregaListaDiversos(id);

      var filterValue = document.getElementById("diversosPesquisa").value;
      console.log(
        "grupo_servico_id_search: " +
          this.state.grupo_servico_id_search +
          "\nfilterValue: " +
          filterValue
      );

      var resp = [];
      if (id != null) {
        resp = this.state.listaDiversosBackup.filter(function (s) {
          console.log(
            "s.nome: " +
              s.nome.toLowerCase().includes(filterValue.toLowerCase()) +
              "contains(" +
              filterValue.toLowerCase() +
              ") && s.grupo_servico_id: " +
              s.grupo_servico_id +
              " == id: " +
              id +
              " = " +
              s.grupo_servico_id ==
              id
          );
          return (
            s.nome.toLowerCase().includes(filterValue.toLowerCase()) &&
            s.grupo_servico_id == id
          );
        });
      } else {
        resp = this.state.listaDiversosBackup.filter(function (s) {
          console.log("s.nome: " + s.nome + " | s.id: " + s);
          return s.nome.toLowerCase().includes(filterValue.toLowerCase());
        });
      }
      buscando = false;
      this.setState({ listaDiversos: resp });
    }, 300);
  };

  historicoTermos = async () => {
    const respostaTermos = await api.post(
      "/parametrosReservaTermos/historicoReservaTermos" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    const usuarioLogado = await api.post(
      "/usuario/pesquisa" + requisicoes.entidade,
      { id: 29 },
      requisicoes.header
    );

    this.setState({ historicoTeremosAltecao: respostaTermos });
  };
  buscaParametrosReserva = async (id) => {
    const diasSemana = await api.post(
      "/diaDaSemanan/lista" + requisicoes.entidade,
      this.props.location.query,
      requisicoes.header
    );
    let index = 0;
    let listaDiasReserva = [];
    diasSemana.data.forEach((e) => {
      diasSemana.data.forEach((dia) => {
        listaDiasReserva.push({
          id: null,
          dia_da_semana_id: e.id,
          dia_da_reserva_id: dia.id,
          libera: false,
          indice: index + 1,
        });
        index = index + 1;
      });
    });
    this.setState({ listaDiasSemana: diasSemana.data });
    this.setState({ listaDiasReserva: diasSemana.data });
    this.setState({ listaDiasReservaLiberada: listaDiasReserva });

    const resposta = await api.post(
      "/servico/servicoPorGrupoServico" + requisicoes.entidade,
      { id: id },
      requisicoes.header
    );
    if(resposta.data != null && resposta.data.length > 0) {
      this.setState({
        capacidadeGrupo: resposta.data[0].capacidade
      });
    }
    const respostaParametros = await api.post(
      "/parametrosReservaEfetivada/lista" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    const respostaParametrosEvento = await api.post(
      "/parametrosReservaEvento/lista" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    const respostaTermos = await api.post(
      "/parametrosReservaTermos/lista" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    this.buscaParametroListaConvidados();
    let listaServico = [];
    let listaRetorno = [];
    let listaRetornoServico = [];
    let listaRetornoEvento = [];
    let listaRetornoServicoEvento = [];
    this.setState({ parametrosReservaEfetivada: [] });
    this.setState({ semParametrosReservaEfetivada: [] });
    this.setState({ parametrosReservaEvento: [] });
    this.setState({ semParametrosReservaEvento: [] });

    let servicos = resposta.data;
    let parametros = respostaParametros.data;
    let parametrosEvento = respostaParametrosEvento.data;
    const termos = respostaTermos.data;

    servicos.sort((a, b) => {
      if (a.nome > b.nome) {
        return 1;
      }
      if (a.nome < b.nome) {
        return -1;
      }
      return 0;
    });

    for (let index = 0; index < servicos.length; index++) {
      const servico = servicos[index];

      let texto = "";
      let termos_id = null;

      const buscaTermos = termos.find(
        (e) => parseInt(e.servico_id) === parseInt(servico.id)
      );
      if (buscaTermos !== undefined) {
        texto = buscaTermos.texto;
        termos_id = buscaTermos.id;
      }

      const diaReservaLiberada = servico.dia_reserva_liberada;

      const respostaDistanciamentoDia =
        servico.parametros_reserva_distanciamento_dia;
      // const respostaDistanciamentoDia = await api.post(
      //   "/parametrosReservaDistanciamentoDia/visualiza" + requisicoes.entidade,
      //   { id: servico.id },
      //   requisicoes.header
      // );
      let diasDistanciamento = {};
      if (respostaDistanciamentoDia) {
        diasDistanciamento = respostaDistanciamentoDia;
      }

      const respostaFrequenciaReserva = servico.parametros_reserva_frequencia;

      let listaDiasReservaLiberada = [];
      const diasReserva = diaReservaLiberada;
      if (diasReserva.length > 0) {
        listaDiasReservaLiberada = diasReserva;
      }

      if (parametros.length > 0) {
        const listaParametros = parametros.filter(
          (e) => parseInt(e.servico_id) === parseInt(servico.id)
        );
        if (listaParametros.length > 0) {
          for (let j = 0; j < listaParametros.length; j++) {
            const parametro = listaParametros[j];
            if (parseInt(servico.id) === parseInt(parametro.servico_id)) {
              listaRetorno.push({
                id: parametro.id,
                nome: servico.nome,
                servico_id: servico.id,
              });
              listaServico.push({
                id: servico.id,
                nome: servico.nome,
                parametro: true,
                parametro_id: parametro.id,
                diasCancelamento: parametro.diasCancelamento,
                capacidade: parametro.capacidade,
                termos_id: termos_id,
                texto: texto,
                listaDiasReservaLiberada: listaDiasReservaLiberada,
                diasDistanciamento: diasDistanciamento,
                frequenciaDias: respostaFrequenciaReserva,
              });
            } else {
              listaRetornoServico.push({
                id: servico.id,
                nome: servico.nome,
              });
              listaServico.push({
                id: servico.id,
                nome: servico.nome,
                parametro: false,
                parametro_id: null,
                diasCancelamento: null,
                // capacidade: null,
                capacidade: servico.capacidade,
                termos_id: termos_id,
                texto: texto,
                listaDiasReservaLiberada: listaDiasReservaLiberada,
                diasDistanciamento: diasDistanciamento,
                frequenciaDias: respostaFrequenciaReserva,
              });
            }
          }
        } else {
          listaRetornoServico.push({
            id: servico.id,
            nome: servico.nome,
          });
          listaServico.push({
            id: servico.id,
            nome: servico.nome,
            parametro: false,
            parametro_id: null,
            diasCancelamento: null,
            // capacidade: null,
            capacidade: servico.capacidade,
            termos_id: termos_id,
            texto: texto,
            listaDiasReservaLiberada: listaDiasReservaLiberada,
            diasDistanciamento: diasDistanciamento,
            frequenciaDias: respostaFrequenciaReserva,
          });
        }
      } else {
        listaRetornoServico.push({
          id: servico.id,
          nome: servico.nome,
        });
        listaServico.push({
          id: servico.id,
          nome: servico.nome,
          parametro: false,
          parametro_id: null,
          diasCancelamento: null,
          // capacidade: null,
          capacidade: servico.capacidade,
          termos_id: termos_id,
          texto: texto,
          listaDiasReservaLiberada: listaDiasReservaLiberada,
          diasDistanciamento: diasDistanciamento,
          frequenciaDias: respostaFrequenciaReserva,
        });
      }
    }

    servicos.forEach((servico) => {
      if (parametrosEvento.length > 0) {
        const listaParametrosEvento = parametrosEvento.filter(
          (e) => parseInt(e.servico_id) === parseInt(servico.id)
        );
        if (listaParametrosEvento.length > 0) {
          listaParametrosEvento.forEach((parametro) => {
            if (parseInt(servico.id) === parseInt(parametro.servico_id)) {
              listaRetornoEvento.push({
                id: parametro.id,
                nome: servico.nome,
                servico_id: servico.id,
              });
            } else {
              listaRetornoServicoEvento.push({
                id: servico.id,
                nome: servico.nome,
              });
            }
          });
        } else {
          listaRetornoServicoEvento.push({
            id: servico.id,
            nome: servico.nome,
          });
        }
      } else {
        listaRetornoServicoEvento.push({
          id: servico.id,
          nome: servico.nome,
        });
      }
    });
    setTimeout(() => {
      this.setState({ parametrosReservaEfetivada: listaRetorno });
      this.setState({ semParametrosReservaEfetivada: listaRetornoServico });
      this.setState({ parametrosReservaEvento: listaRetornoEvento });
      this.setState({ semParametrosReservaEvento: listaRetornoServicoEvento });
      this.setState({ listaServico: listaServico });
    }, 2000);
  };
  buscaParametroListaConvidados = async () => {
    const exige_cpf_convidado = await api.post(
      "/parametros/visualiza" + requisicoes.entidade,
      { nome: 'exige_cpf_convidado'},
      requisicoes.header
    );
    if(exige_cpf_convidado !== null && exige_cpf_convidado !== undefined && exige_cpf_convidado.data[0] !== null) {
      this.setState({
        parametroExigeCPFConvidado: exige_cpf_convidado.data[0].valor === 'true',
        parametroExigeCPF: exige_cpf_convidado.data[0],
      });
    }
  }

  buscaParametros = async () => {
    const resposta = await api.post(
      "/parametrosGrupoReserva/lista" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    const grupoServico = await api.post(
      "/grupoServico/lista" + requisicoes.entidade,
      "",
      requisicoes.header
    );

    this.setState({ listaGrupoParametro: [] });
    this.setState({ parametros: [] });
    let parametros = resposta.data;
    let grupos = grupoServico.data;

    for (let i = 0; i < parametros.length; i++) {
      const element = parametros[i];
      let grupo = grupos.find((e) => e.id === element.grupo_servico_id);
      grupo.titular = element.titular;
      grupo.dependente = element.dependente;
      grupo.dias_frequencia = element.dias_frequencia;
      grupo.hora_inicio = element.hora_inicio
        ? moment(element.hora_inicio, "HH:mm:ss")
        : null;
      grupo.hora_fim = element.hora_fim
        ? moment(element.hora_fim, "HH:mm:ss")
        : null;
    }

    this.setState({ listaGrupoParametro: grupos });
  };

  listaGrupoServico = async () => {
    const listaTrue = [];
    const listaFalse = [];
    const resposta = await api.post(
      "/grupoServico/lista" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    resposta.data.sort((a, b) => {
      if (a.descricao > b.descricao) {
        return 1;
      }
      if (a.descricao < b.descricao) {
        return -1;
      }
      return 0;
    });

    this.setState({ listaGrupoServico: resposta.data });

    for (let a = 0; a < resposta.data.length; a++) {
      resposta.data[a].desporto === true
        ? listaTrue.push(resposta.data[a])
        : listaFalse.push(resposta.data[a]);
    }

    this.setState({ desportoPermitido: listaTrue });
    this.setState({ desportoNaoPermitido: listaFalse });
  };

  editarGrupo = async (e, status) => {
    const retorno = await api.post(
      "/grupoServico/editar" + requisicoes.entidade,
      {
        id: e,
        descricao: this.state.descricao,
      },
      requisicoes.header
    );
    if (retorno != null) {
      window.location.href = "/servicos";
    }
  };

  editarParametro = async (e) => {
    this.setState({ modalAlteracao: false });
    console.log("this.state.diasCancelamento",this.state.diasCancelamento);

    await api.post(
      "/parametrosReservaEfetivada/editar" + requisicoes.entidade,
      {
        id: e,
        diasCancelamento: this.state.diasCancelamento,
      },
      requisicoes.header
    );
    this.buscaParametrosReserva(this.state.grupo_servico_id);
  };

  salvarParametroCapacidade = async (e, servico_id) => {
    this.setState({ modalAlteracao: false });
    await api.post(
      "/parametrosReservaEfetivada/editar" + requisicoes.entidade,
      {
        id: e,
        capacidade: this.state.capacidade,
      },
      requisicoes.header
    );
    let lista = [];
    lista.push(servico_id);
    await api.post(
      "/servico/alterarVarios" + requisicoes.entidade,
      {
        capacidade: this.state.capacidade,
        servicos_id: lista,
      },
      requisicoes.header
    );
    this.buscaParametrosReserva(this.state.grupo_servico_id);
  };

  alteracao = (event) => {
    this.state.alterado = true;
    this.setState({
      [event.target.name]: event.target.value,
      textoEdicao: event.target.value,
      alterado: true,
    });
  };

  visualizacaoGrupo = async (descricao) => {
    this.setState({
      descricao: descricao,
    });
  };

  visualizacaoParametroReservaEfetivada = async (dia) => {
    this.setState({
      diasCancelamento: dia,
    });
  };

  carregaListaServico = async (id) => {
    this.setState({
      servicos: true,
      grupo_servico_id: id,
    });
    this.buscaParametrosReserva(id);
  };

  voltarServicos = async () => {
    this.setState({
      listaServico: [],
      servicos: false,
    });
  };

  voltarDiversos = async () => {
    this.setState({
      listaDiversos: [],
      diversos: false,
    });
  };

  liberaReserva = async (id) => {
    this.setState({ salvando: true });
    let lista = [];
    for (let i = 0; i < this.state.listaGrupoParametro.length; i++) {
      const element = this.state.listaGrupoParametro[i];
      lista.push({
        grupo_servico_id: element.id,
        titular: element.titular || false,
        dependente: element.dependente || false,
        dias_frequencia:
          (element.dias_frequencia && parseInt(element.dias_frequencia)) || 0,
        hora_inicio: element.hora_inicio
          ? element.hora_inicio.format("HH:mm:ss")
          : element.hora_inicio,
        hora_fim: element.hora_fim
          ? element.hora_fim.format("HH:mm:ss")
          : element.hora_fim,
      });
    }
    await api.post(
      "/parametrosGrupoReserva/salvar" + requisicoes.entidade,
      lista,
      requisicoes.header
    );

    setTimeout(() => {
      this.setState({ salvando: false, openModalGrupos: false });
    }, 500);

    // this.buscaParametros();
  };

  // excluirReserva = async (id) => {
  //   await api.post(
  //     "/parametrosGrupoReserva/excluir" + requisicoes.entidade,
  //     { id: id },
  //     requisicoes.header
  //   );
  //   this.buscaParametros();
  // };

  addReservaEfetivada = async (id) => {
    this.setState({ diasCancelamento: "" });
    await api.post(
      "/parametrosReservaEfetivada/salvar" + requisicoes.entidade,
      { servico_id: id, diasCancelamento: this.state.diasCancelamento },
      requisicoes.header
    );
    this.buscaParametrosReserva(this.state.grupo_servico_id);
  };

  excluirReservaEfetivada = async (id) => {
    this.setState({ diasCancelamento: "" });
    await api.post(
      "/parametrosReservaEfetivada/excluir" + requisicoes.entidade,
      { id: id },
      requisicoes.header
    );
    this.buscaParametrosReserva(this.state.grupo_servico_id);
  };

  carregaListaDiversos = async (id, descricao) => {
    const resposta = await api.post(
      "/servico/diversosPorGrupoServico" + requisicoes.entidade,
      { id: id },
      requisicoes.header
    );
    this.setState({
      grupo_servico_id_search: id,
      listaDiversos: resposta.data,
      listaDiversosBackup: resposta.data,
      diversos: true,
      menuDiversosSelected: descricao,
    });
    return resposta.data;
  };

  addParametroTextoPorGrupo = async () => {
    try {
      this.setState({ salvando: true });

      await api.post(
        "/parametrosReservaTermos/editarOuAdicionaPorGrupo" +
          requisicoes.entidade,
        {
          grupo_servico_id: this.state.grupo_servico_id,
          texto: this.state.texto,
        },
        requisicoes.header
      );

      // const resposta = await api.post(
      //   "/servico/servicoPorGrupoServico" + requisicoes.entidade,
      //   { id: this.state.grupo_servico_id },
      //   requisicoes.header
      // );
      // const servicos = resposta.data;

      // servicos.forEach(async (servico) => {
      //   await api
      //     .post(
      //       "/parametrosReservaTermos/visualizaPorServico" +
      //         requisicoes.entidade,
      //       { id: servico.id, texto: this.state.texto },
      //       requisicoes.header
      //     )
      //     .then(async (editavel) => {
      //       if (editavel.data !== undefined) {
      //         if (editavel.data.id !== undefined) {
      //           await api.post(
      //             "/parametrosReservaTermos/editar" + requisicoes.entidade,
      //             {
      //               id: editavel.data.id,
      //               servico_id: servico.id,
      //               texto: this.state.texto,
      //             },
      //             requisicoes.header
      //           );
      //         } else {
      //           await api.post(
      //             "/parametrosReservaTermos/salvar" + requisicoes.entidade,
      //             { servico_id: servico.id, texto: this.state.texto },
      //             requisicoes.header
      //           );
      //         }
      //       }
      //     });

      //   window.location.href = "/servicos";
      // });
    } catch (error) {
      alert("Ocorreu um erro ao salvar");
    } finally {
      this.setState({ salvando: false });
    }
  };

  addDiaReservaLiberadaPorGrupo = async () => {
    this.setState({ salvando: true });
    try {
      const listaDias = this.state.listaDiasReservaLiberada;
      for (let i = 0; i < listaDias.length; i++) {
        const element = listaDias[i];
        if (!element.libera) {
          listaDias.splice(i, 1);
          //remove um numero pra que caso remova o objeto, remova o tamanho da lista para não dar erro
          i--;
        }
      }
      await api.post(
        "/diaReservaLiberada/salvarLista" + requisicoes.entidade,
        { dias: listaDias, grupo_servico_id: this.state.grupo_servico_id },
        requisicoes.header
      );
      // setTimeout(() => {
      //   window.location.href = "/servicos";
      // }, 1000);
    } catch (error) {
      alert("Ocorreu um erro ao salvar");
    } finally {
      this.setState({ salvando: false });
    }
  };

  addParametroTexto = async (id, servico_id) => {
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    if (id !== null) {
      await api.post(
        "/parametrosReservaTermos/editar" + requisicoes.entidade,
        {
          id: id,
          usuario_id: usuarioLogado.data.id,
          servico_id: servico_id,
          texto: this.state.textoEdicao,
        },
        requisicoes.header
      );
    } else {
      await api.post(
        "/parametrosReservaTermos/salvar" + requisicoes.entidade,
        {
          servico_id: servico_id,
          texto: this.state.textoEdicao,
          usuario_id: usuarioLogado.data.id,
        },
        requisicoes.header
      );
    }

    window.location.href = "/servicos";
  };

  abreModalAlteracao = async (state) => {
    this.setState({ modalAlteracao: state });
  };

  addReservaEvento = async (id) => {
    await api.post(
      "/parametrosReservaEvento/salvar" + requisicoes.entidade,
      { servico_id: id },
      requisicoes.header
    );
    this.buscaParametrosReserva(this.state.grupo_servico_id);
  };

  excluirReservaEvento = async (id) => {
    await api.post(
      "/parametrosReservaEvento/excluir" + requisicoes.entidade,
      { id: id },
      requisicoes.header
    );
    this.buscaParametrosReserva(this.state.grupo_servico_id);
  };

  addDiasDistanciamento = async (id, diasDistanciamento_id) => {
    this.setState({ modalAlteracao: false });
    if (diasDistanciamento_id === undefined) {
      await api.post(
        "/parametrosReservaDistanciamentoDia/salvar" + requisicoes.entidade,
        { servico_id: id, diasDistanciamento: this.state.diasDistanciamento },
        requisicoes.header
      );
    } else {
      const dias = {
        id: diasDistanciamento_id,
        diasDistanciamento: this.state.diasDistanciamento,
      };
      await api.post(
        "/parametrosReservaDistanciamentoDia/editar" + requisicoes.entidade,
        dias,
        requisicoes.header
      );
    }
    window.location.href = "/servicos";
  };

  addDiasFrequencia = async (id) => {
    this.setState({ modalAlteracao: false });
    await api.post(
      "/parametrosReservaFrequencia/salvar" + requisicoes.entidade,
      { servico_id: id, dias_frequencia: this.state.frequenciaDias },
      requisicoes.header
    );

    window.location.href = "/servicos";
  };

  checkDiasPorGrupo = async (indice) => {
    let lista = this.state.listaDiasReservaLiberada;
    lista.forEach((dia) => {
      if (dia.indice === parseInt(indice)) {
        if (dia.libera === false) {
          dia.libera = true;
        } else {
          dia.libera = false;
        }
      }
    });
    this.setState({ listaDiasReservaLiberada: lista });
  };

  checkDiasPorServico = async (
    indice,
    servico_id,
    dia_da_semana_id,
    dia_da_reserva_id,
    listaDiasReservaLiberada
  ) => {
    let lista = listaDiasReservaLiberada;
    let edita = false;

    lista.forEach((dia) => {
      if (dia.indice === parseInt(indice)) {
        edita = true;
      } else {
        if (edita !== true) {
          edita = false;
        }
      }
    });

    if (edita === true) {
      lista.forEach((dia) => {
        if (dia.indice === parseInt(indice)) {
          if (dia.libera === false) {
            dia.libera = true;
          } else {
            dia.libera = false;
          }
        }
      });
    } else {
      lista.push({
        id: null,
        servico_id: servico_id,
        libera: true,
        dia_da_semana_id: dia_da_semana_id,
        dia_da_reserva_id: dia_da_reserva_id,
        indice: parseInt(indice),
      });
    }

    this.setState({ listaDiasReservaLiberadaServico: lista });
  };

  for = (indice) => {
    indice = 0;
    return this.state.listaDiasSemana.map((dia, index) => (
      <Table.Row key={index + 1}>
        <Table.Cell>{dia.inicial}</Table.Cell>
        {this.state.listaDiasReserva.map((e, i) => (
          <Table.Cell>
            <formulario>
              <formulario.Check
                value={indice + 1}
                type="checkbox"
                onClick={(e) => this.checkDiasPorGrupo(e.target.value)}
              />
            </formulario>
            <div hidden>{(indice = indice + 1)}</div>
          </Table.Cell>
        ))}
      </Table.Row>
    ));
  };

  forDiasPorServico = (listaDiasReservaLiberada, servico_id) => {
    let indice = 0;
    return this.state.listaDiasSemana.map((dia, index) => (
      <Table.Row key={index + 1}>
        <Table.Cell>{dia.inicial}</Table.Cell>
        {this.state.listaDiasReserva.map((e, i) => (
          <Table.Cell>
            <formulario>
              <formulario.Check
                type="checkbox"
                onClick={(e) =>
                  this.checkDiasPorServico(
                    e.target.value,
                    servico_id,
                    index + 1,
                    i + 1,
                    listaDiasReservaLiberada
                  )
                }
                value={indice + 1}
                defaultChecked={this.verificaCheckBox(
                  indice + 1,
                  listaDiasReservaLiberada
                )}
              />

              <div hidden>{(indice = indice + 1)}</div>
            </formulario>
          </Table.Cell>
        ))}
      </Table.Row>
    ));
  };

  verificaCheckBox = (indice, listaDiasReservaLiberada) => {
    let checked = false;
    listaDiasReservaLiberada.forEach((dia) => {
      if (dia.indice === indice) {
        if (dia.libera === true) {
          checked = true;
        }
      }
    });
    return checked;
  };

  addDiasReservaLiberadaServico = async (id) => {
    this.setState({ modalAlteracao: false });
    const listaDias = this.state.listaDiasReservaLiberadaServico;

    listaDias.forEach(async (dia, i) => {
      if (dia.id !== null) {
        if (dia.servico_id === id) {
          await api.post(
            "/diaReservaLiberada/editar" + requisicoes.entidade,
            {
              id: dia.id,
              servico_id: dia.servico_id,
              libera: dia.libera,
              dia_da_semana_id: dia.dia_da_semana_id,
              dia_da_reserva_id: dia.dia_da_reserva_id,
              indice: dia.indice,
            },
            requisicoes.header
          );
        }
      } else {
        if (dia.servico_id === id) {
          if (dia.libera === true) {
            await api.post(
              "/diaReservaLiberada/salvar" + requisicoes.entidade,
              {
                servico_id: dia.servico_id,
                libera: dia.libera,
                dia_da_semana_id: dia.dia_da_semana_id,
                dia_da_reserva_id: dia.dia_da_reserva_id,
                indice: dia.indice,
              },
              requisicoes.header
            );
          }
        }
      }

      if (i + 1 === listaDias.length) {
        window.location.href = "/servicos";
        // this.buscaParametrosReserva(this.state.grupo_servico_id);
      }
    });
  };

  alteraParametroDiasDaSemanaGrupo = async () => {
    this.setState({ salvando: true });
    try {
      var servicos = await api.post(
        "/servico/servicoPorGrupoServico" + requisicoes.entidade,
        { id: this.state.grupo_servico_id },
        requisicoes.header
      );

      let listaIdServicos = [];

      for (let i = 0; i < servicos.data.length; i++) {
        const element = servicos.data[i];
        listaIdServicos.push(element.id);
      }
      await api.post(
        "/servico/salvaDiasDaSemanaServicos" + requisicoes.entidade,
        {
          servicos: listaIdServicos,
          diasDaSemana: this.state.parametrosDiasDaSemanaGrupo,
        },
        requisicoes.header
      );
    } catch (error) {
      alert("Ocorreu um erro ao salvar");
    } finally {
      setTimeout(() => {
        this.setState({ salvando: false });
      }, 1000);
    }
  };

  onCloseListaConvidados = async () => {
    this.buscaParametroListaConvidados();
  };
  alteraParametroExigeCPF = async (checked) => {
    this.setState({parametroExigeCPFConvidado: checked});

    // Salva o parâmetro que checa a obrigatoriedade do CPF na lista de convidados
    if(this.state.parametroExigeCPF !== null
      && this.state.parametroExigeCPF.id !== null
      && this.state.parametroExigeCPF.id !== undefined) {
      await api.post(
        "/parametros/editar" + requisicoes.entidade,
        {
          id: this.state.parametroExigeCPF.id,
          nome: this.state.parametroExigeCPF.nome,
          valor: checked
        },
        requisicoes.header
      );
    } else {
      await api.post(
        "/parametros/salvar" + requisicoes.entidade,
        {
          nome: 'exige_cpf_convidado',
          valor: checked
        },
        requisicoes.header
      );
    }

    alert('Parâmetro salvo com sucesso.');
  };

  salvarParametroCapacidadeGrupo = async () => {
    this.setState({ salvando: true });
    try {
      var servicos = await api.post(
        "/servico/servicoPorGrupoServico" + requisicoes.entidade,
        { id: this.state.grupo_servico_id },
        requisicoes.header
      );

      let lista = [];
      for (let i = 0; i < servicos.data.length; i++) {
        const element = servicos.data[i];
        lista.push(element.id);
      }

      //Altera a capacidade nas duas tabelas.
      await api.post(
        "/parametrosReservaEfetivada/editarVarios" + requisicoes.entidade,
        {
          ids: lista,
          capacidade: parseInt(this.state.capacidadeGrupo),
        },
        requisicoes.header
      );
      await api.post(
        "/servico/alterarVarios" + requisicoes.entidade,
        {
          servicos_id: lista,
          capacidade: parseInt(this.state.capacidadeGrupo),
        },
        requisicoes.header
      );
      alert('Quantidade salva com sucesso.');
    } catch (error) {
      alert("Ocorreu um erro ao salvar.");
    } finally {
      this.setState({ salvando: false });
    }
  };

  setMenu = async (name) => {
    this.setState({ menusSelect: name });
  };

  alteracaoIcons = (nome) => {
    this.setState({ iconSelecionado: nome });
  };

  salvarGrupoEditado = async (status, id) => {
    await api.post(
      "/grupoServico/editar" + requisicoes.entidade,
      {
        id: id === null ? this.state.grupoServico_id : id,
        desporto: status,
        icon: this.state.iconSelecionado,
      },
      requisicoes.header
    );

    this.listaGrupoServico();
    this.setState({ modalIcons: false });
  };

  changeModalIcons = async (status, id) => {
    this.setState({ modalIcons: true });
    this.setState({ grupoServico_id: id });
  };

  render() {
    let content = "";
    switch (this.state.selectedMenu) {
      case "grupoServico":
        content = (
          <div style={{ paddingTop: 10 }}>
            {localStorage.getItem("tipo_usuario_clube") === "administrador" && (
              <Modal
                size="large"
                trigger={<Button circular icon="cog"></Button>}
                open={this.state.openModalGrupos}
                onOpen={() => this.setState({ openModalGrupos: true })}
                onClose={() => this.setState({ openModalGrupos: false })}
              >
                <Modal.Header>
                  <Menu pointing secondary>
                    <Menu.Item
                      name="Permissao de Reserva"
                      active={this.state.menusSelect === "permissao_reserva"}
                      onClick={() => this.setMenu("permissao_reserva")}
                    />
                    <Menu.Item
                      name="Desportos"
                      active={this.state.menusSelect === "desporto"}
                      onClick={() => this.setMenu("desporto")}
                    />
                  </Menu>
                </Modal.Header>
                <Modal.Content scrolling>
                  <Modal.Description>
                    {this.state.menusSelect === "permissao_reserva" ? (
                      <div textAlign="center">
                        <Row>
                          <Col
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              color="green"
                              onClick={() => this.liberaReserva()}
                              loading={this.state.salvando}
                              disabled={this.state.salvando}
                            >
                              Salvar
                            </Button>
                          </Col>
                        </Row>
                        <Table celled compact>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Nome</Table.HeaderCell>
                              <Table.HeaderCell
                                width={1}
                                style={{ textAlign: "center" }}
                              >
                                Titular
                              </Table.HeaderCell>
                              <Table.HeaderCell
                                width={1}
                                style={{ textAlign: "center" }}
                              >
                                Dependente
                              </Table.HeaderCell>
                              <Table.HeaderCell
                                width={1}
                                style={{ textAlign: "center" }}
                              >
                                {"Intervalo Reserva "}
                                <Tooltip
                                  title="Intervalo de dias para fazer outra reserva do mesmo grupo serviço. 0 = parâmetro desabilitado."
                                  style={{ fontSize: 16 }}
                                >
                                  <HelpOutline />
                                </Tooltip>
                              </Table.HeaderCell>

                              <Table.HeaderCell style={{ textAlign: "center" }}>
                                {"Horário Reserva "}
                                <Tooltip
                                  title="Horário liberado para reserva."
                                  style={{ fontSize: 16 }}
                                >
                                  <HelpOutline />
                                </Tooltip>
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {this.state.listaGrupoParametro.map((e, i) => (
                              <Table.Row key={i}>
                                <Table.Cell>{e.descricao}</Table.Cell>
                                <Table.Cell width={1} textAlign="center">
                                  <Checkbox
                                    toggle
                                    fitted
                                    checked={e.titular}
                                    onChange={(e2, a) => {
                                      let lista = this.state
                                        .listaGrupoParametro;
                                      lista.find(
                                        (e3) => e3.id === e.id
                                      ).titular = a.checked;
                                      this.setState({
                                        listaGrupoParametro: lista,
                                      });
                                    }}
                                  />
                                </Table.Cell>

                                <Table.Cell width={1} textAlign="center">
                                  <Checkbox
                                    toggle
                                    fitted
                                    checked={e.dependente}
                                    onChange={(e2, a) => {
                                      let lista = this.state
                                        .listaGrupoParametro;
                                      lista.find(
                                        (e3) => e3.id === e.id
                                      ).dependente = a.checked;
                                      this.setState({
                                        listaGrupoParametro: lista,
                                      });
                                    }}
                                  />
                                </Table.Cell>

                                <Table.Cell width={2} textAlign="center">
                                  <Input
                                    placeholder="Intervalo"
                                    fullWidth
                                    type="number"
                                    value={e.dias_frequencia}
                                    onChange={(e2, a) => {
                                      let lista = this.state
                                        .listaGrupoParametro;
                                      lista.find(
                                        (e3) => e3.id === e.id
                                      ).dias_frequencia = e2.target.value;
                                      this.setState({
                                        listaGrupoParametro: lista,
                                      });
                                    }}
                                  />
                                </Table.Cell>

                                <Table.Cell textAlign="center" width={5}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={brLocale}
                                  >
                                    <Row
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Checkbox
                                        style={{ marginRight: 5 }}
                                        checked={e.hora_inicio && e.hora_fim}
                                        onChange={(event, a) => {
                                          let lista = this.state
                                            .listaGrupoParametro;
                                          let element = lista.find(
                                            (e3) => e3.id === e.id
                                          );
                                          element.hora_inicio = a.checked
                                            ? moment()
                                            : null;
                                          element.hora_fim = a.checked
                                            ? moment()
                                            : null;
                                          this.setState({
                                            listaGrupoParametro: lista,
                                          });
                                        }}
                                      />
                                      {e.hora_inicio && e.hora_fim && (
                                        <>
                                          <Col
                                            sm={5}
                                            style={{
                                              padding: 0,
                                              marginRight: 5,
                                            }}
                                          >
                                            <DesktopTimePicker
                                              label="Hora inicio"
                                              value={e.hora_inicio}
                                              onChange={(time) => {
                                                time = moment(time);
                                                let lista = this.state
                                                  .listaGrupoParametro;
                                                lista.find(
                                                  (e3) => e3.id === e.id
                                                ).hora_inicio = time;
                                                this.setState({
                                                  listaGrupoParametro: lista,
                                                });
                                              }}
                                              renderInput={(params) => (
                                                <TextField {...params} />
                                              )}
                                            />
                                          </Col>

                                          <Col
                                            sm={5}
                                            style={{
                                              padding: 0,
                                            }}
                                          >
                                            <DesktopTimePicker
                                              label="Hora fim"
                                              value={e.hora_fim}
                                              onChange={(time) => {
                                                time = moment(time);
                                                let lista = this.state
                                                  .listaGrupoParametro;
                                                lista.find(
                                                  (e3) => e3.id === e.id
                                                ).hora_fim = time;
                                                this.setState({
                                                  listaGrupoParametro: lista,
                                                });
                                              }}
                                              renderInput={(params) => (
                                                <TextField {...params} />
                                              )}
                                            />
                                          </Col>
                                        </>
                                      )}
                                    </Row>
                                  </LocalizationProvider>
                                </Table.Cell>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                      </div>
                    ) : (
                      <div textAlign="center">
                        <Header>Sem Permissão Desporto</Header>
                        <Table celled>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Nome</Table.HeaderCell>
                              <Table.HeaderCell
                                width={1}
                                style={{ textAlign: "center" }}
                              >
                                <Icon name="remove"></Icon>
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {this.state.desportoNaoPermitido.map((e, i) => (
                              <Table.Row>
                                <Table.Cell>{e.descricao}</Table.Cell>
                                <Table.Cell>
                                  <Button
                                    type="button"
                                    onClick={() =>
                                      this.changeModalIcons(true, e.id)
                                    }
                                    id={e.id}
                                    size="tiny"
                                    positive
                                    icon="check"
                                  ></Button>
                                </Table.Cell>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>

                        <Header> Com Permissão Desporto</Header>
                        <Table celled>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Nome</Table.HeaderCell>
                              <Table.HeaderCell
                                width={1}
                                style={{ textAlign: "center" }}
                              >
                                <Icon name="remove"></Icon>
                              </Table.HeaderCell>
                              <Table.HeaderCell
                                width={2}
                                style={{ textAlign: "center" }}
                              >
                                Icon
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {this.state.desportoPermitido.map((e, i) => (
                              <Table.Row key={i}>
                                <Table.Cell>{e.descricao}</Table.Cell>
                                <Table.Cell>
                                  <Button
                                    negative
                                    icon="remove"
                                    size="tiny"
                                    onClick={() =>
                                      this.salvarGrupoEditado(false, e.id)
                                    }
                                  ></Button>
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  <Icon name={e.icon} />
                                </Table.Cell>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                      </div>
                    )}
                  </Modal.Description>
                </Modal.Content>
              </Modal>
            )}

            <div>
              <Modal open={this.state.modalIcons} size="tiny">
                <Header icon>Selecionar o Icone</Header>
                <Modal.Content>
                  <div className="flexSpaceAround-Icons">
                    <div>
                      <Button
                        onClick={() => this.alteracaoIcons("paint brush")}
                        size="medium"
                        className="icons"
                        icon
                      >
                        <Icon name="paint brush" />
                      </Button>
                    </div>

                    <div>
                      <Button
                        onClick={() => this.alteracaoIcons("chess knight")}
                        size="medium"
                        className="icons"
                        icon
                      >
                        <Icon name="chess knight" />
                      </Button>
                    </div>

                    <div>
                      <Button
                        onClick={() => this.alteracaoIcons("table")}
                        size="medium"
                        className="icons"
                        icon
                      >
                        <Icon name="table" />
                      </Button>
                    </div>

                    <div>
                      <Button
                        onClick={() => this.alteracaoIcons("bullhorn")}
                        size="medium"
                        className="icons"
                        icon
                      >
                        <Icon name="bullhorn" />
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => this.alteracaoIcons("tag")}
                        size="medium"
                        className="icons"
                        icon
                      >
                        <Icon name="tag" />
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => this.alteracaoIcons("soccer")}
                        size="medium"
                        className="icons"
                        icon
                      >
                        <Icon name="soccer" />
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => this.alteracaoIcons("circle thin")}
                        size="medium"
                        className="icons"
                        icon
                      >
                        <Icon name="circle thin" />
                      </Button>
                    </div>
                    <div>
                      <Button
                        onClick={() => this.alteracaoIcons("baseball ball")}
                        size="medium"
                        className="icons"
                        icon
                      >
                        <Icon name="baseball ball" />
                      </Button>
                    </div>
                  </div>
                </Modal.Content>
                <Modal.Actions>
                  <div className="flexFlexEnd">
                    <div>
                      <Button
                        positive
                        onClick={() => {
                          this.salvarGrupoEditado(true, null);
                        }}
                      >
                        <Icon name="checkmark" /> Salvar
                      </Button>
                    </div>
                  </div>
                </Modal.Actions>
              </Modal>
            </div>

            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Nome</Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign="center">
                    <Link to={{ pathname: "/grupoServico" }}>
                      <Button positive icon="add" size="tiny"></Button>
                    </Link>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.listaGrupoServico.map((e, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{e.descricao}</Table.Cell>
                    <Table.Cell width={1} textAlign="center">
                      <Modal
                        closeIcon
                        size="small"
                        trigger={
                          <Button
                            type="button"
                            name={e.descricao}
                            size="tiny"
                            primary
                            icon="edit"
                            onClick={() => this.visualizacaoGrupo(e.descricao)}
                          ></Button>
                        }
                      >
                        <Header icon>
                          <Icon name="edit" />
                          Alterar Descrição do Grupo
                        </Header>
                        <Modal.Content>
                          <formulario.Label>Descrição</formulario.Label>
                          <FormControl
                            name="descricao"
                            fluid
                            value={this.state.descricao}
                            onChange={this.alteracao}
                          />
                        </Modal.Content>
                        <Modal.Actions>
                          <Button
                            color="green"
                            inverted
                            onClick={() => this.editarGrupo(e.id)}
                          >
                            <Icon name="checkmark" /> Alterar
                          </Button>
                        </Modal.Actions>
                      </Modal>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              <Table.Footer></Table.Footer>
            </Table>
          </div>
        );
        break;
      case "servicos":
        content = (
          <Servicos
            semParametrosReservaEfetivada={
              this.state.semParametrosReservaEfetivada
            }
            alterado={this.state.alterado}
            diasCancelamento={this.state.diasCancelamento}
            historicoTeremosAltecao={this.state.historicoTeremosAltecao}
            addReservaEfetivada={this.addReservaEfetivada}
            parametrosReservaEfetivada={this.state.parametrosReservaEfetivada}
            excluirReservaEfetivada={this.excluirReservaEfetivada}
            alteracao={this.alteracao}
            addParametroTextoPorGrupo={this.addParametroTextoPorGrupo}
            salvando={this.state.salvando}
            semParametrosReservaEvento={this.state.semParametrosReservaEvento}
            addReservaEvento={this.addReservaEvento}
            parametrosReservaEvento={this.state.parametrosReservaEvento}
            excluirReservaEvento={this.excluirReservaEvento}
            for={this.for}
            addDiaReservaLiberadaPorGrupo={this.addDiaReservaLiberadaPorGrupo}
            listaDiasSemana={this.state.listaDiasSemana}
            parametrosDiasDaSemanaGrupo={this.state.parametrosDiasDaSemanaGrupo}
            alteraParametroDiasDaSemanaGrupo={
              this.alteraParametroDiasDaSemanaGrupo
            }
            onCloseListaConvidados={this.onCloseListaConvidados}
            alteraParametroExigeCPF={this.alteraParametroExigeCPF}
            parametroExigeCPFConvidado={this.state.parametroExigeCPFConvidado}
            capacidadeGrupo={this.state.capacidadeGrupo}
            salvarParametroCapacidadeGrupo={this.salvarParametroCapacidadeGrupo}
            servicos={this.state.servicos}
            voltarServicos={this.voltarServicos}
            listaServico={this.state.listaServico}
            abreModalAlteracao={this.abreModalAlteracao}
            setState={(a) => this.setState(a)}
            addParametroTexto={this.addParametroTexto}
            editarParametro={this.editarParametro}
            capacidade={this.state.capacidade}
            salvarParametroCapacidade={this.salvarParametroCapacidade}
            forDiasPorServico={this.forDiasPorServico}
            addDiasReservaLiberadaServico={this.addDiasReservaLiberadaServico}
            diasDistanciamento={this.state.diasDistanciamento}
            addDiasDistanciamento={this.addDiasDistanciamento}
            frequenciaDias={this.state.frequenciaDias}
            addDiasFrequencia={this.addDiasFrequencia}
            voltarPagina={this.voltarPagina}
            proximaPagina={this.proximaPagina}
            pagina={this.state.pagina}
            totalPaginas={this.state.totalPaginas}
            listaGrupoServico={this.state.listaGrupoServico}
            carregaListaServico={this.carregaListaServico}
          />
        );
        break;
      case "diversos":
        content = (
          <div style={{ paddingTop: 10 }}>
            {this.state.diversos !== false ? (
              <div>
                <Row>
                  <Col sm={10}>
                    <Select
                      placeholder={
                        "Digite para buscar o serviço: " +
                        this.state.menuDiversosSelected.toUpperCase()
                      }
                      name="servico"
                      onKeyDown={this.buscaServicoDiversos}
                      inputId="diversosPesquisa"
                      noOptionsMessage={"Nenhum serviço encontrado"}
                      menuIsOpen={false}
                    />
                  </Col>
                  <Col>
                    <div style={{ textAlign: "right" }}>
                      <Button
                        color="blue"
                        icon="th"
                        size="small"
                        content="Voltar"
                        onClick={this.voltarDiversos}
                      ></Button>
                    </div>
                  </Col>
                </Row>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={9}>Nome</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Valor</Table.HeaderCell>
                      <Table.HeaderCell width={1} textAlign="center">
                        <Link to={{ pathname: "./servicosDiversos" }}>
                          <Button positive icon="add" size="tiny"></Button>
                        </Link>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {this.state.listaDiversos.map((e, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>{e.nome}</Table.Cell>
                        <Table.Cell>
                          {parseFloat(e.valor).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Table.Cell>
                        <Table.Cell width={1} textAlign="center">
                          <Link
                            to={{
                              pathname: "/servicosDiversos",
                              query: { id: e.id },
                            }}
                          >
                            <Button primary icon="edit" size="tiny"></Button>
                          </Link>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            ) : (
              <Card.Group itemsPerRow={4}>
                {this.state.listaGrupoServico.map((e, i) => (
                  <Card
                    onClick={() => this.carregaListaDiversos(e.id, e.descricao)}
                  >
                    <Card.Content>
                      <Header>{e.descricao}</Header>
                    </Card.Content>
                  </Card>
                ))}
              </Card.Group>
            )}
          </div>
        );
        break;
      case "turmas":
        content = <Turmas listaGrupoServico={this.state.listaGrupoServico} />;
        break;
      case "feriados":
        content = <Feriados />;
        break;
      default:
        break;
    }
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Serviços
        </Header>
        <Menu tabular icon="labeled">
          {this.props.submenus.find((e) => e.nome === "grupos") && (
            <Menu.Item
              name="grupoServico"
              active={this.state.selectedMenu === "grupoServico"}
              onClick={this.selectMenu.bind(null, "grupoServico")}
            >
              <Icon name="table" />
              Grupos
            </Menu.Item>
          )}

          {this.props.submenus.find((e) => e.nome === "servicos") && (
            <Menu.Item
              name="servicos"
              active={this.state.selectedMenu === "servicos"}
              onClick={this.selectMenu.bind(null, "servicos")}
            >
              <Icon name="sitemap" />
              Serviços
            </Menu.Item>
          )}

          {this.props.submenus.find((e) => e.nome === "diversos") && (
            <Menu.Item
              name="diversos"
              active={this.state.selectedMenu === "diversos"}
              onClick={this.selectMenu.bind(null, "diversos")}
            >
              <Icon name="tag" />
              Diversos
            </Menu.Item>
          )}

          {this.props.submenus.find((e) => e.nome === "turmas") && (
            <Menu.Item
              name="turmas"
              active={this.state.selectedMenu === "turmas"}
              onClick={this.selectMenu.bind(null, "turmas")}
            >
              <Icon name="users" />
              Turmas
            </Menu.Item>
          )}

          {this.props.submenus.find((e) => e.nome === "feriados") && (
            <Menu.Item
              name="feriados"
              active={this.state.selectedMenu === "feriados"}
              onClick={this.selectMenu.bind(null, "feriados")}
            >
              <Icon name="calendar alternate outline" />
              Feriados
            </Menu.Item>
          )}
        </Menu>
        {content}
        {/* <Modal
          closeIcon
          size="mini"
          open={this.state.alert}
          style={{ textAlign: "center" }}
        >
          <Header icon>
            <Icon name="folder open outline" />
            NÃO ENCONTRAMOS NADA AQUI
          </Header>
          <Modal.Content>ESSE GRUPO NÃO POSSUI SERVIÇOS </Modal.Content>
        </Modal> */}
      </div>
    );
  }
  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
    if(newMenu !== null && newMenu !== '' && newMenu !== undefined) {
      window.localStorage.setItem('selectedMenu', newMenu);
    }
  };
}
