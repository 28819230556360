import React, { Component } from "react";

import { Col, Row, Form, FormControl } from "react-bootstrap";
import {
  Modal,
  Button,
  Header,
  Container,
  Message,
  Transition,
} from "semantic-ui-react";

import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

export default class Usuarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alterado: false,
      mensagem: null,
    };
  }

  salvarUsuarios = async () => {
    let lista = this.props.listaUsuarios;

    for (let i = 0; i < lista.length; i++) {
      const element = lista[i];
      if (element.alterado) {
        let resp = await api.put(
          "/usuario/alterarMeusDados" +
            requisicoes.entidade +
            "&id=" +
            element.id,
          {
            login: element.login,
            senha: element.senha,
          },
          requisicoes.header
        );
        if (resp.data === "usuario ja existe") {
          //Fazer algo aqui, talvez
        }
      }
    }
    this.setState({
      mensagem: true,
    });
    this.setState({ alterado: false });
    this.props.alterarUsuario("alterado");
    setTimeout(async () => {
      this.setState({
        mensagem: false,
      });
    }, 3500);
  };

  render() {
    return (
      <Modal
        trigger={
          <Button
            content="Usuários"
            icon="users"
            color="blue"
            size="tiny"
            floated="right"
          ></Button>
        }
      >
        <Modal.Header>
          <Row>
            <Col sm={4}>
              <Header as="h3">Usuários</Header>
            </Col>
            <Col sm={8} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                color="green"
                disabled={this.state.alterado === false}
                onClick={this.salvarUsuarios}
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Modal.Header>

        <Modal.Content>
          <Col sm={12} style={{ paddingLeft: "0", paddingRight: "0" }}>
            {this.props.listaUsuarios.length > 0 ? (
              this.props.listaUsuarios.map((e, i) =>
                this.props.bloqueia ? (
                  <Row>
                    <Col sm={4}>
                      <Form.Label>Login</Form.Label>
                      <FormControl
                        onChange={this.props.alteracao}
                        name="novoLogin"
                        aria-label="Login"
                        aria-describedby="basic-addon1"
                        defaultValue={e.login === null ? "" : e.login}
                        isInvalid={this.props.erroLoginExiste}
                        isValid={this.props.loginNaoExiste}
                        disabled={this.props.bloqueia}
                      />
                      <Form.Control.Feedback>Atualizado</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Login já existe
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm={4}>
                      <Form.Label>Senha</Form.Label>
                      <FormControl
                        name="novaSenha"
                        aria-label="Senha"
                        type="password"
                        aria-describedby="basic-addon1"
                        placeholder="Digite a nova senha"
                        onChange={this.props.alterarUsuario}
                        defaultValue={""}
                        value={"*****"}
                        isValid={this.props.senhaAtualizada}
                        disabled={this.props.bloqueia}
                      />
                      <Form.Control.Feedback>Atualizado</Form.Control.Feedback>
                    </Col>
                    <Col sm={4}>
                      <Form.Label>Perfil</Form.Label>
                      <FormControl
                        aria-describedby="basic-addon1"
                        value={
                          e.tipo_usuario === null ||
                          e.tipo_usuario === undefined
                            ? ""
                            : e.tipo_usuario.charAt(0).toUpperCase() +
                              e.tipo_usuario.slice(1)
                        }
                        disabled={true}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col sm={4}>
                      <Form.Label>Login</Form.Label>
                      <FormControl
                        name="novoLogin"
                        aria-label="Login"
                        aria-describedby="basic-addon1"
                        value={e.login === null ? "" : e.login}
                        isInvalid={!!this.props.erroLoginExiste}
                        isValid={!!this.props.loginNaoExiste}
                        disabled={this.props.bloqueia}
                        onChange={(event) => {
                          this.props.alterarUsuario(event, i);
                          this.setState({ alterado: true });
                        }}
                      />
                      <Form.Control.Feedback>Atualizado</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        Login já existe
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm={4}>
                      <Form.Label>Senha</Form.Label>
                      <FormControl
                        name="novaSenha"
                        aria-label="Senha"
                        type="password"
                        aria-describedby="basic-addon1"
                        placeholder="Digite a nova senha"
                        onChange={(event) => {
                          this.props.alterarUsuario(event, i);
                          this.setState({ alterado: true });
                        }}
                        defaultValue={""}
                        value={e.senha === null ? "*****" : e.senha}
                        isValid={this.props.senhaAtualizada}
                        disabled={this.props.bloqueia}
                      />
                      <Form.Control.Feedback>Atualizado</Form.Control.Feedback>
                    </Col>
                    <Col sm={4}>
                      <Form.Label>Perfil</Form.Label>
                      <FormControl
                        aria-describedby="basic-addon1"
                        value={
                          e.tipo_usuario === null ||
                          e.tipo_usuario === undefined
                            ? ""
                            : e.tipo_usuario.charAt(0).toUpperCase() +
                              e.tipo_usuario.slice(1)
                        }
                        disabled={true}
                      />
                    </Col>
                  </Row>
                )
              )
            ) : (
              <Container style={{ marginTop: "25px", marginBottom: "25px" }}>
                <h3>Não possui usuários cadastrados!</h3>
              </Container>
            )}
          </Col>
          {}
          <Transition.Group animation={"drop"} duration={500}>
            {this.state.mensagem && (
              <Message positive header="Cadastro atualizado!">
                O Usuário foi alterado com sucesso.
              </Message>
            )}
          </Transition.Group>
        </Modal.Content>
      </Modal>
    );
  }
}
