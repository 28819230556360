import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:3001/backend/",
  //master
  // baseURL: "https://clube.faee.com.br/backend/",
  //hml
  // baseURL: "http://hmlclube.faee.com.br/backend/",
  //local
  // baseURL: "http://192.198.2.255:3001/backend/",
});
api.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response?.status === 401) {
    window.location.href = "/"
  }
  return Promise.reject(error.response?.data)
})
export default api;
