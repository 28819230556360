import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { Modal } from "semantic-ui-react";
import {
  TextField,
  Button,
  ThemeProvider,
  Tabs,
  Tab,
  Box,
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Grid,
  CardHeader,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { Save } from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

// Requisições
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

export default class ModalAdicionaPermissao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // inputs
      nome: "",
      tabSelected: 0,

      // listas
      listaRotasEsquerda: [],
      listaRotasDireita: [],
      listaRotasChecked: [],

      listaPermissoesEsquerda: [],
      listaPermissoesDireita: [],
      listaPermissoesChecked: [],

      // state botões
      loading: false,

      // Modais
    };
  }

  handleToggle = (value, checkedListName) => {
    let lista = this.state[checkedListName];
    let idx = lista.indexOf(value);
    if (idx === -1) {
      lista.push(value);
    } else {
      lista.splice(idx, 1);
    }

    this.setState({ [checkedListName]: lista });
  };

  moveEntreListas = (listaChecked, listaDe, listaPara) => {
    let lista1 = this.state[listaDe];
    let lista2 = this.state[listaPara];
    let listaCheckbox = this.state[listaChecked];

    for (let i = 0; i < lista1.length; i++) {
      const element = lista1[i];
      let index = listaCheckbox.findIndex((e) => e === element.id);
      if (index !== -1) {
        lista2.push(element);
        lista1.splice(i, 1);
        listaCheckbox.splice(index, 1);
        i--;
      }
    }

    this.setState({
      [listaChecked]: listaCheckbox,
      [listaDe]: lista1,
      [listaPara]: lista2,
    });
  };

  salvar = async () => {
    try {
      this.setState({ loading: true });

      if (this.props.id) {
        const modulo = await api.post(
          "/modulo/editar" + requisicoes.entidade,
          {
            id: this.props.id,
            nome: this.state.nome,
            rotas: this.state.listaRotasDireita,
            permissoes: this.state.listaPermissoesDireita,
          },
          requisicoes.header
        );
        this.props.addModulo(modulo.data);
      } else {
        const modulo = await api.post(
          "/modulo/salvar" + requisicoes.entidade,
          {
            nome: this.state.nome,
            rotas: this.state.listaRotasDireita,
            permissoes: this.state.listaPermissoesDireita,
          },
          requisicoes.header
        );
        this.props.addModulo(modulo.data);
      }

      setTimeout(() => {
        this.props.onClose();
        this.setState({ loading: false });
      }, 800);
    } catch (error) {
      alert("Ocorreu um erro ao salvar o novo módulo.");
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
        onMount={() => {
          if (this.props.moduloSelectedModalAdicionarModulo) {
            this.setState({
              tabSelected: 0,
              loading: false,
              nome: this.props.moduloSelectedModalAdicionarModulo.nome,
              listaRotasEsquerda: [
                ...this.props.listaRotas.filter(
                  (e) =>
                    this.props.moduloSelectedModalAdicionarModulo.rotas.findIndex(
                      (e2) => e2.id === e.id
                    ) === -1
                ),
              ],
              listaRotasDireita:
                this.props.moduloSelectedModalAdicionarModulo.rotas,

              listaPermissoesEsquerda: [
                ...this.props.listaPermissoes.filter(
                  (e) =>
                    this.props.moduloSelectedModalAdicionarModulo.permissoes.findIndex(
                      (e2) => e2.id === e.id
                    ) === -1
                ),
              ],
              listaPermissoesDireita:
                this.props.moduloSelectedModalAdicionarModulo.permissoes,
            });
          } else {
            this.setState({
              nome: "",
              tabSelected: 0,

              listaRotasEsquerda: [...this.props.listaRotas],
              listaRotasDireita: [],
              listaRotasChecked: [],

              listaPermissoesEsquerda: [...this.props.listaPermissoes],
              listaPermissoesDireita: [],
              listaPermissoesChecked: [],

              loading: false,
            });
          }
        }}
      >
        <Modal.Header>Adicionar Módulos</Modal.Header>
        <Modal.Content>
          <TextField
            style={{ margin: 10 }}
            variant="outlined"
            label="Nome do módulo"
            value={this.state.nome}
            fullWidth
            onChange={(e) => this.setState({ nome: e.target.value })}
          />
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 1 }}
          >
            <Tabs
              value={this.state.tabSelected}
              onChange={(e, newValue) =>
                this.setState({ tabSelected: newValue })
              }
            >
              <Tab label="Rotas" />
              <Tab label="Permissões" />
            </Tabs>
          </Box>

          {/* Rotas */}
          <TabPanel value={this.state.tabSelected} index={0}>
            <div
              style={{
                height: 300,
                overflow: "auto",
                borderWidth: "2px",
                borderColor: "#c3c9cc",
                borderStyle: "solid",
                borderRadius: "7px",
                padding: 15,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={5}>
                  {customList(
                    this.state.listaRotasEsquerda,
                    "listaRotasChecked",
                    this.state.listaRotasChecked,
                    "url",
                    this.handleToggle,
                    "Não Liberadas"
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Grid container direction="column" alignItems="center">
                    <Button
                      variant="outlined"
                      style={{ margin: 20 }}
                      onClick={() =>
                        this.moveEntreListas(
                          "listaRotasChecked",
                          "listaRotasEsquerda",
                          "listaRotasDireita"
                        )
                      }
                    >
                      &gt;
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        this.moveEntreListas(
                          "listaRotasChecked",
                          "listaRotasDireita",
                          "listaRotasEsquerda"
                        )
                      }
                    >
                      &lt;
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  {customList(
                    this.state.listaRotasDireita,
                    "listaRotasChecked",
                    this.state.listaRotasChecked,
                    "url",
                    this.handleToggle,
                    "Liberadas"
                  )}
                </Grid>
              </Grid>
            </div>
          </TabPanel>

          {/* Permissões */}
          <TabPanel value={this.state.tabSelected} index={1}>
            <div
              style={{
                height: 300,
                overflow: "auto",
                borderWidth: "2px",
                borderColor: "#c3c9cc",
                borderStyle: "solid",
                borderRadius: "7px",
                padding: 15,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={5}>
                  {customList(
                    this.state.listaPermissoesEsquerda,
                    "listaPermissoesChecked",
                    this.state.listaPermissoesChecked,
                    "nome",
                    this.handleToggle,
                    "Não Liberadas"
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Grid container direction="column" alignItems="center">
                    <Button
                      variant="outlined"
                      style={{ margin: 20 }}
                      onClick={() =>
                        this.moveEntreListas(
                          "listaPermissoesChecked",
                          "listaPermissoesEsquerda",
                          "listaPermissoesDireita"
                        )
                      }
                    >
                      &gt;
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        this.moveEntreListas(
                          "listaPermissoesChecked",
                          "listaPermissoesDireita",
                          "listaPermissoesEsquerda"
                        )
                      }
                    >
                      &lt;
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  {customList(
                    this.state.listaPermissoesDireita,
                    "listaPermissoesChecked",
                    this.state.listaPermissoesChecked,
                    "nome",
                    this.handleToggle,
                    "Liberadas"
                  )}
                </Grid>
              </Grid>
            </div>
          </TabPanel>
        </Modal.Content>
        <Modal.Actions>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="auto">
              <Button
                onClick={this.props.onClose}
                variant="contained"
                color="inherit"
              >
                Voltar
              </Button>
            </Col>
            <Col md="auto">
              <ThemeProvider
                theme={createTheme({
                  palette: {
                    primary: green,
                  },
                })}
              >
                <LoadingButton
                  variant="contained"
                  style={{ color: "#FFFFFF" }}
                  startIcon={<Save />}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                  onClick={this.salvar}
                >
                  Salvar
                </LoadingButton>
              </ThemeProvider>
            </Col>
          </Row>
        </Modal.Actions>
      </Modal>
    );
  }
}

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && children}</div>;
}

function customList(
  list,
  checkedListName,
  checkedList,
  propriedadeLabel,
  handleToggle,
  title
) {
  return (
    <Card style={{ minHeight: 250 }}>
      <CardHeader sx={{ px: 2, py: 1 }} title={title} />
      <List sx={{ overflow: "auto" }}>
        {list.map((e) => (
          <ListItem
            key={e.id}
            role="listitem"
            button
            onClick={() => handleToggle(e.id, checkedListName)}
          >
            <ListItemIcon>
              <Checkbox
                checked={checkedList.indexOf(e.id) !== -1}
                tabIndex={-1}
                disableRipple
                // inputProps={{
                //   "aria-labelledby": labelId,
                // }}
              />
            </ListItemIcon>
            <ListItemText
              // id={labelId}
              primary={e[propriedadeLabel]}
            />
          </ListItem>
        ))}
      </List>
    </Card>
  );
}
