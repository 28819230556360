/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Modal, Segment, Table } from "semantic-ui-react";
import { Form, FormControl, Col, Row, Alert } from "react-bootstrap";

import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AlertTitle from "@material-ui/lab/AlertTitle";

import "../styles.css";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Modal
          style={{ overflow: "visible" }}
          dimmer="bluring"
          size="small"
          open={this.props.modalAdicionarRodadaMataMata}
        >
          <Modal.Header>
            <IconButton
              onClick={() => this.props.changeModalAdicionarRodada(false)}
              className="bottomModal"
              color="secondary"
            >
              <CloseIcon />
            </IconButton>

            <h6> Ajuste os confrontos</h6>
          </Modal.Header>
          <Modal.Content>
            {this.props.alertaSalvoError ? (
              <Alert variant="danger" style={{ marginTop: "10px" }}>
                <AlertTitle>Erro ao Salvar a Rodada!</AlertTitle>
                Iremos redirecionar-lhe — <strong>Aguarde!</strong>
              </Alert>
            ) : this.props.alertaSalvoSucesso ? (
              <Alert variant="success" style={{ marginTop: "10px" }}>
                <AlertTitle>Sucesso ao Salvar a Rodada!</AlertTitle>
                Iremos redirecionar-lhe — <strong>Aguarde!</strong>
              </Alert>
            ) : this.props.ida_volta ? (
              <div className="flexSpaceBetween">
                <div>
                  <Row>
                    {this.props.listaTimesOpcoes !== undefined &&
                    this.props.listaTimesOpcoes !== null &&
                    this.props.listaTimesOpcoes.length !== 0
                      ? Array.from({
                          length: this.props.listaTimesOpcoes.length * 2,
                        }).map((element, index) => {
                          return (
                            <Col sm={6} style={{ marginTop: "1ch" }}>
                              <Select
                                variant="standard"
                                onChange={(event) =>
                                  this.props.changeSelectTimes(event, index)
                                }
                                options={this.props.listaTimesOpcoes}
                              ></Select>
                            </Col>
                          );
                        })
                      : ""}
                  </Row>
                </div>
                <div>
                  {this.props.listaTimesOpcoes.length !== 0
                    ? Array.from({
                        length: this.props.listaTimesOpcoes.length,
                      }).map((elmt, indx) => {
                        return (
                          <Col sm={12} style={{ marginTop: "1ch" }}>
                            <TextField
                              variant="standard"
                              style={{ marginTop: "1ch" }}
                              type="date"
                              onChange={(event) =>
                                this.props.alteracaoData(indx, event)
                              }
                            ></TextField>
                          </Col>
                        );
                      })
                    : ""}
                </div>
              </div>
            ) : (
              <div className="flexSpaceBetween">
                <div>
                  <Row>
                    {this.props.listaTimesOpcoes !== undefined &&
                      this.props.listaTimesOpcoes !== null &&
                      this.props.listaTimesOpcoes.length !== 0 &&
                      Array.from({
                        length: this.props.listaTimesOpcoes.length,
                      }).map((element, index) => {
                        return (
                          <Col sm={6} style={{ marginTop: "1ch" }}>
                            <Select
                              variant="standard"
                              fullWidth
                              onChange={(event) =>
                                this.props.changeSelectTimes(event, index)
                              }
                            >
                              {this.props.listaTimesOpcoes.map(
                                (element, index) => (
                                  <MenuItem value={element.value}>
                                    {" "}
                                    {element.label}{" "}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </Col>
                        );
                      })}
                  </Row>
                </div>
                <div>
                  {this.props.listaTimesOpcoes.length !== 0 &&
                    Array.from({
                      length: this.props.listaTimesOpcoes.length / 2,
                    }).map((elmt, indx) => {
                      return (
                        <Col sm={12} style={{ marginTop: "1ch" }}>
                          <TextField
                            variant="standard"
                            style={{ marginTop: "1ch" }}
                            type="date"
                            onChange={(event) =>
                              this.props.alteracaoData(indx, event)
                            }
                          ></TextField>
                        </Col>
                      );
                    })}
                </div>
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="primary"
              style={{ outline: "none" }}
              onClick={() => this.props.adicionarRodadaMataMata()}
            >
              Adicionar Rodada
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
