import React, { Component } from "react";
import {
  Container,
  Table,
  Menu,
  Icon,
  Checkbox,
  Button,
  Loader,
  Message,
} from "semantic-ui-react";
import api from "../../../services/api";
import { Col, Form, Row, FormControl } from "react-bootstrap";
import Select from "react-select";
import { parseISO, format } from "date-fns";
import renomearChave from "../../../services/renomearChave";
import requisicoes from "../../../utils/Requisicoes";
import renomear4Chaves from "../../../services/renomear4Chaves";
import renomear2Chaves from "../../../services/renomear2Chaves";

import enumMes from "../../../enum/Mes";

import ModalVisualiza from "./modalVisualiza";

const moment = require("moment-timezone");

export default class Consulta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      tipo: "centrocustoelementocusto",
      data_inicio: moment().startOf("month").format("YYYY-MM-DD"),
      data_final: moment().format("YYYY-MM-DD"),
      filtrar_por_data: "data_cancelamento",
      data_inicial_liq: null,
      data_final_liq: null,
      options: [],
      pessoa_id: null,
      pessoa: null,
      open: false,
      contasAPagar: null,
      lancamento_conta: null,
      conta_bancaria_lista: [],
      conta_bancaria: null,
      data_pagamento: null,
      conta: null,
      num_cheque: null,
      data_inicio_forn: null,
      data_final_forn: null,
      formabusca: null,
      tipo_busca: "nome",
      selectedCheckboxes: false,
      selectedCheckboxesElemento: false,
      optionsCC: [],
      selectvalue: [],
      selectvalueElemento: [],
      centrocustonome: null,
      tipoimpressaoemissao: "todas",
      filtroData: "dataVencimento",
      situacao: "emAberto",
      valorTotal: 0.0,
      listaContasAPagar: [],
      buscando: false,
    };
    this.alteracao = this.alteracao.bind(this);
  }

  pesquisaCentroCusto = async (event) => {
    let pesquisa = "";
    if (event.target.value != null) {
      pesquisa = event.target.value;
    }
    await api
      .post(
        "/centroDeCusto/pesquisa" + requisicoes.entidade,
        { pesquisa: pesquisa },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        lista.push({ value: 0, label: "Todos" });
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomear2Chaves(objeto, "codigo", "descricao", "label");
          lista.push(objeto);
        });
        this.setState({ optionsCC: lista });
      });
  };

  pesquisaElementoCusto = async (event) => {
    let pesquisa = "";
    if (event.target.value != null) {
      pesquisa = event.target.value;
    }
    await api
      .post(
        "/elementoDeCusto/pesquisa" + requisicoes.entidade,
        { pesquisa: pesquisa },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        lista.push({ value: 0, label: "Todos" });
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomear2Chaves(objeto, "codigo", "descricao", "label");
          lista.push(objeto);
        });

        this.setState({ selectvalue: lista });
      });
  };
  pesquisaElementoCustoElemento = async (event) => {
    let pesquisa = "";
    if (event.target.value != null) {
      pesquisa = event.target.value;
    }
    await api
      .post(
        "/elementoDeCusto/pesquisa" + requisicoes.entidade,
        { pesquisa: pesquisa },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        lista.push({ value: 0, label: "Todos" });
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomear2Chaves(objeto, "codigo", "descricao", "label");
          lista.push(objeto);
        });

        this.setState({ selectvalueElemento: lista });
      });
  };

  alteracaoSelectCentroCusto = async (event, id) => {
    this.setState({ centro_de_custo_id: event.value });
    this.setState({ centro_de_custo: event });
    this.setState({ centrocustonome: event.label });
  };
  alteracaoSelectElementoCusto = async (event, id) => {
    this.setState({ elemento_de_custo_id: event.value });
    this.setState({ elemento_de_custo: event });
    this.setState({ elementocustonome: event.label });
  };
  toggleCheckboxElement = () => {
    if (this.state.selectedCheckboxesElemento === false) {
      this.setState({ selectedCheckboxesElemento: true });
    }
    if (this.state.selectedCheckboxesElemento === true) {
      this.setState({ selectedCheckboxesElemento: false });
    }
  };
  toggleCheckbox = () => {
    if (this.state.selectedCheckboxes === false) {
      this.setState({ selectedCheckboxes: true });
    }
    if (this.state.selectedCheckboxes === true) {
      this.setState({ selectedCheckboxes: false });
    }
  };
  handleClose = () => {
    window.location.reload();
    this.setState({ open: false });
  };

  handleShow = () => this.setState({ open: true });

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ lista: [] });

    if (event.target.name === "tipo") {
      this.setState({ data_inicio: null, data_final: null });
      this.setState({ formabusca: null });
    }
  };

  contaBancaria = async () => {
    const retorno = await api.post(
      "/contaBancaria/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ conta_bancaria_lista: retorno.data });
  };

  pesquisaPessoa = (newValue) => {
    api
      .post(
        "/pessoa/pesquisaContasAPagar" + requisicoes.entidade,
        { pesquisa: newValue },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomear4Chaves(
            objeto,
            "reduzido",
            "nome",
            "cnpj",
            "nomefantasia",
            "label"
          );
          lista.push(objeto);
        });
        this.setState({ options: lista });
      });
  };

  alteracaoSelectPessoa = async (event) => {
    this.setState({ pessoa_id: event.value });
    this.setState({ pessoa: event });
    this.setState({ lista: [] });
  };

  verificacoesCampos = async (tipo) => {
    switch (tipo || this.state.tipo) {
      case "cheque":
        let numero_cheque_NotNull = false;
        let datas_NotNull = false;
        let datas_Validas = false;

        if (
          this.state.numero_cheque &&
          this.state.numero_cheque.trim() !== ""
        ) {
          numero_cheque_NotNull = true;
        }

        if (this.state.data_inicio && this.state.data_final) {
          datas_NotNull = true;
        }

        if (
          datas_NotNull &&
          moment(this.state.data_final).isAfter(moment(this.state.data_inicio))
        ) {
          datas_Validas = true;
        }

        if (!numero_cheque_NotNull && (!datas_NotNull || !datas_Validas)) {
          if (!numero_cheque_NotNull && !datas_NotNull) {
            alert(
              "Fornceça um intervalo de datas ou o número do cheque a ser buscado."
            );
          } else if (!datas_Validas) {
            alert("Datas invalidas.");
          }
        } else {
          this.buscaTitulos();
        }
        break;
      default:
        break;
    }
  };

  buscaTitulos = async () => {
    this.setState({ loading: true });
    let retorno = "";
    try {
      switch (this.state.tipo) {
        case "fornecedor":
          retorno = await api.post(
            "/contasAPagar/lancamentosPorPessoa" + requisicoes.entidade,
            {
              pessoa_id: this.state.pessoa_id,
              data_inicial: this.state.data_inicio_forn,
              data_final: this.state.data_final_forn,
              formabusca: this.state.formabusca,
              selectedCheckboxes: this.state.selectedCheckboxes,
            },
            requisicoes.header
          );

          this.setState({
            lista: retorno.data,
            listaContasAPagar: retorno.data,
          });
          this.setState({ loading: false });
          break;

        case "emAberto":
          retorno = await api.post(
            "/contasAPagar/lancamentoAVencerPorData" + requisicoes.entidade,
            {
              data_inicial: this.state.data_inicio,
              data_final: this.state.data_final,
              selectedCheckboxes: this.state.selectedCheckboxes,
            },
            requisicoes.header
          );

          this.setState({ lista: retorno.data });
          this.setState({ loading: false });
          break;

        case "canceladas":
          retorno = await api.post(
            "/contasAPagar/lancamentoCanceladoPorData" + requisicoes.entidade,
            {
              data_inicial: this.state.data_inicio,
              data_final: this.state.data_final,
              filtrar_por_data: this.state.filtrar_por_data,
            },
            requisicoes.header
          );
          this.setState({ lista: retorno.data });
          this.setState({ loading: false });
          break;

        case "pagas":
          retorno = await api.post(
            "/contasAPagar/lancamentoPagas" + requisicoes.entidade,
            {
              data_inicial: this.state.data_inicial_liq,
              data_final: this.state.data_final_liq,
            },
            requisicoes.header
          );
          this.setState({ loading: false });
          this.setState({ lista: retorno.data });
          break;

        case "emissao":
          retorno = await api.post(
            "/contasAPagar/lancamentosPorEmissao" + requisicoes.entidade,

            {
              data_inicial: this.state.data_inicio,
              data_final: this.state.data_final,
              tipoimpressaoemissao: this.state.tipoimpressaoemissao,
              situacao: this.state.situacao,
            },
            requisicoes.header
          );
          let valorTotal = 0.0;
          for (let i = 0; i < retorno.data.length; i++) {
            const element = retorno.data[i];
            valorTotal += parseFloat(element.valor);
          }
          console.log("valorTotal: ", valorTotal);
          this.setState({ valorTotal });
          this.setState({ loading: false });
          this.setState({ lista: retorno.data });
          break;

        case "abertoliquidada":
          retorno = await api.post(
            "/contasAPagar/lancamentosPorPessoaAbertoLiquidadas" +
              requisicoes.entidade,

            {
              data_inicial: this.state.data_inicio,
              data_final: this.state.data_final,
              selectedCheckboxes: this.state.selectedCheckboxes,
              tipoabertaliquidada: this.state.tipoabertaliquidada,
            },
            requisicoes.header
          );
          this.setState({ loading: false });
          this.setState({ lista: retorno.data });
          break;

        case "centrocustoelementocusto":
          retorno = await api.post(
            "/contasAPagar/buscaLancamentoCentroCusto" + requisicoes.entidade,
            {
              data_inicial: this.state.data_inicio,
              data_final: this.state.data_final,
              formabusca: this.state.formabusca,
              centro_de_custo_id: this.state.centro_de_custo_id,
              centro_de_custo_nome: this.state.centrocustonome,
              elemento_de_custo_id: this.state.elemento_de_custo_id,
              elemento_de_custo_nome: this.state.elementocustonome,
              selectedCheckboxes: this.state.selectedCheckboxes,
              filtroData:
                this.state.formabusca === "pagas" ||
                this.state.formabusca === "emAberto" ||
                this.state.formabusca === "canceladas"
                  ? "dataEmissao"
                  : this.state.filtroData,
              canceladas: this.state.formabusca === "canceladas" ? true : false,
            },
            requisicoes.header
          );
          this.setState({ lista: retorno.data });
          this.setState({ loading: false });
          break;

        case "elementocusto":
          retorno = await api.post(
            "/contasAPagar/buscaLancamentoElementoCusto" + requisicoes.entidade,

            {
              data_inicial: this.state.data_inicio,
              data_final: this.state.data_final,
              formabusca: this.state.formabusca,
              elemento_de_custo_id: this.state.elemento_de_custo_id,
              elemento_de_custo_nome: this.state.elementocustonome,
              selectedCheckboxes: this.state.selectedCheckboxes,
              canceladas: this.state.formabusca === "canceladas" ? true : false,
            },
            requisicoes.header
          );
          console.log("retorno.data: ", retorno.data);
          this.setState({ lista: retorno.data });
          this.setState({ loading: false });
          break;

        case "centrocusto":
          retorno = await api.post(
            "/contasAPagar/buscarCentroCustoLancamento" + requisicoes.entidade,
            {
              data_inicial: this.state.data_inicio,
              data_final: this.state.data_final,
              formabusca: this.state.formabusca,
              centro_de_custo_id: this.state.centro_de_custo_id,
              centro_de_custo_nome: this.state.centrocustonome,
              selectedCheckboxes: this.state.selectedCheckboxes,
              canceladas: this.state.formabusca === "canceladas" ? true : false,
            },
            requisicoes.header
          );
          this.setState({ lista: retorno.data });
          this.setState({ loading: false });
          break;

        case "cheque":
          retorno = await api.post(
            "/contasAPagar/buscaCheque" + requisicoes.entidade,
            {
              data_inicial:
                this.state.numero_cheque &&
                this.state.numero_cheque.trim() !== ""
                  ? null
                  : this.state.data_inicio,
              data_final:
                this.state.numero_cheque &&
                this.state.numero_cheque.trim() !== ""
                  ? null
                  : this.state.data_final,
              formabusca: this.state.formabusca,
              numero_cheque:
                this.state.numero_cheque &&
                this.state.numero_cheque.trim() !== ""
                  ? this.state.numero_cheque
                  : null,
            },
            requisicoes.header
          );

          let listaContas = [];

          for (let i = 0, j = 0; i < retorno.data.length; i++) {
            const element = retorno.data[i];
            if (i === 0) {
              listaContas[0] = [];
              listaContas[0].push(element);
              listaContas[0].valorTotal = parseFloat(
                element.lancamento_contas[0].valor
              );
              continue;
            }

            if (
              moment(element.lancamento_contas[0].data_pagamento).get(
                "year"
              ) ===
                moment(
                  listaContas[j][0].lancamento_contas[0].data_pagamento
                ).get("year") &&
              moment(element.lancamento_contas[0].data_pagamento).get(
                "month"
              ) ===
                moment(
                  listaContas[j][0].lancamento_contas[0].data_pagamento
                ).get("month")
            ) {
              listaContas[j].push(element);
              listaContas[j].valorTotal += parseFloat(
                element.lancamento_contas[0].valor
              );
            } else {
              j++;
              listaContas[j] = [];
              listaContas[j].push(element);
              listaContas[j].valorTotal = parseFloat(
                element.lancamento_contas[0].valor
              );
            }
          }
          this.setState({ lista: listaContas });
          break;

        default:
          retorno = await api.post(
            "/contasAPagar/buscarCentroCustoLancamento" + requisicoes.entidade,
            {
              data_inicial: this.state.data_inicio,
              data_final: this.state.data_final,
              formabusca: this.state.formabusca,
              centro_de_custo_id: this.state.centro_de_custo_id,
              centro_de_custo_nome: this.state.centrocustonome,
              selectedCheckboxes: this.state.selectedCheckboxes,
              canceladas: this.state.formabusca === "canceladas" ? true : false,
            },
            requisicoes.header
          );
          this.setState({ lista: retorno.data });
          break;
      }
    } catch (e) {
      console.log("erro");
      this.setState({ erro: true });
      setTimeout(() => {
        this.setState({ erro: false });
      }, 5000);
    }
    this.setState({ loading: false });
  };

  buscaTitulo = async (event) => {
    const id = event.target.id.toString().substring(6, 20);
    let retorno = await api.post(
      "/contasAPagar/contasAPagarPorLancamento" + requisicoes.entidade,
      { lancamento_conta_id: id },
      requisicoes.header
    );
    this.setState({ contasAPagar: retorno.data });
    retorno = await api.post(
      "/lancamentoConta/visualiza" + requisicoes.entidade,
      { id: id },
      requisicoes.header
    );
    this.setState({ lancamento_conta: retorno.data });
    this.contaBancaria();
    this.handleShow();
  };

  handleSubmit = (event) => {
    this.submit();
    event.preventDefault();
  };

  submit = async () => {
    await api.post(
      "/lancamentoConta/baixarLancamento" + requisicoes.entidade,
      {
        id: this.state.lancamento_conta.id,
        data_pagamento: this.state.data_pagamento,
        forma_pagamento: this.state.tipo_pagamento,
        conta_bancaria_id: this.state.conta_bancaria,
        conta: this.state.conta,
        num_cheque: this.state.num_cheque,
      },
      requisicoes.header
    );
    window.location.href = "/financeiro";
  };

  buscarTituloLiquidado = async () => {
    setTimeout(async () => {
      var localStorage = window.localStorage;
      var token = localStorage.getItem("token_clube");
      var entidade = localStorage.getItem("e_clube");
      await api.post(
        "/contasAPagar/gerarTituloLiquidado?e=" + entidade,
        {
          data_inicial_liq: this.state.data_inicial_liq,
          data_final_liq: this.state.data_final_liq,
        },

        { headers: { token: "Baer " + token } }
      );
      this.imprimirTituloLiquidado();
    }, 500);
  };

  imprimirTituloLiquidado = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api.get(
      "/contasAPagar/buscarTituloLiquidado?e=" +
        entidade +
        "&t=Baer " +
        token +
        "&id=" +
        this.state.pessoa_id
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "contasAPagar/buscarTituloLiquidado?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&id=" +
      this.state.pessoa_id;
    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = await new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  buscarTituloAbertoLiquidado = async () => {
    this.setState({ buscando: true });
    await api.post(
      "/contasAPagar/gerarTituloAbertoLiquidado" + requisicoes.entidade,
      {
        data_inicial: this.state.data_inicio,
        data_final: this.state.data_final,
        selectedCheckboxes: this.state.selectedCheckboxes,
        tipoabertaliquidada: this.state.tipoabertaliquidada,
        canceladas: this.state.formabusca === "canceladas" ? true : false,
        centroElemento: this.state.selectedCheckboxesElemento,
      },
      requisicoes.header
    );
    this.setState({ buscando: false });
    await this.imprimirTituloAbertoLiquidado();
  };

  imprimirTituloAbertoLiquidado = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api.get(
      "/contasAPagar/buscarTituloAbertoLiquidado?e=" +
        entidade +
        "&t=Baer " +
        token
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "contasAPagar/buscarTituloAbertoLiquidado?e=" +
      entidade +
      "&t=Baer " +
      token;
    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = await new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  imprimirPdfTituloPorFornecedor = async (event) => {
    event.preventDefault();
    const convidados = await api.post(
      "/contasAPagar/gerarPdfTitulosPorFornecedor" + requisicoes.entidade,
      {
        data_inicial: this.state.data_inicio_forn,
        data_final: this.state.data_final_forn,
        pessoa_id: this.state.pessoa_id,
        formabusca: this.state.formabusca,
        selectedCheckboxes: this.state.selectedCheckboxes,
        lista: this.state.listaContasAPagar,
      },
      requisicoes.header

      // { headers: { token: "Baer " + token } }
    );

    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    const r = await api.get(
      "/contasAPagar/buscarPdfTitulosPorFornecedor" +
        requisicoes.entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.id
    );
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "contasAPagar/buscarPdfTitulosPorFornecedor" +
      requisicoes.entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.id;

    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  buscarTituloPorFornecedor = async () => {
    setTimeout(async () => {
      var localStorage = window.localStorage;
      var token = localStorage.getItem("token_clube");
      var entidade = localStorage.getItem("e_clube");
      await api.post(
        "/contasAPagar/gerarTitulosPorFornecedor?e=" + entidade,
        {
          data_inicial: this.state.data_inicio_forn,
          data_final: this.state.data_final_forn,
          pessoa_id: this.state.pessoa_id,
          formabusca: this.state.formabusca,
          selectedCheckboxes: this.state.selectedCheckboxes,
        },

        { headers: { token: "Baer " + token } }
      );
      this.imprimirTituloPorFornecedor();
    }, 500);
  };

  buscarTituloCentroCusto = async () => {
    setTimeout(async () => {
      this.setState({ buscando: true });
      var localStorage = window.localStorage;
      var token = localStorage.getItem("token_clube");
      var entidade = localStorage.getItem("e_clube");
      await api.post(
        "/contasAPagar/gerarTituloCentroCusto?e=" + entidade,
        {
          data_inicial: this.state.data_inicio,
          data_final: this.state.data_final,

          formabusca: this.state.formabusca,
          centro_de_custo_id: this.state.centro_de_custo_id,
          centro_de_custo_nome: this.state.centrocustonome,
          elemento_de_custo_id: this.state.elemento_de_custo_id,
          elemento_de_custo_nome: this.state.elementocustonome,
          selectedCheckboxes: this.state.selectedCheckboxes,

          filtroData: this.state.filtroData,
          canceladas: this.state.formabusca === "canceladas" ? true : false,
        },

        { headers: { token: "Baer " + token } }
      );
      this.imprimirTituloCentroCusto();
      this.setState({ buscando: false });
    }, 500);
  };

  imprimirTituloCentroCusto = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api.get(
      "/contasAPagar/buscarTituloCentroCusto?e=" + entidade + "&t=Baer " + token
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "contasAPagar/buscarTituloCentroCusto?e=" +
      entidade +
      "&t=Baer " +
      token;
    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = await new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  buscarTituloElementoCusto = async () => {
    setTimeout(async () => {
      var localStorage = window.localStorage;
      var token = localStorage.getItem("token_clube");
      var entidade = localStorage.getItem("e_clube");
      await api.post(
        "/contasAPagar/gerarTituloElementoCusto?e=" + entidade,
        {
          data_inicial: this.state.data_inicio,
          data_final: this.state.data_final,

          formabusca: this.state.formabusca,
          elemento_de_custo_id: this.state.elemento_de_custo_id,

          elemento_de_custo_nome: this.state.elementocustonome,
          selectedCheckboxes: this.state.selectedCheckboxes,
          canceladas: this.state.formabusca === "canceladas" ? true : false,
        },

        { headers: { token: "Baer " + token } }
      );
      this.imprimirTituloElementoCusto();
    }, 500);
  };
  imprimirTituloElementoCusto = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api.get(
      "/contasAPagar/buscarTituloElementoCusto?e=" +
        entidade +
        "&t=Baer " +
        token
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "contasAPagar/buscarTituloElementoCusto?e=" +
      entidade +
      "&t=Baer " +
      token;
    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = await new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  imprimirTituloPorFornecedor = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api.get(
      "/contasAPagar/buscarTitulosPorFornecedor?e=" +
        entidade +
        "&t=Baer " +
        token +
        "&id=" +
        this.state.pessoa_id
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "contasAPagar/buscarTitulosPorFornecedor?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&id=" +
      this.state.pessoa_id;
    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = await new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };
  buscarTituloPorEmissao = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    await api.post(
      "/contasAPagar/gerarTitulosPorEmissao?e=" + entidade,
      {
        data_inicial: this.state.data_inicio,
        data_final: this.state.data_final,
        tipoimpressaoemissao: this.state.tipoimpressaoemissao,
        situacao: this.state.situacao,
      },

      { headers: { token: "Baer " + token } }
    );

    setTimeout(async () => {
      this.imprimirTituloPorEmissao();
    }, 500);
  };
  imprimirTituloPorEmissao = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api.get(
      "/contasAPagar/buscarTitulosPorEmissao?e=" +
        entidade +
        "&t=Baer " +
        token +
        "&id=" +
        this.state.pessoa_id
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "contasAPagar/buscarTitulosPorEmissao?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&id=" +
      this.state.pessoa_id;
    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = await new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  pesquisaPessoaReduzido = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/pessoa/pesquisaReduzidoFornecedor" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaPessoaResuzido").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        if (
          !isNaN(
            parseFloat(document.getElementById("pesquisaPessoaResuzido").value)
          ) &&
          isFinite(document.getElementById("pesquisaPessoaResuzido").value)
        ) {
          if (element.reduzido !== null) {
            let objeto = renomearChave(element, "id", "value");
            objeto = renomearChave(objeto, "reduzido", "label");
            objeto.label = objeto.label + " - " + objeto.nome;
            lista.push(objeto);
          }
        }
      });
      this.setState({ options: lista });
    }, 500);
  };

  imprimirTituloLiquidado = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api.get(
      "/contasAPagar/buscarTituloLiquidado?e=" +
        entidade +
        "&t=Baer " +
        token +
        "&id=" +
        this.state.pessoa_id
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "contasAPagar/buscarTituloLiquidado?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&id=" +
      this.state.pessoa_id;
    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = await new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };
  buscaTituloAberto = async () => {
    this.buscando = true;
    this.setState({ buscando: true });
    setTimeout(async () => {
      var localStorage = window.localStorage;
      var token = localStorage.getItem("token_clube");
      var entidade = localStorage.getItem("e_clube");
      await api.post(
        "/contasAPagar/gerarTituloAberto?e=" + entidade,
        {
          data_inicial: this.state.data_inicio,
          data_final: this.state.data_final,
        },

        { headers: { token: "Baer " + token } }
      );
      this.imprimeTituloAberto();
      this.buscando = false;
      this.setState({ buscando: false });
    }, 500);
  };
  imprimeTituloAberto = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api.get(
      "/contasAPagar/buscarTituloAberto?e=" +
        entidade +
        "&t=Baer " +
        token +
        "&id=" +
        this.state.pessoa_id
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "contasAPagar/buscarTituloAberto?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&id=" +
      this.state.pessoa_id;
    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = await new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  buscaTituloCancelado = async () => {
    setTimeout(async () => {
      var localStorage = window.localStorage;
      var token = localStorage.getItem("token_clube");
      var entidade = localStorage.getItem("e_clube");
      await api.post(
        "/contasAPagar/gerarTituloCancelado?e=" + entidade,
        {
          data_inicial: this.state.data_inicio,
          data_final: this.state.data_final,
          filtrar_por_data: this.state.filtrar_por_data
        },

        { headers: { token: "Baer " + token } }
      );
      this.imprimeTituloCancelado();
    }, 500);
  };
  imprimeTituloCancelado = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api.get(
      "/contasAPagar/buscarTituloCancelado?e=" +
        entidade +
        "&t=Baer " +
        token +
        "&id=" +
        this.state.pessoa_id,
        {
          data_inicial: this.state.data_inicio,
          data_final: this.state.data_final,
          filtrar_por_data: this.state.filtrar_por_data
        },
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "contasAPagar/buscarTituloCancelado?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&id=" +
      this.state.pessoa_id;
    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = await new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  buscarTituloApenasCentroCusto = async () => {
    setTimeout(async () => {
      var localStorage = window.localStorage;
      var token = localStorage.getItem("token_clube");
      var entidade = localStorage.getItem("e_clube");
      await api.post(
        "/contasAPagar/gerarCentroCusto?e=" + entidade,
        {
          data_inicial: this.state.data_inicio,
          data_final: this.state.data_final,

          formabusca: this.state.formabusca,
          centro_de_custo_id: this.state.centro_de_custo_id,
          centro_de_custo_nome: this.state.centrocustonome,

          selectedCheckboxes: this.state.selectedCheckboxes,
        },

        { headers: { token: "Baer " + token } }
      );
      this.imprimirTituloApenasCentroCusto();
    }, 500);
  };

  imprimirTituloApenasCentroCusto = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api.get(
      "/contasAPagar/buscarCentroCusto?e=" + entidade + "&t=Baer " + token
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "contasAPagar/buscarCentroCusto?e=" +
      entidade +
      "&t=Baer " +
      token;
    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = await new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  imprimirRelatorioCheque = async () => {
    await api.post(
      "/contasAPagar/geraRelatorioCheque" + requisicoes.entidade,
      {
        data_inicial:
          this.state.numero_cheque && this.state.numero_cheque.trim() !== ""
            ? null
            : this.state.data_inicio,
        data_final:
          this.state.numero_cheque && this.state.numero_cheque.trim() !== ""
            ? null
            : this.state.data_final,
        formabusca: this.state.formabusca,
        numero_cheque:
          this.state.numero_cheque && this.state.numero_cheque.trim() !== ""
            ? this.state.numero_cheque
            : null,
      },
      requisicoes.header
    );

    const r = await api.get(
      "/contasAPagar/pdfRelatorioCheque" +
        requisicoes.entidade +
        "&t=" +
        requisicoes.header.headers.token
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "contasAPagar/pdfRelatorioCheque?e=" +
      requisicoes.entidade +
      "&t=" +
      requisicoes.header.headers.token;
    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = async () => {
      const file = await new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  render() {
    let content = "";
    if (this.state.tipo != null) {
      switch (this.state.tipo) {
        case "fornecedor":
          content = (
            <Table structured color={"teal"} key={"teal"} celled>
              <Loader active={this.state.loading} inline="centered" />
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={2}>Data Lançamento</Table.HeaderCell>
                  <Table.HeaderCell width={1}>O.P</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Nº Parcela</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Doc</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Venc</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Pagto</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Valor</Table.HeaderCell>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              {this.state.lista.map((e, i) => (
                <Table.Body>
                  <Table.Row key={i}>
                    <Table.Cell sm={2} style={{ textAlign: "right" }}>
                      {e.data_emissao !== null &&
                        format(parseISO(e.data_emissao), "dd/MM/yyyy")}
                    </Table.Cell>

                    <Table.Cell style={{ textAlign: "right" }}>
                      {e.contas_a_pagars.length > 0
                        ? e.contas_a_pagars[0].ordem_pagamento.codigo
                        : ""}
                    </Table.Cell>

                    <Table.Cell style={{ textAlign: "right" }}>
                      {e.contas_a_pagars != 0 &&
                        e.contas_a_pagars[0].ordem_pagamento != null &&
                        e.contas_a_pagars[0].ordem_pagamento.codigo +
                          " / "}{" "}
                      {e.numero_parcela}
                      {e.contas_a_pagars != 0 &&
                        e.contas_a_pagars[0].lancamento_contas != null &&
                        " - " + e.contas_a_pagars[0].lancamento_contas.length}
                    </Table.Cell>

                    <Table.Cell style={{ textAlign: "right" }}>
                      {e.contas_a_pagars.length > 0
                        ? e.contas_a_pagars[0].documento
                        : ""}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "right" }}>
                      {e.data_vencimento !== null &&
                        format(parseISO(e.data_vencimento), "dd/MM/yyyy")}
                    </Table.Cell>
                    {(e.data_cancelamento !== null && (
                      <Table.Cell style={{ textAlign: "right" }}>
                        Cancelado
                      </Table.Cell>
                    )) || (
                      <Table.Cell style={{ textAlign: "right" }}>
                        {e.data_pagamento !== null
                          ? format(parseISO(e.data_pagamento), "dd/MM/yyyy")
                          : "Em aberto"}
                      </Table.Cell>
                    )}

                    <Table.Cell style={{ textAlign: "right" }}>
                      {parseFloat(e.valor).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Table.Cell>
                    <Table.Cell>
                      <ModalVisualiza contaAPagar={e} />
                    </Table.Cell>
                  </Table.Row>
                  {this.state.selectedCheckboxes === true && (
                    <Table.Row>
                      <Table.Cell colSpan={8}>
                        {e.contas_a_pagars[0]?.observacao !== null
                          ? "Obs:" + e.contas_a_pagars[0]?.observacao
                          : "SEM OBSERVAÇÃO"}
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              ))}

              <Table.Footer>
                <Table.Row verticalAlign="middle" textAlign="center">
                  <Table.HeaderCell colSpan={12}>
                    <Menu pagination>
                      <Menu.Item
                        icon
                        onClick={this.voltarPagina}
                        disabled={this.state.pagina === 1}
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item
                        icon
                        onClick={this.proximaPagina}
                        disabled={this.state.pagina === this.state.totalPaginas}
                      >
                        <Icon name="chevron right" />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          );
          break;
        case "emAberto":
          content = (
            <Table color={"yellow"} key={"yellow"} celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1}>Data Vencimento</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Nome</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Situação</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Valor</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.lista.map((e, i) => (
                  <Table.Row key={i}>
                    <Table.Cell style={{ textAlign: "right" }}>
                      {e.data_vencimento !== null &&
                        format(parseISO(e.data_vencimento), "dd/MM/yyyy")}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "right" }}>
                      {e.pessoa !== null && e.pessoa.nome}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "right" }}>
                      {e.data_pagamento == null ? "Em Aberto" : "Pago"}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "right" }}>
                      {parseFloat(e.valor).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>

              <Table.Footer>
                <Table.Row verticalAlign="middle" textAlign="center">
                  <Table.HeaderCell colSpan={4}>
                    <Menu pagination>
                      <Menu.Item
                        icon
                        onClick={this.voltarPagina}
                        disabled={this.state.pagina === 1}
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item
                        icon
                        onClick={this.proximaPagina}
                        disabled={this.state.pagina === this.state.totalPaginas}
                      >
                        <Icon name="chevron right" />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          );
          break;
        case "canceladas":
          content = (
            <Table color={"yellow"} key={"yellow"} celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1}>Documento</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Data Emissão</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Data Vencimento</Table.HeaderCell>
                  <Table.HeaderCell width={1}>
                    Data Cancelamento
                  </Table.HeaderCell>
                  <Table.HeaderCell width={5}>Nome</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Valor</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.lista.map((e, i) => (
                  <Table.Row key={i}>
                    <Table.Cell style={{ textAlign: "right" }}>
                      {e.contas_a_pagars !== null &&
                        e.contas_a_pagars[0].documento}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "right" }}>
                      {e.data_emissao !== null &&
                        format(parseISO(e.data_emissao), "dd/MM/yyyy")}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "right" }}>
                      {e.data_vencimento !== null &&
                        format(parseISO(e.data_vencimento), "dd/MM/yyyy")}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "right" }}>
                      {e.data_cancelamento !== null &&
                        format(parseISO(e.data_cancelamento), "dd/MM/yyyy")}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "left" }}>
                      {e.pessoa !== null && e.pessoa.nome}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "right" }}>
                      {parseFloat(e.valor).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>

              <Table.Footer>
                <Table.Row verticalAlign="middle" textAlign="center">
                  <Table.HeaderCell colSpan={6}>
                    <Menu pagination>
                      <Menu.Item
                        icon
                        onClick={this.voltarPagina}
                        disabled={this.state.pagina === 1}
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item
                        icon
                        onClick={this.proximaPagina}
                        disabled={this.state.pagina === this.state.totalPaginas}
                      >
                        <Icon name="chevron right" />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          );
          break;
        case "pagas":
          content = (
            <Table color={"green"} key={"green"} celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1}>Documento</Table.HeaderCell>
                  <Table.HeaderCell width={2}>O.P</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Data Pagamento</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Data Vencimento</Table.HeaderCell>
                  <Table.HeaderCell>Nome</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Valor</Table.HeaderCell>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.lista.map((e, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>
                      {e.contas_a_pagars != 0 && e.contas_a_pagars[0].documento}
                    </Table.Cell>
                    <Table.Cell>
                      {e.contas_a_pagars != 0 &&
                        e.contas_a_pagars[0].ordem_pagamento != null &&
                        e.contas_a_pagars[0].ordem_pagamento.codigo +
                          " / "}{" "}
                      {e.numero_parcela}
                      {e.contas_a_pagars != 0 &&
                        e.contas_a_pagars[0].lancamento_contas != null &&
                        " - " + e.contas_a_pagars[0].lancamento_contas.length}
                    </Table.Cell>
                    <Table.Cell>
                      {e.data_pagamento !== null &&
                        format(parseISO(e.data_pagamento), "dd/MM/yyyy")}
                    </Table.Cell>
                    <Table.Cell>
                      {e.data_vencimento !== null &&
                        format(parseISO(e.data_vencimento), "dd/MM/yyyy")}
                    </Table.Cell>
                    <Table.Cell>
                      {e.pessoa !== null && e.pessoa.nome}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      {parseFloat(e.valor_pago).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Table.Cell>
                    <Table.Cell>
                      <ModalVisualiza contaAPagar={e} />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>

              <Table.Footer>
                <Table.Row verticalAlign="middle" textAlign="center">
                  <Table.HeaderCell colSpan={4}>
                    <Menu pagination>
                      <Menu.Item
                        icon
                        onClick={this.voltarPagina}
                        disabled={this.state.pagina === 1}
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item
                        icon
                        onClick={this.proximaPagina}
                        disabled={this.state.pagina === this.state.totalPaginas}
                      >
                        <Icon name="chevron right" />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          );
          break;
        case "emissao":
          content = (
            <>
              <Row>
                <Col
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    fontWeight: "bold",
                  }}
                >
                  {"Valor total: " +
                    parseFloat(this.state.valorTotal).toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                </Col>
              </Row>
              <Table color={"green"} key={"yellow"} celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1}>Data Emissão</Table.HeaderCell>
                    <Table.HeaderCell width={6}>Nome</Table.HeaderCell>
                    <Table.HeaderCell width={1}>O.P</Table.HeaderCell>
                    <Table.HeaderCell width={1}>DOC.</Table.HeaderCell>
                    <Table.HeaderCell width={1}>
                      Data Vencimento
                    </Table.HeaderCell>
                    <Table.HeaderCell width={1}>Situação</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Valor</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.lista.map((e, i) => (
                    <Table.Row key={i}>
                      <Table.Cell style={{ textAlign: "right" }}>
                        {e.data_emissao !== null &&
                          format(parseISO(e.data_emissao), "dd/MM/yyyy")}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "left" }}>
                        {e.pessoa !== null && e.pessoa.nome}
                      </Table.Cell>

                      <Table.Cell style={{ textAlign: "right" }}>
                        {e.contas_a_pagars[0] != null
                          ? e.contas_a_pagars[0].ordem_pagamento.codigo != null
                            ? e.contas_a_pagars[0].ordem_pagamento.codigo
                            : "SEM CODIGO"
                          : "SEM CODIGO"}
                      </Table.Cell>

                      <Table.Cell style={{ textAlign: "right" }}>
                        {e.contas_a_pagars[0] != null
                          ? e.contas_a_pagars[0].documento != null
                            ? e.contas_a_pagars[0].documento
                            : "SEM DOCUMENTO"
                          : "SEM DOCUMENTO"}
                      </Table.Cell>

                      <Table.Cell style={{ textAlign: "right" }}>
                        {e.data_vencimento !== null &&
                          format(parseISO(e.data_vencimento), "dd/MM/yyyy")}
                      </Table.Cell>

                      <Table.Cell style={{ textAlign: "right" }}>
                        {e.data_pagamento == null ? "Em Aberto" : "Pago"}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "right" }}>
                        {parseFloat(e.valor).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>

                <Table.Footer>
                  <Table.Row verticalAlign="middle" textAlign="center">
                    <Table.HeaderCell colSpan={12}>
                      <Menu pagination>
                        <Menu.Item
                          icon
                          onClick={this.voltarPagina}
                          disabled={this.state.pagina === 1}
                        >
                          <Icon name="chevron left" />
                        </Menu.Item>
                        <Menu.Item
                          icon
                          onClick={this.proximaPagina}
                          disabled={
                            this.state.pagina === this.state.totalPaginas
                          }
                        >
                          <Icon name="chevron right" />
                        </Menu.Item>
                      </Menu>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </>
          );
          break;
        case "abertoliquidada":
          content = (
            <Table structured color={"teal"} key={"teal"} celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={3}>Fornecedor</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Data Lançamento</Table.HeaderCell>
                  <Table.HeaderCell width={1}>O.P</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Doc</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Data Vencimento</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Pagto</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Valor</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {this.state.lista.map((e, i) => (
                <Table.Body>
                  <Table.Row key={i}>
                    <Table.Cell sm={2} style={{ textAlign: "left" }}>
                      {e.pessoa?.nome !== null
                        ? e.pessoa?.nome
                        : "Sem Fornecedor"}
                    </Table.Cell>
                    <Table.Cell sm={2} style={{ textAlign: "right" }}>
                      {e.data_emissao !== null &&
                        format(parseISO(e.data_emissao), "dd/MM/yyyy")}
                    </Table.Cell>

                    <Table.Cell style={{ textAlign: "right" }}>
                      {e.contas_a_pagars.length > 0
                        ? e.contas_a_pagars[0].ordem_pagamento.codigo
                        : ""}
                    </Table.Cell>

                    <Table.Cell style={{ textAlign: "right" }}>
                      {e.contas_a_pagars.length > 0
                        ? e.contas_a_pagars[0].documento
                        : ""}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "right" }}>
                      {e.data_vencimento !== null &&
                        format(parseISO(e.data_vencimento), "dd/MM/yyyy")}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "right" }}>
                      {e.data_pagamento !== null
                        ? format(parseISO(e.data_pagamento), "dd/MM/yyyy")
                        : "Em aberto"}
                    </Table.Cell>

                    <Table.Cell style={{ textAlign: "right" }}>
                      {parseFloat(e.valor).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Table.Cell>
                  </Table.Row>
                  {this.state.selectedCheckboxes === true && (
                    <Table.Row>
                      <Table.Cell colSpan={7}>
                        {e.contas_a_pagars[0] === undefined ||
                        e.contas_a_pagars[0].observacao === null
                          ? "Sem Obs"
                          : e.contas_a_pagars[0].observacao}
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              ))}

              <Table.Footer>
                <Table.Row verticalAlign="middle" textAlign="center">
                  <Table.HeaderCell colSpan={12}>
                    <Menu pagination>
                      <Menu.Item
                        icon
                        onClick={this.voltarPagina}
                        disabled={this.state.pagina === 1}
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item
                        icon
                        onClick={this.proximaPagina}
                        disabled={this.state.pagina === this.state.totalPaginas}
                      >
                        <Icon name="chevron right" />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          );
          break;
        case "centrocustoelementocusto":
          content = (
            <Table structured color={"blue"} key={"teal"} celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={3}>Fornecedor</Table.HeaderCell>
                  <Table.HeaderCell width={1}>O.P</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Doc</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Venc</Table.HeaderCell>

                  <Table.HeaderCell width={1}>Pagto</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Valor</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              {this.state.lista.map((e, i) => (
                <Table.Body>
                  <Table.Row key={i}>
                    <Table.Cell style={{ textAlign: "left" }}>
                      {e.pessoa.nome}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "left" }}>
                      {e.contas_a_pagars[0] !== undefined
                        ? e.contas_a_pagars[0].ordem_pagamento.codigo
                        : "SEM O.P"}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "left" }}>
                      {e.contas_a_pagars[0] !== undefined
                        ? e.contas_a_pagars[0].documento
                        : "SEM O.P"}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "left" }}>
                      {e.data_vencimento !== undefined
                        ? moment(e.data_vencimento).format("DD-MM-YYYY")
                        : "SEM VENC."}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "left" }}>
                      {e.data_pagamento !== null
                        ? moment(e.data_pagamento).format("DD-MM-YYYY")
                        : "EM ABERTO"}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "left" }}>
                      {e.valor_pago !== null
                        ? "R$ " +
                          // parseFloat(e.rateios[0].valor).toLocaleString(
                          //   undefined,
                          //   {
                          //     minimumFractionDigits: 2,
                          //     maximumFractionDigits: 2,
                          //   }
                          // )
                          parseFloat(e.valor_pago).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : "R$ " +
                          parseFloat(e.valor).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </Table.Cell>
                  </Table.Row>
                  {this.state.selectedCheckboxes === true && (
                    <Table.Row>
                      <Table.Cell colSpan={6}>
                        {e.contas_a_pagars[0].observacao !== null
                          ? "Obs:" + e.contas_a_pagars[0].observacao
                          : "SEM OBSERVAÇÃO"}
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              ))}
              <Table.Footer>
                <Table.Row verticalAlign="middle" textAlign="center">
                  <Table.HeaderCell colSpan={12}>
                    <Menu pagination>
                      <Menu.Item
                        icon
                        onClick={this.voltarPagina}
                        disabled={this.state.pagina === 1}
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item
                        icon
                        onClick={this.proximaPagina}
                        disabled={this.state.pagina === this.state.totalPaginas}
                      >
                        <Icon name="chevron right" />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          );
          break;
        case "elementocusto":
          content = (
            <Table structured color={"blue"} key={"teal"} celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={3}>Fornecedor</Table.HeaderCell>
                  <Table.HeaderCell width={1}>O.P</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Doc</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Venc</Table.HeaderCell>

                  <Table.HeaderCell width={1}>Pagto</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Valor</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {this.state.lista.map((e, i) => (
                <Table.Body>
                  <Table.Row key={i}>
                    <Table.Cell style={{ textAlign: "left" }}>
                      {e.pessoa.nome}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "left" }}>
                      {e.contas_a_pagars[0] !== undefined
                        ? e.contas_a_pagars[0].ordem_pagamento.codigo
                        : "SEM O.P"}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "left" }}>
                      {e.contas_a_pagars[0] !== undefined
                        ? e.contas_a_pagars[0].documento
                        : "SEM O.P"}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "left" }}>
                      {e.data_vencimento !== undefined
                        ? moment(e.data_vencimento).format("DD-MM-YYYY")
                        : "SEM VENC."}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "left" }}>
                      {e.data_pagamento !== null
                        ? moment(e.data_pagamento).format("DD-MM-YYYY")
                        : "EM ABERTO"}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "left" }}>
                      {e.rateios[0] !== null
                        ? "R$ " +
                          parseFloat(e.rateios[0].valor).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        : "Sem Valor"}
                    </Table.Cell>
                  </Table.Row>
                  {this.state.selectedCheckboxes === true && (
                    <Table.Row>
                      <Table.Cell colSpan={6}>
                        {e.contas_a_pagars[0].observacao !== null
                          ? "Obs:" + e.contas_a_pagars[0].observacao
                          : "SEM OBSERVAÇÃO"}
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              ))}

              <Table.Footer>
                <Table.Row verticalAlign="middle" textAlign="center">
                  <Table.HeaderCell colSpan={12}>
                    <Menu pagination>
                      <Menu.Item
                        icon
                        onClick={this.voltarPagina}
                        disabled={this.state.pagina === 1}
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item
                        icon
                        onClick={this.proximaPagina}
                        disabled={this.state.pagina === this.state.totalPaginas}
                      >
                        <Icon name="chevron right" />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          );
          break;
        case "centrocusto":
          content = (
            <Table structured color={"blue"} key={"teal"} celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={3}>Fornecedor</Table.HeaderCell>
                  <Table.HeaderCell width={1}>O.P</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Doc</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Venc</Table.HeaderCell>

                  <Table.HeaderCell width={1}>Pagto</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Valor</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              {this.state.lista.map((e, i) =>
                e.rateios.map((a) => (
                  <Table.Body>
                    <Table.Row key={i}>
                      <Table.Cell style={{ textAlign: "left" }}>
                        {e.pessoa.nome}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "left" }}>
                        {e.contas_a_pagars[0] !== undefined
                          ? e.contas_a_pagars[0].ordem_pagamento.codigo
                          : "SEM O.P"}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "left" }}>
                        {e.contas_a_pagars[0] !== undefined
                          ? e.contas_a_pagars[0].documento
                          : "SEM O.P"}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "left" }}>
                        {e.data_vencimento !== undefined
                          ? moment(e.data_vencimento).format("DD-MM-YYYY")
                          : "SEM VENC."}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "left" }}>
                        {e.data_pagamento !== null
                          ? moment(e.data_pagamento).format("DD-MM-YYYY")
                          : "EM ABERTO"}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "left" }}>
                        {e.rateios[0] !== null
                          ? "R$ " +
                            parseFloat(a.valor).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "Sem Valor"}
                      </Table.Cell>
                    </Table.Row>
                    {this.state.selectedCheckboxes === true && (
                      <Table.Row>
                        <Table.Cell colSpan={6}>
                          {e.contas_a_pagars[0].observacao !== null
                            ? "Obs:" + e.contas_a_pagars[0].observacao
                            : "SEM OBSERVAÇÃO"}
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                ))
              )}
              <Table.Footer>
                <Table.Row verticalAlign="middle" textAlign="center">
                  <Table.HeaderCell colSpan={12}>
                    <Menu pagination>
                      <Menu.Item
                        icon
                        onClick={this.voltarPagina}
                        disabled={this.state.pagina === 1}
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item
                        icon
                        onClick={this.proximaPagina}
                        disabled={this.state.pagina === this.state.totalPaginas}
                      >
                        <Icon name="chevron right" />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          );
          break;

        case "cheque":
          content = (
            <>
              {this.state.lista.map((e, i) => (
                <>
                  <h3>
                    {
                      enumMes[
                        moment(e[0].lancamento_contas[0].data_pagamento).get(
                          "month"
                        )
                      ].label
                    }
                  </h3>
                  <Table celled selectable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Data Liquidação</Table.HeaderCell>
                        <Table.HeaderCell>O.P</Table.HeaderCell>
                        <Table.HeaderCell>Doc</Table.HeaderCell>
                        <Table.HeaderCell>Cheque</Table.HeaderCell>
                        <Table.HeaderCell>Fornecedor</Table.HeaderCell>
                        <Table.HeaderCell>Vencimento</Table.HeaderCell>
                        <Table.HeaderCell>Valor</Table.HeaderCell>
                        {/* <Table.HeaderCell></Table.HeaderCell> */}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {e.map((e2, i) => (
                        <Table.Row key={i}>
                          <Table.Cell>
                            {moment(
                              e2.lancamento_contas[0].data_pagamento
                            ).format("DD/MM/YYYY")}
                          </Table.Cell>
                          <Table.Cell>{e2.ordem_pagamento.codigo}</Table.Cell>
                          <Table.Cell>{e2.documento}</Table.Cell>
                          <Table.Cell>
                            {e2.lancamento_contas[0].itens[0].numero_cheque}
                          </Table.Cell>
                          <Table.Cell>{e2.pessoa.nome}</Table.Cell>
                          <Table.Cell>
                            {moment(
                              e2.lancamento_contas[0].data_vencimento
                            ).format("DD/MM/YYYY")}
                          </Table.Cell>
                          <Table.Cell>
                            {parseFloat(
                              e2.lancamento_contas[0].valor
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Table.Cell>
                          {/* <Table.Cell></Table.Cell> */}
                        </Table.Row>
                      ))}
                    </Table.Body>
                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell style={{ fontWeight: "bold" }}>
                          {parseFloat(e.valorTotal).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Table.HeaderCell>
                        {/* <Table.HeaderCell></Table.HeaderCell> */}
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                </>
              ))}
            </>
          );
          break;
        case "vazio":
          content = (
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Data Pagamento</Table.HeaderCell>
                  <Table.HeaderCell>Data Vencimento</Table.HeaderCell>
                  <Table.HeaderCell>Nome</Table.HeaderCell>
                  <Table.HeaderCell>Valor</Table.HeaderCell>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body></Table.Body>
              <Table.Footer></Table.Footer>
            </Table>
          );
          break;
        default:
          break;
      }
    }
    return (
      <div className="lista">
        <Row>
          <Col sm={3}>
            <Form.Label>Tipo</Form.Label>
            <Form.Control
              placeholder="Tipo"
              label="Tipo"
              id="tipo"
              as="select"
              name="tipo"
              onChange={this.alteracao}
              required
              value={this.state.tipo}
            >
              {/* <option></option> */}
              <option value="centrocusto">Centro de Custo</option>
              <option value="centrocustoelementocusto">
                Centro de Custo x Elemento de Custo
              </option>
              <option value="emissao">Data Emissão</option>
              <option value="elementocusto">Elemento de Custo</option>
              <option value="emAberto">Em Aberto</option>
              <option value="abertoliquidada">Em aberto e Liquidadas</option>
              <option value="canceladas">Canceladas</option>
              <option value="fornecedor">Fornecedor</option>
              <option value="pagas">Liquidadas</option>
              <option value="cheque">Cheque</option>
            </Form.Control>
          </Col>

          {this.state.tipo === "fornecedor" && (
            <Col sm={8}>
              <Row>
                <Col sm={3}>
                  <Form.Label>Tipo</Form.Label>
                  <Form.Control
                    placeholder="Tipo"
                    label="Tipo"
                    id="tipo"
                    as="select"
                    name="tipo_busca"
                    onChange={this.alteracao}
                    value={this.state.tipo_busca}
                    required
                  >
                    <option></option>
                    <option value="nome">Nome</option>
                    <option value="codigo">Código</option>
                  </Form.Control>
                </Col>

                {this.state.tipo_busca === "nome" && (
                  <Col sm={9}>
                    <Form.Label>Fornecedor</Form.Label>
                    <Select
                      components={{}}
                      placeholder="Digite para buscar"
                      name="pessoa"
                      value={this.state.pessoa}
                      onChange={this.alteracaoSelectPessoa}
                      options={this.state.options}
                      onInputChange={this.pesquisaPessoa}
                      inputId="pesquisaPessoa"
                    />
                  </Col>
                )}

                {this.state.tipo_busca === "codigo" && (
                  <Col sm={4} hidden={this.state.tipo_busca === "nome"}>
                    <Form.Label>Busca</Form.Label>
                    <Select
                      placeholder="Digite para buscar"
                      name="pessoa"
                      value={this.state.pessoa}
                      onKeyDown={this.pesquisaPessoaReduzido}
                      onChange={this.alteracaoSelectPessoa}
                      inputId="pesquisaPessoaResuzido"
                      options={this.state.options}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          )}
          {this.state.tipo === "abertoliquidada" && (
            <Col sm={9}>
              <Row>
                <Col sm={4}>
                  <Form.Label>Tipo</Form.Label>
                  <Form.Control
                    placeholder="Tipo"
                    label="Tipo"
                    id="tipo"
                    name="tipoabertaliquidada"
                    as="select"
                    onChange={this.alteracao}
                    value={this.state.tipoabertaliquidada}
                    required
                  >
                    <option value=""></option>
                    <option value="emissao">Data de Emissão</option>
                    <option value="vencimento">Data de Vencim.</option>
                  </Form.Control>
                </Col>

                <Col sm={2}>
                  <Button
                    primary
                    onClick={this.buscarTituloAbertoLiquidado}
                    style={{ marginTop: "25px" }}
                    disabled={this.state.lista.length === 0}
                    loading={this.state.buscando}
                  >
                    Imprimir
                  </Button>
                </Col>

                <>
                  <Col sm={2}>
                    <Button
                      variant="success"
                      style={{ marginTop: "25px" }}
                      onClick={this.buscaTitulos}
                      disabled={
                        this.state.tipoabertaliquidada === undefined ||
                        this.state.loading
                      }
                      loading={this.state.loading}
                    >
                      Buscar
                    </Button>
                  </Col>
                </>
              </Row>
            </Col>
          )}

          {(this.state.tipo === "emAberto" ||
            this.state.tipo === "canceladas") && (
            <Col sm={8}>
              <Row>
                <Col sm={3}>
                  <Form.Label>Data Inicio</Form.Label>
                  <FormControl
                    placeholder="Data Inicio"
                    aria-describedby="basic-addon1"
                    type="date"
                    name="data_inicio"
                    onChange={this.alteracao}
                  />
                </Col>
                <Col sm={3}>
                  <Form.Label>Data Final</Form.Label>
                  <FormControl
                    placeholder="Data Final"
                    aria-describedby="basic-addon1"
                    type="date"
                    name="data_final"
                    onChange={this.alteracao}
                  />
                </Col>
                <Col sm={4}>
                  <Button
                    primary
                    onClick={
                      this.state.tipo === "emAberto"
                        ? this.buscaTituloAberto
                        : this.buscaTituloCancelado
                    }
                    style={{ marginTop: "25px" }}
                    disabled={this.state.lista.length === 0}
                    loading={this.state.buscando}
                  >
                    Imprimir
                  </Button>
                  <Button
                    style={{ marginTop: "25px", marginLeft: "25px" }}
                    onClick={this.buscaTitulos}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
              <Row>
                {(this.state.tipo === "canceladas") && (
                  <Col sm={4}>
                    <Form.Label>Filtrar por</Form.Label>
                    <Form.Control
                      placeholder="Filtrar por"
                      label="Filtrar por"
                      id="filtrar_por_data"
                      as="select"
                      name="filtrar_por_data"
                      onChange={this.alteracao}
                      required
                      value={this.state.filtrar_por_data}
                    >
                      {/* <option></option> */}
                      <option value="data_emissao">Data Emissão</option>
                      <option value="data_vencimento">Data Vencimento</option>
                      <option value="data_cancelamento">Data Cancelamento</option>
                    </Form.Control>
                  </Col>
                )}
              </Row>
            </Col>
          )}

          {this.state.tipo === "emissao" && (
            <>
              <Col></Col>
              <Col md="auto">
                <Button
                  primary
                  onClick={this.buscarTituloPorEmissao}
                  style={{ marginTop: "25px" }}
                  disabled={this.state.lista.length === 0}
                >
                  Imprimir
                </Button>
              </Col>
              <Col md="auto">
                <Button
                  variant="success"
                  style={{ marginTop: "25px" }}
                  onClick={this.buscaTitulos}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                >
                  Buscar
                </Button>
              </Col>
            </>
          )}
          {this.state.tipo === "pagas" && (
            <Col>
              <Row>
                <Col md={"auto"}>
                  <Form.Label>Data Inicio</Form.Label>
                  <FormControl
                    placeholder="Data Inicio"
                    aria-describedby="basic-addon1"
                    type="date"
                    name="data_inicial_liq"
                    onChange={this.alteracao}
                  />
                </Col>
                <Col md={"auto"}>
                  <Form.Label>Data Final</Form.Label>
                  <FormControl
                    placeholder="Data Final"
                    aria-describedby="basic-addon1"
                    type="date"
                    name="data_final_liq"
                    onChange={this.alteracao}
                  />
                </Col>

                <Col sm={2}>
                  <Button
                    primary
                    onClick={this.buscarTituloLiquidado}
                    style={{ marginTop: "25px" }}
                    disabled={this.state.lista.length === 0}
                  >
                    Imprimir
                  </Button>
                </Col>
                <Col sm={2}>
                  <Button
                    variant="success"
                    style={{ marginTop: "25px", marginLeft: "25px" }}
                    onClick={this.buscaTitulos}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
          {this.state.tipo === "centrocustoelementocusto" && (
            <Col sm={8}>
              <Row>
                <Col sm={5}>
                  <Form.Label>Centro de Custo</Form.Label>
                  <Select
                    placeholder="Adicionar Centro de Custo"
                    onChange={this.alteracaoSelectCentroCusto}
                    options={this.state.optionsCC}
                    onKeyDown={this.pesquisaCentroCusto}
                  />
                </Col>
                <Col sm={5}>
                  <Form.Label>Elemento de Custo</Form.Label>
                  <Select
                    placeholder="Adicionar Elemen. de Custo"
                    onChange={this.alteracaoSelectElementoCusto}
                    options={this.state.selectvalue}
                    onKeyDown={this.pesquisaElementoCusto}
                  />
                </Col>
              </Row>
            </Col>
          )}
          {this.state.tipo === "elementocusto" && (
            <>
              <Col sm={2} style={{ paddingTop: "34px" }}>
                <Checkbox
                  label=" Imprimir Observações"
                  onClick={this.toggleCheckbox}
                />
              </Col>

              <>
                <Col sm={2}>
                  <Button
                    primary
                    onClick={this.buscarTituloElementoCusto}
                    style={{ marginTop: "25px" }}
                    disabled={this.state.lista.length === 0}
                  >
                    Imprimir
                  </Button>
                </Col>
              </>
              <Col sm={2}>
                <Button
                  variant="success"
                  style={{ marginTop: "25px" }}
                  onClick={this.buscaTitulos}
                  disabled={
                    this.state.formabusca === null ||
                    this.state.elemento_de_custo_id === undefined
                  }
                  loading={this.state.loading}
                >
                  Buscar
                </Button>
              </Col>
            </>
          )}
          {this.state.tipo === "centrocusto" && (
            <>
              <Col sm={2} style={{ paddingTop: "34px", paddingRight: "15px" }}>
                <Checkbox
                  label=" Imprimir Observações "
                  onClick={this.toggleCheckbox}
                />
              </Col>

              <>
                <Col sm={2}>
                  <Button
                    primary
                    onClick={this.buscarTituloApenasCentroCusto}
                    style={{ marginTop: "25px" }}
                    disabled={this.state.lista.length === 0}
                  >
                    Imprimir
                  </Button>
                </Col>
              </>
              <Col sm={2}>
                <Button
                  variant="success"
                  style={{ marginTop: "25px" }}
                  onClick={this.buscaTitulos}
                  disabled={
                    this.state.formabusca === null ||
                    this.state.centro_de_custo_id === undefined ||
                    this.state.loading
                  }
                  loading={this.state.loading}
                >
                  Buscar
                </Button>
              </Col>
            </>
          )}

          {this.state.tipo === "cheque" && (
            <Col sm={8}>
              <Row>
                <Col sm={3}>
                  <Form.Label>Número do cheque</Form.Label>
                  <FormControl
                    placeholder="nº"
                    aria-describedby="basic-addon1"
                    onChange={this.alteracao}
                    name="numero_cheque"
                  />
                </Col>
                <Col md="auto">
                  <Form.Label>Data Inicio</Form.Label>
                  <FormControl
                    placeholder="Data Inicio"
                    aria-describedby="basic-addon1"
                    type="date"
                    name="data_inicio"
                    onChange={this.alteracao}
                    value={this.state.data_inicio}
                  />
                </Col>
                <Col md="auto">
                  <Form.Label>Data Final</Form.Label>
                  <FormControl
                    placeholder="Data Final"
                    aria-describedby="basic-addon1"
                    type="date"
                    name="data_final"
                    onChange={this.alteracao}
                    value={this.state.data_final}
                  />
                </Col>
                <Col
                  fluid
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    paddingTop: 10,
                  }}
                >
                  <Button
                    color="green"
                    onClick={() => this.verificacoesCampos("cheque")}
                    disabled={this.state.loading}
                    loading={this.state.loading}
                  >
                    Buscar
                  </Button>
                </Col>
                <Col
                  fluid
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                    paddingTop: 10,
                  }}
                >
                  <Button
                    primary
                    loading={this.state.loading}
                    onClick={this.imprimirRelatorioCheque}
                  >
                    Imprimir
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Row>
          <Col sm={12}>
            {this.state.tipo === "emissao" && (
              <Row>
                <Col sm={3}>
                  <Form.Label>Data Inicio</Form.Label>
                  <FormControl
                    placeholder="Data Inicio"
                    aria-describedby="basic-addon1"
                    type="date"
                    name="data_inicio"
                    onChange={this.alteracao}
                  />
                </Col>
                <Col sm={3}>
                  <Form.Label>Data Final</Form.Label>
                  <FormControl
                    placeholder="Data Final"
                    aria-describedby="basic-addon1"
                    type="date"
                    name="data_final"
                    onChange={this.alteracao}
                  />
                </Col>
                <Col sm={3}>
                  <Form.Label>Impressão</Form.Label>
                  <Form.Control
                    placeholder="Tipo"
                    label="Tipo"
                    name="tipoimpressaoemissao"
                    as="select"
                    onChange={this.alteracao}
                    required
                  >
                    <option value="todas">Todas</option>
                    <option value="impressa">Impressas</option>
                    <option value="naoimpressa">Não Impressas</option>
                  </Form.Control>
                </Col>
                <Col sm={3}>
                  <Form.Label>Situação</Form.Label>
                  <Form.Control
                    placeholder="Situacao"
                    label="Situacao"
                    name="situacao"
                    as="select"
                    onChange={this.alteracao}
                    value={this.state.situacao}
                    required
                  >
                    <option value="todas">Todas</option>
                    <option value="emAberto">Em aberto</option>
                    <option value="pagas">Pagas</option>
                  </Form.Control>
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            {/* {this.state.options.length > 0  */}
            {this.state.pessoa !== null && this.state.tipo === "fornecedor" && (
              <Container style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                <Row>
                  <Col sm={2}>
                    <Form.Label>Data Inicio</Form.Label>
                    <FormControl
                      placeholder="Data Inicio"
                      aria-describedby="basic-addon1"
                      type="date"
                      name="data_inicio_forn"
                      onChange={this.alteracao}
                    />
                  </Col>
                  <Col sm={2}>
                    <Form.Label>Data Final</Form.Label>
                    <FormControl
                      placeholder="Data Final"
                      aria-describedby="basic-addon1"
                      type="date"
                      name="data_final_forn"
                      onChange={this.alteracao}
                    />
                  </Col>
                  <Col sm={2}>
                    <Form.Label>Forma</Form.Label>
                    <Form.Control
                      placeholder="Forma"
                      label="Forma"
                      id="formabusca"
                      as="select"
                      name="formabusca"
                      onChange={this.alteracao}
                      required
                    >
                      <option></option>
                      <option value="extrato">Extrato</option>
                      <option value="emAbertoForn">Em Aberto</option>
                      <option value="pagasForn">Liquidadas</option>
                    </Form.Control>
                  </Col>

                  <Col sm={2} style={{ paddingTop: "34px" }}>
                    <Checkbox
                      label=" Imprimir Observações"
                      onClick={this.toggleCheckbox}
                    />
                  </Col>

                  <Col sm={2}>
                    <Button
                      variant="success"
                      style={{ marginTop: "25px" }}
                      onClick={this.buscaTitulos}
                      disabled={
                        this.state.pessoa === null || this.state.loading
                      }
                      loading={this.state.loading}
                    >
                      Buscar
                    </Button>
                  </Col>

                  <>
                    <Col sm={2}>
                      <Button
                        primary
                        onClick={this.imprimirPdfTituloPorFornecedor}
                        style={{ marginTop: "25px", marginLeft: "-28px" }}
                        disabled={this.state.lista.length === 0}
                      >
                        Imprimir
                      </Button>
                    </Col>
                  </>
                </Row>
              </Container>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            {this.state.tipo === "abertoliquidada" && (
              <Container style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                <Row>
                  <Col sm={3}>
                    <Form.Label>Data Inicio</Form.Label>
                    <FormControl
                      placeholder="Data Inicio"
                      aria-describedby="basic-addon1"
                      type="date"
                      name="data_inicio"
                      onChange={this.alteracao}
                    />
                  </Col>
                  <Col sm={3}>
                    <Form.Label>Data Final</Form.Label>
                    <FormControl
                      placeholder="Data Final"
                      aria-describedby="basic-addon1"
                      type="date"
                      name="data_final"
                      onChange={this.alteracao}
                    />
                  </Col>
                  <Col sm={3} style={{ paddingTop: "34px" }}>
                    <Checkbox
                      label=" Imprimir Observações"
                      onClick={this.toggleCheckbox}
                    />
                  </Col>
                  {this.state.tipoabertaliquidada === "emissao" && (
                    <Col sm={2} style={{ paddingTop: "34px" }}>
                      <Checkbox
                        label=" Imprimir Centro e Elemento"
                        onClick={this.toggleCheckboxElement}
                      />
                    </Col>
                  )}
                </Row>
              </Container>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            {this.state.tipo === "centrocustoelementocusto" && (
              <Container style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                <Row style={{ display: "flex" }}>
                  <Col sm={2}>
                    <Form.Label>Forma</Form.Label>
                    <Form.Control
                      placeholder="Forma"
                      label="Forma"
                      id="formabusca"
                      as="select"
                      name="formabusca"
                      onChange={this.alteracao}
                      required
                    >
                      <option></option>
                      <option value="extrato">Extrato</option>
                      <option value="emAberto">Em Aberto</option>
                      <option value="pagas">Liquidadas</option>
                      <option value="canceladas">Canceladas</option>
                    </Form.Control>
                  </Col>

                  {this.state.formabusca === "extrato" && (
                    <Col sm={2}>
                      <Form.Label>Filtro</Form.Label>
                      <Form.Control
                        label="Filtro"
                        id="filtroData"
                        as="select"
                        name="filtroData"
                        value={this.state.filtroData}
                        onChange={this.alteracao}
                        required
                      >
                        <option value="dataEmissao">Data de Emissão</option>
                        <option value="dataVencimento">
                          Data de Vencimento
                        </option>
                      </Form.Control>
                    </Col>
                  )}

                  <Col sm={2}>
                    <Form.Label>Data Inicio</Form.Label>
                    <FormControl
                      placeholder="Data Inicio"
                      aria-describedby="basic-addon1"
                      type="date"
                      name="data_inicio"
                      onChange={this.alteracao}
                    />
                  </Col>
                  <Col sm={2}>
                    <Form.Label>Data Final</Form.Label>
                    <FormControl
                      placeholder="Data Final"
                      aria-describedby="basic-addon1"
                      type="date"
                      name="data_final"
                      onChange={this.alteracao}
                    />
                  </Col>
                  {this.state.formabusca === "extrato" && <Col sm={4}></Col>}
                  {this.state.formabusca === "extrato" && <Col sm={6}></Col>}

                  <Col
                    sm={2}
                    style={{
                      paddingTop: "34px",
                      marginLeft: "auto",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Checkbox
                      label=" Imprimir Observações"
                      onClick={this.toggleCheckbox}
                    />
                  </Col>

                  <Col
                    sm={1}
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="success"
                      style={{ marginTop: "25px" }}
                      onClick={this.buscaTitulos}
                      disabled={
                        this.state.formabusca === null ||
                        this.state.centro_de_custo_id === undefined ||
                        this.state.loading
                      }
                      loading={this.state.loading}
                    >
                      Buscar
                    </Button>
                  </Col>

                  <Col
                    sm={1}
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      primary
                      onClick={this.buscarTituloCentroCusto}
                      style={{ marginTop: "25px", marginLeft: "-60px" }}
                      disabled={this.state.lista.length === 0}
                      loading={this.state.buscando}
                    >
                      Imprimir
                    </Button>
                  </Col>
                </Row>
              </Container>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            {this.state.tipo === "elementocusto" && (
              <Container style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                <Row>
                  <Col sm={5}>
                    <Form.Label>Elemento de Custo</Form.Label>
                    <Select
                      placeholder="Adicionar ELemento de custo"
                      onChange={this.alteracaoSelectElementoCusto}
                      options={this.state.selectvalueElemento}
                      onKeyDown={this.pesquisaElementoCustoElemento}
                    />
                  </Col>

                  <Col sm={2}>
                    <Form.Label>Data Inicio</Form.Label>
                    <FormControl
                      placeholder="Data Inicio"
                      aria-describedby="basic-addon1"
                      type="date"
                      name="data_inicio"
                      onChange={this.alteracao}
                    />
                  </Col>
                  <Col sm={2}>
                    <Form.Label>Data Final</Form.Label>
                    <FormControl
                      placeholder="Data Final"
                      aria-describedby="basic-addon1"
                      type="date"
                      name="data_final"
                      onChange={this.alteracao}
                    />
                  </Col>
                  <Col sm={2}>
                    <Form.Label>Forma</Form.Label>
                    <Form.Control
                      placeholder="Forma"
                      label="Forma"
                      id="formabusca"
                      as="select"
                      name="formabusca"
                      onChange={this.alteracao}
                      required
                    >
                      <option></option>
                      <option value="extrato">Extrato</option>
                      <option value="emAberto">Em Aberto</option>
                      <option value="pagas">Liquidadas</option>
                      <option value="canceladas">Canceladas</option>
                    </Form.Control>
                  </Col>
                </Row>
              </Container>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            {this.state.tipo === "centrocusto" && (
              <Container style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                <Row>
                  <Col sm={5}>
                    <Form.Label>Centro de Custo</Form.Label>
                    <Select
                      placeholder="Adicionar Centro de Custo"
                      onChange={this.alteracaoSelectCentroCusto}
                      options={this.state.optionsCC}
                      onKeyDown={this.pesquisaCentroCusto}
                    />
                  </Col>

                  <Col sm={2}>
                    <Form.Label>Data Inicio</Form.Label>
                    <FormControl
                      placeholder="Data Inicio"
                      aria-describedby="basic-addon1"
                      type="date"
                      name="data_inicio"
                      onChange={this.alteracao}
                    />
                  </Col>
                  <Col sm={2}>
                    <Form.Label>Data Final</Form.Label>
                    <FormControl
                      placeholder="Data Final"
                      aria-describedby="basic-addon1"
                      type="date"
                      name="data_final"
                      onChange={this.alteracao}
                    />
                  </Col>
                  <Col sm={2}>
                    <Form.Label>Forma</Form.Label>
                    <Form.Control
                      placeholder="Forma"
                      label="Forma"
                      id="formabusca"
                      as="select"
                      name="formabusca"
                      onChange={this.alteracao}
                      required
                    >
                      <option></option>
                      <option value="extrato">Extrato</option>
                      <option value="emAberto">Em Aberto</option>
                      <option value="pagas">Liquidadas</option>
                      <option value="canceladas">Canceladas</option>
                    </Form.Control>
                  </Col>
                </Row>
              </Container>
            )}
          </Col>
        </Row>
        <Row paddingTop={15}>
          <Col fluid>
            {this.state.erro && (
              <Message negative>
                <Message.Header>Problema ao buscar os dados</Message.Header>
                <Message.Content>
                  Ocorreu um erro ao buscar os dados. Verifique os filtros e
                  tente novamente.
                </Message.Content>
              </Message>
            )}
          </Col>
        </Row>

        {content}
      </div>
    );
  }
}
