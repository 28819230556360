/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import {
  Container,
  Header,
  Menu,
  Segment,
  Icon,
  Table,
  Divider,
  Grid,
  Label,
  Image,
  TextArea,
  Checkbox,
} from "semantic-ui-react";
import { Form, FormControl, Col, Row, Alert } from "react-bootstrap";
import { MenuItem, Select, IconButton } from "@material-ui/core/";
import PrintIcon from "@material-ui/icons/Print";

import requisicoes from "../../../utils/Requisicoes";
import api from "../../../services/api";
import ListaClassificacao from "./listaClassificacao";

import "../styles.css";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campeonatoCadastrados: [],
      infoClassificacaoCampeonato: [],
      campeonatoSelecionadoId: null,
    };

    this.buscaCampeonatosCadastrador();
  }
  imprimir = async () => {
    const grupoPorCampeonato = await api.post(
      "/campeonato/gerarPDFClassificacao" + requisicoes.entidade,

      {
        campeonato_id: this.state.campeonatoSelecionadoId,
        descricao: "classificacao",
      },
      requisicoes.header
    );

    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");

    const r = await api.get(
      "/campeonato/buscaPdfClassificacao" +
        requisicoes.entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.id
    );
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "campeonato/buscaPdfClassificacao" +
      requisicoes.entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.id;

    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  buscaCampeonatosCadastrador = async () => {
    const retorno = await api.post(
      "/campeonato/listar" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    this.setState({
      campeonatoCadastrados: retorno.data,
    });
  };

  buscaInformacaoeCampeonato = async (event) => {
    const grupoPorCampeonato = await api.post(
      "/grupoCampeonato/listar" + requisicoes.entidade,
      { campeonato_id: event.target.value.id },
      requisicoes.header
    );
    this.setState({ campeonatoSelecionadoId: event.target.value.id });

    let grupos = [];

    for (let index = 0; index < grupoPorCampeonato.data.length; index++) {
      grupos[index] = [];
      for (
        let indx = 0;
        indx < grupoPorCampeonato.data[index].g_c_e.length;
        indx++
      ) {
        grupos[index][indx] = {
          nome_time:
            grupoPorCampeonato.data[index].g_c_e[indx].equipe.organizacao.nome,
          equipe_id: grupoPorCampeonato.data[index].g_c_e[indx].equipe_id,
          pontuacao: pontuacao(grupoPorCampeonato.data[index].g_c_e[indx]),
          jogos: jogos(grupoPorCampeonato.data[index].g_c_e[indx]),
          gols: gols(grupoPorCampeonato.data[index].g_c_e[indx]),
          vitorias: vitorias(grupoPorCampeonato.data[index].g_c_e[indx]),
          empates: empates(grupoPorCampeonato.data[index].g_c_e[indx]),
          aproveitamento: aproveitamento(
            grupoPorCampeonato.data[index].g_c_e[indx],
            event.target.value.pontos_ganhos_vitoria
          ),

          derrotas: derrotas(grupoPorCampeonato.data[index].g_c_e[indx]),
        };

        function pontuacao(info) {
          var pontosPorEmpate = event.target.value.pontos_ganhos_empate;
          var pontosPorVitoria = event.target.value.pontos_ganhos_vitoria;

          var empate = 0;
          var vitoria = 0;
          var pontuacao = 0;
          for (let index = 0; index < info.equipe.placares.length; index++) {
            if (info.equipe.placares[index].empate) {
              empate += 1;
            }
            if (info.equipe.placares[index].colocacao === 1) {
              vitoria += 1;
            }
          }

          pontuacao =
            parseInt(vitoria) * pontosPorVitoria +
            parseInt(empate) * pontosPorEmpate;

          return pontuacao;
        }

        function jogos(info) {
          var jogos = 0;

          for (let index = 0; index < info.equipe.placares.length; index++) {
            if (
              info.equipe.placares[index].colocacao ||
              info.equipe.placares[index].empate
            ) {
              jogos += 1;
            }
          }

          return jogos;
        }
        function gols(info) {
          var gols = 0;
          for (let index = 0; index < info.equipe.placares.length; index++) {
            gols += info.equipe.placares[index].pontuacao;
          }

          return gols;
        }

        function vitorias(info) {
          var vitorias = 0;
          for (let index = 0; index < info.equipe.placares.length; index++) {
            if (
              info.equipe.placares[index].colocacao === 1 &&
              !info.equipe.placares[index].empate
            ) {
              vitorias += 1;
            }
          }
          return vitorias;
        }

        function empates(info) {
          var empates = 0;
          for (let index = 0; index < info.equipe.placares.length; index++) {
            if (info.equipe.placares[index].empate) {
              empates += 1;
            }
          }
          return empates;
        }
        function derrotas(info) {
          var derrotas = 0;
          for (let index = 0; index < info.equipe.placares.length; index++) {
            if (
              !info.equipe.placares[index].empate &&
              info.equipe.placares[index].colocacao === 2
            ) {
              derrotas += 1;
            }
          }
          return derrotas;
        }

        function aproveitamento(info, pontosPorVitoria) {
          var jogos = 0;
          var pontosPorEmpate = 1;
          var empate = 0;
          var vitoria = 0;
          var pontuacao = 0;
          var aproveitamento = 0;

          for (let index = 0; index < info.equipe.placares.length; index++) {
            if (
              info.equipe.placares[index].colocacao ||
              info.equipe.placares[index].empate
            ) {
              jogos += 1;
            }
            if (info.equipe.placares[index].empate) {
              empate += 1;
            }
            if (info.equipe.placares[index].colocacao === 1) {
              vitoria += 1;
            }
          }

          pontuacao =
            parseInt(vitoria) * pontosPorVitoria +
            parseInt(empate) * pontosPorEmpate;
          if (jogos>0) {
            aproveitamento = parseFloat(
              (100 / (jogos * pontosPorVitoria)) * pontuacao
            ).toFixed(2);
          }
          else{
            aproveitamento = 100
          }


          return aproveitamento;
        }

        grupos[index].sort(function (a, b) {
          if (a.pontuacao > b.pontuacao) {
            return -1;
          }
          if (a.pontuacao < b.pontuacao) {
            return 1;
          }
          if (a.vitorias > b.vitorias) {
            return -1;
          }
          if (a.vitorias < b.vitorias) {
            return 1;
          }

          if (a.gols > b.gols) {
            return -1;
          }
          if (a.gols < b.gols) {
            return 1;
          }
          return 0;
        });
      }
    }
    this.setState({ infoClassificacaoCampeonato: grupos });
  };

  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Col style={{ padding: 0 }}>
          <Segment clearing>
            <Header>
              <h3> Campeonatos Cadastrados</h3>
              <Divider style={{ marginTop: "-1ch" }} />
            </Header>
            <Row>
              <Col sm={6}>
                <Select
                  fullWidth
                  variant="standard"
                  label="aa"
                  onChange={(event) => this.buscaInformacaoeCampeonato(event)}
                >
                  {this.state?.campeonatoCadastrados.map((element, index) => {
                    return (
                      <MenuItem value={element}>
                        {element.modalidade_desporto.nome +
                          " - " +
                          element.descricao +
                          " - " +
                          element.status}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Col>
              <Col md={"auto"}>
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={(event) => this.imprimir(event)}
                  style={{ outline: "none" }}
                  //disabled={this.state.campeonatoCadastrados === null}
                >
                  <PrintIcon />
                </IconButton>
              </Col>
            </Row>
          </Segment>
        </Col>

        <Col style={{ padding: 0 }}>
          {this.state?.infoClassificacaoCampeonato.length > 0 && (
            <ListaClassificacao
              infoClassificacaoCampeonato={
                this.state.infoClassificacaoCampeonato
              }
            />
          )}
        </Col>
      </div>
    );
  }
}
