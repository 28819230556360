import React, { Component } from "react";
import { Header, Modal, Segment, Divider } from "semantic-ui-react";
import { Col, Row, Button, Form, FormControl, Alert } from "react-bootstrap";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";
import renomearChave from "../../../../services/renomearChave";
import Select from "react-select";
import GrauParentesco from "../../../../enum/GrauParentesco";
import Acao from "../../../../enum/Acao";
import ReplaceAll from "replaceall";

export default class ModalDependente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pessoa: null,
      options: [],
      login: null,
      senha: null,
      grauParentesto: GrauParentesco,
      grau_parentesco: null,
      infoPessoa: null,
      open_modal_desvincular: false,
    };    
  }

  componentDidMount() {
    console.log("componentDidMount",this.props.pessoa)

    if(this.props.pessoa != null && this.props.pessoa !== undefined) {
      this.carregaDependente(this.props.pessoa);
    }
  }

  carregaDependente = async (pessoa) => {
    console.log("pessoa",pessoa)
    console.log("pessoa dependente",pessoa)
    try {
      this.pesquisaPessoa({target: {value: pessoa.nome}});
      if (pessoa.cpf !== null) {
        let varCpf = pessoa.cpf;
        varCpf = ReplaceAll(".", "", varCpf);
        varCpf = ReplaceAll("-", "", varCpf);
        this.setState({
          login: varCpf,
          senha: varCpf,
        });
      } else if (pessoa.cnpj !== null) {
        let varCnpj = pessoa.cpf;
        varCnpj = ReplaceAll(".", "", varCnpj);
        varCnpj = ReplaceAll("-", "", varCnpj);
        varCnpj = ReplaceAll("/", "", varCnpj);
        this.setState({
          login: varCnpj,
          senha: varCnpj,
        });
      } else {
        this.setState({
          login: null,
          senha: null,
        });
      }

      this.setState({
        pessoa: {
          label: pessoa.nome,
          value: pessoa.id,
        },
        infoPessoa: pessoa,
      });
    } catch(error) {
      console.log("modalDependentes/carregaDependente: " + error);
    }
  }

  desvincularDependente = async () => {
    try {
    console.log("desvincularDependente")

      if(this.state.infoPessoa == null) return alert('Dependente inválido');
      const retorno = await api.post(
        "/pessoa/alterar" +
          requisicoes.entidade +
          "&id=" +
          this.state.infoPessoa.id,
        {
          grau_parentesco: null,
          titular_id: null,
          acao_id: null
        },
        requisicoes.header
      );
      const usuario_existe = await api.post("/usuario/porPessoa" + requisicoes.entidade,
        {
          id: this.state.infoPessoa.id,
        },
        requisicoes.header
      );
      if(usuario_existe.data != null) {
        await api.post(
          "/usuario/excluir" + requisicoes.entidade + "&id=" + usuario_existe.data.id,
          {},
          requisicoes.header
        );
      }
      this.setState({
        infoPessoa: retorno.data,
        open_modal_desvincular: false,
      });
      return alert('Dependente desvinculado do antigo título');
    } catch(error) {
      console.log("modalDependentes/desvincularDependente: " + error);
    }
  }

  adicionaDependente = async () => {
    try {
      console.log("adicionaDependente")
      if (this.state.infoPessoa.acao_id != null) {
        this.setState({
          open_modal_desvincular: true
        });
        return alert("Esta pessoa já possui ação vinculada.");
      }
      if(this.state.login === null || this.state.login === "" || this.state.login === undefined) {
        return alert("Senha não informada corretamente");
      }
      if(this.state.senha === null || this.state.senha === "" || this.state.senha === undefined) {
        return alert("Usuário não informado corretamente");
      }

      await api.post(
        "/pessoa/alterar" +
          requisicoes.entidade +
          "&id=" +
          this.state.pessoa.value,
        {
          grau_parentesco: this.state.grau_parentesco,
          titular_id: this.props.titular_id,
          acao_id: this.props.acao_id
        },
        requisicoes.header
      );
      await api.post(
        "/historicoDependente/salvar" + requisicoes.entidade,
        {
          acao: Acao.find((e) => e.value === "remover").value,
          motivo: "",
          dependente_id: this.state.pessoa.value,
          titular_id: this.props.titular_id,
          acao_id: this.props.acao_id
        },
        requisicoes.header
      );
      const dados = {
        login: this.state.login,
        senha: this.state.senha,
        entidade: window.localStorage.getItem("e_clube"),
        tipo_usuario: "dependente",
        pessoa_id: this.state.pessoa.value,
        nome: this.state.pessoa.label,
      };
      try {
        await api.post(
          "/usuario/salvar" + requisicoes.entidade,
          dados,
          requisicoes.header
        );
      } catch(error) {
        alert("Erro ao cadastrar dependente.\n" +
              "Possíveis motivos:\n" + 
              "Usuário já cadastrado para outro sócio");
        return;
      }
      this.props.adicionaDependentes({
        pessoa: this.state.pessoa,
        login: this.state.login,
        grau_parentesco: this.state.grau_parentesco,
      });
      this.props.handleCloseDependentes();
      this.setState({
        infoPessoa: null,
        pessoa: null,
        grau_parentesco: null,
        login: null,
        senha: null,
      });
    } catch(error) {
      console.log("modalDependentes/adicionaDependente: " + error);
    }
  };

  alteracaoSelectPessoa = async (event) => {
    this.setState({ pessoa: event });
    console.log("alteracaoSelectPessoa")

    const resposta = await api.post(
      "/pessoa/visualiza" + requisicoes.entidade,
      { id: event.value },
      requisicoes.header
    );
    this.setState({ infoPessoa: resposta.data });
  };

  alteracao = (event) => {
    console.log("Login", this.state.login)
    console.log("alteracao")

    this.setState({ [event.target.name]: event.target.value });
  };

  cb = function (dados) {
    console.log("cb")

    return new Promise((resolve) => {
      let lista = [];
      dados.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista.push(objeto);
      });
    console.log("cb lista",lista)

      resolve(lista);
    });
  };

  pesquisaPessoa = async (event) => {
    console.log("pesquisaPessoa")

    const retorno = await api.post(
      "/pessoa/pesquisa" + requisicoes.entidade,
      { pesquisa: event.target.value },
      requisicoes.header
    );
    this.cb(retorno.data).then((r) => {
      this.setState({ options: r });
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Modal
          open={this.props.open_modal_dependentes}
          onClose={this.props.handleCloseDependentes}
          centered={false}
        >
          <Header content="Dependente" />
          <Modal.Content>
            <Row>
              <Col sm={6}>
                <Form.Label>Pessoa</Form.Label>
                <Select
                  placeholder="Digite para buscar"
                  name="pessoa"
                  onChange={this.alteracaoSelectPessoa}
                  options={this.state.options}
                  onKeyDown={this.pesquisaPessoa}
                  inputId="pesquisaPessoa"
                  defaultValue={this.state.options != null && this.props.pessoa != null && this.props.pessoa != undefined ? this.state.options[0] : null}
                  required
                />
              </Col>
              <Col sm={6}>
                <Form.Label>Titulo</Form.Label>
                <FormControl
                  placeholder="Titulo"
                  aria-label="Titulo"
                  aria-describedby="basic-addon1"
                  name="titulo"
                  onChange={this.alteracao}
                  value={this.props.numero_titulo}
                  disabled
                  required
                />
              </Col>

              {this.state.infoPessoa !== null && (
                <Col sm={12} style={{ paddingTop: "10px" }}>
                  <Header as="h2">Dados Cadastrais</Header>
                  <Divider />
                  <Segment.Group>
                    <Segment.Group horizontal>
                      <Segment>
                        <b>Tipo:</b> {this.state.infoPessoa.tipo}
                      </Segment>
                      <Segment>
                        <b>CPF/CNPJ:</b>{" "}
                        {this.state.infoPessoa.cpf === null
                          ? this.state.infoPessoa.cnpj
                          : this.state.infoPessoa.cpf}
                      </Segment>
                      <Segment>
                        <b>E-mail:</b> {this.state.infoPessoa.email}
                      </Segment>
                    </Segment.Group>
                    <Segment>
                      <b>Endereço:</b> {this.state.infoPessoa.endereco} -{" "}
                      {this.state.infoPessoa.bairro},{" "}
                      {this.state.infoPessoa.cep} {this.state.infoPessoa.cidade}
                      -{this.state.infoPessoa.estado}
                    </Segment>
                  </Segment.Group>
                </Col>
              )}

              <Col sm={6}>
                <Form.Label>Login</Form.Label>
                <FormControl
                  placeholder="Login"
                  aria-label="Login"
                  aria-describedby="basic-addon1"
                  name="login"
                  onChange={this.alteracao}
                  value={this.state.login}
                  required
                />
              </Col>
              <Col sm={6}>
                <Form.Label>Senha</Form.Label>
                <FormControl
                  placeholder="Senha"
                  aria-label="Senha"
                  type="password"
                  aria-describedby="basic-addon1"
                  name="senha"
                  onChange={this.alteracao}
                  value={this.state.senha}
                  required
                />
              </Col>

              <Col sm={6}>
                <Form.Label>Grau de Parentesco</Form.Label>
                <Form.Control
                  as="select"
                  name="grau_parentesco"
                  onChange={this.alteracao}
                  required
                >
                  <option></option>
                  {this.state.grauParentesto.map((e, i) => (
                    <option key={i} value={e.value}>
                      {e.label}
                    </option>
                  ))}
                </Form.Control>
              </Col>
              
              <Col sm={6} style={{ display: "flex", alignItems: "flex-end" }}>
                <Button
                  color="blue"
                  onClick={() => {
                    window.location.href = "/pessoa?from=usuarioTitular&dependente=true&titular_id="+this.props.titular_id;
                  }}
                >
                  Cadastrar Pessoa
                </Button>
                {/* Fiz assim pq o Link deixava a pagina em branco */}
              </Col>
            </Row>
          </Modal.Content>

          <Header floated="right">
            <Row>
              <Col sm={5}>
                <Button
                  type="button"
                  variant="light"
                  onClick={this.props.handleCloseDependentes}
                >
                  Voltar
                </Button>
              </Col>
              <Col sm={5}>
                <Button variant="success" onClick={this.adicionaDependente}>
                  Adicionar
                </Button>
              </Col>
            </Row>
          </Header>
        </Modal>

        {(this.state.open_modal_desvincular == true && this.state.infoPessoa !== null && this.state.infoPessoa !== undefined) ? (<Modal
          open={this.state.open_modal_desvincular}
          onClose={() => this.setState({ open_modal_desvincular: false })}
          size="small"
        >
          <Header content="Dependente já vinculado" />
          <Modal.Content>
            <Row>
              <Col sm={12}>
                Dependente <b>{this.state.infoPessoa.id} - {this.state.infoPessoa.nome}</b> vinculado: 
                <b>{this.state.infoPessoa.acao !== undefined && this.state.infoPessoa.acao !== null ? ` ao título ${this.state.infoPessoa.acao.codigo}.` : 'à um título.'}</b><br />
                Deseja desvincular o dependente do título atual?<br />
                Desvincular o dependente fará com que o usuário de login atual seja excluído e será necessário criar um novo <b>login</b> e <b>senha</b> para o dependente.
              </Col>
            </Row>
          </Modal.Content>

          <Header floated="right">
            <Row>
              <Col sm={6}>
                <Button
                  type="button"
                  variant="success"
                  onClick={this.desvincularDependente}
                >
                  Desvincular
                </Button>
              </Col>
              <Col sm={6}>
                <Button 
                  variant="light" 
                  onClick={() => this.setState({ open_modal_desvincular: false })}>
                  Fechar
                </Button>
              </Col>
            </Row>
          </Header>
        </Modal>
        ) : null}        
      </Form>
    );
  }
}
