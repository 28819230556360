import React, { Component } from "react";

import { Container, Header, Segment, Divider, Modal } from "semantic-ui-react";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import { Link } from "react-router-dom";
import api from "../../../services/api";

import Button from "@material-ui/core/Button";

import IconButton from "@material-ui/core/IconButton";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Checkbox from "@material-ui/core/Checkbox";
import SendIcon from "@material-ui/icons/Send";
import Lista from "./lista";
import ModalSumula from "./modalSumula";
import ModalExclusao from "./modalExclusao";

import "../styles.css";

export default class Competicao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectPessoa: [],
      selectArma: [],
      pessoa_nome: "",
      pessoa_id: null,
      arma_descricao: "",
      arma_id: null,
      lista_atiradores: [],
      alert: true,
      status: false,
      disabledAbrir: false,
      pontuacaoEspecifica: [],
      tamanhoLinha: 5,
      listaArma: [],
      listaAtirador: [],
      modalExclusao: false,
      alertaExclusao: true,
      nomeExclusao: "",
      checkExclusao: false,
      modalSumula: false,
      rodada: 0,
      pedana: 0,
      regra_pontuacao: this.props.location.query.regra_pontuacao,
    };
    this.listaAtiradores();
  }

  gerarPdfSumula = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const atiradores = await api.post(
      "/sumula/GerarPdfSumula?e=" + entidade,
      {
        etapa_id: this.props.location.query.etapa_id,
        evento_id: this.props.location.query.evento_id,

        numero_etapa: this.props.location.query.numero_etapa,
        rodada: this.state.rodada,
        pedana: this.state.pedana,
      },
      { headers: { token: "Baer " + token } }
    );

    this.imprimirPdfSumula();
  };

  imprimirPdfSumula = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api.get(
      "/sumula/BuscarPdfSumula?e=" + entidade + "&t=Baer " + token
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "sumula/BuscarPdfSumula?e=" +
      entidade +
      "&t=Baer " +
      token;
    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = await new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  listaAtiradores = async () => {
    var listaInfo = [];
    const atiradores = await api.post(
      "/atirador/lista" + requisicoes.entidade,
      {
        etapa_id: this.props.location.query.etapa_id,
        evento_id: this.props.location.query.evento_id,
      },
      requisicoes.header
    );

    this.setState({ lista_atiradores: atiradores.data });
  };

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeModalExclusao = async (status, nome, id) => {
    this.setState({ modalExclusao: status });
    this.setState({ nomeExclusao: nome });
    this.setState({ idatiradorExclusao: id });
  };

  checkboxExclusao = () => {
    if (this.state.checkExclusao === false) {
      this.setState({ checkExclusao: true });
    } else {
      this.setState({ checkExclusao: false });
    }
  };

  excluirAtirador = async () => {
    this.setState({ alertaExclusao: false });
    const exclusao = await api.post(
      "/atirador/excluirAtirador" + requisicoes.entidade,
      {
        etapa_id: this.props.location.query.etapa_id,
        evento_id: this.props.location.query.evento_id,
        numero_etapa: this.props.location.query.numero_etapa,
        atirador_id: this.state.idatiradorExclusao,
      },
      requisicoes.header
    );
    this.listaAtiradores();
    setTimeout(() => {
      this.setState({ modalExclusao: false });
    }, 1000);
  };

  changeModalSumula = async (status) => {
    this.setState({ modalSumula: status });
  };

  render() {
    return (
      <Container className="paginaSemPaddingTop">
        <Form>
          <Segment>
            <Header as="h2" dividing>
              <div className="flexSpaceBetween">
                <div>
                  {"Lista de Atiradores Etapa " +
                    this.props.location.query.numero_etapa}
                </div>
                <div>
                  <Link
                    to={{
                      pathname: "/eventos/lancamentoInscricao",
                      query: {
                        etapa_id: this.props.location.query.etapa_id,
                        evento_id: this.props.location.query.evento_id,
                        numero_etapa: this.props.location.query.numero_etapa,
                        pedana: this.props.location.query.pedana,
                        status: this.props.location.query.status,
                        regra_pontuacao: this.props.location.query
                          .regra_pontuacao,
                      },
                    }}
                  >
                    <Button
                      style={{ outline: "none" }}
                      icon
                      size="mini"
                      id="voltar"
                      color="primary"
                    >
                      Voltar
                    </Button>
                  </Link>
                </div>
              </div>
            </Header>

            <Row>
              <Col sm={12}>
                <Button
                  id="encerrar"
                  color="secondary"
                  floated="right"
                  style={{ outline: "none" }}
                  onClick={() => this.changeModalSumula(true)}
                >
                  {"Sumula "}
                </Button>
              </Col>
            </Row>

            <Lista
              etapa_id={this.props.location.query.etapa_id}
              evento_id={this.props.location.query.evento_id}
              numero_etapa={this.props.location.query.numero_etapa}
              pedana={this.props.location.query.pedana}
              status={this.props.location.query.status}
              regra_pontuacao={this.props.location.query.regra_pontuacao}
              changeModalExclusao={this.changeModalExclusao}
              lista_atiradores={this.state.lista_atiradores}
            ></Lista>

            <ModalExclusao
              modalExclusao={this.state.modalExclusao}
              numero_etapa={this.props.location.query.numero_etapa}
              changeModalExclusao={this.changeModalExclusao}
              alertaExclusao={this.state.alertaExclusao}
              nomeExclusao={this.state.nomeExclusao}
              checkboxExclusao={this.checkboxExclusao}
              checkExclusao={this.state.checkExclusao}
              excluirAtirador={this.excluirAtirador}
            ></ModalExclusao>
            <ModalSumula
              modalSumula={this.state.modalSumula}
              changeModalSumula={this.changeModalSumula}
              alteracao={this.alteracao}
              gerarPdfSumula={this.gerarPdfSumula}
            ></ModalSumula>

            <Divider></Divider>
          </Segment>
        </Form>
      </Container>
    );
  }
}
