import { extend } from "lodash";
import React, { Component } from "react";
import { Row, Col, FormControl, Form } from "react-bootstrap";
import {
  Container,
  Header,
  Button,
  Divider,
  Segment,
  Menu,
  Icon,
  Card,
  Image,
  Input,
  Label,
} from "semantic-ui-react";

import { Link } from "react-router-dom";

import Cadastro from "./cadastro/index";

import { GiEntryDoor } from "react-icons/gi";

import api from "../../services/api";

import cadastro from "./cadastro.png";
import "./styles.css";
import icon from "./icon.png";
import Visualiza from "./visualiza";
import Modalidade from "./modalidade";
import Subgrupo from "./subgrupo";
import Evento from "./cadastro";
import Calendario from "./calendarioeventos";
import Relatorio from "./relatorio";
import Dashboard from "./dashboard";

export default class Administrativo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo_clube: window.localStorage.getItem("tipo_clube"),
      selectedMenu: localStorage.getItem("pagina_anterior"),
    };
  }

  render() {
    let content = "";
    switch (this.state.selectedMenu) {
      case "modalidade":
        localStorage.setItem("pagina_anterior", "modalidade");
        content = <Modalidade />;
        break;
      case "subgrupo":
        localStorage.setItem("pagina_anterior", "subgrupo");
        content = <Subgrupo />;
        break;
      case "cadastrar":
        localStorage.setItem("pagina_anterior", "cadastrar");
        content = <Evento />;
        break;
      case "visualizar":
        localStorage.setItem("pagina_anterior", "visualizar");
        content = <Visualiza />;
        break;

      case "calendario":
        localStorage.setItem("pagina_anterior", "calendario");
        content = <Calendario />;
        break;
      case "relatorio":
        localStorage.setItem("pagina_anterior", "relatorio");
        content = <Relatorio />;
        break;
      case "dashboard":
        localStorage.setItem("pagina_anterior", "dashboard");
        content = <Dashboard />;
        break;

      default:
        break;
    }
    return (
      <Container className="pagina">
        <Header as="h1" dividing>
          Eventos
        </Header>
        <Card.Group itemsPerRow={6}>
          <Card
            name="modalidade"
            link
            as="cadastrar"
            onClick={this.selectMenu.bind(null, "modalidade")}
            className="cartao"
          >
            <Card.Content className="fontCard" textAlign="center">
              MODALIDADE{" "}
            </Card.Content>
          </Card>
          <Card
            link
            name="subgrupo"
            as="subgrupo"
            id="subgrupo"
            onClick={this.selectMenu.bind(null, "subgrupo")}
            className="cartao"
          >
            <Card.Content className="fontCard" textAlign="center">
              SUBGRUPO{" "}
            </Card.Content>
          </Card>
          <Card
            name="cadastrar"
            className="cartao"
            link
            as="cadastrar"
            onClick={this.selectMenu.bind(null, "cadastrar")}
          >
            <Card.Content className="fontCard" textAlign="center">
              CADASTRAR{" "}
            </Card.Content>
          </Card>
          <Card
            name="visualizar"
            className="cartao"
            link
            as="cadastrar"
            onClick={this.selectMenu.bind(null, "visualizar")}
          >
            <Card.Content className="fontCard" textAlign="center">
              VISUALIZAR{" "}
            </Card.Content>
          </Card>

          <Card
            link
            name="calendario"
            as="calendario"
            id="calendario"
            onClick={this.selectMenu.bind(null, "calendario")}
            className="cartao"
          >
            <Card.Content className="fontCard" textAlign="center">
              CALENDÁRIO{" "}
            </Card.Content>
          </Card>

          <Card
            link
            name="relatorio"
            as="relatorio"
            id="relatorio"
            onClick={this.selectMenu.bind(null, "relatorio")}
            className="cartao"
          >
            <Card.Content className="fontCard" textAlign="center">
              RELATÓRIO{" "}
            </Card.Content>
          </Card>
          <Card
            link
            name="dashboard"
            as="dashboard"
            id="dashboard"
            onClick={this.selectMenu.bind(null, "dashboard")}
            className="cartao"
          >
            <Card.Content className="fontCard" textAlign="center">
              DASHBOARD{" "}
            </Card.Content>
          </Card>
        </Card.Group>

        {content}
      </Container>
    );
  }
  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
  };
}
