import React, { Component } from "react";
import { Table } from "semantic-ui-react";

import moment from "moment-timezone";

import "../styles.css";

import api from "../../../services/api";
export default class Competicao extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Table
        striped
        selectable
        hidden={this.props.relatorio_descricao !== "Atiradores"}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>NOME</Table.HeaderCell>
            <Table.HeaderCell width={1}>ETAPA</Table.HeaderCell>
            <Table.HeaderCell width={3}>EVENTO</Table.HeaderCell>
            <Table.HeaderCell width={2}>DATA</Table.HeaderCell>
            <Table.HeaderCell width={1}>DISPAROS</Table.HeaderCell>
            <Table.HeaderCell width={1}>ACERTOS</Table.HeaderCell>
            <Table.HeaderCell width={1}>%</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {this.props.tabela_atiradores.map((element, index) => (
          <Table.Body>
            <Table.Row key={index}>
              <Table.Cell> {element.nome} </Table.Cell>
              <Table.Cell> {element.etapa.numero_etapa}</Table.Cell>

              <Table.Cell> {element.etapa.evento.descricao}</Table.Cell>
              <Table.Cell>
                {moment(element.etapa.evento.data).format("DD/MM/YYYY")}
              </Table.Cell>
              <Table.Cell>
                {element.etapa.evento.subgrupo.quantidade_tiros}
              </Table.Cell>
              <Table.Cell>{element.pontuacoes[0].pontuacao}</Table.Cell>
              <Table.Cell>
                {element.pontuacoes[0].aproveitamento !== null
                  ? element.pontuacoes[0].aproveitamento + "%"
                  : ""}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        ))}
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan={12}>
              {"Porcentagem de Aproveitamento: " +
                this.props.porcentagem_aproveitamento +
                "%"}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}
