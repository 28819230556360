/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table } from "semantic-ui-react";
import Button from "@material-ui/core/Button";
import { Settings, PlayCircleOutline } from "@material-ui/icons/";
// import ModalRodadaLista from "../rodadascampeonato/index";
export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>NOME</Table.HeaderCell>
            <Table.HeaderCell>MODELO</Table.HeaderCell>
            <Table.HeaderCell>STATUS</Table.HeaderCell>
            <Table.HeaderCell>FASE ATUAL</Table.HeaderCell>
            <Table.HeaderCell>RESERVAS</Table.HeaderCell>
            <Table.HeaderCell>MODALIDADE</Table.HeaderCell>

            <Table.HeaderCell>AJUSTAR</Table.HeaderCell>
            {/* <Table.HeaderCell>EDITAR</Table.HeaderCell> */}
          </Table.Row>
        </Table.Header>

        <Table.Body hidden={this.props.campeonatosCadastrados.length === 0}>
          {this.props.campeonatosCadastrados.map((element, index) => (
            <Table.Row>
              <Table.Cell>{element.nome}</Table.Cell>
              <Table.Cell>
                {element.tipo_campeonato?.descricao
                  ? element.tipo_campeonato?.descricao
                  : element.tipo_campeonato?.label}
              </Table.Cell>
              <Table.Cell>{element.status}</Table.Cell>
              <Table.Cell>{element.fase_atual}</Table.Cell>
              <Table.Cell textAlign="center">
                {element.quantidade_reservas}
              </Table.Cell>

              <Table.Cell>
                {element.modalidade_desporto?.nome
                  ? element.modalidade_desporto?.nome
                  : element.modalidade_desporto?.label}
              </Table.Cell>

              <Table.Cell>
                {element.status === "criado" ? (
                  <Link
                    to={{
                      pathname: "/desporto/ajustar",

                      query: {
                        info: element,
                        campeonato_id: element.id,
                        campeonato_modalidade: element.modalidade_desporto?.nome
                          ? element.modalidade_desporto?.nome
                          : element.modalidade_desporto?.label,
                        campeonato_modelo: element.tipo_campeonato?.descricao,
                        status: element.status,
                        campeonato_tipo: element.modalidade_desporto
                          ?.grupo_servico?.descricao
                          ? element.modalidade_desporto?.grupo_servico
                              ?.descricao
                          : element.modalidade_desporto?.grupo_servico?.label,
                        modalidade_desporto_id: element.modalidade_desporto_id,
                        tipo_campeonato: element.tipo_campeonato.descricao,
                        todos_contra_todos: element.todos_contra_todos,
                        ida_volta: element.ida_volta,
                      },
                    }}
                  >
                    <Button
                      className="botaoTabble"
                      icon
                      style={{ outline: "none" }}
                      size="mini"
                    >
                      <Settings size="small" name="configure" id={element.id} />
                    </Button>
                  </Link>
                ) : element.status === "em andamento" ? (
                  <>
                    <Link
                      to={{
                        pathname: "/desporto/rodada",
                        teste: { teste: this.props.campeonatosCadastrados },
                        query: {
                          info: element,
                          campeonato_id: element.id,
                          campeonato_modalidade:
                            element.modalidade_desporto.nome,
                          campeonato_modelo: element.tipo_campeonato.descricao,
                          status: element.status,
                          campeonato_tipo:
                            element.modalidade_desporto.grupo_servico.descricao,
                          modalidade_desporto_id:
                            element.modalidade_desporto_id,
                          tipo_campeonato: element.tipo_campeonato.descricao,
                          todos_contra_todos: element.todos_contra_todos,
                          ida_volta: element.ida_volta,
                        },
                      }}
                    >
                      {/* <ModalRodadaLista
                      campeonatosCadastrados={element}
                    ></ModalRodadaLista> */}
                      <Button
                        className="botaoTabble"
                        icon
                        style={{ outline: "none" }}
                        size="mini"
                      >
                        {" "}
                        <PlayCircleOutline
                          size="small"
                          name="configure"
                          id={element.id}
                        />
                      </Button>
                    </Link>
                  </>
                ) : (
                  ""
                )}
              </Table.Cell>
              {/* <Table.Cell
                icon={"edit"}
                disabled
                style={{ textAlign: "center" }}
              ></Table.Cell> */}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}
