import React, { Component } from "react";
import { Header, Menu, Icon } from "semantic-ui-react";

import Politica from "./politica/index";
import Termo from "./termo";
import FaleConosco from "./faleConosco";

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu:
        localStorage.getItem("aceitou_termos") === "true"
          ? "politica"
          : "termo",
    };
  }
  render() {
    let content = "";
    switch (this.state.selectedMenu) {
      // política de dados
      // termo de consentimento
      case "politica":
        content = <Politica />;
        break;
      case "termo":
        content = <Termo />;
        break;
      case "faleConosco":
        content = <FaleConosco />;
        break;

      default:
        this.setState({ selectedMenu: "politica" });
        content = <Politica />;
        break;
    }
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Política de Privacidade
        </Header>
        <Menu tabular icon="labeled">
          <>
            <Menu.Item
              name="politica"
              active={this.state.selectedMenu === "politica"}
              onClick={this.selectMenu.bind(null, "politica")}
            >
              <Icon name="info" />
              Política de dados
            </Menu.Item>

            <Menu.Item
              name="termo"
              active={this.state.selectedMenu === "termo"}
              onClick={this.selectMenu.bind(null, "termo")}
            >
              <Icon name="file outline" />
              Termo de consentimento
            </Menu.Item>

            <Menu.Item
              name="faleConosco"
              active={this.state.selectedMenu === "faleConosco"}
              onClick={this.selectMenu.bind(null, "faleConosco")}
            >
              <Icon name="chat" />
              Fale Conosco
            </Menu.Item>

            <div
              style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}
            >
              {localStorage.getItem("aceitou_termos") === "true" ? (
                <b style={{ fontSize: 20, color: "green" }}>Termo já aceito</b>
              ) : (
                <b style={{ fontSize: 20, color: "red" }}>
                  Termo ainda não aceito
                </b>
              )}
            </div>
          </>
        </Menu>
        {content}
      </div>
    );
  }
  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
  };
}
