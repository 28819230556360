import React, { Component } from "react";
import { Container, Card, Segment, Table } from "semantic-ui-react";
import { Form, Col, Row } from "react-bootstrap";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import requisicoes from "../../../utils/Requisicoes";
import renomearChave from "../../../services/renomearChave";
import "../styles.css";
import api from "../../../services/api";
import TextField from "@material-ui/core/TextField";

import { CanvasJSChart } from "canvasjs-react-charts";

export default class Competicao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista_de_atiradores: [],
      atirador_descricao: "",
      atirador_id: null,
      grafico_acertos: [],
      grafico_disparos: [],
      grafico_porcentagem: [],
    };
  }

  alteracao = async (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };
  alteracaoSelectAtirador = async (event) => {
    this.setState({ atirador_descricao: event.label });
    this.setState({ atirador_id: event.value });
  };

  buscaAtirador = async () => {
    let porcentagem = [];
    let qtde_participacao = 0;
    let acertos = [];
    let disparos = [];
    const infoatirador = await api.post(
      "/atirador/buscaAtiradoresPorEtapa" + requisicoes.entidade,
      {
        atirador_id: this.state.atirador_id,
        data_inicial: this.state.data_inicial,
        data_final: this.state.data_final,
      },
      requisicoes.header
    );
    console.log(infoatirador.data);
    for (let a = 0; a < infoatirador.data.length; a++) {
      if (infoatirador.data[a].pontuacoes[0].pontuacao !== null) {
        acertos.push({
          y: parseInt(infoatirador.data[a].pontuacoes[0].pontuacao),
          label:
            infoatirador.data[a].etapa.evento.descricao.substr(0, 15) +
            " Rodada " +
            infoatirador.data[a].rodada +
            " Etapa " +
            infoatirador.data[a].etapa.numero_etapa +
            " " +
            infoatirador.data[a].etapa.evento.subgrupo.tipo_evento,
        });

        disparos.push({
          y: parseInt(
            infoatirador.data[a].etapa.evento.subgrupo.quantidade_tiros
          ),
          label:
            "Evento " +
            infoatirador.data[a].etapa.evento.descricao.substr(0, 10) +
            "Rodada " +
            infoatirador.data[a].rodada +
            "Etapa " +
            infoatirador.data[a].etapa.numero_etapa +
            infoatirador.data[a].etapa.evento.subgrupo.tipo_evento,
        });
        porcentagem.push({
          y: infoatirador.data[a].pontuacoes[0].aproveitamento,
          label:
            infoatirador.data[a].etapa.evento.descricao.substr(0, 15) +
            " Rodada " +
            infoatirador.data[a].rodada +
            " Etapa " +
            infoatirador.data[a].etapa.numero_etapa +
            " " +
            infoatirador.data[a].etapa.evento.subgrupo.tipo_evento,
        });

        // porcentagem =
        //   porcentagem + infoatirador.data[a].pontuacoes[0].aproveitamento;
        // if (infoatirador.data[a].pontuacoes[0].aproveitamento !== null) {
        //   qtde_participacao = qtde_participacao + 1;
        // }
      }
    }
    this.setState({ grafico_disparos: disparos });

    this.setState({ grafico_acertos: acertos });

    this.setState({ grafico_porcentagem: porcentagem });
  };

  pesquisaPessoa = (newValue) => {
    api
      .post(
        "/pessoa/listaPessoaEvento" + requisicoes.entidade,
        {},
        requisicoes.header
      )
      .then((resposta) => {
        const lista = [];
        resposta.data.forEach((e) => {
          let objeto = renomearChave(e, "id", "value");
          objeto = renomearChave(objeto, "nome", "label");

          lista.push(objeto);
        });

        this.setState({ lista_de_atiradores: lista });
      });
  };

  render() {
    const tiposrelatorios = [
      { value: "atiradores", label: "Atiradores" },
      { value: "etapas", label: "Etapas" },
    ];

    var grafico_coluna = {
      animationEnabled: true,
      animationDuration: 2000,
      title: {
        text: "Aproveitamento por Etapa",
      },

      dataPointMaxWidth: 50,
      // subtitles: [
      //   {
      //     text: this.state.atirador_descricao,
      //   },
      // ],
      axisY: {
        title: "Disparos",
        titleFontColor: "#4F81BC",
        lineColor: "#4F81BC",
        labelFontColor: "#4F81BC",
        tickColor: "#4F81BC",
        includeZero: true,
      },

      axisX: {
        lineColor: "black",
        labelFontSize: 12,
        labelMaxWidth: 150,
        // labelAngle: -20,
      },

      toolTip: {
        shared: true,
      },
      legend: {
        cursor: "pointer",
        // itemclick: toggleDataSeries,
      },
      data: [
        {
          color: "rgba(16, 134, 177, 0.419)",
          type: "stackedColumn",
          name: "Disparos",
          legendText: "Disparos",
          showInLegend: true,
          dataPoints: this.state.grafico_disparos,
        },
        {
          color: "rgba(12,243, 81, 0.419)",
          type: "column",
          name: "Acertos",
          legendText: "Acertos",
          showInLegend: true,
          dataPoints: this.state.grafico_acertos,
        },
      ],
    };

    var grafico_area = {
      animationEnabled: true,
      animationDuration: 2000,
      title: {
        text: "Porcentagem ",
      },
      axisX: {},
      axisY: {
        title: "%",
        titleFontColor: "#4F81BC",
        includeZero: true,
      },
      data: [
        {
          indexLabelFontColor: "darkSlateGray",
          name: "views",
          type: "area",
          dataPoints: this.state.grafico_porcentagem,
        },
      ],
    };

    return (
      <div className="paginaSemPaddingTop">
        <Segment>
          <Form>
            <div className="flexSpaceBetween">
              <Col sm={4}>
                <Form.Label>Atiradores</Form.Label>
                <Select
                  inputId="relatorioatiradores"
                  name="relatorioatiradores"
                  onInputChange={this.pesquisaPessoa}
                  onChange={this.alteracaoSelectAtirador}
                  options={this.state.lista_de_atiradores}
                />
              </Col>
              <Col sm={2}>
                <Form.Label>Inicial</Form.Label>

                <TextField
                  type="date"
                  id="data_inicial"
                  name="data_inicio_treino"
                  onChange={this.alteracao}
                />
              </Col>
              <Col sm={2}>
                <Form.Label>Final</Form.Label>

                <TextField
                  type="date"
                  id="data_final"
                  name="data_inicio_treino"
                  onChange={this.alteracao}
                />
              </Col>
              <Col sm={2}>
                <Button
                  type="button"
                  style={{
                    outline: "none",
                    borderBottom: "2px solid rgb(10, 111, 148)",
                    color: "rgb(10, 111, 148)",
                    borderRadius: "0px",
                    marginRight: "4%",
                  }}
                  color="primary"
                  onClick={this.buscaAtirador}
                  disabled={
                    this.state.data_inicial === null ||
                    this.state.data_final === null
                  }
                >
                  Buscar
                </Button>
              </Col>
            </div>
          </Form>
        </Segment>
        <Card.Group
          itemsPerRow={1}
          hidden={this.state.grafico_porcentagem.length === 0}
        >
          <Card>
            <Card.Content textAlign="center">
              <CanvasJSChart options={grafico_coluna} />
            </Card.Content>
          </Card>
          {/*
          <Card>
            <Card.Content textAlign="center">
              <CanvasJSChart options={grafico_area} />
            </Card.Content>
          </Card> */}
        </Card.Group>
      </div>
    );
  }
}
