import React, { Component } from "react";
import { Modal, Table } from "semantic-ui-react";
import {
  CircularProgress,
  Divider,
  TextField,
  Button,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { Add, Delete, Save } from "@material-ui/icons/";

import { Row, Col } from "react-bootstrap";

// libs
import moment from "moment-timezone";

// requisicoes
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

export default class ModalListaEsperaTurma extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // loading modal
      loadingModalVisualiza: true,

      // inputs
      nome: "",
      contato: "",

      listaEsperaTurma: [],
      listaExcluidos: [],

      // loading/disable button
      salvando: false,
      disabledSalvar: true,
    };
  }

  buscaListaEspera = async () => {
    //turmaServicoListaEspera/salvar

    const turmaServicoId = this.props.turmaServicoId;
    if (!turmaServicoId) {
      return;
    }
    this.setState({
      loadingModalVisualiza: true,
      listaEsperaTurma: [],
    });
    const listaEspera = await api.post(
      "/turmaServicoListaEspera/lista" + requisicoes.entidade,
      { turma_servico_id: turmaServicoId },
      requisicoes.header
    );
    setTimeout(() => {
      this.setState({
        listaEsperaTurma: listaEspera.data,
        loadingModalVisualiza: false,
      });
    }, 800);
  };

  salvarListaEspera = async () => {
    const turmaServicoId = this.props.turmaServicoId;
    if (!turmaServicoId) {
      return;
    }
    this.setState({
      salvando: true,
    });
    await api.post(
      "/turmaServicoListaEspera/salvar" + requisicoes.entidade,
      {
        turma_servico_id: turmaServicoId,
        listaNovos: this.state.listaEsperaTurma.filter(
          (e) => e.id === undefined
        ),
        listaExcluidos: this.state.listaExcluidos,
      },
      requisicoes.header
    );

    setTimeout(() => {
      this.setState({
        salvando: false,
        disabledSalvar: true,
      });
    }, 800);
    this.buscaListaEspera();
  };

  render() {
    return (
      <Modal
        open={this.props.open}
        size="large"
        onMount={() => this.buscaListaEspera()}
        onClose={() => {
          this.setState({ listaEsperaTurma: [] });
          this.props.onClose();
        }}
      >
        {this.props.turma && (
          <>
            <Modal.Header>
              <Row>
                <Col md="auto">Lista de espera: </Col>
                <Col>
                  {this.props.turma.nome + " - " + this.props.turma.turma}
                  <h5>
                    {this.props.turma.horaInicio.slice(0, 5) +
                      " - " +
                      this.props.turma.horaFim.slice(0, 5)}
                  </h5>
                </Col>
              </Row>
            </Modal.Header>
            <Modal.Content>
              {this.state.loadingModalVisualiza ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 50,
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <h4>Adicionar a lista de espera</h4>
                  <Row>
                    <Col>
                      <TextField
                        variant="outlined"
                        label="Nome"
                        fullWidth
                        value={this.state.nome}
                        onChange={(e, a) =>
                          this.setState({ nome: e.target.value })
                        }
                      ></TextField>
                    </Col>
                    <Col>
                      <TextField
                        variant="outlined"
                        label="Contato"
                        fullWidth
                        value={this.state.contato}
                        onChange={(e, a) =>
                          this.setState({ contato: e.target.value })
                        }
                      ></TextField>
                    </Col>
                    <Col
                      md="auto"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Tooltip title={"Adicionar pessoa a lista de espera"}>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "green" }}
                          startIcon={<Add />}
                          onClick={() => {
                            this.setState({
                              disabledSalvar: false,
                              listaEsperaTurma: [
                                ...this.state.listaEsperaTurma,
                                {
                                  nome: this.state.nome,
                                  contato: this.state.contato,
                                  data_adicionado:
                                    moment().format("YYYY-MM-DD"),
                                  hora_adicionado: moment().format("HH:mm"),
                                },
                              ],
                            });
                            this.setState({
                              nome: "",
                              contato: "",
                            });
                          }}
                        >
                          Adicionar
                        </Button>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Divider style={{ paddingTop: 15 }} />

                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Nome</Table.HeaderCell>
                        <Table.HeaderCell>Contato</Table.HeaderCell>
                        <Table.HeaderCell>Adicionado</Table.HeaderCell>

                        <Table.HeaderCell collapsing textAlign="center">
                          <Delete />
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.state.listaEsperaTurma.map((e, i) => (
                        <Table.Row key={i}>
                          <Table.Cell>{e.nome}</Table.Cell>
                          <Table.Cell>{e.contato}</Table.Cell>
                          <Table.Cell collapsing>
                            {moment(e.data_adicionado).format("DD/MM/YYYY") +
                              " - " +
                              e.hora_adicionado.slice(0, 5)}
                          </Table.Cell>

                          <Table.Cell collapsing textAlign="center">
                            <Tooltip title={"Excluir"}>
                              <IconButton
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                onClick={() => {
                                  if (e.id) {
                                    this.setState({
                                      listaExcluidos: [
                                        ...this.state.listaExcluidos,
                                        e.id,
                                      ],
                                    });
                                  }
                                  let lista = this.state.listaEsperaTurma;
                                  lista.splice(i, 1);
                                  this.setState({
                                    listaEsperaTurma: lista,
                                    disabledSalvar: false,
                                  });
                                }}
                              >
                                <Delete color="error" />
                              </IconButton>
                            </Tooltip>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>

                  <Divider style={{ marginBottom: 15 }} />

                  <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Col md="auto">
                      <Button
                        variant="contained"
                        color="inherit"
                        onClick={this.props.onClose}
                      >
                        Voltar
                      </Button>
                    </Col>
                    <Col md="auto">
                      <Tooltip
                        title={
                          this.state.disabledSalvar
                            ? "Adicione ou remova alguém da lista para salvar"
                            : "Salvar as alterações feitas na lista"
                        }
                      >
                        <div>
                          <LoadingButton
                            startIcon={<Save />}
                            variant="contained"
                            onClick={this.salvarListaEspera}
                            disabled={
                              this.state.salvando || this.state.disabledSalvar
                            }
                            loading={this.state.salvando}
                          >
                            Salvar
                          </LoadingButton>
                        </div>
                      </Tooltip>
                    </Col>
                  </Row>
                </>
              )}
            </Modal.Content>
          </>
        )}
      </Modal>
    );
  }
}
