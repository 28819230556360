import React, { Component } from "react";
import {
  Container,
  Table,
  Button,
  Menu,
  Icon,
  Header,
  Tab,
} from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import Select from "react-select";
import requisicoes from "../../../utils/Requisicoes";

let intervalFunction = null;
export default class Conexoes extends Component {
  constructor(props) {
    super(props);
    this.state = { lista: [] };
    this.lista();
  }

  lista = async () => {
    intervalFunction = setInterval(async () => {
      const resposta = await api.get(
        "/openConnections" + requisicoes.entidade,
        requisicoes.header
      );

      this.setState({ lista: resposta.data });
    }, 1000);
  };

  componentWillUnmount() {
    clearInterval(intervalFunction);
  }

  render() {
    return (
      <div>
        <span>Dados atualizados a cada 1 segundo.</span>
        <Tab.Pane style={{ padding: "0", borderColor: "transparent" }}>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Application Name</Table.HeaderCell>
                <Table.HeaderCell>Count</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.state.lista.map((e) => {
                return (
                  <Table.Row>
                    <Table.Cell>{e.application_name}</Table.Cell>
                    <Table.Cell>{e.count}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Tab.Pane>
      </div>
    );
  }
}
