import React, { Component } from "react";
import Select from "react-select";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import {
  Header,
  Modal,
  Button,
  Divider,
  Segment,
  Table,
  Icon,
} from "semantic-ui-react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import ListaPessoas from "./listaPessoas";

import { Link, withRouter } from "react-router-dom";
import Meses from "../../../../enum/Mes";
import renomearChave from "../../../../services/renomearChave";
import requisicoes from "../../../../utils/Requisicoes";
import ConverterParaLabelAcao from "../../../../utils/converteParaLabelComAcao";
import ConverterParaLabelPessoa from "../../../../utils/converteParaLabel";
import ValidadorCamposPessoa from "../../../../utils/validadorCamposPessoa";
import ListaPessoasInvalidas from "./listaPessoasInvalidadas";

import TipoCobranca from "../../../../enum/TipoCobranca";
import Checkbox from "muicss/lib/react/checkbox";
import TipoLancamento from "../../../../enum/TipoLancamento";
import CreditoDebito from "../../../../enum/CreditoDebito";
import $ from "jquery";
import "jquery-mask-plugin/dist/jquery.mask.min";
import ReplaceAll from "replaceall";
import CircularProgress from "@material-ui/core/CircularProgress";
import { validaCnpj, validaCpf } from "../../../../utils/validadorCamposPessoa"
import moment from "moment-timezone";
import api from "../../../../services/api";
import proximoDiaUtil from "../../../../utils/proximoDiaUtil";
import checkFeriado from "../../../../enum/Feriados";
import { valorFormatter } from "../../../../utils/formatterValor";

var formatter = new Intl.NumberFormat("pt-br", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const options = [
  { value: "1x", label: 1 },
  { value: "2x", label: 2 },
  { value: "3x", label: 3 },
  { value: "4x", label: 4 },
  { value: "5x", label: 5 },
  { value: "6x", label: 6 },
  { value: "7x", label: 7 },
  { value: "8x", label: 8 },
  { value: "9x", label: 9 },
  { value: "10x", label: 10 },
  { value: "11x", label: 11 },
  { value: "12x", label: 12 },
  { value: "13x", label: 13 },
  { value: "14x", label: 14 },
  { value: "15x", label: 15 },
  { value: "16x", label: 16 },
  { value: "17x", label: 17 },
  { value: "18x", label: 18 },
  { value: "19x", label: 19 },
  { value: "20x", label: 20 },
  { value: "21x", label: 21 },
  { value: "22x", label: 22 },
  { value: "23x", label: 23 },
  { value: "24x", label: 24 },
];

class Mensalidade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descricao: "",
      meses: Meses,
      mesSelect: "",
      mes: "",
      tipo: null,
      usuario: "",
      options: [],
      pessoaSelect: [],
      pessoa: "",
      dataVencimento: null,
      totalMensalidades: "",
      totalMensalidadesGeradas: 0,
      open: false,
      servicoSelect: null,
      servico: null,
      infoParcelasMensalidade: [],
      numero_parcela: null,
      valor_parcela: null,
      desabilitar_impressao: false,
      infoPessoa: null,
      pessoas_invalidadas: [],
      listaPessoas: [],
      gerar_parcela_anual: false,
      valor_parcela_anual: null,
      data_vencimento_parcela_anual: null,
      parcela_anual: null,
      porcentagem_desconto: null,
      tipo_cobranca: null,
      quantidade_patrimonial: 0,
      quantidade_contribuinte_sem_dependente: 0,
      quantidade_contribuinte_com_dependente: 0,
      quantidade_temporario: 0,
      quantidade_licenciado: 0,
      quantidade_mampituba_praia: 0,
      observacoes: null,
      tipo_impressao: null,
      comissionados: [],
      pessoaComissionada: null,
      optionsComissionados: [],
      porcentagemComissionado: null,
      instituicao_cobranca_boleto: null,
      salvando: false,
      calendarEvents: [],
    };
    this.alteracao = this.alteracao.bind(this);
    this.listaInstituicoes();
    this.listaFeriados();
  }

  listaFeriados = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/feriado/lista" + requisicoes.entidade, {},
        requisicoes.header
      );
      this.setState({ calendarEvents: this.setaFeriados(retorno.data) });
    }, 500);
  };
  setaFeriados = (feriados) => {
    let lista = [];
    feriados.forEach((element) => {
      lista.push(moment(element.data).format('YYYY-MM-DD'));
    });
    return lista;
  };

  listaInstituicoes = async () => {
    const retorno = await api.post(
      "/instituicaoCobranca/lista" + requisicoes.entidade,
      {
        boleto: true,
      },
      requisicoes.header
    );

    let instituicoes_debito_em_conta = [];
    let instituicoes_boleto = [];

    for (let i = 0; i < retorno.data.length; i++) {
      const element = retorno.data[i];
      let option = {
        key: element.id,
        value: parseInt(element.id),
        label:
          // "C.C. " +
          element.conta +
          "-" +
          element.digito_conta +
          // " AG " +
          // element.agencia +
          // "-" +
          // element.digito_agencia +
          " / " +
          element.banco.nome,
        registro_automatico: element.registro_automatico,
      };
      if (element.debito_em_conta) {
        instituicoes_debito_em_conta.push(option);
      }
      if (element.boleto) {
        instituicoes_boleto.push(option);
      }
    }
    this.setState({ instituicoes_boleto });
  };

  componentMoney() {
    $(".dinheiro").mask("#.##0,00", {
      reverse: true,
      placeholder: "0,00",
    });
  }

  handleShow = () => this.setState({ open: true });

  alteracao(event) {
    try {
      if (event.target.name === "gerar_parcela_anual") {
        if (this.state.gerar_parcela_anual === false) {
          this.setState({ gerar_parcela_anual: true });
        } else {
          this.setState({ gerar_parcela_anual: false });
        }
      } else {
        this.setState({ [event.target.name]: event.target.value });
      }
    } catch (err) {
      console.log('mensalidade/alteracao: ' + event.target.value + '\nException: ' + err);
    }
  }
  alteracaoOnBlur(event) {
    try {
      const date = new Date(event.target.value).addDays(1);

      if (typeof date === 'object' && date !== null && 'getDay' in date && date.toString().length === 62) {
        const newDate = new Date(proximoDiaUtil(date, this.state.calendarEvents));
        this.setState({
          [event.target.name]: (date.getDay() === 0 || date.getDay() === 6) // Se for Sábado ou Domingo ele muda o campo para a nova data
            ? moment(newDate).format('YYYY-MM-DD')
            : event.target.value
        });
      }
    } catch (err) {
      console.log('mensalidade/alteracaoOnBlur: ' + event.target.value + '\nException: ' + err);
    }
  }

  alteraDataDiaUtil(date_param) {
    const date = new Date(date_param);
    if (typeof date === 'object' && date !== null && 'getDay' in date && date.toString().length == 62
      && (date.getDay() == 0 || date.getDay() == 6 || checkFeriado(date_param))
    ) {
      return moment(new Date(proximoDiaUtil(date, this.state.calendarEvents)));
    }
    return moment(date_param);
  };

  alteracaoSelect = (e) => {
    this.setState({ mesSelect: e });
    this.setState({ mes: e });
  };

  pesquisaPessoa = async () => {
    let term = document.getElementById("pesquisaPessoa").value;

    setTimeout(async () => {
      if (isFinite(String(term))) {
        await api
          .post(
            "/pessoa/pesquisaPorCodigoAcaoValorExato" + requisicoes.entidade,
            { pesquisa: document.getElementById("pesquisaPessoa").value },
            requisicoes.header
          )
          .then((retorno) => {
            this.setState({ options: ConverterParaLabelAcao(retorno.data) });
          });
      } else {
        await api
          .post(
            (this.props.location.query !== undefined
              ? "/pessoa/pesquisaNome"
              : "/pessoa/pesquisa") + requisicoes.entidade,
            { pesquisa: document.getElementById("pesquisaPessoa").value },
            requisicoes.header
          )
          .then((retorno) => {
            this.setState({
              options: ConverterParaLabelPessoa(retorno.data),
            });
          });
      }
    }, 200);
  };

  alteracaoSelectPessoa = async (e, v) => {
    if (v !== null) {
      this.setState({ pessoaSelect: v });
      this.setState({ pessoa: v });

      let retorno = await api.post(
        "/pessoa/visualiza" + requisicoes.entidade,
        { id: v.value },
        requisicoes.header
      );

      if (
        retorno.data.acao !== null &&
        retorno.data.acao.tipo_titulo !== null &&
        retorno.data.acao.tipo_titulo.valor_mensalidade !== null
      ) {
        this.setState({
          valor_parcela: parseFloat(
            retorno.data.acao.tipo_titulo.valor_mensalidade
          ),
        });
      } else {
        this.setState({ valor_parcela: 0 });
      }
      if (retorno.data.cpf !== null) {
        validaCpf(retorno.data.cpf)
      } else if (retorno.data.cnpj !== null) {
        validaCnpj(retorno.data.cpf)
      }
      // ValidadorCamposPessoa(retorno.data).map((e) => {
      //   return Object.entries(e).map((k, i) => {
      //     if (k[1] === false) {
      //       if (
      //         this.state.pessoas_invalidadas.find(
      //           (p) => p.id === retorno.data.id
      //         ) === undefined
      //       ) {
      //         this.state.pessoas_invalidadas.push(retorno.data);
      //       }
      //     }
      //   });
      // });

      this.setState({ infoPessoa: retorno.data });
    }
  };

  validaCamposPessoa = (pessoa) => {
    let pessoa_validada = true;
    ValidadorCamposPessoa(pessoa).map((e) => {
      // eslint-disable-next-line array-callback-return
      return Object.entries(e).map((k, i) => {
        if (k[1] === false) {
          pessoa_validada = false;
        }
      });
    });
    if (pessoa_validada === true) {
      const lista = this.state.pessoas_invalidadas;
      let indice = lista.findIndex((element) => element.id === pessoa.id);
      lista.splice(indice, 1);
      this.setState({ pessoas_invalidadas: lista });
    }
  };

  alteracaoTipo = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.value === "todos") {
      await api
        .post(
          "/pessoa/listaPessoaAcaoAtivo" + requisicoes.entidade,
          { tipo_cobranca: this.props.location.query.tipo_cobranca },
          requisicoes.header
        )
        .then((r) => {
          const lista = [];
          let countPatrimonial = 0;
          let countContComDep = 0;
          let countContSemDep = 0;
          let countTemporario = 0;
          let countLicenciado = 0;
          let countMampPraia = 0;
          r.data.forEach((p) => {
            p.acao.tipo_titulo.descricao === "PATRIMONIAL" &&
              countPatrimonial++;
            p.acao.tipo_titulo.descricao === "CONTRIBUINTE COM DEPENDENTE" &&
              countContComDep++;
            p.acao.tipo_titulo.descricao === "CONTRIBUINTE SEM DEPENDENTES" &&
              countContSemDep++;
            p.acao.tipo_titulo.descricao === "TEMPORÁRIO" && countTemporario++;
            p.acao.tipo_titulo.descricao === "LICENCIADO" && countLicenciado++;
            if (
              p.tipo_cobranca !== "isento" ||
              (p.tipo_cobranca === "isento" &&
                moment().isAfter(moment(p.data_final_isencao)))
            ) {
              p.acao.tipo_titulo.descricao === "MAMPITUBA PRAIA" &&
                countMampPraia++;
              ValidadorCamposPessoa(p).map((e) => {
                // eslint-disable-next-line array-callback-return
                return Object.entries(e).map((k, i) => {
                  if (k[1] === false) {
                    this.state.pessoas_invalidadas.push(p);
                  }
                });
              });
              if (this.props.location.query.tipo_cobranca === "debito_conta") {
                if (
                  p.instituicao_cobranca !== null &&
                  (p.cpf || p.cnpj !== null)
                ) {
                  lista.push(p);
                }
              } else {
                lista.push(p);
              }
            }
          });
          this.setState({ listaPessoas: lista });
          this.setState({ quantidade_patrimonial: countPatrimonial });
          this.setState({
            quantidade_contribuinte_sem_dependente: countContSemDep,
          });
          this.setState({
            quantidade_contribuinte_com_dependente: countContComDep,
          });
          this.setState({ quantidade_temporario: countTemporario });
          this.setState({ quantidade_licenciado: countLicenciado });
          this.setState({ quantidade_mampituba_praia: countMampPraia });
        });
    }
  };

  geraParcelasTodos = (tipo_titulo, pessoa, servico) => {
    return new Promise((resolve) => {
      let dataVencimento = moment(this.state.dataVencimento);
      let diaVencimentoBase = dataVencimento.get("date");
      let parcelas = [];

      let numeroParcela = this.state.numero_parcela;

      for (var i = 1; i < parseInt(numeroParcela) + 1; i++) {
        let mes_ref = Meses[dataVencimento.get("month") - 1 === -1 ? 11 : dataVencimento.get("month") - 1].value;
        parcelas.push({
          numero: i,
          pessoa,
          servico: servico,
          valor: tipo_titulo.valor_mensalidade,
          tipo_cobranca:
            this.props.location.query === undefined
              ? this.state.tipo_cobranca
              : this.props.location.query.tipo_cobranca,
          data_vencimento: dataVencimento.format("YYYY-MM-DD"),
          mes_referencia: mes_ref,
          ano_referencia:
            dataVencimento.get("month") - 1 === -1
              ? dataVencimento.get("year") - 1
              : dataVencimento.get("year"),
          instituicao_cobranca_id: this.state.instituicao_cobranca_boleto,
        });
        dataVencimento.set("date", diaVencimentoBase);
        dataVencimento = this.alteraDataDiaUtil(dataVencimento.add("month", 1));
      }
      if (this.state.gerar_parcela_anual === true) {
        let valor = parseFloat(
          // todos foi alterado, cliente queria gerar 1 mensal e 1 anual, pois aqui ele calcula a parcela anual
          tipo_titulo.valor_mensalidade * 12//parseInt(numeroParcela)
        ).toFixed(2);

        let desconto = (valor * this.state.porcentagem_desconto) / 100;
        valor = valor - desconto;
        parcelas.push({
          numero: i,
          pessoa,
          servico: servico,
          gerar_numero_titulo: true,
          tipo_cobranca:
            this.props.location.query === undefined
              ? this.state.tipo_cobranca
              : this.props.location.query.tipo_cobranca,
          valor: valor,
          data_vencimento: moment(
            this.state.data_vencimento_parcela_anual
          ).format("YYYY-MM-DD"),
          mes_referencia: "anual",
          ano_referencia:
            moment(this.state.data_vencimento_parcela_anual).get("month") - 1 === -1
              ? moment(this.state.data_vencimento_parcela_anual).get("year") - 1
              : moment(this.state.data_vencimento_parcela_anual).get("year"),
          instituicao_cobranca_id: this.state.instituicao_cobranca_boleto,
        });
      }
      resolve(parcelas);
    });
  };

  geraCobranca = async (tipo_cobranca) => {
    if (this.state.tipo === "todos") {
      this.handleShow();
      this.setState({ totalMensalidades: this.state.listaPessoas.length });
      let listaMensalidades = [];
      for (let i = 0; i < this.state.listaPessoas.length; i++) {
        const element = this.state.listaPessoas[i];
        if (
          element.acao &&
          element.acao.tipo_titulo &&
          element.acao.tipo_titulo.valor_mensalidade &&
          element.acao.tipo_titulo.valor_mensalidade > 0
        ) {
          const pessoa = {
            value: this.state.listaPessoas[i].id,
            label: this.state.listaPessoas[i].nome,
            dependente_cobrar: element.dependente_cobrar
          };
          const servico = this.state.servico;

          let lista = await this.geraParcelasTodos(
            this.state.listaPessoas[i].acao.tipo_titulo,
            pessoa,
            servico
          );
          listaMensalidades = listaMensalidades.concat(lista);
        }
      }
      await api.post(
        "/mensalidade/salvar" + requisicoes.entidade,
        listaMensalidades,
        requisicoes.header
      );
      this.setState({ geradoParcelas: true });
      setTimeout(() => {
        window.location.href = "/administrativo";
      }, 8000);
    } else {
      let lista = [];
      if (this.state.gerar_parcela_anual === true) {
        //salvar mensalidade anual
        let valorAnual = (this.state.valor_parcela * 12)
        let porcentagemDecimal = this.state.porcentagem_desconto / 100;

        let valorDesconto = valorAnual * porcentagemDecimal
        let valorFinalComDesconto = valorAnual - valorDesconto;
        await api
          .post(
            "/lancamentoConta/salvar" + requisicoes.entidade,
            {
              descricao:
                "Mensalidade Anual Ano Referência " +
                moment(this.state.data_vencimento_parcela_anual).get("year"),
              tipo_cobranca: TipoCobranca.find(
                (e) =>
                  e.value ===
                  (this.props.location.query !== undefined
                    ? tipo_cobranca
                    : this.state.tipo_cobranca)
              ).value,
              tipo_lancamento: TipoLancamento.find(
                (e) => e.value === "mensalidade"
              ).value,
              credito_debito: CreditoDebito.find((e) => e.value === "credito")
                .value,
              valor: valorFinalComDesconto,
              data_emissao: moment(),
              data_vencimento: this.state.data_vencimento_parcela_anual,
              pessoa_id: this.state.infoPessoa.id,
              mes_referencia: "anual",
                // Meses[
                //   moment(this.state.data_vencimento_parcela_anual).get("month")
                // ].value,
              ano_referencia: moment(
                this.state.data_vencimento_parcela_anual
              ).get("year"),
              servico_id: this.state.servico.value,
              numero_parcela: 1,
              observacoes: this.state.observacoes,
              instituicao_cobranca_id: this.state.instituicao_cobranca_boleto,
            },
            requisicoes.header
          )
          .then((e) => {
            this.setState({ parcela_anual: e.data });
          });
      }
      let listaImpressao = [];

      this.state.infoParcelasMensalidade.forEach(async (e) => {
        e.tipo_cobranca = TipoCobranca.find(
          (e) =>
            e.value ===
            (this.props.location.query !== undefined
              ? tipo_cobranca
              : this.state.tipo_cobranca)
        ).value;
        e.pessoa = this.state.pessoa;
        e.valor =
          typeof e.valor === "string"
            ? ReplaceAll(".", "", e.valor).toString().replace(",", ".")
            : e.valor;
        e.servico = this.state.servico;
        e.observacoes = this.state.observacoes;
        e.instituicao_cobranca_id = this.state.instituicao_cobranca_boleto;
        lista.push(e);
      });
      if (this.state.gerar_parcela_anual === true) {
        listaImpressao.push(this.state.parcela_anual);
      }
      const mensalidades = await api.post(
        "/mensalidade/salvar" + requisicoes.entidade,
        lista,
        requisicoes.header
      );
      for (let i = 0; i < mensalidades.data.length; i++) {
        const element = mensalidades.data[i];
        listaImpressao.push(element);
      }
      let listaComissionados = [];
      if (this.state.comissionados.length > 0) {
        for (let i = 0; i < listaImpressao.length; i++) {
          const element = listaImpressao[i];
          for (let j = 0; j < this.state.comissionados.length; j++) {
            const element2 = this.state.comissionados[j];
            listaComissionados.push({
              pessoa_id: element2.pessoa_id,
              lancamento_conta_id: element.id,
              porcentagem: element2.porcentagem,
            });
          }
        }
      }

      await api.post(
        "/comissionadosLancamentoConta/salvarVarios" + requisicoes.entidade,
        {
          comissoes: listaComissionados,
        },
        requisicoes.header
      );

      let instituicao = this.state.instituicoes_boleto.find(
        (e) => e.value === parseInt(this.state.instituicao_cobranca_boleto)
      );
      if (
        listaImpressao[0].tipo_cobranca === "boleto" &&
        !instituicao.registro_automatico
      ) {
        try {
          await api.post(
            "/boleto/geraBoleto" + requisicoes.entidade,
            {
              lancamento_contas: listaImpressao,
              tipo_impressao: this.state.tipo_impressao,
              observacoes: this.state.observacoes,
            },
            requisicoes.header
          );
          await this.gerarPdfBoletos();
        } catch (error) {
          alert("Problema ao gerar boleto. Tente novamente mais tarde.");
        }
        this.props.history.push("/administrativo");
      } else {
        this.props.history.push("/administrativo");
      }
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (
      ((this.props.location.query !== undefined &&
        this.props.location.query.tipo_cobranca === "boleto") ||
        this.state.tipo_cobranca === "boleto") &&
      this.state.instituicao_cobranca_boleto === null
    ) {
      alert("Selecione a instituição de cobrança para o boleto.");
      return;
    }
    if (typeof this.state.valor_parcela === "string") {
      let valor = ReplaceAll(".", "", this.state.valor_parcela);
      valor = ReplaceAll(",", ".", valor);
      this.setState({ valor_parcela: valor });
    }
    if (typeof this.state.valor_parcela_anual === "string") {
      let valor = ReplaceAll(".", "", this.state.valor_parcela_anual);
      valor = ReplaceAll(",", ".", valor);
      this.setState({ valor_parcela_anual: valor });
    }
    this.setState({ desabilitar_impressao: true });
    // Gera individual

    if (this.state.tipo === "individual") {
      if (this.state.infoPessoa === null) {
        alert("Pessoa Não Selecionada");
        return;
      }
      if (
        this.props.location.query !== undefined &&
        this.state.infoPessoa.tipo_cobranca === null
      ) {
        alert("Tipo Cobrança Não Informada");
        return;
      }
      if (this.state.gerar_parcela_anual === true) {
        if (this.state.valor_parcela_anual === null) {
          alert("Valor da Parcela Anual não foi Definido");
          return;
        }
        if (this.state.data_vencimento_parcela_anual === null) {
          alert("Data De Vencimento Parcela Anual não foi Definido");
          return;
        }
      }
    }
    this.setState({ salvando: true });
    //Gera todos
    if (
      (this.state.infoPessoa !== null &&
        this.state.infoPessoa.tipo_cobranca !== "isento") ||
      this.state.tipo === "todos"
    ) {
      if (
        (this.props.location.query !== undefined &&
          this.props.location.query.tipo_cobranca === "boleto") ||
        this.state.tipo_cobranca === "boleto"
      ) {
        this.geraCobranca("boleto");
      } else {
        this.geraCobranca("debito_em_conta");
      }
    } else {
      if (moment().isAfter(moment(this.state.infoPessoa.data_final_isencao))) {
        if (
          (this.props.location.query !== undefined &&
            this.props.location.query.tipo_cobranca === "boleto") ||
          this.state.tipo_cobranca === "boleto"
        ) {
          this.geraCobranca("boleto");
        } else {
          this.geraCobranca("debito_em_conta");
        }
      } else {
        alert("Cliente Isento de Cobrança");
        return;
      }
    }
  };

  gerarPdfBoletos = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    const r = await api.get("/boleto?e=" + entidade + "&t=Baer " + token);
    var oReq = new XMLHttpRequest();
    var URLToPDF =
      r.config.baseURL + "boleto?e=" + entidade + "&t=Baer " + token;
    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  calculaDescontoParcelaAnual = (event) => {
    const valorDesconto =
      (parseFloat(event.target.value) *
        parseFloat(this.state.valor_parcela_anual)) /
      100;
    const valorComDesconto = parseFloat(
      parseFloat(this.state.valor_parcela_anual) - valorDesconto
    ).toFixed(2);
    this.setState({ valor_parcela_anual: valorComDesconto });
    this.setState({ porcentagem_desconto: parseFloat(event.target.value) });
  };

  alteracaoSelectServico = (e) => {
    this.setState({ servicoSelect: e });
    this.setState({ servico: e });
  };

  pesquisaServico = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/servico/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaServico").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista.push(objeto);
      });
      this.setState({ optionsServico: lista });
    }, 500);
  };

  alteraParcelas = (event) => {
    const name = event.target.name.toString().substring(0, 12);
    const value = event.target.value;
    let lista = [];
    const indice = event.target.name.toString().substring(12, 25);
    lista = this.state.infoParcelasMensalidade;
    lista.splice(parseInt(indice), 1, {
      numero: parseInt(indice) + 1,
      valor: name === "valorParcela" ? value : lista[parseInt(indice)].valor,
      data_vencimento:
        name === "dataVencimen"
          ? value
          : lista[parseInt(indice)].data_vencimento,
      mes_referencia:
        name === "mesReferenci"
          ? value
          : lista[parseInt(indice)].mes_referencia,
      ano_referencia:
        name === "anoReferenci"
          ? value
          : lista[parseInt(indice)].ano_referencia,
    });
    if (lista.find((e) => e.valor.toString() === "NaN")) {
      lista.forEach((element) => {
        if (element.valor.toString() === "NaN") {
          element.valor = "";
        }
      });
    }
    this.setState({ infoParcelasMensalidade: lista });
  };

  geraParcelas = (event) => {
    const parcelas = [];
    let valor_total = 0;

    if (this.state.dataVencimento === null) {
      alert("Data de Vencimento não Definido");
      return;
    } else if (this.state.valor_parcela === null || this.state.valor_parcela === undefined || isNaN( parseFloat (this.state.valor_parcela))) {
      alert("Valor Parcela não Definido ou inválido");
      return;
    }
    const dataVencimento = moment(this.state.dataVencimento);
    const numeroParcela = parseInt(event.target.value.replace("x", ""), 10);
    const valor_parcela_str = String(this.state.valor_parcela);
    const valor_parcela = parseFloat(valor_parcela_str.replace(",", "."));

    if (!(valor_parcela && valor_parcela > 0)) {
      alert("Valor de Parcela inválido");
      return;
    }

    for (let i = 1; i <= numeroParcela; i++) {
      const mes = dataVencimento.month() + 1;
      const mes_ref = Meses[mes === 1 ? 11 : mes - 2].value;
      const ano_referencia = mes === 1 ? dataVencimento.year() - 1 : dataVencimento.year();

      parcelas.push({
        numero: i,
        valor: valor_parcela,
        data_vencimento: dataVencimento.format("YYYY-MM-DD"),
        mes_referencia: mes_ref,
        ano_referencia,
      });

      dataVencimento.add(1, "month");
      valor_total += valor_parcela;
    }

    this.setState({
      infoParcelasMensalidade: parcelas,
      valor_parcela_anual: valor_total,
    });
  };



  buscaComissionados = async (event, a) => {
    let lista = await api.post(
      "/pessoa/buscaComissionadosPessoa" + requisicoes.entidade,
      { pesquisa: event.target.value },
      requisicoes.header
    );

    for (let i = 0; i < lista.data.length; i++) {
      const element = lista.data[i];
      element.value = element.id;
      element.label = element.nome;
    }

    this.setState({ optionsComissionados: lista.data });
  };

  addComisionados = () => {
    if (
      this.state.porcentagemComissionado === null ||
      this.state.porcentagemComissionado === "" ||
      this.state.pessoaComissionada === null
    ) {
      return;
    }
    let comissionados = this.state.comissionados;
    comissionados.push({
      pessoa_nome: this.state.pessoaComissionada.nome,
      pessoa_id: this.state.pessoaComissionada.id,
      porcentagem: this.state.porcentagemComissionado,
    });
    this.setState({
      comissionados: comissionados,
      pessoaComissionada: null,
    });
  };

  removeComisionados = (i) => {
    let comissionados = this.state.comissionados;
    comissionados.splice(i, 1);
    this.setState({ comissionados: comissionados });
  };

  render() {
    return (
      <div>
        <Header as="h1" dividing>
          Mensalidade
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={6}>
              <Form.Label>Serviço</Form.Label>
              <Select
                placeholder="Digite para buscar"
                name="servico"
                value={this.state.servico}
                onChange={this.alteracaoSelectServico}
                options={this.state.optionsServico}
                onKeyDown={this.pesquisaServico}
                inputId="pesquisaServico"
              />
            </Col>
            <Col
              sm={
                (this.props.location.query &&
                  this.props.location.query.tipo_cobranca === "boleto") ||
                  this.state.tipo_cobranca === "boleto"
                  ? 2
                  : 6
              }
            >
              <Form.Label>Tipo de Cobrança</Form.Label>
              <Form.Control
                as="select"
                name="tipo_cobranca"
                value={
                  this.props.location.query !== undefined
                    ? this.props.location.query.tipo_cobranca
                    : this.state.tipo_cobranca
                }
                onChange={this.alteracao}
                disabled={this.props.location.query !== undefined}
              >
                <option></option>
                <option value="debito_em_conta">Débito Em Conta</option>
                <option value="boleto">Boleto</option>
              </Form.Control>
            </Col>
            {((this.props.location.query &&
              this.props.location.query.tipo_cobranca === "boleto") ||
              this.state.tipo_cobranca === "boleto") && (
                <Col sm={4}>
                  <Form.Label>Instituição Cobrança</Form.Label>
                  <Select
                    name="instituicao_cobranca_boleto"
                    options={this.state.instituicoes_boleto}
                    onChange={(e, a) => {
                      this.setState({
                        instituicao_cobranca_boleto: e.value,
                      });
                    }}
                    // value={this.state.instituicao_cobranca_boleto}
                    fluid
                  />
                </Col>
              )}

            <Col sm={6}>
              <Form.Label>Tipo Operação</Form.Label>
              <Form.Control
                as="select"
                name="tipo"
                checked={this.state.tipo}
                id="tipo"
                defaultValue={this.state.tipo}
                required
                onChange={this.alteracaoTipo}
              >
                <option></option>
                {this.props.location.query !== undefined && (
                  <option value="todos">Todos</option>
                )}
                <option value="individual">Individual</option>
              </Form.Control>
            </Col>
            <Col sm={6} hidden={this.state.tipo === "todos"}>
              <Form.Label>Pessoa</Form.Label>
              <Row>
                <Col sm={12}>
                  <Autocomplete
                    id="pesquisaPessoa"
                    value={this.state.pessoa}
                    inputValue={this.state.inputValue}
                    onInputChange={(event, newInputValue) => {
                      this.setState({ inputValue: newInputValue });
                    }}
                    options={this.state.options}
                    getOptionLabel={(option) =>
                      option.label !== undefined ? option.label : ""
                    }
                    onKeyUp={this.pesquisaPessoa}
                    onChange={this.alteracaoSelectPessoa}
                    renderInput={(params) => (
                      <TextField {...params} label="Busca" size="small" />
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Form.Label>Data de Vencimento</Form.Label>
              <FormControl
                type="date"
                name="dataVencimento"
                value={this.state.dataVencimento}
                onBlur={this.alteracaoOnBlur}
                onChange={this.alteracao}
              />
            </Col>

            <Col sm={6} hidden={this.state.tipo === "todos"}>
              <Form.Label>Valor da Parcela</Form.Label>
              <FormControl
                aria-describedby="basic-addon1"
                name="valor_parcela"
                onBlur={this.alteracao}
                defaultValue={
                  this.state.valor_parcela !== null
                    ? valorFormatter.format(this.state.valor_parcela)
                    : ""
                }
                onKeyUp={this.componentMoney}
                className="dinheiro"
                min={0}
              />
            </Col>

            <Col sm={6} hidden={this.state.tipo === "todos"}>
              <b>Tipo De Cobrança: </b>
              <Form.Label>
                {this.state.infoPessoa !== null &&
                  this.state.infoPessoa.tipo_cobranca !== null &&
                  this.props.location.query !== undefined
                  ? TipoCobranca.find(
                    (t) => t.value === this.state.infoPessoa.tipo_cobranca
                  ).label
                  : this.state.infoPessoa === null
                    ? ""
                    : this.props.location.query === undefined
                      ? ""
                      : "Tipo de Cobrança não definido, Favor definir na página Meus Dados"}
              </Form.Label>
            </Col>
          </Row>

          <Col sm={12} style={{ paddingTop: "10px" }}>
            <Row>
              <Col sm={8}>
                <Row>
                  <Col sm={3}>
                    <b>Parcelas:</b>
                  </Col>
                  <Col sm={8}>
                    {(this.props.location.query !== undefined &&
                      this.props.location.query.tipo_cobranca === "debito_em_conta") ||
                      this.state.tipo_cobranca === "debito_em_conta" ? (
                      <Form.Control
                        placeholder="Tipo"
                        as="select"
                        name="numero_parcela"
                        onChange={
                          this.state.tipo === "todos"
                            ? this.alteracao
                            : this.geraParcelas
                        }
                      >
                        <option></option>
                        {options.map((e) => {
                          return <option value={e.value}>{e.label}</option>;
                        })}
                      </Form.Control>
                    ) : (
                      <Form.Control
                        placeholder="Tipo"
                        as="select"
                        name="numero_parcela"
                        disabled={
                          (this.state.tipo !== "todos" &&
                            (this.state.valor_parcela === null ||
                              this.state.valor_parcela === 0 ||
                              this.state.dataVencimento === null)) ||
                          (this.state.dataVencimento === null &&
                            this.state.tipo === "todos")
                        }
                        onChange={
                          this.state.tipo === "todos"
                            ? this.alteracao
                            : this.geraParcelas
                        }
                      >
                        <option></option>
                        {options.map((e) => {
                          return <option value={e.value}>{e.label}</option>;
                        })}
                      </Form.Control>
                    )}
                  </Col>
                  {this.state.infoParcelasMensalidade.map((e, i) => {
                    return (
                      <Row>
                        <Col key={i} sm={12}>
                          <Row>
                            <Col sm={2}>
                              Parcela {e.numero}
                              <Form.Control
                                // value={e.valor}
                                name={"valorParcela" + i}
                                onBlur={this.alteraParcelas}
                                defaultValue={
                                  e.valor !== null
                                    ? formatter.format(e.valor)
                                    : ""
                                }
                                onKeyUp={this.componentMoney}
                                className="dinheiro"
                              ></Form.Control>
                            </Col>
                            <Col sm={3}>
                              Mês de referência
                              <Form.Control
                                as="select"
                                name={"mesReferenci" + i}
                                onChange={this.alteraParcelas}
                                defaultValue={
                                  this.state.infoParcelasMensalidade[i].mes_referencia
                                }
                              >
                                <option></option>
                                {this.state.meses.map((e, i) => (
                                  <option key={i} value={e.value}>
                                    {e.label}
                                  </option>
                                ))}
                              </Form.Control>
                            </Col>
                            <Col sm={2}>
                              Ano
                              <Form.Control
                                value={e.ano_referencia}
                                name={"anoReferenci" + i}
                                onChange={this.alteraParcelas}
                              ></Form.Control>
                            </Col>
                            <Col sm={5}>
                              Vencimento
                              <FormControl
                                type="date"
                                defaultValue={e.data_vencimento}
                                name={"dataVencimen" + i}
                                onChange={this.alteraParcelas}
                              ></FormControl>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                </Row>
              </Col>
              <Col sm={4}>
                <Row>
                  <Col sm={3}>
                    <b>Impressão:</b>
                  </Col>
                  <Col sm={4}>
                    <Button
                      primary
                      type="submit"
                      disabled={
                        this.state.desabilitar_impressao === true ||
                        this.state.pessoas_invalidadas.length > 0 ||
                        (this.state.infoPessoa !== null &&
                          this.state.infoPessoa.tipo_cobranca === null &&
                          this.props.location.query !== undefined)
                      }
                      value="3vias"
                      name="tipo_impressao"
                      onClick={this.alteracao}
                    >
                      3 Vias
                    </Button>
                  </Col>
                  <Col sm={4}>
                    <Button
                      primary
                      type="submit"
                      value="1via"
                      name="tipo_impressao"
                      onClick={this.alteracao}
                      disabled={
                        this.state.desabilitar_impressao === true ||
                        this.state.pessoas_invalidadas.length > 0 ||
                        (this.state.infoPessoa !== null &&
                          this.state.infoPessoa.tipo_cobranca === null &&
                          this.props.location.query !== undefined)
                      }
                    >
                      1 Via
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col sm={12} style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 16 }}>
            <Row>
              <Col sm={3}>
                <Checkbox
                  label="  Gerar Parcela Anual"
                  onClick={this.alteracao}
                  name="gerar_parcela_anual"
                  value={this.state.gerar_parcela_anual}
                  checked={this.state.gerar_parcela_anual}
                />
              </Col>
              <Col sm={3} hidden={this.state.gerar_parcela_anual === false}>
                <Form.Label>Data Vencimento</Form.Label>
                <FormControl
                  type="date"
                  defaultValue={this.state.data_vencimento_parcela_anual}
                  name="data_vencimento_parcela_anual"
                  onChange={this.alteracao}
                ></FormControl>
              </Col>
              <Col sm={3} hidden={this.state.gerar_parcela_anual === false}>
                <Form.Label>Desconto</Form.Label>
                <Row>
                  <Col sm={7}>
                    <Form.Control
                      onChange={this.calculaDescontoParcelaAnual}
                      type="number"
                    ></Form.Control>
                  </Col>
                  <Col sm={2}>%</Col>
                </Row>
              </Col>
              <Col
                sm={3}
                hidden={
                  this.state.gerar_parcela_anual === false ||
                  this.state.tipo === "todos"
                }
              >
                <Form.Label>Valor</Form.Label>
                <Form.Control
                  onBlur={this.alteracao}
                  defaultValue={
                    this.state.valor_parcela !== null
                      ? formatter.format(((this.state.valor_parcela * 12) * (100 - this.state.porcentagem_desconto)) / 100)
                      : ""
                  }
                  onKeyUp={this.componentMoney}
                  value={valorFormatter.format(  ((this.state.valor_parcela * 12) * (100 - this.state.porcentagem_desconto)) / 100)
                }
                  className="dinheiro"
                  disabled
                ></Form.Control>
              </Col>
            </Row>
          </Col>
          <Col sm={12} style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 16 }}>
            <Form.Label>Observações</Form.Label>
            <FormControl
              placeholder="Observações"
              aria-describedby="basic-addon1"
              as="textarea"
              name="observacoes"
              onChange={this.alteracao}
            />
          </Col>
          <Form>
            {this.state.tipo !== "todos" && (
              <>
                <Col
                  sm={12}
                  style={{ paddingTop: 20, paddingLeft: 0, paddingRight: 0 }}
                >
                  <h3>Vincular Comissionado</h3>
                  <Row style={{ marginLeft: 15, marginRight: 0 }}>
                    <Col
                      sm={6}
                      style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}
                    >
                      <Autocomplete
                        // id="pesquisaPessoa"
                        value={this.state.pessoaComissionada}
                        // inputValue={this.state.inputValue}
                        onInputChange={(event, newInputValue) => {
                          this.setState({ inputValue: newInputValue });
                        }}
                        options={this.state.optionsComissionados}
                        getOptionLabel={(option) =>
                          option.label !== undefined ? option.label : ""
                        }
                        onKeyUp={(e, a) => this.buscaComissionados(e, a)}
                        onChange={(e, v) =>
                          this.setState({ pessoaComissionada: v })
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Busca" size="small" />
                        )}
                      />
                    </Col>

                    <Col sm={2} style={{ marginTop: -15 }}>
                      <Form.Label>Comissão (%)</Form.Label>
                      <FormControl
                        aria-describedby="basic-addon1"
                        type="number"
                        step="0.01"
                        min="0"
                        max="100"
                        placeholder="Porcentagem"
                        onChange={this.alteracao}
                        name="porcentagemComissionado"
                        value={this.state.porcentagemComissionado}
                      />
                    </Col>
                    <Col style={{ marginTop: 8 }}>
                      <Button
                        color="green"
                        icon="add"
                        type="button"
                        onClick={this.addComisionados}
                      ></Button>
                    </Col>
                  </Row>
                </Col>
                {this.state.comissionados.length > 0 && (
                  <Table celled selectable style={{ marginTop: 12 }}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Nome</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"right"}>
                          Porcentagem
                        </Table.HeaderCell>
                        <Table.HeaderCell width={1}></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      {this.state.comissionados.map((e, i) => (
                        <Table.Row>
                          <Table.Cell
                            style={{ paddingTop: 7, paddingBottom: 7 }}
                          >
                            {e.pessoa_nome}
                          </Table.Cell>
                          <Table.Cell
                            style={{ paddingTop: 7, paddingBottom: 7 }}
                            textAlign={"right"}
                          >
                            {parseFloat(e.porcentagem).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) + "%"}
                          </Table.Cell>
                          <Table.Cell
                            textAlign="center"
                            style={{ paddingTop: 7, paddingBottom: 7 }}
                          >
                            <Button
                              color="red"
                              icon="x"
                              type="button"
                              size="tiny"
                              onClick={() => this.removeComisionados(i)}
                            ></Button>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                )}
              </>
            )}
          </Form>
          {this.state.tipo === "todos" && (
            <Col sm={10} style={{ float: "right" }}>
              <Form.Label>
                <b>PATRIMONIAL: </b> {this.state.quantidade_patrimonial + "  "}
              </Form.Label>
              <Form.Label>
                <b>&nbsp;CONTRIBUINTE SEM DEPENDENTE: </b>{" "}
                {this.state.quantidade_contribuinte_sem_dependente + "  "}
              </Form.Label>
              <Form.Label>
                <b>&nbsp;CONTRIBUINTE COM DEPENDENTE: </b>{" "}
                {this.state.quantidade_contribuinte_com_dependente + "  "}
              </Form.Label>
              <Form.Label>
                <b>&nbsp;TEMPORÁRIO </b>{" "}
                {this.state.quantidade_temporario + "  "}
              </Form.Label>
              <Form.Label>
                <b>&nbsp;LICENCIADO </b> {this.state.quantidade_licenciado}
              </Form.Label>
              <Form.Label>
                <b>&nbsp;MAMPITUBA PRAIA </b>{" "}
                {this.state.quantidade_mampituba_praia}
              </Form.Label>
            </Col>
          )}
          {this.state.pessoas_invalidadas.length > 0 && (
            <ListaPessoasInvalidas
              pessoas_invalidadas={this.state.pessoas_invalidadas}
              validaCamposPessoa={this.validaCamposPessoa}
            />
          )}
          <Divider />

          <Segment clearing>
            <Header floated="right">
              <Link to="/administrativo">
                <Button type="button">Voltar</Button>
              </Link>
              <Button
                positive
                type="submit"
                disabled={this.state.salvando}
                loading={this.state.salvando}
              >
                Salvar
              </Button>
            </Header>
          </Segment>
          {this.state.listaPessoas.length > 0 && (
            // eslint-disable-next-line react/jsx-no-undef
            <ListaPessoas lista_pessoas={this.state.listaPessoas} />
          )}

          {this.state.listaPessoas.length > 0 && (
            <>
              <Divider />
              <Segment clearing>
                <Header floated="right">
                  <Link to="/administrativo">
                    <Button type="button">Voltar</Button>
                  </Link>
                  <Button
                    positive
                    type="submit"
                    disabled={this.state.salvando}
                    loading={this.state.salvando}
                  >
                    Salvar
                  </Button>
                </Header>
              </Segment>
            </>
          )}

        </Form>

        <Modal
          open={this.state.open}
          // onClose={() => this.setState({ open: false })}
          centered={false}
        >
          <Header icon="wait" content="Gerando Mensalidades" />
          <Modal.Content>
            <Row>
              {this.state.geradoParcelas ? (
                <>
                  <Col sm={12} style={{ textAlign: "center" }}>
                    <Icon color="green" name="check" size="massive" />
                  </Col>

                  <Col sm={12} style={{ textAlign: "center" }}>
                    <h4>Parcelas geradas com sucesso!</h4>
                  </Col>
                </>
              ) : (
                <>
                  <Col sm={12} style={{ textAlign: "center" }}>
                    <CircularProgress />
                  </Col>
                  <Col sm={12} style={{ textAlign: "center" }}>
                    Gerando parcelas. Aguarde...
                  </Col>
                </>
              )}
            </Row>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
export default withRouter(Mensalidade);
