import React, { Component } from "react";
import { Modal, Dropdown, Checkbox } from "semantic-ui-react";
import TextField from "@material-ui/core/TextField";
import SendIcon from "@material-ui/icons/Send";
import IconButton from "@material-ui/core/IconButton";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Button from "@material-ui/core/Button";
import { Form, Col, Row } from "react-bootstrap";
import Select from "react-select";

import CloseIcon from "@material-ui/icons/Close";

import "../styles.css";

export default class lista_modalidades extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal
        style={{ overflow: "visible" }}
        dimmer="bluring"
        size="large"
        open={this.props.modalEditar}
      >
        <Modal.Header>
          {"Editar Subgrupo"}
          <IconButton
            className="bottomModal"
            onClick={() => this.props.changeModalEditar(null, false)}
            color="secondary"
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>

        <Modal.Content>
          <Col sm={12}>
            <Row>
              <Col sm={4}>
                <Form.Label style={{ marginTop: "-3px" }}>
                  Modalidade
                </Form.Label>
                <Select
                  placeholder="vincular modalidade"
                  label="Tipo"
                  id="modalidade"
                  as="select"
                  name="modalidade"
                  options={this.props.optionsM}
                  onChange={this.props.alteracaoSelect}
                  onKeyDown={this.props.pesquisaModalidade}
                  required
                />
              </Col>
              <Col sm={2}>
                <TextField
                  id="descricao"
                  label="Descrição"
                  onChange={this.props.alteracao}
                  style={{
                    marginTop: "17px",
                  }}
                  defaultValue={this.props.descricao}
                  required
                />
              </Col>

              <Col sm={2}>
                <TextField
                  id="quantidade_tiros"
                  label="Nº de Tiros"
                  onChange={this.props.alteracao}
                  type="number"
                  style={{
                    marginTop: "17px",
                  }}
                  disabled={this.props.modalidade_id == null}
                  required
                  defaultValue={this.props.quantidade_tiros}
                />
              </Col>

              <Row>
                <Col sm={5}>
                  <Form required>
                    <Form.Label>Tipo de Evento</Form.Label>
                    <Checkbox
                      onChange={this.props.changeModalEditartoggleCheckbox}
                      checked={this.props.checkedtreino}
                      slider
                      id="treino"
                      name="treino"
                      label="Treino"
                    />
                    <Checkbox
                      onChange={this.props.toggleCheckbox}
                      checked={this.props.checkedcampeonato}
                      id="prova"
                      slider
                      name="prova"
                      label="Prova"
                    />
                  </Form>
                </Col>
              </Row>
            </Row>
          </Col>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="primary"
            floated="right"
            style={{ outline: "none" }}
            onClick={this.props.salvarSubgrupoEditada}
          >
            Salvar
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
