import React, { Component } from "react";
import { Header, Menu, Icon } from "semantic-ui-react";

// componentes criados
import MensagemFaleConosco from "./mensagensFaleConosco/index";
import Permissoes from "./permissoes/index";
import Rotas from "./rotas/index";
import Modulos from "./modulos/index";
import LiberaModulos from "./liberaModulos/index";
import CopiarPerfis from "./copiarPerfis/index";

// Icones
import { Icon as Iconify } from "@iconify/react";
import mdiRoutes from "@iconify-icons/mdi/routes";
import mdiAccountLock from "@iconify-icons/mdi/account-lock";
import mdiViewModule from "@iconify-icons/mdi/view-module";
import mdiFaceManProfile from "@iconify-icons/mdi/face-man-profile";

// requisicoes
import api from "../../services/api";
import requisicoes from "../../utils/Requisicoes";

export default class Suporte extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: "mensagens",
      listaRotas: [],
      listaPermissoes: [],
      listaModulos: [],
      listaEntidades: [],
    };
    let buscas = async () => {
      await this.listaRotas();
      await this.listaPermissoes();
      await this.listaModulos();
      await this.listaEntidades();
      // TODO: Trazer estas infos com uma única rota

      // TODO: Buscar as mensagens aqui tb e não toda vez que eu clico no menu
    };
    buscas();
  }

  listaRotas = async () => {
    const permissoes = await api.post(
      "/rotaFront/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    this.setState({ listaRotas: permissoes.data });
  };

  listaPermissoes = async () => {
    const permissoes = await api.post(
      "/permissao/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ listaPermissoes: permissoes.data });
  };

  listaModulos = async () => {
    const modulos = await api.post(
      "/modulo/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ listaModulos: modulos.data });
  };

  listaEntidades = async () => {
    const entidades = await api.post(
      "/modulo/listaEntidadesComModulos" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ listaEntidades: entidades.data });
  };

  render() {
    let content = "";
    switch (this.state.selectedMenu) {
      case "mensagens":
        content = <MensagemFaleConosco />;
        break;

      case "liberaModulos":
        content = (
          <LiberaModulos
            listaEntidades={this.state.listaEntidades}
            listaModulos={this.state.listaModulos}
          />
        );
        break;

      case "modulos":
        content = (
          <Modulos
            listaModulos={this.state.listaModulos}
            listaRotas={this.state.listaRotas}
            listaPermissoes={this.state.listaPermissoes}
          />
        );
        break;

      case "rotas":
        content = <Rotas listaRotas={this.state.listaRotas} />;
        break;

      case "permissoes":
        content = <Permissoes listaPermissoes={this.state.listaPermissoes} />;
        break;

      case "copiar_perfis":
        content = <CopiarPerfis listaEntidades={this.state.listaEntidades} />;
        break;
      default:
        this.setState({ selectedMenu: "mensagens" });
        content = <MensagemFaleConosco />;
        break;
    }
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Suporte
        </Header>
        <Menu tabular icon="labeled">
          <Menu.Item
            name="mensagens"
            active={this.state.selectedMenu === "mensagens"}
            onClick={() => this.setState({ selectedMenu: "mensagens" })}
          >
            <Icon name="talk" />
            Mensagens
          </Menu.Item>
          <Menu.Item
            name="liberaModulos"
            active={this.state.selectedMenu === "liberaModulos"}
            onClick={() => this.setState({ selectedMenu: "liberaModulos" })}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Iconify icon={mdiViewModule} width={28.313} height={24} />
            Liberar Módulos
          </Menu.Item>
          <Menu.Item
            name="modulos"
            active={this.state.selectedMenu === "modulos"}
            onClick={() => this.setState({ selectedMenu: "modulos" })}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Iconify icon={mdiViewModule} width={28.313} height={24} />
            Módulos
          </Menu.Item>

          <Menu.Item
            name="rotas"
            active={this.state.selectedMenu === "rotas"}
            onClick={() => this.setState({ selectedMenu: "rotas" })}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Iconify icon={mdiRoutes} width={28.313} height={24} />
            Rotas
          </Menu.Item>
          <Menu.Item
            name="permissoes"
            active={this.state.selectedMenu === "permissoes"}
            onClick={() => this.setState({ selectedMenu: "permissoes" })}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Iconify icon={mdiAccountLock} width={28.313} height={24} />
            Permissões
          </Menu.Item>
          <Menu.Item
            name="copiar_perfis"
            active={this.state.selectedMenu === "copiar_perfis"}
            onClick={() => this.setState({ selectedMenu: "copiar_perfis" })}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Iconify icon={mdiFaceManProfile} width={28.313} height={24} />
            Copiar perfil
          </Menu.Item>
        </Menu>
        {content}
      </div>
    );
  }
}
