/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Table, Segment, Divider, Header } from "semantic-ui-react";
import { Form, Row, Col } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import {
  Delete,
  AddCircle,
  Visibility,
  VisibilityOff,
  PlayCircleOutline,
  AddCircleOutline,
  Print,
} from "@material-ui/icons/";
import { Tooltip, IconButton, Button } from "@material-ui/core";

import Campeao from "./campeao.ico";
import Medalha from "./medalha.png";
import Pergaminho from "./pergaminho.png";

import api from "../../../services/api";

import { Link } from "react-router-dom";

import "../styles.css";
// import "../fireWORK.css";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rodadaCadastradas: [],
      infRodada: [],
    };
    this.ordenaLista();
  }
  ordenaLista = async () => {
    function ordena(a, b) {
      if (a.numero > b.numero) {
        return 1;
      }
      if (a.numero < b.numero) {
        return -1;
      }
      return 0;
    }
    this.props.rodadasCadastradas.sort(ordena);
  };
  changeExibirRodada = async () => {
    if (this.state.exibirRodada) {
      this.setState({ exibirRodada: false });
    } else {
      this.setState({ exibirRodada: true });
    }
  };
  imprimir = async (event, rodada) => {
    try {
      // const grupoPorRodada = await api.post(
      //   "/rodada/geraPdfRodada" + requisicoes.entidade,
      //   {
      //     rodada_id: rodada.id,
      //   },
      //   requisicoes.header
      // );

      const usuarioLogado = await api.post(
        "/usuario/usuarioLogado" + requisicoes.entidade,
        {},
        requisicoes.header
      );

      var localStorage = window.localStorage;
      var token = localStorage.getItem("token_clube");
      const r = await api.get(
        "/rodada/buscaPdfRodada" +
        requisicoes.entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.id
      );
      var oReq = new XMLHttpRequest();

      var URLToPDF =
        r.config.baseURL +
        "rodada/buscaPdfRodada" +
        requisicoes.entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.id;

      oReq.open("GET", URLToPDF, true);
      oReq.responseType = "blob";
      oReq.onload = function () {
        const file = new Blob([oReq.response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      };
      oReq.send();
    } catch (e) {
      console.log("error", e);
    }
  };
  deleteLista = async (event) => {
    const grupoPorRodada = await api.post(
      "/rodada/excluirRodada" + requisicoes.entidade,
      {
        rodada_id: event.id,
      },
      requisicoes.header
    );
    // this.setState({rodadasCadastradas:grupoPorRodada})
    // this.props.rodadasCadastradas
    window.location.pathname = "/desporto";
  };
  // adicionaListaPartida = async (event, e) => {

  // };
  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Segment>
          <Header>
            {" "}
            <div className="flexSpaceBetween">
              <div>
                {" "}
                RODADAS
                <Button
                  hidden={this.props.faseAtual !== "Mata-Mata e Fase-de-Grupo"}
                  style={{ outline: "none", color: "crimson" }}
                  onClick={() => this.props.changeModalIniciarMataMata(true)}
                >
                  {" INICIAR MATA-MATA "}
                </Button>
              </div>
              <div className="flexEnd">
                <div>
                  {/* <Button
                    style={{ outline: "none" }}
                    color="primary"
                    disabled={this.props.listaTimesOpcoes.length === 1}
                    onClick={this.props.gerarRodada}
                  >
                    Gerar Rodada
                    { <AddCircleOutline />{" "} }
                  </Button> */}
                </div>
                <div>
                  <IconButton
                    style={{ outline: "none" }}
                    color="primary"
                    disabled={this.props.listaTimesOpcoes.length === 1}
                    onClick={() => this.props.changeModalAdicionarRodada(true)}
                  >
                    <AddCircleOutline />{" "}
                  </IconButton>
                </div>

                <div>
                  <Tooltip
                    title={
                      this.state.exibirRodada ? (
                        <h5>Mostrar Inscritos</h5>
                      ) : (
                        <h5>Esconder Inscritos</h5>
                      )
                    }
                    arrow
                    placement="right"
                  >
                    <IconButton
                      color="secondary"
                      style={{ outline: "none" }}
                      onClick={this.changeExibirRodada}
                    >
                      {this.state.exibirRodada ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
            <Divider style={{ marginTop: "-0.13ch" }} />
          </Header>

          {this.props.fase_atual === "Mata-Mata" ? (
            <Table selectable hidden={this.state.exibirRodada} color="blue">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Rodada</Table.HeaderCell>
                  <Table.HeaderCell>Partidas</Table.HeaderCell>
                  <Table.HeaderCell>*</Table.HeaderCell>
                  <Table.HeaderCell>Imprimir</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.rodadasCadastradas !== undefined ||
                  this.props.rodadasCadastradas !== []
                  ? this.props.rodadasCadastradas.map((element, index) => {
                    return (
                      <Table.Row
                        style={{
                          color: "rgb(9, 124, 165)",
                          backgroundColor: "rgba(73, 105, 186, 0.02)",
                        }}
                      >
                        <Table.Cell width={4}>{element.numero}</Table.Cell>
                        <Table.Cell width={4}>
                          {element.partidas.length}
                        </Table.Cell>
                        <Table.Cell width={4}>
                          <IconButton
                            className="botaoTabble"
                            icon
                            style={{ outline: "none" }}
                            size="mini"
                            onClick={() =>
                              this.props.changeModalListaPartida(
                                element,
                                true
                              )
                            }
                          >
                            {" "}
                            <PlayCircleOutline
                              size="small"
                              name="configure"
                              id={element.id}
                            />
                          </IconButton>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                  : ""}
              </Table.Body>
            </Table>
          ) : (
            <Table selectable hidden={this.state.exibirRodada} color="blue">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Grupo</Table.HeaderCell>
                  <Table.HeaderCell>Rodada</Table.HeaderCell>
                  <Table.HeaderCell>Partidas</Table.HeaderCell>
                  <Table.HeaderCell>Lancamento</Table.HeaderCell>
                  <Table.HeaderCell>Imprimir</Table.HeaderCell>
                  <Table.HeaderCell>Excluir</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.rodadasCadastradas !== undefined ||
                  this.props.rodadasCadastradas !== []
                  ? this.props.rodadasCadastradas.map((element, index) => {
                    return (
                      <Table.Row
                        style={{
                          color: "rgb(9, 124, 165)",
                          backgroundColor: "rgba(73, 105, 186, 0.02)",
                        }}
                      >

                        <Table.Cell width={3}>
                          {element?.grupo_campeonato?.numero_grupo}
                        </Table.Cell>
                        <Table.Cell width={3}>{element?.numero}</Table.Cell>
                        <Table.Cell width={3}>
                          {element?.partidas?.length}
                        </Table.Cell>

                        <Table.Cell width={4}>
                          <IconButton
                            className="botaoTabble"
                            icon
                            style={{ outline: "none" }}
                            size="mini"
                            onClick={() =>
                              this.props.changeModalListaPartida(
                                element,
                                true
                              )
                            }
                          >
                            {" "}
                            <PlayCircleOutline
                              size="small"
                              name="configure"
                              id={element?.id}
                            />
                          </IconButton>
                        </Table.Cell>
                        <Table.Cell width={5}>
                          <IconButton
                            className="botaoTabble"
                            icon
                            style={{ outline: "none" }}
                            size="mini"
                            onClick={(event) => this.imprimir(event, element)}
                          >
                            <Print />
                          </IconButton>
                        </Table.Cell>
                        <Table.Cell width={5}>
                          <Tooltip title={<h5>Excluir Rodada</h5>}>
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={(e) => this.deleteLista(element)}
                              disabled={element.partidas.length > 0}
                            >
                              <Delete color="error" />
                            </IconButton>
                          </Tooltip>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                  : ""}
              </Table.Body>
            </Table>
          )}

          {this.props.listaTimesOpcoes.length === 1 ? (
            <Col>
              <Col
                sm={12}
                style={{
                  backgroundImage: `url(${Medalha})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "50ch",
                  height: "39ch",
                }}
              >
                <div class="pyro">
                  <div class="before"> </div>
                  <div class="after"></div>
                </div>
              </Col>

              <Col
                className="tituloCampeao"
                align="center"
                style={{
                  // backgroundImage: `url(${Pergaminho})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: "3.5ch",
                  backgroundSize: "15ch",
                }}
              >
                {this.props.listaTimesOpcoes[0].label}
              </Col>
            </Col>
          ) : (
            ""
          )}
        </Segment>
      </div>
    );
  }
}
