import React, { Component } from "react";
import $ from "jquery";
import {
  Container,
  Header,
  Menu,
  Segment,
  Table,
  Image,
  Input,
  Divider,
  Card,
  Label,
} from "semantic-ui-react";
import { Row, Col, Form } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import { Link } from "react-router-dom";
import Select from "react-select";
import moment from "moment-timezone";

import api from "../../../services/api";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Checkbox from "@material-ui/core/Checkbox";

import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import CardContent from "@material-ui/core/CardContent";
import Background from "./banner.png";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import StarIcon from "@material-ui/icons/Star";

import css from "../styles.css";

export default class Competicao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectPessoa: [],
      selectArma: [],
      pessoa_nome: "",
      pessoa_id: null,
      arma_descricao: "",
      arma_id: null,
      lista_atiradores: [],
      alert: true,
      status: false,
      disabledAbrir: false,
      qtdetiros: null,
      checkAcertos: [],
      acertos: [],
      pontuacao: 0,
      infoTiros: [],
      numSerie: 0,
      checkboxAcertos: [],
      retornoPontuacao: 0,
      listaCheckBoxMarcados: [],
      contadorCheck: [],
      quantidadedisparos: 0,
      numeros_de_disparos: 0,
      pontuacao_por_disparo: 0,
      tamanho_lista_disparos: [],
      tiros_cabecalho: 0,
    };

    this.buscaCheckMarcado();
  }

  buscaCheckMarcado = async () => {
    const tiros = await api.post(
      "/etapa/listaTiros" + requisicoes.entidade,
      {
        etapa_id: this.props.location.query.etapa_id,
        evento_id: this.props.location.query.evento_id,
        id: this.props.location.query.id,
      },
      requisicoes.header
    );

    this.setState({
      qtdetiros: tiros.data.etapa.evento.subgrupo.quantidade_tiros,
    });

    //Numero de tiros, utilizado na regra de pontuação ALVO MOVEL
    this.setState({
      numeros_de_disparos: tiros.data.etapa.evento.subgrupo.quantidade_tiros,
    });

    //calcula a quantidade de série baseado nas informações.
    //QUANTIDADE DE TIROS / QUANTIDADE DE SÉRIES, dará quantas series precisará.

    var tirosporserie = parseInt(
      tiros.data.etapa.evento.subgrupo.quantidade_tiros /
        tiros.data.etapa.evento.modalidade.serie
    );

    //Numeros de 0 a té N. onde N é o número da série + 1, para fechar visualmente.
    this.setState({
      tiros_cabecalho: parseInt(tiros.data.etapa.evento.modalidade.serie + 1),
    });

    //quantidade Series já calculadas, define quantas linhas da tabela são.
    this.setState({
      numSerie: tirosporserie,
    });

    this.setState({
      contadorCheck: Array.from({
        length: tirosporserie,
      }).fill(0),
    });

    var listaAux = [];
    var listaCheckSoma = Array.from({
      length: tirosporserie,
    }).fill(0);
    const lista = await api.post(
      "/pontuacao/buscaCheckMarcado" + requisicoes.entidade,
      {
        etapa_id: this.props.location.query.etapa_id,
        evento_id: this.props.location.query.evento_id,
        atirador_id: this.props.location.query.id,
      },
      requisicoes.header
    );
    lista.data.forEach((element, index) => {
      // listaAux.push(element.numero_disparo);
      listaAux[element.numero_disparo] = true;
    });

    this.setState({ checkAcertos: listaAux });

    this.setState({ retornoPontuacao: lista.data.length });

    for (let i = 0; i < listaAux.length; i++) {
      const element = listaAux[i];

      if (element === true) {
        listaCheckSoma[parseInt((i + 1) / this.state.tiros_cabecalho)]++;
      }
    }

    this.setState({ contadorCheck: listaCheckSoma });
  };

  checkverify = async (indice, linha, coluna, listaAcertos, checkAcertos) => {
    let pontuacao = this.state.retornoPontuacao;
    let listaMarcados = checkAcertos;
    let listaCheckVerify = this.state.listaCheckBoxMarcados;

    let contador = [...this.state.contadorCheck];

    if (indice !== undefined && indice !== null) {
      if (
        listaMarcados[indice] === undefined ||
        listaMarcados[indice] !== true
      ) {
        listaMarcados[indice] = true;
        pontuacao = pontuacao + 1;
        contador[linha - 1] = contador[linha - 1] + 1;
        listaCheckVerify.push({ linhaCheck: linha });
      } else {
        listaMarcados[indice] = false;
        pontuacao = pontuacao - 1;
        contador[linha - 1] = contador[linha - 1] - 1;
      }
    }

    this.setState({ listaCheckBoxMarcados: listaCheckVerify });
    this.setState({ contadorCheck: contador });
    this.setState({ retornoPontuacao: pontuacao });
    this.setState({ checkAcertos: listaMarcados });

    // NÃO APAGAR
    // if (indice !== undefined && indice !== null) {
    //   // listaAcertos.forEach((element, index) => {
    //   //   if (element.indice === parseInt(indice)) {
    //   //     edita = true;
    //   //   } else {
    //   //     if (edita !== true) {
    //   //       edita = false;
    //   //     }
    //   //   }
    //   // });
    //   // if (edita === true) {
    //   //   listaAcertos.forEach((element, index) => {
    //   //     if (element.indice === parseInt(indice)) {
    //   //       if (element.status === false) {
    //   //         element.status = true;
    //   //       } else {
    //   //         element.status = false;
    //   //       }
    //   //     }
    //   //   });
    //   // } else {
    //   //   if (indice !== undefined && indice !== null) {
    //   //     lista.push({
    //   //       pontuacao: pontuacao,
    //   //       status: true,
    //   //       indice: parseInt(indice),
    //   //     });
    //   //   }
    // }

    // this.setState({ listaAcertos: lista });

    // listaAcertos.forEach((element, index) => {
    //   if (element.status === true) {
    //     pontuacao = pontuacao + 1;
    //   }
    // });
    // if (indice !== undefined && indice !== null) {
    //   this.setState({ pontuacao: pontuacao });
    // }
    // }
  };

  alteracaoPontuacaoProximidade = async (event, index) => {
    if (event.target.value !== undefined && event.target.value !== "") {
      var valorAtual = 0;
      var lista_disparos = [...this.state.tamanho_lista_disparos];

      lista_disparos[index] = event.target.value;

      for (let a = 0; a < lista_disparos.length; a++) {
        if (lista_disparos[a] !== undefined) {
          valorAtual = valorAtual + parseFloat(lista_disparos[a]);
        }
      }

      this.setState({ pontuacao_por_disparo: valorAtual });
      this.setState({ retornoPontuacao: valorAtual });
      this.setState({ tamanho_lista_disparos: lista_disparos });
    }
  };

  handleSubmit = async () => {
    this.submit();
  };

  submit = async () => {
    var aproveitamento =
      (100 / this.state.qtdetiros) * this.state.retornoPontuacao;
    const tiros = await api.post(
      "/pontuacao/salvar" + requisicoes.entidade,
      {
        quantidade_disparos: this.state.quantidadedisparos,
        atirador_id: this.props.location.query.id,
        pontuacao: this.state.retornoPontuacao,
        etapa_id: this.props.location.query.etapa_id,
        evento_id: this.props.location.query.evento_id,
        checkAcertos: this.state.checkAcertos,
        aproveitamento: aproveitamento,
      },
      requisicoes.header
    );
    console.log(aproveitamento);

    this.setState({ alert: false });

    setTimeout(() => {
      $("#voltar").click();
    }, 1500);
  };

  alteracao = async (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  render() {
    let indice = 0;
    return (
      <Container className="paginaSemPaddingTop">
        <Form>
          <Segment>
            <Header as="h5" dividing>
              <Alert severity="success" hidden={this.state.alert}>
                <AlertTitle>Pontuação Salva!</AlertTitle>
                Iremos redirecionar-lhe — <strong>Aguarde!</strong>
              </Alert>
              <div className="flexEnd">
                <div>
                  <Link
                    to={{
                      pathname: "/eventos/lancamento/listaAtiradores",
                      query: {
                        etapa_id: this.props.location.query.etapa_id,
                        evento_id: this.props.location.query.evento_id,
                        numero_etapa: this.props.location.query.numero_etapa,
                        pedana: this.props.location.query.pedana,
                        status: this.props.location.query.status,
                        regra_pontuacao:
                          this.props.location.query.regra_pontuacao,
                      },
                    }}
                  >
                    <Button
                      style={{ outline: "none" }}
                      icon
                      size="mini"
                      id="voltar"
                      color="primary"
                    >
                      Voltar
                    </Button>
                  </Link>
                </div>
              </div>
            </Header>

            <Col sm={12}>
              <Row>
                <Card hidden={this.state.alert === false}>
                  <CardContent className="imagePontuacao">
                    <Typography color="rgba(232, 218, 192, 0.994)">
                      {this.props.location.query.nome +
                        ": " +
                        " Acertos " +
                        this.state.retornoPontuacao}
                    </Typography>
                  </CardContent>
                </Card>
              </Row>
              <Row>
                <Table
                  striped
                  selectable
                  hidden={
                    this.props.location.query.regra_pontuacao !== "alvomovel"
                  }
                >
                  <Table.Header>
                    <Table.Row>
                      {Array.from({
                        length: this.state.tiros_cabecalho,
                      }).map((e, i) => {
                        return (
                          <Table.HeaderCell>
                            {i === 0 ? "" : i}
                          </Table.HeaderCell>
                        );
                      })}
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {Array.from({ length: this.state.numSerie }).map(
                      (element, linha) => {
                        return (
                          <Table.Row key={linha + 1}>
                            <Table.Cell
                              textAlign="center"
                              verticalAlign="middle"
                            >
                              {" "}
                              {linha + 1 + "º"}
                            </Table.Cell>

                            {Array.from({
                              length: this.state.tiros_cabecalho - 1,
                            }).map((e, coluna) => {
                              return (
                                <Table.Cell>
                                  <div hidden>{(indice = indice + 1)}</div>
                                  {
                                    <Checkbox
                                      checked={
                                        //   this.state.checkboxAcertos.includes(
                                        //   indice
                                        // )
                                        this.state.checkAcertos[indice] === true
                                      }
                                      root
                                      value={indice}
                                      className="checkBoxPontuacao"
                                      id={coluna + 1}
                                      onChange={(e) =>
                                        this.checkverify(
                                          e.target.value,
                                          linha + 1,
                                          coluna + 1,
                                          this.state.acertos,
                                          this.state.checkAcertos
                                        )
                                      }
                                    />
                                  }
                                </Table.Cell>
                              );
                            })}
                            <Table.Cell>
                              {" "}
                              {this.state.contadorCheck[linha]}
                            </Table.Cell>
                          </Table.Row>
                        );
                      }
                    )}
                  </Table.Body>
                </Table>

                <Table
                  striped
                  selectable
                  hidden={
                    this.props.location.query.regra_pontuacao !== "proximidade"
                  }
                >
                  <Table.Body>
                    <Table.Row>
                      {Array.from({
                        length: this.state.numeros_de_disparos,
                      }).map((element, index) => {
                        return (
                          <Input
                            type="number"
                            id="pontuacao_por_disparo"
                            name="pontuacao_por_disparo"
                            className="inputPontuacao"
                            onBlur={(event) =>
                              this.alteracaoPontuacaoProximidade(event, index)
                            }
                          />
                        );
                      })}
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Row>
            </Col>
            <div className="quantidadeTirosPontuacao">
              <div>
                <TextField
                  id="quantidadedisparos"
                  name="quantidadedisparos"
                  label="Quantidade de Disparos"
                  onChange={this.alteracao}
                  required
                />
              </div>
            </div>
          </Segment>
          <Segment clearing>
            <Header floated="right">
              <Button
                type="button"
                style={{ outline: "none" }}
                color="primary"
                onClick={this.submit}
              >
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>
      </Container>
    );
  }
}
