import React, { Component } from "react";
import {
  Header,
  Table,
  Modal,
  Menu,
  Icon,
  Button,
  Segment,
} from "semantic-ui-react";
import { AiOutlineUnorderedList } from "react-icons/ai";
import api from "../../../services/api";
import { parseISO, format } from "date-fns";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import TipoLancamento from "../../../enum/TipoLancamento";
import requisicoes from "../../../utils/Requisicoes";

export default class CaixaLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caixas: [],
      caixa: null,
      open: false,
      lancamento_contas: [],
      pagina: 1,
      totalPaginas: 1,
      saldo_total: null,
    };
    this.mostraLancamentos = this.mostraLancamentos.bind(this);
    this.lista();
  }

  lista = async (page = 1) => {
    const retorno = await api.post(
      "/caixa/consultaListaCaixaPorUsuarioETipo" +
        requisicoes.entidade +
        "&pagina=" +
        page,
      { tipo: "caixa" },
      requisicoes.header
    );
    if (retorno.data !== "") {
      let total = 0;
      let { caixas, pagina, totalPaginas } = retorno.data;
      caixas.forEach((e) => {
        e.lancamento_contas.forEach((e2) => {
          if (e2.credito_debito === "credito") {
            total = total + parseFloat(e2.valor);
          } else {
            total = total - parseFloat(e2.valor);
          }
        });
        e.total = total;
        total = 0;
      });
      this.setState({ caixas, pagina, totalPaginas });
    }
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleShow = () => this.setState({ open: true });

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  mostraLancamentos = async (event) => {
    const lancamentos = this.state.caixas.find(
      (e) =>
        parseInt(e.id) === parseInt(event.target.id.toString().substring(1, 5))
    );
    if (
      lancamentos !== undefined &&
      lancamentos.lancamento_contas !== undefined
    ) {
      this.setState({ caixa: lancamentos });
      let ids = [];
      lancamentos.lancamento_contas.forEach((e) => {
        ids.push(e.id);
      });
      const retorno = await api.post(
        "/lancamentoConta/ordenaPorHoraBaixa" + requisicoes.entidade,
        { ids: ids },
        requisicoes.header
      );
      let total = 0;
      if (retorno.data !== "") {
        this.setState({ caixa: lancamentos });
        retorno.data.forEach((e) => {
          if (e.forma_pagamento !== "depositoBancario") {
            if (e.credito_debito === "credito") {
              if (e.valor_pago !== null) {
                total = total + parseFloat(e.valor_pago);
              } else {
                total = total + parseFloat(e.valor);
              }
            } else {
              if (e.valor_pago !== null) {
                total = total - parseFloat(e.valor_pago);
              } else {
                total = total - parseFloat(e.valor);
              }
            }
          }
        });
      }
      this.setState({ saldo_total: total });
      this.setState({ lancamento_contas: retorno.data });
      this.handleShow();
    }
  };

  proximaPagina = async () => {
    const { pagina, totalPaginas } = this.state;

    if (pagina >= totalPaginas) return;
    const paginaNumero = parseInt(pagina) + 1;
    this.lista(paginaNumero);
  };

  voltarPagina = async () => {
    const { pagina } = this.state;

    if (pagina <= 1) return;

    const paginaNumero = pagina - 1;

    this.lista(paginaNumero);
  };

  gerarRelatorio = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    // const usuarioLogado = await api.post("/usuario/usuarioLogado?e=" + entidade, {}, {
    //   headers: { token: "Baer " + token },
    // });
    // const nomePessoa = await api.post("/pessoa/pessoaIdNome?e=" + entidade, { id: usuarioLogado.data.pessoa_id }, {
    //   headers: { token: "Baer " + token },
    // });
    const usuarioCaixa = await api.post(
      "/usuario/visualiza?e=" + entidade,
      { id: this.state.caixa.usuario_id },
      requisicoes.header
    );
    const nomeCaixa = await api.post(
      "/pessoa/pessoaIdNome?e=" + entidade,
      { id: usuarioCaixa.data.pessoa_id },
      requisicoes.header
    );
    await api.post(
      "/caixa/relatorio?e=" + entidade,
      { id: this.state.caixa.id, nome_operador: nomeCaixa.data.nome },
      {
        headers: { token: "Baer " + token },
      }
    );
    const r = await api.get(
      "/caixa/arquivoRelatorio?e=" + entidade + "&t=Baer " + token
    );
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "caixa/arquivoRelatorio?e=" +
      entidade +
      "&t=Baer " +
      token;

    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Caixas
        </Header>

        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Data Abertura</Table.HeaderCell>
              <Table.HeaderCell>Data Fechamento</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.caixas.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>
                  {format(parseISO(e.data_abertura), "dd/MM/yyyy")}
                </Table.Cell>
                <Table.Cell>
                  {e.data_fechamento !== null &&
                    format(parseISO(e.data_fechamento), "dd/MM/yyyy")}
                </Table.Cell>
                <Table.Cell>
                  {parseFloat(e.total).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Table.Cell>
                <Table.Cell>
                  <IconContext.Provider value={{ color: "grey", size: "30px" }}>
                    <div>
                      <AiOutlineUnorderedList
                        id={"l" + e.id}
                        onClick={this.mostraLancamentos}
                      />
                    </div>
                  </IconContext.Provider>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan={4}>
                <Menu pagination>
                  <Menu.Item
                    icon
                    onClick={this.voltarPagina}
                    disabled={this.state.pagina === 1}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  <Menu.Item
                    icon
                    onClick={this.proximaPagina}
                    disabled={this.state.pagina === this.state.totalPaginas}
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>

        <Segment clearing>
          <Header floated="right">
            <Link to="/caixa">
              <Button type="button">Voltar</Button>
            </Link>
          </Header>
        </Segment>

        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          centered={false}
        >
          <Header content="Lancamentos" />
          <Modal.Content>
            <Button primary onClick={this.gerarRelatorio}>
              Imprimir
            </Button>
            {this.state.lancamento_contas !== null && (
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Data Pagamento</Table.HeaderCell>
                    <Table.HeaderCell>Pessoa</Table.HeaderCell>
                    <Table.HeaderCell>Referência</Table.HeaderCell>
                    <Table.HeaderCell>Descrição</Table.HeaderCell>
                    <Table.HeaderCell>Hora</Table.HeaderCell>
                    <Table.HeaderCell>Valor Pago</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.lancamento_contas !== null &&
                    this.state.lancamento_contas.length !== 0 &&
                    this.state.lancamento_contas.map((e, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>
                          {e.data_pagamento !== null &&
                            format(parseISO(e.data_pagamento), "dd/MM/yyyy")}
                        </Table.Cell>
                        <Table.Cell>
                          {e.pessoa !== null && e.pessoa.nome}
                        </Table.Cell>
                        {e.tipo_lancamento === "cobrancaServicoAvulsa" ? (
                          <Table.Cell>Vendas</Table.Cell>
                        ) : (
                          <Table.Cell>
                            {(e.mes_referencia === null
                              ? ""
                              : e.mes_referencia.toString().substring(0, 3) +
                                "/") +
                              (e.ano_referencia === null
                                ? ""
                                : e.ano_referencia)}
                          </Table.Cell>
                        )}
                        {e.tipo_lancamento === "cobrancaServicoAvulsa" ? (
                          <Table.Cell>
                            {e.servico !== null && e.servico.nome}
                          </Table.Cell>
                        ) : (
                          <Table.Cell>
                            {e.descricao !== null &&
                              (e.descricao.toString().length > 20 ||
                              e.tipo_lancamento === "sangria"
                                ? TipoLancamento.find(
                                    (t) => t.value === e.tipo_lancamento
                                  ) !== undefined &&
                                  TipoLancamento.find(
                                    (t2) => t2.value === e.tipo_lancamento
                                  ).label
                                : e.descricao)}
                          </Table.Cell>
                        )}
                        <Table.Cell>
                          {e.hora_baixa !== null &&
                            e.hora_baixa.toString().substring(0, 5)}
                        </Table.Cell>
                        <Table.Cell>
                          {e.valor_pago !== null
                            ? parseFloat(e.valor_pago).toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : parseFloat(e.valor).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            )}
            <Col sm={2} style={{ float: "right", marginBottom: "10px" }}>
              Saldo:&nbsp;&nbsp;{" "}
              {parseFloat(this.state.saldo_total).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Col>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
