import React, { Component } from "react";
import Select from "react-select";

import {
  Table,
  Button,
  Icon,
  Modal,
  Dropdown,
  Pagination,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";
import { parseISO, format } from "date-fns";
import renomearChave from "../../../services/renomearChave";

import { Row, Col, Form, FormControl } from "react-bootstrap";
import $ from "jquery";
import "jquery-mask-plugin/dist/jquery.mask.min";
import Checkbox from "muicss/lib/react/checkbox";
// import { Dimmer, Loader, Segment } from "semantic-ui-react";
import moment from "moment-timezone";

var formatter = new Intl.NumberFormat("pt-br", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export default class ContasAPagarLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaContasAPagar: [],
      pessoa: null,
      pessoa_id: null,
      tipo: "emissao",
      valor: null,
      selectedCheckboxes: [],
      loading: false,
      open: false,
      id: null,
      ordem_pagamento: null,
      tipos: [
        { key: "codigo", value: "codigo", text: "Código" },
        { key: "cnpj", value: "cnpj", text: "Cnpj" },
        { key: "cpf", value: "cpf", text: "Cpf" },
        { key: "documento", value: "documento", text: "Documento" },
        { key: "emissao", value: "emissao", text: "Emissao" },
        { key: "nome", value: "nome", text: "Fornecedor" },
        {
          key: "ordem_pagamento",
          value: "ordem_pagamento",
          text: "Ordem Pagamento",
        },
        { key: "valor", value: "valor", text: "Valor" },
        { key: "vencimento", value: "vencimento", text: "Vencimento" },
      ],
      data_inicio: moment().startOf("month").format("YYYY-MM-DD"),
      data_fim: moment().format("YYYY-MM-DD"),
      cancelamento: null,
      documento: null,
      pesquisando: false,
      allcheck: false,
      parcelaPaga: false,
      checkboxselecionado: false,
      tipoimpressao: "todas",
      sorted: {
        name: "emissao",
        direction: "descending",
      },
      totalPages: 0,
      paginaAtual: 1,
    };
    // this.lista();
    this.buscaContasPorDataEmissao(undefined, undefined, undefined, 1);
    this.valorRestante = this.valorRestante.bind(this);
  }

  marcartodas = () => {
    if (this.state.allcheck === false) {
      this.setState({ allcheck: true, checkboxselecionado: true });
    } else {
      this.setState({ allcheck: false, checkboxselecionado: false });
    }
    if (this.state.allcheck === false) {
      this.state.listaContasAPagar.map((e) => {
        e.selecionado = true;
      });
    } else {
      this.state.listaContasAPagar.map((e) => {
        e.selecionado = false;
      });
    }
  };

  toggleCheckbox = (event) => {
    let lista = this.state.listaContasAPagar;
    lista.map((e, i) => {
      if (parseInt(e.id) === parseInt(event)) {
        if (e.selecionado === true) {
          e.selecionado = false;
          this.setState({ allcheck: false });
        } else {
          e.selecionado = true;
        }
      }
    });
    for (let i = 0; i < lista.length; i++) {
      const element = lista[i];
      if (element.selecionado) {
        this.setState({ checkboxselecionado: true });
        break;
      } else {
        this.setState({ checkboxselecionado: false });
      }
    }
    this.setState({ listaContasAPagar: lista });
  };

  close = () => this.setState({ open: false });

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ listaContasAPagar: [] });
  };

  handleChange = (e, { value }) => {
    if (value === "emissao") {
      document.location.reload(true);
      this.setState({ tipo: value, options: "a" });
    }
    this.setState({ tipo: value });
  };

  handleClose = () => this.setState({ open: false });

  handleShow = async (event) => {
    this.setState({ id: event.target.id });
    await api
      .post(
        "/contasAPagar/visualiza" + requisicoes.entidade,
        { id: event.target.id },
        requisicoes.header
      )
      .then((resp) => {
        this.setState({ cancelamento: resp.data });
        this.setState({ open: true });
      });
  };

  lista = async (page = 1) => {
    await api
      .post(
        "/contasAPagar/lista" + requisicoes.entidade + "&pagina=" + page,
        "",
        requisicoes.header
      )
      .then((resp) => {
        const { contas, pagina, totalPaginas } = resp.data;
        this.setState({ listaContasAPagar: contas, pagina, totalPaginas });
      });
  };
  componentCnpj = async () => {
    $("#pesquisaCnpj").mask("00.000.000/0000-00");
  };

  excluir = async (event) => {
    await api
      .post(
        "/contasAPagar/excluir" + requisicoes.entidade + "&id=" + this.state.id,
        "",
        requisicoes.header
      )
      .then((resp) => {
        localStorage.setItem("pagina_anterior", "contasAPagar");
        window.location.replace("/financeiro");
      });
  };

  cancelar = async (event) => {
    await api
      .post(
        "/contasAPagar/cancelar" +
          requisicoes.entidade +
          "&id=" +
          this.state.id +
          "&dataCancelamento=" +
          moment().format("yyyy-MM-DD"),
        "",
        requisicoes.header
      )
      .then((resp) => {
        localStorage.setItem("pagina_anterior", "contasAPagar");
        window.location.replace("/financeiro");
      });
  };

  pesquisaPessoaReduzido = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/pessoa/pesquisaReduzidoFornecedor" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaPessoaResuzido").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        if (
          !isNaN(
            parseFloat(document.getElementById("pesquisaPessoaResuzido").value)
          ) &&
          isFinite(document.getElementById("pesquisaPessoaResuzido").value)
        ) {
          if (element.reduzido !== null) {
            let objeto = renomearChave(element, "id", "value");
            objeto = renomearChave(objeto, "reduzido", "label");
            objeto.label = objeto.label + " - " + objeto.nome;
            lista.push(objeto);
          }
        }
      });
      this.setState({ options: lista });
    }, 500);
  };

  pesquisaPessoa = async () => {
    setTimeout(async () => {
      console.log("aqui 2 :");
      const retorno = await api.post(
        "/pessoa/pesquisaNomeOuDoc" + requisicoes.entidade,
        {
          pesquisa: document.getElementById("pesquisaPessoa").value,
          fornecedor: true,
        },
        requisicoes.header
      );
      let lista = [];

      for (let index = 0; index < retorno.data.length; index++) {
        if (
          !isNaN(parseFloat(document.getElementById("pesquisaPessoa").value)) &&
          isFinite(document.getElementById("pesquisaPessoa").value)
        ) {
          if (retorno.data[index].cpf !== null) {
            lista.push({
              label: retorno.data[index].cpf,
              value: retorno.data[index].id,
            });
          }
          if (retorno.data[index].cnpj !== null) {
            lista.push({
              label: retorno.data[index].cnpj,
              value: retorno.data[index].id,
            });
          }
        } else {
          lista.push({
            label: retorno.data[index].ativo
              ? retorno.data[index].reduzido + " - " + retorno.data[index].nome
              : retorno.data[index].reduzido +
                " - " +
                retorno.data[index].nome +
                " (Inativo)",
            value: retorno.data[index].id,
          });
          lista.isDisable = retorno.data[index].ativo === true;
        }
      }

      this.setState({ options: lista });
    }, 500);
  };

  alteracaoSelectPessoa = async (event, a, sort, paginaAtual) => {
    if (event.id === undefined) {
      this.setState({ pessoa_id: event.value });
      this.setState({ pessoa: event });
    }
    if (paginaAtual === undefined) {
      paginaAtual = 1;
    } else {
      this.setState({ paginaAtual: paginaAtual });
    }

    var retornoLista = await api.post(
      "/contasAPagar/pesquisaPessoaID" + requisicoes.entidade,
      {
        pessoa_id: event.value,
        sorted: sort !== undefined ? sort : this.state.sorted,
        paginaAtual: paginaAtual,
        fornecedor: true,
      },
      requisicoes.header
    );
    for (let i = 0; i < retornoLista.data.docs.length; i++) {
      var flag = false;
      const element1 = retornoLista.data.docs[i];
      for (let j = 0; j < element1.lancamento_contas.length; j++) {
        const element2 = element1.lancamento_contas[j];
        if (element2.data_pagamento !== null) {
          flag = true;
        }
      }
      element1.temLiquidada = flag;
    }
    this.setState({
      listaContasAPagar: retornoLista.data.docs,
      totalPages: retornoLista.data.totalPaginas,
    });
  };

  pesquisaPorCpf = async (event) => {
    var retorno = await api.post(
      "/pessoa/buscarCpf" + requisicoes.entidade,
      { cpf: event.target.value },
      requisicoes.header
    );

    for (let i = 0; i < retorno.data.length; i++) {
      var flag = false;
      const element1 = retorno.data[i];
      // for (let j = 0; j < element1.lancamento_contas.length; j++) {
      //   const element2 = element1.lancamento_contas[j];
      //   if (element2.data_pagamento !== null){
      //     flag = true;
      //   }
      // }
      element1.temLiquidada = flag;
    }

    var lista = [];
    retorno.data.forEach((element) => {
      if (element.cpf !== null) {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "cpf", "label");
        objeto.label += " " + element.nome;
        lista.push(objeto);
      }
    });

    this.setState({ options: lista });
  };
  pesquisaPorCnpj = async (event) => {
    var retorno = await api.post(
      "/pessoa/buscarCnpj" + requisicoes.entidade,
      {
        cnpj: event.target.value,
      },
      requisicoes.header
    );

    if (retorno.data !== undefined)
      for (let i = 0; i < retorno.data.length; i++) {
        var flag = false;
        const element1 = retorno.data[i];
        // for (let j = 0; j < element1.lancamento_contas.length; j++) {
        //   const element2 = element1.lancamento_contas[j];
        //   if (element2.data_pagamento !== null){
        //     flag = true;
        //   }
        // }
        element1.temLiquidada = flag;
      }

    var lista = [];
    retorno.data.forEach(async (element) => {
      if (element.cnpj !== null) {
        let objeto = renomearChave(element, "id", "value");

        objeto = renomearChave(objeto, "cnpj", "label");

        objeto.label += " " + element.nome;

        lista.push(objeto);
      }
    });

    this.setState({ options: lista });
  };

  componentMoney() {
    $(".dinheiro").mask("#.##0,00", {
      reverse: true,
      placeholder: "0,00",
    });
  }

  //Buscas
  buscaContasAPagar = async (event, a, sort, paginaAtual) => {
    if (paginaAtual === undefined) {
      paginaAtual = 1;
    } else {
      this.setState({ paginaAtual: paginaAtual });
    }
    let valor = this.state.valor.replace(".", "");
    valor = valor.replace(",", ".");
    var retornoLista = await api.post(
      "/contasAPagar/pesquisaValor" + requisicoes.entidade,
      {
        valor: valor,
        sorted: sort !== undefined ? sort : this.state.sorted,
        paginaAtual: paginaAtual,
      },
      requisicoes.header
    );
    retornoLista.data.docs.forEach(async (a) => {
      a.selecionado = false;
    });
    for (let i = 0; i < retornoLista.data.docs.length; i++) {
      var flag = false;
      const element1 = retornoLista.data.docs[i];
      for (let j = 0; j < element1.lancamento_contas.length; j++) {
        const element2 = element1.lancamento_contas[j];
        if (element2.data_pagamento !== null) {
          flag = true;
        }
      }
      element1.temLiquidada = flag;
    }
    this.setState({
      listaContasAPagar: retornoLista.data.docs,
      totalPages: retornoLista.data.totalPaginas,
    });
  };

  buscaContasPorOP = async (event, a, sort, paginaAtual) => {
    if (paginaAtual === undefined) {
      paginaAtual = 1;
    } else {
      this.setState({ paginaAtual: paginaAtual });
    }
    var retornoLista = await api.post(
      "/contasAPagar/pesquisaOrdemPagamento" + requisicoes.entidade,
      {
        ordem_pagamento: this.state.ordem_pagamento,
        sorted: sort !== undefined ? sort : this.state.sorted,
        paginaAtual: paginaAtual,
      },
      requisicoes.header
    );
    for (let i = 0; i < retornoLista.data.docs.length; i++) {
      var flag = false;
      const element1 = retornoLista.data.docs[i];
      for (let j = 0; j < element1.lancamento_contas.length; j++) {
        const element2 = element1.lancamento_contas[j];
        if (element2.data_pagamento !== null) {
          flag = true;
        }
      }
      element1.temLiquidada = flag;
    }
    this.setState({
      listaContasAPagar: retornoLista.data.docs,
      totalPages: retornoLista.data.totalPaginas,
    });
  };

  buscaContasPorDataVencimento = async (event, a, sort, paginaAtual) => {
    if (paginaAtual === undefined) {
      paginaAtual = 1;
    } else {
      this.setState({ paginaAtual: paginaAtual });
    }
    const retornoLista = await api.post(
      "/contasAPagar/pesquisaData" + requisicoes.entidade,
      {
        data_inicio: this.state.data_inicio,
        data_fim: this.state.data_fim,
        sorted: sort !== undefined ? sort : this.state.sorted,
        paginaAtual: paginaAtual,
      },
      requisicoes.header
    );
    for (let i = 0; i < retornoLista.data.docs.length; i++) {
      var flag = false;
      const element1 = retornoLista.data.docs[i];
      for (let j = 0; j < element1.lancamento_contas.length; j++) {
        const element2 = element1.lancamento_contas[j];
        if (element2.data_pagamento !== null) {
          flag = true;
        }
      }
      element1.temLiquidada = flag;
    }
    this.setState({
      listaContasAPagar: retornoLista.data.docs,
      totalPages: retornoLista.data.totalPaginas,
    });
  };

  buscaContasPorDataEmissao = async (event, a, sort, paginaAtual) => {
    if (paginaAtual === undefined) {
      paginaAtual = 1;
    } else {
      this.setState({ paginaAtual: paginaAtual });
    }
    const retornoLista = await api.post(
      "/contasAPagar/pesquisaDataEmissao" + requisicoes.entidade,
      {
        data_inicio: this.state.data_inicio,
        data_fim: this.state.data_fim,
        tipoimpressao: this.state.tipoimpressao,
        sorted: sort !== undefined ? sort : this.state.sorted,
        paginaAtual: paginaAtual,
      },
      requisicoes.header
    );

    for (let i = 0; i < retornoLista.data.docs.length; i++) {
      var flag = false;
      const element1 = retornoLista.data.docs[i];
      for (let j = 0; j < element1.lancamento_contas.length; j++) {
        const element2 = element1.lancamento_contas[j];
        if (element2.data_pagamento !== null) {
          flag = true;
        }
      }
      element1.temLiquidada = flag;
    }
    this.setState({
      listaContasAPagar: retornoLista.data.docs,
      totalPages: retornoLista.data.totalPaginas,
    });
  };

  buscaPorDocumento = async (event, a, sort, paginaAtual) => {
    if (paginaAtual === undefined) {
      paginaAtual = 1;
    } else {
      this.setState({ paginaAtual: paginaAtual });
    }
    const retornoLista = await api.post(
      "/contasAPagar/pesquisaDocumento" + requisicoes.entidade,
      {
        documento: this.state.documento,
        sorted: sort !== undefined ? sort : this.state.sorted,
        paginaAtual: paginaAtual,
      },
      requisicoes.header
    );
    for (let i = 0; i < retornoLista.data.docs.length; i++) {
      var flag = false;
      const element1 = retornoLista.data.docs[i];
      for (let j = 0; j < element1.lancamento_contas.length; j++) {
        const element2 = element1.lancamento_contas[j];
        if (element2.data_pagamento !== null) {
          flag = true;
        }
      }
      element1.temLiquidada = flag;
    }
    this.setState({
      listaContasAPagar: retornoLista.data.docs,
      totalPages: retornoLista.data.totalPaginas,
    });
  };

  //Fim buscas
  voltarPagina = async () => {
    const { pagina } = this.state;
    if (pagina <= 1) return;
    const paginaNumero = pagina - 1;
    this.lista(paginaNumero);
  };

  proximaPagina = async () => {
    const { pagina, totalPaginas } = this.state;
    if (pagina >= totalPaginas) return;
    const paginaNumero = parseInt(pagina) + 1;
    this.lista(paginaNumero);
  };

  valorRestante = async (event) => {
    this.setState({ valor: event.target.value });
  };

  gerarPdfOrdemPagamento = async (id, event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    const r = await api.get(
      "contasAPagar/buscar?e=" + entidade + "&t=Baer " + token + "&id=" + id
    );
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "contasAPagar/buscar?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&id=" +
      id;

    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();

    setTimeout(() => {
      // window.location.reload();
    }, 4000);
  };
  gerarOrdemPagamento = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    let indice = 0;
    let lista = [];
    this.state.listaContasAPagar.forEach((e) => {
      if (e.selecionado !== undefined) {
        if (e.selecionado === true) {
          lista.push(e.id);
        }
      }
    });

    this.setState({ loading: true });

    setTimeout(async () => {
      await api.post("/contasAPagar/gerar?e=" + entidade, lista, {
        headers: { token: "Baer " + token },
      });
      this.gerarPdfOrdemPagamento();
      this.setState({ loading: false });
    }, 1000);
  };

  changeSort = (column) => {
    let sort = this.state.sorted;
    if (sort.name === column) {
      sort.direction =
        sort.direction === "ascending" ? "descending" : "ascending";
    } else {
      sort = {
        name: column,
        direction: "descending",
      };
    }
    this.setState({ sorted: sort });

    if (this.state.tipo === "codigo") {
      this.alteracaoSelectPessoa(
        { value: this.state.pessoa_id },
        undefined,
        sort,
        this.state.paginaAtual
      );
    }
    if (this.state.tipo === "cnpj") {
      this.alteracaoSelectPessoa(
        { value: this.state.pessoa_id },
        undefined,
        sort,
        this.state.paginaAtual
      );
    }
    if (this.state.tipo === "cpf") {
      this.alteracaoSelectPessoa(
        { value: this.state.pessoa_id },
        undefined,
        sort,
        this.state.paginaAtual
      );
    }
    if (this.state.tipo === "documento") {
      this.buscaPorDocumento(
        undefined,
        undefined,
        sort,
        this.state.paginaAtual
      );
    }
    if (this.state.tipo === "emissao") {
      this.buscaContasPorDataEmissao(
        undefined,
        undefined,
        sort,
        this.state.paginaAtual
      );
    }
    if (this.state.tipo === "nome") {
      this.alteracaoSelectPessoa(
        { value: this.state.pessoa_id },
        undefined,
        sort,
        this.state.paginaAtual
      );
    }
    if (this.state.tipo === "ordem_pagamento") {
      this.buscaContasPorOP(undefined, undefined, sort, this.state.paginaAtual);
    }
    if (this.state.tipo === "valor") {
      this.buscaContasAPagar(
        undefined,
        undefined,
        sort,
        this.state.paginaAtual
      );
    }
    if (this.state.tipo === "vencimento") {
      this.buscaContasPorDataVencimento(
        undefined,
        undefined,
        sort,
        this.state.paginaAtual
      );
    }
  };

  pageChange = (event, a) => {
    //TODO: Fazer uma alteração pra cada tipo
    this.setState({ checkboxselecionado: false });

    this.setState({ paginaAtual: a.activePage });

    if (this.state.tipo === "codigo") {
      this.alteracaoSelectPessoa(
        { value: this.state.pessoa_id },
        undefined,
        this.state.sorted,
        a.activePage
      );
    }
    if (this.state.tipo === "cnpj") {
      this.alteracaoSelectPessoa(
        { value: this.state.pessoa_id },
        undefined,
        this.state.sorted,
        a.activePage
      );
    }
    if (this.state.tipo === "cpf") {
      this.alteracaoSelectPessoa(
        { value: this.state.pessoa_id },
        undefined,
        this.state.sorted,
        a.activePage
      );
    }
    if (this.state.tipo === "documento") {
      this.buscaPorDocumento(
        undefined,
        undefined,
        this.state.sorted,
        a.activePage
      );
    }
    if (this.state.tipo === "emissao") {
      //ok
      this.buscaContasPorDataEmissao(
        undefined,
        undefined,
        this.state.sorted,
        a.activePage
      );
    }
    if (this.state.tipo === "nome") {
      this.alteracaoSelectPessoa(
        { value: this.state.pessoa_id },
        undefined,
        this.state.sorted,
        a.activePage
      );
    }
    if (this.state.tipo === "ordem_pagamento") {
      this.buscaContasPorOP(
        undefined,
        undefined,
        this.state.sorted,
        a.activePage
      );
    }
    if (this.state.tipo === "valor") {
      this.buscaContasAPagar(
        undefined,
        undefined,
        this.state.sorted,
        a.activePage
      );
    }
    if (this.state.tipo === "vencimento") {
      this.buscaContasPorDataVencimento(
        undefined,
        undefined,
        this.state.sorted,
        a.activePage
      );
    }
  };

  render() {
    return (
      <div>
        <Row>
          <Col sm={2}>
            <Form.Label>Tipo</Form.Label>
            <Dropdown
              placeholder="Tipo"
              id="tipo"
              name="tipo"
              fluid
              search
              selection
              options={this.state.tipos}
              onChange={this.handleChange}
              value={this.state.tipo}
            />
          </Col>
          <Col sm={6} hidden={this.state.tipo !== "nome"}>
            <Form.Label>Consulta:</Form.Label>
            <Select
              placeholder="Digite para buscar"
              name="pessoa"
              onKeyDown={this.pesquisaPessoa}
              onChange={this.alteracaoSelectPessoa}
              inputId="pesquisaPessoa"
              options={this.state.options}
            />
          </Col>
          <Col sm={6} hidden={this.state.tipo !== "codigo"}>
            <Form.Label>Consulta:</Form.Label>
            <Select
              placeholder="Digite para buscar"
              name="pessoa"
              onKeyDown={this.pesquisaPessoaReduzido}
              onChange={this.alteracaoSelectPessoa}
              inputId="pesquisaPessoaResuzido"
              options={this.state.options}
            />
          </Col>
          <Col sm={6} hidden={this.state.tipo !== "cpf"}>
            <Form.Label>Consulta:</Form.Label>
            <Select
              placeholder="Digite para buscar"
              name="cpf_busca"
              onKeyDown={this.pesquisaPorCpf}
              onChange={this.alteracaoSelectPessoa}
              inputId="pesquisaCpf"
              options={this.state.options}
            />
          </Col>
          <Col sm={6} hidden={this.state.tipo !== "cnpj"}>
            <Form.Label>Consulta:</Form.Label>
            <Select
              placeholder="Digite para buscar"
              name="cnpj_busca"
              onKeyDown={this.pesquisaPorCnpj}
              onChange={this.alteracaoSelectPessoa}
              inputId="pesquisaCnpj"
              onKeyUp={this.componentCnpj}
              className="cnpj_mask"
              options={this.state.options}
            />
          </Col>

          {this.state.tipo === "valor" && (
            <Col sm={6}>
              <Row>
                <Col sm={8}>
                  <Form.Label>Valor</Form.Label>
                  <FormControl
                    placeholder="Valor"
                    aria-describedby="basic-addon1"
                    name="valor"
                    id="valor"
                    className="dinheiro"
                    required
                    onKeyUp={this.componentMoney}
                    onChange={this.alteracao}
                    onBlur={this.valorRestante}
                  />
                </Col>
                <Col sm={2}>
                  <Form.Label></Form.Label>
                  <Button
                    positive
                    onClick={this.buscaContasAPagar}
                    style={{ marginTop: "5px" }}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Col>
          )}
          <Col sm={4} hidden={this.state.tipo !== "ordem_pagamento"}>
            <Form.Label>Ordem Pagamento</Form.Label>
            <FormControl
              placeholder="nº"
              aria-describedby="basic-addon1"
              name="ordem_pagamento"
              id="ordem_pagamento"
              required
              onChange={this.alteracao}
            />
          </Col>
          <Col
            sm={2}
            hidden={this.state.tipo !== "ordem_pagamento"}
            style={{ marginTop: "2%" }}
          >
            <Button positive onClick={this.buscaContasPorOP}>
              Buscar
            </Button>
          </Col>

          <Col sm={10} hidden={this.state.tipo !== "emissao"}>
            <Row>
              <Col md="auto">
                <Form.Label>Período Inicial:</Form.Label>
                <FormControl
                  type="date"
                  name="data_inicio"
                  onChange={this.alteracao}
                  defaultValue={this.state.data_inicio.toString().substr(0, 10)}
                />
              </Col>
              <Col md="auto">
                <Form.Label>Período Final:</Form.Label>
                <FormControl
                  type="date"
                  name="data_fim"
                  onChange={this.alteracao}
                  value={this.state.data_fim}
                />
              </Col>
              <Col sm={2}>
                <Form.Label>Tipo</Form.Label>
                <Form.Control
                  placeholder="Tipo"
                  label="Tipo"
                  id="tipo"
                  as="select"
                  name="tipoimpressao"
                  onChange={this.alteracao}
                  defaultValue="todas"
                  required
                >
                  <option></option>
                  <option value="todas">Todas</option>
                  <option value="impressa">Impressas</option>
                  <option value="naoimpressa">Não Impressa</option>
                </Form.Control>
              </Col>
              <Col
                md="auto"
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <Row>
                  <Col>
                    <Button positive onClick={this.buscaContasPorDataEmissao}>
                      Buscar
                    </Button>
                    {this.state.tipo === "emissao" && (
                      <Button
                        primary
                        onClick={this.gerarOrdemPagamento}
                        style={{ marginLeft: 10 }}
                        loading={this.state.loading}
                        disabled={this.state.checkboxselecionado === false}
                      >
                        Imprimir O.P.
                      </Button>
                    )}
                  </Col>
                </Row>
              </Col>
              {/* <Col md="auto" hidden={this.state.tipo !== "emissao"}>
                <Button primary onClick={this.gerarOrdemPagamento}>
                  Imprimir O.P.
                </Button>
              </Col> */}
            </Row>
          </Col>

          <Col sm={6} hidden={this.state.tipo !== "vencimento"}>
            <Form.Label>Período:</Form.Label>
            <Row style={{ marginLeft: "0.5%" }}>
              <FormControl
                className="col-4"
                type="date"
                name="data_inicio"
                onChange={this.alteracao}
                defaultValue={this.state.data_inicio.toString().substr(0, 10)}
              />
              <Icon name="calendar check outline" size="big" />
              <FormControl
                className="col-4"
                type="date"
                name="data_fim"
                onChange={this.alteracao}
                value={this.state.data_fim}
              />
              <Button
                positive
                onClick={this.buscaContasPorDataVencimento}
                style={{ marginLeft: "1%" }}
              >
                Buscar
              </Button>
            </Row>
          </Col>
          <Col sm={4} hidden={this.state.tipo !== "documento"}>
            <Form.Label>Nº documento</Form.Label>
            <FormControl
              placeholder="nº"
              aria-describedby="basic-addon1"
              name="documento"
              id="documento"
              required
              onChange={this.alteracao}
            />
          </Col>
          <Col
            sm={2}
            hidden={this.state.tipo !== "documento"}
            style={{ marginTop: "2%" }}
          >
            <Button
              positive
              onClick={this.buscaPorDocumento}
              loading={this.state.pesquisando}
              disabled={this.state.pesquisando}
            >
              Buscar
            </Button>
          </Col>
          <Col
            sm={2}
            hidden={this.state.tipo === "emissao"}
            style={{ marginTop: "2.3%" }}
          >
            {/* //todos: fornecedor */}
            <Button
              primary
              onClick={this.gerarOrdemPagamento}
              style={{ marginTop: -10 }}
              loading={this.state.loading}
              disabled={this.state.checkboxselecionado === false}
            >
              Imprimir O.P.
            </Button>
          </Col>
        </Row>

        {/* {this.state.loading === true && (
          <div style={{ paddingTop: 10, height: 60 }}>
            <Segment size="massive">
              <Dimmer active inverted>
                <Loader active inverted inline />
              </Dimmer>
            </Segment>
          </div>
        )} */}
        <Table celled sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={
                  this.state.sorted.name === "nome"
                    ? this.state.sorted.direction
                    : null
                }
                onClick={() => this.changeSort("nome")}
              >
                Nome
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  this.state.sorted.name === "documento"
                    ? this.state.sorted.direction
                    : null
                }
                onClick={() => this.changeSort("documento")}
              >
                Documento
              </Table.HeaderCell>
              <Table.HeaderCell
                width={1}
                sorted={
                  this.state.sorted.name === "op"
                    ? this.state.sorted.direction
                    : null
                }
                onClick={() => this.changeSort("op")}
              >
                <>
                  Ordem <br />
                  Pagamento
                </>
              </Table.HeaderCell>
              {this.state.tipo === "emissao" && (
                <Table.HeaderCell
                  sm={2}
                  sorted={
                    this.state.sorted.name === "emissao"
                      ? this.state.sorted.direction
                      : null
                  }
                  onClick={() => this.changeSort("emissao")}
                >
                  Emissão
                </Table.HeaderCell>
              )}
              {this.state.tipo === "nome" && (
                <Table.HeaderCell
                  sm={2}
                  sorted={
                    this.state.sorted.name === "nome"
                      ? this.state.sorted.direction
                      : null
                  }
                  onClick={() => this.changeSort("nome")}
                >
                  Emissão
                </Table.HeaderCell>
              )}
              <Table.HeaderCell
                sm={2}
                sorted={
                  this.state.sorted.name === "vencimento"
                    ? this.state.sorted.direction
                    : null
                }
                onClick={() => this.changeSort("vencimento")}
              >
                Vencimento
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  this.state.sorted.name === "valor"
                    ? this.state.sorted.direction
                    : null
                }
                onClick={() => this.changeSort("valor")}
              >
                Valor
              </Table.HeaderCell>

              <Table.HeaderCell>
                {" "}
                <Checkbox
                  label=""
                  onClick={this.marcartodas}
                  name="allcheck"
                  checked={this.state.allcheck}
                />
              </Table.HeaderCell>
              <Table.HeaderCell width={2} textAlign="center">
                <Link to={{ pathname: "/contasAPagar" }}>
                  <Button positive icon="add" size="tiny"></Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.listaContasAPagar.map((e, i) => (
              <>
                <Table.Row
                  key={i}
                  // disabled={e.cancelado === true}
                  // style={{ backgroundColor: "#DCDCDC" }}
                  positive={e.impressao === true}
                >
                  {e.cancelado === true ? (
                    <>
                      <Table.Cell width={6} style={{ color: "#DCDCDC" }}>
                        {e.pessoa.nome}
                      </Table.Cell>
                      <Table.Cell style={{ color: "#DCDCDC" }}>
                        {e.documento}
                      </Table.Cell>
                      <Table.Cell width={1} style={{ color: "#DCDCDC" }}>
                        {e.ordem_pagamento !== null && e.ordem_pagamento.codigo}
                      </Table.Cell>
                      {this.state.tipo === "emissao" ? (
                        <Table.Cell style={{ color: "#DCDCDC" }}>
                          {e.lancamento_contas.length > 0 &&
                            e.lancamento_contas[0].data_emissao !== null &&
                            format(
                              parseISO(e.lancamento_contas[0].data_emissao),
                              "dd/MM/yyyy"
                            )}
                        </Table.Cell>
                      ) : null}

                      {this.state.tipo === "nome" ? (
                        <Table.Cell style={{ color: "#DCDCDC" }}>
                          {e.lancamento_contas.length > 0 &&
                            e.lancamento_contas[0].data_emissao !== null &&
                            format(
                              parseISO(e.lancamento_contas[0].data_emissao),
                              "dd/MM/yyyy"
                            )}
                        </Table.Cell>
                      ) : null}
                      <Table.Cell style={{ color: "#DCDCDC" }}>
                        {e.lancamento_contas.length > 0 &&
                          e.lancamento_contas[0].data_vencimento !== null &&
                          format(
                            parseISO(e.lancamento_contas[0].data_vencimento),
                            "dd/MM/yyyy"
                          )}
                      </Table.Cell>
                      <Table.Cell style={{ color: "#DCDCDC" }}>
                        {formatter.format(e.valor != null && e.valor)}
                      </Table.Cell>
                      <Table.Cell>
                        {
                          <Checkbox
                            label=""
                            onClick={() => this.toggleCheckbox(e.id)}
                            defaultValue={e.id}
                            checked={e.selecionado}
                            color="red"
                            disabled={e.cancelado}
                          />
                        }
                      </Table.Cell>
                    </>
                  ) : (
                    <>
                      <Table.Cell width={6}>{e.pessoa.nome}</Table.Cell>

                      <Table.Cell>{e.documento}</Table.Cell>
                      <Table.Cell width={1}>
                        {e.ordem_pagamento !== null && e.ordem_pagamento.codigo}
                      </Table.Cell>
                      {this.state.tipo === "emissao" ? (
                        <Table.Cell>
                          {e.lancamento_contas.length > 0 &&
                            e.lancamento_contas[0].data_emissao !== null &&
                            format(
                              parseISO(e.lancamento_contas[0].data_emissao),
                              "dd/MM/yyyy"
                            )}
                        </Table.Cell>
                      ) : null}

                      {this.state.tipo === "nome" ? (
                        <Table.Cell>
                          {e.lancamento_contas.length > 0 &&
                            e.lancamento_contas[0].data_emissao !== null &&
                            format(
                              parseISO(e.lancamento_contas[0].data_emissao),
                              "dd/MM/yyyy"
                            )}
                        </Table.Cell>
                      ) : null}
                      <Table.Cell color="red">
                        {e.lancamento_contas.length > 0 &&
                          e.lancamento_contas[0].data_vencimento !== null &&
                          format(
                            parseISO(e.lancamento_contas[0].data_vencimento),
                            "dd/MM/yyyy"
                          )}
                      </Table.Cell>
                      <Table.Cell>
                        {formatter.format(e.valor != null && e.valor)}
                      </Table.Cell>
                      <Table.Cell>
                        {
                          <Checkbox
                            label=""
                            onClick={() => this.toggleCheckbox(e.id)}
                            defaultValue={e.id}
                            checked={e.selecionado}
                            color="red"
                            disabled={e.temLiquidada}
                          />
                        }
                      </Table.Cell>
                    </>
                  )}

                  <Table.Cell width={2} textAlign="center">
                    {e.temLiquidada ? (
                      <Button
                        primary
                        size="tiny"
                        icon="edit outline"
                        disabled={(e.temLiquidada, e.cancelado === true)}
                      ></Button>
                    ) : (
                      <Link
                        to={{ pathname: "/contasAPagar", query: { id: e.id } }}
                      >
                        <Button
                          primary
                          size="tiny"
                          icon="edit outline"
                          disabled={e.cancelado || e.temLiquidada}
                        ></Button>
                      </Link>
                    )}
                    <Button
                      color="orange"
                      size="tiny"
                      id={e.id}
                      onClick={this.handleShow}
                      icon={{
                        className: "times",
                        id: [e.id],
                        onClick: [this.handleShow],
                      }}
                      disabled={e.temLiquidada || e.cancelado === true}
                    />
                    <Link
                      to={{
                        pathname: "/contasAPagar",
                        query: { id: e.id, visualiza: false },
                      }}
                    >
                      <Button primary size="tiny" icon="eye"></Button>
                    </Link>
                  </Table.Cell>
                </Table.Row>
              </>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan={20}>
                <Pagination
                  boundaryRange={0}
                  //defaultActivePage={1}
                  ellipsisItem={null}
                  //firstItem={null}
                  //lastItem={null}
                  siblingRange={1}
                  totalPages={this.state.totalPages}
                  onPageChange={this.pageChange}
                  activePage={this.state.paginaAtual}
                  // disabled={this.state.listaPessoasTipoTituloSlice.length === 0}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>

        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          dimmer="blurring"
        >
          <Modal.Header>Cancelar Conta a Pagar?</Modal.Header>
          <Modal.Content>
            <Table size="large">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Nome</Table.HeaderCell>
                  <Table.HeaderCell>Documento</Table.HeaderCell>
                  <Table.HeaderCell sm={2}>O.P.</Table.HeaderCell>
                  <Table.HeaderCell sm={2}>Data Cancelamento</Table.HeaderCell>
                  <Table.HeaderCell>Valor</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.cancelamento !== null && (
                  <Table.Row>
                    <Table.Cell width={12}>
                      {this.state.cancelamento.pessoa.nome}
                    </Table.Cell>
                    <Table.Cell width={4}>
                      {this.state.cancelamento.documento}
                    </Table.Cell>
                    <Table.Cell width={4}>
                      {this.state.cancelamento.ordem_pagamento !== null &&
                        this.state.cancelamento.ordem_pagamento.codigo}
                    </Table.Cell>
                    <Table.Cell width={4}>
                      {
                        moment().format("DD/MM/yyyy")
                        // this.state.cancelamento.data !== null &&
                        //   format(
                        //     parseISO(this.state.cancelamento.data),
                        //     "dd/MM/yyyy"
                        //   )
                      }
                    </Table.Cell>
                    <Table.Cell>
                      {formatter.format(
                        this.state.cancelamento.valor !== null &&
                          this.state.cancelamento.valor
                      )}
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Modal.Content>
          <Modal.Actions>
            {" "}
            <Button color="black" onClick={this.handleClose}>
              Cancelar
            </Button>
            <Button
              content="Confirmar"
              labelPosition="right"
              icon="checkmark"
              onClick={this.cancelar}
              positive
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
