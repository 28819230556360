import React, { Component } from "react";
import {
  Container,
  Table,
  Button,
  Popup,
  Modal,
  Menu,
  Icon,
  Search,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import api from "../../../../../services/api";
import requisicoes from "../../../../../utils/Requisicoes";
import { Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import renomearChave from "../../../../../services/renomearChave";
import { parseISO, format } from "date-fns";

export default class EntradaItemLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      listaEntradaItem: [],
      pagina: 1,
      totalPaginas: 1,
      modal: false,
      centro_de_custo: null,
      centro_de_custo_id: null,
      elemento_de_custo: null,
      elemento_de_custo_id: null,
      optionsCC: [],
      optionsEC: [],
      documento: null,
      sortedName: "data",
      sortDirection: "descending",
    };
    this.lista();
    this.alteracaoSelectCentroCusto = this.alteracaoSelectCentroCusto.bind(
      this
    );
    this.alteracaoSelectElementoCusto = this.alteracaoSelectElementoCusto.bind(
      this
    );
  }

  lista = async (page = 1) => {
    const resposta = await api.post(
      "/entradaItem/lista" + requisicoes.entidade + "&pagina=" + page,
      {
        sortedName: this.state.sortedName,
        sortDirection: this.state.sortDirection,
      },
      requisicoes.header
    );
    const { entradas, pagina, totalPaginas } = resposta.data;
    this.setState({ listaEntradaItem: entradas, pagina, totalPaginas });
  };

  handleClose = () => {
    this.setState({ modal: false });
  };

  handleShow = async () => {
    const retorno = await api.post(
      "/parametrosEntradaItem/visualiza" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    if (retorno.data.length > 0) {
      this.setState(retorno.data[0]);
      let c = renomearChave(retorno.data[0].centro_de_custo, "id", "value");
      c = renomearChave(c, "descricao", "label");
      this.setState({ centro_de_custo_id: retorno.data[0].centro_de_custo_id });
      this.setState({ centro_de_custo: c });

      let p = renomearChave(retorno.data[0].elemento_de_custo, "id", "value");
      p = renomearChave(p, "descricao", "label");
      this.setState({
        elemento_de_custo_id: retorno.data[0].elemento_de_custo_id,
      });
      this.setState({ elemento_de_custo: p });
    }
    this.setState({ modal: true });
  };

  alteracaoSelectCentroCusto = async (event, id) => {
    this.setState({ centro_de_custo_id: event.value });
    this.setState({ centro_de_custo: event });
  };

  alteracaoSelectElementoCusto = async (event, id) => {
    this.setState({ elemento_de_custo_id: event.value });
    this.setState({ elemento_de_custo: event });
  };

  pesquisaCentroCusto = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/centroDeCusto/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaCentroCusto").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "descricao", "label");
        lista.push(objeto);
      });
      this.setState({ optionsCC: lista });
    }, 500);
  };

  pesquisaElementoCusto = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/elementoDeCusto/pesquisaByContaContabil" + requisicoes.entidade,
        {
          pesquisa: document.getElementById("pesquisaElementoCusto").value,
          centroCusto: this.state.centro_de_custo_id,
          cad: true,
        },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "descricao", "label");
        lista.push(objeto);
      });
      this.setState({ optionsEC: lista });
    }, 500);
  };

  handleSubmit = (event) => {
    this.submit();
    event.preventDefault();
  };

  submit = async () => {
    if (this.state.id !== null) {
      await api.post(
        "/parametrosEntradaItem/editar" +
          requisicoes.entidade +
          "&id=" +
          this.state.id,
        this.state,
        requisicoes.header
      );
      this.handleClose();
    } else {
      await api.post(
        "/parametrosEntradaItem/salvar" + requisicoes.entidade,
        this.state,
        requisicoes.header
      );
      this.handleClose();
    }
  };

  novoEntradaItem = async () => {
    const retorno = await api.post(
      "/parametrosEntradaItem/visualiza" + requisicoes.entidade,
      "",
      requisicoes.header
    )
    if (retorno.data === "") {
      console.log("retorno:",retorno.data)
      this.handleShow();
    } else {
      window.location.href = "/entradaItem";
    }
  };

  proximaPagina = async () => {
    const { pagina } = this.state;
    const paginaNumero = parseInt(pagina) + 1;
    this.lista(paginaNumero);
  };

  voltarPagina = async () => {
    const { pagina } = this.state;
    const paginaNumero = pagina - 1;
    this.lista(paginaNumero);
  };

  buscaPorDocumento = async (event) => {
    this.setState({ pesquisando: true });
    let pesquisa = event.target.value;
    setTimeout(async () => {
      this.setState({ popUpPesquisa: false });
      await api
        .post(
          "/entradaItem/pesquisaDocumento" + requisicoes.entidade,
          { documento: pesquisa },
          requisicoes.header
        )
        .then((resp) => {
          if (resp.data.length == 0) {
            this.setState({ popUpPesquisa: true });
            this.lista();
          }
          this.setState({ listaEntradaItem: resp.data });
        });
      this.setState({ pesquisando: false });
    }, 500);
  };

  sortTableClick = async (sortedName) => {
    this.state.sortedName = sortedName;
    this.state.sortDirection =
      this.state.sortDirection === "ascending" ? "descending" : "ascending";
    this.lista();
  };

  render() {
    return (
      <div className="lista">
        <Popup
          content="Parâmetros"
          mouseEnterDelay={500}
          on="hover"
          style={{ zoom: 0.8 }}
          trigger={
            <Button
              circular
              icon="setting"
              size="medium"
              onClick={this.handleShow}
              type="button"
              color="grey"
              floated="right"
              style={{ marginRight: "1.5%" }}
              // style={{ marginTop: "-%", marginRight: "1.5%" }}
            />
          }
        />

        <Form>
          <Modal
            open={this.state.modal}
            onClose={this.props.handleClose}
            dimmer={"blurring"}
            centered={false}
          >
            <Modal.Header>Parâmetros</Modal.Header>
            <Modal.Content>
              <Row>
                <Col sm={6}>
                  <Form.Label>Centro de Custo</Form.Label>
                  <Select
                    placeholder="Digite para buscar"
                    name="centroCusto"
                    value={this.state.centro_de_custo}
                    onChange={this.alteracaoSelectCentroCusto}
                    options={this.state.optionsCC}
                    onKeyDown={this.pesquisaCentroCusto}
                    defaultValue={this.state.centro_de_custo}
                    inputId="pesquisaCentroCusto"
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label>Elemento de Custo</Form.Label>
                  <Select
                    placeholder="Digite para buscar"
                    name="elementoCusto"
                    value={this.state.elemento_de_custo}
                    onChange={this.alteracaoSelectElementoCusto}
                    options={this.state.optionsEC}
                    onKeyDown={this.pesquisaElementoCusto}
                    defaultValue={this.state.elemento_de_custo}
                    inputId="pesquisaElementoCusto"
                  />
                </Col>
              </Row>
            </Modal.Content>
            <Modal.Actions>
              <Button
                variant="light"
                onClick={this.handleClose}
                style={{ marginLeft: "10px" }}
              >
                Voltar
              </Button>
              <Button positive onClick={this.handleSubmit}>
                Salvar
              </Button>
            </Modal.Actions>
          </Modal>
        </Form>
        <Row>
          <Col sm={6}>
            <Popup
              content="Nenhum registro encontrado!"
              open={this.state.popUpPesquisa}
              position="right center"
              className="pagina"
              trigger={
                <Search
                  loading={this.state.pesquisando}
                  fluid
                  onSearchChange={this.buscaPorDocumento}
                  showNoResults={false}
                  input={{
                    icon: "search",
                    iconPosition: "left",
                    style: { width: "100%" },
                    input: { style: { borderRadius: "3%" } },
                  }}
                  placeholder="Digite para buscar"
                />
              }
            />
          </Col>
        </Row>
        <Table celled sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={
                  this.state.sortedName === "fornecedor"
                    ? this.state.sortDirection
                    : null
                }
                onClick={() => this.sortTableClick("fornecedor")}
              >
                Fornecedor
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  this.state.sortedName === "data"
                    ? this.state.sortDirection
                    : null
                }
                onClick={() => this.sortTableClick("data")}
              >
                Data Emissão
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  this.state.sortedName === "numero"
                    ? this.state.sortDirection
                    : null
                }
                onClick={() => this.sortTableClick("numero")}
              >
                Número Documento
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <Button
                  positive
                  icon="add"
                  size="tiny"
                  onClick={this.novoEntradaItem}
                ></Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.listaEntradaItem.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>{e.pessoa.nome}</Table.Cell>
                <Table.Cell>
                  {e.data_emissao != null &&
                    format(parseISO(e.data_emissao), "dd/MM/yyyy")}
                </Table.Cell>
                <Table.Cell>{e.num_doc}</Table.Cell>
                <Table.Cell width={1}>
                  <Link to={{ pathname: "/entradaItem", query: { id: e.id } }}>
                    <Button primary icon="edit" size="tiny"></Button>
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan={4}>
                <Menu pagination>
                  <Menu.Item
                    icon
                    onClick={this.voltarPagina}
                    disabled={parseInt(this.state.pagina) === 1}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  <Menu.Item
                    icon
                    onClick={this.proximaPagina}
                    disabled={
                      parseInt(this.state.pagina) ===
                      parseInt(this.state.totalPaginas)
                    }
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}
