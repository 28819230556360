import React, { Component } from "react";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import Select from "react-select";
import renomearChave from "../../../services/renomearChave";
import { Row, Col, FormControl, Form } from "react-bootstrap";
import { Container, Header, Button, Divider, Segment } from "semantic-ui-react";
import CreditoDebito from "../../../enum/CreditoDebito";
import TipoLancamento from "../../../enum/TipoLancamento";
import TipoCobranca from "../../../enum/TipoCobranca";
import requisicoes from "../../../utils/Requisicoes";

export default class Lancamento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descricao: "",
      usuario: "",
      options: [],
      pessoaSelect: [],
      pessoa: "",
      creditoDebito: "",
      creditoDebitoList: CreditoDebito,
      creditoDebitoSelect: "",
      valor: "",
      tipoCobrancaList: TipoCobranca,
      tipoCobranca: "",
      tipoCobrancaSelect: "",
      datavencimento: null,
    };
  }

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  alteracaoSelectPessoa = (e) => {
    this.setState({ pessoaSelect: e });
    this.setState({ pessoa: e });
  };
  alteracaoSelectCreditoDebito = (e) => {
    this.setState({ creditoDebitoSelect: e });
    this.setState({ creditoDebito: e });
  };

  alteracaoSelectTipoCobranca = (e) => {
    this.setState({ tipoCobrancaSelect: e });
    this.setState({ tipoCobranca: e });
  };

  pesquisaPessoa = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/pessoa/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaPessoa").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista.push(objeto);
      });
      this.setState({ options: lista });
    }, 500);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const dados = {
      descricao: this.state.descricao,
      tipo_lancamento: TipoLancamento.find((e) => e.value === "lancamentoCaixa")
        .value,
      credito_debito: this.state.creditoDebito.value,
      pessoa_id: this.state.pessoa.value,
      valor: this.state.valor,
      tipo_cobranca: this.state.tipoCobranca.value,
      data_vencimento: this.state.datavencimento,
    };
    const retorno = await api.post(
      "/caixa/adicionaLancamento" + requisicoes.entidade,
      dados,
      requisicoes.header
    );
    if (retorno.data !== null) {
      window.location.href = "/caixa/lista";
    }
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Lançamento Conta
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={12}>
              <Form.Label>Descrição</Form.Label>
              <FormControl
                placeholder="Descrição"
                name="descricao"
                onChange={this.alteracao}
                required
                defaultValue={this.state.descricao}
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Crédito/Débito</Form.Label>
              <Select
                name="creditoDebito"
                value={this.state.creditoDebito}
                options={this.state.creditoDebitoList}
                onChange={this.alteracaoSelectCreditoDebito}
                required
              />
            </Col>

            <Col sm={6}>
              <Form.Label>Pessoa</Form.Label>
              <Select
                name="pessoa"
                value={this.state.pessoa}
                onChange={this.alteracaoSelectPessoa}
                options={this.state.options}
                onKeyDown={this.pesquisaPessoa}
                inputId="pesquisaPessoa"
              />
            </Col>

            <Col sm={6}>
              <Form.Label>Valor</Form.Label>
              <FormControl
                placeholder="Valor"
                aria-label="Valor"
                aria-describedby="basic-addon1"
                name="valor"
                required
                value={this.state.valor}
                onChange={this.alteracao}
                type="number"
              />
            </Col>

            <Col sm={6}>
              <Form.Label>Tipo Cobrança</Form.Label>
              <Select
                name="tipoCobranca"
                value={this.state.tipoCobranca}
                options={this.state.tipoCobrancaList}
                onChange={this.alteracaoSelectTipoCobranca}
              />
            </Col>

            <Col sm={6}>
              <Form.Label>Data Vencimento</Form.Label>
              <FormControl
                placeholder="Data Vencimento"
                aria-label="dataVencimento"
                aria-describedby="basic-addon1"
                type="date"
                name="datavencimento"
                onChange={this.alteracao}
              />
            </Col>
          </Row>
          <Divider />
          <Segment clearing>
            <Header floated="right">
              <Link to="/usuario/lista">
                <Button type="button">Voltar</Button>
              </Link>
              <Button positive type="submit">
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}
