import React, { Component } from "react";
import { Container, Table, Button, Modal, Header } from "semantic-ui-react";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import api from "../../../../services/api";
import Requisicoes from "../../../../utils/Requisicoes";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import converterParaLabel from "../../../../utils/converteParaLabel";
import converterParaLabelComAcao from "../../../../utils/converteParaLabelComAcao";

export default class SuspensaoLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      pessoa: null,
      data_inicio: null,
      data_final: null,
      suspensao_historicos: [],
      open_modal: false,
      pessoa_id: null,
      options: [],
    };
    this.lista();
  }

  alteracaoPessoa = async (e, v) => {
    if (v !== null) {
      this.setState({ pessoa_id: v.value });
      document.getElementById("porcentagem").innerText = "";
    }
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleOpen = () => {
    this.setState({ open_modal: true });
  };
  handleClose = () => {
    this.setState({ open_modal: false });
  };

  lista = async () => {
    const retorno = await api.post(
      "/suspensao/lista" + Requisicoes.entidade,
      {},
      Requisicoes.header
    );
    this.setState({ lista: retorno.data });
  };

  pesquisaPessoa = async () => {
    let term = document.getElementById("pesquisaPessoa").value;
    if (term.toString().indexOf("%") !== -1) {
      term = document
        .getElementById("pesquisaPessoa")
        .value.toString()
        .replace("%", "");
      document.getElementById("pesquisaPessoa").value = term;
      if (document.getElementById("porcentagem").innerText === "%") {
        document.getElementById("porcentagem").innerText = "";
      } else {
        document.getElementById("porcentagem").innerText = "%";
      }
    }
    setTimeout(async () => {
      if (isFinite(String(term))) {
        if (document.getElementById("porcentagem").innerText === "%") {
          await api
            .post(
              "/pessoa/pesquisaPorCodigoAcao" + Requisicoes.entidade,
              { pesquisa: document.getElementById("pesquisaPessoa").value },
              Requisicoes.header
            )
            .then((retorno) => {
              this.setState({
                options: converterParaLabelComAcao(retorno.data),
              });
            });
        } else {
          await api
            .post(
              "/pessoa/pesquisaPorCodigoAcaoValorExato" + Requisicoes.entidade,
              { pesquisa: document.getElementById("pesquisaPessoa").value },
              Requisicoes.header
            )
            .then((retorno) => {
              this.setState({
                options: converterParaLabelComAcao(retorno.data),
              });
            });
        }
      } else {
        if (document.getElementById("porcentagem").innerText === "%") {
          await api
            .post(
              "/pessoa/pesquisaNomeInicial" + Requisicoes.entidade,
              { pesquisa: document.getElementById("pesquisaPessoa").value },
              Requisicoes.header
            )
            .then((retorno) => {
              this.setState({ options: converterParaLabel(retorno.data) });
            });
        } else {
          await api
            .post(
              "/pessoa/pesquisaNome" + Requisicoes.entidade,
              { pesquisa: document.getElementById("pesquisaPessoa").value },
              Requisicoes.header
            )
            .then((retorno) => {
              this.setState({ options: converterParaLabel(retorno.data) });
            });
        }
      }
    }, 200);
  };

  historico = async (event) => {
    const listaHistorico = this.state.lista.find(
      (e) => e.id === parseInt(event.target.id)
    ).suspensao_historicos;

    let listaUserId = [];
    let listaUser = [];
    listaHistorico.forEach(async (s, i) => {
      listaUserId.push(s.usuario_id);

      // if (s.usuario_id !== null) {
      //   await api
      //     .post(
      //       "/usuario/visualiza" + Requisicoes.entidade,
      //       { id: s.usuario_id },
      //       Requisicoes.header
      //     )
      //     .then((retorno) => {
      //       s.usuario = retorno.data;
      //     });
      // }
      // if (i + 1 === listaHistorico.length) {
      //   this.setState({ suspensao_historicos: listaHistorico });
      //   this.handleOpen();
      // }
    });
    await api
      .post(
        "/usuario/visualiza" + Requisicoes.entidade,
        { listaId: listaHistorico },
        Requisicoes.header
      )
      .then((retorno) => {
        listaUser = retorno.data;
      });

    listaHistorico.forEach(async (s, i) => {
      let user = listaUser.find((element) => (element.id = s.usuario_id));
      s.usuario = user;
    });

    this.setState({ suspensao_historicos: listaHistorico });
    this.handleOpen();
  };

  buscar = async () => {
    if (
      this.state.pessoa_id !== null &&
      this.state.data_inicio === null &&
      this.state.data_final === null
    ) {
      await api
        .post(
          "/suspensao/suspensaoPorPessoa" + Requisicoes.entidade,
          { pessoa_id: this.state.pessoa_id },
          Requisicoes.header
        )
        .then((retorno) => {
          this.setState({ lista: retorno.data });
        });
    }
    if (
      this.state.pessoa_id !== null &&
      (this.state.data_inicio !== null || this.state.data_final !== null)
    ) {
      await api
        .post(
          "/suspensao/suspensaoPorPessoaDataInicialEDataFinal" +
            Requisicoes.entidade,
          {
            pessoa_id: this.state.pessoa_id,
            data_inicial: this.state.data_inicio,
            data_final: this.state.data_final,
          },
          Requisicoes.header
        )
        .then((retorno) => {
          this.setState({ lista: retorno.data });
        });
    }
  };

  render() {
    return (
      <div>
        <Row>
          <Col sm={4}>
            <Form.Label>Pessoa</Form.Label>
            <Row>
              <Col sm={1}>
                <Form.Label id="porcentagem"></Form.Label>
              </Col>
              <Col sm={11}>
                <Autocomplete
                  id="pesquisaPessoa"
                  options={this.state.options}
                  getOptionLabel={(option) => option.label}
                  onKeyDown={this.pesquisaPessoa}
                  onChange={this.alteracaoPessoa}
                  renderInput={(params) => (
                    <TextField {...params} label="Busca" size="small" />
                  )}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={5}>
            <Row>
              <Col sm={6}>
                <Form.Label>Data Inicio</Form.Label>
                <FormControl
                  placeholder="Data Inicio"
                  aria-describedby="basic-addon1"
                  type="date"
                  name="data_inicio"
                  onChange={this.alteracao}
                />
              </Col>
              <Col sm={6}>
                <Form.Label>Data Final</Form.Label>
                <FormControl
                  placeholder="Data Final"
                  aria-describedby="basic-addon1"
                  type="date"
                  name="data_final"
                  onChange={this.alteracao}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={3}>
            <Button positive onClick={this.buscar}>
              Buscar
            </Button>
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Pessoa</Table.HeaderCell>
              <Table.HeaderCell>Data Inicial</Table.HeaderCell>
              <Table.HeaderCell>Data Final</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell width={2}>
                <Link to={{ pathname: "/suspensao" }}>
                  <Button positive icon="add" size="tiny"></Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.lista.length > 0 &&
              this.state.lista.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{e.pessoa !== null && e.pessoa.nome}</Table.Cell>
                  <Table.Cell>
                    {e.data_inicial !== null &&
                      moment(e.data_inicial).format("DD/MM/YYYY")}
                  </Table.Cell>
                  <Table.Cell>
                    {e.data_final !== null &&
                      moment(e.data_final).format("DD/MM/YYYY")}
                  </Table.Cell>
                  <Table.Cell>{e.status !== null && e.status}</Table.Cell>
                  <Table.Cell width={2}>
                    <Col sm={12}>
                      <Row>
                        <Link
                          to={{ pathname: "/suspensao", query: { id: e.id } }}
                        >
                          <Button primary icon="edit" size="tiny"></Button>
                        </Link>
                        <Button
                          primary
                          icon={{
                            className: "file alternate",
                            id: [e.id],
                            onClick: [this.historico],
                          }}
                          size="tiny"
                          id={e.id}
                          title="Histórico"
                          onClick={this.historico}
                        ></Button>
                      </Row>
                    </Col>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
        <Modal
          open={this.state.open_modal}
          onClose={this.handleClose}
          centered={false}
        >
          <Header content="Histórico" />
          <Modal.Content>
            <Row>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Data</Table.HeaderCell>
                    <Table.HeaderCell>Hora</Table.HeaderCell>
                    <Table.HeaderCell>Usuario</Table.HeaderCell>
                    <Table.HeaderCell>Tipo Movimentação</Table.HeaderCell>
                    <Table.HeaderCell>Motivo</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.suspensao_historicos.length > 0 &&
                    this.state.suspensao_historicos.map((e, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>
                          {e.data !== null &&
                            moment(e.data).format("DD/MM/YYYY")}
                        </Table.Cell>
                        <Table.Cell>
                          {e.hora !== null &&
                            moment(e.data + "T" + e.hora).format("hh:mm")}
                        </Table.Cell>
                        <Table.Cell>{e.usuario.nome}</Table.Cell>
                        <Table.Cell>{e.tipo !== null && e.tipo}</Table.Cell>
                        <Table.Cell>{e.motivo !== null && e.motivo}</Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </Row>
          </Modal.Content>
          <Modal.Actions>
            <Button type="button" variant="light" onClick={this.handleClose}>
              Voltar
            </Button>
            {/* <Header floated="right">
              <Row>
                <Col sm={5}>
                  <Button type="button" variant="light" onClick={this.handleClose}>Voltar</Button>
                </Col>
              </Row>
            </Header> */}
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
