import React, { Component } from "react";
import { Container, Header, Segment, Dropdown } from "semantic-ui-react";
import { Form, Col, Row } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Lista from "./lista";
import ModalEditar from "./modalEditar";
import "../styles.css";

import api from "../../../services/api";
export default class Competicao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalidadedescricao: "",
      pontuacao: "",
      serie: null,
      etapas: null,
      descricao: "",
      alertaSeriesInvalida: false,
      modalidadesExistentes: [],
      modalEditar: false,
      modalidade_id: null,
      alertaSalvarModalidade: false,
    };
    this.buscaModalidadesNaoVinculadas();
  }
  changeModalEditar = async (dados, status) => {
    this.setState({ modalEditar: status });
    if (status) {
      this.setState({ modalidadedescricao: dados.descricao });
      this.setState({ serie: dados.serie });
      this.setState({ modalidade_id: dados.id });
      this.setState({ pontuacao: dados.tipo_pontuacao });
    }
  };

  buscaModalidadesNaoVinculadas = async () => {
    const modalidades = await api.post(
      "/modalidade/listarNaoVinculadas" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    this.setState({ modalidadesExistentes: modalidades.data });
  };

  alteracao = async (event) => {
    if (event.target.value > 25) {
      this.setState({ alertaSeriesInvalida: true });
    } else {
      this.setState({ alertaSeriesInvalida: false });
    }
    this.setState({ [event.target.id]: event.target.value });
  };

  alteracaoPontuacao = async (event, id) => {
    this.setState({ pontuacao: id.value });
  };

  voltar = async () => {
    window.location.href = "/eventos";
  };

  handleSubmit = async () => {
    this.submit();
  };

  salvarModalidadeEditada = async () => {
    this.setState({ alertaSalvarModalidade: true });
    const resposta = await api.post(
      "/modalidade/editar" + requisicoes.entidade,
      {
        descricao: this.state.modalidadedescricao,
        serie: this.state.serie,
        tipo_pontuacao: this.state.pontuacao,
        id: this.state.modalidade_id,
      },
      requisicoes.header
    );

    setTimeout(() => {
      this.setState({ modalEditar: false });
      this.setState({ alertaSalvarModalidade: false });
    }, 1000);
  };

  submit = async () => {
    const resposta = await api
      .post(
        "/modalidade/salvar" + requisicoes.entidade,
        {
          descricao: this.state.modalidadedescricao,
          serie: this.state.serie,
          tipo_pontuacao: this.state.pontuacao,
        },
        requisicoes.header
      )
      .then(() => {
        alert("Salvo com Sucesso");
      })
      .catch((error) => {
        alert("Não foi possível salvar o evento");
      });
  };

  render() {
    const pontuacaooptions = [
      { key: 1, value: "proximidade", text: "Proximidade" },
      { key: 2, value: "alvomovel", text: "Alvo móvel" },
    ];
    return (
      <Container className="paginaSemPaddingTop">
        <Form onSubmit={this.handleSubmit}>
          <Segment>
            <Header as="h2" dividing>
              Cadastro de Modalidade
            </Header>
            <Col sm={12}>
              <Row>
                <Col sm={3}>
                  <TextField
                    id="modalidadedescricao"
                    label="Nome"
                    onChange={this.alteracao}
                    required
                  />
                </Col>
                <Col sm={3}>
                  <TextField
                    error={this.state.alertaSeriesInvalida}
                    id="serie"
                    label="Disparos por Série"
                    onChange={this.alteracao}
                    helperText={
                      this.state.alertaSeriesInvalida === true
                        ? "Nº de séries deve ser entre 1 e 25"
                        : ""
                    }
                    required
                  />
                </Col>
                <Col sm={2} className="modalidadePontuacao">
                  <a>Regra de pontuação:</a>
                </Col>
                <Col sm={3} className="selectModalidade">
                  <Dropdown
                    placeholder="Pontuação"
                    id="pontuacao"
                    label="Regra de Pontuacao"
                    name="pontuacao"
                    onChange={this.alteracaoPontuacao}
                    options={pontuacaooptions}
                  />
                </Col>
              </Row>
            </Col>
            <Lista
              hidden={this.state.modalidadesExistentes.length === 0}
              listaModalidades={this.state.modalidadesExistentes}
              changeModalEditar={this.changeModalEditar}
              modalEditar={this.state.modalEditar}
            ></Lista>

            <ModalEditar
              alertaSalvarModalidade={this.state.alertaSalvarModalidade}
              modalEditar={this.state.modalEditar}
              changeModalEditar={this.changeModalEditar}
              alteracao={this.alteracao}
              alertaSeriesInvalida={this.state.alertaSeriesInvalida}
              alteracaoPontuacao={this.alteracaoPontuacao}
              pontuacaooptions={pontuacaooptions}
              pontuacao={this.state.pontuacao}
              modalidadedescricao={this.state.modalidadedescricao}
              serie={this.state.serie}
              salvarModalidadeEditada={this.salvarModalidadeEditada}
            ></ModalEditar>
          </Segment>

          <Segment clearing>
            <Header floated="right">
              <Button
                type="submit"
                style={{ outline: "none" }}
                color="primary"
                disabled={this.state.alertaSeriesInvalida === true}
              >
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>
      </Container>
    );
  }
}
