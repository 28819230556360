import React, { Component } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Header, Modal, FormField, Input } from "semantic-ui-react";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";
import Select from "react-select";
import renomearChave from "../../../../services/renomearChave";
import { Button } from "semantic-ui-react";
import Checkbox from "muicss/lib/react/checkbox";
import Meses from "../../../../enum/Mes";
import moment from "moment-timezone";
import TipoLancamento from "../../../../enum/TipoLancamento";
import CreditoDebito from "../../../../enum/CreditoDebito";
import CurrencyInput from "react-currency-input";
import ReplaceAll from "replaceall";

export default class ModalVendaAcumulada extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      optionsServico: [],
      selectedCheckboxes: new Set(),
    };
  }

  submit = async (event) => {
    event.preventDefault();
    let lista = Array.from(this.state.selectedCheckboxes);
    let valor = ReplaceAll(".", "", this.props.valor);
    valor = ReplaceAll(",", ".", valor);
    let {
      lancamento_servico_id,
      id,
      descricao,
      servico,
      pessoa_id,
      ano,
      dia_vencimento,
      adicionaLancamentoServico,
      setaId,
      alteracaoSelectServico,
      alteracaoLancamentoServico,
      abreFechaModalVendaAcumulada,
    } = this.props;
    for (var indice = 0; indice < lista.length; indice++) {
      let e = lista[indice];
      if (e !== undefined) {
        const dataVencimento = moment()
          .set("year", ano)
          .set("month", e)
          .set("date", dia_vencimento);
        adicionaLancamentoServico({
          servico: servico,
          valor: valor,
          mes: parseInt(e),
          ano: ano,
          data_vencimento: dataVencimento.format("YYYY-MM-DD"),
          salvar: true,
        });
        if (indice + 1 === lista.length) {
          alteracaoSelectServico({
            servico: null,
            valor: null,
            lancamento_servico_id: null,
          });
          abreFechaModalVendaAcumulada();
        }
      }
    }
    this.setState({ selectedCheckboxes: new Set() });
  };

  toggleCheckbox = (e) => {
    if (this.state.selectedCheckboxes.has(e.target.value)) {
      this.state.selectedCheckboxes.delete(e.target.value);
    } else {
      this.state.selectedCheckboxes.add(e.target.value);
    }
  };

  pesquisaServico = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/servico/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaServico").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista.push(objeto);
      });
      this.setState({ optionsServico: lista });
    }, 500);
  };

  render() {
    return (
      <Modal
        size="small"
        open={this.props.abreModalVendaAcumulada}
        onClose={this.props.abreModalVendaAcumulada}
        style={{ overflow: "visible" }}
      >
        <Modal.Header content="Serviço" />

        <Modal.Content>
          <Row>
            <Col sm={8}>
              <Form.Label>Serviço</Form.Label>
              <Select
                placeholder="Digite para buscar"
                name="servico"
                value={this.props.servico}
                onChange={this.props.alteracaoSelectServico}
                options={this.state.optionsServico}
                onKeyDown={this.pesquisaServico}
                inputId="pesquisaServico"
              />
            </Col>
            <Col sm={4}>
              <Form.Label>Valor</Form.Label>
              <CurrencyInput
                decimalSeparator=","
                thousandSeparator="."
                className="form-control"
                value={this.props.valor}
                onChange={(value) =>
                  this.props.alteracao({
                    target: { name: "valor", value: value },
                  })
                }
              />
            </Col>
            <Col sm={4}>
              <Form.Label>Dia Vencimento</Form.Label>
              <FormField
                name="dia_vencimento"
                type="number"
                control={Input}
                onChange={this.props.alteracao}
                required
                value={this.props.dia_vencimento}
                fluid
              />
            </Col>

            <Col sm={12} style={{ marginTop: "15px" }}>
              <Header as="h5">Meses</Header>
              <Row style={{ marginLeft: "0" }}>
                {Meses.map((m, i) => {
                  return (
                    <Checkbox
                      label={" " + m.label}
                      onClick={this.toggleCheckbox}
                      style={{ width: "90px" }}
                      value={i}
                    />
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={this.submit}>
            Salvar
          </Button>
          <Button onClick={this.props.abreFechaModalVendaAcumulada}>
            Fechar
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
