import React, { Component } from "react";
import {
  Container,
  Table,
  Button,
  Modal,
  Divider,
  Pagination,
} from "semantic-ui-react";
import { Row, Col, Form } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import Select from "react-select";
import moment from "moment-timezone";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import api from "../../../services/api";
import renomearChave from "../../../services/renomearChave";

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo: "pagas",
      data_inicio: null,
      data_final: null,
      usuario: null,
      local: null,
      pesquisaPessoa: null,
      titulo: null,
      tipoTitulo: null,
      tipo_relatorio: "simplificado",
      agrupar_simplificado: "data",
      filtro_autorizacao: "todos",

      imprimindo: false,

      lista_todas_entradas: [],
      lista_entradas: [],
      page: 1,
      totalPages: 0,

      totalizador: null,

      usuarioOptions: [],
      locaisOptions: [],
      optionsPessoa: [],
      tipoTituloOptions: [],

      filtros: [
        {
          id: 0,
          name: "Usuario",
          value: "usuario",
          selected: false,
          disabled: true,
          group: 1,
        },
        {
          id: 1,
          name: "Local",
          value: "local",
          selected: false,
          disabled: false,
          group: 1,
        },
        {
          id: 2,
          name: "Pessoa",
          value: "pessoa",
          selected: false,
          disabled: false,
          group: 2,
        },
        {
          id: 3,
          name: "Código do título",
          value: "codigoTitulo",
          selected: false,
          disabled: false,
          group: 2,
        },
        {
          id: 4,
          name: "Tipo de Título",
          value: "tipoTitulo",
          selected: false,
          disabled: false,
          group: 3,
        },
      ],
    };
    this.buscaOpcoes();
  }

  buscaOpcoes = async (event, value) => {
    //Opções de usuários
    let lista = [];
    const usuariosCatracas = await api.post(
      "/catraca/listaUsuarios" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    const listaUsuarios = await api.post(
      "/usuario/visualiza" + requisicoes.entidade,
      {
        listaId: usuariosCatracas.data,
      },
      requisicoes.header
    );
    for (let i = 0; i < listaUsuarios.data.length; i++) {
      const element = listaUsuarios.data[i];
      let objeto = renomearChave(element, "id", "value");
      objeto = renomearChave(objeto, "nome", "label");
      lista.push(objeto);
    }
    this.setState({ usuarioOptions: lista });

    //Opções de locais
    lista = [];
    const catracas = await api.post(
      "/catraca/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    lista.push({ key: 0, value: 0, label: "Cancela" });
    for (let i = 0; i < catracas.data.length; i++) {
      const element = catracas.data[i];
      let objeto = {};
      objeto.key = element.id;
      objeto.label = element.descricao;
      objeto.value = element.id;
      lista.push(objeto);
    }

    console.log("locaisOptions: ", lista);
    this.setState({ locaisOptions: lista });

    //Opções de tipo de título
    lista = [];
    const tipoTituloLista = await api.post(
      "/tipoTitulo/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    for (let i = 0; i < tipoTituloLista.data.length; i++) {
      const element = tipoTituloLista.data[i];
      let objeto = {};
      objeto.key = element.id;
      objeto.label = element.descricao;
      objeto.value = element.id;
      lista.push(objeto);
    }
    this.setState({ tipoTituloOptions: lista });
  };
  pesquisaPessoaAcao = async () => {
    let term = document.getElementById("pesquisaPessoa").value;
    if (term.toString().indexOf("%") !== -1) {
      term = document
        .getElementById("pesquisaPessoa")
        .value.toString()
        .replace("%", "");
      document.getElementById("pesquisaPessoa").value = term;
      if (document.getElementById("porcentagem").innerText === "%") {
        document.getElementById("porcentagem").innerText = "";
      } else {
        document.getElementById("porcentagem").innerText = "%";
      }
    }

    setTimeout(async () => {
      if (isFinite(String(term))) {
        await api
          .post(
            "/pessoa/pesquisaPorCodigoAcaoValorExato" + requisicoes.entidade,
            {
              pesquisa: term,
            },
            requisicoes.header
          )
          .then((retorno) => {
            this.setState({
              optionsPessoa: this.converterParaLabel(retorno.data, null),
            });
          });
      } else {
        await api
          .post(
            "/pessoa/pesquisa" + requisicoes.entidade,
            {
              pesquisa: term,
            },
            requisicoes.header
          )
          .then((retorno) => {
            this.setState({
              optionsPessoa: this.converterParaLabelPessoa(retorno.data, null),
            });
          });
      }
    }, 200);
  };

  converterParaLabel = (objetoLista, prefixo) => {
    let lista = [];

    objetoLista.forEach((element) => {
      let objeto = renomearChave(element, "id", "value");

      if (element.acao !== undefined && element.acao !== null) {
        if (element.acao.codigo === null && prefixo !== null) {
          objeto.label =
            prefixo +
            renomearChave(objeto.acao, "codigo_auxiliar", "label").label +
            " - " +
            objeto.nome;
        } else {
          objeto.label =
            renomearChave(objeto.acao, "codigo", "label").label +
            " - " +
            objeto.nome;
        }
        if (element.titular_id !== null) {
          objeto.label += "    - Dependente";
        } else if (element.acao === undefined || element.acao === null) {
          objeto.label += "    - Sem ação";
        } else {
          objeto.label += "    - Titular";
        }
        lista.push(objeto);
      } else {
        objeto.label = objeto.nome;
        lista.push(objeto);
      }
    });
    return lista;
  };
  converterParaLabelPessoa = (objetoLista, prefixo) => {
    let lista2 = [];
    objetoLista.forEach((element) => {
      if (element.acao !== undefined && element.acao !== null) {
        let objeto = renomearChave(element, "id", "value");
        if (element.acao.codigo === null && prefixo !== null) {
          objeto = renomearChave(objeto, "nome", "label");
          objeto.label = prefixo + " " + objeto.label;
        } else {
          objeto = renomearChave(objeto, "nome", "label");
        }
        if (element.titular_id !== null) {
          objeto.label += "    - Dependente";
        } else if (element.acao === undefined || element.acao === null) {
          objeto.label += "    - Sem ação";
        } else {
          objeto.label += "    - Titular";
        }
        lista2.push(objeto);
      } else {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista2.push(objeto);
      }
    });
    return lista2;
  };

  alteracao = async (event, value) => {
    this.setState({ [event.target.name]: event.target.value });
    if (
      event.target.name === "tipo_relatorio" ||
      event.target.name === "agrupar_simplificado"
    ) {
      this.setState({ lista_entradas: [] });
      this.setState({ lista_todas_entradas: [] });
      this.setState({ totalizador: null });
    }
  };

  buscaEntradas = async (event) => {
    // Buscar as entradas utilizando os filtros.
    const lancamentos = await api.post(
      "/entradaHistorico/entradasFiltradas" + requisicoes.entidade,
      {
        data_inicio: this.state.data_inicio,
        data_final: this.state.data_final,
        tipo_relatorio: this.state.tipo_relatorio,
        usuario: this.state.filtros[0].selected ? this.state.usuario : null,
        local: this.state.filtros[1].selected ? this.state.local : null,
        pessoa: this.state.filtros[2].selected
          ? this.state.pesquisaPessoa
          : null,
        titulo: this.state.filtros[3].selected ? this.state.titulo : null,
        tipo_titulo: this.state.filtros[4].selected
          ? this.state.tipoTitulo
          : null,
        agrupar_simplificado: this.state.agrupar_simplificado,
        filtro_autorizacao: this.state.filtro_autorizacao,
      },
      requisicoes.header
    );
    this.setState({ lista_todas_entradas: lancamentos.data[0] });

    if (this.state.tipo_relatorio === "detalhado") {
      this.setState({ totalizador: lancamentos.data[0].length });
    } else {
      let total = 0;
      for (let i = 0; i < lancamentos.data[0].length; i++) {
        const element = lancamentos.data[0][i];
        total += parseInt(element.quantidade);
      }
      this.setState({ totalizador: total });
    }

    let porPagina = 30;
    this.setState({ page: 1 });
    this.setState({
      totalPages: Math.ceil(lancamentos.data[1].rows.length / porPagina),
    });

    if (lancamentos.data[1].rows.length > porPagina) {
      this.setState({
        lista_entradas: lancamentos.data[1].rows.slice(0, porPagina),
      });
    } else {
      this.setState({
        lista_entradas: lancamentos.data[1].rows.slice(
          0,
          lancamentos.data[1].rows.length
        ),
      });
    }
  };

  verificaDatas = async () => {
    if (!moment(this.state.data_inicio, "YYYY-MM-DD", true).isValid()) {
      await this.setState({
        data_inicio: moment().startOf("month").format("YYYY-MM-DD"),
      });
    }
    if (!moment(this.state.data_final, "YYYY-MM-DD", true).isValid()) {
      await this.setState({
        data_final: moment().startOf("month").format("YYYY-MM-DD"),
      });
    }
  };

  pesquisaEntradas = async (event) => {
    await this.verificaDatas();

    await this.buscaEntradas();
  };

  gerarRelatorio = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado?e=" + entidade,
      {},
      {
        headers: { token: "Baer " + token },
      }
    );
    const nomePessoa = await api.post(
      "/pessoa/pessoaIdNome?e=" + entidade,
      {
        id: usuarioLogado.data.pessoa_id,
      },
      {
        headers: { token: "Baer " + token },
      }
    );

    await api.post(
      "/entradaHistorico/gerarPdfEntradas?e=" + entidade,
      {
        data_inicio: this.state.data_inicio,
        data_final: this.state.data_final,
        tipo_relatorio: this.state.tipo_relatorio,
        usuario: this.state.filtros[0].selected ? this.state.usuario : null,
        local: this.state.filtros[1].selected ? this.state.local : null,
        pessoa: this.state.filtros[2].selected
          ? this.state.pesquisaPessoa
          : null,
        titulo: this.state.filtros[3].selected ? this.state.titulo : null,
        tipo_titulo: this.state.filtros[4].selected
          ? this.state.tipoTitulo
          : null,
        agrupar_simplificado: this.state.agrupar_simplificado,
        userId: usuarioLogado.data.pessoa_id,
        nome_operador: nomePessoa.data.nome,
        filtro_autorizacao: this.state.filtro_autorizacao,

      },
      {
        headers: { token: "Baer " + token },
      }
    );
  };

  buscarRelatorio = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado?e=" + entidade,
      {},
      {
        headers: { token: "Baer " + token },
      }
    );
    await api.post(
      "/pessoa/pessoaIdNome?e=" + entidade,
      {
        id: usuarioLogado.data.pessoa_id,
      },
      {
        headers: { token: "Baer " + token },
      }
    );

    const r = await api.get(
      "/entradaHistorico/buscaPdfEntradas?e=" +
        entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.pessoa_id
    );
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "entradaHistorico/buscaPdfEntradas?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.pessoa_id;

    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  imprimirButtonOnClick = async () => {
    this.setState({ imprimindo: true });
    await this.gerarRelatorio();
    await this.buscarRelatorio().then(() =>
      this.setState({ imprimindo: false })
    );
  };

  render() {
    return (
      <div>
        <Row
          style={{
            marginBottom: "10px",
            marginTop: "15px",
            marginLeft: 0,
            marginRight: 0,
            display: "flex",
          }}
        >
          <Modal
            size="mini"
            trigger={
              <Button
                hidden={this.state.tipo_relatorio === "simplificado"}
                content="Adicionar filtros"
                icon="filter"
                floated="left"
              ></Button>
            }
          >
            <Modal.Header>Adicionar filtros</Modal.Header>
            <Modal.Content>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h5>Filtros adicionados</h5>
              </Col>
              <Table celled>
                <Table.Body>
                  {this.state.filtros.map((e, i) => (
                    <>
                      {e.selected === true && (
                        <Table.Row>
                          <Table.Cell style={{ textAlignVertical: "center" }}>
                            <h5>{e.name}</h5>
                          </Table.Cell>

                          <Table.Cell width={1}>
                            <Button
                              onClick={() => {
                                let a = this.state.filtros;
                                a[i].selected = false;
                                this.setState({ filtros: a });
                              }}
                              size="tiny"
                              icon="x"
                              color="red"
                            ></Button>
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </>
                  ))}
                </Table.Body>
              </Table>
              <Divider></Divider>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <h5>Filtros para adicionar</h5>
              </Col>
              <Table celled>
                <Table.Body>
                  {this.state.filtros.map((e, i) => (
                    <>
                      {e.selected === false && (
                        <Table.Row>
                          <Table.Cell style={{ textAlignVertical: "center" }}>
                            <h5>{e.name}</h5>
                          </Table.Cell>

                          <Table.Cell width={1}>
                            <Button
                              onClick={() => {
                                let a = this.state.filtros;
                                for (let j = 0; j < a.length; j++) {
                                  const element = a[j];
                                  if (element.group === a[i].group) {
                                    element.selected = false;
                                  }
                                }
                                a[i].selected = true;
                                this.setState({ filtros: a });
                              }}
                              size="tiny"
                              icon="add"
                              color="green"
                            ></Button>
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </>
                  ))}
                </Table.Body>
              </Table>
            </Modal.Content>
          </Modal>

          <Button
            positive
            style={{
              marginLeft: "auto",
            }}
            onClick={this.pesquisaEntradas}
          >
            Buscar
          </Button>
          <Button
            primary
            loading={this.state.imprimindo}
            onClick={this.imprimirButtonOnClick}
            disabled={
              this.state.lista_entradas.length === 0 || this.state.imprimindo
            }
          >
            Imprimir
          </Button>
        </Row>
        <Row>
          <Col sm={2}>
            <Form.Label>Data Inicio</Form.Label>
            <Form.Control
              placeholder="Data Inicio"
              aria-describedby="basic-addon1"
              type="date"
              name="data_inicio"
              value={this.state.data_inicio}
              onChange={this.alteracao}
            />
          </Col>
          <Col sm={2}>
            <Form.Label>Data Final</Form.Label>
            <Form.Control
              placeholder="Data Final"
              aria-describedby="basic-addon1"
              type="date"
              name="data_final"
              value={this.state.data_final}
              onChange={this.alteracao}
            />
          </Col>
          {this.state.filtros[0].selected ? (
            <Col sm={2} hidden={this.state.tipo_relatorio === "simplificado"}>
              <Form.Label>Usuário</Form.Label>
              <Select
                placeholder="Usuário"
                name="usuario"
                id="usuario"
                onChange={(e) => this.setState({ usuario: e.value })}
                options={this.state.usuarioOptions}
              ></Select>
            </Col>
          ) : null}

          {this.state.filtros[1].selected ? (
            <Col sm={2} hidden={this.state.tipo_relatorio === "simplificado"}>
              <Form.Label>Local</Form.Label>
              <Select
                placeholder="Local"
                name="local"
                id="local"
                onChange={(e) => this.setState({ local: e.value })}
                options={this.state.locaisOptions}
              ></Select>
            </Col>
          ) : null}

          {this.state.filtros[2].selected ? (
            <Col sm={4} hidden={this.state.tipo_relatorio === "simplificado"}>
              <Form.Label>Pessoa</Form.Label>
              <Autocomplete
                id="pesquisaPessoa"
                value={this.state.pessoa}
                // inputValue={this.state.inputValue}
                // onInputChange={(event, newInputValue) => {
                //   this.setState({ inputValue: newInputValue });
                // }}
                options={this.state.optionsPessoa}
                getOptionLabel={(option) => option.label}
                onKeyUp={this.pesquisaPessoaAcao}
                onChange={(e, v) => this.setState({ pesquisaPessoa: v.value })}
                filterSelectedOptions={false}
                filterOptions={(options, state) => options}
                renderInput={(params) => (
                  <TextField {...params} label="Busca" size="small" />
                )}
              />
            </Col>
          ) : null}

          {this.state.filtros[3].selected ? (
            <Col sm={2} hidden={this.state.tipo_relatorio === "simplificado"}>
              <Form.Label>Título</Form.Label>
              <Form.Control
                type="number"
                placeholder="Título"
                name="titulo"
                id="titulo"
                onChange={this.alteracao}
              ></Form.Control>
            </Col>
          ) : null}

          {this.state.filtros[4].selected ? (
            <Col sm={2} hidden={this.state.tipo_relatorio === "simplificado"}>
              <Form.Label>Tipo de Título</Form.Label>
              <Select
                placeholder="Tipo de Título"
                name="tipoTitulo"
                id="tipoTitulo"
                onChange={(e) => this.setState({ tipoTitulo: e.value })}
                options={this.state.tipoTituloOptions}
              ></Select>
            </Col>
          ) : null}

          {
            <Col sm={3} hidden={this.state.tipo_relatorio === "detalhado"}>
              <Form.Label>Agrupar por</Form.Label>
              <Form.Control
                as="select"
                name="agrupar_simplificado"
                value={this.state.agrupar_simplificado}
                id="agrupar_simplificado"
                required
                onChange={this.alteracao}
              >
                <option value="data">Data de entrada</option>
                <option value="tipoTitulo">Tipo de título</option>
                <option value="local">Local</option>
                <option value="pessoa">Pessoa</option>
                <option value="bloqueados">Bloqueados</option>
                <option value="liberados">Liberados</option>
              </Form.Control>
            </Col>
          }
          {
            <Col sm={3}>
              <Form.Label>Filtrar por</Form.Label>
              <Form.Control
                as="select"
                name="filtro_autorizacao"
                value={this.state.filtro_autorizacao}
                id="filtro_autorizacao"
                required
                onChange={this.alteracao}
              >
                <option value="todos">Todos</option>
                <option value="bloqueados">Bloqueados</option>
                <option value="liberados">Liberados</option>
              </Form.Control>
            </Col>
          }

          <Col sm={2}>
            <Form.Label>Tipo do Relatório</Form.Label>
            <Form.Control
              as="select"
              name="tipo_relatorio"
              checked={this.state.tipo}
              id="tipo_relatorio"
              required
              onChange={this.alteracao}
            >
              <option value="simplificado">Simplificado</option>
              <option value="detalhado">Detalhado</option>
            </Form.Control>
          </Col>
        </Row>
        <Row
          style={{
            marginLeft: 0,
            marginRight: 0,
            marginTop: 15,
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          {this.state.totalizador !== null && (
            <h5>{"Total de entradas: " + this.state.totalizador}</h5>
          )}
        </Row>
        {
          <div
            style={{
              paddingBottom: 100,
              paddingRight: 0,
              paddingLeft: 0,
              paddingTop: 15,
            }}
          >
            {this.state.tipo_relatorio === "simplificado" && (
              <Table celled selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Agrupamento</Table.HeaderCell>
                    <Table.HeaderCell>Quantidade</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.lista_entradas.map((e, i) => (
                    <Table.Row>
                      <Table.Cell>
                        {this.state.agrupar_simplificado === "data"
                          ? moment(e.data_entrada).format("DD/MM/YYYY")
                          : this.state.agrupar_simplificado === "tipoTitulo" ||
                            this.state.agrupar_simplificado === "local"
                          ? e.descricao !== null
                            ? e.descricao
                            : "Cancela"
                          : this.state.agrupar_simplificado === "pessoa"
                          ? e.nome
                          : ""}
                      </Table.Cell>
                      <Table.Cell>{e.quantidade}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
                {this.state.lista_todas_entradas.length > 3 &&
                  this.state.totalizador !== null && (
                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell>
                          {this.state.lista_todas_entradas.length}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {this.state.totalizador}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Footer>
                  )}
              </Table>
            )}
            {this.state.tipo_relatorio === "detalhado" && (
              <Table celled selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Título</Table.HeaderCell>
                    <Table.HeaderCell>Nome</Table.HeaderCell>
                    <Table.HeaderCell>Motivo</Table.HeaderCell>
                    <Table.HeaderCell>Data de acesso</Table.HeaderCell>
                    <Table.HeaderCell>Local</Table.HeaderCell>
                    <Table.HeaderCell>Tipo de Título</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.lista_entradas.map((e, i) => (
                    <Table.Row
                      negative={e.autorizacao === false}
                      positive={e.autorizacao !== false}
                    >
                      <Table.Cell>
                        {e.codigo === null
                          ? e.codigo_auxiliar !== null
                            ? e.prefixo + " " + e.codigo_auxiliar
                            : ""
                          : e.codigo}
                      </Table.Cell>
                      <Table.Cell>{e.pessoa_nome}</Table.Cell>
                      <Table.Cell>
                        {e.autorizacao === true ? "Liberado" : e.motivo}
                      </Table.Cell>
                      <Table.Cell>
                        {moment(e.data_entrada).format("DD/MM/YYYY") +
                          " - " +
                          e.hora_entrada.slice(0, 5)}
                      </Table.Cell>
                      <Table.Cell>
                        {e.catraca_descricao === undefined ||
                        e.catraca_descricao === null
                          ? "cancela"
                          : e.catraca_descricao}
                      </Table.Cell>
                      <Table.Cell>{e.tipo_titulo}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}
            <Container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Pagination
                boundaryRange={0}
                ellipsisItem={null}
                siblingRange={1}
                activePage={this.state.page}
                totalPages={this.state.totalPages}
                onPageChange={(e, data) => {
                  this.setState({ page: data.activePage });
                  var lista = this.state.lista_todas_entradas;
                  var porPagina = 30;
                  if (lista.length > porPagina * data.activePage) {
                    this.setState({
                      lista_entradas: lista.slice(
                        porPagina * (data.activePage - 1),
                        porPagina * data.activePage
                      ),
                    });
                  } else {
                    this.setState({
                      lista_entradas: lista.slice(
                        porPagina * (data.activePage - 1),
                        lista.length
                      ),
                    });
                  }
                }}
              />
            </Container>
          </div>
        }
      </div>
    );
  }
}
