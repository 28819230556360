import React, { Component } from "react";
import {
  Table,
  Button,
  Header,
  Search,
  Pagination,
  Checkbox,
  Menu,
} from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import requisicoes from "../../../utils/Requisicoes";
import moment from "moment";
import Treino from "./treino";
import ListarPadrao from "./listarPadrao";

export default class TreinoLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo_clube: window.localStorage.getItem("tipo_clube"),
      selectedMenu: localStorage.getItem("subpagina_anterior"),
    };
  }

  render() {
    let content = "";
    switch (this.state.selectedMenu) {
      case "treino":
        localStorage.setItem("pagina_anterior", "treino");
        localStorage.setItem("subpagina_anterior", "treino");
        content = <Treino />;
        break;
      case "CadastrarPadrao":
        localStorage.setItem("pagina_anterior", "treino");
        localStorage.setItem("subpagina_anterior", "CadastrarPadrao");
        content = <ListarPadrao />;
        break;

      default:
        break;
    }
    return (
      <div className="lista">
        <Menu pointing secondary>
          <Menu.Item
            name="Treinos"
            active={this.state.selectedMenu === "treino"}
            onClick={this.selectMenu.bind(null, "treino")}
          />
          <Menu.Item
            name="Cadastrar Padrões"
            active={this.state.selectedMenu === "CadastrarPadrao"}
            onClick={this.selectMenu.bind(null, "CadastrarPadrao")}
          />
        </Menu>
        {content}
      </div>
    );
  }

  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
  };
}
