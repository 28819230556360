const IMAGES = {
  getImage: (entidade) => {
    try {
      return require(`./${entidade}.png`).default;
    } catch (error) {
      return null;
    }
  },
};

export default IMAGES;
