import React, { Component } from "react";
import { Button, Header, Card, Image, Segment } from "semantic-ui-react";
import { Col, Form, FormControl, Row } from "react-bootstrap";
import { Slide } from "react-slideshow-image";
import moment from "moment";
import requisicoes from "../../../utils/Requisicoes";
import api from "../../../services/api";
import CardExames from "./cardExames";
import CardLancamentos from "./cardLancamentos";
import CardMatriculas from "./cardMatriculas";
import CardReservas from "./cardReservas";
import { storage } from "../../../config/firebase";

export default class titular extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reservas: [],
      matriculas: [],
      lista_anos: this.geraListaDeAnos(),
      anoLancamento: moment().format("YYYY"),
      exames_fisico: [],
      exames_medico: [],
      listaLancamentos: [],
      total_lancamentos: [],
      tipoLancamento: [],
      novoEmail: [],
      pessoa: null,
      infoPessoa: {},
      listaEventos: [],
      indexListaEventosRender: [],
    };
    this.visualizacao();
  }

  comparaDatas(data) {
    return new Date(data) >= new Date() ? true : false;
  }

  geraListaDeAnos = () => {
    var years = [];
    const dateStart = moment("2008-01-01");
    const dateEnd = moment();
    while (dateEnd.format("YYYY") !== dateStart.format("YYYY")) {
      years.push(dateStart.format("YYYY"));
      dateStart.add(1, "year");
    }
    years.push(dateEnd.format("YYYY"));
    years.reverse();
    return years;
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === "tipoLancamento") {
      this.setState({ listaLancamentos: [] });
    }
  };

  visualizacao = async () => {
    const eventos = await api.post(
      "/eventoClube/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    let listaEventos = [];
    eventos.data.forEach(async (evento) => {
      let imagem = false;
      await storage
        .ref(
          `${
            process.env.REACT_APP_ENVIRONMENT === "production"
              ? "producao"
              : "hml"
          }/${window.localStorage.getItem("e_clube")}/evento`
        )
        .child(`${evento.id}.jpg`)
        .getDownloadURL()
        .then((url) => {
          imagem = url;
        });

      listaEventos.push({
        evento: evento,
        imagem: imagem,
      });
    });
    this.setState({ listaEventos: listaEventos });

    let indexListaEventosRender = [];
    for (let i = 0, j = 0; i < eventos.data.length; i = i + 2, j++) {
      const element = eventos.data[i];
      const element2 = eventos.data[i + 1];
      indexListaEventosRender[j] = [];
      if (element) {
        indexListaEventosRender[j][0] = i;
      }
      if (element2) {
        indexListaEventosRender[j][1] = i + 1;
      }
    }
    this.setState({ indexListaEventosRender: indexListaEventosRender });
    // const respostaQRcode = await api.post(
    //   "/usuario/matricula" + requisicoes.entidade,
    //   "",
    //   requisicoes.header
    // );

    // const resposta = await api.post(
    //   "/usuario/visualiza" + requisicoes.entidade,
    //   { id: respostaQRcode.data.matricula },
    //   requisicoes.header
    // );

    const resposta = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    const respPessoa = await api.post(
      "/pessoa/pessoaIdNome" + requisicoes.entidade,
      { id: resposta.data.pessoa_id },
      requisicoes.header
    );
    this.setState({ pessoa: respPessoa.data });

    const respPessoaInfo = await api.post(
      "/pessoa/visualiza" + requisicoes.entidade,
      { id: resposta.data.pessoa_id },
      requisicoes.header
    );
    this.setState({ infoPessoa: respPessoaInfo.data });

    this.examesPorPessoa(resposta.data.pessoa_id);
    const respLancamentos = await api.post(
      "lancamentoConta/lancamentosAbertosPorPessoa" + requisicoes.entidade,
      {
        pessoa_id: resposta.data.pessoa_id,
      },
      requisicoes.header
    );
    function ordenaDatasLancamentos(a, b) {
      if (a.data_vencimento !== null && b.data_vencimento !== null) {
        if (a.data_vencimento > b.data_vencimento) {
          return 1;
        }
        if (a.data_vencimento < b.data_vencimento) {
          return -1;
        }
        return 0;
      }
    }
    let listaLancamentos = respLancamentos.data;
    listaLancamentos.sort(ordenaDatasLancamentos);
    this.visualizaDebitoConta();
    this.setState({ listaLancamentos: listaLancamentos });
    let total = 0.0;
    for (let i = 0; i < listaLancamentos.length; i++) {
      const element = listaLancamentos[i];
      total += parseFloat(element.valor);
    }
    this.setState({ total_lancamentos: total });

    const respMatriculas = await api.post(
      "/matriculaTurma/matriculaPorPessoa" + requisicoes.entidade,
      { id: resposta.data.pessoa_id },
      requisicoes.header
    );
    this.setState({ matriculas: respMatriculas.data });

    const respReservas = await api.post(
      "/reservaHorario/horarioPorPessoa" + requisicoes.entidade,
      { id: resposta.data.pessoa_id },
      requisicoes.header
    );

    for (let i = 0; i < respReservas.data.length; i++) {
      const element = respReservas.data[i];
      let lista = [];
      if (element.dataCancelamento === null) {
        lista.push(element);
        this.setState({ reservas: lista });
      }
    }

    const dependentesConst = await api.post(
      "/pessoa/dependentes" + requisicoes.entidade,
      { titular_id: resposta.data.pessoa_id },
      requisicoes.header
    );

    let respMatriculasDep = "";
    var matriculasDependentes = [];

    for (let i = 0; i < dependentesConst.data.length; i++) {
      const element = dependentesConst.data[i];
      respMatriculasDep = await api.post(
        "/matriculaTurma/matriculaPorPessoa" + requisicoes.entidade,
        { id: element.id },
        requisicoes.header
      );
      matriculasDependentes.push(respMatriculasDep.data);
    }
    this.setState({ matriculasDependentes: matriculasDependentes });
  };

  examesPorPessoa = async (pessoa_id) => {
    await api
      .post(
        "/exameFisico/examePorPessoa" + requisicoes.entidade,
        { id: pessoa_id },
        requisicoes.header
      )
      .then((e) => {
        this.setState({ exames_fisico: e.data });
      });

    await api
      .post(
        "/exameMedico/examePorPessoa" + requisicoes.entidade,
        { id: pessoa_id },
        requisicoes.header
      )
      .then((e) => {
        this.setState({ exames_medico: e.data });
      });
  };

  visualizaDebitoConta = async () => {
    setTimeout(async () => {
      if (
        localStorage.getItem("tipo_usuario_clube") === "administradorSistema" ||
        localStorage.getItem("tipo_usuario_clube") === "administrador" ||
        localStorage.getItem("tipo_usuario_clube") === "caixa"
      ) {
        var tipo_cobranca = document.getElementById("tipo_cobranca");
        if (tipo_cobranca !== null) {
          // if (this.state.infoPessoa.tipo_cobranca === "boleto") {
          //   tipo_cobranca.setAttribute("hidden", "");
          // } else {
          //   tipo_cobranca.removeAttribute("hidden");
          // }
          if (this.state.infoPessoa.debito_conta === null) {
            this.setState({ debito_conta: null });
          } else {
            let debito_conta = this.state.infoPessoa.debito_conta;
            if (debito_conta === undefined) {
              debito_conta = null;
            }
            if (debito_conta !== null) {
              debito_conta.agencia_debito_conta.value =
                debito_conta.agencia_debito_conta.id;
              debito_conta.agencia_debito_conta.label =
                debito_conta.agencia_debito_conta.agencia;
            }
            this.setState({ debito_conta: debito_conta });
          }
        }
      }
    }, 1000);
  };

  cancelarMatricula = async (event) => {
    if (this.state.motivo_cancelamento === null) {
      alert("Favor Informar Um Motivo");
    } else {
      const retorno = await api.post(
        "/matriculaTurma/cancelar" + requisicoes.entidade,
        {
          matricula_id: event.target.value,
          motivo: this.state.motivo_cancelamento,
        },
        requisicoes.header
      );
      if (retorno.data !== null) {
        window.location.reload();
      }
    }
  };

  cancelarReserva = async (event) => {
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    const responsavel_id = usuarioLogado.data.pessoa_id;
    if (this.state.motivo_cancelamento === null) {
      alert("Favor Informar Um Motivo");
    } else {
      const retorno = await api.post(
        "/reservaHorario/cancelar" + requisicoes.entidade,
        {
          id: event.target.value,
          motivo: this.state.motivo_cancelamento,
          resposavel_id: responsavel_id,
          plataforma: "web",
        },
        requisicoes.header
      );
      if (retorno.data !== null) {
        window.location.reload();
      }
    }
  };

  render() {
    return (
      <div>
        <Card.Group itemsPerRow={2}>
          <Card color={"green"}>
            <CardReservas
              reservas={this.state.reservas}
              alteracao={this.alteracao}
              cancelarReserva={this.cancelarReserva}
            />
          </Card>
          <Card color={"green"}>
            <CardMatriculas
              matriculas={this.state.matriculas}
              alteracao={this.alteracao}
              cancelarMatricula={this.cancelarMatricula}
              dependentes={this.state.dependentes}
              matriculasDependentes={this.state.matriculasDependentes}
              lista_anos={this.state.lista_anos}
            />
          </Card>
          <Card color={"green"}>
            {/* <CardExames
              comparaDatas={this.comparaDatas}
              examesFisicos={this.state.exames_fisico}
              examesMedicos={this.state.exames_medico}
            /> */}
            <Card.Content
              style={{
                maxHeight: 250,
                flex: 1,
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <Card.Header>Exame Médico</Card.Header>
              <Card.Description>
                <Row>
                  <Col sm={6}>
                    <Form.Label>Data Validade</Form.Label>
                    <FormControl
                      type="date"
                      aria-describedby="basic-addon1"
                      disabled={true}
                      value={
                        this.state.exames_medico.length > 0 &&
                        this.state.exames_medico[0] !== null &&
                        this.state.exames_medico[0].dataValidade
                      }
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Label>Autorização Médica</Form.Label>
                    {this.state.exames_medico.length > 0 &&
                    this.state.exames_medico[0] !== null &&
                    this.comparaDatas(
                      this.state.exames_medico[0].dataValidade
                    ) ? (
                      <Button fluid icon="thumbs up" positive></Button>
                    ) : (
                      <Button fluid icon="thumbs down" negative></Button>
                    )}
                  </Col>
                </Row>
              </Card.Description>
              <Card.Header style={{ paddingTop: 10 }}>Exame Físico</Card.Header>

              <Card.Description>
                <Row>
                  <Col sm={6}>
                    <Form.Label>Data Validade</Form.Label>
                    <FormControl
                      type="date"
                      aria-describedby="basic-addon1"
                      disabled={true}
                      value={
                        this.state.exames_fisico.length > 0 &&
                        this.state.exames_fisico[0] !== null &&
                        this.state.exames_fisico[0].dataValidade
                      }
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Label>Autorização Médica</Form.Label>

                    {this.state.exames_fisico.length > 0 &&
                    this.state.exames_fisico[0] !== null &&
                    this.comparaDatas(
                      this.state.exames_fisico[0].dataValidade
                    ) ? (
                      <Button fluid icon="thumbs up" positive></Button>
                    ) : (
                      <Button fluid icon="thumbs down" negative></Button>
                    )}
                  </Col>
                </Row>
              </Card.Description>
            </Card.Content>
          </Card>
          {this.state.listaLancamentos !== undefined &&
            this.state.listaLancamentos.length > 0 && (
              <Card color={"green"}>
                <CardLancamentos
                  listaLancamentos={this.state.listaLancamentos}
                  comparaDatas={this.comparaDatas}
                  total_lancamentos={this.state.total_lancamentos}
                  tipoLancamento={this.state.tipoLancamento}
                  novoEmail={this.state.novoEmail}
                  pessoa={this.state.infoPessoa}
                />
              </Card>
            )}
          {/* {this.state.listaLancamentos !== undefined &&
            this.state.listaLancamentos.length > 0 && (
              <Card color={"green"}>
                <CardLancamentos
                  listaLancamentos={this.state.listaLancamentos}
                  comparaDatas={this.comparaDatas}
                  total_lancamentos={this.state.total_lancamentos}
                  tipoLancamento={this.state.tipoLancamento}
                  novoEmail={this.state.novoEmail}
                  pessoa={this.state.infoPessoa}
                />
              </Card>
            )} */}
        </Card.Group>
        <Header as="h2" dividing>
          Eventos
        </Header>
        <Card fluid>
          <Slide>
            {this.state.indexListaEventosRender.map((item, index) => {
              return (
                <Row
                  style={{
                    margin: 0,
                  }}
                >
                  {item.map((e, i) => (
                    <Col style={{ padding: 0 }} sm={6}>
                      <div
                        style={{
                          maxHeight: "42vw",
                        }}
                      >
                        <Image
                          href={this.state.listaEventos[e]?.imagem}
                          target="_blank"
                          src={this.state.listaEventos[e]?.imagem}
                          height="auto"
                        />
                      </div>

                      <Segment
                        style={{
                          position: "absolute",
                          bottom: 0,
                          backgroundColor: "rgba(255, 255, 255, 0.75)",
                          borderColor: this.state.listaEventos[e]?.evento.cor,
                          borderWidth: 3,
                          width: "100%",
                        }}
                      >
                        <Card.Content>
                          <Card.Description>
                            <Header>
                              {this.state.listaEventos[e]?.evento.titulo}
                            </Header>
                            <Card.Meta>
                              {this.state.listaEventos[e]?.evento.subtitulo}
                            </Card.Meta>
                            <div style={{ fontSize: 15 }}>
                              {this.state.listaEventos[e]?.evento.descricao
                                .length > 100
                                ? this.state.listaEventos[
                                    e
                                  ]?.evento.descricao.substring(0, 100) + "..."
                                : this.state.listaEventos[
                                    e
                                  ]?.evento.descricao.substring(0, 100)}
                            </div>
                          </Card.Description>
                        </Card.Content>
                      </Segment>
                    </Col>
                  ))}
                </Row>
              );
            })}
          </Slide>
        </Card>
      </div>
    );
  }
}
