import React, { Component } from "react";
import {
  Header,
  Button,
  Divider,
  Message,
  Form,
  Menu,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";

// Componentes criados
import ParametrosGeral from "./geral";
import ParametrosDiretoria from "./diretoria";
import ParametrosEmail from "./email";
// import Carteirinhas from "./carteirinhas";
import ParametrosFaee from "./parametrosFaee";
import Carteirinhas from "./carteirinha/carteirinhas";
import Perfis from "./perfis/index";

// Requisição
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

// utils
import moment from "moment";
import { storage } from "../../../config/firebase";

export default class Parametros extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaParametros: null,
      loading: false,
      success: false,
      menuSelect: "geral",

      // geral
      nomeClube: "",
      cnpjClube: "",
      cidadeClube: "",
      ufClube: "",
      enderecoClube: "",
      bairroClube: "",
      numeroClube: "",
      cepClube: "",
      estadoClube: "",
      instituicao_cobrancao_boleto_servico: "",
      instituicoesOptions: [],

      cnpjValido: true,

      imagem: null,
      dia_cobranca_matricula: null,

      optionsServico: [],
      servico_mensalidade_id: null,

      porcentagem_juros: "",
      porcentagem_multa: "",

      // diretoria
      optionsCargosDiretoria: [],
      optionsPessoas: [],
      optionsGestoesDiretoria: [],
      diretoria: [],
      gestao: null,
      novaGestao: false,
      inicioGestao: null,
      fimGestao: null,

      // email
      email: null,
      clientId: null,
      clientSecret: null,
      refreshToken: null,

      // Contabil
      servico_juros_exportacao_id: null,
      servico_desconto_exportacao_id: null,

      // loading tela
      buscando: true,
    };
    this.fazBuscas();
  }

  fazBuscas = async () => {
    let promisses = [];
    promisses.push(this.listaParametros());
    promisses.push(this.buscaCargos());
    promisses.push(this.buscaGestoes());
    promisses.push(this.buscaEntidade());
    promisses.push(this.buscarServicos());
    promisses.push(this.buscarInstituicoesBoletos());

    await Promise.all(promisses);
    this.setState({ buscando: false });
  };

  buscarInstituicoesBoletos = async () => {
    const instituicoes = await api.post(
      "/instituicaoCobranca/lista" + requisicoes.entidade,
      { boleto: true, debito_em_conta: false },
      requisicoes.header
    );
    this.setState({
      instituicoesOptions: instituicoes.data.map((e) => {
        return {
          value: parseInt(e.id),
          key: e.id,
          text: e.descricao + " - Ag: " + e.agencia + " - CC: " + e.conta,
        };
      }),
    });
  };

  buscarServicos = async () => {
    const servicos = await api.post(
      "/servico/listaDiversos" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    let lista = [];
    for (let i = 0; i < servicos.data.length; i++) {
      const element = servicos.data[i];
      lista.push({ value: element.id, key: element.id, text: element.nome });
    }

    this.setState({ optionsServico: lista });
  };

  buscaEntidade = async () => {
    const entidade = await api.post(
      "/entidade/visualizaDadosEntidade" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    this.setState({
      nomeClube: entidade.data.nome,
      cnpjClube: entidade.data.cnpj,
      cidadeClube: entidade.data.cidade,
      ufClube: entidade.data.uf,
      enderecoClube: entidade.data.endereco,
      bairroClube: entidade.data.bairro,
      numeroClube: entidade.data.numero,
      cepClube: entidade.data.cep,
      estadoClube: entidade.data.estado,
      entidade: entidade.data,
    });
  };

  listaParametros = async () => {
    var listaParametros = await api.post(
      "/parametros/lista" + requisicoes.entidade,
      {
        // nome: "dia_cobranca_matricula",
      },
      requisicoes.header
    );

    let parametros = {};
    for (let i = 0; i < listaParametros.data.length; i++) {
      const element = listaParametros.data[i];

      // Aqui precisa ser ==
      // eslint-disable-next-line eqeqeq
      if (element.valor == parseInt(element.valor)) {
        parametros[element.nome] = parseInt(element.valor);
      } else {
        parametros[element.nome] = element.valor;
      }
    }
    this.setState({ listaParametros: listaParametros.data, ...parametros });
  };

  buscaCargos = async (event, a) => {
    let resp = await api.post(
      "/funcionario/listaCargosTipo" + requisicoes.entidade,
      {
        tipo: "diretoria",
      },
      requisicoes.header
    );
    let lista = [];

    for (let i = 0; i < resp.data.length; i++) {
      const element = resp.data[i];
      lista.push({ key: element.id, text: element.nome, value: element.id });
    }
    this.setState({ optionsCargosDiretoria: lista });
  };

  buscaPessoa = async (event, a, cargo_id) => {
    const retorno = await api.post(
      "/pessoa/pesquisa" + requisicoes.entidade,
      { pesquisa: event.target.value },
      requisicoes.header
    );

    let lista = [];
    retorno.data.forEach((element) => {
      let objeto = {};
      objeto.key = element.id;
      objeto.value = element.id;
      objeto.text = element.nome;
      lista.push(objeto);
    });

    let diretoria = this.state.diretoria;

    if (
      diretoria.find((element) => element.cargo_id === cargo_id) !== undefined
    ) {
      diretoria.find((element) => element.cargo_id === cargo_id).options =
        lista;
    } else {
      let item = {};
      item.cargo_id = cargo_id;
      item.options = lista;
      if (this.state.novaGestao === true) {
        item.data_admissao = this.state.inicioGestao + "-01-01";
        item.data_desocupacao = this.state.fimGestao + "-12-31";
      } else {
        item.data_admissao =
          this.state.optionsGestoesDiretoria[this.state.gestao].data_admissao;
        item.data_desocupacao =
          this.state.optionsGestoesDiretoria[
            this.state.gestao
          ].data_desocupacao;
      }
      item.pessoa_id = null;
      diretoria.push(item);
    }
    this.setState({ diretoria: diretoria });
  };

  buscaGestoes = async (event, a) => {
    const retorno = await api.post(
      "/funcionario/buscaGestoes" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    let lista = [];
    retorno.data.forEach((element, i) => {
      element.key = i;
      element.value = i;
      element.text =
        moment(element.data_admissao).year() +
        " - " +
        moment(element.data_desocupacao).year();
      lista.push(element);
    });
    this.setState({ optionsGestoesDiretoria: lista });
    if (lista.length > 0) {
      this.setState({ gestao: 0 });
      this.buscaDiretoriaGestao(0);
    }
  };

  buscaDiretoriaGestao = async (indexLista) => {
    const retorno = await api.post(
      "/funcionario/buscaDiretoriaGestao" + requisicoes.entidade,
      {
        data_admissao:
          this.state.optionsGestoesDiretoria[indexLista].data_admissao,
        data_desocupacao:
          this.state.optionsGestoesDiretoria[indexLista].data_desocupacao,
      },
      requisicoes.header
    );

    for (let i = 0; i < retorno.data.length; i++) {
      const element = retorno.data[i];
      element.options = [
        {
          key: element.pessoa.id,
          value: element.pessoa.id,
          text: element.pessoa.nome,
        },
      ];
    }

    this.setState({ diretoria: retorno.data });
  };

  alteracao = (event, a) => {
    this.setState({ [event.target.name]: event.target.value });
    if (this.state.sucess === true) {
      this.setState({ sucess: false });
    }
  };

  atualizaNovaGestao = (inicioGestao, fimGestao) => {
    if (inicioGestao !== undefined && inicioGestao !== null) {
      this.setState({ inicioGestao: inicioGestao });
      if (this.state.fimGestao !== null && this.state.gestao === null) {
        this.setState({
          gestao: {
            gestao: 0,
          },
        });
      }
    }

    if (fimGestao !== null && fimGestao !== undefined) {
      this.setState({ fimGestao: fimGestao });
      if (this.state.inicioGestao !== null && this.state.gestao === null) {
        this.setState({
          gestao: {
            gestao: 0,
          },
        });
      }
    }

    if (
      (this.state.inicioGestao !== null && fimGestao !== undefined) ||
      (this.state.fimGestao !== null && inicioGestao !== undefined)
    ) {
      // Atualizar as datas de todos que já foram cadastradados.
      // Isso é necessário casa a data seja alterada depois de já ter adicionado
      // alguma pessoa
      let diretoria = this.state.diretoria;

      for (let i = 0; i < diretoria.length; i++) {
        const element = diretoria[i];
        element.data_admissao =
          inicioGestao !== undefined
            ? inicioGestao
            : this.state.inicioGestao + "-01-01";
        element.data_desocupacao =
          fimGestao !== undefined ? fimGestao : this.state.fimGestao + "-12-31";
      }
      this.setState({ diretoria: diretoria });
    }
  };

  submit = async () => {
    this.setState({ loading: true });

    if (this.state.novaGestao === true) {
      if (
        this.state.optionsGestoesDiretoria.length > 0 &&
        (this.state.inicioGestao <
          this.state.optionsGestoesDiretoria[0].data_desocupacao ||
          this.state.fimGestao <
          this.state.optionsGestoesDiretoria[0].data_desocupacao)
      ) {
        this.setState({ loading: false });
        return;
      }
      if (this.state.inicioGestao > this.state.fimGestao) {
        this.setState({ loading: false });
        return;
      }
    }

    // Salvar os dados da entidade
    if (!this.state.cnpjValido || this.state.cnpjClube === "") {
      alert("Cnpj não é válido!");
      this.setState({ loading: false });

      return;
    }
    let entidade = {
      nome: this.state.nomeClube,
      cnpj: this.state.cnpjClube,
      cidade: this.state.cidadeClube,
      uf: this.state.ufClube,
      endereco: this.state.enderecoClube,
      bairro: this.state.bairroClube,
      numero: this.state.numeroClube,
      cep: this.state.cepClube,
      estado: this.state.estadoClube,
    };

    await api.post(
      "/entidade/alteraDadosEntidade" + requisicoes.entidade,
      entidade,
      requisicoes.header
    );

    //Salva os parametros
    let listaParametros = this.state.listaParametros;
    for (let i = 0; i < listaParametros.length; i++) {
      const element = listaParametros[i];
      element.valor = this.state[element.nome];
    }
    await api.post(
      "/parametros/editarVarios" + requisicoes.entidade,
      {
        listaParametros: listaParametros,
      },
      requisicoes.header
    );

    //salvar a diretoria
    await api.post(
      "/funcionario/salvarGestao" + requisicoes.entidade,
      {
        diretoria: this.state.diretoria,
      },
      requisicoes.header
    );

    // Salvar a imagem
    if (this.state.imagem !== null) {
      const uploadTask = storage

        .ref(
          `${process.env.REACT_APP_ENVIRONMENT === "production"
            ? "producao"
            : "hml"
          }/${window.localStorage.getItem("e_clube")}/logo/logo.png`
        )
        .put(this.state.imagem);
      uploadTask.on("state_changed", null, null, async () => {
        await api.post(
          "/parametros/baixarImagemLogo" + requisicoes.entidade,
          {
            projeto:
              process.env.REACT_APP_ENVIRONMENT === "production"
                ? "producao"
                : "hml",
          },
          requisicoes.header
        );
        this.setState({ loading: false });
        this.setState({ sucess: true });
      });
    } else {
      this.setState({ loading: false });
      this.setState({ sucess: true });
    }
  };

  render() {
    var content = null;
    switch (this.state.menuSelect) {
      case "geral":
        content = (
          <ParametrosGeral
            alteracao={this.alteracao}
            imagem={this.state.imagem}
            setState={(a) => this.setState(a)}
            nomeClube={this.state.nomeClube}
            cnpjClube={this.state.cnpjClube}
            cidadeClube={this.state.cidadeClube}
            ufClube={this.state.ufClube}
            enderecoClube={this.state.enderecoClube}
            bairroClube={this.state.bairroClube}
            numeroClube={this.state.numeroClube}
            cepClube={this.state.cepClube}
            estadoClube={this.state.estadoClube}
            dia_cobranca_matricula={this.state.dia_cobranca_matricula}
            servico_mensalidade_id={this.state.servico_mensalidade_id}
            instituicao_cobrancao_boleto_servico={
              this.state.instituicao_cobrancao_boleto_servico
            }
            optionsServico={this.state.optionsServico}
            instituicoesOptions={this.state.instituicoesOptions}
            porcentagem_juros={this.state.porcentagem_juros}
            porcentagem_multa={this.state.porcentagem_multa}
          />
        );
        break;
      case "diretoria":
        content = (
          <ParametrosDiretoria
            alteracao={this.alteracao}
            atualizaNovaGestao={this.atualizaNovaGestao}
            buscaDiretoriaGestao={this.buscaDiretoriaGestao}
            buscaPessoa={this.buscaPessoa}
            setState={(a) => this.setState(a)}
            novaGestao={this.state.novaGestao}
            optionsCargosDiretoria={this.state.optionsCargosDiretoria}
            diretoria={this.state.diretoria}
            gestao={this.state.gestao}
            optionsGestoesDiretoria={this.state.optionsGestoesDiretoria}
            buscaFuncionario={this.buscaFuncionario}
          />
        );
        break;

      case "email":
        content = (
          <ParametrosEmail
            alteracao={this.alteracao}
            email={this.state.email}
            clientId={this.state.clientId}
            clientSecret={this.state.clientSecret}
            refreshToken={this.state.refreshToken}
          />
        );
        break;

      case "parametrosFaee":
        content = (
          <ParametrosFaee
            pagamentos_com_caixa={this.state.pagamentos_com_caixa}
            alteracao={this.alteracao}
          />
        );
        break;
      case "carteirinha":
        content = <Carteirinhas />;
        break;

      case "perfis":
        content = <Perfis entidade={this.state.entidade} />;
        break;
      default:
        break;
    }
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Parâmetros do sistema
        </Header>
        <Dimmer active={this.state.buscando} inverted>
          {this.state.buscando && <Loader>Buscando</Loader>}
        </Dimmer>

        <Menu pointing secondary>
          {window.localStorage.getItem("tipo_usuario_clube") ===
            "administradorSistema" && (
              <Menu.Item
                name="parametrosFaee"
                active={this.state.menuSelect === "parametrosFaee"}
                onClick={() => this.setState({ menuSelect: "parametrosFaee" })}
              />
            )}

          {this.props.submenus.find((e) => e.nome === "geral") && (
            <Menu.Item
              name="geral"
              active={this.state.menuSelect === "geral"}
              onClick={() => this.setState({ menuSelect: "geral" })}
            />
          )}

          {this.props.submenus.find((e) => e.nome === "diretoria") && (
            <Menu.Item
              name="diretoria"
              active={this.state.menuSelect === "diretoria"}
              onClick={() => this.setState({ menuSelect: "diretoria" })}
            />
          )}

          {this.props.submenus.find((e) => e.nome === "email") && (
            <Menu.Item
              name="email"
              active={this.state.menuSelect === "email"}
              onClick={() => this.setState({ menuSelect: "email" })}
            />
          )}

          {this.props.submenus.find((e) => e.nome === "carteirinha") && (
            <Menu.Item
              name="carteirinha"
              active={this.state.menuSelect === "carteirinha"}
              onClick={() => this.setState({ menuSelect: "carteirinha" })}
            />
          )}

          {this.props.submenus.find((e) => e.nome === "perfis") && (
            <Menu.Item
              name="perfis"
              active={this.state.menuSelect === "perfis"}
              onClick={() => this.setState({ menuSelect: "perfis" })}
            />
          )}
        </Menu>

        {this.state.menuSelect !== "carteirinha" &&
          this.state.menuSelect !== "perfis" ? (
          <Form
            success
            size={"large"}
            onSubmit={this.submit}
            loading={this.state.loading}
          >
            {content}

            {this.state.sucess && (
              <Col sm={12} style={{ paddingTop: 30 }}>
                <Message
                  success
                  header="Salvo!"
                  content="Os parâmetros foram salvos com sucesso."
                />
              </Col>
            )}
            <Divider />
            <Col sm={12}>
              <Button type="submit" color="green" style={{ float: "right" }}>
                Salvar
              </Button>
            </Col>
          </Form>
        ) : (
          <>{content}</>
        )}
      </div>
    );
  }
}
