import renomearChave from "../services/renomearChave";

const converteParaLabel = (objetoLista) => {
  let lista = [];
  for (let i = 0; i < objetoLista.length; i++) {
    const element = objetoLista[i];
    let objeto = element;
    element.value = element.id;
    //objeto.label = renomearChave(objeto, "nome", "label").label;
    if (element.acao) {
      if (!element.acao.codigo) {
        objeto.label = "";
        if (
          element.acao.tipo_titulo !== null &&
          element.acao.tipo_titulo.prefixo !== null
        ) {
          objeto.label = element.acao.tipo_titulo.prefixo + " ";
        }
        objeto.label +=
          renomearChave(objeto.acao, "codigo_auxiliar", "label").label +
          " - " +
          objeto.nome;
      } else {
        objeto.label =
          renomearChave(objeto.acao, "codigo", "label").label +
          " - " +
          objeto.nome;
      }
      if (element.titular_id !== null) {
        objeto.label += " - Dependente";
      } else if (element.acao === undefined || element.acao === null) {
        objeto.label += " - Sem ação";
      } else {
        objeto.label += " - Titular";
      }
      lista.push(objeto);
    } else {
      objeto.label = objeto.nome;
      if (objeto.cnpj !== null && objeto.cnpj !== "") {
        objeto.label += " - CNPJ: " + objeto.cnpj;
      } else if (objeto.cpf !== null && objeto.cpf !== "") {
        objeto.label += " - CPF: " + objeto.cpf;
      }
      lista.push(objeto);
    }
  }
  return lista;
};
export default converteParaLabel;
