import React, { Component } from "react";
import { Container, Table, Button, Popup, Search } from "semantic-ui-react";
import { Link } from "react-router-dom";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";
import { Col, Row } from "react-bootstrap";

export default class ArmaLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      pagina: 1,
      totalPaginas: 1,
      popUpPesquisa: false,
      pesquisando: false,
    };
    this.lista();
  }

  lista = async (page = 1) => {
    const resposta = await api.post(
      "/arma/lista" + requisicoes.entidade + "&pagina=" + page,
      "",
      requisicoes.header
    );
    const { lista, pagina, totalPaginas } = resposta.data;
    this.setState({ lista: lista, pagina, totalPaginas });
  };

  pesquisa = async (event) => {
    this.setState({ pesquisando: true });
    let pesquisa = event.target.value;
    setTimeout(async () => {
      this.setState({ popUpPesquisa: false });
      await api
        .post(
          "/arma/pesquisa" + requisicoes.entidade,
          { pesquisa: pesquisa },
          requisicoes.header
        )
        .then((resp) => {
          if (resp.data.length == 0) {
            this.setState({ popUpPesquisa: true });
          }
          this.setState({ lista: resp.data });
        });
      this.setState({ pesquisando: false });
    }, 500);
  };

  excluir = async () => {
    const resposta = await api.post(
      "/arma/excluir" + requisicoes.entidade + "&id=" + this.state.id,
      {},
      requisicoes.header
    );
    if (resposta.data !== null) {
      window.location.href = "/administrativo";
    }
  };

  proximaPagina = async () => {
    const { pagina } = this.state;
    const paginaNumero = parseInt(pagina) + 1;
    this.lista(paginaNumero);
  };

  voltarPagina = async () => {
    const { pagina } = this.state;
    const paginaNumero = pagina - 1;
    this.lista(paginaNumero);
  };

  render() {
    return (
      <div className="lista">
        <Row>
          <Col sm={6}>
            <Popup
              content="Nenhum registro encontrado!"
              open={this.state.popUpPesquisa}
              position="right center"
              trigger={
                <Search
                  loading={this.state.pesquisando}
                  fluid
                  onSearchChange={this.pesquisa}
                  showNoResults={false}
                  input={{
                    icon: "search",
                    iconPosition: "left",
                    style: { width: "100%" },
                    input: { style: { borderRadius: "3%" } },
                  }}
                  placeholder="Digite para buscar"
                />
              }
            />
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Grupo</Table.HeaderCell>
              <Table.HeaderCell>Modelo</Table.HeaderCell>
              <Table.HeaderCell>Marca</Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <Link to={{ pathname: "/arma" }}>
                  <Button positive icon="add" size="tiny"></Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.lista.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>{e.grupo.nome}</Table.Cell>
                <Table.Cell>{e.modelo}</Table.Cell>
                <Table.Cell>{e.marca}</Table.Cell>
                <Table.Cell width={1}>
                  <Link to={{ pathname: "/arma", query: { id: e.id } }}>
                    <Button primary icon="edit" size="tiny"></Button>
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer></Table.Footer>
        </Table>
      </div>
    );
  }
}
