import React, { Component } from "react";
import {
  Button,
  Segment,
  Dimmer,
  Loader,
  Table,
  Card,
  Header,

} from "semantic-ui-react";
import { Col, Row, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";

//Componentes
import ListaReservas from "./listaReservas";

export default class Reservas extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div style={{ paddingTop: 10 }}>
          {this.props.servicos !== false ? (
            <div>
              <div style={{ paddingBottom: 20, paddingLeft: 0 }}>
                <h2>Pesquisar</h2>
              </div>
              <Row>
                <Col sm={4}>
                  <FormControl
                    type="date"
                    aria-describedby="basic-addon1"
                    name="dataBusca"
                    defaultValue={this.props.dataBusca}
                    onChange={this.props.alteracao}
                  />
                </Col>
                <Col sm={1}>
                  <Button
                    type="button"
                    size="tiny"
                    primary
                    icon="search"
                    onClick={() =>
                      this.props.carregaListaServico(this.props.grupoServico)
                    }
                  ></Button>
                </Col>
                <Col sm={7}>
                  <div style={{ textAlign: "right" }}>
                    <Button
                      floated="right"
                      color="blue"
                      icon="th"
                      size="small"
                      content="Voltar"
                      onClick={this.props.voltar}
                    ></Button>
                  </div>
                </Col>
              </Row>
              {this.props.loading === true ? (
                <div style={{ paddingTop: 10, height: 60 }}>
                  <Segment size="massive">
                    <Dimmer active inverted>
                      <Loader active inverted inline />
                    </Dimmer>
                  </Segment>
                </div>
              ) : (
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={4}>Nome</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center" width={2}>
                        Horários - Vagas
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center" width={5}>
                        Disponibilidade
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center" width={1}>
                        Reserva
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.props.listaServicos.map((e, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>{e.nome}</Table.Cell>
                        <Table.Cell textAlign="center">
                          {e.horaInicio && e.horaFim
                            ? e.horaInicio +
                            " a " +
                            e.horaFim +
                            "  -  " +
                            e.vagasDisponiveis
                            : e.horariosServico.map((e2) => (
                              <p style={{ marginBottom: 5 }}>
                                {e2.vagasDisponiveis > 0 ? (
                                  <p>
                                    {e2.horaInicio +
                                      " a " +
                                      e2.horaFim +
                                      "  -  "}
                                    <b>{e2.vagasDisponiveis}</b>
                                  </p>
                                ) : (
                                  <s>
                                    {e2.horaInicio +
                                      " a " +
                                      e2.horaFim +
                                      " - " +
                                      0}
                                  </s>
                                )}
                              </p>
                            ))}
                        </Table.Cell>
                        <Table.Cell width={1} textAlign="center">
                          {e.disponivel === true ? (
                            <Button fluid positive size="tiny">
                              {e.status}
                            </Button>
                          ) : (
                            <>
                              {e.situacaoReserva === "ativa" && (
                                <>
                                  {this.props.permissoes.find(
                                    (e) => e.nome === "reservar"
                                  ) ? (
                                    <Button
                                      animated
                                      fluid
                                      color="red"
                                      size="tiny"
                                      onClick={() =>
                                        this.props.openModalReservaDados(
                                          true,
                                          e.nome,
                                          e.pessoa,
                                          e.dataCancelamento,
                                          e.diasCancelamento,
                                          e.evento,
                                          e.observacao,
                                          e.reservaEfetivacao,
                                          e.reservaEvento,
                                          e.valor
                                        )
                                      }
                                    >
                                      <Button.Content visible>
                                        {e.status}
                                      </Button.Content>
                                      <Button.Content hidden>
                                        <>
                                          {e.pessoa !== null &&
                                            e.pessoa !== undefined && (
                                              <>
                                                {e.pessoa.acao !== null &&
                                                  e.pessoa.acao !==
                                                  undefined && (
                                                    <>
                                                      {e.pessoa.acao.codigo ===
                                                        null ? (
                                                        <>
                                                          {
                                                            e.pessoa.acao
                                                              .codigo_auxiliar
                                                          }
                                                          {"- "}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {e.pessoa.acao.codigo}
                                                          {"- "}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                {e.pessoa.nome}
                                              </>
                                            )}
                                        </>
                                      </Button.Content>
                                    </Button>
                                  ) : (
                                    <Button
                                      fluid
                                      color="red"
                                      size="tiny"
                                      content={e.status}
                                    ></Button>
                                  )}
                                </>
                              )}
                              {e.situacaoReserva === "prereserva" && (
                                <>
                                  {this.props.permissoes.find(
                                    (e) => e.nome === "reservar"
                                  ) ? (
                                    <Button
                                      animated
                                      fluid
                                      basic
                                      color="black"
                                      size="tiny"
                                      onClick={() =>
                                        this.props.openModalReservaDados(
                                          true,
                                          e.nome,
                                          e.pessoa,
                                          e.dataCancelamento,
                                          e.diasCancelamento,
                                          e.evento,
                                          e.observacao,
                                          e.reservaEfetivacao,
                                          e.reservaEvento,
                                          e.valor
                                        )
                                      }
                                    >
                                      <Button.Content visible>
                                        {e.status}
                                      </Button.Content>
                                      <Button.Content hidden>
                                        <>
                                          {e.pessoa !== null && (
                                            <>
                                              {e.pessoa.acao !== null && (
                                                <>
                                                  {e.pessoa.acao.codigo ===
                                                    null ? (
                                                    <>
                                                      {
                                                        e.pessoa.acao
                                                          .codigo_auxiliar
                                                      }
                                                      {"- "}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {e.pessoa.acao.codigo}
                                                      {"- "}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                              {e.pessoa.nome}
                                            </>
                                          )}
                                        </>
                                      </Button.Content>
                                    </Button>
                                  ) : (
                                    <Button
                                      fluid
                                      color="red"
                                      size="tiny"
                                      content={e.status}
                                    ></Button>
                                  )}
                                </>
                              )}
                              {e.situacaoReserva === "" && (
                                <Button
                                  fluid
                                  color="red"
                                  size="tiny"
                                  content={e.status}
                                ></Button>
                              )}
                            </>
                          )}
                        </Table.Cell>
                        <Table.Cell width={1} textAlign="center">
                          {(this.props.permissoes.find(
                            (e) => e.nome === "reservar",
                          ) ||
                            this.props.permissoes.find(
                              (e) => e.nome === "reservar_titular",
                            ) ||
                            this.props.permissoes.find(
                              (e) => e.nome === "reservar_dependente",
                            )) &&
                            (this.props.permissoes.find(
                              (e) => e.nome === "alterar_meus_dados_alheios"
                            ) ||
                              e.situacaoReserva === "" ||
                              e.pessoa.id ===
                              this.props.usuarioLogado.pessoa_id) &&
                            (localStorage.getItem("tipo_usuario_clube") === "administrador" || e.liberaReserva === true ? (
                              <Link
                                to={{
                                  pathname: "/reserva",
                                  query: {
                                    id: e.id,
                                    dataReserva: this.props.dataBusca,
                                    pessoa: e.pessoa,
                                  },
                                }}
                              >
                                <Button
                                  primary
                                  icon="calendar alternate "
                                  size="tiny"
                                ></Button>
                              </Link>
                            ) : (
                              <Button
                                primary
                                icon="calendar alternate "
                                size="tiny"
                                disabled
                              ></Button>
                            ))}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              )}
            </div>
          ) : (
            <>
              {(this.props.permissoes.find((e) => e.nome === "reservar") ||
                this.props.permissoes.find(
                  (e) => e.nome === "reservar_titular"
                ) ||
                this.props.permissoes.find(
                  (e) => e.nome === "reservar_dependente"
                )) && (
                  <>
                    <Col style={{ paddingBottom: 20, paddingLeft: 0 }}>
                      <h2>Serviços</h2>
                    </Col>
                    <Card.Group itemsPerRow={4}>
                      {this.props.permissoes.find(
                        (e) => e.nome === "reservar"
                      ) ? (
                        <>
                          {this.props.listaGrupoServico.map((e, i) => (
                            <>
                              {e.reserva ? (
                                <Card
                                  onClick={() =>
                                    this.props.carregaListaServico(e.id)
                                  }
                                >
                                  <Card.Content style={{ textAlign: "center" }}>
                                    <Header>{e.descricao}</Header>
                                  </Card.Content>
                                </Card>
                              ) : null}
                            </>
                          ))}
                        </>
                      ) : (
                        <>
                          {this.props.listaParametroGrupoServico.map((e, i) => (
                            <Card
                              onClick={() =>
                                this.props.carregaListaServico(e.grupo_servico.id)
                              }
                            >
                              <Card.Content>
                                <Header>{e.grupo_servico.descricao}</Header>
                              </Card.Content>
                            </Card>
                          ))}
                        </>
                      )}
                    </Card.Group>
                  </>
                )}
            </>
          )}

          {this.props.permissoes.find(
            (e) => e.nome === "visualizar_meus_dados_alheios"
          ) && (
            <ListaReservas
              permissoes={this.props.permissoes}
              listaReservasAdmin={this.props.listaReservasAdmin}
              carregaListaConvidados={this.props.carregaListaConvidados}
              imprimirListaConvidados={this.props.imprimirListaConvidados}
              somente_convidados_presentes={this.props.somente_convidados_presentes}
              mostraListaConvidadosPresentes={this.props.mostraListaConvidadosPresentes}
              buscandoListaPresencaReservas={
                this.props.buscandoListaPresencaReservas
              }
              presencaSocio={this.props.presencaSocio}
              setSocioResponsavel={this.props.setSocioResponsavel}
              listaPresencaReservas={this.props.listaPresencaReservas}
              confirmaPresenca={this.props.confirmaPresenca}
              carregaListaFornecedor={this.props.carregaListaFornecedor}
              imprimirListaFornecedor={this.props.imprimirListaFornecedor}
              buscandoListaFornecedorReserva={
                this.props.buscandoListaFornecedorReserva
              }
              listaFornecedorReserva={this.props.listaFornecedorReserva}
              confirmaPresencaFornecedor={this.props.confirmaPresencaFornecedor}
              pessoa={this.props.pessoa}
              alteracaoSelectPessoa={this.props.alteracaoSelectPessoa}
              options={this.props.options}
              pesquisaPessoa={this.props.pesquisaPessoa}
            />
          )}
        </div>
      </div>
    );
  }
}
