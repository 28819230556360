import React, { Component } from "react";
import {
  Container,
  Header,
  Button,
  Divider,
  Segment,
  Dimmer,
  Loader,
  Table,
  Icon,
  Modal,
  Checkbox,
  Pagination,
} from "semantic-ui-react";
import { Form, FormControl, Row, Col, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CircularProgress, Tooltip, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import LoadingService from "../../../services/LoadingService";
import moment from "moment-timezone";
import Requisicoes from "../../../utils/Requisicoes";
import requisicoes from "../../../utils/Requisicoes";
import converterParaLabel from "../../../utils/converteParaLabel";
import ReplaceAll from "replaceall";
import api from "../../../services/api";

// Componentes
import Cobranca from "./cobranca";
import ModalMatriculadosTurma from "./ModalMatriculadosTurma";
import ModalListaEsperaTurma from "./ModalListaEsperaTurma";
import ModalAlteraTurma from "./ModalAlteraTurma";

let buscando = false;
export default class MatriculaTurma extends Component {
  constructor(props) {
    super(props);
    LoadingService.load((v) => this.setState({ isLoading: true }));

    this.state = {
      id: "",
      descGrupo: null,
      descTurma: null,
      qtdTurma: "",
      dataInicio: "",
      dataFinal: "",
      listaTurmas: [],
      valorMensalidade: "",
      valorInscricao: "",
      diasFiltro: [],
      pessoa: null,
      pessoa_id: null,
      diasEspeciais: [],
      diasDaSemana: [],
      gruposServico: [],
      grupo_servico: null,
      modalOpen: false,
      matriculado: false,
      matricula_id: null,
      listaMatriculados: [],
      data_matricula: null,
      data_matricula_alterada: false,
      mensagem: null,
      open_cobranca: false,
      infoParcelasInscricao: [],
      listaDataExamesMedico: [],
      listaDataExamesFisico: [],
      infoParcelasMensalidade: [],
      turma_servico_id: null,
      options: [],
      totalPages: 0,
      paginaAtual: 1,
      listaMatriculadosSlice: [],
      dadosPorPagina: 30,
      matriculasModalVisualiza: [],
      loadingModalVisualiza: false,
      observacao: "",
      buscando: false,
      quantidade_Turma: [],
      sortedName: "nome",
      sortDirection: "ascending",
      dataExameMedico: moment().format("YYYY-MM-DD"),
      dataValidadeMedico: moment().add(6, "M").format("YYYY-MM-DD"),
      dataExameFisico: moment().format("YYYY-MM-DD"),
      dataValidadeFisico: moment().add(1, "Y").format("YYYY-MM-DD"),

      // Modais
      turmaModal: null,
      turmaModal2: null,
      modalOpenMatriculadosTurma: false,
      modalOpenListaEsperaTurma: false,
      modalOpenAlteraTurma: false,
      checkboxesExameMedico: false,
      checkboxesExameFisico: false,

      // Lancamentos na hora de cancelar a matrícula
      lista_Lancamentos: [],
      checkboxes_lancamentos: [],
    };
    if (this.props.location.query === undefined) {
      window.location.href = "./reservas";
    }
    this.alteracaoSelectPessoa = this.alteracaoSelectPessoa.bind(this);
    this.buscaDiasDaSemana = this.buscaDiasDaSemana.bind(this);
    this.buscaDiasDaSemana();
    this.visualizacao();
    this.buscaMatriculados();
  }

  setaIdPessoa = (id) => {
    this.setState({ pessoa_id: id });
  };

  handleCloseCobranca = () => {
    this.setState({ open_cobranca: false });
  };

  handleCobranca = (e) => {
    this.setState({ open_cobranca: true });
    this.setState({ turma_servico_id: e });
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setParcelasInscricao = (lista) => {
    this.setState({ infoParcelasInscricao: lista });
  };
  setAlteraDataExameMedico = (dataMedico) => {
    if (Object.keys(dataMedico).length > 0) {
      this.setState({ dataExameMedico: dataMedico });
    }
  };
  setAlteraDataValidadeMedico = (event) => {
    if (Object.keys(event).length > 0) {
      this.setState({ dataValidadeMedico: event.dataValidadeMedico });
    }
  };
  setAlteraDataExameFisico = (lista) => {
    if (Object.keys(lista).length > 0) {
      this.setState({ dataExameFisico: lista.dataExameFisico });
    }
  };
  setAlteraDataValidadeFisico = (lista) => {
    if (Object.keys(lista).length > 0) {
      this.setState({ dataValidadeFisico: lista.dataValidadeFisico });
    }
  };

  setcheckboxesExameMedico = (checkboxesExameMedico) => {
    if (JSON.stringify(checkboxesExameMedico) !== "{}") {
      this.setState({ checkboxesExameMedico: checkboxesExameMedico });
    }
  };

  setcheckboxesExameFisico = (checkboxesExameFisico) => {
    if (JSON.stringify(checkboxesExameFisico) !== "{}") {
      this.setState({ checkboxesExameFisico: checkboxesExameFisico });
    }
  };

  setParcelasMensalidade = (lista) => {
    console.log("parcelas lista",lista)
    this.setState({ infoParcelasMensalidade: lista });
  };
  alteracaoDataMatricula = (event) => {
    this.setState({ data_matricula_alterada: true });
    this.setState({ data_matricula: event.target.value });
  };

  mostraMensagem = async (msg) => {
    this.setState({ mensagem: msg });
    setTimeout(async () => {
      this.setState({ mensagem: null });
    }, 10000);
  };

  salvarDataMatricula = async (event) => {
    await api.post(
      "/matriculaTurma/alterar" + Requisicoes.entidade,
      { id: event.target.id, data_matricula: this.state.data_matricula },
      Requisicoes.header
    );
    this.mostraMensagem("Data Alterada");
    this.setState({ data_matricula_alterada: false });
  };

  alteracaoSelectPessoa = async (event, v) => {
    if (v === null || v === undefined) {
      return;
    }
    this.setState({ pessoa_id: v.value });
    this.setState({ pessoa: v });
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var e = localStorage.getItem("e_clube");
    const resposta = await api.post(
      "/pessoa/visualiza?e=" + e,
      { id: v.value },
      { headers: { token: "Baer " + token } }
    );
    this.setState({ infoPessoa: resposta.data });
    this.procuraMatricula(v.value);
  };

  buscaDiasDaSemana = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var e = localStorage.getItem("e_clube");
    const resposta = await api.post(
      "/diaDaSemanan/lista?e=" + e,
      this.props.location.query,
      { headers: { token: "Baer " + token } }
    );
    for (var i = 0; i < resposta.data.lenght; i++) {
      this.state.selectDiasDaSemana.push(false);
    }
    this.setState({ diasDaSemana: resposta.data });
  };

  pesquisaPessoa = async () => {
    if (buscando === true) {
      return;
    }
    buscando = true;
    this.setState({ buscando: true });

    setTimeout(async () => {
      let term = document.getElementById("pesquisaPessoa");

      if (term === null) {
        buscando = false;
        this.setState({ buscando: false });
        return;
      }
      term = term.value;

      if (term.trim() === "") {
        buscando = false;
        this.setState({ buscando: false });
        return;
      }

      if (isFinite(String(term))) {
        await api
          .post(
            "/pessoa/pesquisaPorCodigoAcaoValorExato" + requisicoes.entidade,
            { pesquisa: document.getElementById("pesquisaPessoa").value },
            requisicoes.header
          )
          .then((retorno) => {
            this.setState({
              options: converterParaLabel(retorno.data),
            });
          });
      } else {
        await api
          .post(
            // "/pessoa/pesquisaNome" + requisicoes.entidade,
            "/pessoa/pesquisaNomeOuDoc" + requisicoes.entidade,
            { pesquisa: document.getElementById("pesquisaPessoa").value },
            requisicoes.header
          )
          .then((retorno) => {
            this.setState({ options: converterParaLabel(retorno.data) });
          });
      }
      buscando = false;
      this.setState({ buscando: false });
    }, 2000);
  };

  visualizacao = async () => {
    if (this.props.location.query !== undefined) {
      var localStorage = window.localStorage;
      var token = localStorage.getItem("token_clube");
      var e = localStorage.getItem("e_clube");
      const resposta = await api.post(
        "/servico/visualiza?e=" + e + "&id=" + this.props.location.query.id,
        this.props.location.query,
        { headers: { token: "Baer " + token } }
      );
      if (resposta.data.servico_mensalidade === null) {
        alert("Serviço Sem Valor de Mensalidade");
      }
      this.setState({
        id: resposta.data.id,
        descTurma: resposta.data.nome,
        grupo_servico: resposta.data.grupo_servico_id,
        dataInicio: resposta.data.dataInicio,
        dataFinal: resposta.data.dataFinal,
        servico_inscricao: resposta.data.servico_inscricao,
        servico_mensalidade: resposta.data.servico_mensalidade,
        valorInscricao: resposta.data.valorInscricao,
        valorMensalidade: resposta.data.valorMensalidade,
      });

      const turmas = await api.post(
        "/turmaServico/turmaPorServico?e=" + e,
        { id: resposta.data.id },
        { headers: { token: "Baer " + token } }
      );

      this.setState({
        qtdTurma: turmas.data.length,
        quantidade_Turma: turmas.data,
      });

      await api.post("/diaDaSemanan/lista?e=" + e, this.props.location.query, {
        headers: { token: "Baer " + token },
      });
      this.buscaMatriculas();
    }
  };

  matricular = async (event) => {
    if (this.state.pessoa !== null) {
       await api.post(
        "/usuario/usuarioLogado" + requisicoes.entidade,
        {},
        requisicoes.header
      );
      let localStorage = window.localStorage;
      let token = localStorage.getItem("token_clube");
      let entidade = localStorage.getItem("e_clube");
      let exameMedicoCheck = this.state.checkboxesExameMedico;
      let exameFisicoCheck = this.state.checkboxesExameFisico;
      try {
        if (exameMedicoCheck === true) {
          await api.post(
            "/exameMedico/salvar?e=" + entidade,
            {
              dataExame: this.state.dataExameMedico,
              dataValidade: this.state.dataValidadeMedico,
              pessoa_id: this.state.pessoa_id,
            },
            {
              headers: { token: "Baer " + token },
            }
          );
        }
        if (exameFisicoCheck === true) {

          await api.post(
            "/exameFisico/salvar" + requisicoes.entidade,
            {
              dataExame: this.state.dataExameFisico,
              dataValidade: this.state.dataValidadeFisico,
              pessoa_id: this.state.pessoa_id,
            },
            requisicoes.header
          );
        }
        this.state.infoParcelasInscricao.forEach((e) => {
          e.valor = ReplaceAll(".", "", e.valor).replace(",", ".");
        });
        this.state.infoParcelasMensalidade.forEach((e) => {
          e.valor = ReplaceAll(".", "", e.valor).replace(",", ".");
        });

        const dados = {
          pessoa_id: this.state.pessoa_id,
          cobranca_inscricao: this.state.infoParcelasInscricao,
          cobranca_mensalidade: this.state.infoParcelasMensalidade,
          servico_inscricao_id:
            this.state.servico_inscricao !== null
              ? this.state.servico_inscricao.id
              : null,
          servico_mensalidade_id: this.state.servico_mensalidade.id,
          turma_servico_id: this.state.turma_servico_id,
        };
        await api.post(
          "/matriculaTurma/matricular?e=" + entidade,
          {
            data_matricula: moment(),
            turma_servico_id: this.state.turma_servico_id,
            servico_id: this.props.location.query.id,
            pessoa_id: this.state.pessoa_id,
            observacao: this.state.observacao,
            parcelas:  this.state.infoParcelasMensalidade,
          },
          { headers: { token: "Baer " + token } }
        );
        try {
          await api
            .post(
              "/matriculaTurma/cobranca" + requisicoes.entidade,
              dados,
              requisicoes.header
            )
            .then(() => {
              setTimeout(() => {
                this.handleCloseCobranca();
              }, 1000);
            });
        } catch (error) {
          console.log("Erro al salvar a cobranca: ", error);
        }
      } catch (error) {
        alert(error);
      }
      this.voltarParaMatriculas();
    } else {
      alert("Favor Selecionar uma Pessoa");
    }
  };

  cancelar = async (e, pessoa_id) => {
    if (
      (this.state.pessoa_id === null &&
        (pessoa_id === null || pessoa_id === undefined)) ||
      e === null
    ) {
      return;
    }
    if (pessoa_id === null || pessoa_id === undefined) {
      pessoa_id = this.state.pessoa_id;
    }

    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    let listaLancamentosId = [];

    for (let i = 0; i < this.state.checkboxes_lancamentos.length; i++) {
      const element = this.state.checkboxes_lancamentos[i];
      if (element.checked === true) {
        listaLancamentosId.push(element.id);
      }
    }

    await api.post(
      "/matriculaTurma/cancelar?e=" + entidade,
      {
        matricula_id: e,
        motivo: this.state.motivo,
        lancamentos: listaLancamentosId,
      },
      { headers: { token: "Baer " + token } }
    );

    await api.post(
      "/historicoMatricula/salvar?e=" + entidade,
      {
        matricula_id: e,
        motivo: this.state.motivo,
        pessoa_id: pessoa_id,
      },
      { headers: { token: "Baer " + token } }
    );

    this.voltarParaMatriculas();
    if (document.getElementById("modalCancelaMatricula") !== null) {
      document
        .getElementById("modalCancelaMatricula")
        .setAttribute("open", false);
    }
    if (document.getElementById("modalCancelaMatricula2") !== null) {
      document
        .getElementById("modalCancelaMatricula2")
        .setAttribute("open", false);
    }
  };

  voltarParaMatriculas = () => {
    localStorage.setItem("pagina_anterior_reservas", "turmas");
    window.location.href = "./reservas";
  };

  buscaMatriculas = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    let pessoa_id = this.state.pessoa_id;

    const resposta = await api.post(
      "/turmaServico/turmaPorServico?e=" + entidade,
      { id: this.props.location.query.id },
      { headers: { token: "Baer " + token } }
    );

    this.setState({ qtdTurma: resposta.data.length });

    const dias = await api.post(
      "/diaDaSemanan/lista?e=" + entidade,
      this.props.location.query,
      { headers: { token: "Baer " + token } }
    );

    let listaTurmas = resposta.data;
    let turmas = [];
    listaTurmas.forEach((turma) => {
      let diasDaSemana = [];
      let diasFiltro = turma.dias_da_semana;
      for (var i = 0; i < dias.data.lenght; i++) {
        diasDaSemana.push(false);
      }
      turma.dias_da_semana.forEach((element) => {
        switch (element.nome) {
          case "Domingo":
            diasDaSemana[0] = true;
            break;
          case "Segunda":
            diasDaSemana[1] = true;
            break;
          case "Terça":
            diasDaSemana[2] = true;
            break;
          case "Quarta":
            diasDaSemana[3] = true;
            break;
          case "Quinta":
            diasDaSemana[4] = true;
            break;
          case "Sexta":
            diasDaSemana[5] = true;
            break;
          case "Sabado":
            diasDaSemana[6] = true;
            break;
          default:
            break;
        }
      });
      turmas.push({
        id: turma.id,
        horaInicio: turma.horaInicio,
        horaFim: turma.horaFim,
        vaga: turma.vaga,
        turma: turma.turma,
        nome: turma.nome,
        diasDaSemana: diasDaSemana,
        diasFiltro: diasFiltro,
        servico_id: turma.servico_id,
      });
    });

    const listaRetorno = [];
    this.setState({ listaTurmas: listaRetorno });
    let temMatricula = "";
    let vaga = "";
    this.setState({ matriculado: false });
    turmas.forEach(async (turma) => {
      const listaMatricula = await api.post(
        "/matriculaTurma/matriculaPorTurma?e=" + entidade,
        {
          id: turma.id,
        },
        { headers: { token: "Baer " + token } }
      );
      const matriculas = listaMatricula.data;
      let matricula_id = "";
      let data_matricula = "";
      if (matriculas.length > 0) {
        matriculas.forEach((matricula) => {
          temMatricula = true;
          vaga = parseInt(turma.vaga - matriculas.length);
          if (parseInt(matricula.pessoa_id) === parseInt(pessoa_id)) {
            matricula_id = matricula.id;
            data_matricula = matricula.dataMatricula;
            this.setState({ matricula_id: matricula.id });
            this.setState({ matriculado: true });
          } else {
            if (this.state.matriculado !== true) {
              this.setState({ matriculado: false });
            }
          }
        });
        listaRetorno.push({
          id: matricula_id,
          turma_servico_id: turma.id,
          horaInicio: turma.horaInicio,
          horaFim: turma.horaFim,
          temMatricula: temMatricula,
          vaga: vaga,
          turma: turma.turma,
          nome: turma.nome,
          diasDaSemana: turma.diasDaSemana,
          diasFiltro: turma.diasFiltro,
          servico_id: turma.servico_id,
          data_matricula,
        });
      } else {
        listaRetorno.push({
          id: matricula_id,
          turma_servico_id: turma.id,
          horaInicio: turma.horaInicio,
          horaFim: turma.horaFim,
          temMatricula: false,
          vaga: turma.vaga,
          turma: turma.turma,
          nome: turma.nome,
          diasDaSemana: turma.diasDaSemana,
          diasFiltro: turma.diasFiltro,
          servico_id: turma.servico_id,
          data_matricula,
        });

        if (this.state.matriculado !== true) {
          this.setState({ matriculado: false });
        }
      }
    });
    setTimeout(async () => {
      function ordena(a, b) {
        if (a.turma > b.turma) {
          return 1;
        }
        if (a.turma < b.turma) {
          return -1;
        }
        return 0;
      }
      listaRetorno.sort(ordena);
      this.setState({ listaTurmas: listaRetorno });
    }, 500);
  };

  // buscaMatriculasTurma = async (turmaServicoId) => {
  //   if (turmaServicoId === null || turmaServicoId === undefined) {
  //     return;
  //   }
  //   this.state.loadingModalVisualiza = true;
  //   this.state.matriculasModalVisualiza = [];

  //   var localStorage = window.localStorage;
  //   var token = localStorage.getItem("token_clube");
  //   var entidade = localStorage.getItem("e_clube");

  //   const matriculas = await api.post(
  //     "/matriculaTurma/matriculaPorTurma?e=" + entidade,
  //     { id: turmaServicoId },
  //     { headers: { token: "Baer " + token } }
  //   );

  //   this.setState({ matriculasModalVisualiza: matriculas.data });
  //   this.setState({ loadingModalVisualiza: false });
  // };

  procuraMatricula = async (pessoa_id) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    let turmas = this.state.listaTurmas;
    this.state.matriculado = null;
    turmas.forEach(async (turma) => {
      if (this.state.matriculado !== true) {
        const matriculado = await api.post(
          "/matriculaTurma/matriculaPorTurmaPessoa?e=" + entidade,
          { id: turma.turma_servico_id, pessoa_id: pessoa_id },
          { headers: { token: "Baer " + token } }
        );
        if (matriculado.data !== "") {
          this.setState({ matricula_id: matriculado.data.id });
          this.setState({ matriculado: true });
          turma.temMatricula = true;
          turma.id = matriculado.data.id;
        } else {
          // if (this.state.matriculado !== true) {
          this.setState({ matriculado: false });
          // }
        }
      }
    });
    setTimeout(async () => {
      function ordena(a, b) {
        if (a.turma > b.turma) {
          return 1;
        }
        if (a.turma < b.turma) {
          return -1;
        }
        return 0;
      }
      turmas.sort(ordena);
      this.setState({ listaTurmas: turmas });
    }, 500);
  };

  pageChange = async (event, data) => {
    if (data === undefined || data == null) {
      data = {};
      data.activePage = 1;
    }
    await this.setState({ lista_aniversariantes_dia: [] });
    await this.setState({ paginaAtual: data.activePage });
    //this.buscaAniversariantes();
    await this.setState({ listaMatriculadosSlice: [] });

    var resultSlice = this.state.listaMatriculados.slice(
      this.state.paginaAtual * this.state.dadosPorPagina -
        this.state.dadosPorPagina,
      this.state.paginaAtual * this.state.dadosPorPagina
    );
    await this.setState({ listaMatriculadosSlice: resultSlice });
  };

  sortTableClick = async (sortedName) => {
    this.state.sortedName = sortedName;
    this.state.sortDirection =
      this.state.sortDirection === "ascending" ? "descending" : "ascending";
    this.buscaMatriculados();
  };

  buscaMatriculados = async (activePage = 1) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    const resposta = await api.post(
      "/turmaServico/turmaPorServico?e=" + entidade,
      { id: this.props.location.query.id },
      { headers: { token: "Baer " + token } }
    );

    let listaTurmas = resposta.data;
    let listaRetorno = [];

    this.setState({ listaMatriculados: listaRetorno });
    listaTurmas.forEach(async (turma) => {
      const matriculas = await api.post(
        "/matriculaTurma/matriculaPorTurma?e=" + entidade,
        {
          id: turma.id,
          sortedName: this.state.sortedName,
          sortDirection: this.state.sortDirection,
          // activePage: activePage,
        },
        { headers: { token: "Baer " + token } }
      );
      // totalPages = matriculas.data.total;

      const listaMatricula = matriculas.data;
      listaMatricula.forEach((matricula) => {
        listaRetorno.push({
          id: matricula.id,
          turma_servico_id: matricula?.turma_servico_id,
          servico_id: matricula?.turma_servico?.servico_id,
          pessoa: matricula.pessoa.nome,
          pessoa_id: matricula.pessoa_id,
          turma_nome: turma.nome,
          turma: turma?.turma,
          turma_id: turma.id,
          data_matricula: matricula.dataMatricula,
        });
      });
      // this.setState({ listaMatriculadosSlice: matriculas.data });
    });

    setTimeout(async () => {
      // function ordena(a, b) {
      //   if (Date.parse(a.data_matricula) < Date.parse(b.data_matricula)) {
      //     return 1;
      //   }
      //   if (Date.parse(a.data_matricula) > Date.parse(b.data_matricula)) {
      //     return -1;
      //   }
      //   return 0;
      // }
      // listaRetorno.sort(ordena);
      function ordena(a, b) {
        let aAux = a.pessoa;
        let bAux = b.pessoa;
        if (aAux < bAux) {
          return -1;
        }
        if (aAux > bAux) {
          return 1;
        }
        return 0;
      }
      listaRetorno.sort(ordena);
      this.setState({ listaMatriculados: listaRetorno });

      this.setState({ paginaAtual: 1 });
      var resultSlice = listaRetorno.slice(
        this.state.paginaAtual * this.state.dadosPorPagina -
          this.state.dadosPorPagina,
        this.state.paginaAtual * this.state.dadosPorPagina
      );
      this.setState({ listaMatriculadosSlice: resultSlice });
      this.setState({
        totalPages: Math.ceil(listaRetorno.length / this.state.dadosPorPagina),
      });
      // this.setState({
      //   totalPages: totalPages,
      //   activePage: activePage,
      // });
    }, 700);
  };

  setObservacao = async (obs) => {
    this.setState({ observacao: obs });
  };

  buscarLancamentos = async (pessoa_id, servico_id, data_matricula, e) => {
    if (
      (this.state.pessoa_id === null &&
        (pessoa_id === null || pessoa_id === undefined)) ||
      e === null
    ) {
      return;
    }
    if (pessoa_id === null || pessoa_id === undefined) {
      pessoa_id = this.state.pessoa_id;
    }

    this.setState({ lista_Lancamentos: [], buscandoLancamentos: true });

    const servico = await api.post(
      "/servico/visualiza" + requisicoes.entidade + "&id=" + servico_id,
      {},
      requisicoes.header
    );

    const retorno = await api.post(
      "/lancamentoConta/lancamentosEmAbertoPorPessoaEServico" +
        requisicoes.entidade,
      {
        pessoa_id,
        servico_id: [
          servico.data.servico_inscricao_id,
          servico.data.servico_mensalidade_id,
        ],
      },
      requisicoes.header
    );

    let listaCheckbox = [];
    for (let i = 0; i < retorno.data.length; i++) {
      const element = retorno.data[i];
      listaCheckbox.push({
        id: element.id,
        checked: element.data_emissao === data_matricula,
      });
    }

    this.setState({
      lista_lancamentos: retorno.data,
      buscandoLancamentos: false,
      checkboxes_lancamentos: listaCheckbox,
    });
  };

  changeCheckbox = (i, checked) => {
    let listaCheckbox = this.state.checkboxes_lancamentos;
    if (i !== -1) {
      listaCheckbox[i].checked = checked;
    } else {
      for (let i = 0; i < listaCheckbox.length; i++) {
        const element = listaCheckbox[i];
        element.checked = checked;
      }
      this.setState({ checkboxTodos: checked });
    }
    this.setState({ checkboxes_lancamentos: listaCheckbox });
  };

  render() {
    return (
      <div className="pagina">
        {this.state.isLoading ? (
          <div>
            <Header as="h1" dividing>
              Matricula
            </Header>
            <Row>
              <Col sm={12}>
                {this.state.mensagem !== null && (
                  <Alert variant="primary" style={{ marginTop: "10px" }}>
                    {this.state.mensagem}
                  </Alert>
                )}
              </Col>
            </Row>

            <Segment.Group raised>
              <Segment>
                <Form.Label>Nome: {this.state.descTurma}</Form.Label>
              </Segment>
              <Segment>
                <Form.Label>
                  Valor Inscrição: {this.state.valorInscricao}
                </Form.Label>
              </Segment>
              <Segment>
                <Form.Label>
                  Valor Mensalidade: {this.state.valorMensalidade}
                </Form.Label>
              </Segment>

              {localStorage.getItem("tipo_usuario_clube") === "administrador" ||
              localStorage.getItem("tipo_usuario_clube") === "caixa" ||
              localStorage.getItem("tipo_usuario_clube") === "administrativo" ||
              localStorage.getItem("tipo_usuario_clube") === "modalidade" ? (
                <Segment>
                  <Row>
                    <Col sm={12}>
                      <Form.Label>Pessoa</Form.Label>
                      <Row>
                        <Col sm={8}>
                          <Autocomplete
                            id="pesquisaPessoa"
                            options={this.state.options}
                            getOptionLabel={(option) => option.label}
                            onKeyUp={this.pesquisaPessoa}
                            onChange={this.alteracaoSelectPessoa}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Busca"
                                size="small"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {this.state.buscando ? (
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                          style={{
                                            marginRight: 30,
                                          }}
                                        />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Segment>
              ) : (
                ""
              )}
            </Segment.Group>

            <Divider />

            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={5}>Descrição</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Turma</Table.HeaderCell>
                  <Table.HeaderCell>Hora Inicio</Table.HeaderCell>
                  <Table.HeaderCell>Hora Fim</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Vagas</Table.HeaderCell>
                  <Table.HeaderCell width={4}>Dias da Semana</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    <Icon name="calendar outline" size="large"></Icon>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.listaTurmas.map((matricula, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>
                      <FormControl
                        name="nome"
                        value={matricula.nome}
                        fluid
                        disabled
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <FormControl
                        name="turma"
                        value={matricula.turma}
                        fluid
                        disabled
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <FormControl
                        type="time"
                        placeholder="Horario Inicio"
                        name="horaInicio"
                        value={matricula.horaInicio}
                        disabled
                        fluid
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <FormControl
                        type="time"
                        placeholder="Horario Fim"
                        name="horaFim"
                        value={matricula.horaFim}
                        disabled
                        fluid
                      />
                    </Table.Cell>

                    <Table.Cell>
                      <FormControl
                        name="vaga"
                        value={matricula.vaga}
                        fluid
                        disabled
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {this.state.diasDaSemana.map((dia, index) => {
                        return (
                          <Checkbox
                            id={i[index]}
                            key={index}
                            className="checkboxInline"
                            label={dia.inicial}
                            value={dia.id}
                            name="diasFiltro"
                            checked={matricula.diasDaSemana[index]}
                            disabled
                          />
                        );
                      })}
                    </Table.Cell>
                    <Table.Cell width={2} textAlign="center">
                      <Row
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Col
                          style={{ paddingRight: 0, paddingLeft: 0 }}
                          md="auto"
                        >
                          <Tooltip title="Matriculados">
                            <div>
                              <Button
                                onClick={() => {
                                  this.setState({
                                    turmaModal: matricula,
                                    modalOpenMatriculadosTurma: true,
                                  });
                                }}
                                size="tiny"
                                color="blue"
                                icon="eye"
                              />
                            </div>
                          </Tooltip>
                        </Col>

                        <Col
                          style={{ paddingRight: 0, paddingLeft: 0 }}
                          md="auto"
                        >
                          <Tooltip title="Lista de Espera">
                            <div>
                              <Button
                                onClick={() => {
                                  this.setState({
                                    turmaModal: matricula,
                                    modalOpenListaEsperaTurma: true,
                                  });
                                }}
                                size="tiny"
                                color="blue"
                                icon="ordered list"
                              />
                            </div>
                          </Tooltip>
                        </Col>
                        {this.state.matriculado === false ? (
                          <Col
                            style={{ paddingRight: 0, paddingLeft: 0 }}
                            md="auto"
                          >
                            {matricula.temMatricula === true &&
                            matricula.vaga <= 0 ? (
                              <Tooltip title="Não possui vagas disponíveis">
                                <div>
                                  <Button
                                    color="orange"
                                    icon="calendar times"
                                    size="tiny"
                                  ></Button>
                                </div>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Matricular">
                                <div>
                                  <Button
                                    positive
                                    icon="calendar check"
                                    size="tiny"
                                    onClick={() =>
                                      this.handleCobranca(
                                        matricula.turma_servico_id
                                      )
                                    }
                                  />
                                </div>
                              </Tooltip>
                            )}
                          </Col>
                        ) : (
                          <Col style={{ paddingRight: 0, paddingLeft: 0 }}>
                            {matricula.temMatricula === true &&
                              matricula.vaga >= 0 && (
                                <Col>
                                  {matricula.id !== "" && (
                                    <Modal
                                      closeIcon
                                      size="small"
                                      id="modalCancelaMatricula2"
                                      trigger={
                                        <Button
                                          size="tiny"
                                          negative
                                          icon="calendar times"
                                          onClick={() =>
                                            this.buscarLancamentos(
                                              matricula.pessoa_id,
                                              matricula.servico_id,
                                              matricula.data_matricula,
                                              matricula
                                            )
                                          }
                                        />
                                      }
                                    >
                                      <Header icon>
                                        <Icon name="warning sign" />
                                        Deseja cancelar a reserva?
                                      </Header>
                                      <Modal.Content>
                                        <Form.Label>Motivo</Form.Label>
                                        <FormControl
                                          name="motivo"
                                          fluid
                                          onChange={this.alteracao}
                                        />
                                        <Dimmer
                                          active={
                                            this.state.buscandoLancamentos
                                          }
                                        >
                                          <Loader>Carregando</Loader>
                                        </Dimmer>
                                        {this.state.buscandoLancamentos ===
                                          false &&
                                        this.state.lista_lancamentos.length >
                                          0 ? (
                                          <>
                                            <h4 style={{ marginBottom: 7 }}>
                                              <Row>
                                                <Col>
                                                  Lancamentos em aberto:
                                                </Col>
                                                <Col md="auto">
                                                  {"Data da matrícula" +
                                                    moment(
                                                      matricula.data_matricula
                                                    ).format("DD/MM/YYYY")}
                                                </Col>
                                              </Row>
                                            </h4>
                                            <h4
                                              style={{
                                                color: "red",
                                                marginTop: 0,
                                              }}
                                            >
                                              Selecine os lancamentos a serem
                                              cancelados.
                                            </h4>

                                            <Table celled selectable compact>
                                              <Table.Header>
                                                <Table.Row>
                                                  <Table.HeaderCell width={6}>
                                                    Data Emissao
                                                  </Table.HeaderCell>
                                                  <Table.HeaderCell width={6}>
                                                    Data Vencimento
                                                  </Table.HeaderCell>
                                                  <Table.HeaderCell width={6}>
                                                    Valor
                                                  </Table.HeaderCell>
                                                  <Table.HeaderCell>
                                                    <Checkbox
                                                      checked={
                                                        this.state.checkboxTodos
                                                      }
                                                      onChange={(e, a) =>
                                                        this.changeCheckbox(
                                                          -1,
                                                          a.checked
                                                        )
                                                      }
                                                    />
                                                  </Table.HeaderCell>
                                                </Table.Row>
                                              </Table.Header>
                                              <Table.Body>
                                                {this.state.lista_lancamentos.map(
                                                  (e, i) => (
                                                    <Table.Row>
                                                      <Table.Cell>
                                                        {moment(
                                                          e.data_emissao
                                                        ).format("DD/MM/YYYY")}
                                                      </Table.Cell>
                                                      <Table.Cell>
                                                        {moment(
                                                          e.data_vencimento
                                                        ).format("DD/MM/YYYY")}
                                                      </Table.Cell>
                                                      <Table.Cell>
                                                        {parseFloat(
                                                          e.valor
                                                        ).toLocaleString(
                                                          "pt-BR",
                                                          {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                          }
                                                        )}
                                                      </Table.Cell>
                                                      <Table.Cell>
                                                        <Checkbox
                                                          checked={
                                                            this.state
                                                              .checkboxes_lancamentos[
                                                              i
                                                            ].checked
                                                          }
                                                          onChange={(e, a) =>
                                                            this.changeCheckbox(
                                                              i,
                                                              a.checked
                                                            )
                                                          }
                                                        />
                                                      </Table.Cell>
                                                    </Table.Row>
                                                  )
                                                )}
                                              </Table.Body>
                                            </Table>
                                          </>
                                        ) : null}
                                      </Modal.Content>
                                      <Modal.Actions>
                                        <Button
                                          color="green"
                                          inverted
                                          onClick={() =>
                                            this.cancelar(
                                              this.state.matricula_id,
                                              matricula.pessoa_id
                                            )
                                          }
                                        >
                                          <Icon name="checkmark" /> Sim
                                        </Button>
                                      </Modal.Actions>
                                    </Modal>
                                  )}
                                </Col>
                              )}
                          </Col>
                        )}
                      </Row>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>

            <Cobranca
              handleCloseCobranca={this.handleCloseCobranca}
              handleCobranca={this.handleCobranca}
              infoParcelasMensalidade={this.state.infoParcelasMensalidade}
              setParcelasInscricao={this.setParcelasInscricao}
              setAlteraDataExameMedico={this.setAlteraDataExameMedico}
              setAlteraDataValidadeMedico={this.setAlteraDataValidadeMedico}
              setAlteraDataExameFisico={this.setAlteraDataExameFisico}
              setAlteraDataValidadeFisico={this.setAlteraDataValidadeFisico}
              setParcelasMensalidade={this.setParcelasMensalidade}
              infoParcelasInscricao={this.state.infoParcelasInscricao}
              open_cobranca={this.state.open_cobranca}
              servico_inscricao={this.state.servico_inscricao}
              servico_mensalidade={this.state.servico_mensalidade}
              matricular={this.matricular}
              valorMensalidade={this.state.valorMensalidade}
              valorInscricao={this.state.valorInscricao}
              observacao={this.state.observacao}
              setObservacao={this.setObservacao}
              setcheckboxesExameMedico={this.setcheckboxesExameMedico}
              // checkboxesExameMedico={this.state.checkboxesExameMedico}
              setcheckboxesExameFisico={this.setcheckboxesExameFisico}
              // checkboxesExameFisico={this.state.checkboxesExameFisico}
            />

            <Divider />
            <Segment clearing>
              <Header floated="right">
                <Link
                  to={{
                    pathname: "/reservas",
                    query: {
                      grupo_servico_id_matriculas: this.state.grupo_servico,
                    },
                  }}
                >
                  <Button type="button">Voltar</Button>
                </Link>
              </Header>
            </Segment>
            {(localStorage.getItem("tipo_usuario_clube") === "administrador" ||
              localStorage.getItem("tipo_usuario_clube") === "caixa" ||
              localStorage.getItem("tipo_usuario_clube") === "administrativo" ||
              localStorage.getItem("tipo_usuario_clube") === "modalidade") && (
              <Col>
                <Header as="h1" dividing>
                  Matriculados
                </Header>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        width={5}
                        sorted={
                          this.state.sortedName === "nome"
                            ? this.state.sortDirection
                            : null
                        }
                        onClick={() => this.sortTableClick("nome")}
                      >
                        Nome
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>Descrição</Table.HeaderCell>
                      <Table.HeaderCell
                        width={2}
                        sorted={
                          this.state.sortedName === "turma"
                            ? this.state.sortDirection
                            : null
                        }
                        onClick={() => this.sortTableClick("turma")}
                      >
                        Turma
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>
                        Data Matricula
                      </Table.HeaderCell>
                      <Table.HeaderCell width={1} textAlign="center">
                        <Icon name="calendar outline" size="large"></Icon>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.listaMatriculadosSlice.map((matricula, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>{matricula.pessoa}</Table.Cell>
                        <Table.Cell>{matricula.turma_nome}</Table.Cell>
                        <Table.Cell>{matricula.turma}</Table.Cell>
                        <Table.Cell>
                          <Row>
                            <Col sm={10}>
                              <FormControl
                                type="date"
                                name="data_matricula"
                                onChange={this.alteracaoDataMatricula}
                                defaultValue={matricula.data_matricula}
                                fluid
                              />
                            </Col>
                          </Row>
                        </Table.Cell>

                        <Table.Cell
                          style={{
                            display: "flex",
                            alignItems: "center",

                            justifyContent: "center",
                          }}
                        >
                          <Col sm={4} style={{ paddingLeft: 5 }}>
                            {this.state.data_matricula_alterada === true ? (
                              <Tooltip title="Alterar data">
                                <div>
                                  <Button
                                    icon={{
                                      className: "save outline icon",
                                      id: [matricula.id],
                                      onClick: [this.salvarDataMatricula],
                                    }}
                                    size="tiny"
                                    id={matricula.id}
                                    onClick={this.salvarDataMatricula}
                                    color="blue"
                                  ></Button>
                                </div>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Altera Data Matricula">
                                <div>
                                  <Button
                                    size="tiny"
                                    color="blue"
                                    icon="edit"
                                  />
                                </div>
                              </Tooltip>
                            )}
                          </Col>
                          <Col
                            sm={4}
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                          >
                            <Tooltip title="Edita Aluno">
                              <div>
                                <Button
                                  onClick={() => {
                                    this.setState({
                                      turmaModal2: matricula,
                                      modalOpenAlteraTurma: true,
                                    });
                                  }}
                                  size="tiny"
                                  color="blue"
                                  icon="pencil alternate"
                                />
                              </div>
                            </Tooltip>
                          </Col>

                          <Col
                            sm={5}
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                          >
                            <Modal
                              closeIcon
                              size="small"
                              id="modalCancelaMatricula"
                              trigger={
                                <Tooltip title="Excluir Aluno">
                                  <div>
                                    <Button
                                      size="tiny"
                                      negative
                                      icon="calendar times"
                                      onClick={() =>
                                        this.buscarLancamentos(
                                          matricula.pessoa_id,
                                          matricula.servico_id,
                                          matricula.data_matricula,
                                          matricula
                                        )
                                      }
                                    />
                                  </div>
                                </Tooltip>
                              }
                            >
                              <Header icon>
                                <Icon name="warning sign" />
                                Deseja cancelar a reserva?
                              </Header>
                              <Modal.Content>
                                <Form.Label>Motivo</Form.Label>
                                <FormControl
                                  name="motivo"
                                  fluid
                                  onChange={this.alteracao}
                                />
                                <Dimmer active={this.state.buscandoLancamentos}>
                                  <Loader>Carregando</Loader>
                                </Dimmer>
                                {this.state.buscandoLancamentos === false &&
                                this.state.lista_lancamentos.length > 0 ? (
                                  <>
                                    <h4 style={{ marginBottom: 7 }}>
                                      <Row>
                                        <Col>Lancamentos em aberto:</Col>
                                        <Col md="auto">
                                          {"Data da matrícula: " +
                                            moment(
                                              matricula.data_matricula
                                            ).format("DD/MM/YYYY")}
                                        </Col>
                                      </Row>
                                    </h4>
                                    <h4
                                      style={{
                                        color: "red",
                                        marginTop: 0,
                                      }}
                                    >
                                      Selecine os lancamentos a serem
                                      cancelados.
                                    </h4>

                                    <Table celled selectable compact>
                                      <Table.Header>
                                        <Table.Row>
                                          <Table.HeaderCell width={6}>
                                            Data Emissao
                                          </Table.HeaderCell>
                                          <Table.HeaderCell width={6}>
                                            Data Vencimento
                                          </Table.HeaderCell>
                                          <Table.HeaderCell width={6}>
                                            Valor
                                          </Table.HeaderCell>
                                          <Table.HeaderCell>
                                            <Checkbox
                                              checked={this.state.checkboxTodos}
                                              onChange={(e, a) =>
                                                this.changeCheckbox(
                                                  -1,
                                                  a.checked
                                                )
                                              }
                                            />
                                          </Table.HeaderCell>
                                        </Table.Row>
                                      </Table.Header>
                                      <Table.Body>
                                        {this.state.lista_lancamentos.map(
                                          (e, i) => (
                                            <Table.Row>
                                              <Table.Cell>
                                                {moment(e.data_emissao).format(
                                                  "DD/MM/YYYY"
                                                )}
                                              </Table.Cell>
                                              <Table.Cell>
                                                {moment(
                                                  e.data_vencimento
                                                ).format("DD/MM/YYYY")}
                                              </Table.Cell>
                                              <Table.Cell>
                                                {parseFloat(
                                                  e.valor
                                                ).toLocaleString("pt-BR", {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })}
                                              </Table.Cell>
                                              <Table.Cell>
                                                <Checkbox
                                                  checked={
                                                    this.state
                                                      .checkboxes_lancamentos[i]
                                                      .checked
                                                  }
                                                  onChange={(e, a) =>
                                                    this.changeCheckbox(
                                                      i,
                                                      a.checked
                                                    )
                                                  }
                                                />
                                              </Table.Cell>
                                            </Table.Row>
                                          )
                                        )}
                                      </Table.Body>
                                    </Table>
                                  </>
                                ) : null}
                              </Modal.Content>
                              <Modal.Actions>
                                <Button
                                  color="green"
                                  inverted
                                  onClick={() =>
                                    this.cancelar(
                                      matricula.id,
                                      matricula.pessoa_id
                                    )
                                  }
                                >
                                  <Icon name="checkmark" /> Sim
                                </Button>
                              </Modal.Actions>
                            </Modal>
                          </Col>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <Container
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell colSpan={6}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Pagination
                            count={this.state.totalPages}
                            variant="outlined"
                            shape="rounded"
                            boundaryCount={1}
                            page={this.state.activePage}
                            onChange={this.buscaMatriculados}
                          />
                        </div>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer> */}
                  <Pagination
                    boundaryRange={0}
                    defaultActivePage={1}
                    ellipsisItem={null}
                    //firstItem={null}
                    //lastItem={null}
                    siblingRange={1}
                    totalPages={this.state.totalPages}
                    onPageChange={this.pageChange}
                    activePage={this.state.paginaAtual}
                    disabled={this.state.listaMatriculados.length === 0}
                  />
                </Container>
              </Col>
            )}
          </div>
        ) : (
          <Dimmer active inverted>
            <Loader content="Carregando" />
          </Dimmer>
        )}
        <ModalMatriculadosTurma
          turmaServicoId={this.state.turmaModal?.turma_servico_id}
          turma={this.state.turmaModal}
          open={this.state.modalOpenMatriculadosTurma}
          onClose={() => this.setState({ modalOpenMatriculadosTurma: false })}
        />
        <ModalListaEsperaTurma
          turmaServicoId={this.state.turmaModal?.turma_servico_id}
          turma={this.state.turmaModal}
          open={this.state.modalOpenListaEsperaTurma}
          onClose={() => this.setState({ modalOpenListaEsperaTurma: false })}
        />{" "}
        <ModalAlteraTurma
          turmaServicoId={this.state.turmaModal?.turma_servico_id}
          turma={this.state.turmaModal2}
          open={this.state.modalOpenAlteraTurma}
          buscaMatriculados={this.buscaMatriculados}
          quantidade_Turma={this.state.quantidade_Turma}
          listaMatriculadosSlice={this.state.listaMatriculadosSlice}
          onClose={() => this.setState({ modalOpenAlteraTurma: false })}
        />
      </div>
    );
  }
}
