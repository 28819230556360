import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import { Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import CloseIcon from "@material-ui/icons/Close";

import "../styles.css";

export default class Competicao extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal dimmer="bluring" open={this.props.modalSumula}>
        <Modal.Header>
          {"Selecione a Pedana e Rodada para Imprimir a Súmula"}
          <IconButton
            className="bottomModal"
            onClick={() => this.props.changeModalSumula(false)}
            color="secondary"
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Content>
          <Row>
            <Col sm={12}>
              <Row>
                <Col sm={3}>
                  <TextField
                    id="pedana"
                    label="Nº da Pedana"
                    name="pedana"
                    onChange={this.props.alteracao}
                  />
                </Col>
                <Col sm={3}>
                  <TextField
                    id="rodada"
                    label="Nº da Rodada"
                    name="rodada"
                    onChange={this.props.alteracao}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Content>
        <Modal.Actions>
          <IconButton
            color="primary"
            type="button"
            style={{ outline: "none" }}
            onClick={this.props.gerarPdfSumula}
          >
            <SendIcon />
          </IconButton>
        </Modal.Actions>
      </Modal>
    );
  }
}
