/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Col, Row, Alert } from "react-bootstrap";
import { Header, Segment, Divider } from "semantic-ui-react";
import {
  IconButton,
  TextField,
  MenuItem,
  InputLabel,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  CircularProgress,
} from "@material-ui/core/";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Autocomplete from "@material-ui/lab/Autocomplete";

import requisicoes from "../../../utils/Requisicoes";
import api from "../../../services/api";
import Titular from "./titular.ico";
import Reserva from "./reserva.ico";
import TipoPenalidade from "../../../enum/TipoPenalidade";
import ModalAdicionaEvento from "./modalAdicionaEvento";
import ListaEventos from "./listaEventos";
import moment from "moment-timezone";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      informacoesPlacar: [],
      arbitros: [],
      eventos: [],
      atletasPartida: [],
      tipoVinculo: [],
      escalacaoA: [],
      escalacaoB: [],
      placarTime1: [],
      placarTime2: [],
      placares: [],
      viewEscalacao: [],
      viewEventos: [],
      placarID: [],
      local: "",
      eventoDescricao: "",
      tempo_partida_pendalidade: "",
      penalidadeDescricao: "",
      alertSalvarEventoMessage: "",
      statusPartida: "",
      arbitroPartida: null,
      inputValue: null,
      placarAgregadoTimeA: 0,
      placarAgregadoTimeB: 0,
      tamanhoEquipeA: 1,
      tamanhoEquipeB: 1,
      tamanhoMaximo: null,
      tamanhoTitulares: null,
      arbitroSelecionadoID: null,
      tipoEventoId: null,
      pessoaPrincipal: null,
      pessoaSecundaria: null,
      equipeId: null,
      pontos: null,
      dataEvento: null,
      horaEvento: null,
      hora: null,
      placarViewA: null,
      placarViewB: null,
      modalAdicionaEvento: false,
      alertTamanho: false,
      placarOk: false,
      informacoesOk: false,
      alertSalvarEvento: false,
    };
    // console.log("infopartida", this.props.location.query.infoPartida.rodada.campeonato_id);
    this.buscaInformacoesdaPartida();
  }

  buscaInformacoesdaPartida = async () => {
    var storeArray = [];

    storeArray.push(
      this.props.location.query?.infoPartida.placares[0].equipe_id
    );
    localStorage.setItem("ourarraykey", JSON.stringify(storeArray));
    var storeArray = localStorage.setItem(
      "partidaB",
      this.props.location.query?.infoPartida.placares[1].equipe_id
    );

    if (this.props.location.query?.infoPartida.placares[0].equipe_id) {
    }
    var equipe_id = [
      this.props.location.query?.infoPartida.placares[0].equipe_id,
      this.props.location.query?.infoPartida.placares[1].equipe_id,
    ];

    const regras = await api.post(
      "/modalidadeDesporto/buscaRegrasPorCampeonato" + requisicoes.entidade,
      {
        campeonato_id: this.props.location.query.campeonato_id,
        partida_id: this.props.location.query.infoPartida.id,
        rodada_id: this.props.location.query.rodadaId,
      },
      requisicoes.header
    );
    if (regras.data.campeonato[0].rodadas[0].partidas[0].hora) {
      this.setState({
        hora: regras.data.campeonato[0].rodadas[0].partidas[0].hora,
        local: regras.data.campeonato[0].rodadas[0].partidas[0].local,
      });
    }
    const tipovinculo = await api.post(
      "/tipovinculo/listar" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    var arbitros = [];
    for (let index = 0; index < regras.data.p_m_d.length; index++) {
      if (regras.data.p_m_d[index].tipo_vinculo.descricao === "Arbitro") {
        arbitros.push({
          value: regras.data.p_m_d[index].id,
          label:
            regras.data.p_m_d[index].pessoa.nome +
            " - " +
            regras.data.p_m_d[index].tipo_vinculo.descricao,
        });
      }
    }

    const retorno = await api.post(
      "/equipepessoas/listarPorTime" + requisicoes.entidade,
      {
        equipe_id: equipe_id,
        campeonato_id: this.props.location.query.campeonato_id,
        rodada_id: this.props.location.query.rodadaId,
        tempo_penalidade: this.props.location.query.infoPartida.rodada.campeonato.tempo_penalidade,
      },
      requisicoes.header
    );

    var participantes = [];
    let listaAtleta = [];
    for (let index = 0; index < retorno.data.length; index++) {
      const element = retorno.data[index]
      listaAtleta.push({
        id: retorno.data[index].id,
        nome: retorno.data[index].p_m_d.pessoa.nome,
        equipe_id: retorno.data[index].equipe_id,
        tipo_vinculo_id: retorno.data[index]
      });
      participantes.push({
        id: retorno.data[index].id,
        nome:
          retorno.data[index].p_m_d.pessoa.nome +
          " - " +
          retorno.data[index].equipe.organizacao.nome,
        equipe_id: retorno.data[index].equipe_id,
      });
    }

    const escalacao = await api.post(
      "/escalacao/listarPorTimes" + requisicoes.entidade,
      {
        equipe_id: equipe_id,
        partida_id: this.props.location.query.infoPartida.id,
      },
      requisicoes.header
    );

    var escalacaoSalva = [];

    for (let index = 0; index < escalacao.data.length; index++) {
      escalacaoSalva.push({
        nome: escalacao.data[index]?.equipe_pessoas?.p_m_d?.pessoa?.nome,
        tipo_vinculo: escalacao.data[index]?.tipo_vinculo?.descricao,
        equipe_id: escalacao.data[index]?.equipe_pessoas?.equipe_id,
        id: escalacao.data[index]?.equipe_pessoas_id,
        time_nome:
          escalacao.data[index]?.equipe_pessoas?.equipe?.organizacao?.nome,
      });
    }
    const eventos = await api.post(
      "/partida/listarInformacoesPorPartida" + requisicoes.entidade,
      {
        partida_id: this.props.location.query.partida_id,
        tipo_campeonato: this.props.location.query.tipo_campeonato,
        equipe_id: equipe_id,
        campeonato_id: this.props.location.query.campeonato_id,
      },
      requisicoes.header
    );

    var indice1 = eventos.data.placares.findIndex(
      (element) =>
        element.equipe_id ===
        this.props.location.query.infoPartida.placares[0].equipe_id
    );
    var indice2 = eventos.data.placares.findIndex(
      (element) =>
        element.equipe_id ===
        this.props.location.query.infoPartida.placares[1].equipe_id
    );

    var placarAgregadoTimeA = 0;
    var placarAgregadoTimeB = 0;

    if (this.props.location.query.tipo_campeonato === "Mata-Mata") {
      const placares = await api.post(
        "/placar/listaPlacarPartidaAnterior" + requisicoes.entidade,
        {
          equipe_id: equipe_id,
          campeonato_id: this.props.location.query.campeonato_id,
          partida_id: this.props.location.query.partida_id,
          tipo_campeonato: this.props.location.query.tipo_campeonato,
        },
        requisicoes.header
      );

      for (let index = 0; index < placares.data.length; index++) {
        if (
          this.props.location.query.partida_id !==
          placares.data[index].partida_id
        ) {
          if (
            placares.data[index].equipe_id ===
            this.props.location.query.infoPartida.placares[0].equipe_id
          ) {
            placarAgregadoTimeA = placares.data[index].pontuacao;
          } else {
            placarAgregadoTimeB = placares.data[index].pontuacao;
          }
        }
      }
    }

    const arbitroPartida = await api.post(
      "/partida/arbitroPorPartida" + requisicoes.entidade,
      { partida_id: this.props.location.query.partida_id },
      requisicoes.header
    );

    this.state.arbitroPartida = arbitroPartida.data.pessoa?.nome;
    this.setState({
      placarViewA: eventos.data.placares[indice1].pontuacao,
      placarViewB: eventos.data.placares[indice2].pontuacao,
      placarAgregadoTimeB: placarAgregadoTimeB,
      arbitroPartida: this.state.arbitroPartida,
      viewEscalacao: listaAtleta,
      atletasPartida: participantes,
      listaAtleta: listaAtleta,
      viewEventos: eventos.data.evento_desporto,
      arbitros: arbitros,
      eventos: regras.data.tipo_e_m_dsprt,
      tamanhoTitulares: regras.data.quantidade_titulares,
      tamanhoMaximoEquipe: regras.data.tamanho_max_equipe,
      tipoVinculo: tipovinculo.data,
      placarID: eventos.data.placares,
      placarTime1: eventos.data.placares[0],
      placarTime2: eventos.data.placares[1],
      placares: eventos.data.placares,
      statusPartida: eventos.data.status,
    });
  };

  alteracaoSelectArbitro = async (event, option) => {
    this.buscaInformacoesdaPartida();
    this.setState({ arbitroSelecionadoID: option.value });
  };

  addLinhaInscricao = async (name) => {
    if (name === "tamanhoEquipeA") {
      if (this.state.tamanhoEquipeA < this.state.tamanhoMaximoEquipe) {
        this.setState({ tamanhoEquipeA: this.state.tamanhoEquipeA + 1 });
      } else {
        this.setState({ alertTamanho: true });
      }
    } else {
      if (this.state.tamanhoEquipeB < this.state.tamanhoMaximoEquipe) {
        this.setState({ tamanhoEquipeB: this.state.tamanhoEquipeB + 1 });
      } else {
        this.setState({ alertTamanho: true });
      }
    }
  };

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  alteracaoPlacar = async (event) => {
    var lista = [];
    if (event.target.name === "placarTime1") {
      lista = this.state.placarTime1;

      lista = {
        equipe_id: parseInt(event.target.id),
        pontuacao: parseInt(event.target.value),
        empate: false,
        colocacao: 0,
        partida_id: this.props.location.query.partida_id,
      };

      this.setState({ placarTime1: lista });
    } else {
      lista = this.state.placarTime2;

      lista = {
        equipe_id: parseInt(event.target.id),
        pontuacao: parseInt(event.target.value),
        empate: false,
        colocacao: 0,
        partida_id: this.props.location.query.partida_id,
      };

      this.setState({ placarTime2: lista });
    }
  };

  adicionarEvento = async (element, status) => {

    if (element) {
      this.setState({
        tipoEventoId: element.tipoEventoDesportoId,
        eventoDescricao: element.tipo_e_d.descricao,
        penalidadeDescricao: element.tipo_penalidade,
      });
    } else {
      this.setState({
        equipeId: null,
        pessoaPrincipal: null,
        pessoaSecundaria: null,
        penalidadeDescricao: "",
        tipoEventoId: null,
        eventoDescricao: "",
      });
    }

    this.setState({ modalAdicionaEvento: status });
  };

  salvarEvento = async () => {
    var info = [
      {
        tipo_evento_desporto_id: this.state.tipoEventoId,
        descricao: this.state.penalidadeDescricao,
        equipe_pessoas_principal: this.state.pessoaPrincipal,
        equipe_pessoas_secundaria: this.state.pessoaSecundaria,
        equipe_id: this.state.equipeId,
        tempo_partida_pendalidade: this.props.location.query.info.tempo_penalidade,
        pontos: this.state.pontos,
        data: moment().format("YYYY-MM-DD"),
        hora: moment().format("HH:mm"),
        partida_id: this.props.location.query.infoPartida.id,
        partida_rodada: this.props.location.query.infoPartida.rodada_id,
        campeonato: this.props.location.query.infoPartida.rodada.campeonato.id,
      },
    ];
    var pontuacao = 0;
    var placarA = this.state.placarViewA === null ? 0 : this.state.placarViewA;
    var placarB = this.state.placarViewB === null ? 0 : this.state.placarViewB;

    var equipe_id = [
      this.props.location.query.infoPartida.placares[0].equipe_id,
      this.props.location.query.infoPartida.placares[1].equipe_id,
    ];

    try {
      const salvar = await api.post(
        "/eventodesporto/salvar" + requisicoes.entidade,
        {
          info: info,
          quantidade_advertencia:
            this.props.location.query.info.modalidade_desporto
              .quantidade_advertencia,
          rodada: this.props.location.query.infoPartida.rodada.numero,
        },
        requisicoes.header
      );

      if (this.state.penalidadeDescricao === "pontuacao") {
        var indice = this.state.placares.findIndex(
          (element) => element.equipe_id === this.state.equipeId
        );
        var pontuacaoEquipeAtual =
          this.state.placares[indice].pontuacao === null
            ? 0
            : parseInt(this.state.placares[indice].pontuacao);

        pontuacao =
          parseInt(this.state.pontos) + parseInt(pontuacaoEquipeAtual);

        const salvar = await api.post(
          "/placar/atualizaPlacarUmTime" + requisicoes.entidade,
          {
            equipe_id: this.state.equipeId,
            pontuacao: pontuacao,
            partida_id: this.props.location.query.infoPartida.id,
          },
          requisicoes.header
        );
      }

      this.setState({ tipoEventoId: null });
      this.setState({ penalidadeDescricao: "" });

      this.setState({ pessoaPrincipal: null });

      this.setState({ pessoaSecundaria: null });

      this.setState({ modalAdicionaEvento: false });

      this.buscaInformacoesdaPartida();
    } catch (error) {
      console.log("Erro ao Salvar Evento: ", error.response);
      this.setState({ alertSalvarEvento: true });
      this.setState({ alertSalvarEventoMessage: error.response.data });
      setTimeout(() => {
        this.setState({ alertSalvarEvento: false });
      }, 2500);
    }
  };

  salvarEscalacao = async () => {
    try {
      var escalacao = [...this.state.escalacaoA, ...this.state.escalacaoB];
      const salvar = await api.post(
        "/escalacao/salvar" + requisicoes.entidade,
        { escalacao: escalacao },
        requisicoes.header
      );

      this.setState({
        escalacaoA: [],
        escalacaoB: [],
        tamanhoEquipeA: 1,
        tamanhoEquipeB: 1,
        tipoVinculo: [],
        atletasPartida: [],
      });

      this.buscaInformacoesdaPartida();
    } catch (error) {
      console.log("Erro ao salvar escalacao: ", error.respose);
    }
  };

  imprimir = async () => {
    const grupoPorCampeonato = await api.post(
      "/partida/gerarPdfEscalacao" + requisicoes.entidade,

      {
        partida_id: this.props.location.query.infoPartida.id,
      },
      requisicoes.header
    );

    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");

    const r = await api.get(
      "/partida/buscarPdfEscalacaoPorTime" +
      requisicoes.entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.id
    );
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "partida/buscarPdfEscalacaoPorTime" +
      requisicoes.entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.id;

    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  alteracaoEscalacaoTimeA = async (event, index) => {
    var timeA = [...this.state.escalacaoA];

    if (event.target.name === "jogador") {
      timeA[index] = {
        equipe_pessoas_id: event.target.value,
        tipo_vinculo_id: null,
        partida_id: this.props.location.query.infoPartida.id,
      };
    } else {
      timeA[index].tipo_vinculo_id = event.target.value;
    }

    this.setState({ escalacaoA: timeA });
  };

  alteracaoEscalacaoTimeB = async (event, index) => {
    var timeB = [...this.state.escalacaoB];

    if (event.target.name === "jogador") {
      timeB[index] = {
        equipe_pessoas_id: event.target.value,
        tipo_vinculo_id: null,
        partida_id: this.props.location.query.infoPartida.id,
      };
    } else {
      timeB[index].tipo_vinculo_id = event.target.value;
    }
    this.setState({ escalacaoB: timeB });
  };

  salvarOpcoesPartida = async () => {
    var partida = {
      hora: this.state.hora,
      local: this.state.local,
    };

    var arbitro = {
      arbitro_pessoas_modalidades_desporto_id: this.state.arbitroSelecionadoID,
      partida_id: this.props.location.query.partida_id,
      titular: true,
      funcao: "Arbitro Principal",
    };

    const salvarPartida = await api.post(
      "/partida/salvarInformacoesPartida" + requisicoes.entidade,
      {
        partida: partida,
        arbitro: arbitro,
        id: this.props.location.query.partida_id,
        campeonato_id: this.props.location.query.infoPartida.rodada.campeonato_id
      },
      requisicoes.header
    );
    this.buscaInformacoesdaPartida();
    this.setState({ informacoesOk: true });

    setTimeout(() => {
      this.setState({ informacoesOk: false });
    }, 2500);
  };

  finalizarPartida = async () => {
    try {
      if (window.confirm("Você realmente quer Encerrar a partida?")) {
        var lista1 = this.state.placarTime1;
        var lista2 = this.state.placarTime2;

        lista1.empate = lista1.pontuacao === lista2.pontuacao ? true : false;
        lista2.empate = lista1.pontuacao === lista2.pontuacao ? true : false;

        lista1.colocacao = lista1.empate
          ? 0
          : this.state.placarTime1.pontuacao > this.state.placarTime2.pontuacao
            ? 1
            : 2;
        lista2.colocacao = lista2.empate
          ? 0
          : this.state.placarTime1.pontuacao > this.state.placarTime2.pontuacao
            ? 2
            : 1;

        let infoPlacares = [lista1, lista2];

        var idEquipeEliminada = null;

        if (this.props.location.query.ida_volta) {
          if (
            this.state.placarAgregadoTimeA !== null &&
            this.state.placarAgregadoTimeB !== null
          ) {
            var placarTimeA = this.state.placarAgregadoTimeA + lista1.pontuacao;
            var placarTimeB = this.state.placarAgregadoTimeB + lista2.pontuacao;

            if (placarTimeA === placarTimeB) {
            } else if (placarTimeA > placarTimeB) {
              idEquipeEliminada = lista2.equipe_id;
            } else {
              idEquipeEliminada = lista1.equipe_id;
            }
          } else {
          }
        } else {
          if (lista1.pontuacao === lista2.pontuacao) {
          } else if (lista1.pontuacao > lista2.pontuacao) {
            idEquipeEliminada = lista2.equipe_id;
          } else {
            idEquipeEliminada = lista1.equipe_id;
          }
        }
        var equipe_id = [
          this.props.location.query.infoPartida.placares[0].equipe_id,
          this.props.location.query.infoPartida.placares[1].equipe_id,
        ];

        await api.post(
          "/partida/finalizarPartida" + requisicoes.entidade,
          {
            infoPlacares: infoPlacares,
            id: this.state.placarID,
            idEquipeEliminada: idEquipeEliminada,
            campeonato_id: this.props.location.query.campeonato_id,
            tipo_campeonato:
              this.props.location.query.info.tipo_campeonato.descricao,
            equipe_id: equipe_id,
            partida_id: this.props.location.query.partida_id,
            rodada_id: this.props.location.query.infoPartida.rodada.id
          },
          requisicoes.header
        );

        this.setState({ placarOk: true });

        setTimeout(() => {
          this.setState({ placarOk: false });
        }, 2500);
        this.setState({ statusPartida: "encerrada" });
        this.buscaInformacoesdaPartida();
      }
    } catch (error) {
      console.log("Erro ao finalizar partida: ", error);
    }
  };
  deleteListaEvento = async (event) => {
    if (window.confirm("Você realmente quer excluir esse Evento?")) {
      let index = this.state.viewEventos.indexOf(event);
      let indexB =
        this.props.location.query.infoPartida.placares.indexOf(event);
      if (index !== -1) {
        this.state.viewEventos.splice(index, 1);
      }

      if (indexB !== -1) {
        this.props.location.query.infoPartida.placares.splice(indexB, 1, "0");
      }
      const partidas = api.post(
        "/eventodesporto/excluirEvento" + requisicoes.entidade,
        {
          listaPartida: event,
          campeonato_id: this.props.location.query.infoPartida.rodada.campeonato_id
        },
        requisicoes.header
      );
      this.buscaInformacoesdaPartida();
      this.setState({
        viewEventos: this.props.viewEventos,
        placarViewB: this.props.location.query.infoPartida.placares,
      });
    }
  };
  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Segment>
          {/* <div>
            <Button
              disabled={this.state.statusPartida === "encerrada"}
              type="submit"
              style={{ outline: "none", border: "solid 2px" }}
              color="primary"
              size="small"
              onClick={() => this.finalizarPartida()}
            >
              Encerrar Partida
            </Button>
          </div> */}
          <div>
            <Row>
              {/*  */}
              <Link
                to={{
                  pathname: "/desporto/rodada",
                  query: {
                    info: this.props.location.query.info,
                    ida_volta: this.props.location.query.ida_volta,
                    todos_contra_todos:
                      this.props.location.query.todos_contra_todos,
                    campeonato_id: this.props.location.query.campeonato_id,
                    tipo_campeonato: this.props.location.query.tipo_campeonato,
                  },
                }}
              >
                <Button
                  // style={{ marginTop: "0.6%" }}
                  className="botaoTabble"
                  style={{
                    outline: "none",
                    border: "solid 2px",
                    marginLeft: "20%",
                  }}
                  size="small"
                >
                  Voltar
                </Button>
              </Link>

              <Button
                className="botaoTabble"
                disabled={this.state.statusPartida === "encerrada"}
                type="submit"
                style={{
                  outline: "none",
                  border: "solid 2px",
                  marginLeft: "2.3%",
                }}
                color="primary"
                size="small"
                onClick={() => this.finalizarPartida()}
              >
                Encerrar Partida
              </Button>
            </Row>
          </div>
        </Segment>
        <Row>
          <Col sm={6}>
            <Segment>
              <Header>
                <div className="flexSpaceBetween">
                  <div>Placar</div>
                </div>

                <Divider style={{ marginTop: " 1ch" }} />
              </Header>
              {this.state.placarOk ? (
                <Alert variant="success">Placar Salvo</Alert>
              ) : (
                <div className="flexSpaceAround">
                  <div>
                    {this.props.location.query.ida_volta
                      ? this.props.location.query.infoPartida.placares[0].equipe
                        .organizacao.nome +
                      "(" +
                      this.state.placarAgregadoTimeA +
                      ")"
                      : this.props.location.query.infoPartida.placares[0].equipe
                        .organizacao.nome}
                  </div>
                  <div>
                    <Form.Control
                      id={
                        this.props.location.query.infoPartida.placares[0].equipe
                          .id
                      }
                      disabled
                      name="placarTime1"
                      type="number"
                      onChange={this.alteracaoPlacar}
                      value={this.state.placarViewA}
                    ></Form.Control>
                  </div>

                  <div>X</div>
                  <div>
                    <Form.Control
                      id={
                        this.props.location.query.infoPartida.placares[1].equipe
                          .id
                      }
                      disabled
                      name="placarTime2"
                      type="number"
                      onChange={this.alteracaoPlacar}
                      value={this.state.placarViewB}
                    ></Form.Control>
                  </div>

                  <div>
                    {this.props.location.query.ida_volta
                      ? "(" +
                      this.state.placarAgregadoTimeB +
                      ")" +
                      this.props.location.query.infoPartida.placares[1].equipe
                        .organizacao.nome
                      : this.props.location.query.infoPartida.placares[1].equipe
                        .organizacao.nome}
                  </div>
                </div>
              )}
            </Segment>
          </Col>
          <Col sm={6}>
            <Segment>
              <Header>
                <div className="flexSpaceBetween">
                  <div className="flexSpaceBetween">
                    <div>Configuração e lançamento de Partida</div>
                  </div>
                  <div className="flexEnd">
                    <div>
                      <Button
                        className="botaoTabble"
                        disabled={this.state.statusPartida === "encerrada"}
                        type="submit"
                        style={{ outline: "none", border: "solid 2px" }}
                        color="primary"
                        onClick={() => this.salvarOpcoesPartida()}
                        size="small"
                      >
                        Salvar Opções
                      </Button>
                    </div>
                  </div>
                </div>

                <Divider style={{ marginTop: " 1ch" }} />
              </Header>

              {this.state.informacoesOk ? (
                <Alert variant="success">Informações Salvas com sucesso!</Alert>
              ) : (
                <Row style={{ marginTop: "1" }}>
                  <Col sm={3}>
                    <TextField
                      disabled={this.state.statusPartida === "encerrada"}
                      variant="standard"
                      placeholder="da Partida"
                      label="Local"
                      name="local"
                      onChange={this.alteracao}
                      value={this.state.local}
                      required
                    />
                  </Col>
                  <Col sm={3}>
                    <TextField
                      disabled={this.state.statusPartida === "encerrada"}
                      variant="standard"
                      style={{ marginTop: "2ch" }}
                      placeholder="da Partida"
                      Label="Hora"
                      name="hora"
                      type="time"
                      onChange={this.alteracao}
                      value={this.state.hora}
                      required
                    />
                  </Col>

                  <Col sm={6} style={{ marginTop: "-0.5ch" }}>
                    <InputLabel id="demo-mutiple-name-label"></InputLabel>

                    <Autocomplete
                      id="arbitroSelecionadoID"
                      // value={this.state.arbitroPartida}
                      onKeyUp={this.buscaInformacoesdaPartida}
                      onChange={this.alteracaoSelectArbitro}
                      options={this.state.arbitros}
                      inputValue={this.state.inputValue}
                      onInputChange={(event, newInputValue) => {
                        this.setState({ inputValue: newInputValue });
                      }}
                      getOptionLabel={(option) => {
                        return option.label;
                      }}
                      filterSelectedOptions={false}
                      disableListWrap
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            this.state.arbitroPartida
                              ? this.state.arbitroPartida
                              : "Selecine um árbitro"
                          }
                          size="small"
                          variant="standard"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {this.state.buscando ? (
                                  <CircularProgress
                                    color="inherit"
                                    size={20}
                                    style={{
                                      marginRight: "3ch",
                                    }}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
              )}
            </Segment>
          </Col>
        </Row>
        <Row style={{ marginTop: "1ch" }}>
          <Col sm={6}>
            <Segment>
              <Header>
                <div className="flexSpaceBetween">
                  <div>
                    {"Escalação " +
                      this.props.location.query.infoPartida.placares[0].equipe
                        .organizacao.nome}
                  </div>
                  <div>
                    {" "}
                    {/* <IconButton
                      disabled={this.state.statusPartida === "encerrada"}
                      style={{ outline: "none" }}
                      color="primary"
                      name="a"
                      onClick={() => this.addLinhaInscricao("tamanhoEquipeA")}
                    >
                      <AddCircleOutlineIcon />{" "}
                    </IconButton> */}
                  </div>
                </div>
                <Divider />
              </Header>

              {Array.from({ length: this.state.tamanhoEquipeA }).map(
                (element, index) => {
                  return (
                    <Row>
                      {/* <Col sm={6} style={{ marginTop: "2.5ch" }}>
                        <InputLabel id="demo-mutiple-name-label">
                          Jogadores
                        </InputLabel>

                        <Select
                          disabled={this.state.statusPartida === "encerrada"}
                          key={index}
                          variant="standard"
                          fullWidth
                          name="jogador"
                          onChange={(event) =>
                            this.alteracaoEscalacaoTimeA(event, index)
                          }
                        >

                          {this.state.atletasPartida.map((element) => {
                            return (
                              <MenuItem
                                hidden={
                                  element.equipe_id !==
                                  this.props.location.query.infoPartida
                                    .placares[0].equipe_id
                                }
                                value={element.id}
                              >
                                {element.nome}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Col> */}
                      {/* <Col sm={6} style={{ marginTop: "2.5ch" }}>
                        <InputLabel id="demo-mutiple-name-label">
                          Vinculo
                        </InputLabel>
                        <Select
                          variant="standard"
                          fullWidth
                          name="vinculo"
                          onChange={(event) =>
                            this.alteracaoEscalacaoTimeA(event, index)
                          }
                          disabled={!this.state.escalacaoA[index]}
                        >
                          {this.state.tipoVinculo.map((element, index) => {
                            return (
                              <MenuItem
                                value={element.id}
                                hidden={
                                  element.descricao !== "Titular" &&
                                  element.descricao !== "Reserva"
                                }
                              >
                                {element.descricao}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Col> */}
                    </Row>
                  );
                }
              )}
              {/* <Divider /> */}

              <TableContainer component={Paper}>
                <Table size="medium" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell></TableCell>

                      <TableCell>Situação</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  {/* {// Todos  } */}

                  {this.state.viewEscalacao.length > 0
                    ? this.state.viewEscalacao.map((element, index) => {
                      return (
                        <TableBody>
                          <TableRow
                            hidden={
                              element.equipe_id !==
                              this.props.location.query.infoPartida
                                .placares[0].equipe_id
                            }
                          >
                            <TableCell component="th">
                              {element.nome}
                            </TableCell>
                            <TableCell
                              style={
                                element.tipo_vinculo === "Titular"
                                  ? {
                                    backgroundImage: `url(${Titular})`,
                                    backgroundRepeat: "no-repeat",
                                  }
                                  : element.tipo_vinculo === "Reserva"
                                    ? {
                                      backgroundImage: `url(${Reserva})`,
                                      backgroundRepeat: "no-repeat",
                                    }
                                    : {}
                              }
                            ></TableCell>
                            <TableCell component="th">
                              {element.tipo_vinculo}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                    : ""}
                </Table>
              </TableContainer>
            </Segment>
          </Col>

          <Col sm={6}>
            <Segment>
              <Header>
                <div className="flexSpaceBetween">
                  <div>
                    {"Escalação " +
                      this.props.location.query.infoPartida.placares[1].equipe
                        .organizacao.nome}
                  </div>
                  <div>
                    {" "}
                    {/* <IconButton
                      disabled={this.state.statusPartida === "encerrada"}
                      style={{ outline: "none" }}
                      color="primary"
                      name="a"
                      onClick={() => this.addLinhaInscricao("tamanhoEquipeB")}
                    >
                      <AddCircleOutlineIcon />{" "}
                    </IconButton> */}
                  </div>
                </div>

                <Divider />
              </Header>
              {Array.from({ length: this.state.tamanhoEquipeB }).map(
                (element, index) => {
                  return (
                    <Row>
                      {/* <Col sm={6} style={{ marginTop: "2.5ch" }}>
                        <InputLabel id="demo-mutiple-name-label">
                          Jogadores
                        </InputLabel>

                        <Select
                          disabled={this.state.statusPartida === "encerrada"}
                          variant="standard"
                          fullWidth
                          name="jogador"
                          onChange={(event) =>
                            this.alteracaoEscalacaoTimeB(event, index)
                          }
                          key={index}
                        >
                          {this.state.atletasPartida.map((element) => {
                            return (
                              <MenuItem
                                value={element.id}
                                hidden={
                                  element.equipe_id !==
                                  this.props.location.query.infoPartida
                                    .placares[1].equipe_id
                                }
                              >
                                {element.nome}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Col>

                      <Col sm={6} style={{ marginTop: "2.5ch" }}>
                        <Select
                          variant="standard"
                          fullWidth
                          vinculo="vinculo"
                          onChange={(event) =>
                            this.alteracaoEscalacaoTimeB(event, index)
                          }
                          disabled={!this.state.escalacaoB[index]}
                        >
                          {this.state.tipoVinculo.map((element, index) => {
                            return (
                              <MenuItem
                                value={element.id}
                                hidden={
                                  element.descricao !== "Titular" &&
                                  element.descricao !== "Reserva"
                                }
                              >
                                {element.descricao}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Col> */}
                    </Row>
                  );
                }
              )}
              {/* <Divider /> */}
              <TableContainer component={Paper}>
                <Table size="medium" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>

                      <TableCell>Situação</TableCell>
                    </TableRow>
                  </TableHead>
                  {/* {// Todos  } */}

                  {this.state.viewEscalacao.length > 0
                    ? this.state.viewEscalacao.map((element, index) => {
                      return (
                        <TableBody>
                          <TableRow
                            hidden={
                              element.equipe_id !==
                              this.props.location.query.infoPartida
                                .placares[1].equipe_id
                            }
                          >
                            <TableCell component="th">
                              {element.nome}
                            </TableCell>
                            <TableCell
                              style={
                                element.tipo_vinculo === "Titular"
                                  ? {
                                    backgroundImage: `url(${Titular})`,
                                    backgroundRepeat: "no-repeat",
                                  }
                                  : element.tipo_vinculo === "Reserva"
                                    ? {
                                      backgroundImage: `url(${Reserva})`,
                                      backgroundRepeat: "no-repeat",
                                    }
                                    : {}
                              }
                            ></TableCell>
                            <TableCell component="th">
                              {element.tipo_vinculo}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })
                    : ""}
                </Table>
              </TableContainer>
            </Segment>
          </Col>
        </Row>

        <Row
          style={{ marginTop: "1ch" }}
          hidden={
            !this.state.viewEscalacao.some(
              (item) =>
                item.equipe_id ===
                this.props.location.query.infoPartida.placares[0].equipe.id
            ) &&
            !this.state.viewEscalacao.some(
              (item) =>
                item.equipe_id ===
                this.props.location.query.infoPartida.placares[1].equipe.id
            )
          }
        >
          <Col sm={12}>
            <Segment>
              <Header>
                <div>Cadastrar Evento</div>
                <Divider />
              </Header>
              <div className="flexSpaceBetween">
                {this.state.eventos.length > 0
                  ? this.state.eventos.map((element, index) => {
                    return (
                      <div>
                        <Button
                          disabled={this.state.statusPartida === "encerrada"}
                          style={{ outline: "none", borderRadius: "15px" }}
                          id={element.tipoEventoDesportoId}
                          name={element.descricao}
                          className={element.tipo_penalidade}
                          onClick={() => this.adicionarEvento(element, true)}
                        >
                          {element.tipo_e_d.descricao +
                            " - " +
                            TipoPenalidade.find(
                              (e) => e.value === element.tipo_penalidade
                            ).label}
                        </Button>
                      </div>
                    );
                  })
                  : ""}
              </div>
            </Segment>
          </Col>
        </Row>
        <ListaEventos
          viewEventos={this.state.viewEventos}
          deleteListaEvento={this.deleteListaEvento}
          infoPartida={this.props.location.query.infoPartida}
          infoEquipes={this.props.location.query.infoPartida.placares}
        />

        <ModalAdicionaEvento
          modalAdicionaEvento={this.state.modalAdicionaEvento}
          adicionarEvento={this.adicionarEvento}
          infoPartida={this.props.location.query.infoPartida}
          tipoEventoId={this.state.tipoEventoId}
          eventoDescricao={this.state.eventoDescricao}
          penalidadeDescricao={this.state.penalidadeDescricao}
          infoEquipes={this.props.location.query.infoPartida.placares}
          atletasPartida={this.state.atletasPartida}
          viewEscalacao={this.state.viewEscalacao}
          salvarEvento={this.salvarEvento}
          alteracaoTimeEvento={this.alteracaoTimeEvento}
          equipeId={this.state.equipeId}
          alteracao={this.alteracao}
          alertSalvarEvento={this.state.alertSalvarEvento}
          alertSalvarEventoMessage={this.state.alertSalvarEventoMessage}
          pontos={this.state.pontos}
          pessoaPrincipal={this.state.pessoaPrincipal}
        />

        {/* <Segment clearing>
          <Header>
            <div className="flexSpaceBetween">
              <div> */}
        {/* <Button
                  // disabled={
                  //   this.state.escalacaoA.length === 0 &&
                  //   this.state.escalacaoB.length === 0
                  // }
                  type="submit"
                  style={{
                    outline: "none",
                    border: "solid 2px",
                  }}
                  onClick={() => this.salvarEscalacao()}
                >
                  Salvar Escalacao
                </Button> */}

        {/* <Button
                  type="submit"
                  style={{
                    outline: "none",
                    border: "solid 2px",
                    margin: "10px",
                  }}
                  onClick={() => this.imprimir()}
                >
                  Imprimir Escalação
                </Button> */}
        {/* </div>
            </div>
          </Header>
        </Segment> */}
      </div>
    );
  }
}
