import React, { Component } from "react";
import { Table, Button, Message, Icon, Dropdown } from "semantic-ui-react";
import { Row, Col, Form, FormControl } from "react-bootstrap";

import { Link } from "react-router-dom";

// Requisições
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

// Utils
import { parseISO, format } from "date-fns";
import moment from "moment-timezone";

const tipos = [
  {
    key: "conta_contabil",
    value: "conta_contabil",
    text: "Conta Contábil",
  },
  { key: "data", value: "data", text: "Data" },
];

export default class LancamentoBancarioLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // inputs
      data_inicio: moment().startOf("month").format("YYYY-MM-DD"),
      data_fim: moment().format("YYYY-MM-DD"),
      tipo: "data",
      conta_contabil_id: null,

      // busca
      lista: [],

      // Options
      optionsContaContabil: [],

      // State button / Modal / message
      excluindo: false,
      visible: false,
    };
    this.buscaData();
  }

  pesquisaContaContabil = async (event, props) => {
    try {
      const resposta = await api.post(
        "/contaContabil/pesquisa" + requisicoes.entidade,
        { pesquisa: props },
        requisicoes.header
      );
      this.setState({
        optionsContaContabil: resposta.data.map((e) => {
          return {
            value: e.id,
            text: e.codigo + " - " + e.descricao,
          };
        }),
      });
    } catch (error) {}
  };

  excluir = async (event, index) => {
    try {
      let lista = [...this.state.lista];
      this.setState({ excluindo: parseInt(lista[index].id) });

      await api.post(
        "/lancamentoBancario/excluir" + requisicoes.entidade,
        { id: lista[index].id },
        requisicoes.header
      );

      setTimeout(() => {
        this.handleShow();
        let idx = lista.findIndex((e) => e.id === parseInt(lista[index].id));
        lista.splice(idx, 1);
        this.setState({ lista: lista, excluindo: false });
      }, 1000);
    } catch (error) {
      alert("Ocorreu um erro ao excluir o lançamento.");
      this.setState({ excluindo: false });
    }
  };

  buscaData = async (event) => {
    const resp = await api.post(
      "/lancamentoBancario/pesquisaData" + requisicoes.entidade,
      { data_inicio: this.state.data_inicio, data_fim: this.state.data_fim },
      requisicoes.header
    );
    this.setState({ lista: resp.data });
  };

  buscaConta = async (event) => {
    const resp = await api.post(
      "/lancamentoBancario/pesquisaConta" + requisicoes.entidade,
      { conta_contabil_id: this.state.conta_contabil_id },
      requisicoes.header
    );
    this.setState({ lista: resp.data });
  };

  handleShow = () => {
    this.setState({ visible: true });
    setTimeout(() => {
      this.setState({ visible: false });
    }, 2500);
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  alteracaoTipo = (e, { value }) => this.setState({ tipo: value });

  render() {
    return (
      <div className="lista">
        <Row>
          <Col sm={2}>
            <Form.Label>Tipo</Form.Label>
            <Dropdown
              fluid
              selection
              options={tipos}
              defaultValue={this.state.tipo}
              onChange={this.alteracaoTipo}
            />
          </Col>
          {this.state.tipo === "conta_contabil" && (
            <Col md={6}>
              <Form.Label>Conta Bancária</Form.Label>
              <Dropdown
                search
                selection
                fluid
                onChange={(e, a) =>
                  this.setState({ conta_contabil_id: a.value })
                }
                onSearchChange={(e, a) => {
                  this.pesquisaContaContabil(e, a.searchQuery);
                }}
                options={this.state.optionsContaContabil}
                value={this.state.conta_contabil_id}
              />
            </Col>
          )}

          {this.state.tipo === "data" && (
            <>
              <Col sm={3}>
                <Form.Label>Período Inicial:</Form.Label>
                <FormControl
                  type="date"
                  name="data_inicio"
                  onChange={this.alteracao}
                  defaultValue={this.state.data_inicio}
                />
              </Col>
              <Col sm={3}>
                <Form.Label>Período Final:</Form.Label>
                <FormControl
                  type="date"
                  name="data_fim"
                  onChange={this.alteracao}
                  value={this.state.data_fim}
                />
              </Col>
            </>
          )}

          <Col
            md="auto"
            style={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Button
              positive
              onClick={
                this.state.tipo === "data"
                  ? this.buscaData
                  : this.state.tipo === "conta_contabil"
                  ? this.buscaConta
                  : () => {}
              }
            >
              Buscar
            </Button>
          </Col>
        </Row>

        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Número</Table.HeaderCell>
              <Table.HeaderCell>Conta Bancária Crédito</Table.HeaderCell>
              <Table.HeaderCell>Conta Bancária Débito</Table.HeaderCell>
              <Table.HeaderCell>Data</Table.HeaderCell>
              <Table.HeaderCell style={{ textAlign: "right" }}>
                Valor
              </Table.HeaderCell>
              <Table.HeaderCell width={2} textAlign="center">
                <Link to={{ pathname: "/lancamentoBancario" }}>
                  <Button positive icon="add" size="tiny"></Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.lista.map((e, i) => (
              <Table.Row key={e.id}>
                <Table.Cell>{e.numero}</Table.Cell>
                <Table.Cell>
                  {e.conta_contabil_credito &&
                    e.conta_contabil_credito.codigo +
                      " - " +
                      e.conta_contabil_credito.descricao}
                </Table.Cell>
                <Table.Cell>
                  {e.conta_contabil_debito &&
                    e.conta_contabil_debito.codigo +
                      " - " +
                      e.conta_contabil_debito.descricao}
                </Table.Cell>
                <Table.Cell width={2}>
                  {e.data && format(parseISO(e.data), "dd/MM/yyyy")}
                </Table.Cell>
                <Table.Cell width={2} style={{ textAlign: "right" }}>
                  {e.valor &&
                    parseFloat(e.valor).toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </Table.Cell>
                <Table.Cell width={2} textAlign="center">
                  <Link
                    to={{
                      pathname: "/lancamentoBancario",
                      query: { id: e.id },
                    }}
                  >
                    <Button primary icon="edit" size="tiny"></Button>
                  </Link>
                  <Button
                    icon="trash"
                    size="tiny"
                    negative
                    onClick={(event) => this.excluir(event, i)}
                    loading={
                      this.state.excluindo &&
                      this.state.excluindo === parseInt(e.id)
                    }
                    disabled={this.state.excluindo}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        {this.state.visible && (
          <Message
            positive
            icon
            floating
            visible
            onDismiss={() => this.setState({ visible: false })}
          >
            <Icon color="green" name="check circle" size="small" />
            <Message.Content>
              <Message.Header>Lançamento excluído com sucesso</Message.Header>
            </Message.Content>
          </Message>
        )}
      </div>
    );
  }
}
