/* eslint-disable no-unused-vars */
import React, { Component } from "react";

import { Header, Divider, Segment } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import { Button, Icon, Snackbar, Alert } from "@material-ui/core";
import { CloudUpload, CloudDownload } from "@material-ui/icons/";

import api from "../../../services/api";

import ModalEnviarSumula from "./modalEnviarSumula";
export default class Sumulas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalidades: [],
      openModalSumulas: false,
      mensagemErro: false,
      mensagem: false,
    };
    this.visualiza();
  }
  visualiza = async () => {
    const modalidades = await api.post(
      "/modalidadeDesporto/listar" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ modalidades: modalidades.data });
  };

  baixarSumula = async (modalidade_id) => {
    try {
      const modalidades = await api.post(
        "/modalidadeDesporto/listar" + requisicoes.entidade,
        {},
        requisicoes.header
      );

      const usuarioLogado = await api.post(
        "/usuario/usuarioLogado" + requisicoes.entidade,
        {},
        requisicoes.header
      );
      var localStorage = window.localStorage;
      var token = localStorage.getItem("token_clube");

      const r = await api.get(
        "/modalidadeDesporto/arquivoPDFSumula" +
          requisicoes.entidade +
          "&modalidade_id=" +
          modalidade_id +
          "&t=Baer " +
          token +
          "&userId=" +
          usuarioLogado.data.id
      );
      var oReq = new XMLHttpRequest();

      var URLToPDF =
        r.config.baseURL +
        "modalidadeDesporto/arquivoPDFSumula" +
        requisicoes.entidade +
        "&modalidade_id=" +
        modalidade_id +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.id;

      oReq.open("GET", URLToPDF, true);
      oReq.responseType = "blob";
      oReq.onload = function () {
        const file = new Blob([oReq.response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      };
      oReq.send();
    } catch (e) {
      console.log("error: ", e);
    }

    this.setState({ imprimindo: false });
  };

  render() {
    return (
      <div>
        <Header as="h2" dividing style={{ paddingTop: 20 }}>
          Súmulas por Modalidade
        </Header>
        {this.state.modalidades.map((e, i) => (
          <div>
            <Segment>
              <Row style={{ paddingBottom: 10 }}>
                <Col sm={5}>
                  {
                    <span style={{ fontWeight: "bold", fontSize: 20 }}>
                      {e.nome}
                    </span>
                  }
                </Col>
                <Col md="auto">
                  <Button
                    variant="contained"
                    startIcon={<CloudUpload />}
                    color="inherit"
                    onClick={() => {
                      this.setState({
                        modalidadeSelecionadaId: e.id,
                        openModalSumulas: true,
                      });
                    }}
                  >
                    Enviar súmula
                  </Button>
                </Col>
                <Col md="auto">
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<CloudDownload />}
                    onClick={() => this.baixarSumula(e.id)}
                  >
                    Baixar súmula
                  </Button>
                </Col>
              </Row>
            </Segment>
          </div>
        ))}
        <ModalEnviarSumula
          openModal={this.state.openModalSumulas}
          changeOpenModal={(openModal) => {
            this.setState({ openModalSumulas: openModal });
          }}
          changeOpenSnackbar={(openSnackbar, error) => {
            this.setState({ mensagem: openSnackbar, mensagemErro: error });
          }}
          modalidade_id={this.state.modalidadeSelecionadaId}
        />

        <Snackbar
          open={this.state.mensagem}
          autoHideDuration={3000}
          onClose={() => {
            this.setState({ mensagem: false });
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          fullWidth
        >
          <Alert
            variant="filled"
            severity={this.state.mensagemErro ? "error" : "success"}
            onClose={() => {
              this.setState({ mensagem: false });
            }}
            onError={() =>
              setTimeout(() => {
                this.setState({ mensagem: false });
              }, 3000)
            }
          >
            {this.state.mensagemErro
              ? "Erro ao enviar o arquivo"
              : "Salvo com sucesso"}
          </Alert>
        </Snackbar>
      </div>
    );
  }

  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
  };
}
