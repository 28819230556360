import React, { Component } from "react";
import {
  Container,
  Header,
  Menu,
  Segment,
  Table,
  Image,
  Input,
  Divider,
  Modal,
} from "semantic-ui-react";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import { Link } from "react-router-dom";

import moment from "moment-timezone";
import Icon from "@material-ui/core/Icon";

import Button from "@material-ui/core/Button";

import FilterTiltShiftIcon from "@material-ui/icons/FilterTiltShift";

import AccessTimeIcon from "@material-ui/icons/AccessTime";

import SettingsIcon from "@material-ui/icons/Settings";

import IconButton from "@material-ui/core/IconButton";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import HistoryIcon from "@material-ui/icons/History";

import "../styles.css";

export default class lista extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const percentage = 10;
    return (
      <Table striped selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>ETAPAS</Table.HeaderCell>
            {this.props.tipoEvento === "treino" ? (
              <Table.HeaderCell with={2}>DATA</Table.HeaderCell>
            ) : (
              <Table.HeaderCell width={2}>TEMPORADA</Table.HeaderCell>
            )}
            {this.props.tipoEvento === "treino" ? (
              <Table.HeaderCell with={2}>DESCRIÇÃO</Table.HeaderCell>
            ) : (
              <Table.HeaderCell width={2}>DATA</Table.HeaderCell>
            )}

            <Table.HeaderCell width={1} textAlign="center">
              STATUS{" "}
            </Table.HeaderCell>

            <Table.HeaderCell width={1} textAlign="center">
              {this.props.tipoEvento === "treino" ? (
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  floated="right"
                >
                  <Icon onClick={() => this.props.changeModalEtapa(true)}>
                    add_circle
                  </Icon>
                </IconButton>
              ) : (
                "CONFIG"
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.etapas.map((element, index) => (
            <Table.Row key={index}>
              <Table.Cell> {"Etapa " + element.numero_etapa}</Table.Cell>
              {this.props.tipoEvento === "treino" ? (
                <Table.Cell>
                  {element.data_inicio !== undefined
                    ? moment(element.data_inicio).format("DD/MM/YYYY")
                    : "Etapa Indisponível"}
                </Table.Cell>
              ) : (
                <Table.Cell>
                  {moment(element.data_abertura).format("YYYY")}
                </Table.Cell>
              )}

              {this.props.tipoEvento === "treino" ? (
                <Table.Cell> {element.descricao}</Table.Cell>
              ) : (
                <Table.Cell>
                  {" "}
                  {element.data_inicio !== null
                    ? moment(element.data_inicio).format("DD/MM/YYYY")
                    : "Data indefinida"}
                </Table.Cell>
              )}
              <Table.Cell textAlign="center">
                {element.aberta === false ? (
                  "Etapa Encerrada"
                ) : element.aberta !== true ? (
                  element.pedana !== null ? (
                    <Link
                      to={{
                        pathname: "/eventos/lancamentoInscricao",
                        query: {
                          etapa_id: element.id,
                          evento_id: element.evento_id,
                          status: element.aberta,
                          pedana: element.pedana,
                          numero_etapa: element.numero_etapa,
                          tipo_evento: this.props.tipoEvento,
                          regra_pontuacao: this.props.regraPontuacao,
                        },
                      }}
                    >
                      {" "}
                      <Button
                        className="botaoTabble"
                        disabled={
                          element.pedana === null || element === undefined
                        }
                        icon
                        size="mini"
                        style={{ outline: "none" }}
                      >
                        <AccessTimeIcon
                          size="small"
                          name="configure"
                          id={element.id}
                        />
                      </Button>
                    </Link>
                  ) : (
                    <Button
                      className="botaoTabbleDesabled"
                      disabled={
                        element.pedana === null || element === undefined
                      }
                      icon
                      size="mini"
                      style={{ outline: "none" }}
                    >
                      <AccessTimeIcon
                        size="small"
                        name="configure"
                        id={element.id}
                      />
                    </Button>
                  )
                ) : (
                  <Link
                    disabled={element.aberta === false}
                    to={{
                      pathname: "/eventos/lancamentoInscricao",
                      query: {
                        etapa_id: element.id,
                        evento_id: element.evento_id,
                        status: element.aberta,
                        pedana: element.pedana,
                        numero_etapa: element.numero_etapa,
                        tipo_evento: this.props.tipoEvento,
                        regra_pontuacao: this.props.regraPontuacao,
                      },
                    }}
                  >
                    <Button
                      className="botaoTabble"
                      disabled={element.aberta === false}
                      icon
                      size="mini"
                      style={{ outline: "none" }}
                    >
                      <FilterTiltShiftIcon
                        size="small"
                        name="configure"
                        id={element.id}
                      />
                    </Button>
                  </Link>
                )}
              </Table.Cell>
              <Table.Cell textAlign="center">
                {element.aberta !== false ? (
                  <Button
                    className={
                      element.pedana !== null
                        ? "botaoTabbleDesabled"
                        : "botaoTabble"
                    }
                    onClick={() =>
                      this.props.changeModal(true, element.numero_etapa)
                    }
                    icon
                    size="mini"
                    style={{ outline: "none" }}
                    disabled={element.pedana !== null}
                  >
                    <SettingsIcon
                      size="small"
                      name="configure"
                      id={element.id}
                    />
                  </Button>
                ) : (
                  <Button
                    className="botaoReabrirPedana"
                    icon
                    size="mini"
                    style={{ outline: "none" }}
                    onClick={() =>
                      this.props.changeModalreabrirEtapa(
                        true,
                        element.numero_etapa
                      )
                    }
                  >
                    <HistoryIcon
                      size="small"
                      name="configure"
                      id={element.id}
                    />
                  </Button>
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}
