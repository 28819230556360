import React, { Component } from "react";
import {
  Container,
  Header,
  Button,
  Divider,
  Segment,
  FormField,
  Input,
} from "semantic-ui-react";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import { Col, Form, FormControl, Row } from "react-bootstrap";
import Select from "react-select";
import renomearChave from "../../../services/renomearChave";
import requisicoes from "../../../utils/Requisicoes";

export default class Agencia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      codigo: null,
      nome: null,
      erroCodigoExiste: false,
      listaBanco: [],
      options: [],
    };
    this.visualizacao();
    this.alteracaoSelectBanco = this.alteracaoSelectBanco.bind(this);
    this.alteracao = this.alteracao.bind(this);
  }

  alteracao(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  alteracaoSelectBanco = async (event) => {
    this.setState({ banco_id: event.value });
    this.setState({ banco: event });
  };

  pesquisaBanco = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/banco/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaBanco").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista.push(objeto);
      });
      this.setState({ options: lista });
    }, 500);
  };

  visualizacao = async () => {
    if (this.props.location.query !== undefined) {
      const resposta = await api.post(
        "/agencia/visualiza" + requisicoes.entidade,
        this.props.location.query,
        requisicoes.header
      );
      this.setState(resposta.data);
    }
  };

  handleSubmit = (event) => {
    this.submit();
    event.preventDefault();
  };

  submit = async () => {
    const c = this.state;
    const dados = {
      cod: c.codigo,
      nome: c.codigo + " - " + c.nome,
      banco_id: c.banco_id,
    };
    let retorno = null;
    if (this.state.id !== null) {
      retorno = await api.post(
        "/agencia/alterar" + requisicoes.entidade + "&id=" + this.state.id,
        dados,
        requisicoes.header
      );
      window.location.href = "/contaBancaria";
    } else {
      retorno = await api.post(
        "/agencia/salvar" + requisicoes.entidade,
        dados,
        requisicoes.header
      );
      if (retorno.data === "Codigo ja existe") {
        this.setState({ erroCodigoExiste: true });
      } else {
        window.location.href = "/contaBancaria";
      }
    }
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Agência
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={5}>
              <Form.Label>Banco</Form.Label>
              <Select
                placeholder="Digite para buscar"
                name="banco"
                value={this.state.banco}
                onChange={this.alteracaoSelectBanco}
                options={this.state.options}
                onKeyDown={this.pesquisaBanco}
                inputId="pesquisaBanco"
                isDisabled={this.state.id !== null}
              />
            </Col>
            <Col sm={2}>
              <Form.Label>Código</Form.Label>
              <FormControl
                type="number"
                aria-label="Código"
                name="codigo"
                onChange={this.alteracao}
                isInvalid={!!this.state.erroCodigoExiste}
                defaultValue={this.state.codigo}
                required
              />
              <Form.Control.Feedback type="invalid">
                Código já existe
              </Form.Control.Feedback>
            </Col>
            <Col sm={5}>
              <Form.Label>Nome</Form.Label>
              <FormField
                placeholder="Nome"
                name="nome"
                control={Input}
                onChange={this.alteracao}
                required
                defaultValue={this.state.nome}
                fluid
              />
            </Col>
          </Row>
          <Divider />
          <Segment clearing>
            <Header floated="right">
              <Link to="/contaBancaria">
                <Button type="button">Voltar</Button>
              </Link>
              <Button positive type="submit">
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}
