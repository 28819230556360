import React, { Component } from "react";
import { Container, Header, Menu, Icon } from "semantic-ui-react";

import CancelarDividas from "./cancelamentoDividas/cancelar";
import ReativarDividas from "./reativarDividas/reativarDividas";

export default class Administrativo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo_clube: window.localStorage.getItem("tipo_clube"),
      // selectedMenu: localStorage.getItem("pagina_anterior"),
      selectedMenu: "cancelar",
    };
  }

  render() {
    let content = "";
    switch (this.state.selectedMenu) {
      case "cancelar":
        // localStorage.setItem("pagina_anterior", "cancelar");
        content = <CancelarDividas />;
        break;
      case "reativar":
        // localStorage.setItem("pagina_anterior", "cancelar");
        content = <ReativarDividas />;
        break;
      default:
        break;
    }
    return (
      <div>
        {/* <Header as="h1" dividing>
          Gerenciar dívidas
        </Header> */}
        <Menu secondary pointing>
          <Menu.Item
            name="cancelar"
            active={this.state.selectedMenu === "cancelar"}
            onClick={this.selectMenu.bind(null, "cancelar")}
          >
            {/* <Icon name="calendar alternate outline" /> */}
            Cancelar dívidas
          </Menu.Item>
          <Menu.Item
            name="reativar"
            active={this.state.selectedMenu === "reativar"}
            onClick={this.selectMenu.bind(null, "reativar")}
          >
            {/* <Icon name="calendar alternate outline" /> */}
            Reativar dívidas
          </Menu.Item>
        </Menu>
        {content}
      </div>
    );
  }
  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
  };
}
