const optionsCamposCarteirinhas = [
  {
    value: "imagemSocio",
    label: "Foto do sócio",
    // exemplo: "Foto",
  },
  {
    value: "nomeSocio",
    label: "Nome do sócio",
    exemplo: "João das Neves",
  },
  {
    value: "tipoTitulo",
    label: "Tipo de título",
    exemplo: "Patrimonial",
  },
  {
    value: "codigoAcao",
    label: "Código da ação",
    exemplo: "1000",
  },
  {
    value: "prefixoCodigoAcao",
    label: " Código da ação com prefixo de tipo de título",
    exemplo: "PTR 1000",
  },
  {
    value: "tipoTituloSec",
    label: "Tipo de título ação secundária",
    exemplo: "Projeto",
  },
  {
    value: "codigoAcaoSec",
    label: "Código da ação secundária",
    exemplo: "1000",
  },
  {
    value: "prefixoCodigoAcaoSec",
    label: "Código da ação secundária com prefixo de tipo de título",
    exemplo: "PJT 1000",
  },
  {
    value: "imagemClube",
    label: "Imagem do clube",
    // exemplo: "Clube",
  },
  {
    value: "codigoDeBarras",
    label: "Código de barras",
    exemplo: "Barcode",
  },
  {
    value: "codigoDeBarrasAcaoSec",
    label: "Código de barras ação secundária",
    exemplo: "Barcode ação secundária",
  },
  {
    value: "qrcode",
    label: "QR Code",
    // exemplo: "QR",
  },
  {
    value: "qrcodeAcaoSec",
    label: "QR Code acao secundaria",
    // exemplo: "QR",
  },
  {
    value: "texto",
    label: "Texto",
    exemplo: "Texto",
  },
  {
    value: "responsavel1",
    label: "Responsável 1",
    exemplo: "Nome Responsável 1",
  },
  {
    value: "responsavel2",
    label: "Responsável 2",
    exemplo: "Nome Responsável 2",
  },
  {
    value: "cargo",
    label: "Cargo a ser selecionado",
    exemplo: "Conselheiro Administrativo",
  },
  {
    value: "titularidade",
    label: "Titularidade da pessoa (Titular/Dependente)",
    exemplo: "Titular",
  },
  {
    value: "dataVencimento",
    label: "Data Vencimento",
    exemplo: "Data Vencimento",
  },
];

export default optionsCamposCarteirinhas;
