import React, { Component } from "react";
import { Col, Form, FormControl, Alert, Row } from "react-bootstrap";
import ValidadorCamposPessoa from "../../../../../utils/validadorCamposPessoa";
import InputMask from "react-input-mask";

export default class VisualizaPessoa extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Col
        sm={12}
        style={{ paddingTop: "10px", paddingLeft: "0", paddingRight: "0" }}
      >
        {ValidadorCamposPessoa(this.props.pessoa).map((e) => {
          var validado = false;
          return Object.entries(e).map((k, i) => {
            if (validado === true && k[0] === "mensagem") {
              return <Alert variant="danger">{k[1]}</Alert>;
            }
            if (k[1] === false) {
              validado = true;
            }
          });
          validado = false;
        })}
        <Row>
          <Col sm={6}>
            <Form.Label>Nome</Form.Label>
            <FormControl
              aria-describedby="basic-addon1"
              maxLength={40}
              onChange={this.props.alteracaoPessoa}
              name="nome"
              value={this.props.pessoa.nome}
            />
          </Col>

          <Col sm={6} hidden={this.props.pessoa.cnpj !== null}>
            <Form.Label>CPF</Form.Label>
            <InputMask
              mask="999.999.999-99"
              className="form-control"
              placeholder="CPF"
              id="cpf"
              name="cpf"
              onChange={this.props.alteracaoPessoa}
              value={this.props.pessoa.cpf}
            />
          </Col>
          <Col sm={6} hidden={this.props.pessoa.cpf !== null}>
            <Form.Label>CNPJ</Form.Label>
            <InputMask
              mask="99.999.999/9999-99"
              className="form-control"
              placeholder="CNPJ"
              id="cnpj"
              name="cnpj"
              onChange={this.props.alteracaoPessoa}
              value={this.props.pessoa.cnpj}
            />
          </Col>
          <Col sm={6}>
            <Form.Label>Endereço</Form.Label>
            <FormControl
              placeholder="Endereço"
              aria-label="Endereco"
              aria-describedby="basic-addon1"
              name="endereco"
              maxLength={40}
              onChange={this.props.alteracaoPessoa}
              value={this.props.pessoa.endereco}
            />
          </Col>
          <Col sm={6}>
            <Form.Label>Bairro</Form.Label>
            <FormControl
              placeholder="Bairro"
              aria-label="Bairro"
              aria-describedby="basic-addon1"
              name="bairro"
              maxLength={12}
              onChange={this.props.alteracaoPessoa}
              value={this.props.pessoa.bairro}
            />
          </Col>
          <Col sm={6}>
            <Form.Label>Numero</Form.Label>
            <FormControl
              placeholder="Numero"
              aria-label="Numero"
              aria-describedby="basic-addon1"
              name="numero"
              onChange={this.props.alteracaoPessoa}
              value={this.props.pessoa.numero}
            />
          </Col>
        </Row>
      </Col>
    );
  }
}
