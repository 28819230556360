import React, { Component } from "react";
import {
  Container,
  Header,
  Menu,
  Segment,
  Table,
  Image,
  Input,
  Divider,
  Modal,
} from "semantic-ui-react";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import "../styles.css";

export default class modalReabrirEtapa extends Component {
  constructor(props) {
    super(props);
    this.state = { checkReabrir: false };
  }
  checkboxVerify = () => {
    if (this.state.checkReabrir === false) {
      this.setState({ checkReabrir: true });
    } else {
      this.setState({ checkReabrir: false });
    }
  };

  render() {
    return (
      <Modal size="tiny" dimmer="bluring" open={this.props.modalReabrirEtapa}>
        <Modal.Header>
          {"Reabrir a Etapa " + this.props.numEtapa + "º etapa "}

          <IconButton
            className="bottomModal"
            onClick={() => this.props.changeModalreabrirEtapa(false, 0)}
            color="secondary"
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Content>
          {this.props.alertaReabrirEtapa === true ? (
            <Alert severity="success">
              <AlertTitle>Etapa Reaberta!</AlertTitle>
              Iremos redirecionar-lhe — <strong>Aguarde!</strong>
            </Alert>
          ) : (
            <Row>
              <Col sm={12}>
                <h6>
                  Ao clicar em reabrir etapa, você reabrirá uma etapa do evento
                  selecionado!
                </h6>
              </Col>

              <Col sm={12}>
                <div className="checkModalAjuste">
                  <div className="CheckBoxModal">
                    <Checkbox
                      root
                      name="checkReabrir"
                      id="checkReabrir"
                      onClick={this.checkboxVerify}
                    />
                    <div>
                      <h6> Estou ciente!</h6>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{ outline: "none" }}
            color="primary"
            aria-label="add to shopping cart"
            type="button"
            disabled={!this.state.checkReabrir}
            onClick={this.props.reabrirEtapa}
          >
            Reabrir Etapa
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
