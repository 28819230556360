import React, { Component } from "react";
import { Header, Segment } from "semantic-ui-react";
import { Row, Col } from "react-bootstrap";
import { Paper, Tabs, Tab } from "@material-ui/core";

import { Icon as Iconify } from "@iconify/react";
import stethoscopeIcon from "@iconify-icons/mdi/stethoscope";
import medicalBagIcon from "@iconify-icons/mdi/medical-bag";

// Components
import ExameMedico from "./exameMedico/lista";
import ExameFisico from "./exameFisico/lista";

export default class Exames extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selecionado:
        localStorage.getItem("pagina_anterior_exames") ||
        this.props.submenus[0].nome,
    };
  }

  changeTabMenu = async (event, newValue) => {
    localStorage.setItem("pagina_anterior_exames", newValue);
    this.setState({ selecionado: newValue });
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Exames
        </Header>
        <Segment>
          <Row style={{ paddingBottom: 10 }}>
            <Col sm={12}>
              <Paper square>
                <Tabs
                  indicatorColor="secondary"
                  textColor="primary"
                  style={{ outline: "none" }}
                  variant="scrollable"
                  onChange={this.changeTabMenu}
                  value={this.state.selecionado}
                >
                  <Tab
                    icon={<Iconify icon={stethoscopeIcon} width={24} />}
                    style={{ outline: "none" }}
                    label="Exame médico"
                    value={"exame_medico"}
                  ></Tab>
                  <Tab
                    icon={<Iconify icon={medicalBagIcon} width={24} />}
                    style={{ outline: "none" }}
                    label="Exame Físico"
                    value={"exame_fisico"}
                  ></Tab>
                </Tabs>
              </Paper>
            </Col>
          </Row>
          <TabPanel
            value={this.state.selecionado}
            index={"exame_medico"}
            {...this.props}
          >
            <ExameMedico />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"exame_fisico"}
            {...this.props}
          >
            <ExameFisico />
          </TabPanel>
        </Segment>
      </div>
    );
  }
}

function TabPanel(props) {
  const { children, value, index, submenus, ...other } = props;
  return submenus && submenus.find((e) => e.nome === index) ? (
    <div hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </div>
  ) : (
    <div></div>
  );
}
