import React, { Component } from "react";
import { Modal, Header, Icon } from "semantic-ui-react";

export default class ModalCapacidade extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal
        closeIcon
        size="mini"
        open={this.props.modalCapacidadeOpen}
        style={{ textAlign: "center" }}
      >
        <Header icon>
          <Icon name="remove" />
          APENAS {this.props.capacidade} PESSOAS
        </Header>
        <Modal.Content>PROTOCOLO DE SEGURANÇA CONTRA O COVID-19!</Modal.Content>
      </Modal>
    );
  }
}
