// eslint-disable-next-line import/no-anonymous-default-export
export default (pessoa) => {
  let listaValidacao = [];
  /*listaValidacao.push({
    nome: validaQuantidadeCaracteres(pessoa.nome, 35),
    mensagem: "Nome Muito Extenso",
  });*/
  listaValidacao.push({
    tipo: validaCampoNulo(pessoa.tipo),
    mensagem: "Tipo Vazio",
  });
  listaValidacao.push({
    cpf: validaCpf(pessoa.cpf),
    mensagem: "Cpf Inválido",
  });
  /*listaValidacao.push({
    cnpj: validaCnpj(pessoa.cnpj),
    mensagem: "Cnpj Inválido",
  });*/
  listaValidacao.push({
    endereco: validaQuantidadeCaracteres(pessoa.endereco, 40),
    mensagem: "Endereço Muito Extenso",
  }); /*
  listaValidacao.push({
    endereco: validaQuantidadeCaracteres(pessoa.bairro, 12),
    mensagem: "Bairro Muito Extenso",
  });*/
  return listaValidacao;
};

function validaCampoNulo(val) {
  if (val === null) {
    return false;
  } else {
    return true;
  }
}

function validaQuantidadeCaracteres(val, qtd) {
  if (val !== null) {
    if (val.length > qtd) {
      return false;
    } else {
      return true;
    }
  }
}

export function validaCpf(cpf) {  
  if (cpf !== null) {
    cpf = cpf.replace(/[^\d]/g, ""); // Remove caracteres não numéricos
    if (cpf.length !== 11) {
      return false; // CPF deve ter 11 dígitos
    }
    // Verifica se todos os dígitos são iguais (CPF inválido)
    if (/^(\d)\1+$/.test(cpf)) {
      return false;
    }
    // Calcula o primeiro dígito verificador
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let digit = (sum * 10) % 11;
    if (digit === 10) {
      digit = 0;
    }
    if (digit !== parseInt(cpf.charAt(9))) {
      return false; // CPF inválido
    }
    // Calcula o segundo dígito verificador
    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    digit = (sum * 10) % 11;
    if (digit === 10) {
      digit = 0;
    }
    if (digit !== parseInt(cpf.charAt(10))) {
      return false; // CPF inválido
    }
    return true; // CPF válido
  }
}

export function validaCnpj(cnpj) {
  if (cnpj) {
    cnpj = cnpj.toString();
    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj === "" || cnpj.length !== 14) return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(0))) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(1))) return false;

    return true;
  }
  return false;
}
