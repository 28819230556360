import React, { Component } from "react";
import {
  Header,
  Modal,
  Segment,
  Divider,
  Form as FormSemantic,
  Select,
} from "semantic-ui-react";
import { Button } from "@material-ui/core";
import { Col, Row, Form } from "react-bootstrap";

// Requisições
import api from "../../../services/api";
import Requisicoes from "../../../utils/Requisicoes";

// Utils
import moment from "moment";
import calculoMoraJuro from "../../../utils/calculoMoraJuro";

// Enum
import TipoLancamento from "../../../enum/TipoLancamento";
import TipoCaixa from "../../../enum/TipoCaxa";

export default class ModalCancelamento extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  reimprimirSangria = async () => {
    let dados = {
      descricao: this.props.lancamento_conta.descricao,
      tipo_lancamento: TipoLancamento.find(
        (e) => e.value === this.props.lancamento_conta.tipo_lancamento
      ).value,
      credito_debito: this.props.lancamento_conta.credito_debito,
      tipo: TipoCaixa.find((e) => e.value === "caixa").value,
      conta_bancaria_id: this.props.lancamento_conta.conta_bancaria_id,
      valor: this.props.lancamento_conta.valor,
    };

    await api.post(
      "/caixa/gerarReciboSangria" + Requisicoes.entidade,
      { dados: dados, agencia: null },
      Requisicoes.header
    );

    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");

    await api
      .get(
        "/caixa/buscarReciboSangria" + Requisicoes.entidade + "&t=Baer " + token
      )
      .then((resp) => {
        var oReq = new XMLHttpRequest();
        var URLToPDF = `${resp.config.baseURL}caixa/buscarReciboSangria${Requisicoes.entidade}&t=Baer ${token}`;
        oReq.open("GET", URLToPDF, true);
        oReq.responseType = "blob";
        oReq.onload = function () {
          const file = new Blob([oReq.response], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, "_blank");
        };
        oReq.send();
      });
  };

  reimprimirSuprimento = async () => {
    let dados = {
      descricao: this.props.lancamento_conta.descricao,
      tipo_lancamento: TipoLancamento.find(
        (e) => e.value === this.props.lancamento_conta.tipo_lancamento
      ).value,
      credito_debito: this.props.lancamento_conta.credito_debito,
      tipo: TipoCaixa.find((e) => e.value === "caixa").value,
      conta_bancaria_id: this.props.lancamento_conta.conta_bancaria_id,
      valor: this.props.lancamento_conta.valor,
    };

    await api.post(
      "/caixa/gerarReciboSuprimento" + Requisicoes.entidade,
      { dados: dados, agencia: null },
      Requisicoes.header
    );
    this.buscarComprovanteSuprimento();
  };

  buscarComprovanteSuprimento = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    await api
      .get("/caixa/buscarReciboSuprimento?e=" + entidade + "&t=Baer " + token)
      .then((resp) => {
        var oReq = new XMLHttpRequest();

        var URLToPDF =
          resp.config.baseURL +
          "caixa/buscarReciboSuprimento?e=" +
          entidade +
          "&t=Baer " +
          token;

        oReq.open("GET", URLToPDF, true);
        oReq.responseType = "blob";
        oReq.onload = function () {
          const file = new Blob([oReq.response], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, "_blank");
        };
        oReq.send();
      });
  };

  reimprimirRecibo = async (original) => {
    let listaBaixa = [];
    listaBaixa.push(this.props.lancamento_conta);

    setTimeout(async () => {
      const usuarioLogado = await api.post(
        "/usuario/usuarioLogado" + Requisicoes.entidade,
        {},
        Requisicoes.header
      );
      await api.post(
        "/caixa/comprovantePagamento" + Requisicoes.entidade,
        {
          lancamentos: listaBaixa,
          operador: usuarioLogado.data.nome,
          original: original,
        },
        Requisicoes.header
      );
      this.props.gerarPdfComprovante();
      setTimeout(async () => {
        this.props.handleClose();
      }, 500);
    }, 500);
  };

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.handleClose}
        centered={false}
        style={{ overflow: "visible" }}
      >
        <Header content="Título" />
        <Modal.Content>
          {this.props.lancamento_conta !== null && (
            <Segment.Group>
              <Segment.Group horizontal>
                <Segment>
                  <b>Numero:</b>{" "}
                  {this.props.lancamento_conta.numero_titulo !== null &&
                    this.props.lancamento_conta.numero_titulo}
                </Segment>
                <Segment>
                  <b>Descrição:</b>{" "}
                  {this.props.lancamento_conta.descricao !== null &&
                    this.props.lancamento_conta.descricao}
                </Segment>
                <Segment>
                  <b>Situação:</b>{" "}
                  {this.props.lancamento_conta.data_pagamento === null
                    ? "Em Aberto"
                    : "Pago"}
                </Segment>
              </Segment.Group>
              <Segment.Group horizontal>
                <Segment>
                  <Col sm={12} style={{ textAlign: "center" }}>
                    <Header as="h4">Pagador</Header>
                  </Col>
                  <Col sm={12}>
                    <Row>
                      <Col sm={6}>
                        <b>Nome: </b>
                        {this.props.lancamento_conta.pessoa !== null &&
                          this.props.lancamento_conta.pessoa.nome}
                      </Col>
                      {this.props.lancamento_conta.pessoa !== null && (
                        <Col sm={6}>
                          <b>CPF/CNPJ: </b>
                          {this.props.lancamento_conta.pessoa.cpf === null
                            ? this.props.lancamento_conta.pessoa.cnpj
                            : this.props.lancamento_conta.pessoa.cpf}
                        </Col>
                      )}
                    </Row>
                  </Col>
                  {this.props.lancamento_conta.pessoa !== null && (
                    <Col sm={12}>
                      <b>Endereço: </b>
                      {this.props.lancamento_conta.pessoa.endereco},{" "}
                      {this.props.lancamento_conta.pessoa.bairro} -
                      {this.props.lancamento_conta.pessoa.cidade}/
                      {this.props.lancamento_conta.pessoa.estado} CEP:{" "}
                      {this.props.lancamento_conta.pessoa.cep}
                    </Col>
                  )}
                </Segment>
              </Segment.Group>
              <Segment.Group horizontal>
                <Segment>
                  <Col sm={12} style={{ textAlign: "center" }}>
                    <Header as="h4">Informações</Header>
                  </Col>
                  <Col sm={12}>
                    <Row>
                      <Col sm={3}>
                        <b>Vencimento: &nbsp;&nbsp;</b>
                        {this.props.lancamento_conta.data_vencimento &&
                          moment(
                            this.props.lancamento_conta.data_vencimento
                          ).format("DD/MM/YYYY")}
                      </Col>
                      <Col sm={3}>
                        <b>Multa: &nbsp;&nbsp;</b>2.0%
                      </Col>
                      <Col sm={3}>
                        <b>Mora dia: &nbsp;&nbsp;</b>0,07%
                      </Col>
                      <Col sm={3}>
                        <b>Valor Titulo: &nbsp;&nbsp;</b>
                        {this.props.lancamento_conta.valor}
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12}>
                    <Row>
                      <Col sm={3}>
                        <b>Multa: &nbsp;&nbsp;</b>
                        {this.props.lancamento_conta.numero_titulo !== null &&
                          parseFloat(
                            this.props.lancamento_conta.valor_atraso
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </Col>
                      <Col sm={3}>
                        <b>Mora: &nbsp;&nbsp;</b>
                        {this.props.lancamento_conta.numero_titulo !== null &&
                          parseFloat(
                            calculoMoraJuro(
                              this.props.lancamento_conta.valor,
                              this.props.porcentagem_juros,
                              this.props.porcentagem_multa,
                              this.props.lancamento_conta.data_vencimento
                            ).mora
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </Col>
                      <Col sm={3}>
                        <b>Desconto: &nbsp;&nbsp;</b>
                        {this.props.lancamento_conta.numero_titulo !== null &&
                          parseFloat(
                            this.props.lancamento_conta.desconto
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </Col>
                      <Col sm={3}>
                        <b>Total: </b>
                        {this.props.lancamento_conta.valor !== null &&
                          this.props.lancamento_conta.data_vencimento !==
                            null &&
                          this.props.lancamento_conta.numero_titulo !== null &&
                          parseFloat(
                            this.props.lancamento_conta.valor_pago
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </Col>
                    </Row>
                  </Col>
                </Segment>
              </Segment.Group>
            </Segment.Group>
          )}
          {/* <Form onSubmit={this.handleSubmit}> */}
          <Row>
            <Col sm={6}>
              <Form.Label>Forma de Pagamento</Form.Label>
              <Form.Control
                as="select"
                name="forma_pagamento"
                // onChange={this.alteracao}
                value={
                  this.props.lancamento_conta !== null &&
                  this.props.lancamento_conta.forma_pagamento !== null &&
                  this.props.lancamento_conta.forma_pagamento
                }
                required
                disabled={
                  true
                  // this.props.lancamento_conta !== null &&
                  // this.props.lancamento_conta.forma_pagamento !== null
                }
              >
                <option></option>
                <option value="dinheiro">Dinheiro</option>
                <option value="cheque">Cheque</option>
                <option value="cartao">Cartão</option>
                <option value="cartao_credito">Cartão de Crédito</option>
                <option value="cartao_debito">Cartão de Crédito</option>
                <option value="pix">Pix</option>
                <option value="depositoBancario">Depósito</option>
                <option value="banco">Cobrança Bancária</option>
              </Form.Control>
            </Col>

            {this.props.lancamento_conta !== null &&
              this.props.lancamento_conta.forma_pagamento ===
                "depositoBancario" && (
                <Col sm={6}>
                  <Form.Label>Conta Bancária</Form.Label>
                  <FormSemantic>
                    <FormSemantic.Field
                      control={Select}
                      placeholder="Conta Bancária"
                      options={this.props.optionsContaBancaria}
                      value={this.props.lancamento_conta.conta_bancaria_id}
                      labelPosition="right"
                      readOnly={true}
                    />
                  </FormSemantic>
                </Col>
              )}
          </Row>

          <Divider />
          <Segment clearing>
            <Header floated="right">
              {this.props.lancamento_conta !== null &&
                this.props.lancamento_conta.data_pagamento === null &&
                this.props.lancamento_conta.tipo_lancamento ===
                  "cobrancaServicoAvulsa" && (
                  <Button variant="contained" type="submit">
                    Liquidar1
                  </Button>
                )}
              {this.props.lancamento_conta !== null &&
                this.props.lancamento_conta.tipo_lancamento !== "sangria" &&
                this.props.lancamento_conta.tipo_lancamento !==
                  "suprimento" && (
                  <Button
                    variant="contained"
                    style={{ marginLeft: "15px" }}
                    onClick={() => this.reimprimirRecibo(false)}
                  >
                    Imprimir unitário
                  </Button>
                )}
              {this.props.lancamento_conta !== null &&
                this.props.lancamento_conta.tipo_lancamento !== "sangria" &&
                this.props.lancamento_conta.tipo_lancamento !==
                  "suprimento" && (
                  <Button
                    variant="contained"
                    style={{ marginLeft: "15px" }}
                    onClick={() => this.reimprimirRecibo(true)}
                  >
                    Imprimir original
                  </Button>
                )}
              {this.props.lancamento_conta !== null &&
                this.props.lancamento_conta.tipo_lancamento === "sangria" && (
                  <Button
                    variant="contained"
                    style={{ marginLeft: "15px" }}
                    onClick={this.reimprimirSangria}
                  >
                    Imprimir
                  </Button>
                )}
              {this.props.lancamento_conta !== null &&
                this.props.lancamento_conta.tipo_lancamento ===
                  "suprimento" && (
                  <Button
                    variant="contained"
                    style={{ marginLeft: "15px" }}
                    onClick={this.reimprimirSuprimento}
                  >
                    Imprimir
                  </Button>
                )}
            </Header>
          </Segment>
          {/* </Form> */}
        </Modal.Content>
      </Modal>
    );
  }
}
