import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import {  Modal } from "semantic-ui-react";
import {
  TextField,
  Button,
  ThemeProvider,  
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import {Save } from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";


export default class ModalAdicionaPermissao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // inputs
      nome: "",

      // state botões
      loading: false,
    };
  }

  adicionarPermissao = async () => {
    try {
      this.setState({ loading: true });

      const permissao = await api.post(
        "/permissao/salvar" + requisicoes.entidade,
        {
          nome: this.state.nome,
        },
        requisicoes.header
      );

      this.props.addPermissao(permissao.data);

      setTimeout(() => {
        this.setState({ loading: false });
        this.props.onClose();
      }, 500);
    } catch (error) {
      alert("Ocorreu um erro ao salvar a permissão");
    }
  };

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.onClose}>
        <Modal.Header>Adicionar Permissão</Modal.Header>
        <Modal.Content>
          <TextField
            variant="outlined"
            label="Nome da permissão"
            value={this.state.nome}
            fullWidth
            onChange={(e) => this.setState({ nome: e.target.value })}
          />
        </Modal.Content>
        <Modal.Actions>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="auto">
              <Button
                onClick={this.props.onClose}
                variant="contained"
                color="inherit"
              >
                Voltar
              </Button>
            </Col>
            <Col md="auto">
              <ThemeProvider
                theme={createTheme({
                  palette: {
                    primary: green,
                  },
                })}
              >
                <LoadingButton
                  variant="contained"
                  style={{ color: "#FFFFFF" }}
                  startIcon={<Save />}
                  loading={this.state.loading}
                  disabled={this.state.loading || this.state.nome.trim() === ""}
                  onClick={this.adicionarPermissao}
                >
                  Salvar
                </LoadingButton>
              </ThemeProvider>
            </Col>
          </Row>
        </Modal.Actions>
      </Modal>
    );
  }
}
