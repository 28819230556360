export default [
  { value: "janeiro", label: "Janeiro", id: 0 },
  { value: "fevereiro", label: "Fevereiro", id: 1 },
  { value: "marco", label: "Março", id: 2 },
  { value: "abril", label: "Abril", id: 3 },
  { value: "maio", label: "Maio", id: 4 },
  { value: "junho", label: "Junho", id: 5 },
  { value: "julho", label: "Julho", id: 6 },
  { value: "agosto", label: "Agosto", id: 7 },
  { value: "setembro", label: "Setembro", id: 8 },
  { value: "outubro", label: "Outubro", id: 9 },
  { value: "novembro", label: "Novembro", id: 10 },
  { value: "dezembro", label: "Dezembro", id: 11 },
];
