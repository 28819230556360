import React, { Component } from "react";
import { Header } from "semantic-ui-react";
import { Row, Col } from "react-bootstrap";
import { Segment } from "semantic-ui-react";
import { Paper, Tabs, Tab } from "@material-ui/core";

// Components
import MovimentoCaixa from "./movimentoCaixa/index";
import Titulo from "./titulo/index";
import Aniversariantes from "./aniversariantes/index";
import Receitas from "./receitas/index";
import Entradas from "./entradas/index";
import Fornecedores from "./fornecedores/index";

// Icon
import { Icon as Iconify } from "@iconify/react";
import cashRegisterIcon from "@iconify-icons/mdi/cash-register";
import cardAccountDetailsOutlineIcon from "@iconify-icons/mdi/card-account-details-outline";
import dollarIcon from "@iconify-icons/mdi/dollar";
import doorOpenIcon from "@iconify-icons/mdi/door-open";
import cakeVariantIcon from "@iconify-icons/mdi/cake-variant";
import truckOutlineIcon from "@iconify-icons/mdi/truck-outline";

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selecionado:
        localStorage.getItem("pagina_anterior_relatorios") ||
        this.props.submenus[0].nome,
    };
  }

  changeTabMenu = async (event, newValue) => {
    localStorage.setItem("pagina_anterior_relatorios", newValue);
    this.setState({ selecionado: newValue });
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Relatórios
        </Header>
        <Segment>
          <Row style={{ paddingBottom: 10 }}>
            <Col sm={12}>
              <Paper square>
                <Tabs
                  indicatorColor="secondary"
                  textColor="primary"
                  style={{ outline: "none" }}
                  variant="scrollable"
                  onChange={this.changeTabMenu}
                  value={this.state.selecionado}
                >
                  <Tab
                    icon={<Iconify icon={cashRegisterIcon} width={24} />}
                    style={{ outline: "none" }}
                    label="Caixas"
                    value={"caixas"}
                  ></Tab>
                  <Tab
                    icon={
                      <Iconify
                        icon={cardAccountDetailsOutlineIcon}
                        width={24}
                      />
                    }
                    style={{ outline: "none" }}
                    label="Títulos"
                    value={"titulos"}
                  ></Tab>
                  <Tab
                    icon={<Iconify icon={dollarIcon} width={24} />}
                    style={{ outline: "none" }}
                    label="Receitas"
                    value={"receitas"}
                  ></Tab>
                  <Tab
                    style={{ outline: "none" }}
                    icon={<Iconify icon={doorOpenIcon} width={24} />}
                    label="Entradas"
                    value={"entradas"}
                  ></Tab>
                  <Tab
                    style={{ outline: "none" }}
                    icon={<Iconify icon={cakeVariantIcon} width={24} />}
                    label="Aniversariantes"
                    value={"aniversariantes"}
                  ></Tab>
                  <Tab
                    style={{ outline: "none" }}
                    icon={<Iconify icon={truckOutlineIcon} width={24} />}
                    label="Fornecedores"
                    value={"fornecedores"}
                  ></Tab>
                </Tabs>
              </Paper>
            </Col>
          </Row>
          <TabPanel
            value={this.state.selecionado}
            index={"caixas"}
            {...this.props}
          >
            <MovimentoCaixa />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"titulos"}
            {...this.props}
          >
            <Titulo />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"receitas"}
            {...this.props}
          >
            <Receitas />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"entradas"}
            {...this.props}
          >
            <Entradas />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"aniversariantes"}
            {...this.props}
          >
            <Aniversariantes />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"fornecedores"}
            {...this.props}
          >
            <Fornecedores />
          </TabPanel>
        </Segment>
      </div>
    );
  }
}
function TabPanel(props) {
  const { children, value, index, submenus, ...other } = props;
  return submenus && submenus.find((e) => e.nome === index) ? (
    <div hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </div>
  ) : (
    <div></div>
  );
}
