import React, { Component } from "react";
import {
  Container,
  Table,
  Button,
  Menu,
  Icon,
  Header,
  Modal,
  Form,
  Dropdown,
  Message,
} from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import Select from "react-select";
import requisicoes from "../../../utils/Requisicoes";

import moment from "moment";

export default class ListaFuncionario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaFuncionarios: [],
      pagina: 1,
      totalPaginas: 1,
      optionsPessoas: [],
      optionsCargos: [],
      cargo: null,
      funcionario: null,
      funcionario_id: null,
      mensagem: null,
      pessoaPesquisa: null,
      tipoCargoLista: "funcionario",
      tipoCargoNovoFuncionario: "funcionario",
      inicioGestao: null,
      fimGestao: null,
      modoEditar: false,
    };
    this.listaFuncionarios();
    this.buscaCargos();
  }

  listaFuncionarios = async (page = 1, pessoa_id, tipo) => {
    const resposta = await api.post(
      "/funcionario/lista" + requisicoes.entidade + "&pagina=" + page,
      {
        pessoa_id:
          pessoa_id !== null && pessoa_id !== undefined
            ? pessoa_id
            : this.state.pessoaPesquisa,
        tipo_cargo:
          tipo !== null && tipo !== undefined
            ? tipo
            : this.state.tipoCargoLista,
      },
      requisicoes.header
    );
    this.setState({
      listaFuncionarios: resposta.data.result.docs,
      pagina: page,
      totalPaginas: resposta.data.result.pages,
    });
  };

  buscaCargos = async (event, a, tipo) => {
    let resp = await api.post(
      "/funcionario/listaCargosTipo" + requisicoes.entidade,
      {
        tipo: tipo !== undefined ? tipo : this.state.tipoCargoNovoFuncionario,
      },
      requisicoes.header
    );
    let lista = [];

    for (let i = 0; i < resp.data.length; i++) {
      const element = resp.data[i];
      lista.push({ key: element.id, text: element.nome, value: element.id });
    }
    this.setState({ optionsCargos: lista });
  };

  buscaFuncionario = async (event, a) => {
    let resp = await api.post(
      "/pessoa/buscaFuncionariosPessoa" + requisicoes.entidade,
      { pesquisa: event.target.value },
      requisicoes.header
    );
    console.log("resp.data: ", resp.data);

    let lista = [];
    for (let i = 0; i < resp.data.length; i++) {
      const element = resp.data[i];
      lista.push({ key: element.id, text: element.nome, value: element.id });
    }
    this.setState({ optionsPessoas: lista });
  };

  buscaPessoa = async (event, a) => {
    let resp = await api.post(
      "/pessoa/pesquisa" + requisicoes.entidade,
      { pesquisa: event.target.value },
      requisicoes.header
    );
    let lista = [];
    for (let i = 0; i < resp.data.length; i++) {
      const element = resp.data[i];
      if (element.acao !== null && element.acao.codigo !== null) {
        lista.push({
          key: element.id,
          text: element.acao.codigo + " - " + element.nome,
          value: element.id,
        });
      } else {
        lista.push({ key: element.id, text: element.nome, value: element.id });
      }
    }
    this.setState({ optionsPessoas: lista });
  };

  salvarFuncionario = async (event, a) => {
    if (
      this.state.funcionario === null ||
      this.state.cargo === null ||
      ((this.state.tipoCargoNovoFuncionario === "conselhoDeliberativo" ||
        this.state.tipoCargoNovoFuncionario === "conselhoFiscal") &&
        (!moment(this.state.inicioGestao).isValid() ||
          !moment(this.state.fimGestao).isValid() ||
          this.state.inicioGestao > this.state.fimGestao))
    ) {
      this.setState({
        loading: false,
        mensagem: (
          <Message
            negative
            header="Cadastro incompleto"
            content="Forneça todas as informações solicitadas."
          />
        ),
      });
      setTimeout(async () => {
        this.setState({
          mensagem: null,
        });
      }, 5000);
      return;
    }
    this.setState({loading: true});

    if(this.state.modoEditar) {
      await api.post(
        "/funcionario/alterar" + requisicoes.entidade,
        {
          id: this.state.funcionario_id,
          cargo_id: this.state.cargo,
        },
        requisicoes.header
      );
    } else {
      await api.post(
        "/funcionario/salvar" + requisicoes.entidade,
        this.state.tipoCargoNovoFuncionario === "conselhoDeliberativo" ||
          this.state.tipoCargoNovoFuncionario === "conselhoFiscal"
          ? {
              pessoa_id: this.state.funcionario,
              cargo_id: this.state.cargo,
              data_admissao: this.state.inicioGestao,
              data_desocupacao: this.state.fimGestao,
            }
          : {
              pessoa_id: this.state.funcionario,
              cargo_id: this.state.cargo,
            },
        requisicoes.header
      );
    }

    this.listaFuncionarios();

    this.setState({
      loading: false,
      modoEditar: false,
      mensagem: (
        <Message
          success
          header={`Funcionário ${this.state.modoEditar ? "atualizado" : "cadastrado"}!`}
          content={this.state.modoEditar 
            ? "Funcionário foi atualizado com sucesso" 
            : "Novo funcionário foi cadastrado com sucesso."}
        />
      ),
    });
    setTimeout(async () => {
      this.setState({
        openModal: false,
        mensagem: null,
      });
    }, 3000);
  };

  demitirFuncionario = async (event, a) => {
    await api.post(
      "/funcionario/alterar" + requisicoes.entidade,
      {
        id: this.state.funcionario_id,
        data_desocupacao: moment().format("YYYY-MM-DD"),
      },
      requisicoes.header
    );

    this.listaFuncionarios();

    this.setState({
      loading: false,
      mensagem: (
        <Message
          success
          header="O funcionário foi demitido!"
          content="O funcionário foi demitido com sucesso."
        />
      ),
    });
    setTimeout(async () => {
      this.setState({
        openModal: false,
        mensagem: null,
      });
    }, 3000);
  };

  proximaPagina = async () => {
    const { pagina, totalPaginas } = this.state;
    if (pagina >= totalPaginas) return;
    const paginaNumero = parseInt(pagina) + 1;
    this.listaFuncionarios(paginaNumero);
  };

  voltarPagina = async () => {
    const { pagina } = this.state;
    if (pagina <= 1) return;
    const paginaNumero = pagina - 1;
    this.listaFuncionarios(paginaNumero);
  };

  render() {
    return (
      <div className="lista">
        <Row>
          <Col sm={6}>
            {/* <Select
              placeholder="Digite para buscar"
              name="pessoa"
              options={this.state.optionsPessoas}
              onKeyUp={this.buscaFuncionario}
              inputId="pesquisaPessoa"
              menuIsOpen={false}
            /> */}
            <Dropdown
              search
              fluid
              selection
              options={this.state.optionsPessoas}
              label={{ children: "Pessoa" }}
              placeholder="Digite para buscar o funcionário"
              value={this.state.pessoaPesquisa}
              onKeyUp={this.buscaFuncionario}
              onChange={(e, a) => {
                this.setState({ pessoaPesquisa: a.value });
                this.listaFuncionarios(1, a.value);
              }}
              noResultsMessage="Funcionário não encontrado."
            ></Dropdown>
          </Col>
          <Col>
            <Form.Select
              placeholder="Tipo de Cargo"
              value={this.state.tipoCargoLista}
              onChange={(e, a) => {
                this.setState({ tipoCargoLista: a.value });
                this.listaFuncionarios(undefined, undefined, a.value);
              }}
              options={[
                {
                  key: "funcionario",
                  value: "funcionario",
                  text: "Funcionario",
                },
                {
                  key: "conselhoDeliberativo",
                  value: "conselhoDeliberativo",
                  text: "Conselho Deliberativo",
                },
                {
                  key: "conselhoFiscal",
                  value: "conselhoFiscal",
                  text: "Conselho Fiscal",
                },
              ]}
            />
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Código</Table.HeaderCell>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Cargo</Table.HeaderCell>
              <Table.HeaderCell>Data admissão</Table.HeaderCell>
              <Table.HeaderCell>Data demissão</Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <Button
                  positive
                  icon="add"
                  size="tiny"
                  onClick={() =>
                    this.setState({
                      openModal: true,
                      cargo: null,
                      funcionario: null,
                      funcionario_id: null,
                    })
                  }
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.listaFuncionarios.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>{e.id}</Table.Cell>
                <Table.Cell>{e.pessoa.nome}</Table.Cell>
                <Table.Cell>{e.cargo.nome}</Table.Cell>
                <Table.Cell>
                  {moment(e.data_admissao).format("DD/MM/YYYY")}
                </Table.Cell>
                <Table.Cell>
                  {e.data_desocupacao !== null &&
                    moment(e.data_desocupacao).format("DD/MM/YYYY")}
                </Table.Cell>

                <Table.Cell width={1}>
                  {e.data_desocupacao === null && (
                    <Button
                      primary
                      icon="edit"
                      size="tiny"
                      onClick={() => {
                        this.setState({
                          cargo: e.cargo_id,
                          optionsPessoas: [
                            {
                              key: e.pessoa_id,
                              text: e.pessoa.nome,
                              value: e.pessoa_id,
                            },
                          ],
                          funcionario: e.pessoa_id,
                          funcionario_id: e.id,
                        });
                        console.log("e: ", e);
                        this.setState({
                          openModal: true,
                        });
                      }}
                    />
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan={6}>
                <Menu pagination>
                  <Menu.Item
                    icon
                    onClick={this.voltarPagina}
                    disabled={parseInt(this.state.pagina) === 1}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  <Menu.Item
                    icon
                    onClick={this.proximaPagina}
                    disabled={
                      parseInt(this.state.pagina) ===
                      parseInt(this.state.totalPaginas)
                    }
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Modal
          open={this.state.openModal}
          onClose={() => this.setState({ openModal: false, modoEditar: false })}
          style={{ overflow: "visible" }}
        >
          <Modal.Header>{this.state.modoEditar ? "Editar Funcionário" : "Novo Funcionário"}</Modal.Header>
          <Modal.Content>
            <Form loading={this.state.loading}>
              <Row style={{ paddingBottom: 10 }}>
                <Col style={{ display: "flex", alignItems: "flex-end" }}>
                  <Form.Select
                    label="Tipo de cargo"
                    placeholder="Tipo de Cargo"
                    value={this.state.tipoCargoNovoFuncionario}
                    onChange={(e, a) => {
                      this.setState({
                        tipoCargoNovoFuncionario: a.value,
                        optionsPessoas: [],
                        cargo: null,
                        funcionario: null,
                      });
                      this.buscaCargos(undefined, undefined, a.value);
                    }}
                    options={[
                      {
                        key: "funcionario",
                        value: "funcionario",
                        text: "Funcionario",
                      },
                      {
                        key: "conselhoDeliberativo",
                        value: "conselhoDeliberativo",
                        text: "Conselho Deliberativo",
                      },
                      {
                        key: "conselhoFiscal",
                        value: "conselhoFiscal",
                        text: "Conselho Fiscal",
                      },
                    ]}
                    disabled={this.state.funcionario_id !== null/* && this.state.modoEditar === false*/}
                  />
                </Col>
                {(this.state.tipoCargoNovoFuncionario ===
                  "conselhoDeliberativo" ||
                  this.state.tipoCargoNovoFuncionario === "conselhoFiscal") && (
                  <>
                    <Col>
                      <Form.Input
                        type="number"
                        min="2000"
                        max="3000"
                        label="Inicio da Gestão"
                        placeholder="Inicio da Gestão"
                        onChange={(e, a) => {
                          this.setState({ inicioGestao: a.value + "-01-01" });
                        }}
                        required
                      />
                    </Col>
                    <Col>
                      <Form.Input
                        type="number"
                        min="2000"
                        max="3000"
                        label="Fim da Gestão"
                        placeholder="Fim da Gestão"
                        onChange={(e, a) => {
                          this.setState({ fimGestao: a.value + "-12-31" });
                        }}
                        required
                      />
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                <Col>
                  <Form.Field
                    label="Pessoa"
                    style={{ marginBottom: 4 }}
                    disabled={this.state.funcionario_id !== null}
                  ></Form.Field>
                  <Dropdown
                    search
                    fluid
                    selection
                    options={this.state.optionsPessoas}
                    label={{ children: "Pessoa" }}
                    placeholder="Digite para buscar o funcionário"
                    value={this.state.funcionario}
                    onKeyUp={
                      this.state.tipoCargoNovoFuncionario === "funcionario"
                        ? this.buscaFuncionario
                        : this.buscaPessoa
                    }
                    onChange={(e, a) => this.setState({ funcionario: a.value })}
                    noResultsMessage="Funcionário não encontrado."
                    disabled={this.state.funcionario_id !== null}
                  ></Dropdown>
                </Col>
                <Col>
                  <Form.Select
                    search
                    label="Cargo"
                    placeholder="Cargo"
                    options={this.state.optionsCargos}
                    onKeyUp={this.buscaFuncionario}
                    value={this.state.cargo}
                    onChange={(e, a) => this.setState({ cargo: a.value })}
                    disabled={(this.state.funcionario_id !== null && this.state.modoEditar === false) || this.state.loading === true}
                  />
                </Col>
              </Row>
            </Form>
            {this.state.mensagem !== null && this.state.mensagem}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={
              this.state.modoEditar 
                ? () => this.setState({ modoEditar: false })
                : () => this.setState({ openModal: false, modoEditar: false })
              }>
              Voltar
            </Button>
            {this.state.funcionario_id !== null ? (
              <>
                <Button
                  color={this.state.modoEditar ? "green" : "blue"}
                  onClick={this.state.modoEditar 
                    ? this.salvarFuncionario 
                    : () => this.setState({ modoEditar: true })}
                  loading={this.state.loading}
                  disabled={this.state.loading === true}
                >
                  {this.state.modoEditar ? "Salvar" : "Editar"}
                </Button>
                {this.state.modoEditar === false && (<Button
                  color="red"
                  onClick={this.demitirFuncionario}
                  loading={this.state.loading}
                  disabled={this.state.loading === true}
                >
                  Demitir Funcionario
                </Button>)}
              </>
            ) : (
              <Button
                color="green"
                onClick={this.salvarFuncionario}
                loading={this.state.loading}
                disabled={this.state.loading === true}
              >
                Salvar
              </Button>
            )}
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
