import React, { Component } from "react";
import {
  Header,
  Modal,
  Button,
  Container,
  Icon,
  Table,
} from "semantic-ui-react";
//import { Col, Row, Form, Table } from "react-bootstrap";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

export default class ModalGruposServico extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaGruposServico: [],
      listaPermitidos: [],
      listaNaoPermitidos: [],
      listaPermitidosUsuario: [],
      grupo_servico: null,
    };
    this.listaGruposServico();
  }

  listaGruposServico = async () => {
    const retorno = await api.post(
      "/grupoServico/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    await this.setState({ listaGruposServico: retorno.data });

    //Atualizar com a lista do usuario
    //this.atualizaListas();
  };

  atualizaListas = async () => {
    var listaP = [];
    var listaNP = [...this.state.listaGruposServico];
    var listaPermitidasUsuario = this.props.grupos_servico;

    for (let i = 0; i < listaPermitidasUsuario.length; i++) {
      const element = listaPermitidasUsuario[i];
      let findIndex = -1;

      for (let i = 0; i < listaNP.length; i++) {
        const element2 = listaNP[i];
        if (element2.id === element) {
          findIndex = i;
        }
      }

      if (findIndex != -1) {
        listaP.push(listaNP[findIndex]);
        listaNP.splice(findIndex, 1);
      }
    }

    await this.setState({ listaPermitidos: listaP });
    await this.setState({ listaNaoPermitidos: listaNP });
  };
  excluirMatricula = async (id) => {
    let listaP = this.state.listaPermitidos;
    let listaNP = this.state.listaNaoPermitidos;

    let removeIndex = null;
    for (let i = 0; i < listaP.length; i++) {
      const element = listaP[i];
      if (element.id == id) {
        removeIndex = i;
        break;
      }
    }

    if (removeIndex === null) return;

    listaNP.push(listaP[removeIndex]);
    listaP.splice(removeIndex, 1);

    await this.setState({ listaPermitidos: listaP });
    await this.setState({ listaNaoPermitidos: listaNP });
    this.setaGruposServico();
  };

  liberaMatricula = async (id) => {
    let listaP = this.state.listaPermitidos;
    let listaNP = this.state.listaNaoPermitidos;

    let removeIndex = null;
    for (let i = 0; i < listaNP.length; i++) {
      const element = listaNP[i];
      if (element.id === id) {
        removeIndex = i;
        break;
      }
    }

    if (removeIndex === null) return;

    listaP.push(listaNP[removeIndex]);
    listaNP.splice(removeIndex, 1);

    await this.setState({ listaPermitidos: listaP });
    await this.setState({ listaNaoPermitidos: listaNP });
    this.setaGruposServico();
  };

  setaGruposServico = () => {
    let listaP = [];
    this.state.listaPermitidos.forEach((element) => {
      listaP.push(element.id);
    });
    this.props.setaGruposServico(listaP, this.state.listaPermitidos);
  };

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <div>
        <Modal
          open={this.props.open_grupos_servico}
          onMount={this.atualizaListas}
          onOpen={this.atualizaListas}
          onClose={this.props.handleCloseGruposServico}
          centered={false}
        >
          <Header content="Grupos de Serviço" />
          <Modal.Content scrolling>
            <Modal.Description>
              <Container textAlign="center">
                <Header>Sem permissão de matrícula</Header>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Nome</Table.HeaderCell>
                      <Table.HeaderCell
                        width={1}
                        style={{ textAlign: "center" }}
                      >
                        <Icon name="check"></Icon>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.listaNaoPermitidos.map((e, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>{e.descricao}</Table.Cell>
                        <Table.Cell width={1}>
                          <Button
                            positive
                            icon="check"
                            size="tiny"
                            onClick={() => this.liberaMatricula(e.id)}
                          ></Button>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <Header>Com permissão de matrícula</Header>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Nome</Table.HeaderCell>
                      <Table.HeaderCell
                        width={1}
                        style={{ textAlign: "center" }}
                      >
                        <Icon name="x"></Icon>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.listaPermitidos.map((e, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>{e.descricao}</Table.Cell>
                        <Table.Cell width={1}>
                          <Button
                            negative
                            icon="x"
                            size="tiny"
                            onClick={() => this.excluirMatricula(e.id)}
                          ></Button>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Container>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
