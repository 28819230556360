import React, { Component } from "react";
import { Button, Form, Dropdown } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";

export default class ParametrosDiretoria extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Row>
          {this.props.novaGestao ? (
            <>
              <Col sm={6}>
                <Form.Input
                  type="number"
                  min="2000"
                  max="3000"
                  label="Inicio da Gestão"
                  placeholder="Inicio da Gestão"
                  onChange={(e, a) => {
                    this.props.atualizaNovaGestao(a.value, undefined);
                  }}
                />
              </Col>
              <Col sm={6}>
                <Form.Input
                  type="number"
                  min="2000"
                  max="3000"
                  label="Fim da Gestão"
                  placeholder="Fim da Gestão"
                  onChange={(e, a) => {
                    this.props.atualizaNovaGestao(undefined, a.value);
                  }}
                />
              </Col>
            </>
          ) : (
            <Col sm={9}>
              <Form.Select
                label="Gestão"
                placeholder="Gestão"
                options={this.props.optionsGestoesDiretoria}
                onKeyUp={this.props.buscaFuncionario}
                value={this.props.gestao}
                onChange={(e, a) => {
                  this.props.setState({ gestao: a.value });
                  this.props.buscaDiretoriaGestao(a.value);
                }}
              />
            </Col>
          )}
          {this.props.novaGestao === false && (
            <Col
              sm={3}
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() =>
                  this.props.setState({
                    novaGestao: true,
                    gestao: null,
                    diretoria: [],
                  })
                }
              >
                Nova gestão
              </Button>
            </Col>
          )}
        </Row>

        {this.props.optionsCargosDiretoria.map((e, i) => (
          <Form.Field>
            <label>{e.text}</label>
            <Dropdown
              search
              fluid
              selection
              options={
                this.props.diretoria.find(
                  (element) => element.cargo_id === e.value
                ) !== undefined &&
                this.props.diretoria.find(
                  (element) => element.cargo_id === e.value
                ).options
              }
              placeholder="Digite para buscar"
              value={
                this.props.diretoria.find(
                  (element) => element.cargo_id === e.value
                ) !== undefined &&
                this.props.diretoria.find(
                  (element) => element.cargo_id === e.value
                ).pessoa_id
              }
              onKeyUp={(event, a) => this.props.buscaPessoa(event, a, e.value)}
              onChange={(event, a) => {
                let diretoria = this.props.diretoria;
                diretoria.find(
                  (element) => element.cargo_id === e.value
                ).pessoa_id = a.value;
                this.props.setState({ diretoria: diretoria });
              }}
              noResultsMessage="Pessoa não encontrada."
              disabled={this.props.gestao === null}
            ></Dropdown>
          </Form.Field>
        ))}
      </>
    );
  }
}
