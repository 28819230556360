import React, { Component } from "react";
import { Container, Table, Button, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

export default class UsuarioLista extends Component {
  constructor(props) {
    super(props);
    this.state = { listaTipoTitulo: [] };
    this.listaTipoTitulo();
  }

  listaTipoTitulo = async () => {
    const resposta = await api.post(
      "/tipoTitulo/lista" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    this.setState({ listaTipoTitulo: resposta.data });
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Tipo Titulo
        </Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Descrição</Table.HeaderCell>
              <Table.HeaderCell>Valor Titulo</Table.HeaderCell>
              <Table.HeaderCell>Valor Mensalidade</Table.HeaderCell>
              <Table.HeaderCell width={1}></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.listaTipoTitulo.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>{e.descricao}</Table.Cell>
                <Table.Cell>{e.valor_titulo}</Table.Cell>
                <Table.Cell>{e.valor_mensalidade}</Table.Cell>
                <Table.Cell width={1}>
                  <Link to={{ pathname: "/tipoTitulo", query: { id: e.id } }}>
                    <Button primary icon="edit" size="tiny"></Button>
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}
