import React, { Component } from "react";
import {
  Container,
  Header,
  Menu,
  Segment,
  Table,
  Image,
  Input,
  Divider,
  Modal,
} from "semantic-ui-react";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import { Link } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment-timezone";
import Icon from "@material-ui/core/Icon";
import api from "../../../services/api";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FilterTiltShiftIcon from "@material-ui/icons/FilterTiltShift";
import Slide from "@material-ui/core/Slide";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import SettingsIcon from "@material-ui/icons/Settings";
import SendIcon from "@material-ui/icons/Send";
import IconButton from "@material-ui/core/IconButton";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import ModalAddEtapaTreino from "./modalAddEtapaTreino";
import ModalConfigEtapas from "./modalConfigEtapas";
import ModalReabrirEtapa from "./modalReabrirEtapa";
import Lista from "./lista";

import "../styles.css";

export default class Competicao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      informacoes: [],
      numetapa: null,
      listaetapa: [],
      etapas: [],
      porcentagem: null,
      modal: false,
      numEtapa: 0,
      quantidade_pedanas: 0,
      alerta: true,
      tipoEvento: "",
      modalEtapa: false,
      alertaEtapa: true,
      checkEtapa: false,
      descricaoetapa: "",
      regraPontuacao: "",
      data_inicio_etapa: null,
      data_inicio_alterada: null,
      data_inicio_treino: null,
      modalReabrirEtapa: false,
      alertaReabrirEtapa: false,
    };

    this.visualizar();
  }

  visualizar = async () => {
    var infoevento = await api.post(
      "/eventos/buscaInfoEvento" + requisicoes.entidade,
      { id: this.props.location.query.id },
      requisicoes.header
    );

    this.setState({ tipoEvento: infoevento.data.tipo_evento });
    this.setState({ informacoes: infoevento.data });
    this.setState({ numetapa: infoevento.data.quantidade_etapas });
    this.setState({
      regraPontuacao: infoevento.data.modalidade.tipo_pontuacao,
    });

    var listaetapa = [];
    for (let a = 0; a < this.state.numetapa; a++) {
      listaetapa.push({});
    }

    this.setState({ listaetapa: listaetapa });

    const listatrue = [];
    const lista = 0;
    var percent = 0;
    var etapas = await api.post(
      "/eventos/listaEtapas" + requisicoes.entidade,
      { id: this.props.location.query.id },
      requisicoes.header
    );

    this.setState({ etapas: etapas.data });

    //  lista.length
    var fechado = etapas.data.filter((element) => {
      return element.aberta === false;
    });

    this.setState({
      porcentagem: ((fechado.length * 100) / etapas.data.length).toFixed(2),
    });
  };
  voltar = async () => {
    window.location.href = "/eventos";
  };

  changeModal = async (status, num_etapa) => {
    this.setState({ modal: status });

    this.setState({ numEtapa: num_etapa });

    const etapas = await api.post(
      "/etapa/buscaEtapaEspecifica" + requisicoes.entidade,
      { evento_id: this.props.location.query.id, numero_etapa: num_etapa },
      requisicoes.header
    );

    this.setState({ data_inicio_etapa: etapas.data.data_inicio });
  };

  changeModalEtapa = async (status) => {
    this.setState({ modalEtapa: status });
  };

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeModalreabrirEtapa = async (status, num_etapa) => {
    this.setState({ modalReabrirEtapa: status });
    this.setState({ numEtapa: num_etapa });
  };

  salvarNumEtapa = async () => {
    var pedanas = await api.post(
      "/etapa/salvarPedana" + requisicoes.entidade,
      {
        pedana: this.state.quantidade_pedanas,
        evento_id: this.props.location.query.id,
        numero_etapa: this.state.numEtapa,
        data_inicio:
          this.state.data_inicio_alterada === null
            ? this.state.data_inicio_etapa
            : this.state.data_inicio_alterada,
      },
      requisicoes.header
    );

    this.visualizar();

    this.setState({ alerta: false });
    setTimeout(() => {
      this.setState({ modal: false });
      this.setState({ alerta: true });
    }, 2500);
  };

  reabrirEtapa = async () => {
    this.setState({ alertaReabrirEtapa: true });
    const resposta = await api.post(
      "/etapa/reabrirEtapa" + requisicoes.entidade,
      {
        evento_id: this.props.location.query.id,
        numero_etapa: this.state.numEtapa,
      },
      requisicoes.header
    );

    setTimeout(() => {
      this.setState({ modalReabrirEtapa: false });
      this.setState({ alertaReabrirEtapa: false });
    }, 2500);

    this.visualizar();
  };

  adicionarEtapa = async () => {
    const resposta = await api.post(
      "/etapa/adicionarEtapa" + requisicoes.entidade,
      {
        evento_id: this.props.location.query.id,
        descricao: this.state.descricaoetapa,
        data_inicio: this.state.data_inicio_treino,
      },
      requisicoes.header
    );
    this.visualizar();
    this.setState({ alertaEtapa: false });

    setTimeout(() => {
      this.setState({ modalEtapa: false });
      this.setState({ alertaEtapa: true });
    }, 2500);
  };

  checkboxVerify = () => {
    if (this.state.checkEtapa === false) {
      this.setState({ checkEtapa: true });
    } else {
      this.setState({ checkEtapa: false });
    }
  };

  render() {
    return (
      <Container className="paginaSemPaddingTop">
        <Form>
          <Segment>
            <Header as="h2" dividing>
              <div className="flexSpaceBetween">
                <div>Lançar Resultados</div>
                <div>
                  <Button
                    color="primary"
                    floated="right"
                    onClick={this.voltar}
                    style={{ outline: "none" }}
                  >
                    Voltar
                  </Button>
                </div>
              </div>
            </Header>

            <Header>
              <Row>
                <Col sm={5}></Col>
                <Col sm={2}>
                  <CircularProgressbarWithChildren
                    value={this.state.porcentagem}
                    styles={buildStyles({
                      rotation: 0,

                      strokeLinecap: "butt",
                      strokeWidth: "5",

                      textSize: "16px",

                      pathTransitionDuration: 0.3,

                      pathColor: `rgb(22, 66, 82)`,
                      textColor: "rgb(22, 66, 82)",
                      trailColor: " rgb(106, 156, 174)",
                    })}
                  >
                    <div style={{ fontSize: 20, marginTop: -5 }}>
                      <strong>{`${this.state.porcentagem}%`}</strong>
                    </div>
                  </CircularProgressbarWithChildren>
                </Col>
              </Row>
            </Header>
            <Divider horizontal>
              <Header as="h4">
                <Icon name="quote left" />
                DESCRIÇÃO
              </Header>
            </Divider>
            <label style={{ textAlign: "center" }}>
              {" "}
              {this.state.informacoes.descricao}
            </label>
            <Divider horizontal>
              <Header as="h4">
                <Icon name="bar chart" />
                ETAPAS
              </Header>
            </Divider>

            <Lista
              tipoEvento={this.state.tipoEvento}
              changeModalEtapa={this.changeModalEtapa}
              etapas={this.state.etapas}
              changeModal={this.changeModal}
              regraPontuacao={this.state.regraPontuacao}
              changeModalreabrirEtapa={this.changeModalreabrirEtapa}
            ></Lista>

            <ModalAddEtapaTreino
              modalEtapa={this.state.modalEtapa}
              changeModalEtapa={this.changeModalEtapa}
              adicionarEtapa={this.adicionarEtapa}
              alteracao={this.alteracao}
              alertaEtapa={this.state.alertaEtapa}
              data_inicio_treino={this.state.data_inicio_treino}
            ></ModalAddEtapaTreino>

            <ModalConfigEtapas
              modal={this.state.modal}
              numEtapa={this.state.numEtapa}
              changeModal={this.changeModal}
              alerta={this.state.alerta}
              alteracao={this.alteracao}
              salvarNumEtapa={this.salvarNumEtapa}
              dataInicioEtapa={this.state.data_inicio_etapa}
              data_inicio_alterada={this.state.data_inicio_alterada}
              quantidade_pedanas={this.state.quantidade_pedanas}
            ></ModalConfigEtapas>

            <ModalReabrirEtapa
              changeModalreabrirEtapa={this.changeModalreabrirEtapa}
              numEtapa={this.state.numEtapa}
              modalReabrirEtapa={this.state.modalReabrirEtapa}
              reabrirEtapa={this.reabrirEtapa}
              alertaReabrirEtapa={this.state.alertaReabrirEtapa}
            ></ModalReabrirEtapa>
          </Segment>
        </Form>
      </Container>
    );
  }
}
