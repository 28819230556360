import React, { Component } from "react";
import { Image } from "semantic-ui-react";

//
import mapa from "../../../../images/mapa.png";

export default class Mapa extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ paddingTop: 10 }}>
        <Image src={mapa} rounded fluid />
      </div>
    );
  }
}
