import React, { Component } from "react";
import { Table, Button, Popup, Pagination, Search } from "semantic-ui-react";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";
import { Row, Col } from "react-bootstrap";

export default class CentroDeCustoLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      popUpPesquisa: false,
      pages: null,
    };
    this.lista();
  }

  lista = async (event, data) => {
    if (!data) {
      data = {};
      data.activePage = 1;
    }
    const resposta = await api.post(
      "/centroDeCusto/lista" + requisicoes.entidade,
      {
        page: data.activePage,
      },
      requisicoes.header
    );
    this.setState({
      lista: resposta.data.docs,
      pages: resposta.data.pages,
    });
  };

  excluir = async (index) => {
    try {
      let lista = [...this.state.lista];

      await api.post(
        "/centroDeCusto/excluir" +
          requisicoes.entidade +
          "&id=" +
          lista[index].id,
        "",
        requisicoes.header
      );

      lista.splice(index, 1);
      this.setState({ lista });
    } catch (error) {
      alert("Ocorreu um problema ao excluir o registro.");
    }
  };

  pesquisa = async (event) => {
    this.setState({ pesquisando: true, popUpPesquisa: false });

    let pesquisa = event.target.value;
    await api
      .post(
        "/centroDeCusto/pesquisa" + requisicoes.entidade,
        { pesquisa: pesquisa },
        requisicoes.header
      )
      .then((resp) => {
        if (resp.data.length === 0) {
          this.setState({ popUpPesquisa: true });
        }
        this.setState({ lista: resp.data, pesquisando: false });
      });
  };

  render() {
    return (
      <div className="lista">
        <Row>
          <Col sm={6}>
            <Popup
              content="Nenhum registro encontrado!"
              open={this.state.popUpPesquisa}
              position="right center"
              trigger={
                <Search
                  loading={this.state.pesquisando}
                  fluid
                  onSearchChange={this.pesquisa}
                  showNoResults={false}
                  input={{
                    icon: "search",
                    iconPosition: "left",
                    style: { width: "100%" },
                    input: { style: { borderRadius: "3%" } },
                  }}
                  placeholder="Digite para buscar"
                />
              }
            />
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Código</Table.HeaderCell>
              <Table.HeaderCell>Descrição</Table.HeaderCell>
              <Table.HeaderCell width={2} textAlign="center">
                <Link to={{ pathname: "/centroDeCusto" }}>
                  <Button positive icon="add" size="tiny"></Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.lista.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>{e.codigo}</Table.Cell>
                <Table.Cell>{e.descricao}</Table.Cell>
                <Table.Cell width={2} textAlign="center">
                  <Button
                    negative
                    size="tiny"
                    onClick={() => this.excluir(i)}
                    icon="trash alternate outline"
                  ></Button>
                  <Link
                    to={{ pathname: "/centroDeCusto", query: { id: e.id } }}
                  >
                    <Button primary size="tiny" icon="edit outline"></Button>
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan={4}>
                <Pagination
                  boundaryRange={0}
                  defaultActivePage={1}
                  ellipsisItem={null}
                  siblingRange={1}
                  totalPages={this.state.pages !== null ? this.state.pages : 1}
                  onPageChange={this.lista}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}
