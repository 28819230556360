/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Form, FormControl, Col, Row } from "react-bootstrap";
import { Modal, Segment, Table } from "semantic-ui-react";
import {
  AddCircleOutline,
  VisibilityOff,
  Visibility,
  Close,
} from "@material-ui/icons/";
import {
  Tooltip,
  Button,
  Select,
  IconButton,
  InputLabel,
  TextField,
  Autocomplete,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { Link } from "react-router-dom";

import "../styles.css";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exibirLista: false,
      options: [],
      listaSelecionada: [],
      buscando: false,
    };
    // setTimeout(() => {
    // this.listaAtleta();
    // }, 200);
  }

  changeExibirLista = async () => {
    if (this.state.exibirLista) {
      this.setState({ exibirLista: false });
    } else {
      this.setState({ exibirLista: true });
    }
  };

  listaAtleta = async (element, index, e) => {
    try {
      let lista = [];
      this.state.options = [];
      this.setState({ buscando: true });

      for (let i = 0; i < this.props.atletasEComissaoTecnica.length; i++) {
        const element = this.props.atletasEComissaoTecnica[i];
        let objeto = {
          value: element.id,
          label: element.pessoa.titular_id
            ? element.pessoa.titular_id +
            " - " +
            element.pessoa.nome +
            " - " +
            element.tipo_vinculo.descricao
            : " " +
            "  " +
            //caso nao tenha acao mostra
            element.pessoa.nome +
            " - " +
            element.tipo_vinculo.descricao,
          equipe_id: element.tipo_vinculo.id,
        };
        lista.push(objeto);
      }

      this.setState({
        options: [...this.state.options, ...lista],
        buscando: false,
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Modal
          style={{ overflow: "visible" }}
          dimmer="bluring"
          size="small"
          open={this.props.modalInscricao}
          onClose={() => {
            this.props.changeModalInscricao(null, false);
          }}
          onMount={() => {
            this.listaAtleta();
          }}
        >
          <Modal.Header>
            {"Inscrição " + this.props.organizacaoSelecionadaNome}
            <IconButton
              onClick={() => this.props.changeModalInscricao(null, false)}
              className="bottomModal"
              color="secondary"
            >
              <Close />
            </IconButton>

            <h6>
              Adicionar participantes ao time 1:
              <strong> Atletas, treinador e comissão técnica! </strong>
            </h6>
          </Modal.Header>
          <Modal.Content>
            {this.props.alertTamanhoEquipes ? (
              <Alert severity="error" style={{ marginTop: "10px" }}>
                Número de participantes excedido!
              </Alert>
            ) : this.props.alertErrorSalvarTime ? (
              <Alert severity="error">Erro ao salvar time!</Alert>
            ) : this.props.alertSuccessSalvarTime ? (
              <Alert severity="success">Time Salvo!</Alert>
            ) : (
              Array.from({ length: this.props.linhaInscricao }).map(
                (element, index) => {
                  return (
                    <div className="flexStart">
                      <div style={{ width: "60%" }}>
                        <InputLabel id="demo-mutiple-name-label">
                          Atleta
                        </InputLabel>

                        <Autocomplete
                          id="tipoCampeonato"
                          disableClearable
                          getOptionLabel={(option) => option.label}
                          options={this.state.options}
                          value={null}
                          onChange={(element, a) =>
                            this.props.alteracaoSelectAtleta(element, index, a)
                          }
                          onKeyUp={this.listaAtleta}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              variant="standard"
                              label="Digite para buscar"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {this.state.buscando ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                        style={{
                                          marginRight: "-3.7ch",
                                        }}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                          required
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginLeft: "15px",
                          marginTop: "4ch",
                        }}
                      >
                        <Button
                          style={{
                            display: "flex",
                            outline: "none",
                          }}
                          color="primary"
                          variant="contained"
                          onClick={this.props.adicionaListaAtleta}
                        >
                          Adicionar
                        </Button>
                      </div>
                    </div>
                  );
                }
              )
            )}
            {/* //hidden={this.state.exibirLista} */}
            <Table selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={7}>Nome</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Vínculo</Table.HeaderCell>
                  <Table.HeaderCell width={2}></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {this.props.participantesPorTime.length > 0 ? (
                this.props.participantesPorTime.map((element, index) => {
                  return (
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          {element?.p_m_d?.pessoa?.nome
                            ? element?.p_m_d?.pessoa?.nome
                            : element?.nome}{" "}
                        </Table.Cell>
                        <Table.Cell>
                          {element?.p_m_d?.tipo_vinculo?.descricao
                            ? element?.p_m_d?.tipo_vinculo?.descricao
                            : element?.vinculo}
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            icon
                            style={{ outline: "none" }}
                            size="mini"
                            onClick={() =>
                              this.props.excluirAtleta(element, true)
                            }
                            color={"error"}
                            disabled={this.props.atletasInscricao.length !== 0}
                          >
                            Deletar
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  );
                })
              ) : (
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Nenhum inscrito encontrado</Table.Cell>
                  </Table.Row>
                </Table.Body>
              )}
            </Table>
          </Modal.Content>
          <Modal.Actions>
            <div className="flexSpaceBetween">
              <div>
                <Tooltip
                  title={
                    this.state.exibirLista ? (
                      <h5>Mostrar Inscritos</h5>
                    ) : (
                      <h5>Esconder Inscritos</h5>
                    )
                  }
                  arrow
                  placement="right"
                >
                  <IconButton
                    color="secondary"
                    style={{ outline: "none" }}
                    onClick={this.changeExibirLista}
                  >
                    {this.state.exibirLista ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
              <div>
                <Button
                  color="primary"
                  style={{ outline: "none" }}
                  onClick={this.props.salvarTime}
                  disabled={this.props.atletasInscricao.length === 0}
                >
                  Salvar
                </Button>
              </div>
            </div>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
