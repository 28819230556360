/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Modal, Table } from "semantic-ui-react";
import CloseIcon from "@material-ui/icons/Close";
import { Button, IconButton } from "@material-ui/core/";
import { Delete, AddCircle, AddCircleOutline, Add } from "@material-ui/icons/";

import moment from "moment-timezone";
import "../styles.css";
import { Link } from "react-router-dom";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkIniciar: false,
      partidasCadastradas: [],
      rodadaId: 0,
    };
    this.listaPartidata();
  }

  checkboxVerify = () => {
    if (this.state.checkIniciar === false) {
      this.setState({ checkIniciar: true });
    } else {
      this.setState({ checkIniciar: false });
    }
  };
  listaPartidata = () => {
    this.props.changeModalListaPartida(true);
    this.state.partidasCadastradas = this.props.partidasCadastradas;
    this.setState({ partidasCadastradas: this.props.partidasCadastradas });
  };

  adicionarRodada = async (event) => {
    this.props.adicionaListaPartida(this.props.rodadaId);
    this.props.changeModalAdicionarRodada(true);
    this.props.changeModalListaPartida(false, false);
  };
  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Modal
          style={{ overflow: "visible" }}
          dimmer="bluring"
          size="large"
          open={this.props.modalListaPartida}
        >
          <Modal.Header>
            <IconButton
              onClick={() => this.props.changeModalListaPartida(null, false)}
              className="bottomModal"
              color="secondary"
            >
              <CloseIcon />
            </IconButton>

            <h6> Lista de Partidas</h6>
          </Modal.Header>
          {this.props.partidasCadastradas.length === 0 ? (
            <div className="adiciona">
              <IconButton
                style={{ outline: "none" }}
                color="primary"
                onClick={(e) => this.adicionarRodada(e)}
              >
                <AddCircleOutline id={this.props.rodadaId} />{" "}
              </IconButton>
            </div>
          ) : (
            ""
          )}
          <Table selectable color="blue">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="right">CONFRONTO</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>DATA MARCADA</Table.HeaderCell>
                <Table.HeaderCell>HORA</Table.HeaderCell>

                <Table.HeaderCell>Lançamento</Table.HeaderCell>
                <Table.HeaderCell>Exclui</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.props.partidasCadastradas !== undefined ||
                this.props.partidasCadastradas !== []
                ? this.props.partidasCadastradas.map((element, index) => {
                  return (
                    <Table.Row
                      style={{
                        color: "rgb(9, 124, 165)",
                      }}
                    >

                      {element.placares.map((elmt, indx) => {
                        return (
                          <Table.Cell
                            textAlign="left"
                            width={3}
                            verticalAlign="middle"
                          >
                            {element.placares[indx].equipe.organizacao.nome +
                              " (" +
                              elmt.pontuacao +
                              ")"}
                          </Table.Cell>
                        );
                      })}
                      <Table.Cell verticalAlign="middle">
                        {moment(element.data_marcada).format("DD/MM/YYYY")}
                      </Table.Cell>

                      <Table.Cell verticalAlign="middle">
                        {element.hora === undefined || element.hora === null
                          ? "Não definido"
                          : element.hora}
                      </Table.Cell>

                      <Table.Cell>
                        <Link
                          to={{
                            pathname: "/desporto/partida",
                            query: {
                              infoPartida: element,
                              ida_volta: this.props.ida_volta,
                              todos_contra_todos: this.props
                                .todos_contra_todos,
                              campeonato_id: this.props.campeonato_id,
                              partida_id: element.id,
                              rodadaId: this.props.rodadaId,
                              tipo_campeonato: this.props.tipo_campeonato,
                              info: this.props.info,
                            },
                          }}
                        >
                          <Button style={{ outline: "none" }} size="mini">
                            <AddCircleOutline color="primary" />
                          </Button>
                        </Link>
                      </Table.Cell>

                      <Table.Cell>
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={(e) =>
                            this.props.deletaListaPartida(element)
                          }
                          disabled={element.status === "encerrada"}
                        >
                          <Delete color="error" />
                        </IconButton>
                      </Table.Cell>
                    </Table.Row>
                  );
                })
                : ""}
            </Table.Body>
          </Table>
          <Modal.Content>
            <div className="flexSpaceBetween"></div>
          </Modal.Content>
          <Modal.Actions></Modal.Actions>{" "}
        </Modal>
      </div>
    );
  }
}
