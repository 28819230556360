import React, { Component } from "react";
import { Modal, Header, Icon } from "semantic-ui-react";

export default class ModalRestricaoDias extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal
        closeIcon
        size="mini"
        open={this.props.modalRestricaoDias}
        style={{ textAlign: "center" }}
      >
        <Header icon>
          <Icon name="warning circle" />
          {"RESERVADA NOS ULTIMOS "}
          {this.props.servico.parametros_reserva_frequencia
            ? this.props.servico.parametros_reserva_frequencia[0]
                ?.dias_frequencia
            : ""}
          {" DIAS"}
        </Header>
        <Modal.Content>FAVOR BUSCAR OUTRA</Modal.Content>
      </Modal>
    );
  }
}
