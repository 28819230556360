import React, { Component } from "react";
import { Form, Divider, Button, Message } from "semantic-ui-react";
import { Row, Col } from "react-bootstrap";

import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

export default class Parametros extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaServicos: [],
      servico_juros_id: null,
      servico_descontos_id: null,
      listaParametros: [],
    };
    this.listaParametros();
    this.listaServicos();
  }
  listaParametros = async () => {
    var listaParametros = await api.post(
      "/parametros/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ listaParametros: listaParametros.data });
    for (let i = 0; i < listaParametros.data.length; i++) {
      const element = listaParametros.data[i];
      if (element.nome === "servico_juros_exportacao_id") {
        this.setState({ servico_juros_id: parseInt(element.valor) });
        continue;
      }
      if (element.nome === "servico_desconto_exportacao_id") {
        this.setState({ servico_descontos_id: parseInt(element.valor) });
        continue;
      }
    }
  };

  submit = async () => {
    this.setState({ loading: true });

    //Salva os parametros
    let listaParametros = this.state.listaParametros;
    for (let i = 0; i < listaParametros.length; i++) {
      const element = listaParametros[i];
      if (element.nome === "servico_juros_exportacao_id") {
        element.valor = this.state.servico_juros_id;
        continue;
      }
      if (element.nome === "servico_desconto_exportacao_id") {
        element.valor = this.state.servico_descontos_id;
        continue;
      }
    }
    await api.post(
      "/parametros/editarVarios" + requisicoes.entidade,
      {
        listaParametros,
      },
      requisicoes.header
    );

    this.setState({ loading: false });
    this.setState({ sucess: true });

    setTimeout(() => {
      this.setState({ sucess: false });
    }, 5000);
  };

  listaServicos = async () => {
    var listaServicos = await api.post(
      "/servico/listaDiversos" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    let servicosOptions = [];

    for (let i = 0; i < listaServicos.data.length; i++) {
      const element = listaServicos.data[i];
      servicosOptions.push({
        key: element.id,
        value: element.id,
        text: element.nome,
      });
    }
    this.setState({ servicosOptions: servicosOptions });
  };

  render() {
    return (
      <div style={{ paddingTop: 10 }}>
        <Form
          success
          size={"large"}
          onSubmit={this.submit}
          loading={this.state.loading}
        >
          <Row>
            <Col sm={6}>
              <Form.Select
                label="Serviço de Juros"
                placeholder="Selecione o serviço"
                options={this.state.servicosOptions}
                value={this.state.servico_juros_id}
                onChange={(e, a) =>
                  this.setState({ servico_juros_id: a.value })
                }
                search
              />
            </Col>
            <Col sm={6}>
              <Form.Select
                label="Serviço de Descontos"
                placeholder="Selecione o serviço"
                options={this.state.servicosOptions}
                value={this.state.servico_descontos_id}
                onChange={(e, a) =>
                  this.setState({ servico_descontos_id: a.value })
                }
                search
              />
            </Col>
          </Row>

          {this.state.sucess && (
            <Col sm={12} style={{ paddingTop: 30 }}>
              <Message
                success
                header="Salvo!"
                content="Os parâmetros foram salvos com sucesso."
              />
            </Col>
          )}
          <Divider />
          <Col sm={12}>
            <Button type="submit" color="green" style={{ float: "right" }}>
              Salvar
            </Button>
          </Col>
        </Form>
      </div>
    );
  }
}
