import React, { Component } from "react";
import {
  Container,
  Header,
  Segment,
  Checkbox,
  List,
  Card,
} from "semantic-ui-react";
import { Form, Col, Row, Alert } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import Select from "react-select";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import moment from "moment-timezone";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import renomearChave from "../../../services/renomearChave";
import Lista from "./lista";
import ModalEditar from "./modalEditar";

import api from "../../../services/api";
export default class Competicao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedtreino: false,
      checkedcampeonato: false,
      tipoevento: null,
      modalidade_id: null,
      modalidade_descricao: "",
      optionsM: [],
      quantidade_tiros: null,
      descricao: "",
      modalidadesVinculadas: [],
      modalEditar: false,
    };
  }

  pesquisaModalidade = async () => {
    api
      .post("/modalidade/listar" + requisicoes.entidade, {}, requisicoes.header)
      .then((resp) => {
        let lista = [];

        resp.data.forEach((e) => {
          let objeto = renomearChave(e, "id", "value");
          objeto = renomearChave(objeto, "descricao", "label");
          lista.push(objeto);
        });
        this.setState({ optionsM: lista });
      });
  };

  toggleCheckbox = async (event) => {
    this.setState({ tipoevento: event.target.id });
    if (event.target.id === "treino") {
      if (this.state.checkedtreino === true) {
        this.setState({ checkedtreino: false });
        this.setState({ tipoevento: null });
      } else {
        this.setState({ checkedtreino: true });
        this.setState({ checkedcampeonato: false });
      }
    } else {
      if (this.state.checkedcampeonato === true) {
        this.setState({ checkedcampeonato: false });
        this.setState({ tipoevento: null });
      } else {
        this.setState({ checkedcampeonato: true });
        this.setState({ checkedtreino: false });
      }
    }
  };

  alteracao = async (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  alteracaoSelect = async (event, value) => {
    this.setState({ modalidade_descricao: event.label });
    this.setState({ modalidade_id: event.value });

    this.listaVinculoModalidade(event.value);
  };

  listaVinculoModalidade = async (id) => {
    const retorno = await api.post(
      "/modalidade/listarVinculadas" + requisicoes.entidade,
      { id: id },
      requisicoes.header
    );

    this.setState({ modalidadesVinculadas: retorno.data });
  };

  voltar = async () => {
    window.location.href = "/eventos";
  };

  changeModalEditar = async (dados, status) => {
    this.setState({ modalEditar: status });
    console.log(dados);
    if (status === true) {
      this.setState({ quantidade_tiros: dados.quantidade_tiros });
      this.setState({ descricao: dados.descricao });
      this.setState({ modalidade_id: dados.modalidade[0].id });

      if (dados.tipo_evento === "treino") {
        this.setState({ tipoevento: "treino" });

        this.setState({ checkedtreino: true });
        this.setState({ checkedcampeonato: false });
      } else {
        this.setState({ tipoevento: "prova" });

        this.setState({ checkedcampeonato: true });
        this.setState({ checkedtreino: false });
      }
    }
  };

  salvarSubgrupoEditada = async () => {
    api.post(
      "/subgrupo/editar" + requisicoes.entidade,
      {
        tipo_evento: this.state.tipoevento,
        modalidade_id: this.state.modalidade_id,
        quantidade_tiro: parseInt(this.state.quantidade_tiros),
        descricao: this.state.descricao,
      },
      requisicoes.header
    );
  };

  handleSubmit = async () => {
    this.submit();
  };

  submit = async () => {
    api.post(
      "/subgrupo/salvar" + requisicoes.entidade,
      {
        tipo_evento: this.state.tipoevento,
        modalidade_id: this.state.modalidade_id,
        quantidade_tiro: parseInt(this.state.quantidade_tiros),
        descricao: this.state.descricao,
      },
      requisicoes.header
    );
  };

  render() {
    return (
      <Container className="paginaSemPaddingTop">
        <Form onSubmit={this.handleSubmit}>
          <Segment>
            <Header as="h2" dividing>
              Cadastro de Sub-Grupo
            </Header>
            <Col sm={12}>
              <Row>
                <Col sm={4}>
                  <Form.Label style={{ marginTop: "-3px" }}>
                    Modalidade
                  </Form.Label>
                  <Select
                    placeholder="vincular modalidade"
                    label="Tipo"
                    id="modalidade"
                    as="select"
                    name="modalidade"
                    options={this.state.optionsM}
                    onChange={this.alteracaoSelect}
                    onKeyDown={this.pesquisaModalidade}
                    required
                  />
                </Col>
                <Col sm={2}>
                  <TextField
                    id="descricao"
                    label="Descrição"
                    onChange={this.alteracao}
                    style={{
                      marginTop: "17px",
                    }}
                    required
                  />
                </Col>

                <Col sm={2}>
                  <TextField
                    id="quantidade_tiros"
                    label="Nº de Tiros"
                    onChange={this.alteracao}
                    type="number"
                    style={{
                      marginTop: "17px",
                    }}
                    disabled={this.state.modalidade_id == null}
                    required
                  />
                </Col>

                <Row>
                  {" "}
                  <Col sm={5}>
                    <Form required>
                      <Form.Label>Tipo de Evento</Form.Label>
                      <Checkbox
                        onChange={this.toggleCheckbox}
                        checked={this.state.checkedtreino}
                        slider
                        id="treino"
                        name="treino"
                        label="Treino"
                      />
                      <Checkbox
                        onChange={this.toggleCheckbox}
                        checked={this.state.checkedcampeonato}
                        id="prova"
                        slider
                        name="prova"
                        label="Prova"
                      />
                    </Form>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Segment>

          <Segment>
            <Lista
              modalidadesVinculadas={this.state.modalidadesVinculadas}
              changeModalEditar={this.changeModalEditar}
            ></Lista>
          </Segment>

          <ModalEditar
            modalEditar={this.state.modalEditar}
            changeModalEditar={this.changeModalEditar}
            optionsM={this.state.optionsM}
            alteracaoSelect={this.alteracaoSelect}
            pesquisaModalidade={this.pesquisaModalidade}
            alteracao={this.alteracao}
            modalidade_id={this.state.modalidade_id}
            toggleCheckbox={this.toggleCheckbox}
            checkedtreino={this.state.checkedtreino}
            checkedcampeonato={this.state.checkedcampeonato}
            descricao={this.state.descricao}
            quantidade_tiros={this.state.quantidade_tiros}
            salvarSubgrupoEditada={this.salvarSubgrupoEditada}
          ></ModalEditar>

          <Segment clearing>
            <Header floated="right">
              <Button
                type="submit"
                style={{ outline: "none" }}
                color="primary"
                disabled={
                  this.state.modalidade_id === null ||
                  this.state.quantidade_tiros === null ||
                  this.state.tipoevento === null
                }
              >
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>
      </Container>
    );
  }
}
