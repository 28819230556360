import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { Modal } from "semantic-ui-react";
import {
  Button,
  ThemeProvider,
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Grid,
  CardHeader,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { Save } from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

export default class ModalAdicionaPermissao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // inputs

      // listas
      listaModulosEsquerda: [],
      listaModulosDireita: [],
      listaModulosChecked: [],

      // state botões
      loading: false,

      // Modais
    };
  }

  handleToggle = (value, checkedListName) => {
    let lista = this.state[checkedListName];
    let idx = lista.indexOf(value);
    if (idx === -1) {
      lista.push(value);
    } else {
      lista.splice(idx, 1);
    }

    this.setState({ [checkedListName]: lista });
  };

  moveEntreListas = (listaChecked, listaDe, listaPara) => {
    let lista1 = this.state[listaDe];
    let lista2 = this.state[listaPara];
    let listaCheckbox = this.state[listaChecked];

    for (let i = 0; i < lista1.length; i++) {
      const element = lista1[i];
      let index = listaCheckbox.findIndex((e) => e === element.id);
      if (index !== -1) {
        lista2.push(element);
        lista1.splice(i, 1);
        listaCheckbox.splice(index, 1);
        i--;
      }
    }

    this.setState({
      [listaChecked]: listaCheckbox,
      [listaDe]: lista1,
      [listaPara]: lista2,
    });
  };

  salvar = async () => {
    try {
      this.setState({ loading: true });

      console.log(
        "this.props.entidadeSelectedModalLiberarModulo: ",
        this.props.entidadeSelectedModalLiberarModulo
      );
      await api.post(
        "/modulo/salvarEntidadeModulo" + requisicoes.entidade,
        {
          entidade_id: this.props.entidadeSelectedModalLiberarModulo.id,
          modulos: this.state.listaModulosDireita,
        },
        requisicoes.header
      );

      setTimeout(() => {
        this.props.onClose();
        this.setState({ loading: false });
      }, 800);
    } catch (error) {
      alert("Ocorreu um erro ao salvar o novo módulo.");
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <Modal
        centered={true}
        open={this.props.open}
        onClose={this.props.onClose}
        onMount={() => {
          this.setState({
            loading: false,

            listaModulosEsquerda: [
              ...this.props.listaModulos.filter(
                (e) =>
                  this.props.entidadeSelectedModalLiberarModulo.modulos.findIndex(
                    (e2) => e2.id === e.id
                  ) === -1
              ),
            ],
            listaModulosDireita:
              this.props.entidadeSelectedModalLiberarModulo.modulos,
            listaModulosChecked: [],
          });
        }}
      >
        <Modal.Header>Liberar Módulos Para o Cliente</Modal.Header>
        <Modal.Content>
          <div
            style={{
              height: 500,
              overflow: "auto",
              borderWidth: "2px",
              borderColor: "#c3c9cc",
              borderStyle: "solid",
              borderRadius: "7px",
              padding: 15,
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={5}>
                {customList(
                  this.state.listaModulosEsquerda,
                  "listaModulosChecked",
                  this.state.listaModulosChecked,
                  "nome",
                  this.handleToggle,
                  "Não Liberados"
                )}
              </Grid>
              <Grid item xs={2}>
                <Grid container direction="column" alignItems="center">
                  <Button
                    variant="outlined"
                    style={{ margin: 20 }}
                    onClick={() =>
                      this.moveEntreListas(
                        "listaModulosChecked",
                        "listaModulosEsquerda",
                        "listaModulosDireita"
                      )
                    }
                  >
                    &gt;
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      this.moveEntreListas(
                        "listaModulosChecked",
                        "listaModulosDireita",
                        "listaModulosEsquerda"
                      )
                    }
                  >
                    &lt;
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                {customList(
                  this.state.listaModulosDireita,
                  "listaModulosChecked",
                  this.state.listaModulosChecked,
                  "nome",
                  this.handleToggle,
                  "Liberados"
                )}
              </Grid>
            </Grid>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="auto">
              <Button
                onClick={this.props.onClose}
                variant="contained"
                color="inherit"
              >
                Voltar
              </Button>
            </Col>
            <Col md="auto">
              <ThemeProvider
                theme={createTheme({
                  palette: {
                    primary: green,
                  },
                })}
              >
                <LoadingButton
                  variant="contained"
                  style={{ color: "#FFFFFF" }}
                  startIcon={<Save />}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                  onClick={this.salvar}
                >
                  Salvar
                </LoadingButton>
              </ThemeProvider>
            </Col>
          </Row>
        </Modal.Actions>
      </Modal>
    );
  }
}

function customList(
  list,
  checkedListName,
  checkedList,
  propriedadeLabel,
  handleToggle,
  title
) {
  return (
    <Card style={{ minHeight: 250 }}>
      <CardHeader sx={{ px: 2, py: 1 }} title={title} />
      <List sx={{ overflow: "auto" }}>
        {list.map((e) => (
          <ListItem
            key={e.id}
            role="listitem"
            button
            onClick={() => handleToggle(e.id, checkedListName)}
          >
            <ListItemIcon>
              <Checkbox
                checked={checkedList.indexOf(e.id) !== -1}
                tabIndex={-1}
                disableRipple
                // inputProps={{
                //   "aria-labelledby": labelId,
                // }}
              />
            </ListItemIcon>
            <ListItemText
              // id={labelId}
              primary={e[propriedadeLabel]}
            />
          </ListItem>
        ))}
      </List>
    </Card>
  );
}
