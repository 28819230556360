module.exports = (pessoa) => {
  let listaValidacao = [];
  listaValidacao.push({
    nome: validaQuantidadeCaracteres(pessoa.nome.slice(0, 35), 35),
    mensagem: "Nome Muito Extenso",
  });
  listaValidacao.push({
    tipo: validaCampoNulo(pessoa.tipo),
    mensagem: "Tipo Vazio",
  });
  listaValidacao.push({
    cpf: validaCpf(pessoa.cpf),
    mensagem: "Cpf Inválido 3",
  });
  listaValidacao.push({
    cnpj: validaCnpj(pessoa.cnpj),
    mensagem: "Cnpj Inválido",
  });
  listaValidacao.push({
    endereco: validaQuantidadeCaracteres(pessoa.endereco, 40),
    mensagem: "Endereço Muito Extenso",
  });
  listaValidacao.push({
    bairro: validaQuantidadeCaracteres(pessoa.bairro.slice(0, 12), 12),
    mensagem: "Bairro Muito Extenso",
  });
  listaValidacao.push({
    debito_conta: validaCampoNulo(pessoa.debito_conta_id),
    mensagem: "Débito Conta Está Vazio",
  });
  listaValidacao.push({
    debito_conta_conta:
      pessoa.debito_conta !== null &&
      validaCampoNulo(pessoa.debito_conta.conta),
    mensagem: "Conta Corrente Está Vazio",
  });
  listaValidacao.push({
    debito_conta_digito_conta:
      pessoa.debito_conta !== null &&
      validaCampoNulo(pessoa.debito_conta.digito_conta),
    mensagem: "Dígito Conta Corrente Está Vazio",
  });
  listaValidacao.push({
    debito_conta_agencia:
      pessoa.debito_conta !== null &&
      pessoa.debito_conta.agencia_debito_conta !== null &&
      validaCampoNulo(pessoa.debito_conta.agencia_debito_conta.agencia),
    mensagem: "Agência Está Vazio",
  });
  listaValidacao.push({
    debito_conta_agencia:
      pessoa.debito_conta !== null &&
      pessoa.debito_conta.agencia_debito_conta !== null &&
      validaCampoNulo(pessoa.debito_conta.agencia_debito_conta.digito_agencia),
    mensagem: "Dígito Agência Está Vazio",
  });
  return listaValidacao;
};

function validaCampoNulo(val) {
  if (val === null) {
    return false;
  } else {
    return true;
  }
}

function validaQuantidadeCaracteres(val, qtd) {
  if (val !== null) {
    if (val.length > qtd) {
      return false;
    } else {
      return true;
    }
  }
}

function validaCpf(cpf) {
  cpf = cpf.replace(/[^\d]/g, ""); // Remove caracteres não numéricos
  if (cpf.length !== 11) {
    return false; // CPF deve ter 11 dígitos
  }
  // Verifica se todos os dígitos são iguais (CPF inválido)
  if (/^(\d)\1+$/.test(cpf)) {
    return false;
  }
  // Calcula o primeiro dígito verificador
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let digit = (sum * 10) % 11;
  if (digit === 10) {
    digit = 0;
  }
  if (digit !== parseInt(cpf.charAt(9))) {
    return false; // CPF inválido
  }
  // Calcula o segundo dígito verificador
  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }
  digit = (sum * 10) % 11;
  if (digit === 10) {
    digit = 0;
  }
  if (digit !== parseInt(cpf.charAt(10))) {
    return false; // CPF inválido
  }
  return true; // CPF válido
}

function validaCnpj(val) {
  if (val !== null) {
    val = val.replace(/\./g, "");
    val = val.replace("-", "");
    if (val.length === 18) {
      var cnpj = val.trim();

      cnpj = cnpj.replace(/\./g, "");
      cnpj = cnpj.replace("-", "");
      cnpj = cnpj.replace("/", "");
      cnpj = cnpj.split("");

      var v1 = 0;
      var v2 = 0;
      var aux = false;

      for (let i = 1; cnpj.length > i; i++) {
        if (cnpj[i - 1] !== cnpj[i]) {
          aux = true;
        }
      }

      if (aux === false) {
        return false;
      }

      for (let i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
        if (p1 >= 2) {
          v1 += cnpj[i] * p1;
        } else {
          v1 += cnpj[i] * p2;
        }
      }

      v1 = v1 % 11;

      if (v1 < 2) {
        v1 = 0;
      } else {
        v1 = 11 - v1;
      }

      if (v1 !== cnpj[12]) {
        return false;
      }

      for (let i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
        if (p1 >= 2) {
          v2 += cnpj[i] * p1;
        } else {
          v2 += cnpj[i] * p2;
        }
      }

      v2 = v2 % 11;

      if (v2 < 2) {
        v2 = 0;
      } else {
        v2 = 11 - v2;
      }

      if (v2 !== cnpj[13]) {
        return false;
      } else {
        return true;
      }
    } else {
      if (val !== null) {
        return false;
      } else {
        return true;
      }
    }
  }
}
