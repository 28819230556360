import React, { Component } from "react";
import { Input, Button, Table } from "semantic-ui-react";
import ReactSelect from "react-select";
import { Col, Row } from "react-bootstrap";

import api from "../../../../../services/api";
import requisicoes from "../../../../../utils/Requisicoes";
import moment from "moment";

export default class EntradaItemLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Filtros
      options: [{ value: 0, label: "Todos os itens", selected: false }],
      optionsSelected: [],
      selectTodos: false,

      precoSelect: { value: "ultima", label: "Última compra" },

      dataBuscaInicial: moment().startOf("month").format("YYYY-MM-DD"),
      dataBuscaFinal: moment().format("YYYY-MM-DD"),

      // Dados
      requisicoes: [],

      // estado botões
      buscando: false,
    };
  }
  pesquisa = async (pesquisa) => {
    let result = await api.post(
      "/item/pesquisa" + requisicoes.entidade,
      { pesquisa: pesquisa },
      requisicoes.header
    );

    let lista = [];

    lista.push({
      value: 0,
      label: "Todos os itens",
      selected: this.state.selectTodos,
    });

    for (let i = 0; i < result.data.length; i++) {
      const element = result.data[i];
      const optionsSelected = this.state.optionsSelected;
      if (optionsSelected.findIndex((e) => e.value === element.id) !== -1) {
        lista.push({
          value: element.id,
          label: element.descricao,
          selected: true,
        });
      } else {
        lista.push({
          value: element.id,
          label: element.descricao,
          selected: this.state.selectTodos,
        });
      }
    }
    this.setState({ options: lista });
  };

  onChangeSelectItem = (e, a) => {
    if (a.action === "clear") {
      this.setState({
        options: [],
        optionsSelected: [],
        selectTodos: false,
      });
      return;
    }
    if (a.action === "pop-value" && a.removedValue === undefined) {
      return;
    }
    if (a.removedValue !== undefined) {
      a.option = a.removedValue;
    }

    if (a.option.value === 0) {
      if (a.action === "select-option") {
        let lista2 = this.state.options;
        for (let i = 0; i < lista2.length; i++) {
          const element = lista2[i];
          element.selected = true;
        }
        this.setState({ options: lista2, selectTodos: true });
      }
      if (
        a.action === "deselect-option" ||
        a.action === "pop-value" ||
        a.action === "remove-value"
      ) {
        let lista2 = this.state.options;
        const optionsSelected = this.state.optionsSelected;

        for (let i = 0; i < lista2.length; i++) {
          const element = lista2[i];
          if (optionsSelected.findIndex((e) => e.value === element.id) !== -1) {
            element.selected = true;
          } else {
            element.selected = false;
          }
        }
        this.setState({ options: lista2, selectTodos: false });
      }
    }
    if (a.action === "select-option") {
      let lista = this.state.optionsSelected;
      lista.push(a.option);
      let lista2 = this.state.options;
      let index = lista2.findIndex(
        (element) => element.value === a.option.value
      );
      lista2[index].selected = true;

      this.setState({
        optionsSelected: lista,
        options: lista2,
      });
    }
    if (
      a.action === "deselect-option" ||
      a.action === "pop-value" ||
      a.action === "remove-value"
    ) {
      let lista = this.state.optionsSelected;
      let index0 = lista.findIndex(
        (element) => element.value === a.option.value
      );
      lista.splice(index0, 1);

      let lista2 = this.state.options;
      let index = lista2.findIndex(
        (element) => element.value === a.option.value
      );
      lista2[index].selected = false;

      this.setState({
        optionsSelected: lista,
        options: lista2,
      });
    }
  };

  gerarArquivo = async () => {
    let listaItensID = [];
    for (let i = 0; i < this.state.optionsSelected.length; i++) {
      const element = this.state.optionsSelected[i];
      listaItensID.push(element.value);
    }
    await api.post(
      "/requisicao/relatorioRequisicoes" + requisicoes.entidade,
      {
        data_inicial: this.state.dataBuscaInicial,
        data_final: this.state.dataBuscaFinal,
        itens: this.state.selectTodos ? null : listaItensID,
        precoSelect: this.state.precoSelect.value,
      },
      requisicoes.header
    );
  };
  buscarArquivo = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");

    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    setTimeout(async () => {
      const r = await api.get(
        "/requisicao/arquivoRelatorioRequisicoes" +
          requisicoes.entidade +
          "&t=Baer " +
          token +
          "&userId=" +
          usuarioLogado.data.id
      );
      var oReq = new XMLHttpRequest();

      var URLToPDF =
        r.config.baseURL +
        "requisicao/arquivoRelatorioRequisicoes" +
        requisicoes.entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.id;

      oReq.open("GET", URLToPDF, true);
      oReq.responseType = "blob";
      oReq.onload = function () {
        const file = new Blob([oReq.response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      };
      oReq.send();
    }, 500);
  };
  imprimirOnClick = async () => {
    await this.gerarArquivo();
    await this.buscarArquivo();
  };

  buscarOnClick = async () => {
    this.setState({ buscando: true });
    let listaItensID = [];
    let erro = false;
    for (let i = 0; i < this.state.optionsSelected.length; i++) {
      const element = this.state.optionsSelected[i];
      listaItensID.push(element.value);
    }

    let result = await api
      .post(
        "/requisicao/buscaRequisicaoFiltrada" + requisicoes.entidade,
        {
          data_inicial: this.state.dataBuscaInicial,
          data_final: this.state.dataBuscaFinal,
          itens: this.state.selectTodos ? null : listaItensID,
        },
        requisicoes.header
      )
      .catch((e) => {
        console.log("e: ", e);
        this.setState({ buscando: false });
        erro = true;
      });
    if (erro === true) return;

    let totais = {
      valor: 0,
      valor_medio: 0,
      total_valor: 0,
      total_valor_medio: 0,
      total_quantidade: 0,
      total_requisicoes: 0,
    };

    // Agrupar as saidas por data
    for (let i = 0; i < result.data.length; i++) {
      const element = result.data[i];

      if (element.total_requisicoes === undefined) {
        element.total_requisicoes = 0;
      }
      if (element.itens.length > 0) {
        element.total_requisicoes += 1;
        totais.total_requisicoes += 1;
      }

      for (let j = 0; j < element.itens.length; j++) {
        const element2 = element.itens[j];
        element2.numero = element.numero;
      }
      if (i === 0) {
        continue;
      }
      if (element.data === result.data[i - 1].data) {
        result.data[i - 1].itens = result.data[i - 1].itens.concat(
          element.itens
        );
        if (element.itens.length > 0) {
          result.data[i - 1].total_requisicoes += 1;
        }
        result.data.splice(i, 1);
        i--;
      }
    }

    // somar os valores totais
    for (let i = 0; i < result.data.length; i++) {
      const element = result.data[i];
      element.total_valor = 0.0;
      element.total_valor_medio = 0.0;
      element.total_quantidade = 0.0;
      element.valor = 0.0;
      element.valor_medio = 0.0;
      for (let j = 0; j < element.itens.length; j++) {
        const element2 = element.itens[j];
        element.total_quantidade += parseFloat(element2.quantidade);
        totais.total_quantidade += parseFloat(element2.quantidade);

        element.total_valor_medio += parseFloat(
          parseFloat(
            parseFloat(element2.item.precoMedio) *
              parseFloat(element2.quantidade)
          ).toFixed(4)
        );
        totais.total_valor_medio += parseFloat(
          parseFloat(
            parseFloat(element2.item.precoMedio) *
              parseFloat(element2.quantidade)
          ).toFixed(4)
        );

        element.total_valor += parseFloat(
          parseFloat(
            parseFloat(element2.item.valor_unidade) *
              parseFloat(element2.quantidade)
          ).toFixed(4)
        );
        totais.total_valor += parseFloat(
          parseFloat(
            parseFloat(element2.item.valor_unidade) *
              parseFloat(element2.quantidade)
          )
        );

        element.valor += parseFloat(element2.item.valor_unidade);
        totais.valor += parseFloat(element2.item.valor_unidade);

        element.valor_medio += parseFloat(element2.item.precoMedio);
        totais.valor_medio += parseFloat(element2.item.precoMedio);
      }
    }
    this.setState({
      requisicoes: result.data,
      buscando: false,
      totais,
    });
  };

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // borderBottom: "1px dotted pink",
        // color: state.isSelected ? "red" : "blue",
        // icon: state.isSelected ? <Icon name="x"></Icon> : null,
        // class
      }),
      // control: () => ({
      //   // none of react-select's styles are passed to <Control />
      //   // width: 200,
      // }),
      // singleValue: (provided, state) => {
      //   // const opacity = state.isDisabled ? 0.5 : 1;
      //   // const transition = "opacity 300ms";
      //   // return { ...provided, opacity, transition };
      //   return { ...provided };
      // },
    };
    return (
      <div>
        <Row>
          <Col fluid>
            <ReactSelect
              options={this.state.options}
              isMulti
              className="basic-multi-select"
              classNamePrefix="select"
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              onChange={this.onChangeSelectItem}
              isOptionSelected={(element) => element.selected === true}
              onInputChange={(e, a) => {
                if (e !== "") {
                  this.pesquisa(e);
                }
              }}
              // styles={customStyles}
            />
          </Col>
          <Col sm={2}>
            <ReactSelect
              value={this.state.precoSelect}
              options={[
                { value: "ultima", label: "Ultima compra" },
                { value: "medio", label: "Preço médio" },
              ]}
              onChange={(e, a) => this.setState({ precoSelect: e })}
            />
          </Col>
          <Col md="auto">
            <Input
              type="date"
              error={this.state.dataInvalida}
              // onChange={this.changeDate}
              loading={this.state.loadingData}
              defaultValue={this.state.dataBuscaInicial}
              onKeyUp={(e) => {
                this.setState({ dataBuscaInicial: e.target.value });
              }}
              onChange={(e) => {
                this.setState({ dataBuscaInicial: e.target.value });
              }}
            />
          </Col>
          <Col md="auto">
            <Input
              type="date"
              error={this.state.dataInvalida}
              // onChange={this.changeDate}
              loading={this.state.loadingData}
              defaultValue={this.state.dataBuscaFinal}
              onKeyUp={(e) => {
                this.setState({ dataBuscaFinal: e.target.value });
              }}
              onChange={(e) => {
                this.setState({ dataBuscaFinal: e.target.value });
              }}
            />
          </Col>
        </Row>
        <Row style={{ paddingTop: 10, justifyContent: "flex-end" }}>
          <Col md="auto" style={{ paddingRight: 0 }}>
            <Button
              positive
              onClick={this.buscarOnClick}
              disabled={
                this.state.optionsSelected.length === 0 ||
                this.state.buscando === true
              }
              loading={this.state.buscando}
            >
              Buscar
            </Button>
          </Col>
          <Col md="auto">
            <Button primary onClick={this.imprimirOnClick}>
              Imprimir
            </Button>
          </Col>
        </Row>
        {this.state.requisicoes.length > 0 && (
          <Table celled>
            <Table.Header>
              <Table.Row>
                {/* <Table.HeaderCell width={2}></Table.HeaderCell> */}
                <Table.HeaderCell width={8}></Table.HeaderCell>

                <Table.HeaderCell width={2}>Quantidade</Table.HeaderCell>
                <Table.HeaderCell>Valor</Table.HeaderCell>
                <Table.HeaderCell>V. Total</Table.HeaderCell>
                <Table.HeaderCell width={2}>Nº Req</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell style={{ fontWeight: "bold" }}>
                  Totalizadores
                </Table.Cell>
                <Table.Cell>{this.state.totais.total_quantidade}</Table.Cell>
                <Table.Cell>
                  {this.state.precoSelect.value === "ultima"
                    ? parseFloat(this.state.totais.valor).toLocaleString(
                        "pt-BR",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )
                    : parseFloat(this.state.totais.valor_medio).toLocaleString(
                        "pt-BR",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                </Table.Cell>
                <Table.Cell>
                  {this.state.precoSelect.value === "ultima"
                    ? parseFloat(this.state.totais.total_valor).toLocaleString(
                        "pt-BR",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )
                    : parseFloat(
                        this.state.totais.total_valor_medio
                      ).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </Table.Cell>
                <Table.Cell>{this.state.totais.total_requisicoes}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}

        {this.state.requisicoes.map((e, i) => (
          <>
            <h4>{"Data: " + moment(e.data).format("DD/MM/YYYY")}</h4>
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={2}></Table.HeaderCell>
                  <Table.HeaderCell width={6}></Table.HeaderCell>
                  <Table.HeaderCell width={2}>Quantidade</Table.HeaderCell>
                  <Table.HeaderCell>Valor</Table.HeaderCell>
                  <Table.HeaderCell>V. Total</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Nº Req</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {e.itens.map((e2, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{e2.item.id}</Table.Cell>
                    <Table.Cell>{e2.item.descricao}</Table.Cell>
                    <Table.Cell>{e2.quantidade}</Table.Cell>
                    <Table.Cell>
                      {this.state.precoSelect.value === "ultima"
                        ? parseFloat(e2.item.valor_unidade).toLocaleString(
                            "pt-BR",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        : parseFloat(e2.item.precoMedio).toLocaleString(
                            "pt-BR",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                    </Table.Cell>
                    <Table.Cell>
                      {this.state.precoSelect.value === "ultima"
                        ? parseFloat(
                            parseFloat(e2.item.valor_unidade) *
                              parseFloat(e2.quantidade)
                          ).toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : parseFloat(
                            parseFloat(e2.item.precoMedio) *
                              parseFloat(e2.quantidade)
                          ).toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </Table.Cell>
                    <Table.Cell>{e2.numero}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>{e.total_quantidade}</Table.HeaderCell>
                  <Table.HeaderCell>
                    {this.state.precoSelect.value === "ultima"
                      ? parseFloat(e.valor).toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : parseFloat(e.valor_medio).toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {this.state.precoSelect.value === "ultima"
                      ? parseFloat(e.total_valor).toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : parseFloat(e.total_valor_medio).toLocaleString(
                          "pt-BR",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>{e.total_requisicoes}</Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </>
        ))}
      </div>
    );
  }
}
