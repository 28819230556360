import React, { Component } from "react";
import { Container, Header, Menu, Icon } from "semantic-ui-react";

import Pessoas from "./pessoa/lista";
import TipoTitulo from "./tipoTitulo/lista";
import Funcionario from "./funcionario/menu";

export default class Cadastro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo_clube: window.localStorage.getItem("tipo_clube"),
      selectedMenu: localStorage.getItem("pagina_anterior") || "pessoa",
    };
  }

  render() {
    let content = "";
    switch (this.state.selectedMenu) {
      case "pessoa":
        localStorage.setItem("pagina_anterior", "pessoa");
        content = <Pessoas />;
        break;
      case "tipoTitulo":
        localStorage.setItem("pagina_anterior", "tipoTitulo");
        content = <TipoTitulo />;
        break;
      case "funcionario":
        localStorage.setItem("pagina_anterior", "funcionario");
        content = <Funcionario />;
        break;
      default:
        break;
    }
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Cadastro
        </Header>
        <Menu tabular icon="labeled">
          {(this.props.menus.find((e) => e.url === "/pessoa/lista") ||
            this.props.submenus.find((e) => e.nome === "pessoas")) && (
            <Menu.Item
              name="Pessoas"
              active={this.state.selectedMenu === "pessoa"}
              onClick={this.selectMenu.bind(null, "pessoa")}
            >
              <Icon name="users" />
              Pessoas
            </Menu.Item>
          )}
          {(this.props.menus.find((e) => e.url === "/tipoTitulo/lista") ||
            this.props.submenus.find((e) => e.nome === "tipo_titulo")) && (
            <Menu.Item
              name="Tipo Título"
              active={this.state.selectedMenu === "tipoTitulo"}
              onClick={this.selectMenu.bind(null, "tipoTitulo")}
            >
              <Icon name="book" />
              Tipo Titulo
            </Menu.Item>
          )}
          {this.props.submenus.find((e) => e.nome === "funcionario") && (
            <Menu.Item
              name="Funcionários"
              active={this.state.selectedMenu === "funcionario"}
              onClick={this.selectMenu.bind(null, "funcionario")}
            >
              <Icon name="users" />
              Funcionários
            </Menu.Item>
          )}
        </Menu>
        {content}
      </div>
    );
  }
  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
  };
}
