import React, { Component } from "react";
import {
  Table,
  Icon,
  Modal,
  Button,
  FormField,
  Input,
  Dropdown,
  Card,
  Popup,
  Dimmer,
  Loader,
  List,
  Message,
  Transition,
  Pagination,
} from "semantic-ui-react";
import { Col, Form, Row, FormControl } from "react-bootstrap";
import api from "../../../services/api";
import Select from "react-select";
import requisicoes from "../../../utils/Requisicoes";
import renomearChave from "../../../services/renomearChave";
import renomear2Chaves from "../../../services/renomear2Chaves";
import InputMask from "react-input-mask";
import ibge from "../../../services/apiIbge";
import cep from "cep-promise";
import { parseISO, format } from "date-fns";

import ModalContaContabil from "./modalContaContabil";

export default class PessoaLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      pagina: 1,
      totalPaginas: 1,
      options: [],
      pessoa_id: null,
      pessoa: null,
      open: false,
      conta_contabil: null,
      conta_contabil_id: null,
      fornecedor: true,
      tipoPessoa: "juridico",
      banco: null,
      agencia: null,
      conta: null,
      optionsAI: [
        { key: "ativo", value: true, text: "Ativo" },
        { key: "inativo", value: false, text: "Inativo" },
      ],
      ativo: true,
      reduzido: null,
      popUpPesquisa: false,
      tipo: "juridico",
      cep: null,
      celular: null,
      telefone: null,
      numero: null,
      estado: null,
      email: null,
      id: null,
      cidade: null,
      tipo_busca: "nome",
      nome: null,
      nomefantasia: null,
      historico_fornecedor: [],
      openSecond: false,
      pix: null,
      mensagem: null,
    };
    this.lista();
    this.listaEstado();
    this.alteracao = this.alteracao.bind(this);
  }

  alteracaoSelectAtivo = async (event, id) => {
    this.setState({ [id.name]: id.value });
  };

  buscaReduzido = async () => {
    const retorno = await api.post(
      "/pessoa/findLastReduzido" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    if (retorno.data != "") {
      const num = retorno.data[0].reduzido + 1;
      this.setState({ reduzido: num });
    } else {
      const num = 1;
      this.setState({ reduzido: num });
    }
  };

  alteracaoCampos(tipo) {
    if (tipo === "juridico") {
      document.getElementById("divNomeFantasia").removeAttribute("hidden");
      document.getElementById("divCpf").setAttribute("hidden", "");
      document.getElementById("divCnpj").removeAttribute("hidden");
      document.getElementById("divDataNascimento").setAttribute("hidden", "");
      document.getElementById("divSexo").setAttribute("hidden", "");
      document.getElementById("divEstadoCivil").setAttribute("hidden", "");
    } else {
      document.getElementById("divNomeFantasia").setAttribute("hidden", "");
      document.getElementById("divCpf").removeAttribute("hidden");
      document.getElementById("divCnpj").setAttribute("hidden", "");
      document.getElementById("divDataNascimento").removeAttribute("hidden");
      document.getElementById("divSexo").removeAttribute("hidden");
      document.getElementById("divEstadoCivil").removeAttribute("hidden");
    }
  }

  listaEstado = () => {
    let lista = [];
    ibge.get("/localidades/estados").then((resp) => {
      resp.data.forEach((e) => {
        let objeto = renomearChave(e, "sigla", "value");
        objeto = renomearChave(objeto, "nome", "text");
        lista.push(objeto);
      });
      this.setState({ estados: lista });
    });
  };

  listaTodasCidades = async (event) => {
    let lista = [];
    ibge
      .get("/localidades/estados/" + event.target.value + "/municipios")
      .then((resp) => {
        resp.data.forEach((e) => {
          let objeto = renomearChave(e, "nome", "value");
          objeto = renomearChave(objeto, "nome", "text");
          lista.push(objeto);
        });
        this.setState({ cidades: lista });
      });
  };

  listaCidades = async (event) => {
    let lista = [];
    ibge
      .get("/localidades/estados/" + event.target.value + "/municipios")
      .then((resp) => {
        resp.data.forEach((e) => {
          let objeto = renomearChave(e, "nome", "value");
          objeto = renomearChave(objeto, "nome", "text");
          lista.push(objeto);
        });
        this.setState({ cidades: lista });
      });
  };

  todasCidades = async (event) => {
    let lista = [];
    ibge.get("/localidades/municipios").then((resp) => {
      resp.data.forEach((e) => {
        let objeto = renomearChave(e, "nome", "value");
        objeto = renomearChave(objeto, "nome", "text");
        lista.push(objeto);
      });
      this.setState({ cidades: lista });
    });
  };

  listaCidadesPorUF = async (uf) => {
    let lista = [];
    ibge.get("/localidades/estados/" + uf + "/municipios").then((resp) => {
      resp.data.forEach((e) => {
        let objeto = { value: e.nome, text: e.nome };
        lista.push(objeto);
      });
      this.setState({ cidades: lista });
    });
  };

  buscaCidadeSetaCidEst = async (codigo) => {
    ibge.get("/localidades/municipios/" + codigo).then((resp) => {
      this.setState({ estado: resp.data.microrregiao.mesorregiao.UF.sigla });
      this.setState({ cidade: resp.data.nome });
    });
  };

  alteracao(event) {
    if (event.target.name === "cidade") {
      this.buscaCidadeSetaCidEst(event.target.value);
    } else {
      if (
        event.target.name === "conta" &&
        (event.target.value === "" || event.target.value.includes("."))
      ) {
        return;
      }
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  buscaCep = async (event) => {
    cep(event.target.value).then((cep) => {
      this.setState({ estado: cep.state });
      this.listaCidadesPorUF(cep.state);
      this.setState({ cidade: cep.city });
    });
  };

  lista = async (page = 1) => {
    this.setState({ salvando: false });
    const resposta = await api.post(
      "/pessoa/listaFornecedor" + requisicoes.entidade + "&pagina=" + page,
      "",
      requisicoes.header
    );
    const { pessoas, pagina, totalPaginas } = resposta.data;
    this.setState({ lista: pessoas, pagina, totalPaginas });
  };

  pesquisaFornecedor = (event) => {
    this.setState({ popUpPesquisa: false });
    api
      .post(
        "/pessoa/pesquisaFornecedor" + requisicoes.entidade,
        { pesquisa: event.target.value },
        requisicoes.header
      )
      .then((resp) => {
        if (resp.data.length == 0) {
          this.setState({ popUpPesquisa: true });
        } else {
          let lista = [];
          resp.data.forEach((element) => {
            let objeto = renomearChave(element, "id", "value");
            objeto = renomear2Chaves(objeto, "reduzido", "nome", "label");
            lista.push(objeto);
          });
          this.setState({ lista: resp.data });
        }
      });
  };

  alteracaoFornecedorReduzido = async (event) => {
    this.setState({ pessoa: event });
    const retorno = await api.post(
      "/pessoa/pesquisaFornecedorReduzido" + requisicoes.entidade,
      { pesquisa: event.value },
      requisicoes.header
    );
    this.setState({ listaFornecedor: retorno.data });
  };

  pesquisaPessoa = async () => {
    console.log("this.state.cpf: ", this.state.cpf);
    setTimeout(async () => {
      const retorno = await api.post(
        "/pessoa/pesquisaTipo_Doc" + requisicoes.entidade,
        // { doc: document.getElementById("pesquisaPessoa").value },
        {
          doc:
            this.state.tipo === "juridico" ? this.state.cnpj : this.state.cpf,
        },
        requisicoes.header
      );
      if (retorno.data.length > 0) {
        this.setState(retorno.data[0]);
      }

      await this.todasCidades();
      let lista = [];
      for (let i = 0; i < retorno.data.length; i++) {
        const element = retorno.data[i];
        if (element.cpf !== null) {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomearChave(objeto, "cpf", "label");
          lista.push(objeto);
        }
        if (element.cnpj !== null) {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomearChave(objeto, "cnpj", "label");
          lista.push(objeto);
        }
      }
      this.setState({ options: lista });
    }, 500);
  };

  pesquisaContaContabil = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/contaContabil/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaContaContabil").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomear2Chaves(objeto, "codigo", "descricao", "label");
        lista.push(objeto);
      });
      this.setState({ optionsCC: lista });
    }, 500);
  };

  alteracaoSelectPessoa = async (event) => {
    this.setState({ pessoa_id: event.value });
    this.setState({ pessoa: event });
  };

  alteracaoSelectContaContabil = async (event) => {
    this.setState({ conta_contabil_id: event.value });
    this.setState({ conta_contabil: event });
  };

  pageChange = (event, a) => {
    this.lista(a.activePage);
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleShow = async (idPessoa) => {
    this.listaEstado();
    if (idPessoa == "") {
      await this.buscaReduzido();
    } else {
      this.setState({ carregando: true });
      await api
        .post(
          "/pessoa/visualiza" + requisicoes.entidade,
          { id: idPessoa },
          requisicoes.header
        )
        .then((resposta) => {
          this.setState(resposta.data);
          this.setState({ tipoPessoa: resposta.data.tipo });
          if (resposta.data.conta_contabil !== null) {
            let o = renomearChave(resposta.data.conta_contabil, "id", "value");
            o = renomearChave(o, "descricao", "label");
            this.setState({
              conta_contabil_id: resposta.data.conta_contabil.id,
            });
            this.setState({ conta_contabil: o });
            this.setState({ conta_contabil_DB: resposta.data.conta_contabil });
          }
          this.listaCidadesPorUF(resposta.data.estado);
        });
    }
    this.setState({ open: true });
    this.setState({ carregando: false });
  };

  handleShow2 = () => this.setState({ openSecond: true });
  handleClose2 = () => this.setState({ openSecond: false });

  handleSubmit = (event) => {
    this.setState({ salvando: true });
    this.submit({});
    event.preventDefault();
  };

  submit = async () => {
    if (
      this.state.conta_contabil === null ||
      this.state.conta_contabil_id === null
    ) {
      alert("Selecine uma conta contábil");
      this.setState({ salvando: false });
      return;
    }
    if (this.state.ativo === "ativo") {
      this.setState({ ativo: true });
    } else if (this.state.ativo === "inativo") {
      this.setState({ ativo: false });
    }

    //
    if (this.state.tipo === "juridico") {
      let buscaCnpj = await api.post(
        "/pessoa/buscarCnpj" + requisicoes.entidade,
        {
          cnpj: this.state.cnpj,
        },
        requisicoes.header
      );
      if (buscaCnpj.data.length > 0 && this.state.id !== buscaCnpj.data[0].id) {
        this.setState({
          salvando: false,
          mensagem: (
            <Message
              negative
              header="CNPJ já cadastrado!"
              content={
                "O CNPJ " +
                this.state.cnpj +
                " inserido já foi utilizado em outro cadastro."
              }
            />
          ),
        });
        setTimeout(() => {
          this.setState({ mensagem: null });
        }, 5000);
        return;
      }
    } else if (this.state.tipo === "fisico") {
      let buscaCpf = await api.post(
        "/pessoa/buscarCpf" + requisicoes.entidade,
        {
          cpf: this.state.cpf,
        },
        requisicoes.header
      );
      if (buscaCpf.data.length > 0 && this.state.id !== buscaCpf.data[0].id) {
        this.setState({
          salvando: false,
          mensagem: (
            <Message
              negative
              header="CPF já cadastrado!"
              content={
                "O CPF " +
                this.state.cpf +
                " inserido já foi utilizado em outro cadastro."
              }
            />
          ),
        });
        setTimeout(() => {
          this.setState({ mensagem: null });
        }, 5000);
        return;
      }
    }

    if (this.state.id === null) {
      //Create
      await api.post(
        "/pessoa/salvar" + requisicoes.entidade,
        this.state,
        requisicoes.header
      );
    } else {
      //Update
      await api.post(
        "/pessoa/novoFornecedor" + requisicoes.entidade,
        this.state,
        requisicoes.header
      );
    }
    this.setState({ salvando: false });
    this.lista();
    this.handleClose();
  };

  render() {
    return (
      <div className="lista" style={{ paddingBottom: 100 }}>
        <Dimmer active={this.state.carregando} inverted>
          <Loader size="medium">Carregando...</Loader>
        </Dimmer>
        <Row>
          <Col sm={6}>
            <Popup
              content="Nenhum registro encontrado!"
              open={this.state.popUpPesquisa}
              position="right center"
              trigger={
                <Input
                  fluid
                  placeholder="Digite para buscar"
                  icon="search"
                  name="fornecedor"
                  onKeyUp={this.pesquisaFornecedor}
                />
              }
            />
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>CNPJ</Table.HeaderCell>
              <Table.HeaderCell>Cidade</Table.HeaderCell>
              <Table.HeaderCell>Estado</Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <Button
                  positive
                  icon="add"
                  size="tiny"
                  onClick={() => this.handleShow("")}
                ></Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.lista.map((e, i) => (
              <Table.Row key={i} negative={e.ativo === false}>
                <Table.Cell key={(i + 1) * 100}>
                  {e.nome + " "}
                  {e.ativo === false && <strong>{"(inativo) "}</strong>}
                </Table.Cell>
                <Table.Cell key={(i + 1) * 1000}>{e.cnpj}</Table.Cell>
                <Table.Cell key={(i + 1) * 10000}>{e.cidade}</Table.Cell>
                <Table.Cell key={(i + 1) * 1000000}>{e.estado}</Table.Cell>
                <Table.Cell width={1} key={(i + 1) * 100000000}>
                  <Button
                    primary
                    id={e.id}
                    icon={{
                      className: "edit",
                      // id: [e.id],
                      // onClick: [this.handleShow],
                    }}
                    onClick={() => this.handleShow(e.id)}
                    size="tiny"
                  ></Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan={5}>
                <Pagination
                  boundaryRange={0}
                  ellipsisItem={null}
                  siblingRange={1}
                  totalPages={this.state.totalPaginas}
                  onPageChange={this.pageChange}
                  activePage={this.state.pagina}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          centered={false}
        >
          <Modal.Header>Adicionar Fornecedor</Modal.Header>
          <Modal.Content>
            <Row>
              <Transition
                visible={this.state.mensagem !== null}
                animation="scale"
                duration={500}
              >
                <Col sm={12} style={{ paddingBottom: 15 }}>
                  {this.state.mensagem !== null && this.state.mensagem}
                </Col>
              </Transition>
              <Col sm={2}>
                <Form.Label>Ativo/Inativo</Form.Label>
                <Dropdown
                  placeholder="Ativo/Inativo"
                  fluid
                  selection
                  name="ativo"
                  options={this.state.optionsAI}
                  onChange={this.alteracaoSelectAtivo}
                  value={this.state.ativo}
                />
              </Col>
              <Col sm={2}>
                <Form.Label>Reduzido</Form.Label>
                <FormField
                  placeholder="Reduzido"
                  name="reduzido"
                  control={Input}
                  onChange={this.alteracao}
                  required
                  defaultValue={this.state.reduzido}
                  fluid
                />
              </Col>
              <Col sm={2}>
                <Form.Label>Tipo</Form.Label>
                <Form.Control
                  placeholder="Tipo"
                  label="Tipo"
                  id="tipo"
                  as="select"
                  name="tipo"
                  onChange={this.alteracao}
                  required
                  value={this.state.tipo}
                >
                  <option></option>
                  <option value="fisico">Físico</option>
                  <option
                    value="juridico"
                    selected={this.state.tipoPessoa === "juridico"}
                  >
                    Jurídico
                  </option>
                </Form.Control>
              </Col>
              <Col sm={6}>
                <Form.Label>
                  {this.state.tipo === "juridico" ? "CNPJ" : "CPF"}
                </Form.Label>
                <Row>
                  <Col sm={10}>
                    {this.state.tipo === "juridico" && (
                      <InputMask
                        mask={"99.999.999/9999-99"}
                        className="form-control"
                        placeholder="CNPJ"
                        name={"cnpj"}
                        onChange={this.alteracao}
                        value={this.state.cnpj}
                      />
                    )}
                    {this.state.tipo === "fisico" && (
                      <InputMask
                        mask={"999.999.999-99"}
                        className="form-control"
                        placeholder="CPF"
                        name={"cpf"}
                        onChange={this.alteracao}
                        value={this.state.cpf}
                      />
                    )}
                  </Col>
                  <Col sm={2}>
                    <Button icon onClick={this.pesquisaPessoa}>
                      <Icon name="search" />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Label>Nome</Form.Label>
                <FormField
                  placeholder="Nome"
                  name="nome"
                  control={Input}
                  onChange={this.alteracao}
                  defaultValue={this.state.nome}
                  fluid
                  //Usar uma string qualquer para desabilitar o preenchimento automático do navegador autocomplete="off" não funciona no react
                  autoComplete="disabled"
                />
              </Col>
              <Col sm={6} hidden={this.state.tipoPessoa != "juridico"}>
                <Form.Label>Nome Fantasia</Form.Label>
                <FormField
                  placeholder="Nome Fantasia"
                  name="nomefantasia"
                  control={Input}
                  onChange={this.alteracao}
                  defaultValue={this.state.nomefantasia}
                  fluid
                  //Usar uma string qualquer para desabilitar o preenchimento automático do navegador autocomplete="off" não funciona no react
                  autoComplete="disabled"
                />
              </Col>
              <Col sm={6}>
                <Form.Label>Endereço</Form.Label>
                <FormControl
                  placeholder="Endereço"
                  aria-label="Endereco"
                  aria-describedby="basic-addon1"
                  name="endereco"
                  onChange={this.alteracao}
                  defaultValue={this.state.endereco}
                  //Usar uma string qualquer para desabilitar o preenchimento automático do navegador autocomplete="off" não funciona no react
                  autoComplete="disabled"
                />
              </Col>
              <Col sm={6}>
                <Form.Label>Bairro</Form.Label>
                <FormControl
                  placeholder="Bairro"
                  aria-label="Bairro"
                  aria-describedby="basic-addon1"
                  name="bairro"
                  onChange={this.alteracao}
                  defaultValue={this.state.bairro}
                  //Usar uma string qualquer para desabilitar o preenchimento automático do navegador autocomplete="off" não funciona no react
                  autoComplete="disabled"
                />
              </Col>
              <Col sm={6}>
                <Form.Label>Numero</Form.Label>
                <FormControl
                  placeholder="Numero"
                  aria-label="Numero"
                  aria-describedby="basic-addon1"
                  name="numero"
                  onChange={this.alteracao}
                  defaultValue={this.state.numero}
                  //Usar uma string qualquer para desabilitar o preenchimento automático do navegador autocomplete="off" não funciona no react
                  autoComplete="disabled"
                />
              </Col>
              <Col sm={6}>
                <Form.Label>Complemento</Form.Label>
                <FormControl
                  placeholder="Complemento"
                  aria-label="Complemento"
                  aria-describedby="basic-addon1"
                  onChange={this.alteracao}
                  name="complemento"
                  defaultValue={this.state.complemento}
                  //Usar uma string qualquer para desabilitar o preenchimento automático do navegador autocomplete="off" não funciona no react
                  autoComplete="disabled"
                />
              </Col>

              <Col sm={6}>
                <Form.Label>CEP</Form.Label>
                <InputMask
                  mask="99.999-999"
                  className="form-control"
                  placeholder="CEP"
                  name="cep"
                  id="cep"
                  onChange={this.alteracao}
                  onBlur={this.buscaCep}
                  value={this.state.cep}
                  //Usar uma string qualquer para desabilitar o preenchimento automático do navegador autocomplete="off" não funciona no react
                  autoComplete="disabled"
                />
              </Col>

              <Col sm={6}>
                <Form.Label>Estado</Form.Label>
                <Dropdown
                  placeholder="Estado"
                  fluid
                  search
                  selection
                  options={this.state.estados}
                  name="estado"
                  id="estado"
                  value={this.state.estado}
                  onChange={this.alteracao}
                  disabled
                />
              </Col>

              <Col sm={6}>
                <Form.Label>Cidade</Form.Label>
                <Dropdown
                  placeholder="Cidade"
                  fluid
                  search
                  selection
                  options={this.state.cidades}
                  name="cidade"
                  id="cidade"
                  value={this.state.cidade}
                  onChange={this.alteracao}
                  disabled
                />
              </Col>

              <Col sm={6}>
                <Form.Label>Telefone</Form.Label>
                <InputMask
                  mask="(99) 9999-9999"
                  className="form-control"
                  placeholder="Telefone"
                  name="telefone"
                  onChange={this.alteracao}
                  value={this.state.telefone}
                  //Usar uma string qualquer para desabilitar o preenchimento automático do navegador autocomplete="off" não funciona no react
                  autoComplete="disabled"
                />
              </Col>

              <Col sm={6}>
                <Form.Label>Celular</Form.Label>
                <InputMask
                  mask="(99) 9 9999-9999"
                  className="form-control"
                  placeholder="Celular"
                  name="celular"
                  onChange={this.alteracao}
                  value={this.state.celular}
                  //Usar uma string qualquer para desabilitar o preenchimento automático do navegador autocomplete="off" não funciona no react
                  autoComplete="disabled"
                />
              </Col>
              <Col sm={6}>
                <Form.Label>E-mail</Form.Label>
                <FormControl
                  placeholder="E-mail"
                  aria-label="Email"
                  aria-describedby="basic-addon1"
                  name="email"
                  onChange={this.alteracao}
                  defaultValue={this.state.email}
                  //Usar uma string qualquer para desabilitar o preenchimento automático do navegador autocomplete="off" não funciona no react
                  autoComplete="disabled"
                />
              </Col>

              <Col sm={5}>
                <Form.Label>Conta Contábil</Form.Label>
                <Select
                  placeholder="Digite para buscar"
                  name="conta_contabil"
                  value={this.state.conta_contabil}
                  onChange={this.alteracaoSelectContaContabil}
                  options={this.state.optionsCC}
                  onKeyDown={this.pesquisaContaContabil}
                  inputId="pesquisaContaContabil"
                  required
                  style={{ zIndex: 2 }}
                />
              </Col>
              <Col
                sm={1}
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                  paddingBottom: 2,
                }}
              >
                <ModalContaContabil />
              </Col>
              <Col sm={6} hidden={this.state.tipoPessoa != "juridico"}>
                <Form.Label>Inscrição Estadual</Form.Label>
                <FormField
                  placeholder="Inscrição Estadual"
                  name="inscricao_estadual"
                  control={Input}
                  onChange={this.alteracao}
                  defaultValue={this.state.inscricao_estadual}
                  fluid
                  //Usar uma string qualquer para desabilitar o preenchimento automático do navegador autocomplete="off" não funciona no react
                  autoComplete="disabled"
                />
              </Col>
              <Col sm={6} hidden={this.state.tipoPessoa != "juridico"}>
                <Form.Label>Inscrição Municipal</Form.Label>
                <FormField
                  placeholder="Inscrição Municipal"
                  name="inscricao_municipal"
                  control={Input}
                  onChange={this.alteracao}
                  defaultValue={this.state.inscricao_municipal}
                  fluid
                  //Usar uma string qualquer para desabilitar o preenchimento automático do navegador autocomplete="off" não funciona no react
                  autoComplete="disabled"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Label>Banco</Form.Label>
                <FormControl
                  placeholder="Banco"
                  onChange={this.alteracao}
                  name="banco"
                  defaultValue={this.state.banco}
                  //Usar uma string qualquer para desabilitar o preenchimento automático do navegador autocomplete="off" não funciona no react
                  autoComplete="disabled"
                />
              </Col>
              <Col sm={6}>
                <Form.Label>Agência</Form.Label>
                <FormControl
                  placeholder="Agência"
                  onChange={this.alteracao}
                  name="agencia"
                  defaultValue={this.state.agencia}
                  //Usar uma string qualquer para desabilitar o preenchimento automático do navegador autocomplete="off" não funciona no react
                  autoComplete="disabled"
                />
              </Col>
              <Col sm={3}>
                <Form.Label>Conta</Form.Label>
                <FormControl
                  placeholder="Conta"
                  aria-label="Conta"
                  aria-describedby="basic-addon1"
                  onChange={this.alteracao}
                  name="conta"
                  value={this.state.conta}
                  // as="input"
                  type="number"
                  min="0"
                  step="1"
                  //Usar uma string qualquer para desabilitar o preenchimento automático do navegador autocomplete="off" não funciona no react
                  autoComplete="disabled"
                />
              </Col>
              <Col sm={3}>
                <Form.Label>PIX</Form.Label>
                <FormControl
                  placeholder="Pix"
                  onChange={this.alteracao}
                  name="pix"
                  defaultValue={this.state.pix}
                  //Usar uma string qualquer para desabilitar o preenchimento automático do navegador autocomplete="off" não funciona no react
                  autoComplete="disabled"
                />
              </Col>
            </Row>
          </Modal.Content>
          <Modal.Actions clearing>
            <Row>
              <Col sm={8}>
                <Card.Group>
                  <Card>
                    {this.state.id != null &&
                      this.state.historico_fornecedor != 0 && (
                        <Card.Content textAlign="left">
                          <Button
                            floated="right"
                            size="mini"
                            icon="list"
                            color="olive"
                            onClick={this.handleShow2}
                            type="button"
                          />
                          <Card.Meta style={{ color: "black" }}>
                            {this.state.historico_fornecedor <= 0
                              ? ""
                              : "Usuário: " +
                                this.state.historico_fornecedor[
                                  this.state.historico_fornecedor.length - 1
                                ].usuario.nome}
                          </Card.Meta>
                          <Card.Description style={{ color: "black" }}>
                            {this.state.historico_fornecedor == null
                              ? ""
                              : this.state.historico_fornecedor[
                                  this.state.historico_fornecedor.length - 1
                                ].usuario.entidade +
                                ": " +
                                format(
                                  parseISO(
                                    this.state.historico_fornecedor[
                                      this.state.historico_fornecedor.length - 1
                                    ].data
                                  ),
                                  "dd/MM/yyyy"
                                ) +
                                " " +
                                this.state.historico_fornecedor[
                                  this.state.historico_fornecedor.length - 1
                                ].hora +
                                " hs"}
                          </Card.Description>
                        </Card.Content>
                      )}
                  </Card>
                </Card.Group>
              </Col>
              <Col sm={4}>
                <Button onClick={this.handleClose} animated="vertical">
                  <Button.Content hidden>
                    <Icon name="cancel" />
                  </Button.Content>
                  <Button.Content visible>Cancelar</Button.Content>
                </Button>
                <Button
                  positive
                  onClick={this.handleSubmit}
                  loading={this.state.salvando}
                  disabled={this.state.salvando}
                  animated
                >
                  <Button.Content hidden>
                    <Icon name="save" />
                  </Button.Content>
                  <Button.Content visible>Salvar</Button.Content>
                </Button>
              </Col>
            </Row>
          </Modal.Actions>
          <Modal
            dimmer={"blurring"}
            open={this.state.openSecond}
            onClose={this.handleClose2}
            size="small"
          >
            <Modal.Header>Histórico</Modal.Header>
            <Modal.Content>
              <List>
                {this.state.id != null &&
                  this.state.historico_fornecedor != null &&
                  this.state.historico_fornecedor.map((e, i) => (
                    <List.Item>
                      <List.Icon
                        name="file alternate"
                        size="large"
                        verticalAlign="middle"
                        color="olive"
                      />
                      <List.Content>
                        <List.Header as="a">
                          Usuário: {e.usuario.nome}
                        </List.Header>
                        <List.Description>
                          Data: {format(parseISO(e.data), "dd/MM/yyyy")}
                        </List.Description>
                        <List.Description>Hora: {e.hora}</List.Description>
                        <List.Description>Ação: {e.acao}</List.Description>
                      </List.Content>
                    </List.Item>
                  ))}
              </List>
            </Modal.Content>
            <Modal.Actions clearing>
              <Button onClick={this.handleClose}>Fechar</Button>
            </Modal.Actions>
          </Modal>
        </Modal>
      </div>
    );
  }
}
