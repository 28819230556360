import React, { Component } from "react";
import { Header, Modal, Segment, Divider } from "semantic-ui-react";
import { Col, Row, Button, Form, FormControl } from "react-bootstrap";
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";
import renomearChave from "../../../services/renomearChave";
import Select from "react-select";
import GrauParentesco from "../../../enum/GrauParentesco";
import Acao from "../../../enum/Acao";

export default class ModalDependente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pessoa: null,
      options: [],
      login: null,
      senha: null,
      grauParentesto: GrauParentesco,
      grau_parentesco: null,
      infoPessoa: null,
      motivo: null,
    };
  }

  adicionaDependente = async () => {
    console.log("1");
    await api.post(
      "/pessoa/alterar" +
        requisicoes.entidade +
        "&id=" +
        this.state.pessoa.value,
      {
        grau_parentesco: this.state.grau_parentesco,
        titular_id: this.props.titular_id,
        acao_id: this.props.acao_id
      },
      requisicoes.header
    );
    console.log("2");
    await api.post(
      "/historicoDependente/salvar" + requisicoes.entidade,
      {
        acao: Acao.find((e) => e.value === "adicionar").value,
        motivo: this.state.motivo,
        dependente_id: this.state.infoPessoa.id,
        titular_id: this.props.titular_id,
        acao_id: this.props.titular_id
      },
      requisicoes.header
    );
    console.log("3");
    if (this.state.login !== null && this.state.senha !== null) {
      const dados = {
        login: this.state.login,
        senha: this.state.senha,
        entidade: window.localStorage.getItem("e_clube"),
        tipo_usuario: "dependente",
        pessoa_id: this.state.pessoa.value,
        nome: this.state.pessoa.label,
      };
      await api.post(
        "/usuario/salvar" + requisicoes.entidade,
        dados,
        requisicoes.header
      );
    }
    console.log("4");
    this.props.adicionaDependentes({
      pessoa: this.state.pessoa,
      login: this.state.login,
      grau_parentesco: this.state.grau_parentesco,
    });
    console.log("5");
    this.props.handleCloseDependentes();
  };

  alteracaoSelectPessoa = async (event) => {
    this.setState({ pessoa: event });
    const resposta = await api.post(
      "/pessoa/visualiza" + requisicoes.entidade,
      { id: event.value },
      requisicoes.header
    );
    this.setState({ infoPessoa: resposta.data });
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  cb = function (dados) {
    return new Promise((resolve) => {
      let lista = [];
      dados.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista.push(objeto);
      });
      resolve(lista);
    });
  };

  pesquisaPessoa = async (event) => {
    const retorno = await api.post(
      "/pessoa/pesquisaPessoaSemTitular" + requisicoes.entidade,
      { pesquisa: event.target.value },
      requisicoes.header
    );
    this.cb(retorno.data).then((r) => {
      this.setState({ options: r });
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Modal
          open={this.props.open_modal_dependentes}
          onClose={this.props.handleCloseDependentes}
          centered={false}
        >
          <Header content="Dependente" />
          <Modal.Content>
            <Row>
              <Col sm={6}>
                <Form.Label>Titulo</Form.Label>
                <FormControl
                  placeholder="Titulo"
                  aria-label="Titulo"
                  aria-describedby="basic-addon1"
                  name="titulo"
                  onChange={this.alteracao}
                  value={this.props.numero_titulo}
                  disabled
                  required
                />
              </Col>
              <Col sm={6}>
                <Form.Label>Pessoa</Form.Label>
                <Select
                  placeholder="Digite para buscar"
                  name="pessoa"
                  onChange={this.alteracaoSelectPessoa}
                  options={this.state.options}
                  onKeyDown={this.pesquisaPessoa}
                  inputId="pesquisaPessoa"
                  required
                />
              </Col>

              {this.state.infoPessoa !== null && (
                <Col sm={12} style={{ paddingTop: "10px" }}>
                  <Header as="h2">Dados Cadastrais</Header>
                  <Divider />
                  <Segment.Group>
                    <Segment.Group horizontal>
                      <Segment>
                        <b>Tipo:</b> {this.state.infoPessoa.tipo}
                      </Segment>
                      <Segment>
                        <b>CPF/CNPJ:</b>{" "}
                        {this.state.infoPessoa.cpf === null
                          ? this.state.infoPessoa.cnpj
                          : this.state.infoPessoa.cpf}
                      </Segment>
                      <Segment>
                        <b>E-mail:</b> {this.state.infoPessoa.email}
                      </Segment>
                    </Segment.Group>
                    <Segment>
                      <b>Endereço:</b> {this.state.infoPessoa.endereco} -{" "}
                      {this.state.infoPessoa.bairro},{" "}
                      {this.state.infoPessoa.cep} {this.state.infoPessoa.cidade}
                      -{this.state.infoPessoa.estado}
                    </Segment>
                  </Segment.Group>
                </Col>
              )}

              <Col sm={6}>
                <Form.Label>Login</Form.Label>
                <FormControl
                  placeholder="Login"
                  aria-label="Login"
                  aria-describedby="basic-addon1"
                  name="login"
                  onChange={this.alteracao}
                  required
                />
              </Col>
              <Col sm={6}>
                <Form.Label>Senha</Form.Label>
                <FormControl
                  placeholder="Senha"
                  aria-label="Senha"
                  type="password"
                  aria-describedby="basic-addon1"
                  name="senha"
                  onChange={this.alteracao}
                  required
                />
              </Col>

              <Col sm={6}>
                <Form.Label>Grau de Parentesco</Form.Label>
                <Form.Control
                  as="select"
                  name="grau_parentesco"
                  onChange={this.alteracao}
                  required
                >
                  <option></option>
                  {this.state.grauParentesto.map((e, i) => (
                    <option key={i} value={e.value}>
                      {e.label}
                    </option>
                  ))}
                </Form.Control>
              </Col>
              <Col sm={12} style={{ marginTop: "10px" }}>
                <FormControl
                  placeholder="Motivo"
                  aria-describedby="basic-addon1"
                  as="textarea"
                  name="motivo"
                  onChange={this.alteracao}
                  required
                />
              </Col>
            </Row>
          </Modal.Content>

          <Header floated="right">
            <Row>
              <Col sm={5}>
                <Button
                  type="button"
                  variant="light"
                  onClick={this.props.handleCloseDependentes}
                >
                  Voltar
                </Button>
              </Col>
              <Col sm={5}>
                <Button variant="success" onClick={this.adicionaDependente}>
                  Adicionar
                </Button>
              </Col>
            </Row>
          </Header>
        </Modal>
      </Form>
    );
  }
}
