import React, { Component } from "react";
import { Form as formulario, FormControl, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Container,
  Header,
  Button,
  Divider,
  Segment,
  Dimmer,
  Loader,
  Table,
  Icon,
  Modal,
  Form,
  Tab,
  Input,
  List,
  Card,
} from "semantic-ui-react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Snackbar, Alert, TextField } from "@material-ui/core/";

import { parseISO, format } from "date-fns";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import renomearChave from "../../../services/renomearChave";
import LoadingService from "../../../services/LoadingService";
import requisicoes from "../../../utils/Requisicoes";
import converterParaLabel from "../../../utils/converteParaLabel";
import converterParaLabelComAcao from "../../../utils/converteParaLabelComAcao";
import moment from "moment-timezone";
import { validaCpf } from "../../../utils/validadorCamposPessoa";
import api from "../../../services/api";

//modal
import ModalAlteraDias from "./modalAlteraDias";
import ModalReservar from "./modalReservar";
import ModalCancelar from "./modalCancelar";
import ModalCapacidade from "./modalCapacidade";
import ModalRestricaoDias from "./modalRestricaoDias";
import ModalReservaEfetivacao from "./modalReservaEfetivacao";
import ModalErroHorarioReservado from "./modalErroHorarioReservado";
import ModalRestricaoDiasGrupo from "./modalRestricaoDiasGrupo";
import ModalLerExcel from "../reservas/reservas/modalLerExcel";
// import ParametrosReservas from '../reservas/'

export default class ReservaServico extends Component {
  constructor(props) {
    super(props);
    LoadingService.load((v) => this.setState({ isLoading: true }));
    this.state = {
      reservaEfetivada: {},
      servico: {},
      listaServico: [],
      listaPresenca: [],
      horarios: [],
      options: [],
      listaFornecedor: [],
      listaBusca: [],
      listaBuscaGrupo: [],
      historico_dias_cancelamento: [],
      historico_reserva: [],
      pessoaReservado: [],
      motivo: "",
      dataReserva: "",
      diasCancelamento: "",
      eventoNovo: "",
      diasCancelamentoNovo: "",
      observacaoNovo: "",
      nomeSocio: "",
      valorEfetivacao: "",
      pessoa: null,
      infoPessoa: null,
      pessoa_id: null,
      reserva_id: null,
      reserva: null,
      termos: null,
      evento: null,
      observacao: null,
      reserva_efetivada_id: null,
      reserva_efetivada_dia_id: null,
      dataCancelamento: null,
      valor_reserva_efetivada: null,
      responsavel_id: null,
      servicoTransferenciaLugar: null,
      isLoading: false,
      reservadoPelaPessoa: false,
      liberaReservaBotao: false,
      modalRestricaoDias: false,
      reservaEfetivacao: false,
      reservaEvento: false,
      aceitaTermos: false,
      parametroEvento: false,
      loadingReservaBotao: false,
      botaoTransferenciaDataHorario: false,
      botaoTransferenciaServicoDataHorario: false,
      salvando: false,
      open: false,
      dividaSocio: false,
      reservaUtilizada: false,
      loading: false,
      confirmouReserva: false,
      capacidade: 10,
      valorNovoServico: 0,
      parametroExigeCPFConvidado: false,
      diasBloquados: 0,
      diferencaDias: 0,
      dataBusca: moment().format("YYYY-MM-DD"),
      dataBuscaServico: moment().format("YYYY-MM-DD"),
      dataVencimentoEfetivacao: moment().format("YYYY-MM-DD"),

      // modal
      modalReservaSaloes: false,
      openModalConfirmacaoTransferencia: false,
      modalOpenCancelar: false,
      modalAlteraData: false,
      modalOpenEfetiva: false,
      modalAlteraDiasOpen: false,
      modalReservaEfetivacao: false,
      modalReservaOpen: false,
      modalCapacidadeOpen: false,
      modalErroHorarioReservado: false,
      open_divida: false,
      open_fechamento_divida: false,
      reservada: false,
      mensagemSnackBar: { show: false },
    };
    this.alteracaoSelectPessoa = this.alteracaoSelectPessoa.bind(this);
    this.visualiza();
  }
  buscaParametroListaConvidados = async () => {
    const exige_cpf_convidado = await api.post(
      "/parametros/visualiza" + requisicoes.entidade,
      { nome: "exige_cpf_convidado" },
      requisicoes.header
    );
    if (
      exige_cpf_convidado !== null &&
      exige_cpf_convidado !== undefined &&
      exige_cpf_convidado.data[0] !== null
    ) {
      this.setState({
        parametroExigeCPFConvidado:
          exige_cpf_convidado.data[0].valor === "true",
      });
    }
  };

  historicoReserva = async () => {
    const pessoa_id = this.props.location.query.pessoa.id;
    // if (pessoa_id) {
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    const visualiza = await api.post(
      "/historicoReserva/visualiza" + requisicoes.entidade,
      { pessoa_id: this.state.pessoa_id },
      requisicoes.header
    );
    let lista = [];
    let anoAtual = moment().format("YYYY");
    let mesAtual = moment().format("MM");

    if (visualiza.data.length > 0) {
      for (let i = 0; i < visualiza.data.length; i++) {
        const element = visualiza.data[i];
        if (
          anoAtual === moment(element.data).format("YYYY") &&
          mesAtual === moment(element.data).format("MM")
        ) {
          if (pessoa_id === element.pessoa_id) {
            lista.push({
              usuario_nome: usuarioLogado.data.nome,
              acao: element.acao_feita,
              valor_anterior: element.valor_anterior,
              valor_atual: element.valor_atual,
              data: element.data,
              hora: element.hora,
              servico_nome: element.servico_nome,
            });
          }
        }
      }
    }

    this.setState({
      historico_reserva: lista,
    });
    // }
  };

  historicoDiasCancelamento = async () => {
    const pessoa_id = this.props.location.query.pessoa.id;
    // this.historicoReserva();
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    const respostaReservaEvento = await api.post(
      "/historicoDiasCancelamento/visualiza" + requisicoes.entidade,

      {},
      requisicoes.header
    );

    let lista = [];
    let anoAtual = moment().format("YYYY");
    let mesAtual = moment().format("MM");

    for (let i = 0; i < respostaReservaEvento.data.length; i++) {
      const element = respostaReservaEvento.data[i];
      if (
        anoAtual === moment(element.data).format("YYYY") &&
        mesAtual === moment(element.data).format("MM")
      ) {
        if (pessoa_id === element.pessoa_id) {
          lista.push({
            usuario_nome: usuarioLogado.data.nome,
            acao: element.acao_feita,
            valor_anterior: element.valor_anterior,
            valor_atual: element.valor_atual,
            data: element.data,
            hora: element.hora,
            servico_nome: element.servico_nome,
          });
        }
      }
    }

    this.setState({
      historico_dias_cancelamento: lista,
    });
  };

  abreModal = async (e, horario_servico_id) => {
    this.setState({
      modalOpenCancelar: e,
      horario_servico_id: horario_servico_id,
    });
  };

  abreModalEfetiva = async (e, valor) => {
    this.setState({ modalOpenEfetiva: e });
    this.setState({ valorEfetivacao: valor });
  };

  abreModalReserva = async (e, horario_servico_id) => {
    this.setState({
      modalReservaOpen: e,
      horario_servico_id: horario_servico_id,
      aceitaTermos: false,
    });
  };

  abreModalAlteraDias = async (e) => {
    this.setState({ modalAlteraDiasOpen: e });
  };
  abreModalAlteraData = async (e) => {
    this.setState({ modalAlteraData: e });
  };

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === "evento") {
      this.confirmaTermos(this.state.aceitaTermos);
    }
  };

  alteracaoSelectPessoa = async (event, v) => {
    if (v !== null) {
      this.setState({ pessoa_id: v.value });
      this.setState({ pessoa: v, salvando: false });
      const resposta = await api.post(
        "/pessoa/visualiza" + requisicoes.entidade,
        { id: v.value },
        requisicoes.header
      );
      const buscaHorarioPorPessoa = await api.post(
        "/reservaBloqueio/buscarReservaBloqueadaPorPessoa" + requisicoes.entidade,
        { pessoa_id: this.state.pessoa_id },
        requisicoes.header
      )
      this.setState({
        confirmouReserva: buscaHorarioPorPessoa.data[0]?.bloqueado,
        diferencaDias: buscaHorarioPorPessoa.data[0]?.diferencaDias,
        diasBloquados: buscaHorarioPorPessoa.data[0]?.diasBloqueado
      })
      this.setState({ infoPessoa: resposta.data });
      setTimeout(async () => {
        this.buscaReservas(this.state.dataReserva);
      }, 500);
    }
  };

  pesquisaPessoa = async () => {
    let term = document.getElementById("pesquisaPessoa").value;

    setTimeout(async () => {
      if (isFinite(String(term))) {
        await api
          .post(
            "/pessoa/pesquisaPorCodigoAcaoValorExato" + requisicoes.entidade,
            { pesquisa: document.getElementById("pesquisaPessoa").value },
            requisicoes.header
          )
          .then((retorno) => {
            this.setState({
              options: converterParaLabelComAcao(retorno.data),
            });
          });
      } else {
        await api
          .post(
            "/pessoa/pesquisaNome" + requisicoes.entidade,
            { pesquisa: document.getElementById("pesquisaPessoa").value },
            requisicoes.header
          )
          .then((retorno) => {
            for (let i = 0; i < retorno.data.length; i++) {
              const element = retorno.data[i];
              this.state.nomeSocio = element.nome;
            }
            this.state.nomeSocio = retorno.data[0]?.nome;
            converterParaLabel(retorno.data);
            this.setState({ options: converterParaLabel(retorno.data) });
          });
        this.historicoReserva();
      }
    }, 200);
  };

  visualiza = async () => {
    const resposta = await api.post(
      "/servico/visualiza" +
      requisicoes.entidade +
      "&id=" +
      this.props.location.query?.id,
      "",
      requisicoes.header
    );

    this.setState({
      servico: resposta.data,
      capacidade: resposta.data.capacidade,
    });


    const respostaReservaEfetivacao = await api.post(
      "/parametrosReservaEfetivada/visualizaPorServico" + requisicoes.entidade,
      { id: this.props.location.query.id },
      requisicoes.header
    );

    if (respostaReservaEfetivacao.data !== "") {
      const respostaReservaEfetivada = await api.post(
        "/reservaEfetivada/reservaPorReservaHorario" + requisicoes.entidade,
        { id: this.props.location.query.id },
        requisicoes.header
      );
      this.setState({
        reservaEfetivada: respostaReservaEfetivada.data,
        reservaEfetivacao: true,
        diasCancelamento: respostaReservaEfetivacao.data.diasCancelamento,
        capacidade: respostaReservaEfetivacao.data.capacidade,
      });
    } else {
      this.setState({ reservaEfetivacao: false });
    }

    const respostaReservaEvento = await api.post(
      "/parametrosReservaEvento/visualizaPorServico" + requisicoes.entidade,
      { id: this.props.location.query.id },
      requisicoes.header
    );
    if (respostaReservaEvento.data !== "") {
      this.setState({
        reservaEvento: true,
      });
    }

    const respostaReservaTermos = await api.post(
      "/parametrosReservaTermos/visualizaPorServico" + requisicoes.entidade,
      { id: this.props.location.query.id },
      requisicoes.header
    );
    this.setState({
      termos: respostaReservaTermos.data,
    });

    this.buscaParametroListaConvidados();

    //Busca Matricula do Usuario
    const matricula = await api.post(
      "/usuario/matricula" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    this.setState(matricula.data);
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    this.setState({ responsavel_id: usuarioLogado.data.pessoa_id });
    const user = usuarioLogado.data;

    if (
      this.props.location.query.pessoa.id === null &&
      usuarioLogado.data.tipo_usuario !== "administrador" &&
      usuarioLogado.data.tipo_usuario !== "caixa" &&
      usuarioLogado.data.tipo_usuario !== "administrativo"
    ) {
      const respPessoa = await api.post(
        "/pessoa/pessoaIdNome" + requisicoes.entidade,
        { id: user.pessoa_id },
        requisicoes.header
      );

      let p = renomearChave(respPessoa.data, "id", "value");
      p = renomearChave(p, "nome", "label");
      this.setState({ pessoa: p, pessoa_id: user.pessoa_id });
    } else if (this.props.location.query.pessoa.id !== null) {
      const respPessoa = await api.post(
        "/pessoa/pessoaIdNome" + requisicoes.entidade,
        { id: this.props.location.query.pessoa.id },
        requisicoes.header
      );
      let p = renomearChave(respPessoa.data, "id", "value");
      p = renomearChave(p, "nome", "label");
      this.setState({
        pessoa: p,
        pessoa_id: this.props.location.query.pessoa.id,
      });
    }

    const horarios = await api.post(
      "/horarioServico/horarioPorServico" + requisicoes.entidade,
      { id: resposta.data.id },
      requisicoes.header
    );

    this.setState({ horarios: horarios.data });
    if (this.props.location.query.dataReserva !== undefined) {
      this.setState({ dataReserva: this.props.location.query.dataReserva });
      this.buscaReservas(this.state.dataReserva);
    }
    this.historicoDiasCancelamento();
    this.historicoReserva();

    const buscaHorarioPorPessoa = await api.post(
      "/reservaBloqueio/buscarReservaBloqueadaPorPessoa" + requisicoes.entidade,
      { pessoa_id: this.state.pessoa_id },
      requisicoes.header
    )
    this.setState({
      confirmouReserva: buscaHorarioPorPessoa.data[0]?.bloqueado,
      diferencaDias: buscaHorarioPorPessoa.data[0]?.diferencaDias,
      diasBloquados: buscaHorarioPorPessoa.data[0]?.diasBloqueado
    })

    // const presencaReserva = await api.post(
    //   "/presencaReserva/buscaPresencaReserva" + requisicoes.entidade,
    //   {},
    //   requisicoes.header
    // )
  };

  confereReserva = async () => {
    const reserva = await api.post(
      "/reservaHorario/pesquisaReservaDisponivel" + requisicoes.entidade,
      {
        horario_servico_id: this.state.horario_servico_id,
        dataReserva: this.state.dataReserva,
        servico_id: this.props.location.query.id,
        pessoa_id: this.state.pessoa_id,
        evento: this.state.evento,
        observacao: this.state.observacao,
        plataforma: "web",
        pessoa_responsavel_reserva: this.state.responsavel_id,
        dividaSocio: this.state.dividaSocio,
      },
      requisicoes.header
    );
    if (reserva.data.length > 0) {
      this.state.reserva = true;
      this.setState({ reservada: true });
      this.mensagem(false, "Churrasqueira ja reservada.", 3500);
    } else {
      this.mensagem(true, "Churrasqueira Reservada.", 3500);
    }
  };

  mensagem = (positive, content, duration) => {
    this.setState({
      mensagemSnackBar: {
        show: true,
        positive: positive,
        content: content,
        duration: duration || 4000,
      },
    });
  };

  confirmaReserva = async (element) => {
    //para poder ativar o loading do modal alteraDias saloes
    this.setState({
      liberaReservaBotao: true,
    });
    //---------------------------------
    const pesquisa = await api.post(
      "/reservaHorario/verificaDivida" + requisicoes.entidade,
      {
        pessoa_id: this.state.pessoa_id,
      },
      requisicoes.header
    );
    // churrasqueira e salões
    await this.confereReserva();
    this.state.modalReservaSaloes = element;
    let difference = 0;
    for (let i = 0; i < pesquisa.data.length; i++) {
      const element = pesquisa.data[i];
      if (
        element.descricao.substring(0, 11) === "Mensalidade" &&
        difference === 0
      ) {
        let data1 = new Date(pesquisa.data[0].data_vencimento);
        let data2 = new Date(moment().format("YYYY-MM-DD"));
        difference = moment(data2, "YYYY-MM-DD").diff(
          moment(data1, "YYYY-MM-DD"),
          "days"
        );
      }
    }
    setTimeout(async () => {
      if (difference > 60) {
        this.state.dividaSocio = true;
        this.handleShowAbertoCaixa();
      } else {
        if (element === true) {
          await this.reservaEfetivada();
        } else if (this.state.reservada !== true) {
          await this.reservar();
        }
        this.addTotalCapacidade("confirmaReserva");
      }
    }, 800);
  };

  reservar = async (e) => {
    this.setState({ loadingReservaBotao: true });
    try {
      // Pesquisa restricao de nao poder reservar menos de 30 dias
      const restricaoReserva30Dias = await api.post(
        "/reservaHorario/restricaoReserva30Dias" + requisicoes.entidade,
        {
          pessoa_id: this.state.pessoa_id,
          servico_id: this.props.location.query.id,
          data: this.state.dataReserva,
          dividaSocio: this.state.dividaSocio,
        },
        requisicoes.header
      );
      const restricao = restricaoReserva30Dias.data;
      //consulta paramentro reserva
      const restricaoGrupoServico = await api.post(
        "parametrosGrupoReserva/verificaParametroReserva" +
        requisicoes.entidade,
        {
          pessoa_id: this.state.pessoa_id,
          servico_id: this.props.location.query.id,
          data: this.state.dataReserva,
          dividaSocio: this.state.dividaSocio,
        },
        requisicoes.header
      );

      if (restricao.length !== 0) {
        this.setState({ modalRestricaoDias: true });
        setTimeout(async () => {
          this.setState({ modalRestricaoDias: false });
        }, 2500);
      } else if (restricaoGrupoServico.data.reservas.length !== 0) {
        this.setState({
          modalRestricaoDiasGrupo: true,
          restricaoDiasGrupo: restricaoGrupoServico.data,
        });
      } else {
        const reservaBloqueio = await api.post(
          "/reservaBloqueio/buscarReservaBloqueadaPorPessoa" + requisicoes.entidade,
          { pessoa_id: this.state.pessoa_id },
          requisicoes.header
        )
        let listaBloquada = []
        for (let i = 0; i < reservaBloqueio.data.length; i++) {
          const element = reservaBloqueio.data[i];
          listaBloquada.push(element)
        }

        const reserva = await api.post(
          "/reservaHorario/reservar" + requisicoes.entidade,
          {
            horario_servico_id: this.state.horario_servico_id,
            dataReserva: this.state.dataReserva,
            servico_id: this.props.location.query.id,
            pessoa_id: this.state.pessoa_id,
            evento: this.state.evento,
            observacao: this.state.observacao,
            plataforma: "web",
            pessoa_responsavel_reserva: this.state.responsavel_id,
            dividaSocio: this.state.dividaSocio,
            reservaBloqueio: listaBloquada
          },
          requisicoes.header
        );
        this.state.listaPresenca[0].reserva_horario_id = reserva.data.id
        this.state.listaPresenca[0].dataReserva = this.state.dataReserva
        this.state.listaPresenca[0].pessoa_id = this.state.pessoa_id
        await api.post(
          "/reservaBloqueio/salvaBloqueio" + requisicoes.entidade,
          {
            listaPresenca: this.state.listaPresenca,
          },
          requisicoes.header
        );
        this.setState({
          eventoNovo: this.state.evento,
          observacaoNovo: this.state.observacao,
          reserva_id: reserva.data.id,
        });
      }

      this.setState({ pessoaReservado: this.state.pessoa });
      await this.historicoDiasCancelamento(this.state.pessoa);
      await this.buscaReservas(this.state.dataReserva);
      this.setState({
        loadingReservaBotao: false,
        modalReservaOpen: false,
      });
      this.handleCloseAbertoCaixa();
    } catch (error) {
      if (error.response && error.response.data && error.response.data !== "") {
        if (error.response.data === "Já foi reservada") {
          this.setState({ modalErroHorarioReservado: true });
        } else {
          alert(error.response.data);
          this.setState({
            loadingReservaBotao: false,
            modalReservaOpen: false,
          });
        }
      } else {
        alert(
          "Ocorreu um erro inesperado ao reservar o horário. Tente novamente ou entre em contato com a administração do clube."
        );
        this.setState({
          loadingReservaBotao: false,
          modalReservaOpen: false,
        });
      }
    }
  };

  addSocioListaPresenca = async (convidado) => {
    try {
      if (!convidado) {
        return
      };
      let listaIniciada = this.state.listaPresenca;
      listaIniciada[0] = {
        id: "",
        nome: convidado.label ? convidado.label : convidado.nome,
        parentesco: "Socio",
        presente: false,
        reserva_horario_id: this.state.reserva_id,
        bloqueado: true,
        cpf: convidado.cpf,
        valido: validaCpf(convidado.cpf),
      };

      if (listaIniciada.length > 0) {
        listaIniciada = this.ordenaNomes(listaIniciada);
      }
      this.setState({
        listaPresenca: listaIniciada,
      });
    } catch (error) {
      console.log("reserva/addSocioListaPresenca: " + error);
    }
  };

  reservaEfetivada = async (e) => {
    this.setState({ modalAlteraDiasOpen: false, modalReservaOpen: false });

    const reserva = await api.post(
      "/reservaEfetivada/reservar" + requisicoes.entidade,
      {
        horario_servico_id: this.state.horario_servico_id,
        dataReserva: this.state.dataReserva,
        servico_id: this.props.location.query.id,
        pessoa_id: this.state.pessoa_id,
        evento: this.state.evento,
        observacao: this.state.observacao,
        plataforma: "web",
        pessoa_responsavel_reserva: this.state.responsavel_id,
      },
      requisicoes.header
    );
    this.setState({ eventoNovo: this.state.evento });
    this.setState({ observacaoNovo: this.state.observacao });
    this.setState({ reserva_id: reserva.data.reserva_horario_id });
    const parametrosReservaEfetivadaDia = await api.post(
      "/parametrosReservaEfetivadaDia/salvar" + requisicoes.entidade,
      {
        diasCancelamento: parseInt(this.state.diasCancelamento),
        reserva_efetivada_id: reserva.data.id,
      },
      requisicoes.header
    );
    this.setState({
      reserva_efetivada_dia_id: parametrosReservaEfetivadaDia.data.id,
    });
    this.setState({ diasCancelamentoNovo: this.state.diasCancelamento });
    this.setState({ diasCancelamento: this.state.diasCancelamento });
    this.setState({ modalReservaEfetivacao: true });
    setTimeout(async () => {
      this.setState({ modalReservaEfetivacao: false });
    }, 3000);

    setTimeout(() => {
      this.addTotalCapacidade("reservaEfetivada");
      //this.addSocioListaPresenca(this.state.pessoa);
    }, 1500);
    this.buscaReservas(this.state.dataReserva);
    await api.post(
      "/reservaHorario/geraComprovantePreReserva" + requisicoes.entidade,
      {
        pessoa_id: this.state.pessoa_id,
        servico_id: this.props.location.query.id,
        data: this.state.dataReserva,
        reserva_efetivada_id: reserva.data.id,
      },
      requisicoes.header
    );
    this.imprimirComprovantePreReserva();
    this.handleCloseAbertoCaixa();
  };

  imprimirComprovantePreReserva = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api.get(
      "/reservaHorario/buscaComprovantePreReserva?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&id=" +
      this.state.pessoa_id
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "reservaHorario/buscaComprovantePreReserva?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&id=" +
      this.state.pessoa_id;
    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = await new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  cancelar = async () => {
    if (
      !this.state.motivo ||
      this.state.motivo.trim() === "" ||
      this.state.motivo.trim().length < 10
    )
      return alert("Informe um motivo para o cancelamento");

    let response = await api.post(
      "/reservaHorario/cancelar" + requisicoes.entidade,
      {
        id: this.state.horario_servico_id,
        motivo: this.state.motivo,
        responsavel_id: this.state.responsavel_id,
        plataforma: "web",
      },
      requisicoes.header
    );
    if (response.data[0].presenca !== true)
      await api.post(
        "/reservaBloqueio/cancelaPresenca" + requisicoes.entidade,
        {
          id: this.state.horario_servico_id,
          pessoa_id: this.state.pessoa_id,
        },
        requisicoes.header
      );
    this.setState({ modalOpenCancelar: false });
    this.setState({ evento: null });
    this.setState({ observacao: null });
    this.buscaReservas(this.state.dataReserva);

    if (response.data[0].presenca === true) {
      this.setState({ reservaUtilizada: true });
      return alert("Não pode cancelar uma reserva já utilizada.");
    }
    if (response.data !== null) {
      window.location.reload();
    }
  };

  selecionaData = (e) => {
    let dataReserva =
      e.event.start.getFullYear() +
      "-" +
      ((e.event.start.getMonth() + 1).toString().length === 1
        ? "0" + (e.event.start.getMonth() + 1)
        : e.event.start.getMonth() + 1) +
      "-" +
      (e.event.start.getDate().toString().length === 1
        ? "0" + e.event.start.getDate()
        : e.event.start.getDate());
    this.setState({ dataReserva: dataReserva });
    this.buscaReservas(dataReserva);
  };

  buscaReservas = async (dataReserva) => {
    let pessoa_id = this.state.pessoa_id;
    const horarioServico = await api.post(
      "/horarioServico/horarioPorServico" + requisicoes.entidade,
      { id: this.state.servico.id },
      requisicoes.header
    );
    const horarios = horarioServico.data;

    const resposta = await api.post(
      "/reservaHorario/reservaPorDataAtiva" + requisicoes.entidade,
      {
        dataReserva: dataReserva,
      },
      requisicoes.header
    );

    const reservas = resposta.data;
    const listaRetorno = [];
    let temReserva = "";
    let vaga = "";
    this.setState({ reservadoPelaPessoa: false });
    horarios.forEach(async (horario) => {
      if (reservas.length > 0) {
        let reserva_id = "";
        const listaReservas = reservas.filter(
          (e) => parseInt(e.horario_servico_id) === parseInt(horario.id)
        );
        if (listaReservas.length > 0) {
          listaReservas.forEach(async (reserva) => {
            temReserva = true;
            vaga = parseInt(horario.vaga - listaReservas.length);
            if (parseInt(reserva.pessoa_id) === parseInt(pessoa_id)) {
              reserva_id = reserva.id;
              if (this.state.reservaEvento === true) {
                this.setState({
                  evento: reserva.evento,
                  eventoNovo: reserva.evento,
                  observacao: reserva.observacao,
                  observacaoNovo: reserva.observacao,
                });
              }
              this.setState({
                reserva: reserva,
                reserva_id: reserva.id,
                reservadoPelaPessoa: true,
              });
              if (this.state.reservaEfetivacao === true) {
                const respostaEfetivada = await api.post(
                  "/reservaEfetivada/buscaPorReservaHorario" +
                  requisicoes.entidade,
                  {
                    reserva_horario_id: reserva.id,
                  },
                  requisicoes.header
                );
                const resEfetivada = respostaEfetivada.data;
                this.setState({ reserva_efetivada_id: resEfetivada.id });
                const parametrosEfetivadaDia = await api.post(
                  "/parametrosReservaEfetivadaDia/visualizaPorReserva" +
                  requisicoes.entidade,
                  {
                    id: resEfetivada.id,
                  },
                  requisicoes.header
                );
                const parametroReservaDia = parametrosEfetivadaDia.data;
                const dataCancelamento = moment(
                  resEfetivada.dataLancamento
                ).add(parametroReservaDia.diasCancelamento, "days");

                this.setState({
                  reserva_efetivada_dia_id: parametroReservaDia.id,
                  diasCancelamento: parametroReservaDia.diasCancelamento,
                  dataCancelamento: dataCancelamento.format("DD/MM/YYYY"),
                  diasCancelamentoNovo: parametroReservaDia.diasCancelamento,
                });
                if (this.state.reservaEvento === true) {
                  this.setState({
                    observacaoNovo: resEfetivada.observacao,
                    eventoNovo: resEfetivada.evento,
                    observacao: resEfetivada.observacao,
                    evento: resEfetivada.evento,
                  });
                }
              }
            } else {
              if (this.state.reservadoPelaPessoa !== true) {
                this.setState({ reservadoPelaPessoa: false });
              }
            }
          });
        } else {
          if (
            listaReservas.find(
              (e) => parseInt(e.pessoa_id) === parseInt(pessoa_id)
            )
          ) {
            this.setState({ reservadoPelaPessoa: true });
          } else {
            if (this.state.reservadoPelaPessoa !== true) {
              this.setState({ reservadoPelaPessoa: false });
            }
          }
          temReserva = false;
          vaga = parseInt(horario.vaga);
        }
        listaRetorno.push({
          id: reserva_id,
          horario_servico_id: horario.id,
          horaInicio: horario.horaInicio,
          horaFim: horario.horaFim,
          temReserva: temReserva,
          vaga: vaga,
        });
      } else {
        listaRetorno.push({
          horario_servico_id: horario.id,
          horaInicio: horario.horaInicio,
          horaFim: horario.horaFim,
          temReserva: false,
          vaga: horario.vaga,
        });
        this.setState({ reservadoPelaPessoa: false });
      }
    });

    this.setState({ horarios: listaRetorno });

    this.carregaListaPresenca();
    this.carregaListaFornecedor();
  };
  checkCPFIguais = (cpfA, cpfB, ret) => {
    let cpfLista =
      cpfA !== null && cpfA !== undefined
        ? cpfA.toString().replaceAll(".", "").replaceAll("-", "").trim()
        : "";
    let cpfCompara =
      cpfB !== null && cpfB !== undefined
        ? cpfB.toString().replaceAll(".", "").replaceAll("-", "").trim()
        : "";
    if (cpfCompara.length >= 11 && cpfCompara === cpfLista) {
      return ret;
    } else {
      return undefined;
    }
  };
  alteracaoCPFListaPresenca = async (value, index) => {
    try {
      let listaPresenca = this.state.listaPresenca;
      var listaCompararCPF = listaPresenca.filter(
        (e) => e.nome !== listaPresenca[index].nome
      );
      var exists = listaCompararCPF.filter((e) =>
        this.checkCPFIguais(e.cpf, value, e)
      );

      if (exists && exists.length > 0) {
        listaPresenca[index].cpf = "";
        listaPresenca[index].valido = null;
        this.setState({ listaPresenca: listaPresenca });
        return alert("CPF já cadastrado para o convidado: " + exists[0].nome);
      } else {
        listaPresenca[index].cpf = value;
        listaPresenca[index].valido =
          value !== undefined && value !== null && value !== ""
            ? validaCpf(value)
            : null;
        this.setState({ listaPresenca: listaPresenca });
      }
    } catch (error) {
      console.log("alteracaoCPFListaPresenca/exception: " + error);
    }
  };
  alteracaoNomeListaPresenca = async (value, index) => {
    let listaPresenca = this.state.listaPresenca;
    listaPresenca[index].nome = value;
    this.setState({ listaPresenca: listaPresenca });
  };
  alteracaoParentescoListaPresenca = async (value, index) => {
    let listaPresenca = this.state.listaPresenca;
    listaPresenca[index].parentesco = value;
    this.setState({ listaPresenca: listaPresenca });
  };

  adicionaListaPresencaByExcel = async (lista) => {
    let listaPresenca = this.state.listaPresenca;
    this.addTotalCapacidade(lista.length);
    let espacos_preenchidos = listaPresenca.filter((p) => p.nome != null);
    let espacos_livres = this.state.capacidade - espacos_preenchidos;
    if (
      lista &&
      (lista.length > this.state.capacidade || lista.length > espacos_livres)
    ) {
      return alert(
        "Lista importada tem mais convidados do que a capacidade permite: " +
        lista.length +
        "/" +
        this.state.capacidade
      );
    }
    if (lista) {
      for (var e = 0; e < lista.length; e++) {
        var row = lista[e];
        for (var i = 0; i < listaPresenca.length; i++) {
          if (listaPresenca[i].nome === null || listaPresenca[i].nome === "") {
            // eslint-disable-next-line no-loop-func
            var exists = listaPresenca.filter((e) =>
              this.checkCPFIguais(e.cpf, row.cpf, e)
            );
            let rowCpf = exists && exists.length > 0 ? "" : row.cpf;
            listaPresenca[i] = {
              id: null,
              nome: row.nome,
              cpf: rowCpf,
              valido:
                rowCpf !== null && rowCpf !== undefined && rowCpf !== ""
                  ? validaCpf(rowCpf)
                  : null,
              parentesco: "Convidado",
              presente: false,
              reserva_horario_id: this.state.reserva_id,
              bloqueado: false,
            };
            break;
          }
        }
      }
      this.setState({ listaPresenca: listaPresenca });
    }
  };

  addTotalCapacidade = () => {
    try {
      let lista = this.state.listaPresenca;
      if (lista.length === 0) {
        this.addSocioListaPresenca(this.state.pessoa);
      }
      let maxAutoSize = this.state.capacidade;
      for (var i = lista.length; i < maxAutoSize; i++) {
        lista.push({
          id: null,
          nome: "",
          cpf: "",
          valido: null,
          parentesco: "Convidado",
          presente: false,
          reserva_horario_id: this.state.reserva_id,
          bloqueado: false,
        });
      }

      this.setState({
        listaPresenca: lista,
      });
    } catch (error) {
      console.error("reserva/addTotalCapacidade: " + error);
    }
  };

  addPresenca() {
    if (this.state.reserva.situacaoReserva !== "ativa") {
      // prereserva
      return alert("Sua reserva ainda não foi efetivada!");
    }

    let lista = this.state.listaPresenca;
    if (lista.length === 0) {
      this.addSocioListaPresenca(this.state.pessoa);
    }
    let size = lista.length;
    let capacidade = this.state.capacidade;
    let addQuantia = size + 15 < capacidade ? 15 : capacidade - size;

    if (addQuantia > 0 && size + addQuantia <= capacidade) {
      for (var i = 0; i < addQuantia; i++) {
        lista.push({
          id: null,
          nome: "",
          cpf: "",
          valido: null,
          parentesco: "Convidado",
          presente: false,
          reserva_horario_id: this.state.reserva_id,
          bloqueado: false,
        });
      }

      // if (lista.length > 0) {
      //   lista = this.ordenaNomes(listaPresencas);
      // }

      this.setState({ listaPresenca: lista });
    } else {
      this.setState({ modalCapacidadeOpen: true });
      setTimeout(async () => {
        this.setState({ modalCapacidadeOpen: false });
      }, 2500);
    }
  }

  removePresenca = async (presenca, i) => {
    let listaPresenca = this.state.listaPresenca;
    let listaRetorno = [];

    if (
      presenca.id !== null &&
      presenca.id !== undefined &&
      presenca.id !== ""
    ) {
      try {
        var localStorage = window.localStorage;
        var token = localStorage.getItem("token_clube");
        var e = localStorage.getItem("e_clube");
        await api
          .post(
            "/presencaReserva/excluir?e=" + e + "&id=" + presenca.id,
            {},
            { headers: { token: "Baer " + token } }
          )
          .then(() => {
            const set = new Set(listaPresenca);
            set.delete(presenca);
            set.forEach((element) => {
              listaRetorno.push(element);
            });
          });
      } catch (error) {
        console.log("removePresenca.Exception: " + error);
      }
    } else {
      listaPresenca[i].nome = "";
      listaPresenca[i].cpf = "";
      listaPresenca.splice(i, 1);
      listaRetorno = listaPresenca;
    }
    this.setState({ listaPresenca: listaRetorno });
  };

  alteracaoNomeListaFornecedor = async (value, index) => {
    let listaFornecedor = this.state.listaFornecedor;
    listaFornecedor[index].nome = value;
    this.setState({ listaFornecedor: listaFornecedor });
  };
  alteracaoDocumentoListaFornecedor = async (value, index) => {
    let listaFornecedor = this.state.listaFornecedor;
    listaFornecedor[index].documento = value;
    this.setState({ listaFornecedor: listaFornecedor });
  };
  alteracaoFuncaoListaFornecedor = async (value, index) => {
    let listaFornecedor = this.state.listaFornecedor;
    listaFornecedor[index].funcao = value;
    this.setState({ listaFornecedor: listaFornecedor });
  };

  addFornecedor() {
    if (this.state.reserva.situacaoReserva !== "ativa") {
      // prereserva
      return alert("Sua reserva ainda não foi efetivada!");
    }

    let listaFornecedor = this.state.listaFornecedor;
    listaFornecedor.push({
      id: null,
      nome: "",
      presente: false,
      reserva_horario_id: this.state.reserva_id,
      funcao: "",
      documento: "",
    });
    // if (listaFornecedor.length > 0) {
    //   listaFornecedor = this.ordenaNomes(listaPresencas);
    // }
    this.setState({ listaFornecedor: listaFornecedor });
  }

  removeFornecedor = async (fornecedor, i) => {
    let listaFornecedor = this.state.listaFornecedor;
    let listaRetorno = [];

    if (fornecedor.id !== "") {
      var localStorage = window.localStorage;
      var token = localStorage.getItem("token_clube");
      var e = localStorage.getItem("e_clube");
      await api.post(
        "/fornecedorReserva/excluir?e=" + e + "&id=" + fornecedor.id,
        {},
        { headers: { token: "Baer " + token } }
      );
      const set = new Set(listaFornecedor);
      set.delete(fornecedor);
      set.forEach((element) => {
        listaRetorno.push(element);
      });
    } else {
      listaRetorno = this.state.listaFornecedor;
      listaRetorno.splice(i, 1);
    }
    this.setState({ listaFornecedor: listaRetorno });
  };

  submit = async () => {
    this.setState({ salvando: true });
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    try {
      let covidados_sem_cpf = 0;
      const exige_cpf = this.state.parametroExigeCPFConvidado;
      let listaFiltrada = this.state.listaPresenca.filter(
        (p) => p.nome !== null && p.nome.trim() !== ""
      );
      let listaSalvar = listaFiltrada.filter(function (e) {
        if (
          (exige_cpf === true && e.valido !== true) ||
          (e.cpf !== null && e.cpf !== "" && e.valido !== true) ||
          e.nome === undefined ||
          e.nome === null ||
          e.nome.trim() === ""
        ) {
          covidados_sem_cpf += 1;
        }
        return e;
      });
      if (covidados_sem_cpf > 0 && exige_cpf === true) {
        this.setState({
          salvando: false,
        });
        return alert(
          (exige_cpf === true
            ? "É obrigatório informar um CPF válido para todos os convidados!"
            : "") + "\nExistem convidados com CPF inválido, por favor, corrija."
        );
      }
      if (
        listaSalvar !== undefined &&
        listaSalvar !== null &&
        listaSalvar.length > 0
      ) {
        await api.post(
          "/presencaReserva/salvar?e=" + entidade,
          {
            listaPresenca: listaSalvar,
          },
          {
            headers: { token: "Baer " + token },
          }
        );
      } else {
        this.mensagem(false, "Nenhum convidado foi salvo.", 3500);
      }
    } catch (error) {
      alert("Erro ao atualizar lista de convidados.");
      console.log("presencaReserva/submit: " + JSON.stringify(error));
      this.setState({ salvando: false });
      return;
    }

    const fornecedores = {
      listaFornecedor: this.state.listaFornecedor,
    };
    await api.post("/fornecedorReserva/salvar?e=" + entidade, fornecedores, {
      headers: { token: "Baer " + token },
    });

    if (this.state.reservaEfetivacao === true) {
      const reservaEfetivadaDia = {
        id: this.state.reserva_efetivada_dia_id,
        diasCancelamento: parseInt(this.state.diasCancelamentoNovo),
      };
      if (this.state.reserva_efetivada_dia_id) {
        await api.post(
          "/parametrosReservaEfetivadaDia/editar?e=" + entidade,
          reservaEfetivadaDia,
          {
            headers: { token: "Baer " + token },
          }
        );
      }
      if (this.state.reservaEvento === true) {
        const reservaEfetivada = {
          id: this.state.reserva_efetivada_id,
          evento: this.state.eventoNovo,
          observacao: this.state.observacaoNovo,
          reserva_horario_id: this.state.reserva_id,
        };
        await api.post(
          "/reservaEfetivada/editar?e=" + entidade,
          reservaEfetivada,
          {
            headers: { token: "Baer " + token },
          }
        );
      }

      this.setState({ salvando: false });
      if (
        this.props.permissoes.find(
          (e) => e.nome === "alterar_meus_dados_alheios"
        ) &&
        this.props.menus.find((e) => e.url === "/reservas") &&
        this.props.permissoes.find((e) => e.nome === "efetivar_reservas")
      ) {
        window.location.href =
          "/reservas?reserva_efetivada_id=" + this.state.reserva_efetivada_id;
      } else {
        window.location.href = "/reservas";
      }
    } else {
      if (this.state.reservaEvento === true) {
        const reservaHorario = {
          id: this.state.reserva_id,
          observacao: this.state.observacaoNovo,
          evento: this.state.eventoNovo,
        };
        await api.post("/reservahorario/editar?e=" + entidade, reservaHorario, {
          headers: { token: "Baer " + token },
        });
      }
      this.setState({ salvando: false });
      window.location.href = "/reservas";
    }
  };

  carregaListaPresenca = async () => {
    try {
      const resposta = await api.post(
        "/presencaReserva/presencaReservaPorHorario" + requisicoes.entidade,
        { id: this.state.reserva_id },
        requisicoes.header
      );

      if (resposta.data.length > 0) {
        resposta.data = this.ordenaNomes(resposta.data);
        for (var i = 0; i < resposta.data.length; i++) {
          var e = resposta.data[i];
          if (e.cpf !== undefined && e.cpf !== null && e.cpf.trim() !== "") {
            resposta.data[i].valido = validaCpf(e.cpf);
          } else {
            resposta.data[i].valido = null;
          }
        }
      }
      this.setState({ listaPresenca: resposta.data });
      //
      this.addTotalCapacidade();
    } catch (error) {
      console.log("carregaListaPresenca.exception: " + error);
    }
  };

  ordenaNomes(listaParam) {
    let listaRetorna = listaParam.filter(
      (p) => p.nome !== null && p.nome.trim() !== ""
    );
    listaRetorna.sort((a, b) => a.nome.localeCompare(b.nome));
    return listaRetorna;
  }

  carregaListaFornecedor = async () => {
    const resposta = await api.post(
      "/fornecedorReserva/fornecedorReservaPorHorario" + requisicoes.entidade,
      { id: this.state.reserva_id },
      requisicoes.header
    );
    // if (resposta.data.length > 0) {
    //   resposta.data = this.ordenaNomes(listaPresencas);
    // }
    this.setState({ listaFornecedor: resposta.data });
  };

  confirmaTermos = async (aceitaTermos) => {
    this.setState({ aceitaTermos: aceitaTermos });
    if (aceitaTermos === true) {
      if (this.state.reservaEvento === true) {
        if (this.state.evento !== null) {
          if (this.state.evento !== "") {
            this.setState({ liberaReservaBotao: false });
            this.setState({ parametroEvento: false });
          } else {
            this.setState({ liberaReservaBotao: true });
            this.setState({ parametroEvento: true });
          }
        } else {
          this.setState({ parametroEvento: true });
          this.setState({ liberaReservaBotao: true });
        }
      } else {
        if (this.state.liberaReservaBotao === true) {
          this.setState({ liberaReservaBotao: false });
        } else {
          this.setState({ liberaReservaBotao: false });
        }
      }
    } else {
      this.setState({ liberaReservaBotao: true });
    }
  };

  gerarTermos = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    const r = await api.get(
      "/reservaHorario/GerarTermos?e=" + entidade + "&t=Baer " + token
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "reservaHorario/GerarTermos?e=" +
      entidade +
      "&t=Baer " +
      token;

    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = await new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  buscaLivresData = async () => {
    this.setState({ loading: true });
    const resposta = await api.post(
      "/servico/servicoPorIdeData" + requisicoes.entidade,
      { id: this.props.location.query.id, data: this.state.dataBusca },
      requisicoes.header
    );
    const servicos = resposta.data;
    let listaRetorno = [];
    servicos.forEach(async (servico) => {
      let horariosResposta = await api.post(
        "/horarioServico/horarioPorServico" + requisicoes.entidade,
        { id: servico.id },
        requisicoes.header
      );
      const horarios = horariosResposta.data;
      let disponivel = false;
      horarios.forEach(async (horario) => {
        const reservasResposta = await api.post(
          "/reservaHorario/reservaPorIdDataAtiva" + requisicoes.entidade,
          {
            id: horario.id,
            dataReserva: this.state.dataBusca,
          },
          requisicoes.header
        );
        const reservas = reservasResposta.data;

        if (reservas.length > 0) {
          if (reservas.length >= horario.vaga) {
            if (disponivel !== true) {
              disponivel = false;
            }
          } else {
            disponivel = true;
          }
        } else {
          disponivel = true;
        }
        listaRetorno.push({
          id: servico.id,
          nome: servico.nome,
          horaInicio: horario.horaInicio,
          horaFim: horario.horaFim,
          disponivel: disponivel,
          horario_servico_id: horario.id,
        });
      });
    });
    setTimeout(async () => {
      // await listaRetorno = this.ordenaNomes(listaPresencas);
      this.setState({ loading: false });
      await this.setState({ listaBusca: listaRetorno });
    }, 1000);
  };

  buscaServicosLivresData = async () => {
    this.setState({ loading: true });
    const resposta = await api.post(
      "/servico/servicoPorGrupoServicoeData" + requisicoes.entidade,
      {
        id: this.state.servico.grupo_servico_id,
        data: this.state.dataBuscaServico,
      },
      requisicoes.header
    );
    const servicos = resposta.data;
    let listaRetorno = [];
    for (let i = 0; i < servicos.length; i++) {
      const servico = servicos[i];
      let horariosResposta = await api.post(
        "/horarioServico/horarioPorServico" + requisicoes.entidade,
        { id: servico.id },
        requisicoes.header
      );
      const horarios = horariosResposta.data;
      let disponivel = false;
      for (let j = 0; j < horarios.length; j++) {
        const horario = horarios[j];
        const reservasResposta = await api.post(
          "/reservaHorario/reservaPorIdDataAtiva" + requisicoes.entidade,
          {
            id: horario.id,
            dataReserva: this.state.dataBuscaServico,
          },
          requisicoes.header
        );
        const reservas = reservasResposta.data;

        if (reservas.length > 0) {
          if (horario.vaga >= reservas.length) {
            if (disponivel !== true) {
              disponivel = false;
            }
          } else {
            disponivel = true;
          }
        } else {
          disponivel = true;
        }
        listaRetorno.push({
          id: servico.id,
          nome: servico.nome,
          valor: servico.valor,
          horaInicio: horario.horaInicio,
          horaFim: horario.horaFim,
          disponivel: disponivel,
          horario_servico_id: horario.id,
          dataReserva: this.state.dataBuscaServico,
        });
      }
    }

    // listaRetorno = this.ordenaNomes(listaPresencas);

    this.setState({ listaBuscaGrupo: listaRetorno });
    this.setState({ loading: false });
  };

  transferenciaDataHorario = async (horario) => {
    this.setState({ botaoTransferenciaDataHorario: true });
    const resposta = await api.post(
      "/reservaHorario/transferenciaDataHorario" + requisicoes.entidade,
      {
        id: this.state.reserva_id,
        horario_servico_id: horario,
        dataReserva: this.state.dataBusca,
        responsavel_id: this.state.responsavel_id,
      },
      requisicoes.header
    );
    const reservaEfetivada = await api.post(
      "/reservaEfetivada/buscaPorReservaHorario" + requisicoes.entidade,
      {
        reserva_horario_id: this.state.reserva_id,
      },
      requisicoes.header
    );
    if (
      reservaEfetivada !== undefined &&
      reservaEfetivada !== null &&
      reservaEfetivada.data !== null
    ) {
      await api.post(
        "/reservaEfetivada/editar" + requisicoes.entidade,
        {
          id: reservaEfetivada.data.id,
          evento: null,
          observacao: null,
          reserva_horario_id: this.state.reserva_id,
          data_reserva: this.state.dataBusca,
        },
        requisicoes.header
      );
    }
    if (resposta.data !== "") {
      this.setState({ dataReserva: this.state.dataBusca });
      this.setState({ modalAlteraData: false });
      this.buscaReservas(this.state.dataBusca);
    }
    this.setState({ botaoTransferenciaDataHorario: false });
  };

  buscaLancamento = async () => {
    this.setState({ openModalConfirmacaoTransferencia: true });
    const reservaEfetivada = await api.post(
      "/reservaEfetivada/buscaPorReservaHorario" + requisicoes.entidade,
      {
        reserva_horario_id: this.state.reserva_id,
      },
      requisicoes.header
    );
    let valorTotal = 0;
    for (let i = 0; i < reservaEfetivada.data.lancamentos.length; i++) {
      const element = reservaEfetivada.data.lancamentos[i];
      valorTotal += parseFloat(element.valor);
    }

    if (reservaEfetivada.data.lancamento_conta_id !== null) {
      var lancamentoConta = await api.post(
        "/lancamentoConta/visualiza" + requisicoes.entidade,
        {
          id: reservaEfetivada.data.lancamento_conta_id,
        },
        requisicoes.header
      );

      if (lancamentoConta.data.valor_pago !== null && valorTotal !== null) {
        this.setState({
          valor_pago_reserva_efetivada:
            parseFloat(lancamentoConta.data.valor_pago) +
            parseFloat(valorTotal),
        });
        this.setState({ valor_reserva_efetivada: lancamentoConta.data.valor });
      } else {
        this.setState({ valor_pago_reserva_efetivada: null });
        this.setState({ valor_reserva_efetivada: null });
      }
    } else {
      this.setState({ valor_pago_reserva_efetivada: null });
      this.setState({ valor_reserva_efetivada: null });
    }
  };

  transferenciaServicoDataHorario = async (horario, servico, valor_servico) => {
    this.setState({ botaoTransferenciaServicoDataHorario: true });
    const resposta = await api.post(
      "/reservaHorario/transferenciaDataHorario" + requisicoes.entidade,
      {
        id: this.state.reserva_id,
        horario_servico_id: horario,
        dataReserva: this.state.dataBuscaServico,
        responsavel_id: this.state.responsavel_id,
      },
      requisicoes.header
    );

    const reservaEfetivada = await api.post(
      "/reservaEfetivada/buscaPorReservaHorario" + requisicoes.entidade,
      {
        reserva_horario_id: this.state.reserva_id,
      },
      requisicoes.header
    );
    if (reservaEfetivada !== undefined && reservaEfetivada !== null) {
      await api.post(
        "/reservaEfetivada/editar" + requisicoes.entidade,
        {
          id: reservaEfetivada.data.id,
          evento: null,
          observacao: null,
          reserva_horario_id: this.state.reserva_id,
          data_reserva: this.state.dataBuscaServico,
        },
        requisicoes.header
      );

      if (reservaEfetivada.data.lancamentoConta !== null) {
        var lancamentoConta = await api.post(
          "/lancamentoConta/visualiza" + requisicoes.entidade,
          {
            id: reservaEfetivada.data.lancamento_conta_id,
          },
          requisicoes.header
        );
        let dados = {
          valor: valor_servico,
          servico_id: servico,
        };
        // if (lancamentoConta.data.valor_pago !== null) {
        //   dados.valor_pago = valor_servico;
        // }
        if (
          lancamentoConta.data.valor_pago === null &&
          lancamentoConta.data.data_pagamento === null
        ) {
          //Se o lançamento ainda não foi cobrado
          //alterar o valor e servico dele
          await api.post(
            "/lancamentoConta/alterar" +
            requisicoes.entidade +
            "&id=" +
            reservaEfetivada.data.lancamento_conta_id,
            dados,
            requisicoes.header
          );
        } else {
          if (
            parseFloat(this.state.valor_pago_reserva_efetivada) <
            parseFloat(valor_servico)
          ) {
            // se não, criar um novo lancamento com o valor da diferença.
            dados.tipo_cobranca = "boleto";
            dados.tipo_lancamento = "servico";
            dados.credito_debito = "credito";
            dados.descricao = "Taxa de transferência de servico reservado.";
            dados.valor =
              parseFloat(valor_servico) -
              parseFloat(lancamentoConta.data.valor_pago);
            dados.pessoa_id = lancamentoConta.data.pessoa_id;
            dados.data_emissao = moment().format("YYYY-MM-DD");
            dados.data_vencimento = moment().format("YYYY-MM-DD");
            let lancamento = await api.post(
              "/lancamentoConta/salvar" + requisicoes.entidade,
              dados,
              requisicoes.header
            );
            await api.post(
              "/reservaEfetivada/adicionaLancamentos" + requisicoes.entidade,
              {
                reserva_efetivada_id: reservaEfetivada.data.id,
                lancamento_conta_id: lancamento.data.id,
              },
              requisicoes.header
            );
          }
        }
      }
    }
    this.setState({ openModalConfirmacaoTransferencia: false });
    this.setState({ botaoTransferenciaServicoDataHorario: false });
    if (resposta.data !== "") {
      this.props.location.query = {
        id: servico,
        dataReserva: this.state.dataBuscaServico,
        pessoa: this.state.pessoa,
        pessoa_id: this.state.pessoa_id,
      };
      this.setState({ dataReserva: this.state.dataBuscaServico });
      this.setState({ modalAlteraData: false });
      await this.visualiza();
    }
  };

  imprimirListaConvidados = async () => {
    await api.post(
      "/reservaHorario/gerarListaConvidados" + requisicoes.entidade,
      {
        id: this.state.reserva_id,
        data: this.state.dataReserva,
        servico: this.state.servico,
        responsavel: this.state.pessoa,
        horarioInicial: this.state.horarios[0].horaInicio,
        horarioFinal: this.state.horarios[0].horaFim,
      },
      requisicoes.header
    );
    setTimeout(() => {
      this.buscaPdfListaConvidados();
    }, 1000);
  };
  buscaPdfListaConvidados = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api.get(
      "/reservaHorario/buscarListaConvidados?e=" + entidade + "&t=Baer " + token
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "reservaHorario/buscarListaConvidados?e=" +
      entidade +
      "&t=Baer " +
      token;
    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = await new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  imprimirListaFornecedor = async () => {
    await api.post(
      "/reservaHorario/gerarListaFornecedores" + requisicoes.entidade,
      {
        id: this.state.reserva_id,
        data: this.state.dataReserva,
        servico: this.state.servico,
        responsavel: this.state.pessoa,
        horarioInicial: this.state.horarios[0].horaInicio,
        horarioFinal: this.state.horarios[0].horaFim,
      },
      requisicoes.header
    );
    setTimeout(() => {
      this.buscaPdfListaFornecedores();
    }, 1000);
  };
  buscaPdfListaFornecedores = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api.get(
      "/reservaHorario/buscarListaFornecedores?e=" +
      entidade +
      "&t=Baer " +
      token
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "reservaHorario/buscarListaFornecedores?e=" +
      entidade +
      "&t=Baer " +
      token;
    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = await new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  handleShowAbertoCaixa = () => {
    this.setState({ open_divida: true });
  };

  handleCloseAbertoCaixa = () => {
    this.setState({ open_divida: false, liberaReservaBotao: false });
  };

  handleCloseFechamentoCaixa = () => {
    // window.location.reload();
    this.setState({ open_fechamento_divida: false });
  };

  render() {
    return (
      <div className="pagina">
        {this.state.isLoading ? (
          <div>
            <Header as="h1" dividing>
              Reserva
            </Header>
            <Segment.Group raised>
              <Segment>
                <formulario.Label as="h3">
                  Nome: {this.state.servico.nome}
                </formulario.Label>
              </Segment>
              <Segment>
                <formulario.Label as="h3">
                  Valor:{" "}
                  {this.state.servico?.valor !== undefined &&
                    this.state.servico?.valor.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                </formulario.Label>
              </Segment>

              {this.state.reservadoPelaPessoa === true && (
                <>
                  {this.state.reservaEfetivacao === true && (
                    <Segment.Group horizontal raised>
                      <Segment clearing>
                        Dias para cancelamento automático 1:{" "}
                        {this.state.diasCancelamento}
                      </Segment>
                      <Segment clearing>
                        Data para cancelamento automático:{" "}
                        {this.state.dataCancelamento}
                      </Segment>
                    </Segment.Group>
                  )}
                  {this.state.reservaEvento === true && (
                    <>
                      <Segment>
                        Descrição do Evento: {this.state.evento}
                      </Segment>
                    </>
                  )}
                </>
              )}
              {this.props.permissoes.find(
                (e) => e.nome === "alterar_meus_dados_alheios"
              ) && (
                  <Segment>
                    <Row>
                      <Col sm={12}>
                        <formulario.Label>Pessoa</formulario.Label>
                        <Row>
                          <Col sm={10}>
                            <Autocomplete
                              id="pesquisaPessoa"
                              value={this.state.pessoa}
                              inputValue={this.state.inputValue}
                              onInputChange={(event, newInputValue) => {
                                this.setState({ inputValue: newInputValue });
                              }}
                              disabled={
                                this.props.location.query?.pessoa.id !== null
                              }
                              options={this.state.options}
                              getOptionLabel={(option) => option.label}
                              onKeyDown={this.pesquisaPessoa}
                              onChange={this.alteracaoSelectPessoa}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Busca"
                                  size="small"
                                />
                              )}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Segment>
                )}
            </Segment.Group>
            <Divider />
            <Segment>
              <Row>
                <Col sm={11}>
                  <formulario.Label>Data Reserva</formulario.Label>
                  <FormControl
                    type="date"
                    aria-describedby="basic-addon1"
                    name="dataReserva"
                    onChange={this.alteracao}
                    required
                    value={this.state.dataReserva.toString().substr(0, 10)}
                    disabled
                  />
                </Col>

                {this.props.permissoes.find(
                  (e) => e.nome === "alterar_meus_dados_alheios"
                ) && (
                    <>
                      {this.state.reservadoPelaPessoa === true && (
                        <Col sm={1}>
                          <formulario.Label>⠀⠀⠀⠀⠀⠀⠀⠀</formulario.Label>
                          <Modal
                            open={this.state.modalAlteraData}
                            size="small"
                            trigger={
                              <Button
                                primary
                                icon="exchange"
                                size="tiny"
                                onClick={() => this.abreModalAlteraData(true)}
                              ></Button>
                            }
                          >
                            <Tab
                              panes={[
                                {
                                  menuItem: "Transferência de Data",
                                  render: () => (
                                    <Tab.Pane>
                                      <Header icon textAlign="center">
                                        <Icon name="exchange" />
                                        TRANSFERÊNCIA DE DATA
                                      </Header>
                                      <Modal.Content>
                                        <Modal.Description>
                                          <Row>
                                            <Col sm={10}>
                                              <FormControl
                                                type="date"
                                                aria-describedby="basic-addon1"
                                                name="dataBusca"
                                                onChange={this.alteracao}
                                                value={this.state.dataBusca}
                                              />
                                            </Col>
                                            <Col sm={2}>
                                              <Button
                                                type="button"
                                                size="tiny"
                                                primary
                                                icon="search"
                                                onClick={this.buscaLivresData}
                                              ></Button>
                                            </Col>
                                          </Row>
                                          <Divider />
                                          {this.state.loading === true ? (
                                            <div
                                              style={{
                                                paddingTop: 10,
                                                height: 60,
                                              }}
                                            >
                                              <Segment size="massive">
                                                <Dimmer active inverted>
                                                  <Loader
                                                    active
                                                    inverted
                                                    inline
                                                  />
                                                </Dimmer>
                                              </Segment>
                                            </div>
                                          ) : (
                                            <Table celled>
                                              <Table.Header>
                                                <Table.Row>
                                                  <Table.HeaderCell>
                                                    Nome
                                                  </Table.HeaderCell>
                                                  <Table.HeaderCell>
                                                    Hora Inicio
                                                  </Table.HeaderCell>
                                                  <Table.HeaderCell>
                                                    Hora Final
                                                  </Table.HeaderCell>
                                                  <Table.HeaderCell textAlign="center">
                                                    Disponibilidade
                                                  </Table.HeaderCell>
                                                  <Table.HeaderCell textAlign="center">
                                                    Reserva
                                                  </Table.HeaderCell>
                                                </Table.Row>
                                              </Table.Header>
                                              <Table.Body>
                                                {this.state.listaBusca.map(
                                                  (e, i) => (
                                                    <Table.Row key={i}>
                                                      <Table.Cell>
                                                        {e.nome}
                                                      </Table.Cell>
                                                      <Table.Cell>
                                                        {e.horaInicio}
                                                      </Table.Cell>
                                                      <Table.Cell>
                                                        {e.horaFim}
                                                      </Table.Cell>
                                                      <Table.Cell
                                                        width={1}
                                                        textAlign="center"
                                                      >
                                                        {e.disponivel === true ? (
                                                          <Button
                                                            positive
                                                            icon="thumbs down"
                                                            size="tiny"
                                                          >
                                                            Disponível
                                                          </Button>
                                                        ) : (
                                                          <Button
                                                            negative
                                                            icon="thumbs up"
                                                            size="tiny"
                                                          >
                                                            Reservado
                                                          </Button>
                                                        )}
                                                      </Table.Cell>
                                                      <Table.Cell
                                                        width={1}
                                                        textAlign="center"
                                                      >
                                                        <Button
                                                          loading={
                                                            this.state
                                                              .botaoTransferenciaDataHorario
                                                          }
                                                          primary
                                                          icon="calendar"
                                                          size="tiny"
                                                          onClick={() =>
                                                            this.transferenciaDataHorario(
                                                              e.horario_servico_id
                                                            )
                                                          }
                                                          disabled={!e.disponivel}
                                                        ></Button>
                                                      </Table.Cell>
                                                    </Table.Row>
                                                  )
                                                )}
                                              </Table.Body>
                                            </Table>
                                          )}
                                        </Modal.Description>
                                      </Modal.Content>

                                      <Divider />
                                      <Modal.Actions
                                        style={{
                                          textAlign: "right",
                                          paddingTop: 10,
                                        }}
                                      >
                                        <Button
                                          color="red"
                                          inverted
                                          onClick={() =>
                                            this.abreModalAlteraData(false)
                                          }
                                        >
                                          <Icon name="remove" /> Fechar
                                        </Button>
                                      </Modal.Actions>
                                    </Tab.Pane>
                                  ),
                                },
                                {
                                  menuItem: "Transferência de Lugar",
                                  render: () => (
                                    <Tab.Pane>
                                      <Header icon textAlign="center">
                                        <Icon name="exchange" />
                                        TRANSFERÊNCIA DE LUGAR
                                      </Header>
                                      <Modal.Content>
                                        <Modal.Description>
                                          <Row>
                                            <Col sm={10}>
                                              <FormControl
                                                type="date"
                                                aria-describedby="basic-addon1"
                                                name="dataBuscaServico"
                                                onChange={this.alteracao}
                                                value={
                                                  this.state.dataBuscaServico
                                                }
                                              />
                                            </Col>
                                            <Col sm={2}>
                                              <Button
                                                type="button"
                                                size="tiny"
                                                primary
                                                icon="search"
                                                onClick={() =>
                                                  this.buscaServicosLivresData()
                                                }
                                              ></Button>
                                            </Col>
                                          </Row>
                                          <Divider />
                                          {this.state.loading === true ? (
                                            <div
                                              style={{
                                                paddingTop: 10,
                                                height: 60,
                                              }}
                                            >
                                              <Segment size="massive">
                                                <Dimmer active inverted>
                                                  <Loader
                                                    active
                                                    inverted
                                                    inline
                                                  />
                                                </Dimmer>
                                              </Segment>
                                            </div>
                                          ) : (
                                            <Table celled>
                                              <Table.Header>
                                                <Table.Row>
                                                  <Table.HeaderCell>
                                                    Nome
                                                  </Table.HeaderCell>
                                                  <Table.HeaderCell>
                                                    Hora Inicio
                                                  </Table.HeaderCell>
                                                  <Table.HeaderCell>
                                                    Hora Final
                                                  </Table.HeaderCell>
                                                  <Table.HeaderCell textAlign="center">
                                                    Disponibilidade
                                                  </Table.HeaderCell>
                                                  <Table.HeaderCell textAlign="center">
                                                    Reserva
                                                  </Table.HeaderCell>
                                                </Table.Row>
                                              </Table.Header>
                                              <Table.Body>
                                                {this.state.listaBuscaGrupo.map(
                                                  (e, i) => (
                                                    <Table.Row key={i}>
                                                      <Table.Cell>
                                                        {e.nome}
                                                      </Table.Cell>
                                                      <Table.Cell>
                                                        {e.horaInicio}
                                                      </Table.Cell>
                                                      <Table.Cell>
                                                        {e.horaFim}
                                                      </Table.Cell>
                                                      <Table.Cell
                                                        width={1}
                                                        textAlign="center"
                                                      >
                                                        {e.disponivel === true ? (
                                                          <Button
                                                            positive
                                                            icon="thumbs down"
                                                            size="tiny"
                                                          >
                                                            Disponível
                                                          </Button>
                                                        ) : (
                                                          <Button
                                                            negative
                                                            icon="thumbs up"
                                                            size="tiny"
                                                          >
                                                            Reservado
                                                          </Button>
                                                        )}
                                                      </Table.Cell>
                                                      <Table.Cell
                                                        width={1}
                                                        textAlign="center"
                                                      >
                                                        {e.disponivel === true ? (
                                                          <Modal
                                                            key={e.id}
                                                            open={
                                                              this.state
                                                                .openModalConfirmacaoTransferencia
                                                            }
                                                            size="tiny"
                                                            onOpen={() => {
                                                              this.buscaLancamento();
                                                              this.setState({
                                                                servicoTransferenciaLugar:
                                                                  e,
                                                                valorNovoServico:
                                                                  e.valor,
                                                              });
                                                            }}
                                                            trigger={
                                                              <Button
                                                                primary
                                                                icon="calendar"
                                                                size="small"
                                                              // onClick={() =>
                                                              //   this.transferenciaServicoDataHorario(
                                                              //     e.horario_servico_id,
                                                              //     e.id,
                                                              //     e.valor
                                                              //   )
                                                              // }
                                                              ></Button>
                                                            }
                                                          >
                                                            <Modal.Content>
                                                              {this.state
                                                                .servicoTransferenciaLugar && (
                                                                  <Form>
                                                                    <Container>
                                                                      {this.state
                                                                        .valor_pago_reserva_efetivada !==
                                                                        null ? (
                                                                        <Row>
                                                                          <h3>
                                                                            {"O sócio já pagou R$ " +
                                                                              parseFloat(
                                                                                this
                                                                                  .state
                                                                                  .valor_pago_reserva_efetivada
                                                                              ).toLocaleString(
                                                                                "pt-BR",
                                                                                {
                                                                                  minimumFractionDigits: 2,
                                                                                  maximumFractionDigits: 2,
                                                                                }
                                                                              )}
                                                                          </h3>
                                                                        </Row>
                                                                      ) : (
                                                                        <Row>
                                                                          <h3>
                                                                            O sócio
                                                                            já pagou
                                                                            R$ 0,00
                                                                          </h3>
                                                                        </Row>
                                                                      )}
                                                                      <Row
                                                                        style={{
                                                                          paddingTop: 15,
                                                                        }}
                                                                      >
                                                                        <h4>
                                                                          {`Transferir para: ${this.state.servicoTransferenciaLugar.nome}`}
                                                                        </h4>
                                                                      </Row>

                                                                      <Row
                                                                        style={{
                                                                          paddingTop: 15,
                                                                        }}
                                                                      >
                                                                        <Col>
                                                                          <Form.Field
                                                                            label="Valor do novo serviço"
                                                                            defaultValue={
                                                                              e.valor
                                                                            }
                                                                            value={
                                                                              this
                                                                                .state
                                                                                .valorNovoServico
                                                                            }
                                                                            name="valorNovoServico"
                                                                            control={
                                                                              Input
                                                                            }
                                                                            readOnly={
                                                                              this
                                                                                .state
                                                                                .valor_reserva_efetivada ===
                                                                              null
                                                                            }
                                                                            type="number"
                                                                            onChange={
                                                                              this
                                                                                .alteracao
                                                                            }
                                                                          />
                                                                        </Col>
                                                                        <Col>
                                                                          <Form.Field
                                                                            label="Data"
                                                                            value={
                                                                              e.dataReserva
                                                                            }
                                                                            control={
                                                                              Input
                                                                            }
                                                                            readOnly
                                                                            type="date"
                                                                          />
                                                                        </Col>
                                                                      </Row>
                                                                      {this.state
                                                                        .valor_pago_reserva_efetivada !==
                                                                        null &&
                                                                        this.state
                                                                          .valorNovoServico !==
                                                                        null ? (
                                                                        <>
                                                                          <Row
                                                                            style={{
                                                                              paddingTop: 25,
                                                                              display:
                                                                                "flex",
                                                                              alignItems:
                                                                                "center",
                                                                              justifyContent:
                                                                                "center",
                                                                            }}
                                                                          >
                                                                            <h3>
                                                                              A
                                                                              diferença
                                                                              de
                                                                              valores
                                                                              deve
                                                                              ser
                                                                              paga
                                                                              neste
                                                                              momento.
                                                                            </h3>
                                                                          </Row>
                                                                          <Row
                                                                            style={{
                                                                              display:
                                                                                "flex",
                                                                              alignItems:
                                                                                "center",
                                                                              justifyContent:
                                                                                "center",
                                                                            }}
                                                                          >
                                                                            <h5>
                                                                              {"Diferença a ser paga: " +
                                                                                parseFloat(
                                                                                  parseFloat(
                                                                                    this
                                                                                      .state
                                                                                      .valorNovoServico
                                                                                  ) -
                                                                                  parseFloat(
                                                                                    this
                                                                                      .state
                                                                                      .valor_pago_reserva_efetivada
                                                                                  )
                                                                                ).toLocaleString(
                                                                                  "pt-BR",
                                                                                  {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2,
                                                                                  }
                                                                                )}
                                                                            </h5>
                                                                          </Row>
                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          <Row
                                                                            style={{
                                                                              paddingTop: 25,
                                                                              display:
                                                                                "flex",
                                                                              alignItems:
                                                                                "center",
                                                                              justifyContent:
                                                                                "center",
                                                                            }}
                                                                          >
                                                                            <h3>
                                                                              A
                                                                              diferença
                                                                              de
                                                                              valores
                                                                              deve
                                                                              ser
                                                                              paga
                                                                              neste
                                                                              momento.
                                                                            </h3>
                                                                          </Row>
                                                                          <Row
                                                                            style={{
                                                                              display:
                                                                                "flex",
                                                                              alignItems:
                                                                                "center",
                                                                              justifyContent:
                                                                                "center",
                                                                            }}
                                                                          >
                                                                            <h5>
                                                                              Diferença
                                                                              a ser
                                                                              paga:
                                                                              R$
                                                                              0,00
                                                                            </h5>
                                                                          </Row>
                                                                        </>
                                                                      )}

                                                                      <Row
                                                                        style={{
                                                                          display:
                                                                            "flex",
                                                                          alignItems:
                                                                            "center",
                                                                          justifyContent:
                                                                            "center",
                                                                        }}
                                                                      >
                                                                        <h3
                                                                          style={{
                                                                            paddingTop:
                                                                              this
                                                                                .state
                                                                                .valor_pago_reserva_efetivada !==
                                                                                null
                                                                                ? 10
                                                                                : 40,
                                                                          }}
                                                                        >
                                                                          Tem
                                                                          certeza
                                                                          que deseja
                                                                          alterar
                                                                          esta
                                                                          reserva?
                                                                        </h3>
                                                                      </Row>
                                                                    </Container>
                                                                    <Row
                                                                      style={{
                                                                        paddingTop: 40,
                                                                      }}
                                                                    >
                                                                      <Col
                                                                        style={{
                                                                          display:
                                                                            "flex",
                                                                          alignItems:
                                                                            "center",
                                                                          justifyContent:
                                                                            "center",
                                                                        }}
                                                                      >
                                                                        <Button
                                                                          color="red"
                                                                          size="large"
                                                                          onClick={() =>
                                                                            this.setState(
                                                                              {
                                                                                openModalConfirmacaoTransferencia: false,
                                                                              }
                                                                            )
                                                                          }
                                                                        >
                                                                          Não
                                                                        </Button>
                                                                      </Col>
                                                                      <Col
                                                                        style={{
                                                                          display:
                                                                            "flex",
                                                                          alignItems:
                                                                            "center",
                                                                          justifyContent:
                                                                            "center",
                                                                        }}
                                                                      >
                                                                        <Button
                                                                          loading={
                                                                            this
                                                                              .state
                                                                              .botaoTransferenciaServicoDataHorario
                                                                          }
                                                                          color="green"
                                                                          size="large"
                                                                          onClick={() =>
                                                                            this.transferenciaServicoDataHorario(
                                                                              this
                                                                                .state
                                                                                .servicoTransferenciaLugar
                                                                                .horario_servico_id,
                                                                              this
                                                                                .state
                                                                                .servicoTransferenciaLugar
                                                                                .id,
                                                                              this
                                                                                .state
                                                                                .valorNovoServico
                                                                            )
                                                                          }
                                                                        >
                                                                          Sim
                                                                        </Button>
                                                                      </Col>
                                                                    </Row>
                                                                  </Form>
                                                                )}
                                                            </Modal.Content>
                                                          </Modal>
                                                        ) : (
                                                          <Button
                                                            primary
                                                            icon="calendar"
                                                            size="small"
                                                            disabled
                                                          ></Button>
                                                        )}
                                                      </Table.Cell>
                                                    </Table.Row>
                                                  )
                                                )}
                                              </Table.Body>
                                            </Table>
                                          )}
                                        </Modal.Description>
                                      </Modal.Content>

                                      <Divider />
                                      <Modal.Actions
                                        style={{
                                          textAlign: "right",
                                          paddingTop: 10,
                                        }}
                                      >
                                        <Button
                                          color="red"
                                          inverted
                                          onClick={() =>
                                            this.abreModalAlteraData(false)
                                          }
                                        >
                                          <Icon name="remove" /> Fechar
                                        </Button>
                                      </Modal.Actions>
                                    </Tab.Pane>
                                  ),
                                },
                              ]}
                            />
                          </Modal>
                        </Col>
                      )}
                    </>
                  )}
              </Row>
            </Segment>
            {this.state.dataReserva !== "" && (
              <Segment>
                {/* {this.state.confirmouReserva === true && this.state.servico.nome.indexOf("Churrasqueira") !== -1 && (
                  <div class="ui negative message">
                    <div class="header">
                      Bloqueio por não comparecimento do mesmo
                    </div>
                    <p>No prazo de {this.state.diasBloquados} dias. Falta {this.state.diferencaDias} para reservar novamente.</p>
                  </div>
                )} */}
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Hora Inicio</Table.HeaderCell>
                      <Table.HeaderCell>Hora Fim</Table.HeaderCell>
                      <Table.HeaderCell>Capacidade</Table.HeaderCell>
                      <Table.HeaderCell>Vagas</Table.HeaderCell>
                      <Table.HeaderCell width={1} textAlign="center">
                        <Icon name="calendar outline" size="large"></Icon>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.horarios.map((e, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>
                          <FormControl
                            type="time"
                            placeholder="Horario Inicio"
                            aria-label="Horario Inicio"
                            aria-describedby="basic-addon1"
                            name="horaInicio"
                            fluid
                            disabled
                            defaultValue={e.horaInicio}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <FormControl
                            type="time"
                            placeholder="Horario Fim"
                            aria-label="Horario Fim"
                            aria-describedby="basic-addon1"
                            name="horaFim"
                            fluid
                            disabled
                            defaultValue={e.horaFim}
                          />
                        </Table.Cell>
                        <Table.Cell width={2}>
                          <FormControl
                            name="limite"
                            fluid
                            disabled
                            value={this.state.capacidade}
                          />
                        </Table.Cell>
                        <Table.Cell width={2}>
                          <FormControl
                            name="vaga"
                            fluid
                            disabled
                            value={e.vaga}
                          />
                        </Table.Cell>
                        <Table.Cell width={1}>
                          {this.state.reservadoPelaPessoa === false ? (
                            <div>
                              {
                                //e.temReserva === true &&
                                e.vaga <= 0 ? (
                                  <Button
                                    fluid
                                    color="orange"
                                    icon="calendar times"
                                    size="tiny"
                                  ></Button>
                                ) : (
                                  <>
                                    {(localStorage.getItem("tipo_usuario_clube") === "administrador" ||
                                      localStorage.getItem("tipo_usuario_clube") === "caixa" ||
                                      localStorage.getItem("tipo_usuario_clube") === "administrativo") ? (
                                      <Button
                                        size="tiny"
                                        positive
                                        fluid
                                        icon="calendar check"
                                        disabled={
                                          this.state.salvando ||
                                          !this.state.pessoa_id
                                        }
                                        onClick={() => {
                                          this.abreModalReserva(true, e.horario_servico_id);
                                        }}
                                      ></Button>
                                    ) : localStorage.getItem("tipo_usuario_clube") === "titular" ||
                                      localStorage.getItem("tipo_usuario_clube") === "dependente" ? (
                                      <Button
                                        size="tiny"
                                        positive
                                        fluid
                                        icon="calendar check"
                                        disabled={
                                          this.state.salvando ||
                                          !this.state.pessoa_id
                                          // || this.state.confirmouReserva
                                        }
                                        onClick={() => {
                                          this.abreModalReserva(true, e.horario_servico_id);
                                        }}
                                      ></Button>
                                    ) : null}
                                  </>
                                )
                              }
                            </div>
                          ) : (
                            <div>
                              {e.temReserva === true && (
                                // && e.vaga >= 0
                                <div>
                                  {e.id === "" ? (
                                    "a"
                                  ) : (
                                    <Button
                                      size="tiny"
                                      negative
                                      color={
                                        this.state.reservaUtilizada === true
                                          ? "orange"
                                          : "red"
                                      }
                                      fluid
                                      icon="calendar times"
                                      onClick={() =>
                                        this.state.reservaUtilizada === true
                                          ? alert(
                                            "Sócio ou convidado já registrou entrada, não é possível cancelar a reserva"
                                          )
                                          : this.abreModal(true, e.id)
                                      }
                                    ></Button>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Segment>
            )}
            {this.state.reservadoPelaPessoa === true && (
              <>
                {(localStorage.getItem("tipo_usuario_clube") ===
                  "administrador" ||
                  localStorage.getItem("tipo_usuario_clube") === "caixa" ||
                  localStorage.getItem("tipo_usuario_clube") ===
                  "administrativo") && (
                    <>
                      {this.state.reservaEfetivacao === true && (
                        <Segment>
                          <formulario.Label>Dias cancelamento </formulario.Label>

                          <FormControl
                            aria-describedby="basic-addon1"
                            name="diasCancelamentoNovo"
                            onChange={this.alteracao}
                            value={this.state.diasCancelamentoNovo}
                            disabled={
                              localStorage.getItem("tipo_usuario_clube") !==
                              "administrador" &&
                              this.state.reserva_id &&
                              this.state.reserva.situacaoReserva === "prereserva"
                            }
                          />
                        </Segment>
                      )}
                      {this.state.reservaEvento === true && (
                        <>
                          <Segment>
                            <formulario.Label>
                              Descrição do Evento
                            </formulario.Label>
                            <FormControl
                              aria-describedby="basic-addon1"
                              name="eventoNovo"
                              onChange={this.alteracao}
                              value={this.state.eventoNovo}
                              disabled={
                                this.state.reserva_id &&
                                this.state.reserva.situacaoReserva ===
                                "prereserva"
                              }
                            />
                          </Segment>
                          <Segment>
                            <formulario.Label>Observação</formulario.Label>
                            <FormControl
                              aria-describedby="basic-addon1"
                              name="observacaoNovo"
                              as="textarea"
                              rows={7}
                              onChange={this.alteracao}
                              value={this.state.observacaoNovo}
                              disabled={
                                this.state.reserva_id &&
                                this.state.reserva.situacaoReserva ===
                                "prereserva"
                              }
                            />
                          </Segment>
                        </>
                      )}
                    </>
                  )}

                <Segment>
                  <Col sm={12} style={{ margin: "-5.5px" }}>
                    <Row>
                      <Col sm={10}>
                        <Header>
                          Lista de Convidados ({this.state.listaPresenca.length}
                          /{this.state.capacidade})
                        </Header>
                      </Col>
                      <Col sm={1}>
                        <Button
                          color="blue"
                          floated="right"
                          icon="file alternate outline"
                          size="tiny"
                          onClick={this.imprimirListaConvidados}
                        />
                      </Col>
                      <Col sm={1}>
                        <ModalLerExcel
                          pessoa={this.state.infoPessoa}
                          adicionaListaPresencaByExcel={
                            this.adicionaListaPresencaByExcel
                          }
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Nome</Table.HeaderCell>
                        <Table.HeaderCell>CPF</Table.HeaderCell>
                        {/* <Table.HeaderCell>Vínculo</Table.HeaderCell> */}
                        <Table.HeaderCell width={1} textAlign="center">
                          <Button
                            positive
                            backgroundColor={
                              this.state.reserva.situacaoReserva === "prereserva"
                                ? "yellow"
                                : this.state.reserva.situacaoReserva === "ativa"
                                  ? "green"
                                  : "red"
                            }
                            icon="add"
                            size="tiny"
                            onClick={() => this.addPresenca()}
                          ></Button>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.state.listaPresenca.map((presenca, i) => (
                        <Table.Row key={i}>
                          <Table.Cell>
                            <FormControl
                              required
                              name="nome"
                              value={presenca.nome}
                              onChange={(e) =>
                                this.alteracaoNomeListaPresenca(
                                  e.target.value,
                                  i
                                )
                              }
                              readOnly={presenca.bloqueado}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <FormControl
                              required={
                                this.state.parametroExigeCPFConvidado === true
                              }
                              name="cpf_convidado"
                              value={presenca.nome !== "" ? presenca.cpf : ""}
                              keyboardType="numeric"
                              onChange={(e) =>
                                this.alteracaoCPFListaPresenca(
                                  e.target.value,
                                  i
                                )
                              }
                              readOnly={presenca.bloqueado}
                              style={{
                                borderColor:
                                  presenca.valido == null
                                    ? "#ced4da"
                                    : presenca.valido === true
                                      ? "green"
                                      : "red",
                                borderWidth: 1,
                              }}
                            />
                          </Table.Cell>
                          {/* <Table.Cell>
                            <FormControl
                              required
                              name="parentesco"
                              fluid
                              value={presenca.parentesco}
                              onChange={(e) =>
                                this.alteracaoParentescoListaPresenca(
                                  e.target.value,
                                  i
                                )
                              }
                              readOnly={presenca.bloqueado}
                            />
                          </Table.Cell> */}
                          <Table.Cell>
                            {presenca.presente === false &&
                              presenca.bloqueado === true ? null : (
                              <Button
                                fluid
                                color={
                                  presenca.presente === false
                                    ? "red"
                                    : "positive"
                                }
                                icon={
                                  presenca.presente === false
                                    ? "remove"
                                    : "clipboard check"
                                }
                                size="tiny"
                                onClick={
                                  presenca.presente === false &&
                                    presenca.bloqueado === false
                                    ? () => this.removePresenca(presenca, i)
                                    : null
                                }
                              />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Segment>
                {this.state.reservaEvento === true && (
                  <Segment>
                    <Col sm={12} style={{ margin: "-5.5px" }}>
                      <Row>
                        <Col sm={11}>
                          <Header>Lista de Fornecedor</Header>
                        </Col>
                        <Col sm={1}>
                          <Button
                            color="blue"
                            floated="right"
                            icon="file alternate outline"
                            size="tiny"
                            onClick={this.imprimirListaFornecedor}
                          ></Button>
                        </Col>
                      </Row>
                    </Col>
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Nome</Table.HeaderCell>
                          <Table.HeaderCell>CPF/RG</Table.HeaderCell>
                          <Table.HeaderCell>Função</Table.HeaderCell>
                          <Table.HeaderCell width={1} textAlign="center">
                            <Button
                              fluid
                              positive
                              icon="add"
                              size="tiny"
                              onClick={() => this.addFornecedor()}
                            ></Button>
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {this.state.listaFornecedor.map((fornecedor, i) => (
                          <Table.Row key={i}>
                            <Table.Cell>
                              <FormControl
                                required
                                name="nomeFornecedor"
                                value={fornecedor.nome}
                                onChange={(e) =>
                                  this.alteracaoNomeListaFornecedor(
                                    e.target.value,
                                    i
                                  )
                                }
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <FormControl
                                required
                                name="documentoFornecedor"
                                value={fornecedor.documento}
                                onChange={(e) =>
                                  this.alteracaoDocumentoListaFornecedor(
                                    e.target.value,
                                    i
                                  )
                                }
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <FormControl
                                required
                                name="funcaoFornecedor"
                                value={fornecedor.funcao}
                                onChange={(e) =>
                                  this.alteracaoFuncaoListaFornecedor(
                                    e.target.value,
                                    i
                                  )
                                }
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Button
                                fluid
                                negative
                                icon="remove"
                                size="tiny"
                                onClick={() =>
                                  this.removeFornecedor(fornecedor, i)
                                }
                              ></Button>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </Segment>
                )}
              </>
            )}
            <Divider />

            <Segment clearing>
              <Col sm={8}>
                <Card.Group>
                  <Card>
                    {this.state.historico_reserva !== null &&
                      this.state.historico_reserva !== 0 && (
                        <Card.Content>
                          <Button
                            floated="right"
                            size="mini"
                            icon="list"
                            color="olive"
                            onClick={() => this.setState({ open: true })}
                            type="button"
                          />
                          {/* this.state.historico_reserva !== [] && */}
                          <Card.Meta style={{ color: "black" }}>
                            {this.state.historico_reserva !== null &&
                              this.state.historico_reserva.length > 0
                              ? "Usuário: " +
                              this.state.historico_reserva[
                                this.state.historico_reserva.length - 1
                              ]?.usuario_nome
                              : "Usuário: "}
                          </Card.Meta>
                          <Card.Description style={{ color: "black" }}>
                            {this.state.contas_a_pagar == null
                              ? ""
                              : this.state.historico_reserva[
                                this.state.historico_reserva.length - 1
                              ]?.usuario.entidade +
                              ": " +
                              format(
                                parseISO(
                                  this.state.historico_reserva[
                                    this.state.historico_reserva.length - 1
                                  ].data
                                ),
                                "dd/MM/yyyy"
                              ) +
                              " " +
                              this.state.historico_reserva[
                                this.state.historico_reserva.length - 1
                              ]?.hora +
                              " hs"}
                          </Card.Description>
                        </Card.Content>
                      )}
                  </Card>
                </Card.Group>
              </Col>
              {/* Historico  */}
              <Modal
                dimmer={"blurring"}
                open={this.state.open}
                onClose={() => this.setState({ open: false })}
              >
                <Modal.Header>Histórico</Modal.Header>
                <Modal.Content>
                  <List>
                    {this.state.historico_reserva !== null &&
                      this.state.historico_reserva.map((e, i) => (
                        <List.Item>
                          <List.Icon
                            name="file alternate"
                            size="large"
                            verticalAlign="middle"
                            color="olive"
                          />
                          <List.Content>
                            <List.Header as="a">
                              Usuário: {e.usuario_nome}
                            </List.Header>
                            <List.Description>
                              Reserva: {e.servico_nome}
                            </List.Description>
                            <List.Description>
                              Data: {format(parseISO(e.data), "dd/MM/yyyy")}
                            </List.Description>
                            <List.Description>Hora: {e.hora}</List.Description>
                            <List.Description>Ação: {e.acao}</List.Description>
                            {e.valor_atual !== undefined && (
                              <List.Description>
                                {e.valor_atual > 1
                                  ? "Dias: " + e.valor_atual
                                  : "Dia: " + e.valor_atual}
                              </List.Description>
                            )}
                          </List.Content>
                        </List.Item>
                      ))}
                  </List>
                </Modal.Content>
                <Modal.Actions clearing>
                  <Button onClick={() => this.setState({ open: false })}>
                    Fechar
                  </Button>
                </Modal.Actions>
              </Modal>

              <Header floated="right">
                <Link to="/reservas">
                  <Button type="button">Voltar</Button>
                </Link>
                <Button
                  positive
                  onClick={this.submit}
                  disabled={this.state.salvando || !this.state.pessoa_id}
                // loading={this.state.salvando}
                >
                  Salvar
                </Button>
              </Header>
            </Segment>
          </div>
        ) : (
          <Dimmer active inverted>
            <Loader content="Carregando" />
          </Dimmer>
        )}

        <ModalReservar
          modalReservaOpen={this.state.modalReservaOpen}
          gerarTermos={this.gerarTermos}
          reservaEvento={this.state.reservaEvento}
          alteracao={this.alteracao}
          evento={this.state.evento}
          parametroEvento={this.state.parametroEvento}
          termos={this.state.termos}
          aceitaTermos={this.state.aceitaTermos}
          confirmaTermos={this.confirmaTermos}
          abreModalReserva={this.abreModalReserva}
          reservaEfetivacao={this.state.reservaEfetivacao}
          liberaReservaBotao={this.state.liberaReservaBotao}
          abreModalAlteraDias={this.abreModalAlteraDias}
          reservaEfetivada={this.reservaEfetivada}
          loadingReservaBotao={this.state.loadingReservaBotao}
          reservar={this.reservar}
          confirmaReserva={this.confirmaReserva}
          reservada={this.state.reservada}
        />

        <ModalCancelar
          modalOpenCancelar={this.state.modalOpenCancelar}
          alteracao={this.alteracao}
          abreModal={this.abreModal}
          cancelar={this.cancelar}
        />

        <ModalAlteraDias
          modalAlteraDiasOpen={this.state.modalAlteraDiasOpen}
          diasCancelamento={this.state.diasCancelamento}
          liberaReservaBotao={this.state.liberaReservaBotao}
          alteracao={this.alteracao}
          abreModalAlteraDias={this.abreModalAlteraDias}
          reservaEfetivada={this.reservaEfetivada}
          confirmaReserva={this.confirmaReserva}
        />

        <ModalCapacidade
          modalCapacidadeOpen={this.state.modalCapacidadeOpen}
          capacidade={this.state.capacidade}
        />

        <ModalRestricaoDias
          modalRestricaoDias={this.state.modalRestricaoDias}
          servico={this.state.servico}
        />

        <ModalReservaEfetivacao
          modalReservaEfetivacao={this.state.modalReservaEfetivacao}
          diasCancelamento={this.state.diasCancelamento}
        />

        <ModalErroHorarioReservado
          open={this.state.modalErroHorarioReservado}
        />

        <ModalRestricaoDiasGrupo
          open={this.state.modalRestricaoDiasGrupo}
          onClose={() => this.setState({ modalRestricaoDiasGrupo: false })}
          restricaoDiasGrupo={this.state.restricaoDiasGrupo}
        />
        <Modal
          open={this.state.open_divida}
          onClose={this.handleCloseAbertoCaixa}
          centered={false}
        >
          {localStorage.getItem("tipo_usuario_clube") === "administrador" ? (
            <Header
              content="Sócio com pendência financeira "
              style={{ textAlign: "center" }}
            />
          ) : (
            <Header
              content="Pendencia financeira a mais de 60 dias. "
              style={{ textAlign: "center" }}
            />
          )}

          {localStorage.getItem("tipo_usuario_clube") === "administrador" && (
            <Modal.Content>Sócio: {this.state.pessoa?.nome}</Modal.Content>
          )}
          <Modal.Content></Modal.Content>

          <div floated="right" style={{ marginLeft: "5px" }}>
            <div
              floated="right"
              style={{ marginLeft: "660px", marginTop: "-25px" }}
            >
              {localStorage.getItem("tipo_usuario_clube") ===
                "administrador" && <p>Deseja Continuar a reserva ?</p>}
            </div>

            {/* </div> */}
          </div>

          <Header floated="right">
            {localStorage.getItem("tipo_usuario_clube") === "administrador" ? (
              <Button
                variant="danger"
                onClick={this.handleCloseAbertoCaixa}
              // onClick={this.fechaCaixa}
              >
                Não
              </Button>
            ) : (
              <Button
                variant="danger"
                onClick={this.handleCloseAbertoCaixa}
              // onClick={this.fechaCaixa}
              >
                Sair
              </Button>
            )}

            {this.state.modalReservaSaloes === false &&
              (localStorage.getItem("tipo_usuario_clube") === "administrador" ||
                localStorage.getItem("tipo_usuario_clube") === "caixa" ||
                localStorage.getItem("tipo_usuario_clube") ===
                "administrativo") && (
                <Button
                  variant="success"
                  onClick={this.reservar}
                // onClick={this.fechaCaixa}
                >
                  Sim
                </Button>
              )}
            {this.state.modalReservaSaloes === true && (
              <Button
                variant="success"
                onClick={this.reservaEfetivada}
              // onClick={this.fechaCaixa}
              >
                Sim
              </Button>
            )}
          </Header>
        </Modal>
        <Snackbar
          open={this.state.mensagemSnackBar.show}
          autoHideDuration={this.state.mensagemSnackBar?.duration}
          anchorOrigin={{ vertical: "botton", horizontal: "center" }}
          onClose={() =>
            setTimeout(() => {
              this.setState({
                mensagemSnackBar: {
                  ...this.state.mensagemSnackBar,
                  show: false,
                },
              });
            }, 500)
          }
        >
          <Alert
            onClose={() =>
              setTimeout(() => {
                this.setState({
                  mensagemSnackBar: {
                    ...this.state.mensagemSnackBar,
                    show: false,
                  },
                });
              }, 500)
            }
            severity={
              this.state.mensagemSnackBar?.positive ? "success" : "error"
            }
            sx={{ width: "100%" }}
          >
            {this.state.mensagemSnackBar?.content}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}
