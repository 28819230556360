import React, { Component } from "react";
import {
  Checkbox,
  Button,
  Divider,
  Segment,
  Dimmer,
  Loader,
  Table,
  Modal,
  Container,
  Icon,
  Header,
} from "semantic-ui-react";

import { Row, Col, Form, FormControl } from "react-bootstrap";
import Select from "react-select";

import { Link } from "react-router-dom";
// Utils
import moment from "moment";

export default class Efetivacao extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  verificaPermissao(acao) {
    const tipoUsuario = localStorage.getItem("tipo_usuario_clube");
    switch (acao) {
      case "efetivarReserva":
        return (
          tipoUsuario === "caixa" ||
          tipoUsuario === "administrador" ||
          tipoUsuario === "administrativo"
        );
      case "cancelarReserva":
        return (
          tipoUsuario === "caixa" ||
          tipoUsuario === "administrador" ||
          tipoUsuario === "administrativo"
        );
      default:
        return false;
    }
  }

  render() {
    return (
      <div>
        {
          <div style={{ paddingTop: 10 }}>
            <Row>
              <Col>
                <Select
                  placeholder="Digite para buscar"
                  name="pessoa"
                  value={this.props.pessoa}
                  onChange={this.props.alteracaoSelectPessoa}
                  options={this.props.options}
                  onInputChange={(e, a) => {
                    this.props.pesquisaPessoa();
                  }}
                  inputId="pesquisaPessoa"
                  isClearable
                />
              </Col>
              <Col md="auto">
                <FormControl
                  type="date"
                  aria-describedby="basic-addon1"
                  name="dataBuscaEfetivas"
                  defaultValue={this.props.dataBuscaEfetivas}
                  onChange={this.props.alteracao}
                  disabled={!this.props.checkboxBuscarData}
                />
              </Col>
              <Col
                md="auto"
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 0,
                }}
              >
                <Checkbox
                  checked={this.props.checkboxBuscarData}
                  onChange={(e, a) =>
                    this.props.setState({ checkboxBuscarData: a.checked })
                  }
                />
              </Col>
              <Col sm={2}>
                <Form.Control
                  placeholder="Busca"
                  label="Filtro"
                  id="tipo"
                  as="select"
                  name="selectReservaEfetivacao"
                  onChange={this.props.alteracao}
                  value={this.props.selectReservaEfetivacao}
                >
                  <option value="null">Todos</option>
                  <option value="efetivada">Efetivadas</option>
                  <option value="cancelado">Canceladas</option>
                </Form.Control>
              </Col>
              <Col sm={1}>
                <Button
                  type="button"
                  size="tiny"
                  primary
                  icon="search"
                  onClick={() => this.props.buscaReservasEfetivadaAdmin()}
                ></Button>
              </Col>
            </Row>
            <Divider />
            <h2>Reservas de Hoje</h2>
            {this.props.loadingListaReservaEfetivacaoAdmin === true ? (
              <div style={{ paddingTop: 10, height: 60 }}>
                <Segment size="massive">
                  <Dimmer active inverted>
                    <Loader active inverted inline />
                  </Dimmer>
                </Segment>
              </div>
            ) : (
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Titulo</Table.HeaderCell>
                    <Table.HeaderCell>Socio</Table.HeaderCell>
                    <Table.HeaderCell>Dependencia</Table.HeaderCell>
                    <Table.HeaderCell>Data Reserva</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      Detalhes
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      Lista Conv.
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      Lista Forn.
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Imp.</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                {this.props.listaReservasEfetivacaoAdmin.map((e, i) => (
                  <Table.Body>
                    <Table.Row
                      key={i}
                      positive={e.efetivado}
                      negative={e.cancelado}
                    >
                      <Table.Cell>
                        <div style={{ fontWeight: "bold" }}>
                          {e.pessoa && e.pessoa.acao
                            ? e.pessoa.acao.codigo
                              ? (e.pessoa.acao.tipo_titulo.prefixo || "") +
                                " " +
                                e.pessoa.acao?.codigo
                              : (e.pessoa.acao.tipo_titulo?.prefixo || "") +
                                " " +
                                (e.pessoa.acao?.codigo_auxiliar || "")
                            : " "}
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <div style={{ fontWeight: "bold" }}>
                          {e.pessoa.nome}
                        </div>
                      </Table.Cell>
                      <Table.Cell>{e.nome}</Table.Cell>
                      <Table.Cell>
                        {moment(e.dataReserva).format("DD/MM/YYYY")}
                      </Table.Cell>
                      <Table.Cell width={1} textAlign="center">
                        <Modal
                          closeIcon
                          trigger={<Button icon="expand" color="blue"></Button>}
                        >
                          <Modal.Content>
                            <Container textAlign="center">
                              <Row>
                                <Col sm={6}>
                                  <Form.Label>
                                    Nome responsável reserva
                                  </Form.Label>
                                  <FormControl
                                    placeholder="Nome"
                                    value={e.reservado_responsavel.nome}
                                    disabled
                                  />
                                </Col>
                                <Col sm={3}>
                                  <Form.Label>Data reserva</Form.Label>
                                  <FormControl
                                    placeholder="Horario"
                                    value={e.dataLancamento}
                                    disabled
                                    type="date"
                                  />
                                </Col>
                                <Col sm={3}>
                                  <Form.Label>Hora reserva</Form.Label>
                                  <FormControl
                                    placeholder="Horario"
                                    value={e.horario_reservou}
                                    disabled
                                    type="hora"
                                  />
                                </Col>
                                <Col sm={6}>
                                  <Form.Label>
                                    Nome responsável reserva
                                  </Form.Label>
                                  <FormControl
                                    placeholder="Nome"
                                    value={e.reservado_responsavel.nome}
                                    disabled
                                  />
                                </Col>
                                <Col sm={3}>
                                  <Form.Label>Data reservada</Form.Label>
                                  <FormControl
                                    placeholder="Horario"
                                    value={e.dataReserva}
                                    disabled
                                    type="date"
                                  />
                                </Col>
                                <Col sm={3}>
                                  <Form.Label>Hora reserva</Form.Label>
                                  <FormControl
                                    placeholder="Horario"
                                    value={e.horario_reservou}
                                    disabled
                                    type="hora"
                                  />
                                </Col>
                                <Col sm={6}>
                                  <Form.Label>
                                    Nome responsável cancelamento
                                  </Form.Label>
                                  <FormControl
                                    placeholder="Nome"
                                    value={e.cancelada_responsavel.nome}
                                    disabled
                                  />
                                </Col>
                                <Col sm={3}>
                                  <Form.Label>Data cancelamento</Form.Label>
                                  <FormControl
                                    placeholder="Horario"
                                    value={e.dataCancelamento}
                                    disabled
                                    type="date"
                                  />
                                </Col>
                                <Col sm={3}>
                                  <Form.Label>Hora cancelamento</Form.Label>
                                  <FormControl
                                    placeholder="Horario"
                                    value={e.horario_cancelamento}
                                    disabled
                                    type="hora"
                                  />
                                </Col>
                                <Col sm={6}>
                                  <Form.Label>
                                    Nome responsável efetivação
                                  </Form.Label>
                                  <FormControl
                                    placeholder="Nome"
                                    value={e.efetivada_responsavel.nome}
                                    disabled
                                  />
                                </Col>
                                <Col sm={3}>
                                  <Form.Label>Data efetivação</Form.Label>
                                  <FormControl
                                    placeholder="Date"
                                    value={e.dataEfetivacao}
                                    disabled
                                    type="date"
                                  />
                                </Col>
                                <Col sm={3}>
                                  <Form.Label>Hora efetivação</Form.Label>
                                  <FormControl
                                    placeholder="Horario"
                                    value={e.horario_efetivada}
                                    disabled
                                    type="hora"
                                  />
                                </Col>
                                <Col sm={6}>
                                  <Form.Label>
                                    Nome responsável transferência
                                  </Form.Label>
                                  <FormControl
                                    placeholder="Nome"
                                    value={e.transferiu_responsavel.nome}
                                    disabled
                                  />
                                </Col>
                                <Col sm={3}>
                                  <Form.Label>Data transferência</Form.Label>
                                  <FormControl
                                    placeholder="Date"
                                    value={e.data_transferecia}
                                    disabled
                                    type="date"
                                  />
                                </Col>
                                <Col sm={3}>
                                  <Form.Label>Hora transferência</Form.Label>
                                  <FormControl
                                    placeholder="Horario"
                                    value={e.horario_transferiu}
                                    disabled
                                    type="hora"
                                  />
                                </Col>
                              </Row>
                            </Container>
                          </Modal.Content>
                        </Modal>
                      </Table.Cell>
                      <Table.Cell collapsing textAlign="center">
                        {e.efetivado !== false && (
                          <Modal
                            closeIcon
                            size="small"
                            trigger={
                              <Button
                                onClick={() =>
                                  this.props.carregaListaConvidados(
                                    e.reserva_horario_id
                                  )
                                }
                                icon="clipboard list"
                                color="blue"
                              ></Button>
                            }
                          >
                            <Modal.Content>
                              <Container
                                textAlign="right"
                                style={{ paddingBottom: 10 }}
                              >
                                <Button
                                  fluid={false}
                                  color={"blue"}
                                  onClick={() =>
                                    this.props.imprimirListaConvidados(e)
                                  }
                                >
                                  Imprimir
                                </Button>
                              </Container>
                              <Container textAlign="center">
                                <Table celled>
                                  <Table.Header>
                                    <Table.Row>
                                      <Table.HeaderCell>Nome</Table.HeaderCell>
                                      <Table.HeaderCell>
                                        Vínculo
                                      </Table.HeaderCell>
                                      <Table.HeaderCell
                                        width={1}
                                        textAlign="center"
                                      >
                                        <Icon name="clipboard list" />
                                      </Table.HeaderCell>
                                    </Table.Row>
                                  </Table.Header>
                                  <Table.Body>
                                    {this.props.listaPresencaReservas.map(
                                      (presenca, i) => (
                                        <Table.Row key={i}>
                                          <Table.Cell>
                                            <FormControl
                                              required
                                              name="nome"
                                              fluid
                                              value={presenca.nome}
                                              disabled
                                            />
                                          </Table.Cell>
                                          <Table.Cell>
                                            <FormControl
                                              required
                                              name="parentesco"
                                              fluid
                                              value={presenca.parentesco}
                                              disabled
                                            />
                                          </Table.Cell>
                                          <Table.Cell>
                                            {presenca.presente !== false ? (
                                              <Button
                                                fluid
                                                positive
                                                icon="clipboard check"
                                                size="tiny"
                                              ></Button>
                                            ) : (
                                              <Button
                                                fluid
                                                color="yellow"
                                                icon="clipboard"
                                                size="tiny"
                                                onClick={() =>
                                                  this.props.confirmaPresenca(
                                                    presenca,
                                                    e.reserva_id
                                                  )
                                                }
                                              ></Button>
                                            )}
                                          </Table.Cell>
                                        </Table.Row>
                                      )
                                    )}
                                  </Table.Body>
                                </Table>
                              </Container>
                            </Modal.Content>
                          </Modal>
                        )}
                      </Table.Cell>
                      <Table.Cell collapsing>
                        <Modal
                          closeIcon
                          size="small"
                          trigger={
                            <Button
                              onClick={() =>
                                this.props.carregaListaFornecedor(e.reserva_id)
                              }
                              icon="clipboard list"
                              color="blue"
                            ></Button>
                          }
                        >
                          <Modal.Content>
                            <Container
                              textAlign="right"
                              style={{ paddingBottom: 10 }}
                            >
                              <Button
                                fluid={false}
                                color={"blue"}
                                onClick={() =>
                                  this.props.imprimirListaFornecedor(e)
                                }
                              >
                                Imprimir
                              </Button>
                            </Container>
                            <Container textAlign="center">
                              <Table celled>
                                <Table.Header>
                                  <Table.Row>
                                    <Table.HeaderCell>Nome</Table.HeaderCell>
                                    <Table.HeaderCell>Função</Table.HeaderCell>
                                    <Table.HeaderCell
                                      width={1}
                                      textAlign="center"
                                    >
                                      <Icon name="clipboard list" />
                                    </Table.HeaderCell>
                                  </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                  {this.props.listaFornecedorReserva.map(
                                    (presenca, i) => (
                                      <Table.Row key={i}>
                                        <Table.Cell>
                                          <FormControl
                                            required
                                            name="nome"
                                            fluid
                                            value={presenca.nome}
                                            disabled
                                          />
                                        </Table.Cell>
                                        <Table.Cell>
                                          <FormControl
                                            required
                                            name="parentesco"
                                            fluid
                                            value={presenca.funcao}
                                            disabled
                                          />
                                        </Table.Cell>
                                        <Table.Cell>
                                          {presenca.presente !== false ? (
                                            <Button
                                              fluid
                                              positive
                                              icon="clipboard check"
                                              size="tiny"
                                            ></Button>
                                          ) : (
                                            <Button
                                              fluid
                                              color="yellow"
                                              icon="clipboard"
                                              size="tiny"
                                              onClick={() => {
                                                this.props.confirmaPresencaFornecedor(
                                                  presenca,
                                                  e.reserva_id
                                                );
                                              }}
                                            ></Button>
                                          )}
                                        </Table.Cell>
                                      </Table.Row>
                                    )
                                  )}
                                </Table.Body>
                              </Table>
                            </Container>
                          </Modal.Content>
                        </Modal>
                      </Table.Cell>
                      <Table.Cell width={1} textAlign="center">
                        <Button
                          icon="print"
                          color="blue"
                          onClick={() =>
                            this.props.imprimirContratoEfetivacao(e)
                          }
                        ></Button>
                      </Table.Cell>
                    </Table.Row>
                    {e.evento !== null && (
                      <Table.Row>
                        <Table.Cell colSpan={7} style={{ borderTop: 0 }}>
                          Evento: {e.evento}
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                ))}
              </Table>
            )}
          </div>
        }

        {/*




          -------------   Modal aqui  ----------------





        */}
        {this.props.pessoa_reserva_efetivada_id !== null &&
          this.props.open_modal_cobranca && (
            <Modal open={true} size="tiny">
              <Modal.Content>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h2>Reserva efetivada com sucesso!</h2>
                  <h3>Deseja cobrar essa reserva efetivada no caixa?</h3>
                </Row>
                <Container style={{ paddingTop: 40 }}>
                  <Row>
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        color="red"
                        size="large"
                        onClick={() => (window.location.href = "/reservas")}
                      >
                        Não
                      </Button>
                    </Col>
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Link
                        to={{
                          pathname: "/caixa",
                          query: {
                            id: this.props.pessoa_reserva_efetivada_id,
                          },
                          from: "reservas",
                        }}
                      >
                        <Button color="green" size="large">
                          Sim
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </Container>
              </Modal.Content>
            </Modal>
          )}
        <div style={{ paddingTop: 10, paddingBottom: 100 }}>
          <Divider />
          {this.props.reservasEfetivacao !== false && (
            <>
              <h2>Reservas Aguardando Efetivação</h2>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Pessoa</Table.HeaderCell>
                    <Table.HeaderCell>Nome</Table.HeaderCell>
                    <Table.HeaderCell>Data Reserva</Table.HeaderCell>
                    <Table.HeaderCell width={2}>
                      {"Dias Restantes Para Efetivação"}
                    </Table.HeaderCell>
                    {(localStorage.getItem("tipo_usuario_clube") === "caixa" ||
                      localStorage.getItem("tipo_usuario_clube") ===
                        "administrador" ||
                      localStorage.getItem("tipo_usuario_clube") ===
                        "administrativo") && (
                      <Table.HeaderCell textAlign="center">
                        Efetiva
                      </Table.HeaderCell>
                    )}
                    {(localStorage.getItem("tipo_usuario_clube") === "caixa" ||
                      localStorage.getItem("tipo_usuario_clube") ===
                        "administrador" ||
                      localStorage.getItem("tipo_usuario_clube") ===
                        "administrativo") && (
                      <Table.HeaderCell textAlign="center">
                        Cancela
                      </Table.HeaderCell>
                    )}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.listaReservasEfetivacao.map((e, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{e.pessoa?.nome}</Table.Cell>
                      <Table.Cell>
                        {e.reserva_horario.horario_servico.servico.nome}
                      </Table.Cell>
                      <Table.Cell>
                        {moment(e.dataReserva).format("DD/MM/YYYY")}
                      </Table.Cell>
                      <Table.Cell>
                        {e.parametrosReservaEfetivadaDia === null ||
                        e.parametrosReservaEfetivadaDia === undefined
                          ? "inf"
                          : e.parametrosReservaEfetivadaDia.diasCancelamento -
                            moment().diff(moment(e.dataLancamento), "days")}
                      </Table.Cell>
                      {this.verificaPermissao("efetivarReserva") && (
                        <Table.Cell width={1} textAlign="center">
                          <Modal
                            open={
                              this.props.modalOpenEfetiva === e.id ||
                              this.props.reserva_efetivada_id === e.id
                            }
                            close={!this.props.modalOpenEfetiva}
                            onMount={() =>
                              this.props.setState({
                                valorEfetivacao:
                                  e.reserva_horario.horario_servico.servico
                                    .valor,
                              })
                            }
                            onClose={() =>
                              this.props.setState({
                                reserva_efetivada_id: null,
                              })
                            }
                            closeIcon
                            trigger={
                              <Button
                                positive
                                icon="calendar check"
                                onClick={() =>
                                  this.props.abreModalEfetiva(
                                    e.id,
                                    e.reserva_horario.horario_servico.servico
                                      .valor,
                                    null
                                  )
                                }
                              ></Button>
                            }
                          >
                            <Header icon>
                              <Icon name="checkmark" />
                              Efetivar Reserva
                            </Header>
                            <Modal.Content>
                              <Row>
                                <Col sm={6}>
                                  <Form.Label>Valor</Form.Label>
                                  <FormControl
                                    name="valorEfetivacao"
                                    fluid
                                    onChange={this.props.alteracao}
                                    value={this.props.valorEfetivacao}
                                    type="number"
                                  />
                                </Col>
                                <Col sm={6}>
                                  <Form.Label>Data Vencimento</Form.Label>
                                  <FormControl
                                    name="dataVencimentoEfetivacao"
                                    fluid
                                    type="date"
                                    onChange={this.props.alteracao}
                                    value={this.props.dataVencimentoEfetivacao}
                                  />
                                </Col>
                              </Row>
                            </Modal.Content>
                            <Modal.Actions>
                              <Button
                                color="red"
                                inverted
                                onClick={() =>
                                  this.props.abreModalEfetiva(false)
                                }
                                disabled={this.props.efetivacaoReserva}
                                loading={this.props.efetivacaoReserva}
                              >
                                <Icon name="remove" /> Fechar
                              </Button>
                              {console.log("this.props.efetivacaoReserva",this.props.efetivacaoReserva)}
                              <Button
                                positive
                                onClick={() =>
                                  this.props.efetivaReserva(
                                    e.id,
                                    e.reserva_horario.horario_servico.servico
                                    .id,
                                    e.reserva_horario.id,
                                    e.pessoa.id
                                    )
                                  }
                                  disabled={this.props.efetivacaoReserva}
                                  loading={this.props.efetivacaoReserva}
                              >
                                <Icon name="checkmark" /> Sim
                              </Button>
                            </Modal.Actions>
                          </Modal>
                        </Table.Cell>
                      )}
                      {this.verificaPermissao("cancelarReserva") && (
                        <Table.Cell width={1} textAlign="center">
                          <Modal
                            open={this.props.modalOpen === e.id}
                            closeIcon
                            trigger={
                              <Button
                                negative
                                icon="calendar times"
                                onClick={() => this.props.abreModal(e.id)}
                              ></Button>
                            }
                          >
                            <Header icon>
                              <Icon name="warning sign" />
                              Deseja cancelar a reserva?
                            </Header>
                            <Modal.Content>
                              <Header>Motivo</Header>
                              <FormControl
                                name="motivo"
                                fluid
                                onChange={this.props.alteracao}
                              />
                            </Modal.Content>
                            <Modal.Actions>
                              <Button
                                color="red"
                                inverted
                                onClick={() => this.props.abreModal(false)}
                              >
                                <Icon name="remove" /> Fechar
                              </Button>
                              <Button
                                color="green"
                                inverted
                                onClick={() =>
                                  this.props.cancelaEfetivaReserva(
                                    e.id,
                                    e.reserva_horario_id
                                  )
                                }
                              >
                                <Icon name="checkmark" /> Sim
                              </Button>
                            </Modal.Actions>
                          </Modal>
                        </Table.Cell>
                      )}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </>
          )}
        </div>
      </div>
    );
  }
}
