/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Header, Segment, Divider, Table, Modal } from "semantic-ui-react";
import { Form, Col, Row } from "react-bootstrap";
import {
  IconButton,
  TextField,
  Button,
  Snackbar,
  Alert,
} from "@material-ui/core/";
import { Delete, Edit, AddCircle } from "@material-ui/icons/";

//requisição
import requisicoes from "../../../utils/Requisicoes";
import api from "../../../services/api";

import ModalTipoEvento from "./modalTipoEvento";

import { Link } from "react-router-dom";

import "../styles.css";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descricao: [],
      quantidade: 1,
      nomeEvento: "",
      eventosCriados: [],
      mensagemSnackBar: { show: false },
      mensagem: false,
      mensagemErro: false,

      openModalEvento: false,
    };

    this.listaTiposdeEvento();
  }

  alteracao = async (event, index) => {
    var lista = [...this.state.descricao];

    if (!lista[index]) {
      lista[index] = {};
    }
    lista[index].descricao = event.target.value;
    this.setState({ descricao: lista });
  };

  alteraEvento = (evento) => {
    let lista = this.state.eventosCriados;
    let index = this.state.eventosCriados.findIndex((e) => e.id === evento.id);
    if (index !== -1) {
      lista[index].descricao = evento.descricao;
      this.setState({ mensagem: true });
    }
    this.setState({ eventosCriados: lista });
  };

  alterarLista = async () => {
    this.setState({ openModal: true });
  };

  deleteLista = async (event) => {
    let index = this.state.eventosCriados.indexOf(event);
    try {
      let confimar = window.confirm("Tem certeza que deseja excluir?");
      if (confimar) {
        const editaListaEvento = await api.post(
          "/tipoeventodesporto/excluir" + requisicoes.entidade,
          { id: event.id, descricao: event.descricao },
          requisicoes.header
        );
        if (index !== -1) {
          this.state.eventosCriados.splice(index, 1);
        }
        this.setState({ eventosCriados: this.state.eventosCriados });
      }
    } catch (error) {
      if (error.response.data !== "") {
        alert(error.response.data);
      } else {
        console.log("error", error);
      }
    }
  };

  listaTiposdeEvento = async () => {
    const eventos = await api.post(
      "/tipoeventodesporto/listar" + requisicoes.entidade,
      { descricao: this.state.descricao },
      requisicoes.header
    );

    this.setState({ eventosCriados: eventos.data });
  };

  adicionaLinha = async () => {
    this.setState({ quantidade: this.state.quantidade + 1 });
  };

  salvar = async (event) => {
    const evento = await api.post(
      "/tipoeventodesporto/salvar" + requisicoes.entidade,
      { descricao: this.state.descricao },
      requisicoes.header
    );
  };

  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Form onSubmit={this.salvar}>
          <Segment clearing>
            <Header>
              <div className="flexSpaceBetween">
                <div>{"Eventos "}</div>
                <div>
                  <IconButton
                    size="mini"
                    className="bottomModal"
                    color="primary"
                    onClick={() => this.adicionaLinha()}
                  >
                    <AddCircle />
                  </IconButton>
                </div>
              </div>

              <Divider style={{ marginTop: "-0.2ch" }} />
            </Header>

            {Array.from({ length: this.state.quantidade }).map(
              (element, index) => {
                return (
                  <Row>
                    <Col>
                      <TextField
                        variant="standard"
                        name="descricao"
                        id="descricao"
                        label="descricao"
                        placeholder="..."
                        onChange={(event) => this.alteracao(event, index)}
                      ></TextField>
                    </Col>
                  </Row>
                );
              }
            )}
          </Segment>

          <Table striped selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="left">DESCRIÇÃO</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.eventosCriados.length > 0
                ? this.state.eventosCriados.map((element, index) => {
                    return (
                      <Table.Row key={index}>
                        <Table.Cell sm={"auto"}>{element.descricao}</Table.Cell>

                        <Table.Cell collapsing>
                          <IconButton
                            style={{ marginRight: "25px" }}
                            aria-label="add"
                            size="small"
                            onClick={() => {
                              this.alterarLista(element);

                              this.setState({
                                evento_id: element.id,
                                evento_nome: element.descricao,
                                openModalEvento: true,
                              });
                            }}
                          >
                            <Edit color="primary" />
                          </IconButton>

                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={(e) => this.deleteLista(element)}
                          >
                            <Delete color="error" />
                          </IconButton>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                : ""}
            </Table.Body>
          </Table>
          <Segment clearing>
            <Header floated="right">
              <Button
                type="submit"
                style={{
                  outline: "none",
                }}
                color="primary"
              >
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>
        <ModalTipoEvento
          openModal={this.state.openModalEvento}
          changeOpenModal={(openModal) => {
            this.setState({ openModalEvento: openModal });
          }}
          changeOpenSnackbar={(openSnackbar, error) => {
            this.setState({ mensagem: openSnackbar, mensagemErro: error });
          }}
          evento_id={this.state.evento_id}
          evento_nome={this.state.evento_nome}
          alteraEvento={this.alteraEvento}
        />
        <Snackbar
          open={this.state.mensagem}
          autoHideDuration={3000}
          onClose={() => {
            this.setState({ mensagem: false });
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          fullWidth
        >
          <Alert
            variant="filled"
            severity={this.state.mensagemErro ? "error" : "success"}
            onClose={() => {
              this.setState({ mensagem: false });
            }}
            onError={() =>
              setTimeout(() => {
                this.setState({ mensagem: false });
              }, 1000)
            }
          >
            {this.state.mensagemErro ? "Erro ao salvar" : "Salvo com sucesso"}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}
