import React, { Component } from "react";
import {
  Container,
  Header,
  Button,
  Divider,
  Segment,
  Table,
  Checkbox,
  Modal,
  Icon,
  Popup,
} from "semantic-ui-react";
import { Row, Col, FormControl, Form, InputGroup } from "react-bootstrap";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import ModalServicoMensalidade from "./modalServicoMensalidade";
import ModalServicoInscricao from "./modalServicoInscricao";
import $ from "jquery";
import InputMask from "react-input-mask";
import ReplaceAll from "replaceall";

var formatter = new Intl.NumberFormat("pt-br", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export default class servicoTurma extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      descGrupo: null,
      descTurma: null,
      qtdTurma: "",
      dataInicio: "",
      dataFinal: "",
      listaTurmas: [],
      valorMensalidade: null,
      valorInscricao: null,
      diasFiltro: [],
      diasEspeciais: [],
      diasDaSemana: [],
      gruposServico: [],
      grupo_servico: null,
      modalOpen: false,
      open_modal_servico_mensalidade: null,
      servico_mensalidade: null,
      servico_mensalidade_nome: null,
      open_modal_servico_inscricao: null,
      servico_inscricao: null,
      servico_inscricao_nome: null,
      academia: false,
    };
    this.alteracao = this.alteracao.bind(this);
    this.buscaDiasDaSemana = this.buscaDiasDaSemana.bind(this);
    this.buscaDiasDaSemana();
    this.listaGrupoServico();
    this.visualizacao();
  }

  componentMoney() {
    $(".dinheiro").mask("#.##0,00", {
      reverse: true,
      placeholder: "0,00",
    });
  }

  fecharModalMensalidade = () => {
    this.setState({ open_modal_servico_mensalidade: false });
  };

  abrirModalMensalidade = () => {
    this.setState({ open_modal_servico_mensalidade: true });
  };

  alteracaoSelectServicoMensalidade = (e) => {
    this.setState({ valorMensalidade: e.valor });
    this.setState({ servico_mensalidade: e });
  };

  fecharModalInscricao = () => {
    this.setState({ open_modal_servico_inscricao: false });
  };

  abrirModalInscricao = () => {
    this.setState({ open_modal_servico_inscricao: true });
  };

  fecharModalInscricaoZeraInscricao = () => {
    this.setState({ servico_inscricao: null });
    this.setState({ open_modal_servico_inscricao: false });
  };

  fecharModalMensalidadeZeraMensalidade = () => {
    this.setState({ servico_mensalidade: null });
    this.setState({ open_modal_servico_mensalidade: false });
  };

  alteracaoSelectServicoInscricao = (e) => {
    this.setState({ valorInscricao: e.valor });
    this.setState({ servico_inscricao: e });
  };

  visualizacao = async () => {
    if (typeof this.props.location.query != "undefined") {
      var localStorage = window.localStorage;
      var token = localStorage.getItem("token_clube");
      var e = localStorage.getItem("e_clube");
      const resposta = await api.post(
        "/servico/visualiza?e=" + e + "&id=" + this.props.location.query.id,
        this.props.location.query,
        { headers: { token: "Baer " + token } }
      );
      this.setState({
        id: resposta.data.id,
        descTurma: resposta.data.nome,
        grupo_servico: resposta.data.grupo_servico_id,
        dataInicio: resposta.data.dataInicio,
        dataFinal: resposta.data.dataFinal,
        servico_inscricao: resposta.data.servico_inscricao,
        servico_inscricao_nome:
          resposta.data.servico_inscricao === null
            ? ""
            : resposta.data.servico_inscricao.nome,
        servico_mensalidade: resposta.data.servico_mensalidade,
        servico_mensalidade_nome:
          resposta.data.servico_mensalidade === null
            ? ""
            : resposta.data.servico_mensalidade.nome,
        valorInscricao: parseFloat(resposta.data.valorInscricao),
        valorMensalidade: parseFloat(resposta.data.valorMensalidade),
      });

      const turmas = await api.post(
        "/turmaServico/turmaPorServico?e=" + e,
        { id: resposta.data.id },
        { headers: { token: "Baer " + token } }
      );

      this.setState({ qtdTurma: turmas.data.length });

      const dias = await api.post(
        "/diaDaSemanan/lista?e=" + e,
        this.props.location.query,
        { headers: { token: "Baer " + token } }
      );

      let listaTurmas = turmas.data;
      let listaRetorno = [];
      listaTurmas.forEach((turma) => {
        let diasDaSemana = [];
        let diasFiltro = turma.dias_da_semana;
        for (var i = 0; i < dias.data.lenght; i++) {
          diasDaSemana.push(false);
        }
        turma.dias_da_semana.forEach((element) => {
          switch (element.nome) {
            case "Domingo":
              diasDaSemana[0] = true;
              break;
            case "Segunda":
              diasDaSemana[1] = true;
              break;
            case "Terça":
              diasDaSemana[2] = true;
              break;
            case "Quarta":
              diasDaSemana[3] = true;
              break;
            case "Quinta":
              diasDaSemana[4] = true;
              break;
            case "Sexta":
              diasDaSemana[5] = true;
              break;
            case "Sabado":
              diasDaSemana[6] = true;
              break;
            default:
              break;
          }
        });
        listaRetorno.push({
          id: turma.id,
          horaInicio: turma.horaInicio,
          horaFim: turma.horaFim,
          vaga: turma.vaga,
          turma: turma.turma,
          nome: turma.nome,
          diasDaSemana: diasDaSemana,
          diasFiltro: diasFiltro,
        });
      });

      this.setState({ listaTurmas: listaRetorno });
    }
  };

  listaGrupoServico = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var e = localStorage.getItem("e_clube");
    const resposta = await api.post(
      "/grupoServico/lista?e=" + e,
      this.props.location.query,
      { headers: { token: "Baer " + token } }
    );
    this.setState({ gruposServico: resposta.data });
  };

  alteracao(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  alteracaoHorarioInicio = async (value, index) => {
    let listaTurmas = this.state.listaTurmas;
    listaTurmas[index].horaInicio = value;
    this.setState({ listaTurmas: listaTurmas });
  };

  alteracaoHorarioFim = async (value, index) => {
    let listaTurmas = this.state.listaTurmas;
    listaTurmas[index].horaFim = value;
    this.setState({ listaTurmas: listaTurmas });
  };

  alteracaoVaga = async (value, index) => {
    let listaTurmas = this.state.listaTurmas;
    listaTurmas[index].vaga = value;
    this.setState({ listaTurmas: listaTurmas });
  };

  alteracaoNome = async (value, index) => {
    let listaTurmas = this.state.listaTurmas;
    listaTurmas[index].nome = value;
    this.setState({ listaTurmas: listaTurmas });
  };

  alteracaoTurma = async (value, index) => {
    let listaTurmas = this.state.listaTurmas;
    listaTurmas[index].turma = value;
    this.setState({ listaTurmas: listaTurmas });
  };

  alteracaoDias = async (value, index) => {
    let listaTurmas = this.state.listaTurmas;
    this.state.diasDaSemana.forEach((e, i) => {
      if (e.id === parseInt(value)) {
        let lista = Array.from(listaTurmas[index].diasDaSemana);
        if (lista[i] === true) {
          lista[i] = false;
        } else {
          lista[i] = true;
        }
        listaTurmas[index].diasDaSemana = lista;
        let diasFiltro = Array.from(listaTurmas[index].diasFiltro);

        if (!diasFiltro.find((e) => e.id === parseInt(value))) {
          diasFiltro.push({
            id: value,
            nome: e.nome,
            inicial: e.inicial,
          });
        } else {
          let indice = diasFiltro.findIndex(
            (element) => element.id === parseInt(value)
          );
          diasFiltro.splice(indice, 1);
        }
        listaTurmas[index].diasFiltro = diasFiltro;
      }
    });
    this.setState({ listaTurmas: listaTurmas });
  };

  handleSubmit = (event) => {
    this.submit();
    event.preventDefault();
  };

  excluir = async (turma, i) => {
    let lista = this.state.listaTurmas;
    let listaRetorno = [];

    if (turma.id !== "") {
      var localStorage = window.localStorage;
      var token = localStorage.getItem("token_clube");
      var e = localStorage.getItem("e_clube");
      await api.post(
        "/turmaServico/excluir?e=" + e + "&id=" + turma.id,
        {},
        { headers: { token: "Baer " + token } }
      );
      const set = new Set(lista);
      set.delete(turma);
      set.forEach((element) => {
        listaRetorno.push(element);
      });
    } else {
      listaRetorno = this.state.listaTurmas;
      listaRetorno.splice(i, 1);
    }
    this.setState({ listaTurmas: listaRetorno });
  };

  adicionar = async () => {
    let lista = this.state.listaTurmas;
    lista.push({
      id: "",
      horaInicio: "",
      horaFim: "",
      vaga: "",
      turma: "",
      nome: this.state.descTurma,
      diasDaSemana: [],
      diasFiltro: [],
    });
    this.setState({ listaTurmas: lista });
  };

  submit = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    let valorInscricao = "";
    valorInscricao = this.state.valorInscricao;
    let valorMensalidade = this.state.valorMensalidade;
    if (typeof this.state.valorInscricao === "string") {
      valorInscricao = ReplaceAll(".", "", this.state.valorInscricao);
      valorInscricao = ReplaceAll(",", ".", valorInscricao);
    }
    if (typeof this.state.valorMensalidade === "string") {
      valorMensalidade = ReplaceAll(
        ".",
        "",
        this.state.valorMensalidade.toString()
      );
      valorMensalidade = ReplaceAll(",", ".", valorMensalidade);
    }
    const dados = {
      id: this.state.id,
      descTurma: this.state.descTurma,
      dataInicio: this.state.dataInicio,
      dataFinal: this.state.dataFinal,
      listaTurmas: this.state.listaTurmas,
      valorInscricao: valorInscricao,
      valorMensalidade: valorMensalidade,
      grupo_servico_id: this.state.grupo_servico,
      servico_mensalidade_id:
        this.state.servico_mensalidade === null
          ? null
          : this.state.servico_mensalidade.value,
      servico_inscricao_id:
        this.state.servico_inscricao === null
          ? null
          : this.state.servico_inscricao.value,
      academia: this.state.academia,
    };

    if (dados.valorInscricao !== "" && dados.servico_inscricao_id === null) {
      alert("Favor Informar um Serviço");
      return;
    } else {
      if (
        dados.valorMensalidade !== "" &&
        dados.servico_mensalidade_id === null
      ) {
        alert("Favor Informar um Mensalidade");
        return;
      } else {
        let retorno;
        if (this.state.id !== "") {
          retorno = await api.post(
            "/servico/editarTurmas?e=" + entidade,
            dados,
            {
              headers: { token: "Baer " + token },
            }
          );
        } else {
          retorno = await api.post(
            "/grupoServico/salvarTurma?e=" + entidade,
            dados,
            { headers: { token: "Baer " + token } }
          );
        }
        if (retorno !== null) {
          window.location.href = "/servicos/lista";
        }
      }
    }
  };

  criaTurmas = () => {
    var quantidade = this.state.qtdTurma;
    let lista = [];
    for (let index = 0; index < quantidade; index++) {
      lista.push({
        id: "",
        horaInicio: "",
        horaFim: "",
        vaga: "",
        turma: "",
        nome: this.state.descTurma,
        diasDaSemana: [],
        diasFiltro: [],
      });
    }
    this.setState({ listaTurmas: lista });
  };

  buscaDiasDaSemana = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var e = localStorage.getItem("e_clube");
    const resposta = await api.post(
      "/diaDaSemanan/lista?e=" + e,
      this.props.location.query,
      { headers: { token: "Baer " + token } }
    );
    for (var i = 0; i < resposta.data.lenght; i++) {
      this.state.selectDiasDaSemana.push(false);
    }
    this.setState({ diasDaSemana: resposta.data });
  };

  salvaGrupo = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const grupo = await api.post(
      "/grupoServico/salvarDescricao?e=" + entidade,
      {
        descGrupo: this.state.descGrupo,
      },
      { headers: { token: "Baer " + token } }
    );
    this.listaGrupoServico();
    this.setState({ grupo_servico: grupo.data.id });
    this.setState({ modalOpen: false });
  };

  abreModal = async (e) => {
    this.setState({ modalOpen: e });
  };

  alteracaoServicoInscricao = async (event) => {
    this.setState({ servico_inscricao_nome: event });
  };

  alteracaoServicoMensalidade = async (event) => {
    this.setState({ servico_mensalidade_nome: event });
  };

  onChangeCheckboxAcademia = (evt, data) => {
    this.setState({ academia: data.checked });
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Grupo Serviço
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={5} style={{ paddingRight: 0 }}>
              <Form.Label>Grupo De Serviço</Form.Label>
              <Form.Control
                as="select"
                name="grupo_servico"
                checked={this.state.grupo_servico}
                onChange={this.alteracao}
                defaultValue={this.state.grupo_servico}
                required
              >
                <option></option>
                {this.state.gruposServico.map((e, i) =>
                  this.state.grupo_servico !== null &&
                  e.id === this.state.grupo_servico ? (
                    <option key={i} value={e.id} selected>
                      {e.descricao}
                    </option>
                  ) : (
                    <option key={i} value={e.id}>
                      {e.descricao}
                    </option>
                  )
                )}
              </Form.Control>
            </Col>

            <Col
              sm={1}
              style={{
                alignItems: "center",
                justifyContent: "center",
                textAlign: "right",
              }}
            >
              {/* caracter vazio abaixo */}
              <Form.Label>⠀⠀⠀⠀⠀⠀⠀⠀</Form.Label>
              <Modal
                open={this.state.modalOpen}
                dimmer="blurring"
                size="small"
                trigger={
                  <Button
                    type="button"
                    size="tiny"
                    positive
                    icon="add"
                    onClick={(e) => this.abreModal(true)}
                  ></Button>
                }
              >
                <Header icon>
                  <Icon name="add circle" />
                  Grupo Serviço
                </Header>
                <Modal.Content>
                  <Form.Label>Descrição Grupo</Form.Label>
                  <FormControl
                    name="descGrupo"
                    fluid
                    onChange={this.alteracao}
                  />
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    color="red"
                    inverted
                    onClick={() => this.abreModal(false)}
                  >
                    <Icon name="remove" /> Fechar
                  </Button>
                  <Button color="green" inverted onClick={this.salvaGrupo}>
                    <Icon name="checkmark" /> Sim
                  </Button>
                </Modal.Actions>
              </Modal>
            </Col>

            <Col sm={6}>
              <Form.Label>Descrição Turma</Form.Label>
              <FormControl
                placeholder="Descrição da Turma"
                name="descTurma"
                onChange={this.alteracao}
                required
                defaultValue={this.state.descTurma}
                fluid
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Dia de Inicio</Form.Label>
              <FormControl
                type="date"
                aria-describedby="basic-addon1"
                name="dataInicio"
                onChange={this.alteracao}
                required
                value={this.state.dataInicio.toString().substr(0, 10)}
              />
            </Col>

            <Col sm={6}>
              <Form.Label>Dia do Fim</Form.Label>
              <FormControl
                type="date"
                aria-describedby="basic-addon1"
                name="dataFinal"
                onChange={this.alteracao}
                required
                value={this.state.dataFinal.toString().substr(0, 10)}
              />
            </Col>
            <Col sm={6}>
              <Row>
                <Col sm={10}>
                  <Form.Label>Valor Inscrição</Form.Label>
                  <FormControl
                    placeholder="Valor Inscrição"
                    aria-label="Valor"
                    aria-describedby="basic-addon1"
                    name="valorInscricao"
                    defaultValue={
                      this.state.valorInscricao !== null
                        ? formatter.format(this.state.valorInscricao)
                        : " "
                    }
                    onKeyUp={this.componentMoney}
                    className="dinheiro"
                    onBlur={this.alteracao}
                  />
                </Col>
                <Col sm={2} style={{ marginTop: "28px" }}>
                  <Popup
                    content="Parâmetros"
                    mouseEnterDelay={500}
                    on="hover"
                    trigger={
                      <Button
                        icon="setting"
                        size="medium"
                        onClick={this.abrirModalInscricao}
                        type="button"
                        color="grey"
                        floated="right"
                        style={{
                          marginTop: "-4%",
                          marginRight: "1.5%",
                          float: "right",
                        }}
                      />
                    }
                  />
                </Col>
              </Row>
            </Col>

            <Col sm={6}>
              <Row>
                <Col sm={10}>
                  <Form.Label>Valor Mensalidade</Form.Label>
                  <FormControl
                    aria-label="Valor"
                    aria-describedby="basic-addon1"
                    name="valorMensalidade"
                    className="dinheiro"
                    defaultValue={
                      this.state.valorMensalidade !== null
                        ? formatter.format(this.state.valorMensalidade)
                        : ""
                    }
                    onKeyUp={this.componentMoney}
                    onBlur={this.alteracao}
                    placeholder="R$"
                  />
                </Col>
                <Col sm={2} style={{ marginTop: "28px" }}>
                  <Popup
                    content="Parâmetros"
                    mouseEnterDelay={500}
                    on="hover"
                    trigger={
                      <Button
                        icon="setting"
                        size="medium"
                        onClick={this.abrirModalMensalidade}
                        type="button"
                        color="grey"
                        floated="right"
                        style={{
                          marginTop: "-4%",
                          marginRight: "1.5%",
                          float: "right",
                        }}
                      />
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Form.Label>Quantidade de Turma</Form.Label>
              <InputGroup>
                <FormControl
                  placeholder="Quantidade de Turma"
                  name="qtdTurma"
                  onChange={this.alteracao}
                  required
                  defaultValue={this.state.qtdTurma}
                  fluid
                />
                {this.state.qtdTurma !== "" ? (
                  <InputGroup.Append>
                    <Button
                      type="button"
                      primary
                      size="small"
                      icon="calendar plus"
                      onClick={this.criaTurmas}
                    />
                  </InputGroup.Append>
                ) : (
                  ""
                )}
              </InputGroup>
            </Col>
            <Col sm={6}>
              <Col sm={12} style={{ padding: "0" }}>
                <Form.Label>Academia</Form.Label>
              </Col>
              <Col sm={12} style={{ padding: "0" }}>
                <Checkbox
                  toggle
                  onChange={(evt, data) =>
                    this.onChangeCheckboxAcademia(evt, data)
                  }
                  checked={this.state.academia}
                />
              </Col>
            </Col>
          </Row>
          <Divider />
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nome</Table.HeaderCell>
                <Table.HeaderCell width={2}>Turmas</Table.HeaderCell>
                <Table.HeaderCell>Hora Inicio</Table.HeaderCell>
                <Table.HeaderCell>Hora Fim</Table.HeaderCell>
                <Table.HeaderCell width={2}>Vagas </Table.HeaderCell>
                <Table.HeaderCell>Dias da Semana</Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  <Button
                    type="button"
                    positive
                    size="tiny"
                    onClick={this.adicionar}
                    icon="add"
                  ></Button>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.listaTurmas.map((turma, i) => (
                <Table.Row key={i}>
                  <Table.Cell width={5}>
                    <FormControl
                      name="nome"
                      value={turma.nome}
                      onChange={(e) => this.alteracaoNome(e.target.value, i)}
                      fluid
                    />
                  </Table.Cell>
                  <Table.Cell width={1}>
                    <FormControl
                      name="turma"
                      value={turma.turma}
                      // onChange={(e) => this.alteracaoTurma(e.target.value, i)}
                      fluid
                      disabled
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <FormControl
                      type="time"
                      placeholder="Horario Inicio"
                      aria-label="Horario Inicio"
                      aria-describedby="basic-addon1"
                      name="horaInicio"
                      required
                      onChange={(e) =>
                        this.alteracaoHorarioInicio(e.target.value, i)
                      }
                      value={turma.horaInicio}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <FormControl
                      type="time"
                      placeholder="Horario Fim"
                      aria-label="Horario Fim"
                      aria-describedby="basic-addon1"
                      name="horaFim"
                      required
                      onChange={(e) =>
                        this.alteracaoHorarioFim(e.target.value, i)
                      }
                      value={turma.horaFim}
                    />
                  </Table.Cell>

                  <Table.Cell>
                    <FormControl
                      name="vaga"
                      value={turma.vaga}
                      onChange={(e) => this.alteracaoVaga(e.target.value, i)}
                      fluid
                    />
                  </Table.Cell>
                  <Table.Cell width={1}>
                    <Modal
                      closeIcon={true}
                      trigger={
                        <Button type="button" primary>
                          Dias
                        </Button>
                      }
                    >
                      <Modal.Header>Dias da Semana</Modal.Header>
                      <Modal.Content>
                        {this.state.diasDaSemana.map((dia, index) => {
                          return (
                            <Checkbox
                              id={i[index]}
                              key={index}
                              className="checkboxInline"
                              label={dia.inicial}
                              value={dia.id}
                              name="diasFiltro"
                              onChange={() => this.alteracaoDias(dia.id, i)}
                              checked={turma.diasDaSemana[index]}
                            />
                          );
                        })}
                      </Modal.Content>
                    </Modal>
                  </Table.Cell>
                  <Table.Cell width={1}>
                    <Button
                      type="button"
                      negative
                      size="tiny"
                      value={i}
                      onClick={() => this.excluir(turma, i)}
                      icon="remove"
                    ></Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer></Table.Footer>
          </Table>
          <Divider />

          <ModalServicoMensalidade
            open_modal_servico_mensalidade={
              this.state.open_modal_servico_mensalidade
            }
            servico_mensalidade={this.state.servico_mensalidade}
            alteracaoSelectServicoMensalidade={
              this.alteracaoSelectServicoMensalidade
            }
            fecharModalMensalidade={this.fecharModalMensalidade}
            fecharModalMensalidadeZeraMensalidade={
              this.fecharModalMensalidadeZeraMensalidade
            }
            servico_mensalidade_nome={this.state.servico_mensalidade_nome}
            alteracaoServicoMensalidade={this.alteracaoServicoMensalidade}
          />

          <ModalServicoInscricao
            open_modal_servico_inscricao={
              this.state.open_modal_servico_inscricao
            }
            servico_inscricao_nome={this.state.servico_inscricao_nome}
            servico_inscricao={this.state.servico_inscricao}
            alteracaoSelectServicoInscricao={
              this.alteracaoSelectServicoInscricao
            }
            fecharModalInscricaoZeraInscricao={
              this.fecharModalInscricaoZeraInscricao
            }
            fecharModalInscricao={this.fecharModalInscricao}
            alteracaoServicoInscricao={this.alteracaoServicoInscricao}
          />

          <Segment clearing>
            <Header floated="right">
              <Link to="/servicos">
                <Button type="button">Voltar</Button>
              </Link>
              <Button positive type="submit">
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}
