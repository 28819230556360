import React, { Component } from "react";
import {
  Container,
  Header,
  Table,
  Segment,
  Button,
  Modal,
  Progress,
  Select,
  Form as FormSemantic,
  Label,
} from "semantic-ui-react";

import { Col, Row, Form, Alert } from "react-bootstrap";

import api from "../../../services/api";
import FileList from "../../../components/fileList";
import UploadREM from "../../../components/uploadREM";
import { uniqueId } from "lodash";
import filesize from "filesize";
import { FileInfo, PreviewPhoto } from "../../../components/fileList/styles";
import IdentificacaoOcorrencia from "../../../enum/IndetificacaoOcorrenciaRetorno";
import { parseISO, format } from "date-fns";
import requisicoes from "../../../utils/Requisicoes";
import IORetornoDebitoConta from "../../../enum/IORetornoDebitoConta";
import moment from "moment-timezone";
import { storage } from "../../../config/firebase";

import { Link, withRouter } from "react-router-dom";
import { valorFormatter } from "../../../utils/formatterValor";

class Retorno extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadedFiles: [],
      arquivo_id: "",
      mensagem: null,
      lancamentos: [],
      totalLancamentos: null,
      totalLancamentosGerados: null,
      lancamentosSelecionados: [],
      open: false,
      total: 0,
      ultimosArquivos: [],
      contaBancaria: null,
      optionsContaBancaria: [],
    };
    this.buscas();
    // this.arquivos();
    // this.buscaContaBancaria();
  }

  buscas = async () => {
    const promisses = [];

    promisses.push(this.buscaCaixa());
    promisses.push(this.arquivos());
    promisses.push(this.buscaContaBancaria());

    Promise.all(promisses);
    this.setState({ buscando: false });
  };

  buscaCaixa = async () => {
    const retorno = await api.post(
      "/caixa/consultaCaixaPorUsuarioETipo" + requisicoes.entidade,
      { tipo: "caixa" },
      requisicoes.header
    );
    if (retorno.data !== "" && retorno.data.data_fechamento !== null) {
      alert(
        "Você precisa ter um caixa aberto para adicionar os retornos ao caixa."
      );
      this.props.history.push("/caixa");
    }
  };

  arquivos = async () => {
    await api
      .post(
        "/arquivoRetorno/ultimosCinco" + requisicoes.entidade,
        {},
        requisicoes.header
      )
      .then((r) => {
        this.setState({ ultimosArquivos: r.data });
      });
  };

  buscaContaBancaria = async () => {
    let lista = await api.post(
      "/contaBancaria/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    for (let i = 0; i < lista.data.length; i++) {
      const element = lista.data[i];
      element.key = element.id;
      element.text = element.conta;
      element.label = (
        <Label content={element.banco.nome} attached="bottom right"></Label>
      );
      element.value = element.id;
    }
    this.setState({ optionsContaBancaria: lista.data });
  };

  handleShow = () => this.setState({ open: true });

  mostraMensagem = async (msg) => {
    this.setState({ mensagem: msg });
    setTimeout(async () => {
      this.setState({ mensagem: null });
    }, 10000);
  };

  handleUpload = async (files) => {
    const uploadedFiles = files.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: "",
    }));

    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles),
    });
    this.submit();
  };

  submit = async () => {
    const data = new FormData();
    let uploadedFile = "";
    this.state.uploadedFiles.forEach((e) => {
      uploadedFile = e;
    });
    if (this.state.uploadedFiles.length > 0) {
      data.append("file", uploadedFile.file, uploadedFile.name);

      const usuario = await api.post(
        "/usuario/usuarioLogado" + requisicoes.entidade,
        {},
        requisicoes.header
      );

      //Enviar o arquivo para o firebase
      const uploadTask = await storage
        .ref(
          `${process.env.REACT_APP_ENVIRONMENT === "production"
            ? "producao"
            : "hml"
          }/${window.localStorage.getItem("e_clube")}/retornos/${usuario.data.id
          }.ret`
        )
        .put(uploadedFile.file);

      await api.post(
        "/retorno/upload" + requisicoes.entidade,
        {
          projeto:
            process.env.REACT_APP_ENVIRONMENT === "production"
              ? "producao"
              : "hml",
        },
        requisicoes.header
      );

      console.log("vai ler o arquivo?");
      const retorno = await api.post(
        "/retorno/lerArquivo" + requisicoes.entidade,
        {},
        requisicoes.header
      );

      // console.log(retorno);
      let lancamentosPagos = 0;
      let lancamentosCancelados = 0;

      if (retorno.data.length === 0) {
        this.mostraMensagem("Nenhum Titulo Encontrado");
      } else {
        let total = 0;
        if (retorno.data.length > 0) {
          if (retorno.data[0].tipo_retorno === "debito_em_conta") {
            retorno.data.forEach((e) => {
              if (e.situacao.includes("Cancelado")) {
                lancamentosCancelados++;
              }
              if (
                (parseInt(e.identificacao_ocorrencia) === 0 || parseInt(e.identificacao_ocorrencia) === 31) &&
                e.situacao !== "Pago"
              ) {
                total =
                  total +
                  parseFloat(
                    e.valor_pago.toString().substring(0, 11) +
                    "." +
                    e.valor_pago.toString().substring(11, 13)
                  );
              } else if (e.situacao === "Pago") {
                lancamentosPagos++;
              }
            });
          }
          if (retorno.data[0].tipo_retorno === "boleto") {
            retorno.data.forEach((e) => {
              if (
                parseInt(e.identificacao_ocorrencia) === 6 &&
                e.situacao !== "Pago"
              ) {
                total =
                  total +
                  parseFloat(
                    e.valor_pago.toString().substring(0, 11) +
                    "." +
                    e.valor_pago.toString().substring(11, 13)
                  );
              } else if (e.situacao === "Pago") {
                lancamentosPagos++;
              }
            });
          }
        }
        this.setState({ total: total });
        this.setState({ lancamentos: retorno.data });
        this.setState({ lancamentosSelecionados: retorno.data });
        this.state.lancamentosSelecionados.forEach((e) => {
          e.selecionado = true;
        });
      }
      if (lancamentosCancelados > 0) {
        alert(
          "Existem " +
          lancamentosCancelados +
          " lancamentos que foram cancelados no sistema!"
        );
      }
      if (lancamentosPagos > 0) {
        alert(
          "Existem " +
          lancamentosPagos +
          " lancamentos que já foram recebidos nos caixas."
        );
      }
    }
  };

  baixar = async () => {
    const contaBancaria = this.state.contaBancaria;
    if (!contaBancaria) {
      return;
    }

    await api.post(
      "/historicoMovimentoBancario/salvarHistoricoRetorno" +
      requisicoes.entidade,
      {
        lancamentos: this.state.lancamentos,
      },
      requisicoes.header
    );

    let group = this.state.lancamentos.reduce((r, a) => {
      r[a.id] = [...(r[a.id] || []), a];
      return r;
    }, {});
    const lancamentos = group;

    let listaLancamentos = [];
    let listaLancamentosNaoBaixados = [];
    Object.values(lancamentos).forEach(async (o, i) => {
      for (let j = 0; j < o.length; j++) {
        const e = o[j];

        if (
          (e.tipo_retorno === "boleto" &&
            parseInt(e.identificacao_ocorrencia) === 6) ||
          (e.tipo_retorno === "debito_em_conta" &&
            (parseInt(e.identificacao_ocorrencia) === 0 ||
              parseInt(e.identificacao_ocorrencia) === 31))
        ) {
          if (e.situacao !== "Pago") {
            e.valor_pago = parseFloat(
              e.valor_pago.toString().substring(0, 11) +
              "." +
              e.valor_pago.toString().substring(11, 13)
            );
            e.hora_baixa = moment().format("HH:mm");
            listaLancamentos.push({
              id: e.id,
              valor_pago: e.valor_pago,
              hora_baixa: e.hora_baixa,
              valor_pagamento: e.valor_pagamento,
              forma_pagamento: e.forma_pagamento,
              data_pagamento: e.data_pagamento,
              conta_bancaria_id: contaBancaria,
              valor_atraso: e.valor_atraso,
              valor_mora: e.valor_mora,
            });
          } else {
            listaLancamentosNaoBaixados.push({
              id: e.id,
              hora_baixa: e.hora_baixa,
              valor_pago: e.valor_pago,
              valor_pagamento: e.valor_pagamento,
              valor: e.valor,
              forma_pagamento: e.forma_pagamento,
              data_pagamento: e.data_pagamento,
              numero_titulo: e.numero_titulo,
              // conta_bancaria_id: contaBancaria,
              pessoa_nome: e.pessoa.nome,

              identificacao_ocorrencia:
                e.tipo_retorno === "boleto"
                  ? IdentificacaoOcorrencia.find(
                    (ident) =>
                      ident.value === parseInt(e.identificacao_ocorrencia)
                  ) !== undefined
                    ? IdentificacaoOcorrencia.find(
                      (ident) =>
                        ident.value === parseInt(e.identificacao_ocorrencia)
                    ).label
                    : e.identificacao_ocorrencia
                  : e.tipo_retorno === "debito_em_conta"
                    ? IORetornoDebitoConta.find(
                      (identDC) =>
                        identDC.value === parseInt(e.identificacao_ocorrencia)
                    ) !== undefined
                      ? IORetornoDebitoConta.find(
                        (identDC) =>
                          identDC.value === parseInt(e.identificacao_ocorrencia)
                      ).label
                      : e.identificacao_ocorrencia
                    : "",
            });
          }
        } else {
          listaLancamentosNaoBaixados.push({
            id: e.id,
            valor_pago: e.valor_pago,
            hora_baixa: e.hora_baixa,
            valor_pagamento: e.valor_pagamento,
            forma_pagamento: e.forma_pagamento,
            data_pagamento: e.data_pagamento,
            // conta_bancaria_id: this.state.contaBancaria,
            numero_titulo: e.numero_titulo,
            pessoa_nome: e.pessoa.nome,
            data_vencimento: e.data_vencimento,
            valor: e.valor,
            identificacao_ocorrencia:
              e.tipo_retorno === "boleto"
                ? IdentificacaoOcorrencia.find(
                  (ident) =>
                    ident.value === parseInt(e.identificacao_ocorrencia)
                ) !== undefined
                  ? IdentificacaoOcorrencia.find(
                    (ident) =>
                      ident.value === parseInt(e.identificacao_ocorrencia)
                  ).label
                  : e.identificacao_ocorrencia
                : e.tipo_retorno === "debito_em_conta"
                  ? IORetornoDebitoConta.find(
                    (identDC) =>
                      identDC.value === parseInt(e.identificacao_ocorrencia)
                  ) !== undefined
                    ? IORetornoDebitoConta.find(
                      (identDC) =>
                        identDC.value === parseInt(e.identificacao_ocorrencia)
                    ).label
                    : e.identificacao_ocorrencia
                  : "",
          });
        }
      }
    });

    console.log("listaLancamentosNaoBaixados: ", listaLancamentosNaoBaixados);
    await api.post(
      "/retorno/gerarPDFCobrancasNaoEfetuadas" + requisicoes.entidade,
      {
        lancamentos: listaLancamentosNaoBaixados,
      },
      requisicoes.header
    );

    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");

    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    setTimeout(async () => {
      const r = await api.get(
        "/retorno/arquivoPDFCobrancasNaoEfetuadas" +
        requisicoes.entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.id
      );
      var oReq = new XMLHttpRequest();

      var URLToPDF =
        r.config.baseURL +
        "retorno/arquivoPDFCobrancasNaoEfetuadas" +
        requisicoes.entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.id;

      oReq.open("GET", URLToPDF, true);
      oReq.responseType = "blob";
      oReq.onload = function () {
        const file = new Blob([oReq.response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      };
      oReq.send();
    }, 500);

    //arquivoPDFCobrancasNaoEfetuadas

    await api
      .post(
        "/caixa/baixaRetorno" + requisicoes.entidade,
        {
          tipo: "caixa",
          lancamentos: listaLancamentos,
          nome_arquivo:
            this.state.uploadedFiles.length > 0
              ? this.state.uploadedFiles[0].name
              : "",
        },
        requisicoes.header
      )
      .then(() => {
        setTimeout(() => {
          window.location.href = "/caixa";
        }, 2000);
      });
  };

  updateFile = (id, data) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map((uploadedFile) => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      }),
    });
  };
  deleteImage = () => {
    this.setState({ uploadedFiles: [] });
  };

  alteracao = (event) => {
    if (
      this.state.lancamentosSelecionados.find(
        (e) => parseInt(e.numero_titulo) === parseInt(event.target.value)
      )
    ) {
      let indice = this.state.lancamentosSelecionados.findIndex(
        (element) =>
          parseInt(element.numero_titulo) === parseInt(event.target.value)
      );
      this.state.lancamentosSelecionados.splice(indice, 1);
    } else {
      this.state.lancamentosSelecionados.push(
        this.state.lancamentos.find(
          (e) => parseInt(e.numero_titulo) === parseInt(event.target.value)
        )
      );
    }
  };

  render() {
    const { uploadedFiles } = this.state;

    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Retorno
        </Header>

        <Header as="h3">Ultimos Arquivos</Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Data</Table.HeaderCell>
              <Table.HeaderCell>Descrição</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.ultimosArquivos.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>
                  {e.data !== null ? moment(e.data).format("DD/MM/YYYY") : ""}
                </Table.Cell>
                <Table.Cell>{e.descricao !== null && e.descricao}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Row>
          <Col sm={6}>
            <Form.Label>Arquivo</Form.Label>
            <Row>
              <Col sm={6}>
                {uploadedFiles.length !== 0 ? (
                  uploadedFiles.map((uploadedFile) => (
                    <div key={uploadedFile.id}>
                      <FileInfo>
                        <PreviewPhoto src={uploadedFile.preview} />
                      </FileInfo>
                    </div>
                  ))
                ) : (
                  <UploadREM onUpload={this.handleUpload} />
                )}
              </Col>
              <Col
                sm={4}
                style={{ marginLeft: uploadedFiles.length !== 0 ? -250 : 0 }}
              >
                {!!uploadedFiles.length && (
                  <FileList files={uploadedFiles} onDelete={this.deleteImage} />
                )}
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <FormSemantic>
              <FormSemantic.Field
                label="Conta bancária"
                control={Select}
                placeholder="Conta Bancária"
                options={this.state.optionsContaBancaria}
                value={this.state.contaBancaria}
                onChange={(e, a) => this.setState({ contaBancaria: a.value })}
                labelPosition="right"
              />
            </FormSemantic>
          </Col>
        </Row>
        {this.state.mensagem !== null && (
          <Alert variant="primary" style={{ marginTop: "10px" }}>
            {this.state.mensagem}
          </Alert>
        )}

        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nosso Numero</Table.HeaderCell>
              <Table.HeaderCell>Pessoa</Table.HeaderCell>
              <Table.HeaderCell>Tipo Movimento</Table.HeaderCell>
              <Table.HeaderCell>Data Vencimento</Table.HeaderCell>
              <Table.HeaderCell>Data Pagamento</Table.HeaderCell>
              <Table.HeaderCell>Valor Titulo</Table.HeaderCell>
              <Table.HeaderCell>Valor Pago</Table.HeaderCell>
              <Table.HeaderCell>Valores Iguais</Table.HeaderCell>
              <Table.HeaderCell>Situação Titulo</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.lancamentos.map((e, i) => (
              <Table.Row
                key={i}
                style={
                  e.situacao.includes("Cancelado")
                    ? {
                      color: "#8a0303",
                      fontWeight: "bold",
                    }
                    : e.situacao === "Pago"
                      ? { color: "#d8d73a", fontWeight: "bold" }
                      : (e.tipo_retorno === "boleto" &&
                        parseInt(e.identificacao_ocorrencia) !== 2 &&
                        parseInt(e.identificacao_ocorrencia) !== 6) ||
                        (e.tipo_retorno === "debito_em_conta" &&
                          parseInt(e.identificacao_ocorrencia) !== 0 &&
                          parseInt(e.identificacao_ocorrencia) !== 31)
                        ? { color: "red" }
                        : { color: "black" }
                }
              >
                <Table.Cell>{parseInt(e.numero_titulo)}</Table.Cell>
                <Table.Cell>{e.pessoa !== null && e.pessoa.nome}</Table.Cell>
                <Table.Cell>
                  {e.tipo_retorno === "boleto"
                    ? IdentificacaoOcorrencia.find(
                      (ident) =>
                        ident.value === parseInt(e.identificacao_ocorrencia)
                    ) !== undefined
                      ? IdentificacaoOcorrencia.find(
                        (ident) =>
                          ident.value === parseInt(e.identificacao_ocorrencia)
                      ).label
                      : e.identificacao_ocorrencia
                    : ""}
                  {e.tipo_retorno === "debito_em_conta"
                    ? IORetornoDebitoConta.find(
                      (identDC) =>
                        identDC.value === parseInt(e.identificacao_ocorrencia)
                    ) !== undefined
                      ? IORetornoDebitoConta.find(
                        (identDC) =>
                          identDC.value ===
                          parseInt(e.identificacao_ocorrencia)
                      ).label
                      : e.identificacao_ocorrencia
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {format(parseISO(e.data_vencimento), "dd/MM/yyyy")}
                </Table.Cell>
                <Table.Cell>
                  {e.data_pagamento
                    ? format(parseISO(e.data_pagamento), "dd/MM/yyyy")
                    : ""}
                </Table.Cell>
                <Table.Cell>{valorFormatter.format (e.valor)}</Table.Cell>
                <Table.Cell>
                  { valorFormatter.format( parseFloat(
                    e.valor_pago.toString().substring(0, 11) +
                    "." +
                    e.valor_pago.toString().substring(11, 13)
                  ))}
                </Table.Cell>
                <Table.Cell>
                  {parseFloat(e.valor) ===
                    parseFloat(
                      e.valor_pago.toString().substring(0, 11) +
                      "." +
                      e.valor_pago.toString().substring(11, 13)
                    ) ? (
                    <Col style={{ color: "green" }}>Sim</Col>
                  ) : (
                    <Col style={{ color: "red" }}>Não</Col>
                  )}
                </Table.Cell>
                <Table.Cell>{e.situacao}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Col sm={12}>
          <Form.Label style={{ float: "right" }}>
            Total:{" "}
            {parseFloat(this.state.total).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Form.Label>
        </Col>
        <br />
        <Segment clearing>
          <Header floated="right">
            <Link to={{ pathname: "/caixa" }}>
              <Button type="button">Voltar</Button>
            </Link>
            <Button
              type="button"
              primary
              onClick={this.baixar}
              disabled={this.state.contaBancaria === null}
            >
              Baixar
            </Button>
          </Header>
        </Segment>

        <Modal open={this.state.open} centered={false}>
          <Header icon="wait" content="Executando Retorno" />
          <Modal.Content>
            <Progress
              success
              value={this.state.totalLancamentosGerados}
              total={this.state.totalLancamentos}
            >
              Aguarde...
            </Progress>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
export default withRouter(Retorno);
