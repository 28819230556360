import React, { Component } from "react";
import { Menu } from "semantic-ui-react";

// Components
import Convidados from "./convidados";
import Reservas from "./reservas";
import Matriculas from "./matricula";

export default class RelatoriosReservasMatriculas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu2: "relatorioReservas",
    };
  }

  selectMenu2 = (newMenu) => {
    this.setState({
      selectedMenu2: newMenu,
    });
  };

  render() {
    let content = "";
    switch (this.state.selectedMenu2) {
      case "convidados":
        content = <Convidados />;
        break;
      case "relatorioReservas":
        content = <Reservas listaGrupoServico={this.props.listaGrupoServico} />;
        break;
      case "relatorioMatriculas":
        content = (
          <Matriculas listaGrupoServico={this.props.listaGrupoServico} />
        );
        break;
      default:
        break;
    }

    return (
      <div style={{ paddingTop: 20, paddingBottom: 80 }}>
        <h2>Gerar relatório</h2>
        <Menu pointing>
          <Menu.Item
            name="relatorioReservas"
            active={this.state.selectedMenu2 === "relatorioReservas"}
            onClick={this.selectMenu2.bind(null, "relatorioReservas")}
          >
            Reservas
          </Menu.Item>

          <Menu.Item
            name="relatorioMatriculas"
            active={this.state.selectedMenu2 === "relatorioMatriculas"}
            onClick={this.selectMenu2.bind(null, "relatorioMatriculas")}
          >
            Matrículas
          </Menu.Item>

          <Menu.Item
            name="convidados"
            active={this.state.selectedMenu2 === "convidados"}
            onClick={this.selectMenu2.bind(null, "convidados")}
          >
            Lista de convidados
          </Menu.Item>
        </Menu>
        {content}
      </div>
    );
  }
}
