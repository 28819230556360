import React, { Component } from "react";
import { Header, Menu, Icon } from "semantic-ui-react";
import EventoClubeLista from "../../cadastro/eventoClube/lista";

export default class Marketing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo_clube: window.localStorage.getItem("tipo_clube"),
      selectedMenu: null,
    };
  }

  render() {
    let content = "";
    switch (this.state.selectedMenu) {
      case "eventoClube":
        content = <EventoClubeLista />;
        break;
      default:
        break;
    }
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Marketing
        </Header>
        <Menu tabular icon="labeled">
          {this.props.menus.find((e) => e.url === "/eventoClube/lista") && (
            <Menu.Item
              name="Eventos"
              active={this.state.selectedMenu === "eventoClube"}
              onClick={this.selectMenu.bind(null, "eventoClube")}
            >
              <Icon name="calendar minus" />
              Eventos
            </Menu.Item>
          )}
        </Menu>
        {content}
      </div>
    );
  }
  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
  };
}
