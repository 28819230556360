import React, { Component } from "react";
import {
  Modal,
  Dropdown,
  Container,
  Table,
  Menu,
  Icon,
  Button,
} from "semantic-ui-react";
import api from "../../../services/api";
import { Col, Form, Row, FormControl } from "react-bootstrap";
import Select from "react-select";
import renomearChave from "../../../services/renomearChave";
import requisicoes from "../../../utils/Requisicoes";
import { parseISO, format } from "date-fns";

var formatter = new Intl.NumberFormat("pt-br", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export default class EstornoLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      lista: [],
      tipo: "data",
      data_inicio: null,
      data_final: null,
      options: [],
      pessoa_id: null,
      pessoa: null,
      open: false,
      contasAPagar: null,
      lancamento_conta: null,
      op: null,
      op_cod: null,
      optionsOP: [],
      listaOP: [],
      tipos: [
        { key: "data", value: "data", text: "Data" },
        { key: "nome", value: "nome", text: "Fornecedor" },
        {
          key: "ordem_pagamento",
          value: "ordem_pagamento",
          text: "Ordem Pagamento",
        },
      ],
      motivo_estorno: null,
      estorno: null,
    };
    this.alteracao = this.alteracao.bind(this);
  }

  handleChange = (e, { value }) => this.setState({ tipo: value });

  handleClose = () => this.setState({ open: false });

  handleShow = async (event) => {
    this.setState({ id: event.target.id });
    await api
      .post(
        "/lancamentoConta/visualiza" + requisicoes.entidade,
        { id: event.target.id },
        requisicoes.header
      )
      .then((resp) => {
        this.setState({ estorno: resp.data });
        this.setState({ open: true });
      });
  };

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  pesquisaPessoa = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/pessoa/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaPessoa").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista.push(objeto);
      });
      this.setState({ options: lista });
    }, 500);
  };

  alteracaoSelectPessoa = async (event) => {
    this.setState({ pessoa_id: event.value });
    this.setState({ pessoa: event });
  };

  pesquisaOP = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/ordemPagamento/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaOP").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "codigo", "label");
        lista.push(objeto);
      });
      this.setState({ optionsOP: lista });
    }, 500);
  };

  alteracaoSelectOP = async (event) => {
    // this.setState({ op_cod: event.label });
    this.state.op_cod = event.label;
    this.setState({ op: event });
    await this.buscaContas();
  };

  buscaContas = async () => {
    let retorno = "";
    switch (this.state.tipo) {
      case "nome":
        retorno = await api.post(
          "/contasAPagar/pesquisaPagosPorPessoa" + requisicoes.entidade,
          { pessoa_id: this.state.pessoa_id },
          requisicoes.header
        );
        this.setState({ lista: retorno.data });
        break;
      case "data":
        retorno = await api.post(
          "/contasAPagar/pesquisaPagosPorData" + requisicoes.entidade,
          {
            data_inicial: this.state.data_inicio,
            data_final: this.state.data_final,
          },
          requisicoes.header
        );
        this.setState({ lista: retorno.data });
        break;
      case "ordem_pagamento":
        console.log("buscando ordem de pagamento");
        retorno = await api.post(
          "/contasAPagar/pesquisaPagosPorOrdemPagamento" + requisicoes.entidade,
          { ordem_pagamento: this.state.op_cod },
          requisicoes.header
        );
        console.log("busca: ", retorno.data);
        this.setState({ lista: retorno.data });
        break;
      default:
        break;
    }
  };

  handleSubmit = (event) => {
    this.submit();
    event.preventDefault();
  };

  submit = async () => {
    await api.post(
      "/contasAPagar/estorno" + requisicoes.entidade,
      {
        id: this.state.id,
        data_pagamento: null,
        valor_pago: null,
        valor_atraso: null,
        valor_mora: null,
        desconto: null,
        motivo_estorno: this.state.motivo_estorno,
      },
      requisicoes.header
    );
    localStorage.setItem("pagina_anterior", "estorno");
    window.location.href = "/financeiro";
  };

  render() {
    return (
      <div className="lista">
        <Row>
          <Col sm={4}>
            <Form.Label>Tipo</Form.Label>
            <Dropdown
              placeholder="Tipo"
              id="tipo"
              name="tipo"
              fluid
              search
              selection
              options={this.state.tipos}
              onChange={this.handleChange}
              value={this.state.tipo}
            />
          </Col>
          {this.state.tipo === "ordem_pagamento" && (
            <Col sm={6}>
              <Form.Label>Busca</Form.Label>
              <Select
                placeholder="Nº Ordem de Pagamento"
                name="ordem_pagamento"
                onKeyDown={this.pesquisaOP}
                onChange={this.alteracaoSelectOP}
                inputId="pesquisaOP"
                options={this.state.optionsOP}
              />
            </Col>
          )}
          {this.state.tipo === "nome" && (
            <Col sm={6}>
              <Form.Label>Busca</Form.Label>
              <Select
                placeholder="Digite para buscar"
                name="pessoa"
                onKeyDown={this.pesquisaPessoa}
                onChange={this.alteracaoSelectPessoa}
                inputId="pesquisaPessoa"
                options={this.state.options}
              />
            </Col>
          )}
          {this.state.tipo === "data" && (
            <Col sm={6}>
              <Row>
                <Col sm={6}>
                  <Form.Label>Data Inicio</Form.Label>
                  <FormControl
                    placeholder="Data Inicio"
                    aria-describedby="basic-addon1"
                    type="date"
                    name="data_inicio"
                    onChange={this.alteracao}
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label>Data Final</Form.Label>
                  <FormControl
                    placeholder="Data Final"
                    aria-describedby="basic-addon1"
                    type="date"
                    name="data_final"
                    onChange={this.alteracao}
                  />
                </Col>
              </Row>
            </Col>
          )}
          <Col sm={2}>
            <Button
              positive
              style={{ marginTop: "25px" }}
              onClick={this.buscaContas}
            >
              Buscar
            </Button>
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Data Pagamento</Table.HeaderCell>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Parcela</Table.HeaderCell>
              <Table.HeaderCell>Valor</Table.HeaderCell>
              <Table.HeaderCell>Valor pago</Table.HeaderCell>
              <Table.HeaderCell width={1}></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.lista.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell width={3}>
                  {e.data_pagamento !== null &&
                    format(parseISO(e.data_pagamento), "dd/MM/yyyy")}
                </Table.Cell>
                <Table.Cell width={4}>
                  {e.pessoa !== null && e.pessoa.nome}
                </Table.Cell>
                <Table.Cell width={1}>{e.numero_parcela}</Table.Cell>
                <Table.Cell width={2}>
                  {parseFloat(e.valor).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Table.Cell>
                <Table.Cell width={2}>
                  {e.valor_pago != null
                    ? parseFloat(e.valor_pago).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : 0}
                </Table.Cell>
                <Table.Cell>
                  <Button
                    color="violet"
                    id={e.id}
                    onClick={this.handleShow}
                    icon={{
                      className: "undo alternate",
                      id: [e.id],
                      onClick: [this.handleShow],
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan={6}>
                <Menu pagination>
                  <Menu.Item
                    icon
                    onClick={this.voltarPagina}
                    disabled={this.state.pagina === 1}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  <Menu.Item
                    icon
                    onClick={this.proximaPagina}
                    disabled={this.state.pagina === this.state.totalPaginas}
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          dimmer="blurring"
        >
          <Modal.Header>Estornar liquidação?</Modal.Header>
          <Modal.Content>
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Data Pagamento</Table.HeaderCell>
                  <Table.HeaderCell>Fornecedor</Table.HeaderCell>
                  <Table.HeaderCell>Parcela</Table.HeaderCell>
                  <Table.HeaderCell>Valor</Table.HeaderCell>
                  <Table.HeaderCell>Valor Pago</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.estorno != null && (
                  <Table.Row>
                    <Table.Cell width={3}>
                      {this.state.estorno.data_pagamento !== null &&
                        format(
                          parseISO(this.state.estorno.data_pagamento),
                          "dd/MM/yyyy"
                        )}
                    </Table.Cell>
                    <Table.Cell width={4}>
                      {this.state.estorno.pessoa !== null &&
                        this.state.estorno.pessoa.nome}
                    </Table.Cell>
                    <Table.Cell width={1}>
                      {this.state.estorno.numero_parcela}
                    </Table.Cell>
                    <Table.Cell width={2}>
                      {parseFloat(this.state.estorno.valor).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </Table.Cell>
                    <Table.Cell width={2}>
                      {this.state.estorno.valor_pago != null
                        ? parseFloat(
                            this.state.estorno.valor_pago
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : 0}
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
            <Row>
              <Form.Label>Motivo:</Form.Label>
              <FormControl
                placeholder="Motivo"
                as="textarea"
                name="motivo_estorno"
                id="motivo_estorno"
                onChange={this.alteracao}
              />
            </Row>
          </Modal.Content>
          <Modal.Actions>
            {" "}
            <Button color="black" onClick={this.handleClose}>
              Cancelar
            </Button>
            <Button
              content="Confirmar"
              labelPosition="right"
              icon="checkmark"
              onClick={this.handleSubmit}
              positive
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
