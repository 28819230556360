import React, { Component } from "react";
import { Header, Segment, Checkbox } from "semantic-ui-react";
import { Col, Form, Row, FormControl } from "react-bootstrap";
import { Divider } from "@material-ui/core";
// Utils
import moment from "moment-timezone";
import Parcelas from "../../../../../enum/ParcelasEnum";
import $ from "jquery";

// Enums
import proximoDiaUtil from "../../../../../utils/proximoDiaUtil";
import Meses from "../../../../../enum/Mes";
import checkFeriado from "../../../../../enum/Feriados";

//requisicao
import api from "../../../../../services/api";
import requisicoes from "../../../../../utils/Requisicoes";

export default class Cobranca extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo_pagamento: null,
      meses: Meses,
      parcelas: Parcelas,
      instituicoes: [],
      parcela_0: {
        numero: 0,
        valor: 0,
        data_vencimento: null,
        mes_referencia: null,
        ano_referencia: null,
      }
    };
    this.listaInstituicaoCobrancaBoleto();
  }

  listaInstituicaoCobrancaBoleto = async () => {
    const instituicoes = await api.post(
      "/instituicaoCobranca/lista" + requisicoes.entidade,
      { boleto: true },
      requisicoes.header
    );

    let lista = [];

    for (let i = 0; i < instituicoes.data.length; i++) {
      const element = instituicoes.data[i];
      lista.push({
        value: element.id,
        text: element.descricao + " - " + element.conta,
        id: element.id,
        registro_automatico: element.registro_automatico,
      });
    }

    this.setState({ instituicoes: lista });
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentMoney() {
    $(".dinheiro").mask("#.##0,00", {
      reverse: true,
      placeholder: "0,00",
    });
  }

  alteracaoParcelasMensalidade = (event) => {
    try {
      let lista = [];
      let parcelas = [];

      const indice = parseInt(event.target.id);
      lista = this.props.infoParcelasMensalidade;
      
      /*lista.splice(indice, 1, {
        numero: indice + 1,
        valor:
          event.target.name === "valorParcelaMensalidade"
            ? event.target.value
            : lista[indice].valor,
        data_vencimento:
          event.target.name === "dataVencimentoParcelaMensalidade"
            ? event.target.value
            : lista[indice].data_vencimento,
        mes_referencia:
          event.target.name === "mesReferencia"
            ? event.target.value
            : lista[indice].mes_referencia,
        ano_referencia:
          event.target.name === "anoReferencia"
            ? event.target.value
            : lista[indice].ano_referencia,
      });*/

      if(event.target.name === 'valorParcelaMensalidade') {
        lista.splice(indice, 1, {
          numero: indice + 1,
          valor: event.target.name === "valorParcelaMensalidade"
              ? event.target.value : lista[indice].valor,
          data_vencimento: lista[indice].data_vencimento,
          mes_referencia: lista[indice].mes_referencia,
          ano_referencia: lista[indice].ano_referencia,
        });
      } else {
        lista.splice(indice, 1, {
          numero: indice + 1,
          valor:
            event.target.name === "valorParcelaMensalidade"
              ? event.target.value
              : lista[indice].valor,
          data_vencimento:
            event.target.name === "dataVencimentoParcelaMensalidade"
              ? event.target.value
              : lista[indice].data_vencimento,
          mes_referencia:
            event.target.name === "mesReferencia"
              ? event.target.value
              : lista[indice].mes_referencia,
          ano_referencia:
            event.target.name === "anoReferencia"
              ? event.target.value
              : lista[indice].ano_referencia,
        });

        let data_venc = moment(
          event.target.name === "dataVencimentoParcelaMensalidade" 
                    ? event.target.value 
                    : lista[0].data_vencimento
          ).toDate();

        const atualizarListaParcelas = ((data_venc !== undefined && data_venc != 'Invalid Date') 
                                      && (data_venc.toString().length === 62)
                                      && (moment(data_venc).get('year') > 2000));

        if(atualizarListaParcelas) {
          if(event.target.name === "mesReferencia") {
            data_venc.setDate(this.props.acao.tipo_titulo.dia_vencimento);
          }
          parcelas = this.atualizaLista(data_venc, event, lista);
        }
      }

      if(parcelas.length > 0) {
        lista = parcelas;
      }

      if (lista.find((e) => e.valor.toString() === "NaN")) {
        lista.forEach((element) => {
          if (element.valor.toString() === "NaN") {
            element.valor = "";
          }
        });
      }
      this.props.setParcelasMensalidade(lista);
      if (event.target.name === "valorParcelaMensalidade") {
        this.componentMoney();
      }
    } catch(error) {
      console.log("alteracaoParcelasMensalidade/error: ", error);
    }
  };

  atualizaLista(data_venc, event, lista) {
    try {
      let parcelas = [];

      let mes_ref = event.target.name === "mesReferencia"
                    ? event.target.value
                    : lista[0].mes_referencia;
      let mes_ref_id = event.target.name === "mesReferencia" 
                    ? Meses.find(n => {return n.value === mes_ref;}).id 
                    : event.target.name === "dataVencimentoParcelaMensalidade" 
                      ? Meses[data_venc.getMonth()-1 < 0 ? 11 : data_venc.getMonth()-1].id
                      : Meses[data_venc.getMonth()].id;

      let ano_ref = event.target.name === "anoReferencia"
                    ? event.target.value
                    : event.target.name === "dataVencimentoParcelaMensalidade" 
                      ? data_venc.getFullYear() 
                      : lista[0].ano_referencia;
                    
      let dataVencimento = '', 
          dataHoje = moment(new Date(ano_ref, mes_ref_id, data_venc.getDate()));

      for (var i = 1; i < parseInt(lista.length) + 1; i++) {
        let valor = 0;
        if (i === 1) {
          dataHoje.add(1, "month");
          dataVencimento = moment()
            .set("year", dataHoje.get("year"))
            .set("month", dataHoje.get("month"))
            .set("date", dataHoje.get('date'));
          const quantidadeDiasDoMes = moment().daysInMonth();
          const valorDia =
            this.props.acao.tipo_titulo.servico_mensalidade.valor /
            quantidadeDiasDoMes;
          const diasFaltantesFinalDoMes = moment()
            .endOf("month")
            .subtract(moment())
            .get("date");
          valor = diasFaltantesFinalDoMes * valorDia;
        } else {
          dataVencimento.add(1, "month");
          const mes = dataVencimento.get("month") + 1;
          dataVencimento = moment(
            dataVencimento.get("year") +
              "-" +
              (mes.length === 1 ? "0" + mes : mes) +
              "-" +
              dataHoje.get('date')
          );
          valor = this.props.acao.tipo_titulo.servico_mensalidade.valor;
        }
        const mes = dataVencimento.get("month") + 1;
        parcelas.push({
          numero: i,
          valor: parseFloat(valor).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          data_vencimento: proximoDiaUtil(dataVencimento.get("year") +
          "-" +
          (mes.toString().length === 1 ? "0" + mes : mes) +
          "-" +
          (dataVencimento.get("date").toString().length === 1
            ? "0" + dataVencimento.get("date")
            : dataVencimento.get("date"))),
          mes_referencia:
            Meses[
              dataVencimento.get("month") - 1 === -1
                ? 11
                : dataVencimento.get("month") - 1
            ].value,
          ano_referencia:
            dataVencimento.get("month") - 1 === -1
              ? dataVencimento.get("year") - 1
              : dataVencimento.get("year"),
        });
        this.props.setParcelasMensalidade(parcelas);
      }
      return parcelas;
    } catch(error) {
      console.log("atualizaLista/error: ", error);
    }
  };

  alteracaoParcelasTaxaTransferencia = (event) => {
    let lista = [];
    const indice = parseInt(event.target.id);
    lista = this.props.infoParcelasTaxaTransferencia;
    lista.splice(indice, 1, {
      numero: indice + 1,
      valor:
        event.target.name === "valorParcelaTaxaTransferencia"
          ? event.target.value
          : lista[indice].valor,
      data_vencimento:
        event.target.name === "dataVencimentoTaxaTransferencia"
          ? event.target.value
          : lista[indice].data_vencimento,
    });
    if (lista.find((e) => e.valor.toString() === "NaN")) {
      lista.forEach((element) => {
        if (element.valor.toString() === "NaN") {
          element.valor = "";
        }
      });
    }
    this.props.setParcelasTaxaTransferencia(lista);
    if (event.target.name === "valorParcelaTaxaTransferencia") {
      this.componentMoney();
    }
  };

  alteracaoFormaPagamentoTaxaTranferencia = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.value === "parcelado") {
      this.setState({ tipo_pagamento: event.target.value });
    } else {
      this.geraParcelas(event);
    }
  };

  alteraDataDiaUtil(date_param) {
    const date = new Date(date_param);
    if (typeof date === 'object' && date !== null && 'getDay' in date && date.toString().length == 62 
    && (date.getDay() == 0 || date.getDay() == 6 || checkFeriado(date_param))
    ) {
      const data_prox = moment(new Date(proximoDiaUtil(date, this.state.calendarEvents)));
      console.log('data_prox: ' + data_prox.format("YYYY-MM-DD"));
      return data_prox;
    }
    return moment(date_param);
  };

  geraParcelas = (event) => {
    const parcelas = [];
    let dataVencimento = "";
    let dataHoje = moment();
    if (event.target.value === "avista") {
      if (this.props.acao.tipo_titulo.servico_taxa_transferencia === null) {
        alert(
          "Favor Vincular Serviço de Taxa de Transferência na pagina Tipo De Titulo"
        );
      } else {
        dataHoje.add(1, "month");
        dataVencimento = moment(
          dataHoje.get("year") +
            "-" +
            (dataHoje.get("month").length === 1
              ? "0" + dataHoje.get("month")
              : dataHoje.get("month")) +
            "-" +
            dataHoje.add(1, "day").get("date")
        );

        // if (dataVencimento.day() === 6 || dataVencimento.day() === 0) {
        //   dataVencimento = proximoDiaUtil(dataVencimento);
        // }

        parcelas.push({
          numero: 1,
          valor: parseFloat(
            this.props.acao.tipo_titulo.servico_taxa_transferencia.valor
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          data_vencimento: moment(proximoDiaUtil(dataVencimento)).format("YYYY-MM-DD"),
        });
        this.props.setParcelasTaxaTransferencia(parcelas);
      }
    } else {
      const numeroParcela = event.target.value.toString().replace("x", "");
      if (event.target.name === "forma_pagamento_mensalidade") {
        if (this.props.acao.tipo_titulo.servico_mensalidade === null) {
          return alert(
            "Favor Vincular Serviço de Mensalidade na pagina Tipo De Titulo"
          );
        } else {
          if(numeroParcela === '0') {
            this.props.setGerarParcelasMensalidade(false);
            return;
          }
          this.props.setGerarParcelasMensalidade(true);
          for (var i = 1; i < parseInt(numeroParcela) + 1; i++) {
            let valor = 0;
            if (i === 1) {
              dataHoje.add(1, "month");
              dataVencimento = moment()
                .set("year", dataHoje.get("year"))
                .set("month", dataHoje.get("month"))
                .set("date", this.props.acao.tipo_titulo.dia_vencimento);
              // if (dataVencimento.day() === 6 || dataVencimento.day() === 0) {
              //   dataVencimento = proximoDiaUtil(dataVencimento);
              // }
              const quantidadeDiasDoMes = moment().daysInMonth();
              const valorDia =
                this.props.acao.tipo_titulo.servico_mensalidade.valor /
                quantidadeDiasDoMes;
              const diasFaltantesFinalDoMes = moment()
                .endOf("month")
                .subtract(moment())
                .get("date");
              valor = diasFaltantesFinalDoMes * valorDia;
            } else {
              dataVencimento.add(1, "month");
              const mes = dataVencimento.get("month") + 1;
              dataVencimento = moment(
                dataVencimento.get("year") +
                  "-" +
                  (mes.length === 1 ? "0" + mes : mes) +
                  "-" +
                  this.props.acao.tipo_titulo.dia_vencimento
              );
              // if (dataVencimento.day() === 6 || dataVencimento.day() === 0) {
              //   dataVencimento = proximoDiaUtil(dataVencimento);
              // }
              valor = this.props.acao.tipo_titulo.servico_mensalidade.valor;
            }
            const mes = dataVencimento.get("month") + 1;
            parcelas.push({
              numero: i,
              valor: parseFloat(valor).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              data_vencimento: proximoDiaUtil(dataVencimento.get("year") +
              "-" +
              (mes.toString().length === 1 ? "0" + mes : mes) +
              "-" +
              (dataVencimento.get("date").toString().length === 1
                ? "0" + dataVencimento.get("date")
                : dataVencimento.get("date"))),
              mes_referencia:
                Meses[
                  dataVencimento.get("month") - 1 === -1
                    ? 11
                    : dataVencimento.get("month") - 1
                ].value,
              ano_referencia:
                dataVencimento.get("month") - 1 === -1
                  ? dataVencimento.get("year") - 1
                  : dataVencimento.get("year"),
            });
            this.props.setParcelasMensalidade(parcelas);
          }
        }
      } else {
        if (this.props.acao.tipo_titulo.servico_taxa_transferencia === null) {
          alert(
            "Favor Vincular Serviço de Taxa de Transferência na pagina Tipo De Titulo"
          );
        } else {
          for (var ind = 1; ind < parseInt(numeroParcela) + 1; ind++) {
            if (ind === 1) {
              dataHoje = dataHoje.add(1, "month");
              dataVencimento = moment(
                dataHoje.get("year") +
                  "-" +
                  (dataHoje.get("month").length === 1
                    ? "0" + dataHoje.get("month")
                    : dataHoje.get("month")) +
                  "-" +
                  dataHoje.add(1, "day").get("date")
              );
              // if (dataVencimento.day() === 6 || dataVencimento.day() === 0) {
              //   dataVencimento = proximoDiaUtil(dataVencimento);
              // }
            } else {
              dataVencimento.add(1, "month");
              const mes = dataVencimento.get("month") + 1;
              dataVencimento = moment(
                dataVencimento.get("year") +
                  "-" +
                  (mes.length === 1 ? "0" + mes : mes) +
                  "-" +
                  this.props.acao.tipo_titulo.dia_vencimento
              );
              // if (dataVencimento.day() === 6 || dataVencimento.day() === 0) {
              //   dataVencimento = proximoDiaUtil(dataVencimento);
              // }
            }
            const mes = dataVencimento.get("month") + 1;
            parcelas.push({
              numero: ind,
              valor: parseFloat(
                this.props.acao.tipo_titulo.servico_taxa_transferencia.valor /
                  numeroParcela
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }),
              data_vencimento: proximoDiaUtil(dataVencimento.get("year") +
              "-" +
              (mes.toString().length === 1 ? "0" + mes : mes) +
              "-" +
              (dataVencimento.get("date").toString().length === 1
                ? "0" + dataVencimento.get("date")
                : dataVencimento.get("date"))),
            });
            this.props.setParcelasTaxaTransferencia(parcelas);
          }
        }
      }
    }
  };

  render() {
    return (
      <Col sm={12} style={{ paddingTop: 15 }}>
        <Header as="h3">Cobrança</Header>
        <Segment.Group>
          <Segment.Group horizontal>
            <Segment>
              <Row style={{ paddingBottom: 20 }}>
                <Col sm={4}>
                  <b>Tipo Cobrança</b>
                  <Form.Control
                    placeholder="Tipo"
                    as="select"
                    name="tipo_cobranca"
                    value={this.props.pessoa?.tipo_cobranca}
                    disabled={true}
                  >
                    <option value=""></option>
                    <option value="boleto">Boleto</option>
                    <option value="debito_em_conta">Débito em conta</option>
                    <option value="isento">Isento</option>
                  </Form.Control>
                </Col>
                {this.props.pessoa?.tipo_cobranca === "boleto" && (
                  <>
                  <Col md="auto">
                    <Divider orientation="vertical" />
                  </Col>
                    <Col sm={4}>
                      <b>Instituição Cobrança</b>
                      <Form.Control
                        placeholder="Instituição Cobrança"
                        as="select"
                        name="instituicao_cobranca_id"
                        value={this.props.instituicao_cobranca_id}
                        onChange={(evt) => {
                          this.props.alteracao({ ...evt });
                          let inst = this.state.instituicoes.find(
                            (e) => e.id === evt.target.value
                          );
                          this.props.alteracao({
                            target: {
                              name: "instituicao_cobranca",
                              value: inst,
                            },
                          });
                        }}
                        required
                      >
                        <option value=""></option>
                        {this.state.instituicoes.map((e, i) => (
                          <option value={e.value}>{e.text}</option>
                        ))}
                      </Form.Control>
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                <Col sm={4}>
                  <Row>
                    <Col sm={5} style={{ fontWeight: "bold" }}>
                      <b>Taxa Transferência:</b>
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        as="select"
                        name="tipo_pagamento"
                        onChange={this.alteracaoFormaPagamentoTaxaTranferencia}
                      >
                        <option></option>
                        <option value="avista">Á Vista</option>
                        <option value="parcelado">Parcelado</option>
                      </Form.Control>
                    </Col>
                    <Col sm={3}>
                      <Form.Control
                        hidden={
                          this.state.tipo_pagamento === "avista" ||
                          this.state.tipo_pagamento === null
                        }
                        as="select"
                        name="forma_pagamento_taxa_transferencia"
                        onChange={this.geraParcelas}
                      >
                        <option></option>
                        {this.state.parcelas.map((e, i) => (
                          <option key={i} value={e.value}>
                            {e.label}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: 5 }}>
                    <Col sm={12}>
                      <Checkbox
                        label={"Gerar cobrança bancária"}
                        checked={this.props.cobrancaBancariaTaxaTransferencia}
                        onChange={(e, a) =>
                          this.props.setState({
                            cobrancaBancariaTaxaTransferencia: a.checked,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  {this.props.infoParcelasTaxaTransferencia.map((e, i) => {
                    return (
                      <Row>
                        <Col sm={6}>
                          Parcela {e.numero}
                          <Form.Control
                            id={"" + i + ""}
                            disabled={true}
                            onChange={this.alteracaoParcelasTaxaTransferencia}
                            value={e.valor}
                            onKeyUp={this.alteracaoParcelasTaxaTransferencia}
                            name="valorParcelaTaxaTransferencia"
                            className="dinheiro"
                          ></Form.Control>
                        </Col>
                        <Col sm={6}>
                          Vencimento
                          <FormControl
                            type="date"
                            value={e.data_vencimento}
                            id={"" + i + ""}
                            onChange={this.alteracaoParcelasTaxaTransferencia}
                            name="dataVencimentoTaxaTransferencia"
                          ></FormControl>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
                <Col md="auto">
                  <Divider orientation="vertical" />
                </Col>
                <Col>
                  <Row>
                    <Col md="auto" style={{ fontWeight: "bold" }}>
                      <b>Mensalidade:</b>
                    </Col>
                    <Col sm={2}>
                      <Form.Control
                        placeholder="Tipo"
                        as="select"
                        name="forma_pagamento_mensalidade"
                        onChange={this.geraParcelas}
                      >
                        <option></option>
                        <option value="0">0</option>
                        <option value="1x">1</option>
                        <option value="2x">2</option>
                        <option value="3x">3</option>
                        <option value="4x">4</option>
                        <option value="5x">5</option>
                        <option value="6x">6</option>
                        <option value="7x">7</option>
                        <option value="8x">8</option>
                        <option value="9x">9</option>
                        <option value="10x">10</option>
                        <option value="11x">11</option>
                        <option value="12x">12</option>
                      </Form.Control>
                    </Col>
                  </Row>

                  {this.props.infoParcelasMensalidade.map((e, i) => {
                    return (
                      <Row>
                        <Col sm={2}>
                          Parcela {e.numero}
                          <Form.Control
                            id={"" + i + ""}
                            disabled={i == 0 ? false : true}
                            value={e.valor}
                            onKeyUp={this.alteracaoParcelasMensalidade}
                            onChange={this.alteracaoParcelasMensalidade}
                            name="valorParcelaMensalidade"
                            className="dinheiro"
                          ></Form.Control>
                        </Col>
                        <Col sm={3}>
                          Mês de referência
                          <Form.Control
                            id={"" + i + ""}
                            as="select"
                            name="mesReferencia"
                            disabled={i == 0 ? false : true}
                            // onBlur={this.alteracaoParcelasMensalidade}
                            onChange={this.alteracaoParcelasMensalidade}
                            value={
                              this.props.infoParcelasMensalidade[i]
                                .mes_referencia
                            }
                          >
                            <option></option>
                            {this.state.meses.map((e, i) => (
                              <option key={i} value={e.value}>
                                {e.label}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col sm={2}>
                          Ano
                          <Form.Control
                            id={"" + i + ""}
                            value={e.ano_referencia}
                            name="anoReferencia"
                            disabled={true}
                            onBlur={this.alteracaoParcelasMensalidade}
                          ></Form.Control>
                        </Col>
                        <Col sm={5}>
                          Vencimento
                          <FormControl
                            type="date"
                            value={e.data_vencimento}
                            id={"" + i + ""}
                            disabled={i == 0 ? false : true}
                            onChange={this.alteracaoParcelasMensalidade}
                            name="dataVencimentoParcelaMensalidade"
                          ></FormControl>
                        </Col>
                      </Row>
                    )
                  })}
                </Col>
              </Row>
            </Segment>
          </Segment.Group>
        </Segment.Group>
      </Col>
    );
  }
}
