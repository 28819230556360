import React, { Component } from "react";
import { Header, Segment, Modal, Button, Checkbox } from "semantic-ui-react";
import { Col, Form, Row, FormControl } from "react-bootstrap";
import moment from "moment-timezone";
import proximoDiaUtil from "../../../utils/proximoDiaUtil";
import Meses from "../../../enum/Mes";
import api from "../../../services/api";
import Requisicoes from "../../../utils/Requisicoes";
import Parcelas from "../../../enum/ParcelasEnum";
import $ from "jquery";
import ReplaceAll from "replaceall";
// import { CobrancaParcelada } from "../../../components/CobrancaParcelado";
// var formatter = new Intl.NumberFormat("pt-br", {
//   minimumFractionDigits: 2,
//   maximumFractionDigits: 2,
// });

export default class Cobranca extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo_pagamento: null,
      // parcelas: [],
      infoParcelasTitulo: [],
      meses: Meses,
      listaDataExames: [],
      parcelas: Parcelas,
      dia_vencimento: null,
      data_vencimento_inscricao: moment().tz("America/Sao_Paulo").format(),
      dataExameMedico: moment().format("YYYY-MM-DD"),
      dataValidadeMedico: moment().add(6, "M").format("YYYY-MM-DD"),
      dataExameFisico: moment().format("YYYY-MM-DD"),
      dataValidadeFisico: moment().add(1, "Y").format("YYYY-MM-DD"),
      checkboxesExameMedico: false,
      checkboxesExamedFisico: false,

      loading: false,
      observacao: "",
    };
    this.dia_referencia();
  }

  componentMoney() {
    $(".dinheiro").mask("#.##0,00", {
      reverse: true,
      placeholder: "0,00",
    });
  }

  dia_referencia = async () => {
    // await api
    //   .post(
    //     "/tipoTitulo/diaReferencia" + Requisicoes.entidade,
    //     {},
    //     Requisicoes.header
    //   )
    //   .then((dia_refencia) => {
    //     this.cobrancaInscricao(moment().format("YYYY-MM-DD"));
    //     this.setState({ dia_vencimento: dia_refencia.data.dia_vencimento });
    //   });

    await api
      .post(
        "/parametros/visualiza" + Requisicoes.entidade,
        {
          nome: "dia_cobranca_matricula",
        },
        Requisicoes.header
      )
      .then((dia_refencia) => {
        this.cobrancaInscricao(moment().format("YYYY-MM-DD"));
        this.setState({ dia_vencimento: parseInt(dia_refencia.data[0].valor) });
      });
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  atualizaLista(data_venc, event, lista) {
    try {
      let parcelas = [];
      let diaVencimentoBase = null;      

      let mes_ref =
        event.target.name === "mesReferencia"
          ? event.target.value
          : lista[0].mes_referencia;

      let mes_ref_id =
        event.target.name === "mesReferencia"
          ? Meses.find((n) => {
              return n.value === mes_ref;
            }).id
          : event.target.name === "dataVencimentoParcelaMensalidade"
          ? Meses[data_venc.getMonth() - 1 < 0 ? 11 : data_venc.getMonth() - 1]
              .id
          : Meses[data_venc.getMonth()].id;

      let ano_ref =
        event.target.name === "anoReferencia"
          ? event.target.value
          : event.target.name === "dataVencimentoParcelaMensalidade"
          ? data_venc.getFullYear()
          : lista[0].ano_referencia;

      let dataVencimento = "",
        dataHoje = moment(new Date(ano_ref, mes_ref_id, data_venc.getDate()));

      for (var i = 1; i <= parseInt(lista.length); i++) {
        let valor = 0;
        if (i === 1) {
          dataHoje.add(1, "month");
          dataVencimento = moment()
            .set("year", dataHoje.get("year"))
            .set("month", dataHoje.get("month"))
            .set("date", dataHoje.get("date"));
          const quantidadeDiasDoMes = moment().daysInMonth();
          const valorDia = this.props.valorMensalidade / quantidadeDiasDoMes;
          const diasFaltantesFinalDoMes = moment()
            .endOf("month")
            .subtract(moment())
            .get("date");
          valor = diasFaltantesFinalDoMes * valorDia;
        } else {
          dataVencimento.add(1, "month");
          const mes = dataVencimento.get("month") + 1;
          dataVencimento = moment(
            dataVencimento.get("year") +
              "-" +
              (mes.length === 1 ? "0" + mes : mes) +
              "-" +
              dataHoje.get("date")
          );
          valor = this.props.valorMensalidade;
        }
        if (diaVencimentoBase === null)
          diaVencimentoBase = dataHoje.get("date");
        dataVencimento.set("date", diaVencimentoBase);

        // const mes = dataVencimento.get("month") + 1;
        // dataVencimento = setMonth(dataVencimento, mes);
        parcelas.push({
          numero: i,
          valor: parseFloat(valor).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          data_vencimento: moment(dataVencimento).format("YYYY-MM-DD"),
          /*
           moment(proximoDiaUtil(dataVencimento)).format("YYYY-MM-DD"),
            dataVencimento.get("year") +
            "-" +
            (mes.toString().length === 1 ? "0" + mes : mes) +
            "-" +
            (dataVencimento.get("date").toString().length === 1
              ? "0" + dataVencimento.get("date")
              : dataVencimento.get("date"))*/
          mes_referencia:
            Meses[
              dataVencimento.get("month") - 1 === -1
                ? 11
                : dataVencimento.get("month") - 1
            ].value,
          ano_referencia:
            dataVencimento.get("month") - 1 === -1
              ? dataVencimento.get("year") - 1
              : dataVencimento.get("year"),
        });
        this.props.setParcelasMensalidade(parcelas);
      }
      return parcelas;
    } catch (error) {
      console.log("atualizaLista/error: ", error);
    }
  }
  alteraParcelasInscricao = (event) => {
    const indice = event.target.id;
    let lista = this.props.infoParcelasInscricao;

    if (event.target.name === "valorParcelaInscricao") {
      event.target.value = ReplaceAll(".", ",", event.target.value);
    }

    lista.splice(parseInt(indice), 1, {
      numero: parseInt(indice) + 1,
      valor:
        event.target.name === "valorParcelaInscricao"
          ? event.target.value
          : lista[parseInt(indice)] !== undefined
          ? lista[parseInt(indice)].valor
          : "",
      data_vencimento:
        event.target.name === "dataVencimentoParcelaInscricao"
          ? event.target.value
          : lista[parseInt(indice)] !== undefined
          ? lista[parseInt(indice)].data_vencimento
          : "",
    });
    this.props.setParcelasInscricao(lista);
    this.componentMoney();
  };

  alteracaoParcelasMensalidade = (event) => {
    let lista = [];
    let parcelas = [];
    const indice = event.target.id;
    lista = this.props.infoParcelasMensalidade;
    if (event.target.name === "valorParcelaMensalidade") {
      lista.splice(indice, 1, {
        numero: indice + 1,
        valor:
          event.target.name === "valorParcelaMensalidade"
            ? event.target.value
            : lista[indice].valor,
        data_vencimento: lista[indice].data_vencimento,
        mes_referencia: lista[indice].mes_referencia,
        ano_referencia: lista[indice].ano_referencia,
      });
    } else {
      lista.splice(parseInt(indice), 1, {
        numero: parseInt(indice) + 1,
        valor:
          event.target.name === "valorParcelaMensalidade"
            ? event.target.value
            : lista[parseInt(indice)].valor,
        data_vencimento:
          event.target.name === "dataVencimentoParcelaMensalidade"
            ? event.target.value
            : lista[parseInt(indice)].data_vencimento,
        mes_referencia:
          event.target.name === "mesReferencia"
            ? event.target.value
            : lista[parseInt(indice)].mes_referencia,
        ano_referencia:
          event.target.name === "anoReferencia"
            ? event.target.value
            : lista[parseInt(indice)].ano_referencia,
      });
      let data_venc = moment(
        event.target.name === "dataVencimentoParcelaMensalidade"
          ? event.target.value
          : lista[0].data_vencimento
      ).toDate();

      const atualizarListaParcelas =
        data_venc !== undefined &&
        data_venc !== "Invalid Date" &&
        data_venc.toString().length === 62 &&
        moment(data_venc).get("year") > 2000;
      // const atualizarListaParcelas =
      //   data_venc !== undefined &&
      //   data_venc !== "Invalid Date" &&
      //   data_venc.toString().length === 62 &&
      //   moment(data_venc).get("year") > 2000;
      if (atualizarListaParcelas) {
        parcelas = this.atualizaLista(data_venc, event, lista);
      }

    }

    if (parcelas !== null && parcelas !== undefined && parcelas.length > 0) {
      lista = parcelas;
    }
    if (
      parseInt(indice) === 0 &&
      event.target.name === "dataVencimentoParcelaMensalidade"
    ) {
      const quantidadeDiasDoMes = moment(event.target.value).daysInMonth();
      const valorDia = this.props.valorMensalidade / quantidadeDiasDoMes;
      const diasFaltantesFinalDoMes = moment(event.target.value)
        .endOf("month")
        .subtract(moment(event.target.value))
        .get("date");
      let dataAtual = moment().format("YYYY-MM-dd");
      let data_vencimento = lista[indice].data_vencimento;
      if (data_vencimento > dataAtual) {
        lista[parseInt(indice)].valor = parseFloat(
          this.props.valorMensalidade
        ).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else {
        lista[parseInt(indice)].valor = parseFloat(
          diasFaltantesFinalDoMes * valorDia
        ).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    }
    if (lista.find((e) => e.valor.toString() === "NaN")) {
      lista.forEach((element) => {
        if (element.valor.toString() === "NaN") {
          element.valor = "";
        }
      });
    }
    this.props.setParcelasMensalidade(lista);
    // this.props.infoParcelasMensalidade.push(lista)
    if (event.target.name === "valorParcelaMensalidade") {
      this.componentMoney();
    }
    // this.componentMoney();
  };

  alteracaoDataExamesMedico = (event) => {
    let lista = [];
    //listaDataExames
    // lista
    this.setState({ [event.target.name]: event.target.value });
    if (
      event.target.name === "dataExameMedico" &&
      moment(event.target.value).isValid()
    ) {
      this.props.setAlteraDataExameMedico({
        dataExameMedico: moment(event.target.value).format("YYYY-MM-DD"),
      });
    }
    if (
      event.target.name === "dataValidadeMedico" &&
      moment(event.target.value).isValid()
    ) {
      this.props.setAlteraDataValidadeMedico({
        dataValidadeMedico: moment(event.target.value).format("YYYY-MM-DD"),
      });
    }
  };

  alteracaoDataExamesFisico = (event) => {
    let lista = [];

    this.setState({ [event.target.name]: event.target.value });
    if (
      event.target.name === "dataExameFisico" &&
      moment(event.target.value).isValid()
    ) {
      this.props.setAlteraDataExameFisico({
        dataExameFisico: moment(event.target.value).format("YYYY-MM-DD"),
      });
    }
    if (
      event.target.name === "dataValidadeFisico" &&
      moment(event.target.value).isValid()
    ) {
      this.props.setAlteraDataValidadeFisico({
        dataValidadeFisico: moment(event.target.value).format("YYYY-MM-DD"),
      });
    }
  };
  cobrancaInscricao = (data_vencimento) => {
    if (this.props.valorInscricao !== null) {
      this.setState({ data_vencimento_inscricao: data_vencimento });
      let lista = [];
      lista.push({
        valor: parseFloat(this.props.valorInscricao).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        data_vencimento: data_vencimento,
      });
      this.props.setParcelasInscricao(lista);
    }
  };

  geraParcelas = (event) => {
    const parcelas = [];
    let dataVencimento = "";
    let dataHoje = moment();

    if (event.target.value === "avista") {
      dataHoje.add(2, "month");
      dataVencimento = moment(
        dataHoje.get("year") +
          "-" +
          (dataHoje.get("month").length === 1
            ? "0" + dataHoje.get("month")
            : dataHoje.get("month")) +
          "-" +
          this.state.dia_vencimento
      );
      const mes = dataVencimento.get("month") + 1;
      parcelas.push({
        numero: 1,
        valor: parseFloat(this.props.valorMensalidade).toLocaleString(
          undefined,
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        ),
        data_vencimento:
          dataVencimento.get("year") +
          "-" +
          (dataVencimento.get("month").toString().length === 1
            ? "0" + mes
            : mes) +
          "-" +
          (dataVencimento.get("date").toString().length === 1
            ? "0" + dataVencimento.get("date")
            : dataVencimento.get("date")),
      });
      this.props.setParcelasInscricao(parcelas);
    } else {
      const numeroParcela = event.target.value.toString().replace("x", "");
      if (event.target.name === "forma_pagamento_mensalidade") {
        for (var i = 1; i < parseInt(numeroParcela) + 1; i++) {
          let valor = 0;
          if (i === 1) {
            dataHoje.add(1, "month");
            dataVencimento = moment()
              .tz("America/Sao_Paulo")
              .set(
                "date",
                dataHoje.get("date") <= this.state.dia_vencimento
                  ? this.state.dia_vencimento
                  : dataHoje.get("date")
              )
              .set("month", dataHoje.get("month") - 1)
              .set("year", dataHoje.get("year"));
            // if (dataVencimento.day() === 6 || dataVencimento.day() === 0) {
            //   dataVencimento = proximoDiaUtil(dataVencimento);
            // }
            const quantidadeDiasDoMes = moment().daysInMonth();
            const valorDia = this.props.valorMensalidade / quantidadeDiasDoMes;

            let inicio = moment().format("YYYY-MM-DD");
            let fim = moment().endOf("month").format("YYYY-MM-DD");

            const diasFaltantesFinalDoMes =
              moment(fim).diff(moment(inicio), "days") + 1;

            valor = diasFaltantesFinalDoMes * valorDia;
          } else {
            dataVencimento.add(1, "month");
            const mes = dataVencimento.get("month") + 1;
            dataVencimento = moment(
              dataVencimento.get("year") +
                "-" +
                (mes.length === 1 ? "0" + mes : mes) +
                "-" +
                this.state.dia_vencimento
            );
            // if (dataVencimento.day() === 6 || dataVencimento.day() === 0) {
            //   dataVencimento = proximoDiaUtil(dataVencimento);
            // }
            valor = this.props.valorMensalidade;
          }
          const mes = dataVencimento.get("month") + 1;
          parcelas.push({
            numero: i,
            valor: parseFloat(valor).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            data_vencimento:
              dataVencimento.get("year") +
              "-" +
              (mes.toString().length === 1 ? "0" + mes : mes) +
              "-" +
              (dataVencimento.get("date").toString().length === 1
                ? "0" + dataVencimento.get("date")
                : dataVencimento.get("date")),
            mes_referencia: Meses[dataVencimento.get("month")].value,
            ano_referencia:
              // dataVencimento.get("month") - 1 === -1
              //   ? dataVencimento.get("year") - 1
              //   :
              dataVencimento.get("year"),
          });
          this.props.setParcelasMensalidade(parcelas);
        }
      }
    }
  };

  zerarParcelas = () => {
    let lista = this.props.infoParcelasInscricao;

    for (let i = 0; i < lista.length; i++) {
      const element = lista[i];
      element.valor = "0,0";
    }
    this.props.setParcelasInscricao(lista);

    lista = this.props.infoParcelasMensalidade;
    for (let i = 0; i < lista.length; i++) {
      const element = lista[i];
      element.valor = "0,0";
    }

    this.props.setParcelasMensalidade(lista);
  };

  openCobranca = () => {
    this.setState({ loading: false });
    this.props.setObservacao("");
    this.props.setParcelasMensalidade([]);
    this.props.setAlteraDataExameMedico([]);
    this.props.setAlteraDataExameFisico([]);
    this.props.setcheckboxesExameMedico([]);
    this.props.setcheckboxesExameFisico([]);
  };
  exameMedico = () => {
    if (this.state.checkboxesExameMedico === false) {
      this.setState({ checkboxesExameMedico: true });
      this.props.setcheckboxesExameMedico(true);
    }
    if (this.state.checkboxesExameMedico === true) {
      this.setState({ checkboxesExameMedico: false });
      this.props.setcheckboxesExameMedico(false);
    }
  };
  exameFisico = () => {
    if (this.state.checkboxesExamedFisico === false) {
      this.setState({ checkboxesExamedFisico: true });
      this.props.setcheckboxesExameFisico(true);
    }
    if (this.state.checkboxesExamedFisico === true) {
      this.setState({ checkboxesExamedFisico: false });
      this.props.setcheckboxesExameFisico(false);
    }
  };
  render() {
    return (
      <Modal
        open={this.props.open_cobranca}
        onClose={this.props.handleCloseCobranca}
        onOpen={() => this.openCobranca()}
        onMount={this.openCobranca}
        centered={false}
        size="large"
      >
        <Header content="Cobrança" />
        <Modal.Content>
          <Col sm={12} style={{ paddingTop: "10px" }}>
            <Segment.Group>
              <Segment.Group horizontal>
                <Segment>
                  <Row>
                    <Col sm={5}>
                      <Col sm={2}>
                        <b>Inscrição:</b>
                      </Col>
                      <Col sm={10}>
                        <Row>
                          <Col sm={6}>
                            Parcela
                            <Form.Control
                              // id="valorParcelaTitulo"
                              id="0"
                              onChange={this.alteraParcelasInscricao}
                              name="valorParcelaInscricao"
                              className="dinheiro"
                              // defaultValue={
                              //   this.props.valorInscricao !== null
                              //     ? formatter.format(
                              //         this.props.valorInscricao
                              //       )
                              //     : ""
                              // }
                              onKeyUp={this.alteraParcelasInscricao}
                              value={
                                this.props.infoParcelasInscricao[0] !==
                                undefined
                                  ? this.props.infoParcelasInscricao[0].valor
                                  : 0
                              }
                            ></Form.Control>
                          </Col>
                          <Col sm={6}>
                            Vencimento
                            <FormControl
                              type="date"
                              // defaultValue=""
                              id="dataVencimentoInscricao"
                              name="dataVencimentoParcelaInscricao"
                              defaultValue={this.state.data_vencimento_inscricao}
                              onChange={this.alteraParcelasInscricao}
                            ></FormControl>
                          </Col>
                          <Col sm={6} style={{ paddingTop: "5px" }}>
                            <Checkbox
                              label="Data Exames"
                              onClick={this.exameMedico}
                            />
                          </Col>
                          <Col sm={6} style={{ paddingTop: "5px" }}>
                            <Checkbox
                              label="Data Fisico"
                              onClick={this.exameFisico}
                            />
                          </Col>
                          {this.state.checkboxesExameMedico === true && (
                            <>
                              <Col sm={6}>
                                <Form.Label>Exame Médico</Form.Label>
                                <FormControl
                                  type="date"
                                  aria-describedby="basic-addon1"
                                  name="dataExameMedico"
                                  onChange={this.alteracaoDataExamesMedico}
                                  required
                                  value={this.state.dataExameMedico}
                                />
                              </Col>
                              <Col sm={6}>
                                <Form.Label>Validade Médico</Form.Label>
                                <FormControl
                                  type="date"
                                  aria-describedby="basic-addon1"
                                  name="dataValidadeMedico"
                                  onChange={this.alteracaoDataExamesMedico}
                                  required
                                  value={this.state.dataValidadeMedico}
                                />
                              </Col>
                            </>
                          )}
                          {this.state.checkboxesExamedFisico === true && (
                            <>
                              <Col sm={6}>
                                <Form.Label>Exame Fisico</Form.Label>
                                <FormControl
                                  type="date"
                                  aria-describedby="basic-addon1"
                                  name="dataExameFisico"
                                  onChange={this.alteracaoDataExamesFisico}
                                  required
                                  value={this.state.dataExameFisico}
                                />
                              </Col>
                              <Col sm={6}>
                                <Form.Label>Validade Fisico</Form.Label>
                                <FormControl
                                  type="date"
                                  aria-describedby="basic-addon1"
                                  name="dataValidadeFisico"
                                  onChange={this.alteracaoDataExamesFisico}
                                  required
                                  value={this.state.dataValidadeFisico}
                                />
                              </Col>
                            </>
                          )}
                        </Row>
                        <Row style={{ paddingTop: 20 }}>
                          <Col>
                            <Button
                              fluid
                              size="tiny"
                              onClick={this.zerarParcelas}
                            >
                              ISENTO
                            </Button>
                          </Col>
                        </Row>
                        <Row style={{ paddingTop: 20 }}>
                          <Col>
                            <FormControl
                              placeholder="Observações da matrícula"
                              aria-describedby="basic-addon1"
                              as="textarea"
                              name="observacoes"
                              rows={4}
                              // defaultValue={this.props.observacao}
                              // onChange={(e)=>this.props.setObservacao(e.target.value)}
                              onChange={(e) =>
                                this.setState({ observacao: e.target.value })
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Col>
                    <Col sm={7}>
                      <Row>
                        <Col sm={3}>
                          <b>Mensalidade:</b>
                        </Col>
                        <Col sm={8}>
                          <Form.Control
                            placeholder="Tipo"
                            as="select"
                            name="forma_pagamento_mensalidade"
                            onChange={this.geraParcelas}
                          >
                            <option></option>
                            <option value="1x">1</option>
                            <option value="2x">2</option>
                            <option value="3x">3</option>
                            <option value="4x">4</option>
                            <option value="5x">5</option>
                            <option value="6x">6</option>
                            <option value="7x">7</option>
                            <option value="8x">8</option>
                            <option value="9x">9</option>
                            <option value="10x">10</option>
                            <option value="11x">11</option>
                            <option value="12x">12</option>
                          </Form.Control>
                        </Col>
                        {this.props.infoParcelasMensalidade.map((e, i) => {
                          return (
                            <Row>
                              <Col key={i} sm={12}>
                                <Row>
                                  <Col sm={3}>
                                    Parcela {e.numero}
                                    <Form.Control
                                      value={e.valor}
                                      id={"" + i + ""}
                                      className="dinheiro"
                                      onChange={
                                        this.alteracaoParcelasMensalidade
                                      }
                                      // onBlur={
                                      //   this.alteracaoParcelasMensalidade
                                      // }
                                      onKeyUp={
                                        this.alteracaoParcelasMensalidade
                                      }
                                      name="valorParcelaMensalidade"
                                    ></Form.Control>
                                  </Col>

                                  <Col sm={3}>
                                    Mês de referência
                                    <Form.Control
                                      id={"" + i + ""}
                                      as="select"
                                      name="mesReferencia"
                                      onChange={
                                        this.alteracaoParcelasMensalidade
                                      }
                                      value={
                                        this.props.infoParcelasMensalidade[i]
                                          .mes_referencia
                                      }
                                    >
                                      <option></option>
                                      {this.state.meses.map((e, i) => (
                                        <option key={i} value={e.value}>
                                          {e.label}
                                        </option>
                                      ))}
                                    </Form.Control>
                                  </Col>
                                  <Col sm={2}>
                                    Ano
                                    <Form.Control
                                      value={e.ano_referencia}
                                      id={"" + i + ""}
                                      name="anoReferencia"
                                      onChange={
                                        this.alteracaoParcelasMensalidade
                                      }
                                    ></Form.Control>
                                  </Col>
                                  <Col sm={4}>
                                    <Row>
                                      <Col sm={11}>
                                        Vencimento
                                        <FormControl
                                          type="date"
                                          value={e.data_vencimento}
                                          id={"" + i + ""}
                                          onChange={
                                            this.alteracaoParcelasMensalidade
                                          }
                                          name="dataVencimentoParcelaMensalidade"
                                        ></FormControl>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          );
                        })}
                      </Row>
                    </Col>
                  </Row>
                </Segment>
              </Segment.Group>
            </Segment.Group>
          </Col>
        </Modal.Content>

        <Header floated="right">
          <Row>
            <Col sm={5}>
              <Button
                type="button"
                variant="light"
                onClick={this.props.handleCloseCobranca}
              >
                Voltar
              </Button>
            </Col>
            <Col sm={5}>
              <Button
                color="green"
                disabled={this.state.loading}
                loading={this.state.loading}
                onClick={async () => {
                  await this.props.setObservacao(this.state.observacao);
                  this.setState({ loading: true });
                  this.props.matricular();
                  setTimeout(() => {
                    this.setState({ loading: false });
                  }, 500);
                }}
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </Header>
      </Modal>
    );
  }
}
