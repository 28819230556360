import React, { Component } from "react";
import {
  Container,
  Header,
  Segment,
  Divider,
  Button,
  Icon,
  Checkbox,
} from "semantic-ui-react";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import api from "../../../../services/api";
import Requisicoes from "../../../../utils/Requisicoes";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";

export default class Suspensao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      pessoa: null,
      data_inicial: null,
      data_final: null,
      motivo: null,
      status: null,
      suspensao: null,
      value: {
        value: "",
        label: "",
      },
      inputValue: "",
      id: null,
      dependentes: [],
    };
    this.visualizacao();
  }

  visualizacao = async () => {
    if (this.props.location.query !== undefined) {
      await api
        .post(
          "/suspensao/visualizar" + Requisicoes.entidade,
          { id: this.props.location.query.id },
          Requisicoes.header
        )
        .then((retorno) => {
          this.setState(retorno.data);
          const options = [
            {
              value: retorno.data.pessoa.id,
              label: retorno.data.pessoa.nome,
            },
          ];
          this.setState({ options: options });
          this.setState({
            value: {
              value: retorno.data.pessoa.id,
              label: retorno.data.pessoa.nome,
            },
          });
        });
    }
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  alteracaoAutoComplete = async (event, newValue) => {
    this.setState({ value: newValue });
    if (newValue === null) {
      return;
    }

    console.log("newValue: ", newValue);

    const resposta = await api.post(
      "/pessoa/visualiza" + Requisicoes.entidade,
      { id: newValue.value },
      Requisicoes.header
    );
    if (resposta.data.titular_id === null) {
      var dependentes = await api.post(
        "/pessoa/dependentes" + Requisicoes.entidade,
        { titular_id: resposta.data.id },
        Requisicoes.header
      );
      for (let i = 0; i < dependentes.data.length; i++) {
        const element = dependentes.data[i];
        element.checked = false;
      }
      this.setState({ dependentes: dependentes.data });
      setTimeout(() => {
        console.log("this.state.dependentes: ", this.state.dependentes);
      }, 1000);
    } else {
      var dependentes = await api.post(
        "/pessoa/dependentes" + Requisicoes.entidade,
        { titular_id: resposta.data.titular_id },
        Requisicoes.header
      );
      for (let i = 0; i < dependentes.data.length; i++) {
        const element = dependentes.data[i];
        element.checked = false;
        if (element.id === resposta.data.id) {
          dependentes.data.splice(i, 1);
          break;
        }
      }
      let titular = await api.post(
        "/pessoa/visualiza" + Requisicoes.entidade,
        { id: resposta.data.titular_id },
        Requisicoes.header
      );
      dependentes.data.push(titular.data);

      this.setState({ dependentes: dependentes.data });
    }
  };

  pesquisaPessoa = async () => {
    let term = document.getElementById("pesquisaPessoa").value;
    if (term.toString().indexOf("%") !== -1) {
      term = document
        .getElementById("pesquisaPessoa")
        .value.toString()
        .replace("%", "");
      document.getElementById("pesquisaPessoa").value = term;
      if (document.getElementById("porcentagem").innerText === "%") {
        document.getElementById("porcentagem").innerText = "";
      } else {
        document.getElementById("porcentagem").innerText = "%";
      }
    }
    setTimeout(async () => {
      if (isFinite(String(term))) {
        if (document.getElementById("porcentagem").innerText === "%") {
          await api
            .post(
              "/pessoa/pesquisaPorCodigoAcao" + Requisicoes.entidade,
              { pesquisa: document.getElementById("pesquisaPessoa").value },
              Requisicoes.header
            )
            .then((retorno) => {
              let lista = [];
              retorno.data.forEach((e) => {
                lista.push({
                  value: e.id,
                  label:
                    e.acao !== null ? e.acao.codigo + "-" + e.nome : e.nome,
                });
              });
              this.setState({ options: lista });
            });
        } else {
          await api
            .post(
              "/pessoa/pesquisaPorCodigoAcaoValorExato" + Requisicoes.entidade,
              { pesquisa: document.getElementById("pesquisaPessoa").value },
              Requisicoes.header
            )
            .then((retorno) => {
              let lista = [];
              retorno.data.forEach((e) => {
                lista.push({
                  value: e.id,
                  label:
                    e.acao !== null ? e.acao.codigo + "-" + e.nome : e.nome,
                });
              });
              this.setState({ options: lista });
            });
        }
      } else {
        if (document.getElementById("porcentagem").innerText === "%") {
          await api
            .post(
              "/pessoa/pesquisaNomeInicial" + Requisicoes.entidade,
              { pesquisa: document.getElementById("pesquisaPessoa").value },
              Requisicoes.header
            )
            .then((retorno) => {
              let lista = [];
              retorno.data.forEach((e) => {
                lista.push({ value: e.id, label: e.nome });
              });
              this.setState({ options: lista });
            });
        } else {
          await api
            .post(
              "/pessoa/pesquisaNome" + Requisicoes.entidade,
              { pesquisa: document.getElementById("pesquisaPessoa").value },
              Requisicoes.header
            )
            .then((retorno) => {
              let lista = [];
              retorno.data.forEach((e) => {
                lista.push({ value: e.id, label: e.nome });
              });
              this.setState({ options: lista });
            });
        }
      }
    }, 200);
  };

  submit = async (event) => {
    event.preventDefault();

    console.log("this.state.id: ", this.state.id);
    // if(this.state.suspensao === null){
    if (this.state.id === null) {
      await api.post(
        "/suspensao/salvar" + Requisicoes.entidade,
        {
          pessoa_id: this.state.value.value,
          data_inicial: this.state.data_inicial,
          data_final: this.state.data_final,
          status: this.state.status,
          motivo: this.state.motivo,
        },
        Requisicoes.header
      );

      for (let i = 0; i < this.state.dependentes.length; i++) {
        const element = this.state.dependentes[i];
        if (element.checked) {
          await api.post(
            "/suspensao/salvar" + Requisicoes.entidade,
            {
              pessoa_id: element.id,
              data_inicial: this.state.data_inicial,
              data_final: this.state.data_final,
              status: this.state.status,
              motivo: this.state.motivo,
            },
            Requisicoes.header
          );
        }
      }
    } else {
      await api.post(
        "/suspensao/alterar" + Requisicoes.entidade,
        {
          id: this.state.id,
          pessoa_id: this.state.value.value,
          data_inicial: this.state.data_inicial,
          data_final: this.state.data_final,
          status: this.state.status,
          motivo: this.state.motivo,
        },
        Requisicoes.header
      );
    }
    window.location.href = "/administrativo";
  };

  render() {
    return (
      <div>
        <Header as="h1" dividing>
          Suspensão
        </Header>
        <Form onSubmit={this.submit}>
          <Row>
            <Col sm={6}>
              <Form.Label>Pessoa</Form.Label>
              <Row>
                <Col sm={1}>
                  <Form.Label id="porcentagem"></Form.Label>
                </Col>
                <Col sm={11}>
                  <Autocomplete
                    id="pesquisaPessoa"
                    value={this.state.value}
                    onChange={this.alteracaoAutoComplete}
                    getOptionLabel={(option) => option.label}
                    onKeyDown={this.pesquisaPessoa}
                    inputValue={this.state.inputValue}
                    onInputChange={(event, newInputValue) => {
                      this.setState({ inputValue: newInputValue });
                    }}
                    options={this.state.options}
                    style={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Busca" size="small" />
                    )}
                    disabled={this.state.id !== null}
                  />
                </Col>
              </Row>
            </Col>
            {this.state.pessoa !== null && (
              <Col sm={12} style={{ paddingTop: "10px" }}>
                <Header as="h3">Dados Cadastrais</Header>
                <Divider />
                <Segment.Group>
                  <Segment.Group horizontal>
                    <Segment>
                      <b>Tipo:</b> {this.state.pessoa.tipo}
                    </Segment>
                    <Segment>
                      <b>CPF/CNPJ:</b>{" "}
                      {this.state.cpf !== null
                        ? this.state.cpf
                        : this.state.cnpj}
                    </Segment>
                    <Segment>
                      <b>Telefone:</b> {this.state.pessoa.telefone}
                    </Segment>
                  </Segment.Group>
                  <Segment>
                    <b>Endereço:</b> {this.state.pessoa.endereco} -{" "}
                    {this.state.pessoa.bairro}, {this.state.pessoa.cep}{" "}
                    {this.state.pessoa.cidade}-{this.state.pessoa.estado}
                  </Segment>
                </Segment.Group>
              </Col>
            )}
            <Col sm={12}>
              <Row>
                <Col sm={3}>
                  <Form.Label>Data Inicio</Form.Label>
                  <FormControl
                    aria-describedby="basic-addon1"
                    type="date"
                    name="data_inicial"
                    onChange={this.alteracao}
                    defaultValue={
                      this.state.data_inicial === null
                        ? ""
                        : this.state.data_inicial.toString().substr(0, 10)
                    }
                    required
                  />
                </Col>
                <Col sm={3}>
                  <Form.Label>Data Final</Form.Label>
                  <FormControl
                    aria-describedby="basic-addon1"
                    type="date"
                    name="data_final"
                    onChange={this.alteracao}
                    defaultValue={
                      this.state.data_final === null
                        ? ""
                        : this.state.data_final.toString().substr(0, 10)
                    }
                    required
                  />
                </Col>

                <Col sm={3}>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    name="status"
                    onChange={this.alteracao}
                    required
                  >
                    <option></option>
                    <option
                      value="ativa"
                      selected={
                        this.state.status !== null &&
                        this.state.status === "ativa"
                      }
                    >
                      Ativa
                    </option>
                    <option
                      value="inativa"
                      selected={
                        this.state.status !== null &&
                        this.state.status === "inativa"
                      }
                    >
                      Inativa
                    </option>
                  </Form.Control>
                </Col>

                <Col sm={12}>
                  <Form.Label>
                    Motivo {this.state.id !== null && " da Alteração"}
                  </Form.Label>
                  <FormControl
                    placeholder="Motivo"
                    aria-describedby="basic-addon1"
                    as="textarea"
                    name="motivo"
                    onChange={this.alteracao}
                    required
                  />
                </Col>

                {this.state.id === null && this.state.dependentes.length !== 0 && (
                  <>
                    <Container style={{ marginTop: 20 }}>
                      <strong style={{ fontSize: 20 }}>
                        {"Ligados a essa pessoa"}
                      </strong>
                      <Tooltip
                        title={
                          <strong style={{ fontSize: 14 }}>
                            Suspender também as pessoas selecionadas.
                          </strong>
                        }
                        arrow
                        placement="right"
                      >
                        <Icon
                          name="help"
                          circular
                          size="small"
                          color="blue"
                          bordered
                          style={{ marginBottom: 5, marginLeft: 5 }}
                        ></Icon>
                      </Tooltip>
                      {this.state.dependentes.map((e, i) => (
                        <Row style={{ margin: 0, marginBottom: 5 }}>
                          <Checkbox
                            label={e.nome}
                            onChange={() =>
                              (this.state.dependentes[i].checked = !this.state
                                .dependentes[i].checked)
                            }
                          />
                        </Row>
                      ))}
                    </Container>
                  </>
                )}
              </Row>
            </Col>
          </Row>
          <Segment clearing>
            <Header floated="right">
              <Link to={{ pathname: "/administrativo" }}>
                <Button type="button">Voltar</Button>
              </Link>
              {this.state.id === null ? (
                <Button negative type="submit">
                  Suspender
                </Button>
              ) : (
                <Button primary type="submit">
                  Alterar
                </Button>
              )}
            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}
