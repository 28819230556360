import React, { Component } from "react";
import { Form, Select, Input, Button, Message, Table } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";

import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";
import Meses from "../../../../enum/Mes";

import moment from "moment";

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataInicial: moment().startOf("month").format("YYYY-MM-DD"),
      dataFinal: moment().format("YYYY-MM-DD"),
      imoveisOptions: [],
      selectedImovel: 0,
      erro: false,
      dadosBusca: [],
      total: 0,
      totais: {},
    };
    this.listaImoveis();
  }
  listaImoveis = async () => {
    const imoveis = await api
      .post(
        "/imovel/lista" + requisicoes.entidade,
        {
          simplificada: true,
        },
        requisicoes.header
      )
      .catch((e) => {
        this.setState({ erro: true });
        setTimeout(() => {
          this.setState({ erro: false });
        }, 5000);
      });
    if (imoveis === undefined) {
      return;
    }

    let options = [];
    options.push({ key: 0, value: 0, text: "Todos" });
    for (let i = 0; i < imoveis.data.length; i++) {
      const element = imoveis.data[i];
      options.push({
        key: element.id,
        value: element.id,
        text: element.descricao,
      });
    }
    this.setState({ imoveisOptions: options });
  };
  buscar = async () => {
    // Busca os dados do imóvel no intervalo
    const imoveis = await api
      .post(
        "/imovel/buscar" + requisicoes.entidade,
        {
          imovel_id: this.state.selectedImovel,
          dataInicial: this.state.dataInicial,
          dataFinal: this.state.dataFinal,
        },
        requisicoes.header
      )
      .catch((e) => {
        this.setState({ erro: true });
        setTimeout(() => {
          this.setState({ erro: false });
        }, 5000);
      });
    if (imoveis === undefined) {
      return;
    }
    let total = 0;

    let totais = {
      a_receber: 0,
      recebido: 0,
      em_aberto: 0,
    };
    // Coloca todos os dados em uma única lista para mostrar na tela
    let listaDados = [];
    for (let i = 0; i < imoveis.data.length; i++) {
      const element = imoveis.data[i];
      for (let j = 0; j < element.lancamentos_servico.length; j++) {
        const element2 = element.lancamentos_servico[j];
        listaDados.push({
          imovel_id: element.id,
          imovel_descricao: element.descricao,
          valor: element2.valor,
          data_vencimento: element2.lancamento_conta.data_vencimento,
          data_pagamento: element2.lancamento_conta.data_pagamento,
          servico: element2.servico.nome,
          lancamento_conta_id: element2.lancamento_conta.id,
        });
        totais.a_receber += parseFloat(element2.valor);
        if (element2.lancamento_conta.data_pagamento === null) {
          totais.em_aberto += parseFloat(element2.valor);
        } else {
          totais.recebido += parseFloat(element2.valor);
        }
      }
    }

    // Ordena os dados pela data de vencimento
    listaDados.sort((a, b) => {
      return moment(a.data_vencimento).isAfter(b.data_vencimento) ? 1 : -1;
    });

    // Agrupa os dados pelo mês e ano de vencimento
    // para renderizar uma tebela por lista
    var agrupaMes = [];
    for (let i = 0, aux = 0; i < listaDados.length; i++) {
      const element = listaDados[i];
      if (i === 0) {
        agrupaMes[aux] = [];
        total += parseFloat(element.valor);
        agrupaMes[aux].totalMes = parseFloat(element.valor);
        agrupaMes[aux].push(element);
        continue;
      }
      if (
        moment(element.data_vencimento).get("year") ===
          moment(agrupaMes[aux][0].data_vencimento).get("year") &&
        moment(element.data_vencimento).get("month") ===
          moment(agrupaMes[aux][0].data_vencimento).get("month")
      ) {
        agrupaMes[aux].totalMes += parseFloat(element.valor);
        total += parseFloat(element.valor);
        agrupaMes[aux].push(element);
      } else {
        aux++;
        agrupaMes[aux] = [];
        agrupaMes[aux].totalMes = parseFloat(element.valor);
        total += parseFloat(element.valor);
        agrupaMes[aux].push(element);
      }
    }
    this.setState({ dadosBusca: agrupaMes, totais });
  };

  imprimirOnClick = async () => {
    const imoveis = await api.post(
      "/imovel/relatorioImoveis" + requisicoes.entidade,
      {
        imovel_id: this.state.selectedImovel,
        dataInicial: this.state.dataInicial,
        dataFinal: this.state.dataFinal,
      },
      requisicoes.header
    );

    const r = await api.get(
      "/imovel/arquivoRelatorioImoveis" + requisicoes.entidade,
      requisicoes.header
    );

    var oReq = new XMLHttpRequest();
    var URLToPDF =
      r.config.baseURL +
      "imovel/arquivoRelatorioImoveis" +
      requisicoes.entidade +
      "&t=" +
      requisicoes.header.headers.token; //
    await oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = await function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };
  render() {
    return (
      <div>
        <Form error={this.state.erro} onSubmit={this.buscar}>
          <Form.Group>
            <Form.Select
              label="Imóvel"
              options={this.state.imoveisOptions}
              value={this.state.selectedImovel}
              onChange={(e, a) => this.setState({ selectedImovel: a.value })}
            />
            <Form.Input
              label="Data inicial"
              type="date"
              value={this.state.dataInicial}
              onKeyUp={(e) => {
                this.setState({ dataInicial: e.target.value });
              }}
              onChange={(e) => {
                this.setState({ dataInicial: e.target.value });
              }}
            />
            <Form.Input
              label="Data final"
              type="date"
              value={this.state.dataFinal}
              onKeyUp={(e) => {
                this.setState({ dataFinal: e.target.value });
              }}
              onChange={(e) => {
                this.setState({ dataFinal: e.target.value });
              }}
            />
            <Form.Field style={{ display: "flex", alignItems: "flex-end" }}>
              <Button color="green" type="submit">
                Buscar
              </Button>
            </Form.Field>
            <Form.Field style={{ display: "flex", alignItems: "flex-end" }}>
              <Button primary type="button" onClick={this.imprimirOnClick}>
                Imprimir
              </Button>
            </Form.Field>
          </Form.Group>
          <Row>
            <Col>
              <Message error>
                <Message.Header>Erro ao buscar</Message.Header>
                Ocorreu um erro ao buscar os registros.
              </Message>
            </Col>
          </Row>
        </Form>
        {this.state.dadosBusca.length > 0 && (
          <div style={{ textAlign: "right" }}>
            <h3 style={{ marginBottom: 7 }}>{`Total A Receber: ${parseFloat(
              this.state.totais.a_receber
            ).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}</h3>
            <h3
              style={{ marginBottom: 7, marginTop: 7 }}
            >{`Total Recebido: ${parseFloat(
              this.state.totais.recebido
            ).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}</h3>
            <h3
              style={{ marginBottom: 7, marginTop: 7 }}
            >{`Total Em Aberto: ${parseFloat(
              this.state.totais.em_aberto
            ).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}</h3>
          </div>
        )}
        {this.state.dadosBusca.map((e, i) => (
          <div style={{ paddingBottom: 40 }} key={i}>
            <h4 style={{ marginBottom: 0 }}>
              {Meses[moment(e[0].data_vencimento).get("month")].label}
            </h4>
            <Table celled sortable style={{ marginTop: 7 }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Imóvel</Table.HeaderCell>
                  <Table.HeaderCell>Lançamento</Table.HeaderCell>
                  <Table.HeaderCell>Valor</Table.HeaderCell>
                  <Table.HeaderCell>Data de vencimento</Table.HeaderCell>
                  <Table.HeaderCell>Pagamento</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {e.map((e2, i2) => (
                  <Table.Row key={e2.lancamento_conta_id}>
                    <Table.Cell>{e2.imovel_descricao}</Table.Cell>
                    <Table.Cell>{e2.servico}</Table.Cell>
                    <Table.Cell>{e2.valor}</Table.Cell>
                    <Table.Cell>
                      {moment(e2.data_vencimento).format("DD/MM/YYYY")}
                    </Table.Cell>
                    <Table.Cell>
                      {e2.data_pagamento === null
                        ? "Não pago"
                        : moment(e2.data_pagamento).format("DD/MM/YYYY")}
                    </Table.Cell>
                  </Table.Row>
                ))}
                <Table.Row>
                  <Table.Cell
                    colSpan="10"
                    style={{ fontWeight: "bold" }}
                  >{`Total a ser pago no mês: ${parseFloat(
                    e.totalMes
                  ).toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}`}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        ))}
      </div>
    );
  }
}
