import React, { Component } from "react";
import {
  Container,
  Table,
  Button,
  Menu,
  Icon,
  Pagination,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import api from "../../../../../services/api";
import requisicoes from "../../../../../utils/Requisicoes";
import moment from "moment-timezone";
import { NavLink } from "react-bootstrap";
import ModalVisualizaTransferencia from "./modalVisualizaTransferencia";

export default class TransferenciaLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaHistorico: [],
      pagina: 1,
      totalPaginas: 1,
      porTipoUsuario: false,
      tipoUsuario: null,
      modal_view_transferencia: false,
      acao_transferida: null,
      pessoa_compra: null,
      pessoa_venda: null,
    };
    this.listaHistorico();
  }

  listaHistorico = async (event, data) => {
    if (data == undefined || data == null) {
      data = {};
      data.activePage = 1;
    }
    const resposta = await api.post(
      "/historicoAcao/listaTransferencia" + requisicoes.entidade,
      {
        page: data.activePage,
      },
      requisicoes.header
    );
    this.setState({ listaHistorico: resposta.data });
  };

  pesquisaUsuario = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/usuario/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaUsuario").value },
        requisicoes.header
      );
      this.setState({ listaUsuario: retorno.data });
    }, 500);
  };

  pesquisaPorTipoUsuario = async (e, page = 1) => {
    this.setState({ tipoUsuario: e.value });

    setTimeout(async () => {
      this.setState({ porTipoUsuario: true });
      const retorno = await api.post(
        "/usuario/buscaPorTipoUsuario" +
          requisicoes.entidade +
          "&pagina=" +
          page,
        {
          tipo_usuario: this.state.tipoUsuario,
          entidade: window.localStorage.getItem("e_clube"),
        },
        requisicoes.header
      );
      const { usuarios, pagina, totalPaginas } = retorno.data;
      this.setState({ listaUsuario: usuarios, pagina, totalPaginas });
    }, 500);
  };

  proximaPagina = async () => {
    const { pagina, totalPaginas } = this.state;
    if (pagina >= totalPaginas) return;
    const paginaNumero = parseInt(pagina) + 1;
    this.listaUsuario(paginaNumero);
  };

  voltarPagina = async () => {
    const { pagina } = this.state;
    if (pagina <= 1) return;
    const paginaNumero = pagina - 1;
    this.listaUsuario(paginaNumero);
  };

  proximaPaginaTipo = async () => {
    const tipo_usuario = { value: this.state.tipoUsuario };
    const { pagina, totalPaginas } = this.state;
    if (pagina >= totalPaginas) return;
    const paginaNumero = parseInt(pagina) + 1;
    this.pesquisaPorTipoUsuario(tipo_usuario, paginaNumero);
  };

  voltarPaginaTipo = async () => {
    const tipo_usuario = { value: this.state.tipoUsuario };
    const { pagina } = this.state;
    if (pagina <= 1) return;
    const paginaNumero = pagina - 1;
    this.pesquisaPorTipoUsuario(tipo_usuario, paginaNumero);
  };

  handleCloseTransferencia = () => {
    this.setState({ modal_view_transferencia: false })
  };
  
  openModalVisualizaTransferencia = async (pessoa_c, pessoa_v) => {
    await this.pesquisaAcao(pessoa_c);
    this.setState({
      pessoa_compra: pessoa_c,
      pessoa_venda: pessoa_v,
      modal_view_transferencia: true,
    });
  };

  pesquisaAcao = async (pessoa) => {
    try {
      setTimeout(async () => {
        const respAcao = await api.post(
          "/acao/visualiza" + requisicoes.entidade,
          { id: pessoa.acao_id },
          requisicoes.header
        );
        this.setState({ acao_transferida: respAcao.data });
      }, 500);
    } catch(error) {
      console.log('usuarioTransferencia/pesquisaAcao: ' + error);
    }
  };

  render() {
    return (
      <div style={{ paddingBottom: 100 }}>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Data</Table.HeaderCell>
              <Table.HeaderCell>Tipo</Table.HeaderCell>
              <Table.HeaderCell>Pessoa Compra</Table.HeaderCell>
              <Table.HeaderCell>Pessoa Venda</Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <Link to={{ pathname: "/usuarioTransferencia" }}>
                  <Button positive icon="add" size="tiny"></Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.listaHistorico.docs !== undefined &&
              this.state.listaHistorico.docs.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell>
                    {e.data !== null && moment(e.data).format("DD/MM/YYYY")}
                  </Table.Cell>
                  <Table.Cell>{e.tipo_lancamento}</Table.Cell>
                  <Table.Cell>
                    {e.pessoa_compra !== null && e.pessoa_compra.nome}
                  </Table.Cell>
                  <Table.Cell>
                    {e.pessoa_venda !== null && e.pessoa_venda.nome}
                  </Table.Cell>
                  <Table.Cell width={1}>
                    {/* <Link
                      to={{
                        pathname: "/usuarioTransferencia",// + requisicoes.entidade + "&id=" + e.id,
                        query: { id: e.id },
                        params: { id: e.id }
                      }}
                    > */}
                      <Button 
                        primary icon="eye" 
                        size="tiny"
                        onClick={()=>this.openModalVisualizaTransferencia(e.pessoa_compra, e.pessoa_venda)}
                      ></Button>
                    {/* </Link> */}
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan={4}>
                <Pagination
                  boundaryRange={0}
                  ellipsisItem={null}
                  defaultActivePage={1}
                  totalPages={this.state.listaHistorico.pages}
                  onPageChange={this.listaHistorico}
                ></Pagination>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>

          {/* {this.state.porTipoUsuario === false ? (
            <Table.Footer>
              <Table.Row verticalAlign="middle" textAlign="center">
                <Table.HeaderCell colSpan={4}>
                  <Menu pagination>
                    <Menu.Item
                      icon
                      onClick={this.voltarPagina}
                      disabled={parseInt(this.state.pagina) === 1}
                    >
                      <Icon name="chevron left" />
                    </Menu.Item>
                    <Menu.Item
                      icon
                      onClick={this.proximaPagina}
                      disabled={
                        parseInt(this.state.pagina) ===
                        parseInt(this.state.totalPaginas)
                      }
                    >
                      <Icon name="chevron right" />
                    </Menu.Item>
                  </Menu>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          ) : (
              <Table.Footer>
                <Table.Row verticalAlign="middle" textAlign="center">
                  <Table.HeaderCell colSpan={4}>
                    <Menu pagination>
                      <Menu.Item
                        icon
                        onClick={this.voltarPaginaTipo}
                        disabled={parseInt(this.state.pagina) === 1}
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item
                        icon
                        onClick={this.proximaPaginaTipo}
                        disabled={
                          parseInt(this.state.pagina) ===
                          parseInt(this.state.totalPaginas)
                        }
                      >
                        <Icon name="chevron right" />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            )} */}
        </Table>
        {(this.state.pessoa_compra !== null && this.state.pessoa_venda !== null && (
          <ModalVisualizaTransferencia
            acao={this.state.acao_transferida}
            modal_view_transferencia={this.state.modal_view_transferencia}
            handleCloseTransferencia={this.handleCloseTransferencia}
            pessoa_compra={this.state.pessoa_compra}
            pessoa_venda={this.state.pessoa_venda}
          />
        ))}
      </div>
    );
  }
}
