import React, { Component } from "react";
import {
  Table,
  Menu,
  Icon,
  Header,
  Button,
  Modal,
} from "semantic-ui-react";
import Checkbox from "muicss/lib/react/checkbox";
import api from "../../../../services/api";
import { Col, Form, Row, FormControl } from "react-bootstrap";
import Select from "react-select";
import requisicoes from "../../../../utils/Requisicoes";
import renomearChave from "../../../../services/renomearChave";
import moment from "moment-timezone";
import ConverterParaLabelAcao from "../../../../utils/converteParaLabelComAcao";
import formatDate from "../../../../utils/globalFunctions";

export default class MensalidadeCancelar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagina: 1,
      totalPaginas: 1,
      listaMensalidade: [],
      tipo: "todas",
      data_inicio: null,
      data_final: null,
      optionsP: [],
      pessoa_id: null,
      pessoa: null,
      ano: null,
      consulta_pessoa: null,
      total: (0).toFixed(2),
      open: false,
      openConfirm: false,
      socioSelecionado: null,
      tipoConfirm: null,
      checked: false,
      toggleAll: false,
      openCancelarVarios: false,
      qtdCheckCancel: 0,
      qtdCanceladas: 0,
    };
    this.alteracaoSelectPessoa = this.alteracaoSelectPessoa.bind(this);
    this.alteracao = this.alteracao.bind(this);
  }

  toggleCheckbox = (e) => {
    let lista = this.state.listaMensalidade;
    if (!lista[e].checked) {
      lista[e].checked = true;
    } else {
      lista[e].checked = !lista[e].checked;
    }
    this.setState({
      listaMensalidade: lista,
    });
  };

  toggleAllCheckboxes = () => {
    let lista = this.state.listaMensalidade;
    for (let i = 0; i < lista.length; i++) {
      const element = lista[i];
      element.checked = !this.state.toggleAll;
    }
    this.setState({
      toggleAll: !this.state.toggleAll,
      listaMensalidade: lista,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      socioSelecionado: null,
      openCancelarVarios: false,
    });
    this.handleCloseConfirm();
  };

  handleCloseConfirm = () => {
    this.setState({
      openConfirm: false,
      tipoConfirm: null,
    });
  };

  handleOpen = (selecionado) => {
    this.setState({
      open: true,
      socioSelecionado: selecionado,
    });
  };

  handleOpenConfirm = (tipo) => {
    if (!this.state.openConfirm) {
      this.setState({
        openConfirm: true,
        tipoConfirm: tipo,
      });
    }
  };

  cancelaVariosOpen = async () => {
    let mensalidades = this.state.listaMensalidade;
    let listaMensalidade = []
    let listaCancelaMensalidadeVarias = []
    var qtdCancel = 0;
    if (mensalidades) {
      for (var i = 0; i < mensalidades.length; i++) {
        qtdCancel++;
        var element = mensalidades[i];
        if (element.checked) {
          var temMensalPaga = false;
          var anualPaga = false;

          for (let j = 0; j < element.lancamentos.length; j++) {
            const element2 = element.lancamentos[j];
            if (element2.mes_referencia === 'anual' && element2.data_pagamento !== null) {
              anualPaga = true;
            } else if (element2.mes_referencia !== 'anual' && element2.data_pagamento !== null) {
              temMensalPaga = true;
              listaCancelaMensalidadeVarias.push(element2)
            }
          }
          if (anualPaga) {
            listaCancelaMensalidadeVarias.push(element.lancamentosDevidos);
          } else
            if (temMensalPaga && element.lancamentoAnual !== undefined) {
              listaCancelaMensalidadeVarias.push(element.lancamentoAnual);
            }
        }
      }

    }
    listaCancelaMensalidadeVarias = [].concat(...listaCancelaMensalidadeVarias);
    if (qtdCancel === 0) {
      return alert('Não foi selecionado nenhuma pessoa!');
    }
    let listaCancelados = []
    console.log("lista cancelled", listaCancelaMensalidadeVarias)
    for (let i = 0; i < listaCancelaMensalidadeVarias.length; i++) {
      const element = listaCancelaMensalidadeVarias[i];
      if (element.data_pagamento !== null) {

      } else {
        listaCancelados.push(element)
      }

    }
    this.cancelaMensalidadesAnual(listaCancelados)
    // if (!this.state.openCancelarVarios) {
    //   this.setState({
    //     qtdCheckCancel: qtdCancel,
    //     openCancelarVarios: true,
    //     listaCancelados: listaCancelados
    //   });
    // }
  }

  listaMensalidade = async () => {
    const resposta = await api.post(
      "/mensalidade/lista" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    this.setState({ listaMensalidade: resposta.data });
  };

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ listaMensalidade: [] });
  };

  limpaSelectPessoa = async () => {
    this.setState({
      pessoa_id: null,
      pessoa: null
    });
  };

  pesquisaPessoa = async () => {
    setTimeout(async () => {
      let term = document.getElementById("pesquisaPessoa").value;
      if (isFinite(String(term))) {
        await api
          .post(
            "/pessoa/pesquisaPorCodigoAcaoValorExato" + requisicoes.entidade,
            { pesquisa: document.getElementById("pesquisaPessoa").value },
            requisicoes.header
          )
          .then((retorno) => {
            this.setState({ optionsP: ConverterParaLabelAcao(retorno.data) });
          });
      } else {
        await api
          .post(
            "/pessoa/pesquisa" + requisicoes.entidade,
            { pesquisa: document.getElementById("pesquisaPessoa").value },
            requisicoes.header
          )
          .then((resultado) => {
            let lista = [];
            resultado.data.forEach((element) => {
              let objeto = renomearChave(element, "id", "value");
              objeto = renomearChave(objeto, "nome", "label");
              lista.push(objeto);
            });
            this.setState({ optionsP: lista });
          });
      }
    }, 50);
  };

  alteracaoSelectPessoa = async (event) => {
    this.setState({ pessoa_id: event.value });
    this.setState({ pessoa: event });
  };

  buscaTitulos = async () => {
    this.setState({ loading: true });
    let anoAtual = this.state.ano !== null
      ? this.state.ano
      : parseInt(moment().year());
    let retorno = await api.post(
      "/lancamentoConta/pesquisaMensalidadeMensalAnual" +
      requisicoes.entidade,
      {
        pessoa_id: this.state.pessoa ? this.state.pessoa.value : null,
        ano: `${anoAtual}`,
        tipo: this.state.tipo,
      },
      requisicoes.header
    );
    let lista = retorno.data;
    let listaMensalidades = [];
    // eslint-disable-next-line array-callback-return
    Object.keys(lista).map(key => {
      let e = lista[key];
      e.cor = { background: "#efefef" };
      listaMensalidades.push(e);
    });
    this.setState({
      listaMensalidade: listaMensalidades,
      loading: false,
    });
  };

  somaTotal = (lista) => {
    let soma = parseFloat(0);
    lista.forEach((e) => {
      soma += parseFloat(e.valor);
    });
    return soma;
  };

  proximaPagina = async () => {
    const { pagina, totalPaginas } = this.state;
    if (pagina >= totalPaginas) return;
    const paginaNumero = parseInt(pagina) + 1;
    this.listaUsuario(paginaNumero);
  };

  voltarPagina = async () => {
    const { pagina } = this.state;
    if (pagina <= 1) return;
    const paginaNumero = pagina - 1;
    this.listaUsuario(paginaNumero);
  };

  cancelaMensalidadesAnualSelected = async (tipo) => {
    this.setState({
      qtdCanceladas: 0,
    });
    this.cancelaMensalidadesAnual(this.state.listaCancelados);
    alert(this.state.qtdCanceladas === 0
      ? 'Nenhuma mensalidade foi cancelada!'
      : this.state.qtdCanceladas + ' mensalidades canceladas.');
    this.buscaTitulos();
  };

  cancelaMensalidadesAnual = async (lancamentoAnual, fromVarios) => {
    this.state.loading = true
    this.setState({loading: true});
    // if (lancamentoAnual.data_pagamento === null && this.state.socioSelecionado.lancamentosPagos.length > 0) {
    await api.post(
      "/lancamentoConta/cancelarVarios" + requisicoes.entidade,
      {
        lancamentos: lancamentoAnual,
        motivo: 'Cancelado pelo administrador | PAGOU PARCELA MENSAL',
        tela_usada: "administrativo_mensalidade_cancelar",
      },
      requisicoes.header
    );
    this.state.loading = false
    this.setState({loading: false});

    if (!fromVarios) {
      alert('Mensalidades mensal e anual cancelada');
      this.handleClose();
      this.buscaTitulos();
    } else {
      this.state.qtdCanceladas++;
    }
    // } else {
    //   return alert('Não é possível cancelar a parcela anual pois não foi identificado o pagamento da parcela Mensal!');
    // }
  };

  cancelaMensalidadesMensais = async (socioSelecionado, fromVarios) => {
    let lista = []
    lista = socioSelecionado.lancamentosDevidos
    if (lista.length > 10 && (socioSelecionado.lancamentosPagos === undefined
      || socioSelecionado.lancamentosPagos === null
      || socioSelecionado.lancamentosPagos.length <= 0)
      && socioSelecionado.lancamentoAnual !== undefined
    ) {
      await api.post(
        "/lancamentoConta/cancelarVarios" + requisicoes.entidade,
        {
          lancamentos: lista,
          motivo: 'Cancelado pelo administrador | PAGOU PARCELA ANUAL',
          tela_usada: "administrativo_mensalidade_cancelar",
        },
        requisicoes.header
      );
      if (!fromVarios) {
        alert('Mensalidades canceladas');
        this.handleClose();
        this.buscaTitulos();
      } else {
        this.state.qtdCanceladas++;
      }
    } else {
      return alert(`Não é possível cancelar as mensalidades de ${socioSelecionado.pessoa.nome || ''} pois não foi identificado o pagamento da parcela Anual!`);
    }
  };

  render() {
    return (
      <div className="lista">
        <Row>
          {/* <Col sm={2}>
            <Form.Label>Tipo</Form.Label>
            <Form.Control
              placeholder="Tipo"
              label="Tipo"
              id="tipo"
              as="select"
              name="tipo"
              value={this.state.tipo || ''}
              onChange={this.alteracao}
            >

              <option value="mensal">Mensal</option>
              <option value="anual">Anual</option>
            </Form.Control>
          </Col> */}
          <Col sm={4}>
            <Form.Label>Busca</Form.Label>
            <Select
              placeholder="Digite para buscar"
              name="pessoa"
              onChange={this.alteracaoSelectPessoa}
              options={this.state.optionsP}
              onKeyDown={this.pesquisaPessoa}
              inputId="pesquisaPessoa"
            />
          </Col>

          <Col
            sm={2}
            style={{ paddingTop: "26px" }}
          >
            <FormControl
              placeholder="Ano"
              aria-label="Ano"
              aria-describedby="basic-addon1"
              name="ano"
              value={this.state.ano || ''}
              onChange={this.alteracao}
            />
          </Col>
          <Col sm={2}>
            <Button
              positive
              style={{ marginTop: "25px" }}
              onClick={this.buscaTitulos}
              disabled={this.state.loading}
              loading={this.state.loading}
            >
              Buscar
            </Button>
          </Col>
          <Col sm={2}>
            <Button
              negative
              style={{ marginTop: "25px" }}
              onClick={() => this.cancelaVariosOpen()}
              disabled={this.state.loading}
              loading={this.state.loading}
            >
              Cancelar Mensal/Anual
            </Button>
          </Col>
        </Row>

        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Serviço</Table.HeaderCell>
              <Table.HeaderCell>Mensalidades Pagas</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign="center">
                <Checkbox
                  label=""
                  onClick={() => this.toggleAllCheckboxes()}
                  checked={this.state.toggleAll}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.listaMensalidade.map((e, i) => (
              <Table.Row key={i} style={e.cor}>
                <Table.Cell>{e.pessoa?.nome}</Table.Cell>
                <Table.Cell>
                  {e.servico !== null &&
                    e.servico !== undefined &&
                    e.servico.nome}
                </Table.Cell>
                <Table.Cell>
                  {e.lancamentosPagos && e.lancamentosPagos.length > 0
                    ? e.lancamentosPagos.length + '/12' :
                    e.lancamentoAnual !== undefined && e.lancamentoAnual && e.lancamentoAnual.data_pagamento
                      ? 'Anual' : 'Nenhuma paga'}
                </Table.Cell>
                <Table.Cell width={1} textAlign="center">
                  <Button
                    color='blue'
                    icon="eye"
                    onClick={() => this.handleOpen(e)}
                  ></Button>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Checkbox
                    label=""
                    onClick={() => this.toggleCheckbox(i)}
                    checked={e.checked}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell>
                <b>Total:</b>
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {this.state.total}
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan={6}>
                <Menu pagination>
                  <Menu.Item
                    icon
                    onClick={this.voltarPagina}
                    disabled={this.state.pagina === 1}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  <Menu.Item
                    icon
                    onClick={this.proximaPagina}
                    disabled={this.state.pagina === this.state.totalPaginas}
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>

        {this.state.socioSelecionado !== null && (
          <Modal
            open={this.state.open}
            onClose={this.handleClose}
            centered={false}
          >
            <Header icon="newspaper" content={"Mensalidades de " + this.state.socioSelecionado.pessoa?.nome} />
            <Modal.Content>
              <Row>
                <Col sm={6}>
                  <Table celled>
                    <Table.Header>
                      {((this.state.socioSelecionado.lancamentosPagos && this.state.socioSelecionado.lancamentosPagos.length > 0)
                        || (this.state.socioSelecionado.lancamentosDevidos && this.state.socioSelecionado.lancamentosDevidos.length > 0)) ?
                        (<Table.Row>
                          <Table.HeaderCell>Serviço</Table.HeaderCell>
                          <Table.HeaderCell>{'Vencimento'}</Table.HeaderCell>
                          <Table.HeaderCell>{'Pagamento'}</Table.HeaderCell>
                        </Table.Row>) : (
                          <Table.Row>
                            <Table.HeaderCell>Mensalidade Mensal não gerada</Table.HeaderCell>
                          </Table.Row>)}
                    </Table.Header>
                    <Table.Body>

                      {this.state.socioSelecionado.lancamentosDevidos.length > 0
                        && this.state.socioSelecionado.lancamentosDevidos.map((l, i) => (
                          <Table.Row key={i} style={{
                            color: l.data_cancelamento !== null
                              ? 'orange' : 'red'
                          }}>
                            <Table.Cell>
                              {l.servico.nome}
                            </Table.Cell>
                            <Table.Cell>
                              {formatDate(l.data_vencimento)}
                            </Table.Cell>
                            <Table.Cell>
                              {l.data_cancelamento !== null
                                ? 'Cancelada' : l.data_pagamento === null
                                  ? 'Aberto' : formatDate(l.data_pagamento)}
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      {this.state.socioSelecionado.lancamentosPagos.length > 0
                        && this.state.socioSelecionado.lancamentosPagos.map((l, i) => (
                          <Table.Row key={i} style={{
                            color: l.data_cancelamento !== null
                              ? 'orange' : l.data_pagamento === null
                                ? 'red' : 'green'
                          }}>
                            <Table.Cell>
                              {l.servico.nome}
                            </Table.Cell>
                            <Table.Cell>
                              {formatDate(l.data_cancelamento || l.data_vencimento)}
                            </Table.Cell>
                            <Table.Cell>
                              {l.data_cancelamento !== null
                                ? 'Cancelada' : l.data_pagamento === null
                                  ? 'Aberto' : formatDate(l.data_pagamento)}
                            </Table.Cell>
                          </Table.Row>
                        ))}
                    </Table.Body>
                    {((this.state.socioSelecionado.lancamentosPagos && this.state.socioSelecionado.lancamentosPagos.length === 0)
                      && (this.state.socioSelecionado.lancamentosDevidos && this.state.socioSelecionado.lancamentosDevidos.length > 0))
                      && this.state.socioSelecionado.lancamentoAnual !== undefined
                      && this.state.socioSelecionado.lancamentoAnual.data_cancelamento === null &&
                      (<Table.Footer>
                        <Table.Row verticalAlign="middle" textAlign="center">
                          <Table.HeaderCell colSpan={6}>
                            {/* <Button
                              negative
                              onClick={() => this.handleOpenConfirm('mensal')}
                            > Cancelar Mensais
                            </Button> */}
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>)}
                  </Table>
                </Col>
                <Col sm={6}>
                  <Table celled>
                    <Table.Header>
                      {this.state.socioSelecionado.lancamentoAnual
                        && this.state.socioSelecionado.lancamentoAnual !== undefined ? (
                        <Table.Row>
                          <Table.HeaderCell>Serviço</Table.HeaderCell>
                          <Table.HeaderCell>
                            {this.state.socioSelecionado.lancamentoAnual.data_cancelamento !== null ? 'Data' : 'Vencimento'}
                          </Table.HeaderCell>
                          <Table.HeaderCell>

                            {this.state.socioSelecionado.lancamentoAnual.data_cancelamento !== null ? 'Status' : 'Pagamento'}
                          </Table.HeaderCell>
                        </Table.Row>) : (
                        <Table.Row>

                          <Table.HeaderCell>Mensalidade Anual não gerada</Table.HeaderCell>
                        </Table.Row>)}
                    </Table.Header>
                    {this.state.socioSelecionado.lancamentoAnual && this.state.socioSelecionado.lancamentoAnual !== undefined && (
                      <Table.Body>
                        <Table.Row key={1} style={{
                          color: this.state.socioSelecionado.lancamentoAnual.data_cancelamento !== null
                            ? 'orange' : this.state.socioSelecionado.lancamentoAnual.data_pagamento === null
                              ? 'red' : 'green'
                        }}>
                          <Table.Cell>
                            {this.state.socioSelecionado.servico.nome}
                          </Table.Cell>
                          <Table.Cell>
                            {formatDate(this.state.socioSelecionado.lancamentoAnual.data_cancelamento || this.state.socioSelecionado.lancamentoAnual.data_vencimento)}
                          </Table.Cell>
                          <Table.Cell>
                            {this.state.socioSelecionado.lancamentoAnual.data_cancelamento !== null
                              ? 'Cancelada' : this.state.socioSelecionado.lancamentoAnual.data_pagamento === null
                                ? 'Aberto' : formatDate(this.state.socioSelecionado.lancamentoAnual.data_pagamento)}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    )}
                    {this.state.socioSelecionado.lancamentoAnual && this.state.socioSelecionado.lancamentoAnual !== undefined
                      && this.state.socioSelecionado.lancamentoAnual.data_cancelamento === null && this.state.socioSelecionado.lancamentoAnual.data_pagamento === null && (
                        <Table.Footer>
                          <Table.Row verticalAlign="middle" textAlign="center">
                            <Table.HeaderCell colSpan={6}>
                              {/* <Button
                                negative
                                onClick={() => this.handleOpenConfirm('anual')}
                              > Cancelar Anual
                              </Button> */}
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Footer>)}
                  </Table>
                </Col>
              </Row>

              <Row sm={12} style={{ paddingTop: 16 }}>
                <Col sm={10}></Col>
                <Col sm={2}>
                  <Button
                    onClick={this.handleClose}
                  >Voltar</Button>
                </Col>
              </Row>
            </Modal.Content>
          </Modal>
        )}

        {this.state.socioSelecionado !== null && this.state.openConfirm && (
          <Modal
            open={this.state.openConfirm}
            onClose={this.handleClose}
            centered={false}
          >
            <Header icon="newspaper" content={"Cancelar mensalidades de " + this.state.socioSelecionado.pessoa.nome} />
            <Modal.Content>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      Você tem certeza de que deseja cancelar
                      {this.state.tipoConfirm === 'mensal' ? ' as parcelas mensais ' : ' a parcela anual '}
                      do sócio <b>{this.state.socioSelecionado.pessoa.nome}</b>?
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
              </Table>
              <Row sm={12} style={{ paddingTop: 16 }}>
                <Col sm={8}></Col>
                <Col sm={2}>
                  <Button
                    onClick={() => {
                      if (this.state.tipoConfirm === 'anual') {
                        this.cancelaMensalidadesAnual(this.state.socioSelecionado.lancamentoAnual);
                      } else if (this.state.tipoConfirm === 'mensal') {
                        this.cancelaMensalidadesMensais(this.state.socioSelecionado);
                      }
                    }}
                  >Sim</Button>
                </Col>
                <Col sm={2}>
                  <Button
                    onClick={this.handleCloseConfirm}
                  >Não</Button>
                </Col>
              </Row>
            </Modal.Content>
          </Modal>
        )}

        {this.state.openCancelarVarios && (
          <Modal
            open={this.state.openCancelarVarios}
            onClose={this.handleClose}
            centered={false}
          >
            <Header icon="newspaper" content={"Cancelar várias Mensalidades"} />
            <Modal.Content>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      {`Foram selecionadas ${this.state.qtdCheckCancel} pessoas, escolha quais mensalidades você vai cancelar:
                          ANUAL e MENSAL
                          Se não for possível cancelar alguma mensalidade MENSAL ou ANUAL de alguma pessoa, ela continuará em aberto!`
                      }
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
              </Table>
              <Row sm={12} style={{ paddingTop: 16, marginLeft: 15 }}>
                <Col sm={8}></Col>
                <Col sm={1} style={{ marginRight: 20 }}
                >
                  <Button
                    primary
                    onClick={() => this.cancelaMensalidadesAnualSelected('mensal')}
                  >
                    Sim
                  </Button>
                </Col>
                <Col sm={1} style={{ marginRight: 12 }}
                >
                  <Button
                    secondary
                    onClick={() => this.handleClose()}
                  >
                    Não
                  </Button>
                </Col>
                <Col sm={1} //style={{ marginRight: 16}}
                >
                  <Button
                    negative
                    onClick={() => this.handleClose()}
                  >
                    Fechar
                  </Button>
                </Col>
              </Row>
            </Modal.Content>
          </Modal>
        )}
      </div>
    );
  }
}
