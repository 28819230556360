import React, { Component } from "react";

import Login from "./pages/login";
import PessoaLista from "./pages/cadastro/pessoa/lista";
import Pessoa from "./pages/cadastro/pessoa/index";
import Usuario from "./pages/cadastro/administrativo/usuario/index";
import Servico from "./pages/cadastro/servico/index";
import Reserva from "./pages/lancamento/reserva/index";
import Caixa from "./pages/financeiro/caixa";
import Mensalidade from "./pages/cadastro/administrativo/mensalidade";
import MensalidadeLista from "./pages/cadastro/administrativo/mensalidade/lista";
import LancamentoConta from "./pages/financeiro/lancamento/index";
import GrupoServico from "./pages/cadastro/grupoServico";
import Remessa from "./pages/financeiro/remessa";
import Sair from "./pages/sair";
import TipoTituloLista from "./pages/cadastro/tipoTitulo/lista";
import TipoTitulo from "./pages/cadastro/tipoTitulo";
import Menu from "./components/menu";
import Home from "./pages/home/index";
// import Home from "./pages/home";
import LancamentoCaixa from "./pages/financeiro/lancamentoCaixa";
import MeusDados from "./pages/cadastro/meusDados";
import CaixaLista from "./pages/financeiro/caixa/lista";
import ItemLista from "./pages/cadastro/item/lista";
import Item from "./pages/cadastro/item";
import Retorno from "./pages/financeiro/retorno";
import CentroDeCusto from "./pages/cadastro/centroDeCusto/index";
import ContaContabilLista from "./pages/cadastro/contaContabil/lista";
import ContaContabil from "./pages/cadastro/contaContabil/index";
import ElementoDeCustoLista from "./pages/cadastro/elementoDeCusto/lista";
import ElementoDeCusto from "./pages/cadastro/elementoDeCusto/index";
import Contabil from "./pages/cadastro/contabil/index";
import Almoxarifado from "./pages/cadastro/almoxarifado/index";
import ContaBancaria from "./pages/financeiro/contaBancaria/index";
import Financeiro from "./pages/financeiro/index";
import Agencia from "./pages/financeiro/agencia/index";
import ContasAPagar from "./pages/financeiro/contasAPagar";
import EntradaItem from "./pages/cadastro/almoxarifado/entradaItem/lista/index";
import Servicos from "./pages/cadastro/servicos/index";
import ServicosDiversos from "./pages/cadastro/servicosDiversos/index";
import Requisicao from "./pages/cadastro/almoxarifado/requisicao/lista/index";
import RequisicaoLista from "./pages/cadastro/almoxarifado/requisicao/lista/lista";
import Reservas from "./pages/lancamento/reservas/index";
import ExameMedicoLista from "./pages/lancamento/exames/exameMedico/lista";
import ExameMedico from "./pages/lancamento/exames/exameMedico/index";
import ExameFisico from "./pages/lancamento/exames/exameFisico/index";
import ExameFisicoLista from "./pages/lancamento/exames/exameFisico/lista";
import Exames from "./pages/lancamento/exames/index";
import caixaRelatorio from "./pages/financeiro/caixaRelatorio/lista";
import caixaRelatorioMostrar from "./pages/financeiro/caixaRelatorio/mostrar";
import Estrutura from "./pages/cadastro/almoxarifado/estrutura/index";
import EstruturaLista from "./pages/cadastro/almoxarifado/estrutura/lista";
import Administrativo from "./pages/cadastro/administrativo";
import UsuarioTitular from "./pages/cadastro/administrativo/usuarioTitular";
import servicoTurma from "./pages/cadastro/servicosTurmas/index";
import matriculaTurma from "./pages/lancamento/matricula/index";
import LancamentoBancario from "./pages/financeiro/lancamentoBancario/index";
import LancamentoBancarioLista from "./pages/financeiro/lancamentoBancario/lista";
import EntradaItemLista from "./pages/cadastro/almoxarifado/entradaItem/lista/lista";
import UsuarioTransferencia from "./pages/cadastro/administrativo/transferencia/titularidade/index";
import AlteracaoTipoTitulo from "./pages/cadastro/administrativo/transferencia/tipoTitulo/index";
import LoginMampituba from "./pages/srMampituba";
import PontoEletronico from "./pages/pontoEletronico";
import Relatorio from "./pages/relatorios/index";
import Suspensao from "./pages/cadastro/administrativo/suspensao";
import EsqueceuSenha from "./pages/esqueceuSenha";
import EstornoLista from "./pages/financeiro/estorno/lista";
import ClubeVantagem from "./pages/cadastro/administrativo/clubeVantagem";
import Portaria from "./pages/cadastro/portaria/index";
import Entidade from "./pages/cadastro/entidade/index";
import EntidadeLista from "./pages/cadastro/entidade/lista";
import Parametros from "./pages/cadastro/parametros/index";
import ArmaIndex from "./pages/cadastro/administrativo/arma";
import ArmaLista from "./pages/cadastro/administrativo/arma/lista";
import MunicaoIndex from "./pages/cadastro/administrativo/municao";
import MunicaoLista from "./pages/cadastro/administrativo/municao/lista";
import Evento from "./pages/evento/index";
import EventoCadastro from "./pages/evento/cadastro/index";
import EventoVisualiza from "./pages/evento/visualiza/index";
import EventoAjustar from "./pages/evento/ajustar/index";
import EventoModalidade from "./pages/evento/modalidade/index";
import EventoSubgrupo from "./pages/evento/subgrupo/index";
import LancamentoEtapa from "./pages/evento/inscricaoatiradores/index";
import LancamentoPontuacao from "./pages/evento/lancamentopontuacao/index";
import ListaAtiradores from "./pages/evento/listaatiradores/index";
import Auditoria from "./pages/auditorias/index";
import Feriado from "./pages/cadastro/administrativo/feriado/lista";
import CatracaLista from "./pages/cadastro/catraca/lista";
import Catraca from "./pages/cadastro/catraca/index";
import EventoClube from "./pages/cadastro/eventoClube";
import EventoClubeLista from "./pages/cadastro/eventoClube/lista";
import Marketing from "./pages/cadastro/marketing";
import Cadastro from "./pages/cadastro/index";
import FuncionarioMenu from "./pages/cadastro/funcionario/menu";
import ImovelLista from "./pages/cadastro/imovel/lista/lista";
import Imovel from "./pages/cadastro/imovel/lista/index";
import Academia from "./pages/academia";
import Exercicio from "./pages/academia/exercicio";
import Treino from "./pages/academia/treino";
import AvaliacaoFisica from "./pages/academia/avaliacaoFisica";
import Desporto from "./pages/desportos/index";
import AjustarCampeonato from "./pages/desportos/ajustarcampeonato/index";
import RodadaCampeonato from "./pages/desportos/rodadascampeonato/index";
import PartidaCampeonato from "./pages/desportos/partida/index";
import Politica from "./pages/politica/index";
import InstituicaoCobranca from "./pages/financeiro/instituicaoCobranca/index";
import InstituicaoCobrancaLista from "./pages/financeiro/instituicaoCobranca/lista";
import Suporte from "./pages/suporte/index";

import ProtectedRoute from "./services/protectedRoute";
import api from "./services/api";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import jwt from "jwt-decode";

export default class Rotas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: [],
      submenus: [],
      permissoes: [],
      entidades: [],
      entidade: null,
      adm: null,
    };

    this.recebeListaMenu();
  }

  recebeListaMenu = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var tipo_usuario = localStorage.getItem("tipo_usuario_clube");
    var entidade = localStorage.getItem("e_clube");

    try {
      const resposta = await api.post("/menu/lista?e=" + entidade, "", {
        headers: { token: "Baer " + token, tipo_usuario: tipo_usuario },
      });
      this.setState({
        menus: resposta.data.menus,
        submenus: resposta.data.submenus,
        permissoes: resposta.data.permissoes,
      });
    } catch (e) {
      localStorage.setItem("token_clube", undefined);
    }
  };

  buscaSubmenu = (rota) => {
    let submenus = this.state.submenus;
    if (!submenus) {
      return [];
    }
    let index = submenus.findIndex((e) => e.rota === rota);
    if (index !== -1) {
      return submenus[index].lista;
    } else {
      return [];
    }
  };

  render() {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    if (token === null || token.length < 10) {
      console.log("problema com o token");
      if (
        window.location.pathname !== "/" &&
        window.location.pathname !== "/login/srmampituba"
      ) {
        window.location.pathname = "/";
      }
    } else {
      if (window.location.pathname === "/login/srmampituba") {
        window.location.pathname = "/";
      }

      let decode = jwt(token);
      localStorage.setItem("tipo_usuario_clube", decode.tipo_usuario);
      localStorage.setItem("aceitou_termos", decode.aceitou_termos);
      if (decode.aceitou_termos === undefined) {
        localStorage.setItem("token_clube", null);
        token = null;
        window.location.pathname = "/";
      } else if (
        !decode.aceitou_termos &&
        window.location.pathname !== "/politica"
      ) {
        window.location.pathname = "/politica";
      }
    }

    let classname = "principal";

    if (
      ((window.location.pathname === "/" ||
        window.location.pathname === "/login/srmampituba") &&
        (token === null || token.length < 10)) ||
      window.location.pathname === "/esqueceuSenha"
    ) {
      classname = "";
    }

    return (
      <>
        <BrowserRouter>
          {token !== null &&
            token.length > 10 &&
            this.state.menus.length !== 0 ? (
            <Menu menus={this.state.menus} />
          ) : // (
            //   <Route
            //     exact
            //     path="/"
            //     component={Menu}
            //     menus={this.state.menus}
            //     a={"a"}
            //   />
            // )

            null}
          {/* {token !== null &&
          token.length > 10 &&
          window.location.pathname == "/" && <Home />} */}

          {/* <div
          className={
            window.location.pathname == "/" ||
            window.location.pathname == "/esqueceuSenha" ||
            window.location.pathname == "/login/srmampituba" ||
            window.location.pathname == "/pontoeletronico"
              ? ""
              : "principal"
          }
        > */}

          <div className={classname}>
            <Switch>
              {token === null || token.length < 10 ? (
                <Route exact path="/" component={Login} />
              ) : (
                <ProtectedRoute
                  path="/"
                  component={Home}
                  exact
                  menus={this.state.menus}
                  permissoes={this.state.permissoes}
                />
              )}

              <Route
                exact
                path="/"
                component={token === null || token.length < 10 ? Login : Home}
                render={() => {
                  token === null || token.length < 10 ? (
                    <Login />
                  ) : (
                    <Home
                      menus={this.state.menus}
                      permissoes={this.state.permissoes}
                    />
                  );
                }}
              />

              {/* <ProtectedRoute
                path="/"
                component={token === null || token.length < 10 ? Login : Home}
                menus={this.state.menus}
                permissoes={this.state.permissoes}
              /> */}

              <Route exact path="/esqueceuSenha" component={EsqueceuSenha} />

              <Route
                exact
                path="/login/srmampituba"
                component={
                  token == null || token.length < 10 ? LoginMampituba : Home
                }
              />

              <Route
                exact
                path="/pontoeletronico"
                component={
                  token == null || token.length < 10 ? PontoEletronico : null
                }
              />

              <ProtectedRoute
                path="/politica"
                component={Politica}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/pessoa/lista"
                component={PessoaLista}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/pessoa"
                component={Pessoa}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/usuario"
                component={Usuario}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/servico"
                component={Servico}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/reserva"
                component={Reserva}
                menus={this.state.menus}
                submenus={this.buscaSubmenu("/reserva")}
                permissoes={this.state.permissoes}
              />
              <ProtectedRoute
                path="/mensalidade/lista"
                component={MensalidadeLista}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/mensalidade"
                component={Mensalidade}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/lancamentoConta"
                component={LancamentoConta}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/grupoServico"
                component={GrupoServico}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/tipoTitulo/lista"
                component={TipoTituloLista}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/tipoTitulo"
                component={TipoTitulo}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/sair"
                component={Sair}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/remessa"
                component={Remessa}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/exameMedico/lista"
                component={ExameMedicoLista}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/exameMedico"
                component={ExameMedico}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/caixa/lista"
                component={CaixaLista}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/caixa"
                component={Caixa}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/lancamentoCaixa"
                component={LancamentoCaixa}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/meusDados"
                component={MeusDados}
                menus={this.state.menus}
                permissoes={this.state.permissoes}
              />
              <ProtectedRoute
                path="/retorno"
                component={Retorno}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/centroDeCusto"
                component={CentroDeCusto}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/item/lista"
                component={ItemLista}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/item"
                component={Item}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/contaContabil/lista"
                component={ContaContabilLista}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/contaContabil"
                component={ContaContabil}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/elementoDeCusto/lista"
                component={ElementoDeCustoLista}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/elementoDeCusto"
                component={ElementoDeCusto}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/contabil"
                component={Contabil}
                menus={this.state.menus}
                submenus={this.buscaSubmenu("/contabil")}
                permissoes={this.state.permissoes}
              />
              <ProtectedRoute
                path="/almoxarifado"
                component={Almoxarifado}
                menus={this.state.menus}
                submenus={this.buscaSubmenu("/almoxarifado")}
              />
              <ProtectedRoute
                path="/contaBancaria"
                component={ContaBancaria}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/financeiro"
                component={Financeiro}
                menus={this.state.menus}
                submenus={this.buscaSubmenu("/financeiro")}
              />
              <ProtectedRoute
                path="/agencia"
                component={Agencia}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/contasAPagar"
                component={ContasAPagar}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/entradaItem"
                component={EntradaItem}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/servicos"
                component={Servicos}
                menus={this.state.menus}
                submenus={this.buscaSubmenu("/servicos")}
              />
              <ProtectedRoute
                path="/servicosDiversos"
                component={ServicosDiversos}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/requisicao"
                component={Requisicao}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/requisicao/lista"
                component={RequisicaoLista}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/reservas"
                component={Reservas}
                menus={this.state.menus}
                submenus={this.buscaSubmenu("/reservas")}
                permissoes={this.state.permissoes}
              />
              <ProtectedRoute
                path="/exameFisico"
                component={ExameFisico}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/exameFisico/lista"
                component={ExameFisicoLista}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/exames"
                component={Exames}
                menus={this.state.menus}
                submenus={this.buscaSubmenu("/exames")}
              />
              <ProtectedRoute
                path="/caixaRelatorio/lista"
                component={caixaRelatorio}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/caixaRelatorio/mostrar"
                component={caixaRelatorioMostrar}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/estrutura/lista"
                component={EstruturaLista}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/estrutura"
                component={Estrutura}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/servicosTurmas"
                component={servicoTurma}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/matriculaTurma"
                component={matriculaTurma}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/administrativo"
                component={Administrativo}
                menus={this.state.menus}
                submenus={this.buscaSubmenu("/administrativo")}
              />
              <ProtectedRoute
                path="/usuarioTitular"
                component={UsuarioTitular}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/servicosTurmas"
                component={servicoTurma}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/usuarioTransferencia"
                component={UsuarioTransferencia}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/alteracaoTipoTitulo"
                component={AlteracaoTipoTitulo}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/lancamentoBancario"
                component={LancamentoBancario}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/lancamentoBancario/lista"
                component={LancamentoBancarioLista}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/entradaItem/lista"
                component={EntradaItemLista}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/login/srmampituba"
                component={LoginMampituba}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/relatorios"
                component={Relatorio}
                menus={this.state.menus}
                submenus={this.buscaSubmenu("/relatorios")}
              />
              <ProtectedRoute
                path="/suspensao"
                component={Suspensao}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/estorno/lista"
                component={EstornoLista}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/clubeVantagem"
                component={ClubeVantagem}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/portaria"
                component={Portaria}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/entidade/lista"
                component={EntidadeLista}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/entidade"
                component={Entidade}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/parametros"
                component={Parametros}
                menus={this.state.menus}
                submenus={this.buscaSubmenu("/parametros")}
              />
              <ProtectedRoute
                path="/arma/lista"
                component={ArmaLista}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/arma"
                component={ArmaIndex}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/municao/lista"
                component={MunicaoLista}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/municao"
                component={MunicaoIndex}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/auditoria"
                component={Auditoria}
                menus={this.state.menus}
              />

              <ProtectedRoute
                path="/eventos/lancamento/listaAtiradores"
                component={ListaAtiradores}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/eventos/lancamento/pontuacao"
                component={LancamentoPontuacao}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/eventos/cadastro"
                component={EventoCadastro}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/eventos/visualiza"
                component={EventoVisualiza}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/eventos/ajustar"
                component={EventoAjustar}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/eventos/modalidade"
                component={EventoModalidade}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/eventos/subgrupo"
                component={EventoSubgrupo}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/eventos/lancamentoInscricao"
                component={LancamentoEtapa}
                menus={this.state.menus}
              />

              <ProtectedRoute
                path="/eventos"
                component={Evento}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/feriado"
                component={Feriado}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/catraca/lista"
                component={CatracaLista}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/catraca"
                component={Catraca}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/eventoClube/lista"
                component={EventoClubeLista}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/eventoClube"
                component={EventoClube}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/marketing"
                component={Marketing}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/imovel/lista"
                component={ImovelLista}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/imovel"
                component={Imovel}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/cadastro"
                component={Cadastro}
                menus={this.state.menus}
                submenus={this.buscaSubmenu("/cadastro")}
              />
              <ProtectedRoute
                path="/funcionario/lista"
                component={FuncionarioMenu}
                menus={this.state.menus}
              />

              <ProtectedRoute
                path="/desporto/partida"
                component={PartidaCampeonato}
                menus={this.state.menus}
              />

              <ProtectedRoute
                path="/desporto/rodada"
                component={RodadaCampeonato}
                menus={this.state.menus}
              />

              <ProtectedRoute
                path="/desporto/ajustar"
                component={AjustarCampeonato}
                menus={this.state.menus}
              />

              <ProtectedRoute
                path="/desporto"
                component={Desporto}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/academia"
                component={Academia}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/exercicio"
                component={Exercicio}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/treino"
                component={Treino}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/avaliacaoFisica"
                component={AvaliacaoFisica}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/instituicaoCobranca/lista"
                component={InstituicaoCobrancaLista}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/instituicaoCobranca"
                component={InstituicaoCobranca}
                menus={this.state.menus}
              />
              <ProtectedRoute
                path="/suporte"
                component={Suporte}
                menus={this.state.menus}
              />
            </Switch>
          </div>
        </BrowserRouter>
      </>
    );
  }
}
