import React, { Component } from "react";
import { Row, Col, FormControl, Form, Alert } from "react-bootstrap";
import {
  Container,
  Header,
  Button,
  Divider,
  Segment,
  Message,
} from "semantic-ui-react";

import api from "../../../../services/api";
import Select from "react-select";
import renomearChave from "../../../../services/renomearChave";
import QrCode from "react.qrcode.generator";
import Parcelas from "../../../../enum/ParcelasEnum";
import {
  addMonths,
  setDate,
  getYear,
  getMonth,
  getDate,
  setMonth,
  setYear,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import Meses from "../../../../enum/Mes";
import requisicoes from "../../../../utils/Requisicoes";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import converterParaLabel from "../../../../utils/converteParaLabel";
import converterParaLabelComAcao from "../../../../utils/converteParaLabelComAcao";
import ListaGruposServico from "./listaGruposServico";
import ModalGruposServico from "./modalGruposServico";
import { Checkbox, Label } from "semantic-ui-react";
import moment from "moment-timezone";
import Numerico from "../../../../utils/numerico";
export default class Usuario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      login: null,
      senha: null,
      tipo_usuario: null,
      pessoa_id: null,
      options: [],
      pessoa: null,
      qrCodeLink: "",
      infoPessoa: null,
      acao: null,
      selectTipo: null,
      listaTipoTitulo: [],
      parcelas: Parcelas,
      infoParcelasMensalidade: [],
      infoParcelasTitulo: [],
      meses: Meses,
      tipo_pagamento: null,
      // efetuar_cobranca: false,
      listaUsuario: [],
      usuario_id: null,
      usuario: null,
      funcao: null,
      responsavel1: null,
      responsavel2: null,
      catracas: [],
      catraca: null,
      catraca_id: null,
      mensagem: null,
      loginJaExiste: false,
      inputValue: null,
      loginOriginal: null,
      open_grupos_servico: false,
      grupos_servico: [],
      grupos_servico_permitidos_descricao: [],
      statusUsuario: true,
      permitir_estorno: true,
      listaPerfis: [],
      perfil_id: null,
    };
    this.alteracao = this.alteracao.bind(this);
    this.alteracaoSelectPessoa = this.alteracaoSelectPessoa.bind(this);
    this.fazerBuscas();
  }

  fazerBuscas = async () => {
    let promisses = [];

    promisses.push(this.listaTipoTitulo());
    promisses.push(this.listaCatracas());
    promisses.push(this.listaPerfil());
    await Promise.all(promisses);

    this.visualizacao();
  };

  listaPerfil = async () => {
    const listaPerfis = await api.post(
      "/perfil/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ listaPerfis: listaPerfis.data });
  };

  setaGruposServico = async (lista, listaPermitidos) => {
    this.setState({ grupos_servico: lista });
    this.setState({ grupos_servico_permitidos_descricao: listaPermitidos });
  };

  handleOpenGruposServico = async () => {
    await this.setState({ open_grupos_servico: true });
  };
  handleCloseGruposServico = () => {
    this.setState({ open_grupos_servico: false });
  };

  visualizacao = async () => {
    if (this.props.location.query !== undefined) {
      const resposta = await api.post(
        "/usuario/visualiza" + requisicoes.entidade,
        this.props.location.query,
        requisicoes.header
      );
      this.setState(resposta.data);
      this.setState({ loginOriginal: resposta.data.login });
      this.setState({ statusUsuario: resposta.data.status });
      //pessoa
      const respPessoa = await api.post(
        "/pessoa/pessoaIdNome" + requisicoes.entidade,
        { id: resposta.data.pessoa_id },
        requisicoes.header
      );

      let p = renomearChave(respPessoa.data, "id", "value");
      p = renomearChave(p, "nome", "label");
      this.setState({ pessoa: p });

      if (resposta.data.tipo_usuario === "portaria") {
        this.listaCatracas(resposta.data.catraca_id);
        document.getElementById("catracas").removeAttribute("hidden");
      }

      //usuario

      if (resposta.data.tipo_usuario === "modalidade") {
        var listaGrupServi = await api.post(
          "/usuarioGruposServico/buscaGruposUsuario" + requisicoes.entidade,
          { usuario_id: resposta.data.id },
          requisicoes.header
        );
        var listaAux = [];

        for (let i = 0; i < listaGrupServi.data.length; i++) {
          const element = listaGrupServi.data[i];
          listaAux.push(element.grupo_servico_id);
        }
        this.setState({ grupos_servico: listaAux });

        var listaAux2 = [];
        const retorno = await api.post(
          "/grupoServico/lista" + requisicoes.entidade,
          {},
          requisicoes.header
        );

        for (let i = 0; i < retorno.data.length; i++) {
          const element = retorno.data[i];

          for (let j = 0; j < listaGrupServi.data.length; j++) {
            const element2 = listaGrupServi.data[j];
            if (element.id === element2.grupo_servico_id) {
              listaAux2.push(element);
            }
          }
        }
        this.setState({ grupos_servico_permitidos_descricao: listaAux2 });
      }
      if (resposta.data.tipo_usuario === "dependente") {
        const resPessoa = await api.post(
          "/pessoa/visualiza" + requisicoes.entidade,
          { id: respPessoa.data.id },
          requisicoes.header
        );
        const respPessoaTitular = await api.post(
          "/pessoa/pessoaIdNome" + requisicoes.entidade,
          { id: resPessoa.data.titular_id },
          requisicoes.header
        );
        let p = renomearChave(respPessoaTitular.data, "id", "value");
        p = renomearChave(p, "nome", "label");
        this.setState({ usuario: p });
        this.setState({ usuario_id: p.id });
      }
      this.setState({ qrCodeLink: resposta.data.id });
      const respPessoaInfo = await api.post(
        "/pessoa/visualiza" + requisicoes.entidade,
        { id: resposta.data.pessoa_id },
        requisicoes.header
      );
      this.setState({ infoPessoa: respPessoaInfo.data });
      if (
        respPessoaInfo.data.acao !== undefined &&
        respPessoaInfo.data.acao !== null
      ) {
        const respAcao = await api.post(
          "/acao/visualiza" + requisicoes.entidade,
          { id: respPessoaInfo.data.acao.id },
          requisicoes.header
        );
        this.setState({ acao: respAcao.data });
      }
    }
  };

  listaCatracas = async (id) => {
    //catracas
    const catracas = await api.post(
      "/catraca/lista" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    let listaCatracas = [];
    catracas.data.forEach((element) => {
      listaCatracas.push({ value: element.id, label: element.descricao });
    });

    if (id !== null) {
      listaCatracas.forEach((element) => {
        if (id === element.value) {
          this.setState({ catraca: element });
        }
      });
    }

    this.setState({ catracas: listaCatracas });
  };

  listaTipoTitulo = async () => {
    const resposta = await api.post(
      "/tipoTitulo/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ listaTipoTitulo: resposta.data });
  };

  alteracao(event) {
    if (event.target.name === "tipo_usuario") {
      this.setState({ tipo_usuario: event.target.value });
      if (event.target.value === "projeto") {
        this.novaAcao();
      }
      if (event.target.value === "portaria") {
        document.getElementById("catracas").removeAttribute("hidden");
      } else {
        document.getElementById("catracas").setAttribute("hidden", "");
      }
    } else if (event.target.name === "tipo_pagamento") {
      this.setState({ [event.target.name]: event.target.value });
      this.geraParcelas(event);
    } else if (event.target.name === "login") {
      this.setState({ loginJaExiste: false });
      this.setState({ [event.target.name]: event.target.value });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  alteracaoCatraca = async (event) => {
    this.setState({ catraca_id: event.value });
    this.setState({ catraca: event });
  };

  novaAcao = async (cb) => {
    const tipoTitulo = await api.post(
      "/tipoTitulo/tipoTituloDescricao" + requisicoes.entidade,
      { descricao: "PROJETO" },
      requisicoes.header
    );
    await api
      .post(
        "/acao/codigoSequencial" + requisicoes.entidade,
        {
          tipo_titulo_descricao: tipoTitulo.data.descricao,
          tipo_titulo_id: tipoTitulo.data.id,
        },
        requisicoes.header
      )
      .then(async (a) => {
        let acao = {
          codigo_auxiliar: a.data.codigo,
          status: "ativo",
          tipo_titulo_id: tipoTitulo.data.id,
          tipo_titulo_descricao: tipoTitulo.data.descricao,
          data_emissao: moment().format("YYYY-MM-DD"),
        };
        this.setState({ acao: acao });
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (
      this.state.tipo_usuario === "dependente" &&
      this.state.usuario === null
    ) {
      alert("Campo Titular Obrigatório");
    } else {
      this.submit();
    }
  };

  submit = async () => {
    const dados = {
      nome: this.state.pessoa.label,
      login: this.state.login,
      senha: this.state.senha,
      tipo_usuario: this.state.tipo_usuario,
      pessoa_id: this.state.pessoa_id,
      valor_mensalidade: this.state.valor_mensalidade,
      acao_id: this.state.acao !== null ? this.state.acao.id : null,
      cobranca_titulo: this.state.infoParcelasTitulo,
      cobranca_mensalidade: this.state.infoParcelasMensalidade,
      funcao: this.state.funcao,
      responsavel1: this.state.responsavel1,
      responsavel2: this.state.responsavel2,
      catraca_id: this.state.catraca_id,
      status: this.state.statusUsuario,
      permitir_estorno: this.state.permitir_estorno,
      perfil_id: this.state.perfil_id,
    };
    if (
      (this.state.login !== "" || this.state.login !== null) &&
      this.state.tipo_usuario !== "projeto"
    ) {
      if (this.state.login !== this.state.loginOriginal) {
        let existeUser = await api.post(
          "/usuario/usuarioPorLogin" + requisicoes.entidade,
          {
            login: this.state.login,
            entidade: requisicoes.entidade.slice(3),
          },
          requisicoes.header
        );
        if (existeUser.data.length !== 0) {
          this.setState({ loginJaExiste: true });
          return;
        }
      }
    }
    if (this.state.id !== null) {
      await api.put(
        "/usuario/alterar" + requisicoes.entidade + "&id=" + this.state.id,
        dados,
        requisicoes.header
      );

      if (this.state.tipo_usuario === "modalidade") {
        await api.post(
          "/usuarioGruposServico/salvarListaGruposServico" +
          requisicoes.entidade,
          {
            user_id: this.state.id,
            grupos_servico: this.state.grupos_servico,
          },
          requisicoes.header
        );
      }

      // if (this.state.efetuar_cobranca === true) {
      //   await api.post(
      //     "/usuario/alteraPessoa" + requisicoes.entidade,
      //     dados,
      //     requisicoes.header
      //   );
      //   await api.post(
      //     "/usuario/cobranca" + requisicoes.entidade,
      //     dados,
      //     requisicoes.header
      //   );
      // }
      if (this.state.tipo_usuario !== "projeto") {
        window.location.href = "/administrativo";
      }
    } else {
      let varRet = null;
      await api
        .post(
          "/usuario/salvar" + requisicoes.entidade,
          dados,
          requisicoes.header
        )
        .then(async (retorno) => {
          if (retorno.data === "sucesso") {
            varRet = retorno.data;

            if (this.state.tipo_usuario === "dependente") {
              retorno = await api.post(
                "/usuario/visualiza" + requisicoes.entidade,
                { id: this.state.usuario_id },
                requisicoes.header
              );
              const dadosInserirTitular = {
                id: this.state.pessoa_id,
                titular_id: retorno.data.pessoa_id,
              };
              retorno = await api.post(
                "/pessoa/inserirTitular" + requisicoes.entidade,
                dadosInserirTitular,
                requisicoes.header
              );
            }

            // if (this.state.efetuar_cobranca === true) {
            //   retorno = await api.post(
            //     "/usuario/alteraPessoa" + requisicoes.entidade,
            //     dados,
            //     requisicoes.header
            //   );
            //   retorno = await api.post(
            //     "/usuario/cobranca" + requisicoes.entidade,
            //     dados,
            //     requisicoes.header
            //   );
            // }
          }
          if (retorno !== null && this.state.tipo_usuario !== "projeto") {
            window.location.href = "/administrativo";
          }
        })
     


        .catch((err) => {      
          this.setState({ mensagem: err.response.data });
        });

      if (this.state.tipo_usuario === "modalidade" && varRet === "sucesso") {
        const user = await api.post(
          "/usuario/usuarioPorLogin" + requisicoes.entidade,
          {
            login: this.state.login,
            entidade: requisicoes.entidade.slice(3),
          },
          requisicoes.header
        );
        await api.post(
          "/usuarioGruposServico/salvarListaGruposServico" +
          requisicoes.entidade,
          {
            user_id: user.data[0].id,
            grupos_servico: this.state.grupos_servico,
          },
          requisicoes.header
        );
      }
      if (this.state.tipo_usuario === "projeto" && varRet === "sucesso") {
        if (this.state.infoPessoa.acao !== null) {
          alert(
            "Não é possível cadastrar porque a pessoa já está vinculada a uma ação"
          );
        }
        this.state.acao.historico_codigo =
          "00" + this.state.acao.codigo_auxiliar + "PJT";
        //TODO: teria que pegar o prefixo do tipo título.
        this.state.acao.codigo = null; //Evitar que o codigo fique undefined
        var a = await api.post(
          "/acao/salvar" + requisicoes.entidade,
          this.state.acao,
          requisicoes.header
        );
        await api.post(
          "/pessoa/alterar" +
          requisicoes.entidade +
          "&id=" +
          this.state.pessoa_id,
          { acao_id: a.data.id },
          requisicoes.header
        );
      }
      if (varRet !== null) {
        window.location.href = "/administrativo";
      }
    }
  };

  pesquisaUsuario = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/usuario/pesquisaTitulares" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaUsuario").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista.push(objeto);
      });
      this.setState({ listaUsuario: lista });
    }, 500);
  };
  alteracaoSelectUsuario = async (event) => {
    this.setState({ usuario_id: event.value });
    this.setState({ usuario: event });
  };
  alteracaoSelectPessoa = async (event, v) => {
    this.setState({ pessoa_id: v.value });
    this.setState({ pessoa: v });
    const resposta = await api.post(
      "/pessoa/visualiza" + requisicoes.entidade,
      { id: v.value },
      requisicoes.header
    );
    this.setState({ infoPessoa: resposta.data });
    if (resposta.data.acao !== null) {
      const respAcao = await api.post(
        "/acao/visualiza" + requisicoes.entidade,
        { id: resposta.data.acao.id },
        requisicoes.header
      );
      this.setState({ acao: respAcao.data });
    } else {
      this.setState({ acao: null });
    }
    /*
        const repostaHistoricoAcao = await api.post("/historicoAcao/historicoPorStatusEPessoaCompra?e=" + e, { pessoa: { id: resposta.data.id } }, { headers: { token: "Baer " + token } });
        let codigo = null;
        repostaHistoricoAcao.data.map(e => {
          codigo = e.id;
        });
        if (codigo !== null) {
          const repostaAcaoHistorico = await api.post("/acaoHistorico/acaoPorHistorico?e=" + e, { id: repostaHistoricoAcao.data.id }, { headers: { token: "Baer " + token } });
          if (repostaAcaoHistorico.data !== "") {
            const repostaAcao = await api.post("/acao/visualiza?e=" + e, { id: repostaAcaoHistorico.data.id }, { headers: { token: "Baer " + token } });
            this.setState({ acao: repostaAcao });
          }
        }*/
  };

  alteraAcao = async (event) => {
    this.setState({ selectTipo: event.target.value });
    const dados = {
      id: this.state.acao.id,
      data_emissao: this.state.acao.data_emissao,
      status: this.state.acao.status,
      tipo_titulo_id: parseInt(event.target.value),
    };
    const resposta = await api.post(
      "/acao/alterar" + requisicoes.entidade,
      dados,
      requisicoes.header
    );
    const respostaTipoTitulo = await api.post(
      "/tipotitulo/visualiza" + requisicoes.entidade,
      { id: resposta.data.tipo_titulo_id },
      requisicoes.header
    );
    let acao = this.state.acao;
    acao.tipo_titulo = respostaTipoTitulo.data;
    this.setState({ acao: acao });
  };

  excluir = async () => {
    const resposta = await api.post(
      "/usuario/excluir" + requisicoes.entidade + "&id=" + this.state.id,
      {},
      requisicoes.header
    );
    if (resposta.data !== null) {
      window.location.href = "/usuario/lista";
    }
  };

  verificarMes = (data) => {
    if (getMonth(data) === 0) {
      data = setMonth(data, 11);
      data = setYear(data, getYear(data) - 1);
      return data;
    } else {
      return data;
    }
  };

  geraParcelas = (event) => {
    const parcelas = [];
    let dataVencimento = "";
    const dataHoje = zonedTimeToUtc(new Date(), "America/Sao_Paulo");
    if (event.target.value === "avista") {
      if (getDate(dataHoje) > 15) {
        const setDia = setDate(
          dataHoje,
          this.state.acao.tipo_titulo.dia_vencimento
        );
        dataVencimento = addMonths(setDia, 1);
      } else {
        dataVencimento = setDate(
          dataHoje,
          this.state.acao.tipo_titulo.dia_vencimento
        );
      }
      const mes = getMonth(dataVencimento) + 1;
      if (event.target.name === "forma_pagamento_mensalidade") {
        parcelas.push({
          numero: "Única",
          valor: this.state.acao.tipo_titulo.valor_mensalidade,
          data_vencimento:
            getYear(dataVencimento) +
            "-" +
            (getMonth(dataVencimento).toString().length === 1
              ? "0" + mes
              : mes) +
            "-" +
            (getDate(dataVencimento).toString().length === 1
              ? "0" + getDate(dataVencimento)
              : getDate(dataVencimento)),
        });
        this.setState({ infoParcelasMensalidade: parcelas });
      } else {
        parcelas.push({
          numero: "Única",
          valor: this.state.acao.tipo_titulo.valor_titulo,
          data_vencimento:
            getYear(dataVencimento) +
            "-" +
            (getMonth(dataVencimento).toString().length === 1
              ? "0" + mes
              : mes) +
            "-" +
            (getDate(dataVencimento).toString().length === 1
              ? "0" + getDate(dataVencimento)
              : getDate(dataVencimento)),
        });
        this.setState({ infoParcelasTitulo: parcelas });
      }
    } else {
      const numeroParcela = event.target.value.toString().replace("x", "");
      var acresMes = 1;
      if (event.target.name === "forma_pagamento_mensalidade") {
        for (var i = 1; i < parseInt(numeroParcela) + 1; i++) {
          if (
            getDate(dataHoje) > this.state.acao.tipo_titulo.dia_vencimento &&
            i === 1
          ) {
            const setDia = setDate(
              dataHoje,
              this.state.acao.tipo_titulo.dia_vencimento
            );
            dataVencimento = addMonths(setDia, 1);
            acresMes = acresMes + 1;
          } else {
            const addDia = setDate(
              dataHoje,
              this.state.acao.tipo_titulo.dia_vencimento
            );
            dataVencimento = addMonths(addDia, acresMes);
            acresMes++;
          }
          const mes = getMonth(dataVencimento) + 1;
          parcelas.push({
            numero: i,
            valor: this.state.acao.tipo_titulo.valor_mensalidade,
            data_vencimento:
              getYear(dataVencimento) +
              "-" +
              (mes.toString().length === 1 ? "0" + mes : mes) +
              "-" +
              (getDate(dataVencimento).toString().length === 1
                ? "0" + getDate(dataVencimento)
                : getDate(dataVencimento)),
            mes_referencia:
              Meses[
                getMonth(dataVencimento) - 1 === -1
                  ? 11
                  : getMonth(dataVencimento) - 1
              ].value,
            ano_referencia:
              getMonth(dataVencimento) - 1 === -1
                ? getYear(dataVencimento) - 1
                : getYear(dataVencimento),
          });
        }
        this.setState({ infoParcelasMensalidade: parcelas });
      } else {
        for (var ind = 1; ind < parseInt(numeroParcela) + 1; ind++) {
          if (
            getDate(dataHoje) > this.state.acao.tipo_titulo.dia_vencimento &&
            ind === 1
          ) {
            const setDia = setDate(
              dataHoje,
              this.state.acao.tipo_titulo.dia_vencimento
            );
            dataVencimento = addMonths(setDia, 1);
            acresMes = acresMes + 1;
          } else {
            const addDia = setDate(
              dataHoje,
              this.state.acao.tipo_titulo.dia_vencimento
            );
            dataVencimento = addMonths(addDia, acresMes);
            acresMes++;
          }
          const mes = getMonth(dataVencimento) + 1;

          parcelas.push({
            numero: ind,
            valor: this.state.acao.tipo_titulo.valor_titulo / numeroParcela,
            data_vencimento:
              getYear(dataVencimento) +
              "-" +
              (mes.toString().length === 1 ? "0" + mes : mes) +
              "-" +
              (getDate(dataVencimento).toString().length === 1
                ? "0" + getDate(dataVencimento)
                : getDate(dataVencimento)),
          });
        }
        this.setState({ infoParcelasTitulo: parcelas });
      }
    }
  };

  alteraValorParcelas = (event) => {
    let lista = [];
    const indice = event.target.id.toString().substring(12, 14);
    if (event.target.name === "valorParcelaMensalidade") {
      lista = this.state.infoParcelasMensalidade;
      lista.splice(parseInt(indice), 1, {
        numero: parseInt(indice) + 1,
        valor: event.target.value,
        data_vencimento: lista[parseInt(indice)].data_vencimento,
        mes_referencia: lista[parseInt(indice)].mes_referencia,
        ano_referencia: lista[parseInt(indice)].ano_referencia,
      });
    } else {
      lista = this.state.infoParcelasTitulo;
      lista.splice(parseInt(event.target.id.toString().substring(18, 20)), 1, {
        numero: parseInt(event.target.id.toString().substring(18, 20)) + 1,
        valor: event.target.value,
      });
    }

    if (lista.find((e) => e.valor.toString() === "NaN")) {
      lista.forEach((element) => {
        if (element.valor.toString() === "NaN") {
          element.valor = "";
        }
      });
    }
    if (event.target.name === "valorParcelaMensalidade") {
      this.setState({ infoParcelasMensalidade: lista });
    } else {
      this.setState({ infoParcelasTitulo: lista });
    }
  };

  alteraDataVencimentoParcelas = (event) => {
    let lista = [];
    const indice = event.target.id.toString().substring(25, 27);
    if (event.target.name === "dataVencimentoParcelaMensalidade") {
      lista = this.state.infoParcelasMensalidade;
      if (event.target.value !== "") {
        lista.splice(parseInt(indice), 1, {
          numero: parseInt(indice) + 1,
          valor: lista[parseInt(indice)].valor,
          data_vencimento: event.target.value,
          mes_referencia: lista[parseInt(indice)].mes_referencia,
          ano_referencia: lista[parseInt(indice)].ano_referencia,
        });
      }
    } else {
      lista = this.state.infoParcelasTitulo;
      if (event.target.value !== "") {
        lista.splice(
          parseInt(event.target.id.toString().substring(20, 22)),
          1,
          {
            numero: parseInt(event.target.id.toString().substring(20, 22)) + 1,
            valor:
              lista[parseInt(event.target.id.toString().substring(20, 22))]
                .valor,
            data_vencimento: event.target.value,
          }
        );
      }
    }
    if (event.target.name === "dataVencimentoParcelaMensalidade") {
      this.setState({ infoParcelasMensalidade: lista });
    } else {
      this.setState({ infoParcelasTitulo: lista });
    }
  };

  alteracaoMesReferencia = (event) => {
    let lista = this.state.infoParcelasMensalidade;
    const indice = event.target.name.toString().substring(13, 15);
    lista.splice(indice, 1, {
      numero: parseInt(indice) + 1,
      valor: lista[parseInt(indice)].valor,
      data_vencimento: lista[parseInt(indice)].data_vencimento,
      mes_referencia: event.target.value,
      ano_referencia: lista[parseInt(indice)].ano_referencia,
    });
    this.setState({ infoParcelasMensalidade: lista });
  };

  alteracaoAnoReferencia = (event) => {
    let lista = this.state.infoParcelasMensalidade;
    const indice = event.target.name.toString().substring(13, 15);
    lista.splice(indice, 1, {
      numero: parseInt(indice) + 1,
      valor: lista[parseInt(indice)].valor,
      data_vencimento: lista[parseInt(indice)].data_vencimento,
      mes_referencia: lista[parseInt(indice)].mes_referencia,
      ano_referencia: event.target.value,
    });
    this.setState({ infoParcelasMensalidade: lista });
  };
  voltar() {
    window.location.href = "/administrativo";
  }
  pesquisaPessoa = async () => {
    let term = document.getElementById("pesquisaPessoa").value;

    setTimeout(async () => {
      if (isFinite(String(term))) {
        await api
          .post(
            "/pessoa/pesquisaPorCodigoAcaoValorExato" + requisicoes.entidade,
            { pesquisa: document.getElementById("pesquisaPessoa").value },
            requisicoes.header
          )
          .then((retorno) => {
            this.setState({
              options: converterParaLabelComAcao(retorno.data),
            });
          });
      } else {
        await api
          .post(
            "/pessoa/pesquisa" + requisicoes.entidade,
            { pesquisa: document.getElementById("pesquisaPessoa").value },
            requisicoes.header
          )
          .then((retorno) => {
            this.setState({ options: converterParaLabel(retorno.data) });
          });
      }
    }, 200);
  };
  onChangeCheckboxStatus = (event, data) => {
    this.setState({ statusUsuario: data.checked });
  };

  onChangeCheckboxPermitirEstorno = (event, data) => {
    this.setState({ permitir_estorno: data.checked });
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Usuario
        </Header>
        {/* {this.state.qrCodeLink === undefined} */}
        {/* <Segment raised>
          <Container textAlign="center">
            <QrCode
              value={this.state.qrCodeLink}
              size={300}
              background="white"
            />
          </Container>
        </Segment> */}
        {/* <Divider /> */}
        {this.state.mensagem !== null && (
          <Alert variant="danger">{this.state.mensagem}</Alert>
        )}
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={12}>
              <Form.Label>Pessoa</Form.Label>
              <Autocomplete
                id="pesquisaPessoa"
                value={this.state.pessoa}
                onChange={this.alteracaoSelectPessoa}
                getOptionLabel={(option) => option.label}
                onKeyUp={this.pesquisaPessoa}
                inputValue={this.state.inputValue}
                onInputChange={(event, newInputValue) => {
                  this.setState({ inputValue: newInputValue });
                }}
                disabled={this.state.id !== null}
                options={this.state.options}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={this.state.id === null && "Busca"}
                    size="small"
                  />
                )}
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Login</Form.Label>
              <FormControl
                placeholder="Login"
                aria-label="Login"
                aria-describedby="basic-addon1"
                name="login"
                onChange={this.alteracao}
                required={
                  this.state.tipo_usuario !== "funcionario" &&
                  this.state.tipo_usuario !== "projeto"
                }
                defaultValue={this.state.login}
              //disabled={this.props.location.query !== undefined}
              />
            </Col>
            {this.state.loginJaExiste && <Col sm={6}></Col>}
            {this.state.loginJaExiste && (
              <Col sm={6}>
                <Message negative size="mini">
                  Login já existente.
                </Message>
              </Col>
            )}

            <Col sm={6}>
              <Form.Label>Senha</Form.Label>
              <FormControl
                placeholder="Senha"
                aria-label="Senha"
                type="password"
                aria-describedby="basic-addon1"
                name="senha"
                onChange={this.alteracao}
              />
            </Col>

            <Col sm={6}>
              <Form.Label>Perfil</Form.Label>
              <Form.Control
                placeholder="Tipo"
                as="select"
                name="tipo_usuario"
                onChange={this.alteracao}
                checked={this.state.selectTipo}
                value={this.state.tipo_usuario}
                required
              >
                <option></option>
                <option value="titular">Titular</option>
                <option value="dependente">Dependente</option>
                <option value="administrador">Administrador</option>
                <option value="temporario">Temporário</option>
                <option value="funcionario">Funcionário</option>
                <option value="caixa">Caixa</option>
                <option value="medico">Médico</option>
                <option value="portaria">Acesso</option>
                <option value="almoxarifado">Almoxarifado</option>
                {/* <option value="projeto">Projeto</option> */}
                <option value="modalidade">Modalidade</option>
                <option value="administrativo">Administrativo</option>
                <option value="impressaoCarteirinha">
                  Impressao Carteirinha
                </option>
                <option value="acessoTemporario">Acesso Temporário</option>
                <option value="marketing">Marketing</option>
              </Form.Control>
            </Col>

            <Col sm={6}>
              <Form.Label>Perfil</Form.Label>
              <Form.Control
                placeholder="Perfil"
                as="select"
                name="perfil_id"
                onChange={this.alteracao}
                value={this.state.perfil_id}
                required
              >
                <option></option>
                {this.state.listaPerfis.map((e) => (
                  <option value={e.id}>{e.nome}</option>
                ))}
              </Form.Control>
            </Col>

            <Col sm={6} hidden={this.state.tipo_usuario !== "projeto"}>
              <Form.Label>Código</Form.Label>
              <FormControl
                type="text"
                aria-describedby="basic-addon1"
                name="codigo"
                value={
                  this.state.acao !== null &&
                  this.state.acao.codigo_auxiliar !== null &&
                  "PJT" + Numerico(this.state.acao.codigo_auxiliar, 5)
                }
                disabled
              />
            </Col>

            <Col sm={2} style={{ marginTop: "15px" }}>
              <div>Status</div>
              <Row style={{ marginLeft: "0px" }}>
                <Checkbox
                  style={{ marginTop: "5px" }}
                  checked={this.state.statusUsuario}
                  onChange={(event, data) => {
                    this.onChangeCheckboxStatus(event, data);
                  }}
                  toggle
                />
                <div
                  style={{
                    alignContent: "center",
                    marginLeft: "15px",
                    marginTop: "0px",
                  }}
                >
                  {this.state.statusUsuario === false ? (
                    <Label color="red">Inativo</Label>
                  ) : (
                    <Label color="green">Ativo</Label>
                  )}
                </div>
              </Row>
            </Col>
            <Col
              sm={2}
              style={{ marginTop: "15px" }}
              hidden={this.state.tipo_usuario !== "caixa"}
            >
              <div>Permitir Estorno</div>
              <Row style={{ marginLeft: "0px" }}>
                <Checkbox
                  style={{ marginTop: "5px" }}
                  checked={this.state.permitir_estorno}
                  onChange={(event, data) => {
                    this.onChangeCheckboxPermitirEstorno(event, data);
                  }}
                  toggle
                />
                <div
                  style={{
                    alignContent: "center",
                    marginLeft: "15px",
                    marginTop: "0px",
                  }}
                >
                  {this.state.permitir_estorno === false ? (
                    <Label color="red">Inativo</Label>
                  ) : (
                    <Label color="green">Ativo</Label>
                  )}
                </div>
              </Row>
            </Col>
            <Col sm={11}></Col>

            <Col sm={6} id="catracas" hidden>
              <Form.Label>Catracas</Form.Label>
              <Select
                placeholder="Digite para buscar"
                name="catraca"
                value={this.state.catraca}
                onChange={this.alteracaoCatraca}
                options={this.state.catracas}
                inputId="catraca"
                isSearchable={false}
              />
            </Col>
            {this.state.tipo_usuario === "projeto" && (
              <Col sm={12}>
                <Row>
                  <Col sm={6}>
                    <Form.Label>Reponsável 1</Form.Label>
                    <FormControl
                      placeholder="Responsável"
                      aria-describedby="basic-addon1"
                      name="responsavel1"
                      onChange={this.alteracao}
                      defaultValue={this.state.responsavel1}
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Label>Reponsável 2</Form.Label>
                    <FormControl
                      placeholder="Responsável"
                      aria-describedby="basic-addon1"
                      name="responsavel2"
                      onChange={this.alteracao}
                      defaultValue={this.state.responsavel2}
                    />
                  </Col>
                </Row>
              </Col>
            )}

            {this.state.tipo_usuario === "funcionario" && (
              <Col sm={6}>
                <Form.Label>Função</Form.Label>
                <FormControl
                  placeholder="Função"
                  aria-describedby="basic-addon1"
                  name="funcao"
                  onChange={this.alteracao}
                  required
                  defaultValue={this.state.funcao}
                />
              </Col>
            )}

            {this.state.infoPessoa !== null && (
              <Col sm={12} style={{ paddingTop: "10px" }}>
                <Header as="h2">Dados Cadastrais</Header>
                <Divider />
                <Segment.Group>
                  <Segment.Group horizontal>
                    <Segment>
                      <b>Tipo:</b> {this.state.infoPessoa.tipo}
                    </Segment>
                    <Segment>
                      <b>Telefone:</b> {this.state.infoPessoa.telefone}
                    </Segment>
                    <Segment>
                      <b>E-mail:</b> {this.state.infoPessoa.email}
                    </Segment>
                  </Segment.Group>
                  <Segment>
                    <b>Endereço:</b> {this.state.infoPessoa.endereco} -{" "}
                    {this.state.infoPessoa.bairro}, {this.state.infoPessoa.cep}{" "}
                    {this.state.infoPessoa.cidade}-
                    {this.state.infoPessoa.estado}
                  </Segment>
                </Segment.Group>
              </Col>
            )}

            {this.state.tipo_usuario === "modalidade" && (
              <>
                <ListaGruposServico
                  handleOpenGruposServico={this.handleOpenGruposServico}
                  open_grupos_servico={this.state.open_grupos_servico}
                  grupos_servico={
                    this.state.grupos_servico_permitidos_descricao
                  }
                />
                <ModalGruposServico
                  open_grupos_servico={this.state.open_grupos_servico}
                  handleCloseGruposServico={this.handleCloseGruposServico}
                  setaGruposServico={this.setaGruposServico}
                  grupos_servico={this.state.grupos_servico}
                />
              </>
            )}

            {this.state.tipo_usuario === "dependente" && (
              <Col sm={12} style={{ paddingTop: "10px" }}>
                <Header as="h2">Titular</Header>
                <Divider />
                <Segment.Group>
                  <Segment.Group horizontal>
                    <Segment>
                      <Col sm={6}>
                        <Select
                          placeholder="Digite para buscar"
                          name="usuario"
                          onKeyDown={this.pesquisaUsuario}
                          inputId="pesquisaUsuario"
                          onChange={this.alteracaoSelectUsuario}
                          value={this.state.usuario}
                          options={this.state.listaUsuario}
                        />
                      </Col>
                    </Segment>
                  </Segment.Group>
                </Segment.Group>
              </Col>
            )}
            {this.state.tipo_usuario === "titular" && (
              <Col sm={12} style={{ paddingTop: "10px" }}>
                <Header as="h2">Ação/Titulo</Header>
                <Divider />
                {this.state.acao === null ? (
                  ""
                ) : (
                  <Segment.Group>
                    <Segment.Group horizontal>
                      <Segment>
                        <b>Tipo Titulo:</b>
                        <Form.Control
                          placeholder="TipoTitulo"
                          label="TipoTitulo"
                          id="TipoTitulo"
                          as="select"
                          name="tipoTitulo"
                          required
                          disabled={
                            // this.state.efetuar_cobranca === false
                            true
                          }
                        >
                          <option></option>
                          {this.state.acao.tipo_titulo && (
                            <option
                              key={this.state.acao.tipo_titulo.id}
                              selected
                            >
                              {this.state.acao.tipo_titulo.descricao}
                            </option>
                          )}
                        </Form.Control>
                      </Segment>
                      <Segment>
                        <b>Titulo:</b> {this.state.acao.codigo} <br />
                        <br />
                        <b>Valor Mensalidade:</b>
                        {this.state.acao.tipo_titulo !== null &&
                          this.state.acao.tipo_titulo.valor_mensalidade}
                      </Segment>
                      <Segment>
                        <b>Data Emissão:</b>{" "}
                        {new Date(
                          this.state.acao.data_emissao
                        ).toLocaleDateString()}
                        <br />
                        <br />
                        <b>Valor Titulo:</b>
                        {this.state.acao.tipo_titulo !== null &&
                          this.state.acao.tipo_titulo.valor_titulo}
                      </Segment>
                    </Segment.Group>
                  </Segment.Group>
                )}
              </Col>
            )}
          </Row>
          <Divider />
          <Segment clearing>
            <Header floated="right">
              <Button type="button" onClick={this.voltar}>
                Voltar
              </Button>
              <Button negative type="button" onClick={this.excluir}>
                Deletar
              </Button>
              <Button positive type="submit">
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}
