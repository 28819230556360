import React, { Component } from "react";
import { Row, Col, FormControl, Form, Alert } from "react-bootstrap";
import {
  Header,
  Button,
  Segment,
  Divider,
  Dimmer,
  Loader,
  Checkbox,
} from "semantic-ui-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";

// Utils
import { Link } from "react-router-dom";
import renomearChave from "../../../services/renomearChave";
import { format, addDays } from "date-fns";
import moment from "moment";
import ValidadorCamposPessoa from "../../../utils/validadorCamposPessoa";

// Requisicoes
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";
import { storage } from "../../../config/firebase";

// Componentes
import LoadingService from "../../../services/LoadingService";
import Cargos from "./cargos";
import AcaoTitulo from "./acaoTitulo";
import Numerico from "../../../utils/numerico";
import ListaDependentes from "./listaDependentes";
import ListaUsuarios from "./listaUsuarios";
import ListaLancamentos from "./ListaLancamentos/listaLancamentos";

// Modal
import ModalMatricula from "./modalMatriculas";
import ModalDependente from "./modalDependente";
import ModalEstudante from "./modalEstudante";
import ModalReservas from "./modalReservas";
import ModalFoto from "./modalFoto";
import ModalRfid from "./modalRfid";
import ModalDigital from "./modalDigital";
import ModalCarteirinha from "./modalCarteirinha";
import ModalCarteirinhaPadrao from "./modalCarteirinhaPadrao";

import ModalConta from "./modalConta";
import ModalSelecionarInstituicaoCobrancaBoleto from "./modalSelecionarInstituicaoCobrancaBoleto";
import ModalRemoveDependentes from "./modalRemoveDependente";

// Enum
import Acao from "../../../enum/Acao";
import TipoCargo from "../../../enum/TipoCargo";

let buscando = false;

export default class MeuDados extends Component {
  constructor(props) {
    super(props);
    LoadingService.load((v) => this.setState({ isLoading: true }));
    this.state = {
      id: null,
      login: null,
      senha: null,
      pessoa_id: null,
      options: [],
      pessoa: null,
      qrCodeLink: "",
      infoPessoa: {},
      exameMedico: {},
      enderecoCompleto: "",
      cidadeCompleto: "",
      dataValidade: "",
      dataVencimento: "",
      listaServico: [],
      listaLancamentos: [],
      buscaPessoa: null,
      buscaPessoa_id: null,
      buscaInfoPessoa: {},
      novoLogin: "",
      novaSenha: "",
      novoEmail: "",
      novoTelefone: "",
      novoCelular: "",
      erroLoginExiste: false,
      loginNaoExiste: false,
      senhaAtualizada: false,
      open_modal_dependentes: false,
      tipoLancamento: "",
      open: false,
      listaUsuarios: [],
      bloqueia: null,
      open_conta: false,
      debito_conta: null,
      listaReservasUsuario: [],
      listaPresencaReservas: [],
      acao: null,
      matriculas: [],
      reservas: [],
      listaTipoTitulo: [],
      motivo_cancelamento: null,
      observacao: null,
      mensagem: null,
      suspensoes: [],
      exames_fisico: [],
      open_modal_remove_dependentes: false,
      dependente_remove_id: null,
      motivo_remove_dependente: null,
      listaUsuariosDependente: [],
      inputValue: null,
      open_modal: false,
      nova_entidade_ensino: "",
      nova_data_matricula: "",
      estudante: false,
      titularidade: "",
      titular: "",
      matriculasDependentes: [],
      screenshot: null,
      total_lancamentos: 0.0,
      lista_anos: this.geraListaDeAnos(),
      anoLancamento: moment().format("YYYY"),
      srcImagem: null,
      nova_datanascimento: null,
      checkboxSocio: true,
      checkboxAtivo: true,

      openModalSelecionarInstituicaoCobrancaBoleto: false,
      historico_debito_conta: [],
    };
    this.visualizacao();
    this.listaTipoTitulo();
  }

  geraListaDeAnos = () => {
    var years = [];
    const dateStart = moment("2008-01-01");
    const dateEnd = moment();
    while (dateEnd.format("YYYY") !== dateStart.format("YYYY")) {
      years.push(dateStart.format("YYYY"));
      dateStart.add(1, "year");
    }
    years.push(dateEnd.format("YYYY"));
    years.reverse();
    return years;
  };

  getBase64FromUrl = (imgUrl, cb) => {
    var img = new Image();
    img.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png");
      cb(dataURL);
    };

    img.setAttribute("crossOrigin", "anonymous");
    img.src = imgUrl;
  };

  getBase64(file, cb) {
    var document = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      document = reader.result;
      cb(document);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  abrirImagem = (pessoa_id) => {
    storage
      .ref(
        `${process.env.REACT_APP_ENVIRONMENT === "production"
          ? "producao"
          : "hml"
        }/${window.localStorage.getItem("e_clube")}/pessoa`
      )
      .child(`${pessoa_id}`)
      .getDownloadURL()
      .then((url) => {
        this.setState({ screenshot: url });
      });
  };
  visualizacaoFoto = async () => {
    if (this.state.infoPessoa.id !== undefined) {
      await storage
        .ref(
          `${process.env.REACT_APP_ENVIRONMENT === "production"
            ? "producao"
            : "hml"
          }/${window.localStorage.getItem("e_clube")}/pessoa`
        )
        .child(`${this.state.infoPessoa.id}.jpg`)
        .getDownloadURL()
        .then((url) => {
          this.setState({ screenshot: url });
          this.setState({ srcImagem: url });
        })
        .catch(() => {
          this.setState({ screenshot: null });
          this.setState({ srcImagem: null });
        });
    }
  };

  removeUsuarioDependente = async (id) => {
    let lista = this.state.listaUsuariosDependente;
    let indice = this.state.listaUsuariosDependente.findIndex(
      (element) => element.id === id
    );
    lista.splice(indice, 1);
    this.setState({ listaUsuariosDependente: lista });
    alert(
      "Este Usuário será deletado e não terá mais acesso ao sistema, tem certeza de que deseja excluir?"
    );
    await api.post(
      "/usuario/excluir" + requisicoes.entidade + "&id=" + id,
      {},
      requisicoes.header
    );
  };

  handleShowRemoveDependentes = (id) => {
    this.setState({ open_modal_remove_dependentes: true });
    this.setState({ dependente_remove_id: id });
    if (
      this.state.dependentes.find((d) => d.pessoa.value === id).usuarios !==
      undefined
    ) {
      this.setState({
        listaUsuariosDependente: this.state.dependentes.find(
          (d) => d.pessoa.value === id
        ).usuarios,
      });
    } else {
      this.setState({ listaUsuariosDependente: [] });
    }
  };

  handleCloseRemoveDependentes = () => {
    this.setState({ open_modal_remove_dependentes: false });
  };
  setaAcao = (acao) => {
    this.setState({ acao: acao });
  };

  examesFisicoPorPessoa = async (pessoa_id) => {
    await api
      .post(
        "/exameFisico/examePorPessoa" + requisicoes.entidade,
        { id: pessoa_id },
        requisicoes.header
      )
      .then((e) => {
        this.setState({ exames_fisico: e.data });
      });
  };

  listaTipoTitulo = async () => {
    const resposta = await api.post(
      "/tipoTitulo/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ listaTipoTitulo: resposta.data });
  };

  alteracaoDebitoConta = (content) => {
    this.setState({ debito_conta: content });
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === "tipoLancamento") {
      this.setState({ listaLancamentos: [] });
    }
  };
  handleShowConta = () => {
    this.setState({ open_conta: true });
  };

  handleCloseConta = (dados) => {
    this.setState({ open_conta: false });
    if (dados.id !== null) {
      this.setState({ debito_conta: dados });
    }
  };
  handleCloseDependentes = () => {
    this.setState({ open_modal_dependentes: false });
  };

  geraNumeroTitulo = () => {
    return (
      this.state.acao.codigo +
      "-" +
      Numerico(this.state.dependentes.length + 1, 3)
    );
  };

  adicionaDependentes = (element) => {
    this.state.dependentes.push(element);
  };

  removeDependentes = async () => {
    const lista = this.state.dependentes;
    let indice = lista.findIndex(
      (element) =>
        element.pessoa.value === parseInt(this.state.dependente_remove_id)
    );
    lista.splice(indice, 1);
    this.setState({ dependentes: lista });
    await api.post(
      "/pessoa/alterar" +
      requisicoes.entidade +
      "&id=" +
      parseInt(this.state.dependente_remove_id),
      { grau_parentesco: null, titular_id: null, acao_id: null },
      requisicoes.header
    );
    await api.post(
      "/historicoDependente/salvar" + requisicoes.entidade,
      {
        acao: Acao.find((e) => e.value === "remover").value,
        motivo: this.state.motivo_remove_dependente,
        dependente_id: parseInt(this.state.dependente_remove_id),
        titular_id: this.state.infoPessoa.id,
      },
      requisicoes.header
    );
    this.handleCloseRemoveDependentes();
  };

  handleOpenDependentes = async () => {
    this.setState({
      open_modal_dependentes: true,
      numero_titulo: this.geraNumeroTitulo(),
    });
  };

  visualizacao = async () => {
    const respostaQRcode = await api.post(
      "/usuario/matricula" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    this.setState({ qrCodeLink: respostaQRcode.data.matricula });
    const resposta = await api.post(
      "/usuario/visualiza" + requisicoes.entidade,
      { id: respostaQRcode.data.matricula },
      requisicoes.header
    );
    this.setState(resposta.data);
    this.setState({ pessoa_logada_id: resposta.data.pessoa_id });
    if (this.props.location.query !== undefined) {
      this.alteracaoSelectPessoa(null, this.props.location.query.pessoa);
    } else {
      if (
        this.props.permissoes.find(
          (e) => e.nome === "alterar_meus_dados_alheios"
        ) ||
        this.props.permissoes.find(
          (e) => e.nome === "alterar_meus_dados_proprio"
        )
      ) {
        this.state.bloqueia = false;
      } else {
        this.state.bloqueia = true;
      }

      if (resposta.data.pessoa_id === null) {
        alert(
          "Este Cadastro Não tem Usuário, com isso não vai ser possível gerar crachá ou carteirinha para o mesmo"
        );
      }
      this.setState({
        novoLogin: resposta.data.login,
        novaSenha: "",
      });

      this.examesFisicoPorPessoa(resposta.data.pessoa_id);
      //nao estava sendo usado
      // const respostaTipoUsuario = await api.post(
      //   "/usuario/tipoUsuarioPorPessoa" + requisicoes.entidade,
      //   {
      //     pessoa_id: resposta.data.pessoa_id,
      //     entidade: window.localStorage.getItem("e_clube"),
      //   },
      //   requisicoes.header
      // );

      if (resposta.data.pessoa_id !== null) {
        const usuario = await api.post(
          "/usuario/usuariosPorPessoa" + requisicoes.entidade,
          {
            id: resposta.data.pessoa_id,
            entidade: window.localStorage.getItem("e_clube"),
          },
          requisicoes.header
        );
        this.setState({ listaUsuarios: usuario.data });
      } else {
        this.setState({ listaUsuarios: [] });
      }
      const respPessoa = await api.post(
        "/pessoa/pessoaIdNome" + requisicoes.entidade,
        { id: resposta.data.pessoa_id },
        requisicoes.header
      );

      const suspensao = await api.post(
        "/suspensao/suspensaoPorPessoa" + requisicoes.entidade,
        { pessoa_id: resposta.data.pessoa_id },
        requisicoes.header
      );
      this.setState({ suspensoes: suspensao.data });

      let p = renomearChave(respPessoa.data, "id", "value");
      p = renomearChave(p, "nome", "label");
      this.setState({ pessoa: p });
      const respPessoaInfo = await api.post(
        "/pessoa/visualiza" + requisicoes.entidade,
        { id: resposta.data.pessoa_id },
        requisicoes.header
      );

      if (respPessoaInfo.data.funcionario) {
        let tiposCargos = [];
        for (let i = 0; i < TipoCargo.length; i++) {
          const element = TipoCargo[i];
          tiposCargos.push(element.value);
        }
        const cargos = await api.post(
          "/funcionario/buscaCargoPessoa" + requisicoes.entidade,
          {
            pessoa_id: respPessoaInfo.data.id,
            tipoCargo: tiposCargos,
          },
          requisicoes.header
        );
        this.setState({ cargos: cargos.data });
      } else {
        this.setState({ cargos: [] });
      }

      if (
        respPessoaInfo.data.titular_id !== null &&
        respPessoaInfo.data.titular_id !== undefined
      ) {
        const respTitular = await api.post(
          "/pessoa/visualiza" + requisicoes.entidade,
          { id: respPessoaInfo.data.titular_id },
          requisicoes.header
        );
        this.setState({ titular: respTitular.data });
      } else {
        this.setState({ titular: "" });
      }

      const respMatriculas = await api.post(
        "/matriculaTurma/matriculaPorPessoa" + requisicoes.entidade,
        { id: resposta.data.pessoa_id },
        requisicoes.header
      );
      this.setState({ matriculas: respMatriculas.data });

      const respReservas = await api.post(
        "/reservaHorario/horarioPorPessoa" + requisicoes.entidade,
        { id: resposta.data.pessoa_id },
        requisicoes.header
      );
      this.setState({ reservas: respReservas.data });

      if (respPessoaInfo.data !== null && respPessoaInfo.data !== undefined) {
        if (
          respPessoaInfo.data.titular_id !== null &&
          respPessoaInfo.data.titular_id !== undefined
        ) {
          this.setState({ titularidade: "Dependente" });
        } else if (
          respPessoaInfo.data.acao_id !== null &&
          respPessoaInfo.data.acao_id !== undefined
        ) {
          this.setState({ titularidade: "Titular" });
        } else {
          this.setState({ titularidade: "Pessoa sem ação" });
        }
      }

      if (respPessoaInfo.data.acao !== null && respPessoaInfo.data !== "") {
        const respAcao = await api.post(
          "/acao/visualiza" + requisicoes.entidade,
          { id: respPessoaInfo.data.acao.id },
          requisicoes.header
        );
        if (respAcao.data.codigo) {
          this.setState({ acao_dependentes: respAcao.data.codigo });
          respAcao.data.codigo = respAcao.data.codigo + " - 00";
        }
        if (
          respAcao !== null &&
          respAcao.data.tipoTitulo !== null &&
          respAcao.data.tipoTitulo !== undefined &&
          respAcao.data.tipoTitulo.descricao === "ACESSO TEMPORARIO"
        ) {
          this.setState({ titularidade: "ACESSO TEMPORARIO" });
        }
        this.setState({ acao: respAcao.data });
      } else {
        this.setState({ acao: null });
      }

      var listaDependentes = [];

      const dependentesConst = await api.post(
        "/pessoa/dependentes" + requisicoes.entidade,
        { titular_id: resposta.data.pessoa_id },
        requisicoes.header
      );

      for (let i = 0; i < dependentesConst.data.length; i++) {
        const e = dependentesConst.data[i];
        let p = renomearChave(e, "id", "value");
        p = renomearChave(p, "nome", "label");
        const usrDependente = await api.post(
          "/usuario/usuariosPorPessoaETipo" + requisicoes.entidade,
          {
            pessoa_id: e.id,
            entidade: window.localStorage.getItem("e_clube"),
          },
          requisicoes.header
        );
        if (usrDependente.data.length > 0) {
          listaDependentes.push({
            pessoa: p,
            login: usrDependente.data[0].login,
            grau_parentesco: e.grau_parentesco,
            usuarios: usrDependente.data,
          });
        } else {
          listaDependentes.push({
            pessoa: p,
            login: "",
            grau_parentesco: e.grau_parentesco,
          });
        }
      }
      this.setState({ dependentes: listaDependentes });

      let respMatriculasDep = "";
      var matriculasDependentes = [];

      for (let i = 0; i < listaDependentes.length; i++) {
        const element = listaDependentes[i];
        respMatriculasDep = await api.post(
          "/matriculaTurma/matriculaPorPessoa" + requisicoes.entidade,
          { id: element.pessoa.value },
          requisicoes.header
        );
        matriculasDependentes.push(respMatriculasDep.data);
      }
      this.setState({ matriculasDependentes: matriculasDependentes });
      if (respPessoaInfo.data !== null) {
        this.setState({ infoPessoa: respPessoaInfo.data });
        this.setState({
          novoCpf: respPessoaInfo.data.cpf,
          novoCnpj: respPessoaInfo.data.cnpj,
          novoEmail: respPessoaInfo.data.email,
          novoTelefone: respPessoaInfo.data.telefone,
          novoCelular: respPessoaInfo.data.celular,
          estudante: respPessoaInfo.data.estudante,
          nova_entidade_ensino: respPessoaInfo.data.entidade_ensino,
          nova_data_matricula: respPessoaInfo.data.data_matricula,
          nova_datanascimento: respPessoaInfo.data.datanascimento,
        });
        this.setState({
          enderecoCompleto:
            (this.state.infoPessoa.endereco ?? "Endereço não informado") +
            " - Bairro: " + (this.state.infoPessoa.bairro ?? "*") +
            (", Complemento: " + (this.state.infoPessoa.complemento ?? "N/A")),
          cidadeCompleto:
            (this.state.infoPessoa.cidade ?? "Cidade não informada") +
            " - " + (this.state.infoPessoa.estado ?? "*UF") +
            ", CEP: " + (this.state.infoPessoa.cep ?? "*"),
        });
      } else {
        this.setState({ infoPessoa: null });
        this.setState({
          novoCpf: null,
          novoCnpj: null,
          novoEmail: null,
          novoTelefone: null,
          novoCelular: null,
          estudante: false,
          nova_entidade_ensino: "",
          nova_data_matricula: "",
          nova_datanascimento: "",
        });
        this.setState({
          enderecoCompleto: null,
          cidadeCompleto: null,
        });
      }
      const reservaHorarioPessoa = await api.post(
        "/reservaHorario/horarioPorPessoaCodigo" + requisicoes.entidade,
        { id: resposta.data.pessoa_id },
        requisicoes.header
      );
      const reservaHorarioLista = reservaHorarioPessoa.data;
      const respostaServico = await api.post(
        "/servico/lista" + requisicoes.entidade,
        {},
        requisicoes.header
      );
      const servicos = respostaServico.data;
      const listaRetorno = [];
      servicos.forEach((element) => {
        reservaHorarioLista.forEach((objeto) => {
          if (element.id === objeto.horario_servico.servico_id) {
            listaRetorno.push({
              nome: element.nome,
              id: element.id,
              reserva_id: objeto.id,
              dataReserva: objeto.dataReserva,
              horaInicio: objeto.horario_servico.horaInicio,
              horaFim: objeto.horario_servico.horaFim,
            });
          }
        });
      });

      function ordenaDatas(a, b) {
        if (a.dataReserva > b.dataReserva) {
          return 1;
        }
        if (a.dataReserva < b.dataReserva) {
          return -1;
        }
        return 0;
      }
      listaRetorno.sort(ordenaDatas);
      this.setState({ listaReservasUsuario: listaRetorno });

      const respExame = await api.post(
        "/exameMedico/examePorPessoa" + requisicoes.entidade,
        { id: resposta.data.pessoa_id },
        requisicoes.header
      );
      let exames = respExame.data;

      if (respExame.data.length > 0) {
        const exameMedico = exames[exames.length - 1];
        this.setState({ exameMedico: exameMedico });
        this.setState({
          dataValidade: format(
            addDays(new Date(exameMedico.dataValidade), 1),
            "dd/MM/yyyy"
          ),
        });
      } else {
        this.setState({ exameMedico: null });
        this.setState({ dataValidade: null });
      }
      const respLancamentos = await api.post(
        "lancamentoConta/lancamentosAbertosPorPessoa" + requisicoes.entidade,
        {
          pessoa_id: resposta.data.pessoa_id,
        },
        requisicoes.header
      );
      function ordenaDatasLancamentos(a, b) {
        if (a.data_vencimento !== null && b.data_vencimento !== null) {
          if (a.data_vencimento > b.data_vencimento) {
            return 1;
          }
          if (a.data_vencimento < b.data_vencimento) {
            return -1;
          }
          return 0;
        }
      }
      let listaLancamentos = respLancamentos.data;
      listaLancamentos.sort(ordenaDatasLancamentos);

      this.setState({ listaLancamentos: listaLancamentos });
      this.visualizaDebitoConta();

      let total = 0.0;
      for (let i = 0; i < listaLancamentos.length; i++) {
        const element = listaLancamentos[i];
        total += parseFloat(element.valor);
      }
      this.setState({ total_lancamentos: total });
    }
  };

  alteracaoSelectPessoa = async (event, v) => {
    if (
      event !== undefined &&
      event !== null &&
      event.value === undefined &&
      v === null
    ) {
      return;
    }
    this.setState({ isLoading: false });
    this.setState({ tipoLancamento: "abertas" });

    this.setState({ screenshot: null });
    window.scrollTo(0, 0);

    if (v !== null && v !== undefined) {
      this.setState({ pessoa: v });
      this.setState({ pessoa_id: v.value });
    } else {
      this.setState({ pessoa: event });
      this.setState({ pessoa_id: event.value });
    }
    this.examesFisicoPorPessoa(v !== null ? v.value : event.value);
    const resposta = await api.post(
      "/pessoa/visualiza" + requisicoes.entidade,
      { id: v !== null && v !== undefined ? v.value : event.value },
      requisicoes.header
    );

    if (resposta.data.funcionario) {
      let tiposCargos = [];
      for (let i = 0; i < TipoCargo.length; i++) {
        const element = TipoCargo[i];
        tiposCargos.push(element.value);
      }
      const cargos = await api.post(
        "/funcionario/buscaCargoPessoa" + requisicoes.entidade,
        {
          pessoa_id: resposta.data.id,
          tipoCargo: tiposCargos,
        },
        requisicoes.header
      );
      this.setState({ cargos: cargos.data });
    } else {
      this.setState({ cargos: [] });
    }
    if (
      resposta.data.titular_id !== null &&
      resposta.data.titular_id !== undefined
    ) {
      const respTitular = await api.post(
        "/pessoa/visualiza" + requisicoes.entidade,
        { id: resposta.data.titular_id },
        requisicoes.header
      );
      this.setState({ titular: respTitular.data });
    } else {
      this.setState({ titular: "" });
    }
    const suspensao = await api.post(
      "/suspensao/suspensaoPorPessoa" + requisicoes.entidade,
      { pessoa_id: v !== null ? v.value : event.value },
      requisicoes.header
    );
    this.setState({ suspensoes: suspensao.data });
    var respAcao = null;
    if (resposta.data.acao !== null) {
      respAcao = await api.post(
        "/acao/visualiza" + requisicoes.entidade,
        { id: resposta.data.acao.id },
        requisicoes.header
      );

      if (respAcao.data.codigo !== null) {
        this.setState({ acao_dependentes: respAcao.data.codigo });
        respAcao.data.codigo = respAcao.data.codigo + " - 00";
      }
      this.setState({ acao: respAcao.data });
    } else {
      this.setState({ acao: null });
    }
    let listaDependentes = [];

    const dependentesConst = await api.post(
      "/pessoa/dependentes" + requisicoes.entidade,
      { titular_id: resposta.data.id },
      requisicoes.header
    );

    for (let i = 0; i < dependentesConst.data.length; i++) {
      const e = dependentesConst.data[i];
      let p = renomearChave(e, "id", "value");
      p = renomearChave(p, "nome", "label");
      const usrDependente = await api.post(
        "/usuario/usuariosPorPessoaETipo" + requisicoes.entidade,
        {
          pessoa_id: e.id,
          entidade: window.localStorage.getItem("e_clube"),
        },
        requisicoes.header
      );
      if (usrDependente.data.length > 0) {
        listaDependentes.push({
          pessoa: p,
          login: usrDependente.data[0].login,
          grau_parentesco: e.grau_parentesco,
          usuarios: usrDependente.data,
        });
      } else {
        listaDependentes.push({
          pessoa: p,
          login: "",
          grau_parentesco: e.grau_parentesco,
        });
      }
    }
    this.setState({ dependentes: listaDependentes });

    let respMatriculasDep = "";
    var matriculasDependentes = [];

    for (let i = 0; i < listaDependentes.length; i++) {
      const element = listaDependentes[i];
      respMatriculasDep = await api.post(
        "/matriculaTurma/matriculaPorPessoa" + requisicoes.entidade,
        { id: element.pessoa.value },
        requisicoes.header
      );
      matriculasDependentes.push(respMatriculasDep.data);
    }
    this.setState({ matriculasDependentes: matriculasDependentes });

    const respostaTipoUsuario = await api.post(
      "/usuario/tipoUsuarioPorPessoa" + requisicoes.entidade,
      {
        pessoa_id: resposta.data.id,
        entidade: window.localStorage.getItem("e_clube"),
      },
      requisicoes.header
    );
    if (respostaTipoUsuario.data.pessoa_id === null) {
      alert(
        "Este Cadastro Não tem Usuário, com isso não vai ser possível gerar crachá ou carteirinha para o mesmo"
      );
    }

    const respMatriculas = await api.post(
      "/matriculaTurma/matriculaPorPessoa" + requisicoes.entidade,
      { id: resposta.data.id },
      requisicoes.header
    );
    this.setState({ matriculas: respMatriculas.data });

    const respReservas = await api.post(
      "/reservaHorario/horarioPorPessoa" + requisicoes.entidade,
      { id: resposta.data.id },
      requisicoes.header
    );
    this.setState({ reservas: respReservas.data });

    if (resposta.data !== null && resposta.data !== undefined) {
      if (
        resposta.data.titular_id !== null &&
        resposta.data.titular_id !== undefined
      ) {
        this.setState({ titularidade: "Dependente" });
      } else if (
        resposta.data.acao_id !== null &&
        resposta.data.acao_id !== undefined
      ) {
        this.setState({ titularidade: "Titular" });
      } else {
        this.setState({ titularidade: "Pessoa sem ação" });
      }

      if (
        respAcao !== null &&
        respAcao.data.tipoTitulo !== null &&
        respAcao.data.tipoTitulo !== undefined &&
        respAcao.data.tipo_titulo.descricao === "ACESSO TEMPORARIO"
      ) {
        this.setState({ titularidade: "ACESSO TEMPORARIO" });
      }
    }

    const respPessoa = await api.post(
      "/pessoa/pessoaIdNome" + requisicoes.entidade,
      { id: resposta.data.id },
      requisicoes.header
    );

    let p = renomearChave(respPessoa.data, "id", "value");
    p = renomearChave(p, "nome", "label");
    this.setState({ pessoa: p });
    const usuario = await api.post(
      "/usuario/usuariosPorPessoa" + requisicoes.entidade,
      {
        id: v !== null ? v.value : event.value,
        entidade: window.localStorage.getItem("e_clube"),
      },
      requisicoes.header
    );
    this.setState({ listaUsuarios: usuario.data });

    if (
      this.props.permissoes.find((e) => e.nome === "alterar_meus_dados_alheios")
    ) {
      this.state.bloqueia = false;
    } else {
      this.state.bloqueia = true;
    }

    if (resposta.data !== null) {
      this.setState({
        infoPessoa: resposta.data,
        novoCpf: resposta.data.cpf,
        novoCnpj: resposta.data.cnpj,
        novoEmail: resposta.data.email,
        novoTelefone: resposta.data.telefone,
        novoCelular: resposta.data.celular,
        estudante: resposta.data.estudante,
        nova_entidade_ensino: resposta.data.entidade_ensino,
        nova_data_matricula: resposta.data.data_matricula,
        nova_datanascimento: resposta.data.datanascimento,
      });
      this.setState({
        enderecoCompleto:
          (this.state.infoPessoa.endereco ?? "Endereço não informado") +
          " - Bairro: " + (this.state.infoPessoa.bairro ?? "*") +
          (", Complemento: " + (this.state.infoPessoa.complemento ?? "N/A")),
        cidadeCompleto:
          (this.state.infoPessoa.cidade ?? "Cidade não informada") +
          " - " + (this.state.infoPessoa.estado ?? "*UF") +
          ", CEP: " + (this.state.infoPessoa.cep ?? "*"),
      });
    } else {
      this.setState({ infoPessoa: null });
      this.setState({
        novoCpf: null,
        novoCnpj: null,
        novoEmail: null,
        novoTelefone: null,
        novoCelular: null,
        estudante: false,
        nova_entidade_ensino: "",
        nova_data_matricula: "",
        nova_datanascimento: "",
      });
      this.setState({
        enderecoCompleto: null,
        cidadeCompleto: null,
      });
    }

    const reservaHorarioPessoa = await api.post(
      "/reservaHorario/horarioPorPessoa" + requisicoes.entidade,
      { id: resposta.data.id },
      requisicoes.header
    );
    const reservaHorarioLista = reservaHorarioPessoa.data;
    const respostaServico = await api.post(
      "/servico/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    const servicos = respostaServico.data;
    const listaRetorno = [];
    servicos.forEach((element) => {
      reservaHorarioLista.forEach((objeto) => {
        if (element.id === objeto.horario_servico.servico_id) {
          listaRetorno.push({
            nome: element.nome,
            id: element.id,
            reserva_id: objeto.id,
            dataReserva: objeto.dataReserva,
            horaInicio: objeto.horario_servico.horaInicio,
            horaFim: objeto.horario_servico.horaFim,
          });
        }
      });
    });

    function ordenaDatas(a, b) {
      if (a.dataReserva > b.dataReserva) {
        return 1;
      }
      if (a.dataReserva < b.dataReserva) {
        return -1;
      }
      return 0;
    }
    listaRetorno.sort(ordenaDatas);
    this.setState({ listaReservasUsuario: listaRetorno });
    const respExame = await api.post(
      "/exameMedico/examePorPessoa" + requisicoes.entidade,
      { id: resposta.data.id },
      requisicoes.header
    );
    let exames = respExame.data;
    if (respExame.data.length > 0) {
      const exameMedico = exames[exames.length - 1];
      this.setState({ exameMedico: exameMedico });
      this.setState({
        dataValidade: format(
          addDays(new Date(exameMedico.dataValidade), 1),
          "dd/MM/yyyy"
        ),
      });
    } else {
      this.setState({ exameMedico: null });
      this.setState({ dataValidade: null });
    }
    const respLancamentos = await api.post(
      "lancamentoConta/lancamentosAbertosPorPessoa" + requisicoes.entidade,
      { pessoa_id: resposta.data.id },
      requisicoes.header
    );
    // function ordenaDatasLancamentos(a, b) {
    //   if (a.data_vencimento !== null && b.data_vencimento !== null) {
    //     if (a.data_vencimento > b.data_vencimento) {
    //       if (a.data_vencimento === b.data_vencimento) {
    //         if (a.mes_referencia > b.mes_referencia) {
    //           return 1;
    //         }
    //       }
    //       return 1;
    //     }
    //     if (a.data_vencimento < b.data_vencimento) {
    //       if (a.data_vencimento === b.data_vencimento) {
    //         if (a.mes_referencia > b.mes_referencia) {
    //           return -1;
    //         }
    //       }
    //       return -1;
    //     }
    //     return 0;
    //   }
    // }
    let listaLancamentos = respLancamentos.data;
    // listaLancamentos.sort(ordenaDatasLancamentos);
    this.setState({ listaLancamentos: listaLancamentos });

    let valor = 0.0;
    for (let i = 0; i < listaLancamentos.length; i++) {
      const element = listaLancamentos[i];
      valor += parseFloat(element.valor);
    }
    this.setState({ total_lancamentos: valor });
    this.visualizaDebitoConta();
    this.setState({ isLoading: true });
  };

  pesquisaPessoa = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/pessoa/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaPessoa").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista.push(objeto);
      });
      this.setState({ options: lista });
    }, 200);
  };

  converterParaLabel = (objetoLista, prefixo, busca) => {
    if (busca) {
      busca = busca.toUpperCase();
    }
    let lista = [];
    function ordenaNomesBusca(a, b) {
      if (
        a.nome.toUpperCase().indexOf(busca) ===
        b.nome.toUpperCase().indexOf(busca)
      ) {
        return a.nome.toUpperCase() > b.nome.toUpperCase() ? 1 : -1;
      }
      if (
        a.nome.toUpperCase().indexOf(busca) >
        b.nome.toUpperCase().indexOf(busca)
      ) {
        return 1;
      }
      if (
        a.nome.toUpperCase().indexOf(busca) <
        b.nome.toUpperCase().indexOf(busca)
      ) {
        return -1;
      }
      return 0;
    }

    function ordenaNomes(a, b) {
      if (a.nome > b.nome) {
        return 1;
      }
      if (a.nome < b.nome) {
        return -1;
      }
      return 0;
    }

    if (busca) {
      objetoLista.sort(ordenaNomesBusca);
    } else {
      if (objetoLista.length > 0) {
        objetoLista.sort(ordenaNomes);
      }
    }

    for (let i = 0; i < objetoLista.length; i++) {
      const element = objetoLista[i];

      let objeto = renomearChave(element, "id", "value");

      if (element.acao !== undefined && element.acao !== null) {
        if (element.acao.codigo === null) {
          objeto.label = "";
          if (
            element.acao.tipo_titulo !== null &&
            element.acao.tipo_titulo.prefixo !== null
          ) {
            objeto.label = element.acao.tipo_titulo.prefixo + " ";
          }
          if (element.acao.codigo_auxiliar !== null) {
            for (
              let i = 0;
              i < 3 - String(objeto.acao.codigo_auxiliar).length;
              i++
            ) {
              objeto.label += "0";
            }

            objeto.label += objeto.acao.codigo_auxiliar + " - ";
          }
          objeto.label += objeto.nome;
        } else {
          objeto.label = ""
          if (element.acao.tipo_titulo.prefixo !== null) {
            objeto.label += element.acao.tipo_titulo.prefixo + " - "
          }
          objeto.label +=
            renomearChave(objeto.acao, "codigo", "label").label +
            " - " +
            objeto.nome;
        }
        if (element.titular_id !== null && element.titular_id !== undefined) {
          objeto.label += "    - Dependente";
        } else if (element.acao === undefined || element.acao === null) {
          objeto.label += "    - Sem ação";
        } else if (
          objeto.acao.tipo_titulo !== null &&
          objeto.acao.tipo_titulo.descricao === "ACESSO TEMPORARIO"
        ) {
          objeto.label += "    - ACESSO TEMPORARIO";
        } else {
          objeto.label += "    - Titular";
        }
        lista.push(objeto);
      } else {
        objeto.label = objeto.nome;
        lista.push(objeto);
      }
    }

    return lista;
  };
  converterParaLabelPessoa = (objetoLista, prefixo) => {
    let lista2 = [];
    objetoLista.forEach((element) => {
      if (element.acao !== undefined && element.acao !== null) {
        let objeto = renomearChave(element, "id", "value");
        if (element.acao.codigo === null && prefixo !== null) {
          objeto = renomearChave(objeto, "nome", "label");
          objeto.label = prefixo + " " + objeto.label;
        } else {
          objeto = renomearChave(objeto, "nome", "label");
        }
        if (element.titular_id !== null) {
          objeto.label += "    - Dependente";
        } else if (element.acao === undefined || element.acao === null) {
          objeto.label += "    - Sem ação";
        } else {
          objeto.label += "    - Titular";
        }
        lista2.push(objeto);
      } else {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista2.push(objeto);
      }
    });
    return lista2;
  };

  pesquisaPessoaAcao = async () => {
    if (buscando === true) {
      return;
    }
    this.state.options = []
    buscando = true;
    this.setState({ buscando: true });

    setTimeout(async () => {
      let term = document.getElementById("pesquisaPessoa");

      if (term === null) {
        buscando = false;
        this.setState({ buscando: false });
        return;
      }
      term = term.value;
      if (term.trim() === "") {
        buscando = false;
        this.setState({ buscando: false });
        return;
      }

      let prefixo = null;
      let tipoTituloBusca = null;
      for (let i = 0; i < this.state.listaTipoTitulo.length; i++) {
        const element = this.state.listaTipoTitulo[i];

        if (
          element.prefixo !== null &&
          term.toUpperCase().includes(element.prefixo) &&
          term.slice(0, element.prefixo.length).toUpperCase() ===
          element.prefixo
        ) {
          prefixo = element.prefixo.trim();
          tipoTituloBusca = element.id;
          break;
        }
      }

      // if (prefixo !== null) {
      //   term = term.slice(0,prefixo.length);
      //   term = term.trim();
      // }
      if (term.trim() === "") {
        buscando = false;
        this.setState({ buscando: false });
        return;
      }

      if (isFinite(String(term))) {
        await api
          .post(
            "/pessoa/pesquisaPorCodigoAcaoValorExato" + requisicoes.entidade,
            {
              pesquisa: term,
              tipo_titulo: tipoTituloBusca,
              flag_codigo_aux: tipoTituloBusca !== null,
              socio: this.state.checkboxSocio,
              ativo: this.state.checkboxAtivo,
            },
            requisicoes.header
          )
          .then((retorno) => {
              this.setState({
                options: this.converterParaLabel(retorno.data, prefixo),
              });
          });
      } else {
        await api
          .post(
            "/pessoa/pesquisa" + requisicoes.entidade,
            {
              pesquisa: term,
              tipo_titulo: tipoTituloBusca,
              socio: this.state.checkboxSocio,
              ativo: this.state.checkboxAtivo,
            },
            requisicoes.header
          )
          .then((retorno) => {
              this.setState({
                options: this.converterParaLabel(retorno.data, prefixo, term),
              });
          });
      }
      buscando = false;
      this.setState({ buscando: false });
    }, 500);
  };

  alteracaoCobranca = async (event) => {
    const element = event;
    if (element.target.value === "isento") {
      if (
        window.confirm(
          `Tem certeza que deseja alterar para Isento?\nTodos os lancamentos de mensalidade serão cancelados. `
        )
      ) {
        this.atualizaTipoCobrancaPessoa(element);
      }
    } else if (element.target.value === "boleto") {
      this.setState({ openModalSelecionarInstituicaoCobrancaBoleto: true });
    } else {
      this.atualizaTipoCobrancaPessoa(element);
    }
  };

  submit = async () => {
    var dadosUsuario = {};

    if (this.state.novaSenha !== "") {
      dadosUsuario = {
        login: this.state.novoLogin,
        senha: this.state.novaSenha,
      };
      this.setState({ senhaAtualizada: true });
    } else {
      dadosUsuario = {
        senha: null,
        login: this.state.novoLogin,
      };
    }
    const dadosPessoa = {
      telefone: this.state.novoTelefone,
      celular: this.state.novoCelular,
      email: this.state.novoEmail,
      cpf: this.state.novoCpf,
      cnpj: this.state.novoCnpj,
      estudante: this.state.estudante,
      entidade_ensino:
        this.state.nova_entidade_ensino !== ""
          ? this.state.nova_entidade_ensino
          : null,
      data_matricula:
        this.state.nova_data_matricula !== ""
          ? this.state.nova_data_matricula
          : null,
      datanascimento:
        this.state.nova_datanascimento !== ""
          ? this.state.nova_datanascimento
          : null,
      endereco: this.state.infoPessoa.endereco,
    };

    let documentoInvalido = false;
    if (this.state.infoPessoa.tipo === "fisico") {
      ValidadorCamposPessoa(dadosPessoa).forEach((e) => {
        Object.entries(e).forEach((k, i) => {
          if (k[1] === false) {
            alert("Documento Inválido");
            documentoInvalido = true;
            this.setState({ loading: false });
          }
        });
      });
    }
    if (documentoInvalido) {
      return;
    }

    await api.post(
      "/pessoa/alterarMeusDados" +
      requisicoes.entidade +
      "&id=" +
      this.state.pessoa_id,
      dadosPessoa,
      requisicoes.header
    );
    const usuario = await api.put(
      "/usuario/alterarMeusDados" +
      requisicoes.entidade +
      "&id=" +
      this.state.id,
      dadosUsuario,
      requisicoes.header
    );
    if (usuario.data === "usuario ja existe") {
      this.setState({ erroLoginExiste: true });
      this.setState({ loginNaoExiste: false });
    } else {
      this.setState({ erroLoginExiste: false });
      this.setState({ loginNaoExiste: true });
    }
    alert("Os dados foram alterados com sucesso!");
  };

  handleClose = () => {
    window.location.reload();
    this.setState({ open: false });
  };

  handleShow = () => this.setState({ open: true });

  visualizaDebitoConta = () => {
    if (this.props.permissoes.find((e) => e.nome === "alterar_tipo_cobranca")) {
      // var tipo_cobranca = document.getElementById("tipo_cobranca");
      let tipo_cobranca = this.state.infoPessoa.tipo_cobranca;
      if (tipo_cobranca !== null) {
        if (this.state.infoPessoa.debito_conta === null) {
          this.setState({ debito_conta: null });
        } else {
          let debito_conta = this.state.infoPessoa.debito_conta;
          if (debito_conta === undefined) {
            debito_conta = null;
          }
          if (debito_conta !== null && debito_conta.agencia_debito_conta) {
            debito_conta.agencia_debito_conta.value =
              debito_conta.agencia_debito_conta.id;
            debito_conta.agencia_debito_conta.label =
              debito_conta.agencia_debito_conta.agencia;
          }
          this.setState({ debito_conta: debito_conta });
        }
      }
    }
  };

  cancelarMatricula = async (matricula_id, lancamentos, pessoa_id) => {
    if (this.state.motivo_cancelamento === null) {
      alert("Favor Informar Um Motivo");
    } else {
      let lancamentosId = [];
      for (let i = 0; i < lancamentos.length; i++) {
        const element = lancamentos[i];
        if (element.checked === true) {
          lancamentosId.push(element.id);
        }
      }

      const retorno = await api.post(
        "/matriculaTurma/cancelar" + requisicoes.entidade,
        {
          matricula_id: matricula_id,
          motivo: this.state.motivo_cancelamento,
          lancamentos: lancamentosId,
        },
        requisicoes.header
      );

      if (retorno.data !== null) {
        window.location.reload();
      }
    }
  };

  cancelarReserva = async (event) => {
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    const responsavel_id = usuarioLogado.data.pessoa_id;
    if (this.state.motivo_cancelamento === null) {
      alert("Favor Informar Um Motivo");
    } else {
      const retorno = await api.post(
        "/reservaHorario/cancelar" + requisicoes.entidade,
        {
          id: event.target.value,
          motivo: this.state.motivo_cancelamento,
          responsavel_id: responsavel_id,
          plataforma: "web",
        },
        requisicoes.header
      );
      if (retorno.data !== null) {
        window.location.reload();
      }
    }
  };

  buscaTitulos = async () => {
    let retorno = "";
    if (this.state.tipoLancamento === "pagas") {
      retorno = await api.post(
        "/lancamentoConta/lancamentosPagosPorPessoa" + requisicoes.entidade,
        {
          pessoa_id: this.state.pessoa_id,
          ano_pagamento: this.state.anoLancamento,
        },
        requisicoes.header
      );
    }
    if (this.state.tipoLancamento === "vencidas") {
      retorno = await api.post(
        "/lancamentoConta/lancamentosVencidosPorPessoa" + requisicoes.entidade,
        {
          pessoa_id: this.state.pessoa_id,
        },
        requisicoes.header
      );
    }
    if (this.state.tipoLancamento === "abertas") {
      retorno = await api.post(
        "lancamentoConta/lancamentosAbertosPorPessoa" + requisicoes.entidade,
        {
          pessoa_id: this.state.pessoa_id,
        },
        requisicoes.header
      );
    }
    if (this.state.tipoLancamento === "cancelados") {
      retorno = await api.post(
        "/lancamentoConta/lancamentoCanceladoPorPessoa" + requisicoes.entidade,
        {
          pessoa_id: this.state.pessoa_id,
        },
        requisicoes.header
      );
    }
    this.setState({ listaLancamentos: retorno.data });
    if (retorno.data !== undefined) {
      let total = 0.0;
      for (let i = 0; i < retorno.data.length; i++) {
        const element = retorno.data[i];
        total += parseFloat(element.valor);
      }
      this.setState({ total_lancamentos: total });
    }
  };

  atualizaTipoCobrancaPessoa = async (event, instituicao_cobranca_id) => {
    const tipo_cobranca = event.target.value;
    if (this.state.infoPessoa !== null && this.state.infoPessoa.length !== 0) {
      var a = this.state.infoPessoa;
      a.tipo_cobranca = event.target.value;
      this.setState({ infoPessoa: a });
      // await api.post(
      //   "/pessoa/alterar" +
      //     requisicoes.entidade +
      //     "&id=" +
      //     this.state.infoPessoa.id,
      //   { tipo_cobranca: event.target.value },
      //   requisicoes.header
      // );

      await api.post(
        "/pessoa/alterarTipoCobranca" + requisicoes.entidade,
        {
          tipo_cobranca,
          pessoa_id: this.state.infoPessoa.id,
          instituicao_cobranca_id: instituicao_cobranca_id,
        },
        requisicoes.header
      );
      // this.buscaTitulos();
    }
  };

  atualizaDataIsencaoPessoa = async (event) => {
    if (moment(event.target.value).isValid()) {
      var a = this.state.infoPessoa;
      a.data_final_isencao = moment(event.target.value).format("YYYY-MM-DD");
      this.setState({ infoPessoa: a });
      if (this.state.infoPessoa.length !== 0) {
        await api.post(
          "/pessoa/alterar" +
          requisicoes.entidade +
          "&id=" +
          this.state.infoPessoa.id,
          {
            data_final_isencao: moment(event.target.value).format("YYYY-MM-DD"),
          },
          requisicoes.header
        );
      }
    }
  };

  comparaDatas(data) {
    return new Date(data) >= new Date() ? true : false;
  }

  carregaListaConvidados = async (id) => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/presencaReserva/presencaReservaPorHorario" + requisicoes.entidade,
        { id: id },
        requisicoes.header
      );
      this.setState({ listaPresencaReservas: retorno.data });
    }, 500);
  };

  confirmaPresenca = async (presenca, id) => {
    setTimeout(async () => {
      await api.post(
        "/presencaReserva/confirmarPresenca" + requisicoes.entidade,
        { presenca: presenca },
        requisicoes.header
      );
    }, 500);
    this.carregaListaConvidados(id);
  };

  salvarObservacao = async () => {
    await api.post(
      "/pessoa/alterar" +
      requisicoes.entidade +
      "&id=" +
      this.state.infoPessoa.id,
      { observacao: this.state.observacao },
      requisicoes.header
    );
    this.mostraMensagem("Observação Salva");
  };

  mostraMensagem = async (msg) => {
    this.setState({ mensagem: msg });
    setTimeout(async () => {
      this.setState({ mensagem: null });
    }, 500);
  };

  handleModal = async () => {
    this.setState({ open_modal: true, size: "tiny" });
    this.visualizacaoFoto();
  };

  handleModalClose = async () => {
    this.setState({ open_modal: false });
  };

  onChangeCheckboxEstudante = async (evt, data) => {
    if (data.checked === false) {
      this.setState({
        nova_entidade_ensino: "",
        nova_data_matricula: "",
      });
    }
    this.setState({ estudante: data.checked });
  };

  alterarUsuario = (event, i) => {
    let listaUser = this.state.listaUsuarios;

    if (event === "alterado") {
      for (let i = 0; i < listaUser.length; i++) {
        const element = listaUser[i];
        element.alterado = false;
      }
      this.setState({ listaUsuarios: listaUser });
      return;
    }

    if (event.target.name === "novaSenha") {
      listaUser[i].senha = event.target.value;
    }
    if (event.target.name === "novoLogin") {
      listaUser[i].login = event.target.value;
    }

    listaUser[i].alterado = true;
    this.setState({ listaUsuarios: listaUser });
  };

  render() {
    return (
      <div className="pagina">
        {this.state.isLoading ? (
          <div>
            {this.props.permissoes.find(
              (e) => e.nome === "visualizar_meus_dados_alheios"
            ) && (
                <Segment.Group raised>
                  <Segment>
                    <Row>
                      <Col sm={8}>
                        <Form.Label>Pessoa</Form.Label>
                        <Row>
                          <Col sm={12}>
                            <Autocomplete
                              id="pesquisaPessoa"
                              value={this.state.pessoa}
                              inputValue={this.state.inputValue}
                              onInputChange={(event, newInputValue) => {
                                this.setState({ inputValue: newInputValue });
                              }}
                              options={this.state.options}
                              getOptionLabel={(option) => option.label}
                              onKeyUp={this.pesquisaPessoaAcao}
                              onChange={this.alteracaoSelectPessoa}
                              filterSelectedOptions={false}
                              filterOptions={(options, state) => options}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Busca"
                                  size="small"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {this.state.buscando ? (
                                          <CircularProgress
                                            color="inherit"
                                            size={20}
                                            style={{
                                              marginRight: "0.4ch",
                                            }}
                                          />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={1}>
                        <Checkbox
                          label="Sócio"
                          checked={this.state.checkboxSocio}
                          onChange={(e, a) =>
                            this.setState({ checkboxSocio: a.checked })
                          }
                        />
                        <Checkbox
                          label="Ativo"
                          checked={this.state.checkboxAtivo}
                          onChange={(e, a) =>
                            this.setState({ checkboxAtivo: a.checked })
                          }
                        />
                      </Col>
                      <Col
                        sm={3}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        onMouseEnter={() =>
                          this.setState({ mouseEnterIdPessoa: true })
                        }
                        onMouseLeave={() =>
                          this.setState({ mouseEnterIdPessoa: false })
                        }
                      >
                        <h5
                          style={
                            this.state.mouseEnterIdPessoa
                              ? { color: "black" }
                              : { color: "#d3d3d3" }
                          }
                        >
                          {"ID: " + this.state.infoPessoa.id}
                        </h5>
                      </Col>
                    </Row>
                  </Segment>
                </Segment.Group>
              )}
            <Segment.Group raised>
              <Segment>
                <Header as="h2">
                  <Row style={{ marginTop: "1%" }}>
                    {this.state.infoPessoa.id !== undefined &&
                      this.props.permissoes.find(
                        (e) => e.nome === "imprimir_carteirinha"
                      ) && (
                        <>
                          <ModalCarteirinhaPadrao
                            screenshot={this.state.screenshot}
                            infoPessoa={this.state.infoPessoa}
                            pessoa_id={this.state.pessoa_id}
                            visualizacaoFoto={this.visualizacaoFoto}
                            cargos={this.state.cargos}
                          />
                          <ModalCarteirinha
                            screenshot={this.state.screenshot}
                            infoPessoa={this.state.infoPessoa}
                            pessoa_id={this.state.pessoa_id}
                            visualizacaoFoto={this.visualizacaoFoto}
                          />
                        </>
                      )}
                    {this.props.menus.find(
                      (e) => e.url === "/lancamentoCaixa"
                    ) && (
                        <Link
                          to={{
                            pathname: "/lancamentoCaixa",
                            query: {
                              select: { value: "venda", label: "Vendas" },
                              pessoa: this.state.pessoa,
                            },
                          }}
                          style={{
                            marginLeft: "1%",
                            paddingTop: "0px",
                            marginTop: "0px",
                          }}
                        >
                          <Button
                            content="Vendas"
                            icon="shopping basket"
                            color="blue"
                            size="tiny"
                            style={{ height: "100%", marginTop: "0px" }}
                          ></Button>
                        </Link>
                      )}

                    {
                      <ModalMatricula
                        matriculas={this.state.matriculas}
                        alteracao={this.alteracao}
                        cancelarMatricula={this.cancelarMatricula}
                        dependentes={this.state.dependentes}
                        matriculasDependentes={this.state.matriculasDependentes}
                        lista_anos={this.state.lista_anos}
                        permissoes={this.props.permissoes}
                      />
                    }

                    {
                      <ModalReservas
                        reservas={this.state.reservas}
                        alteracao={this.alteracao}
                        cancelarReserva={this.cancelarReserva}
                        permissoes={this.props.permissoes}
                      />
                    }

                    {this.state.infoPessoa.titular_id !== null && (
                      <ModalEstudante
                        alteracao={this.alteracao}
                        onChangeCheckboxEstudante={
                          this.onChangeCheckboxEstudante
                        }
                        estudante={this.state.estudante}
                        nova_entidade_ensino={this.state.nova_entidade_ensino}
                        nova_data_matricula={this.state.nova_data_matricula}
                        bloqueia={this.state.bloqueia}
                      />
                    )}

                    <ListaUsuarios
                      listaUsuarios={this.state.listaUsuarios}
                      erroLoginExiste={this.state.erroLoginExiste}
                      loginNaoExiste={this.state.loginNaoExiste}
                      bloqueia={
                        this.state.pessoa_logada_id === this.state.infoPessoa.id
                          ? false
                          : this.state.bloqueia
                      }
                      alteracao={this.alteracao}
                      senhaAtualizada={this.state.senhaAtualizada}
                      alterarUsuario={this.alterarUsuario}
                    />
                    <ModalFoto
                      pessoa_logada_id={this.state.pessoa_logada_id}
                      pessoa={this.state.infoPessoa}
                      permissoes={this.props.permissoes}
                    />

                    {this.props.permissoes.find(
                      (e) => e.nome === "cadastrar_rfid"
                    ) && <ModalRfid pessoa={this.state.infoPessoa} />}

                    {this.props.permissoes.find(
                      (e) => e.nome === "cadastrar_digital"
                    ) && <ModalDigital />}

                    {(this.state.novoEmail !== this.state.infoPessoa.email ||
                      this.state.novoLogin !== this.state.login ||
                      this.state.novoTelefone !==
                      this.state.infoPessoa.telefone ||
                      this.state.novoCelular !==
                      this.state.infoPessoa.celular ||
                      this.state.novaSenha !== "" ||
                      this.state.nova_data_matricula !==
                      this.state.infoPessoa.data_matricula ||
                      this.state.nova_entidade_ensino !==
                      this.state.infoPessoa.entidade_ensino ||
                      this.state.nova_datanascimento !==
                      this.state.infoPessoa.datanascimento ||
                      this.state.infoPessoa.cpf !== this.state.novoCpf ||
                      this.state.infoPessoa.cnpj !== this.state.novoCnpj) &&
                      this.props.permissoes.find(
                        (e) => e.nome === "alterar_meus_dados_alheios"
                      ) && (
                        <Button
                          content="Salvar"
                          color="blue"
                          size="tiny"
                          floated="right"
                          icon="print"
                          onClick={this.submit}
                        ></Button>
                      )}
                  </Row>
                </Header>
              </Segment>

              {this.state.cargos && this.state.cargos.length > 0 && (
                <Segment>
                  <Cargos cargos={this.state.cargos} />
                </Segment>
              )}
              <Segment>
                <AcaoTitulo
                  permissoes={this.props.permissoes}
                  acao={this.state.acao}
                  listaTipoTitulo={this.state.listaTipoTitulo}
                  handleShowAlteracaoTipoTitulo={
                    this.handleShowAlteracaoTipoTitulo
                  }
                  infoPessoa={this.state.infoPessoa}
                  titular={this.state.titular}
                  pessoa={this.state.infoPessoa}
                />
              </Segment>
              <Segment>
                <Header as="h3">Dados</Header>
                <Row>
                  {this.state.listaUsuarios.length > 0 &&
                    this.state.listaUsuarios.map((e, i) => (
                      <>
                        {e.tipo_usuario === "funcionario" && (
                          <Col sm={12}>
                            <strong>{"Funcionário: "}</strong>
                            {e.funcao}
                          </Col>
                        )}
                      </>
                    ))}

                  {this.state.mensagem !== null && (
                    <Col sm={12}>
                      <Alert variant="success" style={{ marginTop: "10px" }}>
                        {this.state.mensagem}
                      </Alert>
                    </Col>
                  )}

                  <Col sm={6}>
                    <Form.Label>Pessoa</Form.Label>
                    <Select
                      value={this.state.pessoa}
                      options={this.state.options}
                      isDisabled={this.state.id !== null}
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Label>Titularidade</Form.Label>
                    <Form.Control
                      placeholder="Tipo"
                      //as="select"
                      name="tipo_usuario"
                      // checked={this.state.selectTipo}
                      value={this.state.titularidade}
                      disabled={true}
                    ></Form.Control>
                  </Col>
                </Row>

                {this.state.infoPessoa !== null && (
                  <Row>
                    <Col sm={3}>
                      <Form.Label>Telefone</Form.Label>
                      <FormControl
                        onChange={this.alteracao}
                        aria-label="Telefone"
                        name="novoTelefone"
                        aria-describedby="basic-addon1"
                        value={
                          this.state.novoTelefone !== null
                            ? this.state.novoTelefone
                            : ""
                        }
                        disabled={this.state.bloqueia}
                      />
                    </Col>
                    <Col sm={3}>
                      <Form.Label>Celular</Form.Label>
                      <FormControl
                        onChange={this.alteracao}
                        aria-label="Celular"
                        name="novoCelular"
                        aria-describedby="basic-addon1"
                        value={
                          this.state.novoCelular !== null
                            ? this.state.novoCelular
                            : ""
                        }
                        disabled={this.state.bloqueia}
                      />
                    </Col>
                    <Col sm={6}>
                      <Form.Label>E-mail</Form.Label>
                      <FormControl
                        onChange={this.alteracao}
                        aria-label="email"
                        name="novoEmail"
                        aria-describedby="basic-addon1"
                        value={
                          this.state.novoEmail !== null &&
                            this.state.novoEmail !== undefined
                            ? this.state.novoEmail
                            : ""
                        }
                        disabled={this.state.bloqueia}
                      />
                    </Col>
                    {this.state.infoPessoa.tipo === "fisico" && (
                      <Col sm={3}>
                        <Form.Label>CPF</Form.Label>
                        <InputMask
                          mask="999.999.999-99"
                          className="form-control"
                          placeholder="CPF"
                          id="novoCpf"
                          name="novoCpf"
                          onChange={this.alteracao}
                          value={this.state.novoCpf}
                          disabled={this.state.bloqueia}
                        />
                      </Col>
                    )}
                    {this.state.infoPessoa.tipo === "juridico" && (
                      <Col sm={3}>
                        <Form.Label>CNPJ</Form.Label>
                        <InputMask
                          mask="99.999.999/9999-99"
                          className="form-control"
                          placeholder="CNPJ"
                          id="novoCnpj"
                          name="novoCnpj"
                          onChange={this.alteracao}
                          value={this.state.novoCnpj}
                        />
                      </Col>
                    )}

                    {this.state.infoPessoa.tipo !== "fisico" &&
                      this.state.infoPessoa.tipo !== "juridico" && (
                        <Col sm={3}>
                          <Form.Label>Tipo</Form.Label>
                          <FormControl
                            aria-label="Tipo"
                            aria-describedby="basic-addon1"
                            disabled={true}
                            value={
                              this.state.infoPessoa.tipo !== null
                                ? this.state.infoPessoa.tipo
                                : ""
                            }
                          />
                        </Col>
                      )}
                    <Col sm={3}>
                      <Form.Label>Data de Nascimento</Form.Label>
                      <FormControl
                        aria-label="Data de Nascimento"
                        disabled={this.state.bloqueia}
                        type="date"
                        name="nova_datanascimento"
                        onChange={this.alteracao}
                        value={
                          this.state.nova_datanascimento !== ""
                            ? this.state.nova_datanascimento
                            : ""
                        }
                      />
                    </Col>
                    <Col sm={6}>
                      <Form.Label>Cidade</Form.Label>
                      <FormControl
                        aria-label="Cidade"
                        aria-describedby="basic-addon1"
                        disabled={this.state.bloqueia}
                        value={
                          this.state.cidadeCompleto !== null
                            ? this.state.cidadeCompleto
                            : ""
                        }
                      />
                    </Col>
                    <Col sm={12}>
                      <Form.Label>Endereço</Form.Label>
                      <FormControl
                        aria-label="Endereco"
                        aria-describedby="basic-addon1"
                        disabled={this.state.bloqueia}
                        value={
                          this.state.enderecoCompleto !== null
                            ? this.state.enderecoCompleto
                            : ""
                        }
                      />
                    </Col>
                    {this.props.permissoes.find(
                      (e) => e.nome === "alterar_meus_dados_alheios"
                    ) && (
                        <Col sm={12}>
                          <Form.Label>Observação</Form.Label>
                          <FormControl
                            placeholder="Observação"
                            aria-describedby="basic-addon1"
                            as="textarea"
                            name="observacao"
                            onChange={this.alteracao}
                            value={
                              this.state.infoPessoa !== null
                                ? this.state.observacao !== null
                                  ? this.state.observacao
                                  : this.state.infoPessoa.observacao
                                : ""
                            }
                          />
                          <Button
                            primary
                            style={{ float: "right", marginTop: "10px" }}
                            hidden={this.state.observacao === null}
                            onClick={this.salvarObservacao}
                            type="button"
                          >
                            Salvar Observação
                          </Button>
                        </Col>
                      )}
                  </Row>
                )}
              </Segment>
              <Segment>
                <Row>
                  <Col sm={6}>
                    <Header as="h3">Exame Médico</Header>
                    <Row>
                      <Col sm={6}>
                        <Form.Label>Data Validade</Form.Label>
                        <FormControl
                          type="date"
                          aria-describedby="basic-addon1"
                          disabled={true}
                          value={
                            this.state.exameMedico !== null &&
                            this.state.exameMedico.dataValidade
                          }
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Label>Autorização Médica</Form.Label>

                        {this.state.exameMedico !== null &&
                          this.comparaDatas(
                            this.state.exameMedico.dataValidade
                          ) ? (
                          <Button fluid icon="thumbs up" positive></Button>
                        ) : (
                          <Button fluid icon="thumbs down" negative></Button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={6}>
                    <Header as="h3">Exame Físico</Header>
                    <Row>
                      <Col sm={6}>
                        <Form.Label>Data Validade</Form.Label>
                        <FormControl
                          type="date"
                          aria-describedby="basic-addon1"
                          disabled={true}
                          value={
                            this.state.exames_fisico.length > 0 &&
                            this.state.exames_fisico[0] !== null &&
                            this.state.exames_fisico[0].dataValidade
                          }
                        />
                      </Col>
                      <Col sm={6}>
                        <Form.Label>Autorização Médica</Form.Label>

                        {this.state.exames_fisico.length > 0 &&
                          this.state.exames_fisico[0] !== null &&
                          this.comparaDatas(
                            this.state.exames_fisico[0].dataValidade
                          ) ? (
                          <Button fluid icon="thumbs up" positive></Button>
                        ) : (
                          <Button fluid icon="thumbs down" negative></Button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Segment>

              {
                <Segment>
                  <Row>
                    <Col sm={3}>
                      <Form.Label>Forma Pagamento Mensalidade</Form.Label>
                      {
                        <Form.Control
                          as="select"
                          id="tipo_cobranca"
                          onChange={this.alteracaoCobranca}
                          value={this.state.infoPessoa.tipo_cobranca}
                          disabled={
                            !this.props.permissoes.find(
                              (e) => e.nome === "alterar_tipo_cobranca"
                            )
                          }
                        >
                          <option></option>
                          <option value="boleto">Boleto</option>
                          <option value="debito_em_conta">
                            Debito em Conta
                          </option>
                          <option value="isento">Isento</option>
                        </Form.Control>
                      }
                    </Col>
                    {this.state.infoPessoa.tipo_cobranca === "isento" && (
                      <Col sm={3}>
                        <Form.Label>Data Final Isenção</Form.Label>
                        <Form.Control
                          placeholder="Data Final"
                          aria-describedby="basic-addon1"
                          type="date"
                          name="data_final_isencao"
                          defaultValue={
                            this.state.infoPessoa.data_final_isencao
                          }
                          onChange={this.atualizaDataIsencaoPessoa}
                          disabled={
                            !this.props.permissoes.find(
                              (e) => e.nome === "alterar_tipo_cobranca"
                            )
                          }
                        />
                      </Col>
                    )}

                    {(this.props.permissoes.find(
                      (e) => e.nome === "alterar_tipo_cobranca"
                    ) &&
                      this.state.infoPessoa.tipo_cobranca ===
                      "debito_em_conta") ||
                      this.state.infoPessoa.tipo_cobranca_servico ===
                      "debito_em_conta" ? (
                      <Col
                        sm={1}
                        style={{ display: "flex", alignItems: "flex-end" }}
                      >
                        <ModalConta
                          alteracaoDebitoConta={this.alteracaoDebitoConta}
                          pessoa={this.state.infoPessoa}
                          debito_conta={this.state.debito_conta} // vem daqui
                          handleCloseConta={this.handleCloseConta}
                          open_conta={this.state.open_conta}
                        />
                      </Col>
                    ) : null}

                    <Col sm={3}>
                      <Form.Label>Forma Pagamento Serviços</Form.Label>
                      <Form.Control
                        as="select"
                        id="tipo_cobranca"
                        onChange={async (event) => {
                          let a = this.state.infoPessoa;
                          a.tipo_cobranca_servico = event.target.value;
                          this.setState({ infoPessoa: a });
                          await api.post(
                            "/pessoa/alterar" +
                            requisicoes.entidade +
                            "&id=" +
                            this.state.infoPessoa.id,
                            { tipo_cobranca_servico: event.target.value },
                            requisicoes.header
                          );
                        }}
                        value={this.state.infoPessoa.tipo_cobranca_servico}
                        disabled={
                          !this.props.permissoes.find(
                            (e) => e.nome === "alterar_tipo_cobranca"
                          )
                        }
                      >
                        <option></option>
                        <option value="caixa">Pagamento no caixa</option>
                        <option value="boleto">Boleto</option>
                        <option value="debito_em_conta">Debito em Conta</option>
                      </Form.Control>
                    </Col>

                    {/* ---------------------------------------------------- */}
                    {/* <Col sm={this.state.tipoLancamento === "pagas" ? 2 : 4}>
                      <Form.Control
                        placeholder="Tipo"
                        label="Tipo"
                        id="tipoLancamento"
                        as="select"
                        name="tipoLancamento"
                        onChange={this.alteracao}
                        required
                      >
                        <option value="abertas">Abertos</option>
                        <option value="cancelados">Canceladas</option>
                        <option value="pagas">Pagos</option>
                        <option value="vencidas">Vencidos</option>
                      </Form.Control>
                    </Col>
                    {this.state.tipoLancamento === "pagas" ? (
                      <Col>
                        <Form.Control
                          placeholder="Ano"
                          label="Ano"
                          id="anoLancamento"
                          as="select"
                          name="anoLancamento"
                          onChange={this.alteracao}
                          defaultValue={this.state.anoLancamento}
                          required
                        >
                          {this.state.lista_anos.map((e, i) => (
                            <option value={e}>{e}</option>
                          ))}
                        </Form.Control>
                      </Col>
                    ) : null}
                    <Col sm={1}>
                      <Button
                        primary
                        size="tiny"
                        floated="right"
                        onClick={this.buscaTitulos}
                      >
                        <Icon name="search"></Icon>
                      </Button>
                    </Col> */}
                  </Row>
                  <Divider />
                  {
                    <ListaLancamentos
                      permissoes={this.props.permissoes}
                      listaLancamentos={this.state.listaLancamentos}
                      comparaDatas={this.comparaDatas}
                      total_lancamentos={this.state.total_lancamentos}
                      tipoLancamento={this.state.tipoLancamento}
                      novoEmail={this.state.novoEmail}
                      pessoa={this.state.infoPessoa}
                    />
                  }
                </Segment>
              }

              {
                <Segment>
                  <ListaDependentes
                    permissoes={this.props.permissoes}
                    dependentes={this.state.dependentes}
                    pessoa={this.state.infoPessoa}
                    handleOpenDependentes={this.handleOpenDependentes}
                    handleShowRemoveDependentes={
                      this.handleShowRemoveDependentes
                    }
                    alteracaoSelectPessoa={this.alteracaoSelectPessoa}
                  />
                  {this.props.permissoes.find(
                    (e) => e.nome === "adicionar_dependentes"
                  ) && (
                      <>
                        <ModalDependente
                          open_modal_dependentes={
                            this.state.open_modal_dependentes
                          }
                          handleCloseDependentes={this.handleCloseDependentes}
                          adicionaDependentes={this.adicionaDependentes}
                          titular_id={this.state.pessoa_id}
                          numero_titulo={this.state.numero_titulo}
                          tipoTitulo={this.state.tipoTitulo}
                          listaUsuariosDependente={
                            this.state.listaUsuariosDependente
                          }
                          acao_id={
                            this.state.acao !== null ? this.state.acao.id : null
                          }
                        />
                        <ModalRemoveDependentes
                          open_modal_remove_dependentes={
                            this.state.open_modal_remove_dependentes
                          }
                          handleShowRemoveDependentes={
                            this.handleShowRemoveDependentes
                          }
                          handleCloseRemoveDependentes={
                            this.handleCloseRemoveDependentes
                          }
                          removeDependentes={this.removeDependentes}
                          alteracao={this.alteracao}
                          listaUsuariosDependente={
                            this.state.listaUsuariosDependente
                          }
                          removeUsuarioDependente={this.removeUsuarioDependente}
                        />
                      </>
                    )}
                </Segment>
              }
            </Segment.Group>

            {this.props.permissoes.find(
              (e) => e.nome === "alterar_tipo_cobranca"
            ) && (
                <ModalSelecionarInstituicaoCobrancaBoleto
                  open={this.state.openModalSelecionarInstituicaoCobrancaBoleto}
                  onClose={() =>
                    this.setState({
                      openModalSelecionarInstituicaoCobrancaBoleto: false,
                    })
                  }
                  alterarTipoCobranca={(instituicao_cobranca_id) =>
                    this.atualizaTipoCobrancaPessoa(
                      { target: { value: "boleto" } },
                      instituicao_cobranca_id
                    )
                  }
                />
              )}
          </div>
        ) : (
          <Dimmer active inverted>
            <Loader content="Carregando..." />
          </Dimmer>
        )}
      </div>
    );
  }
}
