import { Component } from "react";
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "semantic-ui-react";

export default class ModalRecusar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Modal
        open={this.props.modalOpenCancelar}
        onClose={this.props.cancelando ? null : this.props.fechaModalCancelar}
      >
        <ModalHeader>Recusar o termo de consentimento</ModalHeader>
        <ModalContent>
          <p>
            Ao declarar que não aceita o termo de consentimento, você como
            usuário não poderá utilizar o sistema.
          </p>
          <h4>Tem certeza que deseja recusar os termos?</h4>
        </ModalContent>
        <ModalActions>
          <Button
            onClick={this.props.fechaModalCancelar}
            disabled={this.props.recusando}
          >
            Voltar
          </Button>
          <Button
            color="red"
            onClick={this.props.recusar}
            disabled={this.props.recusando}
            loading={this.props.recusando}
          >
            Sim
          </Button>
        </ModalActions>
      </Modal>
    );
  }
}
