import React, { Component } from "react";
import {
  Table,
  Button,
  Popup,
  Search,
  Pagination,
  Input,
  Checkbox,
  Select,
  FormField,
} from "semantic-ui-react";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";
import { Col, Row } from "react-bootstrap";
import moment from "moment";

export default class ItemLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      pagina: 1,
      totalPaginas: 1,
      popUpPesquisa: false,
      pesquisando: false,
      open: false,
      item: null,
      sortedName: "descricao",
      sortDirection: "ascending",
      dataBusca: moment().format("YYYY-MM-DD"),
      dataInvalida: false,
      loadingData: false,
      valorTotalItens: 0,
      valorTotalMedioItens: 0,
      checkedSaldoPositivo: false,
      pesquisa: "",
      filtroPreco: "ultima",
    };
    // this.listaItem();
    this.listaData();
  }

  pesquisaItem = async (event) => {
    this.setState({ pesquisando: true });
    let pesquisa = event.target.value;
    this.setState({ pesquisa });
    this.setState({ popUpPesquisa: false });
    if (pesquisa === "") {
      this.listaData();
      // if(this.state.dataBusca === moment().format("YYYY-MM-DD")){
      //   this.listaItem();
      // } else {
      //   this.listaData();
      // }
    } else {
      // if (this.state.dataBusca === moment().format("YYYY-MM-DD")) {
      //   await api
      //     .post(
      //       "/item/pesquisa" + requisicoes.entidade,
      //       { pesquisa: pesquisa },
      //       requisicoes.header
      //     )
      //     .then((resp) => {
      //       if (resp.data.length === 0) {
      //         this.setState({ popUpPesquisa: true });
      //       }
      //       this.setState({ lista: resp.data });
      //     });
      // } else {
      this.listaData(
        undefined,
        { activePage: 1 },
        this.state.dataBusca,
        pesquisa
      );
      // }
    }
    this.setState({ pesquisando: false });
  };

  listaItem = async (a, data) => {
    if (data === undefined || data === null) {
      data = {};
      data.activePage = 1;
    }
    const resposta = await api.post(
      "/item/listaRelatorio" +
        requisicoes.entidade +
        "&pagina=" +
        data.activePage,
      {
        sortDirection: this.state.sortDirection,
        sortedName: this.state.sortedName,
      },
      requisicoes.header
    );
    const { itens, pagina, totalPaginas } = resposta.data;
    this.setState({ lista: itens, pagina, totalPaginas });

    let total = 0.0;
    let totalMedio = 0.0;

    for (let i = 0; i < itens.length; i++) {
      const element = itens[i];
      total += parseFloat(
        parseFloat(element.valor_unidade) * parseFloat(element.quantidade)
      );
      totalMedio += parseFloat(
        parseFloat(element.valor_medio) * parseFloat(element.quantidade)
      );
    }
    this.setState({
      valorTotalItens: total,
      valorTotalMedioItens: totalMedio,
    });
  };

  sortTableClick = async (sortedName) => {
    this.state.sortedName = sortedName;
    this.state.sortDirection =
      this.state.sortDirection === "ascending" ? "descending" : "ascending";

    this.listaData();
    // if(this.state.dataBusca === moment().format("YYYY-MM-DD")){
    //   this.listaItem();
    // } else {
    //   this.listaData();
    // }
  };

  imprimirListaItens = async () => {
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    const nomePessoa = await api.post(
      "/pessoa/pessoaIdNome" + requisicoes.entidade,
      {
        id: usuarioLogado.data.pessoa_id,
      },
      requisicoes.header
    );

    await api.post(
      "/item/relatorioListaItens" + requisicoes.entidade + "&pagina=" + 0,
      {
        userId: usuarioLogado.data.id,
        sortDirection: this.state.sortDirection,
        sortedName: this.state.sortedName,
        data: this.state.dataBusca,
        saldoPositivo: this.state.checkedSaldoPositivo,
        filtroPreco: this.state.filtroPreco,
      },
      requisicoes.header
    );
  };

  arquivoListaItens = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");

    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    setTimeout(async () => {
      const r = await api.get(
        "/item/arquivoRelatorioListaItens" +
          requisicoes.entidade +
          "&t=Baer " +
          token +
          "&userId=" +
          usuarioLogado.data.id
      );
      var oReq = new XMLHttpRequest();

      var URLToPDF =
        r.config.baseURL +
        "item/arquivoRelatorioListaItens" +
        requisicoes.entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.id;

      oReq.open("GET", URLToPDF, true);
      oReq.responseType = "blob";
      oReq.onload = function () {
        const file = new Blob([oReq.response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      };
      oReq.send();
    }, 500);
  };

  imprimirButtomClick = async () => {
    await this.imprimirListaItens();
    await this.arquivoListaItens();
  };
  CSVButtonClick = async () => {
    const r = await api.post(
      "/item/csvRelatorioListaItens" + requisicoes.entidade + "&pagina=" + 0,
      {
        sortDirection: this.state.sortDirection,
        sortedName: this.state.sortedName,
        data: this.state.dataBusca,
        saldoPositivo: this.state.checkedSaldoPositivo,
        filtroPreco: this.state.filtroPreco,
      },
      requisicoes.header
    );
    const url = window.URL.createObjectURL(new Blob([r.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "inventario.csv");
    document.body.appendChild(link);
    link.click();
  };

  XSLXButtonClick = async () => {
    const r = await api.post(
      "/item/xlsxRelatorioListaItens" + requisicoes.entidade + "&pagina=" + 0,
      {
        sortDirection: this.state.sortDirection,
        sortedName: this.state.sortedName,
        data: this.state.dataBusca,
        saldoPositivo: this.state.checkedSaldoPositivo,
        filtroPreco: this.state.filtroPreco,
      },
      {
        headers: {
          token: "Baer " + window.localStorage.getItem("token_clube"),
        },
        responseType: "arraybuffer", //Sem isso aqui não funciona o xlsx!!!
      }
    );
    const url = window.URL.createObjectURL(new Blob([r.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "inventario.xlsx");
    document.body.appendChild(link);
    link.click();
  };
  listaData = async (a, data, dataBusca, pesquisa, saldoPositivo) => {
    if (data === undefined || data === null) {
      data = {};
      data.activePage = 1;
    }
    const resposta = await api.post(
      "/item/listaData" + requisicoes.entidade + "&pagina=" + data.activePage,
      {
        sortDirection: this.state.sortDirection,
        sortedName: this.state.sortedName,
        data: dataBusca || this.state.dataBusca,
        pesquisa: pesquisa || this.state.pesquisa,
        saldoPositivo: saldoPositivo || this.state.checkedSaldoPositivo,
      },
      requisicoes.header
    );
    const { itens, pagina, totalPaginas } = resposta.data;
    this.setState({
      lista: itens,
      pagina: parseInt(pagina),
      totalPaginas,
    });

    let total = 0.0;
    let totalMedio = 0.0;

    for (let i = 0; i < itens.length; i++) {
      const element = itens[i];
      total += parseFloat(
        parseFloat(element.valor_unidade) * parseFloat(element.quantidade)
      );
      totalMedio += parseFloat(
        parseFloat(element.valor_medio) * parseFloat(element.quantidade)
      );
    }

    // this.setState({
    //   valorTotalItens: total,
    //   valorTotalMedioItens: totalMedio,
    // });
    this.setState({
      valorTotalItens: resposta.data.totais.totalUltimaCompra,
      valorTotalMedioItens: resposta.data.totais.totalPrecoMedio,
    });
  };

  changeDate = async (e, a) => {
    if (moment(a.value).isValid()) {
      this.setState({ dataInvalida: false });
      if (moment(a.value).year() < 2000) return;

      this.setState({ loadingData: true });

      await this.listaData(undefined, undefined, a.value);
      // if(a.value === moment().format("YYYY-MM-DD")){
      //   await this.listaItem();
      // } else {
      //   await this.listaData(undefined, undefined, a.value);
      // }

      this.setState({ loadingData: false });
    } else {
      this.setState({ dataInvalida: true });
    }
  };
  render() {
    return (
      <div className="lista" style={{ paddingBottom: 100 }}>
        <Row>
          <Col sm={4}>
            <Popup
              content="Nenhum registro encontrado!"
              open={this.state.popUpPesquisa}
              position="right center"
              trigger={
                <Search
                  loading={this.state.pesquisando}
                  fluid
                  onSearchChange={this.pesquisaItem}
                  showNoResults={false}
                  input={{
                    icon: "search",
                    iconPosition: "left",
                    style: { width: "100%" },
                    input: { style: { borderRadius: "3%" } },
                  }}
                  placeholder="Digite para buscar"
                />
              }
            />
          </Col>
          <Col md="auto">
            <Input
              type="date"
              error={this.state.dataInvalida}
              // onChange={this.changeDate}
              loading={this.state.loadingData}
              defaultValue={this.state.dataBusca}
              onKeyUp={(e) => {
                this.setState({ dataBusca: e.target.value });
              }}
              onChange={(e) => {
                this.setState({ dataBusca: e.target.value });
              }}
              onBlur={(e) => this.listaData()}
            />
          </Col>
          <Col md="auto" style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              label="Saldo positivo"
              checked={this.state.checkedSaldoPositivo}
              onChange={(e, a) => {
                this.setState({ checkedSaldoPositivo: a.checked });
                this.listaData(
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  a.checked
                );
              }}
            />
          </Col>
          <Col md="auto" style={{ display: "flex", alignItems: "center" }}>
            <Select
              value={this.state.filtroPreco}
              onChange={(e, a) => {
                this.setState({ filtroPreco: a.value });
              }}
              options={[
                { key: "ultima", value: "ultima", text: "Ultima compra" },
                { key: "medio", value: "medio", text: "Preço médio" },
                {
                  key: "consolidado",
                  value: "consolidado",
                  text: "Consolidado",
                },
              ]}
            />
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Button.Group>
              <Button
                color="blue"
                disabled={this.state.lista.length === 0}
                onClick={this.XSLXButtonClick}
              >
                XLSX
              </Button>
              <Button.Or text="ou" />
              <Button
                color="blue"
                disabled={this.state.lista.length === 0}
                onClick={this.CSVButtonClick}
              >
                CSV
              </Button>
              <Button.Or text="ou" />
              <Button
                color="blue"
                disabled={this.state.lista.length === 0}
                onClick={this.imprimirButtomClick}
              >
                PDF
              </Button>
            </Button.Group>
          </Col>
        </Row>
        <Table celled sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                width={1}
                sorted={
                  this.state.sortedName === "codigo"
                    ? this.state.sortDirection
                    : null
                }
                onClick={() => this.sortTableClick("codigo")}
              >
                Código
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  this.state.sortedName === "descricao"
                    ? this.state.sortDirection
                    : null
                }
                onClick={() => this.sortTableClick("descricao")}
              >
                Descrição
              </Table.HeaderCell>
              <Table.HeaderCell>Unidade Medida</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>E. Minimo</Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  this.state.sortedName === "estoque"
                    ? this.state.sortDirection
                    : null
                }
                onClick={() => this.sortTableClick("estoque")}
              >
                E. Atual
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                V. Und. Última Compra
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Valor Médio</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Valor Total</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.lista.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>{e.id}</Table.Cell>
                <Table.Cell>{e.descricao}</Table.Cell>
                <Table.Cell>{e.unidade_medida}</Table.Cell>
                <Table.Cell>
                  {e.ativo == true
                    ? "Ativo"
                    : e.ativo == false
                    ? "Inativo"
                    : "Ativo"}
                </Table.Cell>
                <Table.Cell>{e.estoque_minimo}</Table.Cell>
                <Table.Cell
                  negative={
                    e.quantidade <= e.estoque_minimo && e.ativo != false
                  }
                  positive={
                    e.quantidade > e.estoque_minimo + 5 && e.ativo != false
                  }
                  warning={
                    e.quantidade > e.estoque_minimo &&
                    e.quantidade <= e.estoque_minimo + 5 &&
                    e.ativo !== false
                  }
                >
                  {e.quantidade}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {parseFloat(e.valor_unidade).toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {parseFloat(e.valor_medio).toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {this.state.filtroPreco === "ultima"
                    ? parseFloat(
                        parseFloat(e.valor_unidade) * parseFloat(e.quantidade)
                      ).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : parseFloat(
                        parseFloat(e.valor_medio) * parseFloat(e.quantidade)
                      ).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />
              <Table.HeaderCell />

              <Table.HeaderCell textAlign="right">
                {this.state.filtroPreco === "ultima"
                  ? parseFloat(this.state.valorTotalItens).toLocaleString(
                      "pt-BR",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )
                  : parseFloat(this.state.valorTotalMedioItens).toLocaleString(
                      "pt-BR",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
              </Table.HeaderCell>
            </Table.Row>

            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan={9}>
                <Pagination
                  boundaryRange={0}
                  ellipsisItem={null}
                  defaultActivePage={1}
                  totalPages={this.state.totalPaginas}
                  onPageChange={this.listaData}
                  activePage={this.state.pagina}
                ></Pagination>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}
