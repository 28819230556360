import React, { Component } from "react";
import { Table, Button, Header } from "semantic-ui-react";
import Lista from "../../../components/paginaLista";

import { Link } from "react-router-dom";

import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

export default class Catraca extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
    };
    this.listaCatracas();
  }

  listaCatracas = async () => {
    const resposta = await api.post(
      "/catraca/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ lista: resposta.data });
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Catraca
        </Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Descrição</Table.HeaderCell>
              <Table.HeaderCell>Ip</Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <Link to={{ pathname: "/catraca" }}>
                  <Button positive icon="add" size="tiny"></Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
          {console.log(this.state.lista)}

            {this.state.lista.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>{e.descricao}</Table.Cell>
                <Table.Cell>{e.ip}</Table.Cell>
                <Table.Cell width={1}>
                  <Link to={{ pathname: "/catraca", query: { id: e.id } }}>
                    <Button primary icon="edit" size="tiny"></Button>
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}
