import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import {
  TextArea,
  Form,
  Segment,
  Button,
  Icon,
  Message,
} from "semantic-ui-react";
import InputMask from "react-input-mask";

import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

export default class FaleConosco extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // inputs
      motivo: null,
      email: null,
      telefone: null,
      titulo: null,
      mensagem: null,

      // loadings/disables
      enviando: false,

      // status
      sucesso: false,
      erro: false,
    };
  }
  enviarMensagem = async () => {
    if (!this.state.motivo) {
      alert("Selecione um motivo.");
      return;
    }
    try {
      this.setState({ enviando: true });

      const mensagem = {
        motivo: this.state.motivo,
        email: this.state.email,
        telefone: this.state.telefone,
        titulo: this.state.titulo,
        mensagem: this.state.mensagem,
      };

      await api.post(
        "/mensagemFaleConosco/salvar?e=public",
        mensagem,
        requisicoes.header
      );

      this.setState({ enviando: false, sucesso: true });

      setTimeout(() => {
        this.setState({ sucesso: false });
      }, 3000);
    } catch (error) {
      this.setState({ enviando: false, erro: true });

      setTimeout(() => {
        this.setState({ erro: false });
      }, 3000);
    }
  };

  render() {
    return (
      <div>
        <div
          style={{
            fontWeight: "bold",
            padding: "15px 30px 15px 30px",
            fontSize: 16,
          }}
        >
          <p>
            Você pode entrar em contato conosco utilizando nossa central de
            mensagens abaixo, ou se preferir, através do email:{" "}
            <a href="mailto: faee@faee.com.br">faee@faee.com.br</a>.
          </p>
          <p>
            Se você está enfrentando um problema, buscaremos resolvê-lo o mais
            rápido possível.
          </p>
        </div>

        <Form
          loading={this.state.enviando}
          onSubmit={this.enviarMensagem}
          success={this.state.sucesso}
          error={this.state.erro}
        >
          <Segment>
            <Form.Group>
              <Form.Select
                width="8"
                label="Por que está entrando em contato?"
                placeholder="Selecione um motivo"
                options={[
                  {
                    text: "Reportar um bug/problema encontrado",
                    value: "bug",
                  },
                  {
                    text: "Dúvida de funcionamento ou de como fazer uma operação",
                    value: "duvida",
                  },
                  {
                    text: "Sugestão de melhora para o sistema",
                    value: "sugestao",
                  },
                  {
                    text: "Outro problema não listado",
                    value: "outro",
                  },
                ]}
                value={this.state.motivo}
                required
                onChange={(e, a) => this.setState({ motivo: a.value })}
              />

              <Form.Input
                width="4"
                label="Email"
                placeholder="Email para podermos responder sua mensagem"
                value={this.state.email}
                onChange={(e, a) => this.setState({ email: a.value })}
              />

              <Form.Field width="4">
                <label>Telefone</label>
                <InputMask
                  label="Telefone"
                  placeholder="Telefone se julgar necessário"
                  mask="(99) 9 9999-9999"
                  value={this.state.telefone}
                  onChange={(e, a) =>
                    this.setState({ telefone: e.target.value })
                  }
                />
              </Form.Field>
            </Form.Group>

            <Form.Input
              label="Título da mensagem"
              placeholder="Digite um título para sua mensagem"
              required
              value={this.state.titulo}
              onChange={(e, a) => this.setState({ titulo: a.value })}
            />

            <TextArea
              placeholder="Digite sua mensagem aqui"
              required
              value={this.state.mensagem}
              onChange={(e, a) => this.setState({ mensagem: a.value })}
            ></TextArea>
          </Segment>

          <Message error>
            <Message.Header>
              Ocorreu um erro ao salvar a sua mensagem.
            </Message.Header>
            <Message.Content>Por favor, tente novamente.</Message.Content>
          </Message>

          <Message success>
            <Message.Header>Mensagem salva!</Message.Header>
            <Message.Content>
              Sua mensagem foi salva. Ela vai ser lida por nossa equipe e
              entraremos em contato caso julguemos necessário.
            </Message.Content>
          </Message>

          <Segment>
            <Row style={{ display: "flex", justifyContent: "flex-end" }}>
              <Col md="auto">
                <Button>Voltar</Button>
              </Col>
              <Col md="auto">
                <Button
                  color="blue"
                  type="submit"
                  disabled={this.state.enviando}
                >
                  <Icon name="send" />
                  Enviar mensagem
                </Button>
              </Col>
            </Row>
          </Segment>
        </Form>
      </div>
    );
  }
}
