import React, { Component } from "react";
import {
  Header,
  Button,
  Divider,
  Segment,
  FormField,
  Input,
  Table,
  Modal,
} from "semantic-ui-react";
import renomearChave from "../../../services/renomearChave";
import renomear2Chaves from "../../../services/renomear2Chaves";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import Select from "react-select";

export default class CentroDeCusto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      codigo: null,
      descricao: null,
      erroCodigoExiste: false,
      elementos: [],
      options: [],
      elemento_de_custo_id: null,
      elemento_de_custo: null,
    };
    this.visualizacao();
    this.alteracao = this.alteracao.bind(this);
  }

  alteracao(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  visualizacao = async () => {
    if (this.props.location.query !== undefined) {
      const resposta = await api.post(
        "/centroDeCusto/visualiza" + requisicoes.entidade,
        this.props.location.query,
        requisicoes.header
      );
      this.setState(resposta.data);
    }
  };

  handleSubmit = (event) => {
    this.submit();
    event.preventDefault();
  };

  submit = async () => {
    const c = this.state;
    const dados = { codigo: c.codigo, descricao: c.descricao };
    let retorno = null;
    if (this.state.id !== null) {
      retorno = await api.post(
        "/centroDeCusto/alterar" +
          requisicoes.entidade +
          "&id=" +
          this.state.id,
        dados,
        requisicoes.header
      );
      window.location.href = "/contabil";
    } else {
      retorno = await api.post(
        "/centroDeCusto/salvar" + requisicoes.entidade,
        dados,
        requisicoes.header
      );
      if (retorno.data === "Codigo ja existe") {
        this.setState({ erroCodigoExiste: true });
      } else {
        window.location.href = "/contabil";
      }
    }
  };

  adicionarElemento = async () => {
    let retorno = null;
    if (this.state.id === null) {
      retorno = await api.post(
        "/centroDeCusto/salvar" + requisicoes.entidade,
        {
          descricao: this.state.descricao,
          codigo: parseInt(this.state.codigo),
        },
        requisicoes.header
      );
    } else {
      retorno = await api.post(
        "/centroDeCusto/visualiza" + requisicoes.entidade,
        this.props.location.query,
        requisicoes.header
      );
    }
    if (retorno.data === "Codigo ja existe") {
      this.setState({ erroCodigoExiste: true });
    } else {
      api
        .post(
          "/centroDeCusto/adicionarElemento" + requisicoes.entidade,
          {
            id: retorno.data.id,
            elemento_de_custo_id: this.state.elemento_de_custo_id,
          },
          requisicoes.header
        )
        .then((resp) => {
          this.visualizacao();
        });
    }
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ openMensagem: false });
  };

  handleShow = (event) => {
    if (document.getElementById("codigo").value !== "") {
      this.setState({ openMensagem: true });
    } else {
      alert("Informe um código primeiro!");
    }
  };

  pesquisa = (newValue) => {
    api
      .post(
        "/elementoDeCusto/pesquisa" + requisicoes.entidade,
        { pesquisa: newValue },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomear2Chaves(objeto, "codigo", "descricao", "label");
          lista.push(objeto);
        });
        this.setState({ options: lista });
      });
  };

  alteracaoSelect = async (event) => {
    this.setState({ elemento_de_custo_id: event.value });
    this.setState({ elemento_de_custo: event });
  };

  excluirVinculoElementoCentro = async (event) => {
    await api
      .post(
        "/centroDeCusto/excluirVinculo" + requisicoes.entidade,
        { id: this.state.id, elemento_de_custo_id: event.target.id },
        requisicoes.header
      )
      .then((ret) => {
        this.visualizacao();
      });
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Centro de Custo
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={6}>
              <Form.Label>Código</Form.Label>
              <FormControl
                type="number"
                aria-label="Código"
                name="codigo"
                id="codigo"
                onChange={this.alteracao}
                isInvalid={!!this.state.erroCodigoExiste}
                defaultValue={this.state.codigo}
                required
              />
              <Form.Control.Feedback type="invalid">
                Código já existe
              </Form.Control.Feedback>
            </Col>
            <Col sm={6}>
              <Form.Label>Descrição</Form.Label>
              <FormField
                placeholder="Descrição"
                name="descricao"
                control={Input}
                onChange={this.alteracao}
                required
                defaultValue={this.state.descricao}
                fluid
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "2%" }}>
            <Col sm={12}>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Código</Table.HeaderCell>
                    <Table.HeaderCell>Elemento de custo</Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "2%" }}>
                      <Button
                        type="button"
                        positive
                        icon="add"
                        data-toggle="modal"
                        size="tiny"
                        onClick={this.handleShow}
                      ></Button>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.elementos.map((e, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{e.codigo}</Table.Cell>
                      <Table.Cell>{e.descricao}</Table.Cell>
                      <Table.Cell style={{ width: "2%" }} textAlign="center">
                        <button
                          type="button"
                          class="ui tiny icon negative button"
                          id={e.id}
                          onClick={this.excluirVinculoElementoCentro}
                        >
                          <i
                            type="button"
                            id={e.id}
                            onClick={this.excluirVinculoElementoCentro}
                            aria-hidden="true"
                            class="trash alternate outline icon"
                          ></i>
                        </button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Col>
          </Row>
          <Divider />
          <Segment clearing>
            <Header floated="right">
              <Link to="/contabil">
                <Button type="button">Voltar</Button>
              </Link>
              <Button positive type="submit">
                Salvar
              </Button>
            </Header>
          </Segment>
          <Modal
            open={this.state.openMensagem}
            onClose={this.handleClose}
            centered={false}
          >
            <Header content="Elementos de Custo" />
            <Modal.Content>
              <Select
                placeholder="Digite para buscar"
                name="listaElementos"
                onChange={this.alteracaoSelect}
                options={this.state.options}
                onInputChange={this.pesquisa}
              />
              <Divider />
              <Segment clearing>
                <Header floated="right">
                  <Button
                    onClick={this.handleClose}
                    style={{ marginLeft: "10px" }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="button"
                    positive
                    onClick={this.adicionarElemento}
                  >
                    Vincular
                  </Button>
                </Header>
              </Segment>
            </Modal.Content>
          </Modal>
        </Form>
      </div>
    );
  }
}
