/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Segment, Table } from "semantic-ui-react";
import { Row, Col } from "react-bootstrap";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Paper,
  Tabs,
  Tab,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
} from "@material-ui/core";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Table selectable compact striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={8}>Atleta</Table.HeaderCell>

              <Table.HeaderCell width={4}>Equipe</Table.HeaderCell>

              <Table.HeaderCell width={4}>Gol</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {this.props.artilheiros.map((element, index) => (
            <Table.Body>
              <Table.Row>
                <Table.Cell>{element.nome_atleta} </Table.Cell>
                <Table.Cell>{element.nome_equipe} </Table.Cell>
                <Table.Cell>{element.gols} </Table.Cell>
              </Table.Row>
            </Table.Body>
          ))}
        </Table>
      </div>
    );
  }
}
