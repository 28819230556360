/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Table, Segment, Divider, Header } from "semantic-ui-react";
import { Form, Row, Col } from "react-bootstrap";
// import Button from "@material-ui/core/Button";
// import { Tooltip } from "@material-ui/core";
// import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
// import VisibilityIcon from "@material-ui/icons/Visibility";
// import SettingsIcon from "@material-ui/icons/Settings";
// import IconButton from "@material-ui/core/IconButton";
// import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
// import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

// import { Link } from "react-router-dom";

import "../styles.css";
import "../fireWORK.css";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Row>
          {this.props.infoClassificacaoCampeonato.map((element, index) => {
            return (
              <Col>
                <Segment>
                  <Header>
                    {" "}
                    <div className="flexSpaceBetween">
                      <div>{" GRUPO " + (index + 1)}</div>
                    </div>
                    <Divider style={{ marginTop: "-0.13ch" }} />
                  </Header>
                  <Table selectable color="blue">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Times</Table.HeaderCell>
                        <Table.HeaderCell>pontos</Table.HeaderCell>
                        <Table.HeaderCell>gols</Table.HeaderCell>
                        <Table.HeaderCell>jogos</Table.HeaderCell>
                        <Table.HeaderCell>vitorias</Table.HeaderCell>
                        <Table.HeaderCell>empates</Table.HeaderCell>
                        <Table.HeaderCell>derrotas</Table.HeaderCell>
                        <Table.HeaderCell>aproveit.</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {element.map((elmt, indx) => {
                        return (
                          <Table.Row
                            style={{
                              color: "rgb(9, 124, 165)",
                              backgroundColor: "rgba(73, 105, 186, 0.01)",
                            }}
                          >
                            <Table.Cell>{elmt.nome_time}</Table.Cell>
                            <Table.Cell>{elmt.pontuacao}</Table.Cell>
                            <Table.Cell>{elmt.gols}</Table.Cell>
                            <Table.Cell>{elmt.jogos}</Table.Cell>
                            <Table.Cell>{elmt.vitorias}</Table.Cell>
                            <Table.Cell>{elmt.empates}</Table.Cell>
                            <Table.Cell>{elmt.derrotas}</Table.Cell>
                            <Table.Cell>{elmt.aproveitamento + "%"}</Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                </Segment>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}
