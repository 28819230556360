import React, { Component } from "react";
import { Header, Table, Button } from "semantic-ui-react";
import { Row, Col, Form } from "react-bootstrap";
import moment from "moment-timezone";
import ReactSelect from "react-select";

import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo: "pagas",
      data_inicio: null,
      data_final: null,
      lista_lancamentos: [],
      imprimindo: false,
      totaisServicos: {},
      centro_de_custo: { label: "Todos", value: 0, selected: true },
      lista_debitoConta: [],
      tipo_relatorio: "simplificado",
      options: [{ label: "Todos os itens", value: 0, selected: true }],
      optionsSelected: [],
      optionsSelectCentroDeCusto: [],
      valoresTotais: {
        quantidade: 0,
        totalReceber: 0,
        totalJuros: 0,
        totalDescontos: 0,
        totalPago: 0,
      },
    };
    let lista = [];
    this.selectBuscaCentroDeCusto();
    this.setState({ options: lista });
  }
  //inicio
  onChangeSelectItem = (e, a) => {
    if (a.action === "clear") {
      this.setState({
        optionsSelected: [],
        selectTodos: false,
        todos: false,
      });

      let lista = this.state.options;

      for (let i = 0; i < lista.length; i++) {
        const element = lista[i];
        element.selected = false;
      }
      this.setState({ options: lista });
      return;
    }

    if (a.action === "pop-value" && a.removedValue === undefined) {
      return;
    }
    if (a.removedValue !== undefined) {
      a.option = a.removedValue;
    }

    if (a.option.value === 0) {
      if (a.action === "select-option") {
        let lista2 = this.state.options;
        for (let i = 0; i < lista2.length; i++) {
          const element = lista2[i];
          element.selected = true;
        }
        this.setState({ options: lista2, selectTodos: true });
      }
      if (
        a.action === "deselect-option" ||
        a.action === "pop-value" ||
        a.action === "remove-value"
      ) {
        let lista2 = this.state.options;
        const optionsSelected = this.state.optionsSelected;

        for (let i = 0; i < lista2.length; i++) {
          const element = lista2[i];
          if (optionsSelected.findIndex((e) => e.value === element.id) !== -1) {
            element.selected = true;
          } else {
            element.selected = false;
          }
        }
        this.setState({ options: lista2, selectTodos: false });
      }
      return;
    }
    if (a.action === "select-option") {
      let lista = this.state.optionsSelected;
      lista.push(a.option);
      let lista2 = this.state.options;
      let index = lista2.findIndex(
        (element) => element.value === a.option.value
      );
      lista2[index].selected = true;

      this.setState({
        optionsSelected: lista,
        options: lista2,
      });
    }
    if (
      a.action === "deselect-option" ||
      a.action === "pop-value" ||
      a.action === "remove-value"
    ) {
      let lista = this.state.optionsSelected;
      let index0 = lista.findIndex(
        (element) => element.value === a.option.value
      );
      lista.splice(index0, 1);

      let lista2 = this.state.options;
      let index = lista2.findIndex(
        (element) => element.value === a.option.value
      );
      lista2[index].selected = false;

      this.setState({
        optionsSelected: lista,
        options: lista2,
      });
    }
  };

  //fim

  alteracao = async (event, value) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === "tipo_relatorio") {
      this.setState({ lista_lancamentos: [] });
      this.setState({
        valoresTotais: {
          quantidade: 0,
          totalReceber: 0,
          totalJuros: 0,
          totalDescontos: 0,
          totalPago: 0,
        },
      });
    }
    if (
      event.target.name === "tipo_relatorio" &&
      event.target.value === "simplificado"
    ) {
      this.setState({ centro_de_custo: { label: "Todos", value: 0 } });
    }
  };

  buscaLancamentos = async (event) => {
    // Buscar todos os lancamentos contas no intervalo,
    // com o centro de custo
    let listaItensCusto = [];
    for (let i = 0; i < this.state.optionsSelected.length; i++) {
      const element = this.state.optionsSelected[i];
      listaItensCusto.push(element.value);
    }

    const lancamentos = await api.post(
      "/lancamentoConta/lancamentosCentroElementoServico" +
        requisicoes.entidade,
      {
        data_inicio: this.state.data_inicio,
        data_final: this.state.data_final,
        tipo: this.state.tipo,
        centro_de_custo_id: this.state.selectTodos ? null : listaItensCusto,
        tipo_relatorio: this.state.tipo_relatorio,
        credito_debito: "credito",
      },
      requisicoes.header
    );

    var valoresTotais = {
      quantidade: 0,
      totalReceber: 0,
      totalJuros: 0,
      totalDescontos: 0,
      totalPago: 0,
    };

    if (this.state.tipo_relatorio === "detalhado") {
      for (let i = 0; i < lancamentos.data.length; i++) {
        const element = lancamentos.data[i];
        valoresTotais.totalReceber +=
          element.valor !== null ? parseFloat(element.valor) : 0;
        valoresTotais.totalJuros +=
          element.valor_atraso !== null && element.valor_mora !== null
            ? parseFloat(element.valor_atraso) + parseFloat(element.valor_mora)
            : element.valor_atraso !== null
            ? parseFloat(element.valor_atraso)
            : element.valor_mora !== null
            ? parseFloat(element.valor_mora)
            : 0;
        valoresTotais.totalDescontos +=
          element.desconto !== null ? parseFloat(element.desconto) : 0;
        valoresTotais.totalPago +=
          element.valor_pago !== null ? parseFloat(element.valor_pago) : 0;
      }
    }
    if (this.state.tipo_relatorio === "simplificado") {
      for (let i = 0; i < lancamentos.data.length; i++) {
        const element = lancamentos.data[i];
        valoresTotais.quantidade += parseInt(element.quantidade);
        valoresTotais.totalReceber +=
          element.total_divida !== null ? parseFloat(element.total_divida) : 0;
        valoresTotais.totalJuros +=
          element.total_valor_atraso !== null &&
          element.total_valor_mora !== null
            ? parseFloat(element.total_valor_atraso) +
              parseFloat(element.total_valor_mora)
            : element.total_valor_atraso !== null
            ? parseFloat(element.total_valor_atraso)
            : element.total_valor_mora !== null
            ? parseFloat(element.total_valor_mora)
            : 0;
        valoresTotais.totalDescontos +=
          element.total_desconto !== null
            ? parseFloat(element.total_desconto)
            : 0;
        valoresTotais.totalPago +=
          element.total_pago !== null ? parseFloat(element.total_pago) : 0;
      }
    }

    this.setState({ valoresTotais: valoresTotais });

    // this.setState({ totaisServicos: totaisServicos });
    this.setState({ lista_lancamentos: lancamentos.data });
  };

  verificaDatas = async () => {
    if (!moment(this.state.data_inicio, "YYYY-MM-DD", true).isValid()) {
      this.setState({
        data_inicio: moment().startOf("month").format("YYYY-MM-DD"),
      });
    }
    if (!moment(this.state.data_final, "YYYY-MM-DD", true).isValid()) {
      this.setState({ data_final: moment().format("YYYY-MM-DD") });
    }
  };

  pesquisaLancamentos = async (event) => {
    await this.verificaDatas();

    await this.buscaLancamentos();
  };

  gerarRelatorio = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    let listaItensCusto = [];
    for (let i = 0; i < this.state.optionsSelected.length; i++) {
      const element = this.state.optionsSelected[i];
      listaItensCusto.push(element.value);
    }

    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado?e=" + entidade,
      {},
      {
        headers: { token: "Baer " + token },
      }
    );
    const nomePessoa = await api.post(
      "/pessoa/pessoaIdNome?e=" + entidade,
      {
        id: usuarioLogado.data.pessoa_id,
      },
      {
        headers: { token: "Baer " + token },
      }
    );

    await api.post(
      "/lancamentoConta/gerarPdfCentroDeCusto?e=" + entidade,
      {
        userId: usuarioLogado.data.pessoa_id,
        nome_operador: nomePessoa.data.nome,
        data_inicio: this.state.data_inicio,
        data_final: this.state.data_final,
        tipo: this.state.tipo,
        centro_de_custo_id: this.state.selectTodos ? null : listaItensCusto,
        tipo_relatorio: this.state.tipo_relatorio,
        credito_debito: "credito",
        valoresTotais: this.state.valoresTotais,
      },
      {
        headers: { token: "Baer " + token },
      }
    );
  };

  buscarRelatorio = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado?e=" + entidade,
      {},
      {
        headers: { token: "Baer " + token },
      }
    );
    // const nomePessoa = await api.post(
    //   "/pessoa/pessoaIdNome?e=" + entidade,
    //   {
    //     id: usuarioLogado.data.pessoa_id,
    //   },
    //   {
    //     headers: { token: "Baer " + token },
    //   }
    // );

    const r = await api.get(
      "/lancamentoConta/buscaPdfCentroDeCusto?e=" +
        entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.pessoa_id
    );
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "lancamentoConta/buscaPdfCentroDeCusto?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.pessoa_id;

    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  imprimirButtonOnClick = async () => {
    this.setState({ imprimindo: true });
    await this.gerarRelatorio();
    await this.buscarRelatorio().then(() =>
      this.setState({ imprimindo: false })
    );
  };

  selectBuscaCentroDeCusto = async () => {
    const retornoCentroDeCusto = await api.post(
      "/centroDeCusto/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    retornoCentroDeCusto.data.sort(function (a, b) {
      if (a.descricao > b.descricao) {
        return 1;
      }
      if (a.descricao < b.descricao) {
        return -1;
      }
      return 0;
    });
    var lista = [];

    lista.push({ label: "Todos", value: 0 });
    for (let i = 0; i < retornoCentroDeCusto.data.length; i++) {
      const element = retornoCentroDeCusto.data[i];
      let objeto = {
        value: element.id,
        label: element.descricao,
      };
      lista.push(objeto);
    }
    this.setState({ optionsSelectCentroDeCusto: lista });
    this.setState({
      options: lista,
      optionsSelected: [],
    });
  };

  render() {
    return (
      <div>
        <Row
          style={{
            marginBottom: "10px",
            marginTop: "15px",
            marginLeft: 0,
            marginRight: 0,
            display: "flex",
          }}
        >
          <Button
            positive
            style={{
              marginLeft: "auto",
            }}
            onClick={this.pesquisaLancamentos}
          >
            Buscar
          </Button>
          <Button
            primary
            loading={this.state.imprimindo}
            onClick={this.imprimirButtonOnClick}
            disabled={
              this.state.lista_lancamentos.length === 0 || this.state.imprimindo
            }
          >
            Imprimir
          </Button>
        </Row>
        <Row>
          <Col sm={2}>
            <Form.Label>Data Inicio</Form.Label>
            <Form.Control
              placeholder="Data Inicio"
              aria-describedby="basic-addon1"
              type="date"
              name="data_inicio"
              value={this.state.data_inicio}
              onChange={this.alteracao}
            />
          </Col>
          <Col sm={2}>
            <Form.Label>Data Final</Form.Label>
            <Form.Control
              placeholder="Data Final"
              aria-describedby="basic-addon1"
              type="date"
              name="data_final"
              value={this.state.data_final}
              onChange={this.alteracao}
            />
          </Col>

          <Col sm={8}>
            <Col fluid>
              <Form.Label>Centro de custo</Form.Label>
              <ReactSelect
                options={this.state.options}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                onChange={this.onChangeSelectItem}
                isOptionSelected={(element) => element.selected === true}
                // isDisabled={this.state.tipo_relatorio === "simplificado"}

                // onInputChange={(e, a) => {
                //   if (e !== "") {
                //     this.pesquisa(e);
                //   }
                // }}
              />
            </Col>
          </Col>
          <Col sm={2}>
            <Form.Label>Tipo de busca</Form.Label>
            <Form.Control
              as="select"
              name="tipo"
              checked={this.state.tipo}
              id="tipo"
              onChange={this.alteracao}
            >
              <option value="pagas">Pagos no intervalo</option>
              <option value="vencidas">Vence no intervalo</option>
              <option value="emAberto">Em aberto</option>
            </Form.Control>
          </Col>
          <Col sm={2}>
            <Form.Label>Tipo do Relatório</Form.Label>
            <Form.Control
              as="select"
              name="tipo_relatorio"
              checked={this.state.tipo}
              id="tipo_relatorio"
              required
              onChange={this.alteracao}
            >
              <option value="simplificado">Simplificado</option>
              <option value="detalhado">Detalhado</option>
            </Form.Control>
          </Col>
        </Row>
        {
          <form
            style={{
              paddingBottom: 100,
              paddingRight: 0,
              paddingLeft: -200,
              paddingTop: 15,
            }}
          >
            {this.state.tipo_relatorio === "simplificado" && (
              <Table celled selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Quantidade</Table.HeaderCell>
                    <Table.HeaderCell>Nome</Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"} collapsing>
                      Valor a receber
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"} collapsing>
                      Juros/Multa
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"} width={1}>
                      {"Descontos(-)/\nAcréscimos(+)"}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"} collapsing>
                      Valor pago
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.lista_lancamentos.map((e, i) => (
                    <Table.Row>
                      <Table.Cell>{e.quantidade}</Table.Cell>
                      <Table.Cell>{e.centro_de_custo}</Table.Cell>
                      <Table.Cell textAlign={"right"}>
                        {e.total_divida === null
                          ? "0,00"
                          : parseFloat(e.total_divida).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </Table.Cell>
                      <Table.Cell textAlign={"right"}>
                        {e.total_valor_atraso === null &&
                        e.total_valor_mora === null
                          ? "0,00"
                          : e.total_valor_atraso === null
                          ? parseFloat(e.total_valor_mora).toLocaleString(
                              "pt-BR",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : e.total_valor_mora === null
                          ? parseFloat(e.total_valor_atraso).toLocaleString(
                              "pt-BR",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          : parseFloat(
                              parseFloat(e.total_valor_atraso) +
                                parseFloat(e.total_valor_mora)
                            ).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </Table.Cell>
                      <Table.Cell textAlign={"right"}>
                        {e.total_desconto === null
                          ? "0,00"
                          : parseFloat(e.total_desconto * -1).toLocaleString(
                              "pt-BR",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                      </Table.Cell>
                      <Table.Cell textAlign={"right"}>
                        {e.total_pago === null
                          ? "0,00"
                          : parseFloat(e.total_pago).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell>
                      {this.state.valoresTotais.quantidade}
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"}>
                      {parseFloat(
                        this.state.valoresTotais.totalReceber
                      ).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"}>
                      {parseFloat(
                        this.state.valoresTotais.totalJuros
                      ).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"}>
                      {parseFloat(
                        this.state.valoresTotais.totalDescontos
                      ).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"}>
                      {parseFloat(
                        this.state.valoresTotais.totalPago
                      ).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            )}
            {this.state.tipo_relatorio === "detalhado" && (
              <Table celled selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Nome</Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"} collapsing>
                      Valor a receber
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"} collapsing>
                      Juros/Multa
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"} width={1}>
                      {"Descontos(-)/\nAcréscimos(+)"}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"} collapsing>
                      Valor pago
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.lista_lancamentos.map((e, i) => (
                    <Table.Row>
                      <Table.Cell>{e.pessoa?.nome}</Table.Cell>
                      <Table.Cell textAlign={"right"}>
                        {e.valor === null
                          ? "0,00"
                          : parseFloat(e.valor).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </Table.Cell>
                      <Table.Cell textAlign={"right"}>
                        {e.valor_atraso === null && e.valor_mora === null
                          ? "0,00"
                          : e.valor_atraso === null
                          ? parseFloat(e.valor_mora).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : e.valor_mora === null
                          ? parseFloat(e.valor_atraso).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : parseFloat(
                              parseFloat(e.valor_atraso) +
                                parseFloat(e.valor_mora)
                            ).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </Table.Cell>
                      <Table.Cell textAlign={"right"}>
                        {e.desconto === null
                          ? "0,00"
                          : parseFloat(e.desconto * -1).toLocaleString(
                              "pt-BR",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                      </Table.Cell>
                      <Table.Cell textAlign={"right"}>
                        {e.valor_pago === null
                          ? "0,00"
                          : parseFloat(e.valor_pago).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"}>
                      {parseFloat(
                        this.state.valoresTotais.totalReceber
                      ).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"}>
                      {parseFloat(
                        this.state.valoresTotais.totalJuros
                      ).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"}>
                      {parseFloat(
                        this.state.valoresTotais.totalDescontos
                      ).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"}>
                      {parseFloat(
                        this.state.valoresTotais.totalPago
                      ).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            )}
          </form>
        }
      </div>
    );
  }
}
