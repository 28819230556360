import React, { Component } from "react";
import Avaliacao from "./avaliacao";
import Resultado from "./resultado";

export default class AvaliacaoFisica extends Component {
  render() {
    let content = "";
    if (typeof this.props.location.query !== "undefined") {
      if (this.props.location.query.resultado !== true) {
        content = <Avaliacao id={this.props.location.query.id} />;
      } else {
        content = <Resultado id={this.props.location.query.id} />;
      }
    } else {
      content = <Avaliacao id={null} />;
    }
    return <div className="pagina">{content}</div>;
  }
}
