import React, { Component } from "react";
import { Header, Segment, Table } from "semantic-ui-react";
import { IconButton, Icon, ThemeProvider } from "@material-ui/core";

import { green } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

// Requisição
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

// Componentes criados
import ModalAdicionarPerfil from "./ModalAdicionarPerfil";

export default class LiberaModulos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Lista de dados
      entidade: this.props.entidade,
      listaPerfis: [],

      // Modal
      perfilSelectedModalAdicionarPerfil: null,
      openModalAdicionarPerfil: false,
    };
    this.buscarPerfis();
  }

  buscarPerfis = async () => {
    const perfis = await api.post(
      "/perfil/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    this.setState({ listaPerfis: perfis.data });
  };

  excluirPerfil = async (perfil_id) => {
    try {
      await api.post(
        "/perfil/excluir" + requisicoes.entidade,
        { perfil_id: perfil_id },
        requisicoes.header
      );

      let lista = this.state.listaPerfis;
      let index = lista.findIndex((e) => e.id === perfil_id);
      lista.splice(index, 1);

      this.setState({ listaPerfis: lista });
    } catch (error) {
      alert("Ocorreu um erro ao excluir o perfil.");
    }
  };

  render() {
    return (
      <div>
        <Segment>
          <Header>Perfis </Header>
          <Table celled compact>
            <Table.Header>
              <Table.HeaderCell collapsing>Código</Table.HeaderCell>
              <Table.HeaderCell>Nome do perfil</Table.HeaderCell>
              <Table.HeaderCell collapsing>Número de Usuários</Table.HeaderCell>

              <Table.HeaderCell collapsing>Permissões</Table.HeaderCell>
              <Table.HeaderCell collapsing>Páginas</Table.HeaderCell>
              <Table.HeaderCell collapsing>Menus</Table.HeaderCell>
              <Table.HeaderCell
                collapsing
                style={{
                  padding: 0,
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <IconButton
                    onClick={() =>
                      this.setState({ openModalAdicionarPerfil: true })
                    }
                  >
                    <ThemeProvider
                      theme={createTheme({
                        palette: {
                          primary: green,
                        },
                      })}
                    >
                      <Icon color="primary">add_circle</Icon>
                    </ThemeProvider>
                  </IconButton>
                </div>
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {this.state.listaPerfis.map((e, i) => (
                <Table.Row>
                  <Table.Cell>{e.id}</Table.Cell>
                  <Table.Cell>{e.nome}</Table.Cell>
                  <Table.Cell>{}</Table.Cell>
                  <Table.Cell>{e.permissoes.length}</Table.Cell>
                  <Table.Cell>{e.rotas.length}</Table.Cell>
                  <Table.Cell>{e.menus.length}</Table.Cell>

                  <Table.Cell
                    style={{
                      padding: 0,
                      textAlign: "center",
                      display: "flex",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        this.setState({
                          perfilSelectedModalAdicionarPerfil: e,
                          openModalAdicionarPerfil: true,
                        });
                      }}
                    >
                      <Icon color="primary">edit</Icon>
                    </IconButton>

                    <IconButton
                      onClick={() => {
                        this.excluirPerfil(e.id);
                      }}
                    >
                      <Icon color="error">delete_icon</Icon>
                    </IconButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
        <ModalAdicionarPerfil
          open={this.state.openModalAdicionarPerfil}
          onClose={() =>
            this.setState({
              openModalAdicionarPerfil: false,
              perfilSelectedModalAdicionarPerfil: null,
            })
          }
          entidade={this.state.entidade}
          addPerfil={(perfil) => {
            let lista = this.state.listaPerfis;
            let idx = lista.findIndex((e) => e.id === perfil.id);
            if (idx !== -1) {
              lista.splice(idx, 1, perfil);
            } else {
              lista.push(perfil);
            }
            this.setState({ listaPerfis: lista });
          }}
          perfilSelectedModalAdicionarPerfil={
            this.state.perfilSelectedModalAdicionarPerfil
          }
        />
      </div>
    );
  }
}
