import React, { Component } from "react";
import { Modal, Table } from "semantic-ui-react";
import { CircularProgress, Divider, Button } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";

// libs
import moment from "moment-timezone";

// requisicoes
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

export default class ModalMatriculadosTurma extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingModalVisualiza: true,
      matriculasModalVisualiza: [],
    };
  }

  buscaMatriculasTurma = async () => {
    const turmaServicoId = this.props.turmaServicoId;
    if (!turmaServicoId) {
      return;
    }
    this.setState({
      loadingModalVisualiza: true,
      matriculasModalVisualiza: [],
    });

    const matriculas = await api.post(
      "/matriculaTurma/matriculaPorTurma" + requisicoes.entidade,
      { id: turmaServicoId },
      requisicoes.header
    );

    setTimeout(() => {
      this.setState({
        matriculasModalVisualiza: matriculas.data,
        loadingModalVisualiza: false,
      });
    }, 800);
  };

  render() {
    return (
      <Modal
        open={this.props.open}
        size="large"
        onMount={() => this.buscaMatriculasTurma()}
        onClose={() => {
          this.setState({ matriculasModalVisualiza: [] });
          this.props.onClose();
        }}
      >
        {this.props.turma && (
          <>
            <Modal.Header>
              {"MATRICULADOS - " +
                this.props.turma.nome +
                " - " +
                this.props.turma.turma}
              <h5>
                {this.props.turma.horaInicio.slice(0, 5) +
                  " - " +
                  this.props.turma.horaFim.slice(0, 5)}
              </h5>
            </Modal.Header>
            <Modal.Content>
              {this.state.loadingModalVisualiza ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 50,
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <Table celled selectable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={4}>Nome</Table.HeaderCell>
                        <Table.HeaderCell width={3}>
                          Data da matrícula
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2}>Telefone</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Celular</Table.HeaderCell>
                        <Table.HeaderCell width={4}>E-mail</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.state.matriculasModalVisualiza.length === 0 ? (
                        <Table.Row>
                          <Table.Cell
                            colSpan="20"
                            textAlign="center"
                            style={{ fontSize: 20, fontWeight: 20 }}
                          >
                            Não foram econtradas pessoas matriculadas nesta
                            turma.
                          </Table.Cell>
                        </Table.Row>
                      ) : (
                        this.state.matriculasModalVisualiza.map((e, i) => (
                          <Table.Row>
                            <Table.Cell>{e.pessoa.nome}</Table.Cell>
                            <Table.Cell>
                              {moment(e.dataMatricula).format("DD/MM/YYYY")}
                            </Table.Cell>
                            <Table.Cell>
                              {e.pessoa.telefone ? e.pessoa.telefone : ""}
                            </Table.Cell>
                            <Table.Cell>
                              {e.pessoa.celular ? e.pessoa.celular : ""}
                            </Table.Cell>
                            <Table.Cell>
                              {e.pessoa.email ? e.pessoa.email : ""}
                            </Table.Cell>
                          </Table.Row>
                        ))
                      )}
                    </Table.Body>
                  </Table>
                  <Divider style={{ marginBottom: 15 }} />
                  <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Col md="auto">
                      <Button
                        variant="contained"
                        color="inherit"
                        onClick={this.props.onClose}
                      >
                        Voltar
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Modal.Content>
          </>
        )}
      </Modal>
    );
  }
}
