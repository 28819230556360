import React, { Component } from "react";
import { Modal, Table, Segment } from "semantic-ui-react";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import "../styles.css";

export default class modalAddEtapaTreino extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkEditarOrganizacao: false,
    };
  }

  checkboxVerify = () => {
    if (this.state.checkEditarOrganizacao === false) {
      this.setState({ checkEditarOrganizacao: true });
    } else {
      this.setState({ checkEditarOrganizacao: false });
    }
  };

  render() {
    return (
      <Modal dimmer="bluring" size="small" open={this.props.modalEditar}>
        <Modal.Header>
          {"Participações"}
          <IconButton
            className="bottomModal"
            onClick={() => this.props.editOrganizacao(null, false)}
            color="secondary"
          >
            <CloseIcon />
          </IconButton>

          <h6> Todas as competições e escalações que os times participam</h6>
        </Modal.Header>

        <Modal.Content>
          {this.props.campeonatoPorTimes.map((element, index) => {
            return (
              <Segment>
                <Table selectable>
                  <Table.Header>
                    <Table.Row></Table.Row>
                  </Table.Header>

                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={11}>
                        {element.campeonato_equipes[0] !== undefined
                          ? element.campeonato_equipes[0].campeonato.descricao
                          : ""}
                      </Table.HeaderCell>
                      <Table.HeaderCell width={1}></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  {element.equipe_pessoas.length > 0
                    ? element.equipe_pessoas.map((props, index) => {
                        return (
                          <Table.Body>
                            <Table.Cell>{props.p_m_d.pessoa.nome}</Table.Cell>
                            <Table.Cell>
                              {props.p_m_d.tipo_vinculo.descricao}
                            </Table.Cell>
                          </Table.Body>
                        );
                      })
                    : ""}
                </Table>
              </Segment>
            );
          })}
        </Modal.Content>
        <Modal.Actions></Modal.Actions>
      </Modal>
    );
  }
}
