import React, { Component } from "react";
import { Table, Button, Header } from "semantic-ui-react";
import { Col } from "react-bootstrap";
import ModalPessoa from "./modalPessoa";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

export default class ListaPessoaInvalidadas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_pessoa: false,
      pessoa: null,
    };
  }

  modalPessoa = (id) => {
    this.setState({
      pessoa: this.props.pessoas_invalidadas.find((e) => e.id === id),
    });
    this.setState({ modal_pessoa: true });
  };

  fechaModalPessoa = (id) => {
    this.setState({ modal_pessoa: false });
  };

  alteracaoPessoa = (event) => {
    let pessoa = this.state.pessoa;
    Object.entries(pessoa).map((k) => {
      if (k[0] === event.target.name) {
        pessoa[k[0]] = event.target.value;
      }
    });
    this.setState({ pessoa: pessoa });
  };

  alteracaoPessoaDebitoConta = (event) => {
    let pessoa = this.state.pessoa;
    Object.entries(pessoa.debito_conta).map((k) => {
      if (k[0] === event.target.name) {
        pessoa.debito_conta[k[0]] = event.target.value;
      }
    });
    this.setState({ pessoa: pessoa });
  };

  alteracaoPessoaAgenciaDebitoConta = (event) => {
    let pessoa = this.state.pessoa;
    Object.entries(pessoa.debito_conta.agencia_debito_conta).map((k) => {
      if (k[0] === event.target.name) {
        pessoa.debito_conta.agencia_debito_conta[k[0]] = event.target.value;
      }
    });
    this.setState({ pessoa: pessoa });
  };

  salvarPessoa = async () => {
    const pessoa = this.state.pessoa;
    await api.post(
      "/pessoa/alterar" + requisicoes.entidade + "&id=" + pessoa.id,
      {
        tipo: pessoa.tipo,
        nome: pessoa.nome,
        cpf: pessoa.cpf,
        cnpj: pessoa.cnpj,
        endereco: pessoa.endereco,
        bairro: pessoa.bairro,
        numero: pessoa.numero,
      },
      requisicoes.header
    );
    if (pessoa.debito_conta !== null) {
      await api.post(
        "/debitoConta/alterar" +
          requisicoes.entidade +
          "&id=" +
          pessoa.debito_conta.id,
        {
          conta: pessoa.debito_conta.conta,
          digito_conta: pessoa.debito_conta.digito_conta,
        },
        requisicoes.header
      );
    }
    if (
      pessoa.debito_conta !== null &&
      pessoa.debito_conta.agencia_debito_conta !== null
    ) {
      await api.post(
        "/agenciaDebitoConta/alterar" +
          requisicoes.entidade +
          "&id=" +
          pessoa.debito_conta.agencia_debito_conta.id,
        {
          agencia: pessoa.debito_conta.agencia_debito_conta.agencia,
          digito_conta: pessoa.debito_conta.agencia_debito_conta.digito_agencia,
        },
        requisicoes.header
      );
    }
    this.fechaModalPessoa();
  };

  render() {
    return (
      <Col
        sm={12}
        style={{ paddingTop: "10px", paddingLeft: "0", paddingRight: "0" }}
      >
        <Header as="h2">Pessoas Com Dados Inválidos</Header>
        <ModalPessoa
          modal_pessoa={this.state.modal_pessoa}
          modalPessoa={this.modalPessoa}
          fechaModalPessoa={this.fechaModalPessoa}
          pessoa={this.state.pessoa}
          alteracaoPessoa={this.alteracaoPessoa}
          salvarPessoa={this.salvarPessoa}
          alteracaoPessoaDebitoConta={this.alteracaoPessoaDebitoConta}
          alteracaoPessoaAgenciaDebitoConta={
            this.alteracaoPessoaAgenciaDebitoConta
          }
        />
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>CPF/CNPJ</Table.HeaderCell>
              <Table.HeaderCell>Endereço</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.pessoas_invalidadas !== undefined &&
              this.props.pessoas_invalidadas.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{e.nome}</Table.Cell>
                  <Table.Cell>{e.cpf === null ? e.cnpj : e.cpf}</Table.Cell>
                  <Table.Cell>{e.endereco}</Table.Cell>
                  <Table.Cell width={1}>
                    <Button
                      primary
                      icon="list"
                      size="tiny"
                      onClick={() => this.modalPessoa(e.id)}
                      type="button"
                      style={{ marginTop: "7%" }}
                    ></Button>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Col>
    );
  }
}
