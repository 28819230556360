module.exports = [
    {value:"001", label:'001 – Conta Corrente de Pessoa Física'},
    {value:"002", label:'002 – Conta Simples de Pessoa Física'},
    {value:"003", label:'003 – Conta Corrente de Pessoa Jurídica'},
    {value:"006", label:'006 – Entidades Públicas'},
    {value:"007", label:'007 – Depósitos Instituições Financeiras'},
    {value:"013", label:'013 – Poupança de Pessoa Física'},
    {value:"022", label:'022 – Poupança de Pessoa Jurídica'},
    {value:"028", label:'028 – Poupança de Crédito Imobiliário'},
    {value:"043", label:'043 – Depósitos Lotéricos'}
]

