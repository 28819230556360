import React, { Component } from "react";
import { Header, Table, Button, Checkbox } from "semantic-ui-react";
import { Row, Col, Form } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";

import moment from "moment-timezone";
import api from "../../../services/api";

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titulos: [],
      buscando: false,
      imprimindo: false,

      //Filtros
      checkbox_data: true,
      data_inicio: moment().startOf("month").format("YYYY-MM-DD"),
      data_final: moment().format("YYYY-MM-DD"),
    };
  }

  imprimir = async () => {
    this.setState({ imprimindo: true });

    try {
      await api.post(
        "/tipoTitulo/gerarPdfRemidos" + requisicoes.entidade,
        {
          data_inicio: this.state.checkbox_data ? this.state.data_inicio : null,
          data_final: this.state.checkbox_data ? this.state.data_final : null,
        },
        requisicoes.header
      );

      var localStorage = window.localStorage;
      var token = localStorage.getItem("token_clube");

      const r = await api.get(
        "/tipoTitulo/buscarArquivoPdfRemidos" +
          requisicoes.entidade +
          "&t=Baer " +
          token
      );
      var oReq = new XMLHttpRequest();

      var URLToPDF =
        r.config.baseURL +
        "tipoTitulo/buscarArquivoPdfRemidos" +
        requisicoes.entidade +
        "&t=Baer " +
        token;

      oReq.open("GET", URLToPDF, true);
      oReq.responseType = "blob";
      oReq.onload = function () {
        const file = new Blob([oReq.response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      };
      oReq.send();
    } catch (e) {}

    this.setState({ imprimindo: false });
  };

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  buscaTitulosRemidos = async (event, a) => {
    this.setState({ buscando: true });

    try {
      const titulosRemidos = await api.post(
        "/tipoTitulo/buscaRemidosData" + requisicoes.entidade,
        {
          data_inicio: this.state.checkbox_data ? this.state.data_inicio : null,
          data_final: this.state.checkbox_data ? this.state.data_final : null,
        },
        requisicoes.header
      );
      this.setState({
        titulos: titulosRemidos.data,
        buscando: false,
      });
    } catch (e) {
      this.setState({ buscando: false });
    }
  };

  render() {
    return (
      <div>
        {
          <div>
            <Row>
              <Col md="auto" style={{ padding: 0 }}>
                <Col style={{ flex: 1 }}>
                  <Form.Label>Datas</Form.Label>
                </Col>
                <Col style={{ flex: 1 }}>
                  <Checkbox
                    toggle
                    checked={this.state.checkbox_data}
                    onChange={(e, a) =>
                      this.setState({ checkbox_data: a.checked })
                    }
                  />
                </Col>
              </Col>
              <Col sm={3}>
                <Form.Label>Data Inicio</Form.Label>
                <Form.Control
                  placeholder="Data Inicio"
                  aria-describedby="basic-addon1"
                  type="date"
                  name="data_inicio"
                  value={this.state.data_inicio}
                  onChange={this.alteracao}
                  disabled={!this.state.checkbox_data}
                />
              </Col>
              <Col sm={3}>
                <Form.Label>Data Final</Form.Label>
                <Form.Control
                  placeholder="Data Final"
                  aria-describedby="basic-addon1"
                  type="date"
                  name="data_final"
                  value={this.state.data_final}
                  onChange={this.alteracao}
                  disabled={!this.state.checkbox_data}
                />
              </Col>
              <Col md="auto">
                <Button
                  color="green"
                  style={{ marginTop: "25px" }}
                  onClick={this.buscaTitulosRemidos}
                  loading={this.state.buscando}
                  disabled={this.state.buscando || this.state.imprimindo}
                >
                  Buscar
                </Button>
              </Col>
              <Col md="auto">
                <Button
                  color="blue"
                  style={{ marginTop: "25px" }}
                  onClick={this.imprimir}
                  loading={this.state.imprimindo}
                  disabled={
                    this.state.imprimindo || this.state.titulos.length === 0
                  }
                >
                  Imprimir
                </Button>
              </Col>
            </Row>

            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Título</Table.HeaderCell>
                  <Table.HeaderCell>Data da Transferência</Table.HeaderCell>
                  <Table.HeaderCell>Tipo Título Anterior</Table.HeaderCell>
                  <Table.HeaderCell>Pessoa</Table.HeaderCell>
                  <Table.HeaderCell>Motivo</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.titulos.length === 0
                  ? null
                  : this.state.titulos.map((e, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>
                          {e.acoes[0] && e.acoes[0].codigo}
                        </Table.Cell>
                        <Table.Cell>
                          {e.data_tranferencia
                            ? moment(e.data_tranferencia).format("DD/MM/YYYY")
                            : e.data && moment(e.data).format("DD/MM/YYYY")}
                        </Table.Cell>
                        <Table.Cell>
                          {e.tipo_titulo_anterior.descricao}
                        </Table.Cell>
                        <Table.Cell>{e.pessoa_compra.nome}</Table.Cell>
                        <Table.Cell>{e.motivo}</Table.Cell>
                      </Table.Row>
                    ))}
              </Table.Body>
            </Table>
          </div>
        }
      </div>
    );
  }
}
