import React, { Component } from "react";
import { Container, Header, Segment, Table } from "semantic-ui-react";
import { Form, Col, Row } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import moment from "moment-timezone";
import TextField from "@material-ui/core/TextField";
import renomearChave from "../../../services/renomearChave";
import "../styles.css";
import Atiradores from "./atiradores";
import Etapas from "./etapas";
import api from "../../../services/api";

export default class Competicao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista_de_atiradores: [],
      relatorio_descricao: "",
      relatorio_id: null,
      atirador_descricao: "",
      atirador_id: null,
      evento_descricao: "",
      evento_id: null,
      etapa_descricao: "",
      etapa_id: null,
      tabela_atiradores: [],
      lista_de_eventos: [],
      lista_de_etapas: [],
      tabela_etapas: [],
      data_inicial: null,
      data_final: null,
      porcentagem_aproveitamento: null,
    };
  }

  pesquisaPessoa = (newValue) => {
    api
      .post(
        "/pessoa/listaPessoaEvento" + requisicoes.entidade,
        {},
        requisicoes.header
      )
      .then((resposta) => {
        const lista = [];
        resposta.data.forEach((e) => {
          let objeto = renomearChave(e, "id", "value");
          objeto = renomearChave(objeto, "nome", "label");

          lista.push(objeto);
        });

        this.setState({ lista_de_atiradores: lista });
      });
  };
  buscaAtirador = async () => {
    let porcentagem = 0;
    let qtde_participacao = 0;
    const infoatirador = await api.post(
      "/atirador/buscaAtiradoresPorEtapa" + requisicoes.entidade,
      {
        atirador_id: this.state.atirador_id,
        data_inicial: this.state.data_inicial,
        data_final: this.state.data_final,
      },
      requisicoes.header
    );

    for (let a = 0; a < infoatirador.data.length; a++) {
      porcentagem =
        porcentagem + infoatirador.data[a].pontuacoes[0].aproveitamento;
      if (infoatirador.data[a].pontuacoes[0].aproveitamento !== null) {
        qtde_participacao = qtde_participacao + 1;
      }
    }

    console.log(qtde_participacao);

    console.log(porcentagem);
    this.setState({
      porcentagem_aproveitamento: porcentagem / qtde_participacao,
    });
    this.setState({ tabela_atiradores: infoatirador.data });
  };

  pesquisaEventos = (newValue) => {
    api
      .post("/eventos/lista" + requisicoes.entidade, {}, requisicoes.header)
      .then((element) => {
        const lista = [];
        element.data.forEach((e) => {
          let objeto = renomearChave(e, "id", "value");
          objeto = renomearChave(objeto, "descricao", "label");

          lista.push(objeto);
        });
        this.setState({ lista_de_eventos: lista });
      });
  };
  pesquisaEtapas = (newValue) => {
    api
      .post(
        "/eventos/listaEtapas" + requisicoes.entidade,
        { id: this.state.evento_id },
        requisicoes.header
      )
      .then((element) => {
        const lista = [];
        element.data.forEach((e) => {
          let objeto = renomearChave(e, "id", "value");
          objeto = renomearChave(objeto, "numero_etapa", "label");

          lista.push(objeto);
        });

        this.setState({ lista_de_etapas: lista });
      });
  };

  buscaEtapas = async () => {
    const atiradores = await api.post(
      "/atirador/lista" + requisicoes.entidade,
      { evento_id: this.state.evento_id, etapa_id: this.state.etapa_id },
      requisicoes.header
    );
    this.setState({ tabela_etapas: atiradores.data });
  };

  alteracao = async (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  alteracaoSelectRelatorio = async (event) => {
    this.setState({ relatorio_descricao: event.label });
    this.setState({ relatorio_id: event.value });
  };

  alteracaoSelectAtirador = async (event) => {
    this.setState({ atirador_descricao: event.label });
    this.setState({ atirador_id: event.value });
  };

  alteracaoSelectEvento = async (event) => {
    this.setState({ evento_descricao: event.label });
    this.setState({ evento_id: event.value });
    this.setState({ etapa_id: null });
  };
  alteracaoSelectEtapa = async (event) => {
    this.setState({ etapa_descricao: event.label });
    this.setState({ etapa_id: event.value });
  };

  voltar = async () => {
    window.location.href = "/eventos";
  };

  handleSubmit = async () => {
    this.submit();
  };

  submit = async () => {};

  gerarPdfRelatorioAtiradores = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const atiradores = await api.post(
      "/relatorio/GerarPdfPorAtiradores?e=" + entidade,
      {
        atirador_id: this.state.atirador_id,
      },
      { headers: { token: "Baer " + token } }
    );

    this.imprimirPdfRelatorioAtiradores();
  };
  imprimirPdfRelatorioAtiradores = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api.get(
      "/relatorio/BuscarPdfPorAtiradores?e=" + entidade + "&t=Baer " + token
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "relatorio/BuscarPdfPorAtiradores?e=" +
      entidade +
      "&t=Baer " +
      token;
    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = await new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  gerarPdfRelatorioEtapas = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const atiradores = await api.post(
      "/relatorio/GerarPdfPorEtapas?e=" + entidade,
      {
        evento_id: this.state.evento_id,
        etapa_id: this.state.etapa_id,
        etapa_descricao: this.state.etapa_descricao,
        evento_descricao: this.state.evento_descricao,
      },
      { headers: { token: "Baer " + token } }
    );

    this.imprimirPdfRelatorioEtapas();
  };

  imprimirPdfRelatorioEtapas = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api.get(
      "/relatorio/BuscarPdfPorEtapas?e=" + entidade + "&t=Baer " + token
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "relatorio/BuscarPdfPorEtapas?e=" +
      entidade +
      "&t=Baer " +
      token;
    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = await new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  render() {
    const tiposrelatorios = [
      { value: "atiradores", label: "Atiradores" },
      { value: "etapas", label: "Etapas" },
    ];

    return (
      <div className="paginaSemPaddingTop">
        <Form onSubmit={this.handleSubmit}>
          <Segment>
            <Header as="h2" dividing>
              Relatórios
            </Header>

            <div class="row align-items-end">
              <Col sm={3}>
                <Form.Label className="labelRelatorio">Relatório</Form.Label>
                <Select
                  className="selectCss"
                  placeholder="Selecione o tipo"
                  id="tiporelatorio"
                  name="tiporelatorio"
                  label="Tipo de Relatório"
                  onChange={this.alteracaoSelectRelatorio}
                  options={tiposrelatorios}
                />
              </Col>

              {this.state.relatorio_descricao === "Atiradores" ? (
                <>
                  <Col sm={3}>
                    <Form.Label>Atiradores</Form.Label>
                    <Select
                      inputId="relatorioatiradores"
                      name="relatorioatiradores"
                      onInputChange={this.pesquisaPessoa}
                      onChange={this.alteracaoSelectAtirador}
                      options={this.state.lista_de_atiradores}
                    />
                  </Col>
                  <Col sm={2}>
                    <Form.Label>Inicial</Form.Label>

                    <TextField
                      type="date"
                      id="data_inicial"
                      name="data_inicio_treino"
                      onChange={this.alteracao}
                    />
                  </Col>
                  <Col sm={2}>
                    <Form.Label>Final</Form.Label>

                    <TextField
                      type="date"
                      id="data_final"
                      name="data_inicio_treino"
                      onChange={this.alteracao}
                    />
                  </Col>
                  <Col sm={2}>
                    <Button
                      type="button"
                      style={{
                        outline: "none",
                        borderBottom: "2px solid rgb(10, 111, 148)",
                        color: "rgb(10, 111, 148)",
                        borderRadius: "0px",
                        marginRight: "4%",
                      }}
                      color="primary"
                      onClick={this.buscaAtirador}
                      disabled={
                        this.state.data_inicial === null ||
                        this.state.data_final === null
                      }
                    >
                      Buscar
                    </Button>

                    <Button
                      type="button"
                      style={{
                        outline: "none",
                        borderBottom: "2px solid rgb(10, 111, 148)",
                        color: "rgb(10, 111, 148)",
                        borderRadius: "0px",
                      }}
                      onClick={this.gerarPdfRelatorioAtiradores}
                    >
                      Imprimir
                    </Button>
                  </Col>
                </>
              ) : (
                <>
                  <Col sm={3}>
                    <Form.Label className="labelRelatorio">Eventos</Form.Label>
                    <Select
                      id="relatorioetapabuscaeventos"
                      name="relatorioetapabuscaeventos"
                      onChange={this.alteracaoSelectEvento}
                      options={this.state.lista_de_eventos}
                      onInputChange={this.pesquisaEventos}
                    />
                  </Col>
                  <Col sm={2}>
                    <Form.Label className="labelRelatorio">Etapas</Form.Label>
                    <Select
                      className="selectCss"
                      placeholder="Nº Etapa"
                      id="relatorioetapabuscaeventos"
                      name="relatorioetapabuscaeventos"
                      onChange={this.alteracaoSelectEtapa}
                      options={this.state.lista_de_etapas}
                      onKeyDown={this.pesquisaEtapas}
                    />
                  </Col>

                  <Col sm={2}>
                    <Button
                      type="button"
                      style={{
                        outline: "none",
                        marginTop: "19%",
                        borderBottom: "2px solid rgb(10, 111, 148)",
                        borderRadius: "0px",
                        marginRight: "2%",
                        color: "rgb(10, 111, 148)",
                      }}
                      color="inherit"
                      onClick={this.buscaEtapas}
                    >
                      Buscar
                    </Button>
                    <Button
                      type="button"
                      style={{
                        outline: "none",
                        marginTop: "19%",
                        borderBottom: "2px solid rgb(10, 111, 148)",
                        color: "rgb(10, 111, 148)",
                        borderRadius: "0px",
                      }}
                      onClick={this.gerarPdfRelatorioEtapas}
                      color="inherit"
                    >
                      Imprimir
                    </Button>
                  </Col>
                </>
              )}
            </div>
            <Row hidden={this.state.relatorio_descricao !== "Atiradores"}></Row>

            <Atiradores
              relatorio_descricao={this.state.relatorio_descricao}
              tabela_atiradores={this.state.tabela_atiradores}
              porcentagem_aproveitamento={this.state.porcentagem_aproveitamento}
            ></Atiradores>

            <Etapas
              relatorio_descricao={this.state.relatorio_descricao}
              tabela_etapas={this.state.tabela_etapas}
            ></Etapas>
          </Segment>

          <Segment clearing>
            <Header floated="right"></Header>
          </Segment>
        </Form>
      </div>
    );
  }
}
