import React, { Component } from "react";
import { Button, Dimmer, Loader, Segment, Select } from "semantic-ui-react";
import { Col, Form, Row, FormControl } from "react-bootstrap";
import { Tooltip } from "@material-ui/core";

//Componentes Projeto
import ListaRemessaBoleto from "./listaRemessa";
import ValidadorCamposPessoa from "../../../../utils/validadorPessoaEDadosBancarios";
import ListaPessoasInvalidas from "./listaPessoasInvalidadas";
import ListaPessoas from "./listaPessoas";

// Libs
import fileDownload from "js-file-download";
import moment from "moment";
import "moment-timezone";
import requisicoes from "../../../../utils/Requisicoes";
import api from "../../../../services/api";

//Enums
import Meses from "../../../../enum/Mes";
import TipoCobranca from "../../../../enum/TipoCobranca";

export default class Cobranca extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista_remessa: [],
      pagina_remessa: 0,
      total_pagina_remessa: 0,
      tipo_remessa: null,
      loading: false,
      instituicoes_cobranca: [],
      instituicao_cobranca: null,

      lista_pessoas: [],
      pessoas_invalidadas: [],
      mes_referencia: null,
      ano: moment().format("YYYY"),
      buscar: false,
      instituicao_cobranca_boleto: null,
      instituicao_cobranca_debito_em_conta: null,
    };
  }

  alteracao = (event) => {
    if (event.target.name === "tipo_remessa") {
      this.setState({ lista_pessoas: [] });
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  validaCamposPessoa = (pessoa) => {
    let pessoa_validada = true;
    ValidadorCamposPessoa(pessoa).map((e) => {
      return Object.entries(e).map((k, i) => {
        if (k[1] === false) {
          pessoa_validada = false;
        }
      });
    });
    if (pessoa_validada === true) {
      const lista = this.state.pessoas_invalidadas;
      let indice = lista.findIndex((element) => element.id === pessoa.id);
      lista.splice(indice, 1);
      this.setState({ pessoas_invalidadas: lista });
    }
  };

  buscaLancamentoParaRemessaBoleto = async () => {
    const retorno = await api.post(
      "/remessa/cobrancasNaoGeradoRemessa" + requisicoes.entidade,
      {
        pagina: this.state.pagina_remessa > 0 ? this.state.pagina_remessa : 1,
        tipo_cobranca: "boleto",
        instituicao_cobranca_id: this.state.instituicao_cobranca_boleto,
      },
      requisicoes.header
    );
    this.setState({ lista_remessa: retorno.data });
  };

  buscaLancamentoParaRemessaDebitoConta = async (event) => {
    if (this.state.ano === null) {
      alert("Informe um ano");
      return;
    }
    if (this.state.mes_referencia == null) {
      alert("Informe um mês de referência");
      return;
    }
    this.setState({ lista_pessoas: [] });
    const retorno = await api.post(
      "/remessaDebitoConta/cobrancasNaoGeradoRemessa" + requisicoes.entidade,
      {
        mes_referencia: this.state.mes_referencia,
        ano: this.state.ano,
        tipo_cobranca: TipoCobranca.find((t) => t.value === "debito_em_conta")
          .value,
        instituicao_cobranca: this.state.instituicao_cobranca_debito_em_conta,
      },
      requisicoes.header
    );
    retorno.data.forEach((element) => {
      if (
        this.state.lista_pessoas.find((p) => p.id === element.pessoa.id) ===
        undefined
      ) {
        this.state.lista_pessoas.push(element.pessoa);
        ValidadorCamposPessoa(element.pessoa).map((e) => {
          return Object.entries(e).map((k, i) => {
            if (k[1] === false) {
              if (
                this.state.pessoas_invalidadas.find(
                  (p) => p.id === element.pessoa.id
                ) === undefined
              ) {
                this.state.pessoas_invalidadas.push(element.pessoa);
              }
            }
          });
        });
      }
    });
    this.setState({ lista_remessa: retorno.data });
  };

  gerarRemessaBoleto = async () => {
    this.setState({ loading: true });
    const retorno = await api.post(
      "/remessa" + requisicoes.entidade,
      {
        pagina: this.state.pagina_remessa,
        tipo_cobranca: "boleto",
        instituicao_cobranca_id: this.state.instituicao_cobranca_boleto,
      },
      requisicoes.header
    );
    const numeroArquivo = await api.post(
      "/remessa/numeroArquivo" + requisicoes.entidade,
      {
        instituicao_cobranca_id: this.state.instituicao_cobranca_boleto,
      },
      requisicoes.header
    );
    fileDownload(
      retorno.data,
      moment().format("DDMM") + numeroArquivo.data + ".REM"
    );
    if (retorno.data !== null) {
      if (
        parseInt(this.state.pagina_remessa) <=
        parseInt(this.state.total_pagina_remessa)
      ) {
        this.setState({ loading: false });
        this.setState({ lista_remessa: [] });
      }
    }
  };

  gerarRemessaDebitoConta = async () => {
    this.setState({ loading: true });
    const retorno = await api.post(
      "/remessaDebitoConta" + requisicoes.entidade,
      {
        tipo_cobranca: "debito_em_conta",
        instituicao_cobranca: this.state.instituicao_cobranca_debito_em_conta,
        mes_referencia: this.state.mes_referencia,
        ano: this.state.ano,
      },
      requisicoes.header
    );
    const numeroArquivo = await api.post(
      "/remessaDebitoConta/numeroArquivo" + requisicoes.entidade,
      {
        instituicao_cobranca: this.state.instituicao_cobranca_debito_em_conta,
      },
      requisicoes.header
    );
    fileDownload(
      retorno.data,
      moment().format("DDMM") + numeroArquivo.data + ".REM"
    );
    if (retorno.data !== null) {
      this.setState({ loading: false });
      this.setState({ lista_remessa: [] });
    }
  };

  gerarRemessa = async () => {
    if (this.state.tipo_remessa === "boleto") {
      this.gerarRemessaBoleto();
    } else {
      this.gerarRemessaDebitoConta();
    }
  };

  buscarOnClick = async () => {
    this.state.buscar = true
    if (this.state.tipo_remessa === "boleto") {
      this.buscaLancamentoParaRemessaBoleto();
    } else {
      this.buscaLancamentoParaRemessaDebitoConta();
    }
  };
  render() {
    return (
      <div className="lista">
        <Row>
          <Col sm={6}>
            <Form.Label>Tipo</Form.Label>
            <Form.Control
              placeholder="Tipo"
              label="Tipo"
              id="tipo"
              as="select"
              name="tipo_remessa"
              onChange={this.alteracao}
              value={this.state.tipo_remessa}
              required
            >
              <option></option>
              <option value="DebitoConta">Debito Conta</option>
              <option value="boleto">Boleto</option>
            </Form.Control>
          </Col>
          {this.state.tipo_remessa &&
            this.state.tipo_remessa === "DebitoConta" && (
              <>
                <Col sm={6}>
                  <Form.Label>Instituição Cobrança</Form.Label>
                  <Select
                    name="instituicao_cobranca_debito_em_conta"
                    options={this.props.instituicoes_debito_em_conta}
                    onChange={(e, a) => {
                      this.setState({
                        instituicao_cobranca_debito_em_conta: a.value,
                        lista_remessa: [],
                      });
                    }}
                    value={this.state.instituicao_cobranca_debito_em_conta}
                    fluid
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label>Mês Referência</Form.Label>
                  <Form.Control
                    as="select"
                    name="mes_referencia"
                    onChange={this.alteracao}
                  >
                    <option></option>
                    {Meses.map((m) => (
                      <option value={m.value}>{m.label}</option>
                    ))}
                  </Form.Control>
                </Col>
                <Col sm={6}>
                  <Form.Label>Ano</Form.Label>
                  <FormControl
                    type="number"
                    onChange={this.alteracao}
                    name="ano"
                    value={this.state.ano}
                  />
                </Col>
              </>
            )}
          {this.state.tipo_remessa && this.state.tipo_remessa === "boleto" && (
            <>
              <Col sm={6}>
                <Form.Label>Instituicao Cobrança</Form.Label>
                <Select
                  name="instituicao_cobranca_boleto"
                  options={this.props.instituicoes_boleto}
                  onChange={(e, a) => {
                    this.setState({
                      instituicao_cobranca_boleto: a.value,
                      lista_remessa: [],
                    });
                  }}
                  value={this.state.instituicao_cobranca_boleto}
                  fluid
                />
              </Col>
            </>
          )}
          <Col sm={12}>
            <Row
              style={{
                marginTop: "25px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Col md="auto">
                <Button
                  onClick={this.buscarOnClick}
                  positive
                  disabled={
                    this.state.tipo_remessa === null ||
                    (this.state.tipo_remessa === "boleto" &&
                      this.state.instituicao_cobranca_boleto === null) ||
                    (this.state.tipo_remessa === "DebitoConta" &&
                      this.state.instituicao_cobranca_debito_em_conta === null)
                  }
                >
                  Buscar
                </Button>
              </Col>
              <Col md="auto">
                <Tooltip
                  title={
                    this.state.tipo_remessa === "boleto" &&
                      this.props.instituicoes_boleto.find(
                        (e) => e.value === this.state.instituicao_cobranca_boleto
                      )?.registro_automatico
                      ? "Remessa bloqueada. Registro automático."
                      : "Gerar remessa"
                  }
                  arrow
                >
                  <div>
                    <Button
                      onClick={this.gerarRemessa}
                      primary
                      disabled={
                        this.state.buscar === false ||
                        this.state.pessoas_invalidadas.length > 0 ||
                        this.state.tipo_remessa === null ||
                        (this.state.tipo_remessa === "boleto" &&
                          (this.state.instituicao_cobranca_boleto === null ||
                            this.props.instituicoes_boleto.find(
                              (e) =>
                                e.value ===
                                this.state.instituicao_cobranca_boleto
                            ).registro_automatico)) ||
                        (this.state.tipo_remessa === "DebitoConta" &&
                          this.state.instituicao_cobranca_debito_em_conta ===
                          null)
                      }
                    >
                      Gerar Remessa
                    </Button>
                  </div>
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col sm={12} style={{ textAlign: "right" }}>
            <Form.Label>
              {this.state.pagina_remessa +
                " / " +
                this.state.total_pagina_remessa}
            </Form.Label>
          </Col>
          {this.state.loading === true && (
            <Col sm={12}>
              <Segment size="massive">
                <Dimmer active inverted>
                  <Loader active inverted inline />
                </Dimmer>
              </Segment>
            </Col>
          )}
          {this.state.pessoas_invalidadas.length > 0 && (
            <Col>
              <ListaPessoasInvalidas
                pessoas_invalidadas={this.state.pessoas_invalidadas}
              />
            </Col>
          )}
          {this.state.lista_pessoas.length > 0 && (
            <Col>
              <ListaPessoas lista_pessoas={this.state.lista_pessoas} />
            </Col>
          )}
          {this.state.lista_remessa.length > 0 && (
            <Col>
              <ListaRemessaBoleto lista={this.state.lista_remessa} />
            </Col>
          )}
        </Row>
      </div>
    );
  }
}
