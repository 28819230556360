import React, { Component } from "react";
// import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
// import interactionPlugin from "@fullcalendar/interaction";
// import lingua from "@fullcalendar/core/locales/pt-br";
import {
  Container,
  Header,
  Checkbox,
  Button,
  Divider,
  Segment,
  Dimmer,
  Loader,
  Table,
  Modal,
  Icon,
  Popup,
} from "semantic-ui-react";
import { Row, Col, FormControl, Form } from "react-bootstrap";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import LoadingService from "../../../services/LoadingService";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";
import requisicoes from "../../../utils/Requisicoes";
import Select from "react-select";
import renomearChave from "../../../services/renomearChave";
import renomear2Chaves from "../../../services/renomear2Chaves";

export default class Servico extends Component {
  calendarComponentRef = React.createRef();

  constructor(props) {
    LoadingService.load((v) => this.setState({ isLoading: true }));

    super(props);
    this.state = {
      id: "",
      nome: "",
      valor: "",
      calendarWeekends: true,
      calendarEvents: [],
      dataInicio: "",
      dataFinal: "",
      diasFiltro: [],
      diasEspeciais: [],
      diasDaSemana: [],
      selectDiasDaSemana: [],
      gruposServico: [],
      grupo_servico: null,
      isLoading: false,
      listaHorarios: [],
    };

    this.alteracao = this.alteracao.bind(this);
    this.defineListaEventos = this.defineListaEventos.bind(this);
    this.carregaListaEventos = this.carregaListaEventos.bind(this);
    this.buscaDiasDaSemana = this.buscaDiasDaSemana.bind(this);
    this.buscaDiasDaSemana();
    this.visualizacao();
    this.listaGrupoServico();
  }

  listaGrupoServico = async () => {
    const resposta = await api.post(
      "/grupoServico/lista" + requisicoes.entidade,
      this.props.location.query,
      requisicoes.header
    );
    this.setState({ gruposServico: resposta.data });
  };

  buscaDiasDaSemana = async () => {
    const resposta = await api.post(
      "/diaDaSemanan/lista" + requisicoes.entidade,
      this.props.location.query,
      requisicoes.header
    );
    for (var i = 0; i < resposta.data.lenght; i++) {
      this.state.selectDiasDaSemana.push(false);
    }
    this.setState({ diasDaSemana: resposta.data });
  };

  visualizacao = async () => {
    if (typeof this.props.location.query != "undefined") {
      const resposta = await api.post(
        "/servico/visualiza" +
          requisicoes.entidade +
          "&id=" +
          this.props.location.query.id,
        this.props.location.query,
        requisicoes.header
      );

      this.setState({
        id: resposta.data.id,
        diasEspeciais: resposta.data.datas_especiais,
        diasFiltro: resposta.data.dias_da_semana,
        nome: resposta.data.nome,
        valor: resposta.data.valor,
        dataInicio: resposta.data.dataInicio,
        dataFinal: resposta.data.dataFinal,
        grupo_servico: resposta.data.grupo_servico_id,
      });

      if (resposta.data.centro_de_custo !== null) {
        let b = renomearChave(resposta.data.centro_de_custo, "id", "value");
        b = renomear2Chaves(b, "codigo", "descricao", "label");
        this.setState({ centro_de_custo_id: resposta.data.centro_de_custo.id });
        this.setState({ centro_de_custo: b });
      }
      if (resposta.data.elemento_de_custo !== null) {
        let a = renomearChave(resposta.data.elemento_de_custo, "id", "value");
        a = renomear2Chaves(a, "codigo", "descricao", "label");
        this.setState({
          elemento_de_custo_id: resposta.data.elemento_de_custo.id,
        });
        this.setState({ elemento_de_custo: a });
      }

      const horarios = await api.post(
        "/horarioServico/horarioPorServico" + requisicoes.entidade,
        { id: resposta.data.id },
        requisicoes.header
      );
      this.setState({ listaHorarios: horarios.data });

      this.state.diasFiltro.forEach((e) => {
        switch (e.nome) {
          case "Domingo":
            this.state.selectDiasDaSemana[0] = true;
            break;
          case "Segunda":
            this.state.selectDiasDaSemana[1] = true;
            break;
          case "Terça":
            this.state.selectDiasDaSemana[2] = true;
            break;
          case "Quarta":
            this.state.selectDiasDaSemana[3] = true;
            break;
          case "Quinta":
            this.state.selectDiasDaSemana[4] = true;
            break;
          case "Sexta":
            this.state.selectDiasDaSemana[5] = true;
            break;
          case "Sabado":
            this.state.selectDiasDaSemana[6] = true;
            break;
          default:
            break;
        }
      });
      this.carregaListaEventos();
    }
  };

  alteracao(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  alteracaoSelectCentroCusto = async (event, id) => {
    this.setState({ centro_de_custo_id: event.value });
    this.setState({ centro_de_custo: event });
    api
      .post(
        "/centroDeCusto/visualiza" + requisicoes.entidade,
        { id: event.value },
        requisicoes.header
      )
      .then((resp) => {
        let lista0 = [];
        if (resp.data.elementos.length > 0) {
          resp.data.elementos.forEach((e) => {
            let objeto = renomearChave(e, "id", "value");
            objeto = renomear2Chaves(objeto, "codigo", "descricao", "label");
            lista0.push(objeto);
          });
          this.setState({ optionsEC: lista0 });
        }
      });
  };

  alteracaoSelectElementoCusto = async (event, id) => {
    this.setState({ elemento_de_custo_id: event.value });
    this.setState({ elemento_de_custo: event });
  };

  alteracaoDias = async (value) => {
    let dataInicial = new Date(this.state.dataInicio);
    this.state.diasDaSemana.forEach((e, i) => {
      if (e.id === parseInt(value)) {
        let lista = Array.from(this.state.selectDiasDaSemana);
        if (lista[i] === true) {
          lista[i] = false;
        } else {
          lista[i] = true;
        }
        this.setState({ selectDiasDaSemana: lista });
        if (!this.state.diasFiltro.find((e) => e.id === parseInt(value))) {
          this.state.diasFiltro.push({
            id: value,
            nome: e.nome,
          });
        } else {
          let indice = this.state.diasFiltro.findIndex(
            (element) => element.id === parseInt(value)
          );
          this.state.diasFiltro.splice(indice, 1);
          const diaEspecial = this.state.diasEspeciais.find(
            (e) =>
              new Date(e.data).getFullYear() === dataInicial.getFullYear() &&
              new Date(e.data).getMonth() === dataInicial.getMonth() &&
              new Date(e.data).getDate() === dataInicial.getDate()
          );
          if (typeof diaEspecial != "undefined") {
            if (diaEspecial.temEvento === true) {
              indice++;
              lista.push({
                id: indice,
                title: this.state.nome,
                start: new Date(dataInicial),
              });
            } else if (diaEspecial.temEvento === false) {
              const ind = indice;
              let i = lista.findIndex(
                (element) => element.id === parseInt(ind)
              );
              lista.splice(i, 1);
            }
          }
        }
      }
    });
  };

  carregaListaEventos = async () => {
    const resposta = await api.post(
      "/diaDaSemanan/lista" + requisicoes.entidade,
      this.props.location.query,
      requisicoes.header
    );
    let listaDiasResposta = resposta.data;
    let dataInicial = new Date(this.state.dataInicio);
    dataInicial.setDate(dataInicial.getDate() + 1);
    let dataFinal = new Date(this.state.dataFinal);
    var diasSemana = [];
    listaDiasResposta.forEach((e) => {
      diasSemana.push(e.id);
    });
    let lista = [];
    var indice = 1;
    var indiceParaLoop = 0;
    var validacao = true;
    let ignoraInsert = false;
    let dataF = new Date(dataFinal.setDate(dataFinal.getDate() + 2));
    //começa pelo dia inicial definido e vai até o ultimo dia
    while (validacao) {
      if (
        this.state.diasFiltro.find(
          (e) => e.id === diasSemana[dataInicial.getDay()]
        )
      ) {
        ignoraInsert = false;
        let dataConvert = new Date(dataInicial);
        dataConvert = new Date(dataConvert.setDate(dataConvert.getDate() - 1));
        const diaEspecial = this.state.diasEspeciais.find(
          (e) =>
            new Date(e.data).getFullYear() === dataConvert.getFullYear() &&
            new Date(e.data).getMonth() === dataConvert.getMonth() &&
            new Date(e.data).getDate() === dataConvert.getDate()
        );
        if (diaEspecial !== undefined) {
          if (diaEspecial.temEvento === false) {
            ignoraInsert = true;
          }
        }
        if (ignoraInsert === false) {
          lista.push({
            id: indice,
            title: this.state.nome,
            start: new Date(dataInicial),
          });
        }
        indice++;
      }

      const diaEspecial = this.state.diasEspeciais.find(
        (e) =>
          new Date(e.data).getFullYear() === dataInicial.getFullYear() &&
          new Date(e.data).getMonth() === dataInicial.getMonth() &&
          new Date(e.data).getDate() === dataInicial.getDate()
      );
      if (diaEspecial !== undefined) {
        if (diaEspecial.temEvento === true) {
          indice++;
          let dataConvert = new Date(dataInicial);
          dataConvert = new Date(
            dataConvert.setDate(dataConvert.getDate() + 1)
          );
          lista.push({
            id: indice,
            title: this.state.nome,
            start: dataConvert,
          });
        }
      }
      dataInicial.setDate(dataInicial.getDate() + 1);
      indiceParaLoop++;
      if (dataInicial.getTime() === dataF || indiceParaLoop === 10000) {
        validacao = false;
      }
    }
    this.setState({ calendarEvents: lista });
  };

  defineListaEventos = async (event) => {
    event.preventDefault();
    this.setState({ calendarEvents: [] });

    if (this.state.id !== "") {
      const remove = await api.post(
        "/servico/removeLigacoes" +
          requisicoes.entidade +
          "&id=" +
          this.state.id,
        {},
        requisicoes.header
      );
      this.setState({ diasEspeciais: [] });
    }
    let dataInicial = new Date(this.state.dataInicio);
    let dataFinal = new Date(this.state.dataFinal);
    var diasSemana = [];
    this.state.diasDaSemana.forEach((e) => {
      diasSemana.push(e.id.toString());
    });
    let lista = [];
    var indice = 1;
    var indiceParaLoop = 0;
    var validacao = true;
    let dataF = new Date(dataFinal.setDate(dataFinal.getDate() + 1));
    //começa pelo dia inicial definido e vai até o ultimo dia
    while (validacao) {
      dataInicial.setDate(dataInicial.getDate() + 1);
      if (
        this.state.diasFiltro.find(
          (e) => parseInt(e.id) === parseInt(diasSemana[dataInicial.getDay()])
        )
      ) {
        lista.push({
          id: indice,
          title: this.state.nome,
          start: new Date(dataInicial),
        });
        indice++;
      }
      indiceParaLoop++;
      if (
        (dataInicial.getDate() === dataF.getDate() &&
          dataInicial.getMonth() === dataF.getMonth() &&
          dataInicial.getFullYear() === dataF.getFullYear()) ||
        indiceParaLoop === 10000
      ) {
        validacao = false;
      }
    }
    await this.setState({ calendarEvents: lista });
  };

  remove = (e) => {
    var diasSemana = [];
    this.state.diasDaSemana.forEach((e) => {
      diasSemana.push(e.id.toString());
    });
    let ids = [];
    this.state.calendarEvents.map((e) => ids.push(e.id));
    if (
      this.state.diasFiltro.find(
        (e2) => parseInt(e2.id) === parseInt(diasSemana[e.event.start.getDay()])
      )
    ) {
      //verifica se ja esta setado nos dias especiais
      if (
        this.state.diasEspeciais.find(
          (element) =>
            new Date(element.data).getDate() === e.event.start.getDate() &&
            new Date(element.data).getMonth() === e.event.start.getMonth() &&
            new Date(element.data).getFullYear() === e.event.start.getFullYear()
        )
      ) {
        //se estiver dentro do dia de semana e já está na lista dos dias especiais remove
        let indice = this.state.diasEspeciais.findIndex(
          (element) => element.id === parseInt(e.event.id)
        );
        this.state.diasEspeciais.splice(indice, 1);
        const eventos = this.state.diasEspeciais;
        this.setState({ diasEspeciais: eventos });
      } else {
        this.state.diasEspeciais.push({
          id: Math.max(...ids) + 1,
          data:
            e.event.start.getFullYear() +
            "-" +
            ((e.event.start.getMonth() + 1).toString().length === 1
              ? "0" + (e.event.start.getMonth() + 1)
              : e.event.start.getMonth() + 1) +
            "-" +
            (e.event.start.getDate().toString().length === 1
              ? "0" + e.event.start.getDate()
              : e.event.start.getDate()),
          temEvento: false,
        });
      }
    } else {
      let indice = this.state.diasEspeciais.findIndex(
        (element) => element.id === parseInt(e.event.id)
      );
      this.state.diasEspeciais.splice(indice, 1);
      const eventos = this.state.diasEspeciais;
      this.setState({ diasEspeciais: eventos });
    }
    let indice = this.state.calendarEvents.findIndex(
      (element) => element.id === parseInt(e.event.id)
    );
    this.state.calendarEvents.splice(indice, 1);
    const eventos = this.state.calendarEvents;
    this.setState({ calendarEvents: eventos });
    e.event.remove();
  };

  handleDateClick = (arg) => {
    let ids = [];
    this.state.calendarEvents.map((e) => ids.push(e.id));
    var diasSemana = [];
    this.state.diasDaSemana.forEach((e) => {
      diasSemana.push(e.id.toString());
    });
    let eventoSelecionado = this.state.calendarEvents.find(
      (e) =>
        new Date(e.start).getDate() === arg.date.getDate() &&
        new Date(e.start).getMonth() === arg.date.getMonth() &&
        new Date(e.start).getFullYear() === arg.date.getFullYear()
    );
    if (eventoSelecionado === undefined) {
      //condição se o dia não esta no dia da semana definido
      if (
        this.state.diasFiltro.find(
          (e2) => parseInt(e2.id) !== parseInt(diasSemana[arg.date.getDay()])
        )
      ) {
        //verifica se ja esta setado nos dias especiais
        const temListaDiasEspeciais = this.state.diasEspeciais.find(
          (e) =>
            parseInt(e.data.substring(8, 10)) === arg.date.getDate() &&
            parseInt(e.data.substring(5, 7)) === arg.date.getMonth() &&
            parseInt(e.data.substring(0, 4)) === arg.date.getFullYear()
        );
        if (temListaDiasEspeciais === undefined) {
          this.state.diasEspeciais.push({
            id: Math.max(...ids) + 1,
            data:
              arg.date.getFullYear() +
              "-" +
              ((arg.date.getMonth() + 1).toString().length === 1
                ? "0" + (arg.date.getMonth() + 1)
                : arg.date.getMonth() + 1) +
              "-" +
              (arg.date.getDate().toString().length === 1
                ? "0" + arg.date.getDate()
                : arg.date.getDate()),
            temEvento: true,
          });
        }
        this.setState({
          calendarEvents: this.state.calendarEvents.concat({
            id: Math.max(...ids) + 1,
            title: this.state.nome,
            start: new Date(arg.date),
          }),
        });
      }
    } else {
      // adiciona no calendario e verificar se está entre o dia de inicio e dia final
      if (
        new Date(arg.date) > new Date(this.state.dataInicio) &&
        new Date(arg.date) < new Date(this.state.dataFinal)
      ) {
        this.setState({
          calendarEvents: this.state.calendarEvents.concat({
            id: Math.max(...ids) + 1,
            title: this.state.nome,
            start:
              arg.date.getFullYear() +
              "-" +
              (arg.date.getMonth().lenght === 1
                ? "0" + arg.date.getMonth()
                : arg.date.getMonth()) +
              "-" +
              (arg.date.getDate().lenght === 1
                ? "0" + arg.date.getDate()
                : arg.date.getDate()),
          }),
        });
      }
      if (
        this.state.diasFiltro.find(
          (e2) => parseInt(e2.id) === parseInt(diasSemana[arg.date.getDay()])
        )
      ) {
        let ids = [];
        this.state.calendarEvents.map((e) => ids.push(e.id));
        //verifica se ja esta setado nos dias especiais
        if (
          this.state.diasEspeciais.find(
            (e) =>
              new Date(e.data).getDate() !== arg.date.getDate() &&
              new Date(e.data).getMonth() !== arg.date.getMonth() &&
              new Date(e.data).getFullYear() !== arg.date.getFullYear()
          )
        ) {
          this.state.diasEspeciais.push({
            id: Math.max(...ids) + 1,
            data:
              arg.date.getFullYear() +
              "-" +
              (arg.date.getMonth().lenght === 1
                ? "0" + arg.date.getMonth()
                : arg.date.getMonth()) +
              "-" +
              (arg.date.getDate().lenght === 1
                ? "0" + arg.date.getDate()
                : arg.date.getDate()),
            temEvento: false,
          });
        }
      }
    }
  };

  pesquisaCentroCusto = (newValue) => {
    api
      .post(
        "/centroDeCusto/pesquisa" + requisicoes.entidade,
        { pesquisa: newValue },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomear2Chaves(objeto, "codigo", "descricao", "label");
          lista.push(objeto);
        });
        this.setState({ optionsCC: lista });
      });
  };

  handleSubmit = (event) => {
    this.submit();
    event.preventDefault();
  };

  submit = async () => {
    let retorno = "";
    const dados = {
      nome: this.state.nome,
      dataInicio: this.state.dataInicio,
      valor: this.state.valor,
      dataFinal: this.state.dataFinal,
      diasDaSemana: this.state.diasFiltro,
      datasEspeciais: this.state.diasEspeciais,
      grupo_servico_id: this.state.grupo_servico,
      listaHorarios: this.state.listaHorarios,
      centro_de_custo_id: this.state.centro_de_custo_id,
      elemento_de_custo_id: this.state.elemento_de_custo_id,
    };
    if (this.state.id !== "") {
      retorno = await api.put(
        "/servico/alterar" + requisicoes.entidade + "&id=" + this.state.id,
        dados,
        requisicoes.header
      );
    } else {
      retorno = await api.post(
        "/servico/salvar" + requisicoes.entidade,
        dados,
        requisicoes.header
      );
    }
    if (retorno != null) {
      window.location.href = "/servicos";
    }
  };

  excluir = async () => {
    let retorno = "";
    if (this.state.id !== "") {
      retorno = await api.post(
        "/servico/excluir" + requisicoes.entidade + "&id=" + this.state.id,
        {},
        requisicoes.header
      );
    }
    if (retorno != null) {
      window.location.href = "/servicos";
    }
  };

  alteracaoHorarioInicio = async (value, index) => {
    let listaHorarios = this.state.listaHorarios;
    listaHorarios[index].horaInicio = value;
    this.setState({ listaHorarios: listaHorarios });
  };

  alteracaoHorarioFim = async (value, index) => {
    let listaHorarios = this.state.listaHorarios;
    listaHorarios[index].horaFim = value;
    this.setState({ listaHorarios: listaHorarios });
  };

  alteracaoVaga = async (value, index) => {
    let listaHorarios = this.state.listaHorarios;
    listaHorarios[index].vaga = value;
    this.setState({ listaHorarios: listaHorarios });
  };

  submitCentroElemento = async () => {};

  adicionar = async () => {
    let lista = this.state.listaHorarios;
    lista.push({
      id: "",
      horaInicio: "",
      horaFim: "",
      vaga: "",
    });
    this.setState({ listaHorarios: lista });
  };

  remover = async (horario, i) => {
    let horarios = this.state.listaHorarios;
    let listaRetorno = [];

    if (horario.id !== "") {
      var localStorage = window.localStorage;
      var token = localStorage.getItem("token_clube");
      var e = localStorage.getItem("e_clube");
      await api.post(
        "/horarioServico/excluir?e=" + e + "&id=" + horario.id,
        {},
        { headers: { token: "Baer " + token } }
      );
      const set = new Set(horarios);
      set.delete(horario);
      set.forEach((element) => {
        listaRetorno.push(element);
      });
    } else {
      listaRetorno = this.state.listaHorarios;
      listaRetorno.splice(i, 1);
    }
    this.setState({ listaHorarios: listaRetorno });
  };
  render() {
    return (
      <div className="pagina">
        {this.state.isLoading ? (
          <div>
            <Header as="h1" dividing>
              Serviço
            </Header>
            <Form onSubmit={this.handleSubmit}>
              <Row>
                <Col sm={6}>
                  <Form.Label>Nome</Form.Label>
                  <FormControl
                    placeholder="Nome"
                    aria-label="Nome"
                    aria-describedby="basic-addon1"
                    name="nome"
                    required
                    defaultValue={this.state.nome}
                    onChange={this.alteracao}
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label>Valor</Form.Label>
                  <FormControl
                    placeholder="Valor"
                    aria-label="Valor"
                    aria-describedby="basic-addon1"
                    name="valor"
                    required
                    value={this.state.valor}
                    onChange={this.alteracao}
                    type="number"
                  />
                </Col>

                <Col sm={6}>
                  <Form.Label>Dia de Inicio</Form.Label>
                  <FormControl
                    type="date"
                    aria-describedby="basic-addon1"
                    name="dataInicio"
                    onChange={this.alteracao}
                    required
                    value={
                      this.state.dataInicio &&
                      this.state.dataInicio.toString().substr(0, 10)
                    }
                  />
                </Col>

                <Col sm={6}>
                  <Form.Label>Dia do Fim</Form.Label>
                  <FormControl
                    type="date"
                    aria-describedby="basic-addon1"
                    name="dataFinal"
                    onChange={this.alteracao}
                    required
                    value={
                      this.state.dataFinal &&
                      this.state.dataFinal.toString().substr(0, 10)
                    }
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label>Grupo De Serviço</Form.Label>
                  <Form.Control
                    as="select"
                    name="grupo_servico"
                    checked={this.state.grupo_servico}
                    onChange={this.alteracao}
                    defaultValue={this.state.grupo_servico}
                    required
                  >
                    <option></option>
                    {this.state.gruposServico.map((e, i) =>
                      this.state.grupo_servico !== null &&
                      e.id === this.state.grupo_servico ? (
                        <option key={i} value={e.id} selected>
                          {e.descricao}
                        </option>
                      ) : (
                        <option key={i} value={e.id}>
                          {e.descricao}
                        </option>
                      )
                    )}
                  </Form.Control>
                </Col>

                <Col sm={1}>
                  {/* caracter vazio abaixo */}
                  <Form.Label>⠀⠀⠀⠀⠀⠀⠀⠀</Form.Label>
                  <Link to={{ pathname: "/grupoServico/lista" }}>
                    <Button positive icon="add" size="tiny"></Button>
                  </Link>
                </Col>
                <Col
                  sm={1}
                  className="offset-4"
                  onMouseOver={() => this.setState({ gira: true })}
                  onMouseLeave={() => this.setState({ gira: false })}
                >
                  <Button
                    circular
                    icon={
                      <Icon name="cog" loading={this.state.gira} circular />
                    }
                    size="big"
                    onClick={() => this.setState({ modalCentroElemento: true })}
                    type="button"
                    color="grey"
                    style={{ marginTop: "35%", padding: 0 }}
                  />
                </Col>
              </Row>

              <Divider />

              <div id="definicaoHorario">
                <Header as="h4">Dias da Semana</Header>
                <Container>
                  <Row>
                    <Col sm={11}>
                      <Row>
                        {this.state.diasDaSemana.map((e, i) => {
                          return (
                            <Checkbox
                              id={i}
                              className="checkboxInline"
                              label={e.nome}
                              value={e.id}
                              name="diasFiltro"
                              onChange={() => this.alteracaoDias(e.id)}
                              checked={this.state.selectDiasDaSemana[i]}
                            />
                          );
                        })}
                      </Row>
                    </Col>
                    <Col sm={1}>
                      <Row>
                        <Button
                          primary
                          type="button"
                          onClick={this.defineListaEventos}
                        >
                          Define
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </Container>

                <Divider />
                {/* <Segment clearing>
                  <FullCalendar
                    locale={lingua}
                    defaultView="dayGridMonth"
                    header={{
                      left: "prev,next today",
                      center: "title",
                      right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                    }}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    ref={this.calendarComponentRef}
                    weekends={this.state.calendarWeekends}
                    events={this.state.calendarEvents}
                    dateClick={this.handleDateClick}
                    eventClick={this.remove}
                    displayEventTime={false}
                  />
                </Segment> */}
                <Segment>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Hora Inicio</Table.HeaderCell>
                        <Table.HeaderCell>Hora Fim</Table.HeaderCell>
                        <Table.HeaderCell>Vagas</Table.HeaderCell>
                        <Table.HeaderCell width={1}>
                          <Button
                            type="button"
                            positive
                            size="tiny"
                            onClick={this.adicionar}
                            icon="add"
                          ></Button>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.state.listaHorarios.map((horario, i) => (
                        <Table.Row key={i}>
                          <Table.Cell>
                            <FormControl
                              type="time"
                              placeholder="Horario Inicio"
                              aria-label="Horario Inicio"
                              aria-describedby="basic-addon1"
                              name="horaInicio"
                              fluid
                              onChange={(e) =>
                                this.alteracaoHorarioInicio(e.target.value, i)
                              }
                              value={horario.horaInicio}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <FormControl
                              type="time"
                              placeholder="Horario Fim"
                              aria-label="Horario Fim"
                              aria-describedby="basic-addon1"
                              name="horaFim"
                              fluid
                              onChange={(e) =>
                                this.alteracaoHorarioFim(e.target.value, i)
                              }
                              value={horario.horaFim}
                            />
                          </Table.Cell>
                          <Table.Cell width={2}>
                            <FormControl
                              name="vaga"
                              fluid
                              onChange={(e) =>
                                this.alteracaoVaga(e.target.value, i)
                              }
                              value={horario.vaga}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Button
                              type="button"
                              negative
                              size="tiny"
                              value={i}
                              onClick={() => this.remover(horario, i)}
                              icon="remove"
                            ></Button>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Segment>
              </div>

              <Divider />
              <Segment clearing>
                <Header floated="right">
                  <Link to="/servicos">
                    <Button type="button">Voltar</Button>
                  </Link>
                  <Button negative onClick={this.excluir}>
                    Deletar
                  </Button>
                  <Button positive type="submit">
                    Salvar
                  </Button>
                </Header>
              </Segment>
            </Form>
          </div>
        ) : (
          <Dimmer active inverted>
            <Loader content="Carregando" />
          </Dimmer>
        )}

        <Modal
          dimmer="blurring"
          open={this.state.modalCentroElemento}
          style={{ overflow: "visible" }}
        >
          <Modal.Header>Vínculo de Centro e Elemento de custo</Modal.Header>
          <Modal.Content>
            <Row>
              <Col sm={6}>
                <Form.Label>Centro de Custo</Form.Label>
                <Select
                  placeholder="Digite para buscar"
                  name="centro_de_custo"
                  value={this.state.centro_de_custo}
                  onChange={this.alteracaoSelectCentroCusto}
                  options={this.state.optionsCC}
                  onInputChange={this.pesquisaCentroCusto}
                  defaultValue={this.state.centro_de_custo}
                />
              </Col>
              <Col sm={6}>
                <Form.Label>Elemento de Custo</Form.Label>
                <Select
                  placeholder="Digite para buscar"
                  name="elemento_de_custo"
                  value={this.state.elemento_de_custo}
                  onChange={this.alteracaoSelectElementoCusto}
                  options={this.state.optionsEC}
                  defaultValue={this.state.elemento_de_custo}
                />
              </Col>
            </Row>
          </Modal.Content>
          <Modal.Actions>
            <Button
              animated="vertical"
              onClick={() => this.setState({ modalCentroElemento: false })}
            >
              <Button.Content hidden>
                <Icon name="cancel" />
              </Button.Content>
              <Button.Content visible>Cancelar</Button.Content>
            </Button>
            <Button
              animated
              positive
              onClick={() => this.setState({ modalCentroElemento: false })}
            >
              <Button.Content hidden>
                <Icon name="save" />
              </Button.Content>
              <Button.Content visible>Salvar</Button.Content>
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
