import React, { Component } from "react";
import { Container, Header, Table, Button, Modal } from "semantic-ui-react";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import api from "../../../../../services/api";
import renomearChave from "../../../../../services/renomearChave";
import Requisicoes from "../../../../../utils/Requisicoes";
import TipoLancamento from "../../../../../enum/TipoLancamento";
import moment from "moment-timezone";
import Checkbox from "muicss/lib/react/checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default class CancelamentoDivida extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pessoa: null,
      options: [],
      lancamentos: [],
      open: false,
      motivo: null,
      toggleAll: false,
    };
    // this.myViewer = React.createRef();
    // this.alteracao = this.alteracao.bind(this);
  }
  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  converterParaLabel = (objetoLista) => {
    let lista = [];
    objetoLista.forEach((element) => {
      let objeto = renomearChave(element, "id", "value");
      objeto.label =
        renomearChave(objeto.acao, "codigo", "label").label +
        " - " +
        objeto.nome;
      if (element.titular_id !== null) {
        objeto.label += "    - Dependente";
      } else if (element.acao === undefined || element.acao === null) {
        objeto.label += "    - Sem ação";
      } else {
        objeto.label += "    - Titular";
      }
      lista.push(objeto);
    });
    return lista;
  };

  converterParaLabelPessoa = (objetoLista) => {
    let lista2 = [];
    objetoLista.forEach((element) => {
      if (element.acao !== undefined) {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        if (element.titular_id !== null) {
          objeto.label += "    - Dependente";
        } else if (element.acao === undefined || element.acao === null) {
          objeto.label += "    - Sem ação";
        } else {
          objeto.label += "    - Titular";
        }
        lista2.push(objeto);
      } else {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista2.push(objeto);
      }
    });
    return lista2;
  };

  pesquisaPessoa = async () => {
    let term = document.getElementById("pesquisaPessoa").value;
    if (term.toString().indexOf("%") !== -1) {
      term = document
        .getElementById("pesquisaPessoa")
        .value.toString()
        .replace("%", "");
      document.getElementById("pesquisaPessoa").value = term;
      if (document.getElementById("porcentagem").innerText === "%") {
        document.getElementById("porcentagem").innerText = "";
      } else {
        document.getElementById("porcentagem").innerText = "%";
      }
    }
    setTimeout(async () => {
      if (isFinite(String(term))) {
        await api
          .post(
            "/pessoa/pesquisaPorCodigoAcaoValorExato" + Requisicoes.entidade,
            { pesquisa: document.getElementById("pesquisaPessoa").value },
            Requisicoes.header
          )
          .then((retorno) => {
            this.setState({ options: this.converterParaLabel(retorno.data) });
          });
      } else {
        await api
          .post(
            // "/pessoa/pesquisaNome" + Requisicoes.entidade,
            "/pessoa/pesquisa" + Requisicoes.entidade,
            { pesquisa: document.getElementById("pesquisaPessoa").value },
            Requisicoes.header
          )
          .then((retorno) => {
            this.setState({
              options: this.converterParaLabelPessoa(retorno.data),
            });
          });
      }
    }, 200);
  };

  lancamentoPorPessoa = async (event, v) => {
    let retorno = await api.post(
      "/lancamentoConta/lancamentoCanceladoPorPessoa" + Requisicoes.entidade,
      { pessoa_id: v.value },
      Requisicoes.header
    );
    this.setState({ lancamentos: retorno.data });
  };

  toggleCheckbox = (e) => {
    let lancamentos = this.state.lancamentos;
    if (lancamentos[e].checked === undefined) {
      lancamentos[e].checked = true;
    } else {
      lancamentos[e].checked = !lancamentos[e].checked;
    }
    this.setState({ lancamentos: lancamentos });
  };

  toggleAllCheckboxes = () => {
    let lancamentos = this.state.lancamentos;
    for (let i = 0; i < lancamentos.length; i++) {
      const element = lancamentos[i];
      element.checked = !this.state.toggleAll;
    }
    this.setState({
      toggleAll: !this.state.toggleAll,
      lancamentos: lancamentos,
    });
  };

  reativacao = async () => {
    if (this.state.motivo === null) {
      alert("Favor Informar um Motivo");
    } else {
      let lista = [];
      for (let i = 0; i < this.state.lancamentos.length; i++) {
        const lancamento = this.state.lancamentos[i];
        if (lancamento.checked !== true) {
          continue;
        } else {
          lista.push(lancamento);
        }
      }

      if (lista.length > 0) {
        await api.post(
          "/lancamentoConta/reativarVarios" + Requisicoes.entidade,
          {
            lancamentos: lista,
            motivo: this.state.motivo,
            tela_usada: "gerenciar_dividas",
          },
          Requisicoes.header
        );
      }

      window.location.href = "/administrativo";
    }
  };

  render() {
    return (
      <div style={{ paddingBottom: 100 }}>
        <Row>
          <Col sm={6}>
            <Form.Label>Pessoa</Form.Label>
            <Row>
              <Col sm={1}>
                <Form.Label id="porcentagem"></Form.Label>
              </Col>
              <Col sm={11}>
                <Autocomplete
                  id="pesquisaPessoa"
                  options={this.state.options}
                  getOptionLabel={(option) => option.label}
                  onKeyUp={this.pesquisaPessoa}
                  onChange={this.lancamentoPorPessoa}
                  renderInput={(params) => (
                    <TextField {...params} label="Busca" size="small" />
                  )}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={6} style={{ paddingTop: "25px", float: "right" }}>
            <Button
              positive
              onClick={() => this.setState({ open: true })}
              style={{ float: "right" }}
            >
              Reativar Dívidas
            </Button>
          </Col>
          <Col sm={12} style={{ paddingTop: 10 }}>
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={8}>Serviço</Table.HeaderCell>
                  <Table.HeaderCell>Nosso número</Table.HeaderCell>

                  <Table.HeaderCell>Vencimento</Table.HeaderCell>
                  <Table.HeaderCell width={3} textAlign="right">
                    Valor
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign="center">
                    <Checkbox
                      label=""
                      onClick={() => this.toggleAllCheckboxes()}
                      checked={this.state.toggleAll}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.lancamentos.map((e, i) => (
                  <Table.Row key={i}>
                    <Table.Cell width={8}>
                      {e.servico !== null
                        ? e.servico.nome
                        : TipoLancamento.find(
                            (t) => t.value === e.tipo_lancamento
                          ) !== undefined
                        ? TipoLancamento.find(
                            (t) => t.value === e.tipo_lancamento
                          ).label
                        : ""}
                    </Table.Cell>
                    <Table.Cell>{e.numero_titulo}</Table.Cell>
                    <Table.Cell width={2}>
                      {e.data_vencimento !== null &&
                        moment(e.data_vencimento).format("DD/MM/YYYY")}
                    </Table.Cell>
                    <Table.Cell width={2} textAlign="right">
                      {parseFloat(e.valor).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Checkbox
                        label=""
                        onClick={() => this.toggleCheckbox(i)}
                        // defaultValue={e.id}
                        checked={e.checked}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>

              <Table.Footer></Table.Footer>
            </Table>
          </Col>
        </Row>

        <Modal
          open={this.state.open}
          onClose={() => window.location.reload()}
          centered={false}
        >
          <Header content="Reativar dívidas" />
          <Modal.Content>
            <Form.Label>Motivo da reativação</Form.Label>
            <FormControl
              aria-describedby="basic-addon1"
              type="text"
              name="motivo"
              onChange={this.alteracao}
            />
            <h4>
              Dívidas selecionadas a serem{" "}
              <span style={{ color: "green" }}>reativadas</span>
            </h4>
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={8}>Serviço</Table.HeaderCell>
                  <Table.HeaderCell>Vencimento</Table.HeaderCell>
                  <Table.HeaderCell width={3} textAlign="right">
                    Valor
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.lancamentos.map((e, i) => (
                  <>
                    {e.checked && (
                      <Table.Row key={i}>
                        <Table.Cell width={8}>
                          {e.servico !== null
                            ? e.servico.nome
                            : TipoLancamento.find(
                                (t) => t.value === e.tipo_lancamento
                              ) !== undefined
                            ? TipoLancamento.find(
                                (t) => t.value === e.tipo_lancamento
                              ).label
                            : ""}
                        </Table.Cell>
                        <Table.Cell width={2}>
                          {e.data_vencimento !== null &&
                            moment(e.data_vencimento).format("DD/MM/YYYY")}
                        </Table.Cell>
                        <Table.Cell width={2} textAlign="right">
                          {parseFloat(e.valor).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </>
                ))}
              </Table.Body>
            </Table>
          </Modal.Content>
          <Modal.Actions>
            <h4>Confirma Reativação ?</h4>
            <Button type="button" variant="light" onClick={this.handleClose}>
              Voltar
            </Button>
            <Button positive onClick={this.reativacao}>
              Sim
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
