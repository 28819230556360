import React, { Component } from "react";
import {
  Container,
  Table,
  Menu,
  Icon,
  Checkbox,
  Button,
} from "semantic-ui-react";
import api from "../../../services/api";
import { Col, Form, Row, FormControl } from "react-bootstrap";
import Select from "react-select";
import { parseISO, format } from "date-fns";
import renomearChave from "../../../services/renomearChave";
import requisicoes from "../../../utils/Requisicoes";
import moment from "moment-timezone";

export default class Consulta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      tipo: "vazio",
      options: [],
      pessoa_id: null,
      pessoa: null,
      open: false,
      contasAPagar: null,
      lancamento_conta: null,
      conta_bancaria_lista: [],
      conta_bancaria: null,
      data_pagamento: null,
      formabusca: null,
      tipo_busca: "nome",
    };
    this.alteracao = this.alteracao.bind(this);
  }

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ lista: [] });

    if (event.target.name === "tipo") {
      this.setState({ data_inicio: null, data_final: null });
    }
  };

  pesquisaPessoa = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/pessoa/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaPessoa").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista.push(objeto);
      });
      this.setState({ options: lista });
    }, 500);
  };

  alteracaoSelectPessoa = async (event) => {
    this.setState({ pessoa_id: event.value });
    this.setState({ pessoa: event });
    this.setState({ lista: [] });
  };

  buscaTitulos = async () => {
    let retorno = "";

    retorno = await api.post(
      "/contasAPagar/lancamentosPorPessoaRecibo" + requisicoes.entidade,

      {
        pessoa_id: this.state.pessoa_id,
      },
      requisicoes.header
    );

    this.setState({ lista: retorno.data });
  };

  buscaContasPorOP = async (event) => {
    const retornoLista = await api.post(
      "/contasAPagar/pesquisaOrdemPagamentoRecibo" + requisicoes.entidade,
      { ordem_pagamento: this.state.ordem_pagamento },
      requisicoes.header
    );

    this.setState({ lista: retornoLista.data });
  };

  gerarReciboForn = async (event) => {
    let lancamento = null;

    this.state.lista.forEach((a) => {
      if (a.id === parseInt(event.target.id)) {
        lancamento = a;
      }
    });

    let dados = lancamento;
    const retorno = await api.post(
      "/contasAPagar/gerarReciboForn" + requisicoes.entidade,
      { dados },
      requisicoes.header
    );

    this.gerarPdfReciboForn();
  };

  gerarPdfReciboForn = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    const r = await api.get(
      "contasAPagar/buscarReciboForn?e=" + entidade + "&t=Baer " + token
    );
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "contasAPagar/buscarReciboForn?e=" +
      entidade +
      "&t=Baer " +
      token;

    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  gerarReciboOp = async (event) => {
    let lancamentos = null;
    this.state.lista.forEach((a) => {
      a.lancamento_contas.forEach((b) => {
        if (b.id === parseInt(event.target.id)) {
          lancamentos = b;
          lancamentos.ordem_pagamento = a.ordem_pagamento;
        }
      });
    });

    let dados = lancamentos;
    const retorno = await api.post(
      "/contasAPagar/gerarReciboOp" + requisicoes.entidade,
      { dados },
      requisicoes.header
    );

    this.gerarPdfReciboOp();
  };

  gerarPdfReciboOp = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    const r = await api.get(
      "contasAPagar/buscarReciboOp?e=" + entidade + "&t=Baer " + token
    );
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "contasAPagar/buscarReciboOp?e=" +
      entidade +
      "&t=Baer " +
      token;

    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  imprimirReciboOp = async (event) => {};

  render() {
    let content = "";
    if (this.state.tipo != null) {
      switch (this.state.tipo) {
        case "fornecedor":
          content = (
            <Table structured color={"teal"} key={"teal"} celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1}>Data Lançamento</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Parcela</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Numero da O.P</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Documento</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Venc</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Valor</Table.HeaderCell>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              {this.state.lista.map((e, i) => (
                <Table.Body>
                  <Table.Row key={i}>
                    <Table.Cell sm={2} style={{ textAlign: "right" }}>
                      {e.data_emissao !== null &&
                        format(parseISO(e.data_emissao), "dd/MM/yyyy")}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "right" }}>
                      {e.numero_parcela}
                    </Table.Cell>

                    <Table.Cell style={{ textAlign: "right" }}>
                      {e.contas_a_pagars.length > 0
                        ? e.contas_a_pagars[0].ordem_pagamento.codigo
                        : ""}
                    </Table.Cell>

                    <Table.Cell style={{ textAlign: "right" }}>
                      {e.contas_a_pagars.length > 0
                        ? e.contas_a_pagars[0].documento
                        : ""}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "right" }}>
                      {e.data_vencimento !== null &&
                        format(parseISO(e.data_vencimento), "dd/MM/yyyy")}
                    </Table.Cell>

                    <Table.Cell style={{ textAlign: "right" }}>
                      {parseFloat(e.valor).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "right" }}>
                      <Button
                        primary
                        size="tiny"
                        icon={{
                          className: "eye",
                          onClick: [this.gerarReciboForn],
                          id: [e.id],
                        }}
                        onClick={this.gerarReciboForn}
                        id={e.id}
                      ></Button>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              ))}

              <Table.Footer>
                <Table.Row verticalAlign="middle" textAlign="center">
                  <Table.HeaderCell colSpan={12}>
                    <Menu pagination>
                      <Menu.Item
                        icon
                        onClick={this.voltarPagina}
                        disabled={this.state.pagina === 1}
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item
                        icon
                        onClick={this.proximaPagina}
                        disabled={this.state.pagina === this.state.totalPaginas}
                      >
                        <Icon name="chevron right" />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          );
          break;
        case "ordempgto":
          content = (
            <Table structured color={"teal"} key={"teal"} celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4}>Fornecedor</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Data Lançamento</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Parcela</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Numero da O.P</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Documento</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Data Vencimento</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Valor</Table.HeaderCell>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              {this.state.lista.map((e, i) => (
                <Table.Body>
                  {e.lancamento_contas.map((a, b) => (
                    <Table.Row key={e}>
                      <Table.Cell style={{ textAlign: "left" }}>
                        {a.pessoa.nome}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "left" }}>
                        {moment(a.data_emissao).format("DD-MM-YYYY")}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "right" }}>
                        {a.numero_parcela}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "right" }}>
                        {e.ordem_pagamento.codigo}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "right" }}>
                        {e.documento}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "left" }}>
                        {moment(a.data_vencimento).format("DD-MM-YYYY")}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "right" }}>
                        {a.valor}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "right" }}>
                        <Button
                          primary
                          size="tiny"
                          icon={{
                            className: "eye",
                            onClick: [this.gerarReciboOp],
                            id: [a.id],
                          }}
                          onClick={this.gerarReciboOp}
                          id={a.id}
                        ></Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              ))}
              <Table.Footer>
                <Table.Row verticalAlign="middle" textAlign="center">
                  <Table.HeaderCell colSpan={12}>
                    <Menu pagination>
                      <Menu.Item
                        icon
                        onClick={this.voltarPagina}
                        disabled={this.state.pagina === 1}
                      >
                        <Icon name="chevron left" />
                      </Menu.Item>
                      <Menu.Item
                        icon
                        onClick={this.proximaPagina}
                        disabled={this.state.pagina === this.state.totalPaginas}
                      >
                        <Icon name="chevron right" />
                      </Menu.Item>
                    </Menu>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          );
          break;

        case "vazio":
          content = (
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Data Pagamento</Table.HeaderCell>
                  <Table.HeaderCell>Data Vencimento</Table.HeaderCell>
                  <Table.HeaderCell>Nome</Table.HeaderCell>
                  <Table.HeaderCell>Valor</Table.HeaderCell>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body></Table.Body>
              <Table.Footer></Table.Footer>
            </Table>
          );
          break;
        default:
          break;
      }
    }
    return (
      <div className="lista">
        <Row>
          <Col sm={4}>
            <Form.Label>Tipo</Form.Label>
            <Form.Control
              placeholder="Tipo"
              label="Tipo"
              id="tipo"
              as="select"
              name="tipo"
              onChange={this.alteracao}
              required
            >
              <option></option>
              <option value="fornecedor">Fornecedor</option>
              <option value="ordempgto">Ordem de Pagamento</option>
            </Form.Control>
          </Col>

          {this.state.tipo === "fornecedor" && (
            <Col sm={6}>
              <Row>
                <Col sm={8}>
                  <Form.Label>Busca</Form.Label>
                  <Select
                    placeholder="Digite para buscar"
                    name="pessoa"
                    onKeyDown={this.pesquisaPessoa}
                    onChange={this.alteracaoSelectPessoa}
                    inputId="pesquisaPessoa"
                    options={this.state.options}
                  />
                </Col>
                <Button
                  variant="success"
                  style={{ marginTop: "25px" }}
                  onClick={this.buscaTitulos}
                  color="green"
                >
                  Buscar
                </Button>
              </Row>
            </Col>
          )}
          {this.state.tipo === "ordempgto" && (
            <Col sm={6}>
              <Row>
                <Col sm={8}>
                  <Form.Label>Busca</Form.Label>
                  <FormControl
                    placeholder="Digite para buscar"
                    name="ordem_pagamento"
                    onChange={this.alteracao}
                  />
                </Col>
                <Button
                  variant="success"
                  style={{ marginTop: "25px" }}
                  onClick={this.buscaContasPorOP}
                  color="green"
                >
                  Buscar
                </Button>
              </Row>
            </Col>
          )}
        </Row>

        {content}
      </div>
    );
  }
}
