import React, { Component } from "react";
import { Container, Header, Menu, Icon } from "semantic-ui-react";

import Exercicios from "./exercicio/lista";
import Treinos from "./treino/lista";
import Avaliacoes from "./avaliacaoFisica/lista";
import Parametros from "./parametros";

export default class Cadastro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo_clube: window.localStorage.getItem("tipo_clube"),
      selectedMenu: localStorage.getItem("pagina_anterior") || "exercicio",
    };
  }

  render() {
    let content = "";
    switch (this.state.selectedMenu) {
      case "exercicio":
        localStorage.setItem("pagina_anterior", "exercicio");
        content = <Exercicios />;
        break;
      case "treino":
        localStorage.setItem("pagina_anterior", "treino");
        content = <Treinos />;
        break;
      case "avaliacaoFisica":
        localStorage.setItem("pagina_anterior", "avaliacaoFisica");
        content = <Avaliacoes />;
        break;
      case "parametros":
        localStorage.setItem("pagina_anterior", "parametros");
        content = <Parametros />;
        break;
      default:
        break;
    }
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Academia
        </Header>
        <Menu tabular icon="labeled">
          {
            <Menu.Item
              name="Exercicios"
              active={this.state.selectedMenu === "exercicio"}
              onClick={this.selectMenu.bind(null, "exercicio")}
            >
              <Icon name="users" />
              Exercícios
            </Menu.Item>
          }
          {
            <Menu.Item
              name="Treinos"
              active={this.state.selectedMenu === "treino"}
              onClick={this.selectMenu.bind(null, "treino")}
            >
              <Icon name="book" />
              Treinos
            </Menu.Item>
          }
          {
            <Menu.Item
              name="Avaliação Fisica"
              active={this.state.selectedMenu === "avaliacaoFisica"}
              onClick={this.selectMenu.bind(null, "avaliacaoFisica")}
            >
              <Icon name="weight" />
              Avaliações Físicas
            </Menu.Item>
          }
          {
            <Menu.Item
              name="Parametros"
              active={this.state.selectedMenu === "parametros"}
              onClick={this.selectMenu.bind(null, "parametros")}
            >
              <Icon name="cogs" />
              Parametros
            </Menu.Item>
          }
        </Menu>
        {content}
      </div>
    );
  }
  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
  };
}
