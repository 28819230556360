/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Header, Segment, Divider, Table } from "semantic-ui-react";
import { Button } from "@material-ui/core/";

import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

import ListaTimes from "./listaTimes";
import ListaRodadas from "./listaRodadas";
import ListaRodadasNova from "./listaRodadasNova";
import ModalAdicionaRodadaMataMata from "./modalAdicionaRodadaMataMata";
import ModalAdicionaRodadaFasedeGrupo from "./modaAdicionaRodadaFasedeGrupos";
import ModalListaPartida from "./modalListaPartida";
import ModalIniciarMataMata from "./modalIniciarMataMata";
import ListaGruposFasedeGrupos from "./listaGrupoFasedeGrupos";
import ModalAdicionaAtleta from "./ModalAdicionaAtleta";
import ModalInscricao from "../ajustarcampeonato/modalInscricao";

import "../styles.css";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaTimeInscritos: [],
      participantesPorTime: [],
      listaTimesOpcoes: [],
      timesSelecionados: [],
      timesSelecionadosFasedeGrupo: [],
      datasSelecionadas: [],
      localSelecionadas: [],
      horaSelecionadas: [],
      rodadasCadastradas: [],
      partidasCadastradas: [],
      partidasCadastradas2: [],
      gruposTotais: [],
      listaTimesPorGrupo: [],
      infoTimesTabela: [],
      organizacaoSelecionadaNome: [],
      ListaPartidaAdd: 0,
      hora: null,
      local: null,
      rodadaId: null,
      grupoSelecionado: null,
      modalListaPartida: false,
      alertaSalvoSucesso: false,
      alertaSalvoError: false,
      alertAdicionandoRodada: false,
      modalAdicionarRodadaMataMata: false,
      modalAdicionarFasedeGrupos: false,
      modalIniciarMataMata: false,
      pontosPorEmpate: this.props.location.query?.info.pontos_ganhos_empate,
      pontosPorVitoria: this.props.location.query?.info.pontos_ganhos_vitoria,
      timesProximaFase: null,
      rodada_id: "",
      faseAtual: "",
      checkIniciar: false,
      modalInscricao: false,
      tamanho_max_equipe:
        this.props.location?.query?.info.modalidade_desporto.tamanho_max_equipe,
      modalidade_desporto_id: this.props.location.query?.modalidade_desporto_id,
      campeonato_id: this.props.location.query.campeonato_id,
      organizacaoSelecionadaId: null,
      testeEquipeID: null,
      atletasInscricao: [],
      atletasEComissaoTecnica: [],
    };

    this.listaEquipeseRodadas();
    this.listarTodasPartida()

  }
  listarTodasPartida = async (props, status, activePage = 1) => {
    const partidas = await api.post(
      "/partida/listarTodasPartida" + requisicoes.entidade,
      {
        campeonato_id: this.props.location.query.campeonato_id,
        // rodada_id: props.id,
        activePage: activePage
      },
      requisicoes.header
    );

    this.setState({ partidasCadastradas2: partidas.data });
    // this.setState({ rodadaId: partidas.data[0].rodada.id });


    this.setState({ modalListaPartida: status });
  };

  gerarRodada = async () => {
    const equipes = await api.post(
      "/equipe/listarEquipePorCampeonato" + requisicoes.entidade,
      {
        campeonato_id: this.props.location.query.campeonato_id,
        tipo_campeonato: this.props.location.query.info.tipo_campeonato,
      },
      requisicoes.header
    );
    let listaTimes = []
    let listaConfrontos = []

    for (let i = 0; i < equipes.data.length; i++) {
      const element = equipes.data[i];
      listaTimes.push({
        id: element.id,
        nome: element.organizacao.nome,
      })
    }


    let timeA, timeB, confronto;
    let id = 0;
    for (let i = 0; i < listaTimes.length; i++) { // Vai definir o time A
      timeA = listaTimes[i];
      for (let j = 0; j < listaTimes.length; j++) { // Vai definir o time B
        if (timeA !== listaTimes[j]) {
          timeB = listaTimes[j];
          confronto = {
            id: id,
            timeA: timeA,
            timeB: timeB
          };
          if (timeA !== null && timeB !== null
            && listaConfrontos.filter(c => c === confronto).length === 0) {
            listaConfrontos.push(confronto);
            id++;
          }
        }
      }
    }


    // for (let i = 0; i < listaConfrontos.length; i++) {
    //   const element = listaConfrontos[i];



    // }


    // let listaPartidasRodada = [], todasRodadas = [];
    // let listaConfrontosAux = listaConfrontos;
    // let qtdPartidasRodada = Math.floor(listaTimes.length / 2); // No caso de 5 times será 2, 6 times será 3
    // // Enquanto tiver confrontos na lista, ele continuará executando.
    // let tentativas = 0, qtd = 0, idx = 0, r = 1;

    // while (listaConfrontosAux.length > 0 && tentativas < 1000) {
    //   // Se qtd for menor q maxPartidas, então adiciona
    //   let conf = listaConfrontosAux[idx];
    //   if (conf !== null && conf !== undefined) {

    //     let confrontoAdd = listaPartidasRodada.filter(c =>
    //       (c.timeA.id === conf.timeA.id || c.timeB.id === conf.timeA.id) ||
    //       (c.timeA.id === conf.timeB.id || c.timeB.id === conf.timeB.id)
    //     ).length === 0; // Se 0, pode adicionar, Se 1, já foi adicionado

    //     if (confrontoAdd && qtd < qtdPartidasRodada) {
    //       idx = -1;
    //       qtd++;

    //       listaPartidasRodada.push(conf);

    //       listaConfrontosAux = listaConfrontosAux.filter(r => r.id !== conf.id); // Remove após adicionar
    //     }
    //     if (qtd === qtdPartidasRodada && listaPartidasRodada.length === 2) {
    //       idx = -1;
    //       qtd = 0;
    //       todasRodadas.push({
    //         rodada: r,
    //         partidas: listaPartidasRodada,
    //       });
    //       r++;
    //       listaPartidasRodada = [];
    //     }
    //   }
    //   idx++;
    //   tentativas++; // Somente pra não permitir que o código rode infinitamente em caso de erro
    // }



    // let listaRodadas = [], confrontosRodada = [];


    // for (let t = 1; t <= listaConfrontos.length; t += listaTimes.length) {
    //   let confronto = listaConfrontos[t];

    //   confrontosRodada.push(confronto);
    // }

    // Essa é pra separar as rodadas? sim fiz separado para testar ele melhor
    // Ok, vou comentar e fazer um novo
    // let listaTimes = []
    let listaTimesAux = []
    let listaTeste = []
    let listaTeste2 = listaConfrontos

    let qtdPartidasRodada = Math.floor(listaTimes.length / 2)



    for (let i = 0; i < 5; i++) {
      for (let i = 0; i < listaConfrontos.length; i++) {
        const element = listaConfrontos[i];


        let index = listaTimesAux.findIndex((t) => t.timeA.id === element.timeA.id)
        if (index === -1) {
          let index2 = listaTimesAux.findIndex((t) => t.timeA.id === element.timeB.id)
          if (index2 === -1) {


            listaTimesAux.push(element)
          }
        }
      }
    }
    // for (let i = 0; i < listaConfrontos.length; i++) {
    //   const element = listaConfrontos[i];

    // }
    // for (let i = 0; i < 40; i++) {
    //   for (let i = 0; i < listaConfrontos.length; i++) {
    //     const element = listaConfrontos[i];
    //     let index = listaTimesAux.findIndex(
    //       (e2) => e2.timeA.id === element.timeA.id
    //     );
    //     if (index === -1) {
    //       let index2 = listaTimesAux.findIndex(
    //         (e1) => e1.timeA.id === element.timeB.id
    //       );
    //       if (index2 === -1) {
    //
    //         listaTeste.push(listaConfrontos.splice(0, 1))
    //

    //         listaTimesAux.push(element)


    //       }
    //     }
    //   }

    // }



    // let jogos = []
    // listaTimes.map(t => {
    //   return {
    //     t: t,
    //     adv: listaTimes.filter(e => e !== t)
    //   }
    // }).forEach(p => {
    //   p.adv.forEach(j => {

    //     jogos.push(p.t + " x " + j)
    //   })
    // })


    // const partidasPorDia = 2;

    // jogos.reduce((all, one, i) => {
    //   const ch = Math.floor(i / partidasPorDia);
    //   all[ch] = [].concat((all[ch] || []), one);
    //   return all
    // }, [])


  }

  listaEquipeseRodadas = async () => {

    const todosContraTodos = this.props.location.query?.todos_contra_todos;
    const idaeVolta = this.props.location.query?.ida_volta;
    const inscritosModalidade = await api.post(
      "/pessoaModalidadesDesporto/listarAtletaeComissaoTecnicaPorModalidade" +
      requisicoes.entidade,
      {
        modalidade_desporto_id:
          this.props.location.query?.modalidade_desporto_id,
      },
      requisicoes.header
    );
    this.setState({ atletasEComissaoTecnica: inscritosModalidade.data });

    const listaSelect = [];
    // return;
    try {
      const equipes = await api.post(
        "/equipe/listarEquipePorCampeonato" + requisicoes.entidade,
        {
          campeonato_id: this.props.location.query.campeonato_id,
          tipo_campeonato: this.props.location.query.info.tipo_campeonato,
        },
        requisicoes.header
      );

      var faseAtual = "";
      const rodadas = await api.post(
        "/rodada/listarPorCampeonato" + requisicoes.entidade,
        { campeonato_id: this.props.location.query.campeonato_id },
        requisicoes.header
      );

      faseAtual = rodadas?.data[0]
        ? rodadas?.data[0]?.campeonato?.fase_atual
        : this.props.location.query.tipo_campeonato;

      rodadas.data.sort(function (a, b) {
        if (a.partidas.rodada > b.partidas.rodada) {
          return 1;
        }
        if (a.partidas.rodada < b.partidas.rodada) {
          return -1;
        }
        return 0;
      });

      if (faseAtual !== "Mata-Mata") {
        rodadas.data.sort(function (a, b) {
          if (
            a.grupo_campeonato.numero_grupo < b.grupo_campeonato.numero_grupo
          ) {
            return -1;
          }
          if (
            b.grupo_campeonato.numero_grupo < a.grupo_campeonato.numero_grupo
          ) {
            return 1;
          }
          if (a.numero < b.numero) {
            return -1;
          }
          if (b.numero < a.numero) {
            return 1;
          }

          return 0;
        });
      }

      var arrayBase = [];

      for (let index = 0; index < equipes.data.length; index++) {
        if (
          equipes.data[index].campeonato_equipes[0].status === "participando"
        ) {
          if (this.props.location.query.tipo_campeonato !== "Pontos Corridos") {
            listaSelect.push({
              value: equipes.data[index].id,
              label: equipes.data[index].organizacao.nome,
              isDisabled: false,
            });
          }
          arrayBase.push(equipes.data[index].id);
        }
      }

      var dados = [];
      if (this.props.location.query.tipo_campeonato !== "Mata-Mata") {

        const grupoPorCampeonato = await api.post(
          "/grupoCampeonato/listar" + requisicoes.entidade,
          { campeonato_id: this.props.location.query.campeonato_id },
          requisicoes.header
        );

        function separar(base, maximo) {
          var resultado = [[]];
          var grupo = 0;

          for (var indice = 0; indice < base.length; indice++) {
            if (resultado[grupo] === undefined) {
              resultado[grupo] = [];
            }

            resultado[grupo].push(base[indice]);

            if ((indice + 1) % maximo === 0) {
              grupo = grupo + 1;
            }
          }

          return resultado;
        }

        var divisao = Math.ceil(arrayBase.length / 2);

        dados = separar(arrayBase, divisao);

        var confrontos = [];

        if (dados.length > 1) {
          for (let index = 0; index < dados[0].length; index++) {
            for (let indx = 0; indx < dados[1].length; indx++) {
              confrontos.push([dados[0][index], dados[1][indx]]);
            }
          }
        }

        var listaInfoGrupos = [];
        var grupos = [];
        let teste = [];

        for (let index = 0; index < grupoPorCampeonato.data.length; index++) {
          grupos[index] = [];
          for (
            let indx = 0;
            indx < grupoPorCampeonato.data[index].g_c_e.length;
            indx++
          ) {
            grupos[index][indx] = {
              nome_time:
                grupoPorCampeonato.data[index].g_c_e[indx].equipe.organizacao
                  .nome,
              equipe_id: grupoPorCampeonato.data[index].g_c_e[indx].equipe_id,
              pontuacao: pontuacao(grupoPorCampeonato.data[index].g_c_e[indx]),
              jogos: jogos(grupoPorCampeonato.data[index].g_c_e[indx]),
              gols: gols(grupoPorCampeonato.data[index].g_c_e[indx]),
              vitorias: vitorias(grupoPorCampeonato.data[index].g_c_e[indx]),
              empates: empates(grupoPorCampeonato.data[index].g_c_e[indx]),
              aproveitamento: aproveitamento(
                grupoPorCampeonato.data[index].g_c_e[indx],
                this.state.pontosPorVitoria
              ),
              derrotas: derrotas(grupoPorCampeonato.data[index].g_c_e[indx]),
            };

            grupos[index].sort(function (a, b) {
              if (a.pontuacao > b.pontuacao) {
                return -1;
              }
              if (a.pontuacao < b.pontuacao) {
                return 1;
              }
              if (a.vitorias > b.vitorias) {
                return -1;
              }
              if (a.vitorias < b.vitorias) {
                return 1;
              }

              if (a.gols > b.gols) {
                return -1;
              }
              if (a.gols < b.gols) {
                return 1;
              }
              return 0;
            });
          }
        }

        function pontuacao(info) {
          var pontosPorEmpate = 1;
          var pontosPorVitoria = 3;

          var empate = 0;
          var vitoria = 0;
          var pontuacao = 0;
          for (let index = 0; index < info.equipe.placares.length; index++) {
            if (info.equipe.placares[index].empate) {
              empate += 1;
            }
            if (info.equipe.placares[index].colocacao === 1) {
              vitoria += 1;
            }
          }

          pontuacao =
            parseInt(vitoria) * pontosPorVitoria +
            parseInt(empate) * pontosPorEmpate;

          return pontuacao;
        }

        function jogos(info) {
          var jogos = 0;

          for (let index = 0; index < info.equipe.placares.length; index++) {
            if (
              info.equipe.placares[index].colocacao ||
              info.equipe.placares[index].empate
            ) {
              jogos += 1;
            }
          }

          return jogos;
        }
        function gols(info) {
          var gols = 0;
          for (let index = 0; index < info.equipe.placares.length; index++) {
            gols += info.equipe.placares[index].pontuacao;
          }

          return gols;
        }

        function vitorias(info) {
          var vitorias = 0;
          for (let index = 0; index < info.equipe.placares.length; index++) {
            if (
              info.equipe.placares[index].colocacao === 1 &&
              !info.equipe.placares[index].empate
            ) {
              vitorias += 1;
            }
          }
          return vitorias;
        }

        function empates(info) {
          var empates = 0;
          for (let index = 0; index < info.equipe.placares.length; index++) {
            if (info.equipe.placares[index].empate) {
              empates += 1;
            }
          }
          return empates;
        }
        function derrotas(info) {
          var derrotas = 0;
          for (let index = 0; index < info.equipe.placares.length; index++) {
            if (
              !info.equipe.placares[index].empate &&
              info.equipe.placares[index].colocacao === 2
            ) {
              derrotas += 1;
            }
          }
          return derrotas;
        }

        function aproveitamento(info, pontosPorVitoria) {
          var jogos = 0;
          var pontosPorEmpate = 1;
          var empate = 0;
          var vitoria = 0;
          var pontuacao = 0;
          var aproveitamento = 0;

          for (let index = 0; index < info.equipe.placares.length; index++) {
            if (
              info.equipe.placares[index].colocacao ||
              info.equipe.placares[index].empate
            ) {
              jogos += 1;
            }
            if (info.equipe.placares[index].empate) {
              empate += 1;
            }
            if (info.equipe.placares[index].colocacao === 1) {
              vitoria += 1;
            }
          }

          pontuacao =
            parseInt(vitoria) * pontosPorVitoria +
            parseInt(empate) * pontosPorEmpate;
          if (pontuacao && jogos && pontosPorVitoria) {
            aproveitamento = parseFloat(
              (100 / (jogos * pontosPorVitoria)) * pontuacao
            ).toFixed(2);
          } else {
            aproveitamento = 100;
          }

          return aproveitamento;
        }

        this.setState({ gruposTotais: grupoPorCampeonato.data });
      }

      this.setState({
        rodadasCadastradas: rodadas.data,
        listaTimesOpcoes: listaSelect,
        listaTimeInscritos: equipes.data,
        infoTimesTabela: grupos,
        faseAtual: faseAtual,
      });
    } catch (error) {
      console.log(error);
    }
  };

  voltar = async () => {
    window.location.href = "/desporto";
  };

  changeModalAdicionarRodada = async (status) => {
    this.state.faseAtual === "Mata-Mata"
      ? this.setState({
        modalAdicionarRodadaMataMata: status,
      })
      : this.setState({
        modalAdicionarFasedeGrupos: status,
      });
  };

  changeModalIniciarMataMata = async (status) => {
    this.setState({
      modalIniciarMataMata: status,
      timesProximaFase: null,
    });

    if (!status) {
      this.setState({ checkIniciar: false });
    }
  };

  changeModalListaPartida = async (props, status) => {

    if (status) {

      const partidas = await api.post(
        "/partida/listar" + requisicoes.entidade,
        {
          campeonato_id: this.props.location.query.campeonato_id,
          rodada_id: props.id
        },
        requisicoes.header
      );
      this.setState({ partidasCadastradas: partidas.data });
      this.setState({ rodadaId: props.id });
    }

    this.setState({ modalListaPartida: status });
  };

  changeModalInscricao = async (props, status) => {
    if (status) {
      this.setState({ organizacaoSelecionadaNome: props.organizacao.nome });
      this.setState({
        organizacaoSelecionadaId: props.campeonato_equipes[0].equipe_id,
      });
      const inscritosPorTime = await api.post(
        "/rodada/salvar" + requisicoes.entidade,
        {
          modalidade_desporto_id: props.modalidade_desporto_id,
          campeonato_id: props.campeonato_equipes[0].campeonato_id,
          equipe_id: props.campeonato_equipes[0].equipe_id,
        },
        requisicoes.header
      );
      this.setState({
        participantesPorTime: inscritosPorTime.data[0].equipe_pessoas,
      });
    }
    this.setState({ modalInscricao: status });
    this.setState({ linhaInscricao: 1 });


  };
  changeModalAdicionaAtleta = async (props, status) => {
    if (status) {
      let lista = [];
      lista.push(...props.campeonato_equipes);
      this.setState({
        organizacaoSelecionadaNome: props.organizacao?.nome,
        organizacaoSelecionadaId: props.campeonato_equipes[0].equipe_id,
        campeonato_id: props.campeonato_equipes[0].campeonato_id,
        testeEquipeID: props.p_m_d.id,
      });
      const inscritosPorTime = await api.post(
        "/equipe/listaAtletasComissaoPorTime" + requisicoes.entidade,
        {
          modalidade_desporto_id: props.modalidade_desporto_id,
          campeonato_id: props.campeonato_equipes[0].campeonato_id,
          equipe_id: props.campeonato_equipes[0].equipe_id,
        },
        requisicoes.header
      );

      this.setState({
        participantesPorTime: inscritosPorTime.data[0].equipe_pessoas,
        listaParticipantesPorTime: inscritosPorTime.data[0].equipe_pessoas,
      });
      this.setState({
        verificaLinha: inscritosPorTime.data[0].equipe_pessoas.length + 1,
      });
    }

    this.setState({ modalInscricao: status });
    this.setState({ linhaInscricao: 1 });
  };

  changeSelectTimes = async (event, index) => {
    const listaTimesSelecionado = [...this.state.listaTimesOpcoes];
    const indx = listaTimesSelecionado.indexOf(event.target.value);

    const listaSelecionado = [...this.state.timesSelecionados];

    // listaTimesSelecionado[indx].isDisabled = true;
    listaSelecionado[index] = {
      time_id: event.target.value,
    };

    this.setState({ timesSelecionados: listaSelecionado });
  };

  changeSelectTimesFasedeGrupo = async (event, indice) => {
    const timesSelecionados = [...this.state.timesSelecionadosFasedeGrupo];
    timesSelecionados[indice] = { time_id: event.target.value };

    if (
      timesSelecionados[indice]?.time_id ===
      timesSelecionados[indice - 1]?.time_id
    ) {
      window.alert("Time já Selecionado.");
    } else if (
      timesSelecionados[indice]?.time_id ===
      timesSelecionados[indice - 2]?.time_id
    ) {
      window.alert("Time já Selecionado.");
    } else if (
      timesSelecionados[indice]?.time_id ===
      timesSelecionados[indice - 4]?.time_id
    ) {
      window.alert("Time já Selecionado.");
    }
    this.setState({ timesSelecionadosFasedeGrupo: timesSelecionados });
  };

  adicionarRodadaMataMata = async () => {
    var lista = [];

    for (
      let index = 0, partida = 1;
      index < this.state.timesSelecionados.length;
      index += 2, partida++
    ) {
      lista.push({
        partida: partida,
        data_marcada: null,
        placares: [],
        equipeRodada: [],

        status: "criada",
      });

      lista[lista.length - 1].equipeRodada.push({
        pontuacao: 0,
        equipe_id: this.state.timesSelecionados[index].time_id,
      }); lista[lista.length - 1].equipeRodada.push({
        pontuacao: 0,
        equipe_id: this.state.timesSelecionados[index + 1].time_id,
      });

      lista[lista.length - 1].placares.push({
        pontuacao: 0,
        equipe_id: this.state.timesSelecionados[index].time_id,
      });
      lista[lista.length - 1].placares.push({
        pontuacao: 0,
        equipe_id: this.state.timesSelecionados[index + 1].time_id,
      });
    }

    for (let indx = 0; indx < this.state.datasSelecionadas.length; indx++) {
      lista[indx].data_marcada = this.state.datasSelecionadas[indx].data;
    }
    try {
      // const rodadas = await api.post(
      //   "/rodada/salvar" + requisicoes.entidade,
      //   {
      //     campeonato_id: this.props.location.query.campeonato_id,
      //     partidas: lista,
      //   },
      //   requisicoes.header
      // );

      this.setState({
        alertAdicionandoRodada: false,
        alertaSalvoSucesso: true,
      });

      setTimeout(() => {
        this.setState({
          alertaSalvoSucesso: false,
          modalAdicionarRodadaMataMata: false,
        });
      }, 2300);

      this.listaEquipeseRodadas();
    } catch (e) {
      this.setState({ alertaSalvoError: true });
      setTimeout(() => {
        this.setState({
          alertaSalvoError: false,
          modalAdicionarRodadaMataMata: false,
        });
      }, 2300);
    }
  };

  adicionarRodadaFasedeGrupo = async () => {
    var lista = [];
    var listaEquipes = []
    let equipePartidada = []
    for (
      let index = 0, partida = 1;
      index < this.state.timesSelecionadosFasedeGrupo.length;
      index += 2, partida++
    ) {
      lista.push({
        partida: partida,
        data_marcada: null,
        placares: [],
        equipeRodada: [],
        status: "criada",
      });

      lista[lista.length - 1].equipeRodada.push({
        equipe_id: this.state.timesSelecionadosFasedeGrupo[index].time_id,
        campeonato_id: this.props.location.query.campeonato_id

      });
      listaEquipes.push({
        equipe_id: this.state.timesSelecionadosFasedeGrupo[index].time_id,
        campeonato_id: this.props.location.query.campeonato_id
      })
      listaEquipes.push({
        equipe_id: this.state.timesSelecionadosFasedeGrupo[index + 1].time_id,
        campeonato_id: this.props.location.query.campeonato_id
      })
      lista[lista.length - 1].equipeRodada.push({
        equipe_id: this.state.timesSelecionadosFasedeGrupo[index + 1].time_id,
        campeonato_id: this.props.location.query.campeonato_id
      });
      equipePartidada.push({ equipe_id: this.state.timesSelecionadosFasedeGrupo[index].time_id })

      lista[lista.length - 1].placares.push({
        equipe_id: this.state.timesSelecionadosFasedeGrupo[index].time_id,
        pontuacao: 0,
      });
      equipePartidada.push({ equipe_id: this.state.timesSelecionadosFasedeGrupo[index + 1].time_id })

      lista[lista.length - 1].placares.push({
        equipe_id: this.state.timesSelecionadosFasedeGrupo[index + 1].time_id,
        pontuacao: 0,
      });
    }

    for (let indx = 0; indx < this.state.datasSelecionadas.length; indx++) {
      lista[indx].data_marcada = this.state.datasSelecionadas[indx].data;
      lista[indx].hora = this.state.horaSelecionadas[indx].hora;
      lista[indx].local = this.state.localSelecionadas[indx].local;
    }

    try {
      const rodadas = await api.post(
        "/rodada/salvar" + requisicoes.entidade,
        {
          campeonato_id: this.props.location.query.campeonato_id,
          partidas: lista,
          grupo_campeonato_id: this.state.grupoSelecionado,
          rodadaId: this.state.ListaPartidaAdd,
          listaEquipes: listaEquipes

        },
        requisicoes.header
      );

      this.setState({
        alertAdicionandoRodada: false,
        alertaSalvoSucesso: true,
      });

      setTimeout(() => {
        this.setState({
          alertaSalvoSucesso: false,
          modalAdicionarFasedeGrupos: false,
        });
      }, 2300);
      this.state.ListaPartidaAdd = 0;
      this.listaEquipeseRodadas();
    } catch (e) {
      this.setState({ alertaSalvoError: true });
      setTimeout(() => {
        this.setState({
          alertaSalvoError: false,
          modalAdicionarRodadaMataMata: false,
        });
      }, 2300);
    }
  };

  alteracaoData = async (index, event) => {
    let lista = [...this.state.datasSelecionadas];
    lista[index] = {
      data: event.target.value,
    };
    this.setState({ datasSelecionadas: lista });
  };

  alteracaoLocal = async (index, event) => {
    let listaA = [...this.state.localSelecionadas];
    listaA.push({
      local: event.target.value,
    });
    this.setState({ localSelecionadas: listaA });
  };

  alteracaoHora = async (index, event) => {
    let lista = [...this.state.horaSelecionadas];
    lista[index] = {
      hora: event.target.value,
    };
    this.setState({ horaSelecionadas: lista });
  };

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  alteracaoGrupoFasedeGrupo = async (event) => {
    var listaOpcoesPorGrupo = [];


    for (let index = 0; index < this.state.listaTimeInscritos.length; index++) {
      if (
        this.state.listaTimeInscritos[index].g_c_e[0].grupo_campeonato_id ===
        event.target.value
      ) {
        listaOpcoesPorGrupo.push({
          equipe_id: this.state.listaTimeInscritos[index].id,
          equipe_nome: this.state.listaTimeInscritos[index].organizacao.nome,
        });
      }
    }

    this.setState({
      listaTimesPorGrupo: listaOpcoesPorGrupo,
      grupoSelecionado: event.target.value,
      timesSelecionadosFasedeGrupo: [],
    });
  };

  alteraStatusAtualCampeonato = async () => {
    try {
      const campeonato = await api.post(
        "/campeonato/alterarStatusFaseAtual" + requisicoes.entidade,
        {
          id: this.props.location.query.campeonato_id,
        },
        requisicoes.header
      );

      this.setState({ modalIniciarMataMata: false });
    } catch (error) {
      console.log(error);
    }
  };

  selecionaClassificados = async () => {
    try {
      let listaTimes = [...this.state.infoTimesTabela];
      let timesEliminados = [];
      for (let index = 0; index < listaTimes.length; index++) {
        for (let indx = 0; indx < listaTimes[index].length; indx++) {
          if (indx >= this.state.timesProximaFase) {
            timesEliminados.push(listaTimes[index][indx].equipe_id);
          }
        }
      }

      const campeonato = await api.post(
        "/campeonato/alterarStatusFaseAtual" + requisicoes.entidade,
        {
          id: this.props.location.query.campeonato_id,
          info: timesEliminados,
        },
        requisicoes.header
      );

      this.setState({ modalIniciarMataMata: false });
      this.listaEquipeseRodadas();
    } catch (error) {
      console.log(error.response);
    }
  };

  checkIniciarMataMata = () => {
    this.setState({ checkIniciar: !this.state.checkIniciar });
  };
  deletaListaPartida = (event) => {
    let indexB = 0;
    if (window.confirm("Tem certeza que deseja excluir a partida?")) {
      let index = this.state.partidasCadastradas.indexOf(event);

      if (index !== -1) {
        this.state.partidasCadastradas.splice(index, 1);
      }

      const partidas = api.post(
        "/partida/deletaPartida" + requisicoes.entidade,
        {
          listaPartida: event,
        },
        requisicoes.header
      );

      this.setState({
        partidasCadastradas: this.state.partidasCadastradas,
      });
      this.listaEquipeseRodadas();
    }
  };
  adicionaListaPartida = async (event) => {
    this.state.ListaPartidaAdd = event;
    this.setState({ ListaPartidaAdd: event });
    this.changeModalListaPartida();
  };

  addModalLinhaInscricao = async () => {
    if (
      this.state.verificaLinha <
      this.props.location.query.info.modalidade_desporto.tamanho_max_equipe
    ) {
      this.setState({ linhaInscricao: this.state.linhaInscricao + 1 });
      this.setState({ verificaLinha: this.state.verificaLinha + 1 });
    } else {
      this.setState({ alertTamanhoEquipes: true });

      setTimeout(() => {
        this.setState({ alertTamanhoEquipes: false });
      }, 2500);
    }
  };

  alteracaoSelectAtleta = async (event, index, a) => {
    try {
      var inscricaoA = [...this.state.atletasInscricao];
      inscricaoA[index] = {
        pessoas_modalidades_desporto_id: a.value,
        nome: "",
        equipe_id: this.state.organizacaoSelecionadaId,
        vinculo: "",
        status: "regular",
      };

      this.setState({
        atletaSelecionadoId: a.value,
        atletaSelecionadoNome: a.label,
        atletasInscricao: inscricaoA,
      });
    } catch (error) {
      console.error("error:", error);
    }
  };

  salvarTime = async (event) => {
    try {
      let listaParticipantesAdicionados =
        this.state.participantesPorTime.filter((e) => !e.id);

      const equipePessoa = await api.post(
        "/equipePessoas/salvar" + requisicoes.entidade,
        {
          props: listaParticipantesAdicionados,
          campeonato_id: this.state.campeonato_id,
        },
        requisicoes.header
      );

      if (equipePessoa.data.length !== listaParticipantesAdicionados.length) {
        this.mensagem(false, "Nem todos os atletas foram salvos.", 1500);
      } else {
        this.setState({ alertSuccessSalvarTime: true });
      }

      setTimeout(() => {
        this.setState({
          modalInscricao: false,
          linhaInscricao: 1,
          atletasInscricao: [],
          alertSuccessSalvarTime: false,
        });
      }, 2000);
    } catch (error) {
      this.setState({ alertErrorSalvarTime: true });

      setTimeout(() => {
        this.setState({ alertErrorSalvarTime: false });
      }, 2000);
    }
  };

  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Form>
          <Segment clearing>
            <Header>
              <div className="flexSpaceBetween">
                <div >
                  {"RODADAS CAMPEONATO  " +
                    this.props.location.query?.campeonato_id}
                </div>

                <div>
                  <Button
                    color="primary"
                    floated="right"
                    onClick={this.voltar}
                    style={{ outline: "none" }}
                  >
                    Voltar
                  </Button>
                </div>
              </div>
              <Divider style={{ marginTop: "-0.2ch" }} />
            </Header>
          </Segment>
          <Segment clearing>

            <ModalAdicionaAtleta
              modalInscricao={this.state.modalInscricao}
              changeModalAdicionaAtleta={this.changeModalAdicionaAtleta}
              tamanhoMaxEquipe={this.state.tamanho_max_equipe}
              modalidadeDesportoId={this.state.modalidade_desporto_id}
              organizacaoSelecionadaNome={this.state.organizacaoSelecionadaNome}
              participantesPorTime={this.state.participantesPorTime}
              campeonatoId={this.state.campeonato_id}
              organizacaoSelecionadaId={this.state.organizacaoSelecionadaId}
              testeEquipeID={this.state.testeEquipeID}
            />
            <ModalInscricao
              modalInscricao={this.state.modalInscricao}
              organizacaoSelecionadaNome={this.state.organizacaoSelecionadaNome}
              changeModalInscricao={this.changeModalInscricao}
              changeModalAdicionaAtleta={this.changeModalAdicionaAtleta}
              excluirAtleta={this.excluirAtleta}
              atletasEComissaoTecnica={this.state.atletasEComissaoTecnica}
              alteracaoSelectAtleta={this.alteracaoSelectAtleta}
              salvarTime={this.salvarTime}
              addModalLinhaInscricao={this.addModalLinhaInscricao}
              adicionaListaAtleta={this.adicionaListaAtleta}
              linhaInscricao={this.state.linhaInscricao}
              participantesPorTime={this.state.participantesPorTime}
              alertTamanhoEquipes={this.state.alertTamanhoEquipes}
              alertErrorSalvarTime={this.state.alertErrorSalvarTime}
              alertSuccessSalvarTime={this.state.alertSuccessSalvarTime}
              atletasInscricao={this.state.atletasInscricao}
            />


            {/* <ListaRodadasNova
              rodadasCadastradas={this.state.rodadasCadastradas}
              gerarRodada={this.gerarRodada}
              campeonato_id={this.props.location.query.campeonato_id}
              ida_volta={this.props.location.query.ida_volta}
              changeModalAdicionarRodada={this.changeModalAdicionarRodada}
              changeModalListaPartida={this.changeModalListaPartida}
              listaTimesOpcoes={this.state.listaTimesOpcoes}
              faseAtual={this.state.faseAtual}
              tipo_campeonato={this.props.location.query.tipo_campeonato}
              changeModalIniciarMataMata={this.changeModalIniciarMataMata}

              modalListaPartida={this.state.modalListaPartida}
              deletaListaPartida={this.deletaListaPartida}
              adicionaListaPartida={this.adicionaListaPartida}
              partidasCadastradas={this.state.partidasCadastradas2}
              partida={this.state.partida}
              todos_contra_todos={this.props.location.query.todos_contra_todo}
              fase_atual={this.state.faseAtual}
              rodadaId={this.state.rodadaId}
              info={this.props.location.query.info}

            /> */}

            <ListaRodadas
              rodadasCadastradas={this.state.rodadasCadastradas}
              gerarRodada={this.gerarRodada}
              campeonato_id={this.props.location.query.campeonato_id}
              ida_volta={this.props.location.query.ida_volta}
              changeModalAdicionarRodada={this.changeModalAdicionarRodada}
              changeModalListaPartida={this.changeModalListaPartida}
              listaTimesOpcoes={this.state.listaTimesOpcoes}
              faseAtual={this.state.faseAtual}
              tipo_campeonato={this.props.location.query.tipo_campeonato}
              changeModalIniciarMataMata={this.changeModalIniciarMataMata}
            />

            {this.props.location.query.tipo_campeonato !== "Mata-Mata" && (
              <ListaGruposFasedeGrupos
                infoTimesTabela={this.state.infoTimesTabela}
                pontosPorEmpate={
                  this.props.location.query.info.pontos_ganhos_empate
                }
                pontosPorVitoria={
                  this.props.location.query.info.pontos_ganhos_vitoria
                }
              />
            )}

            {this.state.faseAtual === "Mata-Mata e Fase-de-Grupo" && (
              <ModalIniciarMataMata
                changeModalIniciarMataMata={this.changeModalIniciarMataMata}
                modalIniciarMataMata={this.state.modalIniciarMataMata}
                alteraStatusAtualCampeonato={this.alteraStatusAtualCampeonato}
                alteracao={this.alteracao}
                timesProximaFase={this.state.timesProximaFase}
                infoTimesTabela={this.state.infoTimesTabela}
                selecionaClassificados={this.selecionaClassificados}
                checkIniciar={this.state.checkIniciar}
                checkIniciarMataMata={this.checkIniciarMataMata}
              />
            )}
            {this.state.faseAtual === "Mata-Mata" ? (
              <>
                {/* <ModalAdicionaRodadaMataMata
                  changeModalAdicionarRodada={this.changeModalAdicionarRodada}
                  modalAdicionarRodadaMataMata={
                    this.state.modalAdicionarRodadaMataMata
                  }
                  listaTimeInscritos={this.state.listaTimeInscritos}
                  changeSelectTimes={this.changeSelectTimes}
                  listaTimesOpcoes={this.state.listaTimesOpcoes}
                  adicionarRodadaMataMata={this.adicionarRodadaMataMata}
                  alteracaoData={this.alteracaoData}
                  alteracaoLocal={this.alteracaoLocal}
                  alteracaoHora={this.alteracaoHora}
                  alertAdicionandoRodada={this.state.alertAdicionandoRodada}
                  alertaSalvoSucesso={this.state.alertaSalvoSucesso}
                  alertaSalvoError={this.state.alertaSalvoError}
                  ida_volta={this.props.location.query.ida_volta}
                /> */}
              </>
            ) : (
              <>

                <ModalAdicionaRodadaFasedeGrupo
                  changeModalAdicionarRodada={this.changeModalAdicionarRodada}
                  modalAdicionarFasedeGrupos={this.state.modalAdicionarFasedeGrupos}//
                  changeModalListaPartida={this.changeModalListaPartida}
                  adicionaListaPartida={this.adicionaListaPartida}
                  listaTimesOpcoes={this.state.listaTimesOpcoes}//
                  gruposTotais={this.state.gruposTotais}//
                  changeSelectTimesFasedeGrupo={this.changeSelectTimesFasedeGrupo}//
                  adicionarRodadaFasedeGrupo={this.adicionarRodadaFasedeGrupo}
                  alteracaoGrupoFasedeGrupo={this.alteracaoGrupoFasedeGrupo}
                  grupoSelecionado={this.state.grupoSelecionado}//
                  listaTimesPorGrupo={this.state.listaTimesPorGrupo}//
                  alteracaoData={this.alteracaoData}
                  alteracaoLocal={this.alteracaoLocal}
                  alteracaoHora={this.alteracaoHora}
                  alteracao={this.alteracao}
                  alertAdicionandoRodada={this.state.alertAdicionandoRodada}//
                  alertaSalvoSucesso={this.state.alertaSalvoSucesso}//
                  alertaSalvoError={this.state.alertaSalvoError}
                />
              </>
            )}


            <ListaTimes
              listaTimeInscritos={this.state.listaTimeInscritos}
              changeModalAdicionaAtleta={this.changeModalAdicionaAtleta}
            />
            <ModalListaPartida
              modalListaPartida={this.state.modalListaPartida}
              changeModalListaPartida={this.changeModalListaPartida}
              deletaListaPartida={this.deletaListaPartida}
              adicionaListaPartida={this.adicionaListaPartida}
              changeModalAdicionarRodada={this.changeModalAdicionarRodada}
              partidasCadastradas={this.state.partidasCadastradas}
              partida={this.state.partida}
              ida_volta={this.props.location.query.ida_volta}
              todos_contra_todos={this.props.location.query.todos_contra_todo}
              fase_atual={this.state.faseAtual}
              campeonato_id={this.props.location.query.campeonato_id}
              rodadaId={this.state.rodadaId}
              info={this.props.location.query.info}
              tipo_campeonato={this.props.location.query.tipo_campeonato}
            />
          </Segment >
        </Form>




      </div >

    );
  }
}
