import React, { Component } from "react";
import { Table, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { Pagination } from "@material-ui/core/";
import api from "../../../../services/api";
import Requisicoes from "../../../../utils/Requisicoes";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import converterParaLabel from "../../../../utils/converteParaLabel";
import converterParaLabelComAcao from "../../../../utils/converteParaLabelComAcao";

export default class UsuarioTitularLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      pagina: 1,
      totalPaginas: 1,
      options: [],
    };
    this.listaPessoas();
  }

  listaPessoas = async (page = 1) => {
    const resposta = await api.post(
      "/pessoa/listaTitulares" + Requisicoes.entidade + "&pagina=" + page,
      "",
      Requisicoes.header
    );
    const { pessoas, pagina, totalPaginas } = resposta.data;

    this.setState({ lista: pessoas, pagina, totalPaginas });
  };

  pesquisaPessoa = async () => {
    let term = document.getElementById("pesquisaPessoa").value;
    if (term.toString().indexOf("%") !== -1) {
      term = document
        .getElementById("pesquisaPessoa")
        .value.toString()
        .replace("%", "");
      document.getElementById("pesquisaPessoa").value = term;
      if (document.getElementById("porcentagem").innerText === "%") {
        document.getElementById("porcentagem").innerText = "";
      } else {
        document.getElementById("porcentagem").innerText = "%";
      }
    }
    setTimeout(async () => {
      if (isFinite(String(term))) {
        await api
          .post(
            "/pessoa/pesquisaPorCodigoAcaoValorExato" + Requisicoes.entidade,
            { pesquisa: document.getElementById("pesquisaPessoa").value },
            Requisicoes.header
          )
          .then((retorno) => {
            this.setState({
              options: converterParaLabelComAcao(retorno.data),
            });
          });
      } else {
        await api
          .post(
            "/pessoa/pesquisaNomeInicial" + Requisicoes.entidade,
            { pesquisa: document.getElementById("pesquisaPessoa").value },
            Requisicoes.header
          )
          .then((retorno) => {
            this.setState({
              options: converterParaLabel(retorno.data),
            });
          });
      }
    }, 200);
  };

  alteracaoPesquisa = async (event, v) => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/pessoa/visualiza" + Requisicoes.entidade,
        { id: v.value },
        Requisicoes.header
      );
      this.setState({ lista: [retorno.data] });
    }, 500);
  };

  render() {
    return (
      <div>
        <Row>
          <Col sm={6}>
            <Form.Label>Pessoa</Form.Label>
            <Row>
              <Col sm={1}>
                <Form.Label id="porcentagem"></Form.Label>
              </Col>
              <Col sm={11}>
                <Autocomplete
                  id="pesquisaPessoa"
                  options={this.state.options}
                  getOptionLabel={(option) => option.label}
                  onKeyDown={this.pesquisaPessoa}
                  onChange={this.alteracaoPesquisa}
                  renderInput={(params) => (
                    <TextField {...params} label="Busca" size="small" />
                  )}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Código</Table.HeaderCell>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <Link to={{ pathname: "/usuarioTitular" }}>
                  <Button positive icon="add" size="tiny"></Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.lista.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>
                  {e.acao != null ? e.acao.codigo : e.acao.codigo_auxiliar != null ? e.acao.codigo_auxiliar : 'Sem código'}
                </Table.Cell>
                <Table.Cell>{e.nome}</Table.Cell>
                <Table.Cell width={1}>
                  <Link
                    to={{ pathname: "/usuarioTitular", query: { id: e.id } }}
                  >
                    <Button primary icon="edit" size="tiny"></Button>
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          {(this.state.lista.length >= 50) && (
            <Table.Footer>
              <Table.Row verticalAlign="middle" textAlign="center">
                <Table.HeaderCell colSpan={4}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Pagination
                      count={this.state?.totalPaginas}
                      variant="outlined"
                      shape="rounded"
                      boundaryCount={1}
                      page={this.state.activePage}
                      onChange={(e, a) => {
                        this.listaPessoas(a);
                      }}
                    />
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
      </div>
    );
  }
}
