import React, { Component } from "react";
import { Container, Header, Menu, Icon, Table } from "semantic-ui-react";
import { Row, Col, Form, Button } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Pagination } from "semantic-ui-react";

import moment from "moment-timezone";

import api from "../../../services/api";

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data_inicio: null,
      data_final: null,
      select_month: null,
      lista_aniversariantes_dia: [],
      lista_aniversariantes_pesquisa: [],
      paginaAtual1: 1,
      paginaAtual2: 1,
      botaoImprimir1: false,
      botaoImprimir2: false,

      mes_inicio: 0,
      dia_inicio: 1,
      mes_final: 0,
      dia_final: 1,

      lista_dias_inicio: [],
      lista_dias_fim: [],

      totalPages1: 0,
      totalPages2: 0,
    };
    // if (this.state.lista_aniversariantes_dia === 0) {
    this.buscaAniversariantes();
    // }
  }

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
    var name = event.target.name;
    var value = event.target.value;

    switch (name) {
      case "mes_inicio":
        let aux1 = this.state.data_inicio;
        aux1 = moment(aux1).set({ month: value }).format("YYYY-MM-DD");
        this.setState({ data_inicio: aux1 });
        let listaAux1 = [];
        for (let i = 0; i < moment(this.state.data_inicio).daysInMonth(); i++) {
          listaAux1.push(i + 1);
        }
        this.setState({ lista_dias_inicio: listaAux1 });
        break;

      case "dia_inicio":
        let aux2 = this.state.data_inicio;
        aux2 = moment(aux2).set({ date: value }).format("YYYY-MM-DD");
        this.setState({ data_inicio: aux2 });
        break;

      case "mes_final":
        let aux3 = this.state.data_final;
        aux3 = moment(aux3).set({ month: value }).format("YYYY-MM-DD");
        this.setState({ data_final: aux3 });
        let listaAux2 = [];
        for (let i = 0; i < moment(this.state.data_final).daysInMonth(); i++) {
          listaAux2.push(i + 1);
        }
        this.setState({ lista_dias_fim: listaAux2 });
        break;

      case "dia_final":
        let aux4 = this.state.data_final;
        aux4 = moment(aux4).set({ date: value }).format("YYYY-MM-DD");
        this.setState({ data_final: aux4 });
        break;

      default:
        break;
    }
  };

  buscaAniversariantes = async (event) => {
    var flag = 0;
    if (
      this.state.lista_aniversariantes_dia.length !== 0 &&
      this.state.data_inicio !== null &&
      this.state.data_final !== null
    ) {
      //Pesquisar intervalos entre anos diferentes, ex: de dezembro a fevereiro.
      if (
        Date.parse(this.state.data_inicio) > Date.parse(this.state.data_final)
      ) {
        let aux = this.state.data_final;
        //aux = moment(aux).set({'year': moment(aux).year() + 1}).format("YYYY-MM-DD");
        aux = moment(aux).add(1, "Y").format("YYYY-MM-DD");
        this.setState({ data_final: aux });
        flag = 1;
      }
    }
    var r = await api.post(
      "/pessoa/buscaAniversariantesPorTipoTitulo" + requisicoes.entidade,
      {
        data_inicio:
          this.state.lista_aniversariantes_dia.length === 0
            ? moment().format("YYYY-MM-DD")
            : this.state.data_inicio || moment().format("YYYY-MM-DD"),
        data_final:
          this.state.lista_aniversariantes_dia.length === 0
            ? moment().format("YYYY-MM-DD")
            : this.state.data_final || moment().format("YYYY-MM-DD"),
        pagina:
          this.state.lista_aniversariantes_dia.length === 0
            ? this.state.paginaAtual1
            : this.state.paginaAtual2,
        tipo: 12514, //Patrimonial
      },
      requisicoes.header
    );

    if (
      this.state.lista_aniversariantes_dia.length === 0
      // &&
      // this.state.totalPages1 !== 0
    ) {
      this.setState({
        lista_aniversariantes_dia: r.data.aniversariantes,
        data_inicio: moment().startOf("month").format("YYYY-MM-DD"),
        data_final: moment().endOf("month").format("YYYY-MM-DD"),
        dia_inicio: parseInt(moment(this.state.data_inicio).format("DD")),
        dia_final: parseInt(moment(this.state.data_final).format("DD")),
        mes_inicio: parseInt(moment(this.state.data_inicio).month()),
        mes_final: parseInt(moment(this.state.data_final).month()),
      });

      var listaAux = [];
      for (let i = 0; i < moment(this.state.data_inicio).daysInMonth(); i++) {
        listaAux.push(i + 1);
      }
      this.setState({ lista_dias_inicio: listaAux });

      listaAux = [];
      for (let i = 0; i < moment(this.state.data_final).daysInMonth(); i++) {
        listaAux.push(i + 1);
      }
      this.setState({ lista_dias_fim: listaAux });

      this.setState({ totalPages1: r.data.totalPaginas });
      if (r.data.aniversariantes.length === 0) {
        this.setState({ paginaAtual1: 0 });
      }
    } else {
      this.setState({
        lista_aniversariantes_pesquisa: r.data.aniversariantes,
        totalPages2: r.data.totalPaginas,
      });
    }

    if (flag === 1) {
      let aux = this.state.data_final;
      //aux = moment(aux).set({'year': moment(aux).year() - 1});
      aux = moment(aux).subtract(1, "Y").format("YYYY-MM-DD");
      this.setState({ data_final: aux });
    }
  };

  pageChange1 = async (event, data) => {
    if (data === undefined || data === null) {
      data = {};
      data.activePage = 1;
    }
    this.setState({
      lista_aniversariantes_dia: [],
      paginaAtual1: data.activePage,
    });
    this.buscaAniversariantes();
  };

  pageChange2 = async (event, data) => {
    if (data === undefined || data === null) {
      data = {};
      data.activePage = 1;
    }
    this.setState({ paginaAtual2: data.activePage });
    this.buscaAniversariantes();
  };

  gerarRelatorioAniversariantesDia = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado?e=" + entidade,
      {},
      {
        headers: { token: "Baer " + token },
      }
    );
    const nomePessoa = await api.post(
      "/pessoa/pessoaIdNome?e=" + entidade,
      { id: usuarioLogado.data.pessoa_id },
      {
        headers: { token: "Baer " + token },
      }
    );

    this.setState({ botaoImprimir1: true });

    await api.post(
      "/pessoa/relatorioAniversariantes?e=" + entidade,
      {
        userId: usuarioLogado.data.pessoa_id,
        nome_operador: nomePessoa.data.nome,
        //tipo: this.state.tipo
        tipo: 12514, //Patrimonial
        data_inicio: moment().format("YYYY-MM-DD"),
        data_final: moment().format("YYYY-MM-DD"),
      },
      {
        headers: { token: "Baer " + token },
      }
    );

    this.setState({ botaoImprimir1: false });

    const r = await api.get(
      "/pessoa/arquivoRelatorioAniversariantes?e=" +
        entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.pessoa_id
    ); //
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "pessoa/arquivoRelatorioAniversariantes?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.pessoa_id; //

    await oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = await function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  gerarRelatorioAniversariantesPesquisa = async (event) => {
    var flag = 0;
    if (
      this.state.lista_aniversariantes_dia.length != 0 &&
      this.state.data_inicio !== null &&
      this.state.data_final !== null
    ) {
      //Pesquisar intervalos entre anos diferentes, ex: de dezembro a fevereiro.
      if (
        Date.parse(this.state.data_inicio) > Date.parse(this.state.data_final)
      ) {
        let aux = this.state.data_final;
        //aux = moment(aux).set({'year': moment(aux).year() + 1}).format("YYYY-MM-DD");
        aux = moment(aux).add(1, "Y").format("YYYY-MM-DD");
        await this.setState({ data_final: aux });
        flag = 1;
      }
    }

    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado?e=" + entidade,
      {},
      {
        headers: { token: "Baer " + token },
      }
    );
    const nomePessoa = await api.post(
      "/pessoa/pessoaIdNome?e=" + entidade,
      { id: usuarioLogado.data.pessoa_id },
      {
        headers: { token: "Baer " + token },
      }
    );

    this.setState({ botaoImprimir2: true });

    await api.post(
      "/pessoa/relatorioAniversariantes?e=" + entidade,
      {
        userId: usuarioLogado.data.pessoa_id,
        nome_operador: nomePessoa.data.nome,
        tipo: 12514, //Patrimonial
        data_inicio: moment(this.state.data_inicio).format("YYYY-MM-DD"),
        data_final: moment(this.state.data_final).format("YYYY-MM-DD"),
      },
      {
        headers: { token: "Baer " + token },
      }
    );

    this.setState({ botaoImprimir2: false });

    const r = await api.get(
      "/pessoa/arquivoRelatorioAniversariantes?e=" +
        entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.pessoa_id
    ); //
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "pessoa/arquivoRelatorioAniversariantes?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.pessoa_id; //

    await oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = await function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };

    if (flag === 1) {
      let aux = this.state.data_final;
      //aux = moment(aux).set({'year': moment(aux).year() - 1});
      aux = moment(aux).subtract(1, "Y").format("YYYY-MM-DD");
      await this.setState({ data_final: aux });
    }

    oReq.send();
  };

  render() {
    return (
      <div>
        <Header as="h2" dividing>
          Aniversariantes do dia
        </Header>

        <Col sm={0.9} style={{ float: "right" }}>
          <Button
            variant="primary"
            onClick={this.gerarRelatorioAniversariantesDia}
            disabled={
              this.state.lista_aniversariantes_dia.length === 0 ||
              this.state.botaoImprimir1
            }
            style={{ marginBottom: "10px" }}
          >
            Imprimir
          </Button>
        </Col>
        {this.state.lista_aniversariantes_dia.length == 0 &&
        this.state.paginaAtual1 != 0 ? (
          <Container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "40px",
            }}
          >
            <CircularProgress></CircularProgress>
          </Container>
        ) : this.state.paginaAtual1 == 0 ? (
          <Container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <h4>Ninguém faz aniversário hoje</h4>
          </Container>
        ) : (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nome</Table.HeaderCell>
                <Table.HeaderCell>Data de nascimento</Table.HeaderCell>
                <Table.HeaderCell>Idade</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.lista_aniversariantes_dia.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{e.nome}</Table.Cell>
                  <Table.Cell>
                    {moment(e.datanascimento).format("DD/MM/YYYY")}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(moment()).year() -
                      moment(e.datanascimento).year() +
                      " anos"}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}

        <Container
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Pagination
            boundaryRange={0}
            //defaultActivePage={1}
            ellipsisItem={null}
            //firstItem={null}
            //lastItem={null}
            siblingRange={1}
            totalPages={this.state.totalPages1}
            onPageChange={this.pageChange1}
            activePage={this.state.paginaAtual1}
            disabled={this.state.lista_aniversariantes_dia.length == 0}
          />
        </Container>

        <Header as="h2" dividing>
          Pesquisa de Aniversariantes
        </Header>
        <Row>
          <Col sm={2}>
            <Form.Label>Mês Início Aniversários</Form.Label>
            {/* <Form.Control
                  placeholder="Data Inicio"
                  aria-describedby="basic-addon1"
                  type="date"
                  name="data_inicio"
                  defaultValue={this.state.data_inicio}
                  onChange={this.alteracao}
                /> */}
            <Form.Control
              as="select"
              placeholder="Mês"
              aria-describedby="basic-addon1"
              name="mes_inicio"
              value={this.state.mes_inicio}
              onChange={this.alteracao}
            >
              <option value={0}>Janeiro</option>
              <option value={1}>Fevereiro</option>
              <option value={2}>Março</option>
              <option value={3}>Abril</option>
              <option value={4}>Maio</option>
              <option value={5}>Junho</option>
              <option value={6}>Julho</option>
              <option value={7}>Agosto</option>
              <option value={8}>Setembro</option>
              <option value={9}>Outubro</option>
              <option value={10}>Novembro</option>
              <option value={11}>Dezembro</option>
            </Form.Control>
          </Col>
          <Col sm={2}>
            <Form.Label>Dia Inicio Aniversários</Form.Label>
            <Form.Control
              as="select"
              placeholder="Dia"
              aria-describedby="basic-addon1"
              name="dia_inicio"
              value={this.state.dia_inicio}
              onChange={this.alteracao}
            >
              {this.state.lista_dias_inicio.length != 0
                ? this.state.lista_dias_inicio.map((e, i) => (
                    <option value={e} key={i}>
                      {e}
                    </option>
                  ))
                : null}
            </Form.Control>
          </Col>
          <Col sm={1}></Col>
          <Col sm={2}>
            <Form.Label>Mês Final Aniversários</Form.Label>
            {/* <Form.Control
                  placeholder="Data Final"
                  aria-describedby="basic-addon1"
                  type="date"
                  name="data_final"
                  defaultValue={this.state.data_final}
                  onChange={this.alteracao}

                /> */}
            <Form.Control
              as="select"
              placeholder="Mês"
              aria-describedby="basic-addon1"
              name="mes_final"
              value={this.state.mes_final}
              onChange={this.alteracao}
            >
              <option value={0}>Janeiro</option>
              <option value={1}>Fevereiro</option>
              <option value={2}>Março</option>
              <option value={3}>Abril</option>
              <option value={4}>Maio</option>
              <option value={5}>Junho</option>
              <option value={6}>Julho</option>
              <option value={7}>Agosto</option>
              <option value={8}>Setembro</option>
              <option value={9}>Outubro</option>
              <option value={10}>Novembro</option>
              <option value={11}>Dezembro</option>
            </Form.Control>
          </Col>
          <Col sm={2}>
            <Form.Label>Dia Final Aniversários</Form.Label>
            <Form.Control
              as="select"
              placeholder="Dia"
              aria-describedby="basic-addon1"
              name="dia_final"
              value={this.state.dia_final}
              onChange={this.alteracao}
            >
              {this.state.lista_dias_fim.map((e, i) => (
                <option value={e}>{e}</option>
              ))}
            </Form.Control>
          </Col>
          <Col sm={2}></Col>
          <Col sm={1}>
            <Button
              variant="success"
              style={{ marginTop: "25px" }}
              onClick={this.buscaAniversariantes}
            >
              Buscar
            </Button>
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Data de nascimento</Table.HeaderCell>
              <Table.HeaderCell>Idade que terá</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.lista_aniversariantes_pesquisa.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>{e.nome}</Table.Cell>
                <Table.Cell>
                  {moment(e.datanascimento).format("DD/MM/YYYY   ")}

                  {moment(e.datanascimento).format("DD") ===
                  moment(moment()).format("DD") ? (
                    moment(e.datanascimento).format("MM") ===
                    moment(moment()).format("MM") ? (
                      <Icon name="birthday cake" />
                    ) : null
                  ) : null}
                </Table.Cell>
                <Table.Cell>
                  {moment(moment()).year() -
                    moment(e.datanascimento).year() +
                    " anos"}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Col style={{ float: "left" }}>
          {this.state.lista_aniversariantes_pesquisa.length !== 0 && (
            <div>
              <Icon name="birthday cake" />: Faz aniversário hoje.
            </div>
          )}
        </Col>
        <Col sm={0.9} style={{ float: "right" }}>
          <Button
            variant="primary"
            onClick={this.gerarRelatorioAniversariantesPesquisa}
            disabled={
              this.state.lista_aniversariantes_pesquisa.length === 0 ||
              this.state.botaoImprimir2
            }
          >
            Imprimir
          </Button>
        </Col>
        <Container
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Pagination
            boundaryRange={0}
            ellipsisItem={null}
            //firstItem={null}
            //lastItem={null}
            siblingRange={1}
            totalPages={this.state.totalPages2}
            onPageChange={this.pageChange2}
            activePage={this.state.paginaAtual2}
            disabled={this.state.lista_aniversariantes_pesquisa == 0}
          />
        </Container>
      </div>
    );
  }
}
