import React, { Component } from "react";
import { Header } from "semantic-ui-react";
import ExportacaoContabil from "../../financeiro/exportacaoContabil/index";
import CentroDeCustoLista from "../centroDeCusto/lista";
import ElementoDeCustoLista from "../elementoDeCusto/lista";
import ContaContabilLista from "../contaContabil/lista";
import LancamentoBancario from "../../financeiro/lancamentoBancario/lista";
import Consulta from "../../financeiro/consulta/";

import { Segment } from "semantic-ui-react";
import { Row, Col } from "react-bootstrap";
import { Paper, Tabs, Tab } from "@material-ui/core";

import { Icon as Iconify } from "@iconify/react";
import dollarIcon from "@iconify-icons/mdi/dollar";
import cashIcon from "@iconify-icons/mdi/cash";
import calculatorIcon from "@iconify-icons/mdi/calculator";
import fileDownloadIcon from "@iconify-icons/mdi/file-download";
import fileDocumentOutlineIcon from "@iconify-icons/mdi/file-document-outline";
import chartAreasplineIcon from "@iconify-icons/mdi/chart-areaspline";

export default class Contabil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selecionado:
        localStorage.getItem("pagina_anterior_contabil") ||
        this.props.submenus[0].nome,
    };
  }

  changeTabMenu = async (event, newValue) => {
    localStorage.setItem("pagina_anterior_contabil", newValue);
    this.setState({ selecionado: newValue });
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Contábil
        </Header>
        <Segment>
          <Row style={{ paddingBottom: 10 }}>
            <Col sm={12}>
              <Paper square>
                <Tabs
                  indicatorColor="secondary"
                  textColor="primary"
                  style={{ outline: "none" }}
                  variant="scrollable"
                  onChange={this.changeTabMenu}
                  value={this.state.selecionado}
                >
                  <Tab
                    icon={<Iconify icon={dollarIcon} width={24} />}
                    style={{ outline: "none" }}
                    label="Centro de custo"
                    value={"centro_de_custo"}
                  ></Tab>
                  <Tab
                    icon={<Iconify icon={cashIcon} width={24} />}
                    style={{ outline: "none" }}
                    label="Elemento de custo"
                    value={"elemento_de_custo"}
                  ></Tab>
                  <Tab
                    icon={<Iconify icon={calculatorIcon} width={24} />}
                    style={{ outline: "none" }}
                    label="Conta Contábil"
                    value={"conta_contabil"}
                  ></Tab>
                  <Tab
                    style={{ outline: "none" }}
                    icon={<Iconify icon={fileDownloadIcon} width={24} />}
                    label="Exportação Contábil"
                    value={"exportacao_contabil"}
                  ></Tab>
                  <Tab
                    style={{ outline: "none" }}
                    icon={<Iconify icon={fileDocumentOutlineIcon} width={24} />}
                    label="Lançamento Bancário"
                    value={"lancamento_bancario"}
                  ></Tab>
                  <Tab
                    style={{ outline: "none" }}
                    icon={<Iconify icon={chartAreasplineIcon} width={24} />}
                    label="Relatório"
                    value={"relatorio"}
                  ></Tab>
                </Tabs>
              </Paper>
            </Col>
          </Row>
          <TabPanel
            value={this.state.selecionado}
            index={"centro_de_custo"}
            {...this.props}
          >
            <CentroDeCustoLista />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"elemento_de_custo"}
            {...this.props}
          >
            <ElementoDeCustoLista />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"conta_contabil"}
            {...this.props}
          >
            <ContaContabilLista permissoes={this.props.permissoes} />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"exportacao_contabil"}
            {...this.props}
          >
            <ExportacaoContabil />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"lancamento_bancario"}
            {...this.props}
          >
            <LancamentoBancario />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"relatorio"}
            {...this.props}
          >
            <Consulta />
          </TabPanel>
        </Segment>
      </div>
    );
  }
}

function TabPanel(props) {
  const { children, value, index, submenus, ...other } = props;
  return submenus && submenus.find((e) => e.nome === index) ? (
    <div hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </div>
  ) : (
    <div></div>
  );
}
