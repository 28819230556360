import React, { Component } from "react";
import { Container, Table, Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";

export default class ClubeVantagemLista extends Component {
  constructor(props) {
    super(props);
    this.state = { lista: [] };
    this.listaClubeVantagem();
  }

  listaClubeVantagem = async () => {
    const resposta = await api.post(
      "/clubeVantagem/lista" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    this.setState({ lista: resposta.data });
  };

  pesquisaUsuarioClube = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/clubeVantagem/pesquisa" + requisicoes.entidade,
        {
          pesquisa: document.getElementById("pesquisaUsuarioClube").value,
          entidade: window.localStorage.getItem("e_clube"),
        },
        requisicoes.header
      );
      this.setState({ lista: retorno.data });
    }, 500);
  };

  render() {
    return (
      <div>
        <Row>
          <Col sm={6}>
            <Select
              placeholder="Digite para buscar"
              name="pessoa"
              onKeyDown={this.pesquisaUsuarioClube}
              inputId="pesquisaUsuarioClube"
            />
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Endereço</Table.HeaderCell>
              <Table.HeaderCell>Telefone</Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <Link to={{ pathname: "/clubeVantagem" }}>
                  <Button positive icon="add" size="tiny"></Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.lista.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>{e.nome}</Table.Cell>
                <Table.Cell>{e.endereco}</Table.Cell>
                <Table.Cell>{e.telefone}</Table.Cell>
                <Table.Cell width={1}>
                  <Link
                    to={{ pathname: "/clubeVantagem", query: { id: e.id } }}
                  >
                    <Button primary icon="edit" size="tiny"></Button>
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer></Table.Footer>
        </Table>
      </div>
    );
  }
}
