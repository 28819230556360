import React, { Component } from "react";
import { Container, Table, Button, Popup, Icon, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
import api from "../../../../../services/api";
import requisicoes from "../../../../../utils/Requisicoes";
import Select from "react-select";
import { Row, Col } from "react-bootstrap";

import moment from "moment";

export default class RequisicaoLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      pagina: 1,
      totalPaginas: 1,
      options: [],
      popUpPesquisa: false,
      sortedName: "data",
      sortDirection: "descending",
    };
    this.lista();
  }

  lista = async (page = 1) => {

    const resposta = await api.post(
      "/requisicao/lista" + requisicoes.entidade + "&pagina=" + page,
      {
        sortDirection: this.state.sortDirection,
        sortedName: this.state.sortedName,
      },
      requisicoes.header
    );
    const { reqs, pagina, totalPaginas } = resposta.data;
    this.setState({ lista: reqs, pagina, totalPaginas });
  };

  excluir = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const retorno = await api.post(
      "/requisicao/excluir?e=" + entidade + "&id=" + event.target.value,
      "",
      { headers: { token: "Baer " + token } }
    );
    if (retorno != null) {
      window.location.href = "/almoxarifado";
    }
  };

  pesquisaRequisicao = async () => {
    console.log("entra aqui?");
    this.setState({ popUpPesquisa: false });
    setTimeout(async () => {
      let pesquisa = document.getElementById("pesquisaRequisicao").value;
      pesquisa = parseInt(pesquisa);
      console.log("pesquisa: ", pesquisa);
      if (pesquisa != null && pesquisa != "") {
        const retorno = await api.post(
          "/requisicao/pesquisa" + requisicoes.entidade,
          { pesquisa: pesquisa },
          requisicoes.header
        );
        if (retorno.data.length == 0) {
          this.setState({ popUpPesquisa: true });
        }
        console.log("retorno.data: ", retorno.data);
        // this.setState({ listaRequisicao: retorno.data });
        this.setState({ lista: retorno.data });
      } else {
        const resposta = await api.post(
          "/requisicao/lista" + requisicoes.entidade,
          "",
          requisicoes.header
        );
        this.setState({ lista: resposta.data });
        //this.setState({ listaRequisicao: resposta.data });
      }
    }, 500);
  };

  proximaPagina = async () => {
    const { pagina } = this.state;
    const paginaNumero = parseInt(pagina) + 1;
    this.lista(paginaNumero);
  };

  voltarPagina = async () => {
    const { pagina } = this.state;
    const paginaNumero = pagina - 1;
    this.lista(paginaNumero);
  };

  sortTableClick = async (sortedName) => {
    this.state.sortedName = sortedName;
    this.state.sortDirection =
      this.state.sortDirection === "ascending" ? "descending" : "ascending";
    this.lista();
  };

  render() {
    return (
      <div className="lista">
        <Row>
          <Col sm={6}>
            <Popup
              content="Nenhum registro encontrado!"
              open={this.state.popUpPesquisa}
              position="right center"
              trigger={
                <Select
                  placeholder="Digite para buscar"
                  name="requisicao"
                  onKeyDown={this.pesquisaRequisicao}
                  inputId="pesquisaRequisicao"
                  menuIsOpen={false}
                />
              }
            />
          </Col>
        </Row>
        <Table celled sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={
                  this.state.sortedName === "numero"
                    ? this.state.sortDirection
                    : null
                }
                onClick={() => this.sortTableClick("numero")}
              >
                Número
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  this.state.sortedName === "data"
                    ? this.state.sortDirection
                    : null
                }
                onClick={() => this.sortTableClick("data")}
              >
                Data
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  this.state.sortedName === "origem"
                    ? this.state.sortDirection
                    : null
                }
                onClick={() => this.sortTableClick("origem")}
              >
                Origem
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  this.state.sortedName === "destino"
                    ? this.state.sortDirection
                    : null
                }
                onClick={() => this.sortTableClick("destino")}
              >
                Destino
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <Link to={{ pathname: "/requisicao" }}>
                  <Button positive icon="add" size="tiny"></Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.lista.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>{e.numero}</Table.Cell>
                <Table.Cell>{moment(e.data).format("DD/MM/YYYY")}</Table.Cell>
                <Table.Cell>{e.estrutura.nome}</Table.Cell>
                <Table.Cell>{e.estrutura1.nome}</Table.Cell>
                <Table.Cell width={1}>
                  <Link to={{ pathname: "/requisicao", query: { id: e.id } }}>
                    <Button primary icon="edit" size="tiny"></Button>
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan={4}>
                <Menu pagination>
                  <Menu.Item
                    icon
                    onClick={this.voltarPagina}
                    disabled={parseInt(this.state.pagina) === 1}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  <Menu.Item
                    icon
                    onClick={this.proximaPagina}
                    disabled={
                      parseInt(this.state.pagina) ===
                      parseInt(this.state.totalPaginas)
                    }
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}
