import React from "react";
import { Table, Button, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";

// parametros
// title titulo da pagina
// columns lista das colunas da tabela
// list lista que é renderizada na pagina
// link rota para que é redirecionado
const List = ({ title = "", columns = [], list = [], link = "" }) => {
  return (
    <div>
      {title !== null && (
        <Header as="h1" dividing>
          {title}
        </Header>
      )}
      <Table celled>
        <Table.Header>
          {/* cabeçalho da lista */}
          <Table.Row>
            {columns.map((c) => (
              <Table.HeaderCell>{c.name}</Table.HeaderCell>
            ))}
            <Table.HeaderCell width={1}>
              <Link to={{ pathname: link }}>
                <Button positive icon="add" size="tiny"></Button>
              </Link>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {/* corpo da lista */}

        <Table.Body>
          {list.map((e, i) => (
            <Table.Row key={i}>
              {columns.map((col) => (
                <Table.Cell>{e[col.key] !== null ? e[col.key] : ""}</Table.Cell>
              ))}
              <Table.Cell width={1}>
                {/* botao de redirecionamento */}

                <Link to={{ pathname: link, query: { id: e.id } }}>
                  <Button primary icon="edit" size="tiny"></Button>
                </Link>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default List;
