import React, { Component } from "react";
import { Table, Button, Header } from "semantic-ui-react";
import { Col } from "react-bootstrap";

export default class ListaGruposServico extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Col
        sm={6}
        style={{ paddingTop: "15px", }}
      >
        <Header as="h3">Grupos Serviço</Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Descrição</Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <Button
                  positive
                  onClick={this.props.handleOpenGruposServico}
                  icon="add"
                  type="button"
                  size="tiny"
                ></Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.grupos_servico !== undefined &&
              this.props.grupos_servico.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{e.descricao}</Table.Cell>
                  <Table.Cell width={1}>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Col>
    );
  }
}
