import React, { Component } from "react";
import { Menu } from "semantic-ui-react";

import TipoTitulo from "./tipoTitulo/index";
import DireitoRemissao from "./direitoRemissao";
import Cancelados from "./cancelados";
import Remidos from "./remidos";
import Adimplentes from "./tipoTitulo/adimplentes";
import TitulosDisponivel from "./tipoTitulo/tituloDisponivel";
import TitulosComDividas from "./tipoTitulo/TituloComDividas";

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: "tipoTitulo",
    };
  }
  render() {
    let content = "";
    switch (this.state.selectedMenu) {
      case "tipoTitulo":
        content = <TipoTitulo />;
        break;
      case "direitoRemissao":
        content = <DireitoRemissao />;
        break;
      case "remidos":
        content = <Remidos />;
        break;
      case "cancelados":
        content = <Cancelados />;
        break;
      case "adimplentes":
        content = <Adimplentes />;
        break;
      case "tituloDisponivel":
        content = <TitulosDisponivel />;
        break;
      // case "tituloComDividas":
      //   content = <TitulosComDividas />;
      //   break;

      default:
        content = <TipoTitulo />;
        break;
    }
    return (
      <div>
        <Menu pointing secondary>
          <Menu.Item
            name="tipoTitulo"
            active={this.state.selectedMenu === "tipoTitulo"}
            onClick={this.selectMenu.bind(null, "tipoTitulo")}
          >
            Tipo de Titulo
          </Menu.Item>

          <Menu.Item
            name="direitoRemissao"
            active={this.state.selectedMenu === "direitoRemissao"}
            onClick={this.selectMenu.bind(null, "direitoRemissao")}
          >
            Direito a Remissão
          </Menu.Item>

          <Menu.Item
            name="remidos"
            active={this.state.selectedMenu === "remidos"}
            onClick={this.selectMenu.bind(null, "remidos")}
          >
            Remidos
          </Menu.Item>

          <Menu.Item
            name="cancelados"
            active={this.state.selectedMenu === "cancelados"}
            onClick={this.selectMenu.bind(null, "cancelados")}
          >
            Cancelados
          </Menu.Item>
          <Menu.Item
            name="cancelados"
            active={this.state.selectedMenu === "adimplentes"}
            onClick={this.selectMenu.bind(null, "adimplentes")}
          >
            Adimplentes
          </Menu.Item>

          <Menu.Item
            name="cancelados"
            active={this.state.selectedMenu === "tituloDisponivel"}
            onClick={this.selectMenu.bind(null, "tituloDisponivel")}
          >
            Títulos Disponíveis
          </Menu.Item>
          {/* <Menu.Item
            name="tituloComDividas"
            active={this.state.selectedMenu === "tituloComDividas"}
            onClick={this.selectMenu.bind(null, "tituloComDividas")}
          >
            Títulos com Dívidas
          </Menu.Item> */}
        </Menu>
        {content}
      </div>
    );
  }
  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
  };
}
