import React, { Component } from "react";
import {
  Container,
  Header,
  Button,  
  Segment,  
  Checkbox,
} from "semantic-ui-react";
import Select from "react-select";
import { Row, Col, FormControl, Form } from "react-bootstrap";
import api from "../../../../services/api";
import renomearChave from "../../../../services/renomearChave";
import requisicoes from "../../../../utils/Requisicoes";
import moment from "moment-timezone";


export default class ExameFisico extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      dataExame: moment().format("YYYY-MM-DD"),
      dataValidade: moment().add(1, "Y").format("YYYY-MM-DD"),
      pessoa: null,
      infoPessoa: null,
      pessoa_id: null,
      options: [],
      dependentes: [],
    };
    this.alteracao = this.alteracao.bind(this);
    this.alteracaoSelectPessoa = this.alteracaoSelectPessoa.bind(this);
    this.visualizacao();
  }

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (
      event.target.name === "dataExame" &&
      moment(event.target.value).isValid()
    ) {
      this.setState({
        dataValidade: moment(event.target.value)
          .add(1, "Y")
          .format("YYYY-MM-DD"),
      });
    }
  };

  alteracaoSelectPessoa = async (event) => {
    this.setState({ pessoa_id: event.value });
    this.setState({ pessoa: event });
    const resposta = await api.post(
      "/pessoa/visualiza" + requisicoes.entidade,
      { id: event.value },
      requisicoes.header
    );
    if (resposta.data.titular_id === null) {
      var dependentes = await api.post(
        "/pessoa/dependentes" + requisicoes.entidade,
        { titular_id: resposta.data.id },
        requisicoes.header
      );
      for (let i = 0; i < dependentes.data.length; i++) {
        const element = dependentes.data[i];
        element.checked = false;
      }
      this.setState({ dependentes: dependentes.data });
    } else {
      var dependentes = await api.post(
        "/pessoa/dependentes" + requisicoes.entidade,
        { titular_id: resposta.data.titular_id },
        requisicoes.header
      );
      for (let i = 0; i < dependentes.data.length; i++) {
        const element = dependentes.data[i];
        element.checked = false;
        if (element.id === resposta.data.id) {
          dependentes.data.splice(i, 1);
          break;
        }
      }
      let titular = await api.post(
        "/pessoa/visualiza" + requisicoes.entidade,
        { id: resposta.data.titular_id },
        requisicoes.header
      );
      dependentes.data.push(titular.data);

      this.setState({ dependentes: dependentes.data });
    }

    this.setState({ infoPessoa: resposta.data });
  };

  pesquisaPessoa = async () => {
    setTimeout(async () => {
      let term = document.getElementById("pesquisaPessoa").value;
      let retorno = [];
      console.log("term: ", term);
      if (isFinite(String(term))) {
        retorno = await api.post(
          "/pessoa/pesquisaPorCodigoAcaoValorExato" + requisicoes.entidade,
          { pesquisa: document.getElementById("pesquisaPessoa").value },
          requisicoes.header
        );
      } else {
        retorno = await api.post(
          "/pessoa/pesquisa" + requisicoes.entidade,
          { pesquisa: document.getElementById("pesquisaPessoa").value },
          requisicoes.header
        );
      }
      let lista = [];
      for (let i = 0; i < retorno.data.length; i++) {
        const element = retorno.data[i];
        let objeto = { value: element.id };
        if (isFinite(String(term))) {
          objeto.label = element.acao.codigo + " - " + element.nome;
        } else {
          objeto.label = element.nome;
        }
        if (element.titular_id !== null) {
          objeto.label += "    - Dependente";
        } else if (element.acao === undefined || element.acao === null) {
          objeto.label += "    - Sem ação";
        } else {
          objeto.label += "    - Titular";
        }
        if (element.acao && element.acao.tipo_titulo) {
          objeto.label += " - " + element.acao.tipo_titulo.descricao;
        }
        lista.push(objeto);
      }
      this.setState({ options: lista });
    }, 500);
  };

  visualizacao = async () => {
    if (this.props.location.query !== undefined) {
      const resposta = await api.post(
        "/exameFisico/visualiza" + requisicoes.entidade,
        this.props.location.query,
        requisicoes.header
      );
      this.setState(resposta.data);
      const respPessoa = await api.post(
        "/pessoa/pessoaIdNome" + requisicoes.entidade,
        { id: resposta.data.pessoa_id },
        requisicoes.header
      );

      let p = renomearChave(respPessoa.data, "id", "value");
      p = renomearChave(p, "nome", "label");
      this.setState({ pessoa_id: respPessoa.data.id });
      this.setState({ pessoa: p });

      const respPessoaInfo = await api.post(
        "/pessoa/visualiza" + requisicoes.entidade,
        { id: resposta.data.pessoa_id },
        requisicoes.header
      );
      this.setState({ infoPessoa: respPessoaInfo.data });
    }
  };

  handleSubmit = (event) => {
    this.setState({ loading: true });
    this.submit();
    event.preventDefault();
  };

  submit = async () => {
    let retorno = "";
    const dados = {
      dataExame: this.state.dataExame,
      dataValidade: this.state.dataValidade,
      pessoa_id: this.state.pessoa_id,
    };
    if (this.state.id !== null) {
      retorno = await api.put(
        "/exameFisico/alterar" + requisicoes.entidade + "&id=" + this.state.id,
        dados,
        requisicoes.header
      );
    } else {
      retorno = await api.post(
        "/exameFisico/salvar" + requisicoes.entidade,
        dados,
        requisicoes.header
      );
    }

    for (let i = 0; i < this.state.dependentes.length; i++) {
      const element = this.state.dependentes[i];
      if (element.checked) {
        retorno = await api.post(
          "/exameFisico/salvar" + requisicoes.entidade,
          {
            dataExame: this.state.dataExame,
            dataValidade: this.state.dataValidade,
            pessoa_id: element.id,
          },
          requisicoes.header
        );
      }
    }

    if (retorno != null) {
      window.location.href = "/exames";
    }
  };

  excluir = async () => {
    const resposta = await api.post(
      "/exameFisico/excluir" + requisicoes.entidade + "&id=" + this.state.id,
      {},
      requisicoes.header
    );
    if (resposta != null) {
      window.location.href = "/exames";
    }
  };

  voltar() {
    window.location.href = "/exames";
  }

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Exame Físico
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={6}>
              <Form.Label>Data Exame</Form.Label>
              <FormControl
                type="date"
                aria-describedby="basic-addon1"
                name="dataExame"
                onChange={this.alteracao}
                required
                value={this.state.dataExame.toString().substr(0, 10)}
              />
            </Col>

            <Col sm={6}>
              <Form.Label>Data Validade</Form.Label>
              <FormControl
                type="date"
                aria-describedby="basic-addon1"
                name="dataValidade"
                onChange={this.alteracao}
                required
                value={this.state.dataValidade.toString().substr(0, 10)}
              />
            </Col>
          </Row>
          <Segment>
            <Form.Label>Pessoa</Form.Label>
            <Select
              placeholder="Digite para buscar"
              name="pessoa"
              value={this.state.pessoa}
              onChange={this.alteracaoSelectPessoa}
              options={this.state.options}
              onKeyDown={this.pesquisaPessoa}
              inputId="pesquisaPessoa"
              // isDisabled={this.props.location.query.cancelamento}
            />
          </Segment>
        </Form>

        {this.state.dependentes.length !== 0 ? (
          <>
            <Container style={{ marginTop: 20 }}>
              <strong style={{ fontSize: 20 }}>
                {"Ligados a essa pessoa"}
              </strong>
              {/* <Tooltip
                title={
                  <strong style={{ fontSize: 14 }}>
                    Adicionar exame para as pessoas selecionadas.
                  </strong>
                }
                arrow
                placement="right"
              >
                <Icon
                  name="help"
                  circular
                  size="small"
                  color="blue"
                  bordered
                  style={{ marginBottom: 5, marginLeft: 5 }}
                ></Icon>
              </Tooltip> */}

              {this.state.dependentes.map((e, i) => (
                <Row style={{ margin: 0, marginBottom: 5 }}>
                  <Checkbox
                    label={e.nome}
                    onChange={() =>
                      (this.state.dependentes[i].checked =
                        !this.state.dependentes[i].checked)
                    }
                  />
                </Row>
              ))}
            </Container>
          </>
        ) : null}

        <Segment clearing>
          <Header floated="right">
            <Button type="button" onClick={this.voltar}>
              Voltar
            </Button>
            {this.state.id !== undefined ? (
              <Button negative onClick={this.excluir}>
                Deletar
              </Button>
            ) : (
              ""
            )}
            <Button
              positive
              onClick={this.handleSubmit}
              loading={this.state.loading}
              disabled={this.state.loading}
            >
              Salvar
            </Button>
          </Header>
        </Segment>
      </div>
    );
  }
}
