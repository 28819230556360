import React, { Component } from "react";
import { Route } from "react-router-dom";

export default class ProtectedRoute extends Component {
  render() {
    const {
      component: Component,
      menus,
      submenus,
      permissoes,
      ...props
    } = this.props;
    return (
      <Route
        {...props}
        render={(props) =>
          menus.filter(
            (e) =>
              e.url === props.match.path &&
              ((e.tipo_clube !== undefined &&
                e.tipo_clube === window.localStorage.getItem("tipo_clube")) ||
                e.tipo_clube === undefined)
          ).length !== 0 ? (
            <Component
              {...props}
              menus={menus}
              submenus={submenus}
              permissoes={permissoes}
            />
          ) : (
            ""
          )
        }
      />
    );
  }
}
