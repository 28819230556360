import React, { Component } from "react";
import { Button, Modal, List, Form, Message, Select } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import moment from "moment-timezone";
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

export default class ModalSelecionarInstituicaoCobrancaBoleto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // options
      instituicoesOptions: [],

      // inputs
      insticao_cobranca_id: null,
    };
    this.buscaInstituicoesBoleto();
  }
  buscaInstituicoesBoleto = async () => {
    const instituicoes = await api.post(
      "/instituicaoCobranca/lista" + requisicoes.entidade,
      { boleto: true },
      requisicoes.header
    );

    let lista = [];

    for (let i = 0; i < instituicoes.data.length; i++) {
      const element = instituicoes.data[i];
      lista.push({
        value: element.id,
        text:
          "C.C. " +
          element.conta +
          "-" +
          element.digito_conta +
          " AG " +
          element.agencia +
          "-" +
          element.digito_agencia +
          " " +
          element.banco.nome,
      });
    }
    this.setState({ instituicoesOptions: lista });
  };
  render() {
    return (
      <>
        <Modal
          open={this.props.open}
          onClose={() => this.props.onClose()}
          size="tiny"
          style={{ overflow: "visible" }}
        >
          <Modal.Header>Selecionar instituição de cobrança</Modal.Header>
          <Modal.Content>
            É necessário selecionar uma instituição de cobrança para a geração
            dos boletos.
            <Form style={{ marginTop: 20 }}>
              <Form.Select
                fluid
                label="Instituição cobrança"
                options={this.state.instituicoesOptions}
                value={this.state.insticao_cobranca_id}
                onChange={(e, a) =>
                  this.setState({ insticao_cobranca_id: a.value })
                }
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Row style={{ alignItems: "center" }}>
              <Col></Col>
              <Col md="auto">
                <Button content="Voltar" onClick={() => this.props.onClose()} />
              </Col>
              <Col md="auto">
                <Button
                  content="Gerar Boletos"
                  color="blue"
                  disabled={this.state.insticao_cobranca_id === null}
                  onClick={() => {
                    this.props.alterarTipoCobranca(
                      this.state.insticao_cobranca_id
                    );
                    this.props.onClose();
                  }}
                />
              </Col>
            </Row>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}
