import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, FormControl, Form } from "react-bootstrap";
import { Header, Button, Divider, Segment, Checkbox } from "semantic-ui-react";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CurrencyInput from "react-currency-input";
import CircularProgress from "@material-ui/core/CircularProgress";

// Requisicoes
import requisicoes from "../../../utils/Requisicoes";
import api from "../../../services/api";

// Utils
import ConverterParaLabelPessoa from "../../../utils/converteParaLabel";
import ConverterParaLabel from "../../../utils/converteParaLabelComAcao";
import ReplaceAll from "replaceall";
import $ from "jquery";
import renomearChave from "../../../services/renomearChave";
import moment from "moment-timezone";

// Enum
import CreditoDebito from "../../../enum/CreditoDebito";
import TipoLancamento from "../../../enum/TipoLancamento";
import TipoCaixa from "../../../enum/TipoCaxa";

let buscando = false;
export default class LancamentoCaixa extends Component {
  constructor(props) {
    super(props);

    this.state = {
      descricao: "",
      usuario: "",
      options: [],
      pessoaSelect: [],
      lista_banco: [],
      pessoa:
        this.props.location.query !== undefined &&
        this.props.location.query.pessoa !== undefined
          ? this.props.location.query.pessoa
          : "",
      creditoDebito:
        this.props.location.query !== undefined
          ? this.props.location.query.select !== undefined &&
            this.props.location.query.select.value === "sangria"
            ? "debito"
            : "credito"
          : "debito",
      creditoDebitoList: CreditoDebito,
      creditoDebitoSelect: "",
      valor: "",
      tipoList: [
        { value: "venda", label: "Vendas" },
        { value: "sangria", label: "Sangria" },
        { value: "suprimento", label: "Suprimentos" },
        { value: "transferencia", label: "Transferência" },
      ],
      tipo:
        this.props.location.query !== undefined
          ? this.props.location.query.select
          : { value: null, label: null },
      tipoSelect: "",
      datavencimento: null,
      servico: null,
      servicoSelect: null,
      optionsServico: [],
      vagas_servico: null,
      valor_calculado: null,
      lista_agencia: [],
      lista_conta: [],
      conta_bancaria: null,
      value: { value: "", label: "" },
      inputValue: "",
      banco: null,
      agencia: null,
      conta: "",
      lista_conta_usuario_caixa: [],
      quantidade_parcelas: null,
      listaParcela: [],
      edicao: false,
      valorParcela: null,
      valorLancamento: null,
      valorTotalParcelaEdit: null,
      ultimaparcela: null,
      editado: false,
      bloqueiaTipo: false,
      valorServicoVenda: 0,

      socioChecked: true,
      ativoChecked: true,
      buscando: false,
    };
    if (localStorage.getItem("tipo_usuario_clube") === "modalidade") {
      this.state.tipo = { value: "venda", label: "Vendas" };
      this.state.creditoDebito = "credito";
      this.state.bloqueiaTipo = true;
    }
    this.alteracaoSelectTipo = this.alteracaoSelectTipo.bind(this);
    this.bancos();
  }

  todasContas = async () => {
    const retorno = await api.post(
      "/contaBancaria/contaComCaixa" + requisicoes.entidade,
      { entidade: window.localStorage.getItem("e_clube") },
      requisicoes.header
    );
    this.setState({ lista_conta_usuario_caixa: retorno.data });
  };

  alteracaoBanco = async (event) => {
    const retorno = await api.post(
      "/agencia/agenciaPorBanco" + requisicoes.entidade,
      { banco_id: event.target.value },
      requisicoes.header
    );
    this.setState({ lista_agencia: retorno.data });

    if (retorno !== null || retorno !== undefined) {
      this.setState({ banco: retorno.data });
    }
  };

  alteracaoAgencia = async (event) => {
    const retorno = await api.post(
      "/contaBancaria/contaPorAgencia" + requisicoes.entidade,
      { agencia_id: event.target.value },
      requisicoes.header
    );
    this.setState({ lista_conta: retorno.data });

    if (retorno.data !== null || retorno.data !== undefined) {
      this.setState({ agencia: retorno.data });
    }
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (this.state.tipo.value === "venda") {
      if (event.target.name === "valor") {
        let valor = event.target.value;
        valor = ReplaceAll(".", "", valor);
        valor = ReplaceAll(",", ".", valor);
        // this.setState({ valorServicoVenda: event.target.value })
        this.state.valorServicoVenda = event.target.value;
        this.state.valor = parseFloat(
          parseFloat(valor) * parseFloat(this.state.vagas_servico)
        ).toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        this.setState({
          valor: parseFloat(
            parseFloat(valor) * parseFloat(this.state.vagas_servico)
          ).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        });
        if (this.state.vagas_servico !== null) {
          this.state.valortTotal = parseFloat(
            parseFloat(valor) * parseFloat(this.state.vagas_servico)
          ).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          // this.setState({ valortTotal: parseFloat(parseFloat(valor) * parseFloat(this.state.vagas_servico)).toLocaleString("pt-BR", {
          //   minimumFractionDigits: 2,
          //   maximumFractionDigits: 2,
          // }) });
        }
      }
      if (event.target.name === "vagas_servico") {
        console.log("this.state.valor: ", this.state.valor);
        let valor = this.state.valor;
        if (typeof stringvalor === "string") {
          valor = ReplaceAll(".", "", valor);
          valor = ReplaceAll(",", ".", valor);
        }
        if (this.state.valorServicoVenda !== null) {
          this.state.valortTotal = parseFloat(
            parseFloat(valor) * parseFloat(event.target.value)
          ).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          // this.setState({ valortTotal: parseFloat(parseFloat(valor) * parseFloat(event.target.value)).toLocaleString("pt-BR", {
          //   minimumFractionDigits: 2,
          //   maximumFractionDigits: 2,
          // })});
        }
      }
      if (typeof this.state.quantidade_parcelas === "string") {
        this.gerarParcelas({
          target: { value: this.state.quantidade_parcelas },
        });
      }
    } else {
      if (event.target.name === "valor") {
        this.setState({ valortTotal: event.target.value });
        this.state.valortTotal = event.target.value;
      }
    }

    // if(this.state.tipo.value === "venda") {
    //   if(typeof this.state.quantidade_parcelas === "string"){
    //     this.gerarParcelas({target: {value: this.state.quantidade_parcelas}})
    //   }
    // }
  };

  componentMoney() {
    $(".dinheiro").mask("#.##0,00", {
      reverse: true,
      placeholder: "0,00",
    });
  }
  parcela(index) {
    $("#valorParcela" + index).mask("#.##0,00", {
      reverse: true,
      placeholder: "0,00",
    });
  }
  parcelafinal(index) {
    $("#valorParcela" + index).mask("#.##0,00", {
      reverse: true,
      placeholder: "0,00",
    });
  }

  bancos = async () => {
    const retorno = await api.post(
      "/debitoConta/bancos" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    this.setState({ lista_banco: retorno.data });
  };

  alteracaoSelectTipo = async (e) => {
    if (e.value === "venda" || e.value === "suprimento") {
      this.setState({ datavencimento: moment().format("YYYY-MM-DD") });
      this.setState({ creditoDebito: "credito" });
    } else {
      this.setState({ creditoDebito: "debito" });
    }
    if (e.value === "transferencia") {
      this.todasContas();
    }
    this.setState({ tipoSelect: e });
    this.setState({ tipo: e });
  };

  alteracaoSelectServico = (e) => {
    this.setState({ servicoSelect: e });
    this.setState({ servico: e });
    let aux = parseFloat(e.valor).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    $("#valor").val(aux);
    this.state.valor = aux;
    this.state.valortTotal = aux;
    // this.setState({valor: aux});
    // this.setState({valortTotal: aux});
  };

  alteracaoSelectPessoa = (e, v) => {
    this.setState({ pessoaSelect: v });
    this.setState({ pessoa: v });
  };

  handleSubmit = async (event) => {
    this.setState({ salvando: true });
    event.preventDefault();
    let retorno = "";
    let dados = {};
    let lista = [];
    let valor = this.state.valor;
    if (typeof this.state.valor === "string") {
      valor = ReplaceAll(".", "", this.state.valor);
      valor = ReplaceAll(",", ".", valor);
    }

    // if (this.state.tipo.value === "sangria" || this.state.tipo.value === "suprimento") {
    if (this.state.tipo.value === "venda") {
      let listaSalvarVarios = [];
      let dataVencimento = moment(this.state.datavencimento);
      this.state.listaParcela.forEach((element, index) => {
        let valor = ReplaceAll(".", "", element.valor);
        valor = ReplaceAll(",", ".", valor);

        listaSalvarVarios.push({
          descricao: this.state.descricao,
          tipo_lancamento: TipoLancamento.find(
            (e) => e.value === "cobrancaServicoAvulsa"
          ).value,
          credito_debito: this.state.creditoDebito,
          valor: valor,
          data_emissao: moment().format("YYYY-MM-DD"),
          data_vencimento: dataVencimento.format("YYYY-MM-DD"),
          pessoa_id: this.state.pessoa.value,
          servico_id: this.state.servico.value,
          vagas_servico: this.state.vagas_servico,
          numero_parcela: this.state.quantidade_parcelas,
          tela_usada: "lancamento_caixa",
        });
        dataVencimento = dataVencimento.add(1, "month");
      });

      retorno = await api.post(
        "/lancamentoConta/salvarVariasParcelas" + requisicoes.entidade,
        listaSalvarVarios,
        requisicoes.header
      );
    } else if (this.state.tipo.value === "transferencia") {
      let pessoaTransferidoDe = "";
      let pessoaTransferidoPara = "";
      api
        .post(
          "/usuario/usuarioLogado" + requisicoes.entidade,
          {},
          requisicoes.header
        )
        .then(async (user) => {
          pessoaTransferidoDe = user.data.nome;
          pessoaTransferidoPara = this.state.lista_conta_usuario_caixa.find(
            (e) => parseInt(e.id) === parseInt(this.state.conta_bancaria)
          ).nome;
          //pessoa_id é id da pessoa que está transferindo, conta_bancaria_id para qual conta bancária está sendo transferido
          retorno = await api.post(
            "/caixa/adicionaLancamento" + requisicoes.entidade,
            {
              descricao: `Transferência de ${pessoaTransferidoDe} para ${pessoaTransferidoPara}`,
              tipo_lancamento: TipoLancamento.find(
                (e) => e.value === this.state.tipo.value
              ).value,
              credito_debito: this.state.creditoDebito,
              tipo: TipoCaixa.find((e) => e.value === "caixa").value,
              pessoa_id: user.data.pessoa_id,
              conta_bancaria_id: this.state.conta_bancaria,
              valor: valor,
            },
            requisicoes.header
          );
          await api
            .post(
              "/caixa/adicionaLancamentoCaixaEspecifico" + requisicoes.entidade,
              {
                descricao: `Transferência de ${pessoaTransferidoDe} para ${pessoaTransferidoPara}`,
                tipo_lancamento: TipoLancamento.find(
                  (e) => e.value === this.state.tipo.value
                ).value,
                credito_debito: "credito",
                tipo: TipoCaixa.find((e) => e.value === "caixa").value,
                usuario_id: this.state.lista_conta_usuario_caixa.find(
                  (conta) =>
                    parseInt(conta.id) === parseInt(this.state.conta_bancaria)
                ).usuario_caixa_id,
                pessoa_id: user.data.pessoa_id,
                conta_bancaria_id: this.state.conta_bancaria,
                valor: valor,
              },
              requisicoes.header
            )
            .then(() => {
              window.location.href = "/caixa";
            });
        });
    } else {
      if (this.state.banco === null) {
        alert("Favor Selecionar o Banco");
      } else if (this.state.agencia === null) {
        alert("Favor Selecionar a Agência");
      } else if (this.state.conta_bancaria === null) {
        alert("Favor Selecionar a Conta");
      } else {
        dados = {
          descricao: this.state.descricao,
          tipo_lancamento: TipoLancamento.find(
            (e) => e.value === this.state.tipo.value
          ).value,
          credito_debito: this.state.creditoDebito,
          tipo: TipoCaixa.find((e) => e.value === "caixa").value,
          conta_bancaria_id: this.state.conta_bancaria,
          valor: valor,
        };
        retorno = await api.post(
          "/caixa/adicionaLancamento" + requisicoes.entidade,
          dados,
          requisicoes.header
        );
      }
    }
    // else {
    //   dados = {
    //     descricao: this.state.descricao,
    //     tipo_lancamento: TipoLancamento.find(
    //       (e) => e.value === this.state.tipo.value
    //     ).value,
    //     credito_debito: this.state.creditoDebito,
    //     tipo: TipoCaixa.find((e) => e.value === "caixa").value,
    //     conta_bancaria_id: this.state.conta_bancaria,
    //     valor: valor,
    //   };
    //   retorno = await api.post(
    //     "/caixa/adicionaLancamento" + requisicoes.entidade,
    //     dados,
    //     requisicoes.header
    //   );
    // }

    if (
      this.props.location.query !== undefined ||
      localStorage.getItem("tipo_usuario_clube") === "modalidade"
    ) {
      document.getElementById("botao_voltar").click();
    } else {
      if (retorno.data !== undefined && retorno.data !== null) {
        window.location.href = "/caixa";
      }
    }

    if (retorno.data !== undefined && this.state.tipo.value === "sangria") {
      const agencia = this.state.agencia;
      const banco = this.state.banco;
      lista = await api.post(
        "/caixa/gerarReciboSangria" + requisicoes.entidade,
        { dados: dados, agencia: agencia },
        requisicoes.header
      );

      this.buscarComprovanteSangria();
    }
    if (this.state.tipo.value === "suprimento") {
      const agencia = this.state.agencia;
      const banco = this.state.banco;
      lista = await api.post(
        "/caixa/gerarReciboSuprimento" + requisicoes.entidade,
        { dados: dados, agencia: agencia },
        requisicoes.header
      );

      this.buscarComprovanteSuprimento();
    }
    this.setState({ salvando: false });
  };

  buscarComprovanteSangria = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api
      .get("/contasAPagar/buscar?e=" + entidade + "&t=Baer " + token)
      .then((resp) => {
        var oReq = new XMLHttpRequest();

        var URLToPDF =
          resp.config.baseURL +
          "caixa/buscarReciboSangria?e=" +
          entidade +
          "&t=Baer " +
          token;

        oReq.open("GET", URLToPDF, true);

        oReq.responseType = "blob";

        oReq.onload = function () {
          const file = new Blob([oReq.response], { type: "application/pdf" });

          const fileURL = URL.createObjectURL(file);

          window.open(fileURL, "_blank");
        };
        oReq.send();
      })
      .catch((err) => {
        console.log("Deu erro: " + err);
      });
  };
  buscarComprovanteSuprimento = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api
      .get("/caixa/buscarReciboSuprimento?e=" + entidade + "&t=Baer " + token)
      .then((resp) => {
        var oReq = new XMLHttpRequest();

        var URLToPDF =
          resp.config.baseURL +
          "caixa/buscarReciboSuprimento?e=" +
          entidade +
          "&t=Baer " +
          token;

        oReq.open("GET", URLToPDF, true);

        oReq.responseType = "blob";

        oReq.onload = function () {
          const file = new Blob([oReq.response], { type: "application/pdf" });

          const fileURL = URL.createObjectURL(file);

          window.open(fileURL, "_blank");
        };
        oReq.send();
      })
      .catch((err) => {
        console.log("Deu erro: " + err);
      });
  };

  pesquisaServico = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/servico/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaServico").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista.push(objeto);
      });
      this.setState({ optionsServico: lista });
    }, 500);
  };

  pesquisaPessoa = async () => {
    if (buscando) {
      return;
    }
    let term = document.getElementById("pesquisaPessoa").value;
    if (term === null) {
      return;
    }
    this.setState({
      buscando: true,
    });
    buscando = true;

    setTimeout(async () => {
      term = document.getElementById("pesquisaPessoa").value;
      if (term === null) {
        buscando = false;
        this.setState({ buscando: false });
        return;
      }
      if (!isNaN(parseInt(term))) {
        await api
          .post(
            "/pessoa/pesquisaPorCodigoAcaoValorExato" + requisicoes.entidade,
            {
              pesquisa: term,
              ativo: this.state.ativoChecked,
              socio: this.state.socioChecked,
            },
            requisicoes.header
          )
          .then((retorno) => {
            this.setState({ options: ConverterParaLabel(retorno.data) });
          });
      } else {
        await api
          .post(
            "/pessoa/pesquisa" + requisicoes.entidade,
            {
              pesquisa: term,
              ativo: this.state.ativoChecked,
              socio: this.state.socioChecked,
            },
            requisicoes.header
          )
          .then((retorno) => {
            this.setState({
              options: ConverterParaLabelPessoa(retorno.data),
            });
          });
      }
      buscando = false;
      this.setState({ buscando: false });
    }, 2000);
  };

  calculaTotal = async () => {
    if (this.state.servico !== null) {
      const retorno = await api.post(
        "/servico/visualiza" +
          requisicoes.entidade +
          "&id=" +
          this.state.servico.value,
        {},
        requisicoes.header
      );
      const total =
        parseInt(this.state.vagas_servico) * parseFloat(retorno.data.valor);
      this.setState({ valor: total });
      this.setState({
        valor_calculado: parseFloat(total).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      });
    }
  };

  gerarParcelas = async (event) => {
    this.setState({ edicao: false });
    this.setState({ quantidade_parcelas: event.target.value });
    var stringvalor = this.state.valor;
    if (typeof stringvalor !== "string") {
      stringvalor = parseFloat(this.state.valor).toLocaleString("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    let string2 = stringvalor
      .split(``)
      .filter((n) => Number(n) || n == false)
      .join(``);
    let tamanho = parseInt(event.target.value);

    let listaParcelas = [];
    let dataVencimento = moment(this.state.datavencimento);

    let valorParcela = string2 >= 100 ? string2 / 100 : string2;
    var Parcela = parseFloat(valorParcela / tamanho).toFixed(2);
    var valorPrimeiraParcela = valorParcela - Parcela * (tamanho - 1);
    this.setState({ ultimaparcela: valorPrimeiraParcela });
    for (var i = 0; i < tamanho; i++) {
      listaParcelas.push({
        numero: i + 1,
        valor: parseFloat(valorParcela / parseInt(tamanho)).toLocaleString(
          "pt-br",
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        ),
        quantidade: parseInt(this.state.vagas_servico),
        data_vencimento: dataVencimento.format("YYYY-MM-DD"),
        pessoa_id: this.state.pessoa.value,
        servico_id: this.state.servico.value,
        ultimaparcela: valorPrimeiraParcela.toLocaleString("pt-br", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      });
      dataVencimento = dataVencimento.add(1, "month");
    }

    this.setState({ listaParcela: listaParcelas });
  };

  alteracaoValorParcela = async (event) => {
    this.setState({ edicao: true });

    this.setState({ salvando: true });
  };
  alteracaoValorParcelaOk = async (event) => {
    var listaParcela = this.state.listaParcela;
    var valorparcela = 0;
    var valorauxiliar = 0;
    listaParcela.forEach((element, index) => {
      valorauxiliar = ReplaceAll(".", "", element.valor);
      valorauxiliar = ReplaceAll(",", ".", valorauxiliar);
      valorparcela = parseFloat(valorauxiliar) + parseFloat(valorparcela);
    });

    this.setState({ edicao: false });
    this.setState({ salvando: false });
    var valortotal = this.state.valor.toString();
    valorparcela = valorparcela.toString();
    valortotal = ReplaceAll(".", "", valortotal);
    valortotal = ReplaceAll(",", ".", valortotal);

    if (valortotal === valorparcela) {
      console.log("entrou valor igual");
    }
  };

  alteraValorParcela = async (event) => {
    this.setState({ valorParcela: event.target.value });
    const indice = parseInt(event.target.id.toString().substring(12, 25));
    const id = event.target.id.toString().substring(0, 12);

    let value = event.target.value;

    let valorparcela = 0;
    let lista = this.state.listaParcela;
    if (event.target.nome === "valor") {
      value = ReplaceAll(".", "", value);
      value = ReplaceAll(",", ".", value);
    }

    lista.splice(indice, 1, {
      descricao: this.state.descricao,
      tipo_lancamento: "",
      credito_debito: this.state.creditoDebito,
      valor: id === "valorParcela" ? value : lista[parseInt(indice)].valor,
      data_emissao: moment().format("YYYY-MM-DD"),
      data_vencimento:
        id === "dataVencimen" ? value : lista[parseInt(indice)].data_vencimento,
      pessoa_id: this.state.pessoa.value,
      servico_id: this.state.servico.value,
      ultimaparcela:
        id === "valorParcela" ? value : lista[parseInt(indice)].valor,
      vagas_servico: this.state.vagas_servico,
      numero_parcela: this.state.quantidade_parcelas,
      numero: parseInt(indice) + 1,
    });

    this.setState({ listaParcela: lista });
  };

  onUpdateColumn = (i, value, name) => {
    let totalAntesIndice = 0;
    let total = ReplaceAll(".", "", this.state.valor);
    total = ReplaceAll(",", ".", total);
    let lista = this.state.listaParcela;
    for (var ind = 0; ind < lista.length; ind++) {
      if (ind === i) {
        if (name === "valor") {
          lista[ind].valor = value;
        }
        if (name === "data_vencimento") {
          lista[ind].data_vencimento = value;
        }
      }
      if (name === "valor") {
        let valor = ReplaceAll(".", "", lista[ind].valor);
        valor = ReplaceAll(",", ".", valor);
        if (ind <= i) {
          totalAntesIndice += parseFloat(valor);
        } else {
          const calc = parseFloat(total) - totalAntesIndice;
          const indice = lista.filter((e, index) => index > i).length;
          lista[ind].valor = parseFloat(calc / indice).toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          );
        }
      }
      if (ind + 1 === lista.length) {
        console.log(lista);
        this.setState({ listaParcela: lista });
      }
    }
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Lançamento Caixa
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={6}>
              <Form.Label>Tipo</Form.Label>
              <Select
                name="tipo"
                value={this.state.tipo}
                options={this.state.tipoList}
                onChange={this.alteracaoSelectTipo}
                isDisabled={
                  this.props.location.query !== undefined ||
                  this.state.bloqueiaTipo
                }
              />
            </Col>
            <Col
              sm={12}
              hidden={
                this.state.tipo.value === "venda" ||
                this.state.tipo.value === "transferencia"
              }
            >
              <Form.Label>Descrição</Form.Label>
              <FormControl
                placeholder="Descrição"
                name="descricao"
                onChange={this.alteracao}
                defaultValue={this.state.descricao}
              />
            </Col>

            <Col sm={6} hidden={this.state.tipo.value !== "venda"}>
              <Form.Label>Serviço</Form.Label>
              <Select
                placeholder="Digite para buscar"
                name="servico"
                value={this.state.servico}
                onChange={this.alteracaoSelectServico}
                options={this.state.optionsServico}
                onKeyDown={this.pesquisaServico}
                inputId="pesquisaServico"
              />
            </Col>

            <Col sm={6} hidden={this.state.tipo.value !== "venda"}>
              <Form.Label>Data Vencimento</Form.Label>
              <FormControl
                placeholder="Data Vencimento"
                aria-label="dataVencimento"
                aria-describedby="basic-addon1"
                type="date"
                name="datavencimento"
                onChange={this.alteracao}
                value={this.state.datavencimento}
              />
            </Col>

            <Col sm={6} hidden={this.state.tipo.value !== "venda"}>
              <Form.Label>Pessoa</Form.Label>
              <Row>
                <Col sm={8}>
                  <Autocomplete
                    id="pesquisaPessoa"
                    value={this.state.pessoa}
                    options={this.state.options}
                    inputValue={this.state.inputValue}
                    getOptionLabel={(option) => option.label || ""}
                    onKeyUp={this.pesquisaPessoa}
                    onChange={this.alteracaoSelectPessoa}
                    onInputChange={(event, newInputValue) => {
                      this.setState({ inputValue: newInputValue });
                    }}
                    loading={this.state.buscando}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Busca"
                        size="small"
                        style={{ background: "white" }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.buscando ? (
                                <CircularProgress
                                  color="inherit"
                                  size={20}
                                  style={{
                                    marginRight: "5%",
                                  }}
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Col>
                <Col sm={4}>
                  <Row>
                    <Col>
                      <Checkbox
                        label=" Sócio"
                        name="socioChecked"
                        checked={this.state.socioChecked}
                        onChange={(e) =>
                          this.setState({ socioChecked: e.target.checked })
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Checkbox
                        label=" Ativo"
                        name="ativoChecked"
                        checked={this.state.ativoChecked}
                        onChange={(e) =>
                          this.setState({ ativoChecked: e.target.checked })
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col sm={6} hidden={this.state.tipo.value === "venda"}>
              <Form.Label>Débito</Form.Label>
              <Form.Control
                as="select"
                name="creditoDebito"
                onChange={this.alteracao}
                required
                value={this.state.creditoDebito}
                disabled
              >
                <option></option>
                <option value="debito">Débito</option>
                <option value="credito">Crédito</option>
              </Form.Control>
            </Col>

            <Col sm={6} hidden={this.state.tipo.value !== "venda"}>
              <Form.Label>Quantidade</Form.Label>
              <FormControl
                placeholder="Quantidade"
                name="vagas_servico"
                value={this.state.vagas_servico}
                onChange={this.alteracao}
                onKeyDown={this.calculaTotal}
                type="number"
                min={0}
              />
            </Col>

            <Col
              sm={6}
              hidden={
                this.state.tipo.value !== "sangria" &&
                this.state.tipo.value !== "suprimento"
              }
            >
              <Form.Label>Banco: </Form.Label>
              <Form.Control
                aria-describedby="basic-addon1"
                as="select"
                onChange={this.alteracaoBanco}
                name="banco"
              >
                <option value=""></option>
                {this.state.lista_banco.map((e) => {
                  return <option value={e.id}>{e.cod + " - " + e.nome}</option>;
                })}
              </Form.Control>
            </Col>

            <Col
              sm={6}
              hidden={
                this.state.tipo.value !== "sangria" &&
                this.state.tipo.value !== "suprimento"
              }
            >
              <Form.Label>Agencia: </Form.Label>
              <Form.Control
                aria-describedby="basic-addon1"
                as="select"
                onChange={this.alteracaoAgencia}
                name="agencia"
              >
                <option value=""></option>
                {this.state.lista_agencia.map((e) => {
                  return <option value={e.id}>{e.cod + " - " + e.nome}</option>;
                })}
              </Form.Control>
            </Col>
            <Col sm={6} hidden={this.state.tipo.value !== "transferencia"}>
              <Form.Label>Conta: </Form.Label>
              <Form.Control
                aria-describedby="basic-addon1"
                as="select"
                onChange={this.alteracao}
                name="conta_bancaria"
              >
                <option value=""></option>
                {this.state.lista_conta_usuario_caixa.map((e) => {
                  return (
                    <option value={e.id}>
                      {"Caixa: " +
                        e.nome +
                        (e.conta !== null ? " C.C. " + e.conta : "") +
                        (e.banco !== null ? " Banco: " + e.banco : "")}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>

            <Col
              sm={6}
              hidden={
                this.state.tipo.value !== "sangria" &&
                this.state.tipo.value !== "suprimento"
              }
            >
              <Form.Label>Conta: </Form.Label>
              <Form.Control
                aria-describedby="basic-addon1"
                as="select"
                onChange={this.alteracao}
                name="conta_bancaria"
              >
                <option value=""></option>
                {this.state.lista_conta.map((e) => {
                  return (
                    <option value={e.id}>{e.conta + " - " + e.nome}</option>
                  );
                })}
              </Form.Control>
            </Col>

            <Col sm={3} hidden={this.state.tipo.value === null}>
              <Form.Label>Valor</Form.Label>
              <FormControl
                placeholder="Valor"
                aria-describedby="basic-addon1"
                name="valor"
                id="valor"
                className="dinheiro"
                required
                onKeyUp={this.componentMoney}
                onBlur={(e) => {
                  this.alteracao(e);
                }}
              />
            </Col>
            <Col
              sm={3}
              hidden={
                this.state.tipo.value === null ||
                this.state.tipo.value !== "venda"
              }
            >
              <Form.Label>Valor Total</Form.Label>
              <FormControl
                placeholder="Valor Total"
                aria-describedby="basic-addon1"
                name="valor"
                id="valor"
                className="dinheiro"
                value={this.state.valortTotal}
                required
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6} hidden={this.state.tipo.value !== "venda"}>
              <Form.Label>Quantidade Parcelas</Form.Label>
              <Form.Control
                as="select"
                name="quantidade_parcelas"
                onChange={this.gerarParcelas}
                disabled={
                  this.state.valor === "" ||
                  this.state.datavencimento === null ||
                  this.state.pessoa === null ||
                  this.state.servico === null
                }
                required
              >
                <option></option>
                <option key="1" value="1">
                  1
                </option>
                <option key="2" value="2">
                  2
                </option>
                <option key="3" value="3">
                  3
                </option>
                <option key="4" value="4">
                  4
                </option>
                <option key="5" value="5">
                  5
                </option>
                <option key="6" value="6">
                  6
                </option>
                <option key="7" value="7">
                  7
                </option>
                <option key="8" value="8">
                  8
                </option>
                <option key="9" value="9">
                  9
                </option>
                <option key="10" value="10">
                  10
                </option>
                <option key="11" value="11">
                  11
                </option>
                <option key="12" value="12">
                  12
                </option>
              </Form.Control>
            </Col>
          </Row>

          {this.state.listaParcela.map((element, index) => {
            return (
              <Col key={index} sm={6}>
                <Row>
                  <Col sm={4}>
                    <Form.Label>Valor</Form.Label>
                    <CurrencyInput
                      decimalSeparator=","
                      thousandSeparator="."
                      className="form-control"
                      value={element.valor}
                      onChange={(value) =>
                        this.onUpdateColumn(index, value, "valor")
                      }
                    />
                  </Col>

                  <Col sm={6}>
                    <Form.Label>Vencimento</Form.Label>
                    <FormControl
                      type="date"
                      value={element.data_vencimento}
                      id={"dataVencimen" + index}
                      onChange={(event) =>
                        this.onUpdateColumn(
                          index,
                          event.target.value,
                          "data_vencimento"
                        )
                      }
                    ></FormControl>
                  </Col>
                </Row>
              </Col>
            );
          })}

          <Divider />
          <Segment clearing>
            <Header floated="right">
              <Link
                to={{
                  pathname:
                    (this.props.location.query !== undefined &&
                      this.props.location.from === undefined) ||
                    localStorage.getItem("tipo_usuario_clube") === "modalidade"
                      ? "/meusDados"
                      : "/caixa",
                  query: {
                    pessoa:
                      this.props.location.query !== undefined &&
                      this.props.location.query.pessoa,
                  },
                }}
                id="botao_voltar"
              >
                <Button type="button">Voltar</Button>
              </Link>
              <Button positive type="submit" disabled={this.state.salvando}>
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}
