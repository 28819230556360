import React, { Component } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import { Header, Button, Segment, Divider, Grid } from "semantic-ui-react";
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";
import moment from "moment";

export default class avaliacao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      idade: null,
      status: null,
      data_lancamento: null,
      data_validade: null,
      // dados antropometricos
      peso: null,
      altura: null,
      imc: null,
      // dobras
      triceps: null,
      biceps: null,
      supra_iliaca: null,
      subescapular: null,
      abdominal: null,
      peitoral: null,
      axilar_media: null,
      coxa: null,
      panturrilha: null,
      porcentagem_gordura: null,
      // circunferencias
      ombro: null,
      torax: null,
      cintura: null,
      quadril: null,
      circunferencia_abdominal: null,
      // perimetros
      braco_dir: null,
      braco_esq: null,
      braco_dir_contraido: null,
      braco_esq_contraido: null,
      antebraco_dir: null,
      antebraco_esq: null,
      coxa_medial_dir: null,
      coxa_medial_esq: null,
      panturrilha_dir: null,
      panturrilha_esq: null,
      // definições
      biotipo: null,
      somatotipo: null,
      // pessoas
      aluno: null,
      aluno_id: null,
      avaliador: null,
      avaliador_id: null,
      pessoas: [],
      resultado: false,
    };
    if (this.props.id !== null) {
      this.visualiza();
    }
  }

  visualiza = async () => {
    const resposta = await api.post(
      "/avaliacaoFisica/visualiza" + requisicoes.entidade,
      { id: this.props.id },
      requisicoes.header
    );
    let aluno = {
      value: resposta.data.aluno.id,
      label: resposta.data.aluno.nome,
    };
    let avaliador = {
      value: resposta.data.avaliador.id,
      label: resposta.data.avaliador.nome,
    };

    this.setState(resposta.data);
    this.setState({ aluno: aluno, avaliador: avaliador });
    // this.calculaIdade(resposta.data.aluno.dataNascimento);
  };

  calculaIdade = (dataNascimento) => {
    var data = new Date(),
      ano_atual = data.getFullYear(),
      mes_atual = data.getMonth() + 1,
      dia_atual = data.getDate(),
      ano_aniversario = +moment(dataNascimento).year(),
      mes_aniversario = +moment(dataNascimento).month(),
      dia_aniversario = +moment(dataNascimento).day(),
      quantos_anos = ano_atual - ano_aniversario;

    if (
      mes_atual < mes_aniversario ||
      (mes_atual === mes_aniversario && dia_atual < dia_aniversario)
    ) {
      quantos_anos--;
    }

    const idade = quantos_anos < 0 ? 0 : quantos_anos;
    this.setState({ idade: idade });
  };

  alteracao = (event) => {
    const replace = event.target.value.replace(",", ".");
    this.setState({ [event.target.name]: replace });
  };

  alteracaoSelectAluno = async (event) => {
    this.setState({ aluno_id: event.value });
    this.setState({ aluno: event });
    this.calculaIdade(event.datanascimento);
  };

  alteracaoSelectAvaliador = async (event) => {
    this.setState({ avaliador_id: event.value });
    this.setState({ avaliador: event });
  };

  pesquisaPessoa = (newValue) => {
    api
      .post(
        "/pessoa/pesquisa" + requisicoes.entidade,
        {
          pesquisa: newValue,
          pessoaInativa: false,
        },
        requisicoes.header
      )
      .then((resposta) => {
        const lista = [];
        resposta.data.forEach((e) => {
          let objeto = {
            value: e.id,
            label: e.nome,
            datanascimento: e.datanascimento,
          };
          lista.push(objeto);
        });

        this.setState({ pessoas: lista });
      });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const dados = {
      id: this.state.id,
      idade: this.state.idade,
      status: this.state.status,
      data_lancamento: this.state.data_lancamento,
      data_validade: this.state.data_validade,
      // dados antropometricos
      peso: this.state.peso,
      altura: this.state.altura,
      imc: this.state.imc,
      // dobras
      triceps: this.state.triceps,
      biceps: this.state.biceps,
      supra_iliaca: this.state.supra_iliaca,
      subescapular: this.state.subescapular,
      abdominal: this.state.abdominal,
      peitoral: this.state.peitoral,
      axilar_media: this.state.axilar_media,
      coxa: this.state.coxa,
      panturrilha: this.state.panturrilha,
      porcentagem_gordura: this.state.porcentagem_gordura,
      // circunferencias
      ombro: this.state.ombro,
      torax: this.state.torax,
      cintura: this.state.cintura,
      quadril: this.state.quadril,
      circunferencia_abdominal: this.state.circunferencia_abdominal,
      // perimetros
      braco_dir: this.state.braco_dir,
      braco_esq: this.state.braco_esq,
      braco_dir_contraido: this.state.braco_dir_contraido,
      braco_esq_contraido: this.state.braco_esq_contraido,
      antebraco_dir: this.state.antebraco_dir,
      antebraco_esq: this.state.antebraco_esq,
      coxa_medial_dir: this.state.coxa_medial_dir,
      coxa_medial_esq: this.state.coxa_medial_esq,
      panturrilha_dir: this.state.panturrilha_dir,
      panturrilha_esq: this.state.panturrilha_esq,
      // definições
      biotipo: this.state.biotipo,
      somatotipo: this.state.somatotipo,
      // pessoas
      aluno_id: this.state.aluno_id,
      avaliador_id: this.state.avaliador_id,
    };

    if (this.state.id !== "") {
      api
        .post(
          "/avaliacaoFisica/alterar" + requisicoes.entidade,
          dados,
          requisicoes.header
        )
        .then(() => {
          window.location.href = "/academia";
          // this.setState({ resultado: true });
        });
    } else {
      api
        .post(
          "/avaliacaoFisica/salvar" + requisicoes.entidade,
          dados,
          requisicoes.header
        )
        .then(() => {
          window.location.href = "/academia";
          // this.setState({ resultado: true });
        });
    }
  };

  excluir = async () => {
    if (this.state.id !== "") {
      api
        .post(
          "/avaliacaoFisica/excluir" + requisicoes.entidade,
          { id: this.state.id },
          requisicoes.header
        )
        .then(() => {
          window.location.href = "/academia";
        });
    }
  };

  render() {
    return (
      <div>
        <Header as="h1" dividing>
          Avaliação Física
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={12}>
              <Form.Label>Avaliador</Form.Label>
              <Select
                placeholder="Digite para buscar"
                inputId="pessoa"
                as="select"
                name="avaliador"
                options={this.state.pessoas}
                onInputChange={this.pesquisaPessoa}
                onChange={this.alteracaoSelectAvaliador}
                value={this.state.avaliador}
                required
              />
            </Col>
          </Row>
          <Divider />

          <Row style={{ paddingBottom: 20 }}>
            <Col sm={4}>
              <Form.Label>Aluno</Form.Label>
              <Select
                placeholder="Digite para buscar"
                inputId="pessoa"
                as="select"
                name="aluno"
                options={this.state.pessoas}
                onInputChange={this.pesquisaPessoa}
                onChange={this.alteracaoSelectAluno}
                value={this.state.aluno}
                required
              />
            </Col>
            <Col sm={4}>
              <Form.Label>Idade</Form.Label>
              <FormControl
                aria-describedby="basic-addon1"
                name="idade"
                onChange={this.alteracao}
                value={this.state.idade}
                required
              />
            </Col>
            <Col sm={4}>
              <Form.Label>Data Lançamento</Form.Label>
              <FormControl
                type="date"
                name="data_lancamento"
                onChange={this.alteracao}
                value={this.state.data_lancamento}
              />
            </Col>
            <Col sm={4}>
              <Form.Label>Peso Atual (kg)</Form.Label>
              <FormControl
                aria-describedby="basic-addon1"
                name="peso"
                onChange={this.alteracao}
                value={this.state.peso}
                required
              />
            </Col>
            <Col sm={4}>
              <Form.Label>Altura (m)</Form.Label>
              <FormControl
                aria-describedby="basic-addon1"
                name="altura"
                onChange={this.alteracao}
                value={this.state.altura}
                required
              />
            </Col>
          </Row>

          <Grid columns={3}>
            <Grid.Column>
              <Segment fluid raised>
                <Header as="h3" dividing>
                  Dobras (mm)
                </Header>
                <Grid columns={2}>
                  <Grid.Column>
                    <Form.Label>Tríceps (PCT)</Form.Label>
                    <FormControl
                      aria-describedby="basic-addon1"
                      name="triceps"
                      onChange={this.alteracao}
                      value={this.state.triceps}
                      required
                    />
                    <Form.Label>Supra Ilíaca</Form.Label>
                    <FormControl
                      aria-describedby="basic-addon1"
                      name="supra_iliaca"
                      onChange={this.alteracao}
                      value={this.state.supra_iliaca}
                      required
                    />
                    <Form.Label>Abdominal</Form.Label>
                    <FormControl
                      aria-describedby="basic-addon1"
                      name="abdominal"
                      onChange={this.alteracao}
                      value={this.state.abdominal}
                      required
                    />
                    <Form.Label>Axilar Média</Form.Label>
                    <FormControl
                      aria-describedby="basic-addon1"
                      name="axilar_media"
                      onChange={this.alteracao}
                      value={this.state.axilar_media}
                      required
                    />
                    <Form.Label>Panturrilha</Form.Label>
                    <FormControl
                      aria-describedby="basic-addon1"
                      name="panturrilha"
                      onChange={this.alteracao}
                      value={this.state.panturrilha}
                      required
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Label>Bíceps</Form.Label>
                    <FormControl
                      aria-describedby="basic-addon1"
                      name="biceps"
                      onChange={this.alteracao}
                      value={this.state.biceps}
                      required
                    />
                    <Form.Label>Subescapular</Form.Label>
                    <FormControl
                      aria-describedby="basic-addon1"
                      name="subescapular"
                      onChange={this.alteracao}
                      value={this.state.subescapular}
                      required
                    />
                    <Form.Label>Peitoral</Form.Label>
                    <FormControl
                      aria-describedby="basic-addon1"
                      name="peitoral"
                      onChange={this.alteracao}
                      value={this.state.peitoral}
                      required
                    />
                    <Form.Label>Coxa</Form.Label>
                    <FormControl
                      aria-describedby="basic-addon1"
                      name="coxa"
                      onChange={this.alteracao}
                      value={this.state.coxa}
                      required
                    />
                  </Grid.Column>
                </Grid>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment fluid raised>
                <Header as="h3" dividing>
                  Circunferências (cm)
                </Header>
                <Form.Label>Ombro</Form.Label>
                <FormControl
                  aria-describedby="basic-addon1"
                  name="ombro"
                  onChange={this.alteracao}
                  value={this.state.ombro}
                  required
                />
                <Form.Label>Tórax</Form.Label>
                <FormControl
                  aria-describedby="basic-addon1"
                  name="torax"
                  onChange={this.alteracao}
                  value={this.state.torax}
                  required
                />
                <Form.Label>Cintura</Form.Label>
                <FormControl
                  aria-describedby="basic-addon1"
                  name="cintura"
                  onChange={this.alteracao}
                  value={this.state.cintura}
                  required
                />
                <Form.Label>Quadril</Form.Label>
                <FormControl
                  aria-describedby="basic-addon1"
                  name="quadril"
                  onChange={this.alteracao}
                  value={this.state.quadril}
                  required
                />
                <Form.Label>Circunfrência Abdominal</Form.Label>
                <FormControl
                  aria-describedby="basic-addon1"
                  name="circunferencia_abdominal"
                  onChange={this.alteracao}
                  value={this.state.circunferencia_abdominal}
                  required
                />
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment fluid raised>
                <Header as="h3" dividing>
                  Perímetros (cm)
                </Header>
                <Grid columns={2}>
                  <Grid.Column>
                    <Form.Label>Braço direito</Form.Label>
                    <FormControl
                      aria-describedby="basic-addon1"
                      name="braco_dir"
                      onChange={this.alteracao}
                      value={this.state.braco_dir}
                      required
                    />
                    <Form.Label>Braço esquerdo</Form.Label>
                    <FormControl
                      aria-describedby="basic-addon1"
                      name="braco_esq"
                      onChange={this.alteracao}
                      value={this.state.braco_esq}
                      required
                    />
                    <Form.Label>Antebraço direito</Form.Label>
                    <FormControl
                      aria-describedby="basic-addon1"
                      name="antebraco_dir"
                      onChange={this.alteracao}
                      value={this.state.antebraco_dir}
                      required
                    />
                    <Form.Label>Coxa medial direita</Form.Label>
                    <FormControl
                      aria-describedby="basic-addon1"
                      name="coxa_medial_dir"
                      onChange={this.alteracao}
                      value={this.state.coxa_medial_dir}
                      required
                    />
                    <Form.Label>Panturrilha direita</Form.Label>
                    <FormControl
                      aria-describedby="basic-addon1"
                      name="panturrilha_dir"
                      onChange={this.alteracao}
                      value={this.state.panturrilha_dir}
                      required
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Label>Braço direito contraído</Form.Label>
                    <FormControl
                      aria-describedby="basic-addon1"
                      name="braco_dir_contraido"
                      onChange={this.alteracao}
                      value={this.state.braco_dir_contraido}
                      required
                    />
                    <Form.Label>Braço esquerdo contraído</Form.Label>
                    <FormControl
                      aria-describedby="basic-addon1"
                      name="braco_esq_contraido"
                      onChange={this.alteracao}
                      value={this.state.braco_esq_contraido}
                      required
                    />
                    <Form.Label>Antebraço esquerdo</Form.Label>
                    <FormControl
                      aria-describedby="basic-addon1"
                      name="antebraco_esq"
                      onChange={this.alteracao}
                      value={this.state.antebraco_esq}
                      required
                    />
                    <Form.Label>Coxa medial esquerda</Form.Label>
                    <FormControl
                      aria-describedby="basic-addon1"
                      name="coxa_medial_esq"
                      onChange={this.alteracao}
                      value={this.state.coxa_medial_esq}
                      required
                    />
                    <Form.Label>Panturrilha esquerda</Form.Label>
                    <FormControl
                      aria-describedby="basic-addon1"
                      name="panturrilha_esq"
                      onChange={this.alteracao}
                      value={this.state.panturrilha_esq}
                      required
                    />
                  </Grid.Column>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid>

          <Segment clearing>
            <Header floated="right">
              <Link to="/academia">
                <Button type="button">Voltar</Button>
              </Link>
              {this.state.id !== null && (
                <Button negative type="button" onClick={this.excluir}>
                  Deletar
                </Button>
              )}
              <Button positive type="submit">
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}
