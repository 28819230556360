import React, { Component } from "react";
import {
  Container,
  Header,
  Table,
  Button,
  Checkbox,
  Input,
  FormField,
} from "semantic-ui-react";
import { Row, Col, Form } from "react-bootstrap";
import requisicoes from "../../../../utils/Requisicoes";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Pagination } from "semantic-ui-react";

import moment from "moment-timezone";

import api from "../../../../services/api";

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data_inicio: null,
      data_final: null,
      lista_lancamentos: [],
      listaQuantidadeTitulos: [],
      listaPessoasTipoTituloSlice: [],
      totalPages: 0,
      dadosPorPagina: 20,
      paginaAtual: 0,
      tipo: "todos",
      botaoImprimirPessoas: false,
      dataBusca: moment().format("YYYY-MM-DD"),
      checkedReceitas: false,
      etiqueta_avulsa: false,
      etiquetas_avulsas: [], // lista de inteiros
      parcelas_atrasadas: null,
      totalGeral: null,
      totalTitulares: null,
      totalDependetes: null
    };
    if (this.state.listaQuantidadeTitulos.length === 0) {
      this.buscaQuantidadeTipoTitulos();
    }
  }

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  alteracaoEtiquetasAvulsas = async (event) => {
    try {
      let list = [];
      event.target.value.split(",").map(i => {
        if (i !== "" && i !== NaN) list.push(parseInt(i));
      });
      this.setState({ etiquetas_avulsas: list });
    } catch (error) {
      console.log("alteracaoEtiquetasAvulsas.catch: " + error);
    }
  }

  buscaQuantidadeTipoTitulos = async (data) => {
    var r = await api.post(
      "/pessoa/countPessoaPorTipoTitulo" + requisicoes.entidade,
      {
        data: data,
      },
      requisicoes.header
    );
    let totalTitulares = null
    let totalDependetes = null
    let totalGeral = null
    for (let i = 0; i < r.data.length; i++) {
      const element = r.data[i];
      element.checked = true;
      element.totalDependetes = (parseInt(element.count ? element.count : 0) + parseInt(element.dependentes ? element.dependentes : 0))
      totalTitulares += (parseInt(element.count) + parseInt(element.count)) / 2
      totalDependetes += ((parseInt(element.dependentes ? element.dependentes : 0) + parseInt(element.dependentes ? element.dependentes : 0)) / 2)
    }
    console.log("totalDependetes 1 :", totalDependetes)
    totalGeral = totalTitulares + totalDependetes
    // r.data.push({ totalGeral: totalGeral })
    // console.log("totalGeral 2:", r.data)

    this.setState({
      listaQuantidadeTitulos: r.data,
      totalTitulares: totalTitulares,
      totalDependetes: totalDependetes,
      totalGeral: totalGeral
    });
  };

  pageChange = (event, data) => {
    if (data === undefined || data === null) {
      data = {};
      data.activePage = 1;
    }
    this.setState({ paginaAtual: data.activePage });
    this.buscaPessoasAcaoTipoTitulo();
  };

  verificaDatas = () => {
    if (!moment(this.state.data_inicio, "YYYY-MM-DD", true).isValid()) {
      this.setState({
        data_inicio: moment().startOf("month").format("YYYY-MM-DD"),
      });
    }
    if (!moment(this.state.data_final, "YYYY-MM-DD", true).isValid()) {
      this.setState({ data_final: moment().format("YYYY-MM-DD") });
    }
  };

  botaoBuscaPessoasOnClick = () => {
    this.setState({ paginaAtual: 1 });
    this.buscaPessoasAcaoTipoTitulo();
  };

  buscaPessoasAcaoTipoTitulo = async (event) => {
    await this.verificaDatas();
    if (this.state.paginaAtual === 0) {
      this.setState({ paginaAtual: 1 });
    }
    var r = await api.post(
      "/pessoa/buscaPessoaPorTipoTituloPaginate" + requisicoes.entidade,
      {
        data_inicio: this.state.data_inicio,
        data_final: this.state.data_final,
        tipo: this.state.etiqueta_avulsa ? "todos" : this.state.tipo,
        pagina: this.state.paginaAtual,
        etiquetas_avulsas: this.state.etiqueta_avulsa ? this.state.etiquetas_avulsas : null,
        parcelas_atrasadas: this.state.parcelas_atrasadas != null && !this.state.etiqueta_avulsa ? this.state.parcelas_atrasadas : null,
      },
      requisicoes.header
    );
    this.setState({ listaPessoasTipoTituloSlice: r.data.pessoas });
    this.setState({ totalPages: r.data.totalPaginas });
  };

  gerarRelatorioPesquisa = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado?e=" + entidade,
      {},
      {
        headers: { token: "Baer " + token },
      }
    );
    const nomePessoa = await api.post(
      "/pessoa/pessoaIdNome?e=" + entidade,
      { id: usuarioLogado.data.pessoa_id },
      {
        headers: { token: "Baer " + token },
      }
    );

    this.setState({ botaoImprimirPessoas: true });

    await api.post(
      "/pessoa/relatorioPessoasTipoTitulo?e=" + entidade,
      {
        userId: usuarioLogado.data.pessoa_id,
        nome_operador: nomePessoa.data.nome,
        tipo: this.state.tipo,
        listaQuantidadeTitulos: this.state.listaQuantidadeTitulos,
        data_inicio: this.state.data_inicio,
        data_final: this.state.data_final,
        listaPessoasTipoTitulo: this.state.listaPessoasTipoTituloSlice,
      },
      {
        headers: { token: "Baer " + token },
      }
    );
    this.setState({ botaoImprimirPessoas: false });

    const r = await api.get(
      "/pessoa/arquivoRelatorioPessoasTipoTitulo?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.pessoa_id
    ); //
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "pessoa/arquivoRelatorioPessoasTipoTitulo?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.pessoa_id; //

    await oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = await function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  imprimirQuantidadeTipoTitulo = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado?e=" + entidade,
      {},
      {
        headers: { token: "Baer " + token },
      }
    );
    const nomePessoa = await api.post(
      "/pessoa/pessoaIdNome?e=" + entidade,
      { id: usuarioLogado.data.pessoa_id },
      {
        headers: { token: "Baer " + token },
      }
    );

    let listaAux = [];
    for (let i = 0; i < this.state.listaQuantidadeTitulos.length; i++) {
      const element = this.state.listaQuantidadeTitulos[i];
      if (element.checked) {
        listaAux.push(element);
      }
    }
    console.log("Lista tu=itulo", listaAux)
    await api.post(
      "/pessoa/relatorioQuantidadePessoasTipoTitulo?e=" + entidade,
      {
        userId: usuarioLogado.data.pessoa_id,
        nome_operador: nomePessoa.data.nome,
        listaQuantidadeTitulos: listaAux,
        data: this.state.checkedReceitas ? this.state.dataBusca : undefined,
        receita: this.state.checkedReceitas,
      },
      {
        headers: { token: "Baer " + token },
      }
    );

    setTimeout(async () => {
      const r = await api.get(
        "/pessoa/arquivoRelatorioQuantidadePessoasTipoTitulo?e=" +
        entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.pessoa_id
      ); //
      var oReq = await new XMLHttpRequest();

      var URLToPDF =
        r.config.baseURL +
        "pessoa/arquivoRelatorioQuantidadePessoasTipoTitulo?e=" +
        entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.pessoa_id; //

      await oReq.open("GET", URLToPDF, true);
      oReq.responseType = "blob";
      oReq.onload = await async function () {
        const file = await new Blob([oReq.response], {
          type: "application/pdf",
        });
        const fileURL = await URL.createObjectURL(file);
        await window.open(fileURL, "_blank");
      };
      oReq.send();
    }, 500);
  };

  gerarCSVPesquisa = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado?e=" + entidade,
      {},
      {
        headers: { token: "Baer " + token },
      }
    );
    await api.post(
      "/pessoa/csvPessoasTipoTitulo?e=" + entidade,
      {
        userId: usuarioLogado.data.pessoa_id,
        tipo: this.state.tipo,
        listaQuantidadeTitulos: this.state.listaQuantidadeTitulos,
        data_inicio: this.state.data_inicio,
        data_final: this.state.data_final,
        listaPessoasTipoTitulo: this.state.listaPessoasTipoTituloSlice,
      },
      {
        headers: { token: "Baer " + token },
      }
    );

    const r = await api.get(
      "/pessoa/arquivoCsvPessoasTipoTitulo?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.pessoa_id
    ); //
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "pessoa/arquivoCsvPessoasTipoTitulo?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.pessoa_id; //

    await oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = await async function () {
      const file = new Blob([oReq.response], { type: "text/csv" });
      var fileURL = URL.createObjectURL(file);
      //await window.open(fileURL, "_blank");

      // create <a> tag dinamically
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      // it forces the name of the downloaded file
      fileLink.download = "pesquisa_titulares.csv";
      // triggers the click event
      fileLink.click();
    };
    oReq.send();
  };

  gerarEtiquetas = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    await api.post(
      "/etiqueta/gerarEtiqueta?e=" + entidade,
      {
        data_inicio: this.state.data_inicio,
        data_final: this.state.data_final,
        pagina: this.state.paginaAtual,
        tipo: this.state.etiqueta_avulsa ? "todos" : this.state.tipo,
        etiquetas_avulsas: this.state.etiqueta_avulsa ? this.state.etiquetas_avulsas : null,
        parcelas_atrasadas: this.state.parcelas_atrasadas != null && !this.state.etiqueta_avulsa ? this.state.parcelas_atrasadas : null,
      },
      {
        headers: { token: "Baer " + token },
      }
    );

    this.imprimirEtiquetas();
  };

  imprimirEtiquetas = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api.get(
      "/etiqueta/buscarEtiqueta?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&id=" +
      this.state.pessoa_id
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "etiqueta/buscarEtiqueta?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&id=" +
      this.state.pessoa_id;
    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = await new Blob([oReq.response], {
        type: "application/pdf",
      });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  changeDate = (e, a) => {
    this.setState({
      dataBusca: e.target.value,
    });
    if (
      !moment(e.target.value).isValid() ||
      moment(e.target.value).year() < 2010 ||
      moment(e.target.value).year() > 3000
    ) {
      this.setState({ dataInvalida: true });
      return;
    }

    this.setState({
      dataInvalida: false,
    });

    if (
      moment(e.target.value).format("YYYY-MM-DD") ===
      moment().format("YYYY-MM-DD")
    ) {
      this.buscaQuantidadeTipoTitulos();
    } else {
      this.buscaQuantidadeTipoTitulos(e.target.value);
    }
  };

  render() {
    return (
      <div>
        {this.state.listaQuantidadeTitulos.lenght === 0 ? (
          <CircularProgress />
        ) : (
          <div>
            <Row>
              <Col></Col>
              <Col md="auto" style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  label="Receitas"
                  checked={this.state.checkedReceitas}
                  onChange={(e, a) => {
                    this.setState({ checkedReceitas: a.checked });
                    if (a.checked === false) {
                      this.setState({
                        dataBusca: moment().format("YYYY-MM-DD"),
                      });
                      this.buscaQuantidadeTipoTitulos();
                    } else {
                      this.buscaQuantidadeTipoTitulos(
                        moment().format("YYYY-MM-DD")
                      );
                    }
                  }}
                />
              </Col>
              <Col md="auto">
                <Input
                  type="date"
                  error={this.state.dataInvalida}
                  onChange={this.changeDate}
                  loading={this.state.loadingData}
                  value={this.state.dataBusca}
                  disabled={!this.state.checkedReceitas}
                />
              </Col>
              <Col md="auto" style={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="primary"
                  onClick={this.imprimirQuantidadeTipoTitulo}
                  disabled={
                    this.state.listaQuantidadeTitulos.lenght === 0 ||
                    this.state.dataInvalida
                  }
                  color="blue"
                >
                  Imprimir
                </Button>
              </Col>
            </Row>

            <Table celled>
              <Table.Header>
                <Table.HeaderCell>Tipo do título</Table.HeaderCell>
                <Table.HeaderCell>Quantidade de Titulares</Table.HeaderCell>
                <Table.HeaderCell>Quantidade de Dependentes</Table.HeaderCell>
                <Table.HeaderCell>Total</Table.HeaderCell>
                <Table.HeaderCell width={1}></Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                {this.state.listaQuantidadeTitulos.map((e, i) => (
                  <>
                    {console.log("Element", e)}
                    {/* {console.log("Indice", i)} */}
                    {e.checked === true && (
                      <Table.Row key={i}>
                        <Table.Cell>{e.descricao}</Table.Cell>
                        <Table.Cell>{e.count ? e.count : 0}</Table.Cell>
                        <Table.Cell>{e.dependentes ? e.dependentes : 0}</Table.Cell>
                        <Table.Cell>{e.totalDependetes}</Table.Cell>
                        <Table.Cell width={1}>
                          <Checkbox
                            checked={e.checked}
                            onClick={(e) => {
                              let a = this.state.listaQuantidadeTitulos;
                              a[i].checked = false;
                              this.setState({ listaQuantidadeTitulos: a });
                            }}
                          ></Checkbox>
                        </Table.Cell>

                      </Table.Row>
                    )}
                  </>
                ))}
                {/* {console.log("totalGeral 3:", this.state.listaQuantidadeTitulos)} */}
                {this.state.listaQuantidadeTitulos.totalGeral !== undefined}

                <Table.HeaderCell>Total</Table.HeaderCell>
                <Table.HeaderCell textAlign={"right"}>
                  {
                    <strong>
                      {this.state.totalTitulares}
                    </strong>
                  }
                </Table.HeaderCell>
                <Table.HeaderCell textAlign={"right"}>
                  {
                    <strong>
                      {this.state.totalDependetes}
                    </strong>
                  }
                </Table.HeaderCell>
                <Table.HeaderCell textAlign={"right"}>
                  {
                    <strong>
                      {this.state.totalGeral}
                    </strong>
                  }
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>

              </Table.Body>
              <Table.Body>
                {this.state.listaQuantidadeTitulos.map((e, i) => (
                  <>
                    {e.checked === false && (
                      <Table.Row key={i}>
                        <Table.Cell>{e.descricao}</Table.Cell>
                        <Table.Cell>{e.count}</Table.Cell>
                        <Table.Cell width={1}>
                          <Checkbox
                            checked={e.checked}
                            onClick={(e) => {
                              let a = this.state.listaQuantidadeTitulos;
                              a[i].checked = true;
                              this.setState({ listaQuantidadeTitulos: a });
                            }}
                          ></Checkbox>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </>
                ))}
              </Table.Body>

            </Table>

            <Header as="h3" dividing>
              Pesquisa de Títulos
            </Header>
            <Row>
              <Col sm={4}>
                <Form.Label>Data Inicio Admissão</Form.Label>
                <Form.Control
                  placeholder="Data Inicio"
                  aria-describedby="basic-addon1"
                  type="date"
                  name="data_inicio"
                  defaultValue={this.state.data_inicio}
                  onChange={this.alteracao}
                  disabled={this.state.etiqueta_avulsa}
                />
              </Col>
              <Col sm={4}>
                <Form.Label>Data Final Admissão</Form.Label>
                <Form.Control
                  placeholder="Data Final"
                  aria-describedby="basic-addon1"
                  type="date"
                  name="data_final"
                  defaultValue={this.state.data_final}
                  onChange={this.alteracao}
                  disabled={this.state.etiqueta_avulsa}
                />
              </Col>
              <Col sm={4}>
                <Form.Label>Tipo Título</Form.Label>
                <Form.Control
                  as="select"
                  name="tipo"
                  checked={this.state.tipo}
                  id="tipo"
                  defaultValue={this.state.tipo}
                  required
                  onChange={this.alteracao}
                  disabled={this.state.etiqueta_avulsa}
                >
                  <option value="todos">Todos</option>
                  <option value="titular">Titulares</option>
                  {this.state.listaQuantidadeTitulos.map((e, i) => (
                    <option value={e.id}>{e.descricao}</option>
                  ))}
                </Form.Control>
              </Col>
            </Row>


            <Row style={{ paddingTop: 10 }}>
              <Col sm={2}>
                <Checkbox style={{ paddingTop: 30 }}
                  label="Etiquetas Avulsas"
                  checked={this.state.etiqueta_avulsa}
                  onChange={() => this.setState({
                    etiqueta_avulsa: !this.state.etiqueta_avulsa,
                  })}
                />
              </Col>
              <Col sm={6}>
                <Form.Label>Códigos (Separe com vírgula)</Form.Label>
                <FormField
                  placeholder="Códigos de ação: 105,205,235,1201,5415"
                  name="codigos_etiquetas"
                  control={Input}
                  disabled={!this.state.etiqueta_avulsa}
                  onChange={this.alteracaoEtiquetasAvulsas}
                  fluid
                />
              </Col>
              <Col sm={3}>
                <Form.Label>Parcelas atrasadas</Form.Label>
                <FormField
                  placeholder="Parcelas atrasadas: 3"
                  name="parcelas_atrasadas"
                  type="number"
                  control={Input}
                  onChange={this.alteracao}
                  disabled={this.state.etiqueta_avulsa}
                  fluid
                />
              </Col>
              <Col sm={1}>
                <Button
                  variant="success"
                  style={{ marginTop: "27px" }}
                  onClick={this.botaoBuscaPessoasOnClick}
                  color="green"
                >
                  Buscar
                </Button>
              </Col>
            </Row>


            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>CPF/CNPJ</Table.HeaderCell>
                  <Table.HeaderCell>Nome</Table.HeaderCell>
                  <Table.HeaderCell>N.º da ação</Table.HeaderCell>
                  <Table.HeaderCell>Data da emissão</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {this.state.listaPessoasTipoTituloSlice.map((e, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{e.cpf === null ? e.cnpj : e.cpf}</Table.Cell>
                    <Table.Cell>{e.nome}</Table.Cell>
                    <Table.Cell>
                      {e.codigo === null
                        ? e.prefixo + " " + e.codigo_auxiliar
                        : e.codigo}
                    </Table.Cell>
                    <Table.Cell>
                      {moment(e.data_emissao).format("DD/MM/YYYY")}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>

            <Col sm={0.9} style={{ float: "right" }}>
              <Button
                variant="primary"
                onClick={this.gerarRelatorioPesquisa}
                disabled={
                  this.state.listaPessoasTipoTituloSlice.length === 0 ||
                  this.state.botaoImprimirPessoas
                }
                color="blue"
              >
                Imprimir
              </Button>
            </Col>
            <Col sm={0.9} style={{ float: "right", marginRight: "10px" }}>
              <Button
                variant="primary"
                onClick={this.gerarCSVPesquisa}
                disabled={
                  this.state.listaPessoasTipoTituloSlice.length === 0 ||
                  this.state.botaoImprimirPessoas
                }
              >
                Gerar CSV
              </Button>
            </Col>
            <Col sm={0.9} style={{ float: "right", marginRight: "10px" }}>
              <Button
                variant="primary"
                onClick={this.gerarEtiquetas}
                // hidden={this.state.tipo !== "titular"}
                disabled={
                  (this.state.listaPessoasTipoTituloSlice.length === 0 ||
                    this.state.botaoImprimirPessoas) /*||
                  this.state.tipo !== "titular") &&*/
                  //!this.state.etiqueta_avulsa
                }
                color="blue"
              >
                Imprimir Etiquetas
              </Button>
            </Col>
            <Container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Pagination
                boundaryRange={0}
                //defaultActivePage={1}
                ellipsisItem={null}
                //firstItem={null}
                //lastItem={null}
                siblingRange={1}
                totalPages={this.state.totalPages}
                onPageChange={this.pageChange}
                activePage={this.state.paginaAtual}
                disabled={this.state.listaPessoasTipoTituloSlice.length === 0}
              />
            </Container>
          </div>
        )}
      </div>
    );
  }
}
