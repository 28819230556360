/* eslint-disable no-unused-vars */
import React, { Component } from "react";

import { Header } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import CartaoAmarelo from "./cartaoAmarelo/cartaoAmarelo";
import CartaoVermelho from "./cartaovermelho/cartaoVermelho";
import Artilheiro from "./artilheiro/artilheiro";
import Assistencia from "./assistencia/assistencia";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: localStorage.getItem("pagina_anterior"),
      selecionado: null,
    };
  }

  changeTabMenu = async (event, newValue) => {
    this.setState({ selecionado: newValue });
  };

  render() {
    let content = "";
    switch (this.state.selectedMenu) {
      case 0:
        localStorage.setItem("pagina_anterior", "pessoas");
        content = <CartaoAmarelo />;
        break;
      case 1:
        localStorage.setItem("pagina_anterior", "pessoas");
        content = <CartaoVermelho />;
        break;
      case 2:
        localStorage.setItem("pagina_anterior", "pessoas");
        content = <Artilheiro />;
        break;
      case 3:
        localStorage.setItem("panina_anterior", "pessoas");
        content = <Assistencia />;
        break;
      // case "modalidade":
      //   localStorage.setItem("pagina_anterior", "modalidade");
      //   content = <Modalidade />;
      //   break;
      // case "organizacao":
      //   localStorage.setItem("pagina_anterior", "organizacao");
      //   content = <Organizacao />;
      //   break;
      // case "campeonato":
      //   localStorage.setItem("pagina_anterior", "campeonato");
      //   content = <Campeonato />;
      //   break;

      // case "tipoEventos":
      //   localStorage.setItem("pagina_anterior", "tipoEventos");
      //   content = <TipoEventos />;
      //   break;
      // case "classificacao":
      //   localStorage.setItem("pagina_anterior", "tipoEventos");
      //   content = <Classfificacao />;
      //   break;
      // case "realtorios":
      //   localStorage.setItem("pagina_anterior", "tipoEventos");
      //   content = <Relatorio />;
      //   break;

      default:
        break;
    }
    return (
      <div>
        <Header as="h2" dividing></Header>
        <Row>
          <Col>
            <Tabs
              value={this.state.selectedMenu}
              // onChange={handleChange}
              onChange={(event, index) => {
                this.setState({ selectedMenu: index });
              }}
              indicatorColor="primary"
              textColor="primary"
              aria-label="full width tabs example"
            >
              <Tab
                style={{ outline: "none" }}
                label="Cartão amarelo"
                value={0}
              />
              <Tab
                style={{ outline: "none" }}
                label="Cartao Vermelho"
                value={1}
              />
              <Tab style={{ outline: "none" }} label="Artilheiro" value={2} />
              <Tab style={{ outline: "none" }} label="Assistencia" value={3} />
            </Tabs>
          </Col>
        </Row>

        {content}
      </div>
    );
  }

  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
  };
}
