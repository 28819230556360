/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Segment, Table } from "semantic-ui-react";
import { Row, Col } from "react-bootstrap";

import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Paper,
  Tabs,
  Tab,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="paginaSemPaddingTop">
        {this.props.cartoesAmarelo.map((element, index) => (
          <Table selectable compact striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={12}>
                  {element.nome_equipe}
                </Table.HeaderCell>
                <Table.HeaderCell width={4}>{"Cartões"}</Table.HeaderCell>
                <Table.HeaderCell width={4}>{"Suspenso"}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {element.jogadores.map((elmt, indx) => (
                <Table.Row>
                  <Table.Cell>{elmt?.nome} </Table.Cell>
                  <Table.Cell>{elmt?.quantidade_cartoes}</Table.Cell>
                  <Table.Cell>{elmt.jogos_suspenso}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ))}
      </div>
    );
  }
}
