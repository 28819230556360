import React, { Component } from "react";
import { Button, Modal, Header, Icon,Loader,Dimmer } from "semantic-ui-react";
import { FormControl } from "react-bootstrap";
export default class ModalAlteraDias extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Modal open={this.props.modalAlteraDiasOpen} closeIcon size="tiny">
          <Header icon>
            <Icon name="calendar" />
            Dias para cancelamento automático
          </Header>
          <Modal.Content>
            <FormControl
              name="diasCancelamento"
              fluid
              onChange={this.props.alteracao}
              value={this.props.diasCancelamento}
            />
          </Modal.Content>
          <Modal.Actions>
          <Dimmer active={this.props.liberaReservaBotao} style={{ background: '#999999', opacity: 0.7  }}>
            <Loader>Reservando aguarde...</Loader>
          </Dimmer>
            <Button
              color="red"
              inverted
              onClick={() => this.props.abreModalAlteraDias(false)}
            >
              <Icon name="remove" /> Fechar
            </Button>
            <Button
              disabled={this.props.liberaReservaBotao}
              color="green"
              inverted
              icon="calendar check"
              onClick={() => this.props.confirmaReserva(true)}
            >
              <Icon name="checkmark" /> Reservar
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}
