import React, { Component } from "react";
import {
  Table,
  Header,
  Modal,
  Button,
  Divider,
  Dimmer,
  Loader,
  Checkbox,
} from "semantic-ui-react";
import { Col, Form, FormControl, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// Utils
import api from "../../../services/api";
import moment from "moment-timezone";
import requisicoes from "../../../utils/Requisicoes";

export default class ModalMatriculas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anoMatricula: moment().get("year"),

      lista_lancamentos: [],
      buscandoLancamentos: false,
      checkboxes_lancamentos: [],
      checkboxTodos: false,
    };
  }
  buscarLancamentos = async (pessoa_id, servico_id, data_matricula, e) => {
    this.setState({ lista_Lancamentos: [], buscandoLancamentos: true });

    const servico = await api.post(
      "/servico/visualiza" + requisicoes.entidade + "&id=" + servico_id,
      {},
      requisicoes.header
    );

    const retorno = await api.post(
      "/lancamentoConta/lancamentosEmAbertoPorPessoaEServico" +
        requisicoes.entidade,
      {
        pessoa_id,
        servico_id: [
          servico.data.servico_inscricao_id,
          servico.data.servico_mensalidade_id,
        ],
      },
      requisicoes.header
    );

    let listaCheckbox = [];
    for (let i = 0; i < retorno.data.length; i++) {
      const element = retorno.data[i];
      listaCheckbox.push({
        id: element.id,
        checked: element.data_emissao === data_matricula,
      });
    }

    this.setState({
      lista_lancamentos: retorno.data,
      buscandoLancamentos: false,
      checkboxes_lancamentos: listaCheckbox,
    });
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeCheckbox = (i, checked) => {
    let listaCheckbox = this.state.checkboxes_lancamentos;
    if (i !== -1) {
      listaCheckbox[i].checked = checked;
    } else {
      for (let i = 0; i < listaCheckbox.length; i++) {
        const element = listaCheckbox[i];
        element.checked = checked;
      }
      this.setState({ checkboxTodos: checked });
    }
    this.setState({ checkboxes_lancamentos: listaCheckbox });
  };

  render() {
    return (
      <Modal
        style={{ width: "65%" }}
        trigger={
          <Button
            content="Matrículas"
            icon="history"
            color="blue"
            size="tiny"
            floated="right"
          ></Button>
        }
      >
        <Modal.Header>
          <Row>
            <Col sm={9}>
              <Header as="h2">Matrículas</Header>
            </Col>
            <Col sm={2}>
              <Form.Control
                placeholder="Ano"
                label="Ano"
                id="anoMatricula"
                as="select"
                name="anoMatricula"
                onChange={this.alteracao}
                defaultValue={this.state.anoMatricula}
                required
              >
                {this.props.lista_anos.map((e, i) => (
                  <option value={e}>{e}</option>
                ))}
              </Form.Control>
            </Col>

            {localStorage.getItem("tipo_usuario_clube") !==
              "acessoTemporario" && (
              <Col sm={1}>
                <Link
                  to={{
                    pathname: "/reservas",
                    query: {
                      select: { value: "venda", label: "Vendas" },
                      pessoa: this.state.pessoa,
                    },
                  }}
                  style={{
                    marginLeft: "1%",
                    paddingTop: "0px",
                    marginTop: "0px",
                  }}
                >
                  <Button color="green" icon={"add"}></Button>
                </Link>
              </Col>
            )}
          </Row>
        </Modal.Header>
        <Modal.Content>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Data Matricula</Table.HeaderCell>
                <Table.HeaderCell>Matriculado por</Table.HeaderCell>
                <Table.HeaderCell width={4}>Turma</Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  Situação Matricula
                </Table.HeaderCell>
                <Table.HeaderCell>Data Cancelamento</Table.HeaderCell>
                <Table.HeaderCell>Cancelado por</Table.HeaderCell>

                {this.props.permissoes.find(
                  (e) => e.nome === "cancelar_matriculas"
                ) && <Table.HeaderCell width={1}></Table.HeaderCell>}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.matriculas.map((e, i) => {
                return (
                  <>
                    {moment(e.dataMatricula).get("year") ===
                      parseInt(this.state.anoMatricula) && (
                      <Table.Row key={i}>
                        <Table.Cell>
                          {e.dataMatricula !== null &&
                            moment(e.dataMatricula).format("DD/MM/YYYY")}
                          {e.hora_matricula
                            ? " - " + e.hora_matricula.substring(0, 5)
                            : ""}
                        </Table.Cell>
                        <Table.Cell>{e.usuario && e.usuario.nome}</Table.Cell>
                        <Table.Cell>
                          {e.turma_servico !== null && e.turma_servico.nome}
                        </Table.Cell>
                        <Table.Cell>
                          {e.situacaoMatricula !== null && e.situacaoMatricula}
                        </Table.Cell>
                        <Table.Cell>
                          {e.dataCancelamento !== null &&
                            moment(e.dataCancelamento).format("DD/MM/YYYY")}
                          {e.historicos[0] ? " - " + e.historicos[0]?.hora : ""}
                        </Table.Cell>
                        <Table.Cell>
                          {e.historicos[0]?.usuario?.nome}
                        </Table.Cell>

                        {this.props.permissoes.find(
                          (e) => e.nome === "cancelar_matriculas"
                        ) && (
                          <Table.Cell
                            width={1}
                            style={{ paddingTop: 7, paddingBottom: 7 }}
                          >
                            {e.dataCancelamento == null &&
                              this.modalConfirmarCancelamento(e)}
                          </Table.Cell>
                        )}
                      </Table.Row>
                    )}
                  </>
                );
              })}
            </Table.Body>
          </Table>
          {this.props.matriculasDependentes.length > 0 ? (
            <>
              <br />
              <Divider></Divider>
              <br />
            </>
          ) : null}
          {this.props.matriculasDependentes.map((e, i) => (
            <>
              {e.length > 0 ? (
                <>
                  <div style={{ textAlign: "left" }}>
                    <strong>Dependente: </strong>
                    {this.props.dependentes[i] !== undefined
                      ? this.props.dependentes[i].pessoa.label
                      : ""}
                  </div>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Data Matricula</Table.HeaderCell>
                        <Table.HeaderCell>Matriculado por</Table.HeaderCell>
                        <Table.HeaderCell width={4}>Turma</Table.HeaderCell>
                        <Table.HeaderCell width={1}>
                          Situação Matricula
                        </Table.HeaderCell>
                        <Table.HeaderCell>Data Cancelamento</Table.HeaderCell>
                        <Table.HeaderCell>Cancelado por</Table.HeaderCell>
                        {this.props.permissoes.find(
                          (e) => e.nome === "cancelar_matriculas"
                        ) && <Table.HeaderCell width={1}></Table.HeaderCell>}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {e.map((e2, i2) => (
                        <Table.Row key={i2}>
                          {moment(e2.dataMatricula).get("year") ===
                            parseInt(this.state.anoMatricula) && (
                            <>
                              <Table.Cell>
                                {moment(e2.dataMatricula).format("DD/MM/YYYY")}
                                {e2.hora_matricula
                                  ? " - " + e2.hora_matricula.substring(0, 5)
                                  : ""}
                              </Table.Cell>
                              <Table.Cell>
                                {e2.usuario && e2.usuario.nome}
                              </Table.Cell>
                              <Table.Cell>{e2.turma_servico.nome}</Table.Cell>
                              <Table.Cell>{e2.situacaoMatricula}</Table.Cell>
                              <Table.Cell>
                                {e2.dataCancelamento !== null
                                  ? moment(e2.dataCancelamento).format(
                                      "DD/MM/YYYY"
                                    )
                                  : ""}
                                {e2.historicos && e2.historicos[0]
                                  ? " - " + e2.historicos[0]?.hora
                                  : ""}
                              </Table.Cell>
                              <Table.Cell>
                                {e2.historicos[0]?.usuario?.nome}
                              </Table.Cell>

                              <Table.Cell
                                width={1}
                                style={{ paddingTop: 7, paddingBottom: 7 }}
                              >
                                {this.props.permissoes.find(
                                  (e) => e.nome === "cancelar_matriculas"
                                ) &&
                                  e2.dataCancelamento == null &&
                                  this.modalConfirmarCancelamento(e2)}
                              </Table.Cell>
                            </>
                          )}
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </>
              ) : null}
            </>
          ))}
        </Modal.Content>
      </Modal>
    );
  }

  modalConfirmarCancelamento = (e) => {
    return (
      <Modal
        size="small"
        centered={true}
        trigger={
          <Button
            circular
            icon="remove"
            negative
            size="tiny"
            onClick={() =>
              this.buscarLancamentos(
                e.pessoa_id,
                e.turma_servico.servico_id,
                e.dataMatricula,
                e
              )
            }
          ></Button>
        }
      >
        <Modal.Header>
          <Row>
            <Col fluid>
              <Header as="h3">Confirmar Cancelamento</Header>
            </Col>
            <Col md="auto">
              <h5>
                {`Data matrícula: ${moment(e.dataMatricula).format(
                  "DD/MM/YYYY"
                )}`}
              </h5>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Content>
          <Row>
            <Col sm={12}>
              <Form.Label>Motivo</Form.Label>
              <FormControl
                aria-label="Motivo"
                aria-describedby="basic-addon1"
                name="motivo_cancelamento"
                onChange={this.props.alteracao}
              />
            </Col>
            <br />
          </Row>
          <Dimmer active={this.state.buscandoLancamentos}>
            <Loader>Carregando</Loader>
          </Dimmer>
          {this.state.buscandoLancamentos === false &&
          this.state.lista_lancamentos.length > 0 ? (
            <>
              <h4 style={{ marginBottom: 7 }}>Lancamentos em aberto:</h4>
              <h4 style={{ color: "red", marginTop: 0 }}>
                Selecine os lancamentos a serem cancelados.
              </h4>

              <Table celled selectable compact>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={6}>Data Emissao</Table.HeaderCell>
                    <Table.HeaderCell width={6}>
                      Data Vencimento
                    </Table.HeaderCell>
                    <Table.HeaderCell width={6}>Valor</Table.HeaderCell>
                    <Table.HeaderCell>
                      <Checkbox
                        checked={this.state.checkboxTodos}
                        onChange={(e, a) => this.changeCheckbox(-1, a.checked)}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.lista_lancamentos.map((e, i) => (
                    <Table.Row>
                      <Table.Cell>
                        {moment(e.data_emissao).format("DD/MM/YYYY")}
                      </Table.Cell>
                      <Table.Cell>
                        {moment(e.data_vencimento).format("DD/MM/YYYY")}
                      </Table.Cell>
                      <Table.Cell>
                        {parseFloat(e.valor).toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Table.Cell>
                      <Table.Cell>
                        <Checkbox
                          checked={this.state.checkboxes_lancamentos[i].checked}
                          onChange={(e, a) => this.changeCheckbox(i, a.checked)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </>
          ) : null}
        </Modal.Content>
        <Modal.Actions>
          <strong>Confirma Cancelamento?</strong>
          <br />
          <br />
          <Button
            primary
            size="tiny"
            type="button"
            value={e.id}
            onClick={(event) =>
              this.props.cancelarMatricula(
                e.id,
                this.state.checkboxes_lancamentos,
                e.pessoa_id
              )
            }
          >
            Sim
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
}
