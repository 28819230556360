import React, { Component } from "react";
import {
  Grid,
  Image,
  Form,
  Button,
  Container,
  Message,
  Segment,
  Header,
  Divider,
  Icon,
  Label,
  FormField,
  Modal,
  Input,
} from "semantic-ui-react";
import { Formik } from "formik";
import * as Yup from "yup";
import api from "../services/api";
import logo from "../images/LogoFaee03.png";
import logoSrMampituba from "../images/icon.png";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import CircularProgress from "@material-ui/core/CircularProgress";

const logoApp = logo;
const logoMampituba = logoSrMampituba;

const defaultFormShape = {
  senha: "",
  login: "",
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginInvalido: false,
      isLogin: false,
      size: this.tamanhoTela(),
      openCadastrarUsuario: false,
      selectedOption: "",
      cpf_cnpj: "",
      cadastroNotFound: false,
      listaCadastros: [],
      listaCadastrosView: [],
      listaEntidadesView: [],
      clube_opcao_cadastrar: "",
      nome_cadastro: "",
      login_cadastro: "",
      problemaCadastro: false,
      sucessoCadastro: false,
      clicouCadastrar: false,
      tipoUsuarioInvalido: false,
      emailNaoCadastrado: false,
    };
  }

  tamanhoTela() {
    let tamanho = null;
    if (window.innerWidth > 280) {
      tamanho = "tiny";
    } else {
      tamanho = "huge";
    }
    return tamanho;
  }

  efetuarLogin = async (values) => {
    this.setState({ isLogin: true });
    await api
      .post("/usuario/autenticacao?e=public", {
        login: values.login,
        senha: values.senha,
      })
      .then((resposta) => {
        var localStorage = window.localStorage;
        localStorage.setItem("token_clube", resposta.data.token);
        localStorage.setItem("e_clube", resposta.data.usuario.entidade);
        localStorage.setItem(
          "tipo_usuario_clube",
          resposta.data.usuario.tipo_usuario
        );
        window.location.reload();
      })
      .catch((e) => {
        this.setState({ loginInvalido: true });
      });
  };

  buscaUserCpf_Cnpj = async (values) => {
    this.setState({
      listaCadastros: [],
      listaCadastrosView: [],
      emailNaoCadastrado: false,
    });
    if (this.state.cpf_cnpj === "") {
      this.setState({ cadastroNotFound: true });
      return;
    }
    var pessoas = [];
    await api
      .post("pessoa/buscaCpfCnpjTodasEntidade?e=public", {
        cpf_cnpj: this.state.cpf_cnpj,
      })
      .then((resposta) => {
        if (resposta.data.length === 0) {
          this.setState({ cadastroNotFound: true });
          return;
        }
        pessoas = resposta;
      })
      .catch((e) => {
        console.log("erro: ", e);
        this.setState({ loginInvalido: true });
      });
    if (!pessoas.data) {
      this.setState({ cadastroNotFound: true });
      return;
    }

    this.setState({ nome_cadastro: pessoas.data.nome });

    for (let i = 0; i < pessoas.data.length; i++) {
      let tipoTitulo = "";
      const element = pessoas.data[i];

      //Por algum motivo, quando busca uma ação da base teste entra em loop.
      if (!pessoas.data[i].entidade.includes("teste")) {
        await api
          .post("/acao/visualiza?e=" + pessoas.data[i].entidade, {
            id: element.acao_id,
          })
          .then((resposta) => {
            tipoTitulo = resposta.data.tipo_titulo.descricao;
          })
          .catch((e) => {});
      }
      await api
        .post("/usuario/usuariosPorPessoa?e=public", {
          id: element.id,
          entidade: pessoas.data[i].entidade,
        })
        .then((resposta) => {
          let a = {
            entidade: pessoas.data[i].entidade,
            nomeClube: pessoas.data[i].nomeClube,
            pessoa_id: element.id,
            nome: element.nome,
            cpf: element.cpf,
            email: element.email,
            tipoTitulo: tipoTitulo,
            usuarios: resposta.data,
          };
          let b = this.state.listaCadastros;
          b.push(a);
          this.setState({ listaCadastros: b });
        })
        .catch((e) => {
          this.setState({ loginInvalido: true });
        });
    }

    var contEmail = 0;
    var listaCadastrosView = [];
    var listaEntidadesView = [];
    if (this.state.listaCadastros.length > 1) {
      listaEntidadesView.push(<option key={500} value=""></option>);
    }

    //Verificar se tem uma base que não é teste e uma que é teste, pelo menos.
    //Se tiver base teste e outra base, mostrar apenas os registros dessa outra base.
    let verificaBaseTeste = false;
    let verificaBaseNaoTeste = false;
    for (let i = 0; i < this.state.listaCadastros.length; i++) {
      const element = this.state.listaCadastros[i];
      if (element.entidade.includes("teste")) {
        verificaBaseTeste = true;
      } else {
        verificaBaseNaoTeste = true;
      }
    }

    var maxItens = 100;

    for (let i = 0; i < this.state.listaCadastros.length; i++) {
      const element = this.state.listaCadastros[i];

      if (this.state.listaCadastros.length === 1) {
        this.setState({ clube_opcao_cadastrar: element.entidade });
      }
      if (
        verificaBaseTeste &&
        verificaBaseNaoTeste &&
        element.entidade.includes("teste")
      ) {
        continue;
      }
      if (
        element.email !== null &&
        element.email !== "" &&
        element.email.includes("@")
      ) {
        contEmail++;
        listaEntidadesView.push(
          <option key={maxItens * i + 4} value={element.entidade}>
            {element.nomeClube}
          </option>
        );
      }

      listaCadastrosView.push(<Divider key={maxItens * i}></Divider>);
      listaCadastrosView.push(
        <h4 key={maxItens * i + 1}>Clube cadastrado: {element.nomeClube}</h4>
      );
      listaCadastrosView.push(
        <p key={maxItens * i + 2}>
          <strong>Email: </strong>
          {element.email}
        </p>
      );
      listaCadastrosView.push(
        <p key={maxItens * i + 3} style={{ margin: "0px", padding: "0px" }}>
          <strong>Logins: </strong>
        </p>
      );
      for (let j = 0; j < element.usuarios.length; j++) {
        const element2 = element.usuarios[j];

        listaCadastrosView.push(
          <li key={maxItens * i + 5 + j}>{element2.login}</li>
        );
      }
    }

    if (contEmail === 0) {
      this.setState({ emailNaoCadastrado: true });
    }

    listaCadastrosView.push(<Divider key={10000}></Divider>);

    this.setState({ listaCadastrosView: listaCadastrosView });
    this.setState({ listaEntidadesView: listaEntidadesView });
    this.setState({ login_cadastro: this.state.listaCadastros[0].cpf });
    this.setState({ nome_cadastro: this.state.listaCadastros[0].nome });
  };

  cadastrarUsuario = async (values) => {
    this.setState({ clicouCadastrar: true });

    var tipoTitulo = "";
    var pessoaId = 0;
    var entidade = this.state.clube_opcao_cadastrar;
    for (let i = 0; i < this.state.listaCadastros.length; i++) {
      const element = this.state.listaCadastros[i];
      if (element.entidade === entidade) {
        tipoTitulo = element.tipoTitulo;
        pessoaId = element.pessoa_id;
      }
    }

    var tipoUsuario = "";

    if (
      tipoTitulo == "Patrimonial" ||
      tipoTitulo == "PATRIMONIAL" ||
      tipoTitulo == "Contribuinte" ||
      tipoTitulo == "CONTRIBUINTE" ||
      tipoTitulo == "CONTRIBUINTE COM DEPENDENTE" ||
      tipoTitulo == "CONTRIBUINTE SEM DEPENDENTES" ||
      tipoTitulo == "Temporario" ||
      tipoTitulo == "TEMPORÁRIO" ||
      tipoTitulo.toUpperCase() === "CONTRIBUINTE SEM DEPENDENTES"
    ) {
      tipoUsuario = "titular";
    } else if (tipoTitulo == "dependente" || tipoTitulo == "DEPENDENTE") {
      tipoUsuario = tipoTitulo;
    } else {
      tipoUsuario = "invalido";
    }
    if (tipoUsuario == "invalido") {
      this.setState({ tipoUsuarioInvalido: true });
      this.setState({ clicouCadastrar: false });
      return;
    }

    await api
      .post("/usuario/salvar?e=" + entidade, {
        nome: this.state.nome_cadastro,
        login: this.state.login_cadastro,
        tipo_usuario: tipoUsuario,
        pessoa_id: pessoaId,
      })
      .then((resposta) => {
        console.log("cadastrou o usuario");
      })
      .catch((e) => {
        this.setState({ loginInvalido: true });
        this.setState({ clicouCadastrar: false });
      });
    if (this.state.loginInvalido) {
      return;
    }

    await api
      .post("/usuario/esqueceuSenha?e=public", {
        login: this.state.login_cadastro,
      })
      .then((resposta) => {
        this.setState({ sucessoCadastro: true });
      })
      .catch((e) => {
        var a = e.message;
        console.log(a);
        this.setState({ problemaCadastro: true });
      });
  };

  render() {
    const validationSchema = Yup.object().shape({
      login: Yup.string().required("Login é requerido!"),
      senha: Yup.string().required("Por favor digite a senha"),
    });
    return (
      <Formik
        initialValues={defaultFormShape}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values) => {
          this.efetuarLogin(values);
        }}
        render={({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <div
            style={{
              display: "flex",
              minHeight: "100vh",
              flexDirection: "column",
            }}
          >
            <Container fluid>
              <Row>
                <Col sm={6} xs={6}>
                  <Header>
                    <Header.Content>
                      <Header.Subheader>
                        <Image
                          src={logoMampituba}
                          fluid
                          size="tiny"
                          floated="left"
                          circular
                        />
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Col>
                <Col sm={6} xs={6}>
                  <Header floated="right">
                    <Header.Content>
                      <Header.Subheader>
                        <p style={{ marginTop: "12%" }}>
                          <i>Seja Bem Vindo ao</i>
                        </p>
                        <h1 style={{ marginTop: "-20%" }}>Portal</h1>
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </Col>
              </Row>
            </Container>
            <Container fluid>
              <Segment
                placeholder
                style={{
                  backgroundColor: "firebrick",
                  opacity: ".8",
                  height: "70vh",
                  marginBottom: "1%",
                }}
              >
                <Grid
                  columns={1}
                  relaxed="very"
                  divided
                  style={{ height: "80vh", marginBottom: 0 }}
                >
                  <Grid.Column>
                    <Label as="a" color="green" ribbon>
                      <Icon name="vcard" />
                      Entrar
                    </Label>
                    <Header textAlign="center" size={this.state.size}>
                      <Header.Content>
                        <Header.Subheader>
                          <p
                            style={{ color: "white", marginBottom: 0 }}
                            className="d-none d-sm-block"
                          >
                            Para ter acesso ao portal é necessário informar seu
                            CPF e sua senha.
                          </p>
                          <p style={{ color: "white" }}>
                            Se você não possui senha é necessário realizar seu
                            cadastro informando seu CPF
                          </p>
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Form onSubmit={handleSubmit} loading={this.props.loading}>
                      <Form.Input
                        placeholder="Login"
                        icon="user"
                        iconPosition="left"
                        fluid
                        label={
                          <p style={{ color: "white", margin: 0 }}>Login</p>
                        }
                        name="login"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.login}
                        values={values.email}
                      />

                      <Form.Input
                        placeholder="Senha"
                        icon="lock"
                        iconPosition="left"
                        fluid
                        label={
                          <p style={{ color: "white", margin: 0 }}>Senha</p>
                        }
                        type="password"
                        name="senha"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        values={values.senha}
                        error={errors.senha}
                      />
                      {this.state.loginInvalido === true && (
                        <Message negative size={this.state.size}>
                          Login Inválido!
                        </Message>
                      )}
                      <Button
                        fluid
                        type="submit"
                        size="large"
                        icon
                        loading={this.state.isLogin}
                        labelPosition="left"
                        style={{ backgroundColor: "#23a455", color: "white" }}
                      >
                        Login
                        <Icon name="sign-in" />
                      </Button>
                    </Form>
                    <FormField style={{ marginTop: "10px", color: "black" }}>
                      <Link
                        to={{ pathname: "/esqueceuSenha" }}
                        style={{ color: "white" }}
                      >
                        Esqueceu a senha?
                      </Link>
                    </FormField>
                    <Divider />
                    <Header
                      textAlign="center"
                      size={this.state.size}
                      style={{ marginTop: "0" }}
                    >
                      <Header.Content style={{ width: "210px" }}>
                        <Header.Subheader>
                          <h3 style={{ color: "white" }}>Não possui senha?</h3>
                        </Header.Subheader>
                        <Header.Subheader className="d-none d-sm-block">
                          <p style={{ color: "white", paddingTop: "10%" }}>
                            1º Informe seu CPF
                          </p>
                        </Header.Subheader>
                        <Header.Subheader className="d-none d-sm-block">
                          <p style={{ color: "white" }}>
                            2º Confira seus dados
                          </p>
                        </Header.Subheader>
                        <Button
                          fluid
                          type="submit"
                          size="large"
                          icon
                          loading={this.state.isLogin}
                          labelPosition="left"
                          style={{
                            backgroundColor: "#23a455",
                            color: "white",
                            marginTop: "10%",
                          }}
                          onClick={() => {
                            this.setState({ openCadastrarUsuario: true });
                          }}
                        >
                          Cadastrar
                          <Icon name="privacy" />
                        </Button>
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                </Grid>
              </Segment>
              <Modal
                open={this.state.openCadastrarUsuario}
                onClose={() => {
                  this.setState({ openCadastrarUsuario: false });
                }}
                // style={{ width: "95%" }}
                size="small"
              >
                <Header content="Cadastrar Usuário" />
                <Modal.Content>
                  <Form
                    onSubmit={this.buscaUserCpf_Cnpj}
                    style={{
                      width: "80%",
                      maxWidth: "600px",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <h4>Digite o seu CPF:</h4>
                    <Row>
                      <Col>
                        <FormField>
                          <InputMask
                            placeholder="CPF"
                            name="cpf"
                            onBlur={handleBlur}
                            onChange={(event) => {
                              this.setState({
                                cpf_cnpj: event.target.value,
                                cadastroNotFound: false,
                              });
                            }}
                            values={this.state.cpf_cnpj}
                            mask="999.999.999-99"
                          />

                          {this.state.selectedOption === "juridica" ? (
                            <InputMask
                              placeholder="CNPJ"
                              name="cnpj"
                              onBlur={handleBlur}
                              onChange={(event) => {
                                this.setState({
                                  cpf_cnpj: event.target.value,
                                  cadastroNotFound: false,
                                });
                              }}
                              values={values.cnpj}
                              mask="99.999.999/9999-99"
                            />
                          ) : null}
                        </FormField>
                      </Col>
                      <Col>
                        <Button type="submit" fluid color="blue" size="large">
                          Buscar
                        </Button>
                      </Col>
                    </Row>
                    {this.state.cadastroNotFound === true ? ( //this.state.selectedOption !== "" &&
                      <Message negative>Cadastro não encontrado.</Message>
                    ) : null}
                  </Form>
                  {this.state.listaCadastros.length > 0 ? (
                    <Container
                      style={{
                        width: "80%",
                        maxWidth: "600px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        padding: "0",
                      }}
                    >
                      {this.state.listaCadastrosView}
                    </Container>
                  ) : null}

                  <Form
                    style={{
                      width: "80%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "30px",
                      padding: "0",
                    }}
                    onSubmit={this.cadastrarUsuario}
                  >
                    {this.state.listaCadastros.length > 0 ? (
                      <Container style={{ padding: "0" }}>
                        <h4>Em qual clube você deseja se cadastrar?</h4>
                        <select
                          onChange={(event) => {
                            this.setState({
                              clube_opcao_cadastrar: event.target.value,
                            });
                          }}
                          defaultValue={this.state.clube_opcao_cadastrar}
                        >
                          {this.state.listaEntidadesView}
                        </select>
                        <Input
                          placeholder="Nome"
                          icon="user"
                          iconPosition="left"
                          fluid
                          name="nome"
                          onBlur={handleBlur}
                          disabled={true}
                          onChange={(event) => {
                            this.setState({
                              nome_cadastro: event.target.value,
                            });
                          }}
                          values={this.state.nome_cadastro}
                          defaultValue={this.state.nome_cadastro}
                          style={{ marginTop: "10px" }}
                        />
                        <Input
                          placeholder="Login"
                          icon="at"
                          iconPosition="left"
                          fluid
                          name="login"
                          onBlur={(event) => {
                            this.setState({
                              login_cadastro: event.target.value,
                            });
                          }}
                          onChange={(event) => {
                            this.setState({
                              login_cadastro: event.target.value,
                              loginInvalido: false,
                              tipoUsuarioInvalido: false,
                              emailNaoCadastrado: false,
                            });
                          }}
                          values={this.state.login_cadastro}
                          defaultValue={this.state.login_cadastro}
                          style={{ marginTop: "10px" }}
                        />
                        {this.state.loginInvalido === true ? (
                          <Message negative>Login já cadastrado!</Message>
                        ) : null}
                        {this.state.problemaCadastro === true ? (
                          <Message negative>
                            Problema ao gerar a senha do usuário.
                          </Message>
                        ) : null}
                        {this.state.sucessoCadastro === true ? (
                          <Message positive>
                            Cadastro efetuado com sucesso!
                          </Message>
                        ) : null}
                        {this.state.sucessoCadastro && (
                          <Container
                            style={{
                              fontSize: "16px",
                              marginTop: "16px",
                              marginBottom: "16px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            Verifique seu email.
                          </Container>
                        )}
                        {this.state.tipoUsuarioInvalido && (
                          <Message negative>
                            Cadastro deste tipo de título não suportado. Entre
                            em contato com o setor administrativo.
                          </Message>
                        )}
                        {this.state.emailNaoCadastrado && (
                          <Message negative>
                            Nenhum email cadastrado. Impossível enviar email com
                            a senha do usuário.
                          </Message>
                        )}
                        {this.state.sucessoCadastro === false &&
                        this.state.loginInvalido === false &&
                        this.state.problemaCadastro === false &&
                        this.state.clicouCadastrar === true ? (
                          <Container
                            style={{
                              marginTop: "30px",
                              marginBottom: "30px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <CircularProgress />
                          </Container>
                        ) : null}
                        <Button
                          type="submit"
                          color="green"
                          fluid
                          size="large"
                          style={{ marginTop: "15px" }}
                          disabled={
                            this.state.clube_opcao_cadastrar === "" ||
                            this.state.login_cadastro == "" ||
                            this.state.nome_cadastro == "" ||
                            this.state.clicouCadastrar ||
                            this.state.emailNaoCadastrado
                          }
                        >
                          Cadastrar
                        </Button>
                      </Container>
                    ) : null}
                  </Form>
                </Modal.Content>
              </Modal>
            </Container>
            <Segment
              vertical
              style={{
                bottom: 0,
                width: "100%",
                marginTop: "1%",
                marginBottom: "0",
              }}
            >
              <Divider section style={{ marginBottom: "3%" }} />
              <Row sm={12} xs={12}>
                <Col
                  sm={3}
                  xs={12}
                  className="d-none d-sm-block"
                  style={{ marginLeft: "10%", textAlign: "left" }}
                >
                  <b>Sociedade Recreativa Mampituba</b>
                  <br />
                  <p>
                    Sede Campeste Criciúma - SC, nº. - São Simão - Criciúma/SC -
                    CEP 88811-400
                  </p>
                </Col>
                <Col sm={4} xs={5} style={{ textAlign: "center" }}>
                  <b>Sede Centro</b>
                  <br />
                  <p>(48) 3437-0600</p>
                </Col>
                <Col sm={3} xs={6} style={{ textAlign: "center" }}>
                  <Image
                    centered
                    size="small"
                    fluid
                    src={logoApp}
                    style={{ paddingBottom: "11%" }}
                  />
                </Col>
                {/* </List.Item> */}
                {/* <List.Item style={{ textAlign: "left", marginLeft: "10%" }}> */}
                {/* </List.Item>
                </List> */}
              </Row>
            </Segment>
          </div>
        )}
      />
    );
  }
}

export default Login;
