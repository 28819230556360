import React, { Component } from "react";
import { Progress, Divider, Header, Icon } from "semantic-ui-react";
import api from "../../../../services/api";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import moment from "moment";
import "moment-timezone";
import requisicoes from "../../../../utils/Requisicoes";
import $ from "jquery";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import FolderOpenTwoToneIcon from "@material-ui/icons/FolderOpenTwoTone";
import LinearProgress from "@material-ui/core/LinearProgress";

export default class ExportacaoContabil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataInicial: null,
      dataFinal: null,
      gerandoContas: false,
      gerandoLancamentos: false,
      gerandoCaixas: false,
      gerandoRequisicoes: false,
      gerando: false,
      progresso: 0,
      inconsistencias: null,
      contasAPagar: null,
      lancamentoConta: null,
      caixa: null,
      requisicao: null,
    };
    this.visualizacao();
  }

  visualizacao = async () => {
    await api
      .post(
        "/exportacaoContabil/visualiza" + requisicoes.entidade,
        {},
        requisicoes.header
      )
      .then(async (resposta) => {
        console.log(resposta);
        for (let i = 0; i < resposta.data.length; i++) {
          switch (resposta.data[i].arquivo) {
            case "ContasAPagar":
              this.setState({ contasAPagar: resposta.data[i] });
              break;
            case "LancamentoConta":
              this.setState({ lancamentoConta: resposta.data[i] });
              break;
            case "Caixa":
              this.setState({ caixa: resposta.data[i] });
              break;
            case "Requisicao":
              this.setState({ requisicao: resposta.data[i] });
              break;
            default:
              break;
          }
        }
      });
  };

  handleSubmit = (e) => {
    this.setState({ gerando: true });
    if ($("#dataInicial").val() !== "" && $("#dataFinal").val() !== "") {
      this.gerarArquivoExportacao(() => {});
    } else {
      alert("Verifique as datas!");
    }
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  gerarArquivoExportacao = async (cb) => {
    // alert(
    //   "Atenção!\no arquivo de exportação pode demorar até 1 minuto para ser carregado"
    // );
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log(percentCompleted);
      },
    };
    let datas = [];
    for (
      var data = moment(this.state.dataInicial);
      data.diff(this.state.dataFinal, "days") <= 0;
      data.add(1, "days")
    ) {
      datas.push(moment(data));
    }
    let dados = {
      dataInicial: this.state.dataInicial,
      dataFinal: this.state.dataFinal,
      datas: datas,
    };

    var oReq = new XMLHttpRequest();
    await api
      .post(
        "/exportacaoContabil/gerar" + requisicoes.entidade,
        dados,
        requisicoes.header,
        config
      )
      .then((resp) => {
        this.setState({ inconsistencias: resp.data });
        var URLToPDF =
          resp.config.baseURL +
          "exportacaoContabil/gerar?e=" +
          window.localStorage.getItem("e_clube") +
          "&t=Baer " +
          window.localStorage.getItem("token_clube");
        oReq.open("GET", URLToPDF, true);
        oReq.responseType = "blob";

        oReq.onload = function (dataInicial, dataFinal) {
          oReq.fileInfo = { filename: "asdsa" };
          const file = new Blob([oReq.response], { type: "application/txt" });
          const fileURL = URL.createObjectURL(file);

          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = fileURL;

          var i = $("#dataInicial").val().split("-");

          var f = $("#dataFinal").val().split("-");
          a.download =
            "expcontabil_" +
            i[2] +
            "_" +
            i[1] +
            "_" +
            i[0] +
            "__" +
            f[2] +
            "_" +
            f[1] +
            "_" +
            f[0] +
            "__EMP301.txt";
          a.click();
        };
        oReq.send(this.state.dataInicial, this.state.dataFinal);
        this.setState({ gerando: false });
      })
      .catch((error) => console.log(error));
  };

  gerarArquivoContasAPagar = async (cb) => {
    if ($("#dataInicial").val() !== "" && $("#dataFinal").val() !== "") {
      this.setState({ gerandoContas: true });
      this.setState({ gerando: true });

      let datas = [];
      for (
        var data = moment(this.state.dataInicial);
        data.diff(this.state.dataFinal, "days") <= 0;
        data.add(1, "days")
      ) {
        datas.push(moment(data));
      }
      let dados = {
        dataInicial: this.state.dataInicial,
        dataFinal: this.state.dataFinal,
        datas: datas,
      };

      var oReq = new XMLHttpRequest();
      await api
        .post(
          "/exportacaoContabil/gerarContasAPagar" + requisicoes.entidade,
          dados,
          requisicoes.header
        )
        .then((resp) => {
          this.setState({ inconsistencias: resp.data });
          var URLToPDF =
            resp.config.baseURL +
            "exportacaoContabil/gerar?e=" +
            window.localStorage.getItem("e_clube") +
            "&t=Baer " +
            window.localStorage.getItem("token_clube");
          oReq.open("GET", URLToPDF, true);
          oReq.responseType = "blob";

          oReq.onload = function (dataInicial, dataFinal) {
            oReq.fileInfo = { filename: "asdsa" };
            const file = new Blob([oReq.response], { type: "application/txt" });
            const fileURL = URL.createObjectURL(file);

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = fileURL;

            var i = $("#dataInicial").val().split("-");

            var f = $("#dataFinal").val().split("-");
            a.download =
              "expcontabil_c_" +
              i[2] +
              "_" +
              i[1] +
              "_" +
              i[0] +
              "__" +
              f[2] +
              "_" +
              f[1] +
              "_" +
              f[0] +
              "__EMP301.txt";
            a.click();
          };
          oReq.send(this.state.dataInicial, this.state.dataFinal);
          this.setState({ gerando: false });
          this.setState({ gerandoContas: false });
        });
    } else {
      alert("Verifique as datas!");
    }
  };

  gerarArquivoLancamentosConta = async (cb) => {
    try {
      if ($("#dataInicial").val() !== "" && $("#dataFinal").val() !== "") {
        this.setState({ gerandoLancamentos: true });
        this.setState({ gerando: true });

        let datas = [];
        for (
          var data = moment(this.state.dataInicial);
          data.diff(this.state.dataFinal, "days") <= 0;
          data.add(1, "days")
        ) {
          datas.push(moment(data));
        }
        let dados = {
          dataInicial: this.state.dataInicial,
          dataFinal: this.state.dataFinal,
          datas: datas,
        };

        var oReq = new XMLHttpRequest();
        await api
          .post(
            "/exportacaoContabil/gerarLancamentoConta" + requisicoes.entidade,
            dados,
            requisicoes.header
          )
          .then((resp) => {
            this.setState({ inconsistencias: resp.data });
            var URLToPDF =
              resp.config.baseURL +
              "exportacaoContabil/gerar?e=" +
              window.localStorage.getItem("e_clube") +
              "&t=Baer " +
              window.localStorage.getItem("token_clube");
            oReq.open("GET", URLToPDF, true);
            oReq.responseType = "blob";

            oReq.onload = function (dataInicial, dataFinal) {
              oReq.fileInfo = { filename: "asdsa" };
              const file = new Blob([oReq.response], {
                type: "application/txt",
              });
              const fileURL = URL.createObjectURL(file);

              var a = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              a.href = fileURL;

              var i = $("#dataInicial").val().split("-");

              var f = $("#dataFinal").val().split("-");
              a.download =
                "expcontabil_l_" +
                i[2] +
                "_" +
                i[1] +
                "_" +
                i[0] +
                "__" +
                f[2] +
                "_" +
                f[1] +
                "_" +
                f[0] +
                "__EMP301.txt";
              a.click();
            };
            oReq.send(this.state.dataInicial, this.state.dataFinal);
            this.setState({ gerando: false });
            this.setState({ gerandoLancamentos: false });
          });
      } else {
        alert("Verifique as datas!");
      }
    } catch (error) {
      alert("Ocorreu um erro ao gerar a exportação.");
      this.setState({ gerandoLancamentos: false, gerando: false });
    }
  };

  gerarArquivoCaixa = async (cb) => {
    if ($("#dataInicial").val() !== "" && $("#dataFinal").val() !== "") {
      this.setState({ gerandoCaixas: true });
      this.setState({ gerando: true });

      let datas = [];
      for (
        var data = moment(this.state.dataInicial);
        data.diff(this.state.dataFinal, "days") <= 0;
        data.add(1, "days")
      ) {
        datas.push(moment(data));
      }
      let dados = {
        dataInicial: this.state.dataInicial,
        dataFinal: this.state.dataFinal,
        datas: datas,
      };

      var oReq = new XMLHttpRequest();
      await api
        .post(
          "/exportacaoContabil/gerarCaixa" + requisicoes.entidade,
          dados,
          requisicoes.header
        )
        .then((resp) => {
          this.setState({ inconsistencias: resp.data });
          var URLToPDF =
            resp.config.baseURL +
            "exportacaoContabil/gerar?e=" +
            window.localStorage.getItem("e_clube") +
            "&t=Baer " +
            window.localStorage.getItem("token_clube");
          oReq.open("GET", URLToPDF, true);
          oReq.responseType = "blob";

          oReq.onload = function (dataInicial, dataFinal) {
            oReq.fileInfo = { filename: "asdsa" };
            const file = new Blob([oReq.response], { type: "application/txt" });
            const fileURL = URL.createObjectURL(file);

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = fileURL;

            var i = $("#dataInicial").val().split("-");

            var f = $("#dataFinal").val().split("-");
            a.download =
              "expcontabil_mc_" +
              i[2] +
              "_" +
              i[1] +
              "_" +
              i[0] +
              "__" +
              f[2] +
              "_" +
              f[1] +
              "_" +
              f[0] +
              "__EMP301.txt";
            a.click();
          };
          oReq.send(this.state.dataInicial, this.state.dataFinal);
          this.setState({ gerando: false });
          this.setState({ gerandoCaixas: false });
        });
    } else {
      alert("Verifique as datas!");
    }
  };

  gerarArquivoRequisicao = async (cb) => {
    if ($("#dataInicial").val() !== "" && $("#dataFinal").val() !== "") {
      this.setState({ gerandoRequisicoes: true });
      this.setState({ gerando: true });

      let datas = [];
      for (
        var data = moment(this.state.dataInicial);
        data.diff(this.state.dataFinal, "days") <= 0;
        data.add(1, "days")
      ) {
        datas.push(moment(data));
      }
      let dados = {
        dataInicial: this.state.dataInicial,
        dataFinal: this.state.dataFinal,
        datas: datas,
      };

      var oReq = new XMLHttpRequest();
      await api
        .post(
          "/exportacaoContabil/gerarRequisicao" + requisicoes.entidade,
          dados,
          requisicoes.header
        )
        .then((resp) => {
          this.setState({ inconsistencias: resp.data });
          var URLToPDF =
            resp.config.baseURL +
            "exportacaoContabil/gerar?e=" +
            window.localStorage.getItem("e_clube") +
            "&t=Baer " +
            window.localStorage.getItem("token_clube");
          oReq.open("GET", URLToPDF, true);
          oReq.responseType = "blob";

          oReq.onload = function (dataInicial, dataFinal) {
            oReq.fileInfo = { filename: "asdsa" };
            const file = new Blob([oReq.response], { type: "application/txt" });
            const fileURL = URL.createObjectURL(file);

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = fileURL;

            var i = $("#dataInicial").val().split("-");

            var f = $("#dataFinal").val().split("-");
            a.download =
              "expcontabil_r_" +
              i[2] +
              "_" +
              i[1] +
              "_" +
              i[0] +
              "__" +
              f[2] +
              "_" +
              f[1] +
              "_" +
              f[0] +
              "__EMP301.txt";
            a.click();
          };
          oReq.send(this.state.dataInicial, this.state.dataFinal);
          this.setState({ gerando: false });
          this.setState({ gerandoRequisicoes: false });
        });
    } else {
      alert("Verifique as datas!");
    }
  };

  render() {
    return (
      <div>
        <Row>
          <Col sm={1}>
            <Form.Label column sm={2}>
              Período:
            </Form.Label>
          </Col>
          <Col sm={3}>
            <FormControl
              className="col-12"
              type="date"
              name="dataInicial"
              onChange={this.alteracao}
              defaultValue={this.state.dataInicial}
              id="dataInicial"
            />
          </Col>
          <Col sm={3}>
            <FormControl
              className="col-12"
              type="date"
              name="dataFinal"
              onChange={this.alteracao}
              value={this.state.dataFinal}
              id="dataFinal"
            />
          </Col>
          <Col sm={12}>
            <List>
              <ListItem
                button
                onClick={this.gerarArquivoContasAPagar}
                disabled={this.state.gerando}
              >
                <ListItemAvatar>
                  <FolderOpenTwoToneIcon color="primary" fontSize="large" />
                </ListItemAvatar>
                <ListItemText
                  primary="Contas a pagar"
                  secondary={
                    this.state.contasAPagar != null
                      ? "Última exportação: " +
                        moment(this.state.contasAPagar.data).format(
                          "DD/MM/yyyy"
                        ) +
                        " às " +
                        this.state.contasAPagar.hora
                      : "Última exportação:"
                  }
                />
                <IconButton edge="end" style={{ outline: "none" }}>
                  <CloudDownloadOutlinedIcon color="primary" fontSize="large" />
                </IconButton>
              </ListItem>
              <LinearProgress hidden={!this.state.gerandoContas} />
              <ListItem
                button
                onClick={this.gerarArquivoLancamentosConta}
                disabled={this.state.gerando}
              >
                <ListItemAvatar>
                  <FolderOpenTwoToneIcon color="primary" fontSize="large" />
                </ListItemAvatar>
                <ListItemText
                  primary="Liquidações"
                  secondary={
                    this.state.lancamentoConta != null
                      ? "Última exportação: " +
                        moment(this.state.lancamentoConta.data).format(
                          "DD/MM/yyyy"
                        ) +
                        " às " +
                        this.state.lancamentoConta.hora
                      : "Última exportação:"
                  }
                />
                <IconButton edge="end" style={{ outline: "none" }}>
                  <CloudDownloadOutlinedIcon color="primary" fontSize="large" />
                </IconButton>
              </ListItem>
              <LinearProgress hidden={!this.state.gerandoLancamentos} />
              <ListItem
                button
                disabled={this.state.gerando}
                onClick={this.gerarArquivoCaixa}
              >
                <ListItemAvatar>
                  <FolderOpenTwoToneIcon color="primary" fontSize="large" />
                </ListItemAvatar>
                <ListItemText
                  primary="Movimento caixas"
                  secondary={
                    this.state.caixa != null
                      ? "Última exportação: " +
                        moment(this.state.caixa.data).format("DD/MM/yyyy") +
                        " às " +
                        this.state.caixa.hora
                      : "Última exportação:"
                  }
                />
                <IconButton edge="end" style={{ outline: "none" }}>
                  <CloudDownloadOutlinedIcon color="primary" fontSize="large" />
                </IconButton>
              </ListItem>
              <LinearProgress hidden={!this.state.gerandoCaixas} />
              <ListItem
                button
                disabled={this.state.gerando}
                onClick={this.gerarArquivoRequisicao}
              >
                <ListItemAvatar>
                  <FolderOpenTwoToneIcon color="primary" fontSize="large" />
                </ListItemAvatar>
                <ListItemText
                  primary="Requisições"
                  secondary={
                    this.state.requisicao != null
                      ? "Última exportação: " +
                        moment(this.state.requisicao.data).format(
                          "DD/MM/yyyy"
                        ) +
                        " às " +
                        this.state.requisicao.hora
                      : "Última exportação:"
                  }
                />
                <IconButton edge="end" style={{ outline: "none" }}>
                  <CloudDownloadOutlinedIcon color="primary" fontSize="large" />
                </IconButton>
              </ListItem>
              <LinearProgress hidden={!this.state.gerandoRequisicoes} />
            </List>
          </Col>
        </Row>
        <Row hidden={this.state.inconsistencias === null}>
          <Divider horizontal>
            <Header as="h4">
              <Icon name="exclamation" />
              Inconsistências
            </Header>
          </Divider>
          <Col sm={12}>
            <TextareaAutosize
              aria-readonly
              value={this.state.inconsistencias}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <hr />
        <Row hidden>
          <Col sm={12}>
            <Progress
              percent={this.state.progresso}
              active
              style={{ height: "50%" }}
              // color="teal"
              indicating
            />
          </Col>
        </Row>
      </div>
    );
  }
}
