import React, { Component } from "react";
import { Menu } from "semantic-ui-react";
import Lista from "./lista/lista";
import Relatorio from "./relatorio/index";

export default class EntradaItemLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: "item",
    };
  }
  render() {
    let content = "";
    switch (this.state.selectedMenu) {
      case "item":
        localStorage.setItem("pagina_anterior", "item");
        content = <Lista />;
        break;

      default:
        localStorage.setItem("pagina_anterior", "entradaItem");
        content = <Relatorio />;
        break;
    }
    return (
      <div>
        <Menu pointing secondary>
          <Menu.Item
            name="item"
            active={this.state.selectedMenu === "item"}
            onClick={this.selectMenu.bind(null, "item")}
          >
            Lista
          </Menu.Item>
          <Menu.Item
            name="entradaItem"
            active={this.state.selectedMenu === "entradaItem"}
            onClick={this.selectMenu.bind(null, "entradaItem")}
          >
            Relatório
          </Menu.Item>
        </Menu>
        {content}
      </div>
    );
  }
  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
  };
}
