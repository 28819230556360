import React, { Component } from "react";
import { Header, Button, Segment, Table, Dropdown } from "semantic-ui-react";
import renomearChave from "../../../services/renomearChave";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import InputMask from "react-input-mask";

import ModalCriarParamentro from "./modalCriarParametro";
import ModalAdicionaParametro from "./modalAdicionaParametro";

export default class Catraca extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      descricao: null,
      ip: null,
      parametros: [],
      parametrosNaoSelecionados: [],
      usuarios: [],
      listaUsuarios: [],
      usuarioSelecionado: null,
      servicoSelect: null,
      servico: null,
      gruposServico: [],
      grupo_servico: null,
      grupo_servico_id: null,
      optionsServicoTurma: [],
    };
    this.alteracao = this.alteracao.bind(this);
    this.listaUsuarios();
    this.visualiza();
    this.listaGrupoServico();
    this.listaServicoTurma();
  }

  listaServicoTurma = async () => {
    const servicos = await api.post(
      "/servico/listaTurmasAtivas" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    let lista = [];
    for (let i = 0; i < servicos.data.length; i++) {
      const element = servicos.data[i];
      lista.push({ key: element.id, value: element.id, text: element.nome });
    }
    this.setState({ optionsServicoTurma: lista });
  };

  listaGrupoServico = async () => {
    api
      .post(
        "/grupoServico/lista" + requisicoes.entidade,
        {},
        requisicoes.header
      )
      .then((resposta) => {
        let lista = [];

        for (let i = 0; i < resposta.data.length; i++) {
          const element = resposta.data[i];
          lista.push({
            value: element.id,
            text: element.descricao,
            key: element.id,
          });
        }

        this.setState({ gruposServico: lista });
      });
  };

  visualiza = async () => {
    const todosParametros = await api.post(
      "/catraca/listaParametros" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    this.setState({
      todosParametros: todosParametros.data,
      parametros: todosParametros.data,
    });
    if (this.props.location.query !== undefined) {
      await api
        .post(
          "/catraca/visualiza2" + requisicoes.entidade,
          this.props.location.query,
          requisicoes.header
        )
        .then((resposta) => {
          //seta select
          if (resposta.data.catraca_parametros.length > 0) {
            const parametros = resposta.data.catraca_parametros;
            // const todosParametros = this.state.parametros;
            for (let i = 0; i < todosParametros.data.length; i++) {
              if (
                parametros.find(
                  (p) => parseInt(p.id) === parseInt(todosParametros.data[i].id)
                )
              ) {
                this.state.parametros[i].select = true;
              }
            }
          }
          //renomeia chave
          if (
            resposta.data.usuarios !== undefined &&
            resposta.data.usuarios.length > 0
          ) {
            for (let i2 = 0; i2 < resposta.data.usuarios.length; i2++) {
              resposta.data.usuarios[i2].text = resposta.data.usuarios[i2].nome;
              resposta.data.usuarios[i2].value = resposta.data.usuarios[i2].id;
            }
          }
          const servico = resposta.data.servico;
          let objeto = renomearChave(servico, "id", "value");
          objeto = renomearChave(objeto, "nome", "label");
          this.setState(resposta.data);
          this.setState({ servico: objeto });

          console.log("this.state.parametros: ", this.state.parametros);
        });
    }
  };

  alteracao(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  alteracaoUsuario(event) {
    this.setState({ usuarioSelecionado: event });
  }

  listaUsuarios = async () => {
    const resposta = await api.post(
      "/usuario/buscaPorTipoUsuario2" + requisicoes.entidade,
      {
        entidade: window.localStorage.getItem("e_clube"),
        tipo_usuario: ["funcionario", "caixa", "administrador", "portaria"],
      },
      requisicoes.header
    );
    for (let i = 0; i < resposta.data.length; i++) {
      resposta.data[i] = {
        text: resposta.data[i].nome,
        value: resposta.data[i].id,
      };
      if (i + 1 === resposta.data.length) {
        this.setState({ listaUsuarios: resposta.data });
      }
    }
  };

  adicionaUsuario = async () => {
    const usuario = this.state.listaUsuarios.find(
      (u) => parseInt(u.value) === parseInt(this.state.usuarioSelecionado)
    );
    if (usuario !== undefined) {
      if (
        this.state.usuarios.find(
          (user) =>
            parseInt(user.value) === parseInt(this.state.usuarioSelecionado)
        ) === undefined
      ) {
        const usuarios = this.state.usuarios;
        usuarios.push(usuario);
        this.setState(usuarios);
      }
    }
  };
  removeUsuario = async (value) => {
    const usuarios = this.state.usuarios.filter(
      (u) => parseInt(u.value) !== parseInt(value)
    );
    this.setState({ usuarios: usuarios });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.state.id === null) {
      api
        .post(
          "/catraca/salvar" + requisicoes.entidade,
          {
            ip: this.state.ip,
            descricao: this.state.descricao,
            usuarios: this.state.usuarios.map((e) => e.value),
            parametros: this.state.parametros.filter((e) => e.select),
          },
          requisicoes.header
        )
        .then(() => {
          window.location.href = "/catraca/lista";
        });
    } else {
      api
        .post(
          "/catraca/editar" + requisicoes.entidade,
          {
            id: this.state.id,
            ip: this.state.ip,
            descricao: this.state.descricao,
            usuarios: this.state.usuarios.map((e) => e.value),
            parametros: this.state.parametros.filter((e) => e.select),
          },
          requisicoes.header
        )
        .then(() => {
          window.location.href = "/catraca/lista";
        });
    }
  };

  excluir = async () => {
    api
      .post(
        "/catraca/excluir" + requisicoes.entidade,
        this.state,
        requisicoes.header
      )
      .then(() => {
        window.location.href = "/catraca/lista";
      });
  };

  criarParametro = async (
    catraca_id,
    descricao,
    dias,
    servico_id,
    grupo_servico_id,
    labelAux
  ) => {
    console.log(
      catraca_id,
      descricao,
      dias,
      servico_id,
      grupo_servico_id,
      labelAux
    );
    const parametro = await api.post(
      "/catraca/criaParametro" + requisicoes.entidade,
      {
        catraca_id,
        descricao,
        dias,
        servico_id,
        grupo_servico_id,
      },
      requisicoes.header
    );

    let lista = this.state.parametros;
    if (servico_id) {
      parametro.data.servico = {
        id: servico_id,
        nome: labelAux,
      };
    }
    if (grupo_servico_id) {
      parametro.data.grupo_servico = {
        id: grupo_servico_id,
        descricao: labelAux,
      };
    }
    parametro.data.select = true;
    lista.push(parametro.data);
    this.setState({ parametros: lista });
  };

  adicionaParametro = async () => {
    let lista = this.state.parametros;
    let listaIdAdicioanar = [];

    for (let i = 0; i < lista.length; i++) {
      const element = lista[i];
      if (element.checked) {
        listaIdAdicioanar.push(element.id);
        element.checked = undefined;
        element.select = true;
      }
    }
    this.setState({ parametros: lista });
  };

  removerParametro = async (i) => {
    let lista = this.state.parametros;
    lista[i].select = false;
    this.setState({ parametros: lista });
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Catraca
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={6}>
              <Form.Label>Descrição</Form.Label>
              <FormControl
                aria-describedby="basic-addon1"
                name="descricao"
                onChange={this.alteracao}
                defaultValue={this.state.descricao}
                required
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Ip</Form.Label>
              <InputMask
                className="form-control"
                mask="999.999.999.999"
                name="ip"
                onChange={this.alteracao}
                value={this.state.ip}
                required
              />
            </Col>
          </Row>
          <Header as="h3" dividing>
            Parâmetros
          </Header>
          <Row>
            <Col sm={12}>
              <Table compact celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Descrição</Table.HeaderCell>
                    <Table.HeaderCell>Dias bloqueio</Table.HeaderCell>
                    <Table.HeaderCell>Servico</Table.HeaderCell>
                    <Table.HeaderCell>Grupo Servico</Table.HeaderCell>
                    <Table.HeaderCell collapsing></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.parametros.map((p, i) =>
                    p.select === true ? (
                      <Table.Row>
                        <Table.Cell>{" " + p.descricao}</Table.Cell>
                        <Table.Cell>{p.dias}</Table.Cell>
                        <Table.Cell>
                          {p.servico ? p.servico.nome : " - "}
                        </Table.Cell>
                        <Table.Cell>
                          {p.grupo_servico ? p.grupo_servico.descricao : " - "}
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            onClick={() => this.removerParametro(i)}
                            negative
                            size="tiny"
                            type="button"
                            icon="trash alternate outline"
                          />
                        </Table.Cell>
                      </Table.Row>
                    ) : null
                  )}
                </Table.Body>
                <Table.Footer fullWidth>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell colSpan="4">
                      {/* <Button
                        type="button"
                        floated="right"
                        icon
                        labelPosition="left"
                        primary
                        size="small"
                      >
                        <Icon name="plus" /> Adicionar
                      </Button> */}

                      <ModalCriarParamentro
                        options_grupo_servico={this.state.gruposServico}
                        criarParametro={this.criarParametro}
                        catraca_id={this.state.id}
                        options_servico_turma={this.state.optionsServicoTurma}
                      />
                      <ModalAdicionaParametro
                        parametros={this.state.parametros}
                        adicionar={this.adicionaParametro}
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Col>
          </Row>

          <Header as="h3" dividing>
            Usuários
          </Header>
          <Row>
            <Col sm={12}>
              <Row style={{ marginLeft: "0", marginBottom: "15px" }}>
                <Dropdown
                  search
                  selection
                  options={this.state.listaUsuarios}
                  style={{ width: "400px" }}
                  onChange={(e, { name, value }) =>
                    this.alteracaoUsuario(value)
                  }
                />
                <Col sm={2}>
                  <Button
                    type="button"
                    positive
                    icon="add"
                    size="tiny"
                    onClick={this.adicionaUsuario}
                  ></Button>
                </Col>
              </Row>
            </Col>
            <Col sm={12}>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Nome</Table.HeaderCell>
                    <Table.HeaderCell collapsing></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.usuarios.map((e, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{e.text}</Table.Cell>
                      <Table.Cell textAlign="center">
                        <Button
                          negative
                          size="tiny"
                          type="button"
                          onClick={() => this.removeUsuario(e.value)}
                          icon="trash alternate outline"
                        ></Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Col>
          </Row>
          <Segment clearing>
            <Header floated="right">
              <Link to="/catraca/lista">
                <Button type="button">Voltar</Button>
              </Link>
              {this.state.id !== null && (
                <Button negative type="button" onClick={this.excluir}>
                  Deletar
                </Button>
              )}
              <Button positive type="submit">
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}
