import React, { Component } from "react";
import { Col, Form, FormControl, Alert, Row } from "react-bootstrap";
import ValidadorCamposPessoa from "../../../../utils/validadorCamposPessoa";
import InputMask from "react-input-mask";

export default class VisualizaPessoa extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Col
        sm={12}
        style={{ paddingTop: "10px", paddingLeft: "0", paddingRight: "0" }}
      >
        {ValidadorCamposPessoa(this.props.pessoa).map((e) => {
          let validado = false;
          return Object.entries(e).map((k, i) => {
            if (validado === true && k[0] === "mensagem") {
              return <Alert variant="danger">{k[1]}</Alert>;
            }
            if (k[1] === false) {
              validado = true;
            }
          });
        })}
        <Row>
          <Col sm={6}>
            <Form.Label>Nome</Form.Label>
            <FormControl
              aria-describedby="basic-addon1"
              maxLength={40}
              onChange={this.props.alteracaoPessoa}
              name="nome"
              value={this.props.pessoa.nome}
            />
          </Col>
          <Col sm={6}>
            <Form.Label>Tipo</Form.Label>
            <Form.Control
              as="select"
              name="tipo"
              onChange={this.props.alteracaoPessoa}
              required
              value={this.props.pessoa !== null && this.props.pessoa.tipo !== null && this.props.pessoa.tipo}
                  >
                <option></option>
                <option value="fisico">Físico</option>
                <option value="juridico">Jurídico</option>
            </Form.Control>
          </Col>
          <Col sm={6} hidden={this.props.pessoa.cnpj !== null}>
            <Form.Label>CPF</Form.Label>
            <InputMask
              mask="999.999.999-99"
              className="form-control"
              placeholder="CPF"
              id="cpf"
              name="cpf"
              onChange={this.props.alteracaoPessoa}
              value={this.props.pessoa.cpf}
            />
          </Col>
          <Col sm={6} hidden={this.props.pessoa.cpf !== null}>
            <Form.Label>CNPJ</Form.Label>
            <InputMask
              mask="99.999.999/9999-99"
              className="form-control"
              placeholder="CNPJ"
              id="cnpj"
              name="cnpj"
              onChange={this.props.alteracaoPessoa}
              value={this.props.pessoa.cnpj}
            />
          </Col>
          <Col sm={6}>
            <Form.Label>Endereço</Form.Label>
            <FormControl
              placeholder="Endereço"
              aria-label="Endereco"
              aria-describedby="basic-addon1"
              name="endereco"
              maxLength={40}
              onChange={this.props.alteracaoPessoa}
              value={this.props.pessoa.endereco}
            />
          </Col>
          <Col sm={6}>
            <Form.Label>Bairro</Form.Label>
            <FormControl
              placeholder="Bairro"
              aria-label="Bairro"
              aria-describedby="basic-addon1"
              name="bairro"
              maxLength={12}
              onChange={this.props.alteracaoPessoa}
              value={this.props.pessoa.bairro}
            />
          </Col>
          <Col sm={3}>
            <Form.Label>Número</Form.Label>
            <FormControl
              placeholder="Numero"
              aria-label="Numero"
              aria-describedby="basic-addon1"
              name="numero"
              onChange={this.props.alteracaoPessoa}
              value={this.props.pessoa.numero}
            />
          </Col>
          <Col sm={3}>
            <Form.Label>Dependente à cobrar: </Form.Label>
            <FormControl
              placeholder="dependente_cobrar"
              aria-label="Dependente cobrar"
              aria-describedby="basic-addon1"
              name="dependente_cobrar"
              onChange={this.props.alteracaoPessoa}
              value={this.props.pessoa.dependente_cobrar}
            />
          </Col>
        </Row>
        {
          this.props.tipo_titulo_descricao === "PROJETO" &&
          <Row>
            <Col sm={6}>
              <Form.Label>Responsável 1</Form.Label>
              <FormControl
                placeholder="Responsável 1"
                aria-describedby="basic-addon1"
                name="responsavel1"
                onChange={this.props.alteracaoPessoa}
                value={this.props.pessoa.responsavel1}
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Responsável 2</Form.Label>
              <FormControl
                placeholder="Responsável 2"
                aria-describedby="basic-addon1"
                name="responsavel2"
                onChange={this.props.alteracaoPessoa}
                value={this.props.pessoa.responsavel2}
              />
            </Col>
          </Row>
        }
      </Col>
    );
  }
}
