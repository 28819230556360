import React, { Component } from "react";
import { Button, Input, Dropdown, Modal, TextArea } from "semantic-ui-react";
import { Col, Row, Form, FormControl } from "react-bootstrap";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import lingua from "@fullcalendar/core/locales/pt-br";
import moment from "moment";
import calendario from "../../../../services/apiCalendario";

export default class FeriadoLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      pagina: 1,
      totalPaginas: 1,
      tipoFeriado: null,
      ano: moment().add(1, "years").format("YYYY"),
      tiposFeriado: [
        { key: "todos", value: "todos", text: "Todos" },
        {
          key: "Feriado Nacional",
          value: "Feriado Nacional",
          text: "Feriado Nacional",
        },
        {
          key: "Feriado Municipal",
          value: "Feriado Municipal",
          text: "Feriado Municipal",
        },
        { key: "Facultativo", value: "Facultativo", text: "Ponto Facultativo" },
        {
          key: "Dia Convencional",
          value: "Dia Convencional",
          text: "Dia Convencional",
        },
        { key: "diversos", value: "diversos", text: "Eventos Diversos" },
      ],
      feriado: null,
      id: null,
      calendarEvents: [],
      calendarWeekends: [],
    };
    this.lista();
    this.alteracao = this.alteracao.bind(this);
  }

  alteracao(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  alteracaoSelectTipo = async (event, id) => {
    this.setState({ [id.name]: id.value });
  };

  lista = async (page = 1) => {
    const resposta = await api.post(
      "/feriado/lista" + requisicoes.entidade, //+ "&pagina=" + page,
      "",
      requisicoes.header
    );
    // const { feriados, pagina, totalPaginas } = resposta.data;
    // this.setState({ lista: feriados, pagina, totalPaginas });

    console.log("feriados: ", this.setaFeriados(resposta.data));
    this.setState({ calendarEvents: this.setaFeriados(resposta.data) });
  };

  setaFeriados = (feriados) => {
    let lista = [];
    feriados.forEach((element) => {
      let cor = "";
      switch (element.tipo) {
        case "Feriado Nacional":
          cor = "red";
          break;
        case "Feriado Municipal":
          cor = "blue";
          break;
        case "Facultativo":
          cor = "#ffa137";
          break;
        case "Dia Convencional":
          cor = "purple";
          break;
        default:
          break;
      }
      lista.push({
        id: element.id,
        title: element.nome,
        start: element.data,
        backgroundColor: cor,
        borderColor: cor,
      });
    });
    return lista;
  };

  pesquisaFeriado = (newValue) => {
    api
      .post(
        "/feriado/pesquisa" + requisicoes.entidade,
        {
          pesquisa: newValue,
          entidade: window.localStorage.getItem("e_clube"),
        },
        requisicoes.header
      )
      .then((resp) => {
        this.setState({ lista: resp.data });
      });
  };

  pesquisaPorTipoFeriado = async (event, id) => {
    this.setState({ [id.name]: id.value });
    setTimeout(async () => {
      const retorno = await api.post(
        "/feriado/listaPorTipo" + requisicoes.entidade,
        {
          pesquisa: id.value,
        },
        requisicoes.header
      );
      this.setState({ calendarEvents: this.setaFeriados(retorno.data) });
    }, 500);
  };

  proximaPagina = async () => {
    const { pagina } = this.state;
    const paginaNumero = parseInt(pagina) + 1;
    this.lista(paginaNumero);
  };

  voltarPagina = async () => {
    const { pagina } = this.state;
    const paginaNumero = pagina - 1;
    this.lista(paginaNumero);
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleShow = () => {
    this.setState({ open: true });
  };

  handleSubmit = async () => {
    const entidade = await api.post(
      "/entidade/buscaEntidadeUsuario" + requisicoes.entidade,
      { pesquisa: localStorage.getItem("e_clube") },
      requisicoes.header
    );
    const retorno = await calendario.get(
      "&ano=" +
      this.state.ano +
      "&estado=" +
      entidade.data.uf +
      "&cidade=" +
      entidade.data.cidade.trim()
    );
    await api.post(
      "/feriado/salvarVarios" + requisicoes.entidade,
      {
        feriados: retorno.data,
      },
      requisicoes.header
    );
    window.location.href = "/servicos";
  };

  handleDateClick = (arg) => {
    // TODO: Entender essa merda e arrumar quando eu tiver mais disposto
    api
      .post(
        "/feriado/visualizaPorData" + requisicoes.entidade,
        { data: arg.dateStr },
        requisicoes.header
      )
      .then((resp) => {
        if (resp.data !== "") {
          this.setState({ feriado: resp.data });
          this.setState({ id: resp.data.id });
        }
        this.handleShowFeriado();
      });
  };

  handleShowFeriado = async () => {
    this.setState({ feriadoOpen: true });
  };

  handleCloseFeriado = async () => {
    this.setState({ feriadoOpen: false });
    this.setState({ feriado: null });
  };

  handleSubmitFeriado = async () => {
    const c = this.state;
    if (c.id) {
      await api.post(
        "/feriado/alterar" + requisicoes.entidade,
        {
          nome: c.nome,
          descricao: c.descricao,
          data: c.data,
          tipo: c.tipo,
          id: c.id,
        },
        requisicoes.header
      );
    } else {
      await api.post(
        "/feriado/salvar" + requisicoes.entidade,
        { nome: c.nome, descricao: c.descricao, data: c.data, tipo: c.tipo, ativo_inativo: true },
        requisicoes.header
      );
    }
    window.location.href = "/servicos";
  };

  render() {
    return (
      <div className="lista" style={{ paddingBottom: 100 }}>
        <Row>
          <Col sm={6}>
            <Dropdown
              name="tipo"
              id="tipo"
              fluid
              selection
              search
              onChange={this.pesquisaPorTipoFeriado}
              options={this.state.tiposFeriado}
              defaultValue="todos"
            />
          </Col>
          <Col sm={6}>
            <Button onClick={this.handleShow} color="teal">
              Importar Feriados
            </Button>
          </Col>
        </Row>
        <Modal
          size="tiny"
          open={this.state.open}
          onClose={this.handleClose}
          // style={{height: "10%"}}
          style={{ overflow: "visible" }}
        >
          <Modal.Header>Importação Feriados</Modal.Header>
          <Modal.Content>
            <Row>
              <Col sm={6}>
                <Dropdown
                  name="tipo"
                  id="tipo"
                  fluid
                  selection
                  search
                  onChange={this.alteracaoSelectTipo}
                  options={this.state.tiposFeriado}
                  defaultValue="todos"
                />
              </Col>
              <Col sm={6}>
                <Input
                  label="Ano: "
                  name="ano"
                  onChange={this.alteracao}
                  value={this.state.ano}
                  required
                />
              </Col>
            </Row>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={this.handleClose}>
              Cancelar
            </Button>
            <Button positive onClick={this.handleSubmit}>
              Salvar
            </Button>
          </Modal.Actions>
        </Modal>
        <FullCalendar
          locale={lingua}
          defaultView="dayGridMonth"
          header={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
          }}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          ref={this.calendarComponentRef}
          weekends={this.state.calendarWeekends}
          events={this.state.calendarEvents}
          dateClick={this.handleDateClick}
          eventClick={this.remove}
          displayEventTime={false}
        />
        <Modal
          size="small"
          open={this.state.feriadoOpen}
          onClose={this.handleClose}
          style={{ overflow: "visible" }}
        >
          <Modal.Header>
            {this.state.feriado != null && this.state.feriado.nome}
            {this.state.feriado == null && "Novo feriado"}
          </Modal.Header>
          <Modal.Content>
            <Row>
              {this.state.feriado == null && (
                <Col sm={4}>
                  <Form.Label>Nome</Form.Label>
                  <FormControl name="nome" onChange={this.alteracao} required />
                </Col>
              )}
              <Col sm={4}>
                <Form.Label>Data</Form.Label>
                <FormControl
                  name="data"
                  type="date"
                  onChange={this.alteracao}
                  defaultValue={
                    this.state.feriado != null && this.state.feriado.data
                  }
                  required
                />
              </Col>
              <Col sm={4}>
                <Form.Label>Tipo</Form.Label>
                <Dropdown
                  name="tipo"
                  id="tipo"
                  fluid
                  selection
                  search
                  onChange={this.alteracaoSelectTipo}
                  options={this.state.tiposFeriado}
                  defaultValue={
                    this.state.feriado != null && this.state.feriado.tipo
                  }
                />
              </Col>
              <Col sm={12}>
                <Form.Label>Descrição</Form.Label>
                <TextArea
                  name="descricao"
                  onChange={this.alteracao}
                  defaultValue={
                    this.state.feriado != null && this.state.feriado.descricao
                  }
                  required
                  fluid
                  rows={4}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={this.handleCloseFeriado}>
              Cancelar
            </Button>
            <Button positive onClick={this.handleSubmitFeriado}>
              Salvar
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
