import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";

// Utils
import moment from "moment-timezone";

export default class InformacaoLancamento extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Row>
          <Col>
            <span style={{ fontWeight: "bold" }}>{"Geração: "}</span>
            {this.props.lancamento.historicos
              ? this.props.lancamento.historicos.find(
                  (e) => e.tipo === "criacao"
                )
                ? "Criado em " +
                  moment(
                    this.props.lancamento.historicos.find(
                      (e) => e.tipo === "criacao"
                    ).data
                  ).format("DD/MM/YYYY") +
                  " - " +
                  (this.props.listaUsuarios
                    ? this.props.listaUsuarios.find(
                        (e) =>
                          e.id ===
                          this.props.lancamento.historicos.find(
                            (e) => e.tipo === "criacao"
                          ).usuario_id
                      )?.nome
                    : null)
                : "Não possui histórico de criação"
              : "Não possui histórico"}
          </Col>
        </Row>
        <Row>
          <Col>
            {this.props.lancamento.numero_titulo !== null ? (
              <div>
                <span style={{ fontWeight: "bold" }}>{"Nosso número: "}</span>
                {this.props.lancamento.numero_titulo}
              </div>
            ) : (
              <div>
                <span style={{ fontWeight: "bold" }}>Nosso número: </span>
                {"nosso número não foi gerado"}
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {this.props.lancamento.data_pagamento !== null ? (
              <div>
                <span style={{ fontWeight: "bold" }}>{"Quem cobrou: "}</span>
                {this.props.lancamento?.caixas !== undefined 
                && this.props.lancamento?.caixas !== null 
                && this.props.lancamento?.caixas?.length > 0
                ? this.props.lancamento?.caixas[0]?.usuario?.nome
                : 'Não informado'}
              </div>
            ) : (
              <div>
                <span style={{ fontWeight: "bold" }}>Quem cobrou: </span>
                {"dívida ainda não foi paga."}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }
}
