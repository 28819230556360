import React, { Component } from "react";
import {
  Container,
  Header,
  Button,
  Divider,
  Segment,
  Table,
  Checkbox,
  Modal,
  Icon,
} from "semantic-ui-react";
import { Row, Col, FormControl, Form, InputGroup } from "react-bootstrap";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import requisicoes from "../../../utils/Requisicoes";

export default class GrupoServico extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descGrupo: null,
      descServ: null,
      qtdServ: null,
      dataInicio: "",
      dataFinal: "",
      qtdHorarios: "",
      listaHorarios: [],
      valor: "",
      diasFiltro: [],
      diasEspeciais: [],
      diasDaSemana: [],
      selectDiasDaSemana: [],
      gruposServico: [],
      grupo_servico: null,
      modalOpen: false,
    };
    this.listaGrupoServico();
    this.alteracao = this.alteracao.bind(this);
    this.buscaDiasDaSemana = this.buscaDiasDaSemana.bind(this);
    this.buscaDiasDaSemana();
  }

  alteracao(event) {
    let dataInicial = new Date(this.state.dataInicio);

    if (event.target.name === "diasFiltro") {
      this.state.diasDaSemana.forEach((e, i) => {
        if (e.id === parseInt(event.target.value)) {
          let lista = this.state.selectDiasDaSemana;
          if (lista[i] === true) {
            lista[i] = false;
          } else {
            lista[i] = true;
          }
          this.setState({ selectDiasDaSemana: lista });
          if (
            !this.state.diasFiltro.find(
              (e) => e.id === parseInt(event.target.value)
            )
          ) {
            this.state.diasFiltro.push({
              id: event.target.value,
              nome: e.nome,
            });
          } else {
            let indice = this.state.diasFiltro.findIndex(
              (element) => element.id === parseInt(event.target.value)
            );
            this.state.diasFiltro.splice(indice, 1);
            const diaEspecial = this.state.diasEspeciais.find(
              (e) =>
                new Date(e.data).getFullYear() === dataInicial.getFullYear() &&
                new Date(e.data).getMonth() === dataInicial.getMonth() &&
                new Date(e.data).getDate() === dataInicial.getDate()
            );
            if (typeof diaEspecial != "undefined") {
              if (diaEspecial.temEvento === true) {
                indice++;
                lista.push({
                  id: indice,
                  title: this.state.nome,
                  start: new Date(dataInicial),
                });
              } else if (diaEspecial.temEvento === false) {
                const ind = indice;
                let i = lista.findIndex(
                  (element) => element.id === parseInt(ind)
                );
                lista.splice(i, 1);
              }
            }
          }
        }
      });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  alteracaoHorarioInicio = async (value, index) => {
    let listaHorarios = this.state.listaHorarios;
    listaHorarios[index].horaInicio = value;
    this.setState({ listaHorarios: listaHorarios });
  };

  alteracaoHorarioFim = async (value, index) => {
    let listaHorarios = this.state.listaHorarios;
    listaHorarios[index].horaFim = value;
    this.setState({ listaHorarios: listaHorarios });
  };

  alteracaoVaga = async (value, index) => {
    let listaHorarios = this.state.listaHorarios;
    listaHorarios[index].vaga = value;
    this.setState({ listaHorarios: listaHorarios });
  };

  handleSubmit = (event) => {
    this.submit();
    event.preventDefault();
  };
  excluir = async () => {
    let lista = this.state.listaHorarios;
    lista.splice(lista.indexOf(1), 1);
    this.setState({ listaHorarios: lista });
  };

  adicionar = async () => {
    let lista = this.state.listaHorarios;
    lista.push({
      horaInicio: "",
      horaFim: "",
      vaga: "",
    });
    this.setState({ listaHorarios: lista });
  };

  submit = async () => {
    const dados = {
      grupo_servico_id: this.state.grupo_servico,
      descServ: this.state.descServ,
      qtdServ: this.state.qtdServ,
      dataInicio: this.state.dataInicio,
      dataFinal: this.state.dataFinal,
      qtdHorarios: this.state.qtdHorarios,
      listaHorarios: this.state.listaHorarios,
      valor: this.state.valor,
      diasDaSemana: this.state.diasFiltro,
      datasEspeciais: this.state.diasEspeciais,
    };
    const retorno = await api.post(
      "/grupoServico/salvar" + requisicoes.entidade,
      dados,
      requisicoes.header
    );
    if (retorno != null) {
      window.location.href = "/servicos/lista";
    }
  };

  criaHorarios = (event) => {
    var quantidade = this.state.qtdHorarios;
    let lista = [];
    for (let index = 0; index < quantidade; index++) {
      lista.push({
        horaInicio: "",
        horaFim: "",
        vaga: "",
      });
    }
    this.setState({ listaHorarios: lista });
  };

  buscaDiasDaSemana = async () => {
    const resposta = await api.post(
      "/diaDaSemanan/lista" + requisicoes.entidade,
      this.props.location.query,
      requisicoes.header
    );
    for (var i = 0; i < resposta.data.lenght; i++) {
      this.state.selectDiasDaSemana.push(false);
    }
    this.setState({ diasDaSemana: resposta.data });
  };

  listaGrupoServico = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var e = localStorage.getItem("e_clube");
    const resposta = await api.post(
      "/grupoServico/lista?e=" + e,
      this.props.location.query,
      { headers: { token: "Baer " + token } }
    );
    this.setState({ gruposServico: resposta.data });
  };

  salvaGrupo = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const grupo = await api.post(
      "/grupoServico/salvarDescricao?e=" + entidade,
      {
        descGrupo: this.state.descGrupo,
      },
      { headers: { token: "Baer " + token } }
    );
    this.listaGrupoServico();
    this.setState({ grupo_servico: grupo.data.id });
    this.setState({ modalOpen: false });
  };

  abreModal = async (e) => {
    this.setState({ modalOpen: e });
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Grupo Serviço
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={5} style={{ paddingRight: 0 }}>
              <Form.Label>Grupo De Serviço</Form.Label>
              <Form.Control
                as="select"
                name="grupo_servico"
                checked={this.state.grupo_servico}
                onChange={this.alteracao}
                defaultValue={this.state.grupo_servico}
                required
              >
                <option></option>
                {this.state.gruposServico.map((e, i) =>
                  this.state.grupo_servico !== null &&
                  e.id === this.state.grupo_servico ? (
                    <option key={i} value={e.id} selected>
                      {e.descricao}
                    </option>
                  ) : (
                    <option key={i} value={e.id}>
                      {e.descricao}
                    </option>
                  )
                )}
              </Form.Control>
            </Col>

            <Col
              sm={1}
              style={{
                alignItems: "center",
                justifyContent: "center",
                textAlign: "right",
              }}
            >
              {/* caracter vazio abaixo */}
              <Form.Label>⠀⠀⠀⠀⠀⠀⠀⠀</Form.Label>
              <Modal
                open={this.state.modalOpen}
                dimmer="blurring"
                size="small"
                trigger={
                  <Button
                    type="button"
                    size="tiny"
                    positive
                    icon="add"
                    onClick={(e) => this.abreModal(true)}
                  ></Button>
                }
              >
                <Header icon>
                  <Icon name="add circle" />
                  Grupo Serviço
                </Header>
                <Modal.Content>
                  <Form.Label>Descrição Grupo</Form.Label>
                  <FormControl
                    name="descGrupo"
                    fluid
                    onChange={this.alteracao}
                  />
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    color="red"
                    inverted
                    onClick={() => this.abreModal(false)}
                  >
                    <Icon name="remove" /> Fechar
                  </Button>
                  <Button color="green" inverted onClick={this.salvaGrupo}>
                    <Icon name="checkmark" /> Sim
                  </Button>
                </Modal.Actions>
              </Modal>
            </Col>
            <Col sm={6}>
              <Form.Label>Descrição Serviço</Form.Label>
              <FormControl
                placeholder="Descrição do Serviço"
                name="descServ"
                onChange={this.alteracao}
                required
                defaultValue={this.state.descServ}
                fluid
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Dia de Inicio</Form.Label>
              <FormControl
                type="date"
                aria-describedby="basic-addon1"
                name="dataInicio"
                onChange={this.alteracao}
                required
                value={this.state.dataInicio.toString().substr(0, 10)}
              />
            </Col>

            <Col sm={6}>
              <Form.Label>Dia do Fim</Form.Label>
              <FormControl
                type="date"
                aria-describedby="basic-addon1"
                name="dataFinal"
                onChange={this.alteracao}
                required
                value={this.state.dataFinal.toString().substr(0, 10)}
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Valor</Form.Label>
              <FormControl
                required
                placeholder="Valor"
                aria-label="Valor"
                aria-describedby="basic-addon1"
                name="valor"
                value={this.state.valor}
                onChange={this.alteracao}
                type="number"
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Numero de Serviços</Form.Label>
              <FormControl
                placeholder="Numero de Serviços"
                name="qtdServ"
                onChange={this.alteracao}
                required
                defaultValue={this.state.qtdServ}
                fluid
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Quantidade de Horarios</Form.Label>
              <InputGroup>
                <FormControl
                  placeholder="Quantidade de Horarios"
                  name="qtdHorarios"
                  onChange={this.alteracao}
                  required
                  defaultValue={this.state.qtdHorarios}
                  fluid
                />
                {this.state.qtdHorarios !== "" ? (
                  <InputGroup.Append>
                    <Button
                      primary
                      size="small"
                      icon="calendar plus"
                      onClick={this.criaHorarios}
                    />
                  </InputGroup.Append>
                ) : (
                  ""
                )}
              </InputGroup>
            </Col>
          </Row>
          <Divider />

          <div id="definicaoHorario">
            <Header as="h4">Dias da Semana</Header>
            <Container>
              <Row>
                {this.state.diasDaSemana.map((e, i) => {
                  return (
                    <Checkbox
                      id={i}
                      className="checkboxInline"
                      label={e.nome}
                      value={e.id}
                      name="diasFiltro"
                      onChange={this.alteracao}
                      checked={this.state.selectDiasDaSemana[i]}
                    />
                  );
                })}
              </Row>
            </Container>
          </div>
          <Divider />
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Hora Inicio</Table.HeaderCell>
                <Table.HeaderCell>Hora Fim</Table.HeaderCell>
                <Table.HeaderCell>Vagas</Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  <Button
                    type="button"
                    positive
                    size="tiny"
                    onClick={this.adicionar}
                    icon="add"
                  ></Button>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.listaHorarios.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell>
                    <FormControl
                      type="time"
                      placeholder="Horario Inicio"
                      aria-label="Horario Inicio"
                      aria-describedby="basic-addon1"
                      name="horaInicio"
                      required
                      onChange={(e) =>
                        this.alteracaoHorarioInicio(e.target.value, i)
                      }
                      fluid
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <FormControl
                      type="time"
                      placeholder="Horario Fim"
                      aria-label="Horario Fim"
                      aria-describedby="basic-addon1"
                      name="horaFim"
                      required
                      onChange={(e) =>
                        this.alteracaoHorarioFim(e.target.value, i)
                      }
                      fluid
                    />
                  </Table.Cell>
                  <Table.Cell width={2}>
                    <FormControl
                      name="vaga"
                      onChange={(e) => this.alteracaoVaga(e.target.value, i)}
                      fluid
                    />
                  </Table.Cell>
                  <Table.Cell width={1}>
                    <Button
                      type="button"
                      negative
                      size="tiny"
                      value={i}
                      onClick={(e) => this.excluir(e.target.value)}
                      icon="remove"
                    ></Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer></Table.Footer>
          </Table>
          <Divider />

          <Segment clearing>
            <Header floated="right">
              <Link to="/servicos">
                <Button type="button">Voltar</Button>
              </Link>
              <Button positive type="submit">
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}
