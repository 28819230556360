import React, { Component } from "react";
import { Header, Modal, Button, Icon } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";

export default class ModalAvisoExportacaoGerada extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
        centered={true}
        size="tiny"
      >
        <Header content="Exportação contábil já gerada" />
        <Modal.Content>
          <Row>
            <Col md="auto">
              <Icon name="exclamation" size="massive" />
            </Col>
            <Col>
              <div style={{ fontSize: 15 }}>
                Já foi gerada uma exportação contábil para esse intervalo de
                datas.
                <br />
                <br />
                Favor selecionar uma nova data de emissão para a conta a pagar.
              </div>
            </Col>
          </Row>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={this.props.onClose}>
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
