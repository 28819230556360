import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import TextField from "@material-ui/core/TextField";
import SendIcon from "@material-ui/icons/Send";
import IconButton from "@material-ui/core/IconButton";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import CloseIcon from "@material-ui/icons/Close";

export default class modalConfigEtapas extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal size="tiny" dimmer="bluring" open={this.props.modal}>
        <Modal.Header>
          {"Quantidade de pedanas na " + this.props.numEtapa + "º etapa "}

          <IconButton
            className="bottomModal"
            onClick={() => this.props.changeModal(false, 0, 0)}
            color="secondary"
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Content>
          {this.props.alerta === true ? (
            <div className="flexSpaceAround">
              <div>
                <TextField
                  id="quantidade_pedanas"
                  name="quantidade_pedanas"
                  label="Quantidade de Pedanas"
                  onChange={this.props.alteracao}
                  required
                />
              </div>
              <div>
                <TextField
                  style={{ outline: "none", marginTop: "17px" }}
                  type="date"
                  id="data_inicio_alterada"
                  name="data_inicio_alterada"
                  value={
                    this.props.data_inicio_alterada !== null
                      ? this.props.data_inicio_alterada
                      : this.props.dataInicioEtapa
                  }
                  onChange={this.props.alteracao}
                />
              </div>
            </div>
          ) : (
            <Alert severity="success" hidden={this.props.alerta}>
              <AlertTitle>Pedanas Salva!</AlertTitle>
              Iremos redirecionar-lhe — <strong>Aguarde!</strong>
            </Alert>
          )}
        </Modal.Content>
        <Modal.Actions>
          <IconButton
            style={{ outline: "none" }}
            color="primary"
            aria-label="add to shopping cart"
            type="button"
            onClick={this.props.salvarNumEtapa}
            disabled={
              (this.props.data_inicio_alterada === null &&
                this.props.dataInicioEtapa === null) ||
              this.props.quantidade_pedanas === 0 ||
              this.props.quantidade_pedanas === []
            }
          >
            <SendIcon />
          </IconButton>
        </Modal.Actions>
      </Modal>
    );
  }
}
