// Componentes
import React, { Component } from "react";
import { Menu } from "semantic-ui-react";

// Componentes Projeto
import Cobranca from "./cobranca/index";
import Optantes from "./optantes/index";

// Libs
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

export default class Remessa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuSelect: "cobranca",
      instituicoes_debito_em_conta: [],
      instituicoes_boleto: [],
    };
    this.listaInstituicoes();
  }

  listaInstituicoes = async () => {
    const retorno = await api.post(
      "/instituicaoCobranca/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    let instituicoes_debito_em_conta = [];
    let instituicoes_boleto = [];

    for (let i = 0; i < retorno.data.length; i++) {
      const element = retorno.data[i];
      let option = {
        key: element.id,
        value: element.id,
        text:
          "C.C. " +
          element.conta +
          "-" +
          element.digito_conta +
          " AG " +
          element.agencia +
          "-" +
          element.digito_agencia +
          " " +
          element.banco.nome,
        registro_automatico: element.registro_automatico,
      };
      if (element.debito_em_conta) {
        instituicoes_debito_em_conta.push(option);
      }
      if (element.boleto) {
        instituicoes_boleto.push(option);
      }
    }
    this.setState({ instituicoes_debito_em_conta, instituicoes_boleto });
  };

  changeMenu = (menu) => {
    this.setState({ menuSelect: menu });
  };

  render() {
    let content = null;
    switch (this.state.menuSelect) {
      case "cobranca":
        content = (
          <Cobranca
            instituicoes_debito_em_conta={
              this.state.instituicoes_debito_em_conta
            }
            instituicoes_boleto={this.state.instituicoes_boleto}
          />
        );
        break;
      case "optantes":
        content = (
          <Optantes
            instituicoes_debito_em_conta={
              this.state.instituicoes_debito_em_conta
            }
          />
        );
        break;
      default:
        this.setState({ menuSelect: "cobranca" });
        content = (
          <Cobranca
            instituicoes_debito_em_conta={
              this.state.instituicoes_debito_em_conta
            }
            instituicoes_boleto={this.state.instituicoes_boleto}
          />
        );

        break;
    }
    return (
      <div>
        <Menu pointing secondary>
          <Menu.Item
            active={this.state.menuSelect === "cobranca"}
            onClick={() => this.changeMenu("cobranca")}
          >
            Cobranças
          </Menu.Item>
          <Menu.Item
            name="Novos Optantes"
            active={this.state.menuSelect === "optantes"}
            onClick={() => this.changeMenu("optantes")}
          />
        </Menu>
        {content}
      </div>
    );
  }
}
