import React, { Component } from "react";
import { Form, Table } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";

// Libs
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

import fileDownload from "js-file-download";
import moment from "moment";

export default class Cobranca extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instituicao_cobranca_id: null,
      novosOptantes: [],

      buscando: false,
    };
  }

  buscarNovosOptantes = async () => {
    const retorno = await api.post(
      "/debitoConta/buscaRemessaNaogerada" + requisicoes.entidade,
      {
        instituicao_cobranca_id: this.state.instituicao_cobranca_id,
      },
      requisicoes.header
    );
    console.log("retorno", retorno);
    console.log(retorno);
    this.setState({ novosOptantes: retorno.data });
  };

  gerarRemessaOptantes = async () => {
    const retorno = await api.post(
      "/remessaDebitoConta/geraArquivoNovosOptantes" + requisicoes.entidade,
      {
        instituicao_cobranca_id: this.state.instituicao_cobranca_id,
      },
      requisicoes.header
    );

    let numeroRemessa = "00";
    if (retorno.data && retorno.data.length) {
      numeroRemessa = retorno.data.slice(74, 79);
    }
    fileDownload(
      retorno.data,
      moment().format("DDMM") + "_" + numeroRemessa + ".REM"
    );
  };

  render() {
    return (
      <div>
        <h3>Novos optantes débito em conta</h3>
        <Form>
          <Row>
            <Col>
              <Form.Select
                label="Instituição Cobrança"
                floating
                options={this.props.instituicoes_debito_em_conta}
                value={this.state.instituicao_cobranca_id}
                onChange={(e, a) =>
                  this.setState({
                    instituicao_cobranca_id: a.value,
                    novosOptantes: [],
                  })
                }
              />
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "flex-end",
              }}
              md="auto"
            >
              <Row
                style={{
                  paddingBottom: 5,
                }}
              >
                <Col
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <Form.Button
                    color="green"
                    onClick={this.buscarNovosOptantes}
                    disabled={!this.state.instituicao_cobranca_id}
                  >
                    Buscar
                  </Form.Button>
                </Col>
                <Col>
                  <Form.Button
                    color="blue"
                    disabled={!this.state.novosOptantes.length}
                    onClick={this.gerarRemessaOptantes}
                  >
                    Gerar Remessa
                  </Form.Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>

        {this.state.novosOptantes.length !== 0 && (
          <Table>
            <Table.Header>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Dia Vencimento</Table.HeaderCell>
              <Table.HeaderCell>Conta</Table.HeaderCell>
              <Table.HeaderCell>Digito Conta</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {this.state.novosOptantes.map((e, i) => (
                <Table.Row>
                  <Table.Cell>{e.nome}</Table.Cell>
                  <Table.Cell>{e.debito_conta.dia_vencimento}</Table.Cell>
                  <Table.Cell>{e.debito_conta.conta}</Table.Cell>
                  <Table.Cell>{e.debito_conta.digito_conta}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
    );
  }
}
