import React, { Component } from "react";
import { Table, Button, Checkbox, Input, Select } from "semantic-ui-react";
import { Row, Col, Form } from "react-bootstrap";
import ReactSelect from "react-select";
import Pagination from "@material-ui/lab/Pagination";

// import moment from "moment-timezone";

import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // dados inputs pelo user

      // options

      //dados da tela
      titulosPagina: [],

      //estados de controle
      buscando: false,

      totalPages: 0,
      dadosPorPagina: 30,
      activePage: 1,
      pagina: 1,
    };
    this.buscaAcaoDisponivel();
  }

  imprimirTituloAcao = async (parcelas, b, activePage = 1) => {
    let listaAux = [];
    for (let i = 1; i <= 5000; i++) {
      listaAux.push(i);
    }

    await api.post(
      "/acao/geraPdfAcaoLiberada" + requisicoes.entidade,
      { lista: listaAux },
      requisicoes.header
    );
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");

    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    const r = await api.get(
      "/acao/buscaPdfAcaoLiberada" +
        requisicoes.entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.id
    );
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "acao/buscaPdfAcaoLiberada" +
      requisicoes.entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.id;

    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  buscaAcaoDisponivel = async (parcelas, b, activePage = 1) => {
    this.setState({ buscando: true });
    let listaAux = [];

    for (let i = 1; i <= 5000; i++) {
      listaAux.push(i);
    }

    const titulosDisponivel = await api.post(
      "/acao/buscaAcaoLiberada" + requisicoes.entidade,
      { lista: listaAux, activePage: activePage },
      requisicoes.header
    );

    const dadosPorPagina = this.state.dadosPorPagina;

    this.setState({
      buscando: false,
      titulosDisponiveis: titulosDisponivel.data,
      titulosPagina: titulosDisponivel.data.slice(
        0,
        titulosDisponivel.data.length < dadosPorPagina
          ? titulosDisponivel.data.length
          : dadosPorPagina
      ),
      activePage: 1,
      totalPages: Math.ceil(titulosDisponivel.data.length / dadosPorPagina),
    });
    return this.setState({ buscando: false });
  };

  render() {
    return (
      <div>
        <Row style={{ alignItems: "flex-end", paddingTop: 15 }}>
          <Col md={3}>
            <Form.Label>Títulos Disponíveis</Form.Label>
            <Form.Control
              as="select"
              name="grupo_servico_selecionado"
              id="grupo_servico_selecionado"
              value="5.000"
              label="5000"
              placeholder="5000"
              required
              disabled
              onChange={this.buscaAcaoDisponivel}
            >
              <option value={0}>{"5.000"}</option>

              {/* {this.state.optionsServico.map((e, i) => (
                <option value={e.value}>{e.label}</option>
              ))} */}
            </Form.Control>
          </Col>

          <Col md={true} />
          <Col md="auto" style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={this.buscaAcaoDisponivel}
              color="green"
              loading={this.state.buscando}
              disabled={true}
              // disabled={
              //   this.state.optionsSelectedTitulo.length === 0 ||
              //   this.state.buscando === true
              // }
            >
              Buscar
            </Button>
          </Col>

          <Col md="auto" style={{ display: "flex", alignItems: "center" }}>
            <Button onClick={this.imprimirTituloAcao} color="blue">
              Imprimir
            </Button>
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Títulos Disponíveis</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.titulosPagina?.map((e, i) => (
              <Table.Row>
                <Table.Cell>{e.codigo_acao}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Row style={{ justifyContent: "center" }}>
          <Pagination
            count={this.state.totalPages}
            variant="outlined"
            shape="rounded"
            boundaryCount={1}
            page={this.state.activePage}
            onChange={(e, a) => {
              this.setState({
                activePage: a,
                titulosPagina: this.state.titulosDisponiveis.slice(
                  (a - 1) * this.state.dadosPorPagina,
                  this.state.titulosDisponiveis.length <
                    a * this.state.dadosPorPagina
                    ? this.state.titulosDisponiveis.length
                    : a * this.state.dadosPorPagina
                ),
              });
            }}
          />
        </Row>
      </div>
    );
  }
}
