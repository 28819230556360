import React, { Component } from "react";
import { Table, Button, Modal } from "semantic-ui-react";
import { Link } from "react-router-dom";

// Components
import ModalExtrato from "./modalExtrato";

// Utils
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";
import { parseISO, format } from "date-fns";

var idContaModalAtual = null;

export default class ContasAPagarLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaContaBancaria: [],
      lancamentoItem: null,
      listaLancamentoContas: [],
      activePages: 1,
      totalRegistro: 0,
      totalPaginas: 0,

      // estado botões
      disable: false,
      excluindo: false,
      buscando: false,
      loadingBotaoId: null,

      // Modal
      open: false,
    };
    this.lista();
  }

  lista = async (page = 1) => {
    const resposta = await api.post(
      "/contaBancaria/lista" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    this.setState({ listaContaBancaria: resposta.data });
  };

  excluirContaBancaria = async (event) => {
    const idConta = event.target.value || event.target.id;
    this.setState({
      disable: true,
      excluindo: true,
      loadingBotaoId: parseInt(idConta),
    });

    try {
      await api.post(
        "/contaBancaria/excluir" + requisicoes.entidade + "&id=" + idConta,
        "",
        requisicoes.header
      );
      let lista = this.state.listaContaBancaria;
      let index = lista.findIndex((e) => e.id === parseInt(idConta));
      lista.splice(index, 1);
      this.setState({ listaContaBancaria: lista });
    } catch (error) {
      if (error.response.data !== "") {
        alert(error.response.data);
      } else {
        alert("Ocorreu um erro ao excluir o registro. Tente novamente.");
      }
    }
    setTimeout(() => {
      this.setState({ disable: false, excluindo: false });
    }, 800);
  };

  // extratoConta = async (event, activePages = 1) => {
  //   const idConta = event.target.value || event.target.id;
  //   activePages = 1;
  //   this.setState({
  //     disable: true,
  //     buscando: true,
  //     loadingBotaoId: parseInt(idConta),
  //   });
  //   try {
  //     const resposta = await api.post(
  //       "/contaBancaria/extratoConta" +
  //         requisicoes.entidade +
  //         "&id=" +
  //         idConta +
  //         "",
  //       {
  //         activePages: activePages,
  //       },
  //       requisicoes.header
  //     );

  //     this.setState({
  //       listaLancamentoContas: resposta.data.docs,
  //       idConta: idConta,
  //       totalaPaginas: resposta.data.pages,
  //     });
  //     this.handleShow();
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setTimeout(() => {
  //     this.setState({ disable: false, buscando: false });
  //   }, 800);
  // };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleShow = () => this.setState({ open: true });

  render() {
    return (
      <>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Conta</Table.HeaderCell>
              <Table.HeaderCell>Agência</Table.HeaderCell>
              <Table.HeaderCell>Banco</Table.HeaderCell>
              <Table.HeaderCell>Conta Contábil</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                <Link to={{ pathname: "/contaBancaria" }}>
                  <Button positive icon="add" size="tiny"></Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.listaContaBancaria.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>{e.conta}</Table.Cell>
                <Table.Cell>
                  {e.agencia !== null ? e.agencia.nome : ""}
                </Table.Cell>
                <Table.Cell>{e.banco.nome}</Table.Cell>
                <Table.Cell>
                  {e.conta_contabil != null && e.conta_contabil.descricao}
                </Table.Cell>
                <Table.Cell textAlign="center" style={{ width: 135 }}>
                  <Button
                    negative
                    size="tiny"
                    value={e.id}
                    onClick={this.excluirContaBancaria}
                    icon={{
                      className: "trash alternate outline",
                      id: e.id,
                      // onClick: this.excluirContaBancaria,
                    }}
                    disabled={this.state.disable}
                    loading={
                      this.state.excluindo && this.state.loadingBotaoId === e.id
                    }
                  />
                  <Link
                    to={{ pathname: "/contaBancaria", query: { id: e.id } }}
                  >
                    <Button
                      primary
                      size="tiny"
                      icon="edit outline"
                      disabled={this.state.disable}
                    ></Button>
                  </Link>
                  <Button
                    color="teal"
                    size="tiny"
                    value={e.id}
                    onClick={() => {
                      idContaModalAtual = e.id;
                      this.handleShow();
                    }}
                    icon={{
                      className: "list ol",
                      id: e.id,
                      // onClick: this.extratoConta,
                    }}
                    type="button"
                    disabled={this.state.disable}
                    loading={
                      this.state.buscando && this.state.loadingBotaoId === e.id
                    }
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer></Table.Footer>
        </Table>

        <ModalExtrato
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          // listaLancamentoContas={this.state.listaLancamentoContas}
          idConta={idContaModalAtual}
        />
      </>
    );
  }
}
