import React, { Component } from "react";
import {
  Header,
  Button,
  Segment,
  Table,
  Icon,
  Modal,
  Message,
  Image,
} from "semantic-ui-react";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import Select from "react-select";
import renomearChave from "../../../services/renomearChave";
import StaticTimePicker from "@material-ui/lab/StaticTimePicker";
import TextField from "@material-ui/core/TextField";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import moment from "moment";
import { storage } from "../../../config/firebase";

export default class EventoClube extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      treino_padrao: false,
      treino_exercicio_id: "",
      nome: null,
      serie: null,
      repeticao: null,
      duracao: new Date().setHours(0, 0, 0, 0),
      peso: 0,
      tempo_descanso: new Date().setHours(0, 0, 0, 0),
      data_lancamento: null,
      data_validade: null,
      status: null,
      aluno: null,
      aluno_id: null,
      listaProfessor: [],
      professor: null,
      professor_id: null,
      exercicios: [],
      exercicio: null,
      exercicio_id: null,
      pessoas: [],
      listaExercicios: [],
      listaTreinoExercicios: [],
      modalOpen: false,
      messageExercicioAdicionado: false,
      messageExercicioRemovido: false,
      messageTreino: false,
      modalOpenFoto: false,
      srcImagem: null,
      exercicioNome: null,
      exercicioDescricao: null,
      exercicioObservacao: null,
      buscando: false,
    };
    this.visualiza();
    this.pesquisaProfessor();
    this.pesquisaAluno();
  }

  abreModal = async (e) => {
    this.setState({ modalOpen: e });
  };
  openModalFoto = async (e, url, exercicio) => {
    if (e !== false) {
      this.setState({
        modalOpenFoto: e,
        srcImagem: url,
        exercicioNome: exercicio.nome,
        exercicioDescricao: exercicio.descricao,
        exercicioObservacao: exercicio.observacao,
      });
    } else {
      this.setState({
        modalOpenFoto: e,
        srcImagem: null,
        exercicioNome: null,
        exercicioDescricao: null,
        exercicioObservacao: null,
      });
    }
  };

  visualiza = async () => {
    if (typeof this.props.location.query !== "undefined") {
      const resposta = await api.post(
        "/treino/visualiza" + requisicoes.entidade,
        { id: this.props.location.query.id },
        requisicoes.header
      );
      let aluno = {
        value: resposta?.data?.aluno?.id,
        label: resposta?.data?.aluno?.nome,
      };
      let professor = {
        value: resposta.data?.professor?.id,
        label: resposta.data?.professor?.nome,
      };
      this.setState(resposta.data);
      this.setState({ 
        aluno: aluno, 
        professor: professor,
        id: this.props.location.query.id,
      });
      if (resposta.data.id !== null) {
        this.carregaListaExercicios(resposta.data.id);
      }
    }
  };

  carregaListaExercicios = async (treino_id) => {
    const respostaTreinoExercicios = await api.post(
      "/treinoExercicio/listaPorTreino" + requisicoes.entidade,
      { treino_id: treino_id },
      requisicoes.header
    );
    let listaRetorno = [];
    try {
      for (let index = 0; index < respostaTreinoExercicios.data.length; index++) {
        const element = respostaTreinoExercicios.data[index];
  
        let imagem = "";
        await storage
          .ref(
            `${
              process.env.REACT_APP_ENVIRONMENT === "production"
                ? "producao"
                : "hml"
            }/${window.localStorage.getItem("e_clube")}/exercicio`
          )
          .child(`${element.exercicio_id}.jpg`)
          .getDownloadURL()
          .then((url) => {
            imagem = url;
            console.log(url);
            listaRetorno.push({
              imagem: imagem,
              treinoExercicio: element,
            });
          })
          .catch((error) => {
            listaRetorno.push({
              imagem: imagem,
              treinoExercicio: element,
            });
          });
      }
    } catch(error) {
      console.log("carregaListaExercicios.error: " + error);
    }

    this.setState({ listaTreinoExercicios: listaRetorno });
    console.log(listaRetorno);
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  alteracaoTempoDescanso = (event) => {
    this.setState({ tempo_descanso: event });
  };

  alteracaoDuracao = (event) => {
    this.setState({ duracao: event });
  };

  alteracaoSelectAluno = async (event) => {
    this.setState({ aluno_id: event.value });
    this.setState({ aluno: event });
  };

  alteracaoSelectProfessor = async (event) => {
    this.setState({ professor_id: event.value });
    this.setState({ professor: event });
  };

  alteracaoSelectExercicio = async (event) => {
    this.setState({ exercicio_id: event.value });
    this.setState({ exercicio: event });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const dados = {
      id: this.state.id,
      nome: this.state.nome,
      data_lancamento: this.state.data_lancamento,
      data_validade: this.state.data_validade,
      professor_id: this.state.professor_id,
      aluno_id: this.state.aluno_id,
    };

    if (this.state.id !== "") {
      const retorno = await api.post(
        "/treino/alterar" + requisicoes.entidade,
        dados,
        requisicoes.header
      );

      if (retorno !== null) {
        window.location.href = "/academia";
      }
    } else {
      const retorno = await api.post(
        "/treino/salvar" + requisicoes.entidade,
        dados,
        requisicoes.header
      );
      this.setState({ id: retorno.data.id });
      // this.visualiza();
    }
    this.setState({ messageTreino: true });
    setTimeout(() => {
      this.setState({ messageTreino: false });
    }, 3000);
  };

  excluir = async () => {
    if (this.state.id !== "") {
      api
        .post(
          "/treino/alterar" + requisicoes.entidade,
          { id: this.state.id, status: "inativo" },
          requisicoes.header
        )
        .then(() => {
          window.location.href = "/academia";
        });
    }
  };

  excluirExercicio = async (id) => {
    await api.post(
      "/treinoExercicio/excluir" + requisicoes.entidade,
      { id: id },
      requisicoes.header
    );
    this.setState({ messageExercicioRemovido: true });
    setTimeout(() => {
      this.setState({ messageExercicioRemovido: false });
    }, 3000);
    this.carregaListaExercicios(this.state.id);
  };

  visualizaExercicio = async (id) => {
    const treinoExercicio = await api.post(
      "/treinoExercicio/visualiza" + requisicoes.entidade,
      { id: id },
      requisicoes.header
    );
    let exercicio = renomearChave(
      treinoExercicio.data.exercicio,
      "id",
      "value"
    );
    exercicio = renomearChave(exercicio, "nome", "label");

    const tempo_descanso_moment = moment(
      treinoExercicio.data.tempo_descanso,
      "HH:mm:ss"
    );
    const tempo_descanso = new Date().setHours(
      0,
      tempo_descanso_moment.minutes(),
      tempo_descanso_moment.seconds(),
      0
    );
    const duracao_moment = moment(
      treinoExercicio.data.tempo_descanso,
      "HH:mm:ss"
    );
    const duracao = new Date().setHours(
      0,
      duracao_moment.minutes(),
      duracao_moment.seconds(),
      0
    );

    this.setState({
      treino_exercicio_id: treinoExercicio.data.id,
      exercicio_id: treinoExercicio.data.exercicio_id,
      exercicio: exercicio,
      serie: treinoExercicio.data.serie,
      repeticao: treinoExercicio.data.repeticao,
      duracao: duracao,
      tempo_descanso: tempo_descanso,
      peso: treinoExercicio.data.peso,
      modalOpen: true,
    });

    // this.setState({ exercicio_id: event.value });
    // this.setState({ exercicio: event });
  };

  salvaExercicio = async () => {
    let retorno = "";

    const duracao = moment(this.state.duracao).format("HH:mm:ss");
    const tempo_descanso = moment(this.state.tempo_descanso).format("HH:mm:ss");

    const dados = {
      id: this.state.treino_exercicio_id,
      treino_id: this.state.id,
      exercicio_id: this.state.exercicio_id,
      serie: this.state.serie,
      repeticao: this.state.repeticao,
      duracao: duracao,
      tempo_descanso: tempo_descanso,
      peso: this.state.peso,
    };

    if (this.state.treino_exercicio_id !== "") {
      api
        .post(
          "/treinoExercicio/alterar" + requisicoes.entidade,
          dados,
          requisicoes.header
        )
        .then(() => {
          this.abreModal(false);
          this.carregaListaExercicios(this.state.id);
          this.setState({ messageExercicioAdicionado: true });
          setTimeout(() => {
            this.setState({ messageExercicioAdicionado: false });
          }, 3000);
        });
    } else {
      api
        .post(
          "/treinoExercicio/salvar" + requisicoes.entidade,
          dados,
          requisicoes.header
        )
        .then(() => {
          this.abreModal(false);
          this.carregaListaExercicios(this.state.id);
          this.setState({ messageExercicioAdicionado: true });
          setTimeout(() => {
            this.setState({ messageExercicioAdicionado: false });
          }, 3000);
        });
    }
  };

  pesquisaPessoa = (newValue) => {
    // api
    //   .post(
    //     "/pessoa/pesquisa" + requisicoes.entidade,
    //     {
    //       pesquisa: newValue,
    //       pessoaInativa: false,
    //     },
    //     requisicoes.header
    //   )
    //   .then((resposta) => {
    //     const lista = [];
    //     resposta.data.forEach((e) => {
    //       let objeto = {
    //         value: e.id,
    //         label: e.nome,
    //       };
    //       lista.push(objeto);
    //     });
    // this.setState({ pessoas: lista });
    // });
  };

  pesquisaAluno = async () => {
    const alunoAcademia = await api.post(
      "/parametroAcademiaAluno/listaParametroAcademiaAluno" +
        requisicoes.entidade,
      {},
      requisicoes.header
    );
    let lista = [];
    for (let i = 0; i < alunoAcademia.data.length; i++) {
      const element = alunoAcademia.data[i];
      lista.push({
        value: element.pessoa.id,
        label: element.pessoa.nome,
      });
    }

    function ordenaAluno(a, b) {
      let aAux = a.label.toLowerCase();
      let bAux = b.label.toLowerCase();
      if (aAux > bAux) {
        return 1;
      }
      if (aAux < bAux) {
        return -1;
      }
      return 0;
    }
    lista.sort(ordenaAluno);

    this.setState({ pessoas: lista });
  };

  pesquisaProfessor = async () => {
    const professor = await api.post(
      "/funcionario/listaFuncionariosAcademia" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    console.log("professor", professor);
    let dataAtual = moment().format("DD-mm-yyyy");
    let lista = [];
    for (let i = 0; i < professor.data.length; i++) {
      const element = professor.data[i];

      if (
        moment(element.data_admissao).format("DD-mm-yyyy") <= dataAtual &&
        element.data_desocupacao === null
      ) {
        lista.push({
          value: element.pessoa.id,
          label: element.pessoa.nome,
        });
      }
    }
    this.setState({ listaProfessor: lista });
  };

  buscaExercicios = (busca) => {
    api
      .post(
        "/exercicio/lista" + requisicoes.entidade,
        {
          busca: busca,
        },
        requisicoes.header
      )
      .then((resposta) => {
        const lista = [];
        resposta.data.forEach((e) => {
          let objeto = renomearChave(e, "id", "value");
          objeto = renomearChave(objeto, "nome", "label");
          lista.push(objeto);
        });
        this.setState({ listaExercicios: lista });
      });
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Treino
        </Header>
        <Message positive hidden={!this.state.messageTreino}>
          Treino salvo
        </Message>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={4}>
              <Form.Label>Nome</Form.Label>
              <FormControl
                name="nome"
                onChange={this.alteracao}
                value={this.state.nome}
                type="text"
              />
            </Col>
            <Col sm={4}>
              <Form.Label>Data Lançamento</Form.Label>
              <FormControl
                type="date"
                name="data_lancamento"
                onChange={this.alteracao}
                value={this.state.data_lancamento}
              />
            </Col>
            <Col sm={4}>
              <Form.Label>Data Validade</Form.Label>
              <FormControl
                type="date"
                name="data_validade"
                onChange={this.alteracao}
                value={this.state.data_validade}
              />
            </Col>
          </Row>
          {this.state.treino_padrao !== true && (<Row>
            <Col sm={6}>
              <Form.Label>Aluno</Form.Label>
              <Select
                placeholder="Digite para buscar"
                inputId="pessoa"
                as="select"
                name="aluno"
                options={this.state.pessoas}
                onInputChange={this.pesquisaPessoa}
                onChange={this.alteracaoSelectAluno}
                value={this.state.aluno}
                required
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Professor</Form.Label>
              <Select
                placeholder="Digite para buscar"
                inputId="pessoa"
                as="select"
                name="professor"
                options={this.state.listaProfessor}
                onChange={this.alteracaoSelectProfessor}
                value={
                  (console.log("this.state.professor", this.state.professor),
                  this.state.professor)
                }
                required
              />
            </Col>
          </Row>
          )}

          <Message positive hidden={!this.state.messageExercicioAdicionado}>
            Exercício adicionado a lista
          </Message>

          <Message negative hidden={!this.state.messageExercicioRemovido}>
            Exercício excluido da lista
          </Message>

          {this.state.id !== "" && (
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Nome</Table.HeaderCell>
                  <Table.HeaderCell>Série</Table.HeaderCell>
                  <Table.HeaderCell>Repetição</Table.HeaderCell>
                  <Table.HeaderCell>Tempo Descanso (MM:SS)</Table.HeaderCell>
                  <Table.HeaderCell>Peso</Table.HeaderCell>
                  <Table.HeaderCell>Duração (MM:SS)</Table.HeaderCell>

                  <Table.HeaderCell width={1} textAlign="center">
                    <Modal
                      style={{ overflow: "visible" }}
                      open={this.state.modalOpen}
                      trigger={
                        <Button
                          type="button"
                          size="tiny"
                          positive
                          icon="add"
                          onClick={() => this.abreModal(true)}
                        ></Button>
                      }
                    >
                      <Header>
                        <Icon name="add circle" />
                        Adicionar Exercicio
                      </Header>
                      <Modal.Content>
                        <Form.Label>Exercício</Form.Label>
                        <Select
                          placeholder="Digite para buscar"
                          inputId="exercicio"
                          as="select"
                          name="exercicio"
                          options={this.state.listaExercicios}
                          onInputChange={this.buscaExercicios}
                          onChange={this.alteracaoSelectExercicio}
                          value={this.state.exercicio}
                          required
                        />
                        <Row>
                          <Col sm={4}>
                            <Form.Label>Nº Série</Form.Label>
                            <FormControl
                              aria-describedby="basic-addon1"
                              name="serie"
                              onChange={this.alteracao}
                              value={this.state.serie}
                              required
                            />
                          </Col>
                          <Col sm={4}>
                            <Form.Label>Nº Repetições</Form.Label>
                            <FormControl
                              aria-describedby="basic-addon1"
                              name="repeticao"
                              onChange={this.alteracao}
                              value={this.state.repeticao}
                              required
                            />
                          </Col>
                          <Col sm={4}>
                            <Form.Label>Peso</Form.Label>
                            <FormControl
                              aria-describedby="basic-addon1"
                              name="peso"
                              onChange={this.alteracao}
                              value={this.state.peso}
                              required
                              type="number"
                            />
                          </Col>
                        </Row>

                        <Row style={{ paddingTop: 20 }}>
                          <Col sm={6}>
                            <Form.Label as="h3">Tempo Descanso</Form.Label>
                          </Col>
                          <Col sm={6}>
                            <Form.Label as="h3">Duração</Form.Label>
                          </Col>
                          <Col sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <StaticTimePicker
                                ampm={false}
                                orientation="landscape"
                                openTo="minutes"
                                views={["minutes", "seconds"]}
                                inputFormat="mm:ss"
                                mask="__:__"
                                label="Minutos e segundos"
                                value={this.state.tempo_descanso}
                                onChange={this.alteracaoTempoDescanso}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          </Col>

                          <Col sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <StaticTimePicker
                                ampm={false}
                                orientation="landscape"
                                openTo="minutes"
                                views={["minutes", "seconds"]}
                                inputFormat="mm:ss"
                                mask="__:__"
                                label="Minutos e segundos"
                                value={this.state.duracao}
                                onChange={this.alteracaoDuracao}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </LocalizationProvider>{" "}
                          </Col>
                        </Row>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button
                          color="red"
                          inverted
                          onClick={() => this.abreModal(false)}
                        >
                          <Icon name="remove" /> Fechar
                        </Button>
                        <Button
                          color="green"
                          inverted
                          onClick={this.salvaExercicio}
                        >
                          <Icon name="checkmark" /> Adicionar
                        </Button>
                      </Modal.Actions>
                    </Modal>
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={1}
                    textAlign="center"
                  ></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.listaTreinoExercicios.map((e, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>
                      <Header as="h4" image>
                        <Image
                          src={e.imagem}
                          rounded
                          size="mini"
                          onClick={() =>
                            this.openModalFoto(
                              true,
                              e.imagem,
                              e.treinoExercicio.exercicio
                            )
                          }
                        />
                        <Header.Content>
                          {e.treinoExercicio.exercicio.nome}
                        </Header.Content>
                      </Header>
                    </Table.Cell>
                    <Table.Cell>{e.treinoExercicio.serie}</Table.Cell>
                    <Table.Cell>{e.treinoExercicio.repeticao}</Table.Cell>
                    <Table.Cell>
                      {moment(
                        e.treinoExercicio.tempo_descanso,
                        "hh:mm:ss"
                      ).format("mm:ss")}
                    </Table.Cell>
                    <Table.Cell>{e.treinoExercicio.peso} kg</Table.Cell>
                    <Table.Cell>
                      {moment(e.treinoExercicio.duracao, "hh:mm:ss").format(
                        "mm:ss"
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Button
                        type="button"
                        primary
                        icon="edit"
                        onClick={() =>
                          this.visualizaExercicio(e.treinoExercicio.id)
                        }
                      ></Button>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Button
                        type="button"
                        negative
                        icon="remove"
                        onClick={() =>
                          this.excluirExercicio(e.treinoExercicio.id)
                        }
                      ></Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}

          <Segment clearing>
            <Header floated="right">
              <Link to="/academia">
                <Button type="button">Voltar</Button>
              </Link>
              {this.state.id !== null && (
                <Button negative type="button" onClick={this.excluir}>
                  Deletar
                </Button>
              )}
              <Button
                positive
                type="submit"
                loading={this.state.buscando === true}
              >
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>
        <Modal open={this.state.modalOpenFoto} centered={true}>
          <Header content={this.state.exercicioNome} />
          <Modal.Content image scrolling>
            <Image src={this.state.srcImagem} size="medium" />

            <Modal.Description>
              <Row>
                <Header>Descrição: {this.state.exercicioDescricao}</Header>
              </Row>
              <Row style={{ paddingTop: 30 }}>
                <Header>Observação: {this.state.exercicioObservacao}</Header>
              </Row>
            </Modal.Description>
          </Modal.Content>

          <div class="actions">
            <Button
              type="button"
              primary
              onClick={() => this.openModalFoto(false, null, null)}
            >
              Voltar
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}
