/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Modal, Image } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import { Button, TextField, CircularProgress } from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";

import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

import { storage } from "../../../config/firebase";

export default class ModalTipoEvento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: "",
      alertaUpdate: false,
      enviando: false,
      nomeEvento: "",
      estadoAltera: "",
    };
  }

  alteracao = async (event) => {
    this.setState({ nomeEvento: event.target.value });
  };

  updateEvento = async (event) => {
    try {
      setTimeout(async () => {
        const retorno = await api.post(
          "/tipoeventodesporto/editar" + requisicoes.entidade,
          { descricao: this.state.nomeEvento, id: this.props.evento_id },
          requisicoes.header
        );
        this.props.alteraEvento({
          descricao: this.state.nomeEvento,
          id: this.props.evento_id,
        });

        this.props.changeOpenModal(false);
        this.setState({ alterado: retorno });
      }, 800);
    } catch (error) {
      console.log("error", error);
      this.props.changeOpenSnackbar(false, false);
      this.setState({ mensagemErro: false });
    }
  };

  render() {
    return (
      <Modal
        size="tiny"
        open={this.props.openModal}
        onUnmount={() => {
          this.props.changeOpenModal(false);
        }}
        onMount={() => {
          this.setState({
            nomeEvento: this.props.evento_nome,
            idEvento: this.props.evento_id,
          });
        }}
      >
        <Modal.Header>Editar Evento</Modal.Header>
        <Modal.Content>
          <Row>
            <Col>
              <TextField
                placeholder="Alterar nome"
                onChange={(e) => this.alteracao(e)}
                label="Nome Do Evento"
                required
                value={this.state.nomeEvento}
              />
            </Col>
          </Row>
        </Modal.Content>
        <Modal.Actions>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="9">
              <Button
                variant="contained"
                color="inherit"
                onClick={() => {
                  this.props.changeOpenModal(false);
                }}
              >
                Voltar
              </Button>
            </Col>
            <Col md="auto">
              <LoadingButton
                variant="contained"
                onClick={(e) => {
                  if (this.props.evento_nome !== this.state.nomeEvento) {
                    this.updateEvento(e);
                  } else {
                    this.props.changeOpenModal(false);
                  }
                }}
              >
                Salvar
              </LoadingButton>
            </Col>
            <Col md="auto"></Col>
          </Row>
        </Modal.Actions>
      </Modal>
    );
  }

  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
  };
}
