import React, { Component } from "react";
import { Form, Segment } from "semantic-ui-react";
import { Row, Col } from "react-bootstrap";
import ImputMask from "react-input-mask";

// Utils
import { validaCnpj } from "../../../utils/validadorCamposPessoa";

export default class ParametrosGeral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cnpjValido: true,
    };
  }

  render() {
    return (
      <Row>
        <Col sm={6}>
          <Form.Field>
            <label>Nome do clube</label>
            <input
              placeholder="Nome"
              value={this.props.nomeClube}
              name="nomeClube"
              onChange={this.props.alteracao}
            />
          </Form.Field>
          <Form.Field error={!this.state.cnpjValido}>
            <label>CNPJ</label>
            <ImputMask
              mask="99.999.999/9999-99"
              placeholder="CNPJ"
              value={this.props.cnpjClube}
              name="cnpjClube"
              onChange={this.props.alteracao}
              onBlur={(e) => {
                let a = validaCnpj(e.target.value);
                console.log("a: ", a);
                this.props.setState({
                  cnpjValido: a,
                });
                this.setState({ cnpjValido: a });
              }}
            />
          </Form.Field>
          <Form.Group>
            <Form.Field width={14}>
              <label>Endereço</label>
              <input
                placeholder="Endereço"
                value={this.props.enderecoClube}
                name="enderecoClube"
                onChange={this.props.alteracao}
              />
            </Form.Field>
            <Form.Field>
              <label>Numero</label>
              <input
                type="number"
                placeholder="Numero"
                value={this.props.numeroClube}
                name="numeroClube"
                onChange={this.props.alteracao}
              />
            </Form.Field>
          </Form.Group>

          <Form.Field>
            <label>Bairro</label>
            <input
              placeholder="Bairro"
              value={this.props.bairroClube}
              name="bairroClube"
              onChange={this.props.alteracao}
            />
          </Form.Field>
          <Form.Field>
            <label>CEP</label>

            <ImputMask
              mask="99999-999"
              placeholder="CEP"
              value={this.props.cepClube}
              name="cepClube"
              onChange={this.props.alteracao}
            />
          </Form.Field>
          <Form.Group>
            <Form.Field width={14}>
              <label>Estado</label>
              <input
                placeholder="Estado"
                value={this.props.estadoClube}
                name="estadoClube"
                onChange={this.props.alteracao}
              />
            </Form.Field>
            <Form.Field>
              <label>UF</label>
              <input
                placeholder="UF"
                value={this.props.ufClube}
                name="ufClube"
                onChange={this.props.alteracao}
              />
            </Form.Field>
          </Form.Group>
        </Col>
        <Col sm={6} style={{ display: "flex", justifyContent: "center" }}>
          <Form.Field>
            <label>Logo do clube</label>
            <Segment style={{ width: "350px" }}>
              {this.props.imagem !== null && (
                <img
                  width="320px"
                  height="320px"
                  src={URL.createObjectURL(this.props.imagem)}
                  alt=""
                  style={{ marginBottom: 20 }}
                ></img>
              )}

              {this.props.imagem === null &&
                (window.localStorage.getItem("srcImagem") ? (
                  <img
                    width="320px"
                    height="320px"
                    src={window.localStorage.getItem("srcImagem")}
                    alt=""
                    style={{ marginBottom: 20 }}
                  ></img>
                ) : (
                  <div
                    style={{
                      width: "320px",
                      height: "320px",
                      borderColor: "#8d9ba5",
                      borderWidth: "2px",
                      borderStyle: "solid",
                      borderRadius: "50%",
                      color: "#8d9ba5",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 20,
                    }}
                  >
                    <b>Logo não selecionada</b>
                  </div>
                ))}
              <input
                type="file"
                accept=".png"
                onChange={(e, a) => {
                  if (e.target.files[0].type !== "image/png") {
                    alert("Formato de imagem invalido. Apenas PNG.");
                    return;
                  }
                  if (e.target.files[0].size > 150000) {
                    alert("Imagem muito grande. Reduza a qualidade da imagem.");
                    return;
                  }
                  this.props.setState({ imagem: e.target.files[0] });
                }}
              ></input>
            </Segment>
          </Form.Field>
        </Col>
        <Col sm={6} style={{ paddingTop: 25 }}>
          <Form.Field>
            <label>Dia de cobrança da matrícula</label>
            <input
              placeholder="Dia de cobrança da matrícula"
              type="number"
              min="1"
              max="31"
              value={this.props.dia_cobranca_matricula}
              name="dia_cobranca_matricula"
              onChange={this.props.alteracao}
            />
          </Form.Field>
        </Col>
        <Col sm={6} style={{ paddingTop: 25 }}>
          <Form.Select
            label="Serviço de mensalidade"
            placeholder="Serviço mensalidade"
            value={this.props.servico_mensalidade_id}
            name="servico_mensalidade_id"
            // onChange={this.props.alteracao}
            onChange={(e, a) => {
              this.props.setState({ [a.name]: a.value });
            }}
            options={this.props.optionsServico}
            search
          />
        </Col>

        <Col sm={6} style={{ paddingTop: 10 }}>
          <Form.Field>
            <label>Porcentagem Juros (diária)</label>
            <input
              placeholder="Porcentagem Juros"
              type="number"
              step="0.01"
              value={this.props.porcentagem_juros}
              name="porcentagem_juros"
              onChange={this.props.alteracao}
              required
            />
          </Form.Field>
        </Col>

        <Col sm={6} style={{ paddingTop: 10 }}>
          <Form.Field>
            <label>Porcentagem Multa</label>
            <input
              placeholder="Porcentagem Multa"
              type="number"
              step="0.01"
              value={this.props.porcentagem_multa}
              name="porcentagem_multa"
              onChange={this.props.alteracao}
              required
            />
          </Form.Field>
        </Col>

        <Col sm={6} style={{ paddingTop: 25 }}>
          <Form.Field>
            <Form.Select
              label="Instituição cobrança boleto Serviço"
              placeholder="Instituição cobrança boleto Serviço"
              value={this.props.instituicao_cobrancao_boleto_servico}
              name="instituicao_cobrancao_boleto_servico"
              onChange={(e, a) => this.props.setState({ [a.name]: a.value })}
              options={this.props.instituicoesOptions}
              search
            />
          </Form.Field>
        </Col>
      </Row>
    );
  }
}
