import React, { Component } from "react";
import {
  Container,
  Table,
  Button,
  Menu,
  Icon,
  Pagination,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import api from "../../../../../services/api";
import requisicoes from "../../../../../utils/Requisicoes";
import moment from "moment-timezone";

export default class TransferenciaLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaHistorico: [],
      pagina: 1,
      totalPaginas: 1,
      porTipoUsuario: false,
      tipoUsuario: null,
    };
    this.listaHistorico();
  }

  listaHistorico = async (event, data) => {
    if (data === undefined || data === null) {
      data = {};
      data.activePage = 1;
    }
    const resposta = await api.post(
      "/historicoAcao/listaAlteracaoTipoTitulo" + requisicoes.entidade,
      {
        page: data.activePage,
      },
      requisicoes.header
    );
    this.setState({ listaHistorico: resposta.data });
  };

  render() {
    return (
      <div style={{ paddingBottom: 100 }}>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Data</Table.HeaderCell>
              <Table.HeaderCell>Tipo</Table.HeaderCell>
              <Table.HeaderCell>Título</Table.HeaderCell>
              <Table.HeaderCell>Pessoa</Table.HeaderCell>
              <Table.HeaderCell>Descrição</Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <Link to={{ pathname: "/alteracaoTipoTitulo" }}>
                  <Button positive icon="add" size="tiny"></Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.listaHistorico.docs !== undefined &&
              this.state.listaHistorico.docs.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell>
                    {e.data !== null && moment(e.data).format("DD/MM/YYYY")}
                  </Table.Cell>
                  <Table.Cell>{e.tipo_lancamento}</Table.Cell>
                  <Table.Cell>
                    {e.acoes[0] !== undefined &&
                      e.acoes[0] !== null &&
                      e.acoes[0].codigo}
                  </Table.Cell>
                  <Table.Cell>
                    {e.pessoa_compra !== null && e.pessoa_compra.nome}
                  </Table.Cell>
                  <Table.Cell colSpan={2}>
                    {e.motivo !== null && e.motivo}
                  </Table.Cell>
                  {/* <Table.Cell width={1}>
                  <Link to={{ pathname: "/usuarioTransferencia", query: { id: e.id } }}>
                    <Button primary icon="edit" size="tiny"></Button>
                  </Link>
                </Table.Cell> */}
                </Table.Row>
              ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan={6}>
                <Pagination
                  boundaryRange={0}
                  ellipsisItem={null}
                  defaultActivePage={1}
                  totalPages={this.state.listaHistorico.pages}
                  onPageChange={this.listaHistorico}
                ></Pagination>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}
