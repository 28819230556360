/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Header, Segment, Divider, Modal, Checkbox } from "semantic-ui-react";
import { Form, Col, Row } from "react-bootstrap";
import {
  MenuItem,
  InputLabel,
  Select,
  FormLabel,
  IconButton,
  TextField,
} from "@material-ui/core/";
import { LoadingButton } from "@material-ui/lab";

import CloseIcon from "@material-ui/icons/Close";
import requisicoes from "../../../utils/Requisicoes";
import api from "../../../services/api";

import enumCampeonato from "../../../enum/TipoCampeonatoDesporto";

import "../styles.css";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campeonatosCadastrados: [],
      campeonatoSelecionado: [],
      campeonatos: enumCampeonato,
      descricao: "",
      grupoServicoId: null,
      idaeVolta: false,
      modalidades: [],
      modalidadeSelecionadaId: null,
      modalidadeSelecionadaNome: "",
      pontosPorEmpate: null,
      pontosPorVitoria: null,
      quantidadeReservas: null,
      quantidadeComissao: null,
      quantidadeMaxAtleta: null,
      servicos: [],
      ServicosInscricaoSelecionadoNome: "",
      ServicosInscricaoSelecionadoId: null,
      tipoCampeonatoSelecionadoNome: "",
      tipo: "",
      tipoCampeonatoSelecionadoId: null,
      tempoPenalidade: null,
      todosContraTodos: false,
      valorInscricaoEquipe: null,
      valorInscricaoIndividual: null,
      nomeCampeonato: "",
      open: false,
    };
  }
  adicionarNomeCampeonato = async (event) => {
    this.setState({ nomeCampeonato: event.target.value });
  };

  salvar = async (event) => {
    event.preventDefault();
    try {
      this.setState({ salvando: true });
      const retorno = await api.post(
        "/campeonato/salvar" + requisicoes.entidade,
        {
          tipo_campeonato_id: this.props.tipoCampeonatoSelecionadoId,
          modalidade_desporto_id: this.props.modalidadeSelecionadaId,
          descricao: this.state.descricao,
          status: "criado",
          tempo_penalidade: parseFloat(this.state.tempoPenalidade),
          pontos_ganhos_vitoria: parseFloat(this.state.pontosPorVitoria),
          pontos_ganhos_empate: parseFloat(this.state.pontosPorEmpate),
          valor_inscricao_equipe: parseFloat(this.state.valorInscricaoEquipe),
          valor_inscricao_individual: parseFloat(
            this.state.valorInscricaoIndividual
          ),
          quantidade_reservas: parseFloat(this.state.quantidadeReservas),
          quantidade_comissao_tecnica: parseFloat(
            this.state.quantidadeComissao
          ),
          servico_inscricao_id: this.props.ServicosInscricaoSelecionadoId,
          modalAdicionar: false,
          todos_contra_todos: this.state.todosContraTodos,
          ida_volta: this.state.idaeVolta,
          fase_atual: this.props.tipoCampeonatoSelecionadoNome,
          nome: this.state.nomeCampeonato,
        },
        requisicoes.header
      );
      setTimeout(() => {
        retorno.data.tipo_campeonato = this.props.campeonatos.find(
          (e) => parseInt(e.value) === retorno.data.tipo_campeonato_id
        );
        retorno.data.modalidade_desporto = this.props.modalidades.find(
          (e) => parseInt(e.value) === retorno.data.modalidade_desporto_id
        );
        this.props.adicionaCampeonato(retorno.data);
        this.setState({ salvando: false });
        this.props.handleClose();
      }, 1000);

      // return retorno.data;
    } catch (error) {
      console.log("Erro ao salvar", error);
    }
  };

  changeCheckTodosContraTodos = async (event) => {

    if (this.state.todosContraTodos === false) {
      this.setState({ todosContraTodos: !this.state.todosContraTodos });
    } else {
      this.setState({ todosContraTodos: !this.state.todosContraTodos });

    }
  };
  changeCheckIdaEVolta = async (event) => {

    if (this.state.idaeVolta === false) {
      this.setState({ idaeVolta: !this.state.idaeVolta });
    } else {
      this.setState({ idaeVolta: !this.state.idaeVolta });
    }
  };

  alteracao = async (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  render() {
    return (
      <Modal
        dimmer="bluring"
        size="large"
        open={this.props.open_modal_campeonato}
        as="form"
        onSubmit={this.salvar}
        onClose={this.props.handleClose}
      >
        <Modal.Header>
          {"Adicionar Campeonato"}
          <IconButton
            className="bottomModal"
            color="secondary"
            onClick={() => this.props.handleClose(false)}
          >
            <CloseIcon />
          </IconButton>

          <Col sm={3}>
            <TextField
              required
              variant="standard"
              id="nomeCampeonato"
              name="nomeCampeonato"
              label="Nome do campeonato"
              autoFocus="false"
              onChange={this.adicionarNomeCampeonato}
            />
          </Col>
        </Modal.Header>
        <Modal.Content>
          <Col sm={12} style={{ padding: "0px" }}>
            <Segment clearing>
              <Header>
                <h3> Configuração Geral</h3>
                <Divider style={{ marginTop: "-1ch" }} />
              </Header>
              <Row
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <Col sm={3}>
                  <InputLabel id="demo-mutiple-name-label">
                    Tipo de Campeonato
                  </InputLabel>
                  <Select
                    variant="standard"
                    fullWidth
                    onChange={(event) =>
                      this.props.alteracaoSelectTipoCampeonato(event)
                    }
                    required
                  >
                    {this.props.campeonatos.map((element, index) => {
                      return (
                        <MenuItem value={element.value}>
                          {element.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Col>
                <Col sm={3}>
                  <Form.Label>Modalidade</Form.Label>
                  <Select
                    fullWidth
                    variant="standard"
                    name="modalidade"
                    onChange={(event) =>
                      this.props.alteracaoSelectModalidades(event)
                    }
                    required
                  >
                    {this.props.modalidades.map((element, index) => {
                      return (
                        <MenuItem value={element}>{element.label}</MenuItem>
                      );
                    })}
                  </Select>
                </Col>
                <Col sm={3}>
                  <Form.Label>Serviço Inscrição</Form.Label>
                  <Select
                    fullWidth
                    variant="standard"
                    name="servicoInscricao"
                    options={this.props.servicos}
                    onChange={(event) =>
                      this.props.alteracaoSelectServicos(event)
                    }
                    required
                  >
                    {this.props.servicos.map((element, index) => {
                      return (
                        <MenuItem value={element.value}>
                          {element.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Col>
                <Col sm={3}>
                  <TextField
                    variant="standard"
                    id="tempoPenalidade"
                    name="tempoPenalidade"
                    label="Tempo Penalidade"
                    onChange={this.alteracao}
                    type="number"
                    required
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: "2ch" }}>
                <Col sm={12}>
                  <div className="input-group">
                    <textarea
                      className="form-control"
                      name="descricao"
                      id="descricao"
                      rows="3"
                      placeholder="Detalhes do Campeonato"
                      required
                      onBlur={this.alteracao}
                    ></textarea>
                  </div>
                </Col>
              </Row>
            </Segment>

            <Row>
              <Col sm={6}>
                <Segment clearing>
                  <Header>
                    <h3>Pontuacao</h3>
                    <Divider style={{ marginTop: "-1ch" }} />
                  </Header>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                      marginTop: "2ch",
                    }}
                  >
                    <Col sm={4}>
                      <TextField
                        variant="standard"
                        id="pontosPorVitoria"
                        name="pontosPorVitoria"
                        label="Pontos por Vitória"
                        onChange={this.alteracao}
                        type="number"
                        required
                      />
                    </Col>
                    <Col sm={4}>
                      <TextField
                        variant="standard"
                        id="pontosPorEmpate"
                        name="pontosPorEmpate"
                        label="Pontos por Empate"
                        onChange={this.alteracao}
                        type="number"
                        required
                      />
                    </Col>
                  </Row>
                </Segment>
              </Col>
              <Col sm={6}>
                <Segment clearing>
                  <Header>
                    <h3>Valores</h3>
                    <Divider style={{ marginTop: "-1ch" }} />
                  </Header>
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col sm={4}>
                      <TextField
                        variant="standard"
                        id="valorInscricaoEquipe"
                        name="valorInscricaoEquipe"
                        label="Inscrição Equipe"
                        onChange={this.alteracao}
                        type="number"
                        required
                      />
                    </Col>
                    <Col sm={4}>
                      <TextField
                        variant="standard"
                        id="valorInscricaoIndividual"
                        name="valorInscricaoIndividual"
                        label="Inscrição Individual"
                        onChange={this.alteracao}
                        type="number"
                        required
                      />
                    </Col>
                  </Row>
                </Segment>
              </Col>
            </Row>

            <Segment clearing>
              <Header>
                <h3>Participantes/Rodadas</h3>
                <Divider style={{ marginTop: "-1ch" }} />
              </Header>
              <Row>
                <Col sm={4}>
                  <TextField
                    variant="standard"
                    id="quantidadeReservas"
                    name="quantidadeReservas"
                    label="Nº de Reservas"
                    onChange={this.alteracao}
                    type="number"
                    required
                  />
                </Col>
                <Col sm={4}>
                  <TextField
                    variant="standard"
                    id="quantidadeComissao"
                    name="quantidadeComissao"
                    label="Nº de Comissão"
                    onChange={this.alteracao}
                    type="number"
                    required
                  />
                </Col>
                <Col sm={2} className="flexVerticalStart">
                  <FormLabel>Todos contra Todos</FormLabel>
                  <Checkbox
                    style={{ marginTop: "1ch" }}
                    slider
                    name="todosContraTodos"
                    id="todosContraTodos"
                    onClick={(event) => this.changeCheckTodosContraTodos(event)}
                    checked={this.props.tipoCampeonatoSelecionadoNome === "Pontos Corridos" ? true : this.todosContraTodos}
                    disabled={this.props.tipoCampeonatoSelecionadoNome === "Pontos Corridos"}
                  />
                </Col>
                <Col sm={2} className="flexVerticalStart">
                  <FormLabel> Ida e Volta</FormLabel>

                  <Checkbox
                    style={{ marginTop: "1ch" }}
                    slider
                    name="idaeVolta"
                    id="idaeVolta"
                    onClick={(event) => this.changeCheckIdaEVolta(event)}
                    checked={this.props.tipoCampeonatoSelecionadoNome === "Pontos Corridos" ? true : this.idaeVolta}
                    disabled={this.props.tipoCampeonatoSelecionadoNome === "Pontos Corridos"}

                  />
                </Col>
              </Row>
            </Segment>
          </Col>
        </Modal.Content>
        <Modal.Actions>
          <LoadingButton
            color="primary"
            floated="right"
            style={{ outline: "none" }}
            type="submit"
            loading={this.state.salvando}
          >
            Adicionar Campeonato
          </LoadingButton>
        </Modal.Actions>
      </Modal>
    );
  }
}
