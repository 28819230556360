import React, { Component } from "react";
import { Menu } from "semantic-ui-react";

import Servicos from "./servicos";
import CentroDeCusto from "./centro_custo";
import TipoTitulo from "./tipo_titulo";

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: "servicos",
    };
  }
  render() {
    let content = "";
    switch (this.state.selectedMenu) {
      case "servicos":
        content = <Servicos />;
        break;
      case "centro_de_custo":
        content = <CentroDeCusto />;
        break;
      case "tipo_titulo":
        content = <TipoTitulo />;
        break;
      default:
        content = <Servicos />;
        break;
    }
    return (
      <div>
        <Menu pointing secondary>
          <Menu.Item
            name="servicos"
            active={this.state.selectedMenu === "servicos"}
            onClick={this.selectMenu.bind(null, "servicos")}
          >
            Servicos
          </Menu.Item>

          <Menu.Item
            name="centro_de_custo"
            active={this.state.selectedMenu === "centro_de_custo"}
            onClick={this.selectMenu.bind(null, "centro_de_custo")}
          >
            Centro de custo
          </Menu.Item>

          <Menu.Item
            name="tipo_titulo"
            active={this.state.selectedMenu === "tipo_titulo"}
            onClick={this.selectMenu.bind(null, "tipo_titulo")}
          >
            Tipo de título
          </Menu.Item>
        </Menu>
        {content}
      </div>
    );
  }
  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
  };
}
