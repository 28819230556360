import React, { Component } from "react";
import {
  Container,
  Table,
  Button,
  Popup,
  Search,
  Modal,
  Header,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";
import { Col, Row } from "react-bootstrap";
import { parseISO, format } from "date-fns";

export default class ItemLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      pagina: 1,
      totalPaginas: 1,
      popUpPesquisa: false,
      pesquisando: false,
      open: false,
      item: null,
    };
    this.listaItem();
  }

  pesquisaItem = async (event) => {
    this.setState({ pesquisando: true });
    let pesquisa = event.target.value;
    setTimeout(async () => {
      this.setState({ popUpPesquisa: false });
      await api
        .post(
          "/item/pesquisa" + requisicoes.entidade,
          { pesquisa: pesquisa },
          requisicoes.header
        )
        .then((resp) => {
          if (resp.data.length == 0) {
            this.setState({ popUpPesquisa: true });
          }
          this.setState({ lista: resp.data });
        });
      this.setState({ pesquisando: false });
    }, 500);
  };

  listaItem = async (page = 1) => {
    const resposta = await api.post(
      "/item/lista" + requisicoes.entidade + "&pagina=" + page,
      "",
      requisicoes.header
    );
    const { itens, pagina, totalPaginas } = resposta.data;
    this.setState({ lista: itens, pagina, totalPaginas });
  };

  excluir = async (event) => {
    const retorno = await api.post(
      "/item/excluir" + requisicoes.entidade + "&id=" + event.target.value,
      "",
      requisicoes.header
    );
    if (retorno != null) {
      window.location.href = "/item/lista";
    }
  };

  handleClose = () => this.setState({ open: false });

  handleShow = async (event) => {
    const idItem = event.target.id;
    api
      .post(
        "/item/findOne" + requisicoes.entidade,
        { pesquisaId: idItem },
        requisicoes.header
      )
      .then((resp) => {
        this.setState({ item: resp.data });
      });
    this.setState({ open: true });
  };

  render() {
    return (
      <div className="lista">
        <Row>
          <Col sm={6}>
            <Popup
              content="Nenhum registro encontrado!"
              open={this.state.popUpPesquisa}
              position="right center"
              trigger={
                <Search
                  loading={this.state.pesquisando}
                  fluid
                  onSearchChange={this.pesquisaItem}
                  showNoResults={false}
                  input={{
                    icon: "search",
                    iconPosition: "left",
                    style: { width: "100%" },
                    input: { style: { borderRadius: "3%" } },
                  }}
                  placeholder="Digite para buscar"
                />
              }
            />
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>Código</Table.HeaderCell>
              <Table.HeaderCell>Descrição</Table.HeaderCell>
              <Table.HeaderCell>Unidade Medida</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Etoque Minimo</Table.HeaderCell>
              <Table.HeaderCell>Etoque Atual</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" style={{ width: "9%" }}>
                <Link to={{ pathname: "/item" }}>
                  <Button positive icon="add" size="tiny"></Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.lista.map((e, i) => (
              <Table.Row
                key={i} //disabled={e.ativo === false}
                style={e.ativo === false ? { color: "#C6C6C6" } : {}}
              >
                <Table.Cell>{e.id}</Table.Cell>
                <Table.Cell>{e.descricao}</Table.Cell>
                <Table.Cell>{e.unidade_medida}</Table.Cell>
                <Table.Cell>
                  {e.ativo == true
                    ? "Ativo"
                    : e.ativo == false
                    ? "Inativo"
                    : "Ativo"}
                </Table.Cell>
                <Table.Cell>{e.estoque_minimo}</Table.Cell>
                <Table.Cell
                  negative={
                    e.quantidade <= e.estoque_minimo && e.ativo != false
                  }
                  positive={
                    e.quantidade > e.estoque_minimo + 5 && e.ativo != false
                  }
                  warning={
                    e.quantidade > e.estoque_minimo &&
                    e.quantidade <= e.estoque_minimo + 5 &&
                    e.ativo !== false
                  }
                >
                  {e.quantidade}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Link to={{ pathname: "/item", query: { id: e.id } }}>
                    <Button primary icon="edit" size="tiny"></Button>
                  </Link>
                  <Button
                    color="teal"
                    icon={{
                      className: "list",
                      id: [e.id],
                      onClick: [this.handleShow],
                    }}
                    size="tiny"
                    id={e.id}
                    onClick={this.handleShow}
                    type="button"
                  ></Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer></Table.Footer>
        </Table>

        <Modal
          // dimmer={"blurring"}
          open={this.state.open}
          onClose={this.handleClose}
        >
          <Modal.Header>
            Histórico item{" - "}
            {this.state.item != null && this.state.item.descricao}
          </Modal.Header>
          <Modal.Content>
            <Header>Histórico de entrada</Header>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Data</Table.HeaderCell>
                  <Table.HeaderCell>Quantidade</Table.HeaderCell>
                  <Table.HeaderCell>Valor unitário</Table.HeaderCell>
                  <Table.HeaderCell>Valor total</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.item != null &&
                  this.state.item.item_entrada != null &&
                  this.state.item.item_entrada.map((e, i) => (
                    <Table.Row>
                      <Table.Cell>
                        {e.data != null &&
                          format(parseISO(e.data), "dd/MM/yyyy")}
                      </Table.Cell>
                      <Table.Cell>{e.quantidade}</Table.Cell>
                      <Table.Cell>{e.valor_unidade}</Table.Cell>
                      <Table.Cell>{e.valor_total}</Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>

            <Header>Histórico de requisição</Header>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Data</Table.HeaderCell>
                  <Table.HeaderCell>Quantidade</Table.HeaderCell>
                  <Table.HeaderCell>Centro de Custo</Table.HeaderCell>
                  <Table.HeaderCell>Elemento de Custo</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.item != null &&
                  this.state.item.item_entrada != null &&
                  this.state.item.item_saida.map((e, i) => (
                    <Table.Row>
                      <Table.Cell>
                        {e.data != null &&
                          format(parseISO(e.data), "dd/MM/yyyy")}
                      </Table.Cell>
                      <Table.Cell>{e.quantidade}</Table.Cell>
                      <Table.Cell>
                        {e.centro_de_custo != null &&
                          e.centro_de_custo.codigo +
                            " - " +
                            e.centro_de_custo.descricao}
                      </Table.Cell>
                      <Table.Cell>
                        {e.elemento_de_custo != null &&
                          e.elemento_de_custo.codigo +
                            " - " +
                            e.elemento_de_custo.descricao}
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Modal.Content>
          <Modal.Actions clearing>
            <Button onClick={this.handleClose}>Fechar</Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
