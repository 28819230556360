import React, { Component } from "react";
import { Header, Menu, Button, Icon, Modal, Segment } from "semantic-ui-react";
import { Form } from "react-bootstrap";
// Requisicoes
import requisicoes from "../../../utils/Requisicoes";
import api from "../../../services/api";
// utils
import moment from "moment";
// Componentes
import Relatorio from "./relatorios/index";
import Mapa from "./mapa/index";
import MinhasReservas from "./minhasReservas/index";
import Turmas from "./turmas/index";
import Reservas2 from "./reservas/index";
import Efetivacao from "./efetivacao/index";

export default class Reservas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaGrupoServico: [],
      listaParametroGrupoServico: [],
      listaReservasAdmin: [],
      listaServicos: [],
      listaTurmas: [],
      listaHistoricoMatricula: [],
      pessoa_id: null,
      dataBusca: moment().format("YYYY-MM-DD"),
      options: [],
      selectedMenu: localStorage.getItem("pagina_anterior_reservas")
        ? localStorage.getItem("pagina_anterior_reservas")
        : this.props.submenus[0]
          ? this.props.submenus[0].nome
          : "reservas",
      listaPresencaReservas: [],
      presencaSocio: null,
      servicos: false,
      turmas: false,
      alert: false,
      grupoServico: null,
      loading: false,
      listaMatriculasRelatorio: [],
      listaReservasRelatorio: [],
      listaReservasRelatorio1: [],
      grupoServicoSelecionado: "Todos",
      listaReservasEfetivacao: [],
      listaReservasEfetivacaoAdmin: [],
      modalOpen: false,
      modalOpenEfetiva: false,
      dataBuscaEfetivas: moment().format("YYYY-MM-DD"),
      data_inicio: "",
      data_final: "",
      nomePessoa: "",
      dataVencimentoEfetivacao: moment().format("YYYY-MM-DD"),
      valorEfetivacao: "",
      listaUsuarioGrupoServico: [],
      dataBuscaTurma: moment().format("YYYY"),
      diaDaSemana: moment().weekday() + 1,
      checkboxRelatorio: "matriculado",
      reserva_efetivada_id: null,
      pessoa_reserva_efetivada_id: null,
      open_modal_cobranca: false,
      listaFornecedorReserva: [],
      modalReservaDados: false,
      reservaDados: {},
      listaServicosRelatorio: [],
      listaTurmasRelatorio: [],
      servicoSelecionado: null,
      turmaSelecionada: null,
      loadingListaReservaEfetivacaoAdmin: false,
      selectReservaEfetivacao: "efetivada",
      checkboxBuscarData: true,
      activePage: 1,
      activePage1: 1,
      totalRegistro: 0,
      totalPaginas: 0,
      totalPaginas1: 0,
      usuarioLogado: {},
      somente_convidados_presentes: false,
      efetivacaoReserva: false
    };
    this.selectMenu();
    this.buscaReservasAdmin();
    this.buscaReservasEfetivadaAdmin();
    this.listaGrupoServico();
    this.listaParametroGrupoServico();
    this.listaReservasEfetivacao();
    this.usuarioLogado();

    let params = new URLSearchParams(props.location.search);
    let reserva_efetivada_id = params.get("reserva_efetivada_id");

    if (reserva_efetivada_id !== null) {
      this.state.selectedMenu = "efetivacao";
      this.state.reserva_efetivada_id = reserva_efetivada_id;
    }
  }
  usuarioLogado = async () => {
    const respostaUsuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    this.setState({ usuarioLogado: respostaUsuarioLogado.data });
  };

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === "grupoServicoSelecionado") {
      this.buscaServicos(event.target.value);
    }
    if (event.target.name === "servicoSelecionado") {
      this.buscaTurmas(event.target.value);
    }
    if (event.target.name === "selectReservaEfetivacao") {
      this.setState({ listaReservasEfetivacaoAdmin: [] });
    }
    if (event.target.name === "dataBusca") {
      this.setState({ listaServicos: [] });
      this.setState({ listaReservasAdmin: [] });
    }
    if (event.target.name === "dataBuscaEfetivas") {
      this.setState({ listaReservasEfetivacaoAdmin: [] });
    }
  };

  buscaServicos = async (grupo_id) => {
    this.setState({ listaTurmasRelatorio: [] });
    if (grupo_id === "Todos" || grupo_id === "Canceladas") {
      this.setState({
        listaServicosRelatorio: [],
        servicoSelecionado: null,
        turmaSelecionada: null,
      });
      return;
    }
    let listaServicos = await api.post(
      "/servico/turmaPorGrupoServicoeData" + requisicoes.entidade,
      {
        id: grupo_id,
        data: moment().year(),
      },
      requisicoes.header
    );
    let lista = [];
    if (listaServicos.data.length > 1) {
      lista.push({
        id: 0,
        nome: "Todos",
      });
    }
    for (let i = 0; i < listaServicos.data.length; i++) {
      const element = listaServicos.data[i];
      lista.push({ id: element.id, nome: element.nome });
    }
    if (lista.length > 0) {
      this.setState({
        listaServicosRelatorio: lista,
        servicoSelecionado: lista[0].id,
        turmaSelecionada: null,
      });
    } else {
      this.setState({
        listaServicosRelatorio: lista,
        servicoSelecionado: null,
        turmaSelecionada: null,
      });
    }
    if (lista.length === 1) {
      this.buscaTurmas(lista[0].id);
    }
  };

  buscaTurmas = async (servico_id) => {
    this.setState({ servicoSelect: servico_id });

    let listaTurmas = await api.post(
      "/turmaServico/turmaPorServico" + requisicoes.entidade,
      {
        id: servico_id,
      },
      requisicoes.header
    );
    let lista = [];
    if (listaTurmas.data.length > 1) {
      lista.push({ id: 0, nome: "Todas", value: 0 });
    }
    for (let i = 0; i < listaTurmas.data.length; i++) {
      const element = listaTurmas.data[i];
      lista.push({
        id: element.id,
        key: element.id,
        text: element.nome + " - " + element.turma,
        nome: element.nome + " - " + element.turma,
        value: element.id,
        turma: element.turma,
      });
    }
    if (lista.length === 0) {
      this.setState({
        listaTurmasRelatorio: lista,
        turmaSelecionada: null,
      });
    } else {
      this.setState({
        listaTurmasRelatorio: lista,
        turmaSelecionada: lista[0].id,
      });
    }
  };

  buscaReservasAdmin = async () => {
    const reservaHorario = await api.post(
      "/reservaHorario/reservasPorPessoaeData" + requisicoes.entidade,
      { pessoa_id: this.state.pessoa_id, dataBusca: this.state.dataBusca },
      requisicoes.header
    );
    const reservas = reservaHorario.data;
    const respostaServico = await api.post(
      "/servico/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    const servicos = respostaServico.data;
    let listaRetorno = [];
    let pessoa = { id: null };
    servicos.forEach((servico) => {
      reservas.forEach((reserva) => {
        if (servico.id === reserva.horario_servico.servico_id) {
          if (reserva.pessoa !== undefined) {
            pessoa = reserva.pessoa;
          }
          let efetivado = false;
          let cancelado = false;
          if (reserva.dataEfetivacao !== null) {
            efetivado = true;
          }
          if (reserva.dataCancelamento !== null) {
            cancelado = true;
          }
          listaRetorno.push({
            pessoa: pessoa,
            nome: servico.nome,
            id: servico.id,
            reserva_id: reserva.id,
            efetivado: efetivado,
            cancelado: cancelado,
            dataReserva: reserva.dataReserva,
            horaInicio: reserva.horario_servico.horaInicio,
            horaFim: reserva.horario_servico.horaFim,
            evento: reserva.evento,
            servico: servico,
          });
        }
      });
    });

    if (listaRetorno.length > 0) {
      listaRetorno = this.ordenaNomes(listaRetorno);
    }
    this.setState({ listaReservasAdmin: listaRetorno });
  };

  buscaReservasEfetivadaAdmin = async () => {
    if (!this.state.pessoa_id && !this.state.checkboxBuscarData) {
      alert("Selecione uma pessoa ou ative o filtro de data.");
      return;
    }
    this.setState({ loadingListaReservaEfetivacaoAdmin: true });

    const canceladas = await api.post(
      "/reservaEfetivada/cancelarVencidas" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    const reservaEfetivada = await api.post(
      "/reservaEfetivada/reservasPorPessoaeData" + requisicoes.entidade,
      {
        pessoa_id: this.state.pessoa_id,
        dataBusca: this.state.checkboxBuscarData
          ? this.state.dataBuscaEfetivas
          : null,
        status:
          this.state.selectReservaEfetivacao === "null"
            ? null
            : this.state.selectReservaEfetivacao,
      },
      requisicoes.header
    );
    const reservas = reservaEfetivada.data;
    const respostaServico = await api.post(
      "/servico/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    const servicos = respostaServico.data;
    let listaRetorno = [];
    servicos.forEach((servico) => {
      reservas.forEach(async (reserva) => {
        if (
          reserva.reserva_horario !== null &&
          servico.id === reserva.reserva_horario.horario_servico.servico.id
        ) {
          let efetivado = false;
          let cancelado = false;
          if (reserva.dataCancelamento !== null) {
            cancelado = true;
          }
          if (reserva.dataEfetivacao !== null) {
            efetivado = true;
          }
          let responsavel_cancelada = "";
          if (reserva.reserva_horario.cancelada_responsavel_id !== null) {
            responsavel_cancelada =
              reserva.reserva_horario.pessoa_responsavel_cancelada;
          }
          let responsavel_efetivada = "";
          if (reserva.reserva_horario.efetivada_responsavel_id !== null) {
            responsavel_efetivada =
              reserva.reserva_horario.pessoa_responsavel_efetivada;
          }
          let responsavel_reservado = "";
          if (reserva.reserva_horario.reservado_responsavel_id !== null) {
            responsavel_reservado =
              reserva.reserva_horario.pessoa_responsavel_reserva;
          }
          let responsavel_transferiu = "";
          if (reserva.reserva_horario.transferiu_responsavel_id !== null) {
            responsavel_transferiu =
              reserva.reserva_horario.pessoa_responsavel_transferiu;
          }
          listaRetorno.push({
            pessoa: reserva.pessoa,
            nome: servico.nome,
            servico,
            id: servico.id,
            reserva_id: reserva.id,
            dataEfetivacao: reserva.dataEfetivacao,
            dataCancelamento: reserva.dataCancelamento,
            dataLancamento: reserva.dataLancamento,
            reserva_horario_id: reserva.reserva_horario.id,
            data_transferecia: reserva.reserva_horario.data_transferencia,
            dataReserva: reserva.dataReserva,
            horaInicio: reserva.reserva_horario.horario_servico.horaInicio,
            horaFim: reserva.reserva_horario.horario_servico.horaFim,
            efetivado: efetivado,
            cancelado: cancelado,
            evento: reserva.evento,
            observacao: reserva.observacao,
            lancamento_conta_id: reserva.lancamento_conta_id,
            horario_reservou: reserva.reserva_horario.horario_reservou,
            horario_cancelamento: reserva.reserva_horario.horario_cancelamento,
            horario_efetivada: reserva.reserva_horario.horario_efetivada,
            horario_transferiu: reserva.reserva_horario.horario_transferiu,
            cancelada_responsavel: responsavel_cancelada,
            efetivada_responsavel: responsavel_efetivada,
            reservado_responsavel: responsavel_reservado,
            transferiu_responsavel: responsavel_transferiu,
          });
        }
      });
    });

    setTimeout(async () => {
      if (listaRetorno.length > 0) {
        listaRetorno = this.ordenaNomes(listaRetorno);
      }
      this.setState({ listaReservasEfetivacaoAdmin: listaRetorno });
      this.setState({ loadingListaReservaEfetivacaoAdmin: false });
    }, 2000);
  };

  pesquisaPessoa = async () => {
    try {
      let acaoCodigo = null;
      let lista = [];
      let retorno = 0;
      acaoCodigo = parseInt(document.getElementById("pesquisaPessoa").value);

      if (isNaN(acaoCodigo)) {
        retorno = await api.post(
          "/pessoa/pesquisa" + requisicoes.entidade,
          { pesquisa: document.getElementById("pesquisaPessoa").value },
          requisicoes.header
        );
      } else {
        retorno = await api.post(
          "/pessoa/pesquisaPorCodigoAcaoValorExato" + requisicoes.entidade,
          { pesquisa: document.getElementById("pesquisaPessoa").value },
          requisicoes.header
        );
      }
      for (let i = 0; i < retorno.data.length; i++) {
        const element = retorno.data[i];
        let acaoStr = "";

        if (element.acao) {
          acaoStr = element.acao.codigo
            ? element.acao.codigo
            : element.acao.tipo_titulo.prefixo +
            " " +
            element.acao.codigo_auxiliar;
          acaoStr += " - ";
        }
        let objeto = {
          value: element.id,
          label:
            acaoStr + element.nome + (element.cpf ? " - " + element.cpf : ""),
        };
        lista.push(objeto);
      }
      this.setState({ options: lista });
    } catch (error) {
      console.error("error", error);
    }
  };

  alteracaoSelectPessoa = async (event) => {
    if (event !== null) {
      this.setState({ pessoa_id: event.value });
    } else {
      this.setState({ pessoa_id: null });
    }
  };

  ordenaNomes(listaParam) {
    let listaRetorna = listaParam.filter(
      (p) => p.nome !== null && p.nome.trim() !== ""
    );
    listaRetorna.sort((a, b) => a.nome.localeCompare(b.nome));
    return listaRetorna;
  }

  carregaListaConvidados = async (id) => {
    this.setState({ buscandoListaPresencaReservas: true });
    setTimeout(async () => {
      const retorno = await api.post(
        "/presencaReserva/presencaReservaPorHorario" + requisicoes.entidade,
        { id: id },
        requisicoes.header
      );
      let listaFiltrada = this.ordenaNomes(retorno.data);
      this.setState({
        listaPresencaReservas: listaFiltrada,
        buscandoListaPresencaReservas: false,
        somente_convidados_presentes: false,
      });
    }, 100);
  };

  setSocioResponsavel = async (socio) => {
    this.setState({ buscandoListaPresencaReservas: true });
    setTimeout(async () => {
      const presencaSocio = this.state.listaPresencaReservas.find((p) =>
        p.nome.includes(socio?.nome)
      );
      if (presencaSocio) {
        console.log("socio?.nome", socio?.nome)
        presencaSocio.nome = socio?.nome;
        this.setState({
          presencaSocio: presencaSocio,
          buscandoListaPresencaReservas: false,
        });
      }
    }, 300);
  };

  carregaListaFornecedor = async (id) => {
    this.setState({ buscandoListaFornecedorReserva: true });
    const resposta = await api.post(
      "/fornecedorReserva/fornecedorReservaPorHorario" + requisicoes.entidade,
      { id: id },
      requisicoes.header
    );
    this.setState({
      listaFornecedorReserva: this.ordenaNomes(resposta.data),
      buscandoListaFornecedorReserva: false,
    });
  };

  confirmaPresencaFornecedor = async (presenca, id) => {
    await api.post(
      "/fornecedorReserva/confirmarFornecedor" + requisicoes.entidade,
      { fornecedor: presenca },
      requisicoes.header
    );
    this.carregaListaFornecedor(id);
  };

  confirmaPresenca = async (presenca, id, presente) => {
    console.log("confirma presenca",presenca)
    console.log("id",id)
    console.log(" presente",presente)
    await api.post(
      "/presencaReserva/confirmarPresenca" + requisicoes.entidade,
      {
        presenca: presenca,
        presente: presente !== null ? presente : true,
      },
      requisicoes.header
    );
    await api.post(
      "/reservaBloqueio/confirmarPresenca" + requisicoes.entidade,
      {
        listaPresenca: presenca,
        confirmaPresenca: true,
      },
      requisicoes.header
    );
    this.carregaListaConvidados(id);
  };

  imprimirListaConvidados = async (reserva) => {
    const dados = {
      id: reserva.reserva_id,
      data: reserva.dataReserva,
      servico: reserva.servico,
      responsavel: reserva.pessoa,
      horarioInicial: reserva.horaInicio,
      horarioFinal: reserva.horaFim,
    };
    await api.post(
      "/reservaHorario/gerarListaConvidados" + requisicoes.entidade,
      dados,
      requisicoes.header
    );
    setTimeout(() => {
      this.buscaPdfListaConvidados();
    }, 1000);
  };

  buscaPdfListaConvidados = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api.get(
      "/reservaHorario/buscarListaConvidados?e=" + entidade + "&t=Baer " + token
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "reservaHorario/buscarListaConvidados?e=" +
      entidade +
      "&t=Baer " +
      token;
    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  imprimirListaFornecedor = async (reserva) => {
    await api.post(
      "/reservaHorario/gerarListaFornecedores" + requisicoes.entidade,
      {
        id: reserva.reserva_id,
        data: reserva.dataReserva,
        servico: reserva.servico,
        responsavel: reserva.pessoa,
        horarioInicial: reserva.horaInicio,
        horarioFinal: reserva.horaFim,
      },
      requisicoes.header
    );

    setTimeout(() => {
      this.buscaPdfListaFornecedores();
    }, 1000);
  };
  buscaPdfListaFornecedores = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api.get(
      "/reservaHorario/buscarListaFornecedores?e=" +
      entidade +
      "&t=Baer " +
      token
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "reservaHorario/buscarListaFornecedores?e=" +
      entidade +
      "&t=Baer " +
      token;
    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = await new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  mostraListaConvidadosPresentes = async () => {
    console.log("Lista presenca true")
    this.setState({
      somente_convidados_presentes: !this.state.somente_convidados_presentes,
    });
  };

  carregaListaServico = async (id) => {
    const parametroGrupoServico = await api.post(
      "/parametrosGrupoReserva/visualizaGrupoServico" + requisicoes.entidade,
      {
        grupo_servico_id: id,
      },
      requisicoes.header
    );
    if (
      parametroGrupoServico.data &&
      parametroGrupoServico.data.hora_inicio &&
      parametroGrupoServico.data.hora_fim
    ) {
      const momentHoraInicio = moment(
        parametroGrupoServico.data.hora_inicio,
        "HH:mm:ss"
      );
      const momentHoraFim = moment(
        parametroGrupoServico.data.hora_fim,
        "H:mm:ss"
      );
      if (momentHoraInicio.isAfter(momentHoraFim)) {
        momentHoraFim.add(1, "day");
      }
      if (!moment().isBetween(momentHoraInicio, momentHoraFim)) {
        alert(
          `Grupo serviço bloqueado para reservas neste horário. Horário disponível: ${momentHoraInicio.format(
            "HH:mm"
          )} até ${momentHoraFim.format("HH:mm")}.`
        );
        return;
      }
    }

    this.setState({
      servicos: true,
    });
    this.setState({ loading: true });
    this.setState({ grupoServico: id });

    if (id === null) {
      await this.buscaReservasAdmin();
      return;
    }
    const listona = await api.post(
      "/reservaHorario/listaReservasGrupoServico" + requisicoes.entidade,
      { grupo_servico_id: id, data: this.state.dataBusca },
      requisicoes.header
    );
    var feriado = null;
    if (
      moment().format("YYYY-MM-DD") === this.state.dataBusca ||
      moment().add(1, "days").format("YYYY-MM-DD") === this.state.dataBusca
    ) {
      feriado = await api.post(
        "/feriado/feriadoPorData" + requisicoes.entidade,
        { data: this.state.dataBusca },
        requisicoes.header
      );
    }

    let listaRetorno = [];
    const weekday = moment(this.state.dataBusca).weekday() + 1;

    for (let i = 0; i < listona.data.length; i++) {
      const servico = listona.data[i];
      const respostaDistanciamentoDia =
        servico.parametros_reserva_distanciamento_dia;
      let diasDistanciamento = { id: null };
      if (respostaDistanciamentoDia.length > 0) {
        diasDistanciamento = respostaDistanciamentoDia[0];
      }
      const diaLiberadoServico = servico.dia_reserva_liberada;
      var diaReservaLiberada = null;
      for (let j = 0; j < diaLiberadoServico.length; j++) {
        const element = diaLiberadoServico[j];

        if (
          element.servico_id === servico.id &&
          element.dia_da_semana_id !== undefined &&
          element.dia_da_semana_id === this.state.diaDaSemana &&
          element.dia_da_reserva_id !== undefined &&
          element.dia_da_reserva_id === weekday
        ) {
          diaReservaLiberada = element;
        }
      }

      const diaLiberado = diaReservaLiberada;
      let liberaReserva = false;

      if (feriado !== null && feriado.data.length > 0) {
        liberaReserva = true;
      } else if (diasDistanciamento.id === null) {
        if (diaLiberadoServico.length > 0 && diaLiberado) {
          if (diaLiberado.libera === true) {
            liberaReserva = true;
          }
        } else {
          liberaReserva = true;
        }
      } else {
        if (
          moment(this.state.dataBusca).format("YYYY-MM-DD") >
          moment()
            .add(diasDistanciamento.diasDistanciamento, "days")
            .format("YYYY-MM-DD")
        ) {
        } else {
          if (diaLiberadoServico.length > 0) {
            if (diaLiberado) {
              if (diaLiberado.libera === true) {
                liberaReserva = true;
              }
            }
          } else {
            liberaReserva = true;
          }
        }
      }

      let horariosServico = [];
      const horarios = servico.horario_servico;
      let disponivel = false;

      let status = "Disponível";
      let situacaoReserva = "";
      let diasCancelamento = "";
      let dataCancelamento = "";
      let reservaEfetivacao = false;
      let reservaEvento = false;
      let pessoaReservou = false;
      let pessoa = { id: null, nome: null };

      for (let j = 0; j < horarios.length; j++) {
        const horario = horarios[j];
        const reservas = horario.reserva_horario;
        horariosServico.push({
          horaInicio: horario.horaInicio.slice(0, 5),
          horaFim: horario.horaFim.slice(0, 5),
          vagasDisponiveis: horario.vaga - horario.reserva_horario.length,
        });

        if (reservas.length > 0 || horario.vaga === 0) {
          for (let k = 0; k < reservas.length; k++) {
            const reserva = reservas[k];
            pessoa = reserva.pessoa;
            if (pessoa.id === this.state.usuarioLogado.pessoa_id) {
              pessoaReservou = true;
              liberaReserva = true;
            }
            if (servico.parametros_reserva_evento.length > 0) {
              reservaEvento = true;
            }
            const respostaEfetivada = reserva.refetivada;
            if (respostaEfetivada.length > 0) {
              reservaEfetivacao = true;
              const resEfetivada = respostaEfetivada[0];
              const parametroReservaDia = resEfetivada.parametros;
              if (parametroReservaDia !== null) {
                diasCancelamento = parametroReservaDia.diasCance;
              } else {
                diasCancelamento = 3;
              }

              const buscaDataCancelamento = moment(
                resEfetivada.dataLancamento
              ).add(diasCancelamento, "days");
              dataCancelamento = buscaDataCancelamento.format("DD/MM/YYYY");
            }
            switch (reserva.situacaoReserva) {
              case "prereserva":
                status = "Pré-Reserva";
                break;
              case "ativa":
                status = "Reservada";
                break;
              default:
                status = "";
                break;
            }

            situacaoReserva = reserva.situacaoReserva;

            if (reservas.length >= horario.vaga) {
              if (disponivel !== true) {
                disponivel = false;
              }
            } else {
              disponivel = true;
            }
            if (horarios.length <= 1) {
              listaRetorno.push({
                id: servico.id,
                nome: servico.nome,
                valor: servico.valor,
                horaInicio: horario.horaInicio.slice(0, 5),
                horaFim: horario.horaFim.slice(0, 5),
                disponivel: disponivel,
                status: status,
                situacaoReserva: situacaoReserva,
                liberaReserva: liberaReserva,
                diasDistanciamento: diasDistanciamento,
                pessoa: pessoa,
                evento: reserva.evento,
                observacao: reserva.observacao,
                dataCancelamento: dataCancelamento,
                diasCancelamento: diasCancelamento,
                reservaEfetivacao: reservaEfetivacao,
                reservaEvento: reservaEvento,
                vagasDisponiveis: horariosServico[0].vagasDisponiveis,
              });
            }
          }
        } else {
          disponivel = true;
          if (horarios.length <= 1) {
            listaRetorno.push({
              id: servico.id,
              nome: servico.nome,
              valor: servico.valor,
              horaInicio: horario.horaInicio.slice(0, 5),
              horaFim: horario.horaFim.slice(0, 5),
              disponivel: disponivel,
              status: status,
              situacaoReserva: situacaoReserva,
              liberaReserva: liberaReserva,
              diasDistanciamento: diasDistanciamento,
              pessoa: pessoa,
              dataCancelamento: dataCancelamento,
              diasCancelamento: diasCancelamento,
              vagasDisponiveis: horariosServico[0].vagasDisponiveis,
            });
          }
        }
      }
      if (horarios.length > 1) {
        situacaoReserva = ""; // Libera o botão de reserva
        if (pessoaReservou) {
          status = "Reservada por você";
          disponivel = true;
        } else if (disponivel) {
          status = "Disponível";
        } else {
          status = "Sem vagas disponíveis";
        }

        listaRetorno.push({
          id: servico.id,
          nome: servico.nome,
          valor: servico.valor,
          horaInicio: null,
          horaFim: null,
          horariosServico: horariosServico,
          disponivel: disponivel,
          status: status,
          situacaoReserva: situacaoReserva,
          liberaReserva: liberaReserva,
          diasDistanciamento: diasDistanciamento,
          pessoa: { id: null, nome: null },
          dataCancelamento: dataCancelamento,
          diasCancelamento: diasCancelamento,
        });
      }
    }
    setTimeout(async () => {
      listaRetorno = this.ordenaNomes(listaRetorno);

      listaRetorno.map((e) => {
        return {
          disponivel: e.disponivel,
          liberaReserva: e.liberaReserva,
          situacaoReserva: e.situacaoReserva,
          status: e.status,
        };
      });

      this.setState({ listaServicos: listaRetorno });
      this.setState({ loading: false });

    }, 300);


    this.buscaReservasAdmin();
  };

  voltar = async (id) => {
    this.setState({
      listaServico: [],
      servicos: false,
      grupoServico: null,
      dataBusca: moment().format("YYYY-MM-DD"),
      somente_convidados_presentes: false,
    });
  };

  voltarTurmas = async () => {
    this.setState({
      listaTurmas: [],
      turmas: false,
      somente_convidados_presentes: false,
    });
  };

  listaParametroGrupoServico = async () => {
    const resposta = await api.post(
      "/parametrosGrupoReserva/lista" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    this.setState({ listaParametroGrupoServico: resposta.data });
  };

  listaGrupoServico = async () => {
    const resposta = await api.post(
      "/grupoServico/lista" + requisicoes.entidade,
      "",
      requisicoes.header
    );

    let verificador = [];
    const turmas = await api.post(
      "/servico/turmaPorGrupoServico" + requisicoes.entidade,
      "",
      requisicoes.header
    );

    for (let i = 0; i < turmas.data.length; i++) {
      const element = turmas.data[i];
      if (verificador[element.grupo_servico_id] === undefined) {
        verificador[element.grupo_servico_id] = { turma: true, reserva: false };
      } else {
        continue;
      }
    }

    const reservas = await api.post(
      "/servico/servicoPorGrupoServico" + requisicoes.entidade,
      "",
      requisicoes.header
    );

    for (let i = 0; i < reservas.data.length; i++) {
      const element = reservas.data[i];
      if (verificador[element.grupo_servico_id] === undefined) {
        verificador[element.grupo_servico_id] = { turma: false, reserva: true };
      } else {
        verificador[element.grupo_servico_id].reserva = true;
      }
    }

    for (let i = 0; i < resposta.data.length; i++) {
      const element = resposta.data[i];
      if (verificador[element.id] !== undefined) {
        element.turma = verificador[element.id].turma;
        element.reserva = verificador[element.id].reserva;
      } else {
        element.turma = false;
        element.reserva = false;
      }
    }
    this.setState({ listaGrupoServico: resposta.data });

    if (
      this.props.permissoes.find(
        (e) => e.nome === "permissoes_professor_modalidade"
      ) ||
      localStorage.getItem("tipo_usuario_clube") === "modalidade"
    ) {
      const respostaUsuarioLogado = await api.post(
        "/usuario/usuarioLogado" + requisicoes.entidade,
        {},
        requisicoes.header
      );

      const resposta2 = await api.post(
        "/usuarioGruposServico/buscaGruposUsuario" + requisicoes.entidade,
        { usuario_id: respostaUsuarioLogado.data.id },
        requisicoes.header
      );

      var listaUsuarioGrupoServico = [];
      for (let i = 0; i < resposta.data.length; i++) {
        const element = resposta.data[i];

        for (let j = 0; j < resposta2.data.length; j++) {
          const element2 = resposta2.data[j];

          if (element2.grupo_servico_id === element.id) {
            listaUsuarioGrupoServico.push(element);
          }
        }
      }
      this.setState({ listaUsuarioGrupoServico: listaUsuarioGrupoServico });
    }
    if (this.props.location.query !== undefined) {
      let grupo_servico_id =
        this.props.location.query.grupo_servico_id_matriculas;
      if (localStorage.getItem("pagina_anterior_reservas") === "turmas") {
        this.carregaListaTurmas(grupo_servico_id);
      }
    }
  };

  carregaListaTurmas = async (id) => {
    const resposta = await api.post(
      "/servico/turmaPorGrupoServicoeData" + requisicoes.entidade,
      { id: id, data: this.state.dataBuscaTurma },
      requisicoes.header
    );
    this.setState({
      grupoServicoTurma: id,
    });
    this.setState({
      listaTurmas: resposta.data,
    });
    this.setState({
      turmas: true,
    });
  };

  cancelaEfetivaReserva = async (id, reserva_horario_id) => {
    if (
      !this.state.motivo ||
      this.state.motivo.trim() === "" ||
      this.state.motivo.trim().length < 10
    ) {
      return alert("Informe um motivo para o cancelamento");
    }
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    const responsavel_id = usuarioLogado.data.pessoa_id;
    await api.post(
      "/reservaEfetivada/cancelar" + requisicoes.entidade,
      {
        id: id,
        reserva_horario_id: reserva_horario_id,
        motivo: this.state.motivo,
        responsavel_id: responsavel_id,
      },
      requisicoes.header
    );
    this.setState({ modalOpen: false });
    this.listaReservasEfetivacao();

    await api.post(
      "/reservaEfetivada/gerarPreReservaCancelada" + requisicoes.entidade,
      {
        id: id,
        reserva_horario_id: reserva_horario_id,
        motivo: this.state.motivo,
      },
      requisicoes.header
    );

    this.imprimeComprovantePreReserva();
  };

  imprimeComprovantePreReserva = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api.get(
      "/reservaEfetivada/buscarPreReservaCancelada?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&id=" +
      this.state.pessoa_id
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "reservaEfetivada/buscarPreReservaCancelada?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&id=" +
      this.state.pessoa_id;
    oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";

    oReq.onload = async () => {
      const file = await new Blob([oReq.response], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL, "_blank");
    };

    oReq.send();
  };

  abreModal = async (e) => {
    this.setState({ modalOpen: e });
  };

  abreModalEfetiva = async (e, valor) => {
    this.setState({ modalOpenEfetiva: e });
    this.setState({ valorEfetivacao: valor });
    if (this.state.reserva_efetivada_id !== null) {
      window.location.href = "/reservas";
    }
  };
  verificaDatas = () => {
    if (!moment(this.state.data_inicio, "YYYY-MM-DD", true).isValid()) {
      this.setState({
        data_inicio: moment().startOf("month").format("YYYY-MM-DD"),
      });
    }
    if (!moment(this.state.data_final, "YYYY-MM-DD", true).isValid()) {
      this.setState({ data_final: moment().format("YYYY-MM-DD") });
    }
  };

  pesquisaReservas = async (activePage = 1) => {
    await this.verificaDatas();

    const resposta = await api.post(
      "/reservaHorario/reservaPorDataEGrupoServico" + requisicoes.entidade,
      {
        grupo_servico_id:
          this.state.grupoServicoSelecionado === "Todos" ||
            this.state.grupoServicoSelecionado === "Canceladas"
            ? 0
            : this.state.grupoServicoSelecionado,
        data_inicial: this.state.data_inicio,
        data_final: this.state.data_final,
        activePage: activePage,
        situacao:
          this.state.grupoServicoSelecionado === "Canceladas"
            ? "cancelado"
            : "ativa",
      },
      requisicoes.header
    );

    await resposta.data.docs.sort(function (a, b) {
      if (Date.parse(a.dataReserva) === Date.parse(b.dataReserva)) {
        return a.horario_servico.servico.nome > b.horario_servico.servico.nome
          ? 1
          : -1;
      }
      return Date.parse(a.dataReserva) < Date.parse(b.dataReserva) ? 1 : -1;
    });
    this.setState({
      listaReservasRelatorio: resposta.data.docs,
      activePage1: activePage,
      totalPaginas1: resposta.data.pages,
    });
  };

  pesquisaMatriculas = async (activePage = 1) => {
    await this.verificaDatas();

    const resposta = await api.post(
      "/matriculaTurma/matriculaPorDataEGrupoServico" + requisicoes.entidade,
      {
        checkboxRelatorio: this.state.checkboxRelatorio,
        grupo_servico_id:
          this.state.grupoServicoSelecionado === "Todos" ||
            this.state.grupoServicoSelecionado === "Canceladas"
            ? 0
            : this.state.grupoServicoSelecionado,
        servico_id: this.state.servicoSelecionado,
        turma_id: this.state.turmaSelecionada,
        data_inicial: this.state.data_inicio,
        data_final: this.state.data_final,
        activePage: activePage,
        situacao:
          this.state.grupoServicoSelecionado === "Canceladas"
            ? "cancelado"
            : "ativa",
      },
      requisicoes.header
    );

    await resposta.data.docs.sort(function (a, b) {
      if (a.turma_servico.nome.trim() === b.turma_servico.nome.trim()) {
        return Date.parse(a.dataMatricula) > Date.parse(b.dataMatricula)
          ? 1
          : -1;
      }
      return Date.parse(a.dataMatricula) < Date.parse(b.dataMatricula) ? 1 : -1;
      // a.turma_servico.nome
      //   .trim()
      //   .localeCompare(b.turma_servico.nome.trim());
    });
    this.setState({
      listaMatriculasRelatorio: resposta.data.docs,
      activePage: activePage,
      totalPaginas: resposta.data.pages,
      totalRegistro: resposta.data.total,
    });
  };

  gerarRelatorioMatricula = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado?e=" + entidade,
      {},
      {
        headers: { token: "Baer " + token },
      }
    );
    const nomePessoa = await api.post(
      "/pessoa/pessoaIdNome?e=" + entidade,
      { id: usuarioLogado.data.pessoa_id },
      {
        headers: { token: "Baer " + token },
      }
    );

    await api.post(
      "/matriculaTurma/relatorioMatricula?e=" + entidade,
      {
        userId: usuarioLogado.data.pessoa_id,
        nome_operador: nomePessoa.data.nome,
        data_inicio: this.state.data_inicio,
        data_final: this.state.data_final,
        //lista_matriculas: this.state.listaMatriculasRelatorio,

        checkboxRelatorio: this.state.checkboxRelatorio,
        grupo_servico_id:
          this.state.grupoServicoSelecionado === "Todos" ||
            this.state.grupoServicoSelecionado === "Canceladas"
            ? 0
            : this.state.grupoServicoSelecionado,
        servico_id: this.state.servicoSelecionado,
        turma_id: this.state.turmaSelecionada,
        data_inicial: this.state.data_inicio,
        situacao:
          this.state.grupoServicoSelecionado === "Canceladas"
            ? "cancelado"
            : "ativa",
      },
      requisicoes.header
    );

    const r = await api.get(
      "/matriculaTurma/arquivoRelatorioMatricula?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.pessoa_id
    ); //
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "matriculaTurma/arquivoRelatorioMatricula?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.pessoa_id; //
    await oReq.open("GET", URLToPDF, true);

    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  gerarRelatorioReservas = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado?e=" + entidade,
      {},
      {
        headers: { token: "Baer " + token },
      }
    );
    const nomePessoa = await api.post(
      "/pessoa/pessoaIdNome?e=" + entidade,
      { id: usuarioLogado.data.pessoa_id },
      {
        headers: { token: "Baer " + token },
      }
    );

    await api.post(
      "/reservaHorario/relatorioReserva?e=" + entidade,
      {
        userId: usuarioLogado.data.pessoa_id,
        nome_operador: nomePessoa.data.nome,
        data_inicio: this.state.data_inicio,
        data_final: this.state.data_final,
        //lista_reservas: this.state.listaReservasRelatorio,

        grupo_servico_id:
          this.state.grupoServicoSelecionado === "Todos" ||
            this.state.grupoServicoSelecionado === "Canceladas"
            ? 0
            : this.state.grupoServicoSelecionado,
        data_inicial: this.state.data_inicio,
        situacao:
          this.state.grupoServicoSelecionado === "Canceladas"
            ? "cancelado"
            : "ativa",
      },
      requisicoes.header
    );

    const r = await api.get(
      "/reservaHorario/arquivoRelatorioReserva?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.pessoa_id
    ); //
    var oReq = new XMLHttpRequest();
    var URLToPDF =
      r.config.baseURL +
      "reservaHorario/arquivoRelatorioReserva?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.pessoa_id; //
    await oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  imprimirContratoEfetivacao = async (element) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado?e=" + entidade,
      {},
      { headers: { token: "Baer " + token } }
    );
    const nomePessoa = await api.post(
      "/pessoa/pessoaIdNome?e=" + entidade,
      { id: usuarioLogado.data.pessoa_id },
      { headers: { token: "Baer " + token } }
    );

    // const acaoPessoa = await api.post(
    //   "/acao/visualiza?e=" + entidade,
    //   { id: element.pessoa.acao_id },
    //   { headers: { token: "Baer " + token } }
    // );

    const listaConvidados = await api.post(
      "/presencaReserva/presencaReservaPorHorario" + requisicoes.entidade,
      { id: element.reserva_horario_id },
      requisicoes.header
    );

    if (element.lancamento_conta_id !== null) {
      const lancamento_conta = await api.post(
        "/lancamentoConta/visualiza" + requisicoes.entidade,
        { id: element.lancamento_conta_id },
        requisicoes.header
      );
      element.lancamentoConta = lancamento_conta.data;
    }
    const lancamentos = await api.post(
      "/reservaEfetivada/buscaLancamentos" + requisicoes.entidade,
      { id: element.reserva_id },
      requisicoes.header
    );

    if (
      lancamentos.data.lancamentos !== null &&
      lancamentos.data.lancamentos !== undefined
    ) {
      element.lancamentos = lancamentos.data.lancamentos;
    } else {
      element.lancamentos = [];
    }

    // element.pessoa.acao = acaoPessoa.data;
    element.listaConvidados = listaConvidados.data;

    await api.post(
      "/reservaHorario/contratoEfetivada?e=" + entidade,
      {
        userId: usuarioLogado.data.id,
        nome_operador: nomePessoa.data.nome,
        dados: element,
      },
      requisicoes.header
    );

    const r = await api.get(
      "/reservaHorario/arquivoContratoEfetivada?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.id
    );

    var oReq = new XMLHttpRequest();
    var URLToPDF =
      r.config.baseURL +
      "reservaHorario/arquivoContratoEfetivada?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.id;

    await oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  listaReservasEfetivacao = async () => {
    const resposta = await api.post(
      "/reservaEfetivada/lista" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    this.setState({ listaReservasEfetivacao: resposta.data });
    this.setState({ modalOpenEfetiva: false });
  };

  efetivaReserva = async (id, servico, reserva_horario, pessoa) => {
    this.state.efetivacaoReserva = true
    this.setState({ efetivacaoReserva: true })
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    const responsavel_id = usuarioLogado.data.pessoa_id;
    await api.post(
      "/reservaEfetivada/efetivar" + requisicoes.entidade,
      {
        id: id,
        servico_id: servico,
        reserva_horario_id: reserva_horario,
        pessoa_id: pessoa,
        valor: this.state.valorEfetivacao,
        data_vencimento: this.state.dataVencimentoEfetivacao,
        responsavel_id: responsavel_id,
      },
      requisicoes.header
    );
    //Chamar o método de imprimir o contrato
    await this.listaReservasEfetivacao();
    // this.setState=({efetivacaoReserva: false})
    this.state.efetivacaoReserva = false

    this.setState({ efetivacaoReserva: false })
    if (this.props.menus.find((e) => e.url === "/caixa")) {
      this.setState({
        open_modal_cobranca: true,
        reserva_efetivada_id: null,
        pessoa_reserva_efetivada_id: pessoa,
      });
    } else {
      if (this.state.reserva_efetivada_id !== null) {
        window.location.href = "/reservas";
      }
    }
  };

  openModalReservaDados = (
    status,
    nome,
    pessoa,
    dataCancelamento,
    diasCancelamento,
    evento,
    observacao,
    reservaEfetivacao,
    reservaEvento,
    valor
  ) => {
    this.setState({ modalReservaDados: status });
    this.setState({
      reservaDados: {
        nome: nome,
        pessoa: pessoa.nome,
        dataCancelamento: dataCancelamento,
        diasCancelamento: diasCancelamento,
        evento: evento,
        observacao: observacao,
        reservaEfetivacao: reservaEfetivacao,
        reservaEvento: reservaEvento,
        valor: valor,
      },
    });
  };

  render() {
    let content = "";
    switch (this.state.selectedMenu) {
      case "mapa":
        localStorage.setItem("pagina_anterior_reservas", "mapa");
        content = <Mapa />;
        break;
      case "minhas":
        localStorage.setItem("pagina_anterior_reservas", "minhas");
        content = content = <MinhasReservas />;
        break;
      case "reservas":
        localStorage.setItem("pagina_anterior_reservas", "reservas");
        content = (
          <Reservas2
            usuarioLogado={this.state.usuarioLogado}
            permissoes={this.props.permissoes}
            servicos={this.state.servicos}
            pessoa={this.state.pessoa}
            alteracaoSelectPessoa={this.alteracaoSelectPessoa}
            options={this.state.options}
            pesquisaPessoa={this.pesquisaPessoa}
            dataBusca={this.state.dataBusca}
            alteracao={this.alteracao}
            carregaListaServico={this.carregaListaServico}
            grupoServico={this.state.grupoServico}
            voltar={this.voltar}
            loading={this.state.loading}
            listaServicos={this.state.listaServicos}
            openModalReservaDados={this.openModalReservaDados}
            listaReservasAdmin={this.state.listaReservasAdmin}
            carregaListaConvidados={this.carregaListaConvidados}
            presencaSocio={this.state.presencaSocio}
            setSocioResponsavel={this.setSocioResponsavel}
            imprimirListaConvidados={this.imprimirListaConvidados}
            somente_convidados_presentes={
              this.state.somente_convidados_presentes
            }
            mostraListaConvidadosPresentes={this.mostraListaConvidadosPresentes}
            buscandoListaPresencaReservas={
              this.state.buscandoListaPresencaReservas
            }
            listaPresencaReservas={this.state.listaPresencaReservas}
            carregaListaFornecedor={this.carregaListaFornecedor}
            imprimirListaFornecedor={this.imprimirListaFornecedor}
            buscandoListaFornecedorReserva={
              this.state.buscandoListaFornecedorReserva
            }
            listaFornecedorReserva={this.state.listaFornecedorReserva}
            confirmaPresencaFornecedor={this.confirmaPresencaFornecedor}
            listaGrupoServico={this.state.listaGrupoServico}
            confirmaPresenca={this.confirmaPresenca}
            listaParametroGrupoServico={this.state.listaParametroGrupoServico}
          />
        );
        break;
      case "turmas":
        localStorage.setItem("pagina_anterior_reservas", "turmas");
        content = (
          <Turmas
            turmas={this.state.turmas}
            dataBuscaTurma={this.state.dataBusca}
            alteracao={this.alteracao}
            carregaListaTurmas={this.carregaListaTurmas}
            voltarTurmas={this.voltarTurmas}
            listaTurmas={this.state.listaTurmas}
            listaGrupoServico={this.state.listaGrupoServico}
            listaUsuarioGrupoServico={this.state.listaUsuarioGrupoServico}
          />
        );
        break;
      case "efetivacao":
        localStorage.setItem("pagina_anterior_reservas", "efetivacao");
        content = (
          <Efetivacao
            submenus={this.props.submenus}
            pessoa={this.state.pessoa}
            alteracaoSelectPessoa={this.alteracaoSelectPessoa}
            options={this.state.options}
            pesquisaPessoa={this.pesquisaPessoa}
            dataBuscaEfetivas={this.state.dataBuscaEfetivas}
            alteracao={this.alteracao}
            checkboxBuscarData={this.state.checkboxBuscarData}
            setState={(a) => this.setState(a)}
            selectReservaEfetivacao={this.state.selectReservaEfetivacao}
            buscaReservasEfetivadaAdmin={this.buscaReservasEfetivadaAdmin}
            efetivacaoReserva={this.state.efetivacaoReserva}
            loadingListaReservaEfetivacaoAdmin={
              this.state.loadingListaReservaEfetivacaoAdmin
            }
            listaReservasEfetivacaoAdmin={
              this.state.listaReservasEfetivacaoAdmin
            }
            carregaListaConvidados={this.carregaListaConvidados}
            imprimirListaConvidados={this.imprimirListaConvidados}
            listaPresencaReservas={this.state.listaPresencaReservas}
            confirmaPresenca={this.confirmaPresenca}
            carregaListaFornecedor={this.carregaListaFornecedor}
            imprimirListaFornecedor={this.imprimirListaFornecedor}
            listaFornecedorReserva={this.state.listaFornecedorReserva}
            confirmaPresencaFornecedor={this.confirmaPresencaFornecedor}
            imprimirContratoEfetivacao={this.imprimirContratoEfetivacao}
            pessoa_reserva_efetivada_id={this.state.pessoa_reserva_efetivada_id}
            open_modal_cobranca={this.state.open_modal_cobranca}
            reservasEfetivacao={this.state.reservasEfetivacao}
            listaReservasEfetivacao={this.state.listaReservasEfetivacao}
            modalOpenEfetiva={this.state.modalOpenEfetiva}
            reserva_efetivada_id={this.state.reserva_efetivada_id}
            abreModalEfetiva={this.abreModalEfetiva}
            valorEfetivacao={this.state.valorEfetivacao}
            dataVencimentoEfetivacao={this.state.dataVencimentoEfetivacao}
            efetivaReserva={this.efetivaReserva}
            modalOpen={this.state.modalOpen}
            abreModal={this.abreModal}
            cancelaEfetivaReserva={this.cancelaEfetivaReserva}
          />
        );
        break;
      case "relatorio":
        localStorage.setItem("pagina_anterior_reservas", "relatorio");
        content = (
          <Relatorio
            listaGrupoServico={this.state.listaGrupoServico}
            listaServicosRelatorio={this.state.listaServicosRelatorio}
            listaTurmasRelatorio={this.state.listaTurmasRelatorio}
          />
        );
        break;

      default:
        <></>;
        // localStorage.setItem("pagina_anterior_reservas", "turmas");
        // this.setState({ selectedMenu: "turmas"  });
        break;
    }
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Reservas
        </Header>
        <Menu tabular icon="labeled">
          {this.props.submenus.find((e) => e.nome === "reservas") && (
            <Menu.Item
              name="reservas"
              active={this.state.selectedMenu === "reservas"}
              onClick={this.selectMenu.bind(null, "reservas")}
            >
              <Icon name="table" />
              Reservas de Serviços
            </Menu.Item>
          )}
          {this.props.submenus.find((e) => e.nome === "minhas_reservas") && (
            <Menu.Item
              name="minhas"
              active={this.state.selectedMenu === "minhas"}
              onClick={this.selectMenu.bind(null, "minhas")}
            >
              <Icon name="clipboard outline" />
              Minhas Reservas
            </Menu.Item>
          )}
          {this.props.submenus.find((e) => e.nome === "matriculas") && (
            <Menu.Item
              name="turmas"
              active={this.state.selectedMenu === "turmas"}
              onClick={this.selectMenu.bind(null, "turmas")}
            >
              <Icon name="users" />
              Matriculas
            </Menu.Item>
          )}
          {this.props.submenus.find((e) => e.nome === "efetivacao") && (
            <Menu.Item
              name="efetivacao"
              active={this.state.selectedMenu === "efetivacao"}
              onClick={this.selectMenu.bind(null, "efetivacao")}
            >
              <Icon name="cogs" />
              Efetivação
            </Menu.Item>
          )}
          {this.props.submenus.find((e) => e.nome === "mapa") && (
            <Menu.Item
              name="mapa"
              active={this.state.selectedMenu === "mapa"}
              onClick={this.selectMenu.bind(null, "mapa")}
            >
              <Icon name="map outline" />
              Mapa
            </Menu.Item>
          )}
          {this.props.submenus.find((e) => e.nome === "relatorio") && (
            <Menu.Item
              name="relatorio"
              active={this.state.selectedMenu === "relatorio"}
              onClick={this.selectMenu.bind(null, "relatorio")}
            >
              <Icon name="th list" />
              Relatórios
            </Menu.Item>
          )}
        </Menu>
        {content}
        <Modal
          closeIcon
          size="mini"
          open={this.state.alert}
          style={{ textAlign: "center" }}
        >
          <Header icon>
            <Icon name="folder open outline" />
            NÃO ENCONTRAMOS NADA AQUI
          </Header>
          <Modal.Content>ESSE GRUPO NÃO POSSUI SERVIÇOS </Modal.Content>
        </Modal>

        <Modal closeIcon size="large" open={this.state.modalReservaDados}>
          <Modal.Header as="h1" dividing>
            Reserva
          </Modal.Header>
          <Modal.Content>
            <Segment.Group raised>
              <Segment>
                <Form.Label as="h3">Nome: </Form.Label>
                {this.state.reservaDados.nome}
              </Segment>
              <Segment>
                <Form.Label as="h3">Valor: </Form.Label>
                {this.state.reservaDados.valor}
              </Segment>
              <>
                {this.state.reservaDados.reservaEfetivacao === true && (
                  <Segment.Group horizontal raised>
                    <Segment clearing>
                      <Form.Label as="h3">
                        Dias para cancelamento automático:{" "}
                      </Form.Label>
                      {this.state.reservaDados.diasCancelamento}
                    </Segment>
                    <Segment clearing>
                      <Form.Label as="h3">
                        Data para cancelamento automático:{" "}
                      </Form.Label>
                      {this.state.reservaDados.dataCancelamento}
                    </Segment>
                  </Segment.Group>
                )}
                {this.state.reservaDados.reservaEvento === true && (
                  <>
                    <Segment>
                      <Form.Label as="h3">Descrição do Evento: </Form.Label>
                      {this.state.reservaDados.evento}
                    </Segment>
                  </>
                )}
              </>
              <Segment>
                <Form.Label as="h3">
                  Pessoa: {this.state.reservaDados.pessoa}
                </Form.Label>
              </Segment>
            </Segment.Group>
          </Modal.Content>
          <Modal.Actions
            style={{
              textAlign: "right",
              paddingTop: 10,
            }}
          >
            <Button
              color="red"
              inverted
              onClick={() =>
                this.setState({
                  modalReservaDados: false,
                })
              }
            >
              <Icon name="remove" /> Fechar
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
  };
}
