import React, { Component } from "react";
import api from "../../../../services/api";
import { Container, Table, Button, Menu, Icon } from "semantic-ui-react";
import { Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { format, addDays } from "date-fns";
import { TextField } from "@material-ui/core";

export default class ExameMedicoLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaExame: [],
      pagina: 1,
      totalPaginas: 1,
      modalMedicoOpen:false,
    };
    this.listaExame();
  }
 abreModalMedico = async (e) => {
    this.setState({
      modalMedicoOpen: e,
      
    });
  };
  listaExame = async (page = 1) => {
    var localStorage = window.localStorage;
    var entidade = localStorage.getItem("e_clube");
    var token = localStorage.getItem("token_clube");
    const exameMedico = await api.post(
      "/exameMedico/lista?e=" + entidade + "&pagina=" + page,
      "",
      {
        headers: {
          token: "Baer " + token,
        },
      }
    );
    console.log("exane Medico")
    const { exames, pagina, totalPaginas } = exameMedico.data;
    this.setState({ listaExame: exames, pagina, totalPaginas });
  };

  proximaPagina = async () => {
    const { pagina, totalPaginas } = this.state;
    if (pagina >= totalPaginas) return;
    const paginaNumero = parseInt(pagina) + 1;
    this.listaExame(paginaNumero);
  };

  voltarPagina = async () => {
    const { pagina } = this.state;

    if (pagina <= 1) return;

    const paginaNumero = pagina - 1;

    this.listaExame(paginaNumero);
  };

  pesquisaPessoa = async () => {
    let term = document.getElementById("pesquisaPessoa").value;
    // if (term.toString().indexOf("%") !== -1) {
    //   term = document
    //     .getElementById("pesquisaPessoa")
    //     .value.toString()
    //     .replace("%", "");
    //   document.getElementById("pesquisaPessoa").value = term;
    //   if (document.getElementById("porcentagem").innerText === "%") {
    //     document.getElementById("porcentagem").innerText = "";
    //   } else {
    //     document.getElementById("porcentagem").innerText = "%";
    //   }
    // }
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    // if (document.getElementById("porcentagem").innerText === "%") {
    console.log("busca com porcentagem");
    const retorno = await api.post(
      "/exameMedico/buscaPorPessoa?e=" + entidade,
      { pesquisa: document.getElementById("pesquisaPessoa").value },
      { headers: { token: "Baer " + token } }
    );
    console.log("retorno",retorno)
    await this.setState({ listaExame: retorno.data });
    // } else {
    //   console.log("busca exata sem porcentagem");
    //   const retorno = await api.post(
    //     "/exameMedico/buscaPorPessoaExato?e=" + entidade,
    //     { pesquisa: document.getElementById("pesquisaPessoa").value },
    //     { headers: { token: "Baer " + token } }
    //   );
    //   await this.setState({ listaExame: retorno.data });
    // }
  };

  render() {
    return (
      <div className="lista">
        <Row>
          {/* <Col sm={1}>
            <Form.Label id="porcentagem"></Form.Label>
          </Col> */}
          <Col sm={6}>
            <TextField
              variant="standard"
              fullWidth
              id="pesquisaPessoa"
              placeholder="Digite para buscar"
              name="pessoa"
              onKeyDown={this.pesquisaPessoa}
              onChange={this.pesquisaPessoa}
              //inputId="pesquisaPessoa"
            />
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Pessoa</Table.HeaderCell>
              <Table.HeaderCell>Data Inicio</Table.HeaderCell>
              <Table.HeaderCell>Data Final</Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <Link to={{ pathname: "/exameMedico" }}>
                  <Button 
                    positive 
                    icon="add" 
                    size="tiny"
                    onClick={()=>{
                     this.abreModalMedico(true)
                    }}
                  />
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.listaExame !== null &&
            this.state.listaExame.pessoa !== null
              ? this.state.listaExame.map(
                  (e, i) =>
                    e.pessoa !== null && (
                      <Table.Row key={i}>
                        <Table.Cell>{e.pessoa.nome}</Table.Cell>
                        <Table.Cell>
                          {format(
                            addDays(new Date(e.dataExame), 1),
                            "dd/MM/yyyy"
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {format(
                            addDays(new Date(e.dataValidade), 1),
                            "dd/MM/yyyy"
                          )}
                        </Table.Cell>
                        <Table.Cell width={1}>
                          <Link
                            to={{
                              pathname: "/exameMedico",
                              query: { id: e.id },
                            }}
                          >
                            <Button 
                              primary 
                              icon="edit" 
                              size="tiny"
                              onClick={()=>{
                                this.abreModalMedico()
                              }}
                            
                            />                            
                          </Link>
                        </Table.Cell>
                      </Table.Row>
                    )
                )
              : null}
          </Table.Body>
          <Table.Footer>
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan={4}>
                <Menu pagination>
                  <Menu.Item
                    icon
                    onClick={this.voltarPagina}
                    disabled={this.state.pagina === 1}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  <Menu.Item
                    icon
                    onClick={this.proximaPagina}
                    disabled={this.state.pagina === this.state.totalPaginas}
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}
