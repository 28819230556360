import React, { Component } from "react";
import { Header, Modal, Button,Menu,Grid} from "semantic-ui-react";
import { Col, FormControl } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import api from "../../../services/api";

export default class ModalRfid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conteudo:null,
      catracas:[],
      rfidLido:null
    };
  }

  alteracao = (event) => {
    this.setState({[event.target.name]:event.target.value});
  }

  salvarFrid = async() => {
    if(this.state.conteudo !== null){
      console.log(this.props.pessoa);
      api.post(
        "/pessoa/alterar" + requisicoes.entidade+"&id="+this.props.pessoa.id,
        {rfid:parseInt(this.state.conteudo)},
        requisicoes.header
      );
      this.setState({ rfidLido: this.state.conteudo });
    }
}

  render(){
    return(
      <Modal
        trigger={
          <Button
            content="Rfid"
            icon="feed"
            color="blue"
            floated="right"
          ></Button>
        }
        size="small"
      >

          <Modal.Header>
            <Header as="h3">Conteudo Dispositivo</Header>
          </Modal.Header>


        <Modal.Content>
            <Col sm={12}>
              <b>Rfid Atual:</b> {this.state.rfidLido !== null ? this.state.rfidLido : this.props.pessoa.rfid !== null ? this.props.pessoa.rfid:"Está Pessoa não possui Rfid Salvo"}
            </Col>
            <Col sm={12} style={{marginTop:"10px"}}>
              <FormControl
                placeholder="Leitura Dispositivo"
                name="conteudo"
                onChange={this.alteracao}
                value={this.state.conteudo}
              />
            </Col>
              </Modal.Content>
              <Modal.Actions>
                  <Grid>
                    <Grid.Column textAlign="center">
                      <Button
                      content="Salvar"
                      color="blue"
                      size="tiny"
                      onClick={this.salvarFrid}
                    />
                    </Grid.Column>
                  </Grid>
              </Modal.Actions>
      </Modal>
    )
  }
}

