import React, { Component } from "react";
import { Tab, Tabs, Box } from "@material-ui/core/";

import Cargos from "./cargos";
import GrupoServico from "./grupoServico";

export default class ExercicioLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selecionado: "cargo",
    };
  }

  changeTabMenu = async (event, newValue) => {
    this.setState({ selecionado: newValue });
  };

  render() {
    return (
      <div className="lista">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            textColor="primary"
            variant="scrollable"
            style={{ outline: "none" }}
            onChange={this.changeTabMenu}
            value={this.state.selecionado}
          >
            <Tab style={{ outline: "none" }} label="Cargo" value={"cargo"} />
            <Tab
              style={{ outline: "none" }}
              label="Grupo Serviço"
              value={"grupoServico"}
            />
          </Tabs>
        </Box>

        <TabPanel value={this.state.selecionado} index={"cargo"}>
          <Cargos />
        </TabPanel>
        <TabPanel value={this.state.selecionado} index={"grupoServico"}>
          <GrupoServico />
        </TabPanel>
      </div>
    );
  }
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
