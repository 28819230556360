import React, { Component } from "react";
import { Header, Segment, Table } from "semantic-ui-react";
import { IconButton, Icon, ThemeProvider, Paper } from "@material-ui/core";

import { green } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

// requisicoes
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

// utils

// Componentes criados
import ModalAdicionarModulo from "./ModalAdicionarModulo";
export default class Modulos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Lista de dados
      listaModulos: this.props.listaModulos,

      // Modal
      openModalAdicionarModulo: false,
      moduloSelectedModalAdicionarModulo: null,
    };
  }

  excluirModulo = async (modulo_id) => {
    try {
      await api.post(
        "/modulo/excluir" + requisicoes.entidade,
        {
          id: modulo_id,
        },
        requisicoes.header
      );

      let lista = this.state.listaModulos;
      let index = lista.findIndex((e) => e.id === modulo_id);
      if (index !== -1) {
        lista.splice(index, 1);
      }

      this.setState({ listaModulos: lista });
    } catch (error) {
      alert(
        "Não foi possível excluir o módulo. Certifique-se de que não existe nenhum clube com este perfil liberado antes de excluí-lo."
      );
    }
  };

  render() {
    return (
      <div>
        <Segment>
          <Header>Cadastro de Modulos</Header>
          <Table celled compact>
            <Table.Header>
              <Table.HeaderCell width={1}>Id</Table.HeaderCell>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell collapsing>Nº rotas</Table.HeaderCell>
              <Table.HeaderCell collapsing>Nº Permissões</Table.HeaderCell>
              <Table.HeaderCell
                collapsing
                style={{
                  padding: 0,
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <IconButton
                    onClick={() =>
                      this.setState({ openModalAdicionarModulo: true })
                    }
                  >
                    <ThemeProvider
                      theme={createTheme({
                        palette: {
                          primary: green,
                        },
                      })}
                    >
                      <Icon color="primary">add_circle</Icon>
                    </ThemeProvider>
                  </IconButton>
                </div>
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {this.state.listaModulos.map((e, i) => (
                <Table.Row>
                  <Table.Cell>{e.id}</Table.Cell>
                  <Table.Cell>{e.nome}</Table.Cell>
                  <Table.Cell>{e.rotas.length}</Table.Cell>
                  <Table.Cell>{e.permissoes.length}</Table.Cell>

                  <Table.Cell
                    style={{
                      padding: 0,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        this.setState({
                          moduloSelectedModalAdicionarModulo: e,
                          openModalAdicionarModulo: true,
                        });
                      }}
                    >
                      <Icon color="primary">edit</Icon>
                    </IconButton>
                    <IconButton onClick={() => this.excluirModulo(e.id)}>
                      <Icon color="error">delete_icon</Icon>
                    </IconButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
        <ModalAdicionarModulo
          open={this.state.openModalAdicionarModulo}
          onClose={() =>
            this.setState({
              openModalAdicionarModulo: false,
              moduloSelectedModalAdicionarModulo: null,
            })
          }
          listaRotas={this.props.listaRotas}
          listaPermissoes={this.props.listaPermissoes}
          listaModulos={this.props.listaRotas}
          moduloSelectedModalAdicionarModulo={
            this.state.moduloSelectedModalAdicionarModulo
          }
          id={
            this.state.moduloSelectedModalAdicionarModulo
              ? this.state.moduloSelectedModalAdicionarModulo.id
              : null
          }
          addModulo={(e) => {
            let lista = this.state.listaModulos;
            let index = lista.findIndex((elm) => elm.id === e.id);
            if (index !== -1) {
              lista.splice(index, 1, e);
            } else {
              lista.push(e);
            }
            this.setState({ listaModulos: lista });
          }}
        />
      </div>
    );
  }
}
