import React, { Component } from "react";
import { Table, Input, Popup, Dimmer, Loader } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

import ModalComissionado from "./modalComissionado";

export default class ComissionadosLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      totalTurma: 0,
      ultimaBusca: null,
    };
    this.buscaComissionados({ target: { value: "" } });
  }

  alteracao(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  buscaComissionados = (event) => {
    this.setState({
      popUpPesquisa: false,
      ultimaBusca: event.target.value,
    });

    api
      .post(
        "/pessoa/buscaComissionados" + requisicoes.entidade,
        {
          pesquisa: event.target.value,
          comissionado: true,
        },
        requisicoes.header
      )
      .then((resp) => {
        if (resp.data.length === 0) {
          this.setState({ popUpPesquisa: true });
        } else {
          for (let i = 0; i < resp.data.length; i++) {
            const element = resp.data[i];
            let totalLancamentos = 0;
            let totalLancamentosPagos = 0;
            element.comisssaoAtual = 0.0;
            element.comissaoMax = 0.0;

            element.comissoes.forEach((element2) => {
              element2.novo = false;
              element2.alterado = false;

              let total = 0.0;
              let totalPago = 0.0;

              // for (let i = 0; i < element2.turma_servico.lancamentosPagos.length; i++) {
              //   const element3 = element2.turma_servico.lancamentosPagos[i];
              //   totalPago += parseFloat(element3.valor_pago);
              //   total += parseFloat(element3.valor_pago);
              //   totalLancamentosPagos += parseFloat(element3.valor_pago);
              // }

              // for (let i = 0; i < element2.turma_servico.lancamentosNaoPagos.length; i++) {
              //   const element3 = element2.turma_servico.lancamentosNaoPagos[i];
              //   total += parseFloat(element3.valor);
              // }
              if (element2.total_pago !== null) {
                totalLancamentosPagos = parseFloat(element2.total_pago);
                total += parseFloat(element2.total_pago);
                totalPago += parseFloat(element2.total_pago);
              }
              if (element2.total_nao_pago !== null) {
                total += parseFloat(element2.total_nao_pago);
              }

              totalLancamentos += parseFloat(total);
              element2.total = total;
              element2.totalPago = totalPago;
              element2.comisssaoAtual =
                totalPago * (parseFloat(element2.porcentagem) / 100);
              element2.comissaoMax =
                total * (parseFloat(element2.porcentagem) / 100);
              if (element2.data_inativacao === null) {
                element.comisssaoAtual += parseFloat(element2.comisssaoAtual);
                element.comissaoMax += parseFloat(element2.comissaoMax);
              }
            });
            element.totalLancamentos = totalLancamentos;
            element.totalLancamentosPagos = totalLancamentosPagos;

            element.comissaoAtualLancamentos = 0.0;
            element.comissaoEsperadaLancamentos = 0.0;
            element.NTurma = 0;
            element.quantidade_lancamento = 0;

            for (let j = 0; j < element.comissoes.length; j++) {
              const element3 = element.comissoes[j];
              if (element3.data_inativacao === null) {
                element.NTurma++;
              }
            }
            for (let j = 0; j < element.comissoes_lancamentos.length; j++) {
              const element4 = element.comissoes_lancamentos[j];
              if (element4.data_inativacao === null) {
                element.quantidade_lancamento++;
              }
            }

            for (let j = 0; j < element.comissoes_lancamentos.length; j++) {
              const element2 = element.comissoes_lancamentos[j];
              element2.alterado = false;
              if (!element2.lancamento_conta) {
                continue;
              }

              if (element2.lancamento_conta.valor_pago !== null) {
                element2.comissaoAtual =
                  element2.lancamento_conta.valor_pago *
                  (element2.porcentagem / 100);
                element2.comissaoEsperada =
                  element2.lancamento_conta.valor_pago *
                  (element2.porcentagem / 100);
                if (element2.data_inativacao === null) {
                  element.comissaoAtualLancamentos +=
                    element2.lancamento_conta.valor_pago *
                    (element2.porcentagem / 100);
                  element.comissaoEsperadaLancamentos +=
                    element2.lancamento_conta.valor_pago *
                    (element2.porcentagem / 100);
                }
              } else {
                element2.comissaoAtual = 0;
                element2.comissaoEsperada =
                  element2.lancamento_conta.valor *
                  (element2.porcentagem / 100);
                if (element2.data_inativacao === null) {
                  element.comissaoEsperadaLancamentos +=
                    element2.lancamento_conta.valor *
                    (element2.porcentagem / 100);
                }
              }
            }
          }

          this.setState({ lista: resp.data });
          this.setState({ totalTurma: this.state.totalTurma });
        }
      });
  };

  buscaUltimaBusca = () => {
    this.buscaComissionados({ target: { value: this.state.ultimaBusca } });
  };

  render() {
    return (
      <div className="lista">
        <Dimmer active={this.state.carregando} inverted>
          <Loader size="medium">Carregando...</Loader>
        </Dimmer>
        <Row>
          <Col sm={6}>
            <Popup
              content="Nenhum registro encontrado!"
              open={this.state.popUpPesquisa}
              position="right center"
              trigger={
                <Input
                  fluid
                  placeholder="Digite para buscar"
                  icon="search"
                  name="fornecedor"
                  onKeyUp={this.buscaComissionados}
                />
              }
            />
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Nº Turmas</Table.HeaderCell>
              <Table.HeaderCell>Nº Lancamentos</Table.HeaderCell>
              {/* <Table.HeaderCell textAlign="right">
                Comissão atual
              </Table.HeaderCell> */}
              <Table.HeaderCell textAlign="right">
                Total esperado
              </Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <ModalComissionado buscaUltimaBusca={this.buscaUltimaBusca} />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.state.lista.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>{e.nome}</Table.Cell>
                <Table.Cell>{e.NTurma}</Table.Cell>
                <Table.Cell>{e.quantidade_lancamento}</Table.Cell>
                {/* <Table.Cell textAlign="right">
                  {parseFloat(
                    parseFloat(e.comisssaoAtual) +
                      parseFloat(e.comissaoAtualLancamentos)
                  ).toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Table.Cell> */}
                <Table.Cell textAlign="right">
                  {parseFloat(
                    parseFloat(e.comissaoMax) +
                      parseFloat(e.comissaoEsperadaLancamentos)
                  ).toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Table.Cell>

                <Table.Cell width={1}>
                  <ModalComissionado
                    pessoa={e}
                    buscaUltimaBusca={this.buscaUltimaBusca}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}
