import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { IoMdAdd } from "react-icons/io";
import { Col, Form, Row, FormControl } from "react-bootstrap";
import {
  Table,
  Header,
  Button,
  Modal,
} from "semantic-ui-react";
import { Pagination } from "@material-ui/core";
import { parseISO, format } from "date-fns";
import ConverterParaLabelAcao from "../../../../utils/converteParaLabelComAcao";
import moment from "moment-timezone";
import { valorFormatter } from "../../../../utils/formatterValor";
import requisicoes from "../../../../utils/Requisicoes";
import renomearChave from "../../../../services/renomearChave";
import api from "../../../../services/api";

export default class MensalidadeLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagina: 1,
      totalPaginas: 1,
      listaMensalidade: [],
      listaMensalidade2: [],
      tipo: "nome",
      data_inicio: null,
      data_final: null,
      optionsP: [],
      pessoa_id: null,
      pessoa: null,
      ano: null,
      consulta_pessoa: null,
      total: (0).toFixed(2),
      open: false,
      activePage: 1
    };
    this.alteracaoSelectPessoa = this.alteracaoSelectPessoa.bind(this);
    this.alteracao = this.alteracao.bind(this);
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  listaMensalidade = async () => {
    const resposta = await api.post(
      "/mensalidade/lista" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    this.setState({ listaMensalidade: resposta.data });
  };

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ listaMensalidade: [] });
  };

  pesquisaPessoa = async () => {
    setTimeout(async () => {
      let term = document.getElementById("pesquisaPessoa").value;
      if (isFinite(String(term))) {
        await api
          .post(
            "/pessoa/pesquisaPorCodigoAcaoValorExato" + requisicoes.entidade,
            { pesquisa: document.getElementById("pesquisaPessoa").value },
            requisicoes.header
          )
          .then((retorno) => {
            this.setState({ optionsP: ConverterParaLabelAcao(retorno.data) });
          });
      } else {
        await api
          .post(
            "/pessoa/pesquisa" + requisicoes.entidade,
            { pesquisa: document.getElementById("pesquisaPessoa").value },
            requisicoes.header
          )
          .then((resultado) => {
            let lista = [];
            resultado.data.forEach((element) => {
              let objeto = renomearChave(element, "id", "value");
              objeto = renomearChave(objeto, "nome", "label");
              lista.push(objeto);
            });
            this.setState({ optionsP: lista });
          });
      }
    }, 500);
  };

  alteracaoSelectPessoa = async (event) => {
    this.setState({ pessoa_id: event.value });
    this.setState({ pessoa: event });
  };

  buscaTitulos = async (event, activePage = 1) => {
    event.preventDefault();
    console.log("event",event)
    this.setState({ loading: true });
    let lista = [];
    let listaTipoLancamento = [
      "mensalidade",
      "inscricao",
      "cobrancaServicoAvulsa",
      "servico",
    ];

    if (typeof activePage === 'object') {
      activePage = 1
    }
    console.log("data_final", this.state.data_final)
    var dataInicioAno =
      this.state.ano !== null
        ? moment(this.state.ano + "-01-01").format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
    var dataFinalAno =
      this.state.ano !== null
        ? moment(this.state.ano + "-12-31").format("YYYY-MM-DD")
        : null;
    if (this.state.data_final === null) {
      console.log("entrou")
      this.state.data_final = dataInicioAno
    }
    console.log("data_final 2", this.state.data_final)

    console.log("dataInicioAno", dataInicioAno)
    switch (this.state.tipo) {
      case "vencidas":
        const retorno = await api.post(
          "/lancamentoConta/lancamentoVencidosPorData" + requisicoes.entidade,
          {
            data_inicial: this.state.data_inicio,
            data_final: this.state.data_final,
            tipo_lancamento: listaTipoLancamento,
            activePage: activePage,
          },
          requisicoes.header
        );
        this.setState({ loading: false });
        this.setState({
          listaMensalidade2: retorno.data.docs,
          totalPaginas: retorno.data.pagina,
          activePage: activePage
        });

        break;
      case "aVencer":
        const retorno1 = await api.post(
          "/lancamentoConta/lancamentoAVencerPorData" + requisicoes.entidade,
          {
            data_inicial: this.state.data_inicio,
            data_final: this.state.data_final,
            tipo_lancamento: listaTipoLancamento,
            activePage: activePage,
          },
          requisicoes.header
        );
        this.setState({ loading: false });
        this.setState({
          listaMensalidade2: retorno1.data.docs,
          totalPaginas: retorno1.data.pages,
          activePage: activePage
        });
        break;
      case "nome":
        if (this.state.consulta_pessoa !== null) {
          switch (this.state.consulta_pessoa) {
            case "todas":
              let retornoPorNome = "";
              if (this.state.ano !== null && this.state.ano !== "") {
                retornoPorNome = await api.post(
                  "/lancamentoConta/lancamentoPorPessoaCodigoETipo" +
                  requisicoes.entidade,
                  {
                    pessoa_id: this.state.pessoa.value,
                    tipo_lancamento: listaTipoLancamento,
                    dataInicioVencimento: dataInicioAno,
                    dataFinalVencimento: dataFinalAno,
                    activePage: activePage,
                  },
                  requisicoes.header
                );
              } else {
                retornoPorNome = await api.post(
                  "/lancamentoConta/lancamentoPorPessoaCodigoETipo" +
                  requisicoes.entidade,
                  {
                    pessoa_id: this.state.pessoa.value,
                    tipo_lancamento: listaTipoLancamento,
                    activePage: activePage,
                  },
                  requisicoes.header
                );
              }
              this.setState({
                total: this.somaTotal(retornoPorNome.data).toFixed(2),
              });
              lista = retornoPorNome.data;
              this.setState({ loading: false });
              break;
            case "aPagar":
              let retornoAPagar = "";
              if (this.state.ano !== null && this.state.ano !== "") {
                retornoAPagar = await api.post(
                  //"/lancamentoConta/lancamentoEmAbertoPorPessoaMensalidade" +
                  "/lancamentoConta/lancamentoPorPessoaCodigoETipo" +
                  requisicoes.entidade,
                  {
                    pessoa_id: this.state.pessoa.value,
                    tipo_lancamento: listaTipoLancamento,
                    data_pagamento: null,
                    dataInicioVencimento: dataInicioAno,
                    dataFinalVencimento: dataFinalAno,
                    activePage: activePage,

                  },
                  requisicoes.header
                );
              } else {
                retornoAPagar = await api.post(
                  //"/lancamentoConta/pesquisaPorPessoaEmAbertoMensalidadeAno" +
                  "/lancamentoConta/lancamentoPorPessoaCodigoETipo" +
                  requisicoes.entidade,
                  {
                    pessoa_id: this.state.pessoa.value,
                    tipo_lancamento: listaTipoLancamento,
                    data_pagamento: null,
                    activePage: activePage,

                  },
                  requisicoes.header
                );
              }
              this.setState({
                total: this.somaTotal(retornoAPagar.data).toFixed(2),
              });
              lista = retornoAPagar.data;
              this.setState({ loading: false });

              break;
            case "pagas":
              let retornoPagas = "";
              if (this.state.ano !== null && this.state.ano !== "") {
                retornoPagas = await api.post(
                  // "/lancamentoConta/lancamentoPagoPorPessoaMensalidade" +
                  "/lancamentoConta/lancamentoPorPessoaCodigoETipo" +
                  requisicoes.entidade,
                  {
                    pessoa_id: this.state.pessoa.value,
                    tipo_lancamento: listaTipoLancamento,
                    data_pagamento: "",
                    dataInicioVencimento: dataInicioAno,
                    dataFinalVencimento: dataFinalAno,
                    activePage: activePage,

                  },
                  requisicoes.header
                );
              } else {
                retornoPagas = await api.post(
                  // "/lancamentoConta/lancamentoPagoPorPessoaMensalidadeAno" +
                  "/lancamentoConta/lancamentoPorPessoaCodigoETipo" +
                  requisicoes.entidade,
                  {
                    pessoa_id: this.state.pessoa.value,
                    tipo_lancamento: listaTipoLancamento,
                    data_pagamento: "",
                    activePage: activePage,

                  },
                  requisicoes.header
                );
              }
              this.setState({
                total: this.somaTotal(retornoPagas.data).toFixed(2),
              });
              lista = retornoPagas.data;
              this.setState({ loading: false });
              break;
            default:
              break;
          }
        }
        break;
      default:
        break;
    }

    let listaPorServico = [];
    lista.forEach((e) => {
      if (e.data_pagamento === null && moment().isBefore(e.data_vencimento)) {
        e.cor = { background: "#eeee" };
      } else if (
        e.data_pagamento === null &&
        moment().isAfter(e.data_vencimento)
      ) {
        e.cor = { background: "#ffcbc5" }; //#FA8072  #fa8173
      } else {
        e.cor = { background: "#a5e9a5" }; //#65da65
      }
      if (
        e.servico !== null &&
        e.valor !== null &&
        this.state.tipo !== "nome"
      ) {
        if (
          listaPorServico.find(
            (s) => s.servico !== null && s.servico.id === e.servico.id
          ) === undefined
        ) {
          listaPorServico.push(e);
        } else {
          let lancamento = listaPorServico.find(
            (s) => s.servico !== null && s.servico.id === e.servico.id
          );
          lancamento.valor = parseFloat(lancamento.valor) + parseFloat(e.valor);
          let indice = listaPorServico.findIndex(
            (element) =>
              element.servico !== null &&
              element.servico.id === parseInt(e.servico.id)
          );
          listaPorServico.splice(indice, 1);
          listaPorServico.push(lancamento);
        }
      } else {
        listaPorServico.push(e);
      }
    });
    console.log("listaPorServico", listaPorServico)
    this.setState({ listaMensalidade: listaPorServico });
  };

  somaTotal = (lista) => {
    let soma = parseFloat(0);
    lista.forEach((e) => {
      soma += parseFloat(e.valor);
    });
    return soma;
  };

  proximaPagina = async () => {
    const { pagina, totalPaginas } = this.state;

    if (pagina >= totalPaginas) return;
    const paginaNumero = parseInt(pagina) + 1;
    this.listaUsuario(paginaNumero);
  };

  voltarPagina = async () => {
    const { pagina } = this.state;
    if (pagina <= 1) return;
    const paginaNumero = pagina - 1;
    this.listaUsuario(paginaNumero);
  };

  render() {
    return (
      <div className="lista">
        <Row>
          <Col sm={2}>
            <Form.Label>Tipo</Form.Label>
            <Form.Control
              placeholder="Tipo"
              label="Tipo"
              id="tipo"
              as="select"
              name="tipo"
              onChange={this.alteracao}
              required
            >
              <option></option>
              <option value="nome">Nome</option>
              <option value="vencidas">Vencidas</option>
              <option value="aVencer">Á Vencer</option>
            </Form.Control>
          </Col>

          <Col sm={4} hidden={this.state.tipo !== "nome"}>
            <Form.Label>Busca</Form.Label>
            <Select
              placeholder="Digite para buscar"
              name="pessoa"
              onChange={this.alteracaoSelectPessoa}
              options={this.state.optionsP}
              onKeyDown={this.pesquisaPessoa}
              inputId="pesquisaPessoa"
            />
          </Col>
          <Col
            sm={5}
            hidden={
              this.state.tipo !== "vencidas" && this.state.tipo !== "aVencer"
            }
          >
            <Row>
              <Col sm={6}>
                <Form.Label>Data Inicio</Form.Label>
                <FormControl
                  placeholder="Data Inicio"
                  aria-describedby="basic-addon1"
                  type="date"
                  name="data_inicio"
                  onChange={this.alteracao}
                  required
                />
              </Col>
              <Col sm={6}>
                <Form.Label>Data Final</Form.Label>
                <FormControl
                  placeholder="Data Final"
                  aria-describedby="basic-addon1"
                  type="date"
                  name="data_final"
                  onChange={this.alteracao}
                />
              </Col>
            </Row>
          </Col>
          <Col
            sm={2}
            style={{ paddingTop: "26px" }}
            hidden={
              this.state.tipo === "vencidas" || this.state.tipo === "aVencer"
            }
          >
            <FormControl
              placeholder="Ano"
              aria-label="Ano"
              aria-describedby="basic-addon1"
              name="ano"
              onChange={this.alteracao}
              required
            />
          </Col>
          <Col
            sm={2}
            hidden={
              this.state.tipo === "vencidas" || this.state.tipo === "aVencer"
            }
            style={{ paddingTop: "25px" }}
          >
            <Form.Control
              as="select"
              name="consulta_pessoa"
              onChange={this.alteracao}
              required
            >
              <option></option>
              <option value="todas">Todas</option>
              <option value="aPagar">A Pagar</option>
              <option value="pagas">Pagas</option>
            </Form.Control>
          </Col>
          <Col sm={2}>
            <Button
              positive
              style={{ marginTop: "25px" }}
              onClick={this.buscaTitulos}
              disabled={this.state.loading}
              loading={this.state.loading}
            >
              Buscar
            </Button>
          </Col>
        </Row>
        {this.state.tipo === "nome" && (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nome</Table.HeaderCell>
                <Table.HeaderCell>Serviço</Table.HeaderCell>
                <Table.HeaderCell>Vencimento</Table.HeaderCell>
                <Table.HeaderCell>Pagamento</Table.HeaderCell>
                <Table.HeaderCell>Valor</Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  <Button
                    positive
                    icon="plus"
                    onClick={this.handleOpen}
                  ></Button>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {console.log("this.state.listaMensalidade", this.state.listaMensalidade)}
              {this.state.listaMensalidade.map((e, i) => (
                <Table.Row key={i} style={e.cor}>
                  <Table.Cell>{e.pessoa.nome}</Table.Cell>
                  <Table.Cell>
                    {e.servico !== null &&
                      e.servico !== undefined &&
                      e.servico.nome}
                  </Table.Cell>
                  <Table.Cell>
                    {e.data_vencimento !== null &&
                      format(parseISO(e.data_vencimento), "dd/MM/yyyy")}
                  </Table.Cell>
                  <Table.Cell>
                    {e.data_pagamento !== null &&
                      format(parseISO(e.data_pagamento), "dd/MM/yyyy")}
                  </Table.Cell>
                  <Table.Cell width={2} textAlign="right">
                    {e.valor !== undefined &&
                      parseFloat(e.valor).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell>
                  <b>Total:</b>
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  {this.state.total}
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
              <Table.Row verticalAlign="middle" textAlign="center">
                <Table.HeaderCell colSpan={6}>
                  {/* <Menu pagination>
                    <Menu.Item
                      icon
                      onClick={this.voltarPagina}
                      disabled={this.state.pagina === 1}
                    >
                      <Icon name="chevron left" />
                    </Menu.Item>
                    <Menu.Item
                      icon
                      onClick={this.proximaPagina}
                      disabled={this.state.pagina === this.state.totalPaginas}
                    >
                      <Icon name="chevron right" />
                    </Menu.Item>
                  </Menu> */}
                  <div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                    <Pagination
                      count={this.state.totalPaginas}
                      variant="outlined"
                      shape="rounded"
                      boundaryCount={1}
                      page={this.state.activePage}
                      onChange={this.buscaTitulos}
                    />
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
        {this.state.tipo === "vencidas" && (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Titulo</Table.HeaderCell>
                <Table.HeaderCell>Nome</Table.HeaderCell>
                <Table.HeaderCell>Valor</Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  <Button variant="success" onClick={this.handleOpen}>
                    <IoMdAdd />
                  </Button>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {console.log("this.state.listaMensalidade2", this.state.listaMensalidade2)}
              {this.state.listaMensalidade2.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell width={2}>
                    {e.titulo !== null && e.titulo}
                  </Table.Cell>
                  <Table.Cell width={8}>{e.pessoa_nome}</Table.Cell>
                  <Table.Cell width={2} textAlign="right">
                    {e.total_dividas !== undefined && valorFormatter.format(e.total_dividas)}
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row verticalAlign="middle" textAlign="center">
                <Table.HeaderCell colSpan={4}>
                  {/* <Menu pagination>
                    <Menu.Item
                      icon
                      onClick={this.voltarPagina}
                      disabled={this.state.pagina === 1}
                    >
                      <Icon name="chevron left" />
                    </Menu.Item>
                    <Menu.Item
                      icon
                      onClick={this.proximaPagina}
                      disabled={this.state.pagina === this.state.totalPaginas}
                    >
                      <Icon name="chevron right" />
                    </Menu.Item>
                  </Menu> */}
                  <div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                    <Pagination
                      count={this.state.totalPaginas}
                      variant="outlined"
                      shape="rounded"
                      boundaryCount={1}
                      page={this.state.activePage}
                      onChange={this.buscaTitulos}
                    />
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
        {this.state.tipo === "aVencer" && (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Titulo</Table.HeaderCell>
                <Table.HeaderCell>Nome</Table.HeaderCell>
                <Table.HeaderCell>Valor</Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  <Button variant="success" onClick={this.handleOpen}>
                    <IoMdAdd />
                  </Button>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.listaMensalidade2.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell width={2}>
                    {e.titulo !== null && e.titulo}
                  </Table.Cell>
                  <Table.Cell width={8}>{e.pessoa_nome}</Table.Cell>
                  <Table.Cell width={2} textAlign="right">
                    {e.total_dividas !== undefined &&
                      parseFloat(e.total_dividas).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row verticalAlign="middle" textAlign="center">
                <Table.HeaderCell colSpan={4}>
                  {/* <Menu pagination>
                    <Menu.Item
                      icon
                      onClick={this.voltarPagina}
                      disabled={this.state.pagina === 1}
                    >
                      <Icon name="chevron left" />
                    </Menu.Item>
                    <Menu.Item
                      icon
                      onClick={this.proximaPagina}
                      disabled={this.state.pagina === this.state.totalPaginas}
                    >
                      <Icon name="chevron right" />
                    </Menu.Item>
                  </Menu> */}
                  <div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                    <Pagination
                      count={this.state.totalPaginas}
                      variant="outlined"
                      shape="rounded"
                      boundaryCount={1}
                      page={this.state.activePage}
                      onChange={this.buscaTitulos}
                    />
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          centered={false}
        >
          <Header icon="wait" content="Páginas" />
          <Modal.Content>
            <Link
              to={{
                pathname: "/mensalidade",
                query: { tipo_cobranca: "boleto" },
              }}
            >
              <Button primary>Mensalidade Boleto</Button>
            </Link>
            <Link
              to={{
                pathname: "/mensalidade",
                query: { tipo_cobranca: "debito_em_conta" },
              }}
            >
              <Button primary>Mensalidade Debito Em Conta</Button>
            </Link>
            <Link to={{ pathname: "/mensalidade" }}>
              <Button primary>Outros</Button>
            </Link>
          </Modal.Content>
        </Modal>

      </div>
    );
  }
}
