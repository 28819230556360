import React, { Component } from "react";
import { Segment, Divider, Table, TableRow } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  IconButton,
  Icon,
  Chip,
  ThemeProvider,
  Checkbox,
  FormControlLabel,
  SvgIcon,
} from "@material-ui/core";

import { LoadingButton } from "@material-ui/lab";
import Draggable from "react-draggable";
import { Add, Delete, Visibility, Save } from "@material-ui/icons";

import mdiFormatLetterCaseLower from "@iconify-icons/mdi/format-letter-case-lower";
import mdiFormatLetterCaseUpper from "@iconify-icons/mdi/format-letter-case-upper";
import mdiFormatLetterCase from "@iconify-icons/mdi/format-letter-case";

import { Icon as Iconify } from "@iconify/react";

// Css
import "./draggable.css";
import { green } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

// Utils
import $ from "jquery";
import { storage } from "../../../../config/firebase";
import moment from "moment-timezone";

// Requisicoes
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

// Enums
import TipoCargo from "../../../../enum/TipoCargo";
import optionsCamposCarteirinhas from "./CamposCarteirinhas";

export default class Carteirinhas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Entradas
      carteirinha_id: "nova",
      carteirinhaSelected: null,

      nome: "",
      tipo_titulos: [],
      cargos: [],

      checkboxCargos: true,
      checkboxTipoTitulos: true,

      largura: 325,
      altura: 205,

      fundo: "cor",
      cor: "#FFFFFF",
      imagem: null,
      imagem64: null,

      fonte: "Arial",

      novoCampo: "",
      camposCarteirinhaInfo: [],
      camposExcluidos: [],

      // Options
      optionsCarteirinhas: [],
      optionsTipoTitulos: [],

      // Estados botões
      salvando: false,
      excluindo: false,
    };

    this.listaCarteirinhas();
    this.listaTipoTitulos();
  }

  listaCarteirinhas = async () => {
    const carteirinhas = await api.post(
      "/carteirinha/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    this.setState({ optionsCarteirinhas: carteirinhas.data });
  };

  listaTipoTitulos = async () => {
    const tipoTitulos = await api.post(
      "/tipoTitulo/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    this.setState({ optionsTipoTitulos: tipoTitulos.data });
  };

  handleUploadImage = (e, stateAltura, stateLargura) => {
    var image = new Image();
    let reader = new FileReader();

    let file = e.target.files[0];

    const setStateFile = (file, file64) => {
      this.setState({ imagem: file, imagem64: file64, cor: "" });

      $("#corpo").css("background-color", "#FFFFFF");
      $("#corpo").css("background-image", `url(${file64})`);
      $("#corpo").css("background-repeat", "no-repeat");
      $("#corpo").css(
        "background-size",
        `${this.state.largura}px ${this.state.altura}px`
      );
    };

    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      image.src = reader.result;
      image.onload = function () {
        var height = this.height;
        var width = this.width;

        let proporcaoCarteirinha = stateLargura / stateAltura;
        let proporcaoImagem = width / height;

        if (proporcaoCarteirinha !== proporcaoImagem) {
          alert(
            "A proporção largura/altura da carteirinha é diferente da proporção da imagem. " +
            "Isso pode causar distorções inesperadas na imagem, " +
            "principalmente se a diferença entre as proporções for muito grante.\n\n" +
            `Proporção carteirinha: ${proporcaoCarteirinha}  (${stateLargura}/${stateAltura})\n` +
            `Proporção imagem:      ${proporcaoImagem}  (${width}/${height})`
          );
        }
        setStateFile(file, reader.result);
      };
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  onSelectoptionCarteirinha = (e, a) => {
    if (a.props.value === "nova") {
      //Limpar os campos aqui
      this.setState({
        carteirinha_id: "nova",
        carteirinhaSelected: null,

        nome: "",
        tipo_titulos: [],
        cargos: [],

        largura: 325,
        altura: 205,

        fundo: "cor",
        cor: "#FFFFFF",
        imagem: null,
        imagem64: null,

        fonte: "Arial",

        novoCampo: "",
        camposCarteirinhaInfo: [],
        camposExcluidos: [],

        checkboxCargos: true,
        checkboxTipoTitulos: true,
      });
      $("#corpo").css("background-image", ``);
      $("#corpo").css("background-color", "#FFFFFF");
    } else {
      this.setState({
        carteirinha_id: a.props.value,
        carteirinhaSelected: a.props.option,

        nome: a.props.option.nome,
        tipo_titulos: a.props.option.tipo_titulos.map((e) => {
          return e.id;
        }),

        cargos: a.props.option.tipo_cargos.map((e) => {
          return e.tipo_cargo;
        }),

        largura: a.props.option.largura,
        altura: a.props.option.altura,

        checkboxCargos: a.props.option.restringe_cargo,
        checkboxTipoTitulos: a.props.option.restringe_tipo_titulo,

        fundo: a.props.option.fundo,
        cor: a.props.option.cor,
        imagem: null,
        imagem64: null,
        fonte: a.props.option.fonte || "Arial",

        novoCampo: "",
        camposCarteirinhaInfo: a.props.option.campos.map((e) => {
          let optionCarteirinha = optionsCamposCarteirinhas.find(
            (e2) => e2.value === e.nome_campo
          );
          return {
            position: {
              x: e.posicao_x,
              y: e.posicao_y,
            },
            nome: e.nome_campo,
            width: e.largura,
            height: e.altura,
            valor: e.valor_campo,
            color: e.cor,
            bold: e.negrito,
            letter_case: e.letter_case,
            ...e,
            ...optionsCamposCarteirinhas.find(
              (e2) => e2.value === e.nome_campo
            ),
            box:
              optionCarteirinha.value.includes("imagem") ||
              optionCarteirinha.value.includes("codigoDeBarras") ||
              optionCarteirinha.value.includes("qrcode"),
          };
        }),
        camposExcluidos: [],
      });
      $("#corpo").css("background-color", a.props.option.cor);
      $("#corpo").css("background-image", "");

      if (a.props.option.fundo === "imagem") {
        // Buscar a imagem do firebase

        storage
          .ref(
            `${process.env.REACT_APP_ENVIRONMENT === "production"
              ? "producao"
              : "hml"
            }/${window.localStorage.getItem("e_clube")}/carteirinha`
          )
          .child(`${a.props.value}.png`)
          .getDownloadURL()
          .then((url) => {
            this.setState({ imagem64: url });
            $("#corpo").css("background-color", "#FFFFFF");
            $("#corpo").css("background-image", `url(${url})`);
            $("#corpo").css("background-repeat", "no-repeat");
            $("#corpo").css(
              "background-size",
              `${this.state.largura}px ${this.state.altura}px`
            );
          });
      }
    }
  };

  adicionarCampoCarteirinha = () => {
    let lista2 = this.state.camposCarteirinhaInfo;
    console.log("this.state.novoCampo ", this.state.novoCampo)
    let box =
      this.state.novoCampo.includes("imagem") ||
      this.state.novoCampo === "codigoDeBarras" ||
      this.state.novoCampo === "qrcode";

    let info = {
      nome: this.state.novoCampo,
      exemplo: optionsCamposCarteirinhas.find(
        (e) => e.value === this.state.novoCampo
      ).exemplo,
      position: { x: 0, y: 0 },
      width: 100,
      height: box ? 100 : 20,
      box: box,
      bold: false,
      color: "#000000",
      align: "left",
      letter_case: "default",
    };
    lista2.push(info);

    this.setState({
      camposCarteirinhaInfo: lista2,
      novoCampo: null,
    });
  };

  salvar = async () => {
    try {
      this.setState({ salvando: true });
      let carteirinha = {
        nome: this.state.nome,
        largura: this.state.largura,
        altura: this.state.altura,
        fundo: this.state.fundo,
        cor: this.state.cor,
        restringe_cargo: this.state.checkboxCargos,
        restringe_tipo_titulo: this.state.checkboxTipoTitulos,
        fonte: this.state.fonte,
        campos: this.state.camposCarteirinhaInfo.map((e) => {
          return {
            id: e.id,
            nome_campo: e.nome,
            largura: e.width,
            altura: e.height,
            posicao_x: e.position.x,
            posicao_y: e.position.y,
            cor: e.color,
            negrito: e.bold,
            align: e.align || "left",
            valor_campo: e.valor || "",
            letter_case: e.letter_case || "default",
          };
        }),
        carteirinha_tipo_titulos: this.state.tipo_titulos.map((e) => {
          return { tipo_titulo_id: e };
        }),
        tipo_cargos: this.state.cargos.map((e) => {
          return {
            tipo_cargo: e,
          };
        }),
      };

      let carteirinhaManipuladaId = null;
      if (this.state.carteirinha_id === "nova") {
        const carteirinhaCriada = await api.post(
          "/carteirinha/salvar" + requisicoes.entidade,
          carteirinha,
          requisicoes.header
        );
        carteirinhaManipuladaId = carteirinhaCriada.data.id;
      } else {
        await api.post(
          "/carteirinha/alterar" + requisicoes.entidade,
          {
            ...carteirinha,
            carteirinha_id: this.state.carteirinha_id,
            camposExcluidos: this.state.camposExcluidos,
          },
          requisicoes.header
        );
        carteirinhaManipuladaId = this.state.carteirinha_id;
      }

      // Salvar a imagem.
      if (this.state.fundo === "imagem" && this.state.imagem) {
        const imagem = this.state.imagem;
        let a = new Promise(function (resolve, reject) {
          const uploadTask = storage
            .ref(
              `${process.env.REACT_APP_ENVIRONMENT === "production"
                ? "producao"
                : "hml"
              }/${window.localStorage.getItem(
                "e_clube"
              )}/carteirinha/${carteirinhaManipuladaId}.png`
            )
            .put(imagem);
          uploadTask.on("state_changed", {
            next: null,
            error: () => {
              reject();
            },
            complete: () => {
              console.log("upload da imagem feito");
              resolve();
            },
          });
        });
        a.then(async () => {
          await api.post(
            "/carteirinha/salvarImagemFundo" + requisicoes.entidade,
            {
              carteirinha_id: carteirinhaManipuladaId,
              projeto:
                process.env.REACT_APP_API_URL === undefined
                  ? "hml"
                  : "producao",
            },
            requisicoes.header
          );
          setTimeout(() => {
            this.setState({ salvando: false });
            window.location.reload();
          }, 800);
        });
      } else {
        setTimeout(() => {
          this.setState({ salvando: false });
          window.location.reload();
        }, 800);
      }
    } catch (error) {
      console.log(error);
    }
  };

  excluir = async () => {
    this.setState({ excluindo: true });
    try {
      await api.post(
        "/carteirinha/excluir" + requisicoes.entidade,
        {
          carteirinha_id: this.state.carteirinha_id,
        },
        requisicoes.header
      );

      let lista = this.state.optionsCarteirinhas;

      let index = lista.findIndex((e) => {
        return e.id === this.state.carteirinha_id;
      });

      lista.splice(index, 1);
      this.setState({
        optionsCarteirinhas: lista,
        carteirinha_id: "nova",
      });

      this.onSelectoptionCarteirinha(null, { props: { value: "nova" } });
    } catch (error) {
      alert("Não foi possível excluir a carteirinha");
    }
    setTimeout(() => {
      this.setState({ excluindo: false });
    }, 800);
  };

  previsualizarCarteirinha = async () => {
    try {
      await api.post(
        "/carteirinha/criarArquivo" + requisicoes.entidade,
        {
          projeto:
            process.env.REACT_APP_ENVIRONMENT === "production"
              ? "producao"
              : "hml",
          // pessoa_id: 41705408,
        },
        requisicoes.header
      );

      await api.post(
        "/carteirinha/gerarCarteirinha" + requisicoes.entidade,
        {
          carteirinha_id: this.state.carteirinha_id,
          previsualizar: true,
          projeto:
            process.env.REACT_APP_ENVIRONMENT === "production"
              ? "producao"
              : "hml",
        },
        requisicoes.header
      );

      const r = await api.get(
        "/carteirinha/arquivo" + requisicoes.entidade,
        requisicoes.header
      );

      var oReq = new XMLHttpRequest();
      var URLToPDF =
        r.config.baseURL +
        "carteirinha/arquivo" +
        requisicoes.entidade +
        "&t=" +
        requisicoes.header.headers.token; //
      oReq.open("GET", URLToPDF, true);
      oReq.responseType = "blob";
      oReq.onload = await function () {
        const file = new Blob([oReq.response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      };
      oReq.send();
    } catch (error) {
      console.log("error ", error)

    }
  };

  render() {
    return (
      <div style={{ marginBottom: 100, paddingBottom: 100 }}>
        <Segment>
          <Row>
            <Col sm={10}>
              <FormControl fullWidth>
                <InputLabel>Selecione a carteirinha</InputLabel>
                <Select
                  label="Selecione a carteirinha"
                  value={this.state.carteirinha_id}
                  onChange={this.onSelectoptionCarteirinha}
                  options={this.state.optionsCarteirinhas}
                >
                  <MenuItem value="nova">Criar uma nova</MenuItem>
                  {this.state.optionsCarteirinhas.map((e) => (
                    <MenuItem value={e.id} option={e}>
                      {e.id + " - " + e.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingButton
                color="secondary"
                variant="contained"
                disabled={
                  this.state.carteirinha_id === "nova" || this.state.excluindo
                }
                onClick={this.excluir}
                startIcon={<Delete />}
                loading={this.state.excluindo}
              >
                {this.state.excluindo ? "Excluindo..." : "Excluir"}
              </LoadingButton>
            </Col>
          </Row>
          <Divider />

          <Row>
            <Col sm={4}>
              <TextField
                variant="outlined"
                label="Nome da carteirinha"
                value={this.state.nome}
                onChange={(e) => this.setState({ nome: e.target.value })}
                fullWidth
              />
            </Col>
            <Col>
              <FormControl fullWidth>
                <InputLabel>Tipos de Cargos Permitidos</InputLabel>
                <Select
                  multiple
                  label="Tipos de Cargos Permitidos"
                  value={this.state.cargos}
                  disabled={!this.state.checkboxCargos}
                  onChange={(e, a) => this.setState({ cargos: e.target.value })}
                  renderValue={(selected) => (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {selected.map((value) => (
                        <Chip
                          style={{ margin: 2 }}
                          key={value}
                          label={TipoCargo.find((e) => e.value === value).text}
                        />
                      ))}
                    </div>
                  )}
                >
                  {TipoCargo?.map((e) => (
                    <MenuItem value={e.value}>{e.text}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col sm={2}>
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    color="primary"
                    checked={this.state.checkboxCargos}
                    onChange={(e) =>
                      this.setState({
                        checkboxCargos: e.target.checked,
                        cargos: [],
                      })
                    }
                  />
                }
                label="Restringir Cargos"
                labelPlacement="end"
              />
            </Col>
          </Row>

          <Row style={{ marginTop: 15 }}>
            <Col>
              <FormControl fullWidth>
                <InputLabel>Tipos de títulos Permitidos</InputLabel>
                <Select
                  multiple
                  label="Tipos de títulos Permitidos"
                  value={this.state.tipo_titulos}
                  disabled={!this.state.checkboxTipoTitulos}
                  onChange={(e, a) =>
                    this.setState({ tipo_titulos: e.target.value })
                  }
                  renderValue={(selected) => (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {selected.map((value) => (
                        <Chip
                          style={{ margin: 2 }}
                          key={value}
                          label={
                            this.state.optionsTipoTitulos.find(
                              (e) => e.id === value
                            ).descricao
                          }
                        />
                      ))}
                    </div>
                  )}
                >
                  {this.state.optionsTipoTitulos.map((e) => (
                    <MenuItem value={e.id}>{e.descricao}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col sm={2}>
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    color="primary"
                    checked={this.state.checkboxTipoTitulos}
                    onChange={(e) => {
                      this.setState({
                        checkboxTipoTitulos: e.target.checked,
                        tipo_titulos: [],
                      });
                    }}
                  />
                }
                label="Restringir Tipos de títulos"
                labelPlacement="end"
              />
            </Col>
          </Row>

          <Row style={{ marginTop: 15 }}>
            <Col
              sm={2}
              style={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
              }}
            >
              Tamanho da carteirinha:{" "}
            </Col>
            <Col md="auto">
              <TextField
                variant="outlined"
                label="Largura"
                type="number"
                value={this.state.largura}
                onChange={(e) => {
                  this.setState({ largura: e.target.value });
                }}
                onBlur={() => {
                  $("#corpo").width(parseInt(this.state.largura) + 2);
                }}
              />
            </Col>
            <Col
              md="auto"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              x
            </Col>
            <Col md="auto">
              <TextField
                variant="outlined"
                label="Altura"
                type="number"
                value={this.state.altura}
                onChange={(e) => this.setState({ altura: e.target.value })}
                onBlur={() => {
                  $("#boxCarteirinha").height(
                    parseInt(this.state.altura) + 2 + 15
                  );
                  $("#corpo").height(parseInt(this.state.altura) + 2);
                }}
              />
            </Col>
            <Col>
              Isto representa{" "}
              {this.state.largura !== "" &&
                parseFloat(this.state.largura * 0.0264583333).toFixed(2)}{" "}
              cm x{" "}
              {this.state.altura !== "" &&
                parseFloat(this.state.altura * 0.0264583333).toFixed(2)}{" "}
              cm
            </Col>
          </Row>
          <Row style={{ marginTop: 15 }}>
            <Col
              sm={2}
              style={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
              }}
            >
              Adicionar fundo:
            </Col>
            <Col sm={4}>
              <FormControl fullWidth>
                <InputLabel>Fundo</InputLabel>
                <Select
                  label="Fundo"
                  value={this.state.fundo}
                  onChange={(e, a) => this.setState({ fundo: e.target.value })}
                >
                  <MenuItem value="cor">Cor</MenuItem>
                  <MenuItem value="imagem">Imagem de fundo</MenuItem>
                </Select>
              </FormControl>
            </Col>
            {this.state.fundo === "cor" ? (
              <Col sm={4} style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="color"
                  value={this.state.cor}
                  onChange={(e) => {
                    this.setState({ cor: e.target.value });
                    $("#corpo").css("background-color", e.target.value);
                  }}
                />
              </Col>
            ) : (
              <Col sm={4} style={{ display: "flex", alignItems: "center" }}>
                <input
                  id="file"
                  name="file"
                  type="file"
                  accept=".jpg, .png"
                  onChange={(e) =>
                    this.handleUploadImage(
                      e,
                      this.state.altura,
                      this.state.largura
                    )
                  }
                />
              </Col>
            )}
          </Row>

          <Row style={{ marginTop: 15 }}>
            <Col
              sm={2}
              style={{
                fontWeight: "bold",
              }}
            >
              Fonte da carteirinha:
            </Col>
            <Col sm={8}>
              <FormControl fullWidth>
                <InputLabel>Fonte da carteirinha</InputLabel>
                <Select
                  label="Fonte"
                  value={this.state.fonte}
                  onChange={(e, a) => this.setState({ fonte: e.target.value })}
                >
                  <MenuItem value="Arial">Arial</MenuItem>
                  <MenuItem value="Times New Roman">Times New Roman</MenuItem>
                  <MenuItem value="Book Antiqua">Book Antiqua</MenuItem>
                </Select>
              </FormControl>
            </Col>
          </Row>

          <Divider />

          <h3>Dados da carteirinha</h3>

          <Row>
            <Col sm={10}>
              <FormControl fullWidth>
                <InputLabel>Campo a ser adicionado na carteirinha</InputLabel>
                <Select
                  label="Campo a ser adicionado na carteirinha"
                  value={this.state.novoCampo}
                  onChange={(e, a) =>
                    this.setState({ novoCampo: e.target.value })
                  }
                >
                  {optionsCamposCarteirinhas.map((e, i) => (
                    <MenuItem value={e.value}>{e.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
            <Col sm={2} style={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                onClick={this.adicionarCampoCarteirinha}
                disabled={!this.state.novoCampo}
                startIcon={<Add />}
              >
                Adicionar campo
              </Button>
            </Col>

            {this.state.camposCarteirinhaInfo.length > 0 ? (
              <div
                style={{
                  margin: 15,
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Table>
                  <Table.Header>
                    <Table.HeaderCell>Nome</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Largura</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Altura</Table.HeaderCell>
                    <Table.HeaderCell width={2}>X</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Y</Table.HeaderCell>
                    <Table.HeaderCell>Atributos</Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                  </Table.Header>
                  <Table.Body>
                    {this.state.camposCarteirinhaInfo.map((e, i) => (
                      <TableRow>
                        <Table.Cell>
                          {e.nome === "texto" ? (
                            <TextField
                              variant="outlined"
                              label="Texto"
                              value={e.valor}
                              onChange={(e) => {
                                console.log(e.target.value);
                                let lista = this.state.camposCarteirinhaInfo;
                                lista[i].valor = e.target.value;
                                this.setState({ camposCarteirinhaInfo: lista });
                              }}
                            />
                          ) : (
                            e.nome
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          <TextField
                            variant="outlined"
                            label="Largura"
                            value={e.width}
                            type="number"
                            onChange={(e) => {
                              let lista = this.state.camposCarteirinhaInfo;
                              lista[i].width = e.target.value;
                              this.setState({ camposCarteirinhaInfo: lista });
                              $("#campo" + i).width(e.target.value);
                            }}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <TextField
                            variant="outlined"
                            label="Altura"
                            type="number"
                            value={e.height}
                            onChange={(e) => {
                              let lista = this.state.camposCarteirinhaInfo;
                              lista[i].height = e.target.value;
                              this.setState({ camposCarteirinhaInfo: lista });
                              $("#campo" + i).height(e.target.value);
                            }}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <TextField
                            variant="outlined"
                            label="Eixo x"
                            type="number"
                            value={e.position.x}
                            onChange={(e) => {
                              let lista = this.state.camposCarteirinhaInfo;
                              lista[i].position.x = parseInt(e.target.value);
                              this.setState({ camposCarteirinhaInfo: lista });
                            }}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <TextField
                            variant="outlined"
                            label="Eixo Y"
                            type="number"
                            value={e.position.y}
                            onChange={(e) => {
                              let lista = this.state.camposCarteirinhaInfo;
                              lista[i].position.y = parseInt(e.target.value);
                              this.setState({ camposCarteirinhaInfo: lista });
                            }}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          {!e.box && (
                            <>
                              <input
                                type="color"
                                value={e.color}
                                onChange={(e) => {
                                  let lista = this.state.camposCarteirinhaInfo;
                                  lista[i].color = e.target.value;
                                  this.setState({
                                    camposCarteirinhaInfo: lista,
                                  });
                                }}
                              />
                              <IconButton
                                color={e.bold ? "inherit" : "default"}
                                onClick={() => {
                                  let lista = this.state.camposCarteirinhaInfo;
                                  lista[i].bold = !lista[i].bold;
                                  this.setState({
                                    camposCarteirinhaInfo: lista,
                                  });
                                }}
                              >
                                <Icon>format_bold</Icon>
                              </IconButton>

                              <IconButton
                                onClick={() => {
                                  let lista = this.state.camposCarteirinhaInfo;
                                  if (!lista[i].letter_case) {
                                    lista[i].letter_case = "default";
                                  }
                                  let cases = {
                                    default: { next: "upper_case" },
                                    upper_case: { next: "lower_case" },
                                    lower_case: { next: "default" },
                                  };
                                  lista[i].letter_case =
                                    cases[lista[i].letter_case].next;

                                  this.setState({
                                    camposCarteirinhaInfo: lista,
                                  });
                                }}
                              >
                                <Iconify
                                  icon={
                                    e.letter_case === "upper_case"
                                      ? mdiFormatLetterCaseUpper
                                      : e.letter_case === "lower_case"
                                        ? mdiFormatLetterCaseLower
                                        : mdiFormatLetterCase
                                  }
                                  width={24}
                                  color={
                                    e.letter_case === "default"
                                      ? "#A2A2A2"
                                      : "#000000"
                                  }
                                />
                              </IconButton>

                              <IconButton
                                color={
                                  e.align === "left" ? "inherit" : "default"
                                }
                                onClick={() => {
                                  let lista = this.state.camposCarteirinhaInfo;
                                  lista[i].align = "left";
                                  this.setState({
                                    camposCarteirinhaInfo: lista,
                                  });
                                }}
                              >
                                <Icon>format_align_left</Icon>
                              </IconButton>

                              <IconButton
                                color={
                                  e.align === "center" ? "inherit" : "default"
                                }
                                onClick={() => {
                                  let lista = this.state.camposCarteirinhaInfo;
                                  lista[i].align = "center";
                                  this.setState({
                                    camposCarteirinhaInfo: lista,
                                  });
                                }}
                              >
                                <Icon>format_align_center</Icon>
                              </IconButton>
                              <IconButton
                                color={
                                  e.align === "right" ? "inherit" : "default"
                                }
                                onClick={() => {
                                  let lista = this.state.camposCarteirinhaInfo;
                                  lista[i].align = "right";
                                  this.setState({
                                    camposCarteirinhaInfo: lista,
                                  });
                                }}
                              >
                                <Icon>format_align_right</Icon>
                              </IconButton>
                            </>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          <IconButton
                            onClick={() => {
                              let lista = this.state.camposCarteirinhaInfo;
                              let listaExcluidas = this.state.camposExcluidos;
                              if (lista[i].id) {
                                listaExcluidas.push(lista[i].id);
                              }
                              lista.splice(i, 1);
                              this.setState({
                                camposCarteirinhaInfo: lista,
                                camposExcluidos: listaExcluidas,
                              });
                            }}
                          >
                            <Icon color="error">delete_icon</Icon>
                          </IconButton>
                        </Table.Cell>
                      </TableRow>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            ) : null}
          </Row>
          {/* Previsualização da carteirinha */}

          <div
            id="boxCarteirinha"
            style={{
              height: this.state.altura + 2 + 15,
              width: this.state.largura + 2,
            }}
          >
            <div
              id="corpo"
              className="box"
              style={{
                position: "relative",
                overflow: "auto",
                padding: "0",
                height: this.state.altura + 2,
                width: this.state.largura + 2,

                // backgroundColor: "#ABABAB",
                // backgroundColor: this.state.color,
              }}
            >
              {this.state.camposCarteirinhaInfo.map((e, i) => (
                <Draggable
                  bounds="parent"
                  position={e.position}
                  onStop={(e, position) => {
                    console.log("position: ", position);
                    let lista = this.state.camposCarteirinhaInfo;
                    lista[i].position = position;
                    this.setState({ camposCarteirinhaInfo: lista });
                  }}
                  id={"draggableCampo" + i}
                >
                  <div
                    id={"campo" + i}
                    className="box"
                    style={{
                      height: e.height,
                      width: e.width,
                      position: "absolute",
                      top: "0%",
                      left: "0%",
                      backgroundColor: e.box ? "#FFFFFF" : "#D3D3D3",
                      fontSize: e.box ? 12 : parseFloat(e.height),
                      padding: 0,
                      display: "flex",
                      alignItems: "center",
                      color: e.box ? "#000000" : e.color,
                      fontWeight: !e.box && e.bold ? "bold" : "normal",
                      justifyContent:
                        e.align === "left"
                          ? "flex-start"
                          : e.align === "right"
                            ? "flex-end"
                            : e.align,
                      fontFamily: this.state.fonte || "default",
                    }}
                  >
                    {e.letter_case === "upper_case"
                      ? e.exemplo?.toUpperCase()
                      : e.letter_case === "lower_case"
                        ? e.exemplo?.toLowerCase()
                        : e.exemplo}
                  </div>
                </Draggable>
              ))}
            </div>
          </div>

          {this.state.carteirinhaSelected && (
            <Row style={{ display: "flex", justifyContent: "flex-end" }}>
              {`Criada em ${moment(
                this.state.carteirinhaSelected.data_criacao
              ).format(
                "DD/MM/YYYY"
              )} ${this.state.carteirinhaSelected.hora_criacao?.slice(
                0,
                5
              )} por ${this.state.carteirinhaSelected.usuario_criou?.nome}`}
            </Row>
          )}

          {this.state.carteirinhaSelected &&
            this.state.carteirinhaSelected.usuario_alterou && (
              <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                <Col md="auto">
                  {`Ultima alteração ${moment(
                    this.state.carteirinhaSelected.data_ultima_edicao
                  ).format(
                    "DD/MM/YYYY"
                  )} ${this.state.carteirinhaSelected.hora_ultima_edicao.slice(
                    0,
                    5
                  )} por ${this.state.carteirinhaSelected.usuario_alterou.nome
                    }`}
                </Col>
              </Row>
            )}

          <Divider />
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col
              md="auto"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingButton
                color="secondary"
                variant="contained"
                disabled={this.state.carteirinha_id === "nova"}
                onClick={this.excluir}
                startIcon={<Delete />}
                loading={this.state.excluindo}
              >
                {this.state.excluindo ? "Excluindo..." : "Excluir"}
              </LoadingButton>
            </Col>
            <Col md="auto">
              <Button
                variant="contained"
                disabled={this.state.carteirinha_id === "nova"}
                startIcon={<Visibility />}
                onClick={this.previsualizarCarteirinha}
              >
                Previsualizar
              </Button>
            </Col>
            <Col md="auto">
              <ThemeProvider
                theme={createTheme({
                  palette: {
                    primary: green,
                  },
                })}
              >
                <LoadingButton
                  variant="contained"
                  style={{ color: "#FFFFFF" }}
                  onClick={() => {
                    this.salvar();
                  }}
                  loading={this.state.salvando}
                  disabled={this.state.salvando}
                  startIcon={<Save />}
                >
                  Salvar
                </LoadingButton>
              </ThemeProvider>
            </Col>
          </Row>
        </Segment>
      </div>
    );
  }
}
