import React, { Component } from "react";
import { Container, Table, Header, Modal, Button } from "semantic-ui-react";
import { Col, Form, FormControl, Row } from "react-bootstrap";
import moment from "moment-timezone";
import { Link } from "react-router-dom";

export default class ModalReservas extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal
        size="small"
        trigger={
          <Button
            content="Reservas"
            icon="history"
            color="blue"
            size="tiny"
            floated="right"
          ></Button>
        }
      >
        <Modal.Header>
          <Container>
            <Row>
              <Col sm={11}>
                <Header as="h3">Reservas</Header>
              </Col>
              <Col sm={1}>
                <Link
                  to={{
                    pathname: "/reservas",
                    query: {
                      select: { value: "venda", label: "Vendas" },
                      pessoa: this.state.pessoa,
                    },
                  }}
                  style={{
                    marginLeft: "1%",
                    paddingTop: "0px",
                    marginTop: "0px",
                  }}
                >
                  <Button color="green" icon={"add"}></Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </Modal.Header>
        <Modal.Content>
          <Container textAlign="center">
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Serviço</Table.HeaderCell>
                  <Table.HeaderCell>Data Reservada</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  {this.props.permissoes.find(
                    (e) => e.nome === "cancelar_reservas"
                  ) && <Table.HeaderCell width={1}></Table.HeaderCell>}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.reservas.length === 0 ? (
                  <div style={{ margin: "20px" }}>
                    <h3>Não possui reservas ativas!</h3>
                  </div>
                ) : (
                  this.props.reservas.map((e, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{e.horario_servico.servico.nome}</Table.Cell>
                      <Table.Cell>
                        {moment(e.dataReserva).format("DD/MM/YYYY")}
                      </Table.Cell>
                      <Table.Cell>
                        {e.refetivada && e.refetivada.length > 0
                          ? e.refetivada[0].situacaoReserva === "prereserva"
                            ? "pré reserva"
                            : e.situacaoReserva
                          : e.situacaoReserva}
                      </Table.Cell>
                      {this.props.permissoes.find(
                        (e) => e.nome === "cancelar_reservas"
                      ) && (
                        <Table.Cell width={1}>
                          {e.dataCancelamento == null && (
                            <Modal
                              size="small"
                              trigger={
                                <Button
                                  circular
                                  icon="remove"
                                  negative
                                ></Button>
                              }
                            >
                              <Modal.Content>
                                <Container textAlign="center">
                                  <Col sm={12}>
                                    <Form.Label>Motivo </Form.Label>
                                    <FormControl
                                      aria-label="Motivo"
                                      aria-describedby="basic-addon1"
                                      name="motivo_cancelamento"
                                      onChange={this.props.alteracao}
                                    />
                                  </Col>
                                  <br />
                                  Confirma Cancelamento?
                                  <br />
                                  <br />
                                  <Button
                                    primary
                                    size="tiny"
                                    type="button"
                                    value={e.id}
                                    onClick={this.props.cancelarReserva}
                                  >
                                    Sim
                                  </Button>
                                </Container>
                              </Modal.Content>
                            </Modal>
                          )}
                        </Table.Cell>
                      )}
                    </Table.Row>
                  ))
                )}
              </Table.Body>
            </Table>
          </Container>
        </Modal.Content>
      </Modal>
    );
  }
}
