import React, { Component } from "react";
import {
  Table,
  Button,
  Header,
  Search,
  Pagination,
  Checkbox,
} from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import requisicoes from "../../../utils/Requisicoes";
import ModalCadastrarPadrao from "./modalCadastrarPadrao";
import moment from "moment";

export default class TreinoLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaTreino: [],
      listaMeusTreino: [],
      pagina: 1,
      totalPaginas: 1,
      busca: null,
      inativos: false,
      modalAdicionarPadrao: false,
      pessoas: [],
      professor_id: null,
      professor: null,
      linhaAddExercicio: 1,
      grupoMusculares: [],
      exercicios: [],
      grupoMuscularSelecionado: null,
      listaExerciciosSelecionado: [],
      data_lancamento: null,
      data_validade: null,
      serie: null,
      repeticao: null,
      peso: null,
      tempo_descanso: null,
      treinosPadroes: [],
    };
    this.listaTreino();
    this.listaMeusTreino();
    this.buscaInfoTreinos();
  }

  buscaInfoTreinos = async () => {
    const grupoMuscular = await api.post(
      "/grupoMuscular/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    const exercicios = await api.post(
      "/exercicio/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    const treinos = await api.post(
      "/treino/listaTreinosPadroes" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    this.setState({
      grupoMusculares: grupoMuscular.data,
      exercicios: exercicios.data,
      treinosPadroes: treinos.data,
    });
  };

  listaMeusTreino = async () => {
    const respostaUsuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ usuarioLogado: respostaUsuarioLogado.data });

    await api
      .post(
        "/treino/treinoPorPessoa" + requisicoes.entidade,
        {
          aluno_id: respostaUsuarioLogado.data.pessoa_id,
        },
        requisicoes.header
      )
      .then((resp) => {
        this.setState({
          listaMeusTreino: resp.data,
        });
      });
  };

  listaTreino = async (a, data, busca) => {
    if (data === undefined || data === null) {
      data = {};
      data.activePage = 1;
    }

    if (busca !== undefined && busca !== null && busca.trim() === "") {
      busca = undefined;
    }
    await api
      .post(
        "/treino/lista" + requisicoes.entidade,
        {
          busca: busca,
          inativos: this.state.inativos,
          pagina: data.activePage || this.state.pagina,
        },
        requisicoes.header
      )
      .then((resp) => {
        const { docs, pages } = resp.data;
        this.setState({
          listaTreino: docs,
          pagina: data.activePage,
          totalPaginas: pages,
        });
      });
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === "busca") {
      this.listaTreino(null, null, event.target.value);
      // this.listaTreino(null, null);
    }
  };

  onChangeCheckboxInativos = (evt, data) => {
    this.setState({ inativos: data.checked });
    setTimeout(() => {
      this.listaTreino(null, null, this.state.busca);
    }, 500);
  };

  //Funcoes MODEL
  changeModalCadastrarPadrao = async (status, id) => {
    localStorage.setItem("subpagina_anterior", "CadastrarPadrao");
    if(id != null) {
      this.visualiza(id);
    }
    this.setState({
      modalAdicionarPadrao: status,
      linhaAddExercicio: 1,
      grupoMuscularSelecionado: null,
    });
  };

  alteracaoModal = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  pesquisaPessoa = (newValue) => {
    api
      .post(
        "/pessoa/pesquisa" + requisicoes.entidade,
        {
          pesquisa: newValue,
          pessoaInativa: false,
        },
        requisicoes.header
      )
      .then((resposta) => {
        const lista = [];
        resposta.data.forEach((e) => {
          let objeto = {
            value: e.id,
            label: e.nome,
          };
          lista.push(objeto);
        });

        this.setState({ pessoas: lista });
      });
  };

  alteracaoSelectProfessor = async (event) => {
    this.setState({ professor_id: event.value });
    this.setState({ professor: event });
  };

  salvarTreinoPadrao = async () => {
    // event.preventDefault();

    const dados = {
      treinos_exercicio: this.state.listaExerciciosSelecionado,
      nome: this.state.nome,
      data_lancamento: this.state.data_lancamento
        ? this.state.data_lancamento
        : null,
      data_validade: this.state.data_validade ? this.state.data_validade : null,
    };

    const retorno = await api.post(
      "/treino/salvarTreinoPadrao" + requisicoes.entidade,
      dados,
      requisicoes.header
    );
    this.setState({ id: retorno.data.id, messageTreino: true });
    // this.visualiza();
    setTimeout(() => {
      this.setState({ messageTreino: false, modalAdicionarPadrao: false });
      this.buscaInfoTreinos();
    }, 3000);
  };

  adicionarLinhaExercicio = async () => {
    this.setState({ linhaAddExercicio: this.state.linhaAddExercicio + 1 });
  };

  changeSelectGrupoMuscular = async (event, index) => {
    var listaGruposMusculares = [...this.state.listaExerciciosSelecionado];
    this.setState({ grupoMuscularSelecionado: event.target.value });

    if (!listaGruposMusculares[index]) {
      listaGruposMusculares.push();
    }

    listaGruposMusculares[index] = {
      grupo_muscular_id: event.target.value,
      exercicio_id: null,
      serie: null,
      repeticao: null,
      peso: null,
      tempo_descanso: null,
      duracao: null,
      treino_padrao: true,
    };

    this.setState({ listaExerciciosSelecionado: listaGruposMusculares });
  };

  changeSelectExercicio = async (event, index) => {
    var listaExercicios = [...this.state.listaExerciciosSelecionado];

    listaExercicios[index].exercicio_id = event.target.value;

    this.setState({ listaExerciciosSelecionado: listaExercicios });
  };
  changeInputExercicios = async (event, index) => {
    var listaExercicios = [...this.state.listaExerciciosSelecionado];

    listaExercicios[index][event.target.name] =
      event.target.name === "tempo_descanso" || event.target.name === "duracao"
        ? event.target.value
        : parseInt(event.target.value);

    this.setState({ listaExerciciosSelecionado: listaExercicios });
  };

  render() {
    return (
      <div className="lista">
        <Header
          as="h1"
          dividing
          style={{
            display:
              window.localStorage.getItem("pagina_anterior") === "pessoa"
                ? "none"
                : "block",
          }}
        >
          Padrões de treinos
        </Header>

        {localStorage.getItem("tipo_usuario_clube") === "administrador" ||
        localStorage.getItem("tipo_usuario_clube") === "administradorSistema" ||
        localStorage.getItem("tipo_usuario_clube") === "administrativo" ||
        localStorage.getItem("tipo_usuario_clube") === "modalidade" ? (
          <>
            <Row>
              <Col sm={2}>
                <Search
                  placeholder="Digite para buscar"
                  name="busca"
                  onSearchChange={this.alteracao}
                  open={false}
                  fluid
                />
              </Col>
              <Col sm={1}></Col>
              <Col md={"auto"}>
                <Col sm={12}>Inativos</Col>
                <Col sm={12}>
                  <Checkbox
                    toggle
                    onChange={(evt, data) =>
                      this.onChangeCheckboxInativos(evt, data)
                    }
                    checked={this.state.inativos}
                  />
                </Col>
              </Col>
            </Row>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Nome</Table.HeaderCell>
                  <Table.HeaderCell>Data Lançamento</Table.HeaderCell>
                  <Table.HeaderCell>Data Validade</Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign="center">
                    <Button
                      positive
                      icon="add"
                      onClick={() => this.changeModalCadastrarPadrao(true, null)}
                    ></Button>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {this.state.treinosPadroes.map((e, i) => ((this.state.inativos === true && e.status === "inativo") || 
                (e.status === "ativo" || e.status == null)) && (
                  <Table.Row
                    key={i}
                    negative={e.status === "inativo"}
                    positive={e.status === "ativo"}
                  >
                    <Table.Cell>{e.nome}</Table.Cell>
                    <Table.Cell>
                      {e.data_lancamento !== null && (
                        <>{moment(e.data_lancamento).format("DD/MM/YYYY")}</>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {e.data_validade !== null && (
                        <>{moment(e.data_validade).format("DD/MM/YYYY")}</>
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Link to={{ pathname: "/treino", query: { id: e.id, treino_padrao: true } }}>
                        <Button 
                          primary icon="edit"
                          onClick={localStorage.setItem("pagina_anterior", "treino")}
                        >
                        </Button>
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>

              <Table.Footer>
                <Table.Row verticalAlign="middle" textAlign="center">
                  <Table.HeaderCell colSpan="6">
                    <Pagination
                      boundaryRange={0}
                      ellipsisItem={null}
                      totalPages={this.state.totalPaginas}
                      onPageChange={this.listaTreino}
                      activePage={this.state.pagina}
                    ></Pagination>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </>
        ) : (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nome</Table.HeaderCell>
                <Table.HeaderCell>Data Lançamento</Table.HeaderCell>
                <Table.HeaderCell>Data Validade</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.state.listaTreino.map((e, i) => (
                <Table.Row
                  key={i}
                  negative={e.status === "inativo"}
                  positive={e.status === "ativo"}
                >
                  <Table.Cell>{e.nome}</Table.Cell>
                  <Table.Cell>{e.aluno.nome}</Table.Cell>
                  <Table.Cell>{e.professor.nome}</Table.Cell>
                  <Table.Cell>
                    {e.data_lancamento !== null && (
                      <>{moment(e.data_lancamento).format("DD/MM/YYYY")}</>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {e.data_validade !== null && (
                      <>{moment(e.data_validade).format("DD/MM/YYYY")}</>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}

        <ModalCadastrarPadrao
          changeModalCadastrarPadrao={this.changeModalCadastrarPadrao}
          alteracaoModal={this.alteracaoModal}
          pesquisaPessoa={this.pesquisaPessoa}
          alteracaoSelectProfessor={this.alteracaoSelectProfessor}
          adicionarLinhaExercicio={this.adicionarLinhaExercicio}
          salvarTreinoPadrao={this.salvarTreinoPadrao}
          changeSelectGrupoMuscular={this.changeSelectGrupoMuscular}
          changeSelectExercicio={this.changeSelectExercicio}
          changeInputExercicios={this.changeInputExercicios}
          listaExerciciosSelecionado={this.state.listaExerciciosSelecionado}
          modalAdicionarPadrao={this.state.modalAdicionarPadrao}
          pessoas={this.state.pessoas}
          professor={this.state.professor}
          nome={this.state.nome}
          data_lancamento={this.state.data_lancamento}
          data_validade={this.state.data_validade}
          linhaAddExercicio={this.state.linhaAddExercicio}
          grupoMusculares={this.state.grupoMusculares}
          exercicios={this.state.exercicios}
          grupoMuscularSelecionado={this.state.grupoMuscularSelecionado}
        />
      </div>
    );
  }
}
