const listaEnum = [
  { value: 2, label: "Entrada Confirmada" },
  { value: 3, label: "Entrada Rejeitada" },
  { value: 6, label: "Liquidação Normal" },
  { value: 7, label: "Conf. Exc. Cadastro pagador débito" },
  { value: 8, label: "Rej. Ped. Exc. Cadastro de pagador débito" },
  { value: 9, label: "Baixado Automat. via Arquivo" },
  { value: 10, label: "Baixado conforme instruções da Agência" },
  { value: 11, label: "Em Ser - Arquivo de Títulos pendentes" },
  { value: 12, label: "Abatimento Concedido" },
  { value: 13, label: "Abatimento Cancelado" },
  { value: 14, label: "Vencimento Alterado" },
  { value: 15, label: "Liquidação em Cartório" },
  { value: 16, label: "Título Pago em Cheque – Vinculado" },
  { value: 17, label: "Liquidação após baixa ou Título não registrado" },
  { value: 18, label: "Acerto de Depositária" },
  { value: 19, label: "Confirmação Receb.Inst.de Protesto" },
  {
    value: 20,
    label: "Confirmação Recebimento Instrução Sustação de Protesto",
  },
  { value: 21, label: "Acerto do Controle do Participante" },
  { value: 22, label: "Título Com Pagamento Cancelado" },
  { value: 23, label: "Entrada do Título em Cartório" },
  { value: 24, label: "Entrada rejeitada por CEP Irregular" },
  { value: 25, label: "Confirmação Receb.Inst.de Protesto Falimentar" },
  { value: 27, label: "Baixa Rejeitada" },
  { value: 28, label: "Débito de tarifas / custas" },
  { value: 29, label: "Ocorrências do Pagador" },
  { value: 30, label: "Alteração de Outros Dados Rejeitados" },
  { value: 32, label: "Instrução Rejeitada" },
  { value: 33, label: "Confirmação Pedido Alteração Outros Dados" },
  { value: 34, label: "Retirado de Cartório e Manutenção Carteira" },
  { value: 35, label: "Desagendamento do débito automático" },
  { value: 40, label: "Estorno de pagamento" },
  { value: 41, label: "Liquidação de título baixado" },
  { value: 42, label: "Título retirado do cartório" },
  { value: 43, label: "Despesa de cartório" },
  { value: 51, label: "Valor do título alterado" },
  { value: 55, label: "Sustado judicial" },
  { value: 68, label: "Acerto dos dados do rateio de Crédito" },
  { value: 69, label: "Cancelamento de Rateio" },
  { value: 73, label: "Confirmação Receb.Pedido de Negativação" },
  { value: 74, label: "Confir Pedido de Excl de Negat(com ou sem baixa)" },
];
export default listaEnum;
