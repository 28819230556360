import React, { Component } from "react";
import { Table, Icon, Button, Modal } from "semantic-ui-react";
import Checkbox from "muicss/lib/react/checkbox";
import { Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Print from "@material-ui/icons/Print";

// Componentes
import ModalEmail from "./modalEmail";
import InformacaoLancamento from "./modalInfoLancamento";

// Enum
import TipoLancamento from "../../../../enum/TipoLancamento";

// Utils
import moment from "moment-timezone";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";
import { valorFormatter } from "../../../../utils/formatterValor"
import { parseISO, format } from "date-fns";

export default class ListaLancamentos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCheckboxes: new Set(),
      selectedCheckboxes2: new Set(),
      total: 0.0,
      openModalInformacoes: null,
      gerandoBoleto: false,
      mensagem:
        "Mensagem automática.\n\nEnvio de boleto referente a pendencia no clube.\n\n",
      email: props.novoEmail,
      assunto: "Envio de boleto pendente com o clube",
      emailInvalido: false,
      lancamento_contas: [],
      lancamentosDependentes: [], // remover
      total_titular: 0.0,

      lista_vencida_dependentes: [],
      lista_aVencer_dependentes: [],
      listaVencida: [],
      listaAVencer: [],
      total_vencida: 0.0,
      total_aVencer: 0.0,
      total_vencido_dependente: [],
      total_aVencer_dependente: [],

      tipoLancamento: "abertas",
      listaLancamentos: [],
      total_lancamentos: 0,
      anoLancamento: moment().format("YYYY"),
      lista_anos: this.geraListaDeAnos(),
      enviarEmail: 0,
    };
    this.buscaTitulos();
  }

  geraListaDeAnos = () => {
    var years = [];
    const dateStart = moment("2008-01-01");
    const dateEnd = moment();
    while (dateEnd.format("YYYY") !== dateStart.format("YYYY")) {
      years.push(dateStart.format("YYYY"));
      dateStart.add(1, "year");
    }
    years.push(dateEnd.format("YYYY"));
    years.reverse();
    return years;
  };

  buscaTitulos = async () => {
    let retorno = "";
    if (this.state.tipoLancamento === "pagas") {
      retorno = await api.post(
        "/lancamentoConta/lancamentosPagosPorPessoa" + requisicoes.entidade,
        {
          pessoa_id: this.props.pessoa.id,
          ano_pagamento: this.state.anoLancamento,
        },
        requisicoes.header
      );
    }
    if (this.state.tipoLancamento === "vencidas") {
      retorno = await api.post(
        "/lancamentoConta/lancamentosVencidosPorPessoa" + requisicoes.entidade,
        {
          pessoa_id: this.props.pessoa.id,
        },
        requisicoes.header
      );
    }
    if (this.state.tipoLancamento === "abertas") {
      retorno = await api.post(
        "lancamentoConta/lancamentosAbertosPorPessoa" + requisicoes.entidade,
        {
          pessoa_id: this.props.pessoa.id,
        },
        requisicoes.header
      );
    }
    if (this.state.tipoLancamento === "cancelados") {
      retorno = await api.post(
        "/lancamentoConta/lancamentoCanceladoPorPessoa" + requisicoes.entidade,
        {
          pessoa_id: this.props.pessoa.id,
        },
        requisicoes.header
      );
    }
    this.state.buscar = true;

    this.setState({ listaLancamentos: retorno.data });
    if (retorno.data !== undefined) {
      let total = 0.0;
      for (let i = 0; i < retorno.data.length; i++) {
        const element = retorno.data[i];
        if (this.state.tipoLancamento === "pagas") {
          total += parseFloat(element.valor_pago);
        } else {
          total += parseFloat(element.valor);
        }
      }
      this.setState({ total_lancamentos: total });
    }
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === "email") {
      this.setState({ emailInvalido: false });
    }
  };

  toggleCheckbox = (e) => {
    if (this.state.selectedCheckboxes.has(e.target.value)) {
      this.state.selectedCheckboxes.delete(e.target.value);
    } else {
      this.state.selectedCheckboxes.add(e.target.value);
    }
  };
  toggleCheckbox2 = (e) => {
    if (this.state.selectedCheckboxes2.has(e.target.value)) {
      this.state.selectedCheckboxes2.delete(e.target.value);
    } else {
      this.state.selectedCheckboxes2.add(e.target.value);
    }
  };

  gerarPdfBoletos = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api.get("/boleto?e=" + entidade + "&t=Baer " + token);
    var oReq = new XMLHttpRequest();
    var URLToPDF =
      r.config.baseURL + "boleto?e=" + entidade + "&t=Baer " + token;
    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  gerarVariosBoletos = async (mostrar) => {
    if (this.state.selectedCheckboxes.size > 0) {
      this.setState({ gerandoBoleto: true });
      let listaImpressao = [];
      this.state.selectedCheckboxes.forEach((e) => {
        listaImpressao.push({ id: e });
      });

      let selecionados = [...this.state.selectedCheckboxes];

      const lancamentos = this.state.listaLancamentos.filter(
        (e) => selecionados.findIndex((e2) => parseInt(e2) === e.id) !== -1
      );

      let vaiRegistrar = false;
      for (let i = 0; i < lancamentos.length; i++) {
        const element = lancamentos[i];
        if (
          element.instituicao_cobranca.registro_automatico &&
          !element.remessa_gerada
        ) {
          vaiRegistrar = true;
          break;
        }
      }

      if (vaiRegistrar) {
        if (
          !window.confirm(
            "Alguns dos boletos selecionados serão registrados. Tem certeza que deseja continuar?"
          )
        ) {
          return;
        }
      }
      await api
        .post(
          "/boleto/geraBoleto" + requisicoes.entidade,
          {
            lancamento_contas: listaImpressao,
          },
          requisicoes.header
        )
        .then(async () => {
          if (vaiRegistrar) {
            let listaLancamentos = [...this.state.listaLancamentos];
            for (let i = 0; i < selecionados.length; i++) {
              const element = selecionados[i];
              listaLancamentos[
                listaLancamentos.findIndex((e) => e.id === parseInt(element))
              ].remessa_gerada = true;
            }
            this.setState({ listaLancamentos });
          }
          if (mostrar !== false) {
            this.gerarPdfBoletos();
          }
          this.setState({ gerandoBoleto: false });
        })
        .catch((err) => {
          if (err.response) {
            alert("Erro: ", err.response);
          } else {
            alert("Ocorreu um erro inesperado ao gerar os boletos");
          }
        });
    }
  };

  gerarBoleto = async (id, mostrar, emailEnviado) => {
    this.setState({ gerandoBoleto: true });
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    console.log("id", id)
    if (emailEnviado === true) {
      this.state.enviarEmail = id;
    }
    let lancamentos = [...this.state.listaLancamentos];
    const indexLancamento = lancamentos.findIndex((e) => e.id === id);
    const lancamento = lancamentos[indexLancamento];

    if (
      lancamento.instituicao_cobranca &&
      lancamento.instituicao_cobranca.registro_automatico &&
      !lancamento.remessa_gerada
    ) {
      if (!window.confirm("Seu boleto será registrado, deseja continuar?")) {
        return;
      }
    }

    let dados = { lancamento_contas: [{ id: id }], emailEnviado: emailEnviado };
    await api
      .post("/boleto/geraBoleto?e=" + entidade, dados, {
        headers: { token: "Baer " + token },
      })
      .then(async (retorno) => {
        if (mostrar !== false) {
          setTimeout(async () => {
            this.gerarPdfBoletos();
          }, 500);
        }
        if (
          lancamento.instituicao_cobranca &&
          lancamento.instituicao_cobranca.registro_automatico &&
          !lancamento.remessa_gerada
        ) {
          lancamentos[indexLancamento].remessa_gerada = true;
        }
        this.setState({ gerandoBoleto: false, listaLancamentos: lancamentos });
      })
      .catch((err) => {
        if (err.response) {
          console.log("err.response.data", err.response.data)
          console.log("err.response", err.response)
          alert("Erro ao gerar boleto.");
        } else {
          alert("Ocorreu um erro inesperado ao gerar os boletos");
        }
      });
  };

  enviarEmail = async (id) => {
    this.setState({ enviando: true });
    if (!this.state.email.includes("@") || !this.state.email.includes(".")) {
      this.setState({ emailInvalido: true });
      this.setState({ enviando: null });
      return;
    }
    // this.state.enviarEmail
    await api.post(
      "/email/enviarEmail" + requisicoes.entidade,
      {
        email: this.state.email,
        assunto: this.state.assunto,
        mensagem: this.state.mensagem,
        tipo: "boleto",
        enviarEmail: this.state.enviarEmail,
      },
      requisicoes.header
    );
    this.buscaTitulos();
    this.setState({ enviando: false });
  };

  buscaEmAbertoTitulo = async (event, v) => {
    let retorno = null;
    let dependentes = null;
    let lancamentosDependentes = [];
    let totalDependentes = [];
    let totalDependentesVencido = [];
    let totalVencidoDependente = 0.0;
    let totalAVencerDependente = 0.0;
    let totalTitular = 0.0;
    let totalVencido = 0.0;
    let totalAVencer = 0.0;
    this.state.listaVencida = [];
    this.state.listaAVencer = [];
    this.state.lista_vencida_dependentes = [];
    this.state.lista_aVencer_dependentes = [];
    let listaVencidaDependentes = [];

    if (v !== null) {
      retorno = await api.post(
        "/lancamentoConta/lancamentoEmAbertoPorPessoa" + requisicoes.entidade,
        { pessoa_id: v.value },
        requisicoes.header
      );
      for (let i = 0; i < retorno.data.length; i++) {
        const element = retorno.data[i];
        if (
          this.state.selectedCheckboxes2.has(parseFloat(element.id).toString())
        ) {
          element.select = true;
        } else {
          element.select = false;
        }
        if (element.data_vencimento < moment().format("yyyy-MM-DD")) {
          this.state.listaVencida.push(element);

          totalVencido += parseFloat(element.valor);
        } else {
          this.state.listaAVencer.push(element);
          totalAVencer += parseFloat(element.valor);
        }
        totalTitular += parseFloat(element.valor);
      }

      this.setState({
        total_vencida: totalVencido,
        total_aVencer: totalAVencer,
      });
      dependentes = await api.post(
        "/pessoa/dependentes" + requisicoes.entidade,
        { titular_id: v.value },
        requisicoes.header
      );

      for (let i = 0; i < dependentes.data.length; i++) {
        const element = dependentes.data[i];
        let ret2 = await api.post(
          "/lancamentoConta/lancamentoEmAbertoPorPessoa" + requisicoes.entidade,
          { pessoa_id: element.id },
          requisicoes.header
        );

        let lancamentosVencidos = await api.post(
          "/lancamentoConta/lancamentosVencidosPorPessoa" +
          requisicoes.entidade,
          { pessoa_id: element.id },
          requisicoes.header
        );
        let lancamentosAVencer = await api.post(
          "/lancamentoConta/lancamentosAVencidosPorPessoa" +
          requisicoes.entidade,
          { pessoa_id: element.id },
          requisicoes.header
        );
        let aux = 0.0;

        if (ret2.data.length > 0) {
          for (let i = 0; i < ret2.data.length; i++) {
            const element2 = ret2.data[i];
            if (
              this.state.selectedCheckboxes2.has(
                parseFloat(element2.id).toString()
              )
            ) {
              element2.select = true;
            } else {
              element2.select = false;
            }
            aux += parseFloat(element2.valor);
            // if (element2.data_vencimento < moment().format("yyyy-MM-DD")) {
            //   totalVencidoDependente += parseFloat(element2.valor);
            // } else {
            //   totalAVencerDependente += parseFloat(element2.valor);
            // }
          }
          for (let i = 0; i < lancamentosVencidos.data.length; i++) {
            const element3 = lancamentosVencidos.data[i];
            totalVencidoDependente += parseFloat(element3.valor);
          }
          for (let i = 0; i < lancamentosAVencer.data.length; i++) {
            const element4 = lancamentosAVencer.data[i];
            totalAVencerDependente += parseFloat(element4.valor);
          }

          totalTitular += aux;
          totalDependentes.push(aux);
          totalDependentesVencido.push(totalAVencerDependente);
          totalDependentesVencido.push(totalVencidoDependente);
          listaVencidaDependentes.push(lancamentosAVencer.data);
          listaVencidaDependentes.push(lancamentosVencidos.data);
          totalVencidoDependente = 0.0;
          totalAVencerDependente = 0.0;
          // lancamentosDependentes.push(ret2.data);
        }
      }
    }
    // Remove da lista se não tiver dividas
    for (let i = 0; i < listaVencidaDependentes.length; i++) {
      const element = listaVencidaDependentes[i];
      if (element.length === 0) {
        listaVencidaDependentes.splice(i, 1);
        i--;
      }
    }

    if (retorno !== null && retorno.data.length !== 0) {
      this.setState({
        total_titular: totalTitular,
        lancamento_contas: retorno.data,
        listaVencida: this.state.listaVencida,
        listaAVencer: this.state.listaAVencer,
        lista_vencida_dependentes: listaVencidaDependentes,
        lancamentosDependentes: lancamentosDependentes,
        total_vencido_dependente: totalDependentesVencido,
      });
    } else {
      this.mostraMensagem("Nenhum Titulo Em Aberto Encontrado!");
    }
  };

  buscaUsuarioHistoricoLancamento = async (i) => {
    if (!this.props.listaLancamentos[i].historicos) {
      return;
    }

    let listaHistoricos = this.props.listaLancamentos[i].historicos;
    let setUsuarios = new Set([]);

    for (let i = 0; i < listaHistoricos.length; i++) {
      const element = listaHistoricos[i];
      setUsuarios.add(element.usuario_id);
    }
    setUsuarios.add(2);

    const usuarios = await api.post(
      "/usuario/visualizaVarios" + requisicoes.entidade,
      {
        listaId: [...setUsuarios],
      },
      requisicoes.header
    );

    this.setState({ listaUsuarios: usuarios.data });
  };
  imprimir = async () => {
    this.setState({ imprimindo: true });
    try {
      await api.post(
        "/lancamentoConta/gerarPdfDividasTitulos" + requisicoes.entidade,
        {
          pessoa_id: this.props.pessoa.id,
        },
        requisicoes.header
      );

      var localStorage = window.localStorage;
      var token = localStorage.getItem("token_clube");

      const r = await api.get(
        "/lancamentoConta/buscarPdfDividasTitulos" +
        requisicoes.entidade +
        "&t=Baer " +
        token
      );

      var oReq = new XMLHttpRequest();

      var URLToPDF =
        r.config.baseURL +
        "lancamentoConta/buscarPdfDividasTitulos" +
        requisicoes.entidade +
        "&t=Baer " +
        token;

      oReq.open("GET", URLToPDF, true);
      oReq.responseType = "blob";
      oReq.onload = function () {
        const file = new Blob([oReq.response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      };
      oReq.send();
    } catch (e) {
      console.log("Error", e);
    }
    this.setState({ imprimindo: false });
  };

  imprimirLancamentoPagos = async () => {
    let retorno = await api.post(
      "/lancamentoConta/gerarPdfPagasTitulos" + requisicoes.entidade,
      {
        pessoa_id: this.props.pessoa.id,
        ano_pagamento: this.state.anoLancamento,
      },
      requisicoes.header
    );

    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");

    const r = await api.get(
      "/lancamentoConta/buscarPdfTitulosPagos" +
      requisicoes.entidade +
      "&t=Baer " +
      token
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "lancamentoConta/buscarPdfTitulosPagos" +
      requisicoes.entidade +
      "&t=Baer " +
      token;

    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  render() {
    return (
      <>
        <Row>
          <Col sm={3}>
            <Form.Control
              placeholder="Tipo"
              label="Tipo"
              id="tipoLancamento"
              as="select"
              name="tipoLancamento"
              onChange={this.alteracao}
              required
            >
              <option value="abertas">Abertos</option>
              <option value="cancelados">Canceladas</option>
              <option value="pagas">Pagos</option>
              <option value="vencidas">Vencidos</option>
            </Form.Control>
          </Col>
          {this.state.tipoLancamento === "pagas" && (
            <Col sm={3}>
              <Form.Control
                placeholder="Ano"
                label="Ano"
                id="anoLancamento"
                as="select"
                name="anoLancamento"
                onChange={this.alteracao}
                defaultValue={this.state.anoLancamento}
                required
              >
                {this.state.lista_anos.map((e, i) => (
                  <option value={e}>{e}</option>
                ))}
              </Form.Control>
            </Col>
          )}
          <Col sm={1}>
            <Button
              primary
              size="tiny"
              floated="right"
              onClick={this.buscaTitulos}
            >
              <Icon name="search"></Icon>
            </Button>
          </Col>
          {this.state.tipoLancamento === "pagas" && (
            <Col sm={1}>
              <Button
                primary
                size="tiny"
                // floated="right"
                onClick={this.imprimirLancamentoPagos}
              >
                <Icon name="print"></Icon>
              </Button>
            </Col>
          )}
          <Col></Col>

          <Col md="auto" style={{ paddingRight: 0 }}>
            <Modal
              onOpen={() => this.setState({ modalDividas: true })}
              onClose={() => this.setState({ modalDividas: false })}
              open={this.state.modalDividas}
              trigger={
                <Button
                  primary
                  type="button"
                  // style={{ float: "right", marginBottom: "10px" }}
                  onClick={() => {
                    this.setState({
                      selectedCheckboxes2: this.state.selectedCheckboxes,
                    });
                    this.buscaEmAbertoTitulo(null, {
                      value: this.props.pessoa.id,
                    });
                  }}
                >
                  Dívidas do título
                </Button>
              }
            >
              <Modal.Header>Dívidas do título</Modal.Header>
              <Modal.Content>
                {this.state.listaVencida.length > 0 ? (
                  <Table celled selectable compact>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={6}>
                          Nome Pessoa - Vencida
                        </Table.HeaderCell>
                        <Table.HeaderCell width={5}>Descrição</Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                          Vencimento
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign={"right"} width={2}>
                          Valor
                        </Table.HeaderCell>
                        {(localStorage.getItem("tipo_usuario_clube") ===
                          "administrativo" ||
                          localStorage.getItem("tipo_usuario_clube") ===
                          "caixa") && (
                            <Table.HeaderCell width={1}></Table.HeaderCell>
                          )}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.state.listaVencida.map((e, i) => (
                        <>
                          {i > 0 &&
                            moment(e.data_vencimento).month() !==
                            moment(
                              this.state.listaVencida[i - 1].data_vencimento
                            ).month() ? (
                            <Table.Row
                              key={i * -1}
                              style={{ background: "#2dcbec" }}
                            >
                              <Table.Cell
                                colSpan={5}
                                disabled
                                style={{ height: "2px", padding: "0px" }}
                              ></Table.Cell>
                            </Table.Row>
                          ) : null}
                          <Table.Row key={i}>
                            <Table.Cell style={{ color: "red" }}>
                              {e.pessoa.nome}
                            </Table.Cell>

                            {e.tipo_lancamento === "cobrancaServicoAvulsa" ? (
                              <Table.Cell>
                                {e.servico !== undefined && e.servico.nome}
                              </Table.Cell>
                            ) : (
                              <Table.Cell style={{ color: "red" }}>
                                {e.descricao !== null &&
                                  (e.descricao.toString().length > 20 ||
                                    e.tipo_lancamento === "sangria"
                                    ? TipoLancamento.find(
                                      (t) => t.value === e.tipo_lancamento
                                    ) !== undefined &&
                                    TipoLancamento.find(
                                      (t2) => t2.value === e.tipo_lancamento
                                    ).label
                                    : e.descricao)}
                              </Table.Cell>
                            )}
                            <Table.Cell style={{ color: "red" }}>
                              {e.data_vencimento !== null &&
                                format(
                                  parseISO(e.data_vencimento),
                                  "dd/MM/yyyy"
                                )}
                            </Table.Cell>
                            <Table.Cell
                              textAlign={"right"}
                              style={{ color: "red" }}
                            >
                              {e.valor === null
                                ? parseFloat(e.valor_pago)
                                  .toFixed(2)
                                  .replace(".", ",")
                                  .replace(/\d(?=(\d{3})+,)/g, "$&.")
                                : parseFloat(e.valor)
                                  .toFixed(2)
                                  .replace(".", ",")
                                  .replace(/\d(?=(\d{3})+,)/g, "$&.")}
                            </Table.Cell>
                            {(localStorage.getItem("tipo_usuario_clube") ===
                              "administrativo" ||
                              localStorage.getItem("tipo_usuario_clube") ===
                              "caixa") && (
                                <Table.Cell>
                                  <Checkbox
                                    label=""
                                    defaultChecked={e.select}
                                    onClick={this.toggleCheckbox2}
                                    defaultValue={e.id}
                                  />
                                </Table.Cell>
                              )}
                          </Table.Row>
                        </>
                      ))}
                    </Table.Body>
                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell colSpan={2}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} textAlign={"right"}>
                          <strong>
                            {"Total da divida: R$ " +
                              parseFloat(this.state.total_vencida)
                                .toFixed(2)
                                .replace(".", ",")
                                .replace(/\d(?=(\d{3})+,)/g, "$&.")}
                          </strong>
                        </Table.HeaderCell>
                        {(localStorage.getItem("tipo_usuario_clube") ===
                          "administrativo" ||
                          localStorage.getItem("tipo_usuario_clube") ===
                          "caixa") && <Table.HeaderCell></Table.HeaderCell>}
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                ) : null}

                {this.state.listaAVencer.length > 0 ? (
                  <Table celled selectable compact>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={6}>
                          Nome Pessoa - A Vencer
                        </Table.HeaderCell>
                        <Table.HeaderCell width={5}>Descrição</Table.HeaderCell>
                        <Table.HeaderCell width={2}>
                          Vencimento
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign={"right"} width={2}>
                          Valor
                        </Table.HeaderCell>
                        {(localStorage.getItem("tipo_usuario_clube") ===
                          "administrativo" ||
                          localStorage.getItem("tipo_usuario_clube") ===
                          "caixa") && (
                            <Table.HeaderCell width={1}></Table.HeaderCell>
                          )}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.state.listaAVencer.map((e, i) => (
                        <>
                          {i > 0 &&
                            moment(e.data_vencimento).month() !==
                            moment(
                              this.state.listaAVencer[i - 1].data_vencimento
                            ).month() ? (
                            <Table.Row
                              key={i * -1}
                              style={{ background: "#2dcbec" }}
                            >
                              <Table.Cell
                                colSpan={5}
                                disabled
                                style={{ height: "2px", padding: "0px" }}
                              ></Table.Cell>
                            </Table.Row>
                          ) : null}
                          <Table.Row key={i}>
                            <Table.Cell>{e.pessoa.nome}</Table.Cell>

                            {e.tipo_lancamento === "cobrancaServicoAvulsa" ? (
                              <Table.Cell>
                                {e.servico !== undefined && e.servico.nome}
                              </Table.Cell>
                            ) : (
                              <Table.Cell>
                                {e.descricao !== null &&
                                  (e.descricao.toString().length > 20 ||
                                    e.tipo_lancamento === "sangria"
                                    ? TipoLancamento.find(
                                      (t) => t.value === e.tipo_lancamento
                                    ) !== undefined &&
                                    TipoLancamento.find(
                                      (t2) => t2.value === e.tipo_lancamento
                                    ).label
                                    : e.descricao)}
                              </Table.Cell>
                            )}
                            <Table.Cell>
                              {e.data_vencimento !== null &&
                                format(
                                  parseISO(e.data_vencimento),
                                  "dd/MM/yyyy"
                                )}
                            </Table.Cell>
                            <Table.Cell textAlign={"right"}>
                              {e.valor === null
                                ? parseFloat(e.valor_pago)
                                  .toFixed(2)
                                  .replace(".", ",")
                                  .replace(/\d(?=(\d{3})+,)/g, "$&.")
                                : parseFloat(e.valor)
                                  .toFixed(2)
                                  .replace(".", ",")
                                  .replace(/\d(?=(\d{3})+,)/g, "$&.")}
                            </Table.Cell>
                            {(localStorage.getItem("tipo_usuario_clube") ===
                              "administrativo" ||
                              localStorage.getItem("tipo_usuario_clube") ===
                              "caixa") && (
                                <Table.Cell>
                                  <Checkbox
                                    label=""
                                    defaultChecked={e.select}
                                    onClick={this.toggleCheckbox2}
                                    defaultValue={e.id}
                                  />
                                </Table.Cell>
                              )}
                          </Table.Row>
                        </>
                      ))}
                    </Table.Body>

                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell colSpan={2}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} textAlign={"right"}>
                          <strong>
                            {"Total da divida: R$ " +
                              parseFloat(this.state.total_titular)
                                .toFixed(2)
                                .replace(".", ",")
                                .replace(/\d(?=(\d{3})+,)/g, "$&.")}
                          </strong>
                        </Table.HeaderCell>
                        {(localStorage.getItem("tipo_usuario_clube") ===
                          "administrativo" ||
                          localStorage.getItem("tipo_usuario_clube") ===
                          "caixa") && <Table.HeaderCell></Table.HeaderCell>}
                      </Table.Row>
                    </Table.Footer>
                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell colSpan={2}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} textAlign={"right"}>
                          <strong>
                            {"Total a vencer: R$ " +
                              parseFloat(this.state.total_aVencer)
                                .toFixed(2)
                                .replace(".", ",")
                                .replace(/\d(?=(\d{3})+,)/g, "$&.")}
                          </strong>
                        </Table.HeaderCell>
                        {(localStorage.getItem("tipo_usuario_clube") ===
                          "administrativo" ||
                          localStorage.getItem("tipo_usuario_clube") ===
                          "caixa") && <Table.HeaderCell></Table.HeaderCell>}
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                ) : null}

                {this.state.lista_vencida_dependentes.length > 0
                  ? this.state.lista_vencida_dependentes.map((e, i) => (
                    <Table celled selectable compact>
                      <Table.Header>
                        <Table.Row>
                          {e[i]?.data_vencimento <
                            moment().format("yyyy-MM-DD") ? (
                            <Table.HeaderCell width={6}>
                              Nome Pessoa - Vencidas
                            </Table.HeaderCell>
                          ) : (
                            <Table.HeaderCell width={6}>
                              Nome Pessoa - A Vencer
                            </Table.HeaderCell>
                          )}

                          <Table.HeaderCell width={5}>
                            Descrição
                          </Table.HeaderCell>
                          <Table.HeaderCell width={2}>
                            Vencimento
                          </Table.HeaderCell>
                          <Table.HeaderCell textAlign={"right"} width={2}>
                            Valor
                          </Table.HeaderCell>
                          {(localStorage.getItem("tipo_usuario_clube") ===
                            "administrativo" ||
                            localStorage.getItem("tipo_usuario_clube") ===
                            "caixa") && (
                              <Table.HeaderCell width={1}></Table.HeaderCell>
                            )}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {e.map((e2, i2) => (
                          <>
                            {i2 > 0 &&
                              moment(e2.data_vencimento).month() !==
                              moment(
                                this.state.lista_vencida_dependentes[i][
                                  i2 - 1
                                ].data_vencimento
                              ).month() ? (
                              <Table.Row
                                key={i * -1}
                                style={{ background: "#2dcbec" }}
                              >
                                <Table.Cell
                                  colSpan={5}
                                  disabled
                                  style={{ height: "2px", padding: "0px" }}
                                ></Table.Cell>
                              </Table.Row>
                            ) : null}

                            <Table.Row key={i2}>
                              {e2.data_vencimento <
                                moment().format("yyyy-MM-DD") ? (
                                <Table.Cell style={{ color: "red" }}>
                                  {e2.pessoa.nome}
                                </Table.Cell>
                              ) : (
                                <Table.Cell>{e2.pessoa.nome}</Table.Cell>
                              )}

                              {e2.data_vencimento <
                                moment().format("yyyy-MM-DD") ? (
                                e2.tipo_lancamento ===
                                  "cobrancaServicoAvulsa" ? (
                                  <Table.Cell>
                                    {e2.servico !== undefined &&
                                      e2.servico.nome}
                                  </Table.Cell>
                                ) : (
                                  <Table.Cell style={{ color: "red" }}>
                                    {e2.descricao !== null &&
                                      (e2.descricao.toString().length > 20 ||
                                        e2.tipo_lancamento === "sangria"
                                        ? TipoLancamento.find(
                                          (t) =>
                                            t.value === e2.tipo_lancamento
                                        ) !== undefined &&
                                        TipoLancamento.find(
                                          (t2) =>
                                            t2.value === e2.tipo_lancamento
                                        ).label
                                        : e2.descricao)}
                                  </Table.Cell>
                                )
                              ) : e2.tipo_lancamento ===
                                "cobrancaServicoAvulsa" ? (
                                <Table.Cell>
                                  {e2.servico !== undefined &&
                                    e2.servico.nome}
                                </Table.Cell>
                              ) : (
                                <Table.Cell>
                                  {e2.descricao !== null &&
                                    (e2.descricao.toString().length > 20 ||
                                      e2.tipo_lancamento === "sangria"
                                      ? TipoLancamento.find(
                                        (t) =>
                                          t.value === e2.tipo_lancamento
                                      ) !== undefined &&
                                      TipoLancamento.find(
                                        (t2) =>
                                          t2.value === e2.tipo_lancamento
                                      ).label
                                      : e2.descricao)}
                                </Table.Cell>
                              )}

                              {e2.data_vencimento <
                                moment().format("yyyy-MM-DD") ? (
                                <Table.Cell style={{ color: "red" }}>
                                  {e2.data_vencimento !== null &&
                                    format(
                                      parseISO(e2.data_vencimento),
                                      "dd/MM/yyyy"
                                    )}
                                </Table.Cell>
                              ) : (
                                <Table.Cell>
                                  {e2.data_vencimento !== null &&
                                    format(
                                      parseISO(e2.data_vencimento),
                                      "dd/MM/yyyy"
                                    )}
                                </Table.Cell>
                              )}
                              {e2.data_vencimento <
                                moment().format("yyyy-MM-DD") ? (
                                <Table.Cell
                                  textAlign={"right"}
                                  style={{ color: "red" }}
                                >
                                  {e2.valor === null
                                    ? parseFloat(e2.valor_pago)
                                      .toFixed(2)
                                      .replace(".", ",")
                                      .replace(/\d(?=(\d{3})+,)/g, "$&.")
                                    : parseFloat(e2.valor)
                                      .toFixed(2)
                                      .replace(".", ",")
                                      .replace(/\d(?=(\d{3})+,)/g, "$&.")}
                                </Table.Cell>
                              ) : (
                                <Table.Cell textAlign={"right"}>
                                  {e2.valor === null
                                    ? parseFloat(e2.valor_pago)
                                      .toFixed(2)
                                      .replace(".", ",")
                                      .replace(/\d(?=(\d{3})+,)/g, "$&.")
                                    : parseFloat(e2.valor)
                                      .toFixed(2)
                                      .replace(".", ",")
                                      .replace(/\d(?=(\d{3})+,)/g, "$&.")}
                                </Table.Cell>
                              )}

                              {(localStorage.getItem("tipo_usuario_clube") ===
                                "administrativo" ||
                                localStorage.getItem("tipo_usuario_clube") ===
                                "caixa") && (
                                  <Table.Cell textAlign={"center"}>
                                    <Checkbox
                                      label=""
                                      defaultChecked={e2.select}
                                      onClick={this.toggleCheckbox2}
                                      defaultValue={e2.id}
                                    />
                                  </Table.Cell>
                                )}
                            </Table.Row>
                          </>
                        ))}
                      </Table.Body>
                      <Table.Footer>
                        <Table.Row>
                          <Table.HeaderCell colSpan={2}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} textAlign={"right"}>
                            <strong>
                              {"Total : R$ " +
                                parseFloat(
                                  this.state.total_vencido_dependente[i]
                                )
                                  .toFixed(2)
                                  .replace(".", ",")
                                  .replace(/\d(?=(\d{3})+,)/g, "$&.")}
                            </strong>
                          </Table.HeaderCell>
                          {(localStorage.getItem("tipo_usuario_clube") ===
                            "administrativo" ||
                            localStorage.getItem("tipo_usuario_clube") ===
                            "caixa") && (
                              <Table.HeaderCell></Table.HeaderCell>
                            )}
                        </Table.Row>
                      </Table.Footer>
                    </Table>
                  ))
                  : null}
              </Modal.Content>

              <Modal.Actions>
                <Button
                  primary
                  onClick={() => {
                    this.setState({
                      selectedCheckboxes2: this.state.selectedCheckboxes,
                    });
                    this.imprimir(null, { value: this.props.pessoa.id });
                  }}
                >
                  Imprimir
                </Button>
                <Button onClick={() => this.setState({ modalDividas: false })}>
                  Voltar
                </Button>

                {(localStorage.getItem("tipo_usuario_clube") ===
                  "administrativo" ||
                  localStorage.getItem("tipo_usuario_clube") === "caixa") && (
                    <Link
                      to={{
                        pathname: "/caixa",
                        query: {
                          id: this.props.pessoa.id,
                          selectCheckBox: this.state.selectedCheckboxes2,
                        },
                        from: "meusDados",
                      }}
                    >
                      <Button
                        primary
                        type="button"
                        onClick={""}
                        style={{ float: "right", marginBottom: "10px" }}
                      >
                        Cobrar Dívidas
                      </Button>
                    </Link>
                  )}
              </Modal.Actions>
            </Modal>
          </Col>

          {localStorage.getItem("tipo_usuario_clube") !== "modalidade" && (
            <>
              <Col md="auto" style={{ paddingRight: 0 }}>
                <Button
                  primary
                  type="button"
                  onClick={this.gerarVariosBoletos}
                  style={{ float: "right", marginBottom: "10px" }}
                >
                  Imprimir
                </Button>
              </Col>

              {this.props.permissoes.find((e) => e.nome === "enviar_email") && (
                <Col md="auto">
                  <Button
                    primary
                    type="button"
                    onClick={() => {
                      this.gerarVariosBoletos(false);
                      this.setState({ openModalEmail: true });
                    }}
                    style={{ float: "right", marginBottom: "10px" }}
                  >
                    Enviar
                  </Button>
                </Col>
              )}
            </>
          )}
        </Row>

        <Table celled compact selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>Data Vencimento</Table.HeaderCell>
              <Table.HeaderCell width={2}>Referência</Table.HeaderCell>
              <Table.HeaderCell /*width={6}*/>Serviço</Table.HeaderCell>

              {this.state.tipoLancamento === "pagas" ? (
                <Table.HeaderCell width={1}>Data Pagamento</Table.HeaderCell>
              ) : null}
              <Table.HeaderCell width={1} textAlign={"right"}>Valor</Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign="center">
                <Col sm={12}><Icon name="dollar" size="large"></Icon></Col>
              </Table.HeaderCell>
              {localStorage.getItem("tipo_usuario_clube") !== "modalidade" &&
                this.state.tipoLancamento !== "pagas" && (<>
                  <Table.HeaderCell width={2} textAlign="center">
                    <Icon name="print" size="large"></Icon>
                  </Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign="center">
                    <Icon name="print" size="large"></Icon>
                  </Table.HeaderCell>
                </>)}
              {this.state.tipoLancamento === "pagas" ? (<>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </>) : null}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.listaLancamentos.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell width={1} >
                  {moment(e.data_vencimento).format("DD/MM/yyyy")}
                </Table.Cell>
                <Table.Cell width={2} >
                  {e.mes_referencia
                    ? e.mes_referencia + "/" + e.ano_referencia
                    : ""}
                </Table.Cell>
                <Table.Cell width={6} >
                  {e.servico !== null ? e.servico.nome : e.descricao}
                </Table.Cell>

                {this.state.tipoLancamento === "pagas" ? (
                  <Table.Cell width={1} >
                    {e.hora_baixa !== null
                      ? moment(e.data_pagamento).format("DD/MM/yyyy") +
                      " - " +
                      e.hora_baixa.slice(0, 5)
                      : moment(e.data_pagamento).format("DD/MM/yyyy")}
                  </Table.Cell>
                ) : null}
                <Table.Cell width={2} textAlign={"right"}>
                  {e.valor_pago !== null
                    ? valorFormatter.format(e.valor_pago)
                    : valorFormatter.format(e.valor)
                  }
                </Table.Cell>

                <Table.Cell width={1} textAlign="center">
                  {e.data_pagamento !== null ? (<Col sm={12}>
                    <Button
                      fluid
                      icon="thumbs up"
                      positive
                      size="tiny"
                      type="button"
                      onClick={() => this.setState({ openModalInformacoes: i })}
                    ></Button>
                  </Col>) : (<Col sm={12}>
                    <div>
                      {this.props.comparaDatas(e.data_vencimento) ? (
                        <Button
                          fluid
                          icon="thumbs up"
                          positive
                          size="tiny"
                          type="button"
                          onClick={() => {
                            this.setState({ openModalInformacoes: i });
                            this.buscaUsuarioHistoricoLancamento(i);
                          }}
                        ></Button>
                      ) : (
                        <Button
                          fluid
                          icon="thumbs down"
                          negative
                          size="tiny"
                          type="button"
                          onClick={() => {
                            this.setState({ openModalInformacoes: i });
                            this.buscaUsuarioHistoricoLancamento(i);
                          }}
                        ></Button>
                      )}
                    </div>
                  </Col>)}
                </Table.Cell>
                {localStorage.getItem("tipo_usuario_clube") !==
                  "modalidade" && (
                    <>
                      <Table.Cell
                        width={2}
                        textAlign="center"
                      >
                        {
                          // &&
                          ((e.numero_titulo !== null &&
                            e.numero_titulo.length <= 11) ||
                            moment(e.data_vencimento).isAfter(moment()) ||
                            (e.instituicao_cobranca &&
                              e.instituicao_cobranca.registro_automatico)) &&
                          e.tipo_cobranca === "boleto" &&
                          e.data_pagamento === null && (
                            <Row style={{ marginRight: 1, marginLeft: 1 }}>
                              <Col
                                sm={
                                  6
                                }
                                style={{ padding: 2 }}
                              >
                                <Button
                                  fluid
                                  icon="download"
                                  primary
                                  inverted={
                                    !e.remessa_gerada &&
                                    e.instituicao_cobranca &&
                                    e.instituicao_cobranca.registro_automatico
                                  }
                                  onClick={() => this.gerarBoleto(e.id)}
                                ></Button>
                              </Col>
                              {this.props.permissoes.find(
                                (e) => e.nome === "enviar_email"
                              ) ? (

                                <Col sm={6} style={{ padding: 2 }}>
                                  <Button
                                    fluid
                                    icon="mail"
                                    primary
                                    inverted={!e.impresso}
                                    onClick={() => {
                                      this.setState({ openModalEmail: true });
                                      this.gerarBoleto(e.id, false, true);
                                    }}
                                  ></Button>
                                </Col>
                              ) : null}
                            </Row>
                          )
                        }
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        {e.numero_titulo !== null &&
                          e.numero_titulo.length <= 11 &&
                          e.tipo_cobranca === "boleto" &&
                          e.data_pagamento === null && (
                            <Checkbox
                              label=""
                              onClick={this.toggleCheckbox}
                              defaultValue={e.id}
                            />
                          )}
                      </Table.Cell>
                    </>
                  )}
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell
                colSpan={1}
              ></Table.HeaderCell>
              <Table.HeaderCell ></Table.HeaderCell>
              <Table.HeaderCell textAlign={"right"}>
                {this.state.tipoLancamento !== "pagas" ? (
                  <strong>
                    {"Total: R$ " +
                      parseFloat(this.state.total_lancamentos)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/\d(?=(\d{3})+,)/g, "$&.")}
                    {/* .replace(/\d(?=(\d{3})+\,)/g, "$&.")} */}
                  </strong>
                ) : null}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign={"right"}>
                {this.state.tipoLancamento === "pagas" ? (
                  <strong>
                    {"Total: R$ " +
                      parseFloat(this.state.total_lancamentos)
                        .toFixed(2)
                        .replace(".", ",")
                        .replace(/\d(?=(\d{3})+,)/g, "$&.")}
                    {/* .replace(/\d(?=(\d{3})+\,)/g, "$&.")} */}
                  </strong>
                ) : null}
              </Table.HeaderCell>

              {this.state.tipoLancamento === "pagas" ? (
                <Table.HeaderCell
                  textAlign="center"
                ></Table.HeaderCell>
              ) : null}
              {this.state.tipoLancamento === "pagas" ? (
                <Table.HeaderCell
                  textAlign="center"
                ></Table.HeaderCell>
              ) : null}
              <Table.HeaderCell ></Table.HeaderCell>
              <Table.HeaderCell ></Table.HeaderCell>
              <Table.HeaderCell ></Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>

        <InformacaoLancamento
          open={this.state.openModalInformacoes !== null}
          onClose={() => this.setState({ openModalInformacoes: null })}
          lancamento={
            this.state.listaLancamentos[this.state.openModalInformacoes]
          }
          listaUsuarios={this.state.listaUsuarios}
        />

        <ModalEmail
          open={this.state.openModalEmail}
          onClose={() => this.setState({ openModalEmail: false })}
          onOpen={() => this.setState({ enviando: null, emailInvalido: false })}
          // functions
          gerarPdfBoletos={this.gerarPdfBoletos}
          alteracao={this.alteracao}
          enviarEmail={this.enviarEmail}
          // info
          assunto={this.state.assunto}
          mensagem={this.state.mensagem}
          email={this.state.email}
          // Estado
          enviando={this.state.enviando}
          emailInvalido={this.state.emailInvalido}
          gerandoBoleto={this.state.gerandoBoleto}
        />
      </>
    );
  }
}
