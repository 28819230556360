import React, { Component } from "react";
import { Header, Segment, Table } from "semantic-ui-react";
import { IconButton, Icon, Chip } from "@material-ui/core";

// Componentes criados
import ModalLiberaModulos from "./ModalLiberaModulos";

export default class LiberaModulos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Lista de dados
      listaEntidades: this.props.listaEntidades,

      // Modal

      entidadeSelectedModalLiberarModulo: null,
      openModalLiberarModulo: false,
    };
  }

  render() {
    return (
      <div>
        <Segment>
          <Header>Liberar Modulos </Header>
          <Table celled compact>
            <Table.Header>
              <Table.HeaderCell>Nome do Clube</Table.HeaderCell>
              <Table.HeaderCell>Módulos que possui</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {this.state.listaEntidades.map((e, i) => (
                <Table.Row>
                  <Table.Cell>{e.nome}</Table.Cell>
                  <Table.Cell>
                    {e.modulos.map((modulo) => (
                      <Chip
                        variant="outlined"
                        label={modulo.nome}
                        style={{ margin: 5 }}
                      />
                    ))}
                  </Table.Cell>

                  <Table.Cell
                    style={{
                      padding: 0,
                      textAlign: "center",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        this.setState({
                          entidadeSelectedModalLiberarModulo: e,
                          openModalLiberarModulo: true,
                        });
                      }}
                    >
                      <Icon color="primary">edit</Icon>
                    </IconButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
        <ModalLiberaModulos
          listaModulos={this.props.listaModulos}
          entidadeSelectedModalLiberarModulo={
            this.state.entidadeSelectedModalLiberarModulo
          }
          open={this.state.openModalLiberarModulo}
          onClose={() =>
            this.setState({
              openModalLiberarModulo: false,
              entidadeSelectedModalLiberarModulo: null,
            })
          }
        />
      </div>
    );
  }
}
