/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import {
  IconButton,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TableContainer,
  Table,
} from "@material-ui/core";

import { Delete } from "@material-ui/icons/";
import TipoPenalidade from "../../../enum/TipoPenalidade";
import cartaoVeremelho from "./cartaoVermelho.ico";
import cartaoAmarelo from "./cartaoAmarelo.ico";
import substituicao from "./substituicao.ico";
import falta from "./falta.ico";
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";
export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  deleteEvento = async (event) => {};
  render() {
    return (
      <TableContainer component={Paper} style={{ marginTop: "1ch" }}>
        <Table size="medium" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Atleta</TableCell>
              <TableCell align="left"></TableCell>
              <TableCell></TableCell>

              <TableCell>Situação</TableCell>
              <TableCell>Equipe</TableCell>
              <TableCell>Excluir</TableCell>
            </TableRow>
          </TableHead>
          {this.props.viewEventos !== undefined &&
          this.props.viewEventos.length > 0 ? (
            this.props.viewEventos.map((element, index) => {
              return (
                <TableBody>
                  <TableRow>
                    <TableCell component="th">
                      {element?.equipe_pessoas_princ?.p_m_d?.pessoa?.nome + " "}
                    </TableCell>
                    <TableCell
                      style={
                        element.descricao === "penalidade"
                          ? {
                              backgroundImage: `url(${cartaoVeremelho})`,
                              backgroundRepeat: "no-repeat",
                            }
                          : element.descricao === "advertencia"
                          ? {
                              backgroundImage: `url(${cartaoAmarelo})`,
                              backgroundRepeat: "no-repeat",
                            }
                          : element.descricao === "substituicao"
                          ? {
                              backgroundImage: `url(${substituicao})`,
                              backgroundRepeat: "no-repeat",
                            }
                          : element.descricao === "falta"
                          ? {
                              backgroundImage: `url(${falta})`,
                              backgroundRepeat: "no-repeat",
                            }
                          : {}
                      }
                    ></TableCell>
                    <TableCell>
                      {element.equipe_pessoas_secun !== null &&
                      element.equipe_pessoas_secun !== undefined
                        ? element.equipe_pessoas_secun.p_m_d.pessoa.nome
                        : ""}
                    </TableCell>
                    <TableCell component="th">
                      {
                        TipoPenalidade.find(
                          (e) => e.value === element.descricao
                        ).label
                      }
                    </TableCell>
                    <TableCell style={{}} component="th">
                      {element.equipe.organizacao.nome}
                    </TableCell>
                    <TableCell style={{}} component="th">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={(e) => this.props.deleteListaEvento(element)}
                        disabled={this.props.infoPartida.status === "encerrada"}
                      >
                        <Delete color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              );
            })
          ) : (
            <TableCell component="th">Sem Eventos Para Essa Partida</TableCell>
          )}
        </Table>
      </TableContainer>
    );
  }
}
