import firebase from "firebase/app";
import "firebase/storage";

const firebaseConfig = {
  // apiKey: "AIzaSyBuE5QklztxfQhqLndIc-MAFVZMVSAO-mg",
  // authDomain: "clube-1bbd0.firebaseapp.com",
  // databaseURL: "https://clube-1bbd0-default-rtdb.firebaseio.com",
  // projectId: "clube-1bbd0",
  // storageBucket: "clube-1bbd0.appspot.com",
  // messagingSenderId: "186350021591",
  // appId: "1:186350021591:web:066ac0d15b5591c0d3b992",
  // measurementId: "G-R67R67WC5P"
  apiKey: "AIzaSyDBR79gJ7VSYeQGdVm1Q29WMhT_CxawbJM",
  authDomain: "clube-f91a7.firebaseapp.com",
  projectId: "clube-f91a7",
  storageBucket: "clube-f91a7.appspot.com",
  messagingSenderId: "877219289567",
  appId: "1:877219289567:web:588f125b0929f8bef28c6a",
  measurementId: "G-ZLGTFKJH22"
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();

export { storage, firebase as default };
