import React, { Component } from "react";
import { Modal, Button, Checkbox, Icon, Table } from "semantic-ui-react";

export default class ModalAdicionaParametro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open_modal: false,
    };
  }

  adicionar = async () => {
    await this.props.adicionar();
    this.setState({ open_modal: false });
  };

  toggleCheckbox = async (i) => {
    let lista = this.props.parametros;
    lista[i].checked = !lista[i].checked;

    console.log("lista: ", lista);
  };

  render() {
    return (
      <Modal
        open={this.state.open_modal}
        onClose={() => {
          this.setState({ open_modal: false });
        }}
        onOpen={() => {
          this.setState({ open_modal: true });
        }}
        trigger={
          <Button
            type="button"
            floated="right"
            icon
            labelPosition="left"
            primary
            size="small"
          >
            <Icon name="plus" /> Adicionar
          </Button>
        }
        size="large"
        style={{ overflow: "visible" }}
      >
        <Modal.Header>Adicionar Parâmetro</Modal.Header>
        <Modal.Content>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Descrição</Table.HeaderCell>
                <Table.HeaderCell>Dias bloqueio</Table.HeaderCell>
                <Table.HeaderCell>Servico</Table.HeaderCell>
                <Table.HeaderCell>Grupo Servico</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.parametros.map(
                (e, i) =>
                  e.select !== true && (
                    <Table.Row>
                      <Table.Cell>{" " + e.descricao}</Table.Cell>
                      <Table.Cell>{e.dias}</Table.Cell>
                      <Table.Cell>
                        {e.descricao === "Financeiro-servico" && e.servico
                          ? e.servico.nome
                          : " - "}
                      </Table.Cell>
                      <Table.Cell>
                        {e.descricao === "Financeiro-grupo_servico" &&
                        e.grupo_servico
                          ? e.grupo_servico.descricao
                          : " - "}
                      </Table.Cell>
                      <Table.Cell>
                        <Checkbox
                          checked={e.checked}
                          onChange={() => this.toggleCheckbox(i)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  )
              )}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.setState({ open_modal: false })}>
            Voltar
          </Button>
          <Button positive onClick={this.adicionar}>
            Adicionar
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
