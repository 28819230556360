import React, { Component } from "react";
import { Segment } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import { Checkbox, FormControlLabel, Tooltip } from "@material-ui/core";

// Utils

// Requisicoes

export default class ParametrosFaee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Entradas

      // Estados botões
      salvando: false,
    };
  }

  render() {
    return (
      <div>
        <Segment>
          <Row>
            <Col md="auto" style={{ paddingRight: 0 }}>
              <FormControlLabel
                // value="start"
                control={
                  <Checkbox
                    color="primary"
                    name="pagamentos_com_caixa"
                    checked={this.props.pagamentos_com_caixa === "true"}
                    onChange={(e) => {
                      e.target.value = e.target.checked;
                      this.props.alteracao(e);
                    }}
                  />
                }
                label="Pagamentos com caixa"
                labelPlacement="end"
              />
            </Col>
            <Tooltip
              title="Vincular contas a pagar ao caixa. Pagamentos com dinheiro."
              style={{ paddingTop: 8 }}
              placement="right"
            >
              <div>(?)</div>
            </Tooltip>
          </Row>
        </Segment>
      </div>
    );
  }
}
