import React, { Component } from "react";
import { Header } from "semantic-ui-react";
import Titular from "./titular";
import Administrador from "./administrador";
import Almoxarifado from "./almoxarifado";
import Caixa from "./caixa";
import Dependente from "./dependente";
import Home from "./home";
import requisicoes from "../../utils/Requisicoes";
import api from "../../services/api";

export default class home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipoClube: window.localStorage.getItem("tipo_clube"),
      tipoUsuario: window.localStorage.getItem("tipo_usuario_clube"),
      pessoa: {},
    };
    this.visualizacao();
  }

  visualizacao = async () => {
    const respostaQRcode = await api.post(
      "/usuario/matricula" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    const resposta = await api.post(
      "/usuario/visualiza" + requisicoes.entidade,
      { id: respostaQRcode.data.matricula },
      requisicoes.header
    );
    const respPessoa = await api.post(
      "/pessoa/pessoaIdNome" + requisicoes.entidade,
      { id: resposta.data.pessoa_id },
      requisicoes.header
    );
    this.setState({ pessoa: respPessoa.data });
  };

  render() {
    let content = "";

    content = this.props.permissoes.find((e) => e.nome === "home_titular") ? (
      <Titular />
    ) : (
      <Home />
    );

    // switch (this.state.tipoUsuario) {
    //   case "temporario":
    //     content = <Home />;
    //     break;
    //   case "administrativo":
    //     content = <Home />;
    //     break;
    //   case "administrador":
    //     content = <Home />;
    //     break;
    //   case "administradorSistema":
    //     content = <Home />;
    //     break;
    //   case "almoxarifado":
    //     // content = <Almoxarifado />;
    //     content = <Home />;
    //     break;
    //   case "caixa":
    //     content = <Home />;
    //     break;
    //   case "dependente":
    //     // content = <Titular />;
    //     content = <Home />;
    //     break;
    //   case "financeiro":
    //     content = <Home />;
    //     break;
    //   case "funcionario":
    //     content = <Home />;
    //     break;
    //   case "impressaoCarteirinha":
    //     content = <Home />;
    //     break;
    //   case "medico":
    //     content = <Home />;
    //     break;
    //   case "modalidade":
    //     content = <Home />;
    //     break;
    //   case "portaria":
    //     content = <Home />;
    //     break;
    //   case "titular":
    //     // content = <Titular />;
    //     content = <Home />;
    //     break;
    //   default:
    //     break;
    // }
    return (
      // <div className="principal">
      <div className="pagina">
        <Header as="h1" dividing>
          {this.state.pessoa.nome}
        </Header>
        {content}
      </div>
      // </div>
    );
  }
}
