import React, { Component } from "react";
import { Header, Button, Table, Icon, Modal } from "semantic-ui-react";
import { Link } from "react-router-dom";

import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

export default class InstituicaoCobrancaLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instituicoes: [],

      // info modal delete
      instituicao_id_delete: null,
      index_delete: null,
    };
    this.buscaInstituicoes();
  }

  buscaInstituicoes = async () => {
    const instituicoes = await api.post(
      "/instituicaoCobranca/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ instituicoes: instituicoes.data });
  };

  deletarInstituicao = async () => {
    try {
      await api.post(
        "/instituicaoCobranca/deletar" + requisicoes.entidade,
        {
          id: this.state.instituicao_id_delete,
        },
        requisicoes.header
      );
      let listaInstituicao = this.state.instituicoes;
      listaInstituicao.splice(this.state.index_delete, 1);
      this.setState({ instituicoes: listaInstituicao, modalOpen: false });
    } catch (e) {
      alert(`\n
      Não foi possível deletar a instituição cobrança.
      Provavelmente já foi usada em um lançamento conta.`);
      this.setState({ modalOpen: false });
    }
  };

  alteracao(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Instituição cobrança
        </Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Agencia</Table.HeaderCell>
              <Table.HeaderCell>Conta</Table.HeaderCell>
              <Table.HeaderCell>Banco</Table.HeaderCell>
              <Table.HeaderCell>Cliente</Table.HeaderCell>
              <Table.HeaderCell collapsing>Boleto</Table.HeaderCell>
              <Table.HeaderCell collapsing>Debito em conta</Table.HeaderCell>
              <Table.HeaderCell>Convenio</Table.HeaderCell>

              <Table.HeaderCell textAlign="center">
                <Link
                  to={{
                    pathname: "/instituicaoCobranca",
                  }}
                >
                  <Button icon="add" color="green" size="tiny" collapsing />
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.instituicoes.map((e, i) => (
              <Table.Row>
                <Table.Cell>{e.agencia + " - " + e.digito_agencia}</Table.Cell>
                <Table.Cell>{e.conta + " - " + e.digito_conta}</Table.Cell>
                <Table.Cell>{e.banco.nome}</Table.Cell>
                <Table.Cell>{e.cliente + " - " + e.digito_cliente}</Table.Cell>

                <Table.Cell textAlign="center">
                  {e.boleto && (
                    <Icon color="green" name="check" size="big"></Icon>
                  )}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {e.debito_em_conta && (
                    <Icon color="green" name="check" size="big"></Icon>
                  )}
                </Table.Cell>
                <Table.Cell>{e.codigo_convenio}</Table.Cell>
                <Table.Cell textAlign="center" collapsing>
                  <Link
                    to={{
                      pathname: "/instituicaoCobranca",
                      query: { id: e.id },
                    }}
                  >
                    <Button icon="edit" color="green" size="tiny"></Button>
                  </Link>
                  <Button
                    icon="trash"
                    color="red"
                    size="tiny"
                    onClick={() => {
                      this.setState({
                        modalOpen: true,
                        instituicao_id_delete: e.id,
                        index_delete: i,
                      });
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {this.state.modalOpen &&
          this.state.instituicao_id_delete &&
          (this.state.index_delete || this.state.index_delete === 0) &&
          this.modalConfirmarExclusao()}
      </div>
    );
  }

  modalConfirmarExclusao() {
    return (
      <Modal
        open={this.state.modalOpen}
        onClose={() =>
          this.setState({
            modalOpen: false,
            instituicao_id_delete: null,
            index_delete: null,
          })
        }
        size="tiny"
      >
        <Modal.Header>Confirmar exclusão</Modal.Header>
        <Modal.Content>
          Tem certeza que deseja excluir o registro?
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            onClick={() => this.setState({ modalOpen: false })}
          >
            Cancelar
          </Button>
          <Button color="green" onClick={() => this.deletarInstituicao()}>
            Sim
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
