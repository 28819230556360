import React, { Component } from "react";
import {
  Form,
  Select,
  Table,
  Modal,
  Button,
  Input,
  Menu,
} from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";

import requisicoes from "../../../utils/Requisicoes";
import moment from "moment-timezone";
import api from "../../../services/api";

export default class ModalComissionado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comissoes: [],
      open_modal_comissionado: false,
      gruposOptions: [],
      servicosOptions: [],
      turmasOptions: [],
      grupoSelect: null,
      servicoSelect: null,
      turmaSelect: null,
      pessoaSelect: null,
      porcentagemInput: null,
      salvando: false,
      inativos: false,
      pessoaOptions: [],
      filtroIntervalo: false,
      comissaoEmAberto: 0.0,
      comissaoLancamentos: [],

      dataInicioFiltro: moment().startOf("month").format("YYYY-MM-DD"),
      dataFinalFiltro: moment().format("YYYY-MM-DD"),
      somasFiltro: {
        comissaoAtual: 0.0,
        comissaoEsperada: 0.0,
      },
      somasFiltroLancamentos: {
        comissaoAtual: 0.0,
        comissaoEsperada: 0.0,
      },
      activeItem: "turmas",
      comissoesLancamentos: [],
      tipoFiltro: "vencimento",
    };
  }

  buscaGrupos = async () => {
    let listaGrupos = await api.post(
      "/grupoServico/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    let lista = [];
    for (let i = 0; i < listaGrupos.data.length; i++) {
      const element = listaGrupos.data[i];
      lista.push({
        key: element.id,
        text: element.descricao,
        value: element.id,
      });
    }
    this.setState({ gruposOptions: lista });
  };

  buscaServicos = async (grupo_id) => {
    this.setState({ grupoSelect: grupo_id });

    let listaTurmas = await api.post(
      "/servico/turmaPorGrupoServicoeData" + requisicoes.entidade,
      {
        id: grupo_id,
        data: moment().year(),
      },
      requisicoes.header
    );

    let lista = [];
    for (let i = 0; i < listaTurmas.data.length; i++) {
      const element = listaTurmas.data[i];
      lista.push({ key: element.id, text: element.nome, value: element.id });
    }

    this.setState({ servicosOptions: lista });
  };

  buscaTurmas = async (servico_id) => {
    this.setState({ servicoSelect: servico_id });

    let listaTurmas = await api.post(
      "/turmaServico/turmaPorServico" + requisicoes.entidade,
      {
        id: servico_id,
      },
      requisicoes.header
    );

    let lista = [];
    if (listaTurmas.data.length > 1) {
      lista.push({ key: 0, text: "Todas", value: 0 });
    }
    for (let i = 0; i < listaTurmas.data.length; i++) {
      const element = listaTurmas.data[i];
      lista.push({
        key: element.id,
        text: element.nome + " - " + element.turma,
        value: element.id,
        turma: element.turma,
      });
    }

    this.setState({ turmasOptions: lista });
  };

  addComissao = () => {
    if (
      this.state.grupoSelect === null ||
      this.state.servicoSelect === null ||
      this.state.porcentagemInput === null ||
      (this.props.pessoa === undefined && this.state.pessoaSelect === null) ||
      parseFloat(this.state.porcentagemInput) > 100 ||
      parseFloat(this.state.porcentagemInput) < 0
    ) {
      return;
    }
    if (this.props.pessoa === undefined && this.state.pessoaSelect === null) {
      return;
    }
    let servico = this.state.servicosOptions.find(
      (element) => element.value === this.state.servicoSelect
    );
    let turma = this.state.turmasOptions.find(
      (element) => element.value === this.state.turmaSelect
    );
    let lista = this.state.comissoes;
    let pessoa_id =
      this.props.pessoa !== undefined
        ? this.props.pessoa.id
        : this.state.pessoaSelect;
    if (this.state.turmaSelect === 0) {
      for (let i = 1; i < this.state.turmasOptions.length; i++) {
        const element = this.state.turmasOptions[i];
        lista.push({
          data_ativacao: moment().format("YYYY-MM-DD"),
          data_inativacao: null,
          pessoa_id: pessoa_id,
          turma_servico_id: element.value,
          novo: true,
          alterado: false,
          porcentagem: parseFloat(this.state.porcentagemInput),
          turma_servico: {
            nome: servico.text,
            turma: element.turma,
          },
          comisssaoAtual: 0,
          comissaoMax: 0,
        });
      }
    } else {
      lista.push({
        data_ativacao: moment().format("YYYY-MM-DD"),
        data_inativacao: null,
        pessoa_id: pessoa_id,
        turma_servico_id: this.state.turmaSelect,
        novo: true,
        alterado: false,
        porcentagem: parseFloat(this.state.porcentagemInput),
        turma_servico: {
          nome: servico.text,
          turma: turma.turma,
        },
        comisssaoAtual: 0,
        comissaoMax: 0,
      });
    }
    this.setState({ comissoes: lista });
  };

  removeComissao = async (i) => {
    let lista = this.state.comissoes;
    if (lista[i].novo === true) {
      lista.splice(i, 1);
      this.setState({ comissoes: lista });
      return;
    } else {
      lista[i].data_inativacao = moment().format("YYYY-MM-DD");
      lista[i].alterado = true;
      this.setState({ comissoes: lista });
    }
  };

  addComissaoLancamento = (i) => {
    let lista = this.state.comissoesLancamentos;
    lista[i].data_inativacao = null;
    lista[i].alterado = true;
    this.setState({ comissoesLancamentos: lista });
  };

  removeComissaoLancamento = (i) => {
    let lista = this.state.comissoesLancamentos;
    lista[i].data_inativacao = moment().format("YYYY-MM-DD");
    lista[i].alterado = true;
    this.setState({ comissoesLancamentos: lista });
  };

  salvar = async () => {
    this.setState({ salvando: true });
    let listaComissoesNovas = [];
    let listaComissoesEditar = [];
    let listaComissoesLancamentos = [];

    for (let i = 0; i < this.state.comissoes.length; i++) {
      const element = this.state.comissoes[i];
      if (element.novo === true) {
        listaComissoesNovas.push(element);
      } else if (element.alterado === true) {
        listaComissoesEditar.push(element);
      }
    }
    if (this.props.pessoa === undefined) {
      await api.post(
        "/pessoa/alterar" +
          requisicoes.entidade +
          "&id=" +
          this.state.pessoaSelect,
        {
          comissionado: true,
        },
        requisicoes.header
      );
    }
    if (listaComissoesNovas.length > 0) {
      await api.post(
        "/comissionados/salvarVarios" + requisicoes.entidade,
        {
          comissoes: listaComissoesNovas,
        },
        requisicoes.header
      );
    }
    if (listaComissoesEditar.length > 0) {
      await api.post(
        "/comissionados/editarVarios" + requisicoes.entidade,
        {
          comissoes: listaComissoesEditar,
        },
        requisicoes.header
      );
    }
    for (let i = 0; i < this.state.comissoesLancamentos.length; i++) {
      const element = this.state.comissoesLancamentos[i];
      if (element.alterado === true) {
        listaComissoesLancamentos.push({
          id: element.id,
          data_inativacao: element.data_inativacao,
        });
      }
    }

    if (listaComissoesLancamentos.length > 0) {
      await api.post(
        "/comissionadosLancamentoConta/editarVarios" + requisicoes.entidade,
        {
          comissoes: listaComissoesLancamentos,
        },
        requisicoes.header
      );
    }

    this.setState({ salvando: false });
    this.setState({ open_modal_comissionado: false });
    this.props.buscaUltimaBusca();
  };
  // Busca Pessoa Comissionada
  buscaPessoa = (event) => {
    this.setState({
      popUpPesquisa: false,
      ultimaBusca: event.target.value,
    });
    api
      .post(
        "/pessoa/buscaComissionadosPessoa" + requisicoes.entidade,
        {
          pesquisa: event.target.value,
          comissionado: false,
        },
        requisicoes.header
      )
      .then((resp) => {
        if (resp.data.length === 0) {
          this.setState({ popUpPesquisa: true });
        } else {
          resp.data.forEach((element) => {
            element.key = element.id;
            element.text = element.nome;
            element.value = element.id;
          });

          this.setState({ pessoaOptions: resp.data });
        }
      });
  };

  alteracaoDatasFiltro = (a) => {
    if (a.name === undefined && a.value === undefined) {
      this.setState({ [a.target.name]: a.target.value });
    } else {
      this.setState({ [a.name]: a.value });
    }
    if (
      moment(this.state.dataInicioFiltro).isValid() === true &&
      moment(this.state.dataFinalFiltro).isValid() === true &&
      this.state.dataFinalFiltro > this.state.dataInicioFiltro
    ) {
      this.comissaoNoIntervalo();
    }
  };

  comissaoNoIntervalo = async (comissoes, comissoesLancamentos, tipoFiltro) => {
    console.log("tipoFiltro",tipoFiltro)
    if (comissoes !== undefined || comissoesLancamentos !== undefined) {
      this.setState({
        comissoes: comissoes,
        comissoesLancamentos: comissoesLancamentos,
      });
    } else {
      comissoes = this.state.comissoes;
      comissoesLancamentos = this.state.comissoesLancamentos;
    }

    let somasFiltro = {
      comissaoAtual: 0.0,
      comissaoEsperada: 0.0,
    };

    if (tipoFiltro !== undefined) {
      this.setState({ tipoFiltro: tipoFiltro });
    } else {
      tipoFiltro = this.state.tipoFiltro;
    }
    console.log("tipoFiltro 2:",tipoFiltro)

    let comissaoIntervalo = await api.post(
      "/comissionados/comissaoNoIntervalo" + requisicoes.entidade,
      {
        pessoa_id: this.props.pessoa.id,
        data_inicio: this.state.dataInicioFiltro,
        data_fim: this.state.dataFinalFiltro,
      },
      requisicoes.header
    );

    for (let i = 0; i < comissoes.length; i++) {
      const element = comissoes[i];
      element.comissaoAtualFiltro = 0.0;
      element.comissaoEsperadaFiltro = 0.0;

      for (let j = 0; j < comissaoIntervalo.data.length; j++) {
        const element2 = comissaoIntervalo.data[j];
        if (element.id === element2.id) {
          if (element2.total_pago !== null) {
            element.comissaoAtualFiltro +=
              parseFloat(element2.total_pago) *
              (parseFloat(element.porcentagem) / 100);
            element.comissaoEsperadaFiltro +=
              parseFloat(element2.total_pago) *
              (parseFloat(element.porcentagem) / 100);
          }
          if (element2.total_nao_pago !== null) {
            element.comissaoEsperadaFiltro +=
              parseFloat(element2.total_nao_pago) *
              (parseFloat(element.porcentagem) / 100);
          }
        }
      }

      //Lancamentos pagos
      // for (let k = 0; k < element.turma_servico.lancamentosPagos.length; k++) {
      //   let element3 = element.turma_servico.lancamentosPagos[k];
      //   if(
      //     moment(element3.data_pagamento) >= moment(this.state.dataInicioFiltro) &&
      //     moment(element3.data_pagamento) <= moment(this.state.dataFinalFiltro)
      //   ){
      //     element.comissaoAtualFiltro += parseFloat(element3.valor_pago) * (parseFloat(element.porcentagem)/100);
      //     element.comissaoEsperadaFiltro += element3.valor_pago === null ?
      //     parseFloat(element3.valor) * (parseFloat(element.porcentagem)/100) :
      //     parseFloat(element3.valor_pago) * (parseFloat(element.porcentagem)/100)
      //   }
      // }

      //Lancamentos não pagos
      // for (let k = 0; k < element.turma_servico.lancamentosNaoPagos.length; k++) {
      //   let element3 = element.turma_servico.lancamentosNaoPagos[k];
      //   if(
      //     moment(element3.data_vencimento) >= moment(this.state.dataInicioFiltro) &&
      //     moment(element3.data_vencimento) <= moment(this.state.dataFinalFiltro)
      //   ){
      //     element.comissaoEsperadaFiltro +=
      //     parseFloat(element3.valor) * (parseFloat(element.porcentagem)/100);
      //   }
      // }
      // if (element.data_inativacao === null) {
      //   somasFiltro.comissaoAtual += element.comissaoAtualFiltro;
      //   somasFiltro.comissaoEsperada += element.comissaoEsperadaFiltro;
      // }
    }

    var comissaoAtualLancamentos = 0.0;
    var comissaoEsperadaLancamentos = 0.0;
    let valor = 0;
    let contratoAtual = 0;
    let comisaoIntervalor = 0;

    for (let i = 0; i < comissoesLancamentos.length; i++) {
      const element = comissoesLancamentos[i];
      if (element.data_inativacao !== null && this.state.inativos === true) {
        continue;
      }

      var dataFiltro = null;
      switch (tipoFiltro) {
        case "vencimento":
          dataFiltro = element.lancamento_conta?.data_vencimento;
          break;
        case "pagamento":
          dataFiltro = element.lancamento_conta?.data_pagamento;
          break;
        default:
          break;
      }
      if (
        moment(dataFiltro).isBetween(
          this.state.dataInicioFiltro,
          this.state.dataFinalFiltro,
          undefined,
          "[]"
        )
      ) {
        contratoAtual += parseFloat(
          element.lancamento_conta?.valor_pago
            ? element.lancamento_conta?.valor_pago
            : element.lancamento_conta?.valor
        );

        if (element.lancamento_conta?.valor_pago !== null) {
          element.comissaoAtualLancamentos =
            element.lancamento_conta?.valor_pago * (element.porcentagem / 100);
          element.comissaoEsperadaLancamentos =
            element.lancamento_conta?.valor_pago * (element.porcentagem / 100);
          comissaoAtualLancamentos +=
            element.lancamento_conta?.valor_pago * (element.porcentagem / 100);
          comissaoEsperadaLancamentos +=
            element.lancamento_conta?.valor_pago * (element.porcentagem / 100);
        } else {
          element.comissaoAtualLancamentos = 0;
          element.comissaoEsperadaLancamentos =
            element.lancamento_conta?.valor * (element.porcentagem / 100);
          comissaoEsperadaLancamentos +=
            element.lancamento_conta?.valor * (element.porcentagem / 100);
        }
      }
    }

    this.setState({
      somasFiltro: somasFiltro,
      comissoesLancamentos: comissoesLancamentos,
      somasFiltroLancamentos: {
        totalContrato: contratoAtual,
        valor: valor,
        comissaoAtual: comissaoAtualLancamentos,
        comissaoEsperada: comissaoEsperadaLancamentos,
      },
    });
  };

  lancamentosComissionado = async (comissoesLancamentos) => {
    let listaComissionadoPagos = [];
    let comissaoEsperadaLancamentos = 0.0;

    for (let i = 0; i < comissoesLancamentos.length; i++) {
      const element = comissoesLancamentos[i];
      if (element.lancamento_conta?.data_pagamento === null) {
        listaComissionadoPagos.push(element);
        comissaoEsperadaLancamentos +=
          element.lancamento_conta?.valor * (element.porcentagem / 100);
      }
    }

    this.setState({
      comissaoLancamentos: listaComissionadoPagos,
      comissaoEmAberto: comissaoEsperadaLancamentos,
    });
  };

  imprimir = async () => {
    const buscarArtilheiros = await api.post(
      "/pessoa/gerarPDFComissionados" + requisicoes.entidade,
      {
        pessoa_id: this.props.pessoa.id,
        data_inicio: this.state.dataInicioFiltro,
        data_fim: this.state.dataFinalFiltro,
        tipoFiltro: this.state.tipoFiltro,
        comissionado: true,
      },
      requisicoes.header
    );

    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");

    const r = await api.get(
      "/pessoa/buscarPdfComissionados" +
        requisicoes.entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.id
    );
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "pessoa/buscarPdfComissionados" +
      requisicoes.entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.id;

    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  render() {
    return (
      <Modal
        open={this.state.open_modal_comissionado}
        onClose={() => {
          this.setState({ open_modal_comissionado: false });
          this.props.buscaUltimaBusca();
        }}
        onOpen={() => {
          if (
            this.props.pessoa !== undefined &&
            this.props.pessoa.comissoes !== undefined
          ) {
            this.comissaoNoIntervalo(
              this.props.pessoa.comissoes,
              this.props.pessoa.comissoes_lancamentos
            );
            this.lancamentosComissionado(
              this.props.pessoa.comissoes_lancamentos,
              this.props.pessoa.comissoes,
              this.props.pessoa.id
            );
          } else {
            this.setState({
              inativos: false,
              comissoes: [],
            });
          }
          this.setState({
            open_modal_comissionado: true,
            grupoSelect: null,
            servicoSelect: null,
            turmaSelect: null,
          });
          this.buscaGrupos();
        }}
        trigger={
          <Button
            icon={this.props.pessoa !== undefined ? "edit" : "add"}
            color={this.props.pessoa !== undefined ? "blue" : "green"}
            size="tiny"
          ></Button>
        }
        size="large"
        style={{ overflow: "visible" }}
      >
        <Modal.Header>
          {this.props.pessoa !== undefined
            ? "Editar o comissionado: " + this.props.pessoa.nome
            : "Adicionar novo comissionado"}
        </Modal.Header>

        <Modal.Content>
          <Row style={{ margin: 0 }}>
            {this.props.pessoa === undefined && (
              <Col sm={6} style={{ paddingLeft: 0 }}>
                <Form.Field
                  control={Select}
                  options={this.state.pessoaOptions}
                  placeholder="Pessoa"
                  onKeyUp={this.buscaPessoa}
                  onChange={(e, a) =>
                    this.setState({ pessoaSelect: parseInt(a.value) })
                  }
                  search
                  fluid
                />
              </Col>
            )}
            {this.props.pessoa !== undefined && (
              <>
                <Col>
                  <Row>
                    {this.state.activeItem === "comissao" && (
                      <Col>
                        <Col style={{ display: "flex" }}>
                          <Col sm={4}>
                            <Form.Field
                              name="dataInicioFiltro"
                              control={Input}
                              type="date"
                              label="Comissão no intervalo"
                              value={this.state.dataInicioFiltro}
                              onChange={(e, a) => this.alteracaoDatasFiltro(a)}
                              onKeyUp={(e) => this.alteracaoDatasFiltro(e)}
                              // onBlur={(e,a)=>this.alteracaoDatasFiltro(a)}
                            />
                          </Col>
                          <Col sm={4}>
                            <Form.Field
                              name="dataFinalFiltro"
                              control={Input}
                              type="date"
                              label="Comissão no intervalo"
                              value={this.state.dataFinalFiltro}
                              onChange={(e, a) => this.alteracaoDatasFiltro(a)}
                              onKeyUp={(e) => this.alteracaoDatasFiltro(e)}
                              // onBlur={(e)=>{this.alteracaoDatasFiltro(e)}}
                            />
                          </Col>

                          <Col sm={4}>
                            <Form.Field
                              name="tipoFiltro"
                              control={Select}
                              label="Tipo de filtro"
                              value={this.state.tipoFiltro}
                              onChange={(e, a) =>
                                this.comissaoNoIntervalo(
                                  undefined,
                                  undefined,
                                  a.value
                                )
                              }
                              // onKeyUp={(e)=>this.alteracaoDatasFiltro(e)}
                              // onBlur={(e)=>{this.alteracaoDatasFiltro(e)}}

                              options={[
                                {
                                  key: "vencimento",
                                  value: "vencimento",
                                  text: "Data de vencimento",
                                },
                                {
                                  key: "pagamento",
                                  value: "pagamento",
                                  text: "Data de pagamento",
                                },
                              ]}
                            />
                          </Col>
                        </Col>
                      </Col>
                    )}
                    {/* {this.state.activeItem === "lancamentos" && (
                      <Col md="auto">
                        <Button
                          color="blue"
                          type="submit"
                          disabled={this.state.enviando}
                        >
                          <Icon name="send" />
                          Enviar mensagem
                        </Button>
                      </Col>
                    )} */}

                    {/* {this.state.activeItem === "lancamentos" && (
                      <Button
                        primary
                        // type="submit"
                        size="small"
                        style={{
                          position: "absolute",
                          top: "40%",
                          right: "12%",
                        }}
                        // onChange={(e, a) =>
                        //   this.comissaoNoIntervalo(
                        //     undefined,
                        //     undefined,
                        //     a.value
                        //   )
                        // }
                        // onClick={this.busca}
                        onClick={(e, a) =>
                          this.comissaoNoIntervalo(
                            undefined,
                            undefined,
                            this.state.tipoFiltro
                          )
                        }
                      >
                        Buscar
                      </Button>
                    )} */}

                    {/* {this.state.activeItem !== "comissao" ||
                      (this.state.activeItem !== "lancamentos" && (
                        <Col sm={1}>
                          <Checkbox
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: "-14%",
                            }}
                            // label = "Inativos"
                            // style = {{display: 'flex', justifyContent: 'flex-end'}}
                            onChange={(e, a) =>
                              this.setState({ filtroIntervalo: a.checked })
                            }
                            checked={this.state.filtroIntervalo}
                          />
                        </Col>
                      ))} */}
                  </Row>
                </Col>
                <Col sm={this.state.activeItem === "comissao" ? 2 : 5}></Col>
                <Col sm={1}>
                  {/* <Checkbox
                    label="Inativos"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    onChange={(e, a) => {
                      this.setState({ inativos: a.checked });
                      this.comissaoNoIntervalo();
                    }}
                    checked={this.state.inativos}
                  /> */}
                </Col>
              </>
            )}
          </Row>
          {this.props.pessoa !== undefined && (
            <Menu pointing secondary>
              <Menu.Item
                name="turmas"
                active={this.state.activeItem === "turmas"}
                onClick={(e, { name }) => this.setState({ activeItem: name })}
              />
              <Menu.Item
                name="lancamentos"
                active={this.state.activeItem === "lancamentos"}
                onClick={(e, { name }) => this.setState({ activeItem: name })}
              />
              <Menu.Item
                name="comissao"
                active={this.state.activeItem === "comissao"}
                onClick={(e, { name }) => this.setState({ activeItem: name })}
              />
            </Menu>
          )}

          {this.state.activeItem === "turmas" && (
            <>
              <Table celled selectable compact>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Turma</Table.HeaderCell>
                    {/* <Table.HeaderCell>
                      Data ativação
                    </Table.HeaderCell> */}
                    {this.state.inativos && (
                      <Table.HeaderCell>Data inativação</Table.HeaderCell>
                    )}
                    <Table.HeaderCell textAlign="right">
                      Porcentagem
                    </Table.HeaderCell>
                    {this.props.pessoa !== undefined && (
                      <Table.HeaderCell textAlign="right">
                        Comissão Atual
                      </Table.HeaderCell>
                    )}
                    {this.props.pessoa !== undefined && (
                      <Table.HeaderCell textAlign="right">
                        {this.state.filtroIntervalo
                          ? "Total Esperado Intervalo"
                          : "Total Esperado Ano"}
                      </Table.HeaderCell>
                    )}
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {(this.props.pessoa !== undefined ||
                    this.state.pessoaSelect) &&
                    this.state.comissoes !== undefined &&
                    this.state.comissoes.map((e, i) =>
                      this.state.inativos || e.data_inativacao === null ? (
                        <Table.Row>
                          <Table.Cell>
                            {e.turma_servico.nome +
                              " - " +
                              e.turma_servico.turma}
                          </Table.Cell>
                          {/* <Table.Cell>
                            {moment(e.data_ativacao).format("DD/MM/YYYY")}
                          </Table.Cell> */}
                          {this.state.inativos && (
                            <Table.Cell>
                              {e.data_inativacao !== null
                                ? moment(e.data_inativacao).format("DD/MM/YYYY")
                                : ""}
                            </Table.Cell>
                          )}
                          <Table.Cell textAlign="right">
                            {parseFloat(e.porcentagem).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) + "%"}
                          </Table.Cell>
                          {this.props.pessoa !== undefined && (
                            <Table.Cell textAlign="right">
                              {this.state.filtroIntervalo
                                ? parseFloat(
                                    e.comissaoAtualFiltro
                                  ).toLocaleString("pt-BR", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : parseFloat(e.comisssaoAtual).toLocaleString(
                                    "pt-BR",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                            </Table.Cell>
                          )}
                          {this.props.pessoa !== undefined && (
                            <Table.Cell textAlign="right">
                              {this.state.filtroIntervalo
                                ? parseFloat(
                                    e.comissaoEsperadaFiltro
                                  ).toLocaleString("pt-BR", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : parseFloat(e.comissaoMax).toLocaleString(
                                    "pt-BR",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                            </Table.Cell>
                          )}
                          <Table.Cell collapsing>
                            {
                              <Button
                                icon="x"
                                color="red"
                                size="tiny"
                                onClick={() => this.removeComissao(i)}
                                disabled={e.data_inativacao !== null}
                                title="Deleta Turma"
                              ></Button>
                            }
                          </Table.Cell>
                        </Table.Row>
                      ) : null
                    )}
                </Table.Body>
                {this.props.pessoa !== undefined && (
                  <Table.Footer>
                    <Table.Row verticalAlign="middle" textAlign="center">
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>

                      {this.state.inativos && <Table.HeaderCell />}
                      <Table.HeaderCell textAlign="right">
                        {this.state.filtroIntervalo
                          ? parseFloat(
                              this.state.somasFiltro.comissaoAtual
                            ).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : parseFloat(
                              this.props.pessoa.comisssaoAtual
                            ).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {this.state.filtroIntervalo
                          ? parseFloat(
                              this.state.somasFiltro.comissaoEsperada
                            ).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : parseFloat(
                              this.props.pessoa.comissaoMax
                            ).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                      </Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                      {/* <Table.HeaderCell colSpan={4}>
                        Paginação aqui
                      </Table.HeaderCell> */}
                    </Table.Row>
                  </Table.Footer>
                )}
              </Table>
              <h3>Vincular novas turmas</h3>
              <Form>
                <Form.Group>
                  <Col sm={3}>
                    <Form.Field
                      label="Grupo serviço"
                      control={Select}
                      options={this.state.gruposOptions}
                      onChange={(e, a) => this.buscaServicos(a.value)}
                      required
                    />
                  </Col>
                  <Col sm={3}>
                    <Form.Field
                      label="Serviço"
                      control={Select}
                      options={this.state.servicosOptions}
                      disabled={this.state.grupoSelect === null}
                      onChange={(e, a) => this.buscaTurmas(a.value)}
                      required
                    />
                  </Col>
                  <Col sm={3}>
                    <Form.Field
                      label="Turma"
                      control={Select}
                      options={this.state.turmasOptions}
                      disabled={this.state.servicoSelect === null}
                      onChange={(e, a) =>
                        this.setState({ turmaSelect: a.value })
                      }
                      required
                    />
                  </Col>
                  <Col sm={2}>
                    <Form.Field
                      label="Comissão (%)"
                      placeholder="Porcentagem"
                      control={Input}
                      disabled={this.state.servicoSelect === null}
                      type="number"
                      step="0.01"
                      min="0"
                      max="100"
                      onChange={(e, a) =>
                        this.setState({ porcentagemInput: a.value })
                      }
                      required
                    />
                  </Col>

                  <Col
                    style={{ paddingTop: 22, paddingRight: 21, paddingLeft: 0 }}
                  >
                    <Button
                      type="button"
                      color="green"
                      icon="save"
                      onClick={this.addComissao}
                      title="Adiciona Turma"
                    />
                  </Col>
                </Form.Group>
              </Form>
            </>
          )}

          {this.state.activeItem === "comissao" && (
            <>
              <Table celled selectable compact>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Pessoa</Table.HeaderCell>
                    <Table.HeaderCell>Serviço</Table.HeaderCell>
                    <Table.HeaderCell>
                      {this.state.tipoFiltro === "vencimento"
                        ? "Vencimento"
                        : "Pagamento"}
                    </Table.HeaderCell>

                    {this.state.inativos && (
                      <Table.HeaderCell>Data inativação</Table.HeaderCell>
                    )}
                    <Table.HeaderCell textAlign="center">%</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">Valor</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      Comissão
                    </Table.HeaderCell>

                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {(this.props.pessoa !== undefined ||
                    this.state.pessoaSelect) &&
                    this.state.comissoesLancamentos !== undefined &&
                    this.state.comissoesLancamentos.map((e, i) =>
                      this.state.inativos ||
                      (e.data_inativacao === null &&
                        moment(
                          this.state.tipoFiltro === "vencimento"
                            ? e.lancamento_conta?.data_vencimento
                            : this.state.tipoFiltro === "pagamento"
                            ? e.lancamento_conta?.data_pagamento
                            : null
                        ).isBetween(
                          this.state.dataInicioFiltro,
                          this.state.dataFinalFiltro,
                          undefined,
                          "[]"
                        )) ? (
                        <Table.Row>
                          <Table.Cell>
                            {e.lancamento_conta?.pessoa.nome
                              ? e.lancamento_conta?.pessoa.nome
                              : ""}
                          </Table.Cell>
                          <Table.Cell>
                            {e.lancamento_conta?.servico.nome
                              ? e.lancamento_conta?.servico.nome
                              : ""}
                          </Table.Cell>

                          {/* <Table.Cell>
                            {moment(e.lancamento_conta?.data_vencimento).format(
                              "DD/MM/YYYY"
                            )}
                          </Table.Cell> */}
                          <Table.Cell>
                            {this.state.tipoFiltro === "pagamento"
                              ? e.lancamento_conta?.data_pagamento !== null
                                ? moment(
                                    e.lancamento_conta?.data_pagamento
                                  ).format("DD/MM/YYYY")
                                : ""
                              : moment(
                                  e.lancamento_conta?.data_vencimento
                                ).format("DD/MM/YYYY")}
                          </Table.Cell>
                          {this.state.inativos && (
                            <Table.Cell>
                              {e.data_inativacao !== null
                                ? moment(e.data_inativacao).format("DD/MM/YYYY")
                                : ""}
                            </Table.Cell>
                          )}
                          <Table.Cell textAlign="right">
                            {parseFloat(e.porcentagem).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) + "%"}
                          </Table.Cell>

                          {this.props.pessoa !== undefined && (
                            <Table.Cell textAlign="right">
                              {parseFloat(
                                e.lancamento_conta?.valor_pago
                                  ? e.lancamento_conta?.valor_pago
                                  : e.lancamento_conta?.valor
                              ).toLocaleString("pt-BR", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Table.Cell>
                          )}

                          {this.props.pessoa !== undefined && (
                            <Table.Cell textAlign="right">
                              {parseFloat(e.comissaoEsperada).toLocaleString(
                                "pt-BR",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </Table.Cell>
                          )}
                          <Table.Cell collapsing>
                            {e.data_inativacao === null ? (
                              <Button
                                icon="x"
                                color="red"
                                size="tiny"
                                onClick={() => this.removeComissaoLancamento(i)}
                              ></Button>
                            ) : (
                              <Button
                                icon="add"
                                color="green"
                                size="tiny"
                                onClick={() => this.addComissaoLancamento(i)}
                              ></Button>
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ) : null
                    )}
                </Table.Body>
                {this.props.pessoa !== undefined && (
                  <Table.Footer>
                    <Table.Row verticalAlign="middle" textAlign="center">
                      {/* <Table.HeaderCell></Table.HeaderCell> */}
                      {/* <Table.HeaderCell></Table.HeaderCell> */}
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                      {this.state.inativos && <Table.HeaderCell />}
                      <Table.HeaderCell textAlign="right">
                        {
                          //  parseFloat(
                          //     this.state.somasFiltroLancamentos.comissaoAtual
                          //   ).toLocaleString("pt-BR", {
                          //     minimumFractionDigits: 2,
                          //     maximumFractionDigits: 2,
                          //   })

                          parseFloat(
                            this.state.somasFiltroLancamentos.totalContrato
                          ).toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        }
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {
                          parseFloat(
                            this.state.somasFiltroLancamentos.comissaoEsperada
                          ).toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                          // parseFloat(
                          //   this.props.pessoa.comissaoEsperadaLancamentos
                          // ).toLocaleString("pt-BR", {
                          //   minimumFractionDigits: 2,
                          //   maximumFractionDigits: 2,
                          // })
                        }
                      </Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                      {/* <Table.HeaderCell colSpan={4}>
                        Paginação aqui
                      </Table.HeaderCell> */}
                    </Table.Row>
                  </Table.Footer>
                )}
              </Table>
            </>
          )}
          {/* //todos: Lancamento   */}
          {this.state.activeItem === "lancamentos" && (
            <>
              <Table celled selectable compact>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Pessoa</Table.HeaderCell>
                    <Table.HeaderCell>Serviço</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">%</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      Total Contrato
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {(this.props?.pessoa !== undefined ||
                    this.state.pessoaSelect) &&
                    this.state.comissaoLancamentos !== undefined &&
                    this.state.comissaoLancamentos.map((e, i) => (
                      <Table.Row>
                        <Table.Cell>
                          {e?.lancamento_conta?.pessoa?.nome}
                        </Table.Cell>
                        <Table.Cell>
                          {e?.lancamento_conta?.servico?.nome}
                        </Table.Cell>

                        <Table.Cell textAlign="right">
                          {parseFloat(e.porcentagem).toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) + "%"}
                        </Table.Cell>

                        {this.props.pessoa !== undefined && (
                          <Table.Cell textAlign="right">
                            {parseFloat(e.comissaoEsperada).toLocaleString(
                              "pt-BR",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </Table.Cell>
                        )}
                      </Table.Row>
                    ))}
                </Table.Body>
                {this.props.pessoa !== undefined && (
                  <Table.Footer>
                    <Table.Row verticalAlign="middle" textAlign="center">
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                      {this.state.inativos && <Table.HeaderCell />}
                      <Table.HeaderCell textAlign="right">
                        {parseFloat(this.state.comissaoEmAberto).toLocaleString(
                          "pt-BR",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                )}
              </Table>
            </>
          )}
          {/* // Todos: fim lancamento */}
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              this.setState({ open_modal_comissionado: false });
              this.props.buscaUltimaBusca();
            }}
          >
            Voltar
          </Button>
          {this.state.activeItem !== "lancamentos" && (
            <Button
              color="green"
              onClick={() => this.salvar()}
              loading={this.state.salvando}
            >
              Salvar
            </Button>
          )}
          {this.state.activeItem === "comissao" && (
            <Button
              color="blue"
              onClick={() => this.imprimir()}
              loading={this.state.salvando}
            >
              Imprimir
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}
