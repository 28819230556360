import React, { Component } from "react";
import {
  Container,
  Table,
  Button,
  Menu,
  Icon,
  Header,
} from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import Select from "react-select";
import requisicoes from "../../../utils/Requisicoes";

import ListaFuncionario from "./listaFuncionario";
import ListaCargo from "./listaCargo";

export default class MenuFuncionario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagina: 1,
      totalPaginas: 1,
      activeItem: "funcionarios",
    };
  }

  render() {
    var content = {};
    switch (this.state.activeItem) {
      case "funcionarios":
        content = <ListaFuncionario />;
        break;
      case "cargos":
        content = <ListaCargo />;
        break;
      default:
        content = null;
        break;
    }
    return (
      <div className="lista">
        <Menu pointing secondary>
          <Menu.Item
            name="funcionarios"
            active={this.state.activeItem === "funcionarios"}
            onClick={() => this.setState({ activeItem: "funcionarios" })}
          />
          <Menu.Item
            name="cargos"
            active={this.state.activeItem === "cargos"}
            onClick={() => this.setState({ activeItem: "cargos" })}
          />
        </Menu>
        {content}
      </div>
    );
  }
}
