import moment from "moment-timezone";

const lista_feriados = [
    "01/01", // 01 de Janeiro - Ano novo
    "15/04", // 15 de Abril - Paixão de Cristo
    "21/04", // 21 de Abril - Tiradentes
    "01/05", // 01 de Maio - Dia do Trabalhador
    "07/09", // 07 de Setembro - Independência do Brasil
    "12/10", // 12 de Outubro - Nossa Senhora Aparecida
    "02/11", // 02 de Novembro - Finados
    "15/11", // 15 de Novembro - Proclamação da República
    "25/12", // 25 de Dezembro - Natal
];

const checkFeriado = (data, lista) => {
    let dataVerificacao = moment(data);
    let dataFeriado = dataVerificacao.date().toString().padStart(2, "0")+'/'+
                      (dataVerificacao.month()+1).toString().padStart(2, "0");
    return lista_feriados.includes(dataFeriado) || 
            (lista !== undefined && lista !== null && lista.length > 0 ? 
                lista.includes(dataVerificacao.format('YYYY-MM-DD')) : false);
  };
  
  export default checkFeriado;