import { extend } from "lodash";
import React, { Component } from "react";
import { Row, Col, FormControl, Form } from "react-bootstrap";
import {
  Container,
  Header,
  Button,
  Divider,
  Segment,
  Menu,
  Icon,
} from "semantic-ui-react";

import Dividas from "./dividas/index";

import { GiEntryDoor } from "react-icons/gi";

import api from "../../services/api";

export default class Auditoria extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: "dividas",
    };
  }
  render() {
    let content = "";
    switch (this.state.selectedMenu) {
      case "dividas":
        content = <Dividas />;
        break;
    }
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Auditoria
        </Header>
        <Menu tabular icon="labeled">
          <Menu.Item
            name="dividas"
            active={this.state.selectedMenu === "dividas"}
            onClick={this.selectMenu.bind(null, "dividas")}
          >
            <Icon name="dollar" />
            Dívidas
          </Menu.Item>
        </Menu>
        {content}
      </div>
    );
  }
  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
  };
}
