import React, { Component } from "react";
import CheeseburgerMenu from "cheeseburger-menu";
import {
  Menu as MenuSemantic,
  Icon,
  Dropdown,
  Container,
} from "semantic-ui-react";
import HamburgerMenu from "react-hamburger-menu";
import MenuContent from "./menuContent";
import api from "../services/api";
import "../styles/styles.css";
import { Col } from "react-bootstrap";
import requisicoes from "../utils/Requisicoes";
import renomearChave from "../services/renomearChave";

import { Link, Redirect } from "react-router-dom";

export default class menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: this.props.menus,
      menuOpen: false,
      pessoa: {},
      login: "",
      entidades: [],
    };
    this.buscaUsuarioLogado();
    this.listaEntidade();
    console.log("this.props: ", this.props);
  }

  openMenu() {
    this.setState({ menuOpen: true });
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  buscaUsuarioLogado = async () => {
    var localStorage = window.localStorage;
    var e = localStorage.getItem("e_clube");
    var token = localStorage.getItem("token_clube");
    const usuario = await api.post("/usuario/matricula?e=" + e, "", {
      headers: { token: "Baer " + token },
    });
    const buscaUsuario = await api.post(
      "/usuario/visualiza?e=" + e,
      { id: usuario.data.matricula },
      { headers: { token: "Baer " + token } }
    );
    this.setState({ login: buscaUsuario.data.login });
    const pessoa = await api.post(
      "/pessoa/pessoaIdNome?e=" + e,
      { id: buscaUsuario.data.pessoa_id },
      { headers: { token: "Baer " + token } }
    );
    this.setState({ pessoa: pessoa.data });
  };

  sair = async () => {
    var localStorage = window.localStorage;
    localStorage.setItem("token_clube", undefined);
    window.location.href = "/";
    // return <Redirect to={"/"} />;
  };

  listaEntidade = async () => {
    var localStorage = window.localStorage;
    var tipo_usuario = localStorage.getItem("tipo_usuario_clube");
    var token = localStorage.getItem("token_clube");
    if (tipo_usuario === "administradorSistema" && token.length > 10) {
      await api
        .post("/entidade/lista?e=public", {}, requisicoes.header)
        .then((resp) => {
          let lista = [];
          resp.data.map((e) => {
            if (e.descricao !== "public") {
              e = renomearChave(e, "descricao", "value");
              e = renomearChave(e, "nome", "text");
              e.key = e.value;
              lista.push(e);
            } else {
              e = renomearChave(e, "descricao", "value");
              e.text = "Entidade administradora";
              e.key = e.value;
              lista.push(e);
            }
          });
          this.setState({ entidades: lista });
        });
    }
  };

  alteracaoEntidade = (e, { value }) => {
    var localStorage = window.localStorage;
    localStorage.setItem("e_clube", value);
    window.location.reload();
  };

  render() {
    return (
      <div>
        <div className="menuUsuario">
          <MenuSemantic
            fluid
            inverted
            color={"blue"}
            style={{ backgroundColor: "#1045c2" }}
          >
            <MenuSemantic.Menu position="right">
              <Link to={"/politica"}>
                <MenuSemantic.Item
                  active
                  // onClick={() => {
                  //   return <Redirect to={"/politica"} />;
                  // }}
                >
                  Política de dados
                </MenuSemantic.Item>
              </Link>

              {localStorage.getItem("tipo_usuario_clube") ===
                "administradorSistema" &&
                localStorage.getItem("token_clube").length > 10 && (
                  <MenuSemantic.Item>
                    <Col sm={12}>
                      <Dropdown
                        placeholder="Entidade administradora"
                        name="entidade"
                        selection
                        search
                        scrolling
                        onChange={this.alteracaoEntidade}
                        options={this.state.entidades}
                        value={localStorage.getItem("e_clube")}
                      />
                    </Col>
                  </MenuSemantic.Item>
                )}
              <MenuSemantic.Item name={this.state.pessoa.nome} active>
                <Icon name="user circle outline" /> {this.state.pessoa.nome}
              </MenuSemantic.Item>
              <MenuSemantic.Item name={this.state.login} active>
                {this.state.login}
              </MenuSemantic.Item>
              <MenuSemantic.Item
                name="Sair"
                onClick={this.sair}
                active
                color="grey"
              >
                <Icon name="sign-out" color="white" />
              </MenuSemantic.Item>
            </MenuSemantic.Menu>
          </MenuSemantic>
        </div>
        <Container>
          <MenuContent menus={this.props.menus} />
        </Container>
      </div>
    );
  }
}
