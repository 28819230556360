import React, { Component } from "react";
import {
  Table,
  Button,
  Header,
  Search,
  Pagination,
  Checkbox,
} from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import requisicoes from "../../../utils/Requisicoes";
import moment from "moment";

export default class TreinoLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaTreino: [],
      listaMeusTreino: [],
      pagina: 1,
      totalPaginas: 1,
      busca: null,
      inativos: false,
    };
    this.listaTreino();
    this.listaMeusTreino();
  }

  listaMeusTreino = async () => {
    const respostaUsuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ usuarioLogado: respostaUsuarioLogado.data });

    await api
      .post(
        "/treino/treinoPorPessoa" + requisicoes.entidade,
        {
          aluno_id: respostaUsuarioLogado.data.pessoa_id,
        },
        requisicoes.header
      )
      .then((resp) => {
        this.setState({
          listaMeusTreino: resp.data,
        });
      });
  };

  listaTreino = async (a, data, busca) => {
    if (data === undefined || data === null) {
      data = {};
      data.activePage = 1;
    }

    if (busca !== undefined && busca !== null && busca.trim() === "") {
      busca = undefined;
    }
    await api
      .post(
        "/treino/lista" + requisicoes.entidade,
        {
          busca: busca,
          inativos: this.state.inativos,
          pagina: data.activePage || this.state.pagina,
        },
        requisicoes.header
      )
      .then((resp) => {
        const { docs, pages } = resp.data;
        this.setState({
          listaTreino: docs,
          pagina: data.activePage,
          totalPaginas: pages,
        });
      });
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === "busca") {
      this.listaTreino(null, null, event.target.value);
      // this.listaTreino(null, null);
    }
  };

  onChangeCheckboxInativos = (evt, data) => {
    this.setState({ inativos: data.checked });
    setTimeout(() => {
      this.listaTreino(null, null, this.state.busca);
    }, 500);
  };

  render() {
    return (
      <div className="lista">
        <Header
          as="h1"
          dividing
          style={{
            display:
              window.localStorage.getItem("pagina_anterior") === "pessoa"
                ? "none"
                : "block",
          }}
        >
          Treinos
        </Header>

        {localStorage.getItem("tipo_usuario_clube") === "administrador" ||
        localStorage.getItem("tipo_usuario_clube") === "administradorSistema" ||
        localStorage.getItem("tipo_usuario_clube") === "administrativo" ||
        localStorage.getItem("tipo_usuario_clube") === "modalidade" ? (
          <>
            <Row>
              <Col sm={2}>
                <Search
                  placeholder="Digite para buscar"
                  name="busca"
                  onSearchChange={this.alteracao}
                  open={false}
                  fluid
                />
              </Col>
              <Col sm={1}>
                <Col sm={12} style={{ padding: "0" }}>
                  Inativos
                </Col>
                <Col sm={12} style={{ padding: "0" }}>
                  <Checkbox
                    toggle
                    onChange={(evt, data) =>
                      this.onChangeCheckboxInativos(evt, data)
                    }
                    checked={this.state.inativos}
                  />
                </Col>
              </Col>
            </Row>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Nome</Table.HeaderCell>
                  <Table.HeaderCell>Aluno</Table.HeaderCell>
                  <Table.HeaderCell>Professor</Table.HeaderCell>
                  <Table.HeaderCell>Data Lançamento</Table.HeaderCell>
                  <Table.HeaderCell>Data Validade</Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign="center">
                    <Link to={{ pathname: "/treino" }}>
                      <Button positive icon="add"></Button>
                    </Link>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {this.state.listaTreino.map((e, i) => (
                  <Table.Row
                    key={i}
                    negative={e.status === "inativo"}
                    positive={e.status === "ativo"}
                  >
                    <Table.Cell>{e.nome}</Table.Cell>
                    <Table.Cell>{e.aluno.nome}</Table.Cell>
                    <Table.Cell>{e.professor.nome}</Table.Cell>
                    <Table.Cell>
                      {e.data_lancamento !== null && (
                        <>{moment(e.data_lancamento).format("DD/MM/YYYY")}</>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {e.data_validade !== null && (
                        <>{moment(e.data_validade).format("DD/MM/YYYY")}</>
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Link to={{ pathname: "/treino", query: { id: e.id } }}>
                        <Button primary icon="edit"></Button>
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>

              <Table.Footer>
                <Table.Row verticalAlign="middle" textAlign="center">
                  <Table.HeaderCell colSpan="6">
                    <Pagination
                      boundaryRange={0}
                      ellipsisItem={null}
                      totalPages={this.state.totalPaginas}
                      onPageChange={this.listaTreino}
                      activePage={this.state.pagina}
                    ></Pagination>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </>
        ) : (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nome</Table.HeaderCell>
                <Table.HeaderCell>Aluno</Table.HeaderCell>
                <Table.HeaderCell>Professor</Table.HeaderCell>
                <Table.HeaderCell>Data Lançamento</Table.HeaderCell>
                <Table.HeaderCell>Data Validade</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.state.listaTreino.map((e, i) => (
                <Table.Row
                  key={i}
                  negative={e.status === "inativo"}
                  positive={e.status === "ativo"}
                >
                  <Table.Cell>{e.nome}</Table.Cell>
                  <Table.Cell>{e.aluno.nome}</Table.Cell>
                  <Table.Cell>{e.professor.nome}</Table.Cell>
                  <Table.Cell>
                    {e.data_lancamento !== null && (
                      <>{moment(e.data_lancamento).format("DD/MM/YYYY")}</>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {e.data_validade !== null && (
                      <>{moment(e.data_validade).format("DD/MM/YYYY")}</>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
    );
  }
}
