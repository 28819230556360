import React, { Component } from "react";
import { Row, Col, FormControl, Form } from "react-bootstrap";
import {
  Container,
  Header,
  Input,
  FormField,
  Button,
  Divider,
  Segment,
} from "semantic-ui-react";
import InputMask from "react-input-mask";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

export default class ClubeVantagem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      nome: null,
      endereco: null,
      telefone: null,
      celular: null,
    };
    this.visualizacao();
    this.alteracao = this.alteracao.bind(this);
  }

  visualizacao = async () => {
    if (this.props.location.query !== undefined) {
      const resposta = await api.post(
        "/clubeVantagem/visualiza" + requisicoes.entidade,
        this.props.location.query,
        requisicoes.header
      );
      this.setState(resposta.data);
    }
  };

  alteracao(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit = (event) => {
    this.submit();
    event.preventDefault();
  };

  voltar() {
    window.location.href = "/administrativo";
  }

  submit = async () => {
    const dados = this.state;
    let retorno = "";
    if (this.state.id !== null) {
      retorno = await api.post(
        "/clubeVantagem/alterar" +
          requisicoes.entidade +
          "&id=" +
          this.state.id,
        dados,
        requisicoes.header
      );
    } else {
      await api.post(
        "/clubeVantagem/salvar" + requisicoes.entidade,
        dados,
        requisicoes.header
      );
    }
    window.location.href = "/administrativo";
  };

  excluir = async () => {
    const resposta = await api.post(
      "/clubeVantagem/excluir" + requisicoes.entidade + "&id=" + this.state.id,
      {},
      requisicoes.header
    );
    if (resposta.data !== null) {
      window.location.href = "/administrativo";
    }
  };

  render() {
    return (
      <div>
        <Header as="h1" dividing>
          Clube Vantagem
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={6}>
              <Form.Label>Nome</Form.Label>
              <FormControl
                placeholder="Nome"
                aria-label="Nome"
                aria-describedby="basic-addon1"
                name="nome"
                onChange={this.alteracao}
                defaultValue={this.state.nome}
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Endereço</Form.Label>
              <FormControl
                placeholder="Endereço"
                aria-label="Endereco"
                aria-describedby="basic-addon1"
                name="endereco"
                onChange={this.alteracao}
                defaultValue={this.state.endereco}
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Telefone</Form.Label>
              <InputMask
                mask="(99) 9999-9999"
                className="form-control"
                name="telefone"
                onChange={this.alteracao}
                value={this.state.telefone}
              />
            </Col>

            <Col sm={6}>
              <Form.Label>Celular</Form.Label>
              <InputMask
                mask="(99) 9 9999-9999"
                className="form-control"
                name="celular"
                onChange={this.alteracao}
                value={this.state.celular}
              />
            </Col>
          </Row>
          <Divider />
          <Segment clearing>
            <Header floated="right">
              <Button type="button" onClick={this.voltar}>
                Voltar
              </Button>
              {this.state.id !== null && (
                <Button negative type="button" onClick={this.excluir}>
                  Deletar
                </Button>
              )}
              <Button positive type="submit">
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}
