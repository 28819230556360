import React, { Component } from "react";
import { Modal, Dropdown } from "semantic-ui-react";
import TextField from "@material-ui/core/TextField";
import SendIcon from "@material-ui/icons/Send";
import IconButton from "@material-ui/core/IconButton";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Button from "@material-ui/core/Button";
import { Form, Col, Row } from "react-bootstrap";

import CloseIcon from "@material-ui/icons/Close";

import "../styles.css";

export default class lista_modalidades extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal dimmer="bluring" size="small" open={this.props.modalEditar}>
        <Modal.Header>
          {"Editar modalidade"}
          <IconButton
            className="bottomModal"
            onClick={() => this.props.changeModalEditar(null, false)}
            color="secondary"
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>

        <Modal.Content>
          {this.props.alertaSalvarModalidade === true ? (
            <Alert severity="success">
              <AlertTitle>Modalidade Editada! Adicionada!</AlertTitle>
              Iremos redirecionar-lhe — <strong>Aguarde!</strong>
            </Alert>
          ) : (
            <Col sm={12}>
              <Row>
                <Col sm={3}>
                  <TextField
                    id="modalidadedescricao"
                    label="Nome"
                    onChange={this.props.alteracao}
                    defaultValue={this.props.modalidadedescricao}
                    required
                  />
                </Col>
                <Col sm={3}>
                  <TextField
                    error={this.props.alertaSeriesInvalida}
                    id="serie"
                    label="Nº de Série"
                    onChange={this.props.alteracao}
                    helperText={
                      this.props.alertaSeriesInvalida === true
                        ? "Nº de séries deve ser entre 1 e 25"
                        : ""
                    }
                    defaultValue={this.props.serie}
                    required
                  />
                </Col>
                <Col sm={2} className="modalidadePontuacao">
                  <a>Regra de pontuação:</a>
                </Col>
                <Col sm={3} className="selectModalidade">
                  <Dropdown
                    placeholder="Pontuação"
                    id="pontuacao"
                    label="Regra de Pontuacao"
                    name="pontuacao"
                    onChange={this.props.alteracaoPontuacao}
                    options={this.props.pontuacaooptions}
                    defaultValue={this.props.pontuacao}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={this.props.alertaSeriesInvalida === true}
            color="primary"
            floated="right"
            style={{ outline: "none" }}
            onClick={this.props.salvarModalidadeEditada}
          >
            Salvar
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
