import React, { Component } from "react";
import { Container, Table, Button, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";
import moment from "moment-timezone";
import Meses from "../../../../enum/Mes";
export default class Imovel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
    };
    this.lista();
  }

  lista = async () => {
    const resposta = await api.post(
      "/imovel/lista" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    this.setState({ lista: resposta.data });
  };

  render() {
    return (
      <div className="lista">
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Descrição</Table.HeaderCell>
              <Table.HeaderCell>Total Mês</Table.HeaderCell>
              <Table.HeaderCell>Total Ano</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" style={{ width: "9%" }}>
                <Link to={{ pathname: "/imovel" }}>
                  <Button positive icon="add" size="tiny"></Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.lista.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>{e.descricao}</Table.Cell>
                {console.log(e)}
                <Table.Cell>
                  {e.lancamentos_servico.length > 0 &&
                  e.lancamentos_servico.filter(
                    (l) => l.mes === Meses[moment().get("month")].value
                  ).length > 0
                    ? parseFloat(
                        e.lancamentos_servico
                          .filter(
                            (l) => l.mes === Meses[moment().get("month")].value
                          )
                          .reduce(
                            (a, v) =>
                              (a =
                                parseFloat(a) +
                                parseFloat(v.lancamento_conta.valor)),
                            0
                          )
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : 0}
                </Table.Cell>
                <Table.Cell>
                  {e.lancamentos_servico.length > 0 &&
                  e.lancamentos_servico.filter(
                    (l) => l.ano === moment().get("year")
                  ).length > 0
                    ? parseFloat(
                        e.lancamentos_servico
                          .filter((l) => l.ano === moment().get("year"))
                          .reduce(
                            (a, v) =>
                              (a =
                                parseFloat(a) +
                                parseFloat(v.lancamento_conta.valor)),
                            0
                          )
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : 0}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Link to={{ pathname: "/imovel", query: { id: e.id } }}>
                    <Button primary icon="edit" size="tiny"></Button>
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer></Table.Footer>
        </Table>
      </div>
    );
  }
}
