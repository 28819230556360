import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { Modal } from "semantic-ui-react";
import {
  TextField,
  Button,
  ThemeProvider,
  Tabs,
  Tab,
  Box,
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Grid,
  CardHeader,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { Save } from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

// Requisições
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

export default class ModalAdicionaPermissao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // inputs
      nome: "",
      tabSelected: 0,

      // listas
      listaRotasEsquerda: [],
      listaRotasDireita: [],
      listaRotasChecked: [],

      listaPermissoesEsquerda: [],
      listaPermissoesDireita: [],
      listaPermissoesChecked: [],

      listaMenusEsquerda: [],
      listaMenusDireita: [],
      listaMenusChecked: [],

      menus: [],

      // state botões
      loading: false,

      // Modais
    };
  }

  handleToggle = (value, checkedListName) => {
    let lista = this.state[checkedListName];
    let idx = lista.indexOf(value);
    if (idx === -1) {
      lista.push(value);
    } else {
      lista.splice(idx, 1);
    }

    this.setState({ [checkedListName]: lista });
  };

  moveEntreListas = (listaChecked, listaDe, listaPara) => {
    let lista1 = this.state[listaDe];
    let lista2 = this.state[listaPara];
    let listaCheckbox = this.state[listaChecked];

    for (let i = 0; i < lista1.length; i++) {
      const element = lista1[i];
      let index = listaCheckbox.findIndex((e) => e === element.id);
      if (index !== -1) {
        lista2.push(element);
        lista1.splice(i, 1);
        listaCheckbox.splice(index, 1);
        i--;
      }
    }

    this.setState({
      [listaChecked]: listaCheckbox,
      [listaDe]: lista1,
      [listaPara]: lista2,
    });
  };

  // Verifica os menus bloqueados/liberados utilizando as rotas
  validaMenus = () => {
    const rotas = this.state.listaRotasDireita; // Rotas selecionadas para o usuário
    let menusLiberados = [];
    let listaMenusEsquerda = this.state.listaMenusEsquerda;
    let listaMenusDireita = this.state.listaMenusDireita;

    for (let i = 0; i < rotas.length; i++) {
      const element = rotas[i];
      menusLiberados = menusLiberados.concat(element.menus);
    }

    // remover os menus
    for (let i = 0; i < listaMenusEsquerda.length; i++) {
      const element = listaMenusEsquerda[i];
      let idx = -1;

      idx = menusLiberados.findIndex((e) => e.id === element.id);
      if (idx === -1) {
        listaMenusEsquerda.splice(i, 1);
        i--;
      }
    }

    for (let i = 0; i < listaMenusDireita.length; i++) {
      const element = listaMenusDireita[i];
      let idx = -1;

      idx = menusLiberados.findIndex((e) => e.id === element.id);
      if (idx === -1) {
        listaMenusDireita.splice(i, 1);
        i--;
      }
    }

    // adicionar
    for (let i = 0; i < menusLiberados.length; i++) {
      const element = menusLiberados[i];
      let idx1 = -1;
      let idx2 = -1;

      idx1 = listaMenusEsquerda.findIndex((e) => e.id === element.id);
      idx2 = listaMenusDireita.findIndex((e) => e.id === element.id);

      if (idx1 === -1 && idx2 === -1) {
        listaMenusEsquerda.push(element);
      }
    }

    this.setState({
      listaMenusDireita: listaMenusDireita,
      listaMenusEsquerda: listaMenusEsquerda,
      listaMenusChecked: [],
    });
  };

  salvar = async () => {
    try {
      this.setState({ loading: true });

      if (this.props.perfilSelectedModalAdicionarPerfil) {
        const perfil = await api.post(
          "/perfil/editar" + requisicoes.entidade,
          {
            id: this.props.perfilSelectedModalAdicionarPerfil.id,
            nome: this.state.nome,
            rotas: this.state.listaRotasDireita,
            permissoes: this.state.listaPermissoesDireita,
            menus: this.state.listaMenusDireita,
          },
          requisicoes.header
        );
        this.props.addPerfil(perfil.data);
      } else {
        const perfil = await api.post(
          "/perfil/salvar" + requisicoes.entidade,
          {
            nome: this.state.nome,
            rotas: this.state.listaRotasDireita,
            permissoes: this.state.listaPermissoesDireita,
            menus: this.state.listaMenusDireita,
          },
          requisicoes.header
        );
        this.props.addPerfil(perfil.data);
      }

      setTimeout(() => {
        this.props.onClose();
        this.setState({ loading: false });
      }, 800);
    } catch (error) {
      alert("Ocorreu um erro ao salvar o módulo.");
      this.setState({ loading: false });
    }
  };

  onMount = async () => {
    let permissoes = [];
    let rotas = [];
    let menus = [];

    for (let i = 0; i < this.props.entidade.modulos.length; i++) {
      const element = this.props.entidade.modulos[i];
      permissoes = permissoes.concat(element.permissoes);
      rotas = rotas.concat(element.rotas);
      menus = menus.concat(element.rotas.menus);
    }

    if (this.props.perfilSelectedModalAdicionarPerfil) {
      this.setState(
        {
          tabSelected: 0,
          loading: false,
          nome: this.props.perfilSelectedModalAdicionarPerfil.nome,

          listaRotasEsquerda: [
            ...rotas.filter(
              (e) =>
                this.props.perfilSelectedModalAdicionarPerfil.rotas.findIndex(
                  (e2) => e2.id === e.id
                ) === -1
            ),
          ],
          listaRotasDireita: [
            ...this.props.perfilSelectedModalAdicionarPerfil.rotas,
          ],
          listaRotasChecked: [],

          listaPermissoesEsquerda: [
            ...permissoes.filter(
              (e) =>
                this.props.perfilSelectedModalAdicionarPerfil.permissoes.findIndex(
                  (e2) => e2.id === e.id
                ) === -1
            ),
          ],
          listaPermissoesDireita: [
            ...this.props.perfilSelectedModalAdicionarPerfil.permissoes,
          ],
          listaPermissoesChecked: [],

          menus: [...menus],
          listaMenusEsquerda: [],
          listaMenusDireita: [
            ...this.props.perfilSelectedModalAdicionarPerfil.menus,
          ],
          listaMenusChecked: [],
        },
        this.validaMenus // Usando o cb para setar o estado primeiro e depois chamar a função
      );
    } else {
      this.setState({
        nome: "",
        tabSelected: 0,
        loading: false,

        listaRotasEsquerda: [...rotas],
        listaRotasDireita: [],
        listaRotasChecked: [],

        listaPermissoesEsquerda: [...permissoes],
        listaPermissoesDireita: [],
        listaPermissoesChecked: [],

        menus: [...menus],
        listaMenusEsquerda: [],
        listaMenusDireita: [],
        listaMenusChecked: [],
      });
    }
  };

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
        onMount={this.onMount}
      >
        <Modal.Header>Criar Perfil</Modal.Header>
        <Modal.Content>
          <TextField
            style={{ margin: 10 }}
            variant="outlined"
            label="Nome do perfil"
            value={this.state.nome}
            fullWidth
            onChange={(e) => this.setState({ nome: e.target.value })}
          />
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 1 }}
          >
            <Tabs
              value={this.state.tabSelected}
              onChange={(e, newValue) =>
                this.setState({ tabSelected: newValue })
              }
            >
              <Tab label="Permissões" />
              <Tab label="Páginas" />
              <Tab label="Menus" />
            </Tabs>
          </Box>

          {/* Permissões */}
          <TabPanel value={this.state.tabSelected} index={0}>
            <div
              style={{
                height: 300,
                overflow: "auto",
                borderWidth: "2px",
                borderColor: "#c3c9cc",
                borderStyle: "solid",
                borderRadius: "7px",
                padding: 15,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={5}>
                  {customList(
                    this.state.listaPermissoesEsquerda,
                    "listaPermissoesChecked",
                    this.state.listaPermissoesChecked,
                    "nome",
                    this.handleToggle,
                    "Não Liberadas"
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Grid container direction="column" alignItems="center">
                    <Button
                      variant="outlined"
                      style={{ margin: 20 }}
                      onClick={() =>
                        this.moveEntreListas(
                          "listaPermissoesChecked",
                          "listaPermissoesEsquerda",
                          "listaPermissoesDireita"
                        )
                      }
                    >
                      &gt;
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        this.moveEntreListas(
                          "listaPermissoesChecked",
                          "listaPermissoesDireita",
                          "listaPermissoesEsquerda"
                        )
                      }
                    >
                      &lt;
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  {customList(
                    this.state.listaPermissoesDireita,
                    "listaPermissoesChecked",
                    this.state.listaPermissoesChecked,
                    "nome",
                    this.handleToggle,
                    "Liberadas"
                  )}
                </Grid>
              </Grid>
            </div>
          </TabPanel>

          {/* Páginas */}
          <TabPanel value={this.state.tabSelected} index={1}>
            <div
              style={{
                height: 300,
                overflow: "auto",
                borderWidth: "2px",
                borderColor: "#c3c9cc",
                borderStyle: "solid",
                borderRadius: "7px",
                padding: 15,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={5}>
                  {customList(
                    this.state.listaRotasEsquerda,
                    "listaRotasChecked",
                    this.state.listaRotasChecked,
                    "url",
                    this.handleToggle,
                    "Não Liberadas"
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Grid container direction="column" alignItems="center">
                    <Button
                      variant="outlined"
                      style={{ margin: 20 }}
                      onClick={() => {
                        this.moveEntreListas(
                          "listaRotasChecked",
                          "listaRotasEsquerda",
                          "listaRotasDireita"
                        );
                        this.validaMenus();
                      }}
                    >
                      &gt;
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        this.moveEntreListas(
                          "listaRotasChecked",
                          "listaRotasDireita",
                          "listaRotasEsquerda"
                        );
                        this.validaMenus();
                      }}
                    >
                      &lt;
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  {customList(
                    this.state.listaRotasDireita,
                    "listaRotasChecked",
                    this.state.listaRotasChecked,
                    "url",
                    this.handleToggle,
                    "Liberadas"
                  )}
                </Grid>
              </Grid>
            </div>
          </TabPanel>

          {/* Menus */}
          <TabPanel value={this.state.tabSelected} index={2}>
            <div
              style={{
                height: 300,
                overflow: "auto",
                borderWidth: "2px",
                borderColor: "#c3c9cc",
                borderStyle: "solid",
                borderRadius: "7px",
                padding: 15,
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={5}>
                  {customList(
                    this.state.listaMenusEsquerda,
                    "listaMenusChecked",
                    this.state.listaMenusChecked,
                    "nome",
                    this.handleToggle,
                    "Não Liberadas"
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Grid container direction="column" alignItems="center">
                    <Button
                      variant="outlined"
                      style={{ margin: 20 }}
                      onClick={() =>
                        this.moveEntreListas(
                          "listaMenusChecked",
                          "listaMenusEsquerda",
                          "listaMenusDireita"
                        )
                      }
                    >
                      &gt;
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        this.moveEntreListas(
                          "listaMenusChecked",
                          "listaMenusDireita",
                          "listaMenusEsquerda"
                        )
                      }
                    >
                      &lt;
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  {customList(
                    this.state.listaMenusDireita,
                    "listaMenusChecked",
                    this.state.listaMenusChecked,
                    "nome",
                    this.handleToggle,
                    "Liberadas"
                  )}
                </Grid>
              </Grid>
            </div>
          </TabPanel>
        </Modal.Content>
        <Modal.Actions>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="auto">
              <Button
                onClick={this.props.onClose}
                variant="contained"
                color="inherit"
              >
                Voltar
              </Button>
            </Col>
            <Col md="auto">
              <ThemeProvider
                theme={createTheme({
                  palette: {
                    primary: green,
                  },
                })}
              >
                <LoadingButton
                  variant="contained"
                  style={{ color: "#FFFFFF" }}
                  startIcon={<Save />}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                  onClick={this.salvar}
                >
                  Salvar
                </LoadingButton>
              </ThemeProvider>
            </Col>
          </Row>
        </Modal.Actions>
      </Modal>
    );
  }
}

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && children}</div>;
}

function customList(
  list,
  checkedListName,
  checkedList,
  propriedadeLabel,
  handleToggle,
  title
) {
  return (
    <Card style={{ minHeight: 250 }}>
      <CardHeader sx={{ px: 2, py: 1 }} title={title} />
      <List sx={{ overflow: "auto" }}>
        {list.map((e) => (
          <ListItem
            key={e.id}
            role="listitem"
            button
            onClick={() => handleToggle(e.id, checkedListName)}
          >
            <ListItemIcon>
              <Checkbox
                checked={checkedList.indexOf(e.id) !== -1}
                tabIndex={-1}
                disableRipple
                // inputProps={{
                //   "aria-labelledby": labelId,
                // }}
              />
            </ListItemIcon>
            <ListItemText
              // id={labelId}
              primary={e[propriedadeLabel]}
            />
          </ListItem>
        ))}
      </List>
    </Card>
  );
}
