import React, { Component } from "react";
import { Header, Button, Modal } from "semantic-ui-react";
import { Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";
import renomearChave from "../../../services/renomearChave";

export default class ModalServicoInscricao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaTipoTitulo: [],
      optionsServico: [],
      servico_inscricao_nome: "",
    };
  }

  pesquisaServico = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/servico/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaServico").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista.push(objeto);
      });
      this.setState({ optionsServico: lista });
    }, 500);
  };

  render() {
    return (
      <Modal
        open={this.props.open_modal_servico_inscricao}
        centered={true}
        onClose={this.props.fecharModalInscricao}
      >
        <Header content="Serviço Inscrição" />
        <Modal.Content>
          <Row>
            <Col sm={11}>
              <Form.Label>Serviço</Form.Label>
              <Select
                placeholder="Digite para buscar"
                name="servico"
                inputValue={this.props.servico_inscricao_nome}
                onInputChange={this.props.alteracaoServicoInscricao}
                onChange={this.props.alteracaoSelectServicoInscricao}
                options={this.state.optionsServico}
                onKeyDown={this.pesquisaServico}
                inputId="pesquisaServico"
              />
            </Col>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <Col sm={12}></Col>
          </Row>
        </Modal.Content>
        <div class="actions">
          <Button
            negative
            onClick={this.props.fecharModalInscricaoZeraInscricao}
          >
            Remover
          </Button>
          <Button
            type="button"
            primary
            onClick={this.props.fecharModalInscricao}
          >
            Voltar
          </Button>
          <Button
            type="button"
            secondary
            onClick={this.props.fecharModalInscricao}
          >
            Salvar
          </Button>
        </div>
      </Modal>
    );
  }
}
