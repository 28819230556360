import React, { Component } from "react";
import {
  Table,
  Button,
  Modal,
  Loader,
  Dimmer,
  Segment,
} from "semantic-ui-react";
import Pagination from "@material-ui/lab/Pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Row, Col, Form, FormControl } from "react-bootstrap";

// Utils
import { parseISO, format } from "date-fns";
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";
import { colors } from "@material-ui/core";

export default class ContasAPagarLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePages: 1,
      totalPaginas: 1,
      buscando: false,
      listaLancamentoContas: [],
    };
  }
  modalExtratoPages = async (activePage = 1) => {
    // this.buscando = true;
    this.setState({ buscando: true, listaLancamentoContas: [] });
    const resposta = await api.post(
      "/contaBancaria/extratoConta" + requisicoes.entidade,
      {
        idConta: this.props.idConta,
        activePages: activePage,
      },
      requisicoes.header
    );

    setTimeout(() => {
      this.setState({
        buscando: false,
        listaLancamentoContas: resposta.data.docs,
        totalPaginas: resposta.data.pages,
        activePages: activePage,
      });
    }, 200);
  };
  abreModalConta = async (e) => {
    this.setState({ modalConta: e });
  };
  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
        onMount={() => {
          this.modalExtratoPages(1);
        }}
      >
        <Modal.Header>Extrato</Modal.Header>
        <Modal.Content style={{ padding: "0" }}>
          {this.state.buscando ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 100,
                paddingBottom: 100,
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Data Emissão</Table.HeaderCell>
                  <Table.HeaderCell>Histórico</Table.HeaderCell>
                  <Table.HeaderCell>Valor</Table.HeaderCell>
                  <Table.HeaderCell>D/C</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.listaLancamentoContas.map((e, i) => (
                  <Table.Row
                    key={i}
                    positive={e.credito_debito !== "debito"}
                    negative={e.credito_debito === "debito"}
                  >
                    <Table.Cell width={3}>
                      {e.data_emissao !== null &&
                        format(parseISO(e.data_emissao), "dd/MM/yyyy")}
                    </Table.Cell>
                    <Table.Cell>
                      {e.pessoa
                        ? e.pessoa.nome
                          ? e.pessoa.nome
                          : e.pessoa?.nomefantasia
                        : ""}
                    </Table.Cell>
                    <Table.Cell>{e.valor}</Table.Cell>
                    <Table.Cell>
                      {/* <h5 style={{ Color: "red" }}>a</h5> */}
                      {e.credito_debito === "debito" ? "D" : "C"}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>

              <Table.Footer sm={12}>
                <Table.Row>
                  <Table.HeaderCell colSpan={6}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Pagination
                        count={this.state.totalPaginas}
                        variant="outlined"
                        shape="rounded"
                        boundaryCount={1}
                        page={this.state.activePages}
                        onChange={(e, a) => {
                          if (a) {
                            this.modalExtratoPages(a);
                          }
                        }}
                      />
                    </div>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button variant="light" onClick={this.props.onClose}>
            Voltar
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
