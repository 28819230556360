import React, { Component } from "react";
import { Table, Button } from "semantic-ui-react";
import { Col, Row, Form } from "react-bootstrap";
import { Pagination } from "@material-ui/lab/";
import { Tooltip } from "@material-ui/core";

import moment from "moment";

import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

export default class Reservas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // inputs
      data_inicio: "",
      data_final: "",
      grupoServicoSelecionado: "Todos",
      servicoSelecionado: "",

      // dados
      listaReservasRelatorio: [],
      listaServicosRelatorio: [],

      // paginacao
      totalPaginas1: 0,
      activePage1: 0,
    };
  }

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === "grupoServicoSelecionado") {
      this.setState({ listaReservasRelatorio: [] });
      this.buscaServicos(event.target.value);
    }
  };

  buscaServicos = async (grupo_id) => {
    this.setState({ listaTurmasRelatorio: [] });
    if (grupo_id === "Todos" || grupo_id === "Canceladas") {
      this.setState({
        listaServicosRelatorio: [],
        servicoSelecionado: null,
        turmaSelecionada: null,
      });
      return;
    }

    let listaServicos = await api.post(
      "/servico/servicoPorGrupoServico" + requisicoes.entidade,
      {
        id: grupo_id,
        // data: moment().year(),
      },
      requisicoes.header
    );
    let lista = [];
    if (listaServicos.data.length > 1) {
      lista.push({
        id: 0,
        nome: "Todos",
      });
    }
    for (let i = 0; i < listaServicos.data.length; i++) {
      const element = listaServicos.data[i];
      lista.push({ id: element.id, nome: element.nome });
    }
    if (lista.length > 0) {
      this.setState({
        listaServicosRelatorio: lista,
        servicoSelecionado: lista[0].id,
        turmaSelecionada: null,
      });
    } else {
      this.setState({
        listaServicosRelatorio: lista,
        servicoSelecionado: null,
        turmaSelecionada: null,
      });
    }
  };

  verificaDatas = async () => {
    if (!moment(this.state.data_inicio, "YYYY-MM-DD", true).isValid()) {
      this.setState({
        data_inicio: moment().startOf("month").format("YYYY-MM-DD"),
      });
    }
    if (!moment(this.state.data_final, "YYYY-MM-DD", true).isValid()) {
      this.setState({ data_final: moment().format("YYYY-MM-DD") });
    }
  };

  pesquisaReservas = async (activePage = 1) => {
    await this.verificaDatas();
    const resposta = await api.post(
      "/reservaHorario/reservaPorDataEGrupoServico" + requisicoes.entidade,
      {
        grupo_servico_id:
          this.state.grupoServicoSelecionado === "Todos" ||
          this.state.grupoServicoSelecionado === "Canceladas"
            ? 0
            : this.state.grupoServicoSelecionado,
        data_inicial: this.state.data_inicio,
        data_final: this.state.data_final,
        activePage: activePage,
        situacao:
          this.state.grupoServicoSelecionado === "Canceladas"
            ? "cancelado"
            : "ativa",
        servico_id: this.state.servicoSelecionado,
      },
      requisicoes.header
    );

    await resposta.data.docs.sort(function (a, b) {
      if (Date.parse(a.dataReserva) === Date.parse(b.dataReserva)) {
        return a.horario_servico.servico.nome > b.horario_servico.servico.nome
          ? 1
          : -1;
      }
      return Date.parse(a.dataReserva) < Date.parse(b.dataReserva) ? 1 : -1;
    });
    this.setState({
      listaReservasRelatorio: resposta.data.docs,
      activePage1: activePage,
      totalPaginas1: resposta.data.pages,
    });
  };

  gerarRelatorioReservas = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado?e=" + entidade,
      {},
      {
        headers: { token: "Baer " + token },
      }
    );
    const nomePessoa = await api.post(
      "/pessoa/pessoaIdNome?e=" + entidade,
      { id: usuarioLogado.data.pessoa_id },
      {
        headers: { token: "Baer " + token },
      }
    );

    await api.post(
      "/reservaHorario/relatorioReserva?e=" + entidade,
      {
        userId: usuarioLogado.data.pessoa_id,
        nome_operador: nomePessoa.data.nome,
        data_inicio: this.state.data_inicio,
        data_final: this.state.data_final,
        //lista_reservas: this.state.listaReservasRelatorio,

        grupo_servico_id:
          this.state.grupoServicoSelecionado === "Todos" ||
          this.state.grupoServicoSelecionado === "Canceladas"
            ? 0
            : this.state.grupoServicoSelecionado,
        data_inicial: this.state.data_inicio,
        situacao:
          this.state.grupoServicoSelecionado === "Canceladas"
            ? "cancelado"
            : "ativa",
        servico_id: this.state.servicoSelecionado,
      },
      requisicoes.header
    );

    const r = await api.get(
      "/reservaHorario/arquivoRelatorioReserva?e=" +
        entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.pessoa_id
    ); //
    var oReq = new XMLHttpRequest();
    var URLToPDF =
      r.config.baseURL +
      "reservaHorario/arquivoRelatorioReserva?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.pessoa_id; //
    await oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  render() {
    return (
      <div>
        <Row>
          <Col>
            <Form.Label>Data Inicio</Form.Label>
            <Form.Control
              placeholder="Data Inicio"
              aria-describedby="basic-addon1"
              type="date"
              name="data_inicio"
              value={this.state.data_inicio}
              onChange={this.alteracao}
            />
          </Col>
          <Col>
            <Form.Label>Data Final</Form.Label>
            <Form.Control
              placeholder="Data Final"
              aria-describedby="basic-addon1"
              type="date"
              name="data_final"
              value={this.state.data_final}
              onChange={this.alteracao}
            />
          </Col>
          <Col>
            <Form.Label>{"Grupo serviço"}</Form.Label>
            <Form.Control
              as="select"
              name="grupoServicoSelecionado"
              id="grupoServicoSelecionado"
              // defaultValue={this.state.grupoServicoSelecionado}
              value={this.state.grupoServicoSelecionado}
              required
              onChange={this.alteracao}
            >
              <option value="Todos">Todos</option>
              <option value="Canceladas">Canceladas</option>
              {this.props.listaGrupoServico.map((e, i) => (
                <option value={e.id}>{e.descricao}</option>
              ))}
            </Form.Control>
          </Col>
          <Col>
            <Form.Label>Serviço reservado</Form.Label>
            <Form.Control
              as="select"
              name="servicoSelecionado"
              id="servicoSelecionado"
              value={this.state.servicoSelecionado}
              required
              onChange={this.alteracao}
              disabled={this.state.listaServicosRelatorio.length === 0}
            >
              {this.state.listaServicosRelatorio.map((e, i) => (
                <option value={e.id}>{e.nome}</option>
              ))}
            </Form.Control>
          </Col>
          <Col md="auto">
            <Button
              positive
              style={{ marginTop: "25px", float: "right" }}
              onClick={() => this.pesquisaReservas()}
            >
              Buscar
            </Button>
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Serviço</Table.HeaderCell>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              {this.state.listaReservasRelatorio.length > 0 &&
                this.state.listaReservasRelatorio[0].evento !== null && (
                  <Table.HeaderCell>Evento</Table.HeaderCell>
                )}
              <Table.HeaderCell>Telefone</Table.HeaderCell>
              <Table.HeaderCell>Data da reserva</Table.HeaderCell>
              {this.state.grupoServicoSelecionado === "Canceladas" && (
                <Table.HeaderCell>Data de cancelamento</Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.listaReservasRelatorio.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>{e.horario_servico.servico?.nome}</Table.Cell>
                <Table.Cell>{e.pessoa.nome}</Table.Cell>
                {this.state.listaReservasRelatorio.length > 0 &&
                  this.state.listaReservasRelatorio[0].evento !== null && (
                    <Table.Cell>{e.evento}</Table.Cell>
                  )}
                <Table.Cell>
                  {e.pessoa.telefone === "" || e.pessoa.telefone === null
                    ? e.pessoa.celular
                    : e.pessoa.telefone}
                </Table.Cell>
                <Table.Cell>
                  {moment(e.dataReserva).format("DD/MM/YYYY")}
                </Table.Cell>
                {this.state.grupoServicoSelecionado === "Canceladas" && (
                  <Table.Cell>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <Tooltip title={e.motivo}>
                        <div>
                          {moment(e.dataCancelamento).format("DD/MM/YYYY") +
                            "  (?)"}
                        </div>
                      </Tooltip>
                    </div>
                  </Table.Cell>
                )}
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer sm={12}>
            <Table.Row>
              <Table.HeaderCell colSpan={6}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Pagination
                    count={this.state.totalPaginas1}
                    variant="outlined"
                    shape="rounded"
                    boundaryCount={1}
                    page={this.state.activePage1}
                    onChange={(e, a) => this.pesquisaReservas(a)}
                  />
                </div>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Col sm={0.9} style={{ float: "right" }}>
          <Button
            primary
            onClick={this.gerarRelatorioReservas}
            disabled={this.state.listaReservasRelatorio.length === 0}
          >
            Imprimir
          </Button>
        </Col>
      </div>
    );
  }
}
