import React, { Component } from "react";
import {
  Header,
  // Button,
  Segment,
  FormField,
  Input,
} from "semantic-ui-react";

import { LoadingButton as Button } from "@material-ui/lab";
import { TextField, Paper, Select, Autocomplete } from "@material-ui/core";
import api from "../../../services/api";
import { Link, withRouter } from "react-router-dom";
import { Col, Form, FormControl, Row } from "react-bootstrap";

import { LocalizationProvider, DatePicker } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns/index";
import brLocale from "date-fns/locale/pt-BR";

import requisicoes from "../../../utils/Requisicoes";
import moment from "moment-timezone";
import $ from "jquery";
import "jquery-mask-plugin/dist/jquery.mask.min";
import { ThemeProvider } from "@material-ui/private-theming";
import { createTheme } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

import ReplaceAll from "replaceall";

// Components

var formatter = new Intl.NumberFormat("pt-br", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

class LancamentoBancario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      numero: "",
      data: moment(),
      valor: (0).toFixed(2),

      conta_contabil_credito: null,
      conta_contabil_debito: null,

      observacao: "",

      // options
      optionsContaContabil: [],

      // loading/state button
      salvando: false,
    };
    this.buscaUltimo();
    this.visualizacao();
  }

  buscaUltimo = async () => {
    if (this.props.location.query === undefined) {
      let num = 1;
      const r = await api.post(
        "/lancamentoBancario/ultimoNumero" + requisicoes.entidade,
        "",
        requisicoes.header
      );
      if (r.data !== "") {
        num = parseInt(r.data.numero + 1);
      }
      this.setState({ numero: num });
    }
  };

  visualizacao = async () => {
    if (this.props.location.query !== undefined) {
      const resposta = await api.post(
        "/lancamentoBancario/visualiza" + requisicoes.entidade,
        this.props.location.query,
        requisicoes.header
      );

      this.setState({
        ...resposta.data,
        data: moment(resposta.data),
        valor: formatter.format(resposta.data.valor),
        conta_contabil_credito: {
          value: resposta.data.conta_contabil_credito.id,
          label:
            resposta.data.conta_contabil_credito.codigo +
            " - " +
            resposta.data.conta_contabil_credito.descricao,
        },
        conta_contabil_debito: {
          value: resposta.data.conta_contabil_debito.id,
          label:
            resposta.data.conta_contabil_debito.codigo +
            " - " +
            resposta.data.conta_contabil_debito.descricao,
        },
      });
    }
  };

  pesquisaContaContabil = async (event, props) => {
    try {
      const resposta = await api.post(
        "/contaContabil/pesquisa" + requisicoes.entidade,
        { pesquisa: props },
        requisicoes.header
      );
      this.setState({
        optionsContaContabil: resposta.data.map((e) => {
          return {
            value: e.id,
            label: e.codigo + " - " + e.descricao,
          };
        }),
      });
    } catch (error) {}
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentMoney() {
    $(".dinheiro").mask("#.##0,00", {
      reverse: true,
      placeholder: "0,00",
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ salvando: true });
    this.submit();
  };

  submit = async () => {
    try {
      const c = { ...this.state };

      const dados = {
        id: c.id,
        numero: c.numero,
        data: moment(c.data).format("YYYY-MM-DD"),
        valor: ReplaceAll(".", "", c.valor.toString()).replace(",", "."),
        conta_contabil_credito_id: c.conta_contabil_credito.value,
        conta_contabil_debito_id: c.conta_contabil_debito.value,
        observacao: c.observacao,
      };
      console.log("dados: ", dados);
      if (this.state.id !== null) {
        await api.post(
          "/lancamentoBancario/editar" + requisicoes.entidade,
          dados,
          requisicoes.header
        );
      } else {
        await api.post(
          "/lancamentoBancario/salvar" + requisicoes.entidade,
          dados,
          requisicoes.header
        );
      }
      this.props.history.push("/contabil");
    } catch (error) {
      console.log("error: ", error);
      alert("Ocorreu um erro ao processar sua requisição.");
    } finally {
      this.setState({ salvando: false });
    }
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Lançamento Bancário
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Paper style={{ padding: 20 }}>
            <Row>
              <Col sm={3}>
                <TextField
                  label="Número"
                  variant="outlined"
                  name="numero"
                  value={this.state.numero}
                  onChange={this.alteracao}
                  fullWidth
                />
              </Col>
              <Col md="auto">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={brLocale}
                >
                  <DatePicker
                    label="Data"
                    value={this.state.data}
                    onChange={(newValue) => {
                      this.setState({ data: newValue });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Col>
              <Col>
                <Autocomplete
                  name="conta_contabil_credito"
                  onChange={(e, a) => {
                    this.setState({ conta_contabil_credito: a });
                  }}
                  onKeyUp={(event, props) => {
                    this.pesquisaContaContabil(event, event.target.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Conta Contábil Crédito" />
                  )}
                  options={this.state.optionsContaContabil}
                  value={this.state.conta_contabil_credito}
                  fullWidth
                  required
                />
              </Col>
              <Col>
                <Autocomplete
                  name="conta_contabil_debito"
                  onChange={(e, a) => {
                    this.setState({ conta_contabil_debito: a });
                  }}
                  onKeyUp={(event, props) => {
                    this.pesquisaContaContabil(event, event.target.value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Conta Contábil Débito" />
                  )}
                  options={this.state.optionsContaContabil}
                  value={this.state.conta_contabil_debito}
                  fullWidth
                  required
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col sm={3}>
                <TextField
                  label="Valor"
                  name="valor"
                  onChange={this.alteracao}
                  value={this.state.valor}
                  required
                  fullWidth
                  onBlur={() => {
                    let valor = this.state.valor.replace(",", ".");
                    this.setState({
                      valor: formatter.format(valor),
                    });
                  }}
                />
              </Col>
              <Col sm={9}>
                <TextField
                  label="Observação"
                  name="observacao"
                  onChange={this.alteracao}
                  value={this.state.observacao}
                  type="text"
                  fullWidth
                />
              </Col>
            </Row>
          </Paper>
          <Segment clearing>
            <Header floated="right">
              <Row>
                <Col>
                  <Link to="/contabil">
                    <Button variant="contained" type="button" color="inherit">
                      Voltar
                    </Button>
                  </Link>
                </Col>

                <Col>
                  <ThemeProvider
                    theme={createTheme({
                      palette: {
                        primary: green,
                      },
                    })}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      style={{ color: "#FFFFFF" }}
                      loading={this.state.salvando}
                      disabled={this.state.salvando}
                    >
                      Salvar
                    </Button>
                  </ThemeProvider>
                </Col>
              </Row>
            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}
export default withRouter(LancamentoBancario);
