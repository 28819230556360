/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Header, Segment } from "semantic-ui-react";
import { Form, Col, Row } from "react-bootstrap";

import { Paper, Tabs, Tab } from "@material-ui/core";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import GavelIcon from "@material-ui/icons/Gavel";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";

//utils
import Pessoas from "./pessoas/index";
import Modalidade from "./modalidade/index";
import Organizacao from "./organizacao/index";
import Campeonato from "./campeonato/index";
import TipoEventos from "./tipoeventos/TipoEventosMenu";
import Classfificacao from "./classificacao/index";
import Relatorio from "./relatorios/index";
import Sumulas from "./sumulas/index";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selecionado: localStorage.getItem("pagina_anterior")
        ? parseInt(localStorage.getItem("pagina_anterior"))
        : 0,
    };
  }

  changeTabMenu = async (event, newValue) => {
    localStorage.setItem("pagina_anterior", newValue);
    this.setState({ selecionado: newValue });
  };

  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Form>
          <Segment>
            <Header as="h2" dividing></Header>
            <Row>
              <Col sm={12} className="tabsRodadas">
                <Paper square>
                  <Tabs
                    indicatorColor="secondary"
                    textColor="primary"
                    aria-label="disabled tabs example"
                    style={{ outline: "none" }}
                    variant="scrollable"
                    onChange={this.changeTabMenu}
                    value={this.state.selecionado}
                  >

                    <Tab
                      icon={<EmojiEventsIcon />}
                      style={{ outline: "none", color: "rgb(10, 111, 148)" }}
                      label="Campeonato"
                      value={0}
                    />
                    <Tab
                      icon={<FormatListNumberedIcon />}
                      style={{ outline: "none", color: "rgb(10, 111, 148)" }}
                      label="Classificação"
                      value={1}
                    />
                    <Tab
                      icon={<ImportContactsIcon />}
                      style={{ outline: "none", color: "rgb(10, 111, 148)" }}
                      label="Relatórios"
                      value={2}
                    />
                    <Tab
                      icon={<ImportContactsIcon />}
                      style={{ outline: "none", color: "rgb(10, 111, 148)" }}
                      label="Sumulas"
                      value={3}
                    />
                    <Tab
                      icon={<PersonAddIcon />}
                      style={{ outline: "none", color: "rgb(10, 111, 148)" }}
                      label="Pessoas"
                      value={4}
                    />
                    <Tab
                      icon={<GavelIcon />}
                      style={{ outline: "none", color: "rgb(10, 111, 148)" }}
                      label="Tipo Eventos"
                      value={5}
                    />
                    <Tab
                      icon={<LibraryBooksIcon />}
                      style={{ outline: "none", color: "rgb(10, 111, 148)" }}
                      label="Modalidade"
                      value={6}
                    />
                    <Tab
                      icon={<HomeWorkIcon />}
                      style={{ outline: "none", color: "rgb(10, 111, 148)" }}
                      label="Organização"
                      value={7}
                    />

                    {/* {localStorage.getItem("tipo_usuario_clube") ===
                      "administrador" && (
                        <Tab
                          icon={<EmojiEvents />}
                          style={{
                            outline: "none",
                            color: "rgb(10, 111, 148)",
                          }}
                          label="Campeonato"
                          value={0}
                        />
                      )}
                    {localStorage.getItem("tipo_usuario_clube") ===
                      "administrador" && (
                        <Tab
                          icon={<FormatListNumbered />}
                          style={{
                            outline: "none",
                            color: "rgb(10, 111, 148)",
                          }}
                          label="Classificação"
                          value={1}
                        />
                      )}
                    {localStorage.getItem("tipo_usuario_clube") ===
                      "administrador" && (
                        <Tab
                          icon={<ImportContacts />}
                          style={{
                            outline: "none",
                            color: "rgb(10, 111, 148)",
                          }}
                          label="Relatórios"
                          value={2}
                        />
                      )}
                    {localStorage.getItem("tipo_usuario_clube") ===
                      "administrador" && (
                        <Tab
                          icon={<ImportContacts />}
                          style={{
                            outline: "none",
                            color: "rgb(10, 111, 148)",
                          }}
                          label="Sumulas"
                          value={3}
                        />
                      )}
                    {localStorage.getItem("tipo_usuario_clube") ===
                      "administrador" && (
                        <Tab
                          icon={<PersonAdd />}
                          style={{
                            outline: "none",
                            color: "rgb(10, 111, 148)",
                          }}
                          label="Pessoas"
                          value={4}
                        />
                      )}
                    {localStorage.getItem("tipo_usuario_clube") ===
                      "administrador" && (
                        <Tab
                          icon={<Gavel />}
                          style={{
                            outline: "none",
                            color: "rgb(10, 111, 148)",
                          }}
                          label="Tipo Eventos"
                          value={5}
                        />
                      )}

                    {localStorage.getItem("tipo_usuario_clube") ===
                      "administrador" && (
                        <Tab
                          icon={<LibraryBooks />}
                          style={{
                            outline: "none",
                            color: "rgb(10, 111, 148)",
                          }}
                          label="Modalidade"
                          value={6}
                        />
                      )}
                    {localStorage.getItem("tipo_usuario_clube") ===
                      "administrador" && (
                        <Tab
                          icon={<HomeWork />}
                          style={{
                            outline: "none",
                            color: "rgb(10, 111, 148)",
                          }}
                          label="Organização"
                          value={7}
                        />
                      )} */}
                    {/* {localStorage.getItem("tipo_usuario_clube") ===
                      "administrador" && (
                        <Tab
                          icon={<HomeWork />}
                          style={{
                            outline: "none",
                            color: "rgb(10, 111, 148)",
                          }}
                          label="Evento_Cameonato"
                          value={8}
                        />
                      )} */}

                  </Tabs>
                </Paper>
              </Col>
            </Row>
            <TabPanel value={this.state.selecionado} index={0}>
              <Campeonato />
            </TabPanel>
            <TabPanel value={this.state.selecionado} index={1}>
              <Classfificacao />
            </TabPanel>
            <TabPanel value={this.state.selecionado} index={2}>
              <Relatorio />
            </TabPanel>
            <TabPanel value={this.state.selecionado} index={3}>
              <Sumulas />
            </TabPanel>
            <TabPanel value={this.state.selecionado} index={4}>
              <Pessoas />
            </TabPanel>
            <TabPanel value={this.state.selecionado} index={5}>
              <TipoEventos />
            </TabPanel>
            <TabPanel value={this.state.selecionado} index={6}>
              <Modalidade />
            </TabPanel>
            <TabPanel value={this.state.selecionado} index={7}>
              <Organizacao />
            </TabPanel>
            {/* <TabPanel value={this.state.selecionado} index={8}>
              <Evento_Cameonato />
            </TabPanel> */}
          </Segment>
        </Form>
      </div>
    );
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
}
