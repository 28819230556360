import React, { Component } from "react";
import { Button, Modal, Header, Icon } from "semantic-ui-react";

export default class ModalErroHorarioReservado extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Modal open={this.props.open} size="tiny">
          <Header>Horário já reservado</Header>
          <Modal.Content>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Icon name="ban" size="massive" cen></Icon>
            </div>
            <div style={{ textAlign: "center" }}>
              <h4>Esse horario foi reservado nos últimos minutos.</h4>
              Procure outro horário disponível.
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="blue"
              onClick={() => (window.location.href = "/reservas")}
            >
              Confirmar
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}
