import React, { Component } from "react";
import { Table, Button, Pagination } from "semantic-ui-react";
import { Row, Col } from "react-bootstrap";
import ReactSelect from "react-select";

import moment from "moment-timezone";

import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // dados inputs pelo user
      data: moment().format("YYYY-MM-DD"),
      optionsSelected: [],
      selectTodos: false,

      // options
      tipoTituloOptions: [],

      //dados da tela
      acoes: [],

      //estados de controle
      dataInvalida: false,
      buscando: false,

      totalPages: 0,
      dadosPorPagina: 30,
      pagina: 1,
    };
    this.listaTipoTitulo();
  }

  listaTipoTitulo = async () => {
    const titulos = await api.post(
      "/tipoTitulo/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    let listaTitulos = [];
    listaTitulos.push({
      value: 0,
      label: "Todos",
      selected: false,
    });
    for (let i = 0; i < titulos.data.length; i++) {
      const element = titulos.data[i];
      listaTitulos.push({
        value: element.id,
        label: element.descricao,
        selected: false,
      });
    }
    this.setState({ tipoTituloOptions: listaTitulos });
  };

  onChangeSelectItem = (e, a) => {
    if (a.action === "clear") {
      let lista = this.state.tipoTituloOptions;
      for (let i = 0; i < lista.length; i++) {
        const element = lista[i];
        element.selected = false;
      }

      this.setState({
        tipoTituloOptions: lista,
        optionsSelected: [],
        selectTodos: false,
      });
      return;
    }
    if (a.action === "pop-value" && a.removedValue === undefined) {
      return;
    }
    if (a.removedValue !== undefined) {
      a.option = a.removedValue;
    }

    if (a.option.value === 0) {
      if (a.action === "select-option") {
        let lista2 = this.state.tipoTituloOptions;
        for (let i = 0; i < lista2.length; i++) {
          const element = lista2[i];
          element.selected = true;
        }
        this.setState({ tipoTituloOptions: lista2, selectTodos: true });
      }
      if (
        a.action === "deselect-option" ||
        a.action === "pop-value" ||
        a.action === "remove-value"
      ) {
        let lista2 = this.state.tipoTituloOptions;
        const optionsSelected = this.state.optionsSelected;

        for (let i = 0; i < lista2.length; i++) {
          const element = lista2[i];
          if (optionsSelected.findIndex((e) => e.value === element.id) !== -1) {
            element.selected = true;
          } else {
            element.selected = false;
          }
        }
        this.setState({ tipoTituloOptions: lista2, selectTodos: false });
      }
    }
    if (a.action === "select-option") {
      let lista = this.state.optionsSelected;
      lista.push(a.option);
      let lista2 = this.state.tipoTituloOptions;
      let index = lista2.findIndex(
        (element) => element.value === a.option.value
      );
      lista2[index].selected = true;

      this.setState({
        optionsSelected: lista,
        tipoTituloOptions: lista2,
      });
    }
    if (
      a.action === "deselect-option" ||
      a.action === "pop-value" ||
      a.action === "remove-value"
    ) {
      let lista = this.state.optionsSelected;
      let index0 = lista.findIndex(
        (element) => element.value === a.option.value
      );
      lista.splice(index0, 1);

      let lista2 = this.state.tipoTituloOptions;
      let index = lista2.findIndex(
        (element) => element.value === a.option.value
      );
      lista2[index].selected = false;

      this.setState({
        optionsSelected: lista,
        tipoTituloOptions: lista2,
      });
    }
  };
  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeDate = (e, a) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (
      !moment(e.target.value).isValid() ||
      moment(e.target.value).year() < 2010 ||
      moment(e.target.value).year() > 3000
    ) {
      this.setState({ dataInvalida: true });
      return;
    }
    this.setState({
      dataInvalida: false,
    });
  };

  buscarOnClick = async (e, a, pagina) => {
    this.setState({ buscando: true });
    let listaTipoTituloId = [];
    for (let i = 0; i < this.state.optionsSelected.length; i++) {
      const element = this.state.optionsSelected[i];
      listaTipoTituloId.push(element.value);
    }
    let acoes = await api.post(
      "/tipoTitulo/buscaAtivosData" + requisicoes.entidade,
      {
        tipo_titulo_id: this.state.selectTodos ? null : listaTipoTituloId,
        dadosPorPagina: this.state.dadosPorPagina,
        pagina: pagina || this.state.pagina,
      },
      requisicoes.header
    );

    this.setState({
      buscando: false,
      acoes: acoes.data.docs,
      totalPages: acoes.data.pages,
    });
  };

  imprimirOnClick = async () => {
    let listaTipoTituloId = [];
    for (let i = 0; i < this.state.optionsSelected.length; i++) {
      const element = this.state.optionsSelected[i];
      listaTipoTituloId.push(element.value);
    }
    await api.post(
      "/tipoTitulo/gerarPdfTitulos" + requisicoes.entidade,
      {
        tipo_titulo_id: this.state.selectTodos ? null : listaTipoTituloId,
      },
      requisicoes.header
    );

    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    const r = await api.get(
      "/tipoTitulo/buscarArquivoPdfTitulos" +
        requisicoes.entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.id
    );
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "tipoTitulo/buscarArquivoPdfTitulos" +
      requisicoes.entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.id;

    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  render() {
    return (
      <div>
        <Row style={{ alignItems: "flex-end", paddingTop: 15 }}>
          <Col md={true}>
            <ReactSelect
              isMulti
              options={this.state.tipoTituloOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              onChange={this.onChangeSelectItem}
              isOptionSelected={(element) => element.selected === true}
            />
          </Col>
          {/* <Col md={true}>
            <ReactSelect
              options={[
                {
                  value: "pagas",
                  label: "Pagas",
                },
                { value: "aberto", label: "Em Aberto" },
              ]}
              isMulti
              className="basic-multi-select"
              classNamePrefix="select"
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              // onChange={this.onChangeSelectItem}
              // isOptionSelected={(element) => element.selected === true}
            />
          </Col> */}
          {/* <Col md="auto">
            <Col md="auto" style={{ padding: 0 }}>
              <Form.Label>Data</Form.Label>
            </Col>
            <Col md="auto" style={{ padding: 0 }}>
              <Input
                name="data"
                type="date"
                error={this.state.dataInvalida}
                onChange={this.changeDate}
                loading={this.state.loadingData}
                value={this.state.data}
              />
            </Col>
          </Col> */}
          <Col md="auto" style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={this.buscarOnClick}
              color="green"
              loading={this.state.buscando}
              disabled={
                this.state.optionsSelected.length === 0 ||
                this.state.dataInvalida
              }
            >
              Buscar
            </Button>
          </Col>

          <Col md="auto" style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={this.imprimirOnClick}
              color="blue"
              disabled={
                this.state.optionsSelected.length === 0 ||
                this.state.dataInvalida
              }
            >
              Imprimir
            </Button>
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>N° do título</Table.HeaderCell>
              <Table.HeaderCell>Tipo título</Table.HeaderCell>
              <Table.HeaderCell>Nome do sócio</Table.HeaderCell>
              <Table.HeaderCell>Data de Admissão</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.acoes.map((e, i) => (
              <Table.Row>
                <Table.Cell>
                  {e.tipo_titulo
                    ? e.tipo_titulo.prefixo
                      ? e.tipo_titulo.prefixo +
                        " - " +
                        (e.codigo_auxiliar || "")
                      : e.codigo
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {e.tipo_titulo ? e.tipo_titulo.descricao : ""}
                </Table.Cell>
                <Table.Cell>
                  {e.pessoas && e.pessoas[0] ? e.pessoas[0].nome : ""}
                </Table.Cell>
                <Table.Cell>
                  {e.data_emissao
                    ? moment(e.data_emissao).format("DD/MM/YYYY")
                    : ""}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Row style={{ justifyContent: "center" }}>
          <Pagination
            boundaryRange={0}
            ellipsisItem={null}
            totalPages={this.state.totalPages}
            activePage={this.state.pagina}
            onPageChange={(e, a) => {
              this.setState({ pagina: a.activePage });
              this.buscarOnClick(null, null, a.activePage);
            }}
          />
        </Row>
      </div>
    );
  }
}
