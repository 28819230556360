import React, { Component } from "react";
import Select from "react-select";
import { Row, Col, Form } from "react-bootstrap";
import { Table, Button, Checkbox, Pagination, Menu, Icon } from "semantic-ui-react";


import requisicoes from "../../../utils/Requisicoes";
import moment from "moment-timezone";


import api from "../../../services/api";
import renomearChave from "../../../services/renomearChave";

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data_inicio: null,
      tipo: "pagas",
      data_final: null,
      lista_lancamentos: [],
      lista_servicos: [],
      tipo_relatorio: "simplificado",
      buscando: false,
      imprimindo: false,
      totaisServicos: {},
      servicoSelect: null,
      servico_value: null,
      servico_id: null,
      servico: null,
      detalhes: null,
      optionsSelectBuscaServicos: [],
      optionsBuscaServicos: [],
      listaGrupoServico: [],
      grupoServico: 0,
      listaTipoTitulo: [],
      tipoTitulo: 0,
      checkboxTodos: false,
      listaLancamentos: [],
      sortedName: "nome",
      sortDirection: "ascending",
      activePage: 1,
      lista: [],
      pages: 1

    };
    this.selectBuscaTipoTitulo();
    this.selectBuscaGrupoServico();
  }

  selectBuscaTipoTitulo = async () => {
    let lista = [];
    //Options instituição cobrança
    lista.push({ label: "TODOS", value: 0 });
    lista.push({ label: "INATIVADOS", value: 1 });
    const listaTipoTitulo = await api.post(
      "/tipoTitulo/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    listaTipoTitulo.data.forEach((element) => {
      element.value = element.id;
      element.label = element.descricao;
      lista.push(element);
    });

    this.setState({ listaTipoTitulo: lista });
  };

  selectBuscaGrupoServico = async () => {
    let lista = [];
    //Options instituição cobrança
    lista.push({ label: "TODOS", value: 0 });
    const listaGrupoServico = await api.post(
      "/grupoServico/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    listaGrupoServico.data.forEach((element) => {
      element.value = element.id;
      element.label = element.descricao;
      lista.push(element);
    });

    this.setState({ listaGrupoServico: lista });
  };

  alteracao = async (event, value) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    if (event.target.name === "tipo_relatorio") {
      this.setState({
        listaLancamentos: [],
        totaisServicos: {},
      });
    }
  };
  tipoBusca = async (event, value) => {
    this.setState({
      [event.target.name]: event.target.value,
      tipo: event.target.value,
    });
    this.state.listaLancamentos = [];
    this.state.totaisServicos = [];
  };

  selectBuscaServicos = async (grupo_servico_id) => {
    //Options dos servicos
    const retornoservicos = await api.post(
      // "/lancamentoConta/buscaTodosServicos?e=" + entidade,
      "/servico/servicoPorGrupoServicoComDiversos" + requisicoes.entidade,
      {
        id: grupo_servico_id,
      },
      requisicoes.header
    );
    retornoservicos.data.sort(function (a, b) {
      if (a.nome > b.nome) {
        return 1;
      }
      if (a.nome < b.nome) {
        return -1;
      }
      return 0;
    });
    var lista = [];
    lista.push({ label: "TODOS", value: 0 });
    for (let i = 0; i < retornoservicos.data.length; i++) {
      const element = retornoservicos.data[i];
      if (
        element.turmas === true &&
        element.dataInicio === null &&
        element.dataFinal === null
      ) {
        continue;
      }
      let objeto = renomearChave(element, "id", "value");
      objeto = renomearChave(objeto, "nome", "label");
      if (element.turmas === true) {
        objeto.label += " - " + moment(element.dataInicio).year();
      }
      objeto.dataInicio = element.dataInicio;
      objeto.dataFinal = element.dataFinal;
      objeto.turmas = element.turmas;

      lista.push(objeto);
    }
    this.setState({ optionsBuscaServicos: lista });
    this.setState({ optionsSelectBuscaServicos: lista });
  };

  verificaDatas = () => {
    if (!moment(this.state.data_inicio, "YYYY-MM-DD", true).isValid()) {
      this.setState({
        data_inicio: moment().startOf("month").format("YYYY-MM-DD"),
      });
    }
    if (!moment(this.state.data_final, "YYYY-MM-DD", true).isValid()) {
      this.setState({ data_final: moment().format("YYYY-MM-DD") });
    }
    return;
  };

  buscarOnClick = async (event) => {
    this.verificaDatas();

    setTimeout(async () => await this.buscaLancamentos(), 100);
  };

  buscaLancamentos = async (pages = 1, data) => {
    this.setState({ buscando: true });
    if (this.state.tipo === null) {
      this.state.tipo = "pagas";
    }

    try {
      let retorno = null
      try {
        retorno = await api.post(
          "/lancamentoConta/lancamentosTipoTituloServico" + requisicoes.entidade,
          {
            data_inicial: this.state.data_inicio,
            data_final: this.state.data_final,
            tipo_titulo_id: this.state.tipoTitulo,
            grupo_servico_id: this.state.grupoServico,
            servico_id: this.state.servico_id,
            tipo_relatorio: this.state.tipo_relatorio,
            tipo_busca: this.state.tipo,
            checkbox: this.state.checkboxTodos,
            sortedName: this.state.sortedName,
            sortDirection: this.state.sortDirection,
            activePage: pages,
          },
          requisicoes.header
        );
      } catch (error) {
        console.log("erro", error)
      }

      console.log("retorno", retorno)
      const { reqs, pagina, totalPaginas } = retorno.data;

      this.setState({
        lista:
          reqs,
        pagina,
        totalPaginas,
      });
      let total = 0;

      for (let i = 0; i < retorno.data.reqs.length; i++) {
        const element = retorno.data.reqs[i];
        total += parseFloat(
          element.valor_pago ? element.valor_pago : element.valor
        );
      }

      this.setState({
        listaLancamentos: retorno.data.reqs,
        totaisServicos: {
          valor_pago: total,
        },

      });
      this.setState({ buscando: false });
    } catch (error) {
      console.log("Error", error)
      this.setState({ buscando: false })
    }
  };
  proximaPagina = async () => {
    const { pagina } = this.state;
    const paginaNumero = parseInt(pagina) + 1;

    this.buscaLancamentos(paginaNumero);
  };

  voltarPagina = async () => {
    const { pagina } = this.state;
    const paginaNumero = pagina - 1;
    this.buscaLancamentos(paginaNumero);
  };
  gerarRelatorio = async (pages = 1) => {
    const retorno = await api.post(
      "/lancamentoConta/relatorioTipoTitulo" + requisicoes.entidade,
      {
        data_inicial: this.state.data_inicio,
        data_final: this.state.data_final,
        tipo_titulo_id: this.state.tipoTitulo,
        grupo_servico_id: this.state.grupoServico,
        servico_id: this.state.servico_id,
        tipo_relatorio: this.state.tipo_relatorio,
        tipo_busca: this.state.tipo,
        checkbox: this.state.checkboxTodos,
        sortedName: this.state.sortedName,
        sortDirection: this.state.sortDirection,
        activePage: pages,
      },
      requisicoes.header
    );
  };

  buscarRelatorio = async () => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    const r = await api.get(
      "/lancamentoConta/arquivoRelatorioTipoTitulo?e=" +
      entidade +
      "&t=Baer " +
      token
    );

    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado?e=" + entidade,
      {},
      {
        headers: { token: "Baer " + token },
      }
    );

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "lancamentoConta/arquivoRelatorioTipoTitulo?e=" +
      entidade +
      "&t=Baer " +
      token;
    // +
    // "&userId=" +
    // usuarioLogado.data.id;

    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  imprimirButtonOnClick = async () => {
    this.setState({ imprimindo: true });
    await this.gerarRelatorio();
    await this.buscarRelatorio();
    this.setState({ imprimindo: false });
  };
  changeCheckbox = (checked, i) => {
    let lista = "";
    this.state.checkboxTodos = checked;
    this.setState({ checkboxTodos: checked });
    this.state.listaLancamentos = [];
    this.state.totaisServicos.valor_pago = 0.0;
  };
  sortTableClick = async (sortedName) => {
    this.state.sortedName = sortedName;
    this.state.sortDirection =
      this.state.sortDirection === "ascending" ? "descending" : "ascending";
    // this.buscaAdimplentes();
    this.buscarOnClick();
  };
  render() {
    return (
      <div>
        <Row>
          <Col sm={4}>
            <Form.Label>Data Inicio</Form.Label>
            <Form.Control
              placeholder="Data Inicio"
              aria-describedby="basic-addon1"
              type="date"
              name="data_inicio"
              value={this.state.data_inicio}
              onChange={this.alteracao}
            />
          </Col>
          <Col sm={4}>
            <Form.Label>Data Final</Form.Label>
            <Form.Control
              placeholder="Data Final"
              aria-describedby="basic-addon1"
              type="date"
              name="data_final"
              value={this.state.data_final}
              onChange={this.alteracao}
            />
          </Col>
          <Col sm={4}>
            <Form.Label>Tipo de título</Form.Label>
            <Select
              as="select"
              name="tipoTitulo"
              id="tipoTitulo"
              defaultValue={{ label: "TODOS", value: 0 }}
              required
              onChange={(event) => {
                this.setState({ tipoTitulo: event.value });
                this.selectBuscaServicos(event.value);
              }}
              options={this.state.listaTipoTitulo}
            ></Select>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Form.Label>Grupo Serviço</Form.Label>
            <Select
              as="select"
              name="grupoServico"
              id="grupoServico"
              defaultValue={{ label: "TODOS", value: 0 }}
              required
              onChange={(event) => {
                this.setState({ grupoServico: event.value });
                this.selectBuscaServicos(event.value);
              }}
              options={this.state.listaGrupoServico}
            ></Select>
          </Col>

          <Col sm={4}>
            <Form.Label>Serviço</Form.Label>
            <Select
              as="select"
              name="servico"
              id="servico"
              defaultValue={{ label: "TODOS", value: 0 }}
              required
              onChange={this.alteracaoSelectServicos}
              options={this.state.optionsSelectBuscaServicos}
              disabled={this.state.grupoServico === 0}
            ></Select>
          </Col>
          <Col sm={4}>
            <Form.Label>Tipo de busca</Form.Label>
            <Form.Control
              as="select"
              name="tipo"
              id="tipo"
              value={this.state.tipo}
              required
              onChange={this.tipoBusca}
            >
              <option value="pagas">PAGAS</option>
              <option value="aberto">EM ABERTO</option>
            </Form.Control>
          </Col>
        </Row>
        <Row style={{ paddingTop: 10 }}>
          <Col sm={2}>
            <Form.Label>Tipo do Relatório</Form.Label>
            <Form.Control
              as="select"
              name="tipo_relatorio"
              id="tipo_relatorio"
              value={this.state.tipo_relatorio}
              required
              onChange={this.alteracao}
            >
              <option value="simplificado">SIMPLIFICADO</option>
              <option value="detalhado">DETALHADO</option>
            </Form.Control>
          </Col>
          <Col sm={2}>
            {this.state.tipo === "aberto" &&
              this.state.tipoTitulo !== 1 &&
              this.state.tipo_relatorio === "detalhado" && (
                <Checkbox
                  label="Agrupar"
                  checked={this.state.checkboxTodos}
                  onChange={(event, a) => this.changeCheckbox(a.checked, -1)}
                  style={{ marginTop: "35px" }}
                />
              )}
          </Col>
          <Col
            style={{
              marginBottom: "10px",
              marginTop: "15px",
              marginLeft: 0,
              marginRight: 0,
              display: "flex",
            }}
          >
            <Button
              positive
              style={{ marginTop: "10px", marginLeft: "auto" }}
              onClick={this.buscarOnClick}
              loading={this.state.buscando}
            >
              Buscar
            </Button>

            <Button
              primary
              style={{ marginTop: "10px" }}
              loading={this.state.imprimindo}
              onClick={this.imprimirButtonOnClick}
              disabled={
                this.state.imprimindo ||
                this.state.listaLancamentos.length === 0
              }
            >
              Imprimir
            </Button>
          </Col>
        </Row>

        <Table celled sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                collapsing
                sorted={
                  this.state.sortedName === "titulo"
                    ? this.state.sortDirection
                    : null
                }
                onClick={() => this.sortTableClick("titulo")}
              >
                Tipo de Título
              </Table.HeaderCell>
              {this.state.tipoTitulo !== 1 && (
                <Table.HeaderCell
                  collapsing
                  sorted={
                    this.state.sortedName === "servico"
                      ? this.state.sortDirection
                      : null
                  }
                  onClick={() => this.sortTableClick("servico")}
                >
                  Serviço
                </Table.HeaderCell>
              )}
              {this.state.tipoTitulo === 1 &&
                this.state.tipo_relatorio === "simplificado" && (
                  <>
                    <Table.HeaderCell
                      collapsing
                      sorted={
                        this.state.sortedName === "nome"
                          ? this.state.sortDirection
                          : null
                      }
                      onClick={() => this.sortTableClick("nome")}
                    >
                      Pessoa
                    </Table.HeaderCell>
                    {this.state.tipo_relatorio !== "detalhado" && (
                      <Table.HeaderCell
                        collapsing
                        sorted={
                          this.state.sortedName === "parcelas"
                            ? this.state.sortDirection
                            : null
                        }
                        onClick={() => this.sortTableClick("parcelas")}
                      >
                        Parcelas
                      </Table.HeaderCell>
                    )}
                  </>
                )}

              {this.state.tipo_relatorio === "detalhado" && (
                <>
                  <Table.HeaderCell
                    collapsing
                    sorted={
                      this.state.sortedName === "nome"
                        ? this.state.sortDirection
                        : null
                    }
                    onClick={() => this.sortTableClick("nome")}
                  >
                    Pessoa
                  </Table.HeaderCell>
                  {this.state.tipo === "aberto" && (
                    <>
                      {this.state.checkboxTodos === false && (
                        <Table.HeaderCell
                          collapsing
                          sorted={
                            this.state.sortedName === "datavencimento"
                              ? this.state.sortDirection
                              : null
                          }
                          onClick={() => this.sortTableClick("datavencimento")}
                        >
                          Data Vencimento
                        </Table.HeaderCell>
                      )}

                      {this.state.checkboxTodos === true && (
                        <Table.HeaderCell
                          collapsing
                          sorted={
                            this.state.sortedName === "parcelas"
                              ? this.state.sortDirection
                              : null
                          }
                          onClick={() => this.sortTableClick("parcelas")}
                        >
                          Parcelas
                        </Table.HeaderCell>
                      )}
                    </>
                  )}

                  {this.state.tipo === "pagas" && (
                    <Table.HeaderCell
                      collapsing
                      sorted={
                        this.state.sortedName === "datapagamento"
                          ? this.state.sortDirection
                          : null
                      }
                      onClick={() => this.sortTableClick("datapagamento")}
                    >
                      Data Pagamento
                    </Table.HeaderCell>
                  )}
                  {/* <Table.HeaderCell collapsing>Data Pagamento</Table.HeaderCell> */}
                </>
              )}
              <Table.HeaderCell
                collapsing
                textAlign={"right"}
                sorted={
                  this.state.sortedName === "valor"
                    ? this.state.sortDirection
                    : null
                }
                onClick={() => this.sortTableClick("valor")}
              >
                Valor
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.listaLancamentos.map((e, i) => (
              <Table.Row>
                <Table.Cell>{e.tipo_titulo}</Table.Cell>
                {this.state.tipoTitulo !== 1 && (
                  <Table.Cell>{e.servico}</Table.Cell>
                )}
                {this.state.tipoTitulo === 1 &&
                  this.state.tipo_relatorio !== "detalhado" && (
                    <>
                      <Table.Cell>{e.nome}</Table.Cell>
                      <Table.Cell>{e.quantidade_parcelas}</Table.Cell>
                    </>
                  )}
                {this.state.tipo_relatorio === "detalhado" && (
                  <>
                    <Table.Cell>{e.nome}</Table.Cell>
                    {this.state.tipo === "pagas" && (
                      <Table.Cell>
                        {moment(e.data_pagamento).format("DD/MM/YYYY")}
                      </Table.Cell>
                    )}
                    {this.state.tipo === "aberto" && (
                      <>
                        {this.state.checkboxTodos === false && (
                          <Table.Cell>
                            {moment(e.data_vencimento).format("DD/MM/YYYY")}
                          </Table.Cell>
                        )}

                        {this.state.checkboxTodos === true && (
                          <Table.Cell>{e.quantidade_parcelas}</Table.Cell>
                        )}
                      </>
                    )}
                  </>
                )}
                <Table.Cell textAlign={"right"}>
                  {parseFloat(
                    e.valor_pago ? e.valor_pago : e.valor
                  ).toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              {this.state.tipoTitulo !== 1 && (
                <Table.HeaderCell></Table.HeaderCell>
              )}

              <Table.HeaderCell></Table.HeaderCell>
              {this.state.tipo_relatorio === "simplificado" &&
                this.state.tipoTitulo === 1 && (
                  <>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </>
                )}
              {this.state.tipo_relatorio === "detalhado" && (
                <>
                  <Table.HeaderCell></Table.HeaderCell>

                  <Table.HeaderCell></Table.HeaderCell>
                </>
              )}
              <Table.HeaderCell textAlign={"right"}>
                {this.state.totaisServicos.valor_pago !== null &&
                  this.state.totaisServicos.valor_pago !== undefined
                  ? parseFloat(
                    this.state.totaisServicos.valor_pago
                  ).toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                  : "0,00"}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Table celled>
          <Table.Footer >
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan={5}>
                <Menu pagination>
                  <Menu.Item
                    icon
                    onClick={this.voltarPagina}
                    disabled={parseInt(this.state.pagina) === 1}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  <Menu.Item
                    icon
                    onClick={this.proximaPagina}
                    disabled={
                      parseInt(this.state.pagina) ===
                      parseInt(this.state.totalPaginas)
                    }
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                </Menu>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}
