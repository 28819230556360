import React, { Component } from "react";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import { Modal } from "semantic-ui-react";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";
import Select from "react-select";
import renomearChave from "../../../../services/renomearChave";
import { Button } from "semantic-ui-react";
import CurrencyInput from "react-currency-input";
import TipoLancamento from "../../../../enum/TipoLancamento";
import CreditoDebito from "../../../../enum/CreditoDebito";
import moment from "moment-timezone";
import Meses from "../../../../enum/Mes";
import ReplaceAll from "replaceall";
export default class ModalServico extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      optionsServico: [],
    };
  }

  excluir = async () => {
    api
      .post(
        "/imovel/excluirLancamentoServico" + requisicoes.entidade,
        { id: this.props.lancamento_servico_id },
        requisicoes.header
      )
      .then(() => {
        this.props.removeLancamentoServico();
        this.props.alteracaoSelectServico({
          servico: null,
          valor: null,
          lancamento_servico_id: null,
        });
        this.props.abreFechaModalLancamentoServico();
      });
  };

  submit = async (event) => {
    event.preventDefault();
    let {
      data_vencimento,
      lancamento_servico_id,
      id,
      descricao,
      servico,
      pessoa_id,
      mes,
      ano,
      adicionaLancamentoServico,
      setaId,
      alteracaoSelectServico,
      abreFechaModalLancamentoServico,
      alteracaoLancamentoServico,
    } = this.props;
    let valor = ReplaceAll(".", "", this.props.valor);
    valor = ReplaceAll(",", ".", valor);
    if (lancamento_servico_id === null) {
      adicionaLancamentoServico({
        servico: servico,
        valor: valor,
        mes: mes,
        ano: ano,
        data_vencimento: data_vencimento,
        salvar: true,
      });
      alteracaoSelectServico({
        servico: null,
        valor: null,
        lancamento_servico_id: null,
      });
      abreFechaModalLancamentoServico();
    } else {
      alteracaoLancamentoServico();
      alteracaoSelectServico({
        servico: null,
        valor: null,
        lancamento_servico_id: null,
      });
      abreFechaModalLancamentoServico();
    }
  };

  pesquisaServico = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/servico/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaServico").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista.push(objeto);
      });
      this.setState({ optionsServico: lista });
    }, 500);
  };

  render() {
    return (
      <Modal
        size="small"
        open={this.props.abreModalLancamentoServico}
        onClose={this.props.abreFechaModalLancamentoServico}
        style={{ overflow: "visible" }}
      >
        <Modal.Header content="Serviço" />

        <Modal.Content>
          <Row>
            <Col sm={8}>
              <Form.Label>Serviço</Form.Label>
              <Select
                placeholder="Digite para buscar"
                name="servico"
                value={this.props.servico}
                onChange={this.props.alteracaoSelectServico}
                options={this.state.optionsServico}
                onKeyDown={this.pesquisaServico}
                inputId="pesquisaServico"
              />
            </Col>
            <Col sm={4}>
              <Form.Label>Valor</Form.Label>
              <CurrencyInput
                decimalSeparator=","
                thousandSeparator="."
                className="form-control"
                value={this.props.valor}
                disabled={this.props.lancamento_servico_id}
                onChange={(value) =>
                  this.props.alteracao({
                    target: { name: "valor", value: value },
                  })
                }
              />
            </Col>
            <Col sm={4}>
              <Form.Label>Data Vencimento</Form.Label>
              <FormControl
                aria-describedby="basic-addon1"
                type="date"
                name="data_vencimento"
                onChange={this.props.alteracao}
                value={this.props.data_vencimento}
                disabled={this.props.lancamento_servico_id}
              />
            </Col>
          </Row>
        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={this.submit}>
            Salvar
          </Button>
          {this.state.lancamento_servico_id !== null && (
            <Button negative type="button" onClick={this.excluir}>
              Deletar
            </Button>
          )}
          <Button onClick={this.props.abreFechaModalLancamentoServico}>
            Fechar
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
