import React, { Component } from "react";
import { Grid, Image } from "semantic-ui-react";
import logo from "../../images/faeed4.png";

const logoApp = logo;

export default class home extends Component {
  render() {
    return (
      <Grid
        textAlign="center"
        style={{ height: "70vh" }}
        verticalAlign="middle"
        container
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Image src={logoApp} fluid size="medium" centered />
        </Grid.Column>
      </Grid>
    );
  }
}
