import React, { Component } from "react";
import { Header, Modal, Button, Table } from "semantic-ui-react";
import { Col, Row, Form, FormControl } from "react-bootstrap";

export default class ModalRemoveDependente extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Modal
          open={this.props.open_modal_remove_dependentes}
          onClose={this.props.handleCloseRemoveDependentes}
          centered={false}
        >
          <Header content="Dependente" />
          <Modal.Content>
            <Row>
              <Col sm={12} style={{ paddingTop: "10px", paddingBottom: "2%" }}>
                <Header as="h3">Usuários</Header>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Login</Table.HeaderCell>
                      <Table.HeaderCell>Perfil</Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.props.listaUsuariosDependente.map((e, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>{e.login}</Table.Cell>
                        <Table.Cell>
                          {e.tipo_usuario.substring(0, 1).toUpperCase() +
                            e.tipo_usuario.substring(1, 50)}
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            icon="remove"
                            negative
                            onClick={() =>
                              this.props.removeUsuarioDependente(e.id)
                            }
                          ></Button>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Col>
              <Col sm={12} style={{ marginTop: "10px" }}>
                <FormControl
                  placeholder="Motivo"
                  aria-describedby="basic-addon1"
                  as="textarea"
                  name="motivo_remove_dependente"
                  onChange={this.props.alteracao}
                  required
                />
              </Col>
            </Row>
          </Modal.Content>

          <Header floated="right">
            <Row>
              <Col sm={5}>
                <Button
                  type="button"
                  variant="light"
                  onClick={this.props.handleCloseRemoveDependentes}
                >
                  Voltar
                </Button>
              </Col>
              <Col sm={5}>
                <Button primary onClick={this.props.removeDependentes}>
                  Salvar
                </Button>
              </Col>
            </Row>
          </Header>
        </Modal>
      </Form>
    );
  }
}
