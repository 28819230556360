import React, { Component } from "react";
import { Container, Header, Menu, Segment, Table } from "semantic-ui-react";
import { Row, Col, Form } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import moment from "moment-timezone";
import Icon from "@material-ui/core/Icon";
import FilterTiltShiftIcon from "@material-ui/icons/FilterTiltShift";

import api from "../../../services/api";
import "../styles.css";
export default class Competicao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaCampeonato: [],
      id: null,
    };
    this.lista();
  }

  lista = async () => {
    var campeonato = await api.post(
      "/eventos/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    this.setState({ listaCampeonato: campeonato.data });
  };

  voltar = async () => {
    window.location.href = "/eventos";
  };

  cadastrarEvento = async () => {
    window.location.href = "/eventos/cadastro";
  };

  render() {
    return (
      <Container className="paginaSemPaddingTop">
        <Segment>
          <Header as="h2" dividing>
            Eventos
            <Button
              style={{ outline: "none" }}
              color="primary"
              floated="right"
              type="button"
              onClick={this.voltar}
            >
              Voltar
            </Button>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              floated="right"
            >
              <Icon onClick={this.cadastrarEvento}>add_circle</Icon>
            </IconButton>
          </Header>

          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Descricao</Table.HeaderCell>
                <Table.HeaderCell>Nº de Etapas</Table.HeaderCell>
                <Table.HeaderCell>Tipo de Evento</Table.HeaderCell>
                <Table.HeaderCell>Resultados</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.listaCampeonato.map((element, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{element.descricao}</Table.Cell>
                  <Table.Cell>{element.quantidade_etapas}</Table.Cell>
                  <Table.Cell>{element.tipo_evento}</Table.Cell>
                  <Table.Cell>
                    {" "}
                    <Link
                      to={{
                        pathname: "/eventos/ajustar",
                        query: {
                          id: element.id,
                        },
                      }}
                    >
                      <Button
                        className="botaoTabble"
                        icon
                        style={{ outline: "none" }}
                        size="mini"
                      >
                        <FilterTiltShiftIcon
                          size="small"
                          name="configure"
                          id={element.id}
                        />
                      </Button>
                    </Link>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      </Container>
    );
  }
}
