import React, { Component } from "react";
import { Table, Button, Header, Search, Pagination } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import requisicoes from "../../../utils/Requisicoes";
import moment from "moment";

export default class AvaliacaoFisicaLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaAvaliacoes: [],
      listaMinhasAvaliacoes: [],
      pagina: 1,
      totalPaginas: 1,
      busca: null,
    };
    this.listaAvaliacoes();
    this.listaMinhasAvaliacoes();
  }

  listaMinhasAvaliacoes = async () => {
    const respostaUsuarioLogado = await api.post(
      "/usuario/usuarioLogado" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ usuarioLogado: respostaUsuarioLogado.data });

    await api
      .post(
        "/avaliacaoFisica/avaliacaoPorPessoa" + requisicoes.entidade,
        {
          aluno_id: respostaUsuarioLogado.data.pessoa_id,
        },
        requisicoes.header
      )
      .then((resp) => {
        this.setState({
          listaMinhasAvaliacoes: resp.data,
        });
      });
  };

  listaAvaliacoes = async (a, data, busca) => {
    if (data === undefined || data === null) {
      data = {};
      data.activePage = 1;
    }
    if (busca !== undefined && busca !== null && busca.trim() === "") {
      busca = undefined;
    }
    await api
      .post(
        "/avaliacaoFisica/lista" + requisicoes.entidade,
        {
          busca: busca,
          pagina: data.activePage || this.state.pagina,
        },
        requisicoes.header
      )
      .then((resp) => {
        const { docs, pages } = resp.data;
        this.setState({
          listaAvaliacoes: docs,
          pagina: data.activePage,
          totalPaginas: pages,
        });
      });
  };

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === "busca") {
      this.listaAvaliacoes(null, null, event.target.value);
    }
  };

  render() {
    return (
      <div className="lista">
        <Header
          as="h1"
          dividing
          style={{
            display:
              window.localStorage.getItem("pagina_anterior") === "pessoa"
                ? "none"
                : "block",
          }}
        >
          Avaliações físicas
        </Header>
        {localStorage.getItem("tipo_usuario_clube") === "administrador" ||
        localStorage.getItem("tipo_usuario_clube") === "administradorSistema" ||
        localStorage.getItem("tipo_usuario_clube") === "administrativo" ||
        localStorage.getItem("tipo_usuario_clube") === "modalidade" ? (
          <>
            <Row>
              <Col sm={6}>
                <Search
                  placeholder="Digite para buscar"
                  name="busca"
                  onSearchChange={this.alteracao}
                  open={false}
                  fluid
                />
              </Col>
            </Row>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Nome</Table.HeaderCell>
                  <Table.HeaderCell>Data Lançamento</Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign="center">
                    <Link to={{ pathname: "/avaliacaoFisica" }}>
                      <Button positive icon="add"></Button>
                    </Link>
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={1}
                    textAlign="center"
                  ></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {this.state.listaAvaliacoes.map((e, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{e.aluno.nome}</Table.Cell>
                    <Table.Cell>
                      {moment(e.data_lancamento).format("DD/MM/YYYY")}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Link
                        to={{
                          pathname: "/avaliacaoFisica",
                          query: { id: e.id },
                        }}
                      >
                        <Button primary icon="edit"></Button>
                      </Link>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Link
                        to={{
                          pathname: "/avaliacaoFisica",
                          query: { id: e.id, resultado: true },
                        }}
                      >
                        <Button primary icon="eye"></Button>
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>

              <Table.Footer>
                <Table.Row verticalAlign="middle" textAlign="center">
                  <Table.HeaderCell colSpan="4">
                    <Pagination
                      boundaryRange={0}
                      ellipsisItem={null}
                      totalPages={this.state.totalPaginas}
                      onPageChange={this.listaAvaliacoes}
                      activePage={this.state.pagina}
                    ></Pagination>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </>
        ) : (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nome</Table.HeaderCell>
                <Table.HeaderCell>Data Lançamento</Table.HeaderCell>
                <Table.HeaderCell
                  width={1}
                  textAlign="center"
                ></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {this.state.listaMinhasAvaliacoes.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{e.aluno.nome}</Table.Cell>
                  <Table.Cell>
                    {moment(e.data_lancamento).format("DD/MM/YYYY")}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Link
                      to={{
                        pathname: "/avaliacaoFisica",
                        query: { id: e.id, resultado: true },
                      }}
                    >
                      <Button primary icon="eye"></Button>
                    </Link>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
    );
  }
}
