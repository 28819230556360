import React, { Component } from "react";
import {
  Container,
  Header,
  Menu,
  Segment,
  Table,
  Image,
  Input,
  Divider,
  Modal,
} from "semantic-ui-react";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import "../styles.css";

export default class modalAddEtapaTreino extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkEtapa: false,
    };
  }

  checkboxVerify = () => {
    if (this.state.checkEtapa === false) {
      this.setState({ checkEtapa: true });
    } else {
      this.setState({ checkEtapa: false });
    }
  };

  render() {
    return (
      <Modal dimmer="bluring" size="small" open={this.props.modalEtapa}>
        <Modal.Header>
          {"Adicionar Etapa ao Evento de Treino "}
          <IconButton
            className="bottomModal"
            onClick={() => this.props.changeModalEtapa(false)}
            color="secondary"
          >
            <CloseIcon />
          </IconButton>
          <h6>
            {" "}
            Ao clicar em adiciona etapa, você adicionará uma etapa de treino ao
            evento selecionado!
          </h6>
        </Modal.Header>

        <Modal.Content>
          {this.props.alertaEtapa === true ? (
            <Col sm={12}>
              <Form.Label>Descrição</Form.Label>

              <div className="input-group">
                <textarea
                  className="textArea"
                  name="descricaoetapa"
                  id="descricaoetapa"
                  rows="3"
                  placeholder="Detalhes da Etapa, Ex: Treino de tiro ao alvo"
                  required
                  onBlur={this.props.alteracao}
                ></textarea>
              </div>
              <div className="checkModalAjuste">
                <div>
                  <TextField
                    type="date"
                    id="data_inicio_treino"
                    name="data_inicio_treino"
                    onChange={this.props.alteracao}
                  />
                </div>

                <div className="CheckBoxModal">
                  <Checkbox
                    root
                    name="checkEtapa"
                    id="checkEtapa"
                    onClick={this.checkboxVerify}
                  />
                  <h6> Estou ciente!</h6>
                </div>
              </div>
            </Col>
          ) : (
            <Alert severity="success" hidden={this.props.alertaEtapa}>
              <AlertTitle>Etapa Adicionada!</AlertTitle>
              Iremos redirecionar-lhe — <strong>Aguarde!</strong>
            </Alert>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            disabled={
              this.state.checkEtapa === false ||
              this.props.data_inicio_treino === null
            }
            color="primary"
            floated="right"
            onClick={this.props.adicionarEtapa}
            style={{ outline: "none" }}
          >
            Adicionar Etapa
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
