import React, { Component } from "react";
import {
  Container,
  Header,
  Button,
  Divider,
  Segment,
  FormField,
  Input,
} from "semantic-ui-react";
import Select from "react-select";
import api from "../../../../services/api";
import { Link } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import requisicoes from "../../../../utils/Requisicoes";
import ModalLancamentoServico from "./modalServico";
import renomearChave from "../../../../services/renomearChave";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ListaServico from "./listaServico";
import Meses from "../../../../enum/Mes";
import moment from "moment-timezone";
import VendaAcumulada from "./modalVendaAcumulada";
import TipoLancamento from "../../../../enum/TipoLancamento";
import CreditoDebito from "../../../../enum/CreditoDebito";
export default class Imovel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      descricao: null,
      servicos: [],
      abreModalLancamentoServico: false,
      optionsPessoa: [],
      pessoa: null,
      servico: null,
      valor: null,
      lancamento_servico_id: null,
      selecaoMenu: 0,
      anos: [moment().get("year")],
      ano: moment().get("year"),
      mes: moment().get("month"),
      abreModalVendaAcumulada: false,
      data_vencimento: null,
      dia_vencimento: null,
    };
    this.alteracao = this.alteracao.bind(this);
    this.visualiza();
  }

  setaId = (id) => {
    this.setState({ id: id });
  };

  visualizaServico = (id, data_vencimento) => {
    const servico = this.state.servicos.find((s) => s.id === id);
    console.log(data_vencimento);
    this.setState({
      lancamento_servico_id: servico.id,
      servico: servico.servico,
      valor: parseFloat(servico.valor).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      abreModalLancamentoServico: true,
      data_vencimento: data_vencimento,
    });
  };

  alteracao(event) {
    console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  }

  visualiza = async () => {
    if (this.props.location.query !== undefined) {
      const retorno = await api.post(
        "/imovel/visualiza" + requisicoes.entidade,
        { id: this.props.location.query.id },
        requisicoes.header
      );
      retorno.data.imovel.pessoa.value = retorno.data.imovel.pessoa.id;
      retorno.data.imovel.pessoa.label = retorno.data.imovel.pessoa.nome;
      let anos = this.state.anos;
      for (var i = 0; i < retorno.data.lancamentoServico.length; i++) {
        retorno.data.lancamentoServico[i].servico.label =
          retorno.data.lancamentoServico[i].servico.nome;
        retorno.data.lancamentoServico[i].data_vencimento =
          retorno.data.lancamentoServico[i].lancamento_conta.data_vencimento;
        if (
          anos.find((a) => a === retorno.data.lancamentoServico[i].ano) ===
          undefined
        ) {
          anos.push(retorno.data.lancamentoServico[i].ano);
        }
        retorno.data.lancamentoServico[i].mes = Meses.findIndex(
          (m) => m.value === retorno.data.lancamentoServico[i].mes
        );
        if (i + 1 === retorno.data.lancamentoServico.length) {
          this.setState({ selecaoMenu: anos.length - 1 });
          this.setState({ servicos: retorno.data.lancamentoServico });
          this.setState({ anos: anos });
        }
      }
      this.setState(retorno.data.imovel);
    }
  };

  abreFechaModalLancamentoServico = () => {
    this.setState({ servico: null, valor: null, data_vencimento: null });
    this.setState({
      abreModalLancamentoServico: !this.state.abreModalLancamentoServico,
    });
  };

  abreFechaModalVendaAcumulada = () => {
    this.setState({
      abreModalVendaAcumulada: !this.state.abreModalVendaAcumulada,
    });
  };

  adicionaLancamentoServico = (servico) => {
    let servicos = this.state.servicos;
    servicos.push(servico);
    this.setState({ servicos: servicos });
  };

  alteracaoLancamentoServico = () => {
    let servicos = this.state.servicos;
    const indice = servicos.findIndex(
      (s) => s.id === this.state.lancamento_servico_id
    );
    servicos.splice(parseInt(indice), 1, {
      id: this.state.lancamento_servico_id,
      servico: this.state.servico,
      valor: this.state.valor,
      mes: this.state.mes,
      ano: this.state.ano,
    });
    this.setState({ servicos: servicos });
  };

  removeLancamentoServico = () => {
    let servicos = this.state.servicos.filter(
      (s) => s.id !== this.state.lancamento_servico_id
    );
    this.setState({ servicos: servicos });
  };

  alteracaoSelectPessoa = (pessoa) => {
    this.setState({ pessoa: pessoa });
  };

  alteracaoSelectServico = (e) => {
    this.setState({ servico: e });
    this.setState({ valor: e.valor });
    if (e.lancamento_servico_id !== undefined) {
      this.setState({ lancamento_servico_id: e.lancamento_servico_id });
    }
  };

  pesquisaPessoa = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/pessoa/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaPessoa").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista.push(objeto);
      });
      this.setState({ optionsPessoa: lista });
    }, 500);
  };

  handleSubmit = (event) => {
    this.submit();
    event.preventDefault();
  };

  submit = async () => {
    let listaLancamentoSalvar = [];
    let listaDeletar = [];
    let lista = this.state.servicos;
    if (this.state.pessoa === null) {
      alert("Favor Selecionar uma Pessoa");
    } else {
      for (var indice = 0; indice < lista.length; indice++) {
        const e = lista[indice];
        if (e.salvar !== undefined && e.salvar === true) {
          let lancamentoJaExistente = listaLancamentoSalvar.find(
            (l) =>
              l.valor === e.valor &&
              l.data_vencimento === e.data_vencimento &&
              l.mes_referencia === e.mes_referencia &&
              l.ano_referencia === e.ano_referencia &&
              l.servico_id === e.servico_id
          );
          listaLancamentoSalvar.push({
            descricao: "Cobrança Imóvel",
            tipo_lancamento: TipoLancamento.find((e) => e.value === "imovel")
              .value,
            credito_debito: CreditoDebito.find((e) => e.value === "credito")
              .value,
            valor: e.valor,
            data_emissao: moment().format("YYYY-MM-DD"),
            data_vencimento: e.data_vencimento,
            pessoa_id:
              this.state.pessoa !== null
                ? this.state.pessoa.value
                : this.state.pessoa_id,
            mes_referencia: Meses[e.mes].value,
            ano_referencia: e.ano,
            servico_id: e.servico.value,
            numero_parcela:
              lancamentoJaExistente === undefined
                ? 1
                : lancamentoJaExistente.numero_parcela + 1,
          });
          lista[indice].numero_parcela =
            lancamentoJaExistente === undefined
              ? 1
              : lancamentoJaExistente.numero_parcela + 1;
        }
        if (indice + 1 === lista.length) {
          if (this.state.id === null) {
            api
              .post(
                "/imovel/salvar" + requisicoes.entidade,
                {
                  descricao: this.state.descricao,
                  pessoa_id: this.state.pessoa.value,
                  listaLancamentoSalvar: listaLancamentoSalvar,
                  listaLancamentoServico: lista,
                },
                requisicoes.header
              )
              .then(() => {
                window.location.href = "/financeiro";
              });
          } else {
            api
              .post(
                "/imovel/alterar" + requisicoes.entidade,
                {
                  id: this.state.id,
                  descricao: this.state.descricao,
                  pessoa_id: this.state.pessoa.value,
                  listaLancamentoSalvar: listaLancamentoSalvar,
                  listaLancamentoServico: lista,
                },
                requisicoes.header
              )
              .then(() => {
                window.location.href = "/financeiro";
              });
          }
        }
      }
    }
  };
  excluir = async () => {
    const dados = this.state;
    let retorno = "";
    retorno = await api.post(
      "/item/excluir" + requisicoes.entidade,
      dados,
      requisicoes.header
    );
    if (retorno !== null) {
      window.location.href = "/almoxarifado";
    }
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Imóvel
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={12}>
              <Form.Label>Descrição</Form.Label>
              <FormField
                placeholder="Descrição"
                name="descricao"
                control={Input}
                onChange={this.alteracao}
                required
                defaultValue={this.state.descricao}
                fluid
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Pessoa</Form.Label>
              <Select
                placeholder="Digite para buscar"
                name="pessoa"
                value={this.state.pessoa}
                onChange={this.alteracaoSelectPessoa}
                options={this.state.optionsPessoa}
                onKeyDown={this.pesquisaPessoa}
                inputId="pesquisaPessoa"
                required
              />
            </Col>
            <Col sm={1} style={{ marginTop: "25px" }}>
              <Link to={{ pathname: "/pessoa" }}>
                <Button positive icon="add" size="tiny"></Button>
              </Link>
            </Col>
            <Col sm={5} style={{ marginTop: "25px" }}>
              <Form.Label>Venda Acumulada&nbsp;&nbsp;</Form.Label>
              <Button
                positive
                icon="add"
                size="tiny"
                type="button"
                onClick={this.abreFechaModalVendaAcumulada}
              ></Button>
            </Col>

            <VendaAcumulada
              abreFechaModalVendaAcumulada={this.abreFechaModalVendaAcumulada}
              abreModalVendaAcumulada={this.state.abreModalVendaAcumulada}
              lancamento_servico_id={this.state.lancamento_servico_id}
              id={this.state.id}
              descricao={this.state.descricao}
              servico={this.state.servico}
              valor={this.state.valor}
              alteracao={this.alteracao}
              pessoa_id={
                this.state.pessoa !== null ? this.state.pessoa.value : null
              }
              setaId={this.setaId}
              ano={this.state.ano}
              alteracaoSelectServico={this.alteracaoSelectServico}
              adicionaLancamentoServico={this.adicionaLancamentoServico}
              data_vencimento={this.state.data_vencimento}
              dia_vencimento={this.state.dia_vencimento}
            />

            <Col sm={12} style={{ marginTop: "15px" }}>
              <Paper square>
                <Tabs
                  variant="scrollable"
                  value={this.state.selecaoMenu}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={(event, newValue) => {
                    this.setState({ selecaoMenu: newValue });
                  }}
                  aria-label="disabled tabs example"
                >
                  {this.state.anos.map((a) => {
                    return (
                      <Tab
                        label={a}
                        onClick={() => this.setState({ ano: a })}
                      />
                    );
                  })}
                </Tabs>
              </Paper>
            </Col>
            <Col sm={12} style={{ marginTop: "15px" }}>
              <Paper square>
                <Tabs
                  variant="scrollable"
                  value={this.state.mes}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={(event, newValue) => {
                    this.setState({ mes: newValue });
                  }}
                  aria-label="disabled tabs example"
                >
                  {Meses.map((a, i) => {
                    return (
                      <Tab
                        label={a.label}
                        onClick={() => this.setState({ mes: i })}
                      />
                    );
                  })}
                </Tabs>
              </Paper>
            </Col>
          </Row>
          <ListaServico
            abreFechaModalLancamentoServico={
              this.abreFechaModalLancamentoServico
            }
            visualizaServico={this.visualizaServico}
            lista={this.state.servicos.filter(
              (s) => s.mes === this.state.mes && s.ano === this.state.ano
            )}
            ano={this.state.ano}
          />

          <ModalLancamentoServico
            abreModalLancamentoServico={this.state.abreModalLancamentoServico}
            abreFechaModalLancamentoServico={
              this.abreFechaModalLancamentoServico
            }
            setaId={this.setaId}
            id={this.state.id}
            descricao={this.state.descricao}
            adicionaLancamentoServico={this.adicionaLancamentoServico}
            pessoa_id={
              this.state.pessoa !== null ? this.state.pessoa.value : null
            }
            alteracao={this.alteracao}
            valor={this.state.valor}
            servico={this.state.servico}
            alteracaoSelectServico={this.alteracaoSelectServico}
            lancamento_servico_id={this.state.lancamento_servico_id}
            alteracaoLancamentoConta={this.alteracaoLancamentoConta}
            removeLancamentoServico={this.removeLancamentoServico}
            mes={this.state.mes}
            ano={this.state.ano}
            alteracaoLancamentoServico={this.alteracaoLancamentoServico}
            data_vencimento={this.state.data_vencimento}
          />

          <Divider />
          <Segment clearing>
            <Header floated="right">
              <Link to="/financeiro">
                <Button type="button">Voltar</Button>
              </Link>
              <Button positive type="submit">
                Salvar
              </Button>
              {this.state.id !== null && (
                <Button negative type="delete" onClick={this.excluir}>
                  Deletar
                </Button>
              )}
            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}
