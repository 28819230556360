import React, { Component } from "react";
import { Header, Segment, Table } from "semantic-ui-react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Divider,
  Snackbar,
  Alert,
  ThemeProvider,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { green } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

import { Row, Col } from "react-bootstrap";

import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

// Componentes criados

export default class LiberaModulos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // // Lista de dados
      listaEntidades: this.props.listaEntidades.filter(
        (e) => e.descricao !== "public"
      ),
      // // Modal
      // entidadeSelectedModalLiberarModulo: null,
      // openModalLiberarModulo: false,
      entidadePossui: null,
      entidadeCopia: null,
      perfilCopiado: null,
      perfisEntidade: [],

      // button
      loading: false,

      mensagem: {},
    };
    console.log("this.props.listaEntidades: ", this.props.listaEntidades);
  }

  buscarPerfisEntidade = async (entidade) => {
    const perfis = await api.post(
      "/perfil/lista" + "?e=" + entidade,
      {},
      requisicoes.header
    );

    this.setState({ perfisEntidade: perfis.data });
  };

  onChangeSelect = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submit = async (event) => {
    event.preventDefault();

    try {
      this.setState({ loading: true });
      await api.post(
        "/perfil/copiarPerfil" + requisicoes.entidade,
        {
          entidadePossui: this.state.entidadePossui,
          entidadeCopia: this.state.entidadeCopia,
          perfilCopiado: this.state.perfilCopiado,
        },
        requisicoes.header
      );
      this.mensagem(true, "Perfil copiado com sucesso.", 5000);
    } catch (error) {
      if (error.response && error.response.data && error.response.data !== "") {
        this.mensagem(false, error.response.data, 5000);
      }
      this.mensagem(false, "Erro ao salvar o registro.", 5000);
    } finally {
      this.setState({ loading: false });
    }
  };

  mensagem = (positive, content, duration) => {
    this.setState({
      mensagem: {
        show: true,
        positive: positive,
        content: content,
        duration: duration || 4000,
      },
    });
  };

  render() {
    return (
      <div>
        <Segment>
          <Header>Copiar Perfis</Header>
          <div
            style={{
              background: "#F7F7F7",
              padding: 10,
              margin: 20,
              borderRadius: 15,
            }}
          >
            <h4>Instruções</h4>
            <ol>
              <li>Selecionar a entidade que possui o perfil</li>
              <li>Selecionar o perfil a ser copiado</li>
              <li>Selecionar para qual entidade copiar este perfil</li>
            </ol>
            <span>
              OBS: O perfil será criado baseado nas permissões liberadas para a
              entidade, deixando de fora permissões e rotas não autorizadas nos
              módulos.
            </span>
          </div>
          <form onSubmit={this.submit}>
            <Row>
              <Col sm={4}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel>Entidade que possui o perfil</InputLabel>
                  <Select
                    label="Entidade que possui o perfil"
                    name="entidadePossui"
                    value={this.state.entidadePossui}
                    onChange={(evt) => {
                      this.onChangeSelect(evt);
                      this.setState({ perfilCopiado: null });
                      this.buscarPerfisEntidade(evt.target.value);
                    }}
                  >
                    {this.state.listaEntidades.map((e) => (
                      <MenuItem value={e.descricao} key={e.id}>
                        {e.descricao}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
              <Col sm={4}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  required
                  disabled={this.state.entidadePossui === null}
                >
                  <InputLabel>Perfil a ser copiado</InputLabel>

                  <Select
                    label="Perfil a ser copiado"
                    name="perfilCopiado"
                    value={this.state.perfilCopiado}
                    onChange={this.onChangeSelect}
                  >
                    {this.state.perfisEntidade.map((e) => (
                      <MenuItem value={e.id} key={e.id}>
                        {e.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
              <Col sm={4}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel>Entidade em que será copiado o perfil</InputLabel>
                  <Select
                    label="Entidade em que será copiado o perfil"
                    name="entidadeCopia"
                    value={this.state.entidadeCopia}
                    onChange={this.onChangeSelect}
                  >
                    {this.state.listaEntidades.map((e) => (
                      <MenuItem value={e.descricao} key={e.id}>
                        {e.descricao}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>

            <Divider style={{ margin: "20px 0px" }} />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <ThemeProvider
                theme={createTheme({
                  palette: {
                    primary: green,
                  },
                })}
              >
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={this.state.loading}
                  style={{ color: "#FFFFFF" }}
                >
                  Copiar Perfil
                </LoadingButton>
              </ThemeProvider>
            </div>
          </form>
        </Segment>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.mensagem.show}
          autoHideDuration={this.state.mensagem?.duration}
          onClose={() =>
            this.setState({
              mensagem: { ...this.state.mensagem, show: false },
            })
          }
        >
          <Alert
            onClose={() =>
              this.setState({
                mensagem: { ...this.state.mensagem, show: false },
              })
            }
            severity={this.state.mensagem?.positive ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {this.state.mensagem?.content}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}
