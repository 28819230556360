import React, { Component } from "react";
import { Header, Modal, Segment, Divider } from "semantic-ui-react";
import { Col, Row, Button, Form, FormControl } from "react-bootstrap";
import api from "../../../../../services/api";
import requisicoes from "../../../../../utils/Requisicoes";
import renomearChave from "../../../../../services/renomearChave";
import Select from "react-select";
import GrauParentesco from "../../../../../enum/GrauParentesco";
import Numerico from "../../../../../utils/numerico";
import Acao from "../../../../../enum/Acao";

export default class ModalDependente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pessoa: null,
      options: [],
      login: null,
      senha: null,
      grauParentesto: GrauParentesco,
      grau_parentesco: null,
      pessoa_dependente: null,
      open_modal_desvincular: false,
    };
  }

  adicionaDependente = async () => {
    if (this.state.pessoa_dependente.acao_id != null) {
      this.setState({
        open_modal_desvincular: true
      });
      return alert("Esta pessoa já possui ação vinculada.");
    }
    if(this.state.login === null || this.state.login === "" || this.state.login === undefined) {
      return alert("Senha não informada corretamente");
    }
    if(this.state.senha === null || this.state.senha === "" || this.state.senha === undefined) {
      return alert("Usuário não informado corretamente");
    }
    if (this.props.titular_id == null) {
      return alert("Favor Selecionar o Novo Titular");
    } else {
      await api.post(
        "/pessoa/alterar" +
          requisicoes.entidade +
          "&id=" +
          this.state.pessoa.value,
        {
          grau_parentesco: this.state.grau_parentesco,
          titular_id: this.props.titular_id,
          numero_dependente: this.props.numero_titulo,
          acao_id: this.props.acao_id
        },
        requisicoes.header
      );
      await api.post(
        "/historicoDependente/salvar" + requisicoes.entidade,
        {
          acao: Acao.find((e) => e.value === "remover").value,
          motivo: "",
          dependente_id: this.state.pessoa.value,
          titular_id: this.props.titular_id
        },
        requisicoes.header
      );
      const dados = {
        login: this.state.login,
        senha: this.state.senha,
        entidade: window.localStorage.getItem("e_clube"),
        tipo_usuario: "dependente",
        pessoa_id: this.state.pessoa.value,
        nome: this.state.pessoa.label,
      };
      if (this.state.login !== null && this.state.senha !== null) {
        await api.post(
          "/usuario/salvar" + requisicoes.entidade,
          dados,
          requisicoes.header
        );
      }
      this.props.adicionaDependentes({
        pessoa: this.state.pessoa,
        login: this.state.login,
        grau_parentesco: this.state.grau_parentesco,
      });
      this.props.handleCloseDependentes();
      this.setState({
        pessoa_dependente: null,
        pessoa: null,
        grau_parentesco: null,
        login: null,
        senha: null,
      });
    }
  };

  desvincularDependente = async () => {
    try {
      if(this.state.pessoa_dependente == null) return alert('Dependente inválido');
      const retorno = await api.post(
        "/pessoa/alterar" +
          requisicoes.entidade +
          "&id=" +
          this.state.pessoa_dependente.id,
        {
          grau_parentesco: null,
          titular_id: null,
          acao_id: null
        },
        requisicoes.header
      );
      const usuario_existe = await api.post("/usuario/porPessoa" + requisicoes.entidade,
        {
          id: this.state.pessoa_dependente.id,
        },
        requisicoes.header
      );
      if(usuario_existe.data != null) {
        await api.post(
          "/usuario/excluir" + requisicoes.entidade + "&id=" + usuario_existe.data.id,
          {},
          requisicoes.header
        );
      }
      this.setState({
        pessoa_dependente: retorno.data,
        open_modal_desvincular: false,
      });
      return alert('Dependente desvinculado do antigo título');
    } catch(error) {
      console.log("modalDependentes/desvincularDependente: " + error);
    }
  };

  alteracaoSelectPessoa = async (event) => {
    this.setState({ pessoa: event });
    const resposta = await api.post(
      "/pessoa/visualiza" + requisicoes.entidade,
      { id: event.value },
      requisicoes.header
    );
    const usuario_existe = await api.post(
      "/usuario/porPessoa" + requisicoes.entidade,
      { id: event.value },
      requisicoes.header
    );

    this.setState({ 
      pessoa_dependente: resposta.data,
    });
    if(usuario_existe != null && usuario_existe.data != null && usuario_existe != undefined) {
      this.setState({ 
        pessoa_dependente: resposta.data,
        login: usuario_existe.data.login != null ? usuario_existe.data.login 
        : resposta.data.cpf != null ? resposta.data.cpf 
        : resposta.data.cnpj != null ? resposta.data.cnpj != null 
        : '',
        senha: usuario_existe.data.senha != null ? usuario_existe.data.senha 
        : resposta.data.cpf != null ? resposta.data.cpf 
        : resposta.data.cnpj != null ? resposta.data.cnpj != null
        : '',
      });
    }
  };

  alteracao = (event) => {
    console.log('alteracao: ' + event.target.name + " : " + event.target.value);
    this.setState({
      [event.target.name]: event.target.value === "" ? null : event.target.value,
    });
  };

  pesquisaPessoa = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/pessoa/pesquisa" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaPessoa").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        let objeto = renomearChave(element, "id", "value");
        objeto = renomearChave(objeto, "nome", "label");
        lista.push(objeto);
      });
      this.setState({ options: lista });
    }, 500);
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Modal
          open={this.props.open_modal_dependentes}
          onClose={this.props.handleCloseDependentes}
          centered={false}
        >
          <Header content="Dependente" />
          <Modal.Content>
            <Row>
              <Col sm={6}>
                <Form.Label>Pessoa</Form.Label>
                <Select
                  placeholder="Digite para buscar"
                  name="pessoa"
                  onChange={this.alteracaoSelectPessoa}
                  options={this.state.options}
                  onKeyDown={this.pesquisaPessoa}
                  inputId="pesquisaPessoa"
                  required
                />
              </Col>
              <Col sm={6}>
                <Form.Label>Titulo</Form.Label>
                <FormControl
                  placeholder="Titulo"
                  aria-label="Titulo"
                  aria-describedby="basic-addon1"
                  name="titulo"
                  onChange={this.alteracao}
                  value={
                    (this.props.acao !== null ? this.props.acao.codigo : "") +
                    (this.props.numero_titulo !== null
                      ? Numerico(this.props.numero_titulo, 3)
                      : "")
                  }
                  disabled
                  required
                />
              </Col>

              {this.state.pessoa_dependente !== null && (
                <Col sm={12} style={{ paddingTop: "10px" }}>
                  <Header as="h2">Dados Cadastrais</Header>
                  <Divider />
                  <Segment.Group>
                    <Segment.Group horizontal>
                      <Segment>
                        <b>Tipo:</b> {this.state.pessoa_dependente.tipo}
                      </Segment>
                      <Segment>
                        <b>CPF/CNPJ:</b>{" "}
                        {this.state.pessoa_dependente.cpf === null
                          ? this.state.pessoa_dependente.cnpj
                          : this.state.pessoa_dependente.cpf}
                      </Segment>
                      <Segment>
                        <b>E-mail:</b> {this.state.pessoa_dependente.email}
                      </Segment>
                    </Segment.Group>
                    <Segment>
                      <b>Endereço:</b> {this.state.pessoa_dependente.endereco} -{" "}
                      {this.state.pessoa_dependente.bairro},{" "}
                      {this.state.pessoa_dependente.cep} {this.state.pessoa_dependente.cidade}
                      -{this.state.pessoa_dependente.estado}
                    </Segment>
                  </Segment.Group>
                </Col>
              )}

              <Col sm={6}>
                <Form.Label>Login</Form.Label>
                <FormControl
                  placeholder="Login"
                  aria-label="Login"
                  aria-describedby="basic-addon1"
                  name="login"
                  value={this.state.login}
                  onChange={this.alteracao}
                />
              </Col>
              <Col sm={6}>
                <Form.Label>Senha</Form.Label>
                <FormControl
                  placeholder="Senha"
                  aria-label="Senha"
                  type="password"
                  aria-describedby="basic-addon1"
                  name="senha"
                  value={this.state.senha}
                  onChange={this.alteracao}
                />
              </Col>

              <Col sm={6}>
                <Form.Label>Grau de Parentesco</Form.Label>
                <Form.Control
                  as="select"
                  name="grau_parentesco"
                  onChange={this.alteracao}
                  required
                >
                  <option></option>
                  {this.state.grauParentesto.map((e, i) => (
                    <option key={i} value={e.value}>
                      {e.label}
                    </option>
                  ))}
                </Form.Control>
              </Col>
              
              {/* <Col sm={6} style={{ display: "flex", alignItems: "flex-end" }}>
                <Button
                  color="blue"
                  onClick={() => {
                    window.location.href = "/pessoa?from=usuarioTransferencia&dependente=true&titular_id="+this.props.titular_id;
                  }}
                >
                  Cadastrar Pessoa
                </Button>
              </Col> */}
            </Row>
          </Modal.Content>

          <Header floated="right">
            <Row>
              <Col sm={5}>
                <Button
                  type="button"
                  variant="light"
                  onClick={this.props.handleCloseDependentes}
                >
                  Voltar
                </Button>
              </Col>
              <Col sm={5}>
                <Button variant="success" onClick={this.adicionaDependente}>
                  Adicionar
                </Button>
              </Col>
            </Row>
          </Header>
        </Modal>
        {(this.state.open_modal_desvincular == true && this.state.pessoa_dependente !== null && this.state.pessoa_dependente !== undefined) ? (<Modal
          open={this.state.open_modal_desvincular}
          onClose={() => this.setState({ open_modal_desvincular: false })}
          size="small"
        >
          <Header content="Dependente já vinculado" />
          <Modal.Content>
            <Row>
              <Col sm={12}>
                Dependente <b>{this.state.pessoa_dependente.id} - {this.state.pessoa_dependente.nome}</b> vinculado: 
                <b>{this.state.pessoa_dependente.acao !== undefined && this.state.pessoa_dependente.acao !== null ? ` ao título ${this.state.pessoa_dependente.acao.codigo}.` : 'à um título.'}</b><br />
                Deseja desvincular o dependente do título atual?<br />
                Desvincular o dependente fará com que o usuário de login atual seja excluído e será necessário criar um novo <b>login</b> e <b>senha</b> para o dependente.
              </Col>
            </Row>
          </Modal.Content>

          <Header floated="right">
            <Row>
              <Col sm={6}>
                <Button
                  type="button"
                  variant="success"
                  onClick={this.desvincularDependente}
                >
                  Desvincular
                </Button>
              </Col>
              <Col sm={6}>
                <Button 
                  variant="light" 
                  onClick={() => this.setState({ open_modal_desvincular: false })}>
                  Fechar
                </Button>
              </Col>
            </Row>
          </Header>
        </Modal>
        ) : null} 
      </Form>
    );
  }
}
