import React, { Component } from "react";
import { Table, Button, Header } from "semantic-ui-react";
import { Col } from "react-bootstrap";

export default class Dependentes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Col
        sm={12}
        style={{ paddingTop: "10px", paddingLeft: "0", paddingRight: "0" }}
      >
        <Header as="h2">Dependentes</Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Login</Table.HeaderCell>
              <Table.HeaderCell>Grau Parentesco</Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <Button
                  positive
                  onClick={this.props.handleOpenDependentes}
                  icon="add"
                  type="button"
                  size="tiny"
                ></Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.dependentes !== undefined &&
              this.props.dependentes.map((e, i) => (
                <Table.Row key={i}>               
                  <Table.Cell>{e.pessoa.label}</Table.Cell>
                  <Table.Cell>{e.login}</Table.Cell>
                  <Table.Cell>{e.grau_parentesco}</Table.Cell>
                  <Table.Cell width={1}>
                    <Button
                      negative
                      icon="remove"
                      size="tiny"
                      onClick={this.props.removeDependentes}
                      type="button"
                      style={{ marginTop: "7%" }}
                      value={e.pessoa.value}
                    ></Button>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Col>
    );
  }
}
