import React, { Component } from "react";
import {
  Container,
  Table,
  Pagination,
  Button,
  Loader,
  Message,
} from "semantic-ui-react";
import api from "../../../services/api";
import { Col, Form, Row, FormControl } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";

const moment = require("moment-timezone");

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      data_inicio: moment().startOf("month").format("YYYY-MM-DD"),
      data_final: moment().format("YYYY-MM-DD"),
      pessoa_id: null,
      pessoa: null,
      valorTotal: 0.0,
      buscando: false,
      ordenar_por: "maisComprados",
      filtrar_por: null,
      mensagem_erro: null,
      page: 1,
      totalPages: 0,
    };
    this.alteracao = this.alteracao.bind(this);
  }

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if(event.target.name === 'ordenar_por') {
      let listaSort = this.state.lista;
      listaSort.sort((a,b) => event.target.value === "maisComprados" 
          ? (parseFloat(b.valor) - parseFloat(a.valor))
          : (parseFloat(a.valor) - parseFloat(b.valor))
      );
      this.setState({ lista: listaSort });
    } else {
      this.setState({ lista: [] });
    }
  };

  handleSubmit = (event) => {
    this.submit();
    event.preventDefault();
  };

  submit = async () => {
    this.buscaTitulos();
  };

  buscaTitulos = async () => {
    this.setState({ 
      loading: true,
      mensagem_erro: null,
      erro: false,
    });
    let retorno = "";
    try {
      if (!this.state.data_inicio) {
        this.setState({
          mensagem_erro: "Informe uma data inicial", 
          loading: false,
          erro: true 
        });
        return;
      }
      if (!this.state.data_final) {
        this.setState({
          mensagem_erro: "Informe uma data final", 
          loading: false,
          erro: true 
        });
        return;
      }
      if (!this.state.filtrar_por) {
        this.setState({
          mensagem_erro: "Escolha uma opção do Filtrar por", 
          loading: false,
          erro: true 
        });
        return;
      }

      retorno = await api.post(
        "/contasAPagar/lancamentosFornecedores" + requisicoes.entidade,
        {
          data_inicial: this.state.data_inicio,
          data_final: this.state.data_final,
          filtrar_por: this.state.filtrar_por,
          ordenar_por: this.state.ordenar_por,
        },
        requisicoes.header
      );

      this.setState({
        lista: retorno.data,
      });
      this.setState({ loading: false });
    } catch (e) {
      console.log("erro/contasAPagar/lancamentosFornecedores: "+ e);
      this.setState({ erro: true });
      setTimeout(() => {
        this.setState({ erro: false });
      }, 5000);
    }
    this.setState({ loading: false });
  };

  render() {
    return (
      <div className="lista">
        <Row style={{ display: "flex" }}>
          <Col sm={12}>
            <Container style={{ paddingLeft: "0px", paddingRight: "0px" }}>
              <Row>
                <Col sm={2}>
                  <Form.Label>Data Inicio</Form.Label>
                  <FormControl
                    placeholder="Data Inicio"
                    aria-describedby="basic-addon1"
                    type="date"
                    name="data_inicio"
                    onChange={this.alteracao}
                  />
                </Col>
                <Col sm={2}>
                  <Form.Label>Data Final</Form.Label>
                  <FormControl
                    placeholder="Data Final"
                    aria-describedby="basic-addon1"
                    type="date"
                    name="data_final"
                    onChange={this.alteracao}
                  />
                </Col>
                <Col sm={2}>
                  <Form.Label>Ordenar por</Form.Label>
                  <Form.Control
                    placeholder="Ordenar por"
                    label="Ordenar por"
                    id="ordenar_por"
                    as="select"
                    name="ordenar_por"
                    onChange={this.alteracao}
                    required
                  >
                    <option value="maisComprados">Mais Comprados</option>
                    <option value="menosComprados">Menos Comprados</option>
                  </Form.Control>
                </Col>
                <Col sm={2}>
                  <Form.Label>Filtrar por</Form.Label>
                  <Form.Control
                    placeholder="Filtrar por"
                    label="Filtrar por"
                    id="filtrar_por"
                    as="select"
                    name="filtrar_por"
                    onChange={this.alteracao}
                    required
                  >
                    <option></option>
                    <option value="emissao">Emissão</option>
                    <option value="pagamento">Pagamento</option>
                    <option value="vencimento">Vencimento</option>
                  </Form.Control>
                </Col>

                <Col sm={2}>
                  <Button
                    variant="success"
                    style={{ marginTop: "25px" }}
                    onClick={this.buscaTitulos}
                    disabled={this.state.loading}
                    loading={this.state.loading}
                  >
                    Buscar
                  </Button>
                </Col>
                <Col sm={2}>
                  <Button
                    primary
                    onClick={this.imprimirPdfTituloPorFornecedor}
                    style={{ marginTop: "25px", marginLeft: "-28px" }}
                    disabled={this.state.lista.length === 0}
                  >
                    Imprimir
                  </Button>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row paddingTop={15}>
          <Col fluid>
            {this.state.erro && (
              <Message negative>
                <Message.Header>Problema ao buscar os dados</Message.Header>
                <Message.Content>
                  {this.state.mensagem_erro ?? "Ocorreu um erro ao buscar os dados. Verifique os filtros e tente novamente."}
                </Message.Content>
              </Message>
            )}
          </Col>
        </Row>

        <Table structured color={"teal"} key={"teal"} celled>
          <Loader active={this.state.loading} inline="centered" />
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={4}>Fornecedor</Table.HeaderCell>
              <Table.HeaderCell width={1}>Compras</Table.HeaderCell>
              <Table.HeaderCell width={1}>Valor</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          {this.state.lista.map((e, i) => (
            <Table.Body>
              <Table.Row key={i}>
                <Table.Cell sm={4} style={{ textAlign: "left" }}>
                  {e.nome}
                </Table.Cell>

                <Table.Cell style={{ textAlign: "right" }}>
                  {e.compras}
                </Table.Cell>
                <Table.Cell style={{ textAlign: "right" }}>
                  {"R$ "+parseFloat(e.valor).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ))}

          <Table.Footer>
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan={12}>
                <Container
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Pagination
                    boundaryRange={0}
                    ellipsisItem={null}
                    siblingRange={1}
                    activePage={this.state.page}
                    totalPages={this.state.totalPages}
                    onPageChange={(e, data) => {
                      this.setState({ page: data.activePage });
                      var lista = this.state.lista;
                      var porPagina = 30;
                      if (lista.length > porPagina * data.activePage) {
                        this.setState({
                          lista: lista.slice(
                            porPagina * (data.activePage - 1),
                            porPagina * data.activePage
                          ),
                        });
                      } else {
                        this.setState({
                          lista: lista.slice(
                            porPagina * (data.activePage - 1),
                            lista.length
                          ),
                        });
                      }
                    }}
                  />
                </Container>
                {/* <Menu pagination>
                  <Menu.Item
                    icon
                    onClick={this.voltarPagina}
                    disabled={this.state.pagina === 1}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  <Menu.Item
                    icon
                    onClick={this.proximaPagina}
                    disabled={this.state.pagina === this.state.totalPaginas}
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                </Menu> */}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}
