import React, { Component } from "react";
import { Container, Header, Menu, Icon, Table } from "semantic-ui-react";
import { Row, Col, Form, Button } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Pagination } from "semantic-ui-react";

import moment from "moment-timezone";

import api from "../../../services/api";

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      busca: false,
      paginaAtual: 1,
      listaRemissaoSlice: [],
      totalPages: 0,
    };
    this.buscaDireitoRemissao();
  }

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  pageChange = async (event, data) => {
    if (data == undefined || data == null) {
      data = {};
      data.activePage = 1;
    }

    await this.setState({ paginaAtual: data.activePage });
    this.buscaDireitoRemissao();
  };

  buscaDireitoRemissao = async (event) => {
    var r = await api.post(
      "/pessoa/buscaDireitoRemissaoPorTipoTitulo" + requisicoes.entidade,
      {
        tipo: 12514,
        pagina: this.state.paginaAtual,
      },
      requisicoes.header
    );

    await this.setState({ listaRemissaoSlice: r.data.lista });
    await this.setState({ totalPages: r.data.totalPaginas });
    await this.setState({ busca: true });
  };

  render() {
    return (
      <div>
        {
          <div>
            <Header as="h3" dividing>
              Patrimoniais com direito a remissão
            </Header>

            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Nome</Table.HeaderCell>
                  <Table.HeaderCell>Título</Table.HeaderCell>
                  <Table.HeaderCell>Tempo de ação</Table.HeaderCell>
                  <Table.HeaderCell>Idade</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.listaRemissaoSlice.length == 0
                  ? null
                  : this.state.listaRemissaoSlice.map((e, i) => (
                      <Table.Row key={i}>
                        <Table.Cell>{e.nome}</Table.Cell>
                        <Table.Cell>{e.codigo}</Table.Cell>
                        <Table.Cell>
                          {moment().diff(e.data_emissao, "years", false) +
                            " anos"}
                        </Table.Cell>
                        <Table.Cell>
                          {moment().diff(e.datanascimento, "years", false) +
                            " anos"}
                        </Table.Cell>
                      </Table.Row>
                    ))}
              </Table.Body>
            </Table>
            {this.state.listaRemissaoSlice.length == 0 &&
            this.state.busca == false ? (
              <Container
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "40px",
                }}
              >
                <CircularProgress></CircularProgress>
              </Container>
            ) : this.state.listaRemissaoSlice.length == 0 &&
              this.state.busca == true ? (
              <Container
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                <h4>
                  Não existem titulares patrimoniais com direito a remissão.
                </h4>
              </Container>
            ) : null}

            <Container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Pagination
                boundaryRange={0}
                //defaultActivePage={1}
                ellipsisItem={null}
                //firstItem={null}
                //lastItem={null}
                siblingRange={1}
                totalPages={this.state.totalPages}
                onPageChange={this.pageChange}
                activePage={this.state.paginaAtual}
                disabled={this.state.listaRemissaoSlice.length === 0}
              />
            </Container>
          </div>
        }
      </div>
    );
  }
}
