import React, { Component } from "react";
import { Modal, Header, Icon, Button } from "semantic-ui-react";

import moment from "moment";

export default class ModalRestricaoDiasGrupo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal
        closeIcon
        size="mini"
        open={this.props.open}
        onClose={this.props.onClose}
        style={{ textAlign: "center" }}
      >
        <Header icon>
          <Icon name="warning circle" />
          {"Já foi reservado um serviço deste grupo serviço "}
          {this.props.restricaoDiasGrupo?.dias_frequencia === 1
            ? "hoje."
            : `nos ultimos ${this.props.restricaoDiasGrupo?.dias_frequencia} dias.`}
        </Header>
        <Modal.Content>
          <p>
            Você reservou{" "}
            {
              this.props.restricaoDiasGrupo?.reservas[0]?.horario_servico
                .servico.nome
            }{" "}
            para o dia{" "}
            {moment(
              this.props.restricaoDiasGrupo?.reservas[0]?.dataReserva
            ).format("DD/MM/YYYY")}
            .
          </p>
          <p>
            É necessário aguardar até o dia{" "}
            {moment(this.props.restricaoDiasGrupo?.reservas[0]?.dataReserva)
              .add(this.props.restricaoDiasGrupo?.dias_frequencia, "days")
              .format("DD/MM/YYYY")}
            .
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.onClose}>Entendi</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
