// Componentes bibliotecas
import React, { Component } from "react";
import {
  Header,
  Button,
  Divider,
  Segment,
  Dropdown,
  Input,
  Table,
  Card,
  Modal,
  List,
  Message,
  Transition,
  Checkbox,
  FormField,
} from "semantic-ui-react";

import { Col, Form, FormControl, Row } from "react-bootstrap";
import Select from "react-select";

import { withRouter } from "react-router-dom";

// Componentes criados
import ModalAvisoExportacaoGerada from "./modalAvisoExportacaoGerada";
import ModalLiquidarContaAVista from "./modalLiquidarContaAVista";

// Utils
import renomearChave from "../../../services/renomearChave";
import renomear2Chaves from "../../../services/renomear2Chaves";
import renomear4Chaves from "../../../services/renomear4Chaves";
import { addMonths, getYear, getMonth, getDate } from "date-fns";
import moment from "moment-timezone";
import $ from "jquery";
import "jquery-mask-plugin/dist/jquery.mask.min";
import { parseISO, format } from "date-fns";

// Enum
import TipoLancamento from "../../../enum/TipoLancamento";
import CreditoDebito from "../../../enum/CreditoDebito";

// Requisição
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";
var formatter = new Intl.NumberFormat("pt-br", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const optionsTipoContabilizacao = [
  { value: "emissao", label: "Emissão" },
  { value: "vencimento", label: "Vencimento" },
  { value: "baixa", label: "Baixa" },
];

class ContasAPagar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      pessoa: null,
      pessoa_id: null,
      data: moment().format("YYYY-MM-DD"),
      documento: null,
      valor: "0.00,00",
      data_vencimento: addMonths(
        parseISO(moment().tz("America/Sao_Paulo").format()),
        1
      ),
      infoParcelas: [],
      forma_pagamento: null,
      contas_a_pagar: null,
      historico_contas_a_pagar: [],
      observacao: null,
      tipo_documento: null,
      portador: null,
      optionsTD: [
        { key: "notaFiscal", value: "notaFiscal", text: "Nota Fiscal" },
        { key: "recibo", value: "recibo", text: "Recibo" },
      ],
      optionsP: [
        { key: "carteira", value: "carteira", text: "Carteira" },
        { key: "banco", value: "banco", text: "Banco" },
      ],
      itens: [],
      visualizar: true,
      centro_de_custo: null,
      centro_de_custo_id: null,
      elemento_de_custo: null,
      elemento_de_custo_id: null,
      ordem_pagamento: null,
      ordem_pagamento_id: null,
      somaParcelas: 0,
      valorRestante: 0,
      qntParcelas: null,
      quantidadeParcelas: null,
      subtraiParcelas: null,
      tamanhoLista: null,
      tipo: "nome",
      alterado_valor: false,
      optionsCC: [],
      imprimir: false,
      usuarioLogado: null,
      hora: null,
      min: null,
      open: false,
      salvando: false,
      mensagem: null,
      contabilizar: true,
      tipo_contabilizacao: null,
      tipoDocumento: false,

      // ModalOpen
      modalAvisoExportacaoGeradaOpen: false,
      modalLiquidarContaAVistaOpen: false,
    };
    this.alteracao = this.alteracao.bind(this);
    this.geraParcelas = this.geraParcelas.bind(this);
    this.alteracaoSelectTipoDocumento = this.alteracaoSelectTipoDocumento.bind(
      this
    );
    this.alteracaoSelectPortador = this.alteracaoSelectPortador.bind(this);
    this.buscaOP();
    this.valorRestante = this.valorRestante.bind(this);
    this.diminuiTotal = this.diminuiTotal.bind(this);
    this.alteraValorParcelas = this.alteraValorParcelas.bind(this);
    this.visualizacao();
    this.enter = this.enter.bind(this);
  }

  componentMoney() {
    $(".dinheiro").mask("#.##0,00", {
      reverse: true,
      placeholder: "0,00",
    });
  }

  enter(event) {
    if (event.key === "Enter") {
      // event.preventDefault();
      // event.stopPropagation();
      const indice = event.target.name.toString().substring(9, 100);
      for (let i = 0; i < this.state.itens.length; i++) {
        if (i == parseInt(indice)) {
          this.state.itens[i].valor = parseFloat(
            event.target.value.replace(".", "").replace(",", ".")
          );
        }
      }
      let totalItens = 0;
      for (let i = 0; i < this.state.itens.length; i++) {
        totalItens = totalItens + parseFloat(this.state.itens[i].valor);
      }
      let aux = 0;
      if (this.state.id != null) {
        aux =
          parseFloat(this.state.valor.replace(",", ".")) -
          parseFloat(totalItens);
      } else {
        aux =
          parseFloat(this.state.valor.replace(".", "").replace(",", ".")) -
          parseFloat(totalItens);
      }
      this.setState({
        valorRestante: aux.toLocaleString("pt-br", {
          minimumFractionDigits: 2,
        }),
      });
      setTimeout(() => {
        this.semImprimirSubmit(event);
      }, 500);
    }
  }

  diminuiTotal(event) {
    // this.setState({valorRestante: this.state.valor})
    const indice = event.target.name.toString().substring(9, 100);
    for (let i = 0; i < this.state.itens.length; i++) {
      if (i === parseInt(indice)) {
        this.state.itens[i].valor = parseFloat(
          event.target.value.replace(".", "").replace(",", ".")
        );
      }
    }
    let totalItens = 0;
    for (let i = 0; i < this.state.itens.length; i++) {
      totalItens = totalItens + parseFloat(this.state.itens[i].valor);
    }
    let aux = 0;

    if (
      this.state.valor.indexOf(".") !== -1 &&
      this.state.valor.indexOf(",") !== -1
    ) {
      aux =
        parseFloat(this.state.valor.replace(".", "").replace(",", ".")) -
        parseFloat(totalItens);
    } else {
      aux =
        parseFloat(this.state.valor.replace(",", ".")) - parseFloat(totalItens);
    }
    // if (this.state.id !== null) {
    //   console.log("this.state.valor: ", this.state.valor);
    //   aux =
    //     parseFloat(this.state.valor.replace(".", "").replace(",", ".")) -
    //     parseFloat(totalItens);
    //   console.log(aux);
    // } else {
    //   aux =
    //     parseFloat(this.state.valor.replace(".", "").replace(",", ".")) -
    //     parseFloat(totalItens);
    //   console.log(aux);
    // }
    this.setState({
      valorRestante: aux.toLocaleString("pt-br", { minimumFractionDigits: 2 }),
    });
  }

  buscaOP = async () => {
    if (this.props.location.query === undefined) {
      const retorno = await api.post(
        "/ordemPagamento/findLast" + requisicoes.entidade,
        "",
        requisicoes.header
      );
      // this.setState({ ordem_pagamento_id: retorno.data.id });
      this.setState({ ordem_pagamento: retorno.data[0].codigo + 1 });
    }
  };

  alteracao(event) {
    this.setState({ [event.target.name]: event.target.value });
    if (
      event.target.name === "quantidade_parcelas" ||
      (event.target.name === "forma_pagamento" &&
        event.target.value === "aVista")
    ) {
      this.geraParcelas(event);
    }
    if (event.target.name === "valor") {
      this.setState({ alterado_valor: true });
    }
  }

  valorRestante(event) {
    this.setState({ valorRestante: event.target.value });
    this.setState({ valor: event.target.value });
    this.setState({
      subtraiParcelas: parseFloat(event.target.value.replace(".", "")).toFixed(
        2
      ),
    });
  }

  visualizacao = async () => {
    this.setState({ data: null });
    if (this.props.location.query !== undefined) {
      if (this.props.location.query.visualiza === false) {
        this.state.visualizar = false;
      }

      await api
        .post(
          "/contasAPagar/visualiza" + requisicoes.entidade,
          this.props.location.query,
          requisicoes.header
        )
        .then(async (resposta) => {
          const tipo_contabilizacao = resposta.data.tipo_contabilizacao;
          resposta.data.tipo_contabilizacao = undefined;
          this.setState(resposta.data);
          this.setState({
            contas_a_pagar: resposta.data,
            ordem_pagamento: resposta.data.ordem_pagamento.codigo,
            data: resposta.data.data,
            tipo_contabilizacao: optionsTipoContabilizacao.find(
              (e) => e.value === tipo_contabilizacao
            ),
          });
          let parcelas = [];
          resposta.data.lancamento_contas.forEach((e, i) => {
            let id = null;
            if (e.id != null) {
              id = e.id;
            }
            parcelas.push({
              id: id,
              numero: i + 1,
              valor: e.valor,
              data_vencimento: e.data_vencimento,
            });
          });
          this.setState({ infoParcelas: parcelas });
          this.setState({ qntParcelas: parcelas.length });
          this.setState({ itens: resposta.data.contas_rateio });
          this.state.itens.forEach((e) => {
            let o = renomearChave(e.centro_de_custo, "id", "value");
            o = renomearChave(o, "descricao", "label");
            this.setState({ centro_de_custo_id: e.centro_de_custo_id });
            this.setState({ centro_de_custo: o });

            let c = renomearChave(e.elemento_de_custo, "id", "value");
            c = renomearChave(c, "descricao", "label");
            this.setState({ elemento_de_custo_id: e.elemento_de_custo_id });
            this.setState({ elemento_de_custo: c });
          });
          await api
            .post(
              "/pessoa/pessoaIdNome" + requisicoes.entidade,
              { id: resposta.data.pessoa_id },
              requisicoes.header
            )
            .then((resp) => {
              let p = renomearChave(resp.data, "id", "value");
              p = renomearChave(p, "nome", "label");
              this.setState({ pessoa: p });
              this.setState({ pessoa_id: p.value });
              this.setState({ tipo_documento: resposta.data.tipo_documento });
              this.setState({ portador: resposta.data.portador });
            });
        });
    }
  };

  pesquisaPessoa = (newValue) => {
    api
      .post(
        "/pessoa/pesquisaContasAPagar" + requisicoes.entidade,
        { pesquisa: newValue },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomear4Chaves(
            objeto,
            "reduzido",
            "nome",
            "cnpj",
            "nomefantasia",
            "label"
          );

          objeto.isDisabled = objeto.conta_contabil_id === null;

          lista.push(objeto);
        });
        this.setState({ options: lista });
      });
  };

  pesquisaCentroCusto = async (event) => {
    let pesquisa = "";
    if (event.target.value != null) {
      pesquisa = event.target.value;
    }
    const retorno = await api
      .post(
        "/centroDeCusto/pesquisa" + requisicoes.entidade,
        { pesquisa: pesquisa },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomear2Chaves(objeto, "codigo", "descricao", "label");
          lista.push(objeto);
        });
        this.setState({ optionsCC: lista });
      });
  };

  pesquisaElementoCusto = async (event) => {
    let pesquisa = "";
    if (event.target.value != null) {
      pesquisa = event.target.value;
    }
    const retorno = await api
      .post(
        "/elementoDeCusto/pesquisaByContaContabil" + requisicoes.entidade,
        {
          pesquisa: pesquisa,
          centroCusto: this.state.centro_custo_id,
          cad: false,
        },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomear2Chaves(objeto, "codigo", "descricao", "label");
          lista.push(objeto);
        });
        this.setState({ optionsEC: lista });
      });
  };

  alteracaoSelectPessoa = async (event) => {
    this.setState({ pessoa_id: event.value });
    this.setState({ pessoa: event });
  };

  alteracaoSelectCentroCusto = async (event, id) => {
    this.setState({ centro_de_custo_id: event.value });
    this.setState({ centro_de_custo: event });
    for (let i = 0; i < this.state.itens.length; i++) {
      if (i == id.name) {
        this.state.itens[i].centro_de_custo_id = event.value;
      }
    }
    api
      .post(
        "/centroDeCusto/visualiza" + requisicoes.entidade,
        { id: event.value },
        requisicoes.header
      )
      .then((resp) => {
        let lista0 = [];
        if (resp.data.elementos.length > 0) {
          resp.data.elementos.forEach((e) => {
            let objeto = renomearChave(e, "id", "value");
            objeto = renomear2Chaves(objeto, "codigo", "descricao", "label");
            lista0.push(objeto);
          });
          this.setState({ optionsEC: lista0 });
        }
      });
  };

  alteracaoSelectElementoCusto = async (event, id) => {
    this.setState({ elemento_de_custo_id: event.value });
    this.setState({ elemento_de_custo: event });
    for (let i = 0; i < this.state.itens.length; i++) {
      if (i == id.name) {
        this.state.itens[i].elemento_de_custo_id = event.value;
      }
    }
  };

  geraParcelas = (event) => {
    this.setState({
      subtraiParcelas: this.state.valor.replace(".", "").replace(",", "."),
    });

    let parcelas = [];
    const numeroParcela = event.target.value.toString().replace("x", "");
    if (event.target.value == "aVista") {
      let data = null;
      if (this.state.data != null) {
        data = addMonths(parseISO(this.state.data), 2);
      } else {
        data = addMonths(parseISO(moment().format()), 1);
      }
      const mes = getMonth(data) == 0 ? 12 : getMonth(data);
      const ano = getMonth(data) == 0 ? getYear(data) - 1 : getYear(data);
      parcelas.push({
        numero: "Única",
        valor: this.state.valor,
        data_vencimento:
          ano +
          "-" +
          (mes.toString().length === 1 ? "0" + mes : mes) +
          "-" +
          (getDate(data).toString().length === 1
            ? "0" + getDate(data)
            : getDate(data)),
      });
      this.setState({ infoParcelas: parcelas });
    } else {
      for (var i = 2; i < parseInt(numeroParcela) + 2; i++) {
        let data = null;
        if (this.state.data !== null) {
          data = addMonths(parseISO(this.state.data), i);
        } else {
          data = addMonths(
            parseISO(moment().tz("America/Sao_Paulo").format()),
            i
          );
        }
        const mes = getMonth(data) === 0 ? 12 : getMonth(data);
        const ano = getMonth(data) === 0 ? getYear(data) - 1 : getYear(data);

        parcelas.push({
          numero: i - 1,
          valor: parseFloat(
            this.state.valor.replace(".", "").replace(",", ".") /
              parseInt(numeroParcela)
          ).toFixed(2),
          data_vencimento:
            ano +
            "-" +
            (mes.toString().length === 1 ? "0" + mes : mes) +
            "-" +
            (getDate(data).toString().length === 1
              ? "0" + getDate(data)
              : getDate(data)),
        });

        this.setState({ infoParcelas: parcelas });
        this.setState({ tamanhoLista: parcelas.length });
      }
    }
  };

  alteraValorInput = async (event) => {
    let lista = [];
    const indice = event.target.id.toString().substring(12, 20);
    lista = this.state.infoParcelas;

    lista.splice(parseInt(indice), 1, {
      id: lista[parseInt(indice)].id,
      numero: parseInt(indice) + 1,
      valor: event.target.value,
      data_vencimento: lista[parseInt(indice)].data_vencimento,
    });

    if (lista.find((e) => e.valor.toString() === "NaN")) {
      lista.forEach((element) => {
        if (element.valor.toString() === "NaN") {
          element.valor = "";
        }
      });
    }

    this.setState({ infoParcelas: lista });
  };

  alteraValorParcelas = (event) => {
    let valorCampoParcela = parseInt(
      event.target.id.toString().replace("valorParcela", "")
    );
    let listaParcelas = [];
    const indice = event.target.id.toString().substring(12, 20);
    listaParcelas = this.state.infoParcelas;
    let operacao = 0;
    let sizeLista = 0;
    let soma = 0;
    for (let i = 0; i <= valorCampoParcela; i++) {
      const element = listaParcelas[i];
      soma += parseFloat(element.valor);
    }
    if (listaParcelas[valorCampoParcela + 1]) {
      let diferenca =
        parseFloat(this.state.valor.replace(".", "").replace(",", ".")) - soma;

      for (let i = valorCampoParcela + 1; i < listaParcelas.length; i++) {
        const element = listaParcelas[i];

        element.valor = (
          diferenca /
          (listaParcelas.length - (valorCampoParcela + 1))
        ).toFixed(2);
      }
    }

    // todos: estava com problema para salvar devido as casas decimais arrumado
    // if (indice == 0) {
    //   this.setState({
    //     subtraiParcelas: parseFloat(
    //       parseFloat(this.state.valor.replace(".", "").replace(",", ".")) -
    //         parseFloat(event.target.value.replace(".", "").replace(",", "."))
    //     ),
    //   });
    //   operacao = parseFloat(
    //     parseFloat(this.state.valor.replace(".", "").replace(",", ".")) -
    //       parseFloat(event.target.value.replace(".", "").replace(",", "."))
    //   );
    //   this.setState({ tamanhoLista: this.state.infoParcelas.length });
    //   sizeLista = this.state.infoParcelas.length;
    // } else {
    //   this.setState({
    //     subtraiParcelas: parseFloat(
    //       parseFloat(this.state.subtraiParcelas) -
    //         parseFloat(event.target.value.replace(".", "").replace(",", "."))
    //     ),
    //   });
    //   operacao = parseFloat(
    //     parseFloat(this.state.subtraiParcelas) -
    //       parseFloat(event.target.value.replace(".", "").replace(",", "."))
    //   );
    // }
    // for (let i = 0; i < lista.length; i++) {
    //   if (i != indice && i > indice) {
    //     let valor = 0;
    //     if (sizeLista == 0) {
    //       valor = parseFloat(operacao / parseInt(sizeLista + 1))
    //         .toFixed(2)
    //         .replace(".", ",");
    //     } else {
    //       valor = parseFloat(operacao / parseInt(parseInt(sizeLista) - 1))
    //         .toFixed(2)
    //         .replace(".", ",");
    //     }
    //     lista.splice(parseInt(i), 1, {
    //       id: lista[i].id,
    //       numero: parseInt(i) + 1,
    //       valor: valor != Infinity ? valor : 0,
    //       data_vencimento: lista[i].data_vencimento,
    //     });
    //   }
    // }
    this.setState({ infoParcelas: listaParcelas });
    this.setState({ tamanhoLista: this.state.tamanhoLista - 1 });
  };

  alteraDataVencimentoParcelas = (event) => {
    let lista = [];
    const indice = event.target.id.toString().substring(20, 30);
    lista = this.state.infoParcelas;
    lista.splice(parseInt(indice), 1, {
      id: lista[parseInt(indice)].id,
      numero: parseInt(indice) + 1,
      valor: lista[parseInt(indice)].valor,
      data_vencimento: event.target.value,
    });

    if (lista.find((e) => e.valor.toString() === "NaN")) {
      lista.forEach((element) => {
        if (element.valor.toString() === "NaN") {
          element.valor = "";
        }
      });
    }
    this.setState({ infoParcelas: lista });
  };

  alteracaoSelectPortador = async (event, id) => {
    this.setState({ portador: id.value });
  };

  alteracaoSelectTipoDocumento = async (event, id) => {

    if (id.value === "recibo") {
      this.state.tipo_contabilizacao ={value: 'emissao', label: 'Emissão'}
      this.setState({tipoDocumento: true})
      this.setState({ tipo_documento: id.value });
      this.setState({ tipo_contabilizacao: this.state.tipo_contabilizacao });


      }else {

        this.state.tipo_contabilizacao ={value: 'emissao', label: 'Emissão'}
        this.setState({tipoDocumento: false})
        this.setState({ tipo_documento: id.value });

        this.setState({ tipo_contabilizacao: this.state.tipo_contabilizacao });
      }

    // this.state.tipo_contabilizacao

  };

  addItem = async (event) => {
    const item = { centro_de_custo_id: null, elemento_de_custo_id: null };

    let lista = this.state.itens;
    lista.push(item);
    // this.state.itens.push(item);
    this.setState({ itens: lista });
    this.state.item = "";
    this.setState(this.state);
  };

  removeItem = async (event) => {
    if (event.target != null) {
      const retorno = await api.post(
        "/contasAPagar/removeRateio" + requisicoes.entidade,
        {
          rateio: event.target.id,
        },
        requisicoes.header
      );
      alert(retorno);
    }
    let lista = this.state.itens;
    lista.splice(lista.indexOf(1), 1);
    this.setState({ itens: lista });
  };

  verificaCampos = () => {
    const s = this.state;

    //Verifica a forma de pagamento
    if (
      s.forma_pagamento === null ||
      (s.forma_pagamento !== "aVista" && s.forma_pagamento !== "prazo")
    ) {
      this.setState({
        salvando: false,
        mensagem: (
          <Message
            negative
            header="Dados inválidos: Forma de pagamento"
            content="Favor selecionar uma foma de pagamento."
          />
        ),
      });
      setTimeout(() => {
        this.setState({ mensagem: null });
      }, 5000);
      return false;
    }

    //Verifica o centro e elemento de custo dos itens
    for (let i = 0; i < this.state.itens.length; i++) {
      const element = this.state.itens[i];
      if (
        element.centro_de_custo_id === null ||
        element.elemento_de_custo_id === null
      ) {
        this.setState({
          salvando: false,
          mensagem: (
            <Message
              negative
              header={
                element.centro_custo_id === null
                  ? "Dados inválidos: Centro de custo"
                  : "Dados inválidos: Elemento de custo"
              }
              content="Favor selecionar uma opção para o campo."
            />
          ),
        });
        setTimeout(() => {
          this.setState({ mensagem: null });
        }, 5000);
        return false;
      }
    }

    //Verifica a pessoa, tipo_documento, portador
    if (
      this.state.pessoa === null ||
      this.state.pessoa_id === null ||
      this.state.tipo_documento === null ||
      this.state.portador === null
    ) {
      let mensagemVar = "";

      if (this.state.pessoa === null || this.state.pessoa_id === null)
        mensagemVar = "Dados inválidos: Pessoa";
      if (this.state.tipo_documento === null)
        mensagemVar = "Dados inválidos: Tipo do Documento";
      if (this.state.portador === null)
        mensagemVar = "Dados inválidos: Portador";

      this.setState({
        salvando: false,
        mensagem: (
          <Message
            negative
            header={mensagemVar}
            content="Favor selecionar uma opção para o campo."
          />
        ),
      });
      setTimeout(() => {
        this.setState({ mensagem: null });
      }, 5000);
      return false;
    }

    //verifica documento,
    if (this.state.documento === null) {
      let mensagemVar = "";
      if (this.state.documento === null)
        mensagemVar = "Dados inválidos: Documento";

      this.setState({
        salvando: false,
        mensagem: (
          <Message
            negative
            header={mensagemVar}
            content="Campo vazio. Favor completar o campo."
          />
        ),
      });
      setTimeout(() => {
        this.setState({ mensagem: null });
      }, 5000);
      return false;
    }

    if (this.state.contabilizar && this.state.tipo_contabilizacao === null) {
      this.setState({
        salvando: false,
        mensagem: (
          <Message
            negative
            header={"Dados inválidos: Tipo de Contabilização"}
            content="Um tipo de contabilização é necessária para a opção contabilizar estar ativada."
          />
        ),
      });
      setTimeout(() => {
        this.setState({ mensagem: null });
      }, 5000);
      return false;
    }

    return true;
  };

  verificaDataExportacaoContabil = async (data) => {
    const maxDataFinal = await api.post(
      "/exportacaoContabil/maiorDataFinalRemessaGeradaTipo" +
        requisicoes.entidade,
      { arquivo: "ContasAPagar" },
      requisicoes.header
    );
    if (maxDataFinal.data && maxDataFinal.data >= data) {
      return false;
    } else {
      return true;
    }
  };

  semImprimirSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      salvando: true,
      imprimir: false,
    });
    if (this.verificaCampos()) {
      if (
        this.state.contas_a_pagar !== null ||
        (await this.verificaDataExportacaoContabil(this.state.data))
      ) {
        this.submit();
      } else {
        this.setState({
          modalAvisoExportacaoGeradaOpen: true,
          salvando: false,
        });
      }
    }
  };

  submit = async () => {
    try {
      const c = this.state;
      this.setState({ somaParcelas: 0 });

      let retorno = null;
      let total = 0;
      if (this.state.contas_a_pagar != null) {
        if (this.state.alterado_valor === true) {
          total = c.valor.replace(".", "").replace(",", ".");
        } else {
          total = c.valor;
        }
      } else {
        total = c.valor.replace(".", "").replace(",", ".");
      }
      const dados = {
        pessoa_id: c.pessoa_id,
        data: c.data,
        documento: c.documento,
        valor: total,
        forma_pagamento: c.forma_pagamento,
        observacao: c.observacao.toString().replace('°', '').replace('º', ''),
        tipo_documento: c.tipo_documento,
        portador: c.portador,
        ordem_pagamento: c.ordem_pagamento,
        contabilizar: c.contabilizar,
        tipo_contabilizacao: c.tipo_contabilizacao
          ? c.tipo_contabilizacao.value
          : "nao_contabilizado",
      };

      let lista = [];
      for (let i = 0; i < c.itens.length; i++) {
        c.itens[i].valor = document
          .getElementById("itens[" + i + "].valor")
          .value.replace(".", "")
          .replace(",", ".");
      }
      if (this.state.id === null) {
        this.state.infoParcelas.forEach((e, i) => {
          let v = e.valor;
          v = v.replace(",", ".");
          lista.push({
            descricao: "Cobrança Fornecedor",
            data_emissao: moment().format("YYYY-MM-DD"),
            tipo_lancamento: TipoLancamento.find((t) => t.value === "contaAPagar")
              .value,
            valor: v,
            data_vencimento: e.data_vencimento,
            credito_debito: CreditoDebito.find((c) => c.value === "debito").value,

            pessoa_id: this.state.pessoa_id,
            numero_parcela: i + 1,
          });

          this.state.somaParcelas =
            (parseFloat(this.state.somaParcelas) + parseFloat(e.valor)).toFixed(2);
        });

        this.state.somaParcelas = parseFloat(this.state.somaParcelas).toFixed(2);
        let totalItens = 0;
        for (let i = 0; i < this.state.itens.length; i++) {
          totalItens = totalItens + parseFloat(this.state.itens[i].valor);
        }
        let somaParcelas = parseFloat(this.state.somaParcelas).toFixed(2);

        if (
          this.state.valorRestante === "0,00" ||
          this.state.valorRestante === "-0,00"
        ) {
          if (somaParcelas === total) {
            api.post(
                "/contasAPagar/salvarCompleta" + requisicoes.entidade,
                {
                  contas_a_pagar: dados,
                  lancamentos: lista,
                  rateios: this.state.itens,
                  ordem_pagamento: c.ordem_pagamento,
                  // ordem_pagamento_id: null,
                },
                requisicoes.header
              )
              .then(async (resp) => {

                this.setState({ contas_a_pagar: resp.data });

                if (
                  resp.data.ordem_pagamento.codigo !== this.state.ordem_pagamento
                ) {
                  alert(
                    "A OP " +
                      this.state.ordem_pagamento +
                      " já foi utilizada por outro usuário. \n" +
                      "Redirecionamos esta OP para o número " +
                      resp.data.ordem_pagamento.codigo +
                      "."
                  );
                }

                if (this.state.imprimir === true) {
                  await this.gerarOrdemPagamento().catch((err) => {
                    console.log("Deu erro: " + err);
                  });
                }

                if (this.state.forma_pagamento === "aVista") {
                  this.setState({ modalLiquidarContaAVistaOpen: true });
                } else {
                  setTimeout(async () => {
                    localStorage.setItem("pagina_anterior", "contasAPagar");
                    this.props.history.push("/financeiro");
                  });
                }
              })
              .catch((err) => {
                if (err !== undefined && err.erro) {
                  alert(err.erro);
                } else {
                  alert(
                    "Ocorreu um erro ao salvar! Verifique os dados e tente novamente."
                  );
                }
                this.setState({ salvando: false });
              });
          } else {
            alert(
              "Atenção a soma das parcelas não corresponde ao valor total! R$ " +
                this.state.somaParcelas
            );
            this.setState({ salvando: false });
            this.setState({ somaParcelas: 0 });
          }
        } else {
          if (
            this.state.valorRestante.toString() !== "0,00" &&
            this.state.valorRestante.toString() !== "-0,00"
          ) {
            alert("Atenção o rateio não corresponde ao valor total!");
          } else {
            alert(
              "Atenção a soma das parcelas não corresponde ao valor total! R$ " +
                this.state.somaParcelas
            );
          }
          this.setState({ salvando: false });
          this.setState({ somaParcelas: 0 });
        }
      } else {
        api.post(
            "/contasAPagar/editar" + requisicoes.entidade,
            {
              contas_a_pagar: dados,
              rateios: this.state.itens,
              lancamentos: this.state.infoParcelas,
              id: this.state.id,
            },
            requisicoes.header
          )
          .then(() => {
            if (this.state.imprimir === true) {
              this.gerarOrdemPagamento();
              setTimeout(async () => {
                localStorage.setItem("pagina_anterior", "contasAPagar");
                window.location.href = "/financeiro";
              }, 5000);
            } else {
              localStorage.setItem("pagina_anterior", "contasAPagar");
              window.location.href = "/financeiro";
            }
          })
          .catch((err) => {
            alert(
              "Ocorreu um erro ao editar o registro! Verifique os dados e tente novamente."
            );
            this.setState({ salvando: false });
          });
      }
    } catch(err) {
      console.log('contasAPagar/submit error:\n' + JSON.stringify(err));
      alert('Ocorreu um erro ao salvar');
      this.setState({ salvando: false });
    }
  };
  gerarPdfOrdemPagamento = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const r = await api
      .get("contasAPagar/buscar?e=" + entidade + "&t=Baer " + token)
      .then((resp) => {
        var oReq = new XMLHttpRequest();

        var URLToPDF =
          resp.config.baseURL +
          "contasAPagar/buscar?e=" +
          entidade +
          "&t=Baer " +
          token;

        oReq.open("GET", URLToPDF, true);

        oReq.responseType = "blob";

        oReq.onload = function () {
          const file = new Blob([oReq.response], { type: "application/pdf" });

          const fileURL = URL.createObjectURL(file);

          window.open(fileURL, "_blank");
        };
        oReq.send();
      })
      .catch((err) => {
        console.log("Deu erro: " + err);
      });
  };
  gerarOrdemPagamento = async () => {
    let dados = [this.state.contas_a_pagar.id];
    await api.post(
      "/contasAPagar/gerar" + requisicoes.entidade,
      dados,
      requisicoes.header
    );
    await this.gerarPdfOrdemPagamento();
  };

  pesquisaPessoaReduzido = async () => {
    setTimeout(async () => {
      const retorno = await api.post(
        "/pessoa/pesquisaReduzidoFornecedor" + requisicoes.entidade,
        { pesquisa: document.getElementById("pesquisaPessoaReduzido").value },
        requisicoes.header
      );
      let lista = [];
      retorno.data.forEach((element) => {
        if (
          !isNaN(
            parseFloat(document.getElementById("pesquisaPessoaReduzido").value)
          ) &&
          isFinite(document.getElementById("pesquisaPessoaReduzido").value)
        ) {
          if (element.reduzido !== null) {
            let objeto = renomearChave(element, "id", "value");
            objeto = renomearChave(objeto, "reduzido", "label");
            objeto.label = objeto.label + " - " + objeto.nome;
            lista.push(objeto);
          }
        }
      });
      this.setState({ options: lista });
    }, 500);
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Contas A Pagar
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={6}>
              <Form.Label>Pessoa</Form.Label>
              <Select
                components={{}}
                placeholder="Digite para buscar"
                name="pessoa"
                value={this.state.pessoa}
                onChange={this.alteracaoSelectPessoa}
                options={this.state.options}
                onInputChange={this.pesquisaPessoa}
                inputId="pesquisaPessoa"
                isDisabled={this.state.visualizar !== true}
                // isDisabled={this.state.contas_a_pagar !== null}
              />
            </Col>
            <Col md="auto">
              <Form.Label>Data</Form.Label>
              <FormControl
                placeholder="Data"
                type="date"
                name="data"
                onChange={this.alteracao}
                value={this.state.data}
                disabled={this.state.contas_a_pagar != null}
              />
            </Col>
            <Col fluid >
              <Form.Label>Tipo de Documento</Form.Label>
              <Dropdown
                placeholder="Tipo Documento"
                fluid
                selection
                name="tipo_documento"
                options={this.state.optionsTD}
                onChange={this.alteracaoSelectTipoDocumento}
                value={this.state.tipo_documento}
                disabled={this.state.visualizar !== true}
              />
            </Col>

            <Col sm={4}>
              <Form.Label>Tipo de contabilização</Form.Label>
              <FormField
                control={Select}
                isDisabled={
                  (!this.state.contabilizar, this.state.visualizar !== true,this.state.tipoDocumento ===false)
                }
                options={optionsTipoContabilizacao}
                value={this.state.tipo_contabilizacao}
                onChange={(e, a) => this.setState({ tipo_contabilizacao: e })}
                placeholder={"Selecione o tipo"}
              />
            </Col>
            <Col style={{marginTop: "10px", marginLeft: "35px"}}>
              <Form.Label>Contabilizar</Form.Label>
              <FormField
                control={Checkbox}
                checked={this.state.contabilizar}
                toggle
                onChange={(e, a) => {
                  this.setState({ contabilizar: a.checked });
                  if (a.checked === false)
                    this.setState({ tipo_contabilizacao: null });
                }}
                disabled={this.state.visualizar !== true}
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Documento</Form.Label>
              <FormControl
                placeholder="Documento"
                name="documento"
                onChange={this.alteracao}
                required
                defaultValue={
                  this.state.contas_a_pagar !== null
                    ? this.state.contas_a_pagar.documento
                    : ""
                }
                fluid
                readOnly={this.state.contas_a_pagar !== null}
                disabled={this.state.visualizar !== true}
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Portador</Form.Label>
              <Dropdown
                placeholder="Portador"
                fluid
                selection
                name="portador"
                options={this.state.optionsP}
                onChange={this.alteracaoSelectPortador}
                value={this.state.portador}
                disabled={this.state.visualizar !== true}
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Valor</Form.Label>
              <FormControl
                placeholder="Valor"
                aria-describedby="basic-addon1"
                name="valor"
                id="valor"
                className="dinheiro"
                required
                onKeyUp={this.componentMoney}
                onChange={this.alteracao}
                onBlur={this.valorRestante}
                defaultValue={
                  this.state.contas_a_pagar !== null
                    ? formatter.format(this.state.contas_a_pagar.valor)
                    : ""
                }
                disabled={this.state.visualizar !== true}
                // readOnly={this.state.contas_a_pagar !== null}
                // disabled={
                //   this.state.contas_a_pagar !== null}
              />
            </Col>
            <Col sm={12}>
              <Form.Label>Observação</Form.Label>
              <FormControl
                placeholder="Observação"
                aria-describedby="basic-addon1"
                as="textarea"
                name="observacao"
                onChange={this.alteracao}
                defaultValue={
                  this.state.contas_a_pagar !== null
                    ? this.state.contas_a_pagar.observacao
                    : ""
                }
                disabled={this.state.visualizar !== true}
              />
            </Col>
            <Col sm={3}>
              <Form.Label>Nº Ordem de Pagamento 1</Form.Label>
              <FormControl
                placeholder="nº O.P."
                aria-describedby="basic-addon1"
                name="ordem_pagamento"
                required
                onChange={this.alteracao}
                defaultValue={this.state.ordem_pagamento}
                readOnly
                disabled={this.state.visualizar !== true}
              />
            </Col>
            <Col sm={3}>
              <Form.Label>Forma de Pagamento</Form.Label>
              <Form.Control
                as="select"
                name="forma_pagamento"
                onChange={this.alteracao}
                required
                readOnly={this.state.contas_a_pagar !== null}
                disabled={this.state.visualizar !== true}
              >
                <option></option>
                {this.state.contas_a_pagar !== null &&
                this.state.contas_a_pagar.forma_pagamento === "aVista" ? (
                  <option value="aVista" selected>
                    Á Vista
                  </option>
                ) : (
                  <option value="aVista">Á Vista</option>
                )}
                {this.state.contas_a_pagar !== null &&
                this.state.contas_a_pagar.forma_pagamento === "prazo" ? (
                  <option value="prazo" selected>
                    Prazo
                  </option>
                ) : (
                  <option value="prazo">Prazo</option>
                )}
              </Form.Control>
            </Col>

            {this.state.forma_pagamento === "prazo" && (
              <Col sm={3}>
                <Form.Label>Quantidade Parcelas</Form.Label>
                <Form.Control
                  as="select"
                  name="quantidade_parcelas"
                  onChange={this.alteracao}
                  required
                  disabled={
                    (this.state.contas_a_pagar !== null,
                    this.state.visualizar !== true)
                  }
                >
                  <option></option>
                  <option
                    key="1"
                    value="1x"
                    selected={this.state.qntParcelas === 1}
                  >
                    1
                  </option>
                  <option
                    key="2"
                    value="2x"
                    selected={this.state.qntParcelas === 2}
                  >
                    2
                  </option>
                  <option
                    key="3"
                    value="3x"
                    selected={this.state.qntParcelas === 3}
                  >
                    3
                  </option>
                  <option
                    key="4"
                    value="4x"
                    selected={this.state.qntParcelas === 4}
                  >
                    4
                  </option>
                  <option
                    key="5"
                    value="5x"
                    selected={this.state.qntParcelas === 5}
                  >
                    5
                  </option>
                  <option
                    key="6"
                    value="6x"
                    selected={this.state.qntParcelas === 6}
                  >
                    6
                  </option>
                  <option
                    key="7"
                    value="7x"
                    selected={this.state.qntParcelas === 7}
                  >
                    7
                  </option>
                  <option
                    key="8"
                    value="8x"
                    selected={this.state.qntParcelas === 8}
                  >
                    8
                  </option>
                  <option
                    key="9"
                    value="9x"
                    selected={this.state.qntParcelas === 9}
                  >
                    9
                  </option>
                  <option
                    key="10"
                    value="10x"
                    selected={this.state.qntParcelas === 10}
                  >
                    10
                  </option>
                  <option
                    key="11"
                    value="11x"
                    selected={this.state.qntParcelas === 11}
                  >
                    11
                  </option>
                  <option
                    key="12"
                    value="12x"
                    selected={this.state.qntParcelas === 12}
                  >
                    12
                  </option>
                </Form.Control>
              </Col>
            )}

            {this.state.infoParcelas.map((e, i) => {
              return (
                <Col key={i} sm={6}>
                  <Row>
                    <Col sm={4}>
                      <Form.Label>Parcela {e.numero}</Form.Label>
                      <FormControl
                        value={e.valor}
                        id={"valorParcela" + i}
                        onChange={this.alteraValorInput}
                        onBlur={
                          this.state.contas_a_pagar !== null
                            ? ""
                            : this.alteraValorParcelas
                        }
                        name="valorParcela"
                        placeholder="R$"
                        type="number"
                        fluid
                        disabled={this.state.visualizar !== true}
                      />
                    </Col>
                    <Col sm={6}>
                      <Form.Label>Vencimento</Form.Label>
                      <FormControl
                        type="date"
                        defaultValue={e.data_vencimento}
                        id={"dataVencimentoTitulo" + i}
                        onChange={this.alteraDataVencimentoParcelas}
                        disabled={this.state.visualizar !== true}
                      ></FormControl>
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">Rateio</Table.HeaderCell>
                <Table.Cell
                  negative={
                    this.state.valorRestante !== null &&
                    this.state.valorRestante !== "0,00" &&
                    this.state.valorRestante !== "-0,00"
                  }
                  positive={
                    this.state.valorRestante === "0,00" ||
                    this.state.valorRestante === "-0,00"
                  }
                >
                  {this.state.valorRestante !== null
                    ? "Total: " + this.state.valorRestante
                    : "" || this.state.valorRestante === "0,00"
                    ? "OK"
                    : ""}
                </Table.Cell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.HeaderCell width={4}>Centro de Custo</Table.HeaderCell>
                <Table.HeaderCell width={4}>Elemento de Custo</Table.HeaderCell>
                <Table.HeaderCell width={2}>Valor </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="center">
                  <Button
                    positive
                    icon="add"
                    size="tiny"
                    onClick={this.addItem}
                    type="button"
                    style={{ marginTop: "7%" }}
                    disabled={this.state.visualizar !== true}
                  ></Button>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Body>
            <Table.Body>
              {this.state.itens.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell>
                    <Select
                      placeholder="Adicionar Centro de Custo"
                      name={[i]}
                      onChange={this.alteracaoSelectCentroCusto}
                      options={this.state.optionsCC}
                      onKeyDown={this.pesquisaCentroCusto}
                      isDisabled={e.centro_de_custo != null}
                      defaultInputValue={
                        e.centro_de_custo != null
                          ? e.centro_de_custo.descricao
                          : ""
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Select
                      placeholder="Adicionar Elemento de Custo"
                      name={[i]}
                      onChange={this.alteracaoSelectElementoCusto}
                      options={this.state.optionsEC}
                      // onKeyDown={this.pesquisaElementoCusto}
                      isDisabled={e.elemento_de_custo != null}
                      defaultInputValue={
                        e.elemento_de_custo != null
                          ? e.elemento_de_custo.descricao
                          : ""
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <FormControl
                      placeholder=""
                      aria-describedby="basic-addon1"
                      id={"itens[" + [i] + "].valor"}
                      control={Input}
                      name={"valorItem" + i}
                      defaultValue={
                        this.state.id != null
                          ? formatter.format(e.valor)
                          : e.valor
                      }
                      fluid
                      required
                      className="dinheiro"
                      onKeyUp={this.componentMoney}
                      onBlur={this.diminuiTotal}
                      onKeyDown={this.enter}
                      disabled={this.state.visualizar !== true}
                    />
                  </Table.Cell>
                  <Table.Cell width={1} textAlign="center">
                    <Button
                      negative
                      icon={{
                        className: "remove",
                        id: [e.id],
                        onClick: [this.removeItem],
                      }}
                      size="tiny"
                      id={e.id}
                      onClick={this.removeItem}
                      type="button"
                      style={{ marginTop: "7%" }}
                      value={i}
                      disabled={this.state.visualizar !== true}
                    ></Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Divider />

          <Transition
            visible={this.state.mensagem !== null}
            animation={"scale"}
            duration={500}
          >
            <Row>
              <Col sm={12}>
                {this.state.mensagem !== null && this.state.mensagem}
              </Col>
            </Row>
          </Transition>

          <Segment raised>
            <Row>
              <Col sm={8}>
                <Card.Group>
                  <Card>
                    {this.state.contas_a_pagar != null &&
                      this.state.historico_contas_a_pagar != 0 && (
                        <Card.Content>
                          <Button
                            floated="right"
                            size="mini"
                            icon="list"
                            color="olive"
                            onClick={() => this.setState({ open: true })}
                            type="button"
                          />
                          <Card.Meta style={{ color: "black" }}>
                            {this.state.contas_a_pagar == null &&
                            this.state.historico_contas_a_pagar <= 0
                              ? ""
                              : "Usuário: " +
                                this.state.historico_contas_a_pagar[
                                  this.state.historico_contas_a_pagar.length - 1
                                ].usuario.nome}
                          </Card.Meta>
                          <Card.Description style={{ color: "black" }}>
                            {this.state.contas_a_pagar == null
                              ? ""
                              : this.state.historico_contas_a_pagar[
                                  this.state.historico_contas_a_pagar.length - 1
                                ].usuario.entidade +
                                ": " +
                                format(
                                  parseISO(
                                    this.state.historico_contas_a_pagar[
                                      this.state.historico_contas_a_pagar
                                        .length - 1
                                    ].data
                                  ),
                                  "dd/MM/yyyy"
                                ) +
                                " " +
                                this.state.historico_contas_a_pagar[
                                  this.state.historico_contas_a_pagar.length - 1
                                ].hora +
                                " hs"}
                          </Card.Description>
                        </Card.Content>
                      )}
                  </Card>
                </Card.Group>
              </Col>
              <Col sm={4}>
                <Header floated="right">
                  <Button
                    type="button"
                    onClick={() => {
                      localStorage.setItem("pagina_anterior", "contasAPagar");
                      this.props.history.push("/financeiro");
                    }}
                  >
                    Voltar
                  </Button>
                  {this.state.contas_a_pagar !== null && (
                    <Button
                      type="button"
                      class="ui primary button"
                      style={{ color: "white", background: "#2185d0" }}
                      onClick={this.gerarOrdemPagamento}
                      loading={this.state.salvando}
                      disabled={this.state.salvando}
                    >
                      Imprimir
                    </Button>
                  )}

                  <Button
                    positive
                    onClick={this.semImprimirSubmit}
                    loading={this.state.salvando}
                    disabled={
                      (this.state.salvando, this.state.visualizar !== true)
                    }
                  >
                    Salvar
                  </Button>
                </Header>
              </Col>
            </Row>
          </Segment>
          <Modal
            dimmer={"blurring"}
            open={this.state.open}
            onClose={() => this.setState({ open: false })}
          >
            <Modal.Header>Histórico</Modal.Header>
            <Modal.Content>
              <List>
                {this.state.contas_a_pagar != null &&
                  this.state.historico_contas_a_pagar != null &&
                  this.state.historico_contas_a_pagar.map((e, i) => (
                    <List.Item>
                      <List.Icon
                        name="file alternate"
                        size="large"
                        verticalAlign="middle"
                        color="olive"
                      />
                      <List.Content>
                        <List.Header as="a">
                          Usuário: {e.usuario.nome}
                        </List.Header>
                        <List.Description>
                          Data: {format(parseISO(e.data), "dd/MM/yyyy")}
                        </List.Description>
                        <List.Description>Hora: {e.hora}</List.Description>
                        <List.Description>Ação: {e.acao}</List.Description>
                      </List.Content>
                    </List.Item>
                  ))}
              </List>
            </Modal.Content>
            <Modal.Actions clearing>
              <Button onClick={() => this.setState({ open: false })}>
                Fechar
              </Button>
            </Modal.Actions>
          </Modal>
        </Form>
        <ModalAvisoExportacaoGerada
          open={this.state.modalAvisoExportacaoGeradaOpen}
          onClose={() =>
            this.setState({ modalAvisoExportacaoGeradaOpen: false })
          }
        />
        <ModalLiquidarContaAVista
          open={this.state.modalLiquidarContaAVistaOpen}
          // onClose={() => this.setState({ modalLiquidarContaAVistaOpen: false })}
          lancamento_conta_id={
            this.state.contas_a_pagar?.lancamento_contas[0]?.id
          }
        />
      </div>
    );
  }
}

export default withRouter(ContasAPagar);
