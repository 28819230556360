import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import {
  Header,
  Button,
  Divider,
  Segment,
  FormField,
  Input,
} from "semantic-ui-react";
import { Row, Col, Form, FormControl } from "react-bootstrap";

// Requisições
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

class ElementoDeCusto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaCC: [],
      listaElementoDeCusto: [],
      listaContabil: [],
      selectedMenu: "",
      id: null,
      codigo: null,
      descricao: null,
      erroCodigoExiste: false,
      selectedCheckboxes: new Set(),
    };
    this.visualizacao();
  }

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  visualizacao = async () => {
    if (this.props.location.query !== undefined) {
      const resposta = await api.post(
        "/elementoDeCusto/visualiza" + requisicoes.entidade,
        this.props.location.query,
        requisicoes.header
      );
      this.setState(resposta.data);
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.submit();
  };

  submit = async () => {
    try {
      const c = this.state;
      const dados = { codigo: c.codigo, descricao: c.descricao };
      let retorno = null;
      if (this.state.id !== null) {
        retorno = await api.post(
          "/elementoDeCusto/alterar" +
            requisicoes.entidade +
            "&id=" +
            this.state.id,
          dados,
          requisicoes.header
        );
        this.props.history.push("/contabil");
      } else {
        retorno = await api.post(
          "/elementoDeCusto/salvar" + requisicoes.entidade,
          dados,
          requisicoes.header
        );
        if (retorno.data === "Codigo ja existe") {
          this.setState({ erroCodigoExiste: true });
        } else {
          this.props.history.push("/contabil");
        }
      }
    } catch (error) {
      alert("Ocorreu um problema ao salvar o registro");
    }
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Elemento De Custo
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={6}>
              <Form.Label>Código</Form.Label>
              <FormControl
                type="number"
                aria-label="Código"
                name="codigo"
                id="codigo"
                onChange={this.alteracao}
                isInvalid={this.state.erroCodigoExiste}
                defaultValue={this.state.codigo}
                required
              />
              <Form.Control.Feedback type="invalid">
                Código já existe
              </Form.Control.Feedback>
            </Col>
            <Col sm={6}>
              <Form.Label>Descrição</Form.Label>
              <FormField
                placeholder="Descrição"
                name="descricao"
                control={Input}
                onChange={this.alteracao}
                required
                defaultValue={this.state.descricao}
                fluid
              />
            </Col>
          </Row>

          <Divider />

          <Segment clearing>
            <Header floated="right">
              <Link to="/contabil">
                <Button type="button">Voltar</Button>
              </Link>
              <Button positive type="submit">
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}

export default withRouter(ElementoDeCusto);
