import React, { Component } from "react";
import $ from "jquery";
import {
  Container,
  Header,
  Menu,
  Segment,
  Table,
  Image,
  Input,
  Divider,
  Progress,
  Label,
  Modal,
  Message,
} from "semantic-ui-react";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import { Link } from "react-router-dom";
import Select from "react-select";
import moment from "moment-timezone";
import ProgressBar from "react-bootstrap/ProgressBar";
import api from "../../../services/api";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FilterTiltShiftIcon from "@material-ui/icons/FilterTiltShift";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import FormatLineSpacingIcon from "@material-ui/icons/FormatLineSpacing";
import renomearChave from "../../../services/renomearChave";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import css from "../styles.css";

export default class Competicao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectPessoa: [],
      selectArma: [],
      pessoa_nome: "",
      pessoa_id: null,
      arma_descricao: "",
      arma_id: null,
      lista_atiradores: [],
      alert: true,
      status: false,
      disabledAbrir: false,
      pontuacaoEspecifica: [],
      tamanhoLinha: 5,
      listaArma: [],
      listaAtirador: [],
      alertCadastro: true,
      tabSelected: null,
      rodada: null,
      atiradoresPadraoSelect: [],
      armasPadraoSelect: [],
      alertaSalvando: true,
    };
    this.listaAtiradores();
  }

  listaAtiradores = async () => {
    var listaInfo = [];
    const atiradores = await api.post(
      "/atirador/lista" + requisicoes.entidade,
      {
        etapa_id: this.props.location.query.etapa_id,
        evento_id: this.props.location.query.evento_id,
      },
      requisicoes.header
    );

    this.setState({ lista_atiradores: atiradores.data.length });
  };

  pesquisaPessoa = async () => {
    const pessoa = await api.post(
      "/pessoa/listaPessoaEvento" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    const lista = [];
    pessoa.data.forEach((e) => {
      let objeto = renomearChave(e, "id", "value");
      objeto = renomearChave(objeto, "nome", "label");

      lista.push(objeto);
    });

    this.setState({ selectPessoa: lista });
  };

  pesquisaArma = async () => {
    const arma = await api.post(
      "/arma/listaArmaEvento" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    const lista = [];
    arma.data.forEach((e) => {
      let objeto = renomearChave(e, "id", "value");
      objeto = renomearChave(objeto, "modelo", "label");

      lista.push(objeto);
    });
    this.setState({ selectArma: lista });
  };

  alteracaoSelectPessoa = async (listaAtirador, element, pedana, posicao) => {
    let lista = [...this.state.listaAtirador];
    let listaSelect = [...this.state.atiradoresPadraoSelect];

    lista[pedana - 1][posicao - 1] = {
      nome: element.label,
      pessoa_id: element.value,
      posicao: posicao,
      pedana: pedana,
      etapa_id: this.props.location.query.etapa_id,
      evento_id: this.props.location.query.evento_id,
      arma_id: null,
      rodada: this.state.rodada,
      data_inscricao: moment().format("YYYY-MM-DD"),
    };

    listaSelect[pedana - 1][posicao - 1] = {
      label: element.label,
      value: element.value,
      pedana: pedana,
      posicao: posicao,
    };

    this.setState({ listaAtirador: lista });
    this.setState({ atiradoresPadraoSelect: listaSelect });
  };
  alteracaoSelectArma = async (element, pedana, posicao, index) => {
    let lista = [...this.state.listaAtirador];
    let listaSelect = [...this.state.armasPadraoSelect];

    lista[pedana - 1][posicao - 1].arma_id = element.value;
    listaSelect[pedana - 1][posicao - 1] = {
      label: element.label,
      value: element.value,
    };

    this.setState({ listaAtirador: lista });
    this.setState({ armasPadraoSelect: listaSelect });
  };

  cadastrarPessoa = async () => {
    window.location.href = "/pessoa";
  };
  cadastrarArma = async () => {
    window.location.href = "/arma";
  };

  alteracao = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  adicionaLinhas = async () => {
    let tamanho = this.state.tamanhoLinha;
    tamanho = tamanho + 5;
    this.setState({ tamanhoLinha: tamanho });
  };

  changeStatus = async (tipo, status) => {
    const arma = await api.post(
      "/etapa/alterarStatus" + requisicoes.entidade,
      {
        tipo,
        etapa_id: this.props.location.query.etapa_id,
        evento_id: this.props.location.query.evento_id,
      },
      requisicoes.header
    );
    if (tipo === "fechar") {
      this.setState({ alert: status });
      setTimeout(() => {
        $("#voltar").click();
      }, 1500);
    } else {
      this.setState({ disabledAbrir: true });
    }
  };

  changeTabAtirador = async (event, newValue) => {
    this.setState({ tabSelected: newValue });
    this.setState({ rodada: newValue + 1 });
    var listaAtiradorSelect = [];
    var listaArmaSelect = [];
    var listaAtiradorBanco = [];

    const atiradorPorTab = await api.post(
      "/atirador/listaPorTabs" + requisicoes.entidade,
      {
        etapa_id: this.props.location.query.etapa_id,
        evento_id: this.props.location.query.evento_id,
        rodada: newValue + 1,
      },
      requisicoes.header
    );
    //Toda vez que for clicado nas tabs das rodadas ele vai fazer um for para criar 2 listas.
    //A primeira lista é para criar as pedanas com 5 posições sempre.O numero de pedanas é definido nas configurações da etapa.
    //A segunda lista é para adicionar as pessoas nessas pedanas que foram inscritas e sua posição.
    for (let b = 0; b < this.props.location.query.pedana; b++) {
      //Cria a lista de atirador e armas por pedana
      listaAtiradorSelect.push([]);
      listaArmaSelect.push([]);
      listaAtiradorBanco.push([]);
      for (let a = 0; a < 5; a++) {
        //Adiciona 5 posições nas listas
        listaAtiradorSelect[b].push([]);
        listaArmaSelect[b].push([]);
        listaAtiradorBanco[b].push([]);
        //Verifica em qual posição e pedana os inscritos estão
        let atirador = atiradorPorTab.data.find(
          (at) => at.posicao === a + 1 && at.pedana === b + 1
        );
        //popula a lista com as informações dos atiradores
        if (atirador !== undefined) {
          listaAtiradorSelect[b][a] = {
            label: atirador.nome,
            value: atirador.id,
            pedana: atirador.pedana,
            posicao: atirador.posicao,
          };
          listaArmaSelect[b][a] = {
            label: atirador.arma.modelo,
            value: atirador.arma.id,
            pedana: atirador.pedana,
            posicao: atirador.posicao,
          };
        }
        // listaAtiradorBanco[b][a]={
        //   nome: '',
        //   pessoa_id: '',
        //   posicao: '',
        //   pedana: '',
        //   etapa_id: '',
        //   evento_id: '',
        //   arma_id: '',
        //   rodada: '',
        // }
      }
    }

    this.setState({ atiradoresPadraoSelect: listaAtiradorSelect });
    this.setState({ armasPadraoSelect: listaArmaSelect });
    this.setState({ listaAtirador: listaAtiradorBanco });
  };
  handleSubmit = () => {
    this.submit();
  };

  submit = async () => {
    this.setState({ alertaSalvando: true });
    var lista = [];
    var listaAtiradores = [...this.state.listaAtirador];

    for (let b = 0; b < this.props.location.query.pedana; b++) {
      for (let a = 0; a < 5; a++) {
        if (listaAtiradores[b][a].length !== 0) {
          lista.push(listaAtiradores[b][a]);
        }

        if (a + 1 === 5 && b + 1 === this.props.location.query.pedana) {
          const arma = await api
            .post(
              "/atirador/salvar" + requisicoes.entidade,
              {
                atiradores: lista,
              },
              requisicoes.header
            )
            .then(() => {
              setTimeout(() => {
                this.setState({ alertaSalvando: false });
                $("#listarAtiradores").click();
              }, 1500);
            });
        }
      }
    }

    this.listaAtiradores();
    this.setState({ listaAtirador: [] });
    this.setState({ selectAr: [] });
    this.setState({ selectArma: [] });
    this.setState({ alertCadastro: false });
  };

  render() {
    return (
      <Container className="paginaSemPaddingTop">
        <Form>
          <Segment>
            <Header as="h2" dividing>
              <div className="flexSpaceBetween">
                {this.props.location.query.status === true ||
                this.state.disabledAbrir === true ? (
                  <div>
                    <Button
                      id="encerrar"
                      color="secondary"
                      floated="right"
                      onClick={() => this.changeStatus("fechar", false)}
                      style={{ outline: "none" }}
                    >
                      Encerrar
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button
                      color="primary"
                      floated="right"
                      onClick={() => this.changeStatus("abrir", true)}
                      style={{ outline: "none" }}
                    >
                      Abrir
                    </Button>
                  </div>
                )}
                <div>
                  <Link
                    to={{
                      pathname: "/eventos/ajustar",
                      query: {
                        id: this.props.location.query.evento_id,
                        regra_pontuacao: this.props.location.query
                          .regra_pontuacao,
                      },
                    }}
                  >
                    <Button
                      style={{ outline: "none" }}
                      icon
                      size="mini"
                      id="voltar"
                      color="primary"
                    >
                      Voltar
                    </Button>
                  </Link>
                </div>
              </div>

              <Alert severity="success" hidden={this.state.alertCadastro}>
                <AlertTitle>Atirador Cadastrado!</AlertTitle>
                Iremos redirecionar-lhe — <strong>Aguarde!</strong>
              </Alert>

              <Alert severity="success" hidden={this.state.alert}>
                <AlertTitle>Evento Encerrado!</AlertTitle>
                Iremos redirecionar-lhe — <strong>Aguarde!</strong>
              </Alert>
              {this.props.location.query.status !== true &&
                this.state.disabledAbrir !== true && (
                  <Alert severity="warning">
                    <AlertTitle>Atenção!</AlertTitle>{" "}
                    <strong>Abra a etapa para cadastrar atiradores</strong>
                  </Alert>
                )}
            </Header>

            <Divider horizontal>
              {"Inscrição de Atiradores Etapa " +
                this.props.location.query.numero_etapa}
            </Divider>

            <div className="listaAtirador">
              <div>
                <Button
                  id="encerrar"
                  color="secondary"
                  floated="right"
                  style={{ outline: "none" }}
                >
                  {"Atiradores: " + this.state.lista_atiradores}
                </Button>{" "}
              </div>
              <div>
                <Link
                  to={{
                    pathname: "/eventos/lancamento/listaAtiradores",
                    query: {
                      etapa_id: this.props.location.query.etapa_id,
                      evento_id: this.props.location.query.evento_id,
                      numero_etapa: this.props.location.query.numero_etapa,
                      pedana: this.props.location.query.pedana,
                      status: this.props.location.query.status,
                      regra_pontuacao: this.props.location.query
                        .regra_pontuacao,
                    },
                  }}
                >
                  <Button
                    id="listarAtiradores"
                    icon
                    size="mini"
                    style={{
                      outline: "none",
                      backgroundColor: " rgb(22, 66, 82)",
                      color: "rgba(232, 218, 192, 0.994)",
                    }}
                  >
                    Listar Atiradores
                  </Button>
                </Link>
              </div>
            </div>

            <Row>
              <Col
                sm={12}
                className="tabsRodadas"
                hidden={
                  this.props.location.query.status !== true &&
                  this.state.disabledAbrir !== true
                }
              >
                <Paper square>
                  <Tabs
                    value={this.state.tabSelected}
                    indicatorColor="secondary"
                    textColor="primary"
                    aria-label="disabled tabs example"
                    onChange={this.changeTabAtirador}
                    style={{ outline: "none" }}
                    variant="scrollable"
                  >
                    <Tab
                      style={{ outline: "none", color: "rgb(10, 111, 148)" }}
                      label="Rodada 1"
                    />
                    <Tab
                      style={{ outline: "none", color: "rgb(10, 111, 148)" }}
                      label="Rodada 2"
                    />
                    <Tab
                      style={{ outline: "none", color: "rgb(10, 111, 148)" }}
                      label="Rodada 3"
                    />
                    <Tab
                      style={{ outline: "none", color: "rgb(10, 111, 148)" }}
                      label="Rodada 4"
                    />
                    <Tab
                      style={{ outline: "none", color: "rgb(10, 111, 148)" }}
                      label="Rodada 5"
                    />
                    <Tab
                      style={{ outline: "none", color: "rgb(10, 111, 148)" }}
                      label="Rodada 6"
                    />
                    <Tab
                      style={{ outline: "none", color: "rgb(10, 111, 148)" }}
                      label="Rodada 7"
                    />
                    <Tab
                      style={{ outline: "none", color: "rgb(10, 111, 148)" }}
                      label="Rodada 8"
                    />
                    <Tab
                      style={{ outline: "none", color: "rgb(10, 111, 148)" }}
                      label="Rodada 9"
                    />
                  </Tabs>
                </Paper>
              </Col>
            </Row>
            <Row></Row>

            {Array.from({ length: this.props.location.query.pedana }).map(
              (e, linha) => {
                return (
                  <Table
                    striped
                    selectable
                    hidden={this.state.tabSelected === null}
                  >
                    <Table.Header>
                      <Row>
                        <Col sm={10}>
                          <Label className="LabelColuna">
                            {" "}
                            {"Pedana " + (linha + 1)}
                            {/* <Button
                              icon
                              onClick={(e) => this.adicionaLinhas()}
                              size="mini"
                              style={{
                                outline: "none",

                                height: "20px",
                                minWidth: "20px",
                                borderRadius: "50px",
                                backgroundColor: " rgb(22, 66, 82)",
                                color: "rgba(232, 218, 192, 0.994)",
                              }}
                            >
                              <FormatLineSpacingIcon
                                size="small"
                                name="configure"
                              />
                            </Button> */}
                          </Label>
                        </Col>
                      </Row>

                      <Table.Row>
                        <Table.HeaderCell>
                          NOME{" "}
                          <Button
                            style={{
                              marginLeft: "3%",
                              height: "20px",
                              minWidth: "20px",
                              borderRadius: "50px",
                              backgroundColor: " rgb(22, 66, 82)",
                              color: "rgba(232, 218, 192, 0.994)",
                            }}
                            size="small"
                            variant="contained"
                            color="primary"
                            component="span"
                            onClick={this.cadastrarPessoa}
                            disabled={
                              this.props.location.query.status !== true &&
                              this.state.disabledAbrir !== true
                            }
                          >
                            +
                          </Button>
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          ARMA{" "}
                          <Button
                            style={{
                              marginLeft: "3%",
                              height: "20px",
                              minWidth: "20px",
                              borderRadius: "50px",
                              backgroundColor: " rgb(22, 66, 82)",
                              color: "rgba(232, 218, 192, 0.994)",
                            }}
                            size="small"
                            variant="contained"
                            color="primary"
                            component="span"
                            onClick={this.cadastrarArma}
                          >
                            +
                          </Button>
                        </Table.HeaderCell>

                        {/* <Table.HeaderCell width={2}></Table.HeaderCell> */}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {Array.from({ length: this.state.tamanhoLinha }).map(
                        (element, index) => {
                          return (
                            <Table.Row key={index}>
                              <Table.Cell>
                                <Select
                                  value={
                                    this.state.atiradoresPadraoSelect[linha] !==
                                      undefined &&
                                    this.state.atiradoresPadraoSelect[linha][
                                      index
                                    ] !== undefined
                                      ? this.state.atiradoresPadraoSelect[
                                          linha
                                        ][index]
                                      : { label: "livre", value: 0 }
                                  }
                                  placeholder="Cadastrar Atirador"
                                  id="pessoa"
                                  as="select"
                                  name="pessoa"
                                  options={this.state.selectPessoa}
                                  onChange={(element) =>
                                    this.alteracaoSelectPessoa(
                                      this.state.listaAtirador,
                                      element,
                                      linha + 1,
                                      index + 1
                                    )
                                  }
                                  onKeyDown={this.pesquisaPessoa}
                                  required
                                />
                              </Table.Cell>
                              <Table.Cell>
                                <Select
                                  value={
                                    this.state.armasPadraoSelect[linha] !==
                                      undefined &&
                                    this.state.armasPadraoSelect[linha][
                                      index
                                    ] !== undefined
                                      ? this.state.armasPadraoSelect[linha][
                                          index
                                        ]
                                      : { label: "live", value: 0 }
                                  }
                                  placeholder="Cadastrar Arma"
                                  id="pessoa"
                                  as="select"
                                  name="pessoa"
                                  options={this.state.selectArma}
                                  onChange={(element) =>
                                    this.alteracaoSelectArma(
                                      element,
                                      linha + 1,
                                      index + 1,
                                      index
                                    )
                                  }
                                  onKeyDown={this.pesquisaArma}
                                  required
                                />
                              </Table.Cell>
                            </Table.Row>
                          );
                        }
                      )}
                    </Table.Body>
                  </Table>
                );
              }
            )}
          </Segment>
          <Segment clearing>
            <Header floated="right">
              <Button
                type="button"
                style={{ outline: "none" }}
                color="primary"
                onClick={this.handleSubmit}
                disabled={
                  this.props.location.query.status !== true &&
                  this.state.disabledAbrir === false
                }
              >
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>
      </Container>
    );
  }
}
