import React, { Component } from "react";
import { Header, Segment } from "semantic-ui-react";
import { Paper, Tabs, Tab } from "@material-ui/core";
import { Col, Row } from "react-bootstrap";

import UsuarioLista from "./usuario/lista";
import UsuarioTitularLista from "./usuarioTitular/lista";
import TransferenciaMenu from "./transferencia/index";
import Mensalidade from "./mensalidade/lista";
import GerenciarDividas from "./grenciarDividas/index";
import CancelamentoAcao from "./cancelamentoAcao";
import Suspensao from "./suspensao/lista";
import ClubeVantagem from "./clubeVantagem/lista";
import ArmaLista from "./arma/lista";
import MunicaoLista from "./municao/lista";
// TODO: Colocar estes componentes de Arma e Munição no submenu de eventos/Clube de tiro

import { Icon as Iconify } from "@iconify/react";

import accountIcon from "@iconify-icons/mdi/account";
import cardAccountDetailsIcon from "@iconify-icons/mdi/card-account-details";
import swapHorizontalIcon from "@iconify-icons/mdi/swap-horizontal";
import cashIcon from "@iconify-icons/mdi/cash";
import clipboardEditOutlineIcon from "@iconify-icons/mdi/clipboard-edit-outline";
import cancelIcon from "@iconify-icons/mdi/cancel";
import lockClockIcon from "@iconify-icons/mdi/lock-clock";
import shoppingIcon from "@iconify-icons/mdi/shopping";
import MensalidadeTabs from "./mensalidade/tabs";

export default class Administrativo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo_clube: window.localStorage.getItem("tipo_clube"),
      selecionado:
        localStorage.getItem("pagina_anterior_administrativo") ||
        this.props.submenus[0].nome,
    };
  }

  changeTabMenu = async (event, newValue) => {
    localStorage.setItem("pagina_anterior_administrativo", newValue);
    this.setState({ selecionado: newValue });
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Administrativo
        </Header>
        <Segment>
          <Row style={{ paddingBottom: 10 }}>
            <Col sm={12}>
              <Paper square>
                <Tabs
                  indicatorColor="secondary"
                  textColor="primary"
                  style={{ outline: "none" }}
                  variant="scrollable"
                  onChange={this.changeTabMenu}
                  value={this.state.selecionado}
                >
                  {this.props.submenus.find((e) => e.nome === "usuarios") && (
                    <Tab
                      icon={<Iconify icon={accountIcon} width={24} />}
                      style={{ outline: "none" }}
                      label="Usuarios"
                      value={"usuarios"}
                    ></Tab>
                  )}
                  {this.props.submenus.find(
                    (e) => e.nome === "novo_titulo"
                  ) && (
                    <Tab
                      icon={
                        <Iconify icon={cardAccountDetailsIcon} width={24} />
                      }
                      style={{ outline: "none" }}
                      label="Novo Título"
                      value={"novo_titulo"}
                    ></Tab>
                  )}
                  {this.props.submenus.find(
                    (e) => e.nome === "transferencia"
                  ) && (
                    <Tab
                      icon={<Iconify icon={swapHorizontalIcon} width={24} />}
                      style={{ outline: "none" }}
                      label="Transferência"
                      value={"transferencia"}
                    ></Tab>
                  )}
                  {this.props.submenus.find(
                    (e) => e.nome === "mensalidade"
                  ) && (
                    <Tab
                      icon={<Iconify icon={cashIcon} width={24} />}
                      style={{ outline: "none" }}
                      label="Mensalidade"
                      value={"mensalidade"}
                    ></Tab>
                  )}
                  {this.props.submenus.find(
                    (e) => e.nome === "gerenciar_dividas"
                  ) && (
                    <Tab
                      icon={
                        <Iconify icon={clipboardEditOutlineIcon} width={24} />
                      }
                      style={{ outline: "none" }}
                      label="Gerenciar Dívidas"
                      value={"gerenciar_dividas"}
                    ></Tab>
                  )}
                  {this.props.submenus.find(
                    (e) => e.nome === "cancelamento_de_titulo"
                  ) && (
                    <Tab
                      icon={<Iconify icon={cancelIcon} width={24} />}
                      style={{ outline: "none" }}
                      label="Cancelamento de título"
                      value={"cancelamento_de_titulo"}
                    ></Tab>
                  )}
                  {this.props.submenus.find((e) => e.nome === "suspensoes") && (
                    <Tab
                      icon={<Iconify icon={lockClockIcon} width={24} />}
                      style={{ outline: "none" }}
                      label="Suspensões"
                      value={"suspensoes"}
                    ></Tab>
                  )}
                  {this.props.submenus.find(
                    (e) => e.nome === "clube_de_vantagens"
                  ) && (
                    <Tab
                      icon={<Iconify icon={shoppingIcon} width={24} />}
                      style={{ outline: "none" }}
                      label="Clube de Vantagens"
                      value={"clube_de_vantagens"}
                    ></Tab>
                  )}
                </Tabs>
              </Paper>
            </Col>
          </Row>
          <TabPanel
            value={this.state.selecionado}
            index={"usuarios"}
            submenus={this.props.submenus}
          >
            <UsuarioLista />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"novo_titulo"}
            submenus={this.props.submenus}
          >
            <UsuarioTitularLista />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"transferencia"}
            submenus={this.props.submenus}
          >
            <TransferenciaMenu />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"mensalidade"}
            submenus={this.props.submenus}
          >
            <MensalidadeTabs />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"gerenciar_dividas"}
            submenus={this.props.submenus}
          >
            <GerenciarDividas />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"cancelamento_de_titulo"}
            submenus={this.props.submenus}
          >
            <CancelamentoAcao />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"suspensoes"}
            submenus={this.props.submenus}
          >
            <Suspensao />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"clube_de_vantagens"}
            submenus={this.props.submenus}
          >
            <ClubeVantagem />
          </TabPanel>
        </Segment>
      </div>
    );
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
}
