import React, { Component } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import { Header, Button, Segment, Statistic } from "semantic-ui-react";
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";
import moment from "moment";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";

export default class avaliacao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      idade: null,
      status: null,
      data_lancamento: null,
      data_validade: null,
      // dados antropometricos
      peso: null,
      altura: null,
      imc: null,
      // dobras
      triceps: null,
      biceps: null,
      supra_iliaca: null,
      subescapular: null,
      abdominal: null,
      peitoral: null,
      axilar_media: null,
      coxa: null,
      panturrilha: null,
      porcentagem_gordura: null,
      // circunferencias
      ombro: null,
      torax: null,
      cintura: null,
      quadril: null,
      circunferencia_abdominal: null,
      // perimetros
      braco_dir: null,
      braco_esq: null,
      braco_dir_contraido: null,
      braco_esq_contraido: null,
      antebraco_dir: null,
      antebraco_esq: null,
      coxa_medial_dir: null,
      coxa_medial_esq: null,
      panturrilha_dir: null,
      panturrilha_esq: null,
      // definições
      biotipo: null,
      somatotipo: null,
      // pessoas
      aluno: null,
      aluno_id: null,
      avaliador: null,
      avaliador_id: null,
      pessoas: [],
      peso_magro: null,
      peso_gordo: null,
      peso_ideal: null,
      residuos: null,
      ossos: null,
      percentual_gordura: null,
      ultima_peso: 0,
      ultima_peso_magro: 0,
      ultima_peso_gordo: 0,
      grafico_comparativo: [],
    };
    this.resultado();
  }

  resultado = async () => {
    function soma7dobras(
      triceps,
      subescapular,
      peitoral,
      axilar_media,
      abdominal,
      supra_iliaca,
      coxa
    ) {
      let retorno =
        triceps +
        subescapular +
        peitoral +
        axilar_media +
        abdominal +
        supra_iliaca +
        coxa;
      return retorno;
    }

    function densidadeCorporal(idade, soma7dobras) {
      let retorno =
        1.112 -
        0.00043499 * soma7dobras +
        0.00000055 * soma7dobras * 2 -
        0.00028826 * idade;
      return retorno;
    }

    const resposta = await api.post(
      "/avaliacaoFisica/visualiza" + requisicoes.entidade,
      { id: this.props.id },
      requisicoes.header
    );
    this.setState(resposta.data);
    const avaliacao = resposta.data;

    const ultima = await api.post(
      "/avaliacaoFisica/buscaUltima" + requisicoes.entidade,
      {
        aluno_id: avaliacao.aluno_id,
        id: this.props.id,
        data_lancamento: avaliacao.data_lancamento,
      },
      requisicoes.header
    );

    let lista = [];
    // console.log(ultima.data.id);
    if (ultima.data.id) {
      const ultimaAvaliacao = ultima.data;
      const resultadoSoma7dobrasPesoAntigo = soma7dobras(
        ultimaAvaliacao.triceps,
        ultimaAvaliacao.subescapular,
        ultimaAvaliacao.peitoral,
        ultimaAvaliacao.axilar_media,
        ultimaAvaliacao.abdominal,
        ultimaAvaliacao.supra_iliaca,
        ultimaAvaliacao.coxa
      );
      // console.log("entrou", resultadoSoma7dobrasPesoAntigo);
      let densidade_corporal_antigo = densidadeCorporal(
        ultimaAvaliacao.idade,
        resultadoSoma7dobrasPesoAntigo
      );
      let conta_percentual_antigo = 4.95 / densidade_corporal_antigo.toFixed(2);
      let percentual_gordura_antigo =
        (conta_percentual_antigo.toFixed(2) - 4.5) * 100;

      let peso_gordo_antigo =
        (percentual_gordura_antigo / 100) * ultimaAvaliacao.peso;
      let peso_magro_antigo =
        ultimaAvaliacao.peso - peso_gordo_antigo.toFixed(2);

      this.setState({
        ultima_peso: ultimaAvaliacao.peso,
        ultima_peso_gordo: peso_gordo_antigo.toFixed(2),
        ultima_peso_magro: peso_magro_antigo.toFixed(2),
      });

      // console.log(this.state.ultima_peso_magro);

      lista.push({
        id: "Peso Antigo",
        Peso: ultimaAvaliacao.peso,
        Gordura: peso_gordo_antigo.toFixed(2),
        Músculos: peso_magro_antigo.toFixed(2),
      });
    }

    // this.setState({ ultima: ultima.data });

    let imc = avaliacao.peso / (avaliacao.altura * avaliacao.altura);

    this.calculaIdade(resposta.data.aluno.dataNascimento);

    const resultadoSoma7dobrasPesoAtual = soma7dobras(
      avaliacao.triceps,
      avaliacao.subescapular,
      avaliacao.peitoral,
      avaliacao.axilar_media,
      avaliacao.abdominal,
      avaliacao.supra_iliaca,
      avaliacao.coxa
    );

    let densidade_corporal = densidadeCorporal(
      avaliacao.idade,
      resultadoSoma7dobrasPesoAtual
    );

    let conta_percentual = 4.95 / densidade_corporal.toFixed(2);
    let percentual_gordura = (conta_percentual.toFixed(2) - 4.5) * 100;

    let peso_gordo = (percentual_gordura / 100) * avaliacao.peso;
    let peso_magro = avaliacao.peso - peso_gordo.toFixed(2);

    let area_muscular_braco =
      avaliacao.braco_dir -
      Math.pow(Math.PI * avaliacao.triceps, 2) / (4 * Math.PI) -
      6.5;

    let peso_ideal = "";

    switch (true) {
      case imc < 18.5:
        peso_ideal = "Abaixo do peso";
        break;
      case imc >= 18.5 && imc <= 24.9:
        peso_ideal = "Peso normal";
        break;
      case imc >= 25 && imc <= 29.9:
        peso_ideal = "Sobrepeso";
        break;
      case imc >= 30 && imc <= 34.99:
        peso_ideal = "Obesidade grau I";
        break;
      case imc >= 35 && imc <= 39.99:
        peso_ideal = "Obesidade grau II (severa)";
        break;
      case imc > 40:
        peso_ideal = "Obesidade grau III (mórbida)";
        break;

      default:
        break;
    }

    this.setState({
      imc: imc.toFixed(2),
      peso_ideal: peso_ideal,
      peso_magro: peso_magro.toFixed(2),
      peso_gordo: peso_gordo.toFixed(2),
      percentual_gordura: percentual_gordura.toFixed(2),
    });
    lista.push({
      id: "Peso Atual",
      Peso: this.state.peso,
      Gordura: this.state.peso_gordo,
      Músculos: this.state.peso_magro,
    });
    this.setState({ grafico_comparativo: lista });
  };

  calculaIdade = (dataNascimento) => {
    var data = new Date(),
      ano_atual = data.getFullYear(),
      mes_atual = data.getMonth() + 1,
      dia_atual = data.getDate(),
      ano_aniversario = +moment(dataNascimento).year(),
      mes_aniversario = +moment(dataNascimento).month(),
      dia_aniversario = +moment(dataNascimento).day(),
      quantos_anos = ano_atual - ano_aniversario;

    if (
      mes_atual < mes_aniversario ||
      (mes_atual === mes_aniversario && dia_atual < dia_aniversario)
    ) {
      quantos_anos--;
    }

    const idade = quantos_anos < 0 ? 0 : quantos_anos;
    this.setState({ idade: idade });
  };

  render() {
    var grafico_composicao_corporal = [
      {
        id: "Músculos",
        value: this.state.peso_magro,
        label: "Músculos Kg",
      },
      {
        id: "Gordura",
        value: this.state.peso_gordo,
        label: "Gordura Kg",
      },
    ];

    return (
      <div>
        <Header as="h1" dividing>
          Resumo avaliação
        </Header>
        <Segment textAlign="center">
          <Statistic.Group widths={3}>
            <Statistic>
              <Statistic.Value>{this.state.altura} m</Statistic.Value>
              <Statistic.Label>Altura</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{this.state.peso} kg</Statistic.Value>
              <Statistic.Label>Peso</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>
                {this.state.percentual_gordura} %
              </Statistic.Value>
              <Statistic.Label> % Gordura</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </Segment>

        <Segment textAlign="center">
          <Statistic.Group widths={3}>
            <Statistic>
              <Statistic.Value>{this.state.peso_gordo} kg</Statistic.Value>
              <Statistic.Label> Peso gordo</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{this.state.peso_magro} kg</Statistic.Value>
              <Statistic.Label> Peso Magro</Statistic.Label>
            </Statistic>
            <Statistic>
              <Statistic.Value>{this.state.imc}</Statistic.Value>
              <Statistic.Label> IMC</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </Segment>
        <Segment textAlign="center">
          {this.state.peso_ideal === "Abaixo do peso" && (
            <Statistic color="orange">
              <Statistic.Value>{this.state.peso_ideal}</Statistic.Value>
            </Statistic>
          )}
          {this.state.peso_ideal === "Peso normal" && (
            <Statistic color="green">
              <Statistic.Value>{this.state.peso_ideal}</Statistic.Value>
            </Statistic>
          )}
          {this.state.peso_ideal === "Sobrepeso" && (
            <Statistic color="yellow">
              <Statistic.Value>{this.state.peso_ideal}</Statistic.Value>
            </Statistic>
          )}
          {this.state.peso_ideal === "Obesidade grau I" && (
            <Statistic color="orange">
              <Statistic.Value>{this.state.peso_ideal}</Statistic.Value>
            </Statistic>
          )}
          {this.state.peso_ideal === "Obesidade grau II (severa)" && (
            <Statistic color="red">
              <Statistic.Value>{this.state.peso_ideal}</Statistic.Value>
            </Statistic>
          )}
          {this.state.peso_ideal === "Obesidade grau III (mórbida)" && (
            <Statistic color="red">
              <Statistic.Value>{this.state.peso_ideal}</Statistic.Value>
            </Statistic>
          )}
        </Segment>
        <Segment style={{ height: 650 }}>
          <Header as="h3">Composição Corporal</Header>
          <ResponsivePie
            data={grafico_composicao_corporal}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
            colors={{ scheme: "set1" }}
            legends={[
              {
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 50,
                itemWidth: 100,
                itemHeight: 36,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
            theme={{
              axis: {
                fontSize: 36,
                tickColor: "#eee",
              },
              labels: {
                text: {
                  fontSize: 20,
                },
              },
              legends: {
                text: {
                  fontSize: 20,
                },
              },
              // grid: {
              //   stroke: "#888",
              //   strokeWidth: 1,
              // },
            }}
          />
        </Segment>
        <Segment style={{ height: 650 }}>
          <Header as="h3">Comparativo de Pesos (Kg)</Header>
          <ResponsiveBar
            data={this.state.grafico_comparativo}
            indexBy="id"
            groupMode="grouped"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={{ scheme: "set1" }}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              // legend: "Comparação ultima avaliação",
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Peso (kg)",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            keys={["Peso", "Gordura", "Músculos"]}
            legends={[
              {
                justify: true,
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </Segment>

        <Segment clearing>
          <Header floated="right">
            <Link to="/academia">
              <Button type="button">Voltar</Button>
            </Link>
          </Header>
        </Segment>
      </div>
    );
  }
}
