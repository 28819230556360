import React, { Component } from "react";
import {
  Container,
  Table,
  Input,
  Form,
  Select,
  Checkbox,
  Accordion,
  Icon,
  Button,
  Modal,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

import moment from "moment";
import { CircularProgress } from "@material-ui/core";

export default class Inadimplentes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //options selects
      optionsAssinatura: [],
      acordionSelected: null,

      //valores
      listaInadimplencias: [],
      listaSemEmail: [],
      selectAssinatura: 0,
      selectAssinatura2: 0,

      presidente: null,

      mesesAtraso: 2,

      checkboxTodos: false,
      openModalEnviaEmails: false,
      enviarBoletos: false,
      numero_aviso: 1,
      totalLancamentos: 0,
      quantidadeLancamentos: 0,

      filtro_valor: 5000,
      checkbox_filtro_valor: false,

      //estado loading/disable buttons
      enviandoEmails: false,
      imprimindo: false,
      buscando: true,

      servico_mensalidade_id: null,
    };
    this.getServicoMensalidade();
    this.buscaDiretoria();
  }

  getServicoMensalidade = async () => {
    const parametro = await api.post(
      "/parametros/visualiza" + requisicoes.entidade,
      {
        nome: "servico_mensalidade_id",
      },
      requisicoes.header
    );
    this.setState(
      {
        servico_mensalidade_id: parametro.data[0].valor,
      },
      () => this.buscaInadimplentes(2)
    );
  };

  buscaInadimplentes = async (aviso) => {
    this.setState({ buscando: true });
    //Buscar todos os lancamentos taxa manutenção vencidos a 1 mês, 2 ou 3.
    this.setState({
      listaInadimplencias: [],
      listaSemEmail: [],
      numero_aviso: aviso - 1,
    });
    let lancamentos = await api.post(
      "/lancamentoConta/buscaInadimplentes" + requisicoes.entidade,
      {
        parcelas_vencidas: aviso,
        servico_id: this.state.servico_mensalidade_id,
      },
      requisicoes.header
    );
    let lancamentosPessoaSemEmail = [];
    let lancamentoEnviar = [];
    let checkboxes = [];
    let totalLancamentos = 0;
    let quantidadeLancamentos = 0;

    for (let i = 0; i < lancamentos.data.length; i++) {
      const element = lancamentos.data[i];
      let totalPessoa = 0;
      for (let j = 0; j < element.lancamento_conta.length; j++) {
        const lancamento = element.lancamento_conta[j];
        quantidadeLancamentos++;
        totalLancamentos += parseFloat(lancamento.valor);
        totalPessoa += parseFloat(lancamento.valor);
      }
      element.total_lancamentos = totalPessoa;

      if (element?.acao?.codigo === null) {
        element.acao.codigo = element.acao.tipo_titulo.prefixo + " " + element.acao.codigo_auxiliar
        if (element.acao.codigo === null || element.acao.codigo === undefined) {
          element.acao.codigo = "INATIVO"
        }
      }

      if (
        element.email === "" ||
        element.email === null ||
        !element.email.includes("@")
      ) {
        lancamentosPessoaSemEmail.push(element);
      } else {
        element.checked = false;
        checkboxes[lancamentoEnviar.length] = false;
        lancamentoEnviar.push(element);
      }
    }
    this.setState({
      listaInadimplencias: lancamentoEnviar,
      listaSemEmail: lancamentosPessoaSemEmail,
      mesesAtraso: aviso,
      totalLancamentos: totalLancamentos,
      quantidadeLancamentos: quantidadeLancamentos,
    });
    this.setState({ buscando: false });
  };

  buscaDiretoria = async () => {
    let gestoes
      = await api.post(
        "/funcionario/buscaGestoes" + requisicoes.entidade,
        {},
        requisicoes.header
      );

    let gestaoAtual = null;
    for (let i = 0; i < gestoes.data.length; i++) {
      const element = gestoes.data[i];
      if (
        moment(moment().format("YYYY-MM-DD")).isBetween(
          element.data_admissao,
          element.data_desocupacao
        )
      ) {
        gestaoAtual = element;
      }
    }


    if (gestaoAtual !== null) {
      var diretoriaGestaoAtual = await api.post(
        "/funcionario/buscaDiretoriaGestao" + requisicoes.entidade,
        {
          data_admissao: gestaoAtual.data_admissao,
          data_desocupacao: gestaoAtual.data_desocupacao,
        },
        requisicoes.header
      );
    }

    let lista = [];
    lista.push({
      text: "Em branco (Secretário Executivo)",
      value: 0,
      key: 0,
    });
    lista.push({
      text: "Em branco (Gerente Financeiro)",
      value: 1,
      key: 1,
    });
    for (let i = 0; i < diretoriaGestaoAtual.data.length; i++) {
      const element = diretoriaGestaoAtual.data[i];
      lista.push({
        label: element.cargo.nome,
        text: element.pessoa.nome,
        value: i + 2,
        key: i + 2,
        cargo: element.cargo.nome,
        pessoa: element.pessoa.nome,
      });

      if (element.cargo.nome === "Presidente") {
        this.setState({
          presidente: {
            cargo: element.cargo.nome,
            pessoa: element.pessoa.nome,
          },
        });
      }
    }
    this.setState({ optionsAssinatura: lista });
    this.buscaInadimplentes(2);
  };
  imprimirInadimpletes = async () => {
    var listaLancamentos = [];
    var listaInadimplencias = this.state.listaInadimplencias;
    for (let i = 0; i < listaInadimplencias.length; i++) {
      const element = listaInadimplencias[i];
      if (element.checked) {
        listaLancamentos.push(element.id);
      }
    }
    const teste = await api.post(
      "/lancamentoConta/gerarPdfImprimeInadimplentes" + requisicoes.entidade,
      {
        parcelas_vencidas: this.state.numero_aviso + 1,
        servico_id: this.state.servico_mensalidade_id,
        pessoas_selecionadas: listaLancamentos,
      },
      requisicoes.header
    );
  }

  buscarArquivoInadimplentes = async () => {
    setTimeout(async () => {
      const r = await api.get(
        "/lancamentoConta/buscarArquivoPdfImprimeInadimplentes" +
        requisicoes.entidade +
        "&t=" +
        requisicoes.header.headers.token
      ); //
      var oReq = new XMLHttpRequest();
      var URLToPDF =
        r.config.baseURL +
        "lancamentoConta/buscarArquivoPdfImprimeInadimplentes" +
        requisicoes.entidade +
        "&t=" +
        requisicoes.header.headers.token; //

      oReq.open("GET", URLToPDF, true);
      oReq.responseType = "blob";
      oReq.onload = function () {
        const file = new Blob([oReq.response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      };
      oReq.send();
    }, 250);

  };

  imprimirAvisos = async () => {
    this.setState({ imprimindo: true });
    var listaLancamentos = [];
    var listaInadimplencias = this.state.listaInadimplencias;

    for (let i = 0; i < listaInadimplencias.length; i++) {
      const element = listaInadimplencias[i];
      if (element.checked) {
        listaLancamentos.push(element.id);
      }
    }

    await api.post(
      "/lancamentoConta/pdfAvisoInadimplencia" + requisicoes.entidade,
      {
        numero_aviso: this.state.numero_aviso,
        parcelas_vencidas: this.state.mesesAtraso,
        pessoas_selecionadas: listaLancamentos,
        servico_id: this.state.servico_mensalidade_id,
        assinatura: this.state.optionsAssinatura[this.state.selectAssinatura],
        assinatura2:
          this.state.selectAssinatura !== this.state.selectAssinatura2
            ? this.state.optionsAssinatura[this.state.selectAssinatura2]
            : null,
        presidente: this.state.presidente,
      },
      requisicoes.header
    );
  };

  buscarArquivoAviso = async () => {
    setTimeout(async () => {
      const r = await api.get(
        "/lancamentoConta/arquivoAvisoInadimplencia" +
        requisicoes.entidade +
        "&t=" +
        requisicoes.header.headers.token
      ); //
      var oReq = new XMLHttpRequest();

      var URLToPDF =
        r.config.baseURL +
        "lancamentoConta/arquivoAvisoInadimplencia" +
        requisicoes.entidade +
        "&t=" +
        requisicoes.header.headers.token; //

      oReq.open("GET", URLToPDF, true);
      oReq.responseType = "blob";
      oReq.onload = function () {
        const file = new Blob([oReq.response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      };
      oReq.send();
    }, 250);
    setTimeout(() => {
      this.setState({ imprimindo: false });
    }, 3000);
  };

  enviarEmails = async () => {
    this.setState({
      enviandoEmails: true,
      openModalEnviaEmails: true,
    });

    var listaLancamentos = [];
    var listaInadimplencias = this.state.listaInadimplencias;

    for (let i = 0; i < listaInadimplencias.length; i++) {
      const element = listaInadimplencias[i];
      if (element.checked) {
        listaLancamentos.push(element.id);
      }
    }
    this.setState({ numeroEmails: listaLancamentos.length });

    await api.post(
      "/lancamentoConta/enviarAvisos" + requisicoes.entidade,
      {
        numero_aviso: this.state.numero_aviso,
        parcelas_vencidas: this.state.mesesAtraso,
        servico_id: this.state.servico_mensalidade_id,
        pessoas_selecionadas: listaLancamentos, //Agora vai ser pessoas selecionadas.
        enviarBoletos: this.state.enviarBoletos,
        assinatura: this.state.optionsAssinatura[this.state.selectAssinatura],
        presidente: this.state.presidente,
      },
      requisicoes.header
    );
    this.setState({ enviandoEmails: false });
  };

  toggleCheckbox = (i) => {
    let elements = this.state.listaInadimplencias;
    if (i === -1) {
      if (this.state.checkbox_filtro_valor) {
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i];
          element.checked = false;
          if (element.total_lancamentos >= this.state.filtro_valor) {
            element.checked = !this.state.checkboxTodos;
          }
        }
      } else {
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i];
          element.checked = !this.state.checkboxTodos;
        }
      }
      this.setState({ checkboxTodos: !this.state.checkboxTodos });
    } else {
      elements[i].checked = !elements[i].checked;
    }
    this.setState({ listaInadimplencias: elements });
  };

  alteraEmail = (email, i) => {
    console.log("email: ", email);
    let lista = this.state.listaSemEmail;
    if (email.includes("@") && email.includes(".") && email.slice(-1) !== ".") {
      console.log("valido?");
      lista[i].valido = true;
    } else {
      lista[i].valido = false;
    }
    lista[i].email = email;
    this.setState({ listaSemEmail: lista });
  };

  salvarEmail = async (i) => {
    let lista = this.state.listaSemEmail;

    //Verificar novamente se o email é válido
    if (
      lista[i].email.includes("@") &&
      lista[i].email.includes(".") &&
      lista[i].email.slice(-1) !== "."
    ) {
      lista[i].valido = true;
    } else {
      lista[i].valido = false;
      return;
    }

    //Salvar alteracao pessoa
    await api.post(
      "/pessoa/alterar" + requisicoes.entidade + "&id=" + lista[i].id,
      {
        email: lista[i].email,
      },
      requisicoes.header
    );

    //Remover dessa lista e colocar na outra.
    let listaComEmail = this.state.listaInadimplencias;
    listaComEmail.push(lista[i]);
    // lista.splice(i, 1);

    lista[i].salvo = true;
    this.setState({
      listaInadimplencias: listaComEmail,
      listaSemEmail: lista,
    });
  };

  render() {
    return (
      <div className="lista" style={{ paddingBottom: 100 }}>
        <Row>
          <Col sm={4}>
            <Row>
              <Col sm={8}>
                <Form>
                  <Form.Group style={{ margin: 0 }}>
                    <Col style={{ padding: 0, margin: 0 }}>
                      <Form.Field
                        label="Aviso"
                        control={Select}
                        options={[
                          {
                            key: 1,
                            text: "1º Aviso: 2 parcelas vencidas",
                            value: 2,
                          },
                          {
                            key: 2,
                            text: "2º Aviso: 3 parcelas vencidas",
                            value: 3,
                          },
                          {
                            key: 3,
                            text: "3º Aviso: 4 parcelas vencidas",
                            value: 4,
                          },
                          {
                            key: 4,
                            text: "4º Aviso: 5 parcelas vencidas",
                            value: 5,
                          },
                          {
                            key: 5,
                            text: "5º Aviso: 6 parcelas vencidas",
                            value: 6,
                          },
                          {
                            key: 6,
                            text: "6º Aviso: 7 parcelas vencidas",
                            value: 7,
                          },
                          {
                            key: 7,
                            text: "7º Aviso: 8 parcelas vencidas",
                            value: 8,
                          },
                          {
                            key: 8,
                            text: "8º Aviso: 9 parcelas vencidas",
                            value: 9,
                          },
                          {
                            key: 9,
                            text: "9º Aviso: 10 parcelas vencidas",
                            value: 10,
                          },
                          {
                            key: 10,
                            text: "10º Aviso: 11 parcelas vencidas",
                            value: 11,
                          },
                          {
                            key: 11,
                            text: "11º Aviso: 12 parcelas vencidas",
                            value: 12,
                          },
                          {
                            key: 12,
                            text: "Aviso: 13 ou mais parcelas vencidas",
                            value: 13,
                          },
                        ]}
                        onChange={(e, a) => this.buscaInadimplentes(a.value)}
                        defaultValue={2}
                      />
                    </Col>
                  </Form.Group>
                </Form>
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  paddingLeft: 0,
                  marginLeft: 0,
                  paddingRight: 0,
                  marginRight: 0,
                  paddingTop: 10,
                }}
              >
                <Button
                  color="blue"
                  onClick={() =>
                    this.imprimirAvisos().then(() => this.buscarArquivoAviso())
                  }
                  loading={this.state.imprimindo}
                  disabled={this.state.imprimindo}
                >
                  <Icon name="print"></Icon>
                  Imprimir
                </Button>
              </Col>
            </Row>
            <Row style={{ marginTop: 5, marginBottom: 10 }}>
              <Col md="auto" style={{ fontWeight: "bold" }}>
                Filtrar valor devido
                <Form.Field
                  control={Input}
                  onChange={(e, a) => this.setState({ filtro_valor: a.value })}
                  value={this.state.filtro_valor}
                  type="number"
                  disabled={!this.state.checkbox_filtro_valor}
                />
              </Col>
              <Col
                md="auto"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Checkbox
                  checked={this.state.checkbox_filtro_valor}
                  onChange={(e, a) =>
                    this.setState({ checkbox_filtro_valor: a.checked })
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col sm={8}>
            <Row>
              <Col>
                <Form>
                  <Form.Group fluid>
                    <Col
                      sm={12}
                      style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        marginLeft: 0,
                        marginRight: 0,
                      }}
                    >
                      <Form.Field
                        label="Assinaturas"
                        control={Select}
                        options={this.state.optionsAssinatura}
                        onChange={(e, a) =>
                          this.setState({ selectAssinatura: a.value })
                        }
                        value={this.state.selectAssinatura}
                      />
                      <Form.Field
                        control={Select}
                        options={this.state.optionsAssinatura}
                        onChange={(e, a) =>
                          this.setState({ selectAssinatura2: a.value })
                        }
                        value={this.state.selectAssinatura2}
                        style={{ marginTop: 5 }}
                      />
                    </Col>
                  </Form.Group>
                </Form>
              </Col>
              <Col
                sm={6.5}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  paddingTop: 10,
                  paddingRight: 15,
                  paddingLeft: 15,
                }}
              >
                <Checkbox
                  label="Anexar boletos"
                  style={{ paddingRight: 20 }}
                  onClick={(e, a) =>
                    this.setState({ enviarBoletos: a.checked })
                  }
                  checked={this.state.enviarBoletos}
                />
                <Button
                  style={{ float: "right" }}
                  color="blue"
                  onClick={() => this.enviarEmails()}
                >
                  <Icon name="mail" />
                  Enviar emails
                </Button>

              </Col>

            </Row>
          </Col>
          <Col style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingTop: -50,
            paddingRight: 15,
            paddingLeft: 15,
          }}>
            <Button
              style={{ float: "right" }}
              color="blue"
              onClick={() =>
                this.imprimirInadimpletes().then(() => this.buscarArquivoInadimplentes())
              }
            // disabled={true}
            //  this.imprimirAvisos().then(() => this.buscarArquivoAviso())
            >
              {/* <Icon name="mail" /> */}
              Imprimir
            </Button>
          </Col>
        </Row>

        <div
          style={{
            borderStyle: "solid",
            borderWidth: "thin",
            borderColor: "#D3D3D3",
            marginBottom: 15,
            fontSize: 16,
            marginTop: 10
          }}
        >
          <Row style={{ padding: 15, textAlign: "right" }}>
            <Col style={{ textAlign: "left" }}>
              <Row>
                <Col md="auto">{"Quantidade Lancamentos: "}</Col>
                <Col>
                  <div style={{ fontWeight: "bold" }}>
                    {this.state.quantidadeLancamentos}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>{"Valor Total Lancamentos: "}</Col>
                <Col md="auto">
                  <div style={{ fontWeight: "bold" }}>
                    {parseFloat(this.state.totalLancamentos).toLocaleString(
                      "pt-BR",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>Total de Pessoas:</Col>
                <Col md="auto" style={{ fontWeight: "bold" }}>
                  {this.state.listaSemEmail.length +
                    this.state.listaInadimplencias.length}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        {this.state.listaSemEmail.length > 0 && (
          <Accordion styled fluid>
            <Accordion.Title
              active={this.state.activeAccordion === 0}
              index={0}
              onClick={(e, a) => {
                this.state.activeAccordion === a.index
                  ? this.setState({ activeAccordion: -1 })
                  : this.setState({ activeAccordion: a.index });
              }}
            >
              <Icon name="dropdown" />
              {"Pessoas com email inválido (" +
                this.state.listaSemEmail.length +
                ")"}
            </Accordion.Title>
            <Accordion.Content active={this.state.activeAccordion === 0}>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Socio</Table.HeaderCell>
                    <Table.HeaderCell width={5}>Email</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {this.state.listaSemEmail.map((e, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{e.nome}</Table.Cell>
                      <Table.Cell collapsing>
                        <Row>
                          <Col sm={10}>
                            <Input
                              fluid
                              iconPosition="left"
                              placeholder="Email"
                              defaultValue={e.email}
                              // onKeyUp={(e,a) => this.alteraEmail(e.target.value, i)}
                              // onChange={(e,a) => this.alteraEmail(a.value, i)}
                              onBlur={(event, a) =>
                                this.alteraEmail(event.target.value, i)
                              }
                              disabled={e.salvo === true}
                              style={{ color: "green" }}
                            >
                              <Icon
                                name="at"
                                color={e.valido === true ? "green" : "red"}
                              />
                              {/*
                                Variar essa cor conforme salvar ou não
                                Não sei quando jogar pra outra lista
                                Um botão de salvar?
                              */}
                              <input />
                            </Input>
                          </Col>
                          <Col sm={2} style={{ paddingLeft: 0 }}>
                            {e.salvo === true ? (
                              <Button
                                icon="check"
                                color="green"
                                onClick={() =>
                                  alert("Esse email já foi salvo!")
                                }
                              />
                            ) : (
                              <Button
                                icon="save"
                                color="blue"
                                disabled={e.valido !== true}
                                onClick={() => this.salvarEmail(i)}
                              />
                            )}
                          </Col>
                        </Row>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Accordion.Content>
          </Accordion>
        )}
        <Dimmer active={this.state.buscando} inverted>
          {this.state.buscando && <Loader>Buscando</Loader>}
        </Dimmer>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Título</Table.HeaderCell>
              <Table.HeaderCell width={5}>Socio</Table.HeaderCell>
              <Table.HeaderCell width={3}>Email</Table.HeaderCell>
              <Table.HeaderCell>Lançamentos</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" st>
                <Checkbox
                  checked={this.state.checkboxTodos}
                  onChange={(event, a) => this.toggleCheckbox(-1)}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.listaInadimplencias.map(
              (e, i) =>
                ((this.state.checkbox_filtro_valor &&
                  parseFloat(e.total_lancamentos) >= this.state.filtro_valor) ||
                  !this.state.checkbox_filtro_valor) && (
                  <Table.Row key={i}>
                    <Table.Cell md="auto">
                      {e.acao !== null ? e.acao.codigo : "INATIVO"}
                    </Table.Cell>
                    <Table.Cell md="auto">{e.nome}</Table.Cell>
                    <Table.Cell md="auto">{e.email}</Table.Cell>
                    <Table.Cell fluid>
                      <Accordion styled fluid>
                        <Accordion.Title
                          onClick={() =>
                            this.setState({
                              acordionSelected:
                                this.state.acordionSelected !== i ? i : null,
                            })
                          }
                          active={this.state.acordionSelected === i}
                        >
                          {e.lancamento_conta.length +
                            " - R$ " +
                            parseFloat(e.total_lancamentos).toLocaleString(
                              "pt-BR",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                        </Accordion.Title>
                        <Accordion.Content
                          active={this.state.acordionSelected === i}
                        >
                          {e.lancamento_conta.map((e2, i2) => (
                            <p style={{ margin: 0 }}>
                              {moment(e2.data_vencimento).format("DD/MM/YYYY") +
                                " - " +
                                parseFloat(e2.valor).toLocaleString("pt-BR", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }) +
                                " - " +
                                e2.servico.nome}
                            </p>
                          ))}
                        </Accordion.Content>
                      </Accordion>
                    </Table.Cell>
                    <Table.Cell textAlign="center" md="auto">
                      <Checkbox
                        id={i}
                        onClick={() => this.toggleCheckbox(i)}
                        checked={e.checked}
                      />
                    </Table.Cell>
                  </Table.Row>
                )
            )}
          </Table.Body>
        </Table>

        <Modal
          open={this.state.openModalEnviaEmails}
          onClose={() => this.setState({ openModalEnviaEmails: false })}
        >
          <Modal.Header>Envio de emails</Modal.Header>
          <Modal.Content loading>
            {this.state.enviandoEmails ? (
              <Container>
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </Col>
                </Row>
                <Row>
                  <Col
                    sm={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: 20,
                    }}
                  >
                    <h4>Seus emails estão sendo enviados.</h4>
                  </Col>
                  <Col
                    sm={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: 20,
                    }}
                  >
                    <h4>
                      {"Gerando e enviando " +
                        this.state.numeroEmails +
                        " emails"}
                      .
                    </h4>
                  </Col>
                  <Col
                    sm={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: 20,
                    }}
                  >
                    <h4>{"Tempo estimado: "}</h4>
                  </Col>
                </Row>
              </Container>
            ) : (
              <Container
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Icon size="big" name={"check"} color={"green"} />
                <br />
                <br />
                <h4>Emails enviados com sucesso.</h4>
              </Container>
            )}
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
