import React, { Component } from "react";
import { Form, Select, Modal, Button, Checkbox, Icon } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";

import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

const optionsTipoParametro = [
  { key: 1, text: "Financeiro", value: "Financeiro" },
  { key: 2, text: "Exame Médico", value: "Exame Médico" },
  { key: 3, text: "Exame Físico", value: "Exame Físico" },
  { key: 4, text: "Financeiro-servico", value: "Financeiro-servico" },
  {
    key: 5,
    text: "Financeiro-grupo_servico",
    value: "Financeiro-grupo_servico",
  },
  {
    key: 6,
    text: "Maticula-servico",
    value: "Maticula-servico",
  },
];
export default class ModalCriarParamentro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open_modal: false,

      optionsServicos: [],
      labelAux: "",
      //entradas campos
      tipo_parametro: null,
      dias: null,
      servico_id: null,
      grupo_servico: null,
    };
  }

  pesquisaServico = async (busca) => {
    if (busca && busca.trim() !== "") {
      const servicos = await api.post(
        "/servico/pesquisa" + requisicoes.entidade,
        {
          pesquisa: busca,
        },
        requisicoes.header
      );
      let lista = [];

      for (let i = 0; i < servicos.data.length; i++) {
        const element = servicos.data[i];
        lista.push({
          key: element.id,
          text: element.nome,
          value: element.id,
        });
      }
      this.setState({ optionsServicos: lista });
    }
  };

  criar = async (busca) => {
    await this.props.criarParametro(
      this.props.catraca_id,
      this.state.tipo_parametro,
      this.state.dias === "" ? null : parseInt(this.state.dias),
      this.state.servico_id,
      this.state.grupo_servico,
      this.state.labelAux
    );
    this.setState({ open_modal: false });
  };

  render() {
    return (
      <Modal
        open={this.state.open_modal}
        onClose={() => {
          this.setState({ open_modal: false });
        }}
        onOpen={() => {
          this.setState({ open_modal: true });
        }}
        trigger={
          <Button
            type="button"
            floated="right"
            icon
            labelPosition="left"
            primary
            size="small"
          >
            <Icon name="plus" /> Criar
          </Button>
        }
        size="tiny"
        style={{ overflow: "visible" }}
      >
        <Modal.Header>Criar Parâmetro</Modal.Header>
        <Modal.Content>
          <Form>
            <Row>
              <Col sm={6}>
                <Form.Select
                  label="Tipo Parâmetro"
                  placeholder="Tipo Parâmetro"
                  options={optionsTipoParametro}
                  onChange={(e, a) =>
                    this.setState({
                      tipo_parametro: a.value,
                      servico_id: null,
                      grupo_servico: null,
                      labelAux: "",
                      dias: "",
                    })
                  }
                />
              </Col>
              <Col sm={6}>
                <Form.Input
                  label="Dias"
                  placeholder="Dias"
                  type="number"
                  disabled={this.state.tipo_parametro === "Maticula-servico"}
                  value={this.state.dias}
                  onChange={(e, a) => {
                    this.setState({ dias: a.value });
                  }}
                />
              </Col>
              {this.state.tipo_parametro === "Financeiro-servico" && (
                <Col>
                  <Form.Select
                    label="Serviço"
                    placeholder="Serviço"
                    onKeyUp={(event) =>
                      this.pesquisaServico(event.target.value)
                    }
                    search
                    options={this.state.optionsServicos}
                    onChange={(event, a) => {
                      this.setState({
                        servico_id: a.value,
                        labelAux: a.options.find((elm) => elm.value === a.value)
                          .text,
                      });
                    }}
                  />
                </Col>
              )}
              {this.state.tipo_parametro === "Financeiro-grupo_servico" && (
                <Col>
                  <Form.Select
                    label="Grupo Serviço"
                    placeholder="Grupo Serviço"
                    options={this.props.options_grupo_servico}
                    onChange={(event, a) => {
                      this.setState({
                        grupo_servico: a.value,
                        labelAux: a.options.find((elm) => elm.value === a.value)
                          .text,
                      });
                    }}
                  />
                </Col>
              )}
              {this.state.tipo_parametro === "Maticula-servico" && (
                <Col>
                  <Form.Select
                    label="Serviço turma"
                    placeholder="Serviço turma"
                    options={this.props.options_servico_turma}
                    onChange={(event, a) => {
                      this.setState({
                        servico_id: a.value,
                        labelAux: a.options.find((elm) => elm.value === a.value)
                          .text,
                      });
                    }}
                    search
                  />
                </Col>
              )}
            </Row>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.setState({ open_modal: false })}>
            Voltar
          </Button>
          <Button positive onClick={this.criar}>
            Criar
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
