import React, { Component } from "react";
import { Header, Segment, Icon, Divider, Button } from "semantic-ui-react";
import { Form, Col, Row } from "react-bootstrap";
import { TextField, MenuItem, InputLabel, Paper, Tabs, Tab, Select, IconButton, Tooltip } from "@material-ui/core/";
import AddBoxIcon from "@material-ui/icons/AddBox";

import requisicoes from "../../../utils/Requisicoes";
import api from "../../../services/api";
import TipoPenalidade from "../../../enum/TipoPenalidade";
import Lista from "./lista";
import "../styles.css";

export default class Desporto extends Component {
  state = {
    modalidadesCadastradas: [],
    eventosCadastrados: [],
    evento: [],
    tiposPenalidades: [],
    menuModalidades: [],
    tipoPontuacao: "",
    menuSelectedDescricao: "",
    nomeModalidade: "",
    tamanhoLinhaEvento: 1,
    tamanhoMaxEquipe: "",
    tamanhoMinEquipe: "",
    numeroTitulares: "",
    pontuacaoMaxima: "",
    quantidadeEquipesPartida: "",
    numeroAdvertencia: "",
    idModalidadeDesporto: null,
    Modalidadeselecionada: null,
    menuSelectedId: null,
    duracaoEvento: "",
    tamanho_max_equipe: "",
    tamanho_min_equipe: "",
    quantidade_titulares: "",
    pontuacao_maxima: "",
    quantidade_equipe_partida: "",
    editaModalidadeDesporto: false,
  };

  componentDidMount() {
    this.pesquisaGrupoServicoDesporto();
  }

  changeTabModalidades = (event, newValue) => {
    this.setState({
      Modalidadeselecionada: newValue,
    });
  };

  adicionaLinhaEvento = () => {
    const lista = [...this.state.evento];
    lista.push({ tipo_penalidade: "", tipo_evento_desporto_id: "" });
    this.setState({ evento: lista });
  };

  pesquisaGrupoServicoDesporto = async () => {
    const desportos = await api.post(
      "/grupoServico/listaGrupoServicoSelecionados" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    const eventos = await api.post(
      "/tipoeventodesporto/listar" + requisicoes.entidade,
      {},
      requisicoes.header
    );

    this.setState({ eventosCadastrados: eventos.data });
    this.setState({ menuModalidades: desportos.data });
  };

  setMenuSelecionado = async (props) => {
    this.setState({ menuSelectedId: props.id });
    this.setState({ menuSelectedDescricao: props.descricao });

    const modalidades = await api.post(
      "/modalidadeDesporto/listarEspecificoPorSubgrupoId" +
      requisicoes.entidade,
      { grupo_servico_id: props.id },
      requisicoes.header
    );

    this.setState({ modalidadesCadastradas: modalidades.data });
  };

  alteracao = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  alteracaoSelect = (event, index) => {
    const lista = [...this.state.evento];

    if (!lista[index]) {
      lista[index] = {};
    }

    lista[index].tipo_evento_desporto_id = event.target.value;
    if (this.state.idModalidadeDesporto) {
      lista[index].modalidade_desporto_id = this.state.idModalidadeDesporto;
    }
    this.setState({ evento: lista });
  };

  alteracaoTipoSelect = (event, index) => {
    const lista = [...this.state.evento];

    if (!lista[index]) {
      lista[index] = {};
    }

    lista[index].tipo_penalidade = event.target.value;

    this.setState({ evento: lista });
  };

  salvar = async (event) => {
    const infoModalidades = {
      nome: this.state.nomeModalidade,
      duracao: 1000,
      grupo_servico_id: this.state.menuSelectedId,
      tamanho_max_equipe: this.state.tamanhoMaxEquipe,
      tamanho_min_equipe: this.state.tamanhoMinEquipe,
      quantidade_titulares: this.state.numeroTitulares,
      tipo_pontuacao: this.state.tipoPontuacao,
      pontuacao_maxima: this.state.pontuacaoMaxima,
      quantidade_equipe_partida: this.state.quantidadeEquipesPartida,
      quantidade_advertencia: this.state.numeroAdvertencia,
      tipo_e_m_dsprt: this.state.evento,
    };

    if (this.state.editaModalidadeDesporto) {
      await api.post(
        "/modalidadeDesporto/editarModalidade" + requisicoes.entidade,
        { id: this.state.idModalidadeDesporto, info: infoModalidades },
        requisicoes.header
      );
    } else {
      await api.post(
        "/modalidadeDesporto/salvar" + requisicoes.entidade,
        { info: infoModalidades },
        requisicoes.header
      );
    }

    this.setState({
      nomeModalidade: "",
      editaModalidadeDesporto: false,
    });
  };

  updateModalidade = (b, a) => {
    const modalidadeCadastrada = {
      ...this.state.modalidadesCadastradas[a],
    };
    this.setState({
      evento: modalidadeCadastrada.tipo_e_m_dsprt,
      editaModalidadeDesporto: true,
    });

    this.setState({
      id: modalidadeCadastrada.id,
      idModalidadeDesporto: modalidadeCadastrada.id,
      nomeModalidade: modalidadeCadastrada.nome,
      duracaoEvento: modalidadeCadastrada.duracao,
      tamanhoMaxEquipe: modalidadeCadastrada.tamanho_max_equipe,
      tamanhoMinEquipe: modalidadeCadastrada.tamanho_min_equipe,
      numeroTitulares: modalidadeCadastrada.quantidade_titulares,
      tipoPontuacao: modalidadeCadastrada.tipo_pontuacao,
      pontuacaoMaxima: modalidadeCadastrada.pontuacao_maxima,
      quantidadeEquipesPartida: modalidadeCadastrada.quantidade_equipe_partida,
      numeroAdvertencia: modalidadeCadastrada.quantidade_advertencia,
      tipo_e_m_dsprt: this.state.evento,
    });
  };

  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Form onSubmit={this.salvar}>
          <h3>Modalidade</h3>
          <Paper>
            {/* <Segment> */}
            <Tabs
              textColor="primary"
              style={{ outline: "none" }}
              variant="scrollable"
              onChange={this.changeTabModalidades}
              value={this.state.Modalidadeselecionada}
              aria-label="icon label tabs example"
            >
              {this.state.menuModalidades.map((element, index) => (
                <Tab
                  onClick={() => this.setMenuSelecionado(element)}
                  style={{
                    outline: "none",
                    marginTop: "-5px",
                  }}
                  label={
                    <Icon
                      style={{
                        fontSize: "30px",
                        color: "rgb(6, 50, 67)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      name={element.icon}
                    />
                  }
                />
              ))}
            </Tabs>
            {/* </Segment> */}
            <Row>
              <Col sm={12}>
                <div>{this.state.menuSelectedDescricao}</div>
              </Col>
            </Row>
          </Paper>



          <Row>
            <Col sm={6} style={{ marginTop: "1ch" }}>
              <Segment clearing>
                <Header>
                  <h3> Configuração Geral</h3>
                  <Divider style={{ marginTop: "-1ch" }} />
                </Header>
                <Row>
                  <Col>
                    <TextField
                      variant="standard"
                      id="nomeModalidade"
                      name="nomeModalidade"
                      label="Nome da Modalidade"
                      value={this.state.nomeModalidade}
                      onChange={this.alteracao}
                      required
                    />
                  </Col>
                </Row>
              </Segment>
            </Col>

            <Col sm={6} style={{ marginTop: "1ch" }}>
              <Segment clearing>
                <Header>
                  <h3> Equipes</h3>
                  <Divider style={{ marginTop: "-1ch" }} />
                </Header>
                <Row>
                  <Col>
                    <TextField
                      variant="standard"
                      id="tamanhoMaxEquipe"
                      name="tamanhoMaxEquipe"
                      label="Tamanho Máximo"
                      value={this.state.tamanhoMaxEquipe}
                      onChange={this.alteracao}
                      required
                    />
                  </Col>
                  <Col>
                    <TextField
                      variant="standard"
                      id="tamanhoMinEquipe"
                      name="tamanhoMinEquipe"
                      label="Tamanho Mínimo"
                      onChange={this.alteracao}
                      value={this.state.tamanhoMinEquipe}
                      type="number"
                      required
                    />
                  </Col>

                  <Col>
                    <TextField
                      variant="standard"
                      id="numeroTitulares"
                      name="numeroTitulares"
                      label="Nº de Títulares"
                      onChange={this.alteracao}
                      value={this.state.numeroTitulares}
                      type="number"
                      required
                    />
                  </Col>
                </Row>
              </Segment>
            </Col>
          </Row>

          <Col sm={12} style={{ padding: "0px", marginTop: "1ch" }}>
            <Segment clearing>
              <Header>
                <h3> Regras</h3>
                <Divider style={{ marginTop: "-1ch" }} />
              </Header>
              <Row>
                <Col sm={4}>
                  <TextField
                    variant="standard"
                    id="pontuacaoMaxima"
                    name="pontuacaoMaxima"
                    label="Pontuação Máxima"
                    onChange={this.alteracao}
                    value={this.state.pontuacaoMaxima}
                    required
                  />
                </Col>
                <Col sm={4}>
                  <TextField
                    variant="standard"
                    id="quantidadeEquipesPartida"
                    name="quantidadeEquipesPartida"
                    label="Equipes por Partida"
                    onChange={this.alteracao}
                    value={this.state.quantidadeEquipesPartida}
                    type="number"
                    required
                  />
                </Col>
                <Col sm={4}>
                  <Tooltip
                    title={<h5>Nº de advertência para ser suspenso</h5>}
                    arrow
                    placement="right"
                  >
                    <TextField
                      variant="standard"
                      id="numeroAdvertencia"
                      name="numeroAdvertencia"
                      label="Nº de advertências"
                      onChange={this.alteracao}
                      value={this.state.numeroAdvertencia}
                      type="number"
                      required
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Segment>
          </Col>

          <Col sm={12} style={{ padding: "0px", marginTop: "1ch" }}>
            <Segment>
              <Header>
                <div className="flexSpaceBetween">
                  <div>Tipos de Eventos</div>
                  <div>
                    <IconButton
                      size="mini"
                      className="bottomModal"
                      color="primary"
                      onClick={this.adicionaLinhaEvento}
                    >
                      <AddBoxIcon />
                    </IconButton>
                  </div>
                </div>
                <Divider />

                {this.state.evento.map((elmt, indx) => {
                  return (
                    <Row>
                      <Col sm={3} style={{ marginTop: "1.5ch" }}>
                        <InputLabel id="demo-mutiple-name-label">
                          Descrição do Evento
                        </InputLabel>
                        <Select
                          variant="standard"
                          placeholder="Tipo do Evento"
                          fullWidth
                          name="evento"
                          id="evento"
                          value={elmt.tipo_evento_desporto_id || ""}
                          onChange={(event) =>
                            this.alteracaoSelect(event, indx)
                          }
                        >
                          {this.state.eventosCadastrados.map(
                            (element, index) => {
                              return (
                                <MenuItem value={element.id}>
                                  {element.descricao}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </Col>

                      <Col sm={3} style={{ marginTop: "1.5ch" }}>
                        <InputLabel id="demo-mutiple-name-label">
                          Tipo do Evento
                        </InputLabel>
                        <Select
                          variant="standard"
                          placeholder="Tipo do Evento"
                          fullWidth
                          name="evento"
                          id="evento"
                          value={elmt.tipo_penalidade || ""}
                          onChange={(event, props) =>
                            this.alteracaoTipoSelect(event, indx)
                          }
                        >
                          {TipoPenalidade.map((element, index) => {
                            return (
                              <MenuItem value={element.value}>
                                {element.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Col>
                    </Row>
                  );
                })}
              </Header>

            </Segment>

          </Col>

          <Lista
            modalidadesCadastradas={this.state.modalidadesCadastradas}
            updateModalidade={this.updateModalidade}
          />
          <Segment clearing >
            <Header floated="right" >


              <Button
                type="submit"
                style={{ outline: "none" }}
                color="primary"
              >
                Salvar
              </Button>

            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}


