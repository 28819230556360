import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { Header, Table, Modal } from "semantic-ui-react";
import {
  TextField,
  Button,
  IconButton,
  Icon,
  ThemeProvider,
  Checkbox,
  FormControlLabel,
  Box,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { Save } from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";

// Componentes criados
import ModalAddMenu from "./modalAddMenu";

let menusRemovidos = [];

export default class ModalAdicionaPermissao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // inputs
      url: "",
      component_menu: false,
      nome_component_menu: "",
      icon: "",
      ordenacao: "",

      menus: [],

      // state botões
      loading: false,

      // Modais
      openModalAddMenu: false,
    };
  }

  adicionarRota = async () => {
    try {
      this.setState({ loading: true });

      if (this.props.id) {
        // Alterar
        const rota = {
          id: this.props.id,
          url: this.state.url,
          component_menu: this.state.component_menu,
          nome_component_menu: this.state.nome_component_menu,
          icon: this.state.icon,
          menus: this.state.menus.filter((e) => !e.id),
          ordenacao: Number.isInteger(this.state.ordenacao)
            ? this.state.ordenacao
            : null,
        };
        await api.post(
          "/rotaFront/editar" + requisicoes.entidade,
          {
            ...rota,
            menusRemovidos: menusRemovidos,
          },
          requisicoes.header
        );
        this.props.alteraRota(rota);
      } else {
        // Salvar
        const rota = await api.post(
          "/rotaFront/salvar" + requisicoes.entidade,
          {
            url: this.state.url,
            component_menu: this.state.component_menu,
            nome_component_menu: this.state.nome_component_menu,
            icon: this.state.icon,
            menus: this.state.menus,
            ordenacao: Number.isInteger(this.state.ordenacao)
              ? this.state.ordenacao
              : null,
          },
          requisicoes.header
        );
        if (rota.data) {
          this.props.addRota(rota.data);
        }
      }

      setTimeout(() => {
        this.setState({ loading: false });
        this.props.onClose();
      }, 500);
    } catch (error) {
      alert("Ocorreu um erro ao salvar a permissão");
      this.setState({ loading: false });
    }
  };

  removeMenu = async (menu_id, index) => {
    if (menu_id) {
      menusRemovidos.push(menu_id);
    }
    let lista = this.state.menus;
    lista.splice(index, 1);
    this.setState({ menus: lista });
  };

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
        onMount={() => {
          if (this.props.rota) {
            this.setState(this.props.rota);
          } else {
            this.setState({
              url: "",
              component_menu: false,
              nome_component_menu: "",
              icon: "",
              menus: [],
              ordenacao: "",
            });
          }
        }}
      >
        <Modal.Header>
          {this.props.id ? "Editar Rota" : "Adicionar Rota"}
        </Modal.Header>
        <Modal.Content>
          <Box component="form">
            <TextField
              style={{ margin: 10 }}
              variant="outlined"
              label="Url"
              value={this.state.url}
              fullWidth
              onChange={(e) => this.setState({ url: e.target.value })}
            />
            <Row>
              <Col md="auto">
                <FormControlLabel
                  style={{ margin: 10 }}
                  control={
                    <Checkbox
                      checked={this.state.component_menu}
                      onChange={(e) =>
                        this.setState({ component_menu: e.target.checked })
                      }
                    />
                  }
                  label="É um menu"
                />
              </Col>
              <Col>
                <TextField
                  style={{ margin: 10 }}
                  variant="outlined"
                  label="Nome do menu lateral"
                  disabled={!this.state.component_menu}
                  value={this.state.nome_component_menu}
                  onChange={(e) =>
                    this.setState({ nome_component_menu: e.target.value })
                  }
                  fullWidth
                />
              </Col>
              <Col>
                <TextField
                  style={{ margin: 10 }}
                  variant="outlined"
                  label="Ícone do menu lateral"
                  disabled={!this.state.component_menu}
                  value={this.state.icon}
                  onChange={(e) => this.setState({ icon: e.target.value })}
                  fullWidth
                />
              </Col>
              <Col>
                <TextField
                  style={{ margin: 10 }}
                  variant="outlined"
                  label="Posição/Ordenação dos menus"
                  disabled={!this.state.component_menu}
                  value={this.state.ordenacao}
                  onChange={(e) => this.setState({ ordenacao: e.target.value })}
                  fullWidth
                />
              </Col>
            </Row>
          </Box>

          <Header as="h3">Menus</Header>

          <div
            style={{
              height: 300,
              overflow: "auto",
              borderWidth: "2px",
              borderColor: "#c3c9cc",
              borderStyle: "solid",
              borderRadius: "7px",
              padding: 7,
            }}
          >
            <Table celled compact>
              <Table.Header>
                <Table.HeaderCell width={1}>Id</Table.HeaderCell>
                <Table.HeaderCell>Nome</Table.HeaderCell>
                <Table.HeaderCell
                  width={1}
                  style={{ padding: 0, textAlign: "center" }}
                >
                  <IconButton
                    onClick={() => this.setState({ openModalAddMenu: true })}
                  >
                    <ThemeProvider
                      theme={createTheme({
                        palette: {
                          primary: green,
                        },
                      })}
                    >
                      <Icon color="primary">add_circle</Icon>
                    </ThemeProvider>
                  </IconButton>
                </Table.HeaderCell>
              </Table.Header>

              <Table.Body>
                {this.state.menus.length === 0 ? (
                  <Table.Row>
                    <Table.Cell colSpan="3">
                      Ainda não foi adicionado nenhum menu a esta rota.
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  this.state.menus.map((e, i) => (
                    <Table.Row key={e.id + i}>
                      <Table.Cell>{e.id}</Table.Cell>
                      <Table.Cell>{e.nome}</Table.Cell>
                      <Table.Cell style={{ padding: 0, textAlign: "center" }}>
                        <IconButton onClick={() => this.removeMenu(e.id, i)}>
                          <Icon color="error">delete_icon</Icon>
                        </IconButton>
                      </Table.Cell>
                    </Table.Row>
                  ))
                )}
              </Table.Body>
            </Table>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col md="auto">
              <Button
                onClick={this.props.onClose}
                variant="contained"
                color="inherit"
              >
                Voltar
              </Button>
            </Col>
            <Col md="auto">
              <ThemeProvider
                theme={createTheme({
                  palette: {
                    primary: green,
                  },
                })}
              >
                <LoadingButton
                  variant="contained"
                  style={{ color: "#FFFFFF" }}
                  startIcon={<Save />}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                  onClick={this.adicionarRota}
                >
                  Salvar
                </LoadingButton>
              </ThemeProvider>
            </Col>
          </Row>
        </Modal.Actions>
        <ModalAddMenu
          open={this.state.openModalAddMenu}
          onClose={() => this.setState({ openModalAddMenu: false })}
          addMenu={(e) => {
            let lista = this.state.menus;
            lista.push(e);
            this.setState({ menus: lista, openModalAddMenu: false });
          }}
        />
      </Modal>
    );
  }
}
