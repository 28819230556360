import React, { Component } from "react";
import { Row, Col, FormControl, Form } from "react-bootstrap";
import { Container, Header, Button, Divider, Segment } from "semantic-ui-react";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

export default class MunicaoIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      tipo_projetil: null,
      tipo_ponta: null,
    };
    this.visualizacao();
    this.alteracao = this.alteracao.bind(this);
  }

  visualizacao = async () => {
    if (this.props.location.query !== undefined) {
      const resposta = await api.post(
        "/municao/visualiza" + requisicoes.entidade,
        this.props.location.query,
        requisicoes.header
      );
      this.setState(resposta.data);
    }
  };

  alteracao(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit = (event) => {
    this.submit();
    event.preventDefault();
  };

  voltar() {
    window.location.href = "/administrativo";
  }

  submit = async () => {
    const dados = this.state;
    let retorno = "";
    if (this.state.id !== null) {
      retorno = await api.post(
        "/municao/alterar" + requisicoes.entidade + "&id=" + this.state.id,
        dados,
        requisicoes.header
      );
    } else {
      await api.post(
        "/municao/salvar" + requisicoes.entidade,
        dados,
        requisicoes.header
      );
    }
    window.location.href = "/administrativo";
  };

  render() {
    return (
      <div >
        <Header as="h1" dividing>
          Cadastro de munição
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={6}>
              <Form.Label>Tipo de Projétil</Form.Label>
              <FormControl
                placeholder="Expansivo, Encamisado, Traçante, Explosivo"
                name="tipo_projetil"
                onChange={this.alteracao}
                value={this.state.tipo_projetil}
              />
            </Col>
            <Col sm={6}>
              <Form.Label>Tipo de Ponta</Form.Label>
              <FormControl
                placeholder="Ogival, Canto-vivo, Ponta oca"
                name="tipo_ponta"
                onChange={this.alteracao}
                value={this.state.tipo_ponta}
              />
            </Col>
          </Row>
          <Divider />
          <Segment clearing>
            <Header floated="right">
              <Button type="button" onClick={this.voltar}>
                Voltar
              </Button>
              <Button positive type="submit">
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}
