import React, { Component } from "react";
import {
  Header,
  Modal,
  Segment,
  Table,
  Button,
  Input,
} from "semantic-ui-react";
import { Col, Form, Row, FormControl } from "react-bootstrap";
import { parseISO, format } from "date-fns";
import "jquery-mask-plugin/dist/jquery.mask.min";

var formatter = new Intl.NumberFormat("pt-br", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export default class ModalVisualiza extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal
        // open={this.state.open}
        // onClose={this.handleClose}
        centered={false}
        trigger={<Button color="blue" icon="eye" />}
      >
        <Header content="Visualização de conta a pagar" />
        <Modal.Content>
          <Segment.Group>
            <Segment.Group horizontal>
              <Segment>
                <b>Fornecedor: </b>
                {this.props.contaAPagar !== null &&
                  this.props.contaAPagar.pessoa.nome}
              </Segment>
              <Segment>
                <b>Documento: </b>
                {this.props.contaAPagar.contas_a_pagars !== null &&
                  this.props.contaAPagar.contas_a_pagars[0] !== undefined &&
                  this.props.contaAPagar.contas_a_pagars[0].documento}
              </Segment>
              <Segment>
                <b>O.P: </b>
                {this.props.contaAPagar.contas_a_pagars !== null &&
                  this.props.contaAPagar.contas_a_pagars[0]?.ordem_pagamento !=
                    null &&
                  this.props.contaAPagar.contas_a_pagars[0]?.ordem_pagamento
                    .codigo +
                    " / " +
                    this.props.contaAPagar.numero_parcela}
              </Segment>
            </Segment.Group>
            <Segment.Group horizontal>
              <Segment>
                <b>Valor Total: </b>
                {this.props.contaAPagar !== null &&
                  parseFloat(
                    this.props.contaAPagar.contas_a_pagars[0]?.valor
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
              </Segment>
              <Segment>
                <b>Valor Título: </b>
                {this.props.contaAPagar !== null &&
                  parseFloat(this.props.contaAPagar.valor).toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
              </Segment>
              <Segment>
                <b>Vencimento: </b>
                {this.props.contaAPagar !== null &&
                  format(
                    parseISO(this.props.contaAPagar.data_vencimento),
                    "dd/MM/yyyy"
                  )}
              </Segment>
            </Segment.Group>
          </Segment.Group>

          <Form>
            <Row>
              <Col sm={3}>
                <Form.Label>Data Pagamento</Form.Label>
                <FormControl
                  placeholder="Data"
                  type="date"
                  name="data_pagamento"
                  value={
                    this.props?.contaAPagar?.data_pagamento
                      ? this.props?.contaAPagar?.data_pagamento
                      : (0).toString().substr(0, 10)
                  }
                  readOnly
                />
              </Col>
              <Col sm={3}>
                <Form.Label>Valor Título</Form.Label>
                <FormControl
                  placeholder="Valor"
                  aria-describedby="basic-addon1"
                  name="valor_pagamento"
                  required
                  value={
                    this.props.contaAPagar != null &&
                    formatter.format(this.props.contaAPagar.valor)
                  }
                  readOnly
                />
              </Col>
              <Col sm={3}>
                <Form.Label>Juros/Multas</Form.Label>
                <FormControl
                  placeholder="Valor"
                  aria-describedby="basic-addon1"
                  name="valor_atraso"
                  className="dinheiro"
                  value={this.props.contaAPagar.valor_atraso}
                  readOnly
                />
              </Col>
              <Col sm={3}>
                <Form.Label>Desconto</Form.Label>
                <FormControl
                  placeholder="Desconto"
                  aria-describedby="basic-addon1"
                  name="desconto"
                  className="dinheiro"
                  value={this.props.contaAPagar.desconto}
                  readOnly
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <Form.Label>Valor Pagamento</Form.Label>
                <FormControl
                  placeholder="Valor Pagamento"
                  aria-describedby="basic-addon1"
                  name="valor_pago"
                  id="valor_pago"
                  className="dinheiro"
                  value={formatter.format(this.props.contaAPagar.valor_pago)}
                  readOnly
                />
              </Col>
              <Col sm={3}>
                <Form.Label>Tipo Pagamento</Form.Label>
                <Form.Control
                  placeholder="Tipo"
                  as="select"
                  name="tipo_pagamento"
                  value={this.props.contaAPagar.forma_pagamento}
                  readOnly
                >
                  <option value="cheque">Cheque</option>
                  <option value="dda">DDA</option>
                  <option value="ddaCheque">DDA + Cheque</option>
                </Form.Control>
              </Col>

              <Col
                sm={3}
                hidden={
                  this.props.contaAPagar.forma_pagamento !== "dda" &&
                  this.props.contaAPagar.forma_pagamento !== "ddaCheque"
                }
              >
                {this.props.contaAPagar.conta_bancaria !== null ? (
                  <>
                    <Form.Label>Conta DDA</Form.Label>
                    <Form.Control
                      placeholder="Conta"
                      as="select"
                      name="conta_bancaria"
                      value={this.props.contaAPagar.conta_bancaria.id}
                    >
                      <option></option>
                      <option value={this.props.contaAPagar.conta_bancaria.id}>
                        {this.props.contaAPagar.conta_bancaria.conta}
                      </option>
                    </Form.Control>
                  </>
                ) : null}
              </Col>
              {/* <Col
                sm={3}
                hidden={
                  this.props.contaAPagar.tipo_pagamento !== "cheque" &&
                  this.props.contaAPagar.tipo_pagamento !== "ddaCheque"
                }
              >
                <Form.Label>Conta Cheque</Form.Label>
                <Form.Control
                  placeholder="Conta"
                  as="select"
                  name="conta_bancaria"
                  onChange={this.alteracaoConta}
                >
                  <option>{this.props.contaAPagar.contas_a_pagars.conta}</option>
                </Form.Control>
              </Col> */}
            </Row>

            <Row hidden={this.props.contaAPagar.tipo_pagamento === "dda"}>
              {this.props.contaAPagar.itens !== null &&
                this.props.contaAPagar.itens !== undefined &&
                this.props.contaAPagar.itens.map((e, i) => (
                  <>
                    <Col sm={3}>
                      <Form.Label>Nº Cheque</Form.Label>
                      <Form.Control
                        placeholder="nº cheque"
                        name="num_cheque"
                        value={e.numero_cheque}
                        readOnly
                      ></Form.Control>
                    </Col>
                    <Col sm={3}>
                      <Form.Label>Valor</Form.Label>
                      <Form.Control
                        placeholder="valor"
                        id={[i]}
                        name={[i]}
                        className="dinheiro"
                        value={e.valor}
                        readOnly
                      ></Form.Control>
                    </Col>
                    <Col sm={6}>
                      <Form.Label>Favorecido</Form.Label>
                      <Form.Control
                        placeholder="favorecido"
                        id={[i]}
                        name={[i]}
                        value={e.favorecido}
                        readOnly
                      ></Form.Control>
                    </Col>
                  </>
                ))}
            </Row>
            <Row>
              {this.props.contaAPagar.banco !== null && (
                <Col sm={6}>
                  <Form.Label>Banco/Agência</Form.Label>
                  <FormControl
                    placeholder="Banco/Agência"
                    aria-describedby="basic-addon1"
                    name="descricao"
                    id="descricao"
                    value={this.props.contaAPagar.banco}
                    disabled={true}
                    readOnly
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col sm={12}>
                <Form.Label>Observação</Form.Label>
                <FormControl
                  placeholder="Observação"
                  aria-describedby="basic-addon1"
                  as="textarea"
                  name="obs"
                  value={this.props.contaAPagar.contas_a_pagars[0]?.observacao}
                  readOnly
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Table celled>
                  <Table.Header>Rateio</Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.HeaderCell width={4}>
                        Centro de Custo
                      </Table.HeaderCell>
                      <Table.HeaderCell width={4}>
                        Elemento de Custo
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>Valor </Table.HeaderCell>
                    </Table.Row>
                  </Table.Body>
                  <Table.Body>
                    {this.props.contaAPagar != null &&
                      this.props.contaAPagar.rateios.map((e, i) => (
                        <Table.Row key={i}>
                          <Table.Cell>
                            <Input
                              placeholder="Adicionar Centro de Custo"
                              name={[i]}
                              value={
                                e.centro_de_custo !== null
                                  ? e.centro_de_custo.descricao
                                  : ""
                              }
                              fluid
                              readOnly
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Input
                              placeholder="Adicionar Elemento de Custo"
                              name={[i]}
                              value={e.elemento_de_custo.descricao}
                              readOnly
                              fluid
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <FormControl
                              placeholder=""
                              aria-describedby="basic-addon1"
                              id={"itens[" + [i] + "].valor"}
                              name={"valorItem" + i}
                              value={e.valor}
                              readOnly
                              className="dinheiro"
                            />
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </Col>
            </Row>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}
