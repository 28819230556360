import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";

export default class ParametrosEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Row>
        <Col sm={12} style={{ paddingBottom: 25, color: "red" }}>
          <h3>
            {`Configuraçõs de envio de email. Alterar somente caso necessário e já
            tiver em mãos os novos dados.`}
          </h3>
        </Col>

        <Col sm={6}>
          <Form.Field>
            <label>Email do usuário</label>
            <input
              placeholder="email@gmail.com"
              value={this.props.email}
              name="email"
              onChange={this.props.alteracao}
            />
          </Form.Field>
          <Form.Field>
            <label>clientId</label>
            <input
              placeholder="clientId"
              value={this.props.clientId}
              name="clientId"
              onChange={this.props.alteracao}
            />
          </Form.Field>
          <Form.Field>
            <label>clientSecret</label>
            <input
              placeholder="clientSecret"
              value={this.props.clientSecret}
              name="clientSecret"
              onChange={this.props.alteracao}
            />
          </Form.Field>
          <Form.Field>
            <label>refreshToken</label>
            <input
              placeholder="refreshToken"
              value={this.props.refreshToken}
              name="refreshToken"
              onChange={this.props.alteracao}
            />
          </Form.Field>
        </Col>
      </Row>
    );
  }
}
