/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Row, Col, Collapse } from "react-bootstrap";
import { Table, Modal, windowclose } from "semantic-ui-react";
import {
  InputLabel,
  TextField,
  IconButton,
  Button,
  Select,
  MenuItem,
  Pagination,
} from "@material-ui/core/";

import { AddCircleOutline, Close } from "@material-ui/icons/";

export default class EventoClube extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      treino_exercicio_id: "",
      nome: null,
      serie: null,
      repeticao: null,
      duracao: new Date().setHours(0, 0, 0, 0),
      peso: 0,
      tempo_descanso: new Date().setHours(0, 0, 0, 0),
      data_lancamento: null,
      data_validade: null,
      status: null,
      aluno: null,
      aluno_id: null,
      professor: null,
      professor_id: null,
      exercicios: [],
      exercicio: null,
      exercicio_id: null,
      pessoas: [],
      listaExercicios: [],
      listaTreinoExercicios: [],
      modalOpen: false,
      messageExercicioAdicionado: false,
      messageExercicioRemovido: false,
      messageTreino: false,
      modalOpenFoto: false,
      srcImagem: null,
      exercicioNome: null,
      exercicioDescricao: null,
      exercicioObservacao: null,
      open: false,
    };
  }

  handleClose = () => {
    this.props.changeModalCadastrarPadrao(false);
  };
  render() {
    return (
      <div className="pagina">
        <Modal
          dimmer="bluring"
          size="large"
          open={this.props.modalAdicionarPadrao}
          onClose={this.handleClose}
          style={{ overflow: "visible" }}
        >
          <div style={{ margin: 10 }}>
            <IconButton
              className="bottomModal"
              onClick={this.handleClose}
              color="secondary"
            >
              <Close />
            </IconButton>
          </div>
          <Modal.Header as="h1">{"Cadastrar Padrões"}</Modal.Header>

          <Modal.Content>
            <Row>
              <Col sm={4}>
                <InputLabel> Nome</InputLabel>

                <TextField
                  fullWidth
                  placeholder="..."
                  variant="standard"
                  name="nome"
                  onChange={this.props.alteracaoModal}
                  value={this.props.nome}
                />
              </Col>
              <Col sm={4}>
                <InputLabel> Data Lançamento</InputLabel>

                <TextField
                  fullWidth
                  variant="standard"
                  placeholder="Data Lançamento"
                  type="date"
                  name="data_lancamento"
                  onChange={this.props.alteracaoModal}
                  value={this.props.data_lancamento}
                />
              </Col>
              <Col sm={4}>
                <InputLabel> Data Validade</InputLabel>

                <TextField
                  fullWidth
                  variant="standard"
                  type="date"
                  name="data_validade"
                  onChange={this.props.alteracaoModal}
                  value={this.props.data_validade}
                />
              </Col>
            </Row>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Grupo Muscular</Table.HeaderCell>
                  <Table.HeaderCell>Exercício </Table.HeaderCell>
                  <Table.HeaderCell>Série</Table.HeaderCell>
                  <Table.HeaderCell>Repetiçao</Table.HeaderCell>
                  <Table.HeaderCell>Peso</Table.HeaderCell>

                  <Table.HeaderCell width={2}>Descanço </Table.HeaderCell>
                  <Table.HeaderCell width={2}>
                    Duração{" "}
                    <IconButton
                      className="bottomModal"
                      color="secondary"
                      onClick={() => this.props.adicionarLinhaExercicio()}
                    >
                      <AddCircleOutline />
                    </IconButton>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Array.from({ length: this.props.linhaAddExercicio }).map(
                  (element, index) => {
                    return (
                      <Table.Row key={index}>
                        <Table.Cell>
                          {" "}
                          <Col>
                            <Select
                              variant="standard"
                              fullWidth
                              name="grupoMuscular"
                              placeholder="Digite para buscar"
                              onChange={(event) =>
                                this.props?.changeSelectGrupoMuscular(
                                  event,
                                  index
                                )
                              }
                            >
                              {this.props?.grupoMusculares.map(
                                (element, index) => {
                                  return (
                                    <MenuItem value={element.id}>
                                      {element.nome}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Select>
                          </Col>
                        </Table.Cell>
                        <Table.Cell>
                          <Col>
                            <Select
                              disabled={
                                !this.props.listaExerciciosSelecionado[index]
                              }
                              variant="standard"
                              fullWidth
                              placeholder="Digite para buscar"
                              onChange={(event) =>
                                this.props?.changeSelectExercicio(event, index)
                              }
                            >
                              {this.props?.exercicios.map((element, indx) => {
                                return (
                                  <MenuItem
                                    hidden={
                                      element?.grupo_muscular_id !==
                                      this.props.grupoMuscularSelecionado
                                    }
                                    value={element.id}
                                  >
                                    {element.nome}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </Col>
                        </Table.Cell>
                        <Table.Cell>
                          <TextField
                            disabled={
                              !this.props.listaExerciciosSelecionado[index]
                            }
                            fullWidth
                            placeholder="..."
                            variant="standard"
                            name="serie"
                            onChange={(event) =>
                              this.props.changeInputExercicios(event, index)
                            }
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <TextField
                            disabled={
                              !this.props.listaExerciciosSelecionado[index]
                            }
                            fullWidth
                            placeholder="..."
                            variant="standard"
                            name="repeticao"
                            onChange={(event) =>
                              this.props.changeInputExercicios(event, index)
                            }
                          />
                        </Table.Cell>

                        <Table.Cell>
                          {" "}
                          <TextField
                            disabled={
                              !this.props.listaExerciciosSelecionado[index]
                            }
                            fullWidth
                            placeholder="..."
                            variant="standard"
                            name="peso"
                            onChange={(event) =>
                              this.props.changeInputExercicios(event, index)
                            }
                          />
                        </Table.Cell>
                        <Table.Cell>
                          {" "}
                          <TextField
                            disabled={
                              !this.props.listaExerciciosSelecionado[index]
                            }
                            fullWidth
                            placeholder="..."
                            variant="standard"
                            name="tempo_descanso"
                            type="time"
                            onChange={(event) =>
                              this.props.changeInputExercicios(event, index)
                            }
                          />
                        </Table.Cell>
                        <Table.Cell>
                          {" "}
                          <TextField
                            disabled={
                              !this.props.listaExerciciosSelecionado[index]
                            }
                            fullWidth
                            placeholder="..."
                            variant="standard"
                            name="duracao"
                            type="time"
                            onChange={(event) =>
                              this.props.changeInputExercicios(event, index)
                            }
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  }
                )}
              </Table.Body>
            </Table>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => this.props.salvarTreinoPadrao()}
              color="primary"
              floated="right"
              style={{ outline: "none" }}
            >
              Salvar
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
