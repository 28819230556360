import React, { Component } from "react";
import { Table, Button, Popup, Pagination, Search } from "semantic-ui-react";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import requisicoes from "../../../utils/Requisicoes";
import { Row, Col, Form } from "react-bootstrap";
import Select from "react-select";

export default class ContaContabilLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      popUpPesquisa: false,
      pages: null,
      valuePesquisa: null,
      centroCustoPesquisa: null,
      elementoCustoPesquisa: null,
      listaCentroCustoOptions: [],
      listaElementoCustoOptions: [],
    };
    this.lista();
    this.buscaCentroCusto();
    this.buscaElementoCusto();
  }

  lista = async (event, data) => {
    if (!data) {
      data = {};
      data.activePage = 1;
    }
    const resposta = await api.post(
      "/contaContabil/lista" + requisicoes.entidade,
      {
        page: data.activePage,
      },
      requisicoes.header
    );

    this.setState({
      lista: resposta.data.docs,
      pages: resposta.data.pages,
    });
  };

  buscaCentroCusto = async (newValue) => {
    let resp = await api.post(
      "/centroDeCusto/lista" + requisicoes.entidade,
      { pesquisa: newValue != null ? newValue : "" },
      requisicoes.header
    );

    let lista = [];
    lista.push({ label: "Todos", value: 0 });
    for (let i = 0; i < resp.data.length; i++) {
      const element = resp.data[i];
      lista.push({
        label: element.descricao,
        value: element.id,
      });
    }
    this.setState({ listaCentroCustoOptions: lista });
  };
  buscaElementoCusto = async (newValue) => {
    let resp = await api.post(
      "/elementoDeCusto/pesquisa" + requisicoes.entidade,
      /* aqui chamava a função lista, mas ela retorna apenas 15 itens
      tentei usar o pesquisa também e passando parametro newValue como busca, mas não funcionou,
      pois o select só busca depois que seleciona, tentei usar dropdown também e não funcionou
      */
      { pesquisa: newValue != null ? newValue : "" },
      requisicoes.header
    );

    let lista = [];
    lista.push({ label: "Todos", value: 0 });
    for (let i = 0; i < resp.data.length; i++) {
      const element = resp.data[i];
      lista.push({
        label: element.descricao,
        value: element.id,
      });
    }

    this.setState({
      listaElementoCustoOptions: lista,
    });
  };
  /*===================== ALTERAÇÃO =====================*/

  excluir = async (index) => {
    if (
      !window.confirm(
        "A exclusão de uma conta contábil impactará na perda da movimentação ligada diretamente a ela. \nTem certeza que deseja excluir a conta?"
      )
    ) {
      return;
    }

    try {
      let lista = [...this.state.lista];

      await api.post(
        "/contaContabil/excluir" +
        requisicoes.entidade +
        "&id=" +
        lista[index].id,
        "",
        requisicoes.header
      );

      lista.splice(index, 1);
      this.setState({ lista });
    } catch (error) {
      alert("Não foi possível excluir a conta contábil.");
    }
  };

  pesquisa = async (pesquisa, centro_id_changed, elemento_id_changed) => {
    this.setState({ pesquisando: true, popUpPesquisa: false });
    if (pesquisa) {
    }

    // if (
    //   pesquisa === null ||
    //   (pesquisa === "" && centro_id_changed === null) ||
    //   (centro_id_changed === 0 && elemento_id_changed === null) ||
    //   elemento_id_changed === 0
    // ) {
    // this.lista();
    // this.setState({ pesquisando: false, popUpPesquisa: false });
    // } else {
    // lista
    await api
      .post(
        "/contaContabil/pesquisaDetalhada" + requisicoes.entidade,
        {
          pesquisa: pesquisa !== null ? pesquisa : "",
          centro_id:
            centro_id_changed !== null
              ? centro_id_changed
              : this.state.centroCustoPesquisa,
          elemento_id:
            elemento_id_changed !== null
              ? elemento_id_changed
              : this.state.elementoCustoPesquisa,
        },
        requisicoes.header
      )
      .then((resp) => {
        if (resp.data.length === 0) {
          this.setState({ popUpPesquisa: true });
        }
        this.setState({
          lista: resp.data,
          pesquisando: false,
        });
      });
    // }
  };

  render() {
    return (
      <div className="lista">
        <Row>
          <Col sm={6}>
            <Form.Label>Conta</Form.Label>
            <Popup
              content="Nenhum registro encontrado!"
              open={this.state.popUpPesquisa}
              position="bottom center"
              trigger={
                <Search
                  loading={this.state.pesquisando}
                  fluid
                  onSearchChange={(event) => {
                    this.setState({ valuePesquisa: event.target.value });
                    this.pesquisa(
                      event.target.value,
                      this.state.centroCustoPesquisa,
                      this.state.elementoCustoPesquisa
                    );
                  }}
                  showNoResults={false}
                  input={{
                    icon: "search",
                    iconPosition: "left",
                    style: { width: "100%" },
                    input: { style: { borderRadius: "3%" } },
                  }}
                  placeholder="Digite para buscar"
                />
              }
            />
          </Col>
          <Col sm={3}>
            <Form.Label>Centro de Custo</Form.Label>
            <Select
              as="select"
              name="centroCusto"
              //checked={this.state.tipo}
              id="centroCusto"
              defaultValue={{ label: "Todos", value: 0 }}
              required
              //onKeyDown={this.selectBuscaServicos}
              onChange={(event) => {
                this.setState({ centroCustoPesquisa: event.value });
                //this.buscaCentroCusto(event.value);
                this.pesquisa(
                  this.state.valuePesquisa,
                  event.value,
                  this.state.elementoCustoPesquisa
                );
              }}
              options={this.state.listaCentroCustoOptions}
            ></Select>
          </Col>
          <Col sm={3}>
            <Form.Label>Elemento de Custo</Form.Label>
            <Select
              as="select"
              name="elementoCusto"
              //checked={this.state.tipo}
              id="elementoCusto"
              defaultValue={{ label: "Todos", value: 0 }}
              required
              //onKeyDown={this.selectBuscaServicos}
              onChange={(event) => {
                this.setState({ elementoCustoPesquisa: event.value });
                //this.buscaElementoCusto(event.value);
                this.pesquisa(
                  this.state.valuePesquisa,
                  this.state.centroCustoPesquisa,
                  event.value
                );
              }}
              options={this.state.listaElementoCustoOptions}
            ></Select>
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Código</Table.HeaderCell>
              <Table.HeaderCell>Descrição</Table.HeaderCell>
              <Table.HeaderCell>Centro de Custo</Table.HeaderCell>
              <Table.HeaderCell>Elemento de Custo</Table.HeaderCell>
              <Table.HeaderCell width={2} textAlign="center">
                <Link to={{ pathname: "/contaContabil" }}>
                  <Button positive icon="add" size="tiny"></Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.lista.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>{e.codigo}</Table.Cell>
                <Table.Cell>{e.descricao}</Table.Cell>
                <Table.Cell>{e.centro_descricao}</Table.Cell>
                <Table.Cell>{e.elemento_descricao}</Table.Cell>
                <Table.Cell width={2} textAlign="center">
                  {this.props.permissoes.find(
                    (e) => e.nome === "excluir_conta_contabil"
                  ) && (
                      <Button
                        negative
                        size="tiny"
                        onClick={() => this.excluir(i)}
                        icon="trash alternate outline"
                      ></Button>
                    )}
                  <Link
                    to={{ pathname: "/contaContabil", query: { id: e.id } }}
                  >
                    <Button primary size="tiny" icon="edit outline"></Button>
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row verticalAlign="middle" textAlign="center">
              <Table.HeaderCell colSpan={5}>
                <Pagination
                  boundaryRange={0}
                  defaultActivePage={1}
                  ellipsisItem={null}
                  siblingRange={1}
                  totalPages={this.state.pages !== null ? this.state.pages : 1}
                  onPageChange={this.lista}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );
  }
}
