import React, { Component } from "react";
import { Table, Button, Header, Card } from "semantic-ui-react";
import { Col, Row, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";

// requisicao
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

// utils
import moment from "moment";

export default class TurmasLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaTurmas: [],
      grupoServicoTurma: false,
      dataBuscaTurma: moment().format("YYYY"),
      turmas: false,
    };
  }

  alteracao = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  voltarTurmas = async () => {
    this.setState({
      listaTurmas: [],
      turmas: false,
    });
  };

  carregaListaTurmas = async (id) => {
    const resposta = await api.post(
      "/servico/turmaPorGrupoServicoeData" + requisicoes.entidade,
      { id: id, data: this.state.dataBuscaTurma },
      requisicoes.header
    );
    this.setState({
      listaTurmas: resposta.data,
      grupoServicoTurma: id,
      turmas: true,
    });
  };

  render() {
    return (
      <div style={{ paddingTop: 10 }}>
        {this.state.turmas !== false ? (
          <div>
            <Row>
              <Col sm={5}>
                <FormControl
                  aria-describedby="basic-addon1"
                  name="dataBuscaTurma"
                  defaultValue={this.state.dataBuscaTurma}
                  onChange={this.alteracao}
                />
              </Col>
              <Col sm={1}>
                <Button
                  type="button"
                  size="tiny"
                  primary
                  icon="search"
                  onClick={() =>
                    this.carregaListaTurmas(this.state.grupoServicoTurma)
                  }
                ></Button>
              </Col>
              <div style={{ textAlign: "right" }}>
                <Button
                  color="blue"
                  icon="th"
                  size="small"
                  content="Voltar"
                  onClick={this.voltarTurmas}
                ></Button>
              </div>
            </Row>

            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Nome</Table.HeaderCell>
                  <Table.HeaderCell width={1} textAlign="center">
                    <Link to={{ pathname: "/servicosTurmas" }}>
                      <Button positive icon="add" size="tiny"></Button>
                    </Link>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {this.state.listaTurmas.map((e, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>{e.nome}</Table.Cell>
                    <Table.Cell width={1} textAlign="center">
                      <Link
                        to={{
                          pathname: "/servicosTurmas",
                          query: { id: e.id },
                        }}
                      >
                        <Button primary icon="edit" size="tiny"></Button>
                      </Link>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        ) : (
          <Card.Group itemsPerRow={4}>
            {this.props.listaGrupoServico.map((e, i) => (
              <Card onClick={() => this.carregaListaTurmas(e.id)}>
                <Card.Content>
                  <Header>{e.descricao}</Header>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
        )}
      </div>
    );
  }
}
