import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import { CircularProgress } from "@material-ui/core";

// Utils
import moment from "moment-timezone";

// Enum
import IndetificacaoOcorrenciaRetorno from "../../../../../enum/IndetificacaoOcorrenciaRetorno";
import IORetornoDebitoConta from "../../../../../enum/IORetornoDebitoConta";

// Requisicoes
import requisicoes from "../../../../../utils/Requisicoes";
import api from "../../../../../services/api";

export default class InformacaoLancamento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buscando: true,
      listaHistoricos: [],
    };
    this.buscarHistoricoMovimentacaoBancaria();
  }

  buscarHistoricoMovimentacaoBancaria = async () => {
    try {
      const response = await api.post(
        "/lancamentoConta/historicoMovimentacaoBancaria" + requisicoes.entidade,
        {
          lancamento_conta_id: this.props.lancamento.id,
        },
        requisicoes.header
      );
      this.setState({ listaHistoricos: response.data });
    } catch (error) {
    } finally {
      this.setState({ buscando: false });
    }
  };

  render() {
    return (
      <>
        {this.state.buscando ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 40,
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing>Código</Table.HeaderCell>
                <Table.HeaderCell>Descricao</Table.HeaderCell>
                <Table.HeaderCell>Data</Table.HeaderCell>
                <Table.HeaderCell>Usuario</Table.HeaderCell>

                <Table.HeaderCell collapsing>Tipo</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.listaHistoricos.length === 0 ? (
                <Table.Row>
                  <Table.Cell colSpan={5}>
                    Não foram encontrados registros para este lançamento.
                  </Table.Cell>
                </Table.Row>
              ) : (
                this.state.listaHistoricos.map((e) => (
                  <Table.Row>
                    <Table.Cell>{e.codigo}</Table.Cell>
                    <Table.Cell>
                      {e.tipo === "remessa"
                        ? "Remessa"
                        : e.tipo === "registro_automatico"
                        ? "Registro Automático"
                        : e.tipo_cobranca === "boleto"
                        ? IndetificacaoOcorrenciaRetorno.find(
                            (e2) => e2.value === e.codigo
                          )?.label
                        : IORetornoDebitoConta.find(
                            (e2) => e2.value === e.codigo
                          )?.label}
                    </Table.Cell>
                    <Table.Cell>{`${moment(e.data).format(
                      "DD/MM/YYYY"
                    )} - ${e.hora.slice(0, 5)}`}</Table.Cell>
                    <Table.Cell>
                      {e.usuario && e.usuario?.nome.slice(0, 40)}
                    </Table.Cell>
                    <Table.Cell>{e.tipo}</Table.Cell>
                  </Table.Row>
                ))
              )}
            </Table.Body>
          </Table>
        )}
      </>
    );
  }
}
