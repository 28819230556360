import React, { Component } from "react";
import { Modal, Table } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import {
  BottomNavigation,
  BottomNavigationAction,
  Paper,
} from "@material-ui/core";
import { Info, History } from "@material-ui/icons";

// Components
import Geral from "./geral";
import Historico from "./historico";
import MovimentacaoBancaria from "./movimentacaoBancaria";

// Utils

export default class InformacaoLancamento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuSelect: 0,
    };
  }

  render() {
    let content = "";
    switch (this.state.menuSelect) {
      case 0:
        content = this.props.lancamento && (
          <Geral lancamento={this.props.lancamento} />
        );
        break;
      case 1:
        content = this.props.lancamento && (
          <Historico lancamento={this.props.lancamento} />
        );
        break;

      case 2:
        content = <MovimentacaoBancaria lancamento={this.props.lancamento} />;
        break;

      default:
        content = <div></div>;
        break;
    }
    return (
      <>
        <Modal
          open={this.props.open}
          onClose={this.props.onClose}
          size={"large"}
          onMount={() =>
            console.log("this.props.lancamento: ", this.props.lancamento)
          }
        >
          <Modal.Content>
            <Modal.Description>{content}</Modal.Description>
          </Modal.Content>
          <Paper
            sx={{ position: "static", bottom: 0, left: 0, right: 0 }}
            elevation={3}
          >
            <BottomNavigation
              showLabels
              value={this.state.menuSelect}
              onChange={(event, newValue) => {
                this.setState({ menuSelect: newValue });
              }}
            >
              <BottomNavigationAction
                label="Informações Gerais"
                icon={<Info />}
              />
              <BottomNavigationAction label="Histórico" icon={<History />} />
              <BottomNavigationAction
                label="Movimentação Bancária"
                icon={<History />}
              />
            </BottomNavigation>
          </Paper>
        </Modal>
      </>
    );
  }
}
