import React, { Component } from "react";
import { Header, Segment } from "semantic-ui-react";
import { Paper, Tabs, Tab } from "@material-ui/core";
import { Col, Row } from "react-bootstrap";

// Componentes
import Requisicao from "./requisicao/index";
import Item from "../../cadastro/item/lista";
import Estrutura from "./estrutura/lista";
import EntradaItem from "./entradaItem/index";
import Inventario from "./inventario/index";

// Icons
import { Icon as Iconify } from "@iconify/react";

import packageVariantIcon from "@iconify-icons/mdi/package-variant";
import formatListChecksIcon from "@iconify-icons/mdi/format-list-checks";
import clipboardTextOutlineIcon from "@iconify-icons/mdi/clipboard-text-outline";
import homeModernIcon from "@iconify-icons/mdi/home-modern";
import warehouseIcon from "@iconify-icons/mdi/warehouse";

export default class Almoxarifado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selecionado:
        localStorage.getItem("pagina_anterior_almoxarifado") ||
        this.props.submenus[0].nome,
    };
  }

  changeTabMenu = async (event, newValue) => {
    localStorage.setItem("pagina_anterior_almoxarifado", newValue);
    this.setState({ selecionado: newValue });
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Almoxarifado
        </Header>
        <Segment>
          <Row style={{ paddingBottom: 10 }}>
            <Col sm={12}>
              <Paper square>
                <Tabs
                  indicatorColor="secondary"
                  textColor="primary"
                  style={{ outline: "none" }}
                  variant="scrollable"
                  onChange={this.changeTabMenu}
                  value={this.state.selecionado}
                >
                  <Tab
                    icon={<Iconify icon={packageVariantIcon} width={24} />}
                    style={{ outline: "none" }}
                    label="Item"
                    value={"item"}
                  ></Tab>
                  <Tab
                    icon={<Iconify icon={formatListChecksIcon} width={24} />}
                    style={{ outline: "none" }}
                    label="Entrada de Itens"
                    value={"entrada_de_itens"}
                  ></Tab>
                  <Tab
                    icon={
                      <Iconify icon={clipboardTextOutlineIcon} width={24} />
                    }
                    style={{ outline: "none" }}
                    label="Requisição"
                    value={"requisicao"}
                  ></Tab>
                  <Tab
                    icon={<Iconify icon={homeModernIcon} width={24} />}
                    style={{ outline: "none" }}
                    label="Estrutura"
                    value={"estrutura"}
                  ></Tab>
                  <Tab
                    icon={<Iconify icon={warehouseIcon} width={24} />}
                    style={{ outline: "none" }}
                    label="Inventário"
                    value={"inventario"}
                  ></Tab>
                </Tabs>
              </Paper>
            </Col>
          </Row>
          <TabPanel
            value={this.state.selecionado}
            index={"item"}
            {...this.props}
          >
            <Item />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"entrada_de_itens"}
            {...this.props}
          >
            <EntradaItem />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"requisicao"}
            {...this.props}
          >
            <Requisicao />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"estrutura"}
            {...this.props}
          >
            <Estrutura />
          </TabPanel>
          <TabPanel
            value={this.state.selecionado}
            index={"inventario"}
            {...this.props}
          >
            <Inventario />
          </TabPanel>
        </Segment>
      </div>
    );
  }
}

function TabPanel(props) {
  const { children, value, index, submenus, ...other } = props;
  return submenus && submenus.find((e) => e.nome === index) ? (
    <div hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </div>
  ) : (
    <div></div>
  );
}
