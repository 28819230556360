import React, { Component } from "react";
import {
  Button,
  Modal,
  Header,
  Icon,
} from "semantic-ui-react";
import { Form as formulario, FormControl } from "react-bootstrap";

export default class ModalCancelar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal open={this.props.modalOpenCancelar} closeIcon size="small">
        <Header icon>
          <Icon name="warning sign" />
          Deseja cancelar a reserva?
        </Header>
        <Modal.Content>
          <formulario.Label>Motivo</formulario.Label>
          <FormControl name="motivo" fluid onChange={this.props.alteracao} />
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            inverted
            onClick={() => this.props.abreModal(false)}
          >
            <Icon name="remove" /> Fechar
          </Button>
          <Button color="green" inverted onClick={() => this.props.cancelar()}>
            <Icon name="checkmark" /> Sim
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
