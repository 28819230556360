/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Table, Segment, Divider, Header, Menu, Icon } from "semantic-ui-react";
import { Form, Row, Col } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import {
  Delete,
  AddCircle,
  Visibility,
  VisibilityOff,
  PlayCircleOutline,
  AddCircleOutline,
  Print,
} from "@material-ui/icons/";
import { Tooltip, IconButton, Button, Pagination, TablePagination } from "@material-ui/core";

import Campeao from "./campeao.ico";
import Medalha from "./medalha.png";
import Pergaminho from "./pergaminho.png";
import moment from "moment-timezone";

import api from "../../../services/api";

import { Link } from "react-router-dom";

import "../styles.css";
// import "../fireWORK.css";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rodadaCadastradas: [],
      infRodada: [],
      pagina: 1,
      totalPaginas: 1
    };
    this.ordenaLista();
    this.listaPartidata()
  }
  ordenaLista = async () => {
    function ordena(a, b) {
      if (a.numero > b.numero) {
        return 1;
      }
      if (a.numero < b.numero) {
        return -1;
      }
      return 0;
    }
    this.props.rodadasCadastradas.sort(ordena);
  };
  changeExibirRodada = async () => {
    if (this.state.exibirRodada) {
      this.setState({ exibirRodada: false });
    } else {
      this.setState({ exibirRodada: true });
    }
  };
  imprimir = async (event, rodada) => {
    try {
      const grupoPorRodada = await api.post(
        "/rodada/geraPdfRodada" + requisicoes.entidade,
        {
          rodada_id: rodada.id,
        },
        requisicoes.header
      );

      const usuarioLogado = await api.post(
        "/usuario/usuarioLogado" + requisicoes.entidade,
        {},
        requisicoes.header
      );

      var localStorage = window.localStorage;
      var token = localStorage.getItem("token_clube");
      const r = await api.get(
        "/rodada/buscaPdfRodada" +
        requisicoes.entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.id
      );
      var oReq = new XMLHttpRequest();

      var URLToPDF =
        r.config.baseURL +
        "rodada/buscaPdfRodada" +
        requisicoes.entidade +
        "&t=Baer " +
        token +
        "&userId=" +
        usuarioLogado.data.id;

      oReq.open("GET", URLToPDF, true);
      oReq.responseType = "blob";
      oReq.onload = function () {
        const file = new Blob([oReq.response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      };
      oReq.send();
    } catch (e) {
      console.log("error", e);
    }
  };
  deleteLista = async (event) => {
    const grupoPorRodada = await api.post(
      "/rodada/excluirRodada" + requisicoes.entidade,
      {
        rodada_id: event.id,
      },
      requisicoes.header
    );
    // this.setState({rodadasCadastradas:grupoPorRodada})
    // this.props.rodadasCadastradas
    window.location.pathname = "/desporto";
  };
  // adicionaListaPartida = async (event, e) => {
  //   console.log("adicionaListaPartida :", event.target);
  //   console.log("adicionaListaPartida 2:", e);
  // };
  listaPartidata = () => {
    this.props.changeModalListaPartida(true);
    this.state.partidasCadastradas = this.props.partidasCadastradas;
    this.setState({ partidasCadastradas: this.props.partidasCadastradas });
  };

  adicionarRodada = async (event) => {
    this.props.adicionaListaPartida(this.props.rodadaId);
    this.props.changeModalAdicionarRodada(true);
    this.props.changeModalListaPartida(false, false);
  };

  proximaPagina = async () => {
    const { pagina, totalPaginas } = this.state;
    if (pagina >= totalPaginas) return;
    const paginaNumero = parseInt(pagina) + 1;
    this.listaPessoa(paginaNumero);
  };

  voltarPagina = async () => {
    const { pagina } = this.state;
    if (pagina <= 1) return;
    const paginaNumero = pagina - 1;
    this.listaPessoa(paginaNumero);
  };

  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Segment>
          <Header>
            {" "}
            <div className="flexSpaceBetween">
              <div sm={5}></div>
              <div>
                {" "}
                {/* {console.log("Rodada:", this.props.listaTimesOpcoes)} */}
                <Table.Footer >
                  <Table.Row verticalAlign="middle" textAlign="center">
                    <Table.HeaderCell sm={5}>

                      <Table.HeaderCell className="geralRodada" colSpan={5}>
                        <Menu className="todaRodada" pagination>
                          <Menu.Item
                            // icon
                            onClick={this.voltarPagina}
                            disabled={parseInt(this.state.pagina) === 1}
                            className="todaRodada"
                          >
                            <Icon className="chevron_left" name="chevron left" />
                          </Menu.Item>
                          {/* <div className="rodada" >
                            RODADAS 1
                          </div>
                          <br></br>
                          <div>
                            (1 de 10 )
                          </div> */}
                          <a className="rodada">
                            {/* <Button className="rodada" > */}
                            <span>RODADA 1</span>
                            <br></br>
                            <span className="radada2">(1 de 10)</span>
                            {/* </Button> */}
                          </a>
                          <Menu.Item
                            // icon
                            onClick={this.proximaPagina}
                            disabled={
                              parseInt(this.state.pagina) ===
                              parseInt(this.state.totalPaginas)
                            }

                          >
                            <Icon name="chevron right" />
                          </Menu.Item>
                        </Menu>
                      </Table.HeaderCell>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>

                <Button
                  hidden={this.props.faseAtual !== "Mata-Mata e Fase-de-Grupo"}
                  style={{ outline: "none", color: "crimson" }}
                  onClick={() => this.props.changeModalIniciarMataMata(true)}
                >
                  {" INICIAR MATA-MATA "}
                </Button>
              </div>
              <div className="flexEnd">
                {/* <div>
                  <Button
                    style={{ outline: "none" }}
                    color="primary"
                    disabled={this.props.listaTimesOpcoes.length === 1}
                    onClick={this.props.gerarRodada}
                  >
                    Gerar Rodada
                    {/* <AddCircleOutline />{" "}
                  </Button>
                </div> */}
                <div>
                  <IconButton
                    style={{ outline: "none" }}
                    color="primary"
                    disabled={this.props.listaTimesOpcoes.length === 1}
                    onClick={() => this.props.changeModalAdicionarRodada(true)}
                  >
                    <AddCircleOutline />{" "}
                  </IconButton>
                </div>

                <div>
                  <Tooltip
                    title={
                      this.state.exibirRodada ? (
                        <h5>Mostrar Inscritos</h5>
                      ) : (
                        <h5>Esconder Inscritos</h5>
                      )
                    }
                    arrow
                    placement="right"
                  >
                    <IconButton
                      color="secondary"
                      style={{ outline: "none" }}
                      onClick={this.changeExibirRodada}
                    >
                      {this.state.exibirRodada ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
            <Divider style={{ marginTop: "-0.13ch" }} />
          </Header>

          {this.props.fase_atual === "Mata-Mata" ? (
            <Table selectable hidden={this.state.exibirRodada} color="blue">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Rodada</Table.HeaderCell>
                  <Table.HeaderCell>Partidas</Table.HeaderCell>
                  <Table.HeaderCell>*</Table.HeaderCell>
                  <Table.HeaderCell>Imprimir</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.rodadasCadastradas !== undefined ||
                  this.props.rodadasCadastradas !== []
                  ? this.props.rodadasCadastradas.map((element, index) => {
                    return (
                      <Table.Row
                        style={{
                          color: "rgb(9, 124, 165)",
                          backgroundColor: "rgba(73, 105, 186, 0.02)",
                        }}
                      >
                        <Table.Cell width={4}>{element.numero}</Table.Cell>
                        <Table.Cell width={4}>
                          {element.partidas.length}
                        </Table.Cell>
                        <Table.Cell width={4}>
                          <IconButton
                            className="botaoTabble"
                            icon
                            style={{ outline: "none" }}
                            size="mini"
                            onClick={() =>
                              this.props.changeModalListaPartida(
                                element,
                                true
                              )
                            }
                          >
                            {" "}
                            <PlayCircleOutline
                              size="small"
                              name="configure"
                              id={element.id}
                            />
                          </IconButton>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                  : ""}
              </Table.Body>
            </Table>
          ) : (
            <Table selectable color="blue">

              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign="right">CONFRONTO</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>DATA MARCADA</Table.HeaderCell>
                  <Table.HeaderCell>HORA</Table.HeaderCell>
                  <Table.HeaderCell>Lançamento</Table.HeaderCell>
                  <Table.HeaderCell>Exclui</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              {/* {console.log("Rodada", this.props.partidasCadastradas)} */}
              <Table.Body>
                {this.props.partidasCadastradas !== undefined ||
                  this.props.partidasCadastradas !== []
                  ? this.props.partidasCadastradas.map((element, index) => {
                    return (
                      <Table.Row
                        style={{
                          color: "rgb(9, 124, 165)",
                        }}
                      >

                        {element.placares.map((elmt, indx) => {
                          return (
                            <Table.Cell
                              textAlign="left"
                              width={3}
                              verticalAlign="middle"
                            >
                              {element.placares[indx].equipe.organizacao.nome +
                                " (" +
                                elmt.pontuacao +
                                ")"}

                            </Table.Cell>

                          );
                        })}


                        <Table.Cell verticalAlign="middle">
                          {moment(element.data_marcada).format("DD/MM/YYYY")}
                        </Table.Cell>

                        <Table.Cell verticalAlign="middle">
                          {element.hora === undefined || element.hora === null
                            ? "Não definido"
                            : element.hora}
                        </Table.Cell>
                        {/* rodadaId={this.state.rodadaId} */}
                        {/* {console.log("Rodada", this.props.rodadaId)} */}
                        <Table.Cell>
                          <Link
                            to={{
                              pathname: "/desporto/partida",
                              query: {
                                infoPartida: element,
                                ida_volta: this.props.ida_volta,
                                todos_contra_todos: this.props
                                  .todos_contra_todos,
                                campeonato_id: this.props.campeonato_id,
                                partida_id: element.id,
                                rodadaId: this.props.rodadaId,
                                tipo_campeonato: this.props.tipo_campeonato,
                                info: this.props.info,
                              },
                            }}
                          >
                            <Button style={{ outline: "none" }} size="mini">
                              <AddCircleOutline color="primary" />
                            </Button>
                          </Link>
                        </Table.Cell>

                        <Table.Cell>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={(e) =>
                              this.props.deletaListaPartida(element)
                            }
                            disabled={element.status === "encerrada"}
                          >
                            <Delete color="error" />
                          </IconButton>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                  : ""}
              </Table.Body>
            </Table>
          )}

          {this.props.listaTimesOpcoes.length === 1 ? (
            <Col>
              <Col
                sm={12}
                style={{
                  backgroundImage: `url(${Medalha})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "50ch",
                  height: "39ch",
                }}
              >
                <div class="pyro">
                  <div class="before"> </div>
                  <div class="after"></div>
                </div>
              </Col>

              <Col
                className="tituloCampeao"
                align="center"
                style={{
                  // backgroundImage: `url(${Pergaminho})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: "3.5ch",
                  backgroundSize: "15ch",
                }}
              >
                {this.props.listaTimesOpcoes[0].label}
              </Col>
            </Col>
          ) : (
            ""
          )}

        </Segment>

      </div>
    );
  }
}
