import React, { Component } from "react";
import {
  Container,
  Header,
  Button,
  Divider,
  Segment,
  Dimmer,
  Loader,
  Modal,
  Icon,
} from "semantic-ui-react";
import { Row, Col, FormControl, Form } from "react-bootstrap";
import api from "../../../services/api";
import { Link } from "react-router-dom";
import LoadingService from "../../../services/LoadingService";
import requisicoes from "../../../utils/Requisicoes";
import Select from "react-select";
import renomearChave from "../../../services/renomearChave";
import renomear2Chaves from "../../../services/renomear2Chaves";

export default class servicoDiversos extends Component {
  constructor(props) {
    LoadingService.load((v) => this.setState({ isLoading: true }));

    super(props);
    this.state = {
      id: null,
      nome: "",
      valor: "",
      gruposServico: [],
      grupo_servico: null,
      grupo_servico_id: null,
      isLoading: false,
      descGrupo: "",
      modalOpen: false,
      centro_de_custo: null,
      centro_de_custo_id: null,
      elemento_de_custo: null,
      elemento_de_custo_id: null,
      bloqueiaExcluir: true,
    };

    this.alteracao = this.alteracao.bind(this);
    this.visualizacao();
    this.listaGrupoServico();
    this.verificaExclusaoServico();
  }

  listaGrupoServico = async () => {
    const resposta = await api.post(
      "/grupoServico/lista" + requisicoes.entidade,
      this.props.location.query,
      requisicoes.header
    );
    this.setState({ gruposServico: resposta.data });
  };

  visualizacao = async () => {
    if (typeof this.props.location.query != "undefined") {
      const resposta = await api.post(
        "/servico/visualiza" +
          requisicoes.entidade +
          "&id=" +
          this.props.location.query.id,
        this.props.location.query,
        requisicoes.header
      );

      this.setState(resposta.data);
    }
  };

  alteracao(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit = (event) => {
    this.submit();
    event.preventDefault();
  };

  submit = async () => {
    let retorno = "";
    console.log(this.state.id);
    if (this.state.id !== null) {
      retorno = await api.put(
        "/servico/editarDiversos" +
          requisicoes.entidade +
          "&id=" +
          this.state.id,
        this.state,
        requisicoes.header
      );
    } else {
      retorno = await api.post(
        "/servico/salvarDiversos" + requisicoes.entidade,
        this.state,
        requisicoes.header
      );
    }
    if (retorno !== null && retorno !== undefined) {
      window.location.href = "/servicos";
    }
  };

  excluir = async () => {
    let retorno0 = "";
    let retorno1 = "";
    let retorno2 = "";
    let retorno3 = "";
    let retorno = "";
    if (this.state.id !== "") {
      retorno0 = await api.post(
        "/horarioServico/excluirPorServico" + requisicoes.entidade,
        {
          servico_id: this.state.id,
        },
        requisicoes.header
      );
      retorno1 = await api.post(
        "/parametrosReservaEfetivada/excluirPorServico" + requisicoes.entidade,
        {
          servico_id: this.state.id,
        },
        requisicoes.header
      );
      retorno2 = await api.post(
        "/parametrosReservaEvento/excluirPorServico" + requisicoes.entidade,
        {
          servico_id: this.state.id,
        },
        requisicoes.header
      );
      retorno3 = await api.post(
        "/parametrosReservaTermos/excluirPorServico" + requisicoes.entidade,
        {
          servico_id: this.state.id,
        },
        requisicoes.header
      );
      retorno = await api.post(
        "/servico/excluir" + requisicoes.entidade + "&id=" + this.state.id,
        {},
        requisicoes.header
      );
    }
    if (retorno != null) {
      window.location.href = "/servicos";
    }
  };

  verificaExclusaoServico = async () => {
    let retorno1 = "";
    let retorno2 = "";
    let retorno3 = "";
    if (this.props.location.query !== undefined) {
      retorno1 = await api.post(
        "/lancamentoConta/lancamentosPorServico" + requisicoes.entidade,
        {
          servico_id: this.props.location.query.id,
        },
        requisicoes.header
      );

      retorno2 = await api.post(
        "/turmaServico/turmaPorServico" + requisicoes.entidade,
        {
          id: this.props.location.query.id,
        },
        requisicoes.header
      );

      retorno3 = await api.post(
        "/lancamentoItem/lancamentosPorServico" + requisicoes.entidade,
        {
          servico_id: this.props.location.query.id,
        },
        requisicoes.header
      );
      if (
        retorno1.data.length > 0 &&
        retorno2.data.length &&
        retorno3.data.length
      ) {
        this.setState({ bloqueiaExcluir: true });
      } else {
        this.setState({ bloqueiaExcluir: false });
      }
    }
  };

  salvaGrupo = async () => {
    const grupo = await api.post(
      "/grupoServico/salvarDescricao" + requisicoes.entidade,
      {
        descGrupo: this.state.descGrupo,
      },
      requisicoes.header
    );
    this.listaGrupoServico();
    this.setState({ grupo_servico: grupo.data.id });
    this.setState({ modalOpen: false });
  };

  abreModal = async (e) => {
    this.setState({ modalOpen: e });
  };

  pesquisaCentroCusto = async (event) => {
    let pesquisa = "";
    if (event.target.value != null) {
      pesquisa = event.target.value;
    }
    api
      .post(
        "/centroDeCusto/pesquisa" + requisicoes.entidade,
        { pesquisa: pesquisa },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomear2Chaves(objeto, "codigo", "descricao", "label");
          lista.push(objeto);
        });
        this.setState({ optionsCC: lista });
      });
  };

  pesquisaElementoCusto = async (event) => {
    let pesquisa = "";
    if (event.target.value != null) {
      pesquisa = event.target.value;
    }
    api
      .post(
        "/elementoDeCusto/pesquisaByContaContabil" + requisicoes.entidade,
        {
          pesquisa: pesquisa,
          centroCusto: this.state.centro_custo_id,
          cad: false,
        },
        requisicoes.header
      )
      .then((resp) => {
        let lista = [];
        resp.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomear2Chaves(objeto, "codigo", "descricao", "label");
          lista.push(objeto);
        });
        this.setState({ optionsEC: lista });
      });
  };

  alteracaoSelectCentroCusto = async (event, id) => {
    this.setState({ centro_de_custo_id: event.value });
    this.setState({ centro_de_custo: event });
  };

  alteracaoSelectElementoCusto = async (event, id) => {
    this.setState({ elemento_de_custo_id: event.value });
    this.setState({ elemento_de_custo: event });
  };

  render() {
    return (
      <div className="pagina">
        {this.state.isLoading ? (
          <div>
            <Header as="h1" dividing>
              Serviço
            </Header>
            <Form onSubmit={this.defineListaEventos}>
              <Row>
                <Col sm={6}>
                  <Form.Label>Nome</Form.Label>
                  <FormControl
                    placeholder="Nome"
                    aria-label="Nome"
                    aria-describedby="basic-addon1"
                    name="nome"
                    required
                    defaultValue={this.state.nome}
                    onChange={this.alteracao}
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label>Valor</Form.Label>
                  <FormControl
                    placeholder="Valor"
                    aria-label="Valor"
                    aria-describedby="basic-addon1"
                    name="valor"
                    required
                    value={this.state.valor}
                    onChange={this.alteracao}
                    type="number"
                  />
                </Col>

                <Col sm={6}>
                  <Form.Label>Grupo De Serviço</Form.Label>
                  <Form.Control
                    as="select"
                    name="grupo_servico_id"
                    checked={this.state.grupo_servico}
                    onChange={this.alteracao}
                    defaultValue={this.state.grupo_servico_id}
                    required
                  >
                    <option></option>
                    {this.state.gruposServico.map((e, i) =>
                      this.state.grupo_servico !== null &&
                      e.id === this.state.grupo_servico ? (
                        <option key={i} value={e.id} selected>
                          {e.descricao}
                        </option>
                      ) : (
                        <option key={i} value={e.id}>
                          {e.descricao}
                        </option>
                      )
                    )}
                  </Form.Control>
                </Col>
                <Col sm={1}>
                  {/* caracter vazio abaixo */}
                  <Form.Label>⠀⠀⠀⠀⠀⠀⠀⠀</Form.Label>
                  <Modal
                    open={this.state.modalOpen}
                    dimmer="blurring"
                    size="small"
                    trigger={
                      <Button
                        type="button"
                        size="tiny"
                        positive
                        icon="add"
                        onClick={(e) => this.abreModal(true)}
                      ></Button>
                    }
                  >
                    <Header icon>
                      <Icon name="add circle" />
                      Grupo Serviço
                    </Header>
                    <Modal.Content>
                      <Form.Label>Descrição Grupo</Form.Label>
                      <FormControl
                        name="descGrupo"
                        fluid
                        onChange={this.alteracao}
                      />
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        color="red"
                        inverted
                        onClick={() => this.abreModal(false)}
                      >
                        <Icon name="remove" /> Fechar
                      </Button>
                      <Button color="green" inverted onClick={this.salvaGrupo}>
                        <Icon name="checkmark" /> Sim
                      </Button>
                    </Modal.Actions>
                  </Modal>
                </Col>
                <Col sm={6}>
                  <Form.Label>Centro de Custo</Form.Label>
                  <Select
                    placeholder="Adicionar Centro de Custo"
                    name="centro_de_custo"
                    onChange={this.alteracaoSelectCentroCusto}
                    options={this.state.optionsCC}
                    onKeyDown={this.pesquisaCentroCusto}
                    defaultInputValue={
                      this.state.centro_de_custo != null
                        ? this.state.centro_de_custo.codigo +
                          " - " +
                          this.state.centro_de_custo.descricao
                        : ""
                    }
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label>Elemento de Custo</Form.Label>
                  <Select
                    placeholder="Adicionar Elemento de Custo"
                    name="elemento_de_custo"
                    onChange={this.alteracaoSelectElementoCusto}
                    options={this.state.optionsEC}
                    onKeyDown={this.pesquisaElementoCusto}
                    defaultInputValue={
                      this.state.elemento_de_custo != null
                        ? this.state.elemento_de_custo.codigo +
                          " - " +
                          this.state.elemento_de_custo.descricao
                        : ""
                    }
                  />
                </Col>
              </Row>
            </Form>
            <Divider />
            <Segment clearing>
              <Header floated="right">
                <Link to="/servicos">
                  <Button type="button">Voltar</Button>
                </Link>
                <Button
                  negative
                  onClick={this.excluir}
                  disabled={this.state.bloqueiaExcluir}
                >
                  Deletar
                </Button>
                <Button positive onClick={this.handleSubmit}>
                  Salvar
                </Button>
              </Header>
            </Segment>
          </div>
        ) : (
          <Dimmer active inverted>
            <Loader content="Carregando" />
          </Dimmer>
        )}
      </div>
    );
  }
}
