/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";

import "../styles.css";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Table striped selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>NOME</Table.HeaderCell>
              {/* <Table.HeaderCell width={2} textAlign="center">
                DURAÇÃO
              </Table.HeaderCell> */}
              <Table.HeaderCell width={1} textAlign="center">
                TITULARES
              </Table.HeaderCell>
              <Table.HeaderCell width={3} textAlign="center">
                ADVERTÊNCIAS
              </Table.HeaderCell>
              <Table.HeaderCell width={2} textAlign="center">
                TAMANHO MÁXIMO
              </Table.HeaderCell>
              <Table.HeaderCell width={2} textAlign="center">
                TAMANHO MÍNIMO
              </Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign="center">
                EDITAR
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          {this.props.modalidadesCadastradas.map((element, index) => (
            <Table.Body>
              <Table.Row key={index}>
                <Table.Cell>{element.nome}</Table.Cell>
                {/* <Table.Cell textAlign="center">{element.duracao}</Table.Cell> */}
                <Table.Cell textAlign="center">
                  {element.quantidade_titulares}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {element.quantidade_advertencia}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {element.tamanho_max_equipe}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {element.tamanho_min_equipe}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <IconButton
                    edge="false"
                    children="none"
                    color="primary"
                    style={{ border: "none" }}
                    size="small"
                    onClick={(e) =>
                      this.props.updateModalidade(e, index, element)
                    }
                  >
                    <Edit />
                  </IconButton>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ))}
        </Table>
      </div>
    );
  }
}
