/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import { Col, Row, Alert } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import {
  Button,
  IconButton,
  MenuItem,
  InputLabel,
  Select,
  TextField,
} from "@material-ui/core";
import AlertTitle from "@material-ui/lab/AlertTitle";
import "../styles.css";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
    };
  }
  handleClose = () => {
    this.props.changeModalAdicionarRodada(false);
  };
  salvar = async (event) => {
    event.preventDefault();
    this.props.adicionarRodadaFasedeGrupo();
    this.props.changeModalListaPartida(true);
  };
  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Modal
          style={{ overflow: "visible" }}
          dimmer="bluring"
          size="large"
          open={this.props.modalAdicionarFasedeGrupos}
          as="form"
          onSubmit={this.salvar}
          onClose={this.handleClose}
        >
          <Modal.Header>
            <IconButton
              onClick={() => this.props.changeModalAdicionarRodada(false)}
              className="bottomModal"
              color="secondary"
            >
              <CloseIcon />
            </IconButton>

            <h6> Adicionar Rodada Fase de Grupos</h6>
          </Modal.Header>
          <Modal.Content>
            {this.props.alertaSalvoError ? (
              <Alert variant="danger" style={{ marginTop: "10px" }}>
                <AlertTitle>Erro ao Salvar a Rodada!</AlertTitle>
                Iremos redirecionar-lhe — <strong>Aguarde!</strong>
              </Alert>
            ) : this.props.alertaSalvoSucesso ? (
              <Alert variant="success" style={{ marginTop: "10px" }}>
                <AlertTitle>Sucesso ao Salvar a Rodada!</AlertTitle>
                Iremos redirecionar-lhe — <strong>Aguarde!</strong>
              </Alert>
            ) : (
              <div>
                <InputLabel id="demo-mutiple-name-label">Grupo </InputLabel>
                <Select
                  variant="standard"
                  fullWidth
                  id="demo-simple-select-filled"
                  placeholder="Grupo"
                  name="grupoSelecionado"
                  onChange={(event) =>
                    this.props.alteracaoGrupoFasedeGrupo(event)
                  }
                  required
                >
                  {this.props.gruposTotais.map((element, index) => {
                    return (
                      <MenuItem value={element.id}>
                        <en>{element.numero_grupo}</en>
                      </MenuItem>
                    );
                  })}
                </Select>
                <Row>
                  <Col sm={7}>
                    <Row>
                      {this.props.listaTimesPorGrupo.length > 0 &&
                        this.props.listaTimesPorGrupo.map((element, index) => {
                          return (
                            <>
                              <Col sm={6} style={{ marginTop: "2ch" }}>
                                <Select
                                  variant="standard"
                                  fullWidth
                                  name="grupoSelecionado"
                                  // required
                                  onChange={(event) =>
                                    this.props.changeSelectTimesFasedeGrupo(
                                      event,
                                      index
                                    )
                                  }
                                >
                                  {this.props.listaTimesPorGrupo.length > 0 &&
                                    this.props.listaTimesPorGrupo.map(
                                      (elmt, indx) => {
                                        return (
                                          <MenuItem value={elmt.equipe_id}>
                                            {elmt.equipe_nome}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                </Select>
                              </Col>
                            </>
                          );
                        })}
                    </Row>
                  </Col>
                  <Col>
                    {this.props.listaTimesPorGrupo.length > 0 &&
                      Array.from({
                        length: this.props.listaTimesPorGrupo.length / 2,
                      }).map((elmt, indx) => {
                        return (
                          <>
                            <Row style={{ marginTop: "0.1%" }}>
                              <Col sm={6}>
                                <TextField
                                  variant="standard"
                                  style={{
                                    marginTop: "2ch",
                                    marginLeft: "6ch",
                                  }}
                                  type="date"
                                  required
                                  onChange={(event) =>
                                    this.props.alteracaoData(indx, event)
                                  }
                                ></TextField>
                              </Col>

                              <Col sm={3}>
                                <TextField
                                  variant="standard"
                                  placeholder="da Partida"
                                  label="Local"
                                  name="local"
                                  onChange={(event) =>
                                    this.props.alteracaoLocal(indx, event)
                                  }
                                  // value={this.props.local}
                                  required
                                />
                              </Col>

                              <Col sm={2}>
                                <TextField
                                  variant="standard"
                                  style={{ marginTop: "2ch" }}
                                  placeholder="da Partida"
                                  Label="Hora"
                                  name="hora"
                                  type="time"
                                  onChange={(event) =>
                                    this.props.alteracaoHora(indx, event)
                                  }
                                  // value={this.props.hora}
                                  required
                                />
                              </Col>
                            </Row>
                          </>
                        );
                      })}
                  </Col>
                </Row>
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="primary"
              floated="right"
              style={{ outline: "none" }}
              type="submit"
            >
              Adicionar Rodada
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
