/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Table, Segment, Divider, Header } from "semantic-ui-react";
import { Form, Row } from "react-bootstrap";
// import Button from "@material-ui/core/Button";
import { Tooltip } from "@material-ui/core";
import {Edit} from "@material-ui/icons/";

import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";

import "../styles.css";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeExibirLista = async () => {
    if (this.state.exibirTime) {
      this.setState({ exibirTime: false });
    } else {
      this.setState({ exibirTime: true });
    }
  };


  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Segment>
          <Header>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: 0,
              }}
            >
              <div> TIMES</div>
              <div>
                <Tooltip
                  title={
                    this.state.exibirTime ? (
                      <h5>Mostrar Inscritos</h5>
                    ) : (
                      <h5>Esconder Inscritos</h5>
                    )
                  }
                  arrow
                  placement="right"
                >
                  <IconButton
                    color="secondary"
                    style={{ outline: "none" }}
                    onClick={this.changeExibirLista}
                  >
                    {this.state.exibirTime ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
            </Row>

            <Divider style={{ marginTop: "-0.13ch" }} />
          </Header>
          <Table selectable hidden={this.state.exibirTime}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nome</Table.HeaderCell>
                <Table.HeaderCell>Resp</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>Editar</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.listaTimeInscritos !== undefined ||
              this.props.listaTimeInscritos !== []
                ? this.props.listaTimeInscritos.map((element, index) => {
                    return (
                      <Table.Row
                        style={{
                          color: "rgb(9, 124, 165)",
                          backgroundColor: "rgba(73, 105, 186, 0.02)",
                        }}
                      >
                        <Table.Cell width={7}>
                          {element.organizacao.nome}
                        </Table.Cell>
                        <Table.Cell width={5}>
                          {element.p_m_d.pessoa.nome}
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>
                        <IconButton
                      disabled={this.state.statusPartida === "encerrada"}
                      style={{ outline: "none" }}
                      color="primary"
                      name="a"
                      onClick={() => this.props.changeModalAdicionaAtleta(element, true)}

                      // onClick={() => this.addLinhaInscricao("tamanhoEquipeA")}
                    >
                      <Edit />{" "}
                    </IconButton>


                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                : ""}
            </Table.Body>
          </Table>
        </Segment>
      </div>
    );
  }
}
