import React, { Component } from "react";
import { Header, Table } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import moment from "moment-timezone";
import TipoCargo from "../../../enum/TipoCargo";

export default class CargosLista extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Row>
        <Col sm={12}>
          <Header as="h3">Cargos</Header>
        </Col>
        <Col sm={12} style={{ paddingTop: 15 }}>
          <Table celled selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Cargo</Table.HeaderCell>
                <Table.HeaderCell>Tipo Cargo</Table.HeaderCell>
                <Table.HeaderCell>Data admissão</Table.HeaderCell>
                <Table.HeaderCell>Data demissão</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.cargos.map((e, i) => (
                <Table.Row key={e.id}>
                  <Table.Cell>{e.cargo.nome}</Table.Cell>
                  <Table.Cell>
                    {e.cargo.tipo
                      ? TipoCargo.find((find) => find.value === e.cargo.tipo)
                          .text
                      : ""}
                  </Table.Cell>
                  <Table.Cell>
                    {e.data_admissao
                      ? moment(e.data_admissao).format("DD/MM/YYYY")
                      : ""}
                  </Table.Cell>
                  <Table.Cell>
                    {e.data_desocupacao
                      ? moment(e.data_desocupacao).format("DD/MM/YYYY")
                      : ""}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Col>
      </Row>
    );
  }
}
