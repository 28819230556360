import React, { Component } from "react";
import { Container, Table, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import api from "../../../../services/api";
import requisicoes from "../../../../utils/Requisicoes";

export default class EstruturaLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      pagina: 1,
      totalPaginas: 1,
    };
    this.listaEstrutura();
  }

  listaEstrutura = async (page = 1) => {
    var resposta = await api.post(
      "/estrutura/lista" + requisicoes.entidade,
      "",
      requisicoes.header
    );
    resposta.data.sort(function (a, b) {
      if (a.codigo > b.codigo) {
        return 1;
      }
      if (a.codigo < b.codigo) {
        return -1;
      }
      return 0;
    });
    this.setState({ lista: resposta.data });
  };

  excluir = async (event) => {
    const retorno = await api.post(
      "/estrutura/excluir" + requisicoes.entidade + "&id=" + event.target.value,
      "",
      requisicoes.header
    );
    if (retorno != null) {
      window.location.href = "/almoxarifado";
    }
  };

  render() {
    return (
      <div className="lista">
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Código</Table.HeaderCell>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell width={1}>
                <Link to={{ pathname: "/estrutura" }}>
                  <Button positive icon="add" size="tiny"></Button>
                </Link>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.lista.map((e, i) => (
              <Table.Row key={i}>
                <Table.Cell>{e.codigo}</Table.Cell>
                <Table.Cell>{e.nome}</Table.Cell>
                <Table.Cell width={1}>
                  <Link to={{ pathname: "/estrutura", query: { id: e.id } }}>
                    <Button primary icon="edit" size="tiny"></Button>
                  </Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer></Table.Footer>
        </Table>
      </div>
    );
  }
}
