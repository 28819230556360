/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import {
  Button,
  IconButton,
  Tooltip,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core/";

import CloseIcon from "@material-ui/icons/Close";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Alert from "@material-ui/lab/Alert";

import TipoPenalidade from "../../../enum/TipoPenalidade";

import "../styles.css";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Modal
          style={{ overflow: "visible" }}
          dimmer="bluring"
          size="large"
          open={this.props.modalAdicionaEvento}
        >
          <Modal.Header>
            {this.props.eventoDescricao +
              " - " +
              TipoPenalidade.find(
                (e) => e.value === this.props.penalidadeDescricao
              )?.label}

            <IconButton
              className="bottomModal"
              color="secondary"
              onClick={() => this.props.adicionarEvento(null, false)}
            >
              <CloseIcon />
            </IconButton>
          </Modal.Header>
          {this.props.alertSalvarEvento ? (
            <Modal.Content>
              {" "}
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                <strong> {this.props.alertSalvarEventoMessage} </strong>
              </Alert>{" "}
            </Modal.Content>
          ) : (
            <Modal.Content>
              <Row>
                <Col sm={4}>
                  <InputLabel id="demo-mutiple-name-label">Equipe</InputLabel>
                  <Select
                    variant="standard"
                    fullWidth
                    name="equipeId"
                    id="equipeId"
                    onChange={(event) => this.props.alteracao(event)}
                  >
                    {this.props.infoEquipes.map((element, index) => {
                      return (
                        <MenuItem value={element.equipe_id}>
                          {element.equipe.organizacao.nome}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Col>
                <Col sm={4}>
                  <Tooltip
                    title={
                      <h5>
                        Jogador Principal, Ex: Quem cometeu a falta, quem fez o
                        gol, quem foi substituido.
                      </h5>
                    }
                    arrow
                    placement="right"
                  >
                    <InputLabel id="demo-mutiple-name-label">
                      Jogador 1º{" "}
                    </InputLabel>
                  </Tooltip>
                  <Select
                    variant="standard"
                    fullWidth
                    name="pessoaPrincipal"
                    onChange={(event) => {
                      this.props.alteracao(event);
                    }}
                  >
                    {this.props.viewEscalacao.length > 0 ? (
                      this.props.viewEscalacao.map((element, index) => {
                        return (
                          <MenuItem
                            hidden={
                              this.props.penalidadeDescricao ===
                                "substituicao" ||
                                this.props.penalidadeDescricao === "pontuacao" ||
                                this.props.penalidadeDescricao === "sempenalidade"
                                ? element.equipe_id !== this.props.equipeId ||
                                element.tipo_vinculo === "Reserva"
                                : this.props.penalidadeDescricao === "falta"
                                  ? element.equipe_id !== this.props.equipeId ||
                                  element.tipo_vinculo === "Reserva"
                                  : element.equipe_id !== this.props.equipeId
                            }
                            value={element.id}
                          >
                            {element.nome +
                              " - " +
                              element.time_nome +
                              " - " +
                              element.tipo_vinculo}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem value={null}>Nenhum atleta inscrito</MenuItem>
                    )}
                  </Select>
                </Col>
                <Col sm={4}>
                  {this.props.penalidadeDescricao === "pontuacao" && (
                    <>
                      <Tooltip
                        title={
                          <h5>
                            Jogador Secundário, Ex: Quem sofreu a falta, quem deu
                            assistência, quem entrou jogar..
                          </h5>
                        }
                        arrow
                        placement="right"
                      >
                        <InputLabel id="demo-mutiple-name-label">
                          Jogador 2º{" "}
                        </InputLabel>
                      </Tooltip>



                      <Select
                        variant="standard"
                        fullWidth
                        name="pessoaSecundaria"
                        onChange={(event) => {
                          this.props.alteracao(event);
                        }}
                      >
                        {this.props.viewEscalacao.length > 0 ? (
                          this.props.viewEscalacao.map((element, index) => {
                            return (
                              <MenuItem
                                hidden={
                                  this.props.penalidadeDescricao === "pontuacao"
                                    ? element.equipe_id !== this.props.equipeId ||
                                    element.tipo_vinculo === "Reserva"
                                    : this.props.penalidadeDescricao ===
                                      "substituicao"
                                      ? element.equipe_id !== this.props.equipeId ||
                                      element.tipo_vinculo === "Titular"
                                      : this.props.penalidadeDescricao ===
                                        "sempenalidade"
                                        ? element.equipe_id === this.props.equipeId ||
                                        element.tipo_vinculo === "Reserva"
                                        : this.props.penalidadeDescricao === "falta"
                                          ? element.equipe_id === this.props.equipeId ||
                                          element.tipo_vinculo === "Reserva"
                                          : ""
                                }
                                value={element.id}
                              >
                                {element.nome +
                                  " - " +
                                  element.time_nome +
                                  " - " +
                                  element.tipo_vinculo}
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem value={null}>Nenhum atleta inscrito</MenuItem>
                        )}
                      </Select>
                    </>
                  )}
                </Col>
              </Row>
              <Row style={{ marginTop: "1.5ch" }}>
                <Col sm={3}>
                  <Tooltip
                    title={
                      <h5>
                        Pontuação do Evento. <br />
                        Ex: 1 Gol(Futebol, Handebol), 1 ponto(Volei), 3
                        pontos(basquete)
                      </h5>
                    }
                    arrow
                    placement="right"
                  >
                    <TextField
                      variant="standard"
                      hidden={this.props.penalidadeDescricao !== "pontuacao"}
                      label="Pontuação"
                      placeholder="Pontuação"
                      name="pontos"
                      onChange={this.props.alteracao}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Modal.Content>
          )}

          <Modal.Actions>
            <Button
              style={{
                outline: "none",
                border: "solid 2px ",
              }}
              color="primary"
              onClick={this.props.salvarEvento}
              disabled={
                (this.props.penalidadeDescricao === "pontuacao" &&
                  this.props.pontos === null) ||
                this.props.equipeId === null ||
                this.props.pessoaPrincipal === null
              }
            >
              {" "}
              salvar evento{" "}
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
