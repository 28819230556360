/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Form, Col, Row } from "react-bootstrap";
import { Header, Segment, Divider, Table } from "semantic-ui-react";
import {
  Snackbar,
  Button,
  Select,
  InputLabel,
  IconButton,
  MenuItem,
  Alert,
  CircularProgress,
} from "@material-ui/core/";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import MuiAlert from "@material-ui/lab/Alert";
import ModalIniciaMataMata from "./ModalIniciaMataMata";
import ModalIniciaFaseDeGrupo from "./ModalIniciaFaseDeGrupo";

import ModalInscricao from "./modalInscricao";
import ListaTimes from "./listaTimes";
import requisicoes from "../../../utils/Requisicoes";
import api from "../../../services/api";

import "../styles.css";


export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizacoes: [],
      pessoas: [],
      timesInscritos: [],
      listaTimeInscritosTabela: [],
      atletasEComissaoTecnica: [],
      atletasInscricao: [],
      participantesPorTime: [],
      IdTimesParticipantes: [],
      timesSeparadosPorGrupo: [],
      gruposTotais: [],
      listaTimesOpcoesTabela: [],
      timesInscritosFaseGrupo: [],
      listaTimeAdicionado: [],
      pessoaSelecionada: [],
      lista: [],

      organizacaoSelecionadaNome: "",
      atletaSelecionadoNome: "",
      mensagemErrorIniciar: "",
      timesPorGrupo: 0,
      verificaLinha: 0,
      numDeGrupos: 0,
      linhaTimesGrupoManual: 1,
      tamanhoInscricaoTime: 1,
      tamanhoInscricaoTime1: 1,
      linhaInscricao: 1,
      rodadas: null,
      organizacaoSelecionadaId: null,
      atletaSelecionadoId: null,
      modalInscricao: false,
      modalGrupoMataMata: false,
      modalGrupoFaseDeGrupo: false,
      todosContraTodos: false,
      idaeVolta: false,
      abreFechaModalInscricao: false,
      alertErro: false,
      alertErrorIniciar: false,
      alertSucessIniciar: false,
      alertTamanhoEquipes: false,
      alertSuccessSalvarTime: false,
      alertErrorSalvarTime: false,
      adicionaOrganizacao: false,
      campeonatoEquipe: [],
      ListaAtletaJaCadastrado: [],
      mensagemSnackBar: { show: false },
    };
    this.listaEquipes();
  }

  listaEquipes = async () => {
    const todosContraTodos = this.props.location.query?.todos_contra_todos;
    const idaeVolta = this.props.location.query?.ida_volta;
    const inscritosModalidade = await api.post(
      "/pessoaModalidadesDesporto/listarAtletaeComissaoTecnicaPorModalidade" +
      requisicoes.entidade,
      {
        modalidade_desporto_id:
          this.props.location.query?.modalidade_desporto_id,
      },
      requisicoes.header
    );
    this.setState({ atletasEComissaoTecnica: inscritosModalidade.data });

    const equipes = await api.post(
      "/equipe/listarEquipePorCampeonato" + requisicoes.entidade,
      {
        campeonato_id: this.props.location.query.campeonato_id,
        modalidade_desporto_id:
          this.props.location.query.info.modalidade_desporto_id,
      },
      requisicoes.header
    );

    // this.state.campeonatoEquipe.push(equipes);
    function ordenaEquipe(a, b) {
      let aAux = a.organizacao?.nome.toLowerCase();
      let bAux = b.organizacao?.nome.toLowerCase();
      if (aAux > bAux) {
        return 1;
      }
      if (aAux < bAux) {
        return -1;
      }
      return 0;
    }
    equipes.data[0].sort(ordenaEquipe);

    const organizacoesLista = await api.post(
      "/organizacao/listar" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    function ordena(a, b) {
      let aAux = a.nome.toLowerCase();
      let bAux = b.nome.toLowerCase();
      if (aAux > bAux) {
        return 1;
      }
      if (aAux < bAux) {
        return -1;
      }
      return 0;
    }
    organizacoesLista.data.sort(ordena);

    const lista = [];
    const listaEquipes = [];
    const atletas = [];
    //for para os times
    for (let index = 0; index < equipes.data[0].length; index++) {
      const element = equipes.data[0];
      const element2 = equipes.data[1];

      lista.push(equipes.data[0][index].id);
      listaEquipes.push({
        value: element[index].id,
        label: element[index].organizacao?.nome,
        nomeAtleta: element2[index]?.pessoa.nome,
        isDisabled: false,
      });
    }

    let listaAux = [];
    for (let i = 0; i < equipes.data[0].length; i++) {
      const element = equipes.data[0];
      listaAux.push({
        id: element[i].id,
        organizacao_id: element[i].organizacao_id,
        nomeOrg: element[i].organizacao?.nome,
        nomeAtleta: element[i].p_m_d?.pessoa.nome,
        equipes_id: element[i].organizacao?.id,
        modalidade_desporto_id: element[i].modalidade_desporto_id,
        campeonato_equipes: element[i].campeonato_equipes,
        organizacao: element[i].organizacao,
        p_m_d: element[i].p_m_d,
      });
    }
    //for para os atletas
    for (let index = 0; index < equipes.data[1].length; index++) {
      atletas.push({
        id: equipes.data[1][index].id,
        nome: equipes.data[1][index].pessoa.nome,
        tipo_vinculo_id: equipes.data[1][index].tipo_vinculo.id,
        tipo_vinculo_descricao: equipes.data[1][index].tipo_vinculo.descricao,
      });
    }

    function ordenaNome(a, b) {
      if (a.nome > b.nome) {
        return 1;
      }
      if (a.nome < b.nome) {
        return -1;
      }
      return 0;
    }
    listaEquipes.sort(ordenaNome);

    var rodada = 0;
    idaeVolta
      ? (rodada = parseInt(lista.length - 1) * 2)
      : todosContraTodos
        ? (rodada = parseInt(lista.length - 1))
        : (rodada = 1);

    this.setState({
      idTimesParticipantes: lista,
      listaTimeInscritosTabela: listaAux,
      listaTimesOpcoesTabela: listaEquipes,
      pessoas: atletas,
      organizacoes: organizacoesLista.data,
      rodadas: rodada,
      campeonatoEquipe: equipes,
    });
  };

  addModalLinhaInscricao = async () => {
    if (
      this.state.verificaLinha <
      this.props.location.query.info.modalidade_desporto.tamanho_max_equipe
    ) {
      this.setState({ linhaInscricao: this.state.linhaInscricao + 1 });
      this.setState({ verificaLinha: this.state.verificaLinha + 1 });
    } else {
      this.setState({ alertTamanhoEquipes: true });

      setTimeout(() => {
        this.setState({ alertTamanhoEquipes: false });
      }, 2500);
    }
  };

  voltar = async () => {
    window.location.href = "/desporto";
  };

  // addLinhaInscricao = async () => {
  //   this.setState({
  //     tamanhoInscricaoTime: this.state.tamanhoInscricaoTime + 1,
  //   });
  // };
  addRowFaseGrupoManual = async () => {
    this.setState({
      linhaTimesGrupoManual: this.state.linhaTimesGrupoManual + 1,
    });
  };

  alteracaoSelectOrganizacao = async (event) => {
    const listaInscritos = [...this.state.timesInscritos];
    listaInscritos[0] = {
      modalidade_desporto_id: this.props.location.query.modalidade_desporto_id,
      nomeOrg: "",
      organizacao_id: event.target.value,
      pessoas_modalidades_desporto_responsavel_id: null,
      nomeAtleta: "",
      status: true,
    };

    this.setState({
      organizacaoSelecionadaId: event.target.value,
      organizacaoSelecionadaNome: event.target.name,
      timesInscritos: listaInscritos,
    });
  };

  alteracaoSelectOrganizacaoEscolhaManualFasedeGrupos = async (
    event,
    grupo,
    index
  ) => {
    const listaInscritos = [...this.state.timesInscritosFaseGrupo];

    if (!listaInscritos[grupo]) {
      listaInscritos[grupo] = {
        grupo_campeonato_equipe: [],
        status: "criado",
        numero_grupo: grupo + 1,
        campeonato_id: this.props.location.query.campeonato_id,
      };
    }
    listaInscritos[grupo].grupo_campeonato_equipe[index] = {
      equipe_id: event.target.value,
      grupo_id: null,
    };
    this.setState({ timesInscritosFaseGrupo: listaInscritos });
  };

  alteracaoSelectPessoa = async (event) => {
    const listaInscritos = [...this.state.timesInscritos];

    for (let i = 0; i < this.state.pessoas.length; i++) {
      const element = this.state.pessoas[i];
      if (element.id === event.target.value) {
        this.state.pessoaSelecionada = element.nome;
      }
    }

    listaInscritos[0].pessoas_modalidades_desporto_responsavel_id =
      event.target.value;
    listaInscritos[0].nomeAtleta = this.state.pessoaSelecionada;

    this.setState({
      timesInscritos: listaInscritos,
      pessoaSelecionada: this.state.pessoaSelecionada,
      atletaSelecionadoId: event.target.value,
    });
  };

  adicionaListaIncricao = async () => {
    this.setState({ adicionaOrganizacao: true });
    for (let i = 0; i < this.state.organizacoes.length; i++) {
      const element = this.state.organizacoes[i];
      if (element.id === this.state.timesInscritos[0].organizacao_id) {
        this.state.listaTimeInscritosTabela.unshift({
          nomeOrg: element.nome,
          nomeAtleta: this.state.pessoaSelecionada,
        });
        this.state.listaTimeAdicionado.push({
          nomeOrg: element.nome,
          nomeAtleta: this.state.pessoaSelecionada,
          organizacao_id: this.state.timesInscritos[0].organizacao_id,
          pessoas_modalidades_desporto_responsavel_id:
            this.state.atletaSelecionadoId,
          modalidade_desporto_id:
            this.state.timesInscritos[0].modalidade_desporto_id,
          status: true,
        });
      }
    }

    this.setState({
      listaTimeInscritosTabela: this.state.listaTimeInscritosTabela,
    });
  };

  adicionaListaAtleta = async () => {
    try {
      let altetaInscricao =
        this.state.atletasInscricao[0].pessoas_modalidades_desporto_id;

      for (let i = 0; i < this.state.pessoas.length; i++) {
        const element = this.state.pessoas[i];

        if (element.id === altetaInscricao) {
          if (
            this.state.participantesPorTime.findIndex(
              (e) => e.pessoas_modalidades_desporto_id === altetaInscricao
            ) !== -1
          ) {
            this.mensagem(false, "Atleta já cadastrado.", 4500);
            return;
          }

          this.state.lista.push({
            nome: element.nome,
            lista: "Teste",
            vinculo: element.tipo_vinculo_descricao,
            equipe_id: this.state.atletasInscricao[0].equipe_id,
            pessoas_modalidades_desporto_id:
              this.state.atletasInscricao[0].pessoas_modalidades_desporto_id,
            status: this.state.atletasInscricao[0].status,
          });
        }
      }

      this.state.participantesPorTime.unshift(...this.state.lista);
      this.setState({
        participantesPorTime: this.state.participantesPorTime,
        atletasInscricao: this.state.atletasInscricao,
      });
      this.state.lista = [];
    } catch (error) {
      console.error("error:", error);
    }
  };
  //modal de inscricao de atleta
  alteracaoSelectAtleta = async (event, index, a) => {
    try {
      var inscricaoA = [...this.state.atletasInscricao];
      inscricaoA[index] = {
        pessoas_modalidades_desporto_id: a.value,
        nome: "",
        equipe_id: this.state.organizacaoSelecionadaId,
        vinculo: "",
        status: "regular",
      };

      this.setState({
        atletaSelecionadoId: a.value,
        atletaSelecionadoNome: a.label,
        atletasInscricao: inscricaoA,
      });
    } catch (error) {
      console.error("error:", error);
    }
  };

  changeModalInscricao = async (props, status) => {
    if (status) {
      // let lista = [];
      // lista.push(...props.campeonato_equipes);

      this.setState({
        organizacaoSelecionadaNome: props.organizacao?.nome,
        organizacaoSelecionadaId: props.campeonato_equipes[0].equipe_id,
        campeonato_id: props.campeonato_equipes[0].campeonato_id,
      });
      const inscritosPorTime = await api.post(
        "/equipe/listaAtletasComissaoPorTime" + requisicoes.entidade,
        {
          modalidade_desporto_id: props.modalidade_desporto_id,
          campeonato_id: props.campeonato_equipes[0].campeonato_id,
          equipe_id: props.campeonato_equipes[0].equipe_id,
        },
        requisicoes.header
      );

      this.setState({
        participantesPorTime: inscritosPorTime.data[0].equipe_pessoas,
        listaParticipantesPorTime: inscritosPorTime.data[0].equipe_pessoas,
      });
      this.setState({
        verificaLinha: inscritosPorTime.data[0].equipe_pessoas.length + 1,
      });
    }
    this.setState({ modalInscricao: status });
    this.setState({ linhaInscricao: 1 });
  };

  alteracao = async (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  changeModalRodadasMataMata = async (status) => {
    this.setState({ modalGrupoMataMata: status });
  };
  changeModalRodadasFaseDeGrupo = async (status) => {
    this.setState({ modalGrupoFaseDeGrupo: status });
    this.setState({ timesInscritosFaseGrupo: [] });
    this.setState({ numDeGrupostimes: 0 });
    this.setState({ PorGrupo: 0 });
  };

  randomTimes = async () => {
    var array = this.state.idTimesParticipantes;
    var currentIndex = this.state.idTimesParticipantes.length,
      temporaryValue,
      randomIndex;

    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    const novoArray = [];
    for (
      var i = 0;
      i < array.length;
      i = i + parseInt(this.state.timesPorGrupo)
    ) {
      novoArray.push(array.slice(i, i + parseInt(this.state.timesPorGrupo)));
    }

    const infoTimes = [];
    const infoGrupos = [];
    var timesInscritosPorGrupo = [];
    var times = [];

    for (let index = 0; index < novoArray.length; index++) {
      infoGrupos.push({
        grupo_campeonato_equipe: [],
        numero_grupo: index + 1,
        campeonato_id: this.props.location.query.campeonato_id,
        status: "criado",
      });
      for (let a = 0; a < novoArray[index].length; a++) {
        infoTimes.push({ timeId: novoArray[index][a], grupo: index + 1 });
      }
    }

    for (
      let index = 0;
      index < this.state.listaTimeInscritosTabela.length;
      index++
    ) {
      for (let a = 0; a < infoTimes.length; a++) {
        if (
          infoTimes[a].timeId === this.state.listaTimeInscritosTabela[index].id
        ) {
          times.push({
            nomeOrg:
              this.state.listaTimeInscritosTabela[index].organizacao.nome,
            timeId: infoTimes[a].timeId,
            grupo: infoTimes[a].grupo,
          });
        }
      }
    }
    timesInscritosPorGrupo = infoGrupos;

    for (let index = 0; index < novoArray.length; index++) {
      for (let indx = 0; indx < times.length; indx++) {
        if (times[indx].grupo === index + 1) {
          timesInscritosPorGrupo[index].grupo_campeonato_equipe.push({
            grupo_id: null,
            equipe_id: times[indx].timeId,
          });
        }
      }
    }

    this.setState({ timesSeparadosPorGrupo: times });
    this.setState({ gruposTotais: infoGrupos });

    this.setState({ timesInscritosFaseGrupo: timesInscritosPorGrupo });
  };

  salvarTime = async (event) => {
    try {
      let listaParticipantesAdicionados =
        this.state.participantesPorTime.filter((e) => !e.id);

      const equipePessoa = await api.post(
        "/equipePessoas/salvar" + requisicoes.entidade,
        {
          props: listaParticipantesAdicionados,
          campeonato_id: this.state.campeonato_id,
        },
        requisicoes.header
      );

      if (equipePessoa.data.length !== listaParticipantesAdicionados.length) {
        this.mensagem(false, "Nem todos os atletas foram salvos.", 1500);
      } else {
        this.setState({ alertSuccessSalvarTime: true });
      }

      setTimeout(() => {
        this.setState({
          modalInscricao: false,
          linhaInscricao: 1,
          atletasInscricao: [],
          alertSuccessSalvarTime: false,
        });
      }, 2000);
    } catch (error) {
      this.setState({ alertErrorSalvarTime: true });

      setTimeout(() => {
        this.setState({ alertErrorSalvarTime: false });
      }, 2000);
    }
  };

  iniciarCampeonatoMataMata = async () => {
    try {
      const retorno = await api.post(
        "/campeonato/iniciaCampeonatoMataMata" + requisicoes.entidade,
        {
          id: this.props.location.query.campeonato_id,
          status: "em andamento",
          ids_times_participantes: this.state.idTimesParticipantes,
          campeonato_id: this.props.location.query.campeonato_id,
          modalidade_desporto_id:
            this.props.location.query.modalidade_desporto_id,
          tamanho_min_equipe:
            this.props.location.query.info.modalidade_desporto
              .tamanho_min_equipe,
        },
        requisicoes.header
      );
      this.setState({ alertSucessIniciar: true });
      setTimeout(() => {
        window.location.href = "/desporto";

        this.setState({ alertSucessIniciar: false });
      }, 2500);
    } catch (error) {
      this.setState({ mensagemErrorIniciar: error.response.data });
      this.setState({ alertErrorIniciar: true });

      setTimeout(() => {
        this.setState({ alertErrorIniciar: false });
      }, 2500);
    }
  };

  iniciarCampeonatoFasedeGrupo = async () => {
    try {
      const retorno = await api.post(
        "/campeonato/iniciaCampeonatoFasedeGrupos" + requisicoes.entidade,
        {
          ids_times_participantes: this.state.idTimesParticipantes,
          campeonato_id: this.props.location.query.campeonato_id,
          modalidade_desporto_id:
            this.props.location.query.modalidade_desporto_id,
          tamanho_min_equipe:
            this.props.location.query.info.modalidade_desporto
              .tamanho_min_equipe,
          grupoCampeonato: this.state.timesInscritosFaseGrupo,
        },
        requisicoes.header
      );

      this.setState({ alertSucessIniciar: true });
      setTimeout(() => {
        window.location.href = "/desporto";

        this.setState({ alertSucessIniciar: false });
      }, 2500);
    } catch (error) {
      console.error("Error: iniciarCampeonatoFasedeGrupo: ", error.response.data);

      this.setState({ mensagemErrorIniciar: error.response.data });
      this.setState({ alertErrorIniciar: true });

      setTimeout(() => {
        this.setState({ alertErrorIniciar: false });
      }, 2500);
    }
  };

  salvar = async (event) => {
    if (this.state.timesInscritos.length > 0) {
      const equipes = await api.post(
        "/campeonato/inscreverEquipes" + requisicoes.entidade,
        {
          equipes: this.state.listaTimeAdicionado,
          campeonato_id: this.props.location.query.campeonato_id,
          status: true,
        },
        requisicoes.header
      );
      this.listaEquipes();
      this.setState({
        timesInscritos: [],
        listaTimeAdicionado: [],
        tamanhoInscricaoTime: [],
        alertErro: false,
        adicionaOrganizacao: false,
      });
    } else {
      this.setState({
        alertErro: true,
        tamanhoInscricaoTime: 1,
        adicionaOrganizacao: false,
      });
    }
    this.state.tamanhoInscricaoTime = 1;
  };

  excluirEquipe = async (event) => {
    let index = this.state.listaTimeInscritosTabela.indexOf(event);
    if (index !== -1) {
      this.state.listaTimeInscritosTabela.splice(index, 1);
    }
    let index2 = this.state.listaTimeAdicionado.indexOf(event);
    if (index2 !== -1) {
      this.state.listaTimeAdicionado.splice(index2, 1);
    }

    let confimar = window.confirm("Tem certeza que deseja excluir?");
    if (confimar) {
      const equipes = await api.post(
        "/equipe/excluirEquipe" + requisicoes.entidade,
        {
          id: event.id,
          nome: event.organizacao?.nome,
        },
        requisicoes.header
      );

      this.setState({
        listaTimeInscritosTabela: this.state.listaTimeInscritosTabela,
        listaTimeAdicionado: this.state.listaTimeAdicionado,
      });
    }
  };

  excluirAtleta = async (event) => {
    let index = this.state.participantesPorTime.indexOf(event);
    if (index !== -1) {
      this.state.participantesPorTime.splice(index, 1);
    }

    let index2 = this.state.participantesPorTime.indexOf(event);
    if (index2 !== -1) {
      this.state.participantesPorTime.splice(index2, 1);
    }

    let confimar = window.confirm("Tem certeza que deseja excluir?");
    if (confimar) {
      const equipes = await api.post(
        "/equipepessoas/deleta" + requisicoes.entidade,
        {
          id: event.id,
          equipe_id: event.equipe_id,
        },
        requisicoes.header
      );

      this.setState({
        listaTimeInscritosTabela: this.state.listaTimeInscritosTabela,
        listaTimeAdicionado: this.state.listaTimeAdicionado,
      });
    }
  };
  mensagem = (positive, content, duration) => {
    this.setState({
      mensagemSnackBar: {
        show: true,
        positive: positive,
        content: content,
        duration: duration || 4000,
      },
    });
  };

  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Form>
          <Segment clearing>
            <Header>
              <div className="flexSpaceBetween">
                <div>
                  {"Configurar Campeonato " +
                    this.props.location.query?.campeonato_id}
                  <Button
                    color="primary"
                    floated="right"
                    variant="contained"
                    onClick={
                      this.props.location.query?.info.fase_atual === "Mata-Mata"
                        ? () => this.changeModalRodadasMataMata(true)
                        : () => this.changeModalRodadasFaseDeGrupo(true)
                    }
                    style={{ outline: "none", marginLeft: 10 }}
                  >
                    Iniciar
                  </Button>
                </div>

                <div>
                  <Button
                    color="primary"
                    floated="right"
                    variant="contained"
                    onClick={this.voltar}
                    style={{ outline: "none" }}
                  >
                    Voltar
                  </Button>
                </div>
              </div>
              <Divider style={{ marginTop: "0.3ch" }} />
            </Header>
          </Segment>
          <Row>
            <Col sm={12}>
              <Segment>
                <Header>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <div>{"Inscrição "}</div>
                  </Row>

                  <Divider style={{ marginTop: "-0.13ch" }} />
                </Header>
                <Row>
                  <Col sm={4}>
                    <InputLabel id="demo-mutiple-name-label">
                      Organização
                    </InputLabel>
                    <Select
                      variant="standard"
                      fullWidth
                      placeholder="Digite para buscar"
                      name="modalidade"
                      onChange={(event) =>
                        this.alteracaoSelectOrganizacao(event)
                      }
                      required
                    >
                      {this.state.organizacoes.map((element) => {
                        return (
                          <MenuItem value={element.id}>{element.nome}</MenuItem>
                        );
                      })}
                    </Select>
                  </Col>

                  <Col sm={4}>
                    <Form.Label>Pessoa Responsável</Form.Label>
                    <Select
                      variant="standard"
                      placeholder="Digite para buscar"
                      name="pessoa"
                      onChange={(event) => this.alteracaoSelectPessoa(event)}
                      disabled={this.state.timesInscritos[0] === undefined}
                      fullWidth
                      required
                    >
                      {this.state.pessoas.map((element) => {
                        return (
                          <MenuItem value={element.id}>{element.nome}</MenuItem>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col sm={-1}></Col>
                  <Col>
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "right",
                        padding: "15px",
                        margin: "1px",
                        // marginLeft: "30",
                      }}
                    >
                      <Col sm={5}>
                        <Button
                          style={{
                            display: "flex",
                            alignItems: "center",
                            outline: "none",
                          }}
                          color="primary"
                          variant="contained"
                          onClick={this.adicionaListaIncricao}
                          disabled={
                            this.state.timesInscritos.length === 0 ||
                            this.state.pessoaSelecionada.length === 0
                          }
                        >
                          Adicionar
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          type="button"
                          style={{ outline: "none" }}
                          color="primary"
                          variant="contained"
                          onClick={() => this.salvar()}
                          disabled={this.state.listaTimeAdicionado.length === 0}
                        >
                          Salvar Equipes
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* {Array.from({ length: this.state.tamanhoInscricaoTime1 }).map(
                  (event, index) => {
                    return (
                      <></>
                      // <Row>
                      //   <Col sm={4}>
                      //     <InputLabel id="demo-mutiple-name-label">
                      //       Organização
                      //     </InputLabel>
                      //     <Select
                      //       variant="standard"
                      //       fullWidth
                      //       placeholder="Digite para buscar"
                      //       name="modalidade"
                      //       onChange={(event) =>
                      //         this.alteracaoSelectOrganizacao(event)
                      //       }
                      //       required
                      //     >
                      //       {this.state.organizacoes.map((element) => {
                      //         return (
                      //           <MenuItem value={element.id}>
                      //             {element.nome}
                      //           </MenuItem>
                      //         );
                      //       })}
                      //     </Select>
                      //   </Col>
                      //   <Col sm={4}>
                      //     <Form.Label>Pessoa Responsável</Form.Label>
                      //     <Select
                      //       variant="standard"
                      //       placeholder="Digite para buscar"
                      //       name="pessoa"
                      //       onChange={(event) =>
                      //         this.alteracaoSelectPessoa(event)
                      //       }
                      //       disabled={
                      //         this.state.timesInscritos[0] === undefined
                      //       }
                      //       fullWidth
                      //       required
                      //     >
                      //       {this.state.pessoas.map((element, index) => {
                      //         return (
                      //           <MenuItem value={element.id}>
                      //             {element.nome}
                      //           </MenuItem>
                      //         );
                      //       })}
                      //     </Select>
                      //   </Col>
                      //   <Col></Col>
                      //   <Col style={{ padding: "flex" }}>
                      //     <Row
                      //       style={{
                      //         display: "flex",
                      //         justifyContent: "space-between",
                      //         alignItems: "right",
                      //         padding: "15px",
                      //         margin: "1px",
                      //       }}
                      //     >
                      //       <Button
                      //         style={{
                      //           display: "flex",
                      //           alignItems: "center",
                      //           outline: "none",
                      //         }}
                      //         color="primary"
                      //         variant="contained"
                      //         onClick={this.adicionaListaIncricao}
                      //         disabled={
                      //           this.state.timesInscritos.length === 0 ||
                      //           this.state.pessoaSelecionada.length === 0
                      //         }
                      //       >
                      //         Adicionar
                      //       </Button>
                      //     </Row>
                      //   </Col>
                      // </Row>
                    );
                  }
                )} */}
              </Segment>
            </Col>

            <Col sm={12}>
              <ListaTimes
                listaTimeInscritosTabela={this.state.listaTimeInscritosTabela}
                changeModalInscricao={this.changeModalInscricao}
                adicionaOrganizacao={this.state.adicionaOrganizacao}
                excluirEquipe={this.excluirEquipe}
              />
            </Col>
          </Row>

          {/* <Segment clearing>
            <Header floated="right">
              <Form>
                <Button
                  type="button"
                  style={{ outline: "none" }}
                  color="primary"
                  onClick={() => this.salvar()}
                  disabled={this.state.listaTimeAdicionado.length === 0}
                >
                  Salvar Equipes
                </Button>
              </Form>
            </Header>
          </Segment> */}

          <ModalInscricao
            modalInscricao={this.state.modalInscricao}
            organizacaoSelecionadaNome={this.state.organizacaoSelecionadaNome}
            changeModalInscricao={this.changeModalInscricao}
            excluirAtleta={this.excluirAtleta}
            atletasEComissaoTecnica={this.state.atletasEComissaoTecnica}
            alteracaoSelectAtleta={this.alteracaoSelectAtleta}
            salvarTime={this.salvarTime}
            addModalLinhaInscricao={this.addModalLinhaInscricao}
            adicionaListaAtleta={this.adicionaListaAtleta}
            linhaInscricao={this.state.linhaInscricao}
            participantesPorTime={this.state.participantesPorTime}
            alertTamanhoEquipes={this.state.alertTamanhoEquipes}
            alertErrorSalvarTime={this.state.alertErrorSalvarTime}
            alertSuccessSalvarTime={this.state.alertSuccessSalvarTime}
            atletasInscricao={this.state.atletasInscricao}
          />
          <Snackbar
            open={this.state.alertErro}
            autoHideDuration={6000}
            onClose={() => this.setState({ alertErro: false })}
          >
            <MuiAlert
              onClose={() => this.setState({ alertErro: false })}
              elevation={6}
              variant="filled"
              severity="error"
            >
              Erro ao cadastrar!
            </MuiAlert>
          </Snackbar>

          <ModalIniciaMataMata
            modalGrupoMataMata={this.state.modalGrupoMataMata}
            changeModalRodadasMataMata={this.changeModalRodadasMataMata}
            iniciarCampeonatoMataMata={this.iniciarCampeonatoMataMata}
            mensagemErrorIniciar={this.state.mensagemErrorIniciar}
            alertErrorIniciar={this.state.alertErrorIniciar}
            alertSucessIniciar={this.state.alertSucessIniciar}
          />

          <ModalIniciaFaseDeGrupo
            modalGrupoFaseDeGrupo={this.state.modalGrupoFaseDeGrupo}
            changeModalRodadasFaseDeGrupo={this.changeModalRodadasFaseDeGrupo}
            listaTimeInscritosTabela={this.state.listaTimeInscritosTabela}
            randomTimes={this.randomTimes}
            alteracao={this.alteracao}
            changeCheck={this.changeCheck}
            timesSeparadosPorGrupo={this.state.timesSeparadosPorGrupo}
            gruposTotais={this.state.gruposTotais}
            timesPorGrupo={this.state.timesPorGrupo}
            alteracaoSelectOrganizacaoEscolhaManualFasedeGrupos={
              this.alteracaoSelectOrganizacaoEscolhaManualFasedeGrupos
            }
            numDeGrupos={this.state.numDeGrupos}
            linhaTimesGrupoManual={this.state.linhaTimesGrupoManual}
            addRowFaseGrupoManual={this.addRowFaseGrupoManual}
            iniciarCampeonatoFasedeGrupo={this.iniciarCampeonatoFasedeGrupo}
            alertErrorIniciar={this.state.alertErrorIniciar}
            mensagemErrorIniciar={this.state.mensagemErrorIniciar}
            alertSucessIniciar={this.state.alertSucessIniciar}
          />
        </Form>

        <Snackbar
          open={this.state.mensagemSnackBar.show}
          autoHideDuration={this.state.mensagemSnackBar?.duration}
          anchorOrigin={{ vertical: "botton", horizontal: "center" }}
          onClose={() =>
            setTimeout(() => {
              this.setState({
                mensagemSnackBar: {
                  ...this.state.mensagemSnackBar,
                  show: false,
                },
              });
            }, 500)
          }
        >
          <Alert
            onClose={() =>
              setTimeout(() => {
                this.setState({
                  mensagemSnackBar: {
                    ...this.state.mensagemSnackBar,
                    show: false,
                  },
                });
              }, 500)
            }
            severity={
              this.state.mensagemSnackBar?.positive ? "success" : "error"
            }
            sx={{ width: "100%" }}
          >
            {this.state.mensagemSnackBar?.content}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}
