import React, { Component } from "react";
import {
  Container,
  Header,
  Button,
  Divider,
  Segment,
  FormField,
  Input,
  Table,
  Dropdown,
} from "semantic-ui-react";

import api from "../../../services/api";
import { Link } from "react-router-dom";
import { Col, Form, FormControl, Row } from "react-bootstrap";
import { parseISO, format } from "date-fns";
import requisicoes from "../../../utils/Requisicoes";

export default class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      descricao: null,
      estoque_minimo: null,
      entradas: [],
      optionsAI: [
        { key: "ativo", value: true, text: "Ativo" },
        { key: "inativo", value: false, text: "Inativo" },
      ],
      ativo: null,
      unidade_medida: null,
      estoque_atual: null,
      quantidade: null,
      maxId: null,
    };
    this.alteracao = this.alteracao.bind(this);

    console.log("this.props.location.query: ", this.props.location.query);
    if (this.props.location.query !== undefined) {
      this.visualiza();
    } else {
      this.buscarCodigoMaxItem();
    }
  }
  buscarCodigoMaxItem = async () => {
    const codigo = await api.post(
      "/item/maxCodigoItem" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ maxId: parseInt(codigo.data) + 1 });
  };

  alteracaoSelectAtivo = async (event, id) => {
    this.setState({ [id.name]: id.value });
    console.log(" alteracaoSelectAtivo", id.value);
  };

  alteracao(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  visualiza = async () => {
    const retorno = await api.post(
      "/item/visualiza" + requisicoes.entidade,
      { id: this.props.location.query.id },
      requisicoes.header
    );
    const retornoEntradas = await api.post(
      "/entradaItens/buscaPorItem" + requisicoes.entidade,
      { id: this.props.location.query.id },
      requisicoes.header
    );
    this.setState(retorno.data);
    this.setState({ entradas: retornoEntradas.data });
  };

  handleSubmit = (event) => {
    this.submit();
    event.preventDefault();
  };

  submit = async () => {
    if (this.state.ativo == "ativo") {
      this.setState({ ativo: true });
    } else {
      this.setState({ ativo: false });
    }
    const dados = this.state;
    let retorno = "";
    if (this.state.id === null) {
      retorno = await api.post(
        "/item/salvar" + requisicoes.entidade,
        dados,
        requisicoes.header
      );
    } else {
      retorno = await api.post(
        "/item/editar" + requisicoes.entidade,
        dados,
        requisicoes.header
      );
    }
    if (retorno !== null) {
      window.location.href = "/almoxarifado";
    }
  };

  excluir = async () => {
    const dados = this.state;
    let retorno = "";
    retorno = await api.post(
      "/item/excluir" + requisicoes.entidade,
      dados,
      requisicoes.header
    );
    if (retorno !== null) {
      window.location.href = "/almoxarifado";
    }
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Item
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col sm={1}>
              <Form.Label>Codigo</Form.Label>
              <FormField
                placeholder="Codigo"
                name="codigo"
                control={Input}
                required
                value={this.state.maxId || this.state.id}
                fluid
                disabled
              />
            </Col>
            <Col sm={3}>
              <Form.Label>Descrição</Form.Label>
              <FormField
                placeholder="Descrição"
                name="descricao"
                control={Input}
                onChange={this.alteracao}
                required
                defaultValue={this.state.descricao}
                fluid
              />
            </Col>
            <Col sm={2}>
              <Form.Label>Unidade de medida</Form.Label>
              <FormField
                placeholder=""
                id={"unidade_medida"}
                name={"unidade_medida"}
                control={Input}
                onChange={this.alteracao}
                defaultValue={this.state.unidade_medida}
                fluid
              />
            </Col>
            <Col sm={2}>
              <Form.Label>Ativo/Inativo</Form.Label>
              <Dropdown
                placeholder="Ativo/Inativo"
                fluid
                selection
                name="ativo"
                options={this.state.optionsAI}
                onChange={this.alteracaoSelectAtivo}
                value={this.state.ativo}
              />
            </Col>
            <Col sm={2}>
              <Form.Label>Estoque Minimo</Form.Label>
              <FormControl
                placeholder="Estoque Minimo"
                aria-label="Estoque Minimo"
                type="price"
                aria-describedby="basic-addon1"
                name="estoque_minimo"
                onChange={this.alteracao}
                defaultValue={this.state.estoque_minimo}
              />
            </Col>
            <Col sm={2}>
              <Form.Label>Estoque Atual</Form.Label>
              <FormControl
                placeholder="Estoque Atual"
                aria-label="Estoque Atual"
                type="price"
                aria-describedby="basic-addon1"
                name="quantidade"
                onChange={this.alteracao}
                readOnly={this.state.id != null}
                defaultValue={this.state.quantidade}
              />
            </Col>
          </Row>
          {this.state.entradas.length > 0 && (
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Ultima Compra</Table.HeaderCell>
                  <Table.HeaderCell>Preço</Table.HeaderCell>
                  <Table.HeaderCell>Quantidade</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.entradas.map((e, i) => (
                  <Table.Row key={i}>
                    <Table.Cell>
                      {e.data !== null &&
                        format(parseISO(e.data), "dd/MM/yyyy")}
                    </Table.Cell>
                    <Table.Cell>
                      {e.valor_unidade !== null
                        ? parseFloat(e.valor_unidade).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        : parseFloat(0).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </Table.Cell>
                    <Table.Cell>
                      {e.quantidade !== null ? e.quantidade : 0}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              <Table.Footer></Table.Footer>
            </Table>
          )}
          <Divider />
          <Segment clearing>
            <Header floated="right">
              <Link to="/almoxarifado">
                <Button type="button">Voltar</Button>
              </Link>
              <Button positive type="submit">
                Salvar
              </Button>
              {this.state.id !== null && (
                <Button negative type="delete" onClick={this.excluir}>
                  Deletar
                </Button>
              )}
            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}
