import React, { Component } from "react";
import {
  Table
} from "semantic-ui-react";

export default class Dependentes extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Nome</Table.HeaderCell>
            <Table.HeaderCell>Login</Table.HeaderCell>
            <Table.HeaderCell>Grau Parentesco</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.dependentes !== undefined && this.props.dependentes.map((e, i) => (
            <Table.Row key={i}>
              <Table.Cell>{e.pessoa.label}</Table.Cell>
              <Table.Cell>{e.login}</Table.Cell>
              <Table.Cell>{e.grau_parentesco}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

      </Table>
    );
  }
}
