/* eslint-disable no-unused-vars */
import React, { Component } from "react";

import { Modal, Image } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import { Button, TextField, CircularProgress } from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";

import { CloudUpload } from "@material-ui/icons/";

import api from "../../../services/api";

import { storage } from "../../../config/firebase";

export default class ModalEnviarLogoTimes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: "",
      alertaUpdate: false,
      arquivo: null,
      enviando: false,
      nomeModalidade: "",
      srcImage: "",
      srcImagemVazia: false,
      estadoAltera: "",
      deletar: false,
    };
  }

  buscaImagens = async () => {
    await storage
      .ref(
        `${process.env.REACT_APP_ENVIRONMENT === "production"
          ? "producao"
          : "hml"
        }/${window.localStorage.getItem("e_clube")}/logoTimes`
      )
      .child(`${this.props.modalidade_id}.png`)
      .getDownloadURL()
      .then((url) => {
        setTimeout(() => {
          this.setState({ srcImage: url });
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          this.setState({ srcImage: "", srcImagemVazia: true });
        }, 2000);
      });
  };

  enviarLogo = async () => {
    console.log("Envia logo")
    if (!this.props.modalidade_id) {
      return;
    }

    this.setState({ enviando: true });

    const uploadTask = storage
      .ref(
        `${process.env.REACT_APP_ENVIRONMENT === "production"
          ? "producao"
          : "hml"
        }/${window.localStorage.getItem("e_clube")}/logoTimes/${this.props.modalidade_id
        }.png`
      )
      .put(this.state.arquivo);

    uploadTask.on(
      "state_changed",
      () => {
        console.log("state change");
      },
      (error) => {
        console.log("error: ", error);
        this.props.changeOpenSnackbar(true, true);
        this.setState({ enviando: false });
      },
      async () => {
        try {
          await api.post(
            "/organizacao/baixarLogoTime" + requisicoes.entidade,
            {
              projeto:
                process.env.REACT_APP_API_URL === undefined
                  ? "hml"
                  : "producao",
              modalidade_id: this.props.modalidade_id,
            },
            requisicoes.header
          );

          this.props.changeOpenSnackbar(true, false);

          this.setState({ enviando: false });
          setTimeout(() => {
            this.props.changeOpenModal(false);
          }, 3000);
        } catch (e) {
          this.props.changeOpenSnackbar(true, true);
          this.setState({ enviando: false });
        }
      }
    );
  };

  verificaArquivo = async (e) => {
    if (e.target.files[0].type !== "image/png") {
      alert("Somente arquivos PNG são aceitos.");
      return;
    }
    if (e.target.files[0].size > 4000000) {
      alert("Arquivo muito grande!");
      return;
    }
    this.setState({ arquivo: e.target.files[0] });
    this.setState({ srcImage: "algo" });
  };

  alteracao = async (event) => {
    this.setState({ nomeModalidade: event.target.value });
  };

  updateOrganizacao = async (event) => {
    event.preventDefault();
    try {
      this.setState({ mensagem: false });
      this.props.changeOpenSnackbar(true, false);

      await api.post(
        "/organizacao/alterar" + requisicoes.entidade,
        { nome: this.state.nomeModalidade, id: this.props.modalidade_id },
        requisicoes.header
      );
      await this.props.alteracaoStateOrganizacao({
        nome: this.state.nomeModalidade,
        id: this.props.modalidade_id,
        acao: "editar",
      });
      setTimeout(() => {
        this.props.changeOpenModal(false);
      }, 3100);
    } catch (error) {
      this.props.changeOpenSnackbar(true, true);
      this.setState({ mensagemErro: false });
    }
  };

  deletaOrganizacao = async (event) => {
    event.preventDefault();
    try {
      this.state.deletar = true;
      this.setState({ mensagem: false });
      this.props.changeOpenSnackbar(true, false);
      this.setState({ alertaUpdate: true });
      await api.post(
        "/organizacao/deleta" + requisicoes.entidade,
        { nome: this.state.nomeModalidade, id: this.props.modalidade_id },
        requisicoes.header
      );
      await this.props.alteracaoStateOrganizacao({
        nome: this.state.nomeModalidade,
        id: this.props.modalidade_id,
        acao: "excluir",
      });
      setTimeout(() => {
        this.props.changeOpenModal(false);
        this.state.deletar = false;
      }, 3100);
    } catch (error) {
      this.props.changeOpenSnackbar(true, true);
      this.setState({ mensagemErro: false });
    }
  };

  render() {
    return (
      <Modal
        size="tiny"
        open={this.props.openModal}
        onUnmount={() => {
          this.setState({ arquivo: null, srcImage: "", srcImagemVazia: false });
          this.props.changeOpenModal(false);
        }}
        onMount={() => {
          this.setState({
            srcImage: "",
            nomeModalidade: this.props.modalidade_nome,
            idModadalidade: this.props.modalidade_id,
          });
          this.buscaImagens();
        }}
      >
        <Modal.Header>Editar Organização</Modal.Header>
        <Modal.Content>
          <Row>
            <Col>
              <TextField
                placeholder="Alterar nome"
                onChange={(e) => this.alteracao(e)}
                label="Nome Da Organização"
                required
                value={this.state.nomeModalidade}
              />
            </Col>

            <Col>
              {this.state.srcImage === "" ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "150px",
                    border: 1,
                    borderColor: "black",
                    borderStyle: "solid",
                  }}
                >
                  {!this.state.srcImagemVazia && <CircularProgress />}
                </div>
              ) : (
                <Image
                  width="150px"
                  height="150px"
                  centered
                  src={
                    this.state.arquivo === null
                      ? this.state.srcImage
                      : URL.createObjectURL(this.state.arquivo) //Atualiza imagem na tela
                  }
                ></Image>
              )}

              <div>
                <Col sm={12} style={{ padding: 5 }}>
                  <Button
                    variant="contained"
                    color="inherit"
                    fullWidth
                    component="label"
                    onChange={(e) => this.verificaArquivo(e)}
                    endIcon={!this.state.arquivo && <CloudUpload />}
                  >
                    {this.state.arquivo
                      ? this.state.arquivo.name
                      : "Selecione o arquivo"}

                    <input type="file" hidden accept="image/png" />
                  </Button>
                </Col>
              </div>
            </Col>
          </Row>
        </Modal.Content>
        <Modal.Actions>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={(e) => this.deletaOrganizacao(e)}
              >
                Excluir
              </Button>
            </Col>
            <Col></Col>
            <Col md="auto">
              <Button
                variant="contained"
                color="inherit"
                onClick={() => {
                  this.props.changeOpenModal(false);
                  this.setState({ arquivo: null });
                }}
              >
                Voltar
              </Button>
            </Col>
            <Col md="auto">
              <LoadingButton
                variant="contained"
                onClick={(e) => {
                  if (this.state.arquivo) {
                    this.enviarLogo();
                  }

                  if (
                    this.props.modalidade_nome !== this.state.nomeModalidade
                  ) {
                    this.updateOrganizacao(e);
                  }
                }}
                disabled={this.state.deletar === true}
              >
                Salvar
              </LoadingButton>
            </Col>
            <Col md="auto"></Col>
          </Row>
        </Modal.Actions>
      </Modal>
    );
  }

  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
  };
}
