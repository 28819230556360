import React, { Component } from "react";
import { Menu } from "semantic-ui-react";

import Esportacoes from "./exportacoes/index";
import Parametros from "./parametros/index";

export default class Exportacoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo_clube: window.localStorage.getItem("tipo_clube"),
      selectedMenu: "exportacoes",
    };
  }

  render() {
    let content = "";
    switch (this.state.selectedMenu) {
      case "exportacoes":
        content = <Esportacoes />;
        break;
      case "parametros":
        content = <Parametros />;
        break;
      default:
        break;
    }
    return (
      <div>
        <Menu secondary pointing>
          <Menu.Item
            name="exportacoes"
            active={this.state.selectedMenu === "exportacoes"}
            onClick={this.selectMenu.bind(null, "exportacoes")}
          >
            Exportações
          </Menu.Item>
          <Menu.Item
            name="parametros"
            active={this.state.selectedMenu === "parametros"}
            onClick={this.selectMenu.bind(null, "parametros")}
          >
            Parâmetros
          </Menu.Item>
        </Menu>
        {content}
      </div>
    );
  }
  selectMenu = (newMenu) => {
    this.setState({
      selectedMenu: newMenu,
    });
  };
}
