import React, { Component } from "react";
import { Table, Button } from "semantic-ui-react";
import { Row, Col, Form } from "react-bootstrap";

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import requisicoes from "../../../utils/Requisicoes";
import moment from "moment-timezone";
import api from "../../../services/api";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export default class Relatorio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipo: null,
      data_inicio: null,
      data_final: null,
      lista_lancamentos: [],
      caixa_selecionado: [],
      caixas_options: [],
      banco_selecionado: [],
      banco_options: [],
      buscando: false,
      imprimindo: false,
      totaisServicos: {},
    };
    this.buscaUsuariosCaixas();
  }

  buscaUsuariosCaixas = async () => {
    const caixas = await api.post(
      "/usuario/buscaPorTipoUsuario2" + requisicoes.entidade,
      {
        tipo_usuario: ["caixa", "administrativo"],
        entidade: requisicoes.entidade.slice(3),
      },
      requisicoes.header
    );
    this.setState({ caixas_options: caixas.data });
  };

  alteracao = async (event) => {
    if (event.target.value === "retorno") {
      this.state.tipo = "";
      this.listaInstituicoes();
    } else {
      this.state.tipo = "";
      this.buscaUsuariosCaixas();
    }
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === "tipo") {
      this.setState({ lista_lancamentos: [] });
    }
  };

  buscaLancamentosCaixa = async () => {
    let optionsSelect = {
      data_inicial: moment(this.state.data_inicio).toDate(),
      data_final: moment(this.state.data_final).toDate(),
      tipo: this.state.tipo,
    };
    if (this.state.caixa_selecionado.indexOf(0) === -1) {
      optionsSelect.caixa_id = this.state.caixa_selecionado;
    }
    const r = await api.post(
      "/caixa/buscaDataTipoOperacao" + requisicoes.entidade,
      optionsSelect,
      requisicoes.header
    );

    let lista = [];
    for (let i = 0; i < r.data.length; i++) {
      const element = r.data[i];

      element.lancamento_contas.forEach((element2) => {
        if (
          element2.tipo_lancamento === this.state.tipo &&
          ((this.state.data_final === null &&
            Date.parse(moment(element2.data_emissao).format("YYYY-MM-DD")) >=
            Date.parse(
              moment(this.state.data_inicio).format("YYYY-MM-DD")
            )) ||
            (Date.parse(moment(element2.data_emissao).format("YYYY-MM-DD")) >=
              Date.parse(moment(this.state.data_inicio).format("YYYY-MM-DD")) &&
              Date.parse(moment(element2.data_emissao).format("YYYY-MM-DD")) <=
              Date.parse(moment(this.state.data_final).format("YYYY-MM-DD"))))
        ) {
          element2.pessoa_caixa = element.pessoa_caixa;
          lista.push(element2);
        }
      });
    }
    lista.sort((a, b) => {
      return Date.parse(a.data_emissao) - Date.parse(b.data_emissao);
    });
    this.setState({ lista_lancamentos: lista });
  };

  buscaLancamentoPorData = async () => {
    let optionsSelect = {
      data_inicial_abertura: moment(this.state.data_inicio).toDate(),
      data_final_abertura: moment(this.state.data_final).toDate(),
    };

    if (this.state.banco_selecionado.indexOf(0) === -1) {
      optionsSelect.banco_id = this.state.banco_selecionado;
    }
    this.setState({ buscando: false });

    const buscaLancamento = await api.post(
      "/caixa/buscaLancamentoPorData" + requisicoes.entidade,
      { optionsSelect },
      requisicoes.header
    );

    this.setState({ buscando: false });
    var totaisServicos = {
      total_pago: 0.0,
    };
    // let valorPago = 0.0;
    // let nomeBanco = "";
    // let dataPagamento = "";
    // let lista_lancamento = [];
    // let teste = "";
    // for (let i = 0; i < buscaLancamento.data.length; i++) {
    //   const element = buscaLancamento.data[i];

    //   nomeBanco = element?.conta_bancaria?.banco?.nome;
    //   teste = lista_lancamento.indexOf(nomeBanco) === -1;
    //   if (lista_lancamento.indexOf(nomeBanco) === -1) {
    //     lista_lancamento.push({
    //       nomeBanco: element?.conta_bancaria?.banco?.nome,
    //     });
    //   }
    //   dataPagamento = element.data_pagamento;
    //   totaisServicos.total_pago += parseFloat(element.valor_pago);
    // }

    buscaLancamento.data.sort(function (a, b) {
      return b.total_pago - a.total_pago;
    });
    this.setState({
      lista_lancamentos: buscaLancamento.data,
      totaisServicos: totaisServicos,
    });
  };

  buscaCaixas = async () => {
    let optionsSelect = {
      data_inicial_abertura: moment(this.state.data_inicio).toDate(),
      data_final_abertura: moment(this.state.data_final).toDate(),
    };
    if (this.state.caixa_selecionado.indexOf(0) === -1) {
      optionsSelect.caixa_id = this.state.caixa_selecionado;
    }
    const r = await api.post(
      "/caixa/buscaDataTipoOperacao" + requisicoes.entidade,
      optionsSelect,
      requisicoes.header
    );
    if (r.data.length > 0) {
      for (var i = 0; i < r.data.length; i++) {
        let valor_final = 0;
        if (r.data[i].lancamento_contas.length > 0) {
          for (var v = 0; v < r.data[i].lancamento_contas.length; v++) {
            valor_final += r.data[i].lancamento_contas[v].valor;
          }
        }
        r.data[i].valor = valor_final;
      }
    }
    this.setState({ lista_lancamentos: r.data });
  };

  verificaDatas = async () => {
    if (!moment(this.state.data_inicio, "YYYY-MM-DD", true).isValid()) {
      await this.setState({
        data_inicio: moment().startOf("month").format("YYYY-MM-DD"),
      });
    }
    if (!moment(this.state.data_final, "YYYY-MM-DD", true).isValid()) {
      await this.setState({ data_final: moment().format("YYYY-MM-DD") });
    }
  };

  listaInstituicoes = async () => {
    const retorno = await api.post(
      "/instituicaoCobranca/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    let instituicoes = [];
    let option = [];
    for (let i = 0; i < retorno.data.length; i++) {
      const element = retorno.data[i];
      option = {
        id: element.id,
        value: element.banco.id,
        nome:
          "C.C. " +
          element.conta +
          "-" +
          element.digito_conta +
          " AG " +
          element.agencia +
          "-" +
          element.digito_agencia +
          " " +
          element.banco.nome,
        registro_automatico: element.registro_automatico,
      };
      instituicoes.push(option);
      // if (element.debito_em_conta) {
      //   instituicoes_debito_em_conta.push(option);
      // }
      // if (element.boleto) {
      //   instituicoes_boleto.push(option);
      // }
    }
    this.setState({
      banco_options: instituicoes,
    });
  };

  pesquisaCaixas = async () => {
    this.setState({ buscando: true });
    //Busca os caixas que possuem uma operação com o tipo e intervalo de data
    await this.verificaDatas();
    if (this.state.tipo === null) {
      this.setState({ tipo: "cobrancaServicoAvulsa" });
    }
    if (this.state.tipo === "diario" || this.state.tipo === "contabil") {
      await this.buscaCaixas();
    } else if (this.state.tipo === "retorno") {
      await this.buscaLancamentoPorData();
      this.setState({ buscando: false });
    } else {
      //Dessa maneira não busca os lancamentos não pagos, mas é possível filtrar por caixa
      await this.buscaLancamentosCaixa();
    }
    this.setState({ buscando: false });
  };

  gerarRelatorio = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado?e=" + entidade,
      {},
      {
        headers: { token: "Baer " + token },
      }
    );
    const nomePessoa = await api.post(
      "/pessoa/pessoaIdNome?e=" + entidade,
      { id: usuarioLogado.data.pessoa_id },
      {
        headers: { token: "Baer " + token },
      }
    );

    let optionsSelect = {
      userId: usuarioLogado.data.pessoa_id,
      nome_operador: nomePessoa.data.nome,
      data_inicial: moment(this.state.data_inicio).format("YYYY-MM-DD"),
      data_final: moment(this.state.data_final).format("YYYY-MM-DD"),
      tipo: this.state.tipo,
    };

    if (this.state.caixa_selecionado.indexOf(0) === -1) {
      optionsSelect.caixa_id = this.state.caixa_selecionado;
    }

    await api.post("/caixa/relatorioMovimento?e=" + entidade, optionsSelect, {
      headers: { token: "Baer " + token },
    });

    const r = await api.get(
      "/caixa/arquivoRelatorioMovimento?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.pessoa_id
    ); //
    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "caixa/arquivoRelatorioMovimento?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.pessoa_id; //

    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  gerarRelatorioDiarioBanco = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado?e=" + entidade,
      {},
      {
        headers: { token: "Baer " + token },
      }
    );
    const nomePessoa = await api.post(
      "/pessoa/pessoaIdNome?e=" + entidade,
      { id: usuarioLogado.data.pessoa_id },
      {
        headers: { token: "Baer " + token },
      }
    );

    let optionsSelect = {
      data_inicial_abertura: moment(this.state.data_inicio).toDate(),
      data_final_abertura: moment(this.state.data_final).toDate(),
      tipoOperacao: this.state.tipo,
    };

    if (this.state.banco_selecionado.indexOf(0) === -1) {
      optionsSelect.banco_id = this.state.banco_selecionado;
    }
    optionsSelect.userId = usuarioLogado.data.id;
    optionsSelect.nome_operador = nomePessoa.data.nome;
    await api.post("/caixa/gerarRetorno?e=" + entidade, optionsSelect, {
      headers: { token: "Baer " + token },
    });

    const r = await api.get(
      "/caixa/arquivoRelatorioBanco?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.id
    ); //

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "caixa/arquivoRelatorioBanco?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.id; //

    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  gerarRelatorioDiarioCaixas = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");
    const usuarioLogado = await api.post(
      "/usuario/usuarioLogado?e=" + entidade,
      {},
      {
        headers: { token: "Baer " + token },
      }
    );
    const nomePessoa = await api.post(
      "/pessoa/pessoaIdNome?e=" + entidade,
      { id: usuarioLogado.data.pessoa_id },
      {
        headers: { token: "Baer " + token },
      }
    );

    let optionsSelect = {
      data_inicial_abertura: moment(this.state.data_inicio).toDate(),
      data_final_abertura: moment(this.state.data_final).toDate(),
      tipoOperacao: this.state.tipo,
    };

    if (this.state.caixa_selecionado.indexOf(0) === -1) {
      optionsSelect.caixa_id = this.state.caixa_selecionado;
    }
    optionsSelect.userId = usuarioLogado.data.id;
    optionsSelect.nome_operador = nomePessoa.data.nome;
    await api.post("/caixa/gerarDiarioCaixas?e=" + entidade, optionsSelect, {
      headers: { token: "Baer " + token },
    });

    const r = await api.get(
      "/caixa/buscarDiarioCaixas?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.id
    ); //

    var oReq = new XMLHttpRequest();

    var URLToPDF =
      r.config.baseURL +
      "caixa/buscarDiarioCaixas?e=" +
      entidade +
      "&t=Baer " +
      token +
      "&userId=" +
      usuarioLogado.data.id; //

    oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    oReq.send();
  };

  imprimirButtonOnClick = async () => {
    this.setState({ imprimindo: true });
    if (this.state.tipo === "diario" || this.state.tipo === "contabil") {
      await this.gerarRelatorioDiarioCaixas();
    } else if (this.state.tipo === "retorno") {
      await this.gerarRelatorioDiarioBanco();
    } else if (this.state.tipo !== "diario" || this.state.tipo !== "contabil") {
      await this.gerarRelatorio();
    }

    this.setState({ imprimindo: false });
  };

  render() {
    return (
      <>
        <Row>
          <Col sm={2}>
            <Form.Label>Data Inicio</Form.Label>
            <Form.Control
              style={{ height: '50px' }}
              placeholder="Data Inicio"
              aria-describedby="basic-addon1"
              type="date"
              name="data_inicio"
              value={this.state.data_inicio}
              onChange={this.alteracao}
            />
          </Col>
          <Col sm={2}>
            <Form.Label>Data Final</Form.Label>
            <Form.Control
              style={{ height: '50px' }}
              placeholder="Data Final"
              aria-describedby="basic-addon1"
              type="date"
              name="data_final"
              value={this.state.data_final}
              onChange={this.alteracao}
            />
          </Col>
          <Col sm={2}>
            <Form.Label>Tipo Operação</Form.Label>
            <Form.Control
              style={{ height: '50px' }}
              as="select"
              name="tipo"
              checked={this.state.tipo}
              id="tipo"
              value={this.state.tipo}
              required
              onChange={this.alteracao}
            >
              <option></option>
              <option value="retorno">Retorno</option>
              <option value="contabil">Lançamento Contábil</option>
              <option value="diario">Diário de caixas</option>
              <option value="cobrancaServicoAvulsa">Vendas</option>
              <option value="sangria">Sangrias</option>
              <option value="suprimento">Suprimentos</option>
            </Form.Control>
          </Col>
          {this.state.tipo !== "retorno" ? (
            <Col sm={2}>
              <FormControl sx={{ m: [0, 1] }} fullWidth >
                <InputLabel id="demo-multiple-name-label" style={{ marginTop: '18px' }} >Name</InputLabel>
                <Select
                  style={{ maxHeight: "50px", marginTop: '19px' }}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={this.state.caixa_selecionado}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (value.indexOf(0) !== -1) {
                      this.setState({
                        caixa_selecionado: [
                          0,
                          ...this.state.caixas_options.map((e) => e.id),
                        ],
                      });
                    } else if (this.state.caixa_selecionado.indexOf(0) !== -1) {
                      this.setState({
                        caixa_selecionado: [],
                      });
                    } else {
                      this.setState({
                        caixa_selecionado:
                          typeof value === "string" ? value.split(",") : value,
                      });
                    }
                  }}
                  MenuProps={{
                    ...MenuProps, getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                  input={<OutlinedInput label="Name" />}
                >
                  {console.log("this.state.caixas_options", this.state.caixas_options)}
                  {[{ id: 0, nome: "Todos" }, ...this.state.caixas_options].map(
                    (e) => (
                      <MenuItem
                        key={e.id}
                        value={e.id}
                        style={
                          this.state.caixa_selecionado.indexOf(e.id) === -1
                            ? {}
                            : { fontWeight: "bold" }
                        }
                      >
                        {e.nome}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Col>
          ) : (
            <Col sm={2}>
              <FormControl sx={{ m: [0, 1] }} fullWidth>
                <InputLabel id="demo-multiple-name-label" style={{ marginTop: '18px' }} >Banco</InputLabel>

                <Select
                  style={{ maxHeight: "50px", marginTop: '19px' }}
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={this.state.banco_selecionado}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (value.indexOf(0) !== -1) {
                      this.setState({
                        banco_selecionado: [
                          0,
                          ...this.state.banco_options.map((e) => e.id),
                        ],
                      });
                    } else if (this.state.banco_selecionado.indexOf(0) !== -1) {
                      this.setState({
                        banco_selecionado: [],
                      });
                    } else {
                      this.setState({
                        banco_selecionado:
                          typeof value === "string" ? value.split(",") : value,
                      });
                    }
                  }}
                  MenuProps={{
                    ...MenuProps, getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                  input={<OutlinedInput label="Banco" />}
                >
                  {[...this.state.banco_options].map((e) => (
                    <MenuItem
                      key={e.id}
                      value={e.id}
                      style={
                        this.state.caixa_selecionado.indexOf(e.id) === -1
                          ? {}
                          : { fontWeight: "bold" }
                      }
                    >
                      {e.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>
          )}
          <Col sm={0.9}>
            <Button
              color="green"
              style={{ marginTop: "35px" ,float: "right",marginLeft:"333px"}}
              onClick={this.pesquisaCaixas}
              loading={this.state.buscando}
              disabled={this.state.buscando}
            >
              Buscar
            </Button>
          </Col>
          <Col sm={0.9} style={{ float: "right" }}>
            <Button
              style={{ marginTop: "35px" }}
              color="blue"
              onClick={this.imprimirButtonOnClick}
              disabled={this.state.lista_lancamentos.length === 0}
              loading={this.state.imprimindo}
            >
              Imprimir
            </Button>
          </Col>
        </Row>
        <Table celled>
          <Table.Header>
            {this.state.tipo === "cobrancaServicoAvulsa" ? (
              <Table.Row>
                <Table.HeaderCell width={3} sorted="descending">
                  Data da venda
                </Table.HeaderCell>
                <Table.HeaderCell>Caixa</Table.HeaderCell>
                <Table.HeaderCell>Serviço</Table.HeaderCell>
                <Table.HeaderCell>Pessoa</Table.HeaderCell>
                <Table.HeaderCell>Vencimento</Table.HeaderCell>
                <Table.HeaderCell textAlign={"right"}>Valor</Table.HeaderCell>
              </Table.Row>
            ) : this.state.tipo === "sangria" ? (
              <Table.Row>
                <Table.HeaderCell width={2} sorted="ascending">
                  Data da sangria
                </Table.HeaderCell>
                <Table.HeaderCell>Caixa</Table.HeaderCell>
                <Table.HeaderCell width={7}>Descrição</Table.HeaderCell>
                <Table.HeaderCell>Banco</Table.HeaderCell>
                <Table.HeaderCell>Conta</Table.HeaderCell>
                <Table.HeaderCell textAlign={"right"}>Valor</Table.HeaderCell>
              </Table.Row>
            ) : this.state.tipo === "suprimento" ? (
              <Table.Row>
                <Table.HeaderCell width={3} sorted="descending">
                  Data
                </Table.HeaderCell>
                <Table.HeaderCell>Caixa</Table.HeaderCell>
                <Table.HeaderCell>Descrição</Table.HeaderCell>
                <Table.HeaderCell textAlign={"right"}>Valor</Table.HeaderCell>
              </Table.Row>
            ) : this.state.tipo === "diario" ||
              this.state.tipo === "contabil" ? (
              <Table.Row>
                <Table.HeaderCell>Caixa</Table.HeaderCell>
                <Table.HeaderCell width={3}>Data Abertura</Table.HeaderCell>
                <Table.HeaderCell width={3}>Data Fechamento</Table.HeaderCell>
                <Table.HeaderCell textAlign={"right"}>Valor</Table.HeaderCell>
              </Table.Row>
            ) : this.state.tipo === "retorno" ? (
              <Table.Row>
                <Table.HeaderCell>Banco</Table.HeaderCell>
                <Table.HeaderCell width={3}>Data Retorno</Table.HeaderCell>

                <Table.HeaderCell textAlign={"right"}>Valor</Table.HeaderCell>
              </Table.Row>
            ) : null}
          </Table.Header>

          <Table.Body>
            {this.state.lista_lancamentos.length === 0 ? (
              <Table.Row>
                <div style={{ margin: "15px" }}>
                  <h4> Não há dados para mostrar. </h4>
                </div>
              </Table.Row>
            ) : this.state.tipo === "cobrancaServicoAvulsa" ? (
              this.state.lista_lancamentos.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell>
                    {moment(e.data_emissao).format("DD/MM/YYYY")}
                  </Table.Cell>
                  <Table.Cell>
                    {e.pessoa_caixa !== null && e.pessoa_caixa !== undefined
                      ? e.pessoa_caixa.nome
                      : ""}
                  </Table.Cell>
                  <Table.Cell>{e.servico.nome}</Table.Cell>
                  <Table.Cell>{e.pessoa.nome}</Table.Cell>
                  <Table.Cell>
                    {moment(e.data_vencimento).format("DD/MM/YYYY")}
                  </Table.Cell>
                  <Table.Cell textAlign={"right"}>
                    {parseFloat(e.valor)
                      .toFixed(2)
                      .replace(".", ",")
                      .replace(/\d(?=(\d{3})+,)/g, "$&.")}
                  </Table.Cell>
                </Table.Row>
              ))
            ) : this.state.tipo === "sangria" ? (
              this.state.lista_lancamentos.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell>
                    {moment(e.data_emissao).format("DD/MM/YYYY")}
                  </Table.Cell>
                  <Table.Cell>
                    {e.pessoa_caixa !== null && e.pessoa_caixa !== undefined
                      ? e.pessoa_caixa.nome
                      : ""}
                  </Table.Cell>
                  <Table.Cell>{e.descricao}</Table.Cell>
                  <Table.Cell>
                    {e.conta_bancaria !== null
                      ? e.conta_bancaria.banco.nome
                      : ""}
                  </Table.Cell>
                  {/* <Table.Cell>{e.conta_bancaria !== null ? e.conta_bancaria.agencia.nome: ""}</Table.Cell> */}
                  <Table.Cell>
                    {e.conta_bancaria !== null ? e.conta_bancaria.conta : ""}
                  </Table.Cell>
                  <Table.Cell textAlign={"right"}>
                    {parseFloat(e.valor)
                      .toFixed(2)
                      .replace(".", ",")
                      .replace(/\d(?=(\d{3})+,)/g, "$&.")}
                  </Table.Cell>
                </Table.Row>
              ))
            ) : this.state.tipo === "suprimento" ? (
              this.state.lista_lancamentos.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell>
                    {moment(e.data_emissao).format("DD/MM/YYYY")}
                  </Table.Cell>
                  <Table.Cell>
                    {e.pessoa_caixa !== null && e.pessoa_caixa !== undefined
                      ? e.pessoa_caixa.nome
                      : ""}
                  </Table.Cell>
                  <Table.Cell>{e.descricao}</Table.Cell>
                  <Table.Cell textAlign={"right"}>
                    {parseFloat(e.valor)
                      .toFixed(2)
                      .replace(".", ",")
                      .replace(/\d(?=(\d{3})+,)/g, "$&.")}
                  </Table.Cell>
                </Table.Row>
              ))
            ) : this.state.tipo === "diario" ||
              this.state.tipo === "contabil" ? (
              this.state.lista_lancamentos.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell>
                    {e.usuario !== null ? e.usuario.nome : ""}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(e.data_abertura).format("DD/MM/YYYY")}
                  </Table.Cell>
                  <Table.Cell>
                    {e.data_fechamento !== null
                      ? moment(e.data_fechamento).format("DD/MM/YYYY")
                      : ""}
                  </Table.Cell>
                  <Table.Cell textAlign={"right"}>
                    {parseFloat(e.valor)
                      .toFixed(2)
                      .replace(".", ",")
                      .replace(/\d(?=(\d{3}),)/g, "$&.")}
                  </Table.Cell>
                </Table.Row>
              ))
            ) : this.state.tipo === "retorno" ? (
              this.state.lista_lancamentos.map((e, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{e.conta_bancaria?.banco?.nome}</Table.Cell>

                  <Table.Cell>
                    {e.data_pagamento !== null
                      ? moment(e.data_pagamento).format("DD/MM/YYYY")
                      : ""}
                  </Table.Cell>
                  <Table.Cell textAlign={"right"}>
                    {parseFloat(this.state.totaisServicos.total_pago)
                      .toFixed(2)
                      .replace(".", ",")
                      .replace(/\d(?=(\d{3}),)/g, "$&.")}
                  </Table.Cell>
                </Table.Row>
              ))
            ) : null}
          </Table.Body>
        </Table>
        <Col sm={0.9} className="ml-auto" style={{ float: "right" }}>
          <Button
              // style={{marginLeft:"1480px"}}
              // style={{ float: "right" }}

            color="blue"
            onClick={this.imprimirButtonOnClick}
            disabled={this.state.lista_lancamentos.length === 0}
            loading={this.state.imprimindo}
          >
            Imprimir
          </Button>
        </Col>
        {/* <Col sm={3} style={{ float: "right" }}>aaa</Col> */}
      </>
    );
  }
}
