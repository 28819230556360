import React, { Component } from "react";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { List, Card } from "semantic-ui-react";
import "../styles.css";

export default class lista_modalidades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertaModalidadeVinculada: false,
    };
  }

  showAlertaModalidadeVinculada = async () => {
    this.setState({ alertaModalidadeVinculada: true });

    setTimeout(() => {
      this.setState({ alertaModalidadeVinculada: false });
    }, 1300);
  };

  render() {
    return (
      <div>
        <div>
          <Alert
            severity="error"
            hidden={!this.state.alertaModalidadeVinculada}
          >
            <AlertTitle>Subgrupo Vinculado!</AlertTitle>
            <strong> Impossível editar </strong>
          </Alert>
        </div>
        <Card.Group stackable itemsPerRow={4}>
          {this.props.modalidadesVinculadas.map((element, index) => {
            return (
              <Card id="visualizar" link color="blue">
                <Card.Content
                  textAlign="left"
                  className="cardsLista"
                  onClick={
                    element.evento.length !== 0
                      ? () => this.showAlertaModalidadeVinculada()
                      : () => this.props.changeModalEditar(element, true)
                  }
                >
                  <List>
                    <List.Item>
                      <List.Icon name="marker" />
                      <List.Content>
                        {element.modalidade[0].descricao}
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="globe" />
                      <List.Content>{element.tipo_evento}</List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="certificate" />
                      <List.Content>
                        {"Qtde Tiros: " + element.quantidade_tiros}
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="comment alternate" />
                      <List.Content>
                        {" "}
                        {element.modalidade[0].tipo_pontuacao}
                      </List.Content>
                    </List.Item>
                  </List>
                </Card.Content>
              </Card>
            );
          })}
        </Card.Group>
      </div>
    );
  }
}
