import React, { Component } from "react";

import { Header, Segment, Table } from "semantic-ui-react";
import {  
  IconButton,
  Icon,  
  ThemeProvider,  
} from "@material-ui/core";

import { green } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

// Componentes criados
import ModalAdicionaPermissao from "./modalAdicionaPermissao";
export default class Permissoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Lista info
      listaPermissoes: this.props.listaPermissoes,

      //Modal
      openModalAdicionaPermissao: false,
    };
  }

  render() {
    return (
      <div>
        <Segment>
          <Header>Cadastro de Permissões</Header>
          <Table celled compact>
            <Table.Header>
              <Table.HeaderCell width={1}>Id</Table.HeaderCell>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell
                width={1}
                style={{
                  padding: 0,
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <IconButton
                    onClick={() =>
                      this.setState({ openModalAdicionaPermissao: true })
                    }
                  >
                    <ThemeProvider
                      theme={createTheme({
                        palette: {
                          primary: green,
                        },
                      })}
                    >
                      <Icon color="primary">add_circle</Icon>
                    </ThemeProvider>
                  </IconButton>
                </div>
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {this.state.listaPermissoes.map((e, i) => (
                <Table.Row>
                  <Table.Cell>{e.id}</Table.Cell>
                  <Table.Cell>{e.nome}</Table.Cell>
                  <Table.Cell
                    style={{
                      padding: 0,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        alert(
                          "Solicitar exclusão diretamente no banco para evitar problemas."
                        );
                      }}
                    >
                      <Icon color="error">delete_icon</Icon>
                    </IconButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
        <ModalAdicionaPermissao
          open={this.state.openModalAdicionaPermissao}
          onClose={() => this.setState({ openModalAdicionaPermissao: false })}
          addPermissao={(permissao) => {
            let lista = this.state.listaPermissoes;
            lista.push(permissao);
            this.setState({ listaPermissoes: lista });
          }}
        />
      </div>
    );
  }
}
