import React, { Component } from "react";
import { Row, Col, FormControl, Form } from "react-bootstrap";
import { Header, Button, Divider, Segment, Modal } from "semantic-ui-react";
import Select from "react-select";
import { Paper } from "@material-ui/core";

// Componentes
import AcaoTitulo from "./acaoTitulo";
import Cobranca from "./cobranca";
import VisualizaPessoa from "./visualizaPessoa";
import ModalDependente from "./modalDependente";
import ListaDependentes from "./listaDependentes";
import ListaPendencias from "./listaPendencias";

// Enum
import Acao from "../../../../../enum/Acao";
import Meses from "../../../../../enum/Mes";
import Parcelas from "../../../../../enum/ParcelasEnum";

// Utils
import ValidadorCamposPessoa from "../../../../../utils/validadorCamposPessoa";
import ReplaceAll from "replaceall";
import moment from "moment-timezone";
import { getYear, getMonth, setMonth, setYear } from "date-fns";
import renomearChave from "../../../../../services/renomearChave";

// Requisicao
import api from "../../../../../services/api";
import requisicoes from "../../../../../utils/Requisicoes";

import { withRouter } from "react-router-dom";

class Usuario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      login: null,
      senha: null,
      tipo_usuario: null,
      pessoa_venda_id: null,
      options_venda: [],
      options_compra: [],
      qrCodeLink: "",
      infoPessoa: null,
      acao: null,
      selectTipo: null,
      listaTipoTitulo: [],
      parcelas: Parcelas,
      infoParcelasMensalidade: [],
      infoParcelasTitulo: [],
      meses: Meses,
      tipo_pagamento: null,
      efetuar_cobranca: false,
      listaUsuario: [],
      usuario_id: null,
      usuario: null,
      funcao: null,
      pessoa_venda: null,
      pessoa_compra: null,
      pessoa_compra_id: null,
      open_modal_dependentes: null,
      dependentes: [],
      infoPessoaTitular: null,
      pendencias: [],
      acao_dependentes: null,
      numero_titulo: null,
      selectedCheckboxes: new Set(),
      open_cancelamento_dividas: null,
      open_transfere_dividas: false,
      novo_titular_id_dividas: null,
      confirma_transfere_dividas: false,
      motivo_divida: null,
      infoParcelasTaxaTransferencia: [],
      data_transferencia: moment(),
      acaoPatrimonial: null,
      loading: false,
      instituicao_cobranca_id: "",
      cobrancaBancariaTaxaTransferencia: false,
      instituicao_cobranca: null,
      novo_dependente: null,
      dependente_id: null,
      gerarParcelaMensalidade: true,
    };
    this.alteracao = this.alteracao.bind(this);
    this.alteracaoSelectPessoaVenda =
      this.alteracaoSelectPessoaVenda.bind(this);
    this.alteracaoSelectPessoaCompra =
      this.alteracaoSelectPessoaCompra.bind(this);
    this.listaTipoTitulo();


    var url_string = window.location.href;
    var url = new URL(url_string);
    var dependente_id = url.searchParams.get("dependente_id");
    if (dependente_id !== null) {
      this.alteracaoSelectDependente({ value: dependente_id });
    }
  }

  handleCloseDividas = () => {
    this.setState({ open_cancelamento_dividas: false });
  };

  toggleCheckbox = (e) => {
    let newSet = this.state.selectedCheckboxes;
    if (this.state.selectedCheckboxes.has(e.target.value)) {
      newSet.delete(e.target.value);
    } else {
      newSet.add(e.target.value);
    }
    console.log("toggleCheckbox: " + e.target.value);
    this.setState({ selectedCheckboxes: newSet });
  };

  handleShowDividas = () => this.setState({ open_cancelamento_dividas: true });

  handleShowTransfereDividas = () => {
    if (this.state.infoPessoaTitular === null || this.state.infoPessoaTitular === undefined) {
      return alert("Você precisa selecionar um novo titular.");
    }
    this.setState({ open_transfere_dividas: true })
  };

  handleCloseTransfereDividas = () => this.setState({ open_transfere_dividas: false });

  handleOpenDependentes = () => {
    this.setState({ open_modal_dependentes: true });
    this.geraNumeroTitulo();
  };
  handleCloseDependentes = () => {
    this.setState({ open_modal_dependentes: false });
  };

  adicionaDependentes = (element) => {
    this.state.dependentes.push(element);
  };

  alteracaoSelectDependente = async (event) => {
    const resposta = await api.post(
      "/pessoa/visualiza" + requisicoes.entidade,
      { id: event.value },
      requisicoes.header
    );

    this.setState({
      novo_dependente: resposta.data,
    });
    alert("Nova pessoa cadastrada com sucesso.\nCadastro: " + this.state.novo_dependente.nome +
      "\nAo clicar em 'Adicionar', esse cadastro será setado como pré-selecionado.");
    //this.handleOpenDependentes();
  };

  removeDependenteFromURL = async () => {
    try {
      var url_string = window.location.href;
      var url = new URL(url_string);
      var dependente_id = url.searchParams.get("dependente_id");

      if (dependente_id) {
        url.searchParams.delete("dependente_id");

        window.history.replaceState({}, 'Faee - Clube', url.href);

        this.setState({
          dependente_id: null,
          novo_dependente: null,
        });
      }
    } catch (error) {
      console.log("removeDependenteFromURL.error: " + error);
    }
  }

  geraNumeroTitulo = async () => {
    const numero = this.state.dependentes.length + 1;
    this.setState({ numero_titulo: numero });
  };

  removeDependentes = async (event) => {
    const lista = this.state.dependentes;
    let indice = lista.findIndex(
      (element) => element.pessoa.value === parseInt(event.target.value)
    );
    lista.splice(indice, 1);
    this.setState({ dependentes: lista });
    await api.post(
      "/pessoa/alterar" +
      requisicoes.entidade +
      "&id=" +
      parseInt(event.target.value),
      { grau_parentesco: null, titular_id: null, acao_id: null },
      requisicoes.header
    );
    await api.post(
      "/historicoDependente/salvar" + requisicoes.entidade,
      {
        acao: Acao.find((e) => e.value === "remover").value,
        motivo: "",
        dependente_id: parseInt(event.target.value),
        titular_id: this.state.pessoa_compra_id,
      },
      requisicoes.header
    );
  };

  listaTipoTitulo = async () => {
    const resposta = await api.post(
      "/tipoTitulo/lista" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ listaTipoTitulo: resposta.data });
  };

  alteracao(event) {
    if (event.target.name === "tipo_usuario") {
      this.setState({ tipo_usuario: event.target.value });
    } else if (event.target.name === "tipo_pagamento") {
      this.setState({ [event.target.name]: event.target.value });
      this.geraParcelas(event);
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  setParcelasTaxaTransferencia = (parcelas) => {
    this.setState({ infoParcelasTaxaTransferencia: parcelas });
  };

  setParcelasMensalidade = (parcelas) => {
    this.setState({ infoParcelasMensalidade: parcelas });
  };

  setGerarParcelasMensalidade = (gera) => {
    this.setState({ gerarParcelaMensalidade: gera });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    if (this.state.infoPessoaTitular === null || this.state.infoPessoaTitular === undefined) {
      this.setState({ loading: false });
      return alert(
        "Pessoa titular não validada para transferência de título."
      );
    } else if (
      this.state.tipo_usuario === "dependente" &&
      this.state.usuario === null
    ) {
      this.setState({ loading: false });
      return alert("Campo Titular Obrigatório");
    } else if (
      this.state.infoPessoaTitular.tipo_cobranca === "boleto" &&
      !this.state.instituicao_cobranca_id
    ) {
      this.setState({ loading: false });
      return alert("Selecione a instituição cobrança para a geração dos boletos.");
    } else if (
      this.state.infoPessoaTitular.tipo_cobranca === 'debito_em_conta' &&
      this.state.infoPessoaTitular.debito_conta_id === null
    ) {
      this.setState({ loading: false });
      return alert(
        "Cadastre uma conta para utilizar o débito em conta."
      );
    } else if(this.state.pendencias !== undefined 
        && this.state.pendencias.length > 0
        && this.state.novo_titular_id_dividas == null) {
      this.setState({ loading: false });
      return alert(
        "Para realizar a transferência do título, precisará cancelar ou transferir as dívidas do atual titular."
      );
    } else if (this.state.infoPessoa == null || this.state.infoPessoa == undefined) {
      this.setState({ loading: false });
      return alert(
        "Pessoa não validada para transferência de título."
      );
    } else if((this.state.infoParcelasMensalidade === null 
      || this.state.infoParcelasMensalidade === undefined 
      || this.state.infoParcelasMensalidade.length <= 0)
      && this.state.gerarParcelaMensalidade === true) {
      this.setState({ loading: false });
      return alert(
        "Selecione a quantidade de mensalidades."
      );
    } else if (this.state.infoParcelasTaxaTransferencia === null 
      || this.state.infoParcelasTaxaTransferencia === undefined 
      || this.state.infoParcelasTaxaTransferencia.length <= 0) {
      this.setState({ loading: false });
      return alert(
        "Selecione a quantidade de parcelas da taxa de transferência."
      );
    } else if(this.state.pendencias !== undefined 
      && this.state.pendencias.length > 0
      && !this.state.confirma_transfere_dividas) {
      this.setState({ loading: false });
      return alert(
        "Titular atual está com pendências em aberto."
      );
    } else {
      this.submit();
    }
  };

  submit = async () => {
    let pessoa_validada = true;
    if (
      // this.state.infoParcelasMensalidade.length === 0 ||
      this.state.infoParcelasTaxaTransferencia.length === 0
    ) {
      alert("Taxa de transferência obrigatória");
      this.setState({ loading: false });
      return;
    }
    if (this.state.infoPessoaTitular !== null) {
      ValidadorCamposPessoa(this.state.infoPessoaTitular).map((e) => {
        return Object.entries(e).map((k, i) => {
          if (k[1] === false) {
            pessoa_validada = false;
            this.setState({ loading: false });
            alert("Dados da Pessoa São Inválidos");
          }
        });
      });
      if (pessoa_validada === true) {
        if (
          this.state.pessoa_compra_id === null ||
          this.state.pessoa_compra_id === undefined
        ) {
          alert("Favor Selecionar a Pessoa que está comprando");
          this.setState({ loading: false });
        } else if (
          this.state.pessoa_venda_id === null ||
          this.state.pessoa_venda_id === undefined
        ) {
          alert("Favor Selecionar a Pessoa que está vendendo");
          this.setState({ loading: false });
        } else if (
          (this.state.acao.tipo_titulo.servico_taxa_transferencia_id === null &&
            this.state.acao.tipo_titulo.descricao !== "REMIDO") ||
          (this.state.acao.tipo_titulo.descricao === "REMIDO" &&
            this.state.acaoPatrimonial.tipo_titulo
              .servico_taxa_transferencia_id === null)
        ) {
          alert(
            "Favor Selecionar o Serviço da Taxa de transferência na pagina do Tipo de Titulo"
          );
          this.setState({ loading: false });
        } else if (
          (this.state.acao.tipo_titulo.servico_mensalidade_id === null &&
            this.state.acao.tipo_titulo.descricao !== "REMIDO") ||
          (this.state.acao.tipo_titulo.descricao === "REMIDO" &&
            this.state.acaoPatrimonial.tipo_titulo.servico_mensalidade_id ===
            null)
        ) {
          alert(
            "Favor Selecionar o Serviço da Mensalidade na pagina do Tipo de Titulo"
          );
          this.setState({ loading: false });
        } else {
          // === PROCESSO PARA REMOVER A AÇÃO DO TITULAR E DEPENDENTES
          let pessoa = this.state.infoPessoaTitular;
          pessoa.acao_id = this.state.acao.id;
          //remove ação da pessoa que está vendendo
          await api.post(
            "/pessoa/alterar" +
            requisicoes.entidade +
            "&id=" +
            this.state.pessoa_venda_id,
            {
              acao_id: null,
            },
            requisicoes.header
          );

          //Remove a ação dos dependentes de quem está vendendo.
          const resDependentesVenda = await api.post(
            "/pessoa/dependentes" + requisicoes.entidade,
            {
              titular_id: this.state.pessoa_venda_id,
            },
            requisicoes.header
          );

          for (let i = 0; i < resDependentesVenda.data.length; i++) {
            const element = resDependentesVenda.data[i];
            // Só remover se tiver a mesma ação, caso projeto
            if (element.acao_id === pessoa.acao_id) {
              await api.post(
                "/pessoa/alterar" + requisicoes.entidade + "&id=" + element.id,
                {
                  acao_id: null,
                },
                requisicoes.header
              );
            }
          }

          // === PROCESSO DE ALTERAÇÃO DA AÇÃO
          //altera ação
          if (this.state.acao.tipo_titulo.descricao === "REMIDO") {
            await api.post(
              "/acao/alterar" + requisicoes.entidade,
              {
                id: this.state.acao.id,
                status: "ativo",
                codigo: this.state.acao.codigo,
                tipo_titulo_id: 12514,
                data_emissao: moment().format("YYYY-MM-DD"),
                qtd_transferencias:
                  this.state.acao.qtd_transferencias === null
                    ? 1
                    : this.state.acao.qtd_transferencias + 1,
              },
              requisicoes.header
            );
          } else {
            await api.post(
              "/acao/alterar" + requisicoes.entidade,
              {
                id: this.state.acao.id,
                status: "ativo",
                codigo: this.state.acao.codigo,
                data_emissao: moment().format("YYYY-MM-DD"),
                qtd_transferencias:
                  this.state.acao.qtd_transferencias === null
                    ? 1
                    : this.state.acao.qtd_transferencias + 1,
              },
              requisicoes.header
            );
          }

          //armazena histórico
          //Guardando na data de lançamento a data anterior de emissão da ação.
          if (this.state.acao.tipo_titulo.descricao === "REMIDO") {

            await api.post(
              "/historicoAcao/salvar" + requisicoes.entidade,
              {
                tipo_lancamento: "alteracaoTipoTitulo",
                acao_id: this.state.acao.id,
                data_transferencia: this.state.data_transferencia,
                motivo: "Alteração de remido para patrimonial",
                pessoa_compra_id: this.state.pessoa_venda_id, //Salvar quem tem a ação até o momento.
                pessoa_venda_id: this.state.pessoa_venda_id,
              },
              requisicoes.header
            );
          }

          if (this.state.acao.status !== "ativo") {

            await api.post(
              "/historicoAcao/salvar" + requisicoes.entidade,
              {
                tipo_lancamento: "alteracaoStatus",
                acao_id: this.state.acao.id,
                data_transferencia: this.state.data_transferencia,
                motivo: "Alteração de " + this.state.acao.status + " para ativo",
                pessoa_compra_id: this.state.pessoa_venda_id, //Salvar quem tem a ação até o momento.
                pessoa_venda_id: this.state.pessoa_venda_id,
              },
              requisicoes.header
            );
          }

          await api.post(
            "/historicoAcao/salvar" + requisicoes.entidade,
            {
              tipo_lancamento: "transferencia",
              status: "Transferido",
              pessoa_compra_id: this.state.pessoa_compra_id,
              pessoa_venda_id: this.state.pessoa_venda_id,
              acao_id: this.state.acao.id,
              data_lancamento: this.state.acao.data_emissao,
              data_transferencia: this.state.data_transferencia,
            },
            requisicoes.header
          );

          //gera cobrança
          this.state.infoParcelasTaxaTransferencia.forEach((e) => {
            e.valor = ReplaceAll(".", "", e.valor).replace(",", ".");
          });
          this.state.infoParcelasMensalidade.forEach((e) => {
            e.valor = ReplaceAll(".", "", e.valor).replace(",", ".");
          });
          const dados = {
            pessoa_id: this.state.pessoa_compra_id,
            acao_id: this.state.acao.id,
            cobranca_taxa_transferencia:
              this.state.infoParcelasTaxaTransferencia,
            gerarCobrancaBancariaTaxaTransferencia:
              this.state.cobrancaBancariaTaxaTransferencia,
            cobranca_mensalidade: this.state.infoParcelasMensalidade,
            servico_taxa_transferencia_id:
              this.state.acao.tipo_titulo.servico_taxa_transferencia_id,
            servico_mensalidade_id:
              this.state.acao.tipo_titulo.servico_mensalidade_id,
            instituicao_cobranca_id: this.state.instituicao_cobranca_id,
            tipo_cobranca: this.state.infoPessoaTitular.tipo_cobranca,
          };
          //Seta a ação dos dependentes de quem está comprando.
          const resDependentesCompra = await api.post(
            "/pessoa/dependentes" + requisicoes.entidade,
            { titular_id: this.state.pessoa_compra_id },
            requisicoes.header
          );

          for (let i = 0; i < resDependentesCompra.data.length; i++) {
            const element = resDependentesCompra.data[i];
            await api.post(
              "/pessoa/alterar" + requisicoes.entidade + "&id=" + element.id,
              {
                acao_id: this.state.acao.id,
              },
              requisicoes.header
            );
          }

          // === PROCESSO PARA PARA TRANSFERIR COBRANÇA
          const transfMensalidade = await api.post(
            "/transferencia/cobranca" + requisicoes.entidade,
            dados,
            requisicoes.header
          );

          if (
            this.state.infoPessoaTitular.tipo_cobranca === "boleto" &&
            !this.state.instituicao_cobranca.registro_automatico &&
            this.state.gerarParcelaMensalidade === true
          ) {
            await api.post(
              "/boleto/geraBoleto" + requisicoes.entidade,
              {
                lancamento_contas: transfMensalidade.data,
              },
              requisicoes.header
            );
            await this.gerarPdfBoletos();
          }

          // Transfere as dívidas para o novo titular
          // Deve transferir somente quando clicar no botão 'Transferir Dívidas' e clicar em 'Sim'
          if (this.state.novo_titular_id_dividas !== null && this.state.confirma_transfere_dividas) {
            this.transfereDividas(this.state.novo_titular_id_dividas);
          }
          
          this.props.history.push("/administrativo");
        }
      }
    }
  };

  gerarPdfBoletos = async (event) => {
    var localStorage = window.localStorage;
    var token = localStorage.getItem("token_clube");
    var entidade = localStorage.getItem("e_clube");

    const r = await api.get("/boleto?e=" + entidade + "&t=Baer " + token);
    var oReq = new XMLHttpRequest();
    var URLToPDF =
      r.config.baseURL + "boleto?e=" + entidade + "&t=Baer " + token;
    await oReq.open("GET", URLToPDF, true);
    oReq.responseType = "blob";
    oReq.onload = await function () {
      const file = new Blob([oReq.response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    };
    await oReq.send();
  };

  alteracaoSelectPessoaVenda = async (event) => {
    this.setState({ pessoa_venda_id: event.value });
    this.setState({ pessoa_venda: event });
    const resposta = await api.post(
      "/pessoa/visualiza" + requisicoes.entidade,
      { id: event.value },
      requisicoes.header
    );
    const respostaPendencias = await api.post(
      "/lancamentoConta/pesquisaPorPessoaCodigo" + requisicoes.entidade,
      { pessoa_id: resposta.data.id },
      requisicoes.header
    );
    let listaPendencias = [];
    respostaPendencias.data.forEach((e) => {
      if (
        e.tipo_lancamento !== null &&
        e.data_pagamento === null &&
        (e.tipo_lancamento === "mensalidade" ||
          e.tipo_lancamento === "cobrancaServicoAvulsa" ||
          e.tipo_lancamento === "titulo" ||
          e.tipo_lancamento === "debitoConta")
      ) {
        e.cancelado = false;
        listaPendencias.push(e);
      }
    });
    this.setState({ pendencias: listaPendencias });
    this.setState({ infoPessoa: resposta.data });
    if (resposta.data.acao !== null) {
      const respAcao = await api.post(
        "/acao/visualiza" + requisicoes.entidade,
        { id: resposta.data.acao.id },
        requisicoes.header
      );
      if (respAcao.data.codigo !== null) {
        this.setState({ acao_dependentes: respAcao.data.codigo });
      }
      this.setState({ acao: respAcao.data });
    } else {
      const historicoAcao = await api.post(
        "/historicoAcao/ultimoHistoricoPorPessoa" + requisicoes.entidade,
        { pessoa_id: resposta.data.id },
        requisicoes.header
      );
      if (historicoAcao.data !== null) {
        let dataMovimentacao = null;
        let acao = null;
        let historico = null;
        historicoAcao.data.forEach((ha, i) => {
          ha.historico_acoes.forEach((h) => {
            if (
              (h.pessoa_compra_id !== null &&
                h.pessoa_compra_id === resposta.data.id) ||
              (h.pessoa_venda_id !== null &&
                h.pessoa_venda_id === resposta.data.id)
            ) {
              if (dataMovimentacao === null) {
                dataMovimentacao = h.data;
              }
              if (moment(dataMovimentacao).isSameOrBefore(moment(h.data))) {
                dataMovimentacao = h.data;
                historico = h;
                acao = ha;
              }
            }
          });
          if (i + 1 === historicoAcao.data.length) {
            acao.historico_acoes = [];
            acao.historico_acoes.push(historico);
            this.setState({ acao: acao });
          }
        });
      } else {
        this.setState({ acao: null });
      }
    }

    //Muito errado fazer dessa forma
    const tipoTituloPatrimonial = await api.post(
      "/tipoTitulo/visualiza" + requisicoes.entidade,
      { id: 12514 },
      requisicoes.header
    );
    var a = { tipo_titulo: tipoTituloPatrimonial.data };
    this.setState({ acaoPatrimonial: a });
    // this.carregaDependentes(event.value);
  };

  alteracaoPessoa = (event) => {
    let pessoa = this.state.infoPessoaTitular;
    Object.entries(pessoa).map((k) => {
      if (k[0] === event.target.name) {
        pessoa[k[0]] = event.target.value;
      }
    });
    this.setState({ 
      infoPessoaTitular: pessoa,
      novo_titular_id_dividas: null,
    });
  };

  alteracaoSelectPessoaCompra = async (event) => {
    if (event.acao && event.acao.status === "ativo") {
      if (event.titular_id) {
        alert(
          "A pessoa selecionada já possui um título vinculado e está " +
          "vinculada como dependente de outro sócio. " +
          "Acesse o perfil do seu titular em Meus dados e exclua a depêndencia."
        );
      } else {
        alert("A pessoa selecionada já possui um título vinculado.");
      }
      this.setState({ pessoa_compra_id: null });
      this.setState({ pessoa_compra: null });
      return;
    }
    if (event.titular_id) {
      return alert(
        "A pessoa selecionada está vinculada como dependente de outro sócio."
      );
    }

    this.setState({ pessoa_compra_id: event.value });
    this.setState({ pessoa_compra: event });

    const resposta = await api.post(
      "/pessoa/visualiza" + requisicoes.entidade,
      { id: event.value },
      requisicoes.header
    );

    this.setState({ infoPessoaTitular: resposta.data });
  };

  novaAcao = async () => {
    const resposta = await api.post(
      "/acao/salvar" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    this.setState({ acao: resposta.data });
    this.setState({ efetuar_cobranca: true });
  };

  verificarMes = (data) => {
    if (getMonth(data) === 0) {
      data = setMonth(data, 11);
      data = setYear(data, getYear(data) - 1);
      return data;
    } else {
      return data;
    }
  };

  voltar() {
    window.location.href = "/administrativo";
  }
  pesquisaPessoaVenda = async () => {
    setTimeout(async () => {
      let retorno = "";
      if (
        isFinite(String(document.getElementById("pesquisaPessoaVenda").value))
      ) {
        let retorno = await api.post(
          "/pessoa/pesquisaCodigoAcao" + requisicoes.entidade,
          { pesquisa: document.getElementById("pesquisaPessoaVenda").value },
          requisicoes.header
        );
        let lista = [];
        retorno.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto.label = renomearChave(objeto.acao, "codigo", "label").label;
          lista.push(objeto);
        });
        this.setState({ options_venda: lista });
      } else {
        retorno = await api.post(
          "/pessoa/pesquisaComAcao" + requisicoes.entidade,
          { pesquisa: document.getElementById("pesquisaPessoaVenda").value },
          requisicoes.header
        );
        let lista2 = [];
        retorno.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomearChave(objeto, "nome", "label");
          lista2.push(objeto);
        });
        this.setState({ options_venda: lista2 });
      }
    }, 500);
  };

  pesquisaPessoaCompra = async () => {
    setTimeout(async () => {
      let retorno = "";
      if (
        isFinite(String(document.getElementById("pesquisaPessoaCompra").value))
      ) {
        let retorno = await api.post(
          "/pessoa/pesquisaCodigoAcao" + requisicoes.entidade,
          { pesquisa: document.getElementById("pesquisaPessoaCompra").value },
          requisicoes.header
        );
        let lista = [];
        retorno.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto.label = renomearChave(objeto.acao, "codigo", "label").label;
          lista.push(objeto);
        });
        this.setState({ options_compra: lista });
      } else {
        retorno = await api.post(
          "/pessoa/pesquisa" + requisicoes.entidade,
          { pesquisa: document.getElementById("pesquisaPessoaCompra").value },
          requisicoes.header
        );
        let lista2 = [];
        retorno.data.forEach((element) => {
          let objeto = renomearChave(element, "id", "value");
          objeto = renomearChave(objeto, "nome", "label");
          lista2.push(objeto);
        });
        this.setState({ options_compra: lista2 });
      }
    }, 500);
  };

  cancelamentoDividas = async () => {
    if (this.state.motivo_divida === null) {
      alert("Favor Informar um Motivo");
    } else if(this.state.selectedCheckboxes.size > 0) {
      alert("Nenhuma dívida foi selecionada!");
    }  else {
      let lista = [];
      lista = [...this.state.selectedCheckboxes].filter((e) => e);

      await api.post(
        "/lancamentoConta/cancelarVarios" + requisicoes.entidade,
        {
          lancamentos: lista.map((e) => {
            return {
              id: e,
            };
          }),
          motivo: this.state.motivo,
          tela_usada: "transferencia_titularidade",
        },
        requisicoes.header
      );

      let newSet = this.state.selectedCheckboxes;
      let newPendencias = this.state.pendencias;
      for (let i = 0; i < lista.length; i++) {
        const element = lista[i];
        let idx = newPendencias.findIndex((e) => e.id === parseInt(element));
        newPendencias.splice(idx, 1);
        newSet.delete(element);
      }
      this.setState({ selectedCheckboxes: newSet, pendencias: newPendencias });

      this.handleCloseDividas();
    }
  };

  definePessoaTransfereDividas = async () => {
    if (this.state.infoPessoaTitular === null || this.state.infoPessoaTitular === undefined) {
      return alert("Você precisa selecionar um novo titular.");
    }
    this.setState({
      novo_titular_id_dividas: this.state.infoPessoaTitular.id,
    })
  };
  
  handleTransfereDividas = async (confirma) => {
    let checkedBoxes = new Set();
    this.state.pendencias.forEach(c => {
      if(checkedBoxes.has(c.id+"") && !confirma) {
        checkedBoxes.delete(c.id+"");
      } else if(!checkedBoxes.has(c.id+"") && confirma) {
        checkedBoxes.add(c.id+"");
      }
    });
    this.setState({
      selectedCheckboxes: checkedBoxes,
      confirma_transfere_dividas: confirma, 
      open_transfere_dividas: false,
      novo_titular_id_dividas: confirma 
        ? this.state.infoPessoaTitular.id
        : null
    })
  };

  transfereDividas = async (novo_id) => {
    try {
      if(this.state.confirma_transfere_dividas) {
        let lista = [];
        lista = [...this.state.selectedCheckboxes].filter((e) => e);
  
        await api.post(
          "/lancamentoConta/transfereDividas" + requisicoes.entidade,
          {
            lancamentos: lista.map((e) => { return { id: e }; }),
            pessoa_id: novo_id,
            tela_usada: "transferencia_titularidade",
          },
          requisicoes.header
        );
  
        let newSet = this.state.selectedCheckboxes;
        let newPendencias = this.state.pendencias;
        for (let i = 0; i < lista.length; i++) {
          const element = lista[i];
          let idx = newPendencias.findIndex((e) => e.id === parseInt(element));
          newPendencias.splice(idx, 1);
          newSet.delete(element);
        }
        this.setState({ selectedCheckboxes: newSet, pendencias: newPendencias });
  
        this.handleCloseTransfereDividas();
      } else {
        alert("Nenhuma dívida foi selecionada!");
      }
    } catch (err) {
      console.log('transfereDividas/err: ' + err);
    }
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Transferência de Titularidade
        </Header>
        <Form onSubmit={this.handleSubmit}>
          <Paper style={{ padding: 20 }}>
            <Header as="h3">Pessoa/Ação Venda</Header>
            <Row>
              <Col sm={6}>
                <Form.Label>Pessoa</Form.Label>
                <Select
                  placeholder="Digite para buscar"
                  name="pessoaVenda"
                  value={this.state.pessoaVenda}
                  onChange={this.alteracaoSelectPessoaVenda}
                  options={this.state.options_venda}
                  onKeyDown={this.pesquisaPessoaVenda}
                  inputId="pesquisaPessoaVenda"
                  isDisabled={this.state.id !== null}
                  required
                />
              </Col>

              <Col sm={6}>
                <Form.Label>Perfil</Form.Label>
                <Form.Control
                  placeholder="Tipo"
                  as="select"
                  name="tipo_usuario"
                  onChange={this.alteracao}
                  checked={this.state.selectTipo}
                  value={this.state.tipo_usuario}
                  required
                  disabled
                >
                  <option></option>
                  <option value="titular" selected>
                    Titular
                  </option>
                  <option value="dependente">Dependente</option>
                  <option value="administrador">Administrador</option>
                  <option value="temporario">Temporário</option>
                  <option value="funcionario">Funcionário</option>
                  <option value="caixa">Caixa</option>
                  <option value="medico">Médico</option>
                  <option value="portaria">Acesso</option>
                  <option value="almoxarifado">Almoxarifado</option>
                  <option value="administrativo">Administrativo</option>
                </Form.Control>
              </Col>

              {this.state.tipo_usuario === "funcionario" && (
                <Col sm={6}>
                  <Form.Label>Função</Form.Label>
                  <FormControl
                    placeholder="Função"
                    aria-describedby="basic-addon1"
                    name="funcao"
                    onChange={this.alteracao}
                    required
                    defaultValue={this.state.funcao}
                  />
                </Col>
              )}

              {this.state.infoPessoa !== null && (
                <Col sm={12} style={{ paddingTop: "10px" }}>
                  <Header as="h3">Dados Cadastrais</Header>
                  <Segment.Group>
                    <Segment.Group horizontal>
                      <Segment>
                        <b>CPF/CNPJ:</b>{" "}
                        {this.state.infoPessoa.cpf !== null
                          ? this.state.infoPessoa.cpf
                          : this.state.infoPessoa.cnpj}
                      </Segment>
                      <Segment>
                        <b>Telefone:</b> {this.state.infoPessoa.telefone}
                      </Segment>
                      <Segment>
                        <b>E-mail:</b> {this.state.infoPessoa.email}
                      </Segment>
                    </Segment.Group>
                    <Segment>
                      <b>Endereço:</b> {this.state.infoPessoa.endereco} -{" "}
                      {this.state.infoPessoa.bairro},{" "}
                      {this.state.infoPessoa.cep} {this.state.infoPessoa.cidade}
                      -{this.state.infoPessoa.estado}
                    </Segment>
                  </Segment.Group>
                </Col>
              )}

              {this.state.tipo_usuario === "dependente" && (
                <Col sm={12} style={{ paddingTop: "10px" }}>
                  <Header as="h2">Titular</Header>
                  <Divider />
                  <Segment.Group>
                    <Segment.Group horizontal>
                      <Segment>
                        <Col sm={6}>
                          <Select
                            placeholder="Digite para buscar"
                            name="usuario"
                            onKeyDown={this.pesquisaUsuario}
                            inputId="pesquisaUsuario"
                            onChange={this.alteracaoSelectUsuario}
                            value={this.state.usuario}
                            options={this.state.listaUsuario}
                          />
                        </Col>
                      </Segment>
                    </Segment.Group>
                  </Segment.Group>
                </Col>
              )}
              {this.state.pendencias.length > 0 && (
                <Col sm={12} style={{ paddingTop: "20px" }}>
                  <Header floated="right">
                    <Button
                      negative
                      onClick={this.handleShowDividas}
                      style={{ float: "right" }}
                      type="button"
                    >
                      Cancelar Dívidas
                    </Button>
                    <Button
                      onClick={this.handleShowTransfereDividas}
                      color="blue"
                      style={{ float: "right" }}
                      type="button"
                    >
                      Transferir Dívidas
                    </Button>
                  </Header>

                  <Header as="h3">Dívidas</Header>
                  <ListaPendencias
                    pendencias={this.state.pendencias}
                    selectedCheckboxes={this.state.selectedCheckboxes}
                    toggleCheckbox={this.toggleCheckbox}
                  />
                </Col>
              )}
              <AcaoTitulo
                acao={this.state.acao}
                novaAcao={this.novaAcao}
                selectTipo={this.state.selectTipo}
                efetuar_cobranca={this.state.efetuar_cobranca}
                listaTipoTitulo={this.state.listaTipoTitulo}
                pessoaCompra={false}
                data_transferencia={this.state.data_transferencia}
              />
            </Row>
          </Paper>
          <Paper style={{ padding: 20, marginTop: 20 }}>
            <Row>
              <Col sm={12} style={{ paddingTop: "10px" }}>
                <h3>Novo Titular</h3>
              </Col>
              <Col sm={6} style={{ paddingTop: "10px" }}>
                <Select
                  placeholder="Digite para buscar"
                  name="pessoaCompra"
                  value={this.state.pessoa_compra}
                  onChange={this.alteracaoSelectPessoaCompra}
                  options={this.state.options_compra}
                  onKeyDown={this.pesquisaPessoaCompra}
                  inputId="pesquisaPessoaCompra"
                  isDisabled={this.state.id !== null}
                  required
                />
              </Col>

              {this.state.infoPessoaTitular !== null && (
                <Col sm={12} style={{ paddingTop: "10px" }}>
                  <Header as="h3">Dados Cadastrais</Header>

                  <VisualizaPessoa
                    pessoa={this.state.infoPessoaTitular}
                    alteracaoPessoa={this.alteracaoPessoa}
                  />
                  <Segment.Group>
                    <Segment.Group horizontal>
                      <Segment>
                        <b>Tipo:</b> {this.state.infoPessoaTitular.tipo}
                      </Segment>
                      <Segment>
                        <b>Telefone:</b> {this.state.infoPessoaTitular.telefone}
                      </Segment>
                      <Segment>
                        <b>E-mail:</b> {this.state.infoPessoaTitular.email}
                      </Segment>
                    </Segment.Group>
                    <Segment>
                      <b>Endereço:</b> {this.state.infoPessoaTitular.endereco} -{" "}
                      {this.state.infoPessoaTitular.bairro},{" "}
                      {this.state.infoPessoaTitular.cep}{" "}
                      {this.state.infoPessoaTitular.cidade}-
                      {this.state.infoPessoaTitular.estado}
                    </Segment>
                  </Segment.Group>
                </Col>
              )}

              <Modal
                open={this.state.open_cancelamento_dividas}
                onClose={this.handleCloseDividas}
                centered={false}
              >
                <Header content="Mensagem" />
                <Modal.Content>
                  <Form.Label>Motivo</Form.Label>
                  <FormControl
                    aria-describedby="basic-addon1"
                    type="text"
                    name="motivo_divida"
                    onChange={this.alteracao}
                  />
                  Confirma Cancelamento ?
                </Modal.Content>
                <Header floated="right">
                  <Row>
                    <Col sm={5}>
                      <Button
                        type="button"
                        variant="light"
                        onClick={this.handleCloseDividas}
                      >
                        Voltar
                      </Button>
                    </Col>
                    <Col sm={5}>
                      <Button positive onClick={this.cancelamentoDividas}>
                        Sim
                      </Button>
                    </Col>
                  </Row>
                </Header>
              </Modal>

              {(this.state.infoPessoaTitular !== null && this.state.infoPessoaTitular !== undefined) && (
                <Modal
                  open={this.state.open_transfere_dividas}
                  onClose={this.handleCloseTransfereDividas}
                  centered={false}
                >
                  <Header content="Transferir as dívidas do título" />
                  <Modal.Content>
                    Confirma a transferência de dívidas para o novo titular?<br />
                    {"Todas as dívidas serão transferidas para '" + this.state.infoPessoaTitular.nome + "', você deseja confirmar?"}
                  </Modal.Content>
                  <Header floated="right">
                    <Row>
                      <Col sm={4}>
                        <Button
                          type="button"
                          variant="light"
                          onClick={this.handleCloseTransfereDividas}
                        >
                          Voltar
                        </Button>
                      </Col>
                      <Col sm={3}>
                        <Button 
                          positive 
                          onClick={()=>this.handleTransfereDividas(true)}>
                          Sim
                        </Button>
                      </Col>
                      <Col sm={3}>
                        <Button negative onClick={()=>this.handleTransfereDividas(false)}>
                          Não
                        </Button>
                      </Col>
                    </Row>
                  </Header>
                </Modal>
              )}

              <AcaoTitulo
                acao={this.state.acao}
                novaAcao={this.novaAcao}
                selectTipo={this.state.selectTipo}
                efetuar_cobranca={this.state.efetuar_cobranca}
                listaTipoTitulo={this.state.listaTipoTitulo}
                pessoaCompra={true}
                alteracao
              />
            </Row>
          </Paper>
          {(this.state.infoPessoa !== null) && (
            <Row>
              <Cobranca
                acao={
                  this.state.acao !== null &&
                    this.state.acao.tipo_titulo.descricao === "REMIDO"
                    ? this.state.acaoPatrimonial
                    : this.state.acao
                }
                setParcelasTaxaTransferencia={this.setParcelasTaxaTransferencia}
                setParcelasMensalidade={this.setParcelasMensalidade}
                setGerarParcelasMensalidade={this.setGerarParcelasMensalidade}
                infoParcelasTaxaTransferencia={
                  this.state.infoParcelasTaxaTransferencia
                }

                infoParcelasMensalidade={this.state.infoParcelasMensalidade}
                instituicao_cobranca_id={this.state.instituicao_cobranca_id}
                instituicao_cobranca={this.state.instituicao_cobranca}
                alteracao={this.alteracao}
                pessoa={this.state.infoPessoaTitular}
                cobrancaBancariaTaxaTransferencia={
                  this.state.cobrancaBancariaTaxaTransferencia
                }
                dataTransferencia={this.state.data_transferencia}
                setState={(a) => this.setState(a)}
              />

              <ModalDependente
                acao={this.state.acao}
                open_modal_dependentes={this.state.open_modal_dependentes}
                handleCloseDependentes={this.handleCloseDependentes}
                adicionaDependentes={this.adicionaDependentes}
                titular_id={this.state.pessoa_compra_id}
                numero_titulo={this.state.numero_titulo}
                pessoa_dependente={this.state.novo_dependente}
              />
              <ListaDependentes
                dependentes={this.state.dependentes}
                removeDependentes={this.removeDependentes}
                handleOpenDependentes={this.handleOpenDependentes}
              />
            </Row>
          )}

          <Divider />
          <Segment clearing>
            <Header floated="right">
              <Button type="button" onClick={this.voltar}>
                Voltar
              </Button>
              <Button
                negative
                type="button"
                onClick={this.excluir}

              >
                Deletar
              </Button>
              <Button
                positive
                type="submit"
                loading={this.state.loading}
                disabled={this.state.loading}
              >
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}
export default withRouter(Usuario);
