import React, { Component } from "react";
import { Row, Col, FormControl, Form } from "react-bootstrap";
import {
  Header,
  Button,
  Segment,
  Popup,
  Divider,
  Checkbox,
} from "semantic-ui-react";
import {
  Snackbar,
  Alert,
} from "@material-ui/core/";
import requisicoes from "../../../utils/Requisicoes";
import ModalServicoMensalidade from "./modalServicoMensalidade";
import ModalServicoTitulo from "./modalServicoTitulo";
import ModalServicoTaxaTransferencia from "./modalServicoTaxaTranferencia";
import renomearChave from "../../../services/renomearChave";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import "./styles.css"
var formatterNumbrer = new Intl.NumberFormat("pt-br", {
  style: 'currency',
  currency: 'BRL'
});
export default class TipoTitulo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      descricao: null,
      valor_titulo: null,
      valorTitulo: null,
      valor_mensalidade: null,
      valor_taxa_transferencia: null,
      dia_vencimento: null,
      servico_mensalidade: null,
      open_modal_servico_mensalidade: false,
      servico_titulo: null,
      open_modal_servico_titulo: false,
      open_modal_servico_taxa_transferencia: false,
      servico_taxa_transferencia: null,
      parametro_idade: false,
      idade_masculino: null,
      idade_feminino: null,
      tempo_titularidade: null,
      liberar_portaria: false,
      socio: false,
      cobrar_dependente: false,
      mensagemSnackBar: { show: false },
      alertSucessIniciar: false,
      porcentagem: null,
      dependente_cobrar: null,
      valor_base_calculo: null,

    };
    this.visualizacao();
    this.alteracao = this.alteracao.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(event, campo) {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, ""); // Remove caracteres não numéricos
    const parsedValue = parseFloat(numericValue) / 100; // Divide por 100 para ajustar as casas decimais
    if (!isNaN(parsedValue)) {
      const formattedValue = parsedValue.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL"
      });
      this.setState({ [campo]: formattedValue }); // Usa o valor de 'campo' como chave dinâmica para atualizar o estado
    } else {
      this.setState({ [campo]: "" });
    }
  }

  alteracao(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  fecharModalTaxaTransferencia = () => {
    this.setState({ open_modal_servico_taxa_transferencia: false });
  };

  alteracaoSelectServicoTaxaTransferencia = (e) => {
    this.setState({ servico_taxa_transferencia: e });
    this.setState({ valor_taxa_transferencia: parseFloat(e.valor) });
  };

  abrirModalTaxaTransferencia = () => {
    this.setState({ open_modal_servico_taxa_transferencia: true });
  };

  fecharModalMensalidade = () => {
    this.setState({ open_modal_servico_mensalidade: false });
  };

  abrirModalMensalidade = () => {
    this.setState({ open_modal_servico_mensalidade: true });
  };

  alteracaoSelectServicoMensalidade = (e) => {
    this.setState({ servico_mensalidade: e });
    this.setState({ valor_mensalidade: parseFloat(e.valor) });
  };

  fecharModalTitulo = () => {
    this.setState({ open_modal_servico_titulo: false });
  };

  abrirModalTitulo = () => {
    this.setState({ open_modal_servico_titulo: true });
  };

  alteracaoSelectServicoTitulo = (e) => {
    this.setState({ servico_titulo: e });
    this.setState({ valor_titulo: e.valor });
  };

  visualizacao = async () => {
    if (this.props.location.query !== undefined) {
      const resposta = await api.post(
        "/tipoTitulo/visualiza" + requisicoes.entidade,
        this.props.location.query,
        requisicoes.header
      );
      resposta.data.valor_mensalidade =
        resposta.data.valor_mensalidade !== null ?
          formatterNumbrer.format(resposta.data.valor_mensalidade)
          : "R$ 0.00";
      resposta.data.valor_titulo =
        resposta.data.valor_titulo !== null ?
          formatterNumbrer.format(resposta.data.valor_titulo)
          : "R$ 0.00";
      resposta.data.valor_taxa_transferencia =
        resposta.data.valor_taxa_transferencia !== null ?
          formatterNumbrer.format(resposta.data.valor_taxa_transferencia)
          : "R$ 0.00";
      resposta.data.valor_base_calculo =
        resposta.data.valor_base_calculo !== null ?
          formatterNumbrer.format(resposta.data.valor_base_calculo)
          : "R$ 0.00";
      this.setState(resposta.data);
      let objeto_mensalidade = renomearChave(
        resposta.data.servico_mensalidade,
        "id",
        "value"
      );
      objeto_mensalidade = renomearChave(objeto_mensalidade, "nome", "label");
      this.setState({ servico_mensalidade: objeto_mensalidade });
      let objeto_titulo = renomearChave(
        resposta.data.servico_titulo,
        "id",
        "value"
      );
      objeto_titulo = renomearChave(objeto_titulo, "nome", "label");
      this.setState({ servico_titulo: objeto_titulo });
      let objeto_taxa_transferencia = renomearChave(
        resposta.data.servico_taxa_transferencia,
        "id",
        "value"
      );

      objeto_taxa_transferencia = renomearChave(
        objeto_taxa_transferencia,
        "nome",
        "label"
      );
      this.setState({ servico_taxa_transferencia: objeto_taxa_transferencia });
    }
  };

  handleSubmit = (event) => {
    this.submit();
    event.preventDefault();
  };

  converteEmNumero = async (valor, campo) => {
    if (typeof valor === "string") {
      const valor_titulo2 = valor.replace(/\D/g, "");
      const parsedValue = parseFloat(valor_titulo2) / 100;
      return parsedValue;
    }
    return valor;
  };

  submit = async () => {
    if (this.state.servico_mensalidade === null) {
      alert("Favor Informar o Serviço Mensalidade");
    } else {
      const valor_titulo = await this.converteEmNumero(this.state.valor_titulo, "valor_titulo");
      const valor_mensalidade = await this.converteEmNumero(this.state.valor_mensalidade, "valor_mensalidade");
      const valor_taxa_transferencia = await this.converteEmNumero(this.state.valor_taxa_transferencia, "valor_taxa_transferencia");
      const valor_base_calculo = await this.converteEmNumero(this.state.valor_base_calculo, "valor_base_calculo");
      const dados = {
        descricao: this.state.descricao,
        valor_titulo: valor_titulo,
        valor_mensalidade: valor_mensalidade,
        valor_taxa_transferencia: valor_taxa_transferencia,
        valor_base_calculo: valor_base_calculo,
        dia_vencimento: this.state.dia_vencimento,
        tempo_titularidade:
          this.state.tempo_titularidade !== ""
            ? this.state.tempo_titularidade
            : null,
        parametro_idade: this.state.parametro_idade,
        idade_masculino: this.state.idade_masculino,
        idade_feminino: this.state.idade_feminino,
        servico_mensalidade_id: this.state.servico_mensalidade.value,
        servico_titulo_id: this.state.servico_titulo.value,
        servico_taxa_transferencia_id: this.state.servico_taxa_transferencia.value,
        socio: this.state.socio === null ? false : this.state.socio,
        cobrar_dependente: this.state.cobrar_dependente === null ? false : this.state.cobrar_dependente,
        liberar_portaria: this.state.liberar_portaria,
        idade_cobranca: this.state.idade_cobranca,
        porcentagem: this.state.porcentagem,
      };
      try {
        if (this.state.id !== null) {
          await api.put(
            "/tipoTitulo/alterar" + requisicoes.entidade + "&id=" + this.state.id,
            dados,
            requisicoes.header
          );
          this.setState({ alertSucessIniciar: true });
          this.mensagem(true, `Título: ${this.state.descricao}, Salva com sucesso.`, 3500)
        }
      } catch (error) {
        this.mensagem(false, error)
        this.setState({ mensagemErrorIniciar: error, alertSucessIniciar: false });
      }
    }
  };

  excluir = async () => {
    const resposta = await api.post(
      "/tipoTitulo/excluir" + requisicoes.entidade + "&id=" + this.state.id,
      {},
      requisicoes.header
    );
    if (resposta != null) {
      window.location.href = "/tipoTitulo/lista";
    }
  };

  onChangeCheckbox = (evt, data) => {
    this.setState({ parametro_idade: data.checked });
  };
  descricaoPermitida(descricao) {
    const descricoesPermitidas = [
      "PATRIMONIAL",
      "ASSOCIADO PARTICIPATIVO",
      "TEMPORÁRIO",
      "CONTRIBUINTE COM DEPENDENTE",
      "CONTRIBUINTE SEM DEPENDENTES",
      "MAMPITUBA PRAIA",
    ];
    return descricoesPermitidas.includes(descricao);
  }
  mensagem = (positive, content, duration) => {
    this.setState({
      mensagemSnackBar: {
        show: true,
        positive: positive,
        content: content,
        duration: duration || 4000,
      },
    });
  };
  render() {
    const {
      parametro_idade,
    } = this.state;

    return (
      <>
        <Segment>
          <Header
            style={{ textAlign: 'center' }}
            as="h1"
            content={this.state.id ? "Editar Tipo de Título" : "Novo Tipo de Título"}
          />
          <Divider />
          <Form onSubmit={this.handleSubmit}>
            <Form.Group as={Row}>
              <Form.Label column sm="2">
                Descrição:
              </Form.Label>
              <Col sm="4">
                <FormControl
                  name="descricao"
                  type="text"
                  value={this.state.descricao}
                  onChange={this.alteracao}
                  maxLength={50}
                  disabled
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">
                Valor do Título:
              </Form.Label>
              <Col sm="4">
                <FormControl
                  className="dinheiro1"
                  name="valor_titulo"
                  type="text"
                  value={this.state.valor_titulo}
                  onChange={(event) => this.handleChange(event, "valor_titulo")}
                  maxLength={15}
                  required
                />
              </Col>
              <Col sm="6">
                {this.descricaoPermitida(this.state.descricao) && (
                  <>
                    <Button
                      style={{ width: '264px', textAlign: 'left' }}
                      color="green"
                      icon="plus"
                      size="medium"
                      type="button"
                      content="Serviço de Titulo"
                      onClick={this.abrirModalTitulo}
                    />
                    <Popup
                      content="Serviço Titulo"
                      className="popup"
                      trigger={
                        <Button
                          color="black"
                          icon="question circle"
                          circular
                          style={{ marginLeft: "10px" }}
                        />
                      }
                    />
                  </>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">
                Valor da Mensalidade:
              </Form.Label>
              <Col sm="4">
                <FormControl
                  className="dinheiro"
                  name="valor_mensalidade"
                  type="text"
                  value={this.state.valor_mensalidade}
                  onChange={(event) => this.handleChange(event, "valor_mensalidade")}
                  maxLength={15}
                />
              </Col>
              <Col sm="6">
                {this.descricaoPermitida(this.state.descricao) && (
                  <>
                    <Button
                      style={{ width: '264px', textAlign: 'left' }}
                      color="green"
                      icon="plus"
                      size="medium"
                      type="button"
                      content="Serviço de Mensalidade"
                      onClick={this.abrirModalMensalidade}
                    />
                    <Popup
                      className="popup"
                      content="Selecionar serviço de mensalidade"
                      trigger={
                        <Button
                          color="black"
                          icon="question circle"
                          circular
                          style={{ marginLeft: "10px", whiteSpace: "nowrap" }}
                        />
                      }
                    />
                  </>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">
                Valor da Taxa de Transferência:
              </Form.Label>
              <Col sm="4">
                <FormControl
                  className="dinheiro1"
                  name="valor_taxa_transferencia"
                  type="text"
                  value={this.state.valor_taxa_transferencia}
                  onChange={(event) => this.handleChange(event, "valor_taxa_transferencia")}
                  maxLength={15}
                />
              </Col>
              <Col sm="6">
                {this.descricaoPermitida(this.state.descricao) && (
                  <>
                    <Button
                      color="green"
                      icon="plus"
                      content="Serviço de Taxa de Transferência"
                      type="button"
                      onClick={() =>
                        this.setState({
                          open_modal_servico_taxa_transferencia: true,
                        })
                      }
                    />
                    <Popup
                      className="popup"
                      content="Selecionar serviço de taxa de transferência"
                      trigger={
                        <Button
                          color="black"
                          icon="question circle"
                          circular
                          style={{ marginLeft: "10px" }}
                        />
                      }
                    />
                  </>
                )}
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">
                Dia do Vencimento:
              </Form.Label>
              <Col sm="4">
                <FormControl
                  name="dia_vencimento"
                  type="number"
                  min={1}
                  max={31}
                  value={this.state.dia_vencimento}
                  onChange={this.alteracao}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">
                Selecione a idade máxima por gênero:
              </Form.Label>
              <Col sm="10" style={{ marginTop: "7px" }}>
                <Checkbox
                  toggle
                  onChange={(evt, data) =>
                    this.onChangeCheckbox(evt, data)
                  }
                  checked={this.state.parametro_idade}
                />
              </Col>
            </Form.Group>
            {parametro_idade && (
              <>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Idade (Masculino):
                  </Form.Label>
                  <Col sm="4">
                    <FormControl
                      name="idade_masculino"
                      type="number"
                      min={1}
                      max={100}
                      value={this.state.idade_masculino}
                      onChange={this.alteracao}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Idade (Feminino):
                  </Form.Label>
                  <Col sm="4">
                    <FormControl
                      name="idade_feminino"
                      type="number"
                      min={1}
                      max={100}
                      value={this.state.idade_feminino}
                      onChange={this.alteracao}
                    />
                  </Col>
                </Form.Group>
              </>
            )}
            <Form.Group as={Row} style={{ marginTop: "-7px" }}>
              <Form.Label column sm="2">
                Tempo de Titularidade:
              </Form.Label>
              <Col sm="4">
                <FormControl
                  name="tempo_titularidade"
                  type="number"
                  min={0}
                  max={100}
                  value={this.state.tempo_titularidade}
                  onChange={this.alteracao}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">
                Liberar Portaria:
              </Form.Label>
              <Col sm="10">
                <Checkbox
                  toggle
                  onChange={(evt, data) =>
                    this.setState({ liberar_portaria: data.checked })
                  }
                  checked={this.state.liberar_portaria}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">
                Sócio do Clube:
              </Form.Label>
              <Col sm="10">
                <Checkbox
                  toggle
                  onChange={(evt, data) =>
                    this.setState({ socio: data.checked })
                  }
                  checked={this.state.socio}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">
                Cobrar Dependete:
              </Form.Label>
              <Col sm="10">
                <Checkbox
                  toggle
                  onChange={(evt, data) =>
                    this.setState({ cobrar_dependente: data.checked })
                  }
                  checked={this.state.cobrar_dependente}
                />
              </Col>
            </Form.Group>
            {this.state.cobrar_dependente === true && (
              <>
                <Form.Group as={Row} style={{ marginTop: "-7px" }}>
                  <Form.Label column sm="2">
                    Idade de Cobrança:
                  </Form.Label>
                  <Col sm="4">
                    <FormControl
                      name="idade_cobranca"
                      type="number"
                      min={0}
                      max={100}
                      value={this.state.idade_cobranca}
                      onChange={this.alteracao}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} style={{ marginTop: "-7px" }}>
                  <Form.Label column sm="2">
                    Porcentagem:
                  </Form.Label>
                  <Col sm="4">
                    <FormControl
                      name="porcentagem"
                      type="number"
                      min={0}
                      max={100}
                      value={this.state.porcentagem}
                      onChange={this.alteracao}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} style={{ marginTop: "-7px" }}>
                  <Form.Label column sm="2">
                    Base de calculo:
                  </Form.Label>
                  <Col sm="4">
                    <FormControl
                      className="dinheiro"
                      name="valor_base_calculo"
                      type="text"
                      min={0}
                      max={100}
                      value={this.state.valor_base_calculo}
                      onChange={(event) => this.handleChange(event, "valor_base_calculo")}

                    />
                  </Col>
                </Form.Group>
              </>
            )}
            <Form.Group as={Row}>
              <Col sm={{ offset: 2 }}>
                <Link to="/tipoTitulo/lista">
                  <Button type="button" color="blue" content="Voltar" />
                  <Button type="button" color="grey" content="Cancelar" />
                </Link>
                <Button type="submit" color="green" content="Salvar" />
                {this.state.id && (
                  <></>
                  //
                  // <Button
                  //   type="button"
                  //   color="red"
                  //   content="Excluir"
                  //   onClick={this.excluir}
                  //   style={{ marginLeft: "10px" }}
                  // />
                )}
              </Col>
            </Form.Group>
          </Form>
          <Snackbar
            open={this.state.mensagemSnackBar.show}
            autoHideDuration={this.state.mensagemSnackBar?.duration}
            anchorOrigin={{ vertical: "botton", horizontal: "center" }}

            onClose={() =>
              setTimeout(() => {
                this.setState({
                  mensagemSnackBar: {
                    ...this.state.mensagemSnackBar,
                    show: false,
                  },
                });
              }, 500)
            }
          >
            <Alert
              onClose={() =>
                setTimeout(() => {
                  this.setState({
                    mensagemSnackBar: {
                      ...this.state.mensagemSnackBar,
                      show: false,
                    },
                  });
                }, 500)
              }
              severity={
                this.state.mensagemSnackBar?.positive ? "success" : "error"
              }
              sx={{ width: "100%" }}
            >
              {this.state.mensagemSnackBar?.content}
            </Alert>
          </Snackbar>
          <ModalServicoMensalidade
            open_modal_servico_mensalidade={
              this.state.open_modal_servico_mensalidade
            }
            servico_mensalidade={this.state.servico_mensalidade}
            alteracaoSelectServicoMensalidade={
              this.alteracaoSelectServicoMensalidade
            }
            fecharModalMensalidade={this.fecharModalMensalidade}
          />
          <ModalServicoTitulo
            open_modal_servico_titulo={this.state.open_modal_servico_titulo}
            servico_titulo={this.state.servico_titulo}
            alteracaoSelectServicoTitulo={this.alteracaoSelectServicoTitulo}
            fecharModalTitulo={this.fecharModalTitulo}
          />
          <ModalServicoTaxaTransferencia
            open_modal_servico_taxa_transferencia={
              this.state.open_modal_servico_taxa_transferencia
            }
            servico_taxa_transferencia={this.state.servico_taxa_transferencia}
            alteracaoSelectServicoTaxaTransferencia={
              this.alteracaoSelectServicoTaxaTransferencia
            }
            fecharModalTaxaTransferencia={this.fecharModalTaxaTransferencia}
          />
        </Segment>
      </>
    );
  }
}
