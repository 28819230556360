/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Table, Segment, Divider, Header } from "semantic-ui-react";
import { Form, Row } from "react-bootstrap";
import Button from "@material-ui/core/Button";

import SettingsIcon from "@material-ui/icons/Settings";

import "../styles.css";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Segment>
          <Header>
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: 0,
              }}
            >
              <div> TIMES</div>
            </Row>

            <Divider style={{ marginTop: "-0.13ch" }} />
          </Header>
          <Table selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nome</Table.HeaderCell>
                <Table.HeaderCell>Responsável</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.listaTimeInscritosTabela !== undefined ||
              this.props.listaTimeInscritosTabela !== []
                ? this.props.listaTimeInscritosTabela.map((element, index) => {
                    return (
                      <Table.Row>
                        <Table.Cell width={7}>{element.nomeOrg}</Table.Cell>
                        <Table.Cell width={5}>{element.nomeAtleta}</Table.Cell>

                        <Table.Cell>
                          <Button
                            icon
                            style={{ outline: "none" }}
                            size="mini"
                            onClick={() =>
                              this.props.changeModalInscricao(element, true)
                            }
                            disabled={this.props.adicionaOrganizacao === true}
                          >
                            INSCRIÇÃO
                          </Button>
                          <Button
                            icon
                            style={{ outline: "none" }}
                            size="mini"
                            onClick={() =>
                              this.props.excluirEquipe(element, true)
                            }
                            color={"error"}
                            disabled={this.props.adicionaOrganizacao === true}
                          >
                            Deletar
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })
                : ""}
            </Table.Body>
          </Table>
        </Segment>
      </div>
    );
  }
}
