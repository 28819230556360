import React, { Component } from "react";
import { Header, Segment } from "semantic-ui-react";
import { Col, Row } from "react-bootstrap";
import { Paper, Tabs, Tab } from "@material-ui/core";

// Icons
import { Icon as Iconify } from "@iconify/react";

import calculatorIcon from "@iconify-icons/mdi/calculator";
import folderOpenIcon from "@iconify-icons/mdi/folder-open";
import cashMultipleIcon from "@iconify-icons/mdi/cash-multiple";
import clipboardTextIcon from "@iconify-icons/mdi/clipboard-text";
import dollyIcon from "@iconify-icons/mdi/dolly";
import soccerIcon from "@iconify-icons/mdi/soccer";
import handshakeIcon from "@iconify-icons/mdi/handshake";
import undoIcon from "@iconify-icons/mdi/undo";
import barcodeIcon from "@iconify-icons/mdi/barcode";
import curencyUsdOffIcon from "@iconify-icons/mdi/currency-usd-off";
import homeCityIcon from "@iconify-icons/mdi/home-city";

// Componentes
import ContasAPagar from "./contasAPagar/lista";
import Liquidacao from "./liquidacao";
import Consulta from "./consulta/lista";
import ContaBancaria from "./contaBancaria/lista";
import LancamentoBancario from "./lancamentoBancario/lista";
import Fornecedor from "./fornecedor/lista";
import Estorno from "./estorno/lista";
import Recibo from "./recibo/lista";
import Remessa from "./remessa/index";
import Comissionados from "./comissionados/lista";
import Inadimplentes from "./inadimplentes/index";
import Imovel from "../cadastro/imovel/index";

export default class Financeiro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selecionado: localStorage.getItem("pagina_anterior_financeiro")
        ? localStorage.getItem("pagina_anterior_financeiro")
        : this.props.submenus[0].nome,
    };
    // console.log("this.props.submenus: ", this.props.submenus);
  }

  changeTabMenu = async (event, newValue) => {
    localStorage.setItem("pagina_anterior_financeiro", newValue);
    this.setState({ selecionado: newValue });
  };

  render() {
    return (
      <div className="pagina">
        <Header as="h1" dividing>
          Financeiro
        </Header>
        <Segment>
          <Row style={{ paddingBottom: 10 }}>
            <Col sm={12}>
              <Paper square>
                <Tabs
                  indicatorColor="secondary"
                  textColor="primary"
                  style={{ outline: "none" }}
                  variant="scrollable"
                  onChange={this.changeTabMenu}
                  value={this.state.selecionado}
                >
                  {this.props.submenus.find((e) => e.nome === "conta") && (
                    <Tab
                      icon={<Iconify icon={calculatorIcon} width={24} />}
                      style={{ outline: "none" }}
                      label="Conta"
                      value={"contaBancaria"}
                    />
                  )}

                  {this.props.submenus.find(
                    (e) => e.nome === "contas_a_pagar"
                  ) && (
                    <Tab
                      icon={<Iconify icon={folderOpenIcon} width={24} />}
                      style={{ outline: "none" }}
                      label="Contas a Pagar"
                      value={"contasAPagar"}
                    />
                  )}

                  {this.props.submenus.find((e) => e.nome === "liquidacao") && (
                    <Tab
                      icon={<Iconify icon={cashMultipleIcon} width={24} />}
                      style={{ outline: "none" }}
                      label="Liquidação"
                      value={"liquidacao"}
                    />
                  )}

                  {this.props.submenus.find((e) => e.nome === "relatorio") && (
                    <Tab
                      icon={<Iconify icon={clipboardTextIcon} width={24} />}
                      style={{ outline: "none" }}
                      label="Relatório"
                      value={"consulta"}
                    />
                  )}

                  {this.props.submenus.find((e) => e.nome === "fornecedor") && (
                    <Tab
                      icon={<Iconify icon={dollyIcon} width={24} />}
                      style={{ outline: "none" }}
                      label="Fornecedor"
                      value={"fornecedor"}
                    />
                  )}

                  {this.props.submenus.find(
                    (e) => e.nome === "comissionados"
                  ) && (
                    <Tab
                      icon={<Iconify icon={soccerIcon} width={24} />}
                      style={{ outline: "none" }}
                      label="Comissionados"
                      value={"comissionados"}
                    />
                  )}

                  {this.props.submenus.find((e) => e.nome === "recibo") && (
                    <Tab
                      icon={<Iconify icon={handshakeIcon} width={24} />}
                      style={{ outline: "none" }}
                      label="Recibo"
                      value={"recibo"}
                    />
                  )}

                  {this.props.submenus.find((e) => e.nome === "estorno") && (
                    <Tab
                      icon={<Iconify icon={undoIcon} width={24} />}
                      style={{ outline: "none" }}
                      label="Estorno"
                      value={"estorno"}
                    />
                  )}

                  {this.props.submenus.find((e) => e.nome === "remessas") && (
                    <Tab
                      icon={<Iconify icon={barcodeIcon} width={24} />}
                      style={{ outline: "none" }}
                      label="Remessas"
                      value={"remessas"}
                    />
                  )}

                  {this.props.submenus.find(
                    (e) => e.nome === "inadimplentes"
                  ) && (
                    <Tab
                      icon={<Iconify icon={curencyUsdOffIcon} width={24} />}
                      style={{ outline: "none" }}
                      label="Inadimplentes"
                      value={"inadimplentes"}
                    />
                  )}

                  {this.props.submenus.find(
                    (e) => e.nome === "inadimplentes"
                  ) && (
                    <Tab
                      icon={<Iconify icon={homeCityIcon} width={24} />}
                      style={{ outline: "none" }}
                      label="Locações"
                      value={"imovel"}
                    />
                  )}
                </Tabs>
              </Paper>
            </Col>
          </Row>
          <TabPanel
            value={this.state.selecionado}
            index={"contaBancaria"}
            submenus={this.props.submenus}
          >
            <ContaBancaria />
          </TabPanel>
          <TabPanel value={this.state.selecionado} index={"contasAPagar"}>
            <ContasAPagar />
          </TabPanel>
          <TabPanel value={this.state.selecionado} index={"liquidacao"}>
            <Liquidacao {...this.props} />
          </TabPanel>
          <TabPanel value={this.state.selecionado} index={"consulta"}>
            <Consulta />
          </TabPanel>
          <TabPanel value={this.state.selecionado} index={"lancamentoBancario"}>
            <LancamentoBancario />
          </TabPanel>
          <TabPanel value={this.state.selecionado} index={"fornecedor"}>
            <Fornecedor />
          </TabPanel>
          <TabPanel value={this.state.selecionado} index={"estorno"}>
            <Estorno />
          </TabPanel>
          <TabPanel value={this.state.selecionado} index={"recibo"}>
            <Recibo />
          </TabPanel>
          <TabPanel value={this.state.selecionado} index={"remessas"}>
            <Remessa />
          </TabPanel>
          <TabPanel value={this.state.selecionado} index={"comissionados"}>
            <Comissionados />
          </TabPanel>
          <TabPanel value={this.state.selecionado} index={"inadimplentes"}>
            <Inadimplentes />
          </TabPanel>
          <TabPanel value={this.state.selecionado} index={"imovel"}>
            <Imovel />
          </TabPanel>
        </Segment>
      </div>
    );
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
}
