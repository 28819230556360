/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Header, Segment } from "semantic-ui-react";
import { Form } from "react-bootstrap";
import requisicoes from "../../../utils/Requisicoes";
import Button from "@material-ui/core/Button";
import api from "../../../services/api";
import Lista from "./lista";
import ModalParticipacoes from "./modalParticipacoes";
import ModalAdiciona from "./modalAdiciona";

export default class Desporto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizacoesCadastradas: [],
      campeonatoPorTimes: [],
      nome: "",
      organizacaoId: null,
      alertaSalvando: false,
      alertaUpdate: false,
      modalEditar: false,
      alertaAdiciona: false,
      modalAdicionar: false,
    };

    this.listaOrganizacoes();
  }

  listaOrganizacoes = async (event) => {
    const organizacoes = await api.post(
      "/organizacao/listar" + requisicoes.entidade,
      {},
      requisicoes.header
    );
    function ordena(a, b) {
      let aAux = a.nome.toLowerCase();
      let bAux = b.nome.toLowerCase();
      if (aAux > bAux) {
        return 1;
      }
      if (aAux < bAux) {
        return -1;
      }
      return 0;
    }
    organizacoes.data.sort(ordena);
    this.setState({ organizacoesCadastradas: organizacoes.data });
  };

  alteracao = async (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  alteracaoStateOrganizacao = async (organizacao) => {
    let lista = this.state.organizacoesCadastradas;

    let index = this.state.organizacoesCadastradas.findIndex(
      (e) => e.id === organizacao.id
    );
    if (organizacao.acao === "editar") {
      lista[index].nome = organizacao.nome;
    }
    if (organizacao.acao === "excluir") {
      lista.splice(index, 1);
    }
    this.setState({ organizacoesCadastradas: lista });
  };

  editOrganizacao = async (props, status) => {
    if (status === true) {
      const organizacoes = await api.post(
        "/equipe/listaCampeonatoPorEquipes" + requisicoes.entidade,
        { organizacao_id: props.id },
        requisicoes.header
      );
      this.setState({ campeonatoPorTimes: organizacoes.data });
      this.setState({ modalEditar: status });
      this.setState({ organizacaoId: props.id });
      this.setState({ nome: props.nome });
    } else {
      this.setState({ modalEditar: status });
    }
  };

  adicionarOrganizacao = async (status) => {
    this.setState({ modalAdicionar: status });
  };

  updateOrganizacao = async (event) => {
    event.preventDefault();
    this.setState({ alertaUpdate: true });
    const salvar = await api.post(
      "/organizacao/update" + requisicoes.entidade,
      { nome: this.state.nome, id: this.state.organizacaoId },
      requisicoes.header
    );

    this.listaOrganizacoes();
    setTimeout(() => {
      this.setState({ alertaUpdate: false });

      this.setState({ modalEditar: false });
    }, 1000);
  };

  salvar = async (event) => {
    event.preventDefault();

    try {
      this.setState({ alertaSalvando: true, nome: "" });
      const salvar = await api.post(
        "/organizacao/salvar" + requisicoes.entidade,
        { nome: this.state.nome },
        requisicoes.header
      );

      let lista = this.state.organizacoesCadastradas;
      lista.unshift(salvar.data);

      setTimeout(() => {
        this.setState({
          organizacoesCadastradas: lista,
          modalAdicionar: false,
          alertaSalvando: false,
          nome: "",
        });
      }, 1300);
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <div className="paginaSemPaddingTop">
        <Form onSubmit={this.salvar}>
          <Lista
            organizacoesCadastradas={this.state.organizacoesCadastradas}
            editOrganizacao={this.editOrganizacao}
            organizacaoId={this.state.organizacaoId}
            adicionarOrganizacao={this.adicionarOrganizacao}
            alteracaoStateOrganizacao={this.alteracaoStateOrganizacao}
          />

          <ModalParticipacoes
            alteracao={this.alteracao}
            nome={this.state.nome}
            modalEditar={this.state.modalEditar}
            editOrganizacao={this.editOrganizacao}
            alertaOrganizacao={this.state.alertaOrganizacao}
            alertaUpdate={this.state.alertaUpdate}
            updateOrganizacao={this.updateOrganizacao}
            campeonatoPorTimes={this.state.campeonatoPorTimes}
          />

          <ModalAdiciona
            alertaSalvando={this.state.alertaSalvando}
            modalAdicionar={this.state.modalAdicionar}
            adicionarOrganizacao={this.adicionarOrganizacao}
            alertaAdiciona={this.state.alertaAdiciona}
            alteracao={this.alteracao}
            salvar={this.salvar}
          />

          <Segment clearing>
            <Header floated="right">
              <Button type="submit" style={{ outline: "none" }} color="primary">
                Salvar
              </Button>
            </Header>
          </Segment>
        </Form>
      </div>
    );
  }
}
