module.exports = [
  { value: "mensalidade", label: "Mensalidade" },
  { value: "lancamentoCaixa", label: "Lançamento Caixa" },
  { value: "cobrancaServicoAvulsa", label: "Cobrança Serviço Avulsa" },
  { value: "contaAPagar", label: "Conta A Pagar" },
  { value: "sangria", label: "Sangria" },
  { value: "saldoanterior", label: "Saldo Anterior" },
  { value: "suprimento", label: "Suprimento" },
  { value: "venda", label: "Vendas" },
  { value: "debitoConta", label: "Debito Conta" },
  { value: "titulo", label: "Título" },
  { value: "servico", label: "Serviço" },
  { value: "transferencia", label: "Transferência" },
  { value: "imovel", label: "Imóvel" },
  { value: "inscricao", label: "Inscrição" },
];
